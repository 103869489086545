/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const ProtoPoint = $root.ProtoPoint = (() => {

    /**
     * Namespace ProtoPoint.
     * @exports ProtoPoint
     * @namespace
     */
    const ProtoPoint = {};

    ProtoPoint.PointName = (function() {

        /**
         * Properties of a PointName.
         * @memberof ProtoPoint
         * @interface IPointName
         * @property {string|null} [name] PointName name
         * @property {number|null} [price] PointName price
         * @property {boolean|null} [charge] PointName charge
         * @property {string|null} [maxPrice] PointName maxPrice
         * @property {string|null} [perMaxPrice] PointName perMaxPrice
         * @property {string|null} [currency] PointName currency
         */

        /**
         * Constructs a new PointName.
         * @memberof ProtoPoint
         * @classdesc Represents a PointName.
         * @implements IPointName
         * @constructor
         * @param {ProtoPoint.IPointName=} [p] Properties to set
         */
        function PointName(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PointName name.
         * @member {string} name
         * @memberof ProtoPoint.PointName
         * @instance
         */
        PointName.prototype.name = "";

        /**
         * PointName price.
         * @member {number} price
         * @memberof ProtoPoint.PointName
         * @instance
         */
        PointName.prototype.price = 0;

        /**
         * PointName charge.
         * @member {boolean} charge
         * @memberof ProtoPoint.PointName
         * @instance
         */
        PointName.prototype.charge = false;

        /**
         * PointName maxPrice.
         * @member {string} maxPrice
         * @memberof ProtoPoint.PointName
         * @instance
         */
        PointName.prototype.maxPrice = "";

        /**
         * PointName perMaxPrice.
         * @member {string} perMaxPrice
         * @memberof ProtoPoint.PointName
         * @instance
         */
        PointName.prototype.perMaxPrice = "";

        /**
         * PointName currency.
         * @member {string} currency
         * @memberof ProtoPoint.PointName
         * @instance
         */
        PointName.prototype.currency = "";

        /**
         * Creates a new PointName instance using the specified properties.
         * @function create
         * @memberof ProtoPoint.PointName
         * @static
         * @param {ProtoPoint.IPointName=} [properties] Properties to set
         * @returns {ProtoPoint.PointName} PointName instance
         */
        PointName.create = function create(properties) {
            return new PointName(properties);
        };

        /**
         * Encodes the specified PointName message. Does not implicitly {@link ProtoPoint.PointName.verify|verify} messages.
         * @function encode
         * @memberof ProtoPoint.PointName
         * @static
         * @param {ProtoPoint.IPointName} m PointName message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PointName.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(17).double(m.price);
            if (m.charge != null && Object.hasOwnProperty.call(m, "charge"))
                w.uint32(24).bool(m.charge);
            if (m.maxPrice != null && Object.hasOwnProperty.call(m, "maxPrice"))
                w.uint32(34).string(m.maxPrice);
            if (m.perMaxPrice != null && Object.hasOwnProperty.call(m, "perMaxPrice"))
                w.uint32(42).string(m.perMaxPrice);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(50).string(m.currency);
            return w;
        };

        /**
         * Decodes a PointName message from the specified reader or buffer.
         * @function decode
         * @memberof ProtoPoint.PointName
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {ProtoPoint.PointName} PointName
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PointName.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.ProtoPoint.PointName();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.name = r.string();
                    break;
                case 2:
                    m.price = r.double();
                    break;
                case 3:
                    m.charge = r.bool();
                    break;
                case 4:
                    m.maxPrice = r.string();
                    break;
                case 5:
                    m.perMaxPrice = r.string();
                    break;
                case 6:
                    m.currency = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PointName message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtoPoint.PointName
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {ProtoPoint.PointName} PointName
         */
        PointName.fromObject = function fromObject(d) {
            if (d instanceof $root.ProtoPoint.PointName)
                return d;
            var m = new $root.ProtoPoint.PointName();
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.charge != null) {
                m.charge = Boolean(d.charge);
            }
            if (d.maxPrice != null) {
                m.maxPrice = String(d.maxPrice);
            }
            if (d.perMaxPrice != null) {
                m.perMaxPrice = String(d.perMaxPrice);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            return m;
        };

        /**
         * Creates a plain object from a PointName message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtoPoint.PointName
         * @static
         * @param {ProtoPoint.PointName} m PointName
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PointName.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.name = "";
                d.price = 0;
                d.charge = false;
                d.maxPrice = "";
                d.perMaxPrice = "";
                d.currency = "";
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.charge != null && m.hasOwnProperty("charge")) {
                d.charge = m.charge;
            }
            if (m.maxPrice != null && m.hasOwnProperty("maxPrice")) {
                d.maxPrice = m.maxPrice;
            }
            if (m.perMaxPrice != null && m.hasOwnProperty("perMaxPrice")) {
                d.perMaxPrice = m.perMaxPrice;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            return d;
        };

        /**
         * Converts this PointName to JSON.
         * @function toJSON
         * @memberof ProtoPoint.PointName
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PointName.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PointName;
    })();

    ProtoPoint.UserPoint = (function() {

        /**
         * Properties of a UserPoint.
         * @memberof ProtoPoint
         * @interface IUserPoint
         * @property {string|null} [uid] UserPoint uid
         * @property {string|null} [value] UserPoint value
         * @property {number|Long|null} [registerDate] UserPoint registerDate
         * @property {number|Long|null} [modifyDate] UserPoint modifyDate
         * @property {string|null} [expireInMonth] UserPoint expireInMonth
         */

        /**
         * Constructs a new UserPoint.
         * @memberof ProtoPoint
         * @classdesc Represents a UserPoint.
         * @implements IUserPoint
         * @constructor
         * @param {ProtoPoint.IUserPoint=} [p] Properties to set
         */
        function UserPoint(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserPoint uid.
         * @member {string} uid
         * @memberof ProtoPoint.UserPoint
         * @instance
         */
        UserPoint.prototype.uid = "";

        /**
         * UserPoint value.
         * @member {string} value
         * @memberof ProtoPoint.UserPoint
         * @instance
         */
        UserPoint.prototype.value = "";

        /**
         * UserPoint registerDate.
         * @member {number|Long} registerDate
         * @memberof ProtoPoint.UserPoint
         * @instance
         */
        UserPoint.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UserPoint modifyDate.
         * @member {number|Long} modifyDate
         * @memberof ProtoPoint.UserPoint
         * @instance
         */
        UserPoint.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UserPoint expireInMonth.
         * @member {string} expireInMonth
         * @memberof ProtoPoint.UserPoint
         * @instance
         */
        UserPoint.prototype.expireInMonth = "";

        /**
         * Creates a new UserPoint instance using the specified properties.
         * @function create
         * @memberof ProtoPoint.UserPoint
         * @static
         * @param {ProtoPoint.IUserPoint=} [properties] Properties to set
         * @returns {ProtoPoint.UserPoint} UserPoint instance
         */
        UserPoint.create = function create(properties) {
            return new UserPoint(properties);
        };

        /**
         * Encodes the specified UserPoint message. Does not implicitly {@link ProtoPoint.UserPoint.verify|verify} messages.
         * @function encode
         * @memberof ProtoPoint.UserPoint
         * @static
         * @param {ProtoPoint.IUserPoint} m UserPoint message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserPoint.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(18).string(m.value);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(32).int64(m.modifyDate);
            if (m.expireInMonth != null && Object.hasOwnProperty.call(m, "expireInMonth"))
                w.uint32(42).string(m.expireInMonth);
            return w;
        };

        /**
         * Decodes a UserPoint message from the specified reader or buffer.
         * @function decode
         * @memberof ProtoPoint.UserPoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {ProtoPoint.UserPoint} UserPoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserPoint.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.ProtoPoint.UserPoint();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.value = r.string();
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    m.modifyDate = r.int64();
                    break;
                case 5:
                    m.expireInMonth = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserPoint message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtoPoint.UserPoint
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {ProtoPoint.UserPoint} UserPoint
         */
        UserPoint.fromObject = function fromObject(d) {
            if (d instanceof $root.ProtoPoint.UserPoint)
                return d;
            var m = new $root.ProtoPoint.UserPoint();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.value != null) {
                m.value = String(d.value);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.expireInMonth != null) {
                m.expireInMonth = String(d.expireInMonth);
            }
            return m;
        };

        /**
         * Creates a plain object from a UserPoint message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtoPoint.UserPoint
         * @static
         * @param {ProtoPoint.UserPoint} m UserPoint
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserPoint.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.value = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.expireInMonth = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = m.value;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.expireInMonth != null && m.hasOwnProperty("expireInMonth")) {
                d.expireInMonth = m.expireInMonth;
            }
            return d;
        };

        /**
         * Converts this UserPoint to JSON.
         * @function toJSON
         * @memberof ProtoPoint.UserPoint
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserPoint.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserPoint;
    })();

    ProtoPoint.UserPointHistory = (function() {

        /**
         * Properties of a UserPointHistory.
         * @memberof ProtoPoint
         * @interface IUserPointHistory
         * @property {string|null} [uid] UserPointHistory uid
         * @property {string|null} [pointUID] UserPointHistory pointUID
         * @property {number|null} [useType] UserPointHistory useType
         * @property {number|null} [type] UserPointHistory type
         * @property {string|null} [targetUID] UserPointHistory targetUID
         * @property {string|null} [targetGuid] UserPointHistory targetGuid
         * @property {string|null} [text1] UserPointHistory text1
         * @property {string|null} [text2] UserPointHistory text2
         * @property {string|null} [value] UserPointHistory value
         * @property {string|null} [remain] UserPointHistory remain
         * @property {string|null} [portion] UserPointHistory portion
         * @property {string|null} [memo] UserPointHistory memo
         * @property {number|Long|null} [expireDate] UserPointHistory expireDate
         * @property {number|Long|null} [registerDate] UserPointHistory registerDate
         * @property {number|Long|null} [modifyDate] UserPointHistory modifyDate
         * @property {number|null} [status] UserPointHistory status
         */

        /**
         * Constructs a new UserPointHistory.
         * @memberof ProtoPoint
         * @classdesc Represents a UserPointHistory.
         * @implements IUserPointHistory
         * @constructor
         * @param {ProtoPoint.IUserPointHistory=} [p] Properties to set
         */
        function UserPointHistory(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserPointHistory uid.
         * @member {string} uid
         * @memberof ProtoPoint.UserPointHistory
         * @instance
         */
        UserPointHistory.prototype.uid = "";

        /**
         * UserPointHistory pointUID.
         * @member {string} pointUID
         * @memberof ProtoPoint.UserPointHistory
         * @instance
         */
        UserPointHistory.prototype.pointUID = "";

        /**
         * UserPointHistory useType.
         * @member {number} useType
         * @memberof ProtoPoint.UserPointHistory
         * @instance
         */
        UserPointHistory.prototype.useType = 0;

        /**
         * UserPointHistory type.
         * @member {number} type
         * @memberof ProtoPoint.UserPointHistory
         * @instance
         */
        UserPointHistory.prototype.type = 0;

        /**
         * UserPointHistory targetUID.
         * @member {string} targetUID
         * @memberof ProtoPoint.UserPointHistory
         * @instance
         */
        UserPointHistory.prototype.targetUID = "";

        /**
         * UserPointHistory targetGuid.
         * @member {string} targetGuid
         * @memberof ProtoPoint.UserPointHistory
         * @instance
         */
        UserPointHistory.prototype.targetGuid = "";

        /**
         * UserPointHistory text1.
         * @member {string} text1
         * @memberof ProtoPoint.UserPointHistory
         * @instance
         */
        UserPointHistory.prototype.text1 = "";

        /**
         * UserPointHistory text2.
         * @member {string} text2
         * @memberof ProtoPoint.UserPointHistory
         * @instance
         */
        UserPointHistory.prototype.text2 = "";

        /**
         * UserPointHistory value.
         * @member {string} value
         * @memberof ProtoPoint.UserPointHistory
         * @instance
         */
        UserPointHistory.prototype.value = "";

        /**
         * UserPointHistory remain.
         * @member {string} remain
         * @memberof ProtoPoint.UserPointHistory
         * @instance
         */
        UserPointHistory.prototype.remain = "";

        /**
         * UserPointHistory portion.
         * @member {string} portion
         * @memberof ProtoPoint.UserPointHistory
         * @instance
         */
        UserPointHistory.prototype.portion = "";

        /**
         * UserPointHistory memo.
         * @member {string} memo
         * @memberof ProtoPoint.UserPointHistory
         * @instance
         */
        UserPointHistory.prototype.memo = "";

        /**
         * UserPointHistory expireDate.
         * @member {number|Long} expireDate
         * @memberof ProtoPoint.UserPointHistory
         * @instance
         */
        UserPointHistory.prototype.expireDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UserPointHistory registerDate.
         * @member {number|Long} registerDate
         * @memberof ProtoPoint.UserPointHistory
         * @instance
         */
        UserPointHistory.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UserPointHistory modifyDate.
         * @member {number|Long} modifyDate
         * @memberof ProtoPoint.UserPointHistory
         * @instance
         */
        UserPointHistory.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UserPointHistory status.
         * @member {number} status
         * @memberof ProtoPoint.UserPointHistory
         * @instance
         */
        UserPointHistory.prototype.status = 0;

        /**
         * Creates a new UserPointHistory instance using the specified properties.
         * @function create
         * @memberof ProtoPoint.UserPointHistory
         * @static
         * @param {ProtoPoint.IUserPointHistory=} [properties] Properties to set
         * @returns {ProtoPoint.UserPointHistory} UserPointHistory instance
         */
        UserPointHistory.create = function create(properties) {
            return new UserPointHistory(properties);
        };

        /**
         * Encodes the specified UserPointHistory message. Does not implicitly {@link ProtoPoint.UserPointHistory.verify|verify} messages.
         * @function encode
         * @memberof ProtoPoint.UserPointHistory
         * @static
         * @param {ProtoPoint.IUserPointHistory} m UserPointHistory message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserPointHistory.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.pointUID != null && Object.hasOwnProperty.call(m, "pointUID"))
                w.uint32(18).string(m.pointUID);
            if (m.useType != null && Object.hasOwnProperty.call(m, "useType"))
                w.uint32(24).int32(m.useType);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(32).int32(m.type);
            if (m.targetUID != null && Object.hasOwnProperty.call(m, "targetUID"))
                w.uint32(42).string(m.targetUID);
            if (m.targetGuid != null && Object.hasOwnProperty.call(m, "targetGuid"))
                w.uint32(50).string(m.targetGuid);
            if (m.text1 != null && Object.hasOwnProperty.call(m, "text1"))
                w.uint32(58).string(m.text1);
            if (m.text2 != null && Object.hasOwnProperty.call(m, "text2"))
                w.uint32(66).string(m.text2);
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(74).string(m.value);
            if (m.remain != null && Object.hasOwnProperty.call(m, "remain"))
                w.uint32(82).string(m.remain);
            if (m.portion != null && Object.hasOwnProperty.call(m, "portion"))
                w.uint32(90).string(m.portion);
            if (m.memo != null && Object.hasOwnProperty.call(m, "memo"))
                w.uint32(98).string(m.memo);
            if (m.expireDate != null && Object.hasOwnProperty.call(m, "expireDate"))
                w.uint32(104).int64(m.expireDate);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(112).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(120).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(128).int32(m.status);
            return w;
        };

        /**
         * Decodes a UserPointHistory message from the specified reader or buffer.
         * @function decode
         * @memberof ProtoPoint.UserPointHistory
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {ProtoPoint.UserPointHistory} UserPointHistory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserPointHistory.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.ProtoPoint.UserPointHistory();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.pointUID = r.string();
                    break;
                case 3:
                    m.useType = r.int32();
                    break;
                case 4:
                    m.type = r.int32();
                    break;
                case 5:
                    m.targetUID = r.string();
                    break;
                case 6:
                    m.targetGuid = r.string();
                    break;
                case 7:
                    m.text1 = r.string();
                    break;
                case 8:
                    m.text2 = r.string();
                    break;
                case 9:
                    m.value = r.string();
                    break;
                case 10:
                    m.remain = r.string();
                    break;
                case 11:
                    m.portion = r.string();
                    break;
                case 12:
                    m.memo = r.string();
                    break;
                case 13:
                    m.expireDate = r.int64();
                    break;
                case 14:
                    m.registerDate = r.int64();
                    break;
                case 15:
                    m.modifyDate = r.int64();
                    break;
                case 16:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserPointHistory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtoPoint.UserPointHistory
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {ProtoPoint.UserPointHistory} UserPointHistory
         */
        UserPointHistory.fromObject = function fromObject(d) {
            if (d instanceof $root.ProtoPoint.UserPointHistory)
                return d;
            var m = new $root.ProtoPoint.UserPointHistory();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.pointUID != null) {
                m.pointUID = String(d.pointUID);
            }
            if (d.useType != null) {
                m.useType = d.useType | 0;
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.targetUID != null) {
                m.targetUID = String(d.targetUID);
            }
            if (d.targetGuid != null) {
                m.targetGuid = String(d.targetGuid);
            }
            if (d.text1 != null) {
                m.text1 = String(d.text1);
            }
            if (d.text2 != null) {
                m.text2 = String(d.text2);
            }
            if (d.value != null) {
                m.value = String(d.value);
            }
            if (d.remain != null) {
                m.remain = String(d.remain);
            }
            if (d.portion != null) {
                m.portion = String(d.portion);
            }
            if (d.memo != null) {
                m.memo = String(d.memo);
            }
            if (d.expireDate != null) {
                if ($util.Long)
                    (m.expireDate = $util.Long.fromValue(d.expireDate)).unsigned = false;
                else if (typeof d.expireDate === "string")
                    m.expireDate = parseInt(d.expireDate, 10);
                else if (typeof d.expireDate === "number")
                    m.expireDate = d.expireDate;
                else if (typeof d.expireDate === "object")
                    m.expireDate = new $util.LongBits(d.expireDate.low >>> 0, d.expireDate.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a UserPointHistory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtoPoint.UserPointHistory
         * @static
         * @param {ProtoPoint.UserPointHistory} m UserPointHistory
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserPointHistory.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.pointUID = "";
                d.useType = 0;
                d.type = 0;
                d.targetUID = "";
                d.targetGuid = "";
                d.text1 = "";
                d.text2 = "";
                d.value = "";
                d.remain = "";
                d.portion = "";
                d.memo = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.expireDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.expireDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.pointUID != null && m.hasOwnProperty("pointUID")) {
                d.pointUID = m.pointUID;
            }
            if (m.useType != null && m.hasOwnProperty("useType")) {
                d.useType = m.useType;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.targetUID != null && m.hasOwnProperty("targetUID")) {
                d.targetUID = m.targetUID;
            }
            if (m.targetGuid != null && m.hasOwnProperty("targetGuid")) {
                d.targetGuid = m.targetGuid;
            }
            if (m.text1 != null && m.hasOwnProperty("text1")) {
                d.text1 = m.text1;
            }
            if (m.text2 != null && m.hasOwnProperty("text2")) {
                d.text2 = m.text2;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = m.value;
            }
            if (m.remain != null && m.hasOwnProperty("remain")) {
                d.remain = m.remain;
            }
            if (m.portion != null && m.hasOwnProperty("portion")) {
                d.portion = m.portion;
            }
            if (m.memo != null && m.hasOwnProperty("memo")) {
                d.memo = m.memo;
            }
            if (m.expireDate != null && m.hasOwnProperty("expireDate")) {
                if (typeof m.expireDate === "number")
                    d.expireDate = o.longs === String ? String(m.expireDate) : m.expireDate;
                else
                    d.expireDate = o.longs === String ? $util.Long.prototype.toString.call(m.expireDate) : o.longs === Number ? new $util.LongBits(m.expireDate.low >>> 0, m.expireDate.high >>> 0).toNumber() : m.expireDate;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this UserPointHistory to JSON.
         * @function toJSON
         * @memberof ProtoPoint.UserPointHistory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserPointHistory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserPointHistory;
    })();

    ProtoPoint.UserPointHistoryList = (function() {

        /**
         * Properties of a UserPointHistoryList.
         * @memberof ProtoPoint
         * @interface IUserPointHistoryList
         * @property {Array.<ProtoPoint.IUserPointHistory>|null} [value] UserPointHistoryList value
         */

        /**
         * Constructs a new UserPointHistoryList.
         * @memberof ProtoPoint
         * @classdesc Represents a UserPointHistoryList.
         * @implements IUserPointHistoryList
         * @constructor
         * @param {ProtoPoint.IUserPointHistoryList=} [p] Properties to set
         */
        function UserPointHistoryList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserPointHistoryList value.
         * @member {Array.<ProtoPoint.IUserPointHistory>} value
         * @memberof ProtoPoint.UserPointHistoryList
         * @instance
         */
        UserPointHistoryList.prototype.value = $util.emptyArray;

        /**
         * Creates a new UserPointHistoryList instance using the specified properties.
         * @function create
         * @memberof ProtoPoint.UserPointHistoryList
         * @static
         * @param {ProtoPoint.IUserPointHistoryList=} [properties] Properties to set
         * @returns {ProtoPoint.UserPointHistoryList} UserPointHistoryList instance
         */
        UserPointHistoryList.create = function create(properties) {
            return new UserPointHistoryList(properties);
        };

        /**
         * Encodes the specified UserPointHistoryList message. Does not implicitly {@link ProtoPoint.UserPointHistoryList.verify|verify} messages.
         * @function encode
         * @memberof ProtoPoint.UserPointHistoryList
         * @static
         * @param {ProtoPoint.IUserPointHistoryList} m UserPointHistoryList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserPointHistoryList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.ProtoPoint.UserPointHistory.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a UserPointHistoryList message from the specified reader or buffer.
         * @function decode
         * @memberof ProtoPoint.UserPointHistoryList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {ProtoPoint.UserPointHistoryList} UserPointHistoryList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserPointHistoryList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.ProtoPoint.UserPointHistoryList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.ProtoPoint.UserPointHistory.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserPointHistoryList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtoPoint.UserPointHistoryList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {ProtoPoint.UserPointHistoryList} UserPointHistoryList
         */
        UserPointHistoryList.fromObject = function fromObject(d) {
            if (d instanceof $root.ProtoPoint.UserPointHistoryList)
                return d;
            var m = new $root.ProtoPoint.UserPointHistoryList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".ProtoPoint.UserPointHistoryList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".ProtoPoint.UserPointHistoryList.value: object expected");
                    m.value[i] = $root.ProtoPoint.UserPointHistory.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a UserPointHistoryList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtoPoint.UserPointHistoryList
         * @static
         * @param {ProtoPoint.UserPointHistoryList} m UserPointHistoryList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserPointHistoryList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.ProtoPoint.UserPointHistory.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this UserPointHistoryList to JSON.
         * @function toJSON
         * @memberof ProtoPoint.UserPointHistoryList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserPointHistoryList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserPointHistoryList;
    })();

    ProtoPoint.UserPointCode = (function() {

        /**
         * Properties of a UserPointCode.
         * @memberof ProtoPoint
         * @interface IUserPointCode
         * @property {string|null} [uid] UserPointCode uid
         * @property {number|null} [type] UserPointCode type
         * @property {string|null} [data] UserPointCode data
         * @property {number|Long|null} [expireDate] UserPointCode expireDate
         * @property {number|Long|null} [registerDate] UserPointCode registerDate
         * @property {number|Long|null} [modifyDate] UserPointCode modifyDate
         */

        /**
         * Constructs a new UserPointCode.
         * @memberof ProtoPoint
         * @classdesc Represents a UserPointCode.
         * @implements IUserPointCode
         * @constructor
         * @param {ProtoPoint.IUserPointCode=} [p] Properties to set
         */
        function UserPointCode(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserPointCode uid.
         * @member {string} uid
         * @memberof ProtoPoint.UserPointCode
         * @instance
         */
        UserPointCode.prototype.uid = "";

        /**
         * UserPointCode type.
         * @member {number} type
         * @memberof ProtoPoint.UserPointCode
         * @instance
         */
        UserPointCode.prototype.type = 0;

        /**
         * UserPointCode data.
         * @member {string} data
         * @memberof ProtoPoint.UserPointCode
         * @instance
         */
        UserPointCode.prototype.data = "";

        /**
         * UserPointCode expireDate.
         * @member {number|Long} expireDate
         * @memberof ProtoPoint.UserPointCode
         * @instance
         */
        UserPointCode.prototype.expireDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UserPointCode registerDate.
         * @member {number|Long} registerDate
         * @memberof ProtoPoint.UserPointCode
         * @instance
         */
        UserPointCode.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UserPointCode modifyDate.
         * @member {number|Long} modifyDate
         * @memberof ProtoPoint.UserPointCode
         * @instance
         */
        UserPointCode.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new UserPointCode instance using the specified properties.
         * @function create
         * @memberof ProtoPoint.UserPointCode
         * @static
         * @param {ProtoPoint.IUserPointCode=} [properties] Properties to set
         * @returns {ProtoPoint.UserPointCode} UserPointCode instance
         */
        UserPointCode.create = function create(properties) {
            return new UserPointCode(properties);
        };

        /**
         * Encodes the specified UserPointCode message. Does not implicitly {@link ProtoPoint.UserPointCode.verify|verify} messages.
         * @function encode
         * @memberof ProtoPoint.UserPointCode
         * @static
         * @param {ProtoPoint.IUserPointCode} m UserPointCode message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserPointCode.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.data != null && Object.hasOwnProperty.call(m, "data"))
                w.uint32(26).string(m.data);
            if (m.expireDate != null && Object.hasOwnProperty.call(m, "expireDate"))
                w.uint32(32).int64(m.expireDate);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(40).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(48).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a UserPointCode message from the specified reader or buffer.
         * @function decode
         * @memberof ProtoPoint.UserPointCode
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {ProtoPoint.UserPointCode} UserPointCode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserPointCode.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.ProtoPoint.UserPointCode();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.data = r.string();
                    break;
                case 4:
                    m.expireDate = r.int64();
                    break;
                case 5:
                    m.registerDate = r.int64();
                    break;
                case 6:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserPointCode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtoPoint.UserPointCode
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {ProtoPoint.UserPointCode} UserPointCode
         */
        UserPointCode.fromObject = function fromObject(d) {
            if (d instanceof $root.ProtoPoint.UserPointCode)
                return d;
            var m = new $root.ProtoPoint.UserPointCode();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.data != null) {
                m.data = String(d.data);
            }
            if (d.expireDate != null) {
                if ($util.Long)
                    (m.expireDate = $util.Long.fromValue(d.expireDate)).unsigned = false;
                else if (typeof d.expireDate === "string")
                    m.expireDate = parseInt(d.expireDate, 10);
                else if (typeof d.expireDate === "number")
                    m.expireDate = d.expireDate;
                else if (typeof d.expireDate === "object")
                    m.expireDate = new $util.LongBits(d.expireDate.low >>> 0, d.expireDate.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a UserPointCode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtoPoint.UserPointCode
         * @static
         * @param {ProtoPoint.UserPointCode} m UserPointCode
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserPointCode.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.data = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.expireDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.expireDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.data != null && m.hasOwnProperty("data")) {
                d.data = m.data;
            }
            if (m.expireDate != null && m.hasOwnProperty("expireDate")) {
                if (typeof m.expireDate === "number")
                    d.expireDate = o.longs === String ? String(m.expireDate) : m.expireDate;
                else
                    d.expireDate = o.longs === String ? $util.Long.prototype.toString.call(m.expireDate) : o.longs === Number ? new $util.LongBits(m.expireDate.low >>> 0, m.expireDate.high >>> 0).toNumber() : m.expireDate;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this UserPointCode to JSON.
         * @function toJSON
         * @memberof ProtoPoint.UserPointCode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserPointCode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserPointCode;
    })();

    ProtoPoint.UserPointCodeList = (function() {

        /**
         * Properties of a UserPointCodeList.
         * @memberof ProtoPoint
         * @interface IUserPointCodeList
         * @property {Array.<ProtoPoint.IUserPointCode>|null} [value] UserPointCodeList value
         */

        /**
         * Constructs a new UserPointCodeList.
         * @memberof ProtoPoint
         * @classdesc Represents a UserPointCodeList.
         * @implements IUserPointCodeList
         * @constructor
         * @param {ProtoPoint.IUserPointCodeList=} [p] Properties to set
         */
        function UserPointCodeList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserPointCodeList value.
         * @member {Array.<ProtoPoint.IUserPointCode>} value
         * @memberof ProtoPoint.UserPointCodeList
         * @instance
         */
        UserPointCodeList.prototype.value = $util.emptyArray;

        /**
         * Creates a new UserPointCodeList instance using the specified properties.
         * @function create
         * @memberof ProtoPoint.UserPointCodeList
         * @static
         * @param {ProtoPoint.IUserPointCodeList=} [properties] Properties to set
         * @returns {ProtoPoint.UserPointCodeList} UserPointCodeList instance
         */
        UserPointCodeList.create = function create(properties) {
            return new UserPointCodeList(properties);
        };

        /**
         * Encodes the specified UserPointCodeList message. Does not implicitly {@link ProtoPoint.UserPointCodeList.verify|verify} messages.
         * @function encode
         * @memberof ProtoPoint.UserPointCodeList
         * @static
         * @param {ProtoPoint.IUserPointCodeList} m UserPointCodeList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserPointCodeList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.ProtoPoint.UserPointCode.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a UserPointCodeList message from the specified reader or buffer.
         * @function decode
         * @memberof ProtoPoint.UserPointCodeList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {ProtoPoint.UserPointCodeList} UserPointCodeList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserPointCodeList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.ProtoPoint.UserPointCodeList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.ProtoPoint.UserPointCode.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserPointCodeList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtoPoint.UserPointCodeList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {ProtoPoint.UserPointCodeList} UserPointCodeList
         */
        UserPointCodeList.fromObject = function fromObject(d) {
            if (d instanceof $root.ProtoPoint.UserPointCodeList)
                return d;
            var m = new $root.ProtoPoint.UserPointCodeList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".ProtoPoint.UserPointCodeList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".ProtoPoint.UserPointCodeList.value: object expected");
                    m.value[i] = $root.ProtoPoint.UserPointCode.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a UserPointCodeList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtoPoint.UserPointCodeList
         * @static
         * @param {ProtoPoint.UserPointCodeList} m UserPointCodeList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserPointCodeList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.ProtoPoint.UserPointCode.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this UserPointCodeList to JSON.
         * @function toJSON
         * @memberof ProtoPoint.UserPointCodeList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserPointCodeList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserPointCodeList;
    })();

    ProtoPoint.AppPointTier = (function() {

        /**
         * Properties of an AppPointTier.
         * @memberof ProtoPoint
         * @interface IAppPointTier
         * @property {string|null} [point] AppPointTier point
         * @property {string|null} [tierId] AppPointTier tierId
         * @property {number|null} [price] AppPointTier price
         * @property {string|null} [currency] AppPointTier currency
         * @property {string|null} [tierName] AppPointTier tierName
         * @property {string|null} [offerId] AppPointTier offerId
         */

        /**
         * Constructs a new AppPointTier.
         * @memberof ProtoPoint
         * @classdesc Represents an AppPointTier.
         * @implements IAppPointTier
         * @constructor
         * @param {ProtoPoint.IAppPointTier=} [p] Properties to set
         */
        function AppPointTier(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AppPointTier point.
         * @member {string} point
         * @memberof ProtoPoint.AppPointTier
         * @instance
         */
        AppPointTier.prototype.point = "";

        /**
         * AppPointTier tierId.
         * @member {string} tierId
         * @memberof ProtoPoint.AppPointTier
         * @instance
         */
        AppPointTier.prototype.tierId = "";

        /**
         * AppPointTier price.
         * @member {number} price
         * @memberof ProtoPoint.AppPointTier
         * @instance
         */
        AppPointTier.prototype.price = 0;

        /**
         * AppPointTier currency.
         * @member {string} currency
         * @memberof ProtoPoint.AppPointTier
         * @instance
         */
        AppPointTier.prototype.currency = "";

        /**
         * AppPointTier tierName.
         * @member {string} tierName
         * @memberof ProtoPoint.AppPointTier
         * @instance
         */
        AppPointTier.prototype.tierName = "";

        /**
         * AppPointTier offerId.
         * @member {string} offerId
         * @memberof ProtoPoint.AppPointTier
         * @instance
         */
        AppPointTier.prototype.offerId = "";

        /**
         * Creates a new AppPointTier instance using the specified properties.
         * @function create
         * @memberof ProtoPoint.AppPointTier
         * @static
         * @param {ProtoPoint.IAppPointTier=} [properties] Properties to set
         * @returns {ProtoPoint.AppPointTier} AppPointTier instance
         */
        AppPointTier.create = function create(properties) {
            return new AppPointTier(properties);
        };

        /**
         * Encodes the specified AppPointTier message. Does not implicitly {@link ProtoPoint.AppPointTier.verify|verify} messages.
         * @function encode
         * @memberof ProtoPoint.AppPointTier
         * @static
         * @param {ProtoPoint.IAppPointTier} m AppPointTier message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppPointTier.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.point != null && Object.hasOwnProperty.call(m, "point"))
                w.uint32(10).string(m.point);
            if (m.tierId != null && Object.hasOwnProperty.call(m, "tierId"))
                w.uint32(18).string(m.tierId);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(25).double(m.price);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(34).string(m.currency);
            if (m.tierName != null && Object.hasOwnProperty.call(m, "tierName"))
                w.uint32(42).string(m.tierName);
            if (m.offerId != null && Object.hasOwnProperty.call(m, "offerId"))
                w.uint32(50).string(m.offerId);
            return w;
        };

        /**
         * Decodes an AppPointTier message from the specified reader or buffer.
         * @function decode
         * @memberof ProtoPoint.AppPointTier
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {ProtoPoint.AppPointTier} AppPointTier
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppPointTier.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.ProtoPoint.AppPointTier();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.point = r.string();
                    break;
                case 2:
                    m.tierId = r.string();
                    break;
                case 3:
                    m.price = r.double();
                    break;
                case 4:
                    m.currency = r.string();
                    break;
                case 5:
                    m.tierName = r.string();
                    break;
                case 6:
                    m.offerId = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AppPointTier message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtoPoint.AppPointTier
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {ProtoPoint.AppPointTier} AppPointTier
         */
        AppPointTier.fromObject = function fromObject(d) {
            if (d instanceof $root.ProtoPoint.AppPointTier)
                return d;
            var m = new $root.ProtoPoint.AppPointTier();
            if (d.point != null) {
                m.point = String(d.point);
            }
            if (d.tierId != null) {
                m.tierId = String(d.tierId);
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.tierName != null) {
                m.tierName = String(d.tierName);
            }
            if (d.offerId != null) {
                m.offerId = String(d.offerId);
            }
            return m;
        };

        /**
         * Creates a plain object from an AppPointTier message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtoPoint.AppPointTier
         * @static
         * @param {ProtoPoint.AppPointTier} m AppPointTier
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppPointTier.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.point = "";
                d.tierId = "";
                d.price = 0;
                d.currency = "";
                d.tierName = "";
                d.offerId = "";
            }
            if (m.point != null && m.hasOwnProperty("point")) {
                d.point = m.point;
            }
            if (m.tierId != null && m.hasOwnProperty("tierId")) {
                d.tierId = m.tierId;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.tierName != null && m.hasOwnProperty("tierName")) {
                d.tierName = m.tierName;
            }
            if (m.offerId != null && m.hasOwnProperty("offerId")) {
                d.offerId = m.offerId;
            }
            return d;
        };

        /**
         * Converts this AppPointTier to JSON.
         * @function toJSON
         * @memberof ProtoPoint.AppPointTier
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppPointTier.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppPointTier;
    })();

    ProtoPoint.AppPointTierList = (function() {

        /**
         * Properties of an AppPointTierList.
         * @memberof ProtoPoint
         * @interface IAppPointTierList
         * @property {string|null} [perMaxPoint] AppPointTierList perMaxPoint
         * @property {string|null} [maxPoint] AppPointTierList maxPoint
         * @property {Array.<ProtoPoint.IAppPointTier>|null} [tiers] AppPointTierList tiers
         */

        /**
         * Constructs a new AppPointTierList.
         * @memberof ProtoPoint
         * @classdesc Represents an AppPointTierList.
         * @implements IAppPointTierList
         * @constructor
         * @param {ProtoPoint.IAppPointTierList=} [p] Properties to set
         */
        function AppPointTierList(p) {
            this.tiers = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AppPointTierList perMaxPoint.
         * @member {string} perMaxPoint
         * @memberof ProtoPoint.AppPointTierList
         * @instance
         */
        AppPointTierList.prototype.perMaxPoint = "";

        /**
         * AppPointTierList maxPoint.
         * @member {string} maxPoint
         * @memberof ProtoPoint.AppPointTierList
         * @instance
         */
        AppPointTierList.prototype.maxPoint = "";

        /**
         * AppPointTierList tiers.
         * @member {Array.<ProtoPoint.IAppPointTier>} tiers
         * @memberof ProtoPoint.AppPointTierList
         * @instance
         */
        AppPointTierList.prototype.tiers = $util.emptyArray;

        /**
         * Creates a new AppPointTierList instance using the specified properties.
         * @function create
         * @memberof ProtoPoint.AppPointTierList
         * @static
         * @param {ProtoPoint.IAppPointTierList=} [properties] Properties to set
         * @returns {ProtoPoint.AppPointTierList} AppPointTierList instance
         */
        AppPointTierList.create = function create(properties) {
            return new AppPointTierList(properties);
        };

        /**
         * Encodes the specified AppPointTierList message. Does not implicitly {@link ProtoPoint.AppPointTierList.verify|verify} messages.
         * @function encode
         * @memberof ProtoPoint.AppPointTierList
         * @static
         * @param {ProtoPoint.IAppPointTierList} m AppPointTierList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppPointTierList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.perMaxPoint != null && Object.hasOwnProperty.call(m, "perMaxPoint"))
                w.uint32(10).string(m.perMaxPoint);
            if (m.maxPoint != null && Object.hasOwnProperty.call(m, "maxPoint"))
                w.uint32(18).string(m.maxPoint);
            if (m.tiers != null && m.tiers.length) {
                for (var i = 0; i < m.tiers.length; ++i)
                    $root.ProtoPoint.AppPointTier.encode(m.tiers[i], w.uint32(26).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes an AppPointTierList message from the specified reader or buffer.
         * @function decode
         * @memberof ProtoPoint.AppPointTierList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {ProtoPoint.AppPointTierList} AppPointTierList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppPointTierList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.ProtoPoint.AppPointTierList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.perMaxPoint = r.string();
                    break;
                case 2:
                    m.maxPoint = r.string();
                    break;
                case 3:
                    if (!(m.tiers && m.tiers.length))
                        m.tiers = [];
                    m.tiers.push($root.ProtoPoint.AppPointTier.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AppPointTierList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtoPoint.AppPointTierList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {ProtoPoint.AppPointTierList} AppPointTierList
         */
        AppPointTierList.fromObject = function fromObject(d) {
            if (d instanceof $root.ProtoPoint.AppPointTierList)
                return d;
            var m = new $root.ProtoPoint.AppPointTierList();
            if (d.perMaxPoint != null) {
                m.perMaxPoint = String(d.perMaxPoint);
            }
            if (d.maxPoint != null) {
                m.maxPoint = String(d.maxPoint);
            }
            if (d.tiers) {
                if (!Array.isArray(d.tiers))
                    throw TypeError(".ProtoPoint.AppPointTierList.tiers: array expected");
                m.tiers = [];
                for (var i = 0; i < d.tiers.length; ++i) {
                    if (typeof d.tiers[i] !== "object")
                        throw TypeError(".ProtoPoint.AppPointTierList.tiers: object expected");
                    m.tiers[i] = $root.ProtoPoint.AppPointTier.fromObject(d.tiers[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from an AppPointTierList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtoPoint.AppPointTierList
         * @static
         * @param {ProtoPoint.AppPointTierList} m AppPointTierList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppPointTierList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.tiers = [];
            }
            if (o.defaults) {
                d.perMaxPoint = "";
                d.maxPoint = "";
            }
            if (m.perMaxPoint != null && m.hasOwnProperty("perMaxPoint")) {
                d.perMaxPoint = m.perMaxPoint;
            }
            if (m.maxPoint != null && m.hasOwnProperty("maxPoint")) {
                d.maxPoint = m.maxPoint;
            }
            if (m.tiers && m.tiers.length) {
                d.tiers = [];
                for (var j = 0; j < m.tiers.length; ++j) {
                    d.tiers[j] = $root.ProtoPoint.AppPointTier.toObject(m.tiers[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this AppPointTierList to JSON.
         * @function toJSON
         * @memberof ProtoPoint.AppPointTierList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppPointTierList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppPointTierList;
    })();

    return ProtoPoint;
})();

export { $root as default };
