/* eslint-disable @typescript-eslint/no-explicit-any */
import Http from '@/service/api'
import { AxiosRequestConfig } from 'axios'
import { hexToRGBA, rgbaToCss } from '@/model/template'
import { Observable } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { ErrorCode, MessageError } from '@/model/error'
import app from '@/main'

export class APIService {
  readonly domain: string

  constructor(domain: string) {
    this.domain = domain
  }

  public static getDomain(custom: string): Promise<any> {
    return Http.instance().requestAPIJson(`subscription/web/c/${encodeURIComponent(custom)}/domain`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      })
      .catch(e => {
        if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
          return Promise.reject(MessageError.from(ErrorCode.NoDomain))
        }
        return Promise.reject(e)
      })
  }

  public static utmUrl(host: string, token: string): string {
    return process.env.VUE_APP_API_HOST + `subscription/web/utm?host=${encodeURIComponent(host)}&token=${encodeURIComponent(token)}`
  }

  public logo(): Promise<any> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/logo`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      })
      .catch(e => {
        if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
          return Promise.reject(MessageError.from(ErrorCode.NoDomain))
        }
        return Promise.reject(e)
      })
  }

  public btobLogo(): Promise<any> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/btob-logo`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      })
      .catch(e => {
        if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
          return Promise.resolve({})
        }
        return Promise.reject(e)
      })
  }

  public template(content: boolean): Promise<any> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/template?content=${content ? 1 : 0}`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      })
      .catch(e => {
        if (e instanceof MessageError && (e as MessageError).code === ErrorCode.NoContent) {
          return Promise.reject(MessageError.from(ErrorCode.NoDomainPublic))
        }
        return Promise.reject(e)
      })
  }

  public channelIO(oauthType: number, uid?: string): Promise<any> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/channelIO?t=${oauthType}`
    if (uid) {
      url += `&uid=${uid}`
    }
    return Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      })
  }

  public planList(uidList: string[], targetPlaylistUID?: string, targetContentUID?: string): Promise<any[]> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/plan`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        uidList,
        playlistUID: targetPlaylistUID,
        contentUID: targetContentUID
      }
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      })
  }

  public playlistList(uidList: string[]): Promise<any[]> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/playlist`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        uuidList: uidList
      }
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      })
  }

  public contentList(
    uidList: string[],
    pageRow?: number,
    sortType?: number,
    includeDescription?: boolean): Promise<any[]> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/content`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        uuidList: uidList,
        includeDescription,
        pageRow,
        sortType
      }
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      })
  }

  public contentAllList(
    pageNum: number,
    pageRow: number,
    sortType: number,
    includeDescription?: boolean,
    contentTypes?: number[],
    playlistUIDList?: string[]): Promise<any[]> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/content`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        all: true,
        includeDescription,
        includeWillPublic: true,
        pageNum,
        pageRow,
        sortType,
        ct: contentTypes && contentTypes.length > 0 ? contentTypes : undefined,
        playlistUIDList
      }
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      })
  }

  public recentUpdateList(
    uidList: any[],
    pageNum: number,
    pageRow: number,
    sortType: number): Promise<any[]> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/recent-update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        uidList,
        pageNum,
        pageRow,
        sortType
      }
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      })
  }

  public commerceList(
    all: boolean,
    uidList?: string[],
    pageNum?: number,
    pageRow?: number,
    sortType?: number,
    includeSoldOut?: boolean,
    search?: string): Promise<any[]> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/commerce`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        all,
        uuidList: uidList,
        pageNum,
        pageRow,
        sortType,
        includeSoldOut,
        search
      }
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      })
  }

  public fundingList(
    all: boolean,
    uidList?: string[],
    pageNum?: number,
    pageRow?: number,
    sortType?: number,
    includeSoldOut?: boolean,
    search?: string): Promise<any[]> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/funding`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        all,
        uuidList: uidList,
        pageNum,
        pageRow,
        sortType,
        includeSoldOut,
        search
      }
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      })
  }

  public ticketList(
    all: boolean,
    uidList?: string[],
    pageNum?: number,
    pageRow?: number,
    sortType?: number,
    includeSoldOut?: boolean,
    search?: string): Promise<any[]> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/ticket`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        all,
        uuidList: uidList,
        pageNum,
        pageRow,
        sortType,
        includeSoldOut,
        search
      }
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      })
  }

  public workflowList(
    all: boolean,
    uidList?: string[],
    pageNum?: number,
    pageRow?: number,
    sortType?: number): Promise<any[]> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/workflow`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        all,
        uuidList: uidList,
        pageNum,
        pageRow,
        sortType
      }
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      })
  }

  public allPlanList(pageNum: number, pageRow: number, sortType?: number, includeHidden?: boolean): Promise<any[]> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/plan?pageNum=${pageNum}&pageRow=${pageRow}`
    if (sortType !== undefined) {
      url += `&t=${sortType}`
    }
    if (includeHidden !== undefined) {
      url += `&h=${includeHidden ? 1 : 0}`
    }
    return Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      })
  }

  public allPlaylist(
    pageNum: number,
    pageRow: number,
    planUIDList?: string[],
    title?: string,
    sortType?: number): Observable<any[]> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/playlist?pageNum=${pageNum}&pageRow=${pageRow}`
    if (planUIDList) {
      url += planUIDList.map(p => `&p=${p}`).join('')
    }
    if (title) {
      url += `&s=${title}`
    }
    if (sortType !== undefined) {
      url += `&t=${sortType}`
    }
    return fromPromise(Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      }))
  }

  public allPlayableContentList(
    pageNum: number,
    pageRow: number,
    title?: string,
    sortType?: number,
    tagUID?: string): Observable<any[]> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/playable-content?pageNum=${pageNum}&pageRow=${pageRow}`
    if (title) {
      url += `&s=${title}`
    }
    if (sortType !== undefined) {
      url += `&t=${sortType}`
    }
    if (tagUID) {
      url += `&tag=${tagUID}`
    }
    return fromPromise(Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      }))
  }

  public allContentList(
    pageNum: number,
    pageRow: number,
    title?: string,
    sortType?: number,
    tagUID?: string): Observable<any[]> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/content?pageNum=${pageNum}&pageRow=${pageRow}`
    if (title) {
      url += `&s=${encodeURIComponent(title)}`
    }
    if (sortType !== undefined) {
      url += `&t=${sortType}`
    }
    if (tagUID) {
      url += `&tag=${tagUID}`
    }
    return fromPromise(Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      }))
  }

  public allPlaylistContentList(playlistUID: string, pageNum: number, pageRow: number, title?: string): Promise<any[]> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/playlist/${playlistUID}/content?pageNum=${pageNum}&pageRow=${pageRow}`
    if (title) {
      url += `&s=${title}`
    }
    return Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      })
  }

  public allPlanContentList(
    planUID: string,
    pageNum: number,
    pageRow: number,
    title?: string,
    sortType?: number,
    tagUID?: string): Promise<any[]> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/plan/${planUID}/content?pageNum=${pageNum}&pageRow=${pageRow}`
    if (title) {
      url += `&s=${title}`
    }
    if (sortType) {
      url += `&t=${sortType}`
    }
    if (tagUID) {
      url += `&tag=${tagUID}`
    }
    return Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      })
  }

  public static preConfirm(token: string): Promise<any> {
    return Http.instance().requestAPIJson(`subscription/web/preConfirm?token=${encodeURIComponent(token)}`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        const data = res.data as any
        if (data.logo) {
          data.logo.bgCss = rgbaToCss(hexToRGBA(data.logo.bg ?? 'FFFFFF00'))
        }
        return data
      })
      .catch(e => {
        if ((e as MessageError).code === ErrorCode.ServerLoginNeed) {
          // Token Expire
          return Promise.reject(MessageError.from(ErrorCode.LinkTokenInvalid))
        } else {
          return Promise.reject(e)
        }
      })
  }

  public emailCheck(email: string): Promise<boolean> {
    return Http.instance().requestAPIJson(`subscription/web/emailCheck?e=${encodeURIComponent(email)}&d=${encodeURIComponent(this.domain)}`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        const data = res.data as any
        return data === '1'
      })
  }

  public static planAutoScheduleCheck(planUID: string): Promise<any> {
    return Http.instance().requestAPIJson(`subscription/web/plan/${planUID}/auto-schedule/confirm`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      })
  }

  public static register(data: any): Promise<string> {
    return Http.instance().requestAPIJson('subscription/web/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as string
      })
  }

  public static registerV2(data: any): Promise<any> {
    return Http.instance().requestAPIJson('subscription/web/register/v2', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      })
  }

  public userFiles(data: FormData, token?: string, progressBlock?: (progress: number) => void): Promise<any[]> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/signup-file-user`
    if (token) {
      url = `subscription/web/d/${encodeURIComponent(this.domain)}/signup-file?token=${encodeURIComponent(token)}`
    }
    return Http.instance().requestAPIJson(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data,
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const progress = progressEvent.loaded / progressEvent.total
          if (progressBlock) {
            progressBlock(progress)
          }
        }
      }
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      })
      .catch(e => {
        if (e instanceof MessageError && (e as MessageError).code === ErrorCode.ServerTrafficExceed) {
          return Promise.reject(MessageError.from(ErrorCode.ServerTrafficExceedUpload))
        }
        return Promise.reject(e)
      })
  }

  public coupon(planUID: string, code: string): Promise<any> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/plan/${planUID}/coupon?code=${encodeURIComponent(code)}`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      })
  }

  public companyPaymentMethodList(): Observable<number[]> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/payment-method`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as number[]
      }))
  }

  public static userServiceList(
    pageNum: number,
    pageRow: number,
    domain?: string,
    excludeOwner?: boolean,
    onlyThis?: boolean): Observable<any[]> {
    let url = `subscription/web/user/service/list?pageNum=${pageNum}&pageRow=${pageRow}`
    if (domain) {
      url += `&d=${encodeURIComponent(domain)}`
    }
    if (excludeOwner !== undefined) {
      url += `&eo=${excludeOwner ? 1 : 0}`
    }
    if (onlyThis) {
      url += '&ot=1'
    }
    return fromPromise(Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      }))
  }

  public static userGroupList(pageNum: number, pageRow: number, domain?: string): Observable<any[]> {
    let url = `subscription/web/user/group?pageNum=${pageNum}&pageRow=${pageRow}`
    if (domain) {
      url += `&d=${encodeURIComponent(domain)}`
    }
    return fromPromise(Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      }))
  }

  public static userPlanList(
    pageNum: number,
    pageRow: number,
    domain?: string,
    excludeType?: number[],
    includeInvoicePlan?: boolean): Observable<any[]> {
    let url = `subscription/web/user/plan?pageNum=${pageNum}&pageRow=${pageRow}`
    if (domain) {
      url += `&d=${encodeURIComponent(domain)}`
    }
    if (excludeType) {
      url += excludeType.map(value => `&ext=${value}`)
    }
    if (includeInvoicePlan) {
      url += `&iip=${includeInvoicePlan ? 1 : 0}`
    }
    return fromPromise(Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      }))
  }

  public static userPlanCardList(domain?: string): Observable<any[]> {
    let url = 'subscription/web/user/plan/credit'
    if (domain) {
      url += `?d=${encodeURIComponent(domain)}`
    }
    return fromPromise(Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      }))
  }

  public static userHistoryList(pageNum: number, pageRow: number, domain?: string): Observable<any[]> {
    let url = `subscription/web/user/history?pageNum=${pageNum}&pageRow=${pageRow}`
    if (domain) {
      url += `&d=${encodeURIComponent(domain)}`
    }
    return fromPromise(Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      }))
  }

  public static userBookmarkList(pageNum: number, pageRow: number, domain?: string): Observable<any[]> {
    let url = `subscription/web/user/bookmark?pageNum=${pageNum}&pageRow=${pageRow}`
    if (domain) {
      url += `&d=${encodeURIComponent(domain)}`
    }
    return fromPromise(Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      }))
  }

  public static userScheduleHistoryList(pageNum: number, pageRow: number, domain?: string): Observable<any[]> {
    let url = `subscription/web/user/schedule-history?pageNum=${pageNum}&pageRow=${pageRow}`
    if (domain) {
      url += `&d=${encodeURIComponent(domain)}`
    }
    return fromPromise(Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      }))
  }

  public static cancelUserPlan(planUID: string, reserveDate?: string): Observable<number> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/user/service/${planUID}/reserve`, {
      method: 'POST',
      data: JSON.stringify({
        reserveDate
      })
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as number
      }))
  }

  public static recoverUserPlan(
    planUID: string,
    cardToken: string,
    cardType: string | undefined,
    cardNum: string): Observable<number> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/user/service/${planUID}/reserve`, {
      method: 'POST',
      data: JSON.stringify({
        cardToken,
        cardType,
        cardNum
      })
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as number
      }))
  }

  public static updatePassword(current: string, newPassword: string, forceLogout: boolean): Observable<void> {
    return fromPromise(Http.instance().requestAPIJson('auth/changePassword', {
      method: 'POST',
      data: JSON.stringify({
        password: newPassword,
        passwordConfirm: current,
        forceLogout
      })
    } as AxiosRequestConfig)
      .then(_ => {
      }))
  }

  public static me(): Observable<any> {
    return fromPromise(Http.instance().requestAPIJson('subscription/web/user/me', {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      }))
  }

  public userInfo(): Observable<any> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/user/d/${encodeURIComponent(this.domain)}/user`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      }))
  }

  public updateUserInfo(user: any): Observable<number> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/user/d/${encodeURIComponent(this.domain)}/user`, {
      method: 'POST',
      data: JSON.stringify(user)
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as number
      }))
  }

  public static deleteBookmark(contentUID: string): Observable<void> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/user/bookmark/${contentUID}`, {
      method: 'DELETE'
    } as AxiosRequestConfig)
      .then(_ => {
      }))
  }

  public reviewCount(): Observable<string> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/review/count`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as string
      }))
  }

  public reviewList(pageNum: number, pageRow: number, parentUID?: string): Observable<any[]> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/review?pageNum=${pageNum}&pageRow=${pageRow}`
    if (parentUID) {
      url += `&parent=${parentUID}`
    }
    return fromPromise(Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      }))
  }

  public insertReview(planUID: string | undefined, text: string, parentUID?: string): Observable<any> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/review`, {
      method: 'POST',
      data: JSON.stringify({
        planUID,
        text,
        parentUID
      })
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      }))
  }

  public deleteReview(uid: string): Observable<any> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/review/${uid}`, {
      method: 'DELETE'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      }))
  }

  public reviewLike(reviewUID: string, like: boolean): Observable<string> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/review/${reviewUID}/like`, {
      method: like ? 'PUT' : 'DELETE',
      data: undefined
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as string
      }))
  }

  public reviewLiked(reviewUID: string): Observable<boolean> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/review/${reviewUID}/like`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as number > 0
      }))
  }

  public reviewProfile(reviewUID: string): Observable<any> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/review/${reviewUID}/profile`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      }))
  }

  public thumbReview(uid: string): Observable<string> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/review/${uid}/thumb`, {
      method: 'POST'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as string
      }))
  }

  public deleteThumbReview(uid: string): Observable<string> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/review/${uid}/thumb`, {
      method: 'DELETE'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as string
      }))
  }

  public checkJoin(): Observable<boolean> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/user/service/join?d=${encodeURIComponent(this.domain)}`,
        {
          method: 'GET'
        })
        .then(res => {
          return !!res.data
        })
        .catch(e => {
          if (e.code === ErrorCode.NoContent) {
            return false
          }
          return Promise.reject(e)
        })
    )
  }

  public static updatePaymentMethod(
    planUID: string,
    paymentMethod: number,
    cardTokens: string[],
    cardType: string | undefined,
    cardNum: string): Observable<number> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/user/service/${planUID}/change-payment`, {
      method: 'POST',
      data: JSON.stringify({
        planUID,
        paymentMethod,
        thirdCreditToken: cardTokens[0],
        thirdCreditNum: cardNum,
        thirdCreditTokenList: cardTokens,
        thirdCreditType: cardType
      })
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as number
      }))
  }

  public static updateCredit(
    planUID: string,
    cardTokens: string[],
    cardType: string | undefined,
    cardNum: string): Observable<number> {
    return fromPromise(Http.instance().requestAPIJson('subscription/web/user/payment/credit/plan', {
      method: 'POST',
      data: JSON.stringify({
        planUID,
        thirdCreditToken: cardTokens[0],
        thirdCreditNum: cardNum,
        thirdCreditTokenList: cardTokens,
        thirdCreditType: cardType
      })
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as number
      }))
  }

  public static paymentHistoryList(pageNum: number, pageRow: number, domain?: string): Observable<any[]> {
    let url = `subscription/web/user/payment/history?pageNum=${pageNum}&pageRow=${pageRow}`
    if (domain) {
      url += `&d=${encodeURIComponent(domain)}`
    }
    return fromPromise(Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      }))
  }

  public static paymentHistory(uid: string): Observable<any> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/user/payment/history/${uid}`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        if (!res) {
          throw MessageError.from(ErrorCode.NoCommerceProduct)
        }
        return res.data as any
      }))
  }

  public static paymentReceipt(uid: string): Observable<Blob> {
    return fromPromise(Http.instance().requestAPI(`subscription/web/user/payment/history/${uid}/receipt`, {
      method: 'GET',
      responseType: 'blob'
    } as AxiosRequestConfig)
      .then(res => Http.defaultHandleAPIResponse(res as any))
      .then(res => res as unknown as Blob))
  }

  public static commercePaymentHistoryList(pageNum: number, pageRow: number, domain?: string): Observable<any[]> {
    let url = `subscription/web/user/commerce/history?pageNum=${pageNum}&pageRow=${pageRow}`
    if (domain) {
      url += `&d=${encodeURIComponent(domain)}`
    }
    return fromPromise(Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      }))
  }

  public static commercePaymentHistory(uid: string): Observable<any> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/user/commerce/history/${uid}`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        if (!res) {
          throw MessageError.from(ErrorCode.NoCommerceProduct)
        }
        return res.data as any
      }))
  }

  public static commercePaymentReceipt(uid: string): Observable<Blob> {
    return fromPromise(Http.instance().requestAPI(`subscription/web/user/commerce/history/${uid}/receipt`, {
      method: 'GET',
      responseType: 'blob'
    } as AxiosRequestConfig)
      .then(res => Http.defaultHandleAPIResponse(res as any))
      .then(res => res as unknown as Blob))
  }

  public static cancelCommercePayment(uid: string): Observable<any> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/user/commerce/history/${uid}`, {
      method: 'DELETE'
    } as AxiosRequestConfig)
      .then(res => {
        if (!res) {
          throw MessageError.from(ErrorCode.NoCommerceProduct)
        }
        return res.data as any
      }))
  }

  public static contactAdminEmail(email: string, name: string, body: string): Observable<string> {
    return fromPromise(
      Http.instance().requestAPIJson('subscription/web/admin/contact',
        {
          method: 'POST',
          data: JSON.stringify({
            email,
            name,
            body
          })
        })
        .then(res => {
          return res.data as string
        })
    )
  }

  public static corporateBizContact(data: any): Observable<void> {
    return fromPromise(
      Http.instance().requestAPIJson('corporate/biz/contact-submit?token=YrBubvTxA22XbLI7dqTcQgjZdushr9CV',
        {
          method: 'POST',
          data: JSON.stringify(data)
        })
        .then(res => {
        })
    )
  }

  public static corporateBizDocument(data: any): Observable<void> {
    return fromPromise(
      Http.instance().requestAPIJson('corporate/biz/document-submit?token=YrBubvTxA22XbLI7dqTcQgjZdushr9CV',
        {
          method: 'POST',
          data: JSON.stringify(data)
        })
        .then(res => {
        })
    )
  }

  public static existUser(email: string): Observable<boolean> {
    return fromPromise(
      Http.instance().requestAPIJson('subscription/web/admin/exist',
        {
          method: 'POST',
          data: JSON.stringify({
            email
          })
        })
        .then(res => {
          return res.data as boolean
        })
    )
  }

  public static existDomain(domain: string): Observable<boolean> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/admin/exist/domain?domain=${encodeURIComponent(domain)}`,
        {
          method: 'GET'
        })
        .then(res => {
          return res.data as boolean
        })
    )
  }

  public static adminPreUser(
    email: string,
    subdomain: string,
    sendEmail: boolean,
    recaptcha: string,
    queries?: string): Observable<string> {
    let url = `subscription/web/admin/pre?e=${sendEmail ? 1 : 0}`
    if (queries) {
      url += `&lp-query=${encodeURIComponent(queries)}`
    }
    return fromPromise(
      Http.instance().requestAPIJson(url,
        {
          method: 'POST',
          data: JSON.stringify({
            email,
            // recaptcha,
            lp: {
              subdomain
            }
          })
        })
        .then(res => {
          return res.data as string
        })
    )
  }

  public static cmsPlanList(): Observable<any[]> {
    return fromPromise(Http.instance().requestAPIJson('subscription/web/admin/plan', {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      }))
  }

  public static cmsPlanOptionList(): Observable<any[]> {
    return fromPromise(Http.instance().requestAPIJson('subscription/web/admin/plan/option', {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      }))
  }

  public newsLikeCommentCount(uid: string): Observable<any> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/news/d/${encodeURIComponent(this.domain)}/${uid}/like/count`,
        {
          method: 'GET'
        })
        .then(res => {
          return res.data as any
        })
    )
  }

  public newsLiked(uid: string): Observable<boolean> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/news/d/${encodeURIComponent(this.domain)}/${uid}/like`,
        {
          method: 'GET'
        })
        .then(res => {
          if (res.data) {
            return parseInt(res.data as string, 10) > 0
          }
          return false
        })
    )
  }

  public newsLikePut(uid: string): Observable<string> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/news/d/${encodeURIComponent(this.domain)}/${uid}/like`,
        {
          method: 'PUT'
        })
        .then(res => {
          return res.data as string || '0'
        })
    )
  }

  public newsLikeDelete(uid: string): Observable<string> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/news/d/${encodeURIComponent(this.domain)}/${uid}/like`,
        {
          method: 'DELETE'
        })
        .then(res => {
          return res.data as string || '0'
        })
    )
  }

  public newsCommentList(
    uid: string,
    pageNum: number,
    pageRow: number,
    parentUID?: string): Observable<any[]> {
    let url = `subscription/web/news/d/${encodeURIComponent(this.domain)}/${uid}/comment?pageNum=${pageNum}&pageRow=${pageRow}`
    if (parentUID) {
      url += `&parent=${parentUID}`
    }
    return fromPromise(
      Http.instance().requestAPIJson(url,
        {
          method: 'GET'
        })
        .then(res => {
          return res.data as any[]
        })
    )
  }

  public newsCommentPost(
    uid: string,
    comment: any): Observable<any> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/news/d/${encodeURIComponent(this.domain)}/${uid}/comment`,
        {
          method: 'POST',
          data: JSON.stringify(comment)
        })
        .then(res => {
          return res.data as any
        })
    )
  }

  public newsCommentPut(
    uid: string,
    comment: any): Observable<any> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/news/d/${encodeURIComponent(this.domain)}/${uid}/comment`,
        {
          method: 'PUT',
          data: JSON.stringify(comment)
        })
        .then(res => {
          return res.data as any
        })
    )
  }

  public newsCommentDelete(uid: string, commentUID: string): Observable<void> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/news/d/${encodeURIComponent(this.domain)}/${uid}/comment/${commentUID}`,
        {
          method: 'DELETE'
        })
        .then(res => {
        })
    )
  }

  public newsCommentLikePut(uid: string, commentUID: string): Observable<string> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/news/d/${encodeURIComponent(this.domain)}/${uid}/comment/${commentUID}/like`,
        {
          method: 'PUT'
        })
        .then(res => {
          return res.data as string || '0'
        })
    )
  }

  public newsCommentLikeDelete(uid: string, commentUID: string): Observable<string> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/news/d/${encodeURIComponent(this.domain)}/${uid}/comment/${commentUID}/like`,
        {
          method: 'DELETE'
        })
        .then(res => {
          return res.data as string || '0'
        })
    )
  }

  public newsCommentProfile(uid: string, commentUID: string): Observable<any> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/news/d/${encodeURIComponent(this.domain)}/${uid}/comment/${commentUID}/profile`,
        {
          method: 'GET'
        })
        .then(res => {
          return res.data as any
        })
    )
  }

  public commerceService(productUID: string, app: boolean, userToken?: string): Observable<any> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/commerce/${productUID}?app=${app ? '1' : '0'}`
    if (userToken) {
      url += `&user=${encodeURIComponent(userToken)}`
    }
    return fromPromise(
      Http.instance().requestAPIJson(url, {
        method: 'GET'
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
    )
  }

  public commerceCheckout(
    type: number,
    productUID: string,
    app: boolean,
    data: any,
    userToken?: string): Observable<any> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/commerce/${productUID}?type=${type}&app=${app ? '1' : '0'}`
    if (userToken) {
      url += `&user=${encodeURIComponent(userToken)}`
    }
    return fromPromise(
      Http.instance().requestAPIJson(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
    )
  }

  public commerceCheckoutFail(type: number, productUID: string, orderUID: string, data: any): Observable<any> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/commerce/${productUID}/fail?type=${type}&order=${orderUID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
    )
  }

  public commerceDeletePaymentMethod(paymentMethodId: string): Observable<void> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/commerce/payment-method?type=1&pm=${encodeURIComponent(paymentMethodId)}`, {
        method: 'DELETE'
      } as AxiosRequestConfig)
        .then(_ => {
        })
    )
  }

  public communityBoard(): Promise<any> {
    return Http.instance().requestAPIJson(`player/community/board?domain=${encodeURIComponent(this.domain)}`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      })
      .catch(e => {
        app.$log?.error(e)
        return Promise.resolve({
          topicPolicy: 0
        })
      })
  }

  public topicAllList(
    uidList?: string[],
    pageNum?: number,
    pageRow?: number,
    sortType?: number,
    all?: boolean,
    search?: string,
    tagUID?: string,
    forumUID?: string[]): Promise<any[]> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/topic`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        all,
        uuidList: uidList,
        pageNum,
        pageRow,
        sortType,
        search,
        tagUID,
        forumUIDList: forumUID
      }
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      })
  }

  public forumAllList(
    all: boolean,
    uidList?: string[],
    pageNum?: number,
    pageRow?: number,
    sortType?: number,
    search?: string): Promise<any[]> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/forum`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        all,
        uuidList: uidList,
        pageNum,
        pageRow,
        sortType,
        search
      }
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      })
  }

  public static boardTopicList(
    boardUID: string,
    pageNum: number,
    pageRow: number): Promise<any[]> {
    return Http.instance().requestAPIJson(`player/community/${boardUID}/topic?pageNum=${pageNum}&pageRow=${pageRow}`, {
      method: 'GET'
    })
      .then(res => {
        return (res.data as any).value as any[]
      })
  }

  public companyUserGroupList(
    all: boolean,
    uidList?: string[],
    pageNum?: number,
    pageRow?: number,
    sortType?: number,
    types?: number[],
    parentOnly?: boolean,
    search?: string): Promise<any[]> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/company-user-group`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        all,
        uuidList: uidList && uidList.length ? uidList : undefined,
        pageNum,
        pageRow,
        sortType,
        t: types && types.length ? types : undefined,
        parentOnly: parentOnly !== undefined ? parentOnly : undefined,
        search
      }
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      })
  }

  public searchAll(
    text: string,
    companyUID: string,
    pageNum: number,
    pageRow: number,
    types?: number[]): Promise<any[]> {
    let url = `player/json/search?t=${encodeURIComponent(text)}&pageNum=${pageNum}&pageRow=${pageRow}&cp=${companyUID}`
    if (types) {
      url += types.map(t => `&type=${t}`).join('')
    }
    return Http.instance().requestAPIJson(url, {
      method: 'GET'
    })
      .then(res => {
        if (res.data) {
          return (res.data as any).value as any[]
        }
        return []
      })
  }

  public appstoreUrl(osType: number): Promise<string> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/appstore?os=${osType}`, {
      method: 'GET'
    })
      .then(res => {
        return res.data as string
      })
  }

  public tag(uid: string): Promise<any> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/tag/${uid}`, {
      method: 'GET'
    })
      .then(res => {
        return res.data as any
      })
  }

  public tagSearch(text: string, pageRow: number, types?: number[]): Promise<any[]> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/tag/search?t=${encodeURIComponent(text)}&pageNum=0&pageRow=${pageRow}`
    if (types) {
      url += types.map(t => `&type=${t}`).join('')
    }
    return Http.instance().requestAPIJson(url, {
      method: 'GET'
    })
      .then(res => {
        if (res.data) {
          return (res.data as any).value as any[]
        }
        return []
      })
  }

  public static introFeatureCategoryList(
    pageNum?: number,
    pageRow?: number): Promise<any[]> {
    let url = 'subscription/web/intro-feature/category/list?'
    if (pageNum !== undefined) {
      url += `&pageNum=${pageNum}`
    }
    if (pageRow !== undefined) {
      url += `&pageRow=${pageRow}`
    }
    return Http.instance().requestAPIJson(url, {
      method: 'GET'
    })
      .then(res => {
        return (res.data as any).value as any[]
      })
  }

  public introFeatureListCategoryCount(): Observable<string> {
    return fromPromise(Http.instance().requestAPIJson('subscription/web/intro-feature/category/count', {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as string
      }))
  }

  public static introFeatureList(
    pageNum?: number,
    pageRow?: number,
    categoryUID?: string,
    keyword?: string): Promise<any[]> {
    let url = 'subscription/web/intro-feature/list?'
    if (pageNum !== undefined) {
      url += `&pageNum=${pageNum}`
    }
    if (pageRow !== undefined) {
      url += `&pageRow=${pageRow}`
    }
    if (categoryUID) {
      url += `&category=${categoryUID}`
    }
    if (keyword) {
      url += `&keyword=${encodeURIComponent(keyword)}`
    }
    return Http.instance().requestAPIJson(url, {
      method: 'GET'
    })
      .then(res => {
        return (res.data as any).value as any[]
      })
  }

  public introFeatureListCount(categoryUID?: string): Observable<string> {
    let url = 'subscription/web/intro-feature/count?'
    if (categoryUID) {
      url += `&category=${categoryUID}`
    }
    return fromPromise(Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as string
      }))
  }

  public introFeature(uid: string): Observable<any> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/intro-feature/${uid}`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        if (!res) {
          throw MessageError.from(ErrorCode.IntroFeatureNoData)
        }
        return res.data as any
      }))
  }

  public fundingService(variationUID: string, userToken?: string): Observable<any> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/funding/${variationUID}?`
    if (userToken) {
      url += `&user=${encodeURIComponent(userToken)}`
    }
    return fromPromise(
      Http.instance().requestAPIJson(url, {
        method: 'GET'
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
    )
  }

  public fundingCheckout(
    type: number,
    variationUID: string,
    app: boolean,
    data: any,
    userToken?: string): Observable<any> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/funding/${variationUID}?type=${type}&app=${app ? '1' : '0'}`
    if (userToken) {
      url += `&user=${encodeURIComponent(userToken)}`
    }
    return fromPromise(
      Http.instance().requestAPIJson(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
    )
  }

  public fundingCheckoutFail(type: number, variationUID: string, orderUID: string, data: any): Observable<any> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/funding/${variationUID}/fail?type=${type}&order=${orderUID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
    )
  }

  public static fundingPaymentHistoryList(pageNum: number, pageRow: number, domain?: string): Observable<any[]> {
    let url = `subscription/web/user/funding/history?pageNum=${pageNum}&pageRow=${pageRow}`
    if (domain) {
      url += `&d=${encodeURIComponent(domain)}`
    }
    return fromPromise(Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      }))
  }

  public static fundingPaymentHistory(uid: string): Observable<any> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/user/funding/history/${uid}`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        if (!res) {
          throw MessageError.from(ErrorCode.NoCommerceProduct)
        }
        return res.data as any
      }))
  }

  public static fundingPaymentReceipt(uid: string): Observable<Blob> {
    return fromPromise(Http.instance().requestAPI(`subscription/web/user/funding/history/${uid}/receipt`, {
      method: 'GET',
      responseType: 'blob'
    } as AxiosRequestConfig)
      .then(res => Http.defaultHandleAPIResponse(res as any))
      .then(res => res as unknown as Blob))
  }

  public static cancelFundingPayment(uid: string): Observable<any> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/user/funding/history/${uid}`, {
      method: 'DELETE'
    } as AxiosRequestConfig)
      .then(res => {
        if (!res) {
          throw MessageError.from(ErrorCode.NoCommerceProduct)
        }
        return res.data as any
      })
      .catch(e => {
        if (e instanceof MessageError) {
          const code = (e as MessageError).code
          switch (code) {
            case ErrorCode.PaymentRefundFail:
              return Promise.reject(MessageError.from(ErrorCode.FundingRefundFail, e))
            case ErrorCode.PaymentRefundDeliveredFail:
              return Promise.reject(MessageError.from(ErrorCode.FundingRefundDeliveredFail, e))
            case ErrorCode.PaymentRefundFixedError:
              return Promise.reject(MessageError.from(ErrorCode.FundingRefundFixedError, e))
          }
        }
        return Promise.reject(e)
      }))
  }

  public static userInterviewList(
    fixed: boolean,
    type: number,
    pageNum?: number,
    pageRow?: number): Promise<any[]> {
    let url = `subscription/web/user-interview/list?fixed=${fixed ? 1 : 0}&t=${type}`
    if (pageNum !== undefined) {
      url += `&pageNum=${pageNum}`
    }
    if (pageRow !== undefined) {
      url += `&pageRow=${pageRow}`
    }
    return Http.instance().requestAPIJson(url, {
      method: 'GET'
    })
      .then(res => {
        return (res.data as any).value as any[]
      })
  }

  public userInterviewCount(): Observable<string> {
    return fromPromise(Http.instance().requestAPIJson('subscription/web/user-interview/count', {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as string
      }))
  }

  public userInterview(uid: string): Observable<any> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/user-interview/${uid}`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        if (!res) {
          throw MessageError.from(ErrorCode.UserInterviewNoData)
        }
        return res.data as any
      }))
  }

  public ticketService(dateUserUID: string, userToken?: string): Observable<any> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/ticket/${dateUserUID}?`
    if (userToken) {
      url += `&user=${encodeURIComponent(userToken)}`
    }
    return fromPromise(
      Http.instance().requestAPIJson(url, {
        method: 'GET'
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
    )
  }

  public ticketCheckout(
    type: number,
    dateUserUID: string,
    app: boolean,
    data: any,
    userToken?: string): Observable<any> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/ticket/${dateUserUID}?type=${type}&app=${app ? '1' : '0'}`
    if (userToken) {
      url += `&user=${encodeURIComponent(userToken)}`
    }
    return fromPromise(
      Http.instance().requestAPIJson(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
        .catch(e => {
          if (e instanceof MessageError) {
            const code = (e as MessageError).code
            switch (code) {
              case ErrorCode.ProductQuantityFail:
                return Promise.reject(MessageError.from(ErrorCode.TicketQuantityFail, e))
            }
          }
          return Promise.reject(e)
        })
    )
  }

  public ticketCheckoutFail(type: number, dateUserUID: string, orderUID: string, data: any): Observable<any> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/ticket/${dateUserUID}/fail?type=${type}&order=${orderUID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
    )
  }

  public ticketCheckoutCancel(dateUserUID: string, userToken?: string): Observable<void> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/ticket/${dateUserUID}/cancel`
    if (userToken) {
      url += `?user=${encodeURIComponent(userToken)}`
    }
    return fromPromise(
      Http.instance().requestAPIJson(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      } as AxiosRequestConfig)
        .then(res => {
        })
    )
  }

  public static ticketPaymentHistoryList(pageNum: number, pageRow: number, domain?: string): Observable<any[]> {
    let url = `subscription/web/user/ticket/history?pageNum=${pageNum}&pageRow=${pageRow}`
    if (domain) {
      url += `&d=${encodeURIComponent(domain)}`
    }
    return fromPromise(Http.instance().requestAPIJson(url, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any[]
      }))
  }

  public static ticketPaymentHistory(uid: string): Observable<any> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/user/ticket/history/${uid}`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        if (!res) {
          throw MessageError.from(ErrorCode.NoCommerceProduct)
        }
        return res.data as any
      }))
  }

  public static ticketPaymentReceipt(uid: string): Observable<Blob> {
    return fromPromise(Http.instance().requestAPI(`subscription/web/user/ticket/history/${uid}/receipt`, {
      method: 'GET',
      responseType: 'blob'
    } as AxiosRequestConfig)
      .then(res => Http.defaultHandleAPIResponse(res as any))
      .then(res => res as unknown as Blob))
  }

  public static cancelTicketPayment(uid: string): Observable<any> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/user/ticket/history/${uid}?tz=${new Date().getTimezoneOffset() * -60}`, {
      method: 'DELETE'
    } as AxiosRequestConfig)
      .then(res => {
        if (!res) {
          throw MessageError.from(ErrorCode.NoCommerceProduct)
        }
        return res.data as any
      })
      .catch(e => {
        if (e instanceof MessageError) {
          const code = (e as MessageError).code
          switch (code) {
            case ErrorCode.PaymentRefundFail:
              return Promise.reject(MessageError.from(ErrorCode.FundingRefundFail, e))
            case ErrorCode.PaymentRefundDeliveredFail:
              return Promise.reject(MessageError.from(ErrorCode.FundingRefundDeliveredFail, e))
            case ErrorCode.PaymentRefundFixedError:
              return Promise.reject(MessageError.from(ErrorCode.FundingRefundFixedError, e))
          }
        }
        return Promise.reject(e)
      }))
  }

  public appFeatures(): Observable<number[]> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/app-features`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      }))
  }

  public loginLaunchScreen(): Observable<any[]> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/login-launch-screen`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      }))
  }

  public maliFilterInfo(): Observable<any> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/mail-filter-info`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as any
      }))
  }

  public maliFilterDomainList(): Observable<string[]> {
    return fromPromise(Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/domain-list`, {
      method: 'GET'
    } as AxiosRequestConfig)
      .then(res => {
        return res.data as string[]
      }))
  }

  public userNotificationList(pageNum: number, pageRow: number, types: number[]): Promise<any[]> {
    let url = `subscription/web/user/d/${encodeURIComponent(this.domain)}/notification?pageNum=${pageNum}&pageRow=${pageRow}`
    if (types) {
      url += types.map(t => `&t=${t}`).join('')
    }
    return Http.instance().requestAPIJson(url, {
      method: 'GET'
    })
      .then(res => {
        if (res.data) {
          return res.data as any[]
        }
        return []
      })
  }

  public quickAPIURL(type: number, dataJson?: string): Promise<string> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/quick/api?t=${type}`
    if (dataJson) {
      url += `&d=${encodeURIComponent(dataJson)}`
    }
    return Http.instance().requestAPIJson(url, {
      method: 'GET'
    })
      .then(res => {
        return res.data as string
      })
  }

  public quickWatchList(): Promise<any[]> {
    return Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/quick/watch-list`, {
      method: 'GET'
    })
      .then(res => {
        return ((res.data as any)?.value ?? []) as any[]
      })
  }

  public workflowService(chargePointType: number,
    builderUID: string,
    app: boolean,
    userToken?: string): Observable<any> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/workflow/${builderUID}?cpt=${chargePointType}&app=${app ? '1' : '0'}`
    if (userToken) {
      url += `&user=${encodeURIComponent(userToken)}`
    }
    return fromPromise(
      Http.instance().requestAPIJson(url, {
        method: 'GET'
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
    )
  }

  public workflowCheckout(
    chargePointType: number,
    type: number,
    builderUID: string,
    app: boolean,
    data: any,
    userToken?: string): Observable<any> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/workflow/${builderUID}?cpt=${chargePointType}&type=${type}&app=${app ? '1' : '0'}`
    if (userToken) {
      url += `&user=${encodeURIComponent(userToken)}`
    }
    return fromPromise(
      Http.instance().requestAPIJson(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
        .catch(e => {
          if (e instanceof MessageError) {
            const code = (e as MessageError).code
            switch (code) {
              case ErrorCode.ProductQuantityFail:
                return Promise.reject(MessageError.from(ErrorCode.TicketQuantityFail, e))
            }
          }
          return Promise.reject(e)
        })
    )
  }

  public workflowCheckoutFail(type: number, builderUID: string, orderUID: string, data: any): Observable<any> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/workflow/${builderUID}/fail?type=${type}&order=${orderUID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
    )
  }

  public playlistService(playlistUID: string,
    app: boolean,
    userToken?: string): Observable<any> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/playlist/${playlistUID}/payment?app=${app ? '1' : '0'}`
    if (userToken) {
      url += `&user=${encodeURIComponent(userToken)}`
    }
    return fromPromise(
      Http.instance().requestAPIJson(url, {
        method: 'GET'
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
    )
  }

  public playlistCheckout(
    type: number,
    playlistUID: string,
    app: boolean,
    data: any,
    userToken?: string): Observable<any> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/playlist/${playlistUID}/payment?type=${type}&app=${app ? '1' : '0'}`
    if (userToken) {
      url += `&user=${encodeURIComponent(userToken)}`
    }
    return fromPromise(
      Http.instance().requestAPIJson(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
        .catch(e => {
          if (e instanceof MessageError) {
            const code = (e as MessageError).code
            switch (code) {
              case ErrorCode.ProductQuantityFail:
                return Promise.reject(MessageError.from(ErrorCode.TicketQuantityFail, e))
            }
          }
          return Promise.reject(e)
        })
    )
  }

  public playlistCheckoutFail(type: number, playlistUID: string, orderUID: string, data: any): Observable<any> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/playlist/${playlistUID}/payment/fail?type=${type}&order=${orderUID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
    )
  }

  public pointService(
    app: boolean,
    userToken?: string): Observable<any> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/point/payment?app=${app ? '1' : '0'}`
    if (userToken) {
      url += `&user=${encodeURIComponent(userToken)}`
    }
    return fromPromise(
      Http.instance().requestAPIJson(url, {
        method: 'GET'
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
    )
  }

  public pointCheckout(
    type: number,
    app: boolean,
    data: any,
    userToken?: string): Observable<any> {
    let url = `subscription/web/d/${encodeURIComponent(this.domain)}/point/payment?type=${type}&app=${app ? '1' : '0'}`
    if (userToken) {
      url += `&user=${encodeURIComponent(userToken)}`
    }
    return fromPromise(
      Http.instance().requestAPIJson(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
        .catch(e => {
          if (e instanceof MessageError) {
            const code = (e as MessageError).code
            switch (code) {
              case ErrorCode.ProductQuantityFail:
                return Promise.reject(MessageError.from(ErrorCode.TicketQuantityFail, e))
            }
          }
          return Promise.reject(e)
        })
    )
  }

  public pointCheckoutFail(type: number, orderUID: string, data: any): Observable<any> {
    return fromPromise(
      Http.instance().requestAPIJson(`subscription/web/d/${encodeURIComponent(this.domain)}/point/payment/fail?type=${type}&order=${orderUID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data
      } as AxiosRequestConfig)
        .then(res => {
          return res.data as any
        })
    )
  }
}
