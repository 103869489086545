import { Moment } from 'moment'

export interface ServiceData {
  name: string;
}

enum SiteDesignSectionType {
  None = 0,
  Top = 1,
  Playlist = 2,
  About = 3,
  SNS = 4,
  Button = 5,
  ContentList = 6,
  Text = 7,
  Plan = 8,
  Alert = 9,
  Basic = 10,
  Review = 11,
  Image = 12,
  Slide = 13,
  News = 14,
  Program = 15,
  Footer = 16,
  Community = 17,
  FAQ = 18,
  Top3 = 19,
  Tile = 21,
  Panel4Image = 22,
  Panel = 23,
  ECList = 24,
  RecentUpdate = 25,
  TopicList = 26,
  ForumList = 27,
  NotificationList = 28,
  FundingList = 29,
  TicketList = 30,
  QuickFinanceIssueList = 31,
  QuickFinanceIssueSearch = 32,
  QuickFinanceWatchList = 33,
  QuickFinanceNewsList = 34,
  WorkflowList = 41,
  MobileAppGuide = 51,
  QuickMAArticle = 60,
  QuickMAFundAlarmService = 61,
  QuickMAMarketOverview = 62,
  QuickMAMonthlyDividendRanking = 64,
  QuickMADomesticStockRanking = 65,
  QuickMAWorldStockRanking = 66,
  QuickMADomesticBondRanking = 67,
  QuickNavFundSearch = 68,
  QuickNavSectionList = 69,
  QuickNavFilterSearch = 70,
  QuickNavDividerLine = 71,
  Header = 99
}

/* eslint-disable @typescript-eslint/no-namespace */
namespace SiteDesignSectionType {
  /* eslint-disable no-inner-declarations */
  export function isNavSection(type: SiteDesignSectionType): boolean {
    switch (type) {
      case SiteDesignSectionType.QuickNavDividerLine:
      case SiteDesignSectionType.QuickNavSectionList:
      case SiteDesignSectionType.QuickNavFilterSearch:
      case SiteDesignSectionType.QuickNavFundSearch:
        return true
    }
    return false
  }

  export function isRankingSection(type: SiteDesignSectionType): boolean {
    switch (type) {
      case SiteDesignSectionType.QuickMAWorldStockRanking:
      case SiteDesignSectionType.QuickMAMonthlyDividendRanking:
      case SiteDesignSectionType.QuickMADomesticBondRanking:
      case SiteDesignSectionType.QuickMADomesticStockRanking:
        return true
    }
    return false
  }
}

export {
  SiteDesignSectionType
}

enum SiteDesignSectionDataType {
  HeaderMenu = 0,
  Title = 1,
  Text = 2,
  Button = 3,
  SubText = 4,
  HTMLTitle = 5,
  HTMLBody = 6,
  SectionTitle = 7,
  Image = 11,
  BackgroundImage = 12,
  Logo = 13,
  MobileBackgroundImage = 14,
  MobileImage = 15,
  SlideImage = 16,
  ImageList = 17,
  AssetType = 18,
  Video = 19,
  UIDList = 21,
  UIDListPageRow = 22,
  UIDSortType = 23,
  UIDFixedList = 24,
  UIDAuto = 25,
  UIDListDirection = 26,
  TypeUIDList = 27,
  ForumUIDList = 28,
  UIDTypes = 29,
  UIDParentOnly = 30,
  ButtonType = 31,
  ButtonLink = 32,
  ButtonUID = 33,
  ButtonContentUID = 34,
  ButtonLayout = 35,
  ButtonEnable = 36,
  BackgroundColor = 41,
  TextColor = 42,
  ButtonColor = 43,
  ButtonTextColor = 44,
  HeaderColor = 45,
  HeaderButtonColor = 46,
  TileTextColor = 47,
  BorderColor = 48,
  PlanList = 51,
  PlanListType = 52,
  ShowImage = 61,
  NotificationTypes = 71,
  PlaylistUIDList = 81,
  YoutubeLink = 101,
  TwitterLink = 102,
  FacebookLink = 103,
  InstagramLink = 104,
  LineLink = 105,
  MixChannelLink = 106,
  SNSCustomLink = 107,
  SNSCustomText = 108,
  SNSCustomImage = 109,
  TikTokLink = 110,
  LayoutType = 151,
  TitleAlign = 201,
  TextAlign = 202,
  BackgroundImageAlpha = 301,
  MobileImageEnable = 302,
  MobileBackgroundImageEnable = 303,
  NewsData = 401,
  ProgramData = 451,
  FaqData = 461,
  TileData = 471,
  Pattern = 501,
  HeaderCustom = 601,
  HeaderHTML = 602,
  HeaderCSS = 603,
  PageLink = 701,
  SearchType = 702,
  Text1 = 801,
  Text2 = 802,
  Text3 = 803,
  Text4 = 804,
  Text5 = 805,
  Text6 = 806,
  Text7 = 807,
  Text8 = 808,
  Text9 = 809,
  MobileMode = 10000,
  MobileTitle = 10001,
  MobileText = 10002,
  MobileButton = 10003,
  MobileSubText = 10004,
  MobileHTMLTitle = 10005,
  MobileButtonType = 10031,
  MobileButtonLink = 10032,
  MobileButtonUID = 10033,
  MobileButtonContentUID = 10034,
  MobileButtonLayout = 10035,
  MobileAssetType = 10300,
  MobileBackgroundImageAlpha = 10301,
  MobileSlideImage = 10302,
  MobileVideo = 10303,
  CustomEditorMode = 100000,
  CustomCodeHead = 100001,
  CustomCodeBody = 100002,
  CustomSectionClass = 100003
}

/* eslint-disable @typescript-eslint/no-namespace */
namespace SiteDesignSectionDataType {
  /* eslint-disable no-inner-declarations */
  export function isTitle(type: SiteDesignSectionDataType): boolean {
    switch (type) {
      case SiteDesignSectionDataType.Title:
      case SiteDesignSectionDataType.Button:
      case SiteDesignSectionDataType.MobileTitle:
      case SiteDesignSectionDataType.MobileButton:
        return true
      default:
        return false
    }
  }

  /* eslint-disable no-inner-declarations */
  export function isText(type: SiteDesignSectionDataType): boolean {
    switch (type) {
      case SiteDesignSectionDataType.Text:
      case SiteDesignSectionDataType.SubText:
      case SiteDesignSectionDataType.MobileText:
      case SiteDesignSectionDataType.MobileSubText:
        return true
      default:
        return false
    }
  }

  /* eslint-disable no-inner-declarations */
  export function isImage(type: SiteDesignSectionDataType): boolean {
    switch (type) {
      case SiteDesignSectionDataType.Image:
      case SiteDesignSectionDataType.BackgroundImage:
      case SiteDesignSectionDataType.Logo:
      case SiteDesignSectionDataType.MobileImage:
      case SiteDesignSectionDataType.MobileBackgroundImage:
        return true
      default:
        return false
    }
  }

  /* eslint-disable no-inner-declarations */
  export function isSNS(type: SiteDesignSectionDataType): boolean {
    switch (type) {
      case SiteDesignSectionDataType.YoutubeLink:
      case SiteDesignSectionDataType.TwitterLink:
      case SiteDesignSectionDataType.FacebookLink:
      case SiteDesignSectionDataType.InstagramLink:
      case SiteDesignSectionDataType.LineLink:
      case SiteDesignSectionDataType.MixChannelLink:
      case SiteDesignSectionDataType.SNSCustomLink:
        return true
      default:
        return false
    }
  }

  /* eslint-disable no-inner-declarations */
  export function isTextAlign(type: SiteDesignSectionDataType): boolean {
    switch (type) {
      case SiteDesignSectionDataType.TitleAlign:
      case SiteDesignSectionDataType.TextAlign:
        return true
      default:
        return false
    }
  }

  /* eslint-disable no-inner-declarations */
  export function isColor(type: SiteDesignSectionDataType): boolean {
    switch (type) {
      case SiteDesignSectionDataType.BackgroundColor:
      case SiteDesignSectionDataType.TextColor:
      case SiteDesignSectionDataType.ButtonColor:
      case SiteDesignSectionDataType.ButtonTextColor:
        return true
      default:
        return false
    }
  }

  /* eslint-disable no-inner-declarations */
  export function isMobile(type: SiteDesignSectionDataType): boolean {
    switch (type) {
      case SiteDesignSectionDataType.MobileTitle:
      case SiteDesignSectionDataType.MobileText:
      case SiteDesignSectionDataType.MobileButton:
      case SiteDesignSectionDataType.MobileSubText:
      case SiteDesignSectionDataType.MobileButtonType:
      case SiteDesignSectionDataType.MobileButtonLink:
      case SiteDesignSectionDataType.MobileButtonUID:
      case SiteDesignSectionDataType.MobileBackgroundImageAlpha:
      case SiteDesignSectionDataType.MobileBackgroundImage:
        return true
      default:
        return false
    }
  }
}

export {
  SiteDesignSectionDataType
}

export enum SiteDesignTheme {
  Theme1 = 1,
  Theme2 = 2,
  Theme3 = 3,
  Theme4 = 4,
  Theme5 = 5
}

export enum SiteDesignButtonType {
  None = 0,
  External = 1,
  PageTop = 2,
  PageContentList = 3,
  PagePlaylist = 4,
  PagePlan = 5,
  PageAlert = 6,
  PageAbout = 7,
  ContentList = 8,
  PageReview = 9,
  Contact = 10,
  LoadMore = 11,
  PageText = 12,
  PageImage = 13,
  PageSlide = 14,
  PageNews = 15,
  Content = 16,
  PageTile = 17,
  PagePanel = 18,
  PageECList = 19,
  PageRecentUpdate = 20,
  PageTopicList = 21,
  Forum = 22,
  SubPage = 23,
  PageForumList = 24,
  PageNotificationList = 25,
  Playlist = 26,
  Workflow = 27,
  PageFundingList = 28,
  PageTicketList = 29,
  PageWorkflowList = 30,
  PageQuickFinanceIssueList = 31,
  PageQuickFinanceIssueSearch = 32,
  PageQuickFinanceWatchList = 33,
  PageQuickFinanceNewsList = 41,
  PageMobileAppGuide = 51,
}

export enum SiteDesignTextAlignment {
  Left = 1,
  Center = 2,
  Right = 3
}

export enum SiteDesignTypeUIDDataType {
  Playlist = 1,
  Forum = 2,
  Content = 3,
  Topic = 4,
  Alert = 5
}

export interface SiteDesignTypeUIDData {
  uid?: string;
  type: SiteDesignTypeUIDDataType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export enum SiteDesignListAutoType {
  Auto = 0,
  Detail = 1
}

export enum SiteDesignUIDListDirectionType {
  Horizontal = 1,
  Vertical = 2
}

enum SiteDesignPageType {
  Home = 1,
  Login = 2,
  Logout = 3,
  MyPage = 4,
  Custom = 5,
  ContentList = 6,
  ForumList = 7,
  Chatroom = 8,
  UserGroup = 9,
  Contact = 10,
  Webview = 11,
  External = 12,
  Search = 13,
  UserSearch = 14,
  Enquete = 15,
  Space = 16,
  AdminMyPage = 17,
  Workflow = 18,
  Forum = 19,
  ECList = 20,
  FundingList = 21,
  TicketList = 22,
  MobileAppGuide = 23,
  WorkflowData = 24,
  WorkflowList = 25,
  CtaPage = 101,
  CtaPageQuick = 102
}

namespace SiteDesignPageType {
  export function usePageTemplate(type: SiteDesignPageType): boolean {
    switch (type) {
      case SiteDesignPageType.Webview:
        return true
    }
    return false
  }

  export function reservedLinks(): string[] {
    return [
      'member', 'programs', 'forum', 'chatroom', 'news', 'group', 'user', 'login', 'logout', 'order', 'player', 'app', 'lp', '404'
    ]
  }
}

export {
  SiteDesignPageType
}

export interface RGB {
  r: number;
  g: number;
  b: number;
}

export interface RGBA extends RGB {
  a: number;
}

export enum SiteDesignPlanType {
  Subscription = 1,
  TVOD = 2
}

export interface SiteDesignPlan {
  uid: string;
  type: SiteDesignPlanType;
  text: string;
  button: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  plan: any | null;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  price: any | null;
  collapsed?: boolean;
  needCollapse?: boolean;
}

export class SiteDesignPage {
  uid!: string
  type!: SiteDesignPageType
  link!: string
  name!: string
  sections!: SiteDesignSection[]
  data!: SiteDesignSectionData[]

  public static from(json: SiteDesignPage): SiteDesignPage {
    const data = new SiteDesignPage()
    data.uid = json.uid
    data.type = json.type
    data.link = json.link
    data.name = json.name
    data.sections = json.sections.map(sec => SiteDesignSection.from(sec))
    data.data = json.data
    return data
  }

  isAvailable(boardData: any | null, isUser: boolean, isOwner: boolean, isSubOwner: boolean): boolean {
    switch (this.type) {
      case SiteDesignPageType.ForumList:
      case SiteDesignPageType.Forum:
        return boardData && boardData.topicPolicy !== 0
      case SiteDesignPageType.Chatroom:
        return boardData && boardData.webTalkType === 100
      case SiteDesignPageType.Space:
        return false
      case SiteDesignPageType.Login:
        return !isUser
      case SiteDesignPageType.Logout:
        return isUser
      case SiteDesignPageType.Contact:
        return !isOwner
      case SiteDesignPageType.MyPage:
        return isUser
      case SiteDesignPageType.AdminMyPage:
        return isUser && isOwner
    }
    return true
  }

  pageLinkHref(): string {
    switch (this.type) {
      case SiteDesignPageType.Home:
        return '/'
      case SiteDesignPageType.Custom:
      case SiteDesignPageType.ContentList:
      case SiteDesignPageType.ForumList:
      case SiteDesignPageType.Forum:
      case SiteDesignPageType.Chatroom:
      case SiteDesignPageType.UserGroup:
      case SiteDesignPageType.Webview:
      case SiteDesignPageType.Search:
      case SiteDesignPageType.UserSearch:
      case SiteDesignPageType.Workflow:
      case SiteDesignPageType.CtaPage:
      case SiteDesignPageType.CtaPageQuick:
      case SiteDesignPageType.WorkflowData:
      case SiteDesignPageType.WorkflowList:
      case SiteDesignPageType.ECList:
      case SiteDesignPageType.FundingList:
      case SiteDesignPageType.TicketList:
      case SiteDesignPageType.MobileAppGuide:
        return `/${this.link}`
      case SiteDesignPageType.External:
      case SiteDesignPageType.Login:
      case SiteDesignPageType.Logout:
      case SiteDesignPageType.Contact:
      case SiteDesignPageType.MyPage:
      case SiteDesignPageType.AdminMyPage:
        return ''
    }
    return ''
  }
}

export class SiteDesignData {
  theme!: SiteDesignTheme
  name!: string
  nameHTML!: string
  pages!: SiteDesignPage[]
  sections!: SiteDesignSection[]
  companyUID!: string

  public static from(lp: string): SiteDesignData {
    const json = JSON.parse(lp)
    const data = new SiteDesignData()
    data.theme = json.theme
    data.name = json.name
    data.nameHTML = json.nameHTML
    data.pages = (json.pages ?? []).map(sec => SiteDesignPage.from(sec))
    data.sections = (json.sections ?? []).map(sec => SiteDesignSection.from(sec))
    return data
  }
}

export interface SiteDesignSectionData {
  type: SiteDesignSectionDataType;
  data: string | string[] | number | number[] | boolean | SiteDesignPlan[] | SiteDesignHeaderMenu[];
}

export class SiteDesignSection {
  uid?: string
  type!: SiteDesignSectionType
  data: SiteDesignSectionData[] = []

  public static from(json: SiteDesignSection): SiteDesignSection {
    const data = new SiteDesignSection()
    data.uid = json.uid
    data.type = json.type
    data.data = json.data
    return data
  }
}

export interface SiteDesignHeaderMenu {
  type: number;
  title: string;
}

export enum SiteDesignSearchType {
  Playlist = 1,
  Topic = 2,
  Tag = 3,
  Content = 4,
  User = 5,
  UserGroup = 6,
  Forum = 7
}

export const numberWithCommas = (x: number | string): string => {
  if (x === undefined || x === null) {
    return '0'
  }
  const parts = x.toString().split('.')
  if (parts.length > 1 && /^0+$/.test(parts[1])) {
    parts[1] = ''
  }
  return parts[0].replace(/\B(?=(\d{3})+(?=$))/g, ',') + (parts[1] ? '.' + parts[1] : '')
}

export function hexToRGBA(hex: string): RGBA {
  let colors = hex.toLowerCase()
    .match(/^([0-9a-fA-F]{2})([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/)
  if (!colors) {
    colors = hex.toLowerCase()
      .match(/^([0-9a-fA-F]{2})([0-9a-f]{2})([0-9a-f]{2})$/)
    if (colors) {
      return {
        r: parseInt(colors[1], 16),
        g: parseInt(colors[2], 16),
        b: parseInt(colors[3], 16),
        a: 1.0
      }
    }
    return {
      r: 0,
      g: 0,
      b: 0,
      a: 0
    }
  }
  return {
    r: parseInt(colors[1], 16),
    g: parseInt(colors[2], 16),
    b: parseInt(colors[3], 16),
    a: parseInt(colors[4], 16) / 255.0
  }
}

export function rgbaToCss(colorValue: RGBA): string {
  return `rgba(${colorValue.r}, ${colorValue.g}, ${colorValue.b}, ${colorValue.a})`
}

export function regExpEscape(str: string): string {
  return str.replace(/[-[\]{}()*+!<=:?./\\^$|#\s,]/g, '\\$&')
}

export enum UploadFileType {
  Image = 1,
  Movie = 2
}

export enum TopicSortType {
  RegisterDateDESC = 2,
  ModifyDateDESC = 4,
  Popular = 5
}

export function defaultContentThumbnailImage(companyUID?: string | null): string {
  if (companyUID && companyUID === '4176') {
    return 'https://liteview.jp/static/assets/flatblank.png'
  }
  return 'https://liteview.jp/static/assets/contentblank.png'
}

export function defaultTemplateThumbnailImage(companyUID?: string | null): string {
  if (companyUID && companyUID === '4176') {
    return 'https://liteview.jp/static/assets/flatblank.png'
  }
  return 'https://liteview.jp/static/assets/templateblank.png'
}

export function defaultQuickThumbnail(companyUID?: string | null): string {
  return 'https://liteview.jp/static/assets/quick_finance_tmp.png'
}

export function defaultQuickBigThumbnail(companyUID?: string | null): string {
  return 'https://liteview.jp/static/assets/quick_finance_tmp_big.png'
}

export function quickDiffColor(companyUID?: string) {
  return {
    diffUpColor: '#D22331',
    diffDownColor: '#52A69F',
    diffNormalColor: '#777777'
  }
}

export function getPagePath(data: SiteDesignData, type: SiteDesignPageType, defaultPath: string): string {
  let path = ''
  let lastPageUID: string | undefined
  // if (app.$store) {
  //   const pageUIDMap = (app.$store.state as State).meta.lastPageUID
  //   if (pageUIDMap) {
  //     lastPageUID = pageUIDMap[type.toString(10)]
  //   }
  // }
  let find = false
  for (const page of data.pages) {
    if (page.type === type && (!!lastPageUID && lastPageUID === page.uid)) {
      path = page.link
      find = true
      break
    }
  }
  if (!find) {
    for (const page of data.pages) {
      if (page.type === type && (lastPageUID !== undefined && page.link === defaultPath)) {
        path = page.link
        find = true
        break
      }
    }
  }
  if (!find) {
    for (const page of data.pages) {
      if (page.type === type && page.link === defaultPath) {
        path = page.link
        find = true
        break
      }
    }
  }
  if (!find) {
    for (const page of data.pages) {
      if (page.type === type) {
        path = page.link
        find = true
        break
      }
    }
  }
  if (!find && defaultPath) {
    path = defaultPath
  }
  return path
}

function zenkaku2Hankaku(str: string): string {
  return str.replace(/[A-Za-z0-9]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) + 0xFEE0)
  })
}

enum CompanyCouponType {
  OneMonthFree = 1,
  OneMonth10 = 5,
  OneMonth20 = 6,
  OneMonth30 = 2,
  OneMonth40 = 7,
  OneMonth50 = 3,
  OneMonth60 = 8,
  OneMonth70 = 9,
  OneMonth80 = 4,
  OneMonth90 = 10,
  TwoMonthFree = 11,
  TwoMonth10 = 15,
  TwoMonth20 = 16,
  TwoMonth30 = 12,
  TwoMonth40 = 17,
  TwoMonth50 = 13,
  TwoMonth60 = 18,
  TwoMonth70 = 19,
  TwoMonth80 = 14,
  TwoMonth90 = 20,
  ThreeMonthFree = 51,
  ThreeMonth10 = 55,
  ThreeMonth20 = 56,
  ThreeMonth30 = 52,
  ThreeMonth40 = 57,
  ThreeMonth50 = 53,
  ThreeMonth60 = 58,
  ThreeMonth70 = 59,
  ThreeMonth80 = 54,
  ThreeMonth90 = 60,
  SixMonthFree = 61,
  SixMonth10 = 65,
  SixMonth20 = 66,
  SixMonth30 = 62,
  SixMonth40 = 67,
  SixMonth50 = 63,
  SixMonth60 = 68,
  SixMonth70 = 69,
  SixMonth80 = 64,
  SixMonth90 = 70,
  Normal10 = 105,
  Normal20 = 106,
  Normal30 = 102,
  Normal40 = 107,
  Normal50 = 103,
  Normal60 = 108,
  Normal70 = 109,
  Normal80 = 104,
  Normal90 = 110,
  OneYearFree = 1001,
  OneYear10 = 1005,
  OneYear20 = 1006,
  OneYear30 = 1002,
  OneYear40 = 1007,
  OneYear50 = 1003,
  OneYear60 = 1008,
  OneYear70 = 1009,
  OneYear80 = 1004,
  OneYear90 = 1010,
  TwoYearFree = 1011,
  TwoYear10 = 1015,
  TwoYear20 = 1016,
  TwoYear30 = 1012,
  TwoYear40 = 1017,
  TwoYear50 = 1013,
  TwoYear60 = 1018,
  TwoYear70 = 1019,
  TwoYear80 = 1014,
  TwoYear90 = 1020
}

namespace CompanyCouponType {
  export function all(): CompanyCouponType[] {
    return [
      CompanyCouponType.OneMonthFree,
      CompanyCouponType.OneMonth10,
      CompanyCouponType.OneMonth20,
      CompanyCouponType.OneMonth30,
      CompanyCouponType.OneMonth40,
      CompanyCouponType.OneMonth50,
      CompanyCouponType.OneMonth60,
      CompanyCouponType.OneMonth70,
      CompanyCouponType.OneMonth80,
      CompanyCouponType.OneMonth90,
      CompanyCouponType.TwoMonthFree,
      CompanyCouponType.TwoMonth10,
      CompanyCouponType.TwoMonth20,
      CompanyCouponType.TwoMonth30,
      CompanyCouponType.TwoMonth40,
      CompanyCouponType.TwoMonth50,
      CompanyCouponType.TwoMonth60,
      CompanyCouponType.TwoMonth70,
      CompanyCouponType.TwoMonth80,
      CompanyCouponType.TwoMonth90,
      CompanyCouponType.ThreeMonthFree,
      CompanyCouponType.ThreeMonth10,
      CompanyCouponType.ThreeMonth20,
      CompanyCouponType.ThreeMonth30,
      CompanyCouponType.ThreeMonth40,
      CompanyCouponType.ThreeMonth50,
      CompanyCouponType.ThreeMonth60,
      CompanyCouponType.ThreeMonth70,
      CompanyCouponType.ThreeMonth80,
      CompanyCouponType.ThreeMonth90,
      CompanyCouponType.SixMonthFree,
      CompanyCouponType.SixMonth10,
      CompanyCouponType.SixMonth20,
      CompanyCouponType.SixMonth30,
      CompanyCouponType.SixMonth40,
      CompanyCouponType.SixMonth50,
      CompanyCouponType.SixMonth60,
      CompanyCouponType.SixMonth70,
      CompanyCouponType.SixMonth80,
      CompanyCouponType.SixMonth90,
      CompanyCouponType.Normal10,
      CompanyCouponType.Normal20,
      CompanyCouponType.Normal30,
      CompanyCouponType.Normal40,
      CompanyCouponType.Normal50,
      CompanyCouponType.Normal60,
      CompanyCouponType.Normal70,
      CompanyCouponType.Normal80,
      CompanyCouponType.Normal90,
      CompanyCouponType.OneYearFree,
      CompanyCouponType.OneYear10,
      CompanyCouponType.OneYear20,
      CompanyCouponType.OneYear30,
      CompanyCouponType.OneYear40,
      CompanyCouponType.OneYear50,
      CompanyCouponType.OneYear60,
      CompanyCouponType.OneYear70,
      CompanyCouponType.OneYear80,
      CompanyCouponType.OneYear90,
      CompanyCouponType.TwoYearFree,
      CompanyCouponType.TwoYear10,
      CompanyCouponType.TwoYear20,
      CompanyCouponType.TwoYear30,
      CompanyCouponType.TwoYear40,
      CompanyCouponType.TwoYear50,
      CompanyCouponType.TwoYear60,
      CompanyCouponType.TwoYear70,
      CompanyCouponType.TwoYear80,
      CompanyCouponType.TwoYear90
    ]
  }

  export function value(type: CompanyCouponType): [number, number] {
    switch (type) {
      case CompanyCouponType.OneMonthFree:
        return [1, 100]
      case CompanyCouponType.OneMonth10:
        return [1, 10]
      case CompanyCouponType.OneMonth20:
        return [1, 20]
      case CompanyCouponType.OneMonth30:
        return [1, 30]
      case CompanyCouponType.OneMonth40:
        return [1, 40]
      case CompanyCouponType.OneMonth50:
        return [1, 50]
      case CompanyCouponType.OneMonth60:
        return [1, 60]
      case CompanyCouponType.OneMonth70:
        return [1, 70]
      case CompanyCouponType.OneMonth80:
        return [1, 80]
      case CompanyCouponType.OneMonth90:
        return [1, 90]
      case CompanyCouponType.TwoMonthFree:
        return [2, 100]
      case CompanyCouponType.TwoMonth10:
        return [2, 10]
      case CompanyCouponType.TwoMonth20:
        return [2, 20]
      case CompanyCouponType.TwoMonth30:
        return [2, 30]
      case CompanyCouponType.TwoMonth40:
        return [2, 40]
      case CompanyCouponType.TwoMonth50:
        return [2, 50]
      case CompanyCouponType.TwoMonth60:
        return [2, 60]
      case CompanyCouponType.TwoMonth70:
        return [2, 70]
      case CompanyCouponType.TwoMonth80:
        return [2, 80]
      case CompanyCouponType.TwoMonth90:
        return [2, 90]
      case CompanyCouponType.ThreeMonthFree:
        return [3, 100]
      case CompanyCouponType.ThreeMonth10:
        return [3, 10]
      case CompanyCouponType.ThreeMonth20:
        return [3, 20]
      case CompanyCouponType.ThreeMonth30:
        return [3, 30]
      case CompanyCouponType.ThreeMonth40:
        return [3, 40]
      case CompanyCouponType.ThreeMonth50:
        return [3, 50]
      case CompanyCouponType.ThreeMonth60:
        return [3, 60]
      case CompanyCouponType.ThreeMonth70:
        return [3, 70]
      case CompanyCouponType.ThreeMonth80:
        return [3, 80]
      case CompanyCouponType.ThreeMonth90:
        return [3, 90]
      case CompanyCouponType.SixMonthFree:
        return [6, 100]
      case CompanyCouponType.SixMonth10:
        return [6, 10]
      case CompanyCouponType.SixMonth20:
        return [6, 20]
      case CompanyCouponType.SixMonth30:
        return [6, 30]
      case CompanyCouponType.SixMonth40:
        return [6, 40]
      case CompanyCouponType.SixMonth50:
        return [6, 50]
      case CompanyCouponType.SixMonth60:
        return [6, 60]
      case CompanyCouponType.SixMonth70:
        return [6, 70]
      case CompanyCouponType.SixMonth80:
        return [6, 80]
      case CompanyCouponType.SixMonth90:
        return [6, 90]
      case CompanyCouponType.Normal10:
        return [0, 10]
      case CompanyCouponType.Normal20:
        return [0, 20]
      case CompanyCouponType.Normal30:
        return [0, 30]
      case CompanyCouponType.Normal40:
        return [0, 40]
      case CompanyCouponType.Normal50:
        return [0, 50]
      case CompanyCouponType.Normal60:
        return [0, 60]
      case CompanyCouponType.Normal70:
        return [0, 70]
      case CompanyCouponType.Normal80:
        return [0, 80]
      case CompanyCouponType.Normal90:
        return [0, 90]
      case CompanyCouponType.OneYearFree:
        return [12, 100]
      case CompanyCouponType.OneYear10:
        return [12, 10]
      case CompanyCouponType.OneYear20:
        return [12, 20]
      case CompanyCouponType.OneYear30:
        return [12, 30]
      case CompanyCouponType.OneYear40:
        return [12, 40]
      case CompanyCouponType.OneYear50:
        return [12, 50]
      case CompanyCouponType.OneYear60:
        return [12, 60]
      case CompanyCouponType.OneYear70:
        return [12, 70]
      case CompanyCouponType.OneYear80:
        return [12, 80]
      case CompanyCouponType.OneYear90:
        return [12, 90]
      case CompanyCouponType.TwoYearFree:
        return [24, 100]
      case CompanyCouponType.TwoYear10:
        return [24, 10]
      case CompanyCouponType.TwoYear20:
        return [24, 20]
      case CompanyCouponType.TwoYear30:
        return [24, 30]
      case CompanyCouponType.TwoYear40:
        return [24, 40]
      case CompanyCouponType.TwoYear50:
        return [24, 50]
      case CompanyCouponType.TwoYear60:
        return [24, 60]
      case CompanyCouponType.TwoYear70:
        return [24, 70]
      case CompanyCouponType.TwoYear80:
        return [24, 80]
      case CompanyCouponType.TwoYear90:
        return [24, 90]
    }
    return [0, 0]
  }

  export function name(type: CompanyCouponType): string {
    const typeValue = CompanyCouponType.value(type)
    if (typeValue[0] > 0) {
      if (typeValue[0] >= 12) {
        if (typeValue[1] === 100) {
          return `${zenkaku2Hankaku((typeValue[0] / 12).toString(10))}年無料`
        } else {
          return `${zenkaku2Hankaku((typeValue[0] / 12).toString(10))}年${zenkaku2Hankaku(typeValue[1].toString(10))}％割引`
        }
      } else {
        if (typeValue[1] === 100) {
          return `${zenkaku2Hankaku((typeValue[0]).toString(10))}ヶ月無料`
        } else {
          return `${zenkaku2Hankaku((typeValue[0]).toString(10))}ヶ月${zenkaku2Hankaku(typeValue[1].toString(10))}％割引`
        }
      }
    } else {
      return `${typeValue[1].toString(10)}％割引`
    }
  }
}

export {
  CompanyCouponType
}

export enum QuickRankingSearchType {
  FundName = 1,
  FundType,
  FundCode,
  Manager,
}

export enum QuickRankingFilterType {
  Manager = 1
}

export enum QuickRankingSortType {
  FundName = 1,
  FundType,
  BaseDay,
  Manager,
  FundCode,
  SettlementPrice,
  NetAssetValue,
  ComparedPrevDay,
  TotalReturn,
}

export interface QuickAlertSettingData {
  uid: string;
  fundName: string;
  settlementPriceNoticeEnabled: boolean;
  regularNoticeEnabled: boolean;
  allotmentNoticeEnabled: boolean;
  priceFallingNoticeEnabled: boolean;
  settlementPriceYen: number;
  settlementPriceUpperPercent: number;
  settlementPriceLowerPercent: number;
  settlementPriceUpperYen: number;
  settlementPriceLowerYen: number;
  noticeInTheDay: boolean;
  noticeType: QuickAlertPeriodType;
  noticeTime: number;
  noticeMinute: number;
}

export interface QuickFundData {
  uid: string;
  fundCode: string;
  fundName: string;
  fundType: string;
  manager: string;
  settlementPriceYen: number;
  netAssetValueYen: number;
  comparedToPrevDayYen: number;
  baseDay: Moment;
  totalReturnThreeYears: number;
}

export enum QuickAlertPeriodType {
  EveryBusinessDay = 1,
  EveryWeekend,
  EveryEndOfMonth,
}
