/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const Proto = $root.Proto = (() => {

    /**
     * Namespace Proto.
     * @exports Proto
     * @namespace
     */
    const Proto = {};

    Proto.NullBoolean = (function() {

        /**
         * Properties of a NullBoolean.
         * @memberof Proto
         * @interface INullBoolean
         * @property {boolean|null} [has] NullBoolean has
         * @property {boolean|null} [value] NullBoolean value
         */

        /**
         * Constructs a new NullBoolean.
         * @memberof Proto
         * @classdesc Represents a NullBoolean.
         * @implements INullBoolean
         * @constructor
         * @param {Proto.INullBoolean=} [p] Properties to set
         */
        function NullBoolean(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * NullBoolean has.
         * @member {boolean} has
         * @memberof Proto.NullBoolean
         * @instance
         */
        NullBoolean.prototype.has = false;

        /**
         * NullBoolean value.
         * @member {boolean} value
         * @memberof Proto.NullBoolean
         * @instance
         */
        NullBoolean.prototype.value = false;

        /**
         * Creates a new NullBoolean instance using the specified properties.
         * @function create
         * @memberof Proto.NullBoolean
         * @static
         * @param {Proto.INullBoolean=} [properties] Properties to set
         * @returns {Proto.NullBoolean} NullBoolean instance
         */
        NullBoolean.create = function create(properties) {
            return new NullBoolean(properties);
        };

        /**
         * Encodes the specified NullBoolean message. Does not implicitly {@link Proto.NullBoolean.verify|verify} messages.
         * @function encode
         * @memberof Proto.NullBoolean
         * @static
         * @param {Proto.INullBoolean} m NullBoolean message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NullBoolean.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.has != null && Object.hasOwnProperty.call(m, "has"))
                w.uint32(8).bool(m.has);
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(16).bool(m.value);
            return w;
        };

        /**
         * Decodes a NullBoolean message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.NullBoolean
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.NullBoolean} NullBoolean
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NullBoolean.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.NullBoolean();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.has = r.bool();
                    break;
                case 2:
                    m.value = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a NullBoolean message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.NullBoolean
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.NullBoolean} NullBoolean
         */
        NullBoolean.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.NullBoolean)
                return d;
            var m = new $root.Proto.NullBoolean();
            if (d.has != null) {
                m.has = Boolean(d.has);
            }
            if (d.value != null) {
                m.value = Boolean(d.value);
            }
            return m;
        };

        /**
         * Creates a plain object from a NullBoolean message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.NullBoolean
         * @static
         * @param {Proto.NullBoolean} m NullBoolean
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NullBoolean.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.has = false;
                d.value = false;
            }
            if (m.has != null && m.hasOwnProperty("has")) {
                d.has = m.has;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = m.value;
            }
            return d;
        };

        /**
         * Converts this NullBoolean to JSON.
         * @function toJSON
         * @memberof Proto.NullBoolean
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NullBoolean.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NullBoolean;
    })();

    Proto.NullInt32 = (function() {

        /**
         * Properties of a NullInt32.
         * @memberof Proto
         * @interface INullInt32
         * @property {boolean|null} [has] NullInt32 has
         * @property {number|null} [value] NullInt32 value
         */

        /**
         * Constructs a new NullInt32.
         * @memberof Proto
         * @classdesc Represents a NullInt32.
         * @implements INullInt32
         * @constructor
         * @param {Proto.INullInt32=} [p] Properties to set
         */
        function NullInt32(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * NullInt32 has.
         * @member {boolean} has
         * @memberof Proto.NullInt32
         * @instance
         */
        NullInt32.prototype.has = false;

        /**
         * NullInt32 value.
         * @member {number} value
         * @memberof Proto.NullInt32
         * @instance
         */
        NullInt32.prototype.value = 0;

        /**
         * Creates a new NullInt32 instance using the specified properties.
         * @function create
         * @memberof Proto.NullInt32
         * @static
         * @param {Proto.INullInt32=} [properties] Properties to set
         * @returns {Proto.NullInt32} NullInt32 instance
         */
        NullInt32.create = function create(properties) {
            return new NullInt32(properties);
        };

        /**
         * Encodes the specified NullInt32 message. Does not implicitly {@link Proto.NullInt32.verify|verify} messages.
         * @function encode
         * @memberof Proto.NullInt32
         * @static
         * @param {Proto.INullInt32} m NullInt32 message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NullInt32.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.has != null && Object.hasOwnProperty.call(m, "has"))
                w.uint32(8).bool(m.has);
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(16).int32(m.value);
            return w;
        };

        /**
         * Decodes a NullInt32 message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.NullInt32
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.NullInt32} NullInt32
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NullInt32.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.NullInt32();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.has = r.bool();
                    break;
                case 2:
                    m.value = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a NullInt32 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.NullInt32
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.NullInt32} NullInt32
         */
        NullInt32.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.NullInt32)
                return d;
            var m = new $root.Proto.NullInt32();
            if (d.has != null) {
                m.has = Boolean(d.has);
            }
            if (d.value != null) {
                m.value = d.value | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a NullInt32 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.NullInt32
         * @static
         * @param {Proto.NullInt32} m NullInt32
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NullInt32.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.has = false;
                d.value = 0;
            }
            if (m.has != null && m.hasOwnProperty("has")) {
                d.has = m.has;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = m.value;
            }
            return d;
        };

        /**
         * Converts this NullInt32 to JSON.
         * @function toJSON
         * @memberof Proto.NullInt32
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NullInt32.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NullInt32;
    })();

    Proto.NullInt64 = (function() {

        /**
         * Properties of a NullInt64.
         * @memberof Proto
         * @interface INullInt64
         * @property {boolean|null} [has] NullInt64 has
         * @property {number|Long|null} [value] NullInt64 value
         */

        /**
         * Constructs a new NullInt64.
         * @memberof Proto
         * @classdesc Represents a NullInt64.
         * @implements INullInt64
         * @constructor
         * @param {Proto.INullInt64=} [p] Properties to set
         */
        function NullInt64(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * NullInt64 has.
         * @member {boolean} has
         * @memberof Proto.NullInt64
         * @instance
         */
        NullInt64.prototype.has = false;

        /**
         * NullInt64 value.
         * @member {number|Long} value
         * @memberof Proto.NullInt64
         * @instance
         */
        NullInt64.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new NullInt64 instance using the specified properties.
         * @function create
         * @memberof Proto.NullInt64
         * @static
         * @param {Proto.INullInt64=} [properties] Properties to set
         * @returns {Proto.NullInt64} NullInt64 instance
         */
        NullInt64.create = function create(properties) {
            return new NullInt64(properties);
        };

        /**
         * Encodes the specified NullInt64 message. Does not implicitly {@link Proto.NullInt64.verify|verify} messages.
         * @function encode
         * @memberof Proto.NullInt64
         * @static
         * @param {Proto.INullInt64} m NullInt64 message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NullInt64.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.has != null && Object.hasOwnProperty.call(m, "has"))
                w.uint32(8).bool(m.has);
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(16).int64(m.value);
            return w;
        };

        /**
         * Decodes a NullInt64 message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.NullInt64
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.NullInt64} NullInt64
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NullInt64.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.NullInt64();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.has = r.bool();
                    break;
                case 2:
                    m.value = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a NullInt64 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.NullInt64
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.NullInt64} NullInt64
         */
        NullInt64.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.NullInt64)
                return d;
            var m = new $root.Proto.NullInt64();
            if (d.has != null) {
                m.has = Boolean(d.has);
            }
            if (d.value != null) {
                if ($util.Long)
                    (m.value = $util.Long.fromValue(d.value)).unsigned = false;
                else if (typeof d.value === "string")
                    m.value = parseInt(d.value, 10);
                else if (typeof d.value === "number")
                    m.value = d.value;
                else if (typeof d.value === "object")
                    m.value = new $util.LongBits(d.value.low >>> 0, d.value.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a NullInt64 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.NullInt64
         * @static
         * @param {Proto.NullInt64} m NullInt64
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NullInt64.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.has = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.value = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.value = o.longs === String ? "0" : 0;
            }
            if (m.has != null && m.hasOwnProperty("has")) {
                d.has = m.has;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                if (typeof m.value === "number")
                    d.value = o.longs === String ? String(m.value) : m.value;
                else
                    d.value = o.longs === String ? $util.Long.prototype.toString.call(m.value) : o.longs === Number ? new $util.LongBits(m.value.low >>> 0, m.value.high >>> 0).toNumber() : m.value;
            }
            return d;
        };

        /**
         * Converts this NullInt64 to JSON.
         * @function toJSON
         * @memberof Proto.NullInt64
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NullInt64.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NullInt64;
    })();

    Proto.NullDouble = (function() {

        /**
         * Properties of a NullDouble.
         * @memberof Proto
         * @interface INullDouble
         * @property {boolean|null} [has] NullDouble has
         * @property {number|null} [value] NullDouble value
         */

        /**
         * Constructs a new NullDouble.
         * @memberof Proto
         * @classdesc Represents a NullDouble.
         * @implements INullDouble
         * @constructor
         * @param {Proto.INullDouble=} [p] Properties to set
         */
        function NullDouble(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * NullDouble has.
         * @member {boolean} has
         * @memberof Proto.NullDouble
         * @instance
         */
        NullDouble.prototype.has = false;

        /**
         * NullDouble value.
         * @member {number} value
         * @memberof Proto.NullDouble
         * @instance
         */
        NullDouble.prototype.value = 0;

        /**
         * Creates a new NullDouble instance using the specified properties.
         * @function create
         * @memberof Proto.NullDouble
         * @static
         * @param {Proto.INullDouble=} [properties] Properties to set
         * @returns {Proto.NullDouble} NullDouble instance
         */
        NullDouble.create = function create(properties) {
            return new NullDouble(properties);
        };

        /**
         * Encodes the specified NullDouble message. Does not implicitly {@link Proto.NullDouble.verify|verify} messages.
         * @function encode
         * @memberof Proto.NullDouble
         * @static
         * @param {Proto.INullDouble} m NullDouble message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NullDouble.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.has != null && Object.hasOwnProperty.call(m, "has"))
                w.uint32(8).bool(m.has);
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(17).double(m.value);
            return w;
        };

        /**
         * Decodes a NullDouble message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.NullDouble
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.NullDouble} NullDouble
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NullDouble.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.NullDouble();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.has = r.bool();
                    break;
                case 2:
                    m.value = r.double();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a NullDouble message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.NullDouble
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.NullDouble} NullDouble
         */
        NullDouble.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.NullDouble)
                return d;
            var m = new $root.Proto.NullDouble();
            if (d.has != null) {
                m.has = Boolean(d.has);
            }
            if (d.value != null) {
                m.value = Number(d.value);
            }
            return m;
        };

        /**
         * Creates a plain object from a NullDouble message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.NullDouble
         * @static
         * @param {Proto.NullDouble} m NullDouble
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NullDouble.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.has = false;
                d.value = 0;
            }
            if (m.has != null && m.hasOwnProperty("has")) {
                d.has = m.has;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = o.json && !isFinite(m.value) ? String(m.value) : m.value;
            }
            return d;
        };

        /**
         * Converts this NullDouble to JSON.
         * @function toJSON
         * @memberof Proto.NullDouble
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NullDouble.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NullDouble;
    })();

    Proto.NullString = (function() {

        /**
         * Properties of a NullString.
         * @memberof Proto
         * @interface INullString
         * @property {boolean|null} [has] NullString has
         * @property {string|null} [value] NullString value
         */

        /**
         * Constructs a new NullString.
         * @memberof Proto
         * @classdesc Represents a NullString.
         * @implements INullString
         * @constructor
         * @param {Proto.INullString=} [p] Properties to set
         */
        function NullString(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * NullString has.
         * @member {boolean} has
         * @memberof Proto.NullString
         * @instance
         */
        NullString.prototype.has = false;

        /**
         * NullString value.
         * @member {string} value
         * @memberof Proto.NullString
         * @instance
         */
        NullString.prototype.value = "";

        /**
         * Creates a new NullString instance using the specified properties.
         * @function create
         * @memberof Proto.NullString
         * @static
         * @param {Proto.INullString=} [properties] Properties to set
         * @returns {Proto.NullString} NullString instance
         */
        NullString.create = function create(properties) {
            return new NullString(properties);
        };

        /**
         * Encodes the specified NullString message. Does not implicitly {@link Proto.NullString.verify|verify} messages.
         * @function encode
         * @memberof Proto.NullString
         * @static
         * @param {Proto.INullString} m NullString message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NullString.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.has != null && Object.hasOwnProperty.call(m, "has"))
                w.uint32(8).bool(m.has);
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(18).string(m.value);
            return w;
        };

        /**
         * Decodes a NullString message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.NullString
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.NullString} NullString
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NullString.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.NullString();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.has = r.bool();
                    break;
                case 2:
                    m.value = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a NullString message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.NullString
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.NullString} NullString
         */
        NullString.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.NullString)
                return d;
            var m = new $root.Proto.NullString();
            if (d.has != null) {
                m.has = Boolean(d.has);
            }
            if (d.value != null) {
                m.value = String(d.value);
            }
            return m;
        };

        /**
         * Creates a plain object from a NullString message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.NullString
         * @static
         * @param {Proto.NullString} m NullString
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NullString.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.has = false;
                d.value = "";
            }
            if (m.has != null && m.hasOwnProperty("has")) {
                d.has = m.has;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = m.value;
            }
            return d;
        };

        /**
         * Converts this NullString to JSON.
         * @function toJSON
         * @memberof Proto.NullString
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NullString.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NullString;
    })();

    Proto.StringList = (function() {

        /**
         * Properties of a StringList.
         * @memberof Proto
         * @interface IStringList
         * @property {Array.<string>|null} [value] StringList value
         */

        /**
         * Constructs a new StringList.
         * @memberof Proto
         * @classdesc Represents a StringList.
         * @implements IStringList
         * @constructor
         * @param {Proto.IStringList=} [p] Properties to set
         */
        function StringList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * StringList value.
         * @member {Array.<string>} value
         * @memberof Proto.StringList
         * @instance
         */
        StringList.prototype.value = $util.emptyArray;

        /**
         * Creates a new StringList instance using the specified properties.
         * @function create
         * @memberof Proto.StringList
         * @static
         * @param {Proto.IStringList=} [properties] Properties to set
         * @returns {Proto.StringList} StringList instance
         */
        StringList.create = function create(properties) {
            return new StringList(properties);
        };

        /**
         * Encodes the specified StringList message. Does not implicitly {@link Proto.StringList.verify|verify} messages.
         * @function encode
         * @memberof Proto.StringList
         * @static
         * @param {Proto.IStringList} m StringList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StringList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    w.uint32(10).string(m.value[i]);
            }
            return w;
        };

        /**
         * Decodes a StringList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.StringList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.StringList} StringList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StringList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.StringList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push(r.string());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a StringList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.StringList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.StringList} StringList
         */
        StringList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.StringList)
                return d;
            var m = new $root.Proto.StringList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.StringList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    m.value[i] = String(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a StringList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.StringList
         * @static
         * @param {Proto.StringList} m StringList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StringList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = m.value[j];
                }
            }
            return d;
        };

        /**
         * Converts this StringList to JSON.
         * @function toJSON
         * @memberof Proto.StringList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StringList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StringList;
    })();

    Proto.GlobalError = (function() {

        /**
         * Properties of a GlobalError.
         * @memberof Proto
         * @interface IGlobalError
         * @property {number|null} [status] GlobalError status
         * @property {string|null} [path] GlobalError path
         * @property {string|null} [timestamp] GlobalError timestamp
         * @property {string|null} [error] GlobalError error
         * @property {string|null} [message] GlobalError message
         */

        /**
         * Constructs a new GlobalError.
         * @memberof Proto
         * @classdesc Represents a GlobalError.
         * @implements IGlobalError
         * @constructor
         * @param {Proto.IGlobalError=} [p] Properties to set
         */
        function GlobalError(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * GlobalError status.
         * @member {number} status
         * @memberof Proto.GlobalError
         * @instance
         */
        GlobalError.prototype.status = 0;

        /**
         * GlobalError path.
         * @member {string} path
         * @memberof Proto.GlobalError
         * @instance
         */
        GlobalError.prototype.path = "";

        /**
         * GlobalError timestamp.
         * @member {string} timestamp
         * @memberof Proto.GlobalError
         * @instance
         */
        GlobalError.prototype.timestamp = "";

        /**
         * GlobalError error.
         * @member {string} error
         * @memberof Proto.GlobalError
         * @instance
         */
        GlobalError.prototype.error = "";

        /**
         * GlobalError message.
         * @member {string} message
         * @memberof Proto.GlobalError
         * @instance
         */
        GlobalError.prototype.message = "";

        /**
         * Creates a new GlobalError instance using the specified properties.
         * @function create
         * @memberof Proto.GlobalError
         * @static
         * @param {Proto.IGlobalError=} [properties] Properties to set
         * @returns {Proto.GlobalError} GlobalError instance
         */
        GlobalError.create = function create(properties) {
            return new GlobalError(properties);
        };

        /**
         * Encodes the specified GlobalError message. Does not implicitly {@link Proto.GlobalError.verify|verify} messages.
         * @function encode
         * @memberof Proto.GlobalError
         * @static
         * @param {Proto.IGlobalError} m GlobalError message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GlobalError.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(8).int32(m.status);
            if (m.path != null && Object.hasOwnProperty.call(m, "path"))
                w.uint32(18).string(m.path);
            if (m.timestamp != null && Object.hasOwnProperty.call(m, "timestamp"))
                w.uint32(26).string(m.timestamp);
            if (m.error != null && Object.hasOwnProperty.call(m, "error"))
                w.uint32(34).string(m.error);
            if (m.message != null && Object.hasOwnProperty.call(m, "message"))
                w.uint32(42).string(m.message);
            return w;
        };

        /**
         * Decodes a GlobalError message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.GlobalError
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.GlobalError} GlobalError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GlobalError.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.GlobalError();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.status = r.int32();
                    break;
                case 2:
                    m.path = r.string();
                    break;
                case 3:
                    m.timestamp = r.string();
                    break;
                case 4:
                    m.error = r.string();
                    break;
                case 5:
                    m.message = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a GlobalError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.GlobalError
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.GlobalError} GlobalError
         */
        GlobalError.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.GlobalError)
                return d;
            var m = new $root.Proto.GlobalError();
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.path != null) {
                m.path = String(d.path);
            }
            if (d.timestamp != null) {
                m.timestamp = String(d.timestamp);
            }
            if (d.error != null) {
                m.error = String(d.error);
            }
            if (d.message != null) {
                m.message = String(d.message);
            }
            return m;
        };

        /**
         * Creates a plain object from a GlobalError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.GlobalError
         * @static
         * @param {Proto.GlobalError} m GlobalError
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GlobalError.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.status = 0;
                d.path = "";
                d.timestamp = "";
                d.error = "";
                d.message = "";
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.path != null && m.hasOwnProperty("path")) {
                d.path = m.path;
            }
            if (m.timestamp != null && m.hasOwnProperty("timestamp")) {
                d.timestamp = m.timestamp;
            }
            if (m.error != null && m.hasOwnProperty("error")) {
                d.error = m.error;
            }
            if (m.message != null && m.hasOwnProperty("message")) {
                d.message = m.message;
            }
            return d;
        };

        /**
         * Converts this GlobalError to JSON.
         * @function toJSON
         * @memberof Proto.GlobalError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GlobalError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GlobalError;
    })();

    /**
     * ResultCode enum.
     * @name Proto.ResultCode
     * @enum {number}
     * @property {number} Error=0 Error value
     * @property {number} Success=1 Success value
     * @property {number} Login=2 Login value
     */
    Proto.ResultCode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Error"] = 0;
        values[valuesById[1] = "Success"] = 1;
        values[valuesById[2] = "Login"] = 2;
        return values;
    })();

    /**
     * ErrorCode enum.
     * @name Proto.ErrorCode
     * @enum {number}
     * @property {number} OK=0 OK value
     * @property {number} UNKNOWN=1 UNKNOWN value
     * @property {number} UNRECOGNIZED_EVENT=2 UNRECOGNIZED_EVENT value
     * @property {number} NO_SESSION=3 NO_SESSION value
     * @property {number} NO_USER=4 NO_USER value
     * @property {number} NO_CONTENT=5 NO_CONTENT value
     * @property {number} NO_CONTENT_PUBLIC=6 NO_CONTENT_PUBLIC value
     * @property {number} NO_USER_OAUTH=7 NO_USER_OAUTH value
     * @property {number} USER_RESIGN=8 USER_RESIGN value
     * @property {number} ACCOUNT_BLOCK=9 ACCOUNT_BLOCK value
     * @property {number} SECURITY_IP=10 SECURITY_IP value
     * @property {number} LOGIN_DOMAIN=11 LOGIN_DOMAIN value
     * @property {number} SUBUSER_NOT_STAFF=12 SUBUSER_NOT_STAFF value
     * @property {number} NO_ZOOM=91 NO_ZOOM value
     * @property {number} NO_VIMEO=92 NO_VIMEO value
     * @property {number} NO_VIMEO_VIDEO=93 NO_VIMEO_VIDEO value
     * @property {number} LIVE_DUPLICATE=94 LIVE_DUPLICATE value
     * @property {number} NO_YOUTUBE=95 NO_YOUTUBE value
     * @property {number} NO_YOUTUBE_VIDEO=96 NO_YOUTUBE_VIDEO value
     * @property {number} NO_YOUTUBE_LIVE_AUTH=97 NO_YOUTUBE_LIVE_AUTH value
     * @property {number} NO_YOUTUBE_LIVE_BLOCK=98 NO_YOUTUBE_LIVE_BLOCK value
     * @property {number} LIVE_USER_LIMIT=99 LIVE_USER_LIMIT value
     * @property {number} MULTIPLE_ACCOUNT=2000 MULTIPLE_ACCOUNT value
     * @property {number} DUPLICATE_PLAN=2001 DUPLICATE_PLAN value
     * @property {number} IS_OWNER=2002 IS_OWNER value
     * @property {number} IS_USER=2003 IS_USER value
     * @property {number} USER_BLOCK=2004 USER_BLOCK value
     * @property {number} LP_BLOCK=2005 LP_BLOCK value
     * @property {number} PLAN_DEPS=2006 PLAN_DEPS value
     * @property {number} PLAN_USER_EXCEED=2007 PLAN_USER_EXCEED value
     * @property {number} PLAN_SIGNUP_BLOCKED=2008 PLAN_SIGNUP_BLOCKED value
     * @property {number} PLAN_APPROVE_CHANGE=2009 PLAN_APPROVE_CHANGE value
     * @property {number} SCHEDULE_MAX_USER=2011 SCHEDULE_MAX_USER value
     * @property {number} SCHEDULE_DUPLICATE=2012 SCHEDULE_DUPLICATE value
     * @property {number} BLOCK_ADMIN=2013 BLOCK_ADMIN value
     * @property {number} IS_BTOB_USER=2014 IS_BTOB_USER value
     * @property {number} HAS_BTOB_USER_PLAN=2015 HAS_BTOB_USER_PLAN value
     * @property {number} SCHEDULE_STAFF=2016 SCHEDULE_STAFF value
     * @property {number} POLL_DUPLICATE=2101 POLL_DUPLICATE value
     * @property {number} POINT_ENABLE=2500 POINT_ENABLE value
     * @property {number} POINT_SHORTAGE=2501 POINT_SHORTAGE value
     * @property {number} POINT_APP_CHARGE=2502 POINT_APP_CHARGE value
     * @property {number} POINT_MAX_CHARGE=2503 POINT_MAX_CHARGE value
     * @property {number} TVOD_ENABLE=2601 TVOD_ENABLE value
     * @property {number} TVOD_DUPLICATE=2602 TVOD_DUPLICATE value
     * @property {number} TVOD_INAPP_UNAVAILABLE=2603 TVOD_INAPP_UNAVAILABLE value
     * @property {number} SIGNING_JOINING=3001 SIGNING_JOINING value
     * @property {number} SIGNING_DUPLICATE=3002 SIGNING_DUPLICATE value
     * @property {number} BOARD_GROUP_JOIN_DUPLICATE=3601 BOARD_GROUP_JOIN_DUPLICATE value
     * @property {number} BOARD_FORUM_MAIN=3602 BOARD_FORUM_MAIN value
     * @property {number} BOARD_GROUP_EXCLUSIVE_NOT_USER=3603 BOARD_GROUP_EXCLUSIVE_NOT_USER value
     * @property {number} BOARD_GROUP_NOT_JOINED=3604 BOARD_GROUP_NOT_JOINED value
     * @property {number} CHAT_REALTIME_TYPE=5001 CHAT_REALTIME_TYPE value
     * @property {number} CHAT_ACCESS=5002 CHAT_ACCESS value
     * @property {number} PROFILE_ACCESS=6001 PROFILE_ACCESS value
     * @property {number} WORKFLOW_NO_ACCESS=7001 WORKFLOW_NO_ACCESS value
     * @property {number} WORKFLOW_INPUT_DUPLICATE=7002 WORKFLOW_INPUT_DUPLICATE value
     * @property {number} WORKFLOW_FILE_DUPLICATE=7003 WORKFLOW_FILE_DUPLICATE value
     * @property {number} WORK_NO_STAFF=7501 WORK_NO_STAFF value
     * @property {number} WORK_STAFF_SCHEDULE_DUPLICATE=7502 WORK_STAFF_SCHEDULE_DUPLICATE value
     * @property {number} WORK_STAFF_CLOCK_DUPLICATE=7503 WORK_STAFF_CLOCK_DUPLICATE value
     * @property {number} WORK_STAFF_NO_TIMETABLE=7504 WORK_STAFF_NO_TIMETABLE value
     * @property {number} WORK_STAFF_TIMETABLE_TIME_MATCH=7505 WORK_STAFF_TIMETABLE_TIME_MATCH value
     * @property {number} WORK_STAFF_TIMETABLE_EXCEED=7506 WORK_STAFF_TIMETABLE_EXCEED value
     * @property {number} WORK_STAFF_HOLIDAY_SHORTAGE=7507 WORK_STAFF_HOLIDAY_SHORTAGE value
     * @property {number} WORK_STAFF_CLOCK_CHANGE_DUPLICATE=7508 WORK_STAFF_CLOCK_CHANGE_DUPLICATE value
     * @property {number} JOIN_MAX_USER=9001 JOIN_MAX_USER value
     * @property {number} TRAFFIC_EXCEED=9004 TRAFFIC_EXCEED value
     * @property {number} SALES_EXCEED=9005 SALES_EXCEED value
     * @property {number} LP_STOP=9006 LP_STOP value
     * @property {number} ALL_SALES_EXCEED=9007 ALL_SALES_EXCEED value
     * @property {number} FREE_PLAN_PERIOD_EXCEED=9008 FREE_PLAN_PERIOD_EXCEED value
     * @property {number} STORAGE_EXCEED=9009 STORAGE_EXCEED value
     * @property {number} NO_WEB3=9401 NO_WEB3 value
     * @property {number} NO_WALLET_USER=9402 NO_WALLET_USER value
     * @property {number} WALLET_PASSWORD=9403 WALLET_PASSWORD value
     * @property {number} COMMERCE_QUANTITY_CHANGE_WEB3=9411 COMMERCE_QUANTITY_CHANGE_WEB3 value
     * @property {number} NFT_TRANSFER_NOT_USER=9421 NFT_TRANSFER_NOT_USER value
     * @property {number} NFT_TRANSFER_IS_OWNER=9422 NFT_TRANSFER_IS_OWNER value
     * @property {number} NFT_TRANSFER_DUPLICATE=9423 NFT_TRANSFER_DUPLICATE value
     * @property {number} NFT_TRANSFER_NO_TARGET_WALLET=9424 NFT_TRANSFER_NO_TARGET_WALLET value
     * @property {number} NFT_TRANSFER_NOT_ALLOWED=9425 NFT_TRANSFER_NOT_ALLOWED value
     * @property {number} NFT_TRANSFER_TOKEN_SHORTAGE=9426 NFT_TRANSFER_TOKEN_SHORTAGE value
     * @property {number} NFT_TRANSFER_ALREADY_USER=9427 NFT_TRANSFER_ALREADY_USER value
     * @property {number} NFT_TRANSFER_ADVERTISE_DUPLICATE=9428 NFT_TRANSFER_ADVERTISE_DUPLICATE value
     * @property {number} PAYMENT_ERROR=9501 PAYMENT_ERROR value
     * @property {number} PAYMENT_EMPTY_ERROR=9502 PAYMENT_EMPTY_ERROR value
     * @property {number} PAYMENT_BANK_ERROR=9503 PAYMENT_BANK_ERROR value
     * @property {number} PAYMENT_REFUND_ERROR=9504 PAYMENT_REFUND_ERROR value
     * @property {number} PAYMENT_REFUND_DELIVERED_ERROR=9505 PAYMENT_REFUND_DELIVERED_ERROR value
     * @property {number} PAYMENT_REFUND_FIXED_ERROR=9506 PAYMENT_REFUND_FIXED_ERROR value
     * @property {number} PAYMENT_METHOD_CAPTURED_ERROR=9507 PAYMENT_METHOD_CAPTURED_ERROR value
     * @property {number} FUNDING_PROJECT_END_ERROR=9508 FUNDING_PROJECT_END_ERROR value
     * @property {number} PAYMENT_SERVICE_EMPTY_ERROR=9509 PAYMENT_SERVICE_EMPTY_ERROR value
     * @property {number} PAYMENT_CANCEL_POLICY=9510 PAYMENT_CANCEL_POLICY value
     * @property {number} PRODUCT_QUANTITY=9551 PRODUCT_QUANTITY value
     * @property {number} ORDER_DEPENDENCY=9552 ORDER_DEPENDENCY value
     * @property {number} RECAPTCHA=9601 RECAPTCHA value
     * @property {number} DEVICE_COUNT=9701 DEVICE_COUNT value
     * @property {number} MIN_APP_VERSION=9997 MIN_APP_VERSION value
     * @property {number} SERVER_MESSAGE=9998 SERVER_MESSAGE value
     * @property {number} API_TOKEN_INVALID=9999 API_TOKEN_INVALID value
     * @property {number} MAINTENANCE=10000 MAINTENANCE value
     * @property {number} NO_THIRD_USER=100001 NO_THIRD_USER value
     * @property {number} NO_THIRD_USER_EMAIL=100002 NO_THIRD_USER_EMAIL value
     * @property {number} NO_THIRD_REGISTER=100003 NO_THIRD_REGISTER value
     * @property {number} NO_THIRD_SYNC=100004 NO_THIRD_SYNC value
     * @property {number} THIRD_USER_DUPLICATE=100005 THIRD_USER_DUPLICATE value
     */
    Proto.ErrorCode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "OK"] = 0;
        values[valuesById[1] = "UNKNOWN"] = 1;
        values[valuesById[2] = "UNRECOGNIZED_EVENT"] = 2;
        values[valuesById[3] = "NO_SESSION"] = 3;
        values[valuesById[4] = "NO_USER"] = 4;
        values[valuesById[5] = "NO_CONTENT"] = 5;
        values[valuesById[6] = "NO_CONTENT_PUBLIC"] = 6;
        values[valuesById[7] = "NO_USER_OAUTH"] = 7;
        values[valuesById[8] = "USER_RESIGN"] = 8;
        values[valuesById[9] = "ACCOUNT_BLOCK"] = 9;
        values[valuesById[10] = "SECURITY_IP"] = 10;
        values[valuesById[11] = "LOGIN_DOMAIN"] = 11;
        values[valuesById[12] = "SUBUSER_NOT_STAFF"] = 12;
        values[valuesById[91] = "NO_ZOOM"] = 91;
        values[valuesById[92] = "NO_VIMEO"] = 92;
        values[valuesById[93] = "NO_VIMEO_VIDEO"] = 93;
        values[valuesById[94] = "LIVE_DUPLICATE"] = 94;
        values[valuesById[95] = "NO_YOUTUBE"] = 95;
        values[valuesById[96] = "NO_YOUTUBE_VIDEO"] = 96;
        values[valuesById[97] = "NO_YOUTUBE_LIVE_AUTH"] = 97;
        values[valuesById[98] = "NO_YOUTUBE_LIVE_BLOCK"] = 98;
        values[valuesById[99] = "LIVE_USER_LIMIT"] = 99;
        values[valuesById[2000] = "MULTIPLE_ACCOUNT"] = 2000;
        values[valuesById[2001] = "DUPLICATE_PLAN"] = 2001;
        values[valuesById[2002] = "IS_OWNER"] = 2002;
        values[valuesById[2003] = "IS_USER"] = 2003;
        values[valuesById[2004] = "USER_BLOCK"] = 2004;
        values[valuesById[2005] = "LP_BLOCK"] = 2005;
        values[valuesById[2006] = "PLAN_DEPS"] = 2006;
        values[valuesById[2007] = "PLAN_USER_EXCEED"] = 2007;
        values[valuesById[2008] = "PLAN_SIGNUP_BLOCKED"] = 2008;
        values[valuesById[2009] = "PLAN_APPROVE_CHANGE"] = 2009;
        values[valuesById[2011] = "SCHEDULE_MAX_USER"] = 2011;
        values[valuesById[2012] = "SCHEDULE_DUPLICATE"] = 2012;
        values[valuesById[2013] = "BLOCK_ADMIN"] = 2013;
        values[valuesById[2014] = "IS_BTOB_USER"] = 2014;
        values[valuesById[2015] = "HAS_BTOB_USER_PLAN"] = 2015;
        values[valuesById[2016] = "SCHEDULE_STAFF"] = 2016;
        values[valuesById[2101] = "POLL_DUPLICATE"] = 2101;
        values[valuesById[2500] = "POINT_ENABLE"] = 2500;
        values[valuesById[2501] = "POINT_SHORTAGE"] = 2501;
        values[valuesById[2502] = "POINT_APP_CHARGE"] = 2502;
        values[valuesById[2503] = "POINT_MAX_CHARGE"] = 2503;
        values[valuesById[2601] = "TVOD_ENABLE"] = 2601;
        values[valuesById[2602] = "TVOD_DUPLICATE"] = 2602;
        values[valuesById[2603] = "TVOD_INAPP_UNAVAILABLE"] = 2603;
        values[valuesById[3001] = "SIGNING_JOINING"] = 3001;
        values[valuesById[3002] = "SIGNING_DUPLICATE"] = 3002;
        values[valuesById[3601] = "BOARD_GROUP_JOIN_DUPLICATE"] = 3601;
        values[valuesById[3602] = "BOARD_FORUM_MAIN"] = 3602;
        values[valuesById[3603] = "BOARD_GROUP_EXCLUSIVE_NOT_USER"] = 3603;
        values[valuesById[3604] = "BOARD_GROUP_NOT_JOINED"] = 3604;
        values[valuesById[5001] = "CHAT_REALTIME_TYPE"] = 5001;
        values[valuesById[5002] = "CHAT_ACCESS"] = 5002;
        values[valuesById[6001] = "PROFILE_ACCESS"] = 6001;
        values[valuesById[7001] = "WORKFLOW_NO_ACCESS"] = 7001;
        values[valuesById[7002] = "WORKFLOW_INPUT_DUPLICATE"] = 7002;
        values[valuesById[7003] = "WORKFLOW_FILE_DUPLICATE"] = 7003;
        values[valuesById[7501] = "WORK_NO_STAFF"] = 7501;
        values[valuesById[7502] = "WORK_STAFF_SCHEDULE_DUPLICATE"] = 7502;
        values[valuesById[7503] = "WORK_STAFF_CLOCK_DUPLICATE"] = 7503;
        values[valuesById[7504] = "WORK_STAFF_NO_TIMETABLE"] = 7504;
        values[valuesById[7505] = "WORK_STAFF_TIMETABLE_TIME_MATCH"] = 7505;
        values[valuesById[7506] = "WORK_STAFF_TIMETABLE_EXCEED"] = 7506;
        values[valuesById[7507] = "WORK_STAFF_HOLIDAY_SHORTAGE"] = 7507;
        values[valuesById[7508] = "WORK_STAFF_CLOCK_CHANGE_DUPLICATE"] = 7508;
        values[valuesById[9001] = "JOIN_MAX_USER"] = 9001;
        values[valuesById[9004] = "TRAFFIC_EXCEED"] = 9004;
        values[valuesById[9005] = "SALES_EXCEED"] = 9005;
        values[valuesById[9006] = "LP_STOP"] = 9006;
        values[valuesById[9007] = "ALL_SALES_EXCEED"] = 9007;
        values[valuesById[9008] = "FREE_PLAN_PERIOD_EXCEED"] = 9008;
        values[valuesById[9009] = "STORAGE_EXCEED"] = 9009;
        values[valuesById[9401] = "NO_WEB3"] = 9401;
        values[valuesById[9402] = "NO_WALLET_USER"] = 9402;
        values[valuesById[9403] = "WALLET_PASSWORD"] = 9403;
        values[valuesById[9411] = "COMMERCE_QUANTITY_CHANGE_WEB3"] = 9411;
        values[valuesById[9421] = "NFT_TRANSFER_NOT_USER"] = 9421;
        values[valuesById[9422] = "NFT_TRANSFER_IS_OWNER"] = 9422;
        values[valuesById[9423] = "NFT_TRANSFER_DUPLICATE"] = 9423;
        values[valuesById[9424] = "NFT_TRANSFER_NO_TARGET_WALLET"] = 9424;
        values[valuesById[9425] = "NFT_TRANSFER_NOT_ALLOWED"] = 9425;
        values[valuesById[9426] = "NFT_TRANSFER_TOKEN_SHORTAGE"] = 9426;
        values[valuesById[9427] = "NFT_TRANSFER_ALREADY_USER"] = 9427;
        values[valuesById[9428] = "NFT_TRANSFER_ADVERTISE_DUPLICATE"] = 9428;
        values[valuesById[9501] = "PAYMENT_ERROR"] = 9501;
        values[valuesById[9502] = "PAYMENT_EMPTY_ERROR"] = 9502;
        values[valuesById[9503] = "PAYMENT_BANK_ERROR"] = 9503;
        values[valuesById[9504] = "PAYMENT_REFUND_ERROR"] = 9504;
        values[valuesById[9505] = "PAYMENT_REFUND_DELIVERED_ERROR"] = 9505;
        values[valuesById[9506] = "PAYMENT_REFUND_FIXED_ERROR"] = 9506;
        values[valuesById[9507] = "PAYMENT_METHOD_CAPTURED_ERROR"] = 9507;
        values[valuesById[9508] = "FUNDING_PROJECT_END_ERROR"] = 9508;
        values[valuesById[9509] = "PAYMENT_SERVICE_EMPTY_ERROR"] = 9509;
        values[valuesById[9510] = "PAYMENT_CANCEL_POLICY"] = 9510;
        values[valuesById[9551] = "PRODUCT_QUANTITY"] = 9551;
        values[valuesById[9552] = "ORDER_DEPENDENCY"] = 9552;
        values[valuesById[9601] = "RECAPTCHA"] = 9601;
        values[valuesById[9701] = "DEVICE_COUNT"] = 9701;
        values[valuesById[9997] = "MIN_APP_VERSION"] = 9997;
        values[valuesById[9998] = "SERVER_MESSAGE"] = 9998;
        values[valuesById[9999] = "API_TOKEN_INVALID"] = 9999;
        values[valuesById[10000] = "MAINTENANCE"] = 10000;
        values[valuesById[100001] = "NO_THIRD_USER"] = 100001;
        values[valuesById[100002] = "NO_THIRD_USER_EMAIL"] = 100002;
        values[valuesById[100003] = "NO_THIRD_REGISTER"] = 100003;
        values[valuesById[100004] = "NO_THIRD_SYNC"] = 100004;
        values[valuesById[100005] = "THIRD_USER_DUPLICATE"] = 100005;
        return values;
    })();

    Proto.ReturnData = (function() {

        /**
         * Properties of a ReturnData.
         * @memberof Proto
         * @interface IReturnData
         * @property {Proto.ResultCode|null} [code] ReturnData code
         * @property {Uint8Array|null} [data] ReturnData data
         * @property {Proto.ReturnData.IReturnError|null} [error] ReturnData error
         */

        /**
         * Constructs a new ReturnData.
         * @memberof Proto
         * @classdesc Represents a ReturnData.
         * @implements IReturnData
         * @constructor
         * @param {Proto.IReturnData=} [p] Properties to set
         */
        function ReturnData(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReturnData code.
         * @member {Proto.ResultCode} code
         * @memberof Proto.ReturnData
         * @instance
         */
        ReturnData.prototype.code = 0;

        /**
         * ReturnData data.
         * @member {Uint8Array} data
         * @memberof Proto.ReturnData
         * @instance
         */
        ReturnData.prototype.data = $util.newBuffer([]);

        /**
         * ReturnData error.
         * @member {Proto.ReturnData.IReturnError|null|undefined} error
         * @memberof Proto.ReturnData
         * @instance
         */
        ReturnData.prototype.error = null;

        /**
         * Creates a new ReturnData instance using the specified properties.
         * @function create
         * @memberof Proto.ReturnData
         * @static
         * @param {Proto.IReturnData=} [properties] Properties to set
         * @returns {Proto.ReturnData} ReturnData instance
         */
        ReturnData.create = function create(properties) {
            return new ReturnData(properties);
        };

        /**
         * Encodes the specified ReturnData message. Does not implicitly {@link Proto.ReturnData.verify|verify} messages.
         * @function encode
         * @memberof Proto.ReturnData
         * @static
         * @param {Proto.IReturnData} m ReturnData message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReturnData.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                w.uint32(8).int32(m.code);
            if (m.data != null && Object.hasOwnProperty.call(m, "data"))
                w.uint32(18).bytes(m.data);
            if (m.error != null && Object.hasOwnProperty.call(m, "error"))
                $root.Proto.ReturnData.ReturnError.encode(m.error, w.uint32(26).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a ReturnData message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ReturnData
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ReturnData} ReturnData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReturnData.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ReturnData();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.code = r.int32();
                    break;
                case 2:
                    m.data = r.bytes();
                    break;
                case 3:
                    m.error = $root.Proto.ReturnData.ReturnError.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ReturnData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ReturnData
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ReturnData} ReturnData
         */
        ReturnData.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ReturnData)
                return d;
            var m = new $root.Proto.ReturnData();
            switch (d.code) {
            case "Error":
            case 0:
                m.code = 0;
                break;
            case "Success":
            case 1:
                m.code = 1;
                break;
            case "Login":
            case 2:
                m.code = 2;
                break;
            }
            if (d.data != null) {
                if (typeof d.data === "string")
                    $util.base64.decode(d.data, m.data = $util.newBuffer($util.base64.length(d.data)), 0);
                else if (d.data.length)
                    m.data = d.data;
            }
            if (d.error != null) {
                if (typeof d.error !== "object")
                    throw TypeError(".Proto.ReturnData.error: object expected");
                m.error = $root.Proto.ReturnData.ReturnError.fromObject(d.error);
            }
            return m;
        };

        /**
         * Creates a plain object from a ReturnData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ReturnData
         * @static
         * @param {Proto.ReturnData} m ReturnData
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReturnData.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.code = o.enums === String ? "Error" : 0;
                if (o.bytes === String)
                    d.data = "";
                else {
                    d.data = [];
                    if (o.bytes !== Array)
                        d.data = $util.newBuffer(d.data);
                }
                d.error = null;
            }
            if (m.code != null && m.hasOwnProperty("code")) {
                d.code = o.enums === String ? $root.Proto.ResultCode[m.code] : m.code;
            }
            if (m.data != null && m.hasOwnProperty("data")) {
                d.data = o.bytes === String ? $util.base64.encode(m.data, 0, m.data.length) : o.bytes === Array ? Array.prototype.slice.call(m.data) : m.data;
            }
            if (m.error != null && m.hasOwnProperty("error")) {
                d.error = $root.Proto.ReturnData.ReturnError.toObject(m.error, o);
            }
            return d;
        };

        /**
         * Converts this ReturnData to JSON.
         * @function toJSON
         * @memberof Proto.ReturnData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReturnData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ReturnData.ReturnError = (function() {

            /**
             * Properties of a ReturnError.
             * @memberof Proto.ReturnData
             * @interface IReturnError
             * @property {Proto.ErrorCode|null} [code] ReturnError code
             * @property {string|null} [message] ReturnError message
             */

            /**
             * Constructs a new ReturnError.
             * @memberof Proto.ReturnData
             * @classdesc Represents a ReturnError.
             * @implements IReturnError
             * @constructor
             * @param {Proto.ReturnData.IReturnError=} [p] Properties to set
             */
            function ReturnError(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ReturnError code.
             * @member {Proto.ErrorCode} code
             * @memberof Proto.ReturnData.ReturnError
             * @instance
             */
            ReturnError.prototype.code = 0;

            /**
             * ReturnError message.
             * @member {string} message
             * @memberof Proto.ReturnData.ReturnError
             * @instance
             */
            ReturnError.prototype.message = "";

            /**
             * Creates a new ReturnError instance using the specified properties.
             * @function create
             * @memberof Proto.ReturnData.ReturnError
             * @static
             * @param {Proto.ReturnData.IReturnError=} [properties] Properties to set
             * @returns {Proto.ReturnData.ReturnError} ReturnError instance
             */
            ReturnError.create = function create(properties) {
                return new ReturnError(properties);
            };

            /**
             * Encodes the specified ReturnError message. Does not implicitly {@link Proto.ReturnData.ReturnError.verify|verify} messages.
             * @function encode
             * @memberof Proto.ReturnData.ReturnError
             * @static
             * @param {Proto.ReturnData.IReturnError} m ReturnError message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReturnError.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                    w.uint32(8).int32(m.code);
                if (m.message != null && Object.hasOwnProperty.call(m, "message"))
                    w.uint32(18).string(m.message);
                return w;
            };

            /**
             * Decodes a ReturnError message from the specified reader or buffer.
             * @function decode
             * @memberof Proto.ReturnData.ReturnError
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {Proto.ReturnData.ReturnError} ReturnError
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReturnError.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ReturnData.ReturnError();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.code = r.int32();
                        break;
                    case 2:
                        m.message = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ReturnError message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Proto.ReturnData.ReturnError
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {Proto.ReturnData.ReturnError} ReturnError
             */
            ReturnError.fromObject = function fromObject(d) {
                if (d instanceof $root.Proto.ReturnData.ReturnError)
                    return d;
                var m = new $root.Proto.ReturnData.ReturnError();
                switch (d.code) {
                case "OK":
                case 0:
                    m.code = 0;
                    break;
                case "UNKNOWN":
                case 1:
                    m.code = 1;
                    break;
                case "UNRECOGNIZED_EVENT":
                case 2:
                    m.code = 2;
                    break;
                case "NO_SESSION":
                case 3:
                    m.code = 3;
                    break;
                case "NO_USER":
                case 4:
                    m.code = 4;
                    break;
                case "NO_CONTENT":
                case 5:
                    m.code = 5;
                    break;
                case "NO_CONTENT_PUBLIC":
                case 6:
                    m.code = 6;
                    break;
                case "NO_USER_OAUTH":
                case 7:
                    m.code = 7;
                    break;
                case "USER_RESIGN":
                case 8:
                    m.code = 8;
                    break;
                case "ACCOUNT_BLOCK":
                case 9:
                    m.code = 9;
                    break;
                case "SECURITY_IP":
                case 10:
                    m.code = 10;
                    break;
                case "LOGIN_DOMAIN":
                case 11:
                    m.code = 11;
                    break;
                case "SUBUSER_NOT_STAFF":
                case 12:
                    m.code = 12;
                    break;
                case "NO_ZOOM":
                case 91:
                    m.code = 91;
                    break;
                case "NO_VIMEO":
                case 92:
                    m.code = 92;
                    break;
                case "NO_VIMEO_VIDEO":
                case 93:
                    m.code = 93;
                    break;
                case "LIVE_DUPLICATE":
                case 94:
                    m.code = 94;
                    break;
                case "NO_YOUTUBE":
                case 95:
                    m.code = 95;
                    break;
                case "NO_YOUTUBE_VIDEO":
                case 96:
                    m.code = 96;
                    break;
                case "NO_YOUTUBE_LIVE_AUTH":
                case 97:
                    m.code = 97;
                    break;
                case "NO_YOUTUBE_LIVE_BLOCK":
                case 98:
                    m.code = 98;
                    break;
                case "LIVE_USER_LIMIT":
                case 99:
                    m.code = 99;
                    break;
                case "MULTIPLE_ACCOUNT":
                case 2000:
                    m.code = 2000;
                    break;
                case "DUPLICATE_PLAN":
                case 2001:
                    m.code = 2001;
                    break;
                case "IS_OWNER":
                case 2002:
                    m.code = 2002;
                    break;
                case "IS_USER":
                case 2003:
                    m.code = 2003;
                    break;
                case "USER_BLOCK":
                case 2004:
                    m.code = 2004;
                    break;
                case "LP_BLOCK":
                case 2005:
                    m.code = 2005;
                    break;
                case "PLAN_DEPS":
                case 2006:
                    m.code = 2006;
                    break;
                case "PLAN_USER_EXCEED":
                case 2007:
                    m.code = 2007;
                    break;
                case "PLAN_SIGNUP_BLOCKED":
                case 2008:
                    m.code = 2008;
                    break;
                case "PLAN_APPROVE_CHANGE":
                case 2009:
                    m.code = 2009;
                    break;
                case "SCHEDULE_MAX_USER":
                case 2011:
                    m.code = 2011;
                    break;
                case "SCHEDULE_DUPLICATE":
                case 2012:
                    m.code = 2012;
                    break;
                case "BLOCK_ADMIN":
                case 2013:
                    m.code = 2013;
                    break;
                case "IS_BTOB_USER":
                case 2014:
                    m.code = 2014;
                    break;
                case "HAS_BTOB_USER_PLAN":
                case 2015:
                    m.code = 2015;
                    break;
                case "SCHEDULE_STAFF":
                case 2016:
                    m.code = 2016;
                    break;
                case "POLL_DUPLICATE":
                case 2101:
                    m.code = 2101;
                    break;
                case "POINT_ENABLE":
                case 2500:
                    m.code = 2500;
                    break;
                case "POINT_SHORTAGE":
                case 2501:
                    m.code = 2501;
                    break;
                case "POINT_APP_CHARGE":
                case 2502:
                    m.code = 2502;
                    break;
                case "POINT_MAX_CHARGE":
                case 2503:
                    m.code = 2503;
                    break;
                case "TVOD_ENABLE":
                case 2601:
                    m.code = 2601;
                    break;
                case "TVOD_DUPLICATE":
                case 2602:
                    m.code = 2602;
                    break;
                case "TVOD_INAPP_UNAVAILABLE":
                case 2603:
                    m.code = 2603;
                    break;
                case "SIGNING_JOINING":
                case 3001:
                    m.code = 3001;
                    break;
                case "SIGNING_DUPLICATE":
                case 3002:
                    m.code = 3002;
                    break;
                case "BOARD_GROUP_JOIN_DUPLICATE":
                case 3601:
                    m.code = 3601;
                    break;
                case "BOARD_FORUM_MAIN":
                case 3602:
                    m.code = 3602;
                    break;
                case "BOARD_GROUP_EXCLUSIVE_NOT_USER":
                case 3603:
                    m.code = 3603;
                    break;
                case "BOARD_GROUP_NOT_JOINED":
                case 3604:
                    m.code = 3604;
                    break;
                case "CHAT_REALTIME_TYPE":
                case 5001:
                    m.code = 5001;
                    break;
                case "CHAT_ACCESS":
                case 5002:
                    m.code = 5002;
                    break;
                case "PROFILE_ACCESS":
                case 6001:
                    m.code = 6001;
                    break;
                case "WORKFLOW_NO_ACCESS":
                case 7001:
                    m.code = 7001;
                    break;
                case "WORKFLOW_INPUT_DUPLICATE":
                case 7002:
                    m.code = 7002;
                    break;
                case "WORKFLOW_FILE_DUPLICATE":
                case 7003:
                    m.code = 7003;
                    break;
                case "WORK_NO_STAFF":
                case 7501:
                    m.code = 7501;
                    break;
                case "WORK_STAFF_SCHEDULE_DUPLICATE":
                case 7502:
                    m.code = 7502;
                    break;
                case "WORK_STAFF_CLOCK_DUPLICATE":
                case 7503:
                    m.code = 7503;
                    break;
                case "WORK_STAFF_NO_TIMETABLE":
                case 7504:
                    m.code = 7504;
                    break;
                case "WORK_STAFF_TIMETABLE_TIME_MATCH":
                case 7505:
                    m.code = 7505;
                    break;
                case "WORK_STAFF_TIMETABLE_EXCEED":
                case 7506:
                    m.code = 7506;
                    break;
                case "WORK_STAFF_HOLIDAY_SHORTAGE":
                case 7507:
                    m.code = 7507;
                    break;
                case "WORK_STAFF_CLOCK_CHANGE_DUPLICATE":
                case 7508:
                    m.code = 7508;
                    break;
                case "JOIN_MAX_USER":
                case 9001:
                    m.code = 9001;
                    break;
                case "TRAFFIC_EXCEED":
                case 9004:
                    m.code = 9004;
                    break;
                case "SALES_EXCEED":
                case 9005:
                    m.code = 9005;
                    break;
                case "LP_STOP":
                case 9006:
                    m.code = 9006;
                    break;
                case "ALL_SALES_EXCEED":
                case 9007:
                    m.code = 9007;
                    break;
                case "FREE_PLAN_PERIOD_EXCEED":
                case 9008:
                    m.code = 9008;
                    break;
                case "STORAGE_EXCEED":
                case 9009:
                    m.code = 9009;
                    break;
                case "NO_WEB3":
                case 9401:
                    m.code = 9401;
                    break;
                case "NO_WALLET_USER":
                case 9402:
                    m.code = 9402;
                    break;
                case "WALLET_PASSWORD":
                case 9403:
                    m.code = 9403;
                    break;
                case "COMMERCE_QUANTITY_CHANGE_WEB3":
                case 9411:
                    m.code = 9411;
                    break;
                case "NFT_TRANSFER_NOT_USER":
                case 9421:
                    m.code = 9421;
                    break;
                case "NFT_TRANSFER_IS_OWNER":
                case 9422:
                    m.code = 9422;
                    break;
                case "NFT_TRANSFER_DUPLICATE":
                case 9423:
                    m.code = 9423;
                    break;
                case "NFT_TRANSFER_NO_TARGET_WALLET":
                case 9424:
                    m.code = 9424;
                    break;
                case "NFT_TRANSFER_NOT_ALLOWED":
                case 9425:
                    m.code = 9425;
                    break;
                case "NFT_TRANSFER_TOKEN_SHORTAGE":
                case 9426:
                    m.code = 9426;
                    break;
                case "NFT_TRANSFER_ALREADY_USER":
                case 9427:
                    m.code = 9427;
                    break;
                case "NFT_TRANSFER_ADVERTISE_DUPLICATE":
                case 9428:
                    m.code = 9428;
                    break;
                case "PAYMENT_ERROR":
                case 9501:
                    m.code = 9501;
                    break;
                case "PAYMENT_EMPTY_ERROR":
                case 9502:
                    m.code = 9502;
                    break;
                case "PAYMENT_BANK_ERROR":
                case 9503:
                    m.code = 9503;
                    break;
                case "PAYMENT_REFUND_ERROR":
                case 9504:
                    m.code = 9504;
                    break;
                case "PAYMENT_REFUND_DELIVERED_ERROR":
                case 9505:
                    m.code = 9505;
                    break;
                case "PAYMENT_REFUND_FIXED_ERROR":
                case 9506:
                    m.code = 9506;
                    break;
                case "PAYMENT_METHOD_CAPTURED_ERROR":
                case 9507:
                    m.code = 9507;
                    break;
                case "FUNDING_PROJECT_END_ERROR":
                case 9508:
                    m.code = 9508;
                    break;
                case "PAYMENT_SERVICE_EMPTY_ERROR":
                case 9509:
                    m.code = 9509;
                    break;
                case "PAYMENT_CANCEL_POLICY":
                case 9510:
                    m.code = 9510;
                    break;
                case "PRODUCT_QUANTITY":
                case 9551:
                    m.code = 9551;
                    break;
                case "ORDER_DEPENDENCY":
                case 9552:
                    m.code = 9552;
                    break;
                case "RECAPTCHA":
                case 9601:
                    m.code = 9601;
                    break;
                case "DEVICE_COUNT":
                case 9701:
                    m.code = 9701;
                    break;
                case "MIN_APP_VERSION":
                case 9997:
                    m.code = 9997;
                    break;
                case "SERVER_MESSAGE":
                case 9998:
                    m.code = 9998;
                    break;
                case "API_TOKEN_INVALID":
                case 9999:
                    m.code = 9999;
                    break;
                case "MAINTENANCE":
                case 10000:
                    m.code = 10000;
                    break;
                case "NO_THIRD_USER":
                case 100001:
                    m.code = 100001;
                    break;
                case "NO_THIRD_USER_EMAIL":
                case 100002:
                    m.code = 100002;
                    break;
                case "NO_THIRD_REGISTER":
                case 100003:
                    m.code = 100003;
                    break;
                case "NO_THIRD_SYNC":
                case 100004:
                    m.code = 100004;
                    break;
                case "THIRD_USER_DUPLICATE":
                case 100005:
                    m.code = 100005;
                    break;
                }
                if (d.message != null) {
                    m.message = String(d.message);
                }
                return m;
            };

            /**
             * Creates a plain object from a ReturnError message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Proto.ReturnData.ReturnError
             * @static
             * @param {Proto.ReturnData.ReturnError} m ReturnError
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReturnError.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.code = o.enums === String ? "OK" : 0;
                    d.message = "";
                }
                if (m.code != null && m.hasOwnProperty("code")) {
                    d.code = o.enums === String ? $root.Proto.ErrorCode[m.code] : m.code;
                }
                if (m.message != null && m.hasOwnProperty("message")) {
                    d.message = m.message;
                }
                return d;
            };

            /**
             * Converts this ReturnError to JSON.
             * @function toJSON
             * @memberof Proto.ReturnData.ReturnError
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReturnError.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ReturnError;
        })();

        return ReturnData;
    })();

    Proto.User = (function() {

        /**
         * Properties of a User.
         * @memberof Proto
         * @interface IUser
         * @property {string|null} [uid] User uid
         * @property {number|Long|null} [registerDate] User registerDate
         * @property {number|Long|null} [modifyDate] User modifyDate
         * @property {string|null} [userId] User userId
         * @property {string|null} [userNickname] User userNickname
         * @property {string|null} [locale] User locale
         * @property {string|null} [countryCode] User countryCode
         * @property {number|null} [gender] User gender
         * @property {string|null} [birth] User birth
         * @property {string|null} [companyUID] User companyUID
         * @property {string|null} [postalCode] User postalCode
         * @property {string|null} [state] User state
         * @property {string|null} [address] User address
         * @property {string|null} [userFirstname] User userFirstname
         * @property {string|null} [userLastname] User userLastname
         * @property {string|null} [userFirstnameFuri] User userFirstnameFuri
         * @property {string|null} [userLastnameFuri] User userLastnameFuri
         * @property {Proto.IUploadFile|null} [icon] User icon
         * @property {boolean|null} [isTmp] User isTmp
         * @property {string|null} [phone] User phone
         * @property {Proto.IUploadFile|null} [companyIcon] User companyIcon
         * @property {number|null} [userType] User userType
         * @property {string|null} [token] User token
         * @property {string|null} [btobUID] User btobUID
         * @property {Proto.IUploadFile|null} [btobIcon] User btobIcon
         * @property {string|null} [btobName] User btobName
         * @property {string|null} [city] User city
         * @property {string|null} [addressDetail] User addressDetail
         * @property {string|null} [profile] User profile
         * @property {Array.<number>|null} [adminSubUserTypes] User adminSubUserTypes
         * @property {string|null} [companyNickname] User companyNickname
         * @property {string|null} [userBtobUID] User userBtobUID
         * @property {Array.<Proto.ICompanyUserGroup>|null} [userGroupList] User userGroupList
         * @property {number|null} [adminThirdOAuthType] User adminThirdOAuthType
         * @property {string|null} [adminSubUserSetting] User adminSubUserSetting
         * @property {string|null} [adminSubContentSetting] User adminSubContentSetting
         */

        /**
         * Constructs a new User.
         * @memberof Proto
         * @classdesc Represents a User.
         * @implements IUser
         * @constructor
         * @param {Proto.IUser=} [p] Properties to set
         */
        function User(p) {
            this.adminSubUserTypes = [];
            this.userGroupList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * User uid.
         * @member {string} uid
         * @memberof Proto.User
         * @instance
         */
        User.prototype.uid = "";

        /**
         * User registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.User
         * @instance
         */
        User.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * User modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.User
         * @instance
         */
        User.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * User userId.
         * @member {string} userId
         * @memberof Proto.User
         * @instance
         */
        User.prototype.userId = "";

        /**
         * User userNickname.
         * @member {string} userNickname
         * @memberof Proto.User
         * @instance
         */
        User.prototype.userNickname = "";

        /**
         * User locale.
         * @member {string} locale
         * @memberof Proto.User
         * @instance
         */
        User.prototype.locale = "";

        /**
         * User countryCode.
         * @member {string} countryCode
         * @memberof Proto.User
         * @instance
         */
        User.prototype.countryCode = "";

        /**
         * User gender.
         * @member {number} gender
         * @memberof Proto.User
         * @instance
         */
        User.prototype.gender = 0;

        /**
         * User birth.
         * @member {string} birth
         * @memberof Proto.User
         * @instance
         */
        User.prototype.birth = "";

        /**
         * User companyUID.
         * @member {string} companyUID
         * @memberof Proto.User
         * @instance
         */
        User.prototype.companyUID = "";

        /**
         * User postalCode.
         * @member {string} postalCode
         * @memberof Proto.User
         * @instance
         */
        User.prototype.postalCode = "";

        /**
         * User state.
         * @member {string} state
         * @memberof Proto.User
         * @instance
         */
        User.prototype.state = "";

        /**
         * User address.
         * @member {string} address
         * @memberof Proto.User
         * @instance
         */
        User.prototype.address = "";

        /**
         * User userFirstname.
         * @member {string} userFirstname
         * @memberof Proto.User
         * @instance
         */
        User.prototype.userFirstname = "";

        /**
         * User userLastname.
         * @member {string} userLastname
         * @memberof Proto.User
         * @instance
         */
        User.prototype.userLastname = "";

        /**
         * User userFirstnameFuri.
         * @member {string} userFirstnameFuri
         * @memberof Proto.User
         * @instance
         */
        User.prototype.userFirstnameFuri = "";

        /**
         * User userLastnameFuri.
         * @member {string} userLastnameFuri
         * @memberof Proto.User
         * @instance
         */
        User.prototype.userLastnameFuri = "";

        /**
         * User icon.
         * @member {Proto.IUploadFile|null|undefined} icon
         * @memberof Proto.User
         * @instance
         */
        User.prototype.icon = null;

        /**
         * User isTmp.
         * @member {boolean} isTmp
         * @memberof Proto.User
         * @instance
         */
        User.prototype.isTmp = false;

        /**
         * User phone.
         * @member {string} phone
         * @memberof Proto.User
         * @instance
         */
        User.prototype.phone = "";

        /**
         * User companyIcon.
         * @member {Proto.IUploadFile|null|undefined} companyIcon
         * @memberof Proto.User
         * @instance
         */
        User.prototype.companyIcon = null;

        /**
         * User userType.
         * @member {number} userType
         * @memberof Proto.User
         * @instance
         */
        User.prototype.userType = 0;

        /**
         * User token.
         * @member {string} token
         * @memberof Proto.User
         * @instance
         */
        User.prototype.token = "";

        /**
         * User btobUID.
         * @member {string} btobUID
         * @memberof Proto.User
         * @instance
         */
        User.prototype.btobUID = "";

        /**
         * User btobIcon.
         * @member {Proto.IUploadFile|null|undefined} btobIcon
         * @memberof Proto.User
         * @instance
         */
        User.prototype.btobIcon = null;

        /**
         * User btobName.
         * @member {string} btobName
         * @memberof Proto.User
         * @instance
         */
        User.prototype.btobName = "";

        /**
         * User city.
         * @member {string} city
         * @memberof Proto.User
         * @instance
         */
        User.prototype.city = "";

        /**
         * User addressDetail.
         * @member {string} addressDetail
         * @memberof Proto.User
         * @instance
         */
        User.prototype.addressDetail = "";

        /**
         * User profile.
         * @member {string} profile
         * @memberof Proto.User
         * @instance
         */
        User.prototype.profile = "";

        /**
         * User adminSubUserTypes.
         * @member {Array.<number>} adminSubUserTypes
         * @memberof Proto.User
         * @instance
         */
        User.prototype.adminSubUserTypes = $util.emptyArray;

        /**
         * User companyNickname.
         * @member {string} companyNickname
         * @memberof Proto.User
         * @instance
         */
        User.prototype.companyNickname = "";

        /**
         * User userBtobUID.
         * @member {string} userBtobUID
         * @memberof Proto.User
         * @instance
         */
        User.prototype.userBtobUID = "";

        /**
         * User userGroupList.
         * @member {Array.<Proto.ICompanyUserGroup>} userGroupList
         * @memberof Proto.User
         * @instance
         */
        User.prototype.userGroupList = $util.emptyArray;

        /**
         * User adminThirdOAuthType.
         * @member {number} adminThirdOAuthType
         * @memberof Proto.User
         * @instance
         */
        User.prototype.adminThirdOAuthType = 0;

        /**
         * User adminSubUserSetting.
         * @member {string} adminSubUserSetting
         * @memberof Proto.User
         * @instance
         */
        User.prototype.adminSubUserSetting = "";

        /**
         * User adminSubContentSetting.
         * @member {string} adminSubContentSetting
         * @memberof Proto.User
         * @instance
         */
        User.prototype.adminSubContentSetting = "";

        /**
         * Creates a new User instance using the specified properties.
         * @function create
         * @memberof Proto.User
         * @static
         * @param {Proto.IUser=} [properties] Properties to set
         * @returns {Proto.User} User instance
         */
        User.create = function create(properties) {
            return new User(properties);
        };

        /**
         * Encodes the specified User message. Does not implicitly {@link Proto.User.verify|verify} messages.
         * @function encode
         * @memberof Proto.User
         * @static
         * @param {Proto.IUser} m User message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        User.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(16).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(24).int64(m.modifyDate);
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(34).string(m.userId);
            if (m.userNickname != null && Object.hasOwnProperty.call(m, "userNickname"))
                w.uint32(42).string(m.userNickname);
            if (m.locale != null && Object.hasOwnProperty.call(m, "locale"))
                w.uint32(50).string(m.locale);
            if (m.countryCode != null && Object.hasOwnProperty.call(m, "countryCode"))
                w.uint32(58).string(m.countryCode);
            if (m.gender != null && Object.hasOwnProperty.call(m, "gender"))
                w.uint32(64).int32(m.gender);
            if (m.birth != null && Object.hasOwnProperty.call(m, "birth"))
                w.uint32(74).string(m.birth);
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(82).string(m.companyUID);
            if (m.postalCode != null && Object.hasOwnProperty.call(m, "postalCode"))
                w.uint32(90).string(m.postalCode);
            if (m.state != null && Object.hasOwnProperty.call(m, "state"))
                w.uint32(98).string(m.state);
            if (m.address != null && Object.hasOwnProperty.call(m, "address"))
                w.uint32(106).string(m.address);
            if (m.userFirstname != null && Object.hasOwnProperty.call(m, "userFirstname"))
                w.uint32(114).string(m.userFirstname);
            if (m.userLastname != null && Object.hasOwnProperty.call(m, "userLastname"))
                w.uint32(122).string(m.userLastname);
            if (m.userFirstnameFuri != null && Object.hasOwnProperty.call(m, "userFirstnameFuri"))
                w.uint32(130).string(m.userFirstnameFuri);
            if (m.userLastnameFuri != null && Object.hasOwnProperty.call(m, "userLastnameFuri"))
                w.uint32(138).string(m.userLastnameFuri);
            if (m.icon != null && Object.hasOwnProperty.call(m, "icon"))
                $root.Proto.UploadFile.encode(m.icon, w.uint32(146).fork()).ldelim();
            if (m.isTmp != null && Object.hasOwnProperty.call(m, "isTmp"))
                w.uint32(152).bool(m.isTmp);
            if (m.phone != null && Object.hasOwnProperty.call(m, "phone"))
                w.uint32(162).string(m.phone);
            if (m.companyIcon != null && Object.hasOwnProperty.call(m, "companyIcon"))
                $root.Proto.UploadFile.encode(m.companyIcon, w.uint32(170).fork()).ldelim();
            if (m.userType != null && Object.hasOwnProperty.call(m, "userType"))
                w.uint32(176).int32(m.userType);
            if (m.token != null && Object.hasOwnProperty.call(m, "token"))
                w.uint32(186).string(m.token);
            if (m.btobUID != null && Object.hasOwnProperty.call(m, "btobUID"))
                w.uint32(194).string(m.btobUID);
            if (m.btobIcon != null && Object.hasOwnProperty.call(m, "btobIcon"))
                $root.Proto.UploadFile.encode(m.btobIcon, w.uint32(202).fork()).ldelim();
            if (m.btobName != null && Object.hasOwnProperty.call(m, "btobName"))
                w.uint32(210).string(m.btobName);
            if (m.city != null && Object.hasOwnProperty.call(m, "city"))
                w.uint32(218).string(m.city);
            if (m.addressDetail != null && Object.hasOwnProperty.call(m, "addressDetail"))
                w.uint32(226).string(m.addressDetail);
            if (m.profile != null && Object.hasOwnProperty.call(m, "profile"))
                w.uint32(234).string(m.profile);
            if (m.adminSubUserTypes != null && m.adminSubUserTypes.length) {
                w.uint32(242).fork();
                for (var i = 0; i < m.adminSubUserTypes.length; ++i)
                    w.int32(m.adminSubUserTypes[i]);
                w.ldelim();
            }
            if (m.companyNickname != null && Object.hasOwnProperty.call(m, "companyNickname"))
                w.uint32(250).string(m.companyNickname);
            if (m.userBtobUID != null && Object.hasOwnProperty.call(m, "userBtobUID"))
                w.uint32(258).string(m.userBtobUID);
            if (m.userGroupList != null && m.userGroupList.length) {
                for (var i = 0; i < m.userGroupList.length; ++i)
                    $root.Proto.CompanyUserGroup.encode(m.userGroupList[i], w.uint32(266).fork()).ldelim();
            }
            if (m.adminThirdOAuthType != null && Object.hasOwnProperty.call(m, "adminThirdOAuthType"))
                w.uint32(272).int32(m.adminThirdOAuthType);
            if (m.adminSubUserSetting != null && Object.hasOwnProperty.call(m, "adminSubUserSetting"))
                w.uint32(282).string(m.adminSubUserSetting);
            if (m.adminSubContentSetting != null && Object.hasOwnProperty.call(m, "adminSubContentSetting"))
                w.uint32(290).string(m.adminSubContentSetting);
            return w;
        };

        /**
         * Decodes a User message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.User
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.User} User
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        User.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.User();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.registerDate = r.int64();
                    break;
                case 3:
                    m.modifyDate = r.int64();
                    break;
                case 4:
                    m.userId = r.string();
                    break;
                case 5:
                    m.userNickname = r.string();
                    break;
                case 6:
                    m.locale = r.string();
                    break;
                case 7:
                    m.countryCode = r.string();
                    break;
                case 8:
                    m.gender = r.int32();
                    break;
                case 9:
                    m.birth = r.string();
                    break;
                case 10:
                    m.companyUID = r.string();
                    break;
                case 11:
                    m.postalCode = r.string();
                    break;
                case 12:
                    m.state = r.string();
                    break;
                case 13:
                    m.address = r.string();
                    break;
                case 14:
                    m.userFirstname = r.string();
                    break;
                case 15:
                    m.userLastname = r.string();
                    break;
                case 16:
                    m.userFirstnameFuri = r.string();
                    break;
                case 17:
                    m.userLastnameFuri = r.string();
                    break;
                case 18:
                    m.icon = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 19:
                    m.isTmp = r.bool();
                    break;
                case 20:
                    m.phone = r.string();
                    break;
                case 21:
                    m.companyIcon = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 22:
                    m.userType = r.int32();
                    break;
                case 23:
                    m.token = r.string();
                    break;
                case 24:
                    m.btobUID = r.string();
                    break;
                case 25:
                    m.btobIcon = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 26:
                    m.btobName = r.string();
                    break;
                case 27:
                    m.city = r.string();
                    break;
                case 28:
                    m.addressDetail = r.string();
                    break;
                case 29:
                    m.profile = r.string();
                    break;
                case 30:
                    if (!(m.adminSubUserTypes && m.adminSubUserTypes.length))
                        m.adminSubUserTypes = [];
                    if ((t & 7) === 2) {
                        var c2 = r.uint32() + r.pos;
                        while (r.pos < c2)
                            m.adminSubUserTypes.push(r.int32());
                    } else
                        m.adminSubUserTypes.push(r.int32());
                    break;
                case 31:
                    m.companyNickname = r.string();
                    break;
                case 32:
                    m.userBtobUID = r.string();
                    break;
                case 33:
                    if (!(m.userGroupList && m.userGroupList.length))
                        m.userGroupList = [];
                    m.userGroupList.push($root.Proto.CompanyUserGroup.decode(r, r.uint32()));
                    break;
                case 34:
                    m.adminThirdOAuthType = r.int32();
                    break;
                case 35:
                    m.adminSubUserSetting = r.string();
                    break;
                case 36:
                    m.adminSubContentSetting = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a User message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.User
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.User} User
         */
        User.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.User)
                return d;
            var m = new $root.Proto.User();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.userId != null) {
                m.userId = String(d.userId);
            }
            if (d.userNickname != null) {
                m.userNickname = String(d.userNickname);
            }
            if (d.locale != null) {
                m.locale = String(d.locale);
            }
            if (d.countryCode != null) {
                m.countryCode = String(d.countryCode);
            }
            if (d.gender != null) {
                m.gender = d.gender | 0;
            }
            if (d.birth != null) {
                m.birth = String(d.birth);
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.postalCode != null) {
                m.postalCode = String(d.postalCode);
            }
            if (d.state != null) {
                m.state = String(d.state);
            }
            if (d.address != null) {
                m.address = String(d.address);
            }
            if (d.userFirstname != null) {
                m.userFirstname = String(d.userFirstname);
            }
            if (d.userLastname != null) {
                m.userLastname = String(d.userLastname);
            }
            if (d.userFirstnameFuri != null) {
                m.userFirstnameFuri = String(d.userFirstnameFuri);
            }
            if (d.userLastnameFuri != null) {
                m.userLastnameFuri = String(d.userLastnameFuri);
            }
            if (d.icon != null) {
                if (typeof d.icon !== "object")
                    throw TypeError(".Proto.User.icon: object expected");
                m.icon = $root.Proto.UploadFile.fromObject(d.icon);
            }
            if (d.isTmp != null) {
                m.isTmp = Boolean(d.isTmp);
            }
            if (d.phone != null) {
                m.phone = String(d.phone);
            }
            if (d.companyIcon != null) {
                if (typeof d.companyIcon !== "object")
                    throw TypeError(".Proto.User.companyIcon: object expected");
                m.companyIcon = $root.Proto.UploadFile.fromObject(d.companyIcon);
            }
            if (d.userType != null) {
                m.userType = d.userType | 0;
            }
            if (d.token != null) {
                m.token = String(d.token);
            }
            if (d.btobUID != null) {
                m.btobUID = String(d.btobUID);
            }
            if (d.btobIcon != null) {
                if (typeof d.btobIcon !== "object")
                    throw TypeError(".Proto.User.btobIcon: object expected");
                m.btobIcon = $root.Proto.UploadFile.fromObject(d.btobIcon);
            }
            if (d.btobName != null) {
                m.btobName = String(d.btobName);
            }
            if (d.city != null) {
                m.city = String(d.city);
            }
            if (d.addressDetail != null) {
                m.addressDetail = String(d.addressDetail);
            }
            if (d.profile != null) {
                m.profile = String(d.profile);
            }
            if (d.adminSubUserTypes) {
                if (!Array.isArray(d.adminSubUserTypes))
                    throw TypeError(".Proto.User.adminSubUserTypes: array expected");
                m.adminSubUserTypes = [];
                for (var i = 0; i < d.adminSubUserTypes.length; ++i) {
                    m.adminSubUserTypes[i] = d.adminSubUserTypes[i] | 0;
                }
            }
            if (d.companyNickname != null) {
                m.companyNickname = String(d.companyNickname);
            }
            if (d.userBtobUID != null) {
                m.userBtobUID = String(d.userBtobUID);
            }
            if (d.userGroupList) {
                if (!Array.isArray(d.userGroupList))
                    throw TypeError(".Proto.User.userGroupList: array expected");
                m.userGroupList = [];
                for (var i = 0; i < d.userGroupList.length; ++i) {
                    if (typeof d.userGroupList[i] !== "object")
                        throw TypeError(".Proto.User.userGroupList: object expected");
                    m.userGroupList[i] = $root.Proto.CompanyUserGroup.fromObject(d.userGroupList[i]);
                }
            }
            if (d.adminThirdOAuthType != null) {
                m.adminThirdOAuthType = d.adminThirdOAuthType | 0;
            }
            if (d.adminSubUserSetting != null) {
                m.adminSubUserSetting = String(d.adminSubUserSetting);
            }
            if (d.adminSubContentSetting != null) {
                m.adminSubContentSetting = String(d.adminSubContentSetting);
            }
            return m;
        };

        /**
         * Creates a plain object from a User message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.User
         * @static
         * @param {Proto.User} m User
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        User.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.adminSubUserTypes = [];
                d.userGroupList = [];
            }
            if (o.defaults) {
                d.uid = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.userId = "";
                d.userNickname = "";
                d.locale = "";
                d.countryCode = "";
                d.gender = 0;
                d.birth = "";
                d.companyUID = "";
                d.postalCode = "";
                d.state = "";
                d.address = "";
                d.userFirstname = "";
                d.userLastname = "";
                d.userFirstnameFuri = "";
                d.userLastnameFuri = "";
                d.icon = null;
                d.isTmp = false;
                d.phone = "";
                d.companyIcon = null;
                d.userType = 0;
                d.token = "";
                d.btobUID = "";
                d.btobIcon = null;
                d.btobName = "";
                d.city = "";
                d.addressDetail = "";
                d.profile = "";
                d.companyNickname = "";
                d.userBtobUID = "";
                d.adminThirdOAuthType = 0;
                d.adminSubUserSetting = "";
                d.adminSubContentSetting = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                d.userId = m.userId;
            }
            if (m.userNickname != null && m.hasOwnProperty("userNickname")) {
                d.userNickname = m.userNickname;
            }
            if (m.locale != null && m.hasOwnProperty("locale")) {
                d.locale = m.locale;
            }
            if (m.countryCode != null && m.hasOwnProperty("countryCode")) {
                d.countryCode = m.countryCode;
            }
            if (m.gender != null && m.hasOwnProperty("gender")) {
                d.gender = m.gender;
            }
            if (m.birth != null && m.hasOwnProperty("birth")) {
                d.birth = m.birth;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.postalCode != null && m.hasOwnProperty("postalCode")) {
                d.postalCode = m.postalCode;
            }
            if (m.state != null && m.hasOwnProperty("state")) {
                d.state = m.state;
            }
            if (m.address != null && m.hasOwnProperty("address")) {
                d.address = m.address;
            }
            if (m.userFirstname != null && m.hasOwnProperty("userFirstname")) {
                d.userFirstname = m.userFirstname;
            }
            if (m.userLastname != null && m.hasOwnProperty("userLastname")) {
                d.userLastname = m.userLastname;
            }
            if (m.userFirstnameFuri != null && m.hasOwnProperty("userFirstnameFuri")) {
                d.userFirstnameFuri = m.userFirstnameFuri;
            }
            if (m.userLastnameFuri != null && m.hasOwnProperty("userLastnameFuri")) {
                d.userLastnameFuri = m.userLastnameFuri;
            }
            if (m.icon != null && m.hasOwnProperty("icon")) {
                d.icon = $root.Proto.UploadFile.toObject(m.icon, o);
            }
            if (m.isTmp != null && m.hasOwnProperty("isTmp")) {
                d.isTmp = m.isTmp;
            }
            if (m.phone != null && m.hasOwnProperty("phone")) {
                d.phone = m.phone;
            }
            if (m.companyIcon != null && m.hasOwnProperty("companyIcon")) {
                d.companyIcon = $root.Proto.UploadFile.toObject(m.companyIcon, o);
            }
            if (m.userType != null && m.hasOwnProperty("userType")) {
                d.userType = m.userType;
            }
            if (m.token != null && m.hasOwnProperty("token")) {
                d.token = m.token;
            }
            if (m.btobUID != null && m.hasOwnProperty("btobUID")) {
                d.btobUID = m.btobUID;
            }
            if (m.btobIcon != null && m.hasOwnProperty("btobIcon")) {
                d.btobIcon = $root.Proto.UploadFile.toObject(m.btobIcon, o);
            }
            if (m.btobName != null && m.hasOwnProperty("btobName")) {
                d.btobName = m.btobName;
            }
            if (m.city != null && m.hasOwnProperty("city")) {
                d.city = m.city;
            }
            if (m.addressDetail != null && m.hasOwnProperty("addressDetail")) {
                d.addressDetail = m.addressDetail;
            }
            if (m.profile != null && m.hasOwnProperty("profile")) {
                d.profile = m.profile;
            }
            if (m.adminSubUserTypes && m.adminSubUserTypes.length) {
                d.adminSubUserTypes = [];
                for (var j = 0; j < m.adminSubUserTypes.length; ++j) {
                    d.adminSubUserTypes[j] = m.adminSubUserTypes[j];
                }
            }
            if (m.companyNickname != null && m.hasOwnProperty("companyNickname")) {
                d.companyNickname = m.companyNickname;
            }
            if (m.userBtobUID != null && m.hasOwnProperty("userBtobUID")) {
                d.userBtobUID = m.userBtobUID;
            }
            if (m.userGroupList && m.userGroupList.length) {
                d.userGroupList = [];
                for (var j = 0; j < m.userGroupList.length; ++j) {
                    d.userGroupList[j] = $root.Proto.CompanyUserGroup.toObject(m.userGroupList[j], o);
                }
            }
            if (m.adminThirdOAuthType != null && m.hasOwnProperty("adminThirdOAuthType")) {
                d.adminThirdOAuthType = m.adminThirdOAuthType;
            }
            if (m.adminSubUserSetting != null && m.hasOwnProperty("adminSubUserSetting")) {
                d.adminSubUserSetting = m.adminSubUserSetting;
            }
            if (m.adminSubContentSetting != null && m.hasOwnProperty("adminSubContentSetting")) {
                d.adminSubContentSetting = m.adminSubContentSetting;
            }
            return d;
        };

        /**
         * Converts this User to JSON.
         * @function toJSON
         * @memberof Proto.User
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        User.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return User;
    })();

    Proto.UserList = (function() {

        /**
         * Properties of a UserList.
         * @memberof Proto
         * @interface IUserList
         * @property {Array.<Proto.IUser>|null} [value] UserList value
         */

        /**
         * Constructs a new UserList.
         * @memberof Proto
         * @classdesc Represents a UserList.
         * @implements IUserList
         * @constructor
         * @param {Proto.IUserList=} [p] Properties to set
         */
        function UserList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserList value.
         * @member {Array.<Proto.IUser>} value
         * @memberof Proto.UserList
         * @instance
         */
        UserList.prototype.value = $util.emptyArray;

        /**
         * Creates a new UserList instance using the specified properties.
         * @function create
         * @memberof Proto.UserList
         * @static
         * @param {Proto.IUserList=} [properties] Properties to set
         * @returns {Proto.UserList} UserList instance
         */
        UserList.create = function create(properties) {
            return new UserList(properties);
        };

        /**
         * Encodes the specified UserList message. Does not implicitly {@link Proto.UserList.verify|verify} messages.
         * @function encode
         * @memberof Proto.UserList
         * @static
         * @param {Proto.IUserList} m UserList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.User.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a UserList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.UserList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.UserList} UserList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.UserList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.User.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.UserList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.UserList} UserList
         */
        UserList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.UserList)
                return d;
            var m = new $root.Proto.UserList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.UserList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.UserList.value: object expected");
                    m.value[i] = $root.Proto.User.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a UserList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.UserList
         * @static
         * @param {Proto.UserList} m UserList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.User.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this UserList to JSON.
         * @function toJSON
         * @memberof Proto.UserList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserList;
    })();

    Proto.Company = (function() {

        /**
         * Properties of a Company.
         * @memberof Proto
         * @interface ICompany
         * @property {string|null} [uid] Company uid
         * @property {string|null} [name] Company name
         * @property {number|null} [planType] Company planType
         * @property {Proto.IUploadFile|null} [image] Company image
         * @property {number|Long|null} [registerDate] Company registerDate
         * @property {number|Long|null} [modifyDate] Company modifyDate
         * @property {number|null} [status] Company status
         * @property {string|null} [companyName] Company companyName
         * @property {string|null} [postalCode] Company postalCode
         * @property {string|null} [state] Company state
         * @property {string|null} [address] Company address
         * @property {string|null} [representer] Company representer
         * @property {boolean|null} [subscription] Company subscription
         * @property {Proto.ICompanyLogo|null} [logo] Company logo
         * @property {number|Long|null} [readCount] Company readCount
         * @property {string|null} [introCode] Company introCode
         * @property {string|null} [nickname] Company nickname
         * @property {string|null} [btobUID] Company btobUID
         * @property {string|null} [appName] Company appName
         * @property {boolean|null} [invoiceTransfer] Company invoiceTransfer
         * @property {string|null} [appIcon] Company appIcon
         * @property {number|null} [appTheme] Company appTheme
         * @property {number|null} [appHome] Company appHome
         * @property {string|null} [city] Company city
         * @property {string|null} [addressDetail] Company addressDetail
         * @property {number|null} [btobFeature] Company btobFeature
         * @property {Proto.ICompanyAppSetting|null} [appSetting] Company appSetting
         * @property {number|null} [ageRating] Company ageRating
         * @property {number|null} [userCountType] Company userCountType
         * @property {number|null} [lpDistribution] Company lpDistribution
         * @property {string|null} [profile] Company profile
         * @property {boolean|null} [hasCustomTier] Company hasCustomTier
         * @property {number|null} [thirdOAuthType] Company thirdOAuthType
         * @property {Proto.ICompanyThirdOAuth|null} [thirdOAuth] Company thirdOAuth
         */

        /**
         * Constructs a new Company.
         * @memberof Proto
         * @classdesc Represents a Company.
         * @implements ICompany
         * @constructor
         * @param {Proto.ICompany=} [p] Properties to set
         */
        function Company(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Company uid.
         * @member {string} uid
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.uid = "";

        /**
         * Company name.
         * @member {string} name
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.name = "";

        /**
         * Company planType.
         * @member {number} planType
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.planType = 0;

        /**
         * Company image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.image = null;

        /**
         * Company registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Company modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Company status.
         * @member {number} status
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.status = 0;

        /**
         * Company companyName.
         * @member {string} companyName
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.companyName = "";

        /**
         * Company postalCode.
         * @member {string} postalCode
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.postalCode = "";

        /**
         * Company state.
         * @member {string} state
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.state = "";

        /**
         * Company address.
         * @member {string} address
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.address = "";

        /**
         * Company representer.
         * @member {string} representer
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.representer = "";

        /**
         * Company subscription.
         * @member {boolean} subscription
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.subscription = false;

        /**
         * Company logo.
         * @member {Proto.ICompanyLogo|null|undefined} logo
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.logo = null;

        /**
         * Company readCount.
         * @member {number|Long} readCount
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.readCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Company introCode.
         * @member {string} introCode
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.introCode = "";

        /**
         * Company nickname.
         * @member {string} nickname
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.nickname = "";

        /**
         * Company btobUID.
         * @member {string} btobUID
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.btobUID = "";

        /**
         * Company appName.
         * @member {string} appName
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.appName = "";

        /**
         * Company invoiceTransfer.
         * @member {boolean} invoiceTransfer
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.invoiceTransfer = false;

        /**
         * Company appIcon.
         * @member {string} appIcon
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.appIcon = "";

        /**
         * Company appTheme.
         * @member {number} appTheme
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.appTheme = 0;

        /**
         * Company appHome.
         * @member {number} appHome
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.appHome = 0;

        /**
         * Company city.
         * @member {string} city
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.city = "";

        /**
         * Company addressDetail.
         * @member {string} addressDetail
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.addressDetail = "";

        /**
         * Company btobFeature.
         * @member {number} btobFeature
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.btobFeature = 0;

        /**
         * Company appSetting.
         * @member {Proto.ICompanyAppSetting|null|undefined} appSetting
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.appSetting = null;

        /**
         * Company ageRating.
         * @member {number} ageRating
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.ageRating = 0;

        /**
         * Company userCountType.
         * @member {number} userCountType
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.userCountType = 0;

        /**
         * Company lpDistribution.
         * @member {number} lpDistribution
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.lpDistribution = 0;

        /**
         * Company profile.
         * @member {string} profile
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.profile = "";

        /**
         * Company hasCustomTier.
         * @member {boolean} hasCustomTier
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.hasCustomTier = false;

        /**
         * Company thirdOAuthType.
         * @member {number} thirdOAuthType
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.thirdOAuthType = 0;

        /**
         * Company thirdOAuth.
         * @member {Proto.ICompanyThirdOAuth|null|undefined} thirdOAuth
         * @memberof Proto.Company
         * @instance
         */
        Company.prototype.thirdOAuth = null;

        /**
         * Creates a new Company instance using the specified properties.
         * @function create
         * @memberof Proto.Company
         * @static
         * @param {Proto.ICompany=} [properties] Properties to set
         * @returns {Proto.Company} Company instance
         */
        Company.create = function create(properties) {
            return new Company(properties);
        };

        /**
         * Encodes the specified Company message. Does not implicitly {@link Proto.Company.verify|verify} messages.
         * @function encode
         * @memberof Proto.Company
         * @static
         * @param {Proto.ICompany} m Company message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Company.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.planType != null && Object.hasOwnProperty.call(m, "planType"))
                w.uint32(24).int32(m.planType);
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(34).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(40).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(48).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(56).int32(m.status);
            if (m.companyName != null && Object.hasOwnProperty.call(m, "companyName"))
                w.uint32(66).string(m.companyName);
            if (m.postalCode != null && Object.hasOwnProperty.call(m, "postalCode"))
                w.uint32(74).string(m.postalCode);
            if (m.state != null && Object.hasOwnProperty.call(m, "state"))
                w.uint32(82).string(m.state);
            if (m.address != null && Object.hasOwnProperty.call(m, "address"))
                w.uint32(90).string(m.address);
            if (m.representer != null && Object.hasOwnProperty.call(m, "representer"))
                w.uint32(98).string(m.representer);
            if (m.subscription != null && Object.hasOwnProperty.call(m, "subscription"))
                w.uint32(104).bool(m.subscription);
            if (m.logo != null && Object.hasOwnProperty.call(m, "logo"))
                $root.Proto.CompanyLogo.encode(m.logo, w.uint32(114).fork()).ldelim();
            if (m.readCount != null && Object.hasOwnProperty.call(m, "readCount"))
                w.uint32(120).int64(m.readCount);
            if (m.introCode != null && Object.hasOwnProperty.call(m, "introCode"))
                w.uint32(130).string(m.introCode);
            if (m.nickname != null && Object.hasOwnProperty.call(m, "nickname"))
                w.uint32(138).string(m.nickname);
            if (m.btobUID != null && Object.hasOwnProperty.call(m, "btobUID"))
                w.uint32(146).string(m.btobUID);
            if (m.appName != null && Object.hasOwnProperty.call(m, "appName"))
                w.uint32(154).string(m.appName);
            if (m.invoiceTransfer != null && Object.hasOwnProperty.call(m, "invoiceTransfer"))
                w.uint32(160).bool(m.invoiceTransfer);
            if (m.appIcon != null && Object.hasOwnProperty.call(m, "appIcon"))
                w.uint32(170).string(m.appIcon);
            if (m.appTheme != null && Object.hasOwnProperty.call(m, "appTheme"))
                w.uint32(176).int32(m.appTheme);
            if (m.appHome != null && Object.hasOwnProperty.call(m, "appHome"))
                w.uint32(264).int32(m.appHome);
            if (m.city != null && Object.hasOwnProperty.call(m, "city"))
                w.uint32(274).string(m.city);
            if (m.addressDetail != null && Object.hasOwnProperty.call(m, "addressDetail"))
                w.uint32(282).string(m.addressDetail);
            if (m.btobFeature != null && Object.hasOwnProperty.call(m, "btobFeature"))
                w.uint32(288).int32(m.btobFeature);
            if (m.appSetting != null && Object.hasOwnProperty.call(m, "appSetting"))
                $root.Proto.CompanyAppSetting.encode(m.appSetting, w.uint32(298).fork()).ldelim();
            if (m.ageRating != null && Object.hasOwnProperty.call(m, "ageRating"))
                w.uint32(304).int32(m.ageRating);
            if (m.userCountType != null && Object.hasOwnProperty.call(m, "userCountType"))
                w.uint32(312).int32(m.userCountType);
            if (m.lpDistribution != null && Object.hasOwnProperty.call(m, "lpDistribution"))
                w.uint32(320).int32(m.lpDistribution);
            if (m.profile != null && Object.hasOwnProperty.call(m, "profile"))
                w.uint32(330).string(m.profile);
            if (m.hasCustomTier != null && Object.hasOwnProperty.call(m, "hasCustomTier"))
                w.uint32(336).bool(m.hasCustomTier);
            if (m.thirdOAuthType != null && Object.hasOwnProperty.call(m, "thirdOAuthType"))
                w.uint32(344).int32(m.thirdOAuthType);
            if (m.thirdOAuth != null && Object.hasOwnProperty.call(m, "thirdOAuth"))
                $root.Proto.CompanyThirdOAuth.encode(m.thirdOAuth, w.uint32(354).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a Company message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.Company
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.Company} Company
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Company.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.Company();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.name = r.string();
                    break;
                case 3:
                    m.planType = r.int32();
                    break;
                case 4:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 5:
                    m.registerDate = r.int64();
                    break;
                case 6:
                    m.modifyDate = r.int64();
                    break;
                case 7:
                    m.status = r.int32();
                    break;
                case 8:
                    m.companyName = r.string();
                    break;
                case 9:
                    m.postalCode = r.string();
                    break;
                case 10:
                    m.state = r.string();
                    break;
                case 11:
                    m.address = r.string();
                    break;
                case 12:
                    m.representer = r.string();
                    break;
                case 13:
                    m.subscription = r.bool();
                    break;
                case 14:
                    m.logo = $root.Proto.CompanyLogo.decode(r, r.uint32());
                    break;
                case 15:
                    m.readCount = r.int64();
                    break;
                case 16:
                    m.introCode = r.string();
                    break;
                case 17:
                    m.nickname = r.string();
                    break;
                case 18:
                    m.btobUID = r.string();
                    break;
                case 19:
                    m.appName = r.string();
                    break;
                case 20:
                    m.invoiceTransfer = r.bool();
                    break;
                case 21:
                    m.appIcon = r.string();
                    break;
                case 22:
                    m.appTheme = r.int32();
                    break;
                case 33:
                    m.appHome = r.int32();
                    break;
                case 34:
                    m.city = r.string();
                    break;
                case 35:
                    m.addressDetail = r.string();
                    break;
                case 36:
                    m.btobFeature = r.int32();
                    break;
                case 37:
                    m.appSetting = $root.Proto.CompanyAppSetting.decode(r, r.uint32());
                    break;
                case 38:
                    m.ageRating = r.int32();
                    break;
                case 39:
                    m.userCountType = r.int32();
                    break;
                case 40:
                    m.lpDistribution = r.int32();
                    break;
                case 41:
                    m.profile = r.string();
                    break;
                case 42:
                    m.hasCustomTier = r.bool();
                    break;
                case 43:
                    m.thirdOAuthType = r.int32();
                    break;
                case 44:
                    m.thirdOAuth = $root.Proto.CompanyThirdOAuth.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Company message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.Company
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.Company} Company
         */
        Company.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.Company)
                return d;
            var m = new $root.Proto.Company();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.planType != null) {
                m.planType = d.planType | 0;
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.Company.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.companyName != null) {
                m.companyName = String(d.companyName);
            }
            if (d.postalCode != null) {
                m.postalCode = String(d.postalCode);
            }
            if (d.state != null) {
                m.state = String(d.state);
            }
            if (d.address != null) {
                m.address = String(d.address);
            }
            if (d.representer != null) {
                m.representer = String(d.representer);
            }
            if (d.subscription != null) {
                m.subscription = Boolean(d.subscription);
            }
            if (d.logo != null) {
                if (typeof d.logo !== "object")
                    throw TypeError(".Proto.Company.logo: object expected");
                m.logo = $root.Proto.CompanyLogo.fromObject(d.logo);
            }
            if (d.readCount != null) {
                if ($util.Long)
                    (m.readCount = $util.Long.fromValue(d.readCount)).unsigned = false;
                else if (typeof d.readCount === "string")
                    m.readCount = parseInt(d.readCount, 10);
                else if (typeof d.readCount === "number")
                    m.readCount = d.readCount;
                else if (typeof d.readCount === "object")
                    m.readCount = new $util.LongBits(d.readCount.low >>> 0, d.readCount.high >>> 0).toNumber();
            }
            if (d.introCode != null) {
                m.introCode = String(d.introCode);
            }
            if (d.nickname != null) {
                m.nickname = String(d.nickname);
            }
            if (d.btobUID != null) {
                m.btobUID = String(d.btobUID);
            }
            if (d.appName != null) {
                m.appName = String(d.appName);
            }
            if (d.invoiceTransfer != null) {
                m.invoiceTransfer = Boolean(d.invoiceTransfer);
            }
            if (d.appIcon != null) {
                m.appIcon = String(d.appIcon);
            }
            if (d.appTheme != null) {
                m.appTheme = d.appTheme | 0;
            }
            if (d.appHome != null) {
                m.appHome = d.appHome | 0;
            }
            if (d.city != null) {
                m.city = String(d.city);
            }
            if (d.addressDetail != null) {
                m.addressDetail = String(d.addressDetail);
            }
            if (d.btobFeature != null) {
                m.btobFeature = d.btobFeature | 0;
            }
            if (d.appSetting != null) {
                if (typeof d.appSetting !== "object")
                    throw TypeError(".Proto.Company.appSetting: object expected");
                m.appSetting = $root.Proto.CompanyAppSetting.fromObject(d.appSetting);
            }
            if (d.ageRating != null) {
                m.ageRating = d.ageRating | 0;
            }
            if (d.userCountType != null) {
                m.userCountType = d.userCountType | 0;
            }
            if (d.lpDistribution != null) {
                m.lpDistribution = d.lpDistribution | 0;
            }
            if (d.profile != null) {
                m.profile = String(d.profile);
            }
            if (d.hasCustomTier != null) {
                m.hasCustomTier = Boolean(d.hasCustomTier);
            }
            if (d.thirdOAuthType != null) {
                m.thirdOAuthType = d.thirdOAuthType | 0;
            }
            if (d.thirdOAuth != null) {
                if (typeof d.thirdOAuth !== "object")
                    throw TypeError(".Proto.Company.thirdOAuth: object expected");
                m.thirdOAuth = $root.Proto.CompanyThirdOAuth.fromObject(d.thirdOAuth);
            }
            return m;
        };

        /**
         * Creates a plain object from a Company message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.Company
         * @static
         * @param {Proto.Company} m Company
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Company.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.name = "";
                d.planType = 0;
                d.image = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.companyName = "";
                d.postalCode = "";
                d.state = "";
                d.address = "";
                d.representer = "";
                d.subscription = false;
                d.logo = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.readCount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.readCount = o.longs === String ? "0" : 0;
                d.introCode = "";
                d.nickname = "";
                d.btobUID = "";
                d.appName = "";
                d.invoiceTransfer = false;
                d.appIcon = "";
                d.appTheme = 0;
                d.appHome = 0;
                d.city = "";
                d.addressDetail = "";
                d.btobFeature = 0;
                d.appSetting = null;
                d.ageRating = 0;
                d.userCountType = 0;
                d.lpDistribution = 0;
                d.profile = "";
                d.hasCustomTier = false;
                d.thirdOAuthType = 0;
                d.thirdOAuth = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.planType != null && m.hasOwnProperty("planType")) {
                d.planType = m.planType;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.companyName != null && m.hasOwnProperty("companyName")) {
                d.companyName = m.companyName;
            }
            if (m.postalCode != null && m.hasOwnProperty("postalCode")) {
                d.postalCode = m.postalCode;
            }
            if (m.state != null && m.hasOwnProperty("state")) {
                d.state = m.state;
            }
            if (m.address != null && m.hasOwnProperty("address")) {
                d.address = m.address;
            }
            if (m.representer != null && m.hasOwnProperty("representer")) {
                d.representer = m.representer;
            }
            if (m.subscription != null && m.hasOwnProperty("subscription")) {
                d.subscription = m.subscription;
            }
            if (m.logo != null && m.hasOwnProperty("logo")) {
                d.logo = $root.Proto.CompanyLogo.toObject(m.logo, o);
            }
            if (m.readCount != null && m.hasOwnProperty("readCount")) {
                if (typeof m.readCount === "number")
                    d.readCount = o.longs === String ? String(m.readCount) : m.readCount;
                else
                    d.readCount = o.longs === String ? $util.Long.prototype.toString.call(m.readCount) : o.longs === Number ? new $util.LongBits(m.readCount.low >>> 0, m.readCount.high >>> 0).toNumber() : m.readCount;
            }
            if (m.introCode != null && m.hasOwnProperty("introCode")) {
                d.introCode = m.introCode;
            }
            if (m.nickname != null && m.hasOwnProperty("nickname")) {
                d.nickname = m.nickname;
            }
            if (m.btobUID != null && m.hasOwnProperty("btobUID")) {
                d.btobUID = m.btobUID;
            }
            if (m.appName != null && m.hasOwnProperty("appName")) {
                d.appName = m.appName;
            }
            if (m.invoiceTransfer != null && m.hasOwnProperty("invoiceTransfer")) {
                d.invoiceTransfer = m.invoiceTransfer;
            }
            if (m.appIcon != null && m.hasOwnProperty("appIcon")) {
                d.appIcon = m.appIcon;
            }
            if (m.appTheme != null && m.hasOwnProperty("appTheme")) {
                d.appTheme = m.appTheme;
            }
            if (m.appHome != null && m.hasOwnProperty("appHome")) {
                d.appHome = m.appHome;
            }
            if (m.city != null && m.hasOwnProperty("city")) {
                d.city = m.city;
            }
            if (m.addressDetail != null && m.hasOwnProperty("addressDetail")) {
                d.addressDetail = m.addressDetail;
            }
            if (m.btobFeature != null && m.hasOwnProperty("btobFeature")) {
                d.btobFeature = m.btobFeature;
            }
            if (m.appSetting != null && m.hasOwnProperty("appSetting")) {
                d.appSetting = $root.Proto.CompanyAppSetting.toObject(m.appSetting, o);
            }
            if (m.ageRating != null && m.hasOwnProperty("ageRating")) {
                d.ageRating = m.ageRating;
            }
            if (m.userCountType != null && m.hasOwnProperty("userCountType")) {
                d.userCountType = m.userCountType;
            }
            if (m.lpDistribution != null && m.hasOwnProperty("lpDistribution")) {
                d.lpDistribution = m.lpDistribution;
            }
            if (m.profile != null && m.hasOwnProperty("profile")) {
                d.profile = m.profile;
            }
            if (m.hasCustomTier != null && m.hasOwnProperty("hasCustomTier")) {
                d.hasCustomTier = m.hasCustomTier;
            }
            if (m.thirdOAuthType != null && m.hasOwnProperty("thirdOAuthType")) {
                d.thirdOAuthType = m.thirdOAuthType;
            }
            if (m.thirdOAuth != null && m.hasOwnProperty("thirdOAuth")) {
                d.thirdOAuth = $root.Proto.CompanyThirdOAuth.toObject(m.thirdOAuth, o);
            }
            return d;
        };

        /**
         * Converts this Company to JSON.
         * @function toJSON
         * @memberof Proto.Company
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Company.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Company;
    })();

    Proto.CompanyList = (function() {

        /**
         * Properties of a CompanyList.
         * @memberof Proto
         * @interface ICompanyList
         * @property {Array.<Proto.ICompany>|null} [value] CompanyList value
         */

        /**
         * Constructs a new CompanyList.
         * @memberof Proto
         * @classdesc Represents a CompanyList.
         * @implements ICompanyList
         * @constructor
         * @param {Proto.ICompanyList=} [p] Properties to set
         */
        function CompanyList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyList value.
         * @member {Array.<Proto.ICompany>} value
         * @memberof Proto.CompanyList
         * @instance
         */
        CompanyList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CompanyList instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyList
         * @static
         * @param {Proto.ICompanyList=} [properties] Properties to set
         * @returns {Proto.CompanyList} CompanyList instance
         */
        CompanyList.create = function create(properties) {
            return new CompanyList(properties);
        };

        /**
         * Encodes the specified CompanyList message. Does not implicitly {@link Proto.CompanyList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyList
         * @static
         * @param {Proto.ICompanyList} m CompanyList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.Company.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CompanyList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyList} CompanyList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.Company.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyList} CompanyList
         */
        CompanyList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyList)
                return d;
            var m = new $root.Proto.CompanyList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CompanyList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CompanyList.value: object expected");
                    m.value[i] = $root.Proto.Company.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyList
         * @static
         * @param {Proto.CompanyList} m CompanyList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.Company.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CompanyList to JSON.
         * @function toJSON
         * @memberof Proto.CompanyList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyList;
    })();

    Proto.CompanyThirdOAuth = (function() {

        /**
         * Properties of a CompanyThirdOAuth.
         * @memberof Proto
         * @interface ICompanyThirdOAuth
         * @property {number|null} [oauthType] CompanyThirdOAuth oauthType
         * @property {boolean|null} [register] CompanyThirdOAuth register
         * @property {boolean|null} [hasPlan] CompanyThirdOAuth hasPlan
         * @property {number|Long|null} [registerDate] CompanyThirdOAuth registerDate
         * @property {number|Long|null} [modifyDate] CompanyThirdOAuth modifyDate
         */

        /**
         * Constructs a new CompanyThirdOAuth.
         * @memberof Proto
         * @classdesc Represents a CompanyThirdOAuth.
         * @implements ICompanyThirdOAuth
         * @constructor
         * @param {Proto.ICompanyThirdOAuth=} [p] Properties to set
         */
        function CompanyThirdOAuth(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyThirdOAuth oauthType.
         * @member {number} oauthType
         * @memberof Proto.CompanyThirdOAuth
         * @instance
         */
        CompanyThirdOAuth.prototype.oauthType = 0;

        /**
         * CompanyThirdOAuth register.
         * @member {boolean} register
         * @memberof Proto.CompanyThirdOAuth
         * @instance
         */
        CompanyThirdOAuth.prototype.register = false;

        /**
         * CompanyThirdOAuth hasPlan.
         * @member {boolean} hasPlan
         * @memberof Proto.CompanyThirdOAuth
         * @instance
         */
        CompanyThirdOAuth.prototype.hasPlan = false;

        /**
         * CompanyThirdOAuth registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CompanyThirdOAuth
         * @instance
         */
        CompanyThirdOAuth.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyThirdOAuth modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CompanyThirdOAuth
         * @instance
         */
        CompanyThirdOAuth.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new CompanyThirdOAuth instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyThirdOAuth
         * @static
         * @param {Proto.ICompanyThirdOAuth=} [properties] Properties to set
         * @returns {Proto.CompanyThirdOAuth} CompanyThirdOAuth instance
         */
        CompanyThirdOAuth.create = function create(properties) {
            return new CompanyThirdOAuth(properties);
        };

        /**
         * Encodes the specified CompanyThirdOAuth message. Does not implicitly {@link Proto.CompanyThirdOAuth.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyThirdOAuth
         * @static
         * @param {Proto.ICompanyThirdOAuth} m CompanyThirdOAuth message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyThirdOAuth.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.oauthType != null && Object.hasOwnProperty.call(m, "oauthType"))
                w.uint32(8).int32(m.oauthType);
            if (m.register != null && Object.hasOwnProperty.call(m, "register"))
                w.uint32(16).bool(m.register);
            if (m.hasPlan != null && Object.hasOwnProperty.call(m, "hasPlan"))
                w.uint32(24).bool(m.hasPlan);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a CompanyThirdOAuth message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyThirdOAuth
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyThirdOAuth} CompanyThirdOAuth
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyThirdOAuth.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyThirdOAuth();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.oauthType = r.int32();
                    break;
                case 2:
                    m.register = r.bool();
                    break;
                case 3:
                    m.hasPlan = r.bool();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyThirdOAuth message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyThirdOAuth
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyThirdOAuth} CompanyThirdOAuth
         */
        CompanyThirdOAuth.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyThirdOAuth)
                return d;
            var m = new $root.Proto.CompanyThirdOAuth();
            if (d.oauthType != null) {
                m.oauthType = d.oauthType | 0;
            }
            if (d.register != null) {
                m.register = Boolean(d.register);
            }
            if (d.hasPlan != null) {
                m.hasPlan = Boolean(d.hasPlan);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyThirdOAuth message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyThirdOAuth
         * @static
         * @param {Proto.CompanyThirdOAuth} m CompanyThirdOAuth
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyThirdOAuth.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.oauthType = 0;
                d.register = false;
                d.hasPlan = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.oauthType != null && m.hasOwnProperty("oauthType")) {
                d.oauthType = m.oauthType;
            }
            if (m.register != null && m.hasOwnProperty("register")) {
                d.register = m.register;
            }
            if (m.hasPlan != null && m.hasOwnProperty("hasPlan")) {
                d.hasPlan = m.hasPlan;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this CompanyThirdOAuth to JSON.
         * @function toJSON
         * @memberof Proto.CompanyThirdOAuth
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyThirdOAuth.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyThirdOAuth;
    })();

    Proto.CompanyLogo = (function() {

        /**
         * Properties of a CompanyLogo.
         * @memberof Proto
         * @interface ICompanyLogo
         * @property {string|null} [companyUID] CompanyLogo companyUID
         * @property {number|null} [type] CompanyLogo type
         * @property {string|null} [text] CompanyLogo text
         * @property {string|null} [bg] CompanyLogo bg
         * @property {Proto.IUploadFile|null} [image] CompanyLogo image
         * @property {number|Long|null} [registerDate] CompanyLogo registerDate
         * @property {number|Long|null} [modifyDate] CompanyLogo modifyDate
         * @property {string|null} [textColor] CompanyLogo textColor
         * @property {string|null} [appBg] CompanyLogo appBg
         * @property {string|null} [appTextColor] CompanyLogo appTextColor
         * @property {number|null} [appType] CompanyLogo appType
         * @property {string|null} [appText] CompanyLogo appText
         * @property {Proto.IUploadFile|null} [appImage] CompanyLogo appImage
         * @property {number|null} [appLogoWidth] CompanyLogo appLogoWidth
         */

        /**
         * Constructs a new CompanyLogo.
         * @memberof Proto
         * @classdesc Represents a CompanyLogo.
         * @implements ICompanyLogo
         * @constructor
         * @param {Proto.ICompanyLogo=} [p] Properties to set
         */
        function CompanyLogo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyLogo companyUID.
         * @member {string} companyUID
         * @memberof Proto.CompanyLogo
         * @instance
         */
        CompanyLogo.prototype.companyUID = "";

        /**
         * CompanyLogo type.
         * @member {number} type
         * @memberof Proto.CompanyLogo
         * @instance
         */
        CompanyLogo.prototype.type = 0;

        /**
         * CompanyLogo text.
         * @member {string} text
         * @memberof Proto.CompanyLogo
         * @instance
         */
        CompanyLogo.prototype.text = "";

        /**
         * CompanyLogo bg.
         * @member {string} bg
         * @memberof Proto.CompanyLogo
         * @instance
         */
        CompanyLogo.prototype.bg = "";

        /**
         * CompanyLogo image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.CompanyLogo
         * @instance
         */
        CompanyLogo.prototype.image = null;

        /**
         * CompanyLogo registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CompanyLogo
         * @instance
         */
        CompanyLogo.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyLogo modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CompanyLogo
         * @instance
         */
        CompanyLogo.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyLogo textColor.
         * @member {string} textColor
         * @memberof Proto.CompanyLogo
         * @instance
         */
        CompanyLogo.prototype.textColor = "";

        /**
         * CompanyLogo appBg.
         * @member {string} appBg
         * @memberof Proto.CompanyLogo
         * @instance
         */
        CompanyLogo.prototype.appBg = "";

        /**
         * CompanyLogo appTextColor.
         * @member {string} appTextColor
         * @memberof Proto.CompanyLogo
         * @instance
         */
        CompanyLogo.prototype.appTextColor = "";

        /**
         * CompanyLogo appType.
         * @member {number} appType
         * @memberof Proto.CompanyLogo
         * @instance
         */
        CompanyLogo.prototype.appType = 0;

        /**
         * CompanyLogo appText.
         * @member {string} appText
         * @memberof Proto.CompanyLogo
         * @instance
         */
        CompanyLogo.prototype.appText = "";

        /**
         * CompanyLogo appImage.
         * @member {Proto.IUploadFile|null|undefined} appImage
         * @memberof Proto.CompanyLogo
         * @instance
         */
        CompanyLogo.prototype.appImage = null;

        /**
         * CompanyLogo appLogoWidth.
         * @member {number} appLogoWidth
         * @memberof Proto.CompanyLogo
         * @instance
         */
        CompanyLogo.prototype.appLogoWidth = 0;

        /**
         * Creates a new CompanyLogo instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyLogo
         * @static
         * @param {Proto.ICompanyLogo=} [properties] Properties to set
         * @returns {Proto.CompanyLogo} CompanyLogo instance
         */
        CompanyLogo.create = function create(properties) {
            return new CompanyLogo(properties);
        };

        /**
         * Encodes the specified CompanyLogo message. Does not implicitly {@link Proto.CompanyLogo.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyLogo
         * @static
         * @param {Proto.ICompanyLogo} m CompanyLogo message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyLogo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(10).string(m.companyUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.bg != null && Object.hasOwnProperty.call(m, "bg"))
                w.uint32(34).string(m.bg);
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(42).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.textColor != null && Object.hasOwnProperty.call(m, "textColor"))
                w.uint32(66).string(m.textColor);
            if (m.appBg != null && Object.hasOwnProperty.call(m, "appBg"))
                w.uint32(74).string(m.appBg);
            if (m.appTextColor != null && Object.hasOwnProperty.call(m, "appTextColor"))
                w.uint32(82).string(m.appTextColor);
            if (m.appType != null && Object.hasOwnProperty.call(m, "appType"))
                w.uint32(88).int32(m.appType);
            if (m.appText != null && Object.hasOwnProperty.call(m, "appText"))
                w.uint32(98).string(m.appText);
            if (m.appImage != null && Object.hasOwnProperty.call(m, "appImage"))
                $root.Proto.UploadFile.encode(m.appImage, w.uint32(106).fork()).ldelim();
            if (m.appLogoWidth != null && Object.hasOwnProperty.call(m, "appLogoWidth"))
                w.uint32(112).int32(m.appLogoWidth);
            return w;
        };

        /**
         * Decodes a CompanyLogo message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyLogo
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyLogo} CompanyLogo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyLogo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyLogo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.companyUID = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.bg = r.string();
                    break;
                case 5:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.textColor = r.string();
                    break;
                case 9:
                    m.appBg = r.string();
                    break;
                case 10:
                    m.appTextColor = r.string();
                    break;
                case 11:
                    m.appType = r.int32();
                    break;
                case 12:
                    m.appText = r.string();
                    break;
                case 13:
                    m.appImage = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 14:
                    m.appLogoWidth = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyLogo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyLogo
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyLogo} CompanyLogo
         */
        CompanyLogo.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyLogo)
                return d;
            var m = new $root.Proto.CompanyLogo();
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.bg != null) {
                m.bg = String(d.bg);
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.CompanyLogo.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.textColor != null) {
                m.textColor = String(d.textColor);
            }
            if (d.appBg != null) {
                m.appBg = String(d.appBg);
            }
            if (d.appTextColor != null) {
                m.appTextColor = String(d.appTextColor);
            }
            if (d.appType != null) {
                m.appType = d.appType | 0;
            }
            if (d.appText != null) {
                m.appText = String(d.appText);
            }
            if (d.appImage != null) {
                if (typeof d.appImage !== "object")
                    throw TypeError(".Proto.CompanyLogo.appImage: object expected");
                m.appImage = $root.Proto.UploadFile.fromObject(d.appImage);
            }
            if (d.appLogoWidth != null) {
                m.appLogoWidth = d.appLogoWidth | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyLogo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyLogo
         * @static
         * @param {Proto.CompanyLogo} m CompanyLogo
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyLogo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.companyUID = "";
                d.type = 0;
                d.text = "";
                d.bg = "";
                d.image = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.textColor = "";
                d.appBg = "";
                d.appTextColor = "";
                d.appType = 0;
                d.appText = "";
                d.appImage = null;
                d.appLogoWidth = 0;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.bg != null && m.hasOwnProperty("bg")) {
                d.bg = m.bg;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.textColor != null && m.hasOwnProperty("textColor")) {
                d.textColor = m.textColor;
            }
            if (m.appBg != null && m.hasOwnProperty("appBg")) {
                d.appBg = m.appBg;
            }
            if (m.appTextColor != null && m.hasOwnProperty("appTextColor")) {
                d.appTextColor = m.appTextColor;
            }
            if (m.appType != null && m.hasOwnProperty("appType")) {
                d.appType = m.appType;
            }
            if (m.appText != null && m.hasOwnProperty("appText")) {
                d.appText = m.appText;
            }
            if (m.appImage != null && m.hasOwnProperty("appImage")) {
                d.appImage = $root.Proto.UploadFile.toObject(m.appImage, o);
            }
            if (m.appLogoWidth != null && m.hasOwnProperty("appLogoWidth")) {
                d.appLogoWidth = m.appLogoWidth;
            }
            return d;
        };

        /**
         * Converts this CompanyLogo to JSON.
         * @function toJSON
         * @memberof Proto.CompanyLogo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyLogo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyLogo;
    })();

    Proto.CompanyLPFavicon = (function() {

        /**
         * Properties of a CompanyLPFavicon.
         * @memberof Proto
         * @interface ICompanyLPFavicon
         * @property {string|null} [companyUID] CompanyLPFavicon companyUID
         * @property {Proto.IUploadFile|null} [ico] CompanyLPFavicon ico
         * @property {Proto.IUploadFile|null} [png] CompanyLPFavicon png
         * @property {Proto.IUploadFile|null} [mask] CompanyLPFavicon mask
         * @property {string|null} [maskColor] CompanyLPFavicon maskColor
         * @property {number|Long|null} [registerDate] CompanyLPFavicon registerDate
         * @property {number|Long|null} [modifyDate] CompanyLPFavicon modifyDate
         * @property {number|null} [status] CompanyLPFavicon status
         */

        /**
         * Constructs a new CompanyLPFavicon.
         * @memberof Proto
         * @classdesc Represents a CompanyLPFavicon.
         * @implements ICompanyLPFavicon
         * @constructor
         * @param {Proto.ICompanyLPFavicon=} [p] Properties to set
         */
        function CompanyLPFavicon(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyLPFavicon companyUID.
         * @member {string} companyUID
         * @memberof Proto.CompanyLPFavicon
         * @instance
         */
        CompanyLPFavicon.prototype.companyUID = "";

        /**
         * CompanyLPFavicon ico.
         * @member {Proto.IUploadFile|null|undefined} ico
         * @memberof Proto.CompanyLPFavicon
         * @instance
         */
        CompanyLPFavicon.prototype.ico = null;

        /**
         * CompanyLPFavicon png.
         * @member {Proto.IUploadFile|null|undefined} png
         * @memberof Proto.CompanyLPFavicon
         * @instance
         */
        CompanyLPFavicon.prototype.png = null;

        /**
         * CompanyLPFavicon mask.
         * @member {Proto.IUploadFile|null|undefined} mask
         * @memberof Proto.CompanyLPFavicon
         * @instance
         */
        CompanyLPFavicon.prototype.mask = null;

        /**
         * CompanyLPFavicon maskColor.
         * @member {string} maskColor
         * @memberof Proto.CompanyLPFavicon
         * @instance
         */
        CompanyLPFavicon.prototype.maskColor = "";

        /**
         * CompanyLPFavicon registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CompanyLPFavicon
         * @instance
         */
        CompanyLPFavicon.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyLPFavicon modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CompanyLPFavicon
         * @instance
         */
        CompanyLPFavicon.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyLPFavicon status.
         * @member {number} status
         * @memberof Proto.CompanyLPFavicon
         * @instance
         */
        CompanyLPFavicon.prototype.status = 0;

        /**
         * Creates a new CompanyLPFavicon instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyLPFavicon
         * @static
         * @param {Proto.ICompanyLPFavicon=} [properties] Properties to set
         * @returns {Proto.CompanyLPFavicon} CompanyLPFavicon instance
         */
        CompanyLPFavicon.create = function create(properties) {
            return new CompanyLPFavicon(properties);
        };

        /**
         * Encodes the specified CompanyLPFavicon message. Does not implicitly {@link Proto.CompanyLPFavicon.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyLPFavicon
         * @static
         * @param {Proto.ICompanyLPFavicon} m CompanyLPFavicon message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyLPFavicon.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(10).string(m.companyUID);
            if (m.ico != null && Object.hasOwnProperty.call(m, "ico"))
                $root.Proto.UploadFile.encode(m.ico, w.uint32(18).fork()).ldelim();
            if (m.png != null && Object.hasOwnProperty.call(m, "png"))
                $root.Proto.UploadFile.encode(m.png, w.uint32(26).fork()).ldelim();
            if (m.mask != null && Object.hasOwnProperty.call(m, "mask"))
                $root.Proto.UploadFile.encode(m.mask, w.uint32(34).fork()).ldelim();
            if (m.maskColor != null && Object.hasOwnProperty.call(m, "maskColor"))
                w.uint32(42).string(m.maskColor);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(64).int32(m.status);
            return w;
        };

        /**
         * Decodes a CompanyLPFavicon message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyLPFavicon
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyLPFavicon} CompanyLPFavicon
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyLPFavicon.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyLPFavicon();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.companyUID = r.string();
                    break;
                case 2:
                    m.ico = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 3:
                    m.png = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 4:
                    m.mask = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 5:
                    m.maskColor = r.string();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyLPFavicon message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyLPFavicon
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyLPFavicon} CompanyLPFavicon
         */
        CompanyLPFavicon.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyLPFavicon)
                return d;
            var m = new $root.Proto.CompanyLPFavicon();
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.ico != null) {
                if (typeof d.ico !== "object")
                    throw TypeError(".Proto.CompanyLPFavicon.ico: object expected");
                m.ico = $root.Proto.UploadFile.fromObject(d.ico);
            }
            if (d.png != null) {
                if (typeof d.png !== "object")
                    throw TypeError(".Proto.CompanyLPFavicon.png: object expected");
                m.png = $root.Proto.UploadFile.fromObject(d.png);
            }
            if (d.mask != null) {
                if (typeof d.mask !== "object")
                    throw TypeError(".Proto.CompanyLPFavicon.mask: object expected");
                m.mask = $root.Proto.UploadFile.fromObject(d.mask);
            }
            if (d.maskColor != null) {
                m.maskColor = String(d.maskColor);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyLPFavicon message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyLPFavicon
         * @static
         * @param {Proto.CompanyLPFavicon} m CompanyLPFavicon
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyLPFavicon.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.companyUID = "";
                d.ico = null;
                d.png = null;
                d.mask = null;
                d.maskColor = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.ico != null && m.hasOwnProperty("ico")) {
                d.ico = $root.Proto.UploadFile.toObject(m.ico, o);
            }
            if (m.png != null && m.hasOwnProperty("png")) {
                d.png = $root.Proto.UploadFile.toObject(m.png, o);
            }
            if (m.mask != null && m.hasOwnProperty("mask")) {
                d.mask = $root.Proto.UploadFile.toObject(m.mask, o);
            }
            if (m.maskColor != null && m.hasOwnProperty("maskColor")) {
                d.maskColor = m.maskColor;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this CompanyLPFavicon to JSON.
         * @function toJSON
         * @memberof Proto.CompanyLPFavicon
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyLPFavicon.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyLPFavicon;
    })();

    Proto.CompanyLP = (function() {

        /**
         * Properties of a CompanyLP.
         * @memberof Proto
         * @interface ICompanyLP
         * @property {string|null} [companyUID] CompanyLP companyUID
         * @property {string|null} [content] CompanyLP content
         * @property {string|null} [subdomain] CompanyLP subdomain
         * @property {boolean|null} [custom] CompanyLP custom
         * @property {string|null} [customDomain] CompanyLP customDomain
         * @property {number|null} [distribution] CompanyLP distribution
         * @property {number|Long|null} [registerDate] CompanyLP registerDate
         * @property {number|Long|null} [modifyDate] CompanyLP modifyDate
         * @property {string|null} [name] CompanyLP name
         * @property {number|null} [trialDay] CompanyLP trialDay
         * @property {number|null} [ageRating] CompanyLP ageRating
         * @property {string|null} [description] CompanyLP description
         * @property {number|null} [requiredContactLogin] CompanyLP requiredContactLogin
         * @property {number|null} [appTheme] CompanyLP appTheme
         * @property {string|null} [receiptName] CompanyLP receiptName
         * @property {string|null} [receiptPostal] CompanyLP receiptPostal
         * @property {string|null} [receiptState] CompanyLP receiptState
         * @property {string|null} [receiptAddr] CompanyLP receiptAddr
         * @property {number|null} [appHome] CompanyLP appHome
         * @property {boolean|null} [useCustomDescription] CompanyLP useCustomDescription
         * @property {string|null} [customDescription] CompanyLP customDescription
         * @property {boolean|null} [noIndex] CompanyLP noIndex
         * @property {string|null} [gtmId] CompanyLP gtmId
         * @property {Proto.ICompanyLPFavicon|null} [favicon] CompanyLP favicon
         * @property {boolean|null} [autoSsl] CompanyLP autoSsl
         * @property {string|null} [customMetaTag] CompanyLP customMetaTag
         * @property {number|null} [loginPage] CompanyLP loginPage
         * @property {Proto.IUploadFile|null} [loginPageImage] CompanyLP loginPageImage
         */

        /**
         * Constructs a new CompanyLP.
         * @memberof Proto
         * @classdesc Represents a CompanyLP.
         * @implements ICompanyLP
         * @constructor
         * @param {Proto.ICompanyLP=} [p] Properties to set
         */
        function CompanyLP(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyLP companyUID.
         * @member {string} companyUID
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.companyUID = "";

        /**
         * CompanyLP content.
         * @member {string} content
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.content = "";

        /**
         * CompanyLP subdomain.
         * @member {string} subdomain
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.subdomain = "";

        /**
         * CompanyLP custom.
         * @member {boolean} custom
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.custom = false;

        /**
         * CompanyLP customDomain.
         * @member {string} customDomain
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.customDomain = "";

        /**
         * CompanyLP distribution.
         * @member {number} distribution
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.distribution = 0;

        /**
         * CompanyLP registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyLP modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyLP name.
         * @member {string} name
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.name = "";

        /**
         * CompanyLP trialDay.
         * @member {number} trialDay
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.trialDay = 0;

        /**
         * CompanyLP ageRating.
         * @member {number} ageRating
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.ageRating = 0;

        /**
         * CompanyLP description.
         * @member {string} description
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.description = "";

        /**
         * CompanyLP requiredContactLogin.
         * @member {number} requiredContactLogin
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.requiredContactLogin = 0;

        /**
         * CompanyLP appTheme.
         * @member {number} appTheme
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.appTheme = 0;

        /**
         * CompanyLP receiptName.
         * @member {string} receiptName
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.receiptName = "";

        /**
         * CompanyLP receiptPostal.
         * @member {string} receiptPostal
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.receiptPostal = "";

        /**
         * CompanyLP receiptState.
         * @member {string} receiptState
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.receiptState = "";

        /**
         * CompanyLP receiptAddr.
         * @member {string} receiptAddr
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.receiptAddr = "";

        /**
         * CompanyLP appHome.
         * @member {number} appHome
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.appHome = 0;

        /**
         * CompanyLP useCustomDescription.
         * @member {boolean} useCustomDescription
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.useCustomDescription = false;

        /**
         * CompanyLP customDescription.
         * @member {string} customDescription
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.customDescription = "";

        /**
         * CompanyLP noIndex.
         * @member {boolean} noIndex
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.noIndex = false;

        /**
         * CompanyLP gtmId.
         * @member {string} gtmId
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.gtmId = "";

        /**
         * CompanyLP favicon.
         * @member {Proto.ICompanyLPFavicon|null|undefined} favicon
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.favicon = null;

        /**
         * CompanyLP autoSsl.
         * @member {boolean} autoSsl
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.autoSsl = false;

        /**
         * CompanyLP customMetaTag.
         * @member {string} customMetaTag
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.customMetaTag = "";

        /**
         * CompanyLP loginPage.
         * @member {number} loginPage
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.loginPage = 0;

        /**
         * CompanyLP loginPageImage.
         * @member {Proto.IUploadFile|null|undefined} loginPageImage
         * @memberof Proto.CompanyLP
         * @instance
         */
        CompanyLP.prototype.loginPageImage = null;

        /**
         * Creates a new CompanyLP instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyLP
         * @static
         * @param {Proto.ICompanyLP=} [properties] Properties to set
         * @returns {Proto.CompanyLP} CompanyLP instance
         */
        CompanyLP.create = function create(properties) {
            return new CompanyLP(properties);
        };

        /**
         * Encodes the specified CompanyLP message. Does not implicitly {@link Proto.CompanyLP.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyLP
         * @static
         * @param {Proto.ICompanyLP} m CompanyLP message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyLP.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(10).string(m.companyUID);
            if (m.content != null && Object.hasOwnProperty.call(m, "content"))
                w.uint32(18).string(m.content);
            if (m.subdomain != null && Object.hasOwnProperty.call(m, "subdomain"))
                w.uint32(26).string(m.subdomain);
            if (m.custom != null && Object.hasOwnProperty.call(m, "custom"))
                w.uint32(32).bool(m.custom);
            if (m.customDomain != null && Object.hasOwnProperty.call(m, "customDomain"))
                w.uint32(42).string(m.customDomain);
            if (m.distribution != null && Object.hasOwnProperty.call(m, "distribution"))
                w.uint32(48).int32(m.distribution);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(56).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(64).int64(m.modifyDate);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(74).string(m.name);
            if (m.trialDay != null && Object.hasOwnProperty.call(m, "trialDay"))
                w.uint32(80).int32(m.trialDay);
            if (m.ageRating != null && Object.hasOwnProperty.call(m, "ageRating"))
                w.uint32(88).int32(m.ageRating);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(98).string(m.description);
            if (m.requiredContactLogin != null && Object.hasOwnProperty.call(m, "requiredContactLogin"))
                w.uint32(104).int32(m.requiredContactLogin);
            if (m.appTheme != null && Object.hasOwnProperty.call(m, "appTheme"))
                w.uint32(112).int32(m.appTheme);
            if (m.receiptName != null && Object.hasOwnProperty.call(m, "receiptName"))
                w.uint32(122).string(m.receiptName);
            if (m.receiptPostal != null && Object.hasOwnProperty.call(m, "receiptPostal"))
                w.uint32(130).string(m.receiptPostal);
            if (m.receiptState != null && Object.hasOwnProperty.call(m, "receiptState"))
                w.uint32(138).string(m.receiptState);
            if (m.receiptAddr != null && Object.hasOwnProperty.call(m, "receiptAddr"))
                w.uint32(146).string(m.receiptAddr);
            if (m.appHome != null && Object.hasOwnProperty.call(m, "appHome"))
                w.uint32(152).int32(m.appHome);
            if (m.useCustomDescription != null && Object.hasOwnProperty.call(m, "useCustomDescription"))
                w.uint32(160).bool(m.useCustomDescription);
            if (m.customDescription != null && Object.hasOwnProperty.call(m, "customDescription"))
                w.uint32(170).string(m.customDescription);
            if (m.noIndex != null && Object.hasOwnProperty.call(m, "noIndex"))
                w.uint32(176).bool(m.noIndex);
            if (m.gtmId != null && Object.hasOwnProperty.call(m, "gtmId"))
                w.uint32(186).string(m.gtmId);
            if (m.favicon != null && Object.hasOwnProperty.call(m, "favicon"))
                $root.Proto.CompanyLPFavicon.encode(m.favicon, w.uint32(194).fork()).ldelim();
            if (m.autoSsl != null && Object.hasOwnProperty.call(m, "autoSsl"))
                w.uint32(200).bool(m.autoSsl);
            if (m.customMetaTag != null && Object.hasOwnProperty.call(m, "customMetaTag"))
                w.uint32(210).string(m.customMetaTag);
            if (m.loginPage != null && Object.hasOwnProperty.call(m, "loginPage"))
                w.uint32(216).int32(m.loginPage);
            if (m.loginPageImage != null && Object.hasOwnProperty.call(m, "loginPageImage"))
                $root.Proto.UploadFile.encode(m.loginPageImage, w.uint32(226).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a CompanyLP message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyLP
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyLP} CompanyLP
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyLP.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyLP();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.companyUID = r.string();
                    break;
                case 2:
                    m.content = r.string();
                    break;
                case 3:
                    m.subdomain = r.string();
                    break;
                case 4:
                    m.custom = r.bool();
                    break;
                case 5:
                    m.customDomain = r.string();
                    break;
                case 6:
                    m.distribution = r.int32();
                    break;
                case 7:
                    m.registerDate = r.int64();
                    break;
                case 8:
                    m.modifyDate = r.int64();
                    break;
                case 9:
                    m.name = r.string();
                    break;
                case 10:
                    m.trialDay = r.int32();
                    break;
                case 11:
                    m.ageRating = r.int32();
                    break;
                case 12:
                    m.description = r.string();
                    break;
                case 13:
                    m.requiredContactLogin = r.int32();
                    break;
                case 14:
                    m.appTheme = r.int32();
                    break;
                case 15:
                    m.receiptName = r.string();
                    break;
                case 16:
                    m.receiptPostal = r.string();
                    break;
                case 17:
                    m.receiptState = r.string();
                    break;
                case 18:
                    m.receiptAddr = r.string();
                    break;
                case 19:
                    m.appHome = r.int32();
                    break;
                case 20:
                    m.useCustomDescription = r.bool();
                    break;
                case 21:
                    m.customDescription = r.string();
                    break;
                case 22:
                    m.noIndex = r.bool();
                    break;
                case 23:
                    m.gtmId = r.string();
                    break;
                case 24:
                    m.favicon = $root.Proto.CompanyLPFavicon.decode(r, r.uint32());
                    break;
                case 25:
                    m.autoSsl = r.bool();
                    break;
                case 26:
                    m.customMetaTag = r.string();
                    break;
                case 27:
                    m.loginPage = r.int32();
                    break;
                case 28:
                    m.loginPageImage = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyLP message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyLP
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyLP} CompanyLP
         */
        CompanyLP.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyLP)
                return d;
            var m = new $root.Proto.CompanyLP();
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.content != null) {
                m.content = String(d.content);
            }
            if (d.subdomain != null) {
                m.subdomain = String(d.subdomain);
            }
            if (d.custom != null) {
                m.custom = Boolean(d.custom);
            }
            if (d.customDomain != null) {
                m.customDomain = String(d.customDomain);
            }
            if (d.distribution != null) {
                m.distribution = d.distribution | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.trialDay != null) {
                m.trialDay = d.trialDay | 0;
            }
            if (d.ageRating != null) {
                m.ageRating = d.ageRating | 0;
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.requiredContactLogin != null) {
                m.requiredContactLogin = d.requiredContactLogin | 0;
            }
            if (d.appTheme != null) {
                m.appTheme = d.appTheme | 0;
            }
            if (d.receiptName != null) {
                m.receiptName = String(d.receiptName);
            }
            if (d.receiptPostal != null) {
                m.receiptPostal = String(d.receiptPostal);
            }
            if (d.receiptState != null) {
                m.receiptState = String(d.receiptState);
            }
            if (d.receiptAddr != null) {
                m.receiptAddr = String(d.receiptAddr);
            }
            if (d.appHome != null) {
                m.appHome = d.appHome | 0;
            }
            if (d.useCustomDescription != null) {
                m.useCustomDescription = Boolean(d.useCustomDescription);
            }
            if (d.customDescription != null) {
                m.customDescription = String(d.customDescription);
            }
            if (d.noIndex != null) {
                m.noIndex = Boolean(d.noIndex);
            }
            if (d.gtmId != null) {
                m.gtmId = String(d.gtmId);
            }
            if (d.favicon != null) {
                if (typeof d.favicon !== "object")
                    throw TypeError(".Proto.CompanyLP.favicon: object expected");
                m.favicon = $root.Proto.CompanyLPFavicon.fromObject(d.favicon);
            }
            if (d.autoSsl != null) {
                m.autoSsl = Boolean(d.autoSsl);
            }
            if (d.customMetaTag != null) {
                m.customMetaTag = String(d.customMetaTag);
            }
            if (d.loginPage != null) {
                m.loginPage = d.loginPage | 0;
            }
            if (d.loginPageImage != null) {
                if (typeof d.loginPageImage !== "object")
                    throw TypeError(".Proto.CompanyLP.loginPageImage: object expected");
                m.loginPageImage = $root.Proto.UploadFile.fromObject(d.loginPageImage);
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyLP message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyLP
         * @static
         * @param {Proto.CompanyLP} m CompanyLP
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyLP.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.companyUID = "";
                d.content = "";
                d.subdomain = "";
                d.custom = false;
                d.customDomain = "";
                d.distribution = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.name = "";
                d.trialDay = 0;
                d.ageRating = 0;
                d.description = "";
                d.requiredContactLogin = 0;
                d.appTheme = 0;
                d.receiptName = "";
                d.receiptPostal = "";
                d.receiptState = "";
                d.receiptAddr = "";
                d.appHome = 0;
                d.useCustomDescription = false;
                d.customDescription = "";
                d.noIndex = false;
                d.gtmId = "";
                d.favicon = null;
                d.autoSsl = false;
                d.customMetaTag = "";
                d.loginPage = 0;
                d.loginPageImage = null;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.content != null && m.hasOwnProperty("content")) {
                d.content = m.content;
            }
            if (m.subdomain != null && m.hasOwnProperty("subdomain")) {
                d.subdomain = m.subdomain;
            }
            if (m.custom != null && m.hasOwnProperty("custom")) {
                d.custom = m.custom;
            }
            if (m.customDomain != null && m.hasOwnProperty("customDomain")) {
                d.customDomain = m.customDomain;
            }
            if (m.distribution != null && m.hasOwnProperty("distribution")) {
                d.distribution = m.distribution;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.trialDay != null && m.hasOwnProperty("trialDay")) {
                d.trialDay = m.trialDay;
            }
            if (m.ageRating != null && m.hasOwnProperty("ageRating")) {
                d.ageRating = m.ageRating;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.requiredContactLogin != null && m.hasOwnProperty("requiredContactLogin")) {
                d.requiredContactLogin = m.requiredContactLogin;
            }
            if (m.appTheme != null && m.hasOwnProperty("appTheme")) {
                d.appTheme = m.appTheme;
            }
            if (m.receiptName != null && m.hasOwnProperty("receiptName")) {
                d.receiptName = m.receiptName;
            }
            if (m.receiptPostal != null && m.hasOwnProperty("receiptPostal")) {
                d.receiptPostal = m.receiptPostal;
            }
            if (m.receiptState != null && m.hasOwnProperty("receiptState")) {
                d.receiptState = m.receiptState;
            }
            if (m.receiptAddr != null && m.hasOwnProperty("receiptAddr")) {
                d.receiptAddr = m.receiptAddr;
            }
            if (m.appHome != null && m.hasOwnProperty("appHome")) {
                d.appHome = m.appHome;
            }
            if (m.useCustomDescription != null && m.hasOwnProperty("useCustomDescription")) {
                d.useCustomDescription = m.useCustomDescription;
            }
            if (m.customDescription != null && m.hasOwnProperty("customDescription")) {
                d.customDescription = m.customDescription;
            }
            if (m.noIndex != null && m.hasOwnProperty("noIndex")) {
                d.noIndex = m.noIndex;
            }
            if (m.gtmId != null && m.hasOwnProperty("gtmId")) {
                d.gtmId = m.gtmId;
            }
            if (m.favicon != null && m.hasOwnProperty("favicon")) {
                d.favicon = $root.Proto.CompanyLPFavicon.toObject(m.favicon, o);
            }
            if (m.autoSsl != null && m.hasOwnProperty("autoSsl")) {
                d.autoSsl = m.autoSsl;
            }
            if (m.customMetaTag != null && m.hasOwnProperty("customMetaTag")) {
                d.customMetaTag = m.customMetaTag;
            }
            if (m.loginPage != null && m.hasOwnProperty("loginPage")) {
                d.loginPage = m.loginPage;
            }
            if (m.loginPageImage != null && m.hasOwnProperty("loginPageImage")) {
                d.loginPageImage = $root.Proto.UploadFile.toObject(m.loginPageImage, o);
            }
            return d;
        };

        /**
         * Converts this CompanyLP to JSON.
         * @function toJSON
         * @memberof Proto.CompanyLP
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyLP.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyLP;
    })();

    Proto.AWSCognito = (function() {

        /**
         * Properties of a AWSCognito.
         * @memberof Proto
         * @interface IAWSCognito
         * @property {string|null} [identityId] AWSCognito identityId
         * @property {string|null} [token] AWSCognito token
         * @property {number|Long|null} [expire] AWSCognito expire
         * @property {string|null} [region] AWSCognito region
         */

        /**
         * Constructs a new AWSCognito.
         * @memberof Proto
         * @classdesc Represents a AWSCognito.
         * @implements IAWSCognito
         * @constructor
         * @param {Proto.IAWSCognito=} [p] Properties to set
         */
        function AWSCognito(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AWSCognito identityId.
         * @member {string} identityId
         * @memberof Proto.AWSCognito
         * @instance
         */
        AWSCognito.prototype.identityId = "";

        /**
         * AWSCognito token.
         * @member {string} token
         * @memberof Proto.AWSCognito
         * @instance
         */
        AWSCognito.prototype.token = "";

        /**
         * AWSCognito expire.
         * @member {number|Long} expire
         * @memberof Proto.AWSCognito
         * @instance
         */
        AWSCognito.prototype.expire = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * AWSCognito region.
         * @member {string} region
         * @memberof Proto.AWSCognito
         * @instance
         */
        AWSCognito.prototype.region = "";

        /**
         * Creates a new AWSCognito instance using the specified properties.
         * @function create
         * @memberof Proto.AWSCognito
         * @static
         * @param {Proto.IAWSCognito=} [properties] Properties to set
         * @returns {Proto.AWSCognito} AWSCognito instance
         */
        AWSCognito.create = function create(properties) {
            return new AWSCognito(properties);
        };

        /**
         * Encodes the specified AWSCognito message. Does not implicitly {@link Proto.AWSCognito.verify|verify} messages.
         * @function encode
         * @memberof Proto.AWSCognito
         * @static
         * @param {Proto.IAWSCognito} m AWSCognito message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AWSCognito.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.identityId != null && Object.hasOwnProperty.call(m, "identityId"))
                w.uint32(10).string(m.identityId);
            if (m.token != null && Object.hasOwnProperty.call(m, "token"))
                w.uint32(18).string(m.token);
            if (m.expire != null && Object.hasOwnProperty.call(m, "expire"))
                w.uint32(24).int64(m.expire);
            if (m.region != null && Object.hasOwnProperty.call(m, "region"))
                w.uint32(34).string(m.region);
            return w;
        };

        /**
         * Decodes a AWSCognito message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.AWSCognito
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.AWSCognito} AWSCognito
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AWSCognito.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.AWSCognito();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.identityId = r.string();
                    break;
                case 2:
                    m.token = r.string();
                    break;
                case 3:
                    m.expire = r.int64();
                    break;
                case 4:
                    m.region = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a AWSCognito message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.AWSCognito
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.AWSCognito} AWSCognito
         */
        AWSCognito.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.AWSCognito)
                return d;
            var m = new $root.Proto.AWSCognito();
            if (d.identityId != null) {
                m.identityId = String(d.identityId);
            }
            if (d.token != null) {
                m.token = String(d.token);
            }
            if (d.expire != null) {
                if ($util.Long)
                    (m.expire = $util.Long.fromValue(d.expire)).unsigned = false;
                else if (typeof d.expire === "string")
                    m.expire = parseInt(d.expire, 10);
                else if (typeof d.expire === "number")
                    m.expire = d.expire;
                else if (typeof d.expire === "object")
                    m.expire = new $util.LongBits(d.expire.low >>> 0, d.expire.high >>> 0).toNumber();
            }
            if (d.region != null) {
                m.region = String(d.region);
            }
            return m;
        };

        /**
         * Creates a plain object from a AWSCognito message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.AWSCognito
         * @static
         * @param {Proto.AWSCognito} m AWSCognito
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AWSCognito.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.identityId = "";
                d.token = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.expire = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.expire = o.longs === String ? "0" : 0;
                d.region = "";
            }
            if (m.identityId != null && m.hasOwnProperty("identityId")) {
                d.identityId = m.identityId;
            }
            if (m.token != null && m.hasOwnProperty("token")) {
                d.token = m.token;
            }
            if (m.expire != null && m.hasOwnProperty("expire")) {
                if (typeof m.expire === "number")
                    d.expire = o.longs === String ? String(m.expire) : m.expire;
                else
                    d.expire = o.longs === String ? $util.Long.prototype.toString.call(m.expire) : o.longs === Number ? new $util.LongBits(m.expire.low >>> 0, m.expire.high >>> 0).toNumber() : m.expire;
            }
            if (m.region != null && m.hasOwnProperty("region")) {
                d.region = m.region;
            }
            return d;
        };

        /**
         * Converts this AWSCognito to JSON.
         * @function toJSON
         * @memberof Proto.AWSCognito
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AWSCognito.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AWSCognito;
    })();

    Proto.AWSCognitoPool = (function() {

        /**
         * Properties of a AWSCognitoPool.
         * @memberof Proto
         * @interface IAWSCognitoPool
         * @property {string|null} [identityPoolId] AWSCognitoPool identityPoolId
         * @property {number|null} [region] AWSCognitoPool region
         * @property {string|null} [bucketName] AWSCognitoPool bucketName
         * @property {string|null} [pathPrefix] AWSCognitoPool pathPrefix
         * @property {string|null} [regionName] AWSCognitoPool regionName
         */

        /**
         * Constructs a new AWSCognitoPool.
         * @memberof Proto
         * @classdesc Represents a AWSCognitoPool.
         * @implements IAWSCognitoPool
         * @constructor
         * @param {Proto.IAWSCognitoPool=} [p] Properties to set
         */
        function AWSCognitoPool(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AWSCognitoPool identityPoolId.
         * @member {string} identityPoolId
         * @memberof Proto.AWSCognitoPool
         * @instance
         */
        AWSCognitoPool.prototype.identityPoolId = "";

        /**
         * AWSCognitoPool region.
         * @member {number} region
         * @memberof Proto.AWSCognitoPool
         * @instance
         */
        AWSCognitoPool.prototype.region = 0;

        /**
         * AWSCognitoPool bucketName.
         * @member {string} bucketName
         * @memberof Proto.AWSCognitoPool
         * @instance
         */
        AWSCognitoPool.prototype.bucketName = "";

        /**
         * AWSCognitoPool pathPrefix.
         * @member {string} pathPrefix
         * @memberof Proto.AWSCognitoPool
         * @instance
         */
        AWSCognitoPool.prototype.pathPrefix = "";

        /**
         * AWSCognitoPool regionName.
         * @member {string} regionName
         * @memberof Proto.AWSCognitoPool
         * @instance
         */
        AWSCognitoPool.prototype.regionName = "";

        /**
         * Creates a new AWSCognitoPool instance using the specified properties.
         * @function create
         * @memberof Proto.AWSCognitoPool
         * @static
         * @param {Proto.IAWSCognitoPool=} [properties] Properties to set
         * @returns {Proto.AWSCognitoPool} AWSCognitoPool instance
         */
        AWSCognitoPool.create = function create(properties) {
            return new AWSCognitoPool(properties);
        };

        /**
         * Encodes the specified AWSCognitoPool message. Does not implicitly {@link Proto.AWSCognitoPool.verify|verify} messages.
         * @function encode
         * @memberof Proto.AWSCognitoPool
         * @static
         * @param {Proto.IAWSCognitoPool} m AWSCognitoPool message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AWSCognitoPool.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.identityPoolId != null && Object.hasOwnProperty.call(m, "identityPoolId"))
                w.uint32(10).string(m.identityPoolId);
            if (m.region != null && Object.hasOwnProperty.call(m, "region"))
                w.uint32(16).int32(m.region);
            if (m.bucketName != null && Object.hasOwnProperty.call(m, "bucketName"))
                w.uint32(26).string(m.bucketName);
            if (m.pathPrefix != null && Object.hasOwnProperty.call(m, "pathPrefix"))
                w.uint32(34).string(m.pathPrefix);
            if (m.regionName != null && Object.hasOwnProperty.call(m, "regionName"))
                w.uint32(42).string(m.regionName);
            return w;
        };

        /**
         * Decodes a AWSCognitoPool message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.AWSCognitoPool
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.AWSCognitoPool} AWSCognitoPool
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AWSCognitoPool.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.AWSCognitoPool();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.identityPoolId = r.string();
                    break;
                case 2:
                    m.region = r.int32();
                    break;
                case 3:
                    m.bucketName = r.string();
                    break;
                case 4:
                    m.pathPrefix = r.string();
                    break;
                case 5:
                    m.regionName = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a AWSCognitoPool message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.AWSCognitoPool
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.AWSCognitoPool} AWSCognitoPool
         */
        AWSCognitoPool.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.AWSCognitoPool)
                return d;
            var m = new $root.Proto.AWSCognitoPool();
            if (d.identityPoolId != null) {
                m.identityPoolId = String(d.identityPoolId);
            }
            if (d.region != null) {
                m.region = d.region | 0;
            }
            if (d.bucketName != null) {
                m.bucketName = String(d.bucketName);
            }
            if (d.pathPrefix != null) {
                m.pathPrefix = String(d.pathPrefix);
            }
            if (d.regionName != null) {
                m.regionName = String(d.regionName);
            }
            return m;
        };

        /**
         * Creates a plain object from a AWSCognitoPool message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.AWSCognitoPool
         * @static
         * @param {Proto.AWSCognitoPool} m AWSCognitoPool
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AWSCognitoPool.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.identityPoolId = "";
                d.region = 0;
                d.bucketName = "";
                d.pathPrefix = "";
                d.regionName = "";
            }
            if (m.identityPoolId != null && m.hasOwnProperty("identityPoolId")) {
                d.identityPoolId = m.identityPoolId;
            }
            if (m.region != null && m.hasOwnProperty("region")) {
                d.region = m.region;
            }
            if (m.bucketName != null && m.hasOwnProperty("bucketName")) {
                d.bucketName = m.bucketName;
            }
            if (m.pathPrefix != null && m.hasOwnProperty("pathPrefix")) {
                d.pathPrefix = m.pathPrefix;
            }
            if (m.regionName != null && m.hasOwnProperty("regionName")) {
                d.regionName = m.regionName;
            }
            return d;
        };

        /**
         * Converts this AWSCognitoPool to JSON.
         * @function toJSON
         * @memberof Proto.AWSCognitoPool
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AWSCognitoPool.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AWSCognitoPool;
    })();

    Proto.SignedCookie = (function() {

        /**
         * Properties of a SignedCookie.
         * @memberof Proto
         * @interface ISignedCookie
         * @property {string|null} [policy] SignedCookie policy
         * @property {string|null} [signature] SignedCookie signature
         * @property {string|null} [keypair] SignedCookie keypair
         * @property {number|Long|null} [expireDate] SignedCookie expireDate
         * @property {number|null} [serverType] SignedCookie serverType
         * @property {number|Long|null} [registerDate] SignedCookie registerDate
         * @property {number|Long|null} [modifyDate] SignedCookie modifyDate
         * @property {string|null} [domain] SignedCookie domain
         * @property {boolean|null} [bo] SignedCookie bo
         */

        /**
         * Constructs a new SignedCookie.
         * @memberof Proto
         * @classdesc Represents a SignedCookie.
         * @implements ISignedCookie
         * @constructor
         * @param {Proto.ISignedCookie=} [p] Properties to set
         */
        function SignedCookie(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * SignedCookie policy.
         * @member {string} policy
         * @memberof Proto.SignedCookie
         * @instance
         */
        SignedCookie.prototype.policy = "";

        /**
         * SignedCookie signature.
         * @member {string} signature
         * @memberof Proto.SignedCookie
         * @instance
         */
        SignedCookie.prototype.signature = "";

        /**
         * SignedCookie keypair.
         * @member {string} keypair
         * @memberof Proto.SignedCookie
         * @instance
         */
        SignedCookie.prototype.keypair = "";

        /**
         * SignedCookie expireDate.
         * @member {number|Long} expireDate
         * @memberof Proto.SignedCookie
         * @instance
         */
        SignedCookie.prototype.expireDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SignedCookie serverType.
         * @member {number} serverType
         * @memberof Proto.SignedCookie
         * @instance
         */
        SignedCookie.prototype.serverType = 0;

        /**
         * SignedCookie registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.SignedCookie
         * @instance
         */
        SignedCookie.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SignedCookie modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.SignedCookie
         * @instance
         */
        SignedCookie.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SignedCookie domain.
         * @member {string} domain
         * @memberof Proto.SignedCookie
         * @instance
         */
        SignedCookie.prototype.domain = "";

        /**
         * SignedCookie bo.
         * @member {boolean} bo
         * @memberof Proto.SignedCookie
         * @instance
         */
        SignedCookie.prototype.bo = false;

        /**
         * Creates a new SignedCookie instance using the specified properties.
         * @function create
         * @memberof Proto.SignedCookie
         * @static
         * @param {Proto.ISignedCookie=} [properties] Properties to set
         * @returns {Proto.SignedCookie} SignedCookie instance
         */
        SignedCookie.create = function create(properties) {
            return new SignedCookie(properties);
        };

        /**
         * Encodes the specified SignedCookie message. Does not implicitly {@link Proto.SignedCookie.verify|verify} messages.
         * @function encode
         * @memberof Proto.SignedCookie
         * @static
         * @param {Proto.ISignedCookie} m SignedCookie message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignedCookie.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.policy != null && Object.hasOwnProperty.call(m, "policy"))
                w.uint32(10).string(m.policy);
            if (m.signature != null && Object.hasOwnProperty.call(m, "signature"))
                w.uint32(18).string(m.signature);
            if (m.keypair != null && Object.hasOwnProperty.call(m, "keypair"))
                w.uint32(26).string(m.keypair);
            if (m.expireDate != null && Object.hasOwnProperty.call(m, "expireDate"))
                w.uint32(32).int64(m.expireDate);
            if (m.serverType != null && Object.hasOwnProperty.call(m, "serverType"))
                w.uint32(40).int32(m.serverType);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.domain != null && Object.hasOwnProperty.call(m, "domain"))
                w.uint32(66).string(m.domain);
            if (m.bo != null && Object.hasOwnProperty.call(m, "bo"))
                w.uint32(72).bool(m.bo);
            return w;
        };

        /**
         * Decodes a SignedCookie message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.SignedCookie
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.SignedCookie} SignedCookie
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignedCookie.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.SignedCookie();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.policy = r.string();
                    break;
                case 2:
                    m.signature = r.string();
                    break;
                case 3:
                    m.keypair = r.string();
                    break;
                case 4:
                    m.expireDate = r.int64();
                    break;
                case 5:
                    m.serverType = r.int32();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.domain = r.string();
                    break;
                case 9:
                    m.bo = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a SignedCookie message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.SignedCookie
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.SignedCookie} SignedCookie
         */
        SignedCookie.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.SignedCookie)
                return d;
            var m = new $root.Proto.SignedCookie();
            if (d.policy != null) {
                m.policy = String(d.policy);
            }
            if (d.signature != null) {
                m.signature = String(d.signature);
            }
            if (d.keypair != null) {
                m.keypair = String(d.keypair);
            }
            if (d.expireDate != null) {
                if ($util.Long)
                    (m.expireDate = $util.Long.fromValue(d.expireDate)).unsigned = false;
                else if (typeof d.expireDate === "string")
                    m.expireDate = parseInt(d.expireDate, 10);
                else if (typeof d.expireDate === "number")
                    m.expireDate = d.expireDate;
                else if (typeof d.expireDate === "object")
                    m.expireDate = new $util.LongBits(d.expireDate.low >>> 0, d.expireDate.high >>> 0).toNumber();
            }
            if (d.serverType != null) {
                m.serverType = d.serverType | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.domain != null) {
                m.domain = String(d.domain);
            }
            if (d.bo != null) {
                m.bo = Boolean(d.bo);
            }
            return m;
        };

        /**
         * Creates a plain object from a SignedCookie message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.SignedCookie
         * @static
         * @param {Proto.SignedCookie} m SignedCookie
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SignedCookie.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.policy = "";
                d.signature = "";
                d.keypair = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.expireDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.expireDate = o.longs === String ? "0" : 0;
                d.serverType = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.domain = "";
                d.bo = false;
            }
            if (m.policy != null && m.hasOwnProperty("policy")) {
                d.policy = m.policy;
            }
            if (m.signature != null && m.hasOwnProperty("signature")) {
                d.signature = m.signature;
            }
            if (m.keypair != null && m.hasOwnProperty("keypair")) {
                d.keypair = m.keypair;
            }
            if (m.expireDate != null && m.hasOwnProperty("expireDate")) {
                if (typeof m.expireDate === "number")
                    d.expireDate = o.longs === String ? String(m.expireDate) : m.expireDate;
                else
                    d.expireDate = o.longs === String ? $util.Long.prototype.toString.call(m.expireDate) : o.longs === Number ? new $util.LongBits(m.expireDate.low >>> 0, m.expireDate.high >>> 0).toNumber() : m.expireDate;
            }
            if (m.serverType != null && m.hasOwnProperty("serverType")) {
                d.serverType = m.serverType;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.domain != null && m.hasOwnProperty("domain")) {
                d.domain = m.domain;
            }
            if (m.bo != null && m.hasOwnProperty("bo")) {
                d.bo = m.bo;
            }
            return d;
        };

        /**
         * Converts this SignedCookie to JSON.
         * @function toJSON
         * @memberof Proto.SignedCookie
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SignedCookie.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SignedCookie;
    })();

    Proto.UserPasswordToken = (function() {

        /**
         * Properties of a UserPasswordToken.
         * @memberof Proto
         * @interface IUserPasswordToken
         * @property {string|null} [token] UserPasswordToken token
         * @property {string|null} [password] UserPasswordToken password
         */

        /**
         * Constructs a new UserPasswordToken.
         * @memberof Proto
         * @classdesc Represents a UserPasswordToken.
         * @implements IUserPasswordToken
         * @constructor
         * @param {Proto.IUserPasswordToken=} [p] Properties to set
         */
        function UserPasswordToken(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserPasswordToken token.
         * @member {string} token
         * @memberof Proto.UserPasswordToken
         * @instance
         */
        UserPasswordToken.prototype.token = "";

        /**
         * UserPasswordToken password.
         * @member {string} password
         * @memberof Proto.UserPasswordToken
         * @instance
         */
        UserPasswordToken.prototype.password = "";

        /**
         * Creates a new UserPasswordToken instance using the specified properties.
         * @function create
         * @memberof Proto.UserPasswordToken
         * @static
         * @param {Proto.IUserPasswordToken=} [properties] Properties to set
         * @returns {Proto.UserPasswordToken} UserPasswordToken instance
         */
        UserPasswordToken.create = function create(properties) {
            return new UserPasswordToken(properties);
        };

        /**
         * Encodes the specified UserPasswordToken message. Does not implicitly {@link Proto.UserPasswordToken.verify|verify} messages.
         * @function encode
         * @memberof Proto.UserPasswordToken
         * @static
         * @param {Proto.IUserPasswordToken} m UserPasswordToken message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserPasswordToken.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.token != null && Object.hasOwnProperty.call(m, "token"))
                w.uint32(10).string(m.token);
            if (m.password != null && Object.hasOwnProperty.call(m, "password"))
                w.uint32(18).string(m.password);
            return w;
        };

        /**
         * Decodes a UserPasswordToken message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.UserPasswordToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.UserPasswordToken} UserPasswordToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserPasswordToken.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.UserPasswordToken();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.token = r.string();
                    break;
                case 2:
                    m.password = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserPasswordToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.UserPasswordToken
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.UserPasswordToken} UserPasswordToken
         */
        UserPasswordToken.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.UserPasswordToken)
                return d;
            var m = new $root.Proto.UserPasswordToken();
            if (d.token != null) {
                m.token = String(d.token);
            }
            if (d.password != null) {
                m.password = String(d.password);
            }
            return m;
        };

        /**
         * Creates a plain object from a UserPasswordToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.UserPasswordToken
         * @static
         * @param {Proto.UserPasswordToken} m UserPasswordToken
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserPasswordToken.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.token = "";
                d.password = "";
            }
            if (m.token != null && m.hasOwnProperty("token")) {
                d.token = m.token;
            }
            if (m.password != null && m.hasOwnProperty("password")) {
                d.password = m.password;
            }
            return d;
        };

        /**
         * Converts this UserPasswordToken to JSON.
         * @function toJSON
         * @memberof Proto.UserPasswordToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserPasswordToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserPasswordToken;
    })();

    Proto.UserBrowserToken = (function() {

        /**
         * Properties of a UserBrowserToken.
         * @memberof Proto
         * @interface IUserBrowserToken
         * @property {string|null} [endpoint] UserBrowserToken endpoint
         * @property {number|null} [type] UserBrowserToken type
         * @property {string|null} [uuid] UserBrowserToken uuid
         * @property {number|Long|null} [expire] UserBrowserToken expire
         * @property {string|null} [p256dh] UserBrowserToken p256dh
         * @property {string|null} [auth] UserBrowserToken auth
         * @property {number|Long|null} [registerDate] UserBrowserToken registerDate
         * @property {number|Long|null} [modifyDate] UserBrowserToken modifyDate
         * @property {number|null} [status] UserBrowserToken status
         */

        /**
         * Constructs a new UserBrowserToken.
         * @memberof Proto
         * @classdesc Represents a UserBrowserToken.
         * @implements IUserBrowserToken
         * @constructor
         * @param {Proto.IUserBrowserToken=} [p] Properties to set
         */
        function UserBrowserToken(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserBrowserToken endpoint.
         * @member {string} endpoint
         * @memberof Proto.UserBrowserToken
         * @instance
         */
        UserBrowserToken.prototype.endpoint = "";

        /**
         * UserBrowserToken type.
         * @member {number} type
         * @memberof Proto.UserBrowserToken
         * @instance
         */
        UserBrowserToken.prototype.type = 0;

        /**
         * UserBrowserToken uuid.
         * @member {string} uuid
         * @memberof Proto.UserBrowserToken
         * @instance
         */
        UserBrowserToken.prototype.uuid = "";

        /**
         * UserBrowserToken expire.
         * @member {number|Long} expire
         * @memberof Proto.UserBrowserToken
         * @instance
         */
        UserBrowserToken.prototype.expire = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UserBrowserToken p256dh.
         * @member {string} p256dh
         * @memberof Proto.UserBrowserToken
         * @instance
         */
        UserBrowserToken.prototype.p256dh = "";

        /**
         * UserBrowserToken auth.
         * @member {string} auth
         * @memberof Proto.UserBrowserToken
         * @instance
         */
        UserBrowserToken.prototype.auth = "";

        /**
         * UserBrowserToken registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.UserBrowserToken
         * @instance
         */
        UserBrowserToken.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UserBrowserToken modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.UserBrowserToken
         * @instance
         */
        UserBrowserToken.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UserBrowserToken status.
         * @member {number} status
         * @memberof Proto.UserBrowserToken
         * @instance
         */
        UserBrowserToken.prototype.status = 0;

        /**
         * Creates a new UserBrowserToken instance using the specified properties.
         * @function create
         * @memberof Proto.UserBrowserToken
         * @static
         * @param {Proto.IUserBrowserToken=} [properties] Properties to set
         * @returns {Proto.UserBrowserToken} UserBrowserToken instance
         */
        UserBrowserToken.create = function create(properties) {
            return new UserBrowserToken(properties);
        };

        /**
         * Encodes the specified UserBrowserToken message. Does not implicitly {@link Proto.UserBrowserToken.verify|verify} messages.
         * @function encode
         * @memberof Proto.UserBrowserToken
         * @static
         * @param {Proto.IUserBrowserToken} m UserBrowserToken message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserBrowserToken.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.endpoint != null && Object.hasOwnProperty.call(m, "endpoint"))
                w.uint32(10).string(m.endpoint);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.uuid != null && Object.hasOwnProperty.call(m, "uuid"))
                w.uint32(26).string(m.uuid);
            if (m.expire != null && Object.hasOwnProperty.call(m, "expire"))
                w.uint32(32).uint64(m.expire);
            if (m.p256dh != null && Object.hasOwnProperty.call(m, "p256dh"))
                w.uint32(42).string(m.p256dh);
            if (m.auth != null && Object.hasOwnProperty.call(m, "auth"))
                w.uint32(50).string(m.auth);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(56).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(64).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(72).int32(m.status);
            return w;
        };

        /**
         * Decodes a UserBrowserToken message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.UserBrowserToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.UserBrowserToken} UserBrowserToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserBrowserToken.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.UserBrowserToken();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.endpoint = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.uuid = r.string();
                    break;
                case 4:
                    m.expire = r.uint64();
                    break;
                case 5:
                    m.p256dh = r.string();
                    break;
                case 6:
                    m.auth = r.string();
                    break;
                case 7:
                    m.registerDate = r.int64();
                    break;
                case 8:
                    m.modifyDate = r.int64();
                    break;
                case 9:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserBrowserToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.UserBrowserToken
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.UserBrowserToken} UserBrowserToken
         */
        UserBrowserToken.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.UserBrowserToken)
                return d;
            var m = new $root.Proto.UserBrowserToken();
            if (d.endpoint != null) {
                m.endpoint = String(d.endpoint);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.uuid != null) {
                m.uuid = String(d.uuid);
            }
            if (d.expire != null) {
                if ($util.Long)
                    (m.expire = $util.Long.fromValue(d.expire)).unsigned = true;
                else if (typeof d.expire === "string")
                    m.expire = parseInt(d.expire, 10);
                else if (typeof d.expire === "number")
                    m.expire = d.expire;
                else if (typeof d.expire === "object")
                    m.expire = new $util.LongBits(d.expire.low >>> 0, d.expire.high >>> 0).toNumber(true);
            }
            if (d.p256dh != null) {
                m.p256dh = String(d.p256dh);
            }
            if (d.auth != null) {
                m.auth = String(d.auth);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a UserBrowserToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.UserBrowserToken
         * @static
         * @param {Proto.UserBrowserToken} m UserBrowserToken
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserBrowserToken.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.endpoint = "";
                d.type = 0;
                d.uuid = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, true);
                    d.expire = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.expire = o.longs === String ? "0" : 0;
                d.p256dh = "";
                d.auth = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.endpoint != null && m.hasOwnProperty("endpoint")) {
                d.endpoint = m.endpoint;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.uuid != null && m.hasOwnProperty("uuid")) {
                d.uuid = m.uuid;
            }
            if (m.expire != null && m.hasOwnProperty("expire")) {
                if (typeof m.expire === "number")
                    d.expire = o.longs === String ? String(m.expire) : m.expire;
                else
                    d.expire = o.longs === String ? $util.Long.prototype.toString.call(m.expire) : o.longs === Number ? new $util.LongBits(m.expire.low >>> 0, m.expire.high >>> 0).toNumber(true) : m.expire;
            }
            if (m.p256dh != null && m.hasOwnProperty("p256dh")) {
                d.p256dh = m.p256dh;
            }
            if (m.auth != null && m.hasOwnProperty("auth")) {
                d.auth = m.auth;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this UserBrowserToken to JSON.
         * @function toJSON
         * @memberof Proto.UserBrowserToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserBrowserToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserBrowserToken;
    })();

    Proto.UploadFile = (function() {

        /**
         * Properties of an UploadFile.
         * @memberof Proto
         * @interface IUploadFile
         * @property {string|null} [uid] UploadFile uid
         * @property {string|null} [userUID] UploadFile userUID
         * @property {number|null} [serverType] UploadFile serverType
         * @property {string|null} [path] UploadFile path
         * @property {number|null} [fileType] UploadFile fileType
         * @property {Proto.INullBoolean|null} [uploaded] UploadFile uploaded
         * @property {Proto.INullBoolean|null} [converted] UploadFile converted
         * @property {number|null} [thumbnailServerType] UploadFile thumbnailServerType
         * @property {string|null} [thumbnailPath] UploadFile thumbnailPath
         * @property {number|null} [width] UploadFile width
         * @property {number|null} [height] UploadFile height
         * @property {number|null} [duration] UploadFile duration
         * @property {number|null} [fps] UploadFile fps
         * @property {Proto.INullBoolean|null} [thumbnailUploaded] UploadFile thumbnailUploaded
         * @property {string|null} [fileURL] UploadFile fileURL
         * @property {string|null} [thumbnailImageURL] UploadFile thumbnailImageURL
         * @property {number|null} [progress] UploadFile progress
         * @property {number|Long|null} [registerDate] UploadFile registerDate
         * @property {number|Long|null} [modifyDate] UploadFile modifyDate
         * @property {number|null} [status] UploadFile status
         * @property {Array.<Proto.IUploadFileAdditional>|null} [additional] UploadFile additional
         * @property {Array.<Proto.IUploadFileOutput>|null} [outputs] UploadFile outputs
         * @property {number|null} [thumbnailStatus] UploadFile thumbnailStatus
         * @property {number|null} [problemType] UploadFile problemType
         * @property {number|null} [downloadableOutputType] UploadFile downloadableOutputType
         * @property {number|null} [fileSize] UploadFile fileSize
         * @property {number|null} [uploadProgress] UploadFile uploadProgress
         * @property {string|null} [fileExt] UploadFile fileExt
         * @property {boolean|null} [autoPw] UploadFile autoPw
         * @property {string|null} [name] UploadFile name
         * @property {string|null} [fileMimeType] UploadFile fileMimeType
         */

        /**
         * Constructs a new UploadFile.
         * @memberof Proto
         * @classdesc Represents an UploadFile.
         * @implements IUploadFile
         * @constructor
         * @param {Proto.IUploadFile=} [p] Properties to set
         */
        function UploadFile(p) {
            this.additional = [];
            this.outputs = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UploadFile uid.
         * @member {string} uid
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.uid = "";

        /**
         * UploadFile userUID.
         * @member {string} userUID
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.userUID = "";

        /**
         * UploadFile serverType.
         * @member {number} serverType
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.serverType = 0;

        /**
         * UploadFile path.
         * @member {string} path
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.path = "";

        /**
         * UploadFile fileType.
         * @member {number} fileType
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.fileType = 0;

        /**
         * UploadFile uploaded.
         * @member {Proto.INullBoolean|null|undefined} uploaded
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.uploaded = null;

        /**
         * UploadFile converted.
         * @member {Proto.INullBoolean|null|undefined} converted
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.converted = null;

        /**
         * UploadFile thumbnailServerType.
         * @member {number} thumbnailServerType
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.thumbnailServerType = 0;

        /**
         * UploadFile thumbnailPath.
         * @member {string} thumbnailPath
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.thumbnailPath = "";

        /**
         * UploadFile width.
         * @member {number} width
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.width = 0;

        /**
         * UploadFile height.
         * @member {number} height
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.height = 0;

        /**
         * UploadFile duration.
         * @member {number} duration
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.duration = 0;

        /**
         * UploadFile fps.
         * @member {number} fps
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.fps = 0;

        /**
         * UploadFile thumbnailUploaded.
         * @member {Proto.INullBoolean|null|undefined} thumbnailUploaded
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.thumbnailUploaded = null;

        /**
         * UploadFile fileURL.
         * @member {string} fileURL
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.fileURL = "";

        /**
         * UploadFile thumbnailImageURL.
         * @member {string} thumbnailImageURL
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.thumbnailImageURL = "";

        /**
         * UploadFile progress.
         * @member {number} progress
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.progress = 0;

        /**
         * UploadFile registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UploadFile modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UploadFile status.
         * @member {number} status
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.status = 0;

        /**
         * UploadFile additional.
         * @member {Array.<Proto.IUploadFileAdditional>} additional
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.additional = $util.emptyArray;

        /**
         * UploadFile outputs.
         * @member {Array.<Proto.IUploadFileOutput>} outputs
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.outputs = $util.emptyArray;

        /**
         * UploadFile thumbnailStatus.
         * @member {number} thumbnailStatus
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.thumbnailStatus = 0;

        /**
         * UploadFile problemType.
         * @member {number} problemType
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.problemType = 0;

        /**
         * UploadFile downloadableOutputType.
         * @member {number} downloadableOutputType
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.downloadableOutputType = 0;

        /**
         * UploadFile fileSize.
         * @member {number} fileSize
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.fileSize = 0;

        /**
         * UploadFile uploadProgress.
         * @member {number} uploadProgress
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.uploadProgress = 0;

        /**
         * UploadFile fileExt.
         * @member {string} fileExt
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.fileExt = "";

        /**
         * UploadFile autoPw.
         * @member {boolean} autoPw
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.autoPw = false;

        /**
         * UploadFile name.
         * @member {string} name
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.name = "";

        /**
         * UploadFile fileMimeType.
         * @member {string} fileMimeType
         * @memberof Proto.UploadFile
         * @instance
         */
        UploadFile.prototype.fileMimeType = "";

        /**
         * Creates a new UploadFile instance using the specified properties.
         * @function create
         * @memberof Proto.UploadFile
         * @static
         * @param {Proto.IUploadFile=} [properties] Properties to set
         * @returns {Proto.UploadFile} UploadFile instance
         */
        UploadFile.create = function create(properties) {
            return new UploadFile(properties);
        };

        /**
         * Encodes the specified UploadFile message. Does not implicitly {@link Proto.UploadFile.verify|verify} messages.
         * @function encode
         * @memberof Proto.UploadFile
         * @static
         * @param {Proto.IUploadFile} m UploadFile message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadFile.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(18).string(m.userUID);
            if (m.serverType != null && Object.hasOwnProperty.call(m, "serverType"))
                w.uint32(24).int32(m.serverType);
            if (m.path != null && Object.hasOwnProperty.call(m, "path"))
                w.uint32(34).string(m.path);
            if (m.fileType != null && Object.hasOwnProperty.call(m, "fileType"))
                w.uint32(40).int32(m.fileType);
            if (m.uploaded != null && Object.hasOwnProperty.call(m, "uploaded"))
                $root.Proto.NullBoolean.encode(m.uploaded, w.uint32(50).fork()).ldelim();
            if (m.converted != null && Object.hasOwnProperty.call(m, "converted"))
                $root.Proto.NullBoolean.encode(m.converted, w.uint32(58).fork()).ldelim();
            if (m.thumbnailServerType != null && Object.hasOwnProperty.call(m, "thumbnailServerType"))
                w.uint32(64).int32(m.thumbnailServerType);
            if (m.thumbnailPath != null && Object.hasOwnProperty.call(m, "thumbnailPath"))
                w.uint32(74).string(m.thumbnailPath);
            if (m.width != null && Object.hasOwnProperty.call(m, "width"))
                w.uint32(80).int32(m.width);
            if (m.height != null && Object.hasOwnProperty.call(m, "height"))
                w.uint32(88).int32(m.height);
            if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                w.uint32(97).double(m.duration);
            if (m.fps != null && Object.hasOwnProperty.call(m, "fps"))
                w.uint32(105).double(m.fps);
            if (m.thumbnailUploaded != null && Object.hasOwnProperty.call(m, "thumbnailUploaded"))
                $root.Proto.NullBoolean.encode(m.thumbnailUploaded, w.uint32(114).fork()).ldelim();
            if (m.fileURL != null && Object.hasOwnProperty.call(m, "fileURL"))
                w.uint32(122).string(m.fileURL);
            if (m.thumbnailImageURL != null && Object.hasOwnProperty.call(m, "thumbnailImageURL"))
                w.uint32(130).string(m.thumbnailImageURL);
            if (m.progress != null && Object.hasOwnProperty.call(m, "progress"))
                w.uint32(136).int32(m.progress);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(144).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(152).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(160).int32(m.status);
            if (m.additional != null && m.additional.length) {
                for (var i = 0; i < m.additional.length; ++i)
                    $root.Proto.UploadFileAdditional.encode(m.additional[i], w.uint32(170).fork()).ldelim();
            }
            if (m.outputs != null && m.outputs.length) {
                for (var i = 0; i < m.outputs.length; ++i)
                    $root.Proto.UploadFileOutput.encode(m.outputs[i], w.uint32(178).fork()).ldelim();
            }
            if (m.thumbnailStatus != null && Object.hasOwnProperty.call(m, "thumbnailStatus"))
                w.uint32(184).int32(m.thumbnailStatus);
            if (m.problemType != null && Object.hasOwnProperty.call(m, "problemType"))
                w.uint32(192).int32(m.problemType);
            if (m.downloadableOutputType != null && Object.hasOwnProperty.call(m, "downloadableOutputType"))
                w.uint32(200).int32(m.downloadableOutputType);
            if (m.fileSize != null && Object.hasOwnProperty.call(m, "fileSize"))
                w.uint32(209).double(m.fileSize);
            if (m.uploadProgress != null && Object.hasOwnProperty.call(m, "uploadProgress"))
                w.uint32(216).int32(m.uploadProgress);
            if (m.fileExt != null && Object.hasOwnProperty.call(m, "fileExt"))
                w.uint32(226).string(m.fileExt);
            if (m.autoPw != null && Object.hasOwnProperty.call(m, "autoPw"))
                w.uint32(232).bool(m.autoPw);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(242).string(m.name);
            if (m.fileMimeType != null && Object.hasOwnProperty.call(m, "fileMimeType"))
                w.uint32(250).string(m.fileMimeType);
            return w;
        };

        /**
         * Decodes an UploadFile message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.UploadFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.UploadFile} UploadFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadFile.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.UploadFile();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.userUID = r.string();
                    break;
                case 3:
                    m.serverType = r.int32();
                    break;
                case 4:
                    m.path = r.string();
                    break;
                case 5:
                    m.fileType = r.int32();
                    break;
                case 6:
                    m.uploaded = $root.Proto.NullBoolean.decode(r, r.uint32());
                    break;
                case 7:
                    m.converted = $root.Proto.NullBoolean.decode(r, r.uint32());
                    break;
                case 8:
                    m.thumbnailServerType = r.int32();
                    break;
                case 9:
                    m.thumbnailPath = r.string();
                    break;
                case 10:
                    m.width = r.int32();
                    break;
                case 11:
                    m.height = r.int32();
                    break;
                case 12:
                    m.duration = r.double();
                    break;
                case 13:
                    m.fps = r.double();
                    break;
                case 14:
                    m.thumbnailUploaded = $root.Proto.NullBoolean.decode(r, r.uint32());
                    break;
                case 15:
                    m.fileURL = r.string();
                    break;
                case 16:
                    m.thumbnailImageURL = r.string();
                    break;
                case 17:
                    m.progress = r.int32();
                    break;
                case 18:
                    m.registerDate = r.int64();
                    break;
                case 19:
                    m.modifyDate = r.int64();
                    break;
                case 20:
                    m.status = r.int32();
                    break;
                case 21:
                    if (!(m.additional && m.additional.length))
                        m.additional = [];
                    m.additional.push($root.Proto.UploadFileAdditional.decode(r, r.uint32()));
                    break;
                case 22:
                    if (!(m.outputs && m.outputs.length))
                        m.outputs = [];
                    m.outputs.push($root.Proto.UploadFileOutput.decode(r, r.uint32()));
                    break;
                case 23:
                    m.thumbnailStatus = r.int32();
                    break;
                case 24:
                    m.problemType = r.int32();
                    break;
                case 25:
                    m.downloadableOutputType = r.int32();
                    break;
                case 26:
                    m.fileSize = r.double();
                    break;
                case 27:
                    m.uploadProgress = r.int32();
                    break;
                case 28:
                    m.fileExt = r.string();
                    break;
                case 29:
                    m.autoPw = r.bool();
                    break;
                case 30:
                    m.name = r.string();
                    break;
                case 31:
                    m.fileMimeType = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an UploadFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.UploadFile
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.UploadFile} UploadFile
         */
        UploadFile.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.UploadFile)
                return d;
            var m = new $root.Proto.UploadFile();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.serverType != null) {
                m.serverType = d.serverType | 0;
            }
            if (d.path != null) {
                m.path = String(d.path);
            }
            if (d.fileType != null) {
                m.fileType = d.fileType | 0;
            }
            if (d.uploaded != null) {
                if (typeof d.uploaded !== "object")
                    throw TypeError(".Proto.UploadFile.uploaded: object expected");
                m.uploaded = $root.Proto.NullBoolean.fromObject(d.uploaded);
            }
            if (d.converted != null) {
                if (typeof d.converted !== "object")
                    throw TypeError(".Proto.UploadFile.converted: object expected");
                m.converted = $root.Proto.NullBoolean.fromObject(d.converted);
            }
            if (d.thumbnailServerType != null) {
                m.thumbnailServerType = d.thumbnailServerType | 0;
            }
            if (d.thumbnailPath != null) {
                m.thumbnailPath = String(d.thumbnailPath);
            }
            if (d.width != null) {
                m.width = d.width | 0;
            }
            if (d.height != null) {
                m.height = d.height | 0;
            }
            if (d.duration != null) {
                m.duration = Number(d.duration);
            }
            if (d.fps != null) {
                m.fps = Number(d.fps);
            }
            if (d.thumbnailUploaded != null) {
                if (typeof d.thumbnailUploaded !== "object")
                    throw TypeError(".Proto.UploadFile.thumbnailUploaded: object expected");
                m.thumbnailUploaded = $root.Proto.NullBoolean.fromObject(d.thumbnailUploaded);
            }
            if (d.fileURL != null) {
                m.fileURL = String(d.fileURL);
            }
            if (d.thumbnailImageURL != null) {
                m.thumbnailImageURL = String(d.thumbnailImageURL);
            }
            if (d.progress != null) {
                m.progress = d.progress | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.additional) {
                if (!Array.isArray(d.additional))
                    throw TypeError(".Proto.UploadFile.additional: array expected");
                m.additional = [];
                for (var i = 0; i < d.additional.length; ++i) {
                    if (typeof d.additional[i] !== "object")
                        throw TypeError(".Proto.UploadFile.additional: object expected");
                    m.additional[i] = $root.Proto.UploadFileAdditional.fromObject(d.additional[i]);
                }
            }
            if (d.outputs) {
                if (!Array.isArray(d.outputs))
                    throw TypeError(".Proto.UploadFile.outputs: array expected");
                m.outputs = [];
                for (var i = 0; i < d.outputs.length; ++i) {
                    if (typeof d.outputs[i] !== "object")
                        throw TypeError(".Proto.UploadFile.outputs: object expected");
                    m.outputs[i] = $root.Proto.UploadFileOutput.fromObject(d.outputs[i]);
                }
            }
            if (d.thumbnailStatus != null) {
                m.thumbnailStatus = d.thumbnailStatus | 0;
            }
            if (d.problemType != null) {
                m.problemType = d.problemType | 0;
            }
            if (d.downloadableOutputType != null) {
                m.downloadableOutputType = d.downloadableOutputType | 0;
            }
            if (d.fileSize != null) {
                m.fileSize = Number(d.fileSize);
            }
            if (d.uploadProgress != null) {
                m.uploadProgress = d.uploadProgress | 0;
            }
            if (d.fileExt != null) {
                m.fileExt = String(d.fileExt);
            }
            if (d.autoPw != null) {
                m.autoPw = Boolean(d.autoPw);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.fileMimeType != null) {
                m.fileMimeType = String(d.fileMimeType);
            }
            return m;
        };

        /**
         * Creates a plain object from an UploadFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.UploadFile
         * @static
         * @param {Proto.UploadFile} m UploadFile
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadFile.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.additional = [];
                d.outputs = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.userUID = "";
                d.serverType = 0;
                d.path = "";
                d.fileType = 0;
                d.uploaded = null;
                d.converted = null;
                d.thumbnailServerType = 0;
                d.thumbnailPath = "";
                d.width = 0;
                d.height = 0;
                d.duration = 0;
                d.fps = 0;
                d.thumbnailUploaded = null;
                d.fileURL = "";
                d.thumbnailImageURL = "";
                d.progress = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.thumbnailStatus = 0;
                d.problemType = 0;
                d.downloadableOutputType = 0;
                d.fileSize = 0;
                d.uploadProgress = 0;
                d.fileExt = "";
                d.autoPw = false;
                d.name = "";
                d.fileMimeType = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.serverType != null && m.hasOwnProperty("serverType")) {
                d.serverType = m.serverType;
            }
            if (m.path != null && m.hasOwnProperty("path")) {
                d.path = m.path;
            }
            if (m.fileType != null && m.hasOwnProperty("fileType")) {
                d.fileType = m.fileType;
            }
            if (m.uploaded != null && m.hasOwnProperty("uploaded")) {
                d.uploaded = $root.Proto.NullBoolean.toObject(m.uploaded, o);
            }
            if (m.converted != null && m.hasOwnProperty("converted")) {
                d.converted = $root.Proto.NullBoolean.toObject(m.converted, o);
            }
            if (m.thumbnailServerType != null && m.hasOwnProperty("thumbnailServerType")) {
                d.thumbnailServerType = m.thumbnailServerType;
            }
            if (m.thumbnailPath != null && m.hasOwnProperty("thumbnailPath")) {
                d.thumbnailPath = m.thumbnailPath;
            }
            if (m.width != null && m.hasOwnProperty("width")) {
                d.width = m.width;
            }
            if (m.height != null && m.hasOwnProperty("height")) {
                d.height = m.height;
            }
            if (m.duration != null && m.hasOwnProperty("duration")) {
                d.duration = o.json && !isFinite(m.duration) ? String(m.duration) : m.duration;
            }
            if (m.fps != null && m.hasOwnProperty("fps")) {
                d.fps = o.json && !isFinite(m.fps) ? String(m.fps) : m.fps;
            }
            if (m.thumbnailUploaded != null && m.hasOwnProperty("thumbnailUploaded")) {
                d.thumbnailUploaded = $root.Proto.NullBoolean.toObject(m.thumbnailUploaded, o);
            }
            if (m.fileURL != null && m.hasOwnProperty("fileURL")) {
                d.fileURL = m.fileURL;
            }
            if (m.thumbnailImageURL != null && m.hasOwnProperty("thumbnailImageURL")) {
                d.thumbnailImageURL = m.thumbnailImageURL;
            }
            if (m.progress != null && m.hasOwnProperty("progress")) {
                d.progress = m.progress;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.additional && m.additional.length) {
                d.additional = [];
                for (var j = 0; j < m.additional.length; ++j) {
                    d.additional[j] = $root.Proto.UploadFileAdditional.toObject(m.additional[j], o);
                }
            }
            if (m.outputs && m.outputs.length) {
                d.outputs = [];
                for (var j = 0; j < m.outputs.length; ++j) {
                    d.outputs[j] = $root.Proto.UploadFileOutput.toObject(m.outputs[j], o);
                }
            }
            if (m.thumbnailStatus != null && m.hasOwnProperty("thumbnailStatus")) {
                d.thumbnailStatus = m.thumbnailStatus;
            }
            if (m.problemType != null && m.hasOwnProperty("problemType")) {
                d.problemType = m.problemType;
            }
            if (m.downloadableOutputType != null && m.hasOwnProperty("downloadableOutputType")) {
                d.downloadableOutputType = m.downloadableOutputType;
            }
            if (m.fileSize != null && m.hasOwnProperty("fileSize")) {
                d.fileSize = o.json && !isFinite(m.fileSize) ? String(m.fileSize) : m.fileSize;
            }
            if (m.uploadProgress != null && m.hasOwnProperty("uploadProgress")) {
                d.uploadProgress = m.uploadProgress;
            }
            if (m.fileExt != null && m.hasOwnProperty("fileExt")) {
                d.fileExt = m.fileExt;
            }
            if (m.autoPw != null && m.hasOwnProperty("autoPw")) {
                d.autoPw = m.autoPw;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.fileMimeType != null && m.hasOwnProperty("fileMimeType")) {
                d.fileMimeType = m.fileMimeType;
            }
            return d;
        };

        /**
         * Converts this UploadFile to JSON.
         * @function toJSON
         * @memberof Proto.UploadFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadFile;
    })();

    Proto.UploadFileList = (function() {

        /**
         * Properties of an UploadFileList.
         * @memberof Proto
         * @interface IUploadFileList
         * @property {Array.<Proto.IUploadFile>|null} [value] UploadFileList value
         */

        /**
         * Constructs a new UploadFileList.
         * @memberof Proto
         * @classdesc Represents an UploadFileList.
         * @implements IUploadFileList
         * @constructor
         * @param {Proto.IUploadFileList=} [p] Properties to set
         */
        function UploadFileList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UploadFileList value.
         * @member {Array.<Proto.IUploadFile>} value
         * @memberof Proto.UploadFileList
         * @instance
         */
        UploadFileList.prototype.value = $util.emptyArray;

        /**
         * Creates a new UploadFileList instance using the specified properties.
         * @function create
         * @memberof Proto.UploadFileList
         * @static
         * @param {Proto.IUploadFileList=} [properties] Properties to set
         * @returns {Proto.UploadFileList} UploadFileList instance
         */
        UploadFileList.create = function create(properties) {
            return new UploadFileList(properties);
        };

        /**
         * Encodes the specified UploadFileList message. Does not implicitly {@link Proto.UploadFileList.verify|verify} messages.
         * @function encode
         * @memberof Proto.UploadFileList
         * @static
         * @param {Proto.IUploadFileList} m UploadFileList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadFileList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.UploadFile.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes an UploadFileList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.UploadFileList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.UploadFileList} UploadFileList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadFileList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.UploadFileList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.UploadFile.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an UploadFileList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.UploadFileList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.UploadFileList} UploadFileList
         */
        UploadFileList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.UploadFileList)
                return d;
            var m = new $root.Proto.UploadFileList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.UploadFileList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.UploadFileList.value: object expected");
                    m.value[i] = $root.Proto.UploadFile.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from an UploadFileList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.UploadFileList
         * @static
         * @param {Proto.UploadFileList} m UploadFileList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadFileList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.UploadFile.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this UploadFileList to JSON.
         * @function toJSON
         * @memberof Proto.UploadFileList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadFileList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadFileList;
    })();

    Proto.UploadFileAdditional = (function() {

        /**
         * Properties of an UploadFileAdditional.
         * @memberof Proto
         * @interface IUploadFileAdditional
         * @property {string|null} [uid] UploadFileAdditional uid
         * @property {string|null} [inputUID] UploadFileAdditional inputUID
         * @property {number|null} [type] UploadFileAdditional type
         * @property {string|null} [userUID] UploadFileAdditional userUID
         * @property {number|null} [serverType] UploadFileAdditional serverType
         * @property {string|null} [path] UploadFileAdditional path
         * @property {number|null} [fileType] UploadFileAdditional fileType
         * @property {Proto.INullBoolean|null} [uploaded] UploadFileAdditional uploaded
         * @property {Proto.INullBoolean|null} [converted] UploadFileAdditional converted
         * @property {string|null} [language] UploadFileAdditional language
         * @property {string|null} [fileURL] UploadFileAdditional fileURL
         * @property {number|Long|null} [registerDate] UploadFileAdditional registerDate
         * @property {number|Long|null} [modifyDate] UploadFileAdditional modifyDate
         * @property {number|null} [fileStatus] UploadFileAdditional fileStatus
         * @property {Proto.IUploadFile|null} [convertFile] UploadFileAdditional convertFile
         * @property {string|null} [fileExt] UploadFileAdditional fileExt
         */

        /**
         * Constructs a new UploadFileAdditional.
         * @memberof Proto
         * @classdesc Represents an UploadFileAdditional.
         * @implements IUploadFileAdditional
         * @constructor
         * @param {Proto.IUploadFileAdditional=} [p] Properties to set
         */
        function UploadFileAdditional(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UploadFileAdditional uid.
         * @member {string} uid
         * @memberof Proto.UploadFileAdditional
         * @instance
         */
        UploadFileAdditional.prototype.uid = "";

        /**
         * UploadFileAdditional inputUID.
         * @member {string} inputUID
         * @memberof Proto.UploadFileAdditional
         * @instance
         */
        UploadFileAdditional.prototype.inputUID = "";

        /**
         * UploadFileAdditional type.
         * @member {number} type
         * @memberof Proto.UploadFileAdditional
         * @instance
         */
        UploadFileAdditional.prototype.type = 0;

        /**
         * UploadFileAdditional userUID.
         * @member {string} userUID
         * @memberof Proto.UploadFileAdditional
         * @instance
         */
        UploadFileAdditional.prototype.userUID = "";

        /**
         * UploadFileAdditional serverType.
         * @member {number} serverType
         * @memberof Proto.UploadFileAdditional
         * @instance
         */
        UploadFileAdditional.prototype.serverType = 0;

        /**
         * UploadFileAdditional path.
         * @member {string} path
         * @memberof Proto.UploadFileAdditional
         * @instance
         */
        UploadFileAdditional.prototype.path = "";

        /**
         * UploadFileAdditional fileType.
         * @member {number} fileType
         * @memberof Proto.UploadFileAdditional
         * @instance
         */
        UploadFileAdditional.prototype.fileType = 0;

        /**
         * UploadFileAdditional uploaded.
         * @member {Proto.INullBoolean|null|undefined} uploaded
         * @memberof Proto.UploadFileAdditional
         * @instance
         */
        UploadFileAdditional.prototype.uploaded = null;

        /**
         * UploadFileAdditional converted.
         * @member {Proto.INullBoolean|null|undefined} converted
         * @memberof Proto.UploadFileAdditional
         * @instance
         */
        UploadFileAdditional.prototype.converted = null;

        /**
         * UploadFileAdditional language.
         * @member {string} language
         * @memberof Proto.UploadFileAdditional
         * @instance
         */
        UploadFileAdditional.prototype.language = "";

        /**
         * UploadFileAdditional fileURL.
         * @member {string} fileURL
         * @memberof Proto.UploadFileAdditional
         * @instance
         */
        UploadFileAdditional.prototype.fileURL = "";

        /**
         * UploadFileAdditional registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.UploadFileAdditional
         * @instance
         */
        UploadFileAdditional.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UploadFileAdditional modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.UploadFileAdditional
         * @instance
         */
        UploadFileAdditional.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UploadFileAdditional fileStatus.
         * @member {number} fileStatus
         * @memberof Proto.UploadFileAdditional
         * @instance
         */
        UploadFileAdditional.prototype.fileStatus = 0;

        /**
         * UploadFileAdditional convertFile.
         * @member {Proto.IUploadFile|null|undefined} convertFile
         * @memberof Proto.UploadFileAdditional
         * @instance
         */
        UploadFileAdditional.prototype.convertFile = null;

        /**
         * UploadFileAdditional fileExt.
         * @member {string} fileExt
         * @memberof Proto.UploadFileAdditional
         * @instance
         */
        UploadFileAdditional.prototype.fileExt = "";

        /**
         * Creates a new UploadFileAdditional instance using the specified properties.
         * @function create
         * @memberof Proto.UploadFileAdditional
         * @static
         * @param {Proto.IUploadFileAdditional=} [properties] Properties to set
         * @returns {Proto.UploadFileAdditional} UploadFileAdditional instance
         */
        UploadFileAdditional.create = function create(properties) {
            return new UploadFileAdditional(properties);
        };

        /**
         * Encodes the specified UploadFileAdditional message. Does not implicitly {@link Proto.UploadFileAdditional.verify|verify} messages.
         * @function encode
         * @memberof Proto.UploadFileAdditional
         * @static
         * @param {Proto.IUploadFileAdditional} m UploadFileAdditional message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadFileAdditional.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.inputUID != null && Object.hasOwnProperty.call(m, "inputUID"))
                w.uint32(18).string(m.inputUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(24).int32(m.type);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(34).string(m.userUID);
            if (m.serverType != null && Object.hasOwnProperty.call(m, "serverType"))
                w.uint32(40).int32(m.serverType);
            if (m.path != null && Object.hasOwnProperty.call(m, "path"))
                w.uint32(50).string(m.path);
            if (m.fileType != null && Object.hasOwnProperty.call(m, "fileType"))
                w.uint32(56).int32(m.fileType);
            if (m.uploaded != null && Object.hasOwnProperty.call(m, "uploaded"))
                $root.Proto.NullBoolean.encode(m.uploaded, w.uint32(66).fork()).ldelim();
            if (m.converted != null && Object.hasOwnProperty.call(m, "converted"))
                $root.Proto.NullBoolean.encode(m.converted, w.uint32(74).fork()).ldelim();
            if (m.language != null && Object.hasOwnProperty.call(m, "language"))
                w.uint32(82).string(m.language);
            if (m.fileURL != null && Object.hasOwnProperty.call(m, "fileURL"))
                w.uint32(90).string(m.fileURL);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(96).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(104).int64(m.modifyDate);
            if (m.fileStatus != null && Object.hasOwnProperty.call(m, "fileStatus"))
                w.uint32(112).int32(m.fileStatus);
            if (m.convertFile != null && Object.hasOwnProperty.call(m, "convertFile"))
                $root.Proto.UploadFile.encode(m.convertFile, w.uint32(122).fork()).ldelim();
            if (m.fileExt != null && Object.hasOwnProperty.call(m, "fileExt"))
                w.uint32(130).string(m.fileExt);
            return w;
        };

        /**
         * Decodes an UploadFileAdditional message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.UploadFileAdditional
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.UploadFileAdditional} UploadFileAdditional
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadFileAdditional.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.UploadFileAdditional();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.inputUID = r.string();
                    break;
                case 3:
                    m.type = r.int32();
                    break;
                case 4:
                    m.userUID = r.string();
                    break;
                case 5:
                    m.serverType = r.int32();
                    break;
                case 6:
                    m.path = r.string();
                    break;
                case 7:
                    m.fileType = r.int32();
                    break;
                case 8:
                    m.uploaded = $root.Proto.NullBoolean.decode(r, r.uint32());
                    break;
                case 9:
                    m.converted = $root.Proto.NullBoolean.decode(r, r.uint32());
                    break;
                case 10:
                    m.language = r.string();
                    break;
                case 11:
                    m.fileURL = r.string();
                    break;
                case 12:
                    m.registerDate = r.int64();
                    break;
                case 13:
                    m.modifyDate = r.int64();
                    break;
                case 14:
                    m.fileStatus = r.int32();
                    break;
                case 15:
                    m.convertFile = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 16:
                    m.fileExt = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an UploadFileAdditional message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.UploadFileAdditional
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.UploadFileAdditional} UploadFileAdditional
         */
        UploadFileAdditional.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.UploadFileAdditional)
                return d;
            var m = new $root.Proto.UploadFileAdditional();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.inputUID != null) {
                m.inputUID = String(d.inputUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.serverType != null) {
                m.serverType = d.serverType | 0;
            }
            if (d.path != null) {
                m.path = String(d.path);
            }
            if (d.fileType != null) {
                m.fileType = d.fileType | 0;
            }
            if (d.uploaded != null) {
                if (typeof d.uploaded !== "object")
                    throw TypeError(".Proto.UploadFileAdditional.uploaded: object expected");
                m.uploaded = $root.Proto.NullBoolean.fromObject(d.uploaded);
            }
            if (d.converted != null) {
                if (typeof d.converted !== "object")
                    throw TypeError(".Proto.UploadFileAdditional.converted: object expected");
                m.converted = $root.Proto.NullBoolean.fromObject(d.converted);
            }
            if (d.language != null) {
                m.language = String(d.language);
            }
            if (d.fileURL != null) {
                m.fileURL = String(d.fileURL);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.fileStatus != null) {
                m.fileStatus = d.fileStatus | 0;
            }
            if (d.convertFile != null) {
                if (typeof d.convertFile !== "object")
                    throw TypeError(".Proto.UploadFileAdditional.convertFile: object expected");
                m.convertFile = $root.Proto.UploadFile.fromObject(d.convertFile);
            }
            if (d.fileExt != null) {
                m.fileExt = String(d.fileExt);
            }
            return m;
        };

        /**
         * Creates a plain object from an UploadFileAdditional message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.UploadFileAdditional
         * @static
         * @param {Proto.UploadFileAdditional} m UploadFileAdditional
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadFileAdditional.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.inputUID = "";
                d.type = 0;
                d.userUID = "";
                d.serverType = 0;
                d.path = "";
                d.fileType = 0;
                d.uploaded = null;
                d.converted = null;
                d.language = "";
                d.fileURL = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.fileStatus = 0;
                d.convertFile = null;
                d.fileExt = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.inputUID != null && m.hasOwnProperty("inputUID")) {
                d.inputUID = m.inputUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.serverType != null && m.hasOwnProperty("serverType")) {
                d.serverType = m.serverType;
            }
            if (m.path != null && m.hasOwnProperty("path")) {
                d.path = m.path;
            }
            if (m.fileType != null && m.hasOwnProperty("fileType")) {
                d.fileType = m.fileType;
            }
            if (m.uploaded != null && m.hasOwnProperty("uploaded")) {
                d.uploaded = $root.Proto.NullBoolean.toObject(m.uploaded, o);
            }
            if (m.converted != null && m.hasOwnProperty("converted")) {
                d.converted = $root.Proto.NullBoolean.toObject(m.converted, o);
            }
            if (m.language != null && m.hasOwnProperty("language")) {
                d.language = m.language;
            }
            if (m.fileURL != null && m.hasOwnProperty("fileURL")) {
                d.fileURL = m.fileURL;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.fileStatus != null && m.hasOwnProperty("fileStatus")) {
                d.fileStatus = m.fileStatus;
            }
            if (m.convertFile != null && m.hasOwnProperty("convertFile")) {
                d.convertFile = $root.Proto.UploadFile.toObject(m.convertFile, o);
            }
            if (m.fileExt != null && m.hasOwnProperty("fileExt")) {
                d.fileExt = m.fileExt;
            }
            return d;
        };

        /**
         * Converts this UploadFileAdditional to JSON.
         * @function toJSON
         * @memberof Proto.UploadFileAdditional
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadFileAdditional.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadFileAdditional;
    })();

    Proto.UploadFileAdditionalList = (function() {

        /**
         * Properties of an UploadFileAdditionalList.
         * @memberof Proto
         * @interface IUploadFileAdditionalList
         * @property {Array.<Proto.IUploadFileAdditional>|null} [value] UploadFileAdditionalList value
         */

        /**
         * Constructs a new UploadFileAdditionalList.
         * @memberof Proto
         * @classdesc Represents an UploadFileAdditionalList.
         * @implements IUploadFileAdditionalList
         * @constructor
         * @param {Proto.IUploadFileAdditionalList=} [p] Properties to set
         */
        function UploadFileAdditionalList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UploadFileAdditionalList value.
         * @member {Array.<Proto.IUploadFileAdditional>} value
         * @memberof Proto.UploadFileAdditionalList
         * @instance
         */
        UploadFileAdditionalList.prototype.value = $util.emptyArray;

        /**
         * Creates a new UploadFileAdditionalList instance using the specified properties.
         * @function create
         * @memberof Proto.UploadFileAdditionalList
         * @static
         * @param {Proto.IUploadFileAdditionalList=} [properties] Properties to set
         * @returns {Proto.UploadFileAdditionalList} UploadFileAdditionalList instance
         */
        UploadFileAdditionalList.create = function create(properties) {
            return new UploadFileAdditionalList(properties);
        };

        /**
         * Encodes the specified UploadFileAdditionalList message. Does not implicitly {@link Proto.UploadFileAdditionalList.verify|verify} messages.
         * @function encode
         * @memberof Proto.UploadFileAdditionalList
         * @static
         * @param {Proto.IUploadFileAdditionalList} m UploadFileAdditionalList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadFileAdditionalList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.UploadFileAdditional.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes an UploadFileAdditionalList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.UploadFileAdditionalList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.UploadFileAdditionalList} UploadFileAdditionalList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadFileAdditionalList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.UploadFileAdditionalList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.UploadFileAdditional.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an UploadFileAdditionalList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.UploadFileAdditionalList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.UploadFileAdditionalList} UploadFileAdditionalList
         */
        UploadFileAdditionalList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.UploadFileAdditionalList)
                return d;
            var m = new $root.Proto.UploadFileAdditionalList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.UploadFileAdditionalList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.UploadFileAdditionalList.value: object expected");
                    m.value[i] = $root.Proto.UploadFileAdditional.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from an UploadFileAdditionalList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.UploadFileAdditionalList
         * @static
         * @param {Proto.UploadFileAdditionalList} m UploadFileAdditionalList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadFileAdditionalList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.UploadFileAdditional.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this UploadFileAdditionalList to JSON.
         * @function toJSON
         * @memberof Proto.UploadFileAdditionalList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadFileAdditionalList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadFileAdditionalList;
    })();

    Proto.UploadFileOutput = (function() {

        /**
         * Properties of an UploadFileOutput.
         * @memberof Proto
         * @interface IUploadFileOutput
         * @property {string|null} [inputUID] UploadFileOutput inputUID
         * @property {number|null} [type] UploadFileOutput type
         * @property {number|null} [serverType] UploadFileOutput serverType
         * @property {string|null} [path] UploadFileOutput path
         * @property {Proto.INullBoolean|null} [converted] UploadFileOutput converted
         * @property {string|null} [orderInfo] UploadFileOutput orderInfo
         * @property {number|null} [progress] UploadFileOutput progress
         * @property {string|null} [logUrl] UploadFileOutput logUrl
         * @property {string|null} [fileURL] UploadFileOutput fileURL
         * @property {number|Long|null} [registerDate] UploadFileOutput registerDate
         * @property {number|Long|null} [modifyDate] UploadFileOutput modifyDate
         * @property {number|null} [status] UploadFileOutput status
         * @property {string|null} [password] UploadFileOutput password
         */

        /**
         * Constructs a new UploadFileOutput.
         * @memberof Proto
         * @classdesc Represents an UploadFileOutput.
         * @implements IUploadFileOutput
         * @constructor
         * @param {Proto.IUploadFileOutput=} [p] Properties to set
         */
        function UploadFileOutput(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UploadFileOutput inputUID.
         * @member {string} inputUID
         * @memberof Proto.UploadFileOutput
         * @instance
         */
        UploadFileOutput.prototype.inputUID = "";

        /**
         * UploadFileOutput type.
         * @member {number} type
         * @memberof Proto.UploadFileOutput
         * @instance
         */
        UploadFileOutput.prototype.type = 0;

        /**
         * UploadFileOutput serverType.
         * @member {number} serverType
         * @memberof Proto.UploadFileOutput
         * @instance
         */
        UploadFileOutput.prototype.serverType = 0;

        /**
         * UploadFileOutput path.
         * @member {string} path
         * @memberof Proto.UploadFileOutput
         * @instance
         */
        UploadFileOutput.prototype.path = "";

        /**
         * UploadFileOutput converted.
         * @member {Proto.INullBoolean|null|undefined} converted
         * @memberof Proto.UploadFileOutput
         * @instance
         */
        UploadFileOutput.prototype.converted = null;

        /**
         * UploadFileOutput orderInfo.
         * @member {string} orderInfo
         * @memberof Proto.UploadFileOutput
         * @instance
         */
        UploadFileOutput.prototype.orderInfo = "";

        /**
         * UploadFileOutput progress.
         * @member {number} progress
         * @memberof Proto.UploadFileOutput
         * @instance
         */
        UploadFileOutput.prototype.progress = 0;

        /**
         * UploadFileOutput logUrl.
         * @member {string} logUrl
         * @memberof Proto.UploadFileOutput
         * @instance
         */
        UploadFileOutput.prototype.logUrl = "";

        /**
         * UploadFileOutput fileURL.
         * @member {string} fileURL
         * @memberof Proto.UploadFileOutput
         * @instance
         */
        UploadFileOutput.prototype.fileURL = "";

        /**
         * UploadFileOutput registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.UploadFileOutput
         * @instance
         */
        UploadFileOutput.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UploadFileOutput modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.UploadFileOutput
         * @instance
         */
        UploadFileOutput.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UploadFileOutput status.
         * @member {number} status
         * @memberof Proto.UploadFileOutput
         * @instance
         */
        UploadFileOutput.prototype.status = 0;

        /**
         * UploadFileOutput password.
         * @member {string} password
         * @memberof Proto.UploadFileOutput
         * @instance
         */
        UploadFileOutput.prototype.password = "";

        /**
         * Creates a new UploadFileOutput instance using the specified properties.
         * @function create
         * @memberof Proto.UploadFileOutput
         * @static
         * @param {Proto.IUploadFileOutput=} [properties] Properties to set
         * @returns {Proto.UploadFileOutput} UploadFileOutput instance
         */
        UploadFileOutput.create = function create(properties) {
            return new UploadFileOutput(properties);
        };

        /**
         * Encodes the specified UploadFileOutput message. Does not implicitly {@link Proto.UploadFileOutput.verify|verify} messages.
         * @function encode
         * @memberof Proto.UploadFileOutput
         * @static
         * @param {Proto.IUploadFileOutput} m UploadFileOutput message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadFileOutput.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.inputUID != null && Object.hasOwnProperty.call(m, "inputUID"))
                w.uint32(10).string(m.inputUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.serverType != null && Object.hasOwnProperty.call(m, "serverType"))
                w.uint32(24).int32(m.serverType);
            if (m.path != null && Object.hasOwnProperty.call(m, "path"))
                w.uint32(34).string(m.path);
            if (m.converted != null && Object.hasOwnProperty.call(m, "converted"))
                $root.Proto.NullBoolean.encode(m.converted, w.uint32(42).fork()).ldelim();
            if (m.orderInfo != null && Object.hasOwnProperty.call(m, "orderInfo"))
                w.uint32(50).string(m.orderInfo);
            if (m.progress != null && Object.hasOwnProperty.call(m, "progress"))
                w.uint32(56).int32(m.progress);
            if (m.logUrl != null && Object.hasOwnProperty.call(m, "logUrl"))
                w.uint32(66).string(m.logUrl);
            if (m.fileURL != null && Object.hasOwnProperty.call(m, "fileURL"))
                w.uint32(74).string(m.fileURL);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(80).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(88).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(96).int32(m.status);
            if (m.password != null && Object.hasOwnProperty.call(m, "password"))
                w.uint32(106).string(m.password);
            return w;
        };

        /**
         * Decodes an UploadFileOutput message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.UploadFileOutput
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.UploadFileOutput} UploadFileOutput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadFileOutput.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.UploadFileOutput();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.inputUID = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.serverType = r.int32();
                    break;
                case 4:
                    m.path = r.string();
                    break;
                case 5:
                    m.converted = $root.Proto.NullBoolean.decode(r, r.uint32());
                    break;
                case 6:
                    m.orderInfo = r.string();
                    break;
                case 7:
                    m.progress = r.int32();
                    break;
                case 8:
                    m.logUrl = r.string();
                    break;
                case 9:
                    m.fileURL = r.string();
                    break;
                case 10:
                    m.registerDate = r.int64();
                    break;
                case 11:
                    m.modifyDate = r.int64();
                    break;
                case 12:
                    m.status = r.int32();
                    break;
                case 13:
                    m.password = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an UploadFileOutput message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.UploadFileOutput
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.UploadFileOutput} UploadFileOutput
         */
        UploadFileOutput.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.UploadFileOutput)
                return d;
            var m = new $root.Proto.UploadFileOutput();
            if (d.inputUID != null) {
                m.inputUID = String(d.inputUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.serverType != null) {
                m.serverType = d.serverType | 0;
            }
            if (d.path != null) {
                m.path = String(d.path);
            }
            if (d.converted != null) {
                if (typeof d.converted !== "object")
                    throw TypeError(".Proto.UploadFileOutput.converted: object expected");
                m.converted = $root.Proto.NullBoolean.fromObject(d.converted);
            }
            if (d.orderInfo != null) {
                m.orderInfo = String(d.orderInfo);
            }
            if (d.progress != null) {
                m.progress = d.progress | 0;
            }
            if (d.logUrl != null) {
                m.logUrl = String(d.logUrl);
            }
            if (d.fileURL != null) {
                m.fileURL = String(d.fileURL);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.password != null) {
                m.password = String(d.password);
            }
            return m;
        };

        /**
         * Creates a plain object from an UploadFileOutput message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.UploadFileOutput
         * @static
         * @param {Proto.UploadFileOutput} m UploadFileOutput
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadFileOutput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.inputUID = "";
                d.type = 0;
                d.serverType = 0;
                d.path = "";
                d.converted = null;
                d.orderInfo = "";
                d.progress = 0;
                d.logUrl = "";
                d.fileURL = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.password = "";
            }
            if (m.inputUID != null && m.hasOwnProperty("inputUID")) {
                d.inputUID = m.inputUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.serverType != null && m.hasOwnProperty("serverType")) {
                d.serverType = m.serverType;
            }
            if (m.path != null && m.hasOwnProperty("path")) {
                d.path = m.path;
            }
            if (m.converted != null && m.hasOwnProperty("converted")) {
                d.converted = $root.Proto.NullBoolean.toObject(m.converted, o);
            }
            if (m.orderInfo != null && m.hasOwnProperty("orderInfo")) {
                d.orderInfo = m.orderInfo;
            }
            if (m.progress != null && m.hasOwnProperty("progress")) {
                d.progress = m.progress;
            }
            if (m.logUrl != null && m.hasOwnProperty("logUrl")) {
                d.logUrl = m.logUrl;
            }
            if (m.fileURL != null && m.hasOwnProperty("fileURL")) {
                d.fileURL = m.fileURL;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.password != null && m.hasOwnProperty("password")) {
                d.password = m.password;
            }
            return d;
        };

        /**
         * Converts this UploadFileOutput to JSON.
         * @function toJSON
         * @memberof Proto.UploadFileOutput
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadFileOutput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadFileOutput;
    })();

    Proto.PlaylistContentTitleMap = (function() {

        /**
         * Properties of a PlaylistContentTitleMap.
         * @memberof Proto
         * @interface IPlaylistContentTitleMap
         * @property {string|null} [playlistUID] PlaylistContentTitleMap playlistUID
         * @property {string|null} [contentUID] PlaylistContentTitleMap contentUID
         * @property {string|null} [playlistTitle] PlaylistContentTitleMap playlistTitle
         * @property {number|Long|null} [registerDate] PlaylistContentTitleMap registerDate
         * @property {number|Long|null} [modifyDate] PlaylistContentTitleMap modifyDate
         * @property {number|null} [playlistType] PlaylistContentTitleMap playlistType
         */

        /**
         * Constructs a new PlaylistContentTitleMap.
         * @memberof Proto
         * @classdesc Represents a PlaylistContentTitleMap.
         * @implements IPlaylistContentTitleMap
         * @constructor
         * @param {Proto.IPlaylistContentTitleMap=} [p] Properties to set
         */
        function PlaylistContentTitleMap(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PlaylistContentTitleMap playlistUID.
         * @member {string} playlistUID
         * @memberof Proto.PlaylistContentTitleMap
         * @instance
         */
        PlaylistContentTitleMap.prototype.playlistUID = "";

        /**
         * PlaylistContentTitleMap contentUID.
         * @member {string} contentUID
         * @memberof Proto.PlaylistContentTitleMap
         * @instance
         */
        PlaylistContentTitleMap.prototype.contentUID = "";

        /**
         * PlaylistContentTitleMap playlistTitle.
         * @member {string} playlistTitle
         * @memberof Proto.PlaylistContentTitleMap
         * @instance
         */
        PlaylistContentTitleMap.prototype.playlistTitle = "";

        /**
         * PlaylistContentTitleMap registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.PlaylistContentTitleMap
         * @instance
         */
        PlaylistContentTitleMap.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlaylistContentTitleMap modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.PlaylistContentTitleMap
         * @instance
         */
        PlaylistContentTitleMap.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlaylistContentTitleMap playlistType.
         * @member {number} playlistType
         * @memberof Proto.PlaylistContentTitleMap
         * @instance
         */
        PlaylistContentTitleMap.prototype.playlistType = 0;

        /**
         * Creates a new PlaylistContentTitleMap instance using the specified properties.
         * @function create
         * @memberof Proto.PlaylistContentTitleMap
         * @static
         * @param {Proto.IPlaylistContentTitleMap=} [properties] Properties to set
         * @returns {Proto.PlaylistContentTitleMap} PlaylistContentTitleMap instance
         */
        PlaylistContentTitleMap.create = function create(properties) {
            return new PlaylistContentTitleMap(properties);
        };

        /**
         * Encodes the specified PlaylistContentTitleMap message. Does not implicitly {@link Proto.PlaylistContentTitleMap.verify|verify} messages.
         * @function encode
         * @memberof Proto.PlaylistContentTitleMap
         * @static
         * @param {Proto.IPlaylistContentTitleMap} m PlaylistContentTitleMap message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlaylistContentTitleMap.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.playlistUID != null && Object.hasOwnProperty.call(m, "playlistUID"))
                w.uint32(10).string(m.playlistUID);
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(18).string(m.contentUID);
            if (m.playlistTitle != null && Object.hasOwnProperty.call(m, "playlistTitle"))
                w.uint32(26).string(m.playlistTitle);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            if (m.playlistType != null && Object.hasOwnProperty.call(m, "playlistType"))
                w.uint32(48).int32(m.playlistType);
            return w;
        };

        /**
         * Decodes a PlaylistContentTitleMap message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.PlaylistContentTitleMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.PlaylistContentTitleMap} PlaylistContentTitleMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlaylistContentTitleMap.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.PlaylistContentTitleMap();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.playlistUID = r.string();
                    break;
                case 2:
                    m.contentUID = r.string();
                    break;
                case 3:
                    m.playlistTitle = r.string();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                case 6:
                    m.playlistType = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PlaylistContentTitleMap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.PlaylistContentTitleMap
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.PlaylistContentTitleMap} PlaylistContentTitleMap
         */
        PlaylistContentTitleMap.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.PlaylistContentTitleMap)
                return d;
            var m = new $root.Proto.PlaylistContentTitleMap();
            if (d.playlistUID != null) {
                m.playlistUID = String(d.playlistUID);
            }
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.playlistTitle != null) {
                m.playlistTitle = String(d.playlistTitle);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.playlistType != null) {
                m.playlistType = d.playlistType | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a PlaylistContentTitleMap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.PlaylistContentTitleMap
         * @static
         * @param {Proto.PlaylistContentTitleMap} m PlaylistContentTitleMap
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlaylistContentTitleMap.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.playlistUID = "";
                d.contentUID = "";
                d.playlistTitle = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.playlistType = 0;
            }
            if (m.playlistUID != null && m.hasOwnProperty("playlistUID")) {
                d.playlistUID = m.playlistUID;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.playlistTitle != null && m.hasOwnProperty("playlistTitle")) {
                d.playlistTitle = m.playlistTitle;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.playlistType != null && m.hasOwnProperty("playlistType")) {
                d.playlistType = m.playlistType;
            }
            return d;
        };

        /**
         * Converts this PlaylistContentTitleMap to JSON.
         * @function toJSON
         * @memberof Proto.PlaylistContentTitleMap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlaylistContentTitleMap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlaylistContentTitleMap;
    })();

    Proto.Content = (function() {

        /**
         * Properties of a Content.
         * @memberof Proto
         * @interface IContent
         * @property {string|null} [uid] Content uid
         * @property {string|null} [title] Content title
         * @property {string|null} [userUID] Content userUID
         * @property {string|null} [description] Content description
         * @property {Proto.IUploadFile|null} [file] Content file
         * @property {number|null} [distribution] Content distribution
         * @property {number|Long|null} [startDate] Content startDate
         * @property {number|Long|null} [endDate] Content endDate
         * @property {number|null} [type] Content type
         * @property {number|Long|null} [registerDate] Content registerDate
         * @property {number|Long|null} [modifyDate] Content modifyDate
         * @property {number|null} [status] Content status
         * @property {string|null} [companyUID] Content companyUID
         * @property {number|null} [endTime] Content endTime
         * @property {number|null} [ageRating] Content ageRating
         * @property {string|null} [playlistUID] Content playlistUID
         * @property {string|null} [accessCount] Content accessCount
         * @property {Array.<string>|null} [playlistUIDList] Content playlistUIDList
         * @property {Array.<Proto.IContentChapter>|null} [chapters] Content chapters
         * @property {Array.<Proto.IContentEvent>|null} [events] Content events
         * @property {number|null} [allowComment] Content allowComment
         * @property {string|null} [likeCount] Content likeCount
         * @property {string|null} [commentCount] Content commentCount
         * @property {string|null} [oauthUID] Content oauthUID
         * @property {number|null} [allowProduct] Content allowProduct
         * @property {string|null} [productOrder] Content productOrder
         * @property {Array.<Proto.IContentProduct>|null} [products] Content products
         * @property {number|null} [commentVisible] Content commentVisible
         * @property {Array.<Proto.IPlaylistContentTitleMap>|null} [playlistMapList] Content playlistMapList
         * @property {number|null} [publicPolicy] Content publicPolicy
         * @property {Array.<Proto.IContentAdditionalFile>|null} [additionalFileList] Content additionalFileList
         * @property {Array.<Proto.IContentFileStash>|null} [fileStashList] Content fileStashList
         * @property {Array.<Proto.IHashTag>|null} [tagList] Content tagList
         * @property {Array.<Proto.IContentCommerceMap>|null} [commerceMapList] Content commerceMapList
         * @property {number|null} [commercePolicy] Content commercePolicy
         * @property {number|null} [videoPolicy] Content videoPolicy
         * @property {Array.<Proto.IHashTag>|null} [adminTagList] Content adminTagList
         * @property {number|null} [fundingPolicy] Content fundingPolicy
         * @property {Array.<Proto.IContentFundingMap>|null} [fundingMapList] Content fundingMapList
         * @property {boolean|null} [hiddenDisplayDate] Content hiddenDisplayDate
         * @property {number|null} [mapPolicy] Content mapPolicy
         * @property {number|null} [commentMapPolicy] Content commentMapPolicy
         * @property {Array.<Proto.IContentMapGroup>|null} [mapGroupList] Content mapGroupList
         * @property {number|null} [ticketPolicy] Content ticketPolicy
         * @property {Array.<Proto.IContentTicketMap>|null} [ticketMapList] Content ticketMapList
         * @property {string|null} [readCount] Content readCount
         * @property {string|null} [reactionCount] Content reactionCount
         * @property {Array.<Proto.IContentReaction>|null} [reactionGroupList] Content reactionGroupList
         * @property {boolean|null} [read] Content read
         * @property {Array.<Proto.IUser>|null} [readUserList] Content readUserList
         * @property {string|null} [pollUID] Content pollUID
         * @property {string|null} [pollText] Content pollText
         * @property {string|null} [paymentDescription] Content paymentDescription
         * @property {number|null} [paymentDescriptionVersion] Content paymentDescriptionVersion
         * @property {boolean|null} [hasMoreDescription] Content hasMoreDescription
         * @property {number|null} [viewerType] Content viewerType
         * @property {number|null} [workflowPolicy] Content workflowPolicy
         * @property {Array.<Proto.IContentWorkflowMap>|null} [workflowMapList] Content workflowMapList
         * @property {Proto.IPlaylistTVOD|null} [playlistTVOD] Content playlistTVOD
         */

        /**
         * Constructs a new Content.
         * @memberof Proto
         * @classdesc Represents a Content.
         * @implements IContent
         * @constructor
         * @param {Proto.IContent=} [p] Properties to set
         */
        function Content(p) {
            this.playlistUIDList = [];
            this.chapters = [];
            this.events = [];
            this.products = [];
            this.playlistMapList = [];
            this.additionalFileList = [];
            this.fileStashList = [];
            this.tagList = [];
            this.commerceMapList = [];
            this.adminTagList = [];
            this.fundingMapList = [];
            this.mapGroupList = [];
            this.ticketMapList = [];
            this.reactionGroupList = [];
            this.readUserList = [];
            this.workflowMapList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Content uid.
         * @member {string} uid
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.uid = "";

        /**
         * Content title.
         * @member {string} title
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.title = "";

        /**
         * Content userUID.
         * @member {string} userUID
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.userUID = "";

        /**
         * Content description.
         * @member {string} description
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.description = "";

        /**
         * Content file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.file = null;

        /**
         * Content distribution.
         * @member {number} distribution
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.distribution = 0;

        /**
         * Content startDate.
         * @member {number|Long} startDate
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Content endDate.
         * @member {number|Long} endDate
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Content type.
         * @member {number} type
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.type = 0;

        /**
         * Content registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Content modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Content status.
         * @member {number} status
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.status = 0;

        /**
         * Content companyUID.
         * @member {string} companyUID
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.companyUID = "";

        /**
         * Content endTime.
         * @member {number} endTime
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.endTime = 0;

        /**
         * Content ageRating.
         * @member {number} ageRating
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.ageRating = 0;

        /**
         * Content playlistUID.
         * @member {string} playlistUID
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.playlistUID = "";

        /**
         * Content accessCount.
         * @member {string} accessCount
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.accessCount = "";

        /**
         * Content playlistUIDList.
         * @member {Array.<string>} playlistUIDList
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.playlistUIDList = $util.emptyArray;

        /**
         * Content chapters.
         * @member {Array.<Proto.IContentChapter>} chapters
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.chapters = $util.emptyArray;

        /**
         * Content events.
         * @member {Array.<Proto.IContentEvent>} events
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.events = $util.emptyArray;

        /**
         * Content allowComment.
         * @member {number} allowComment
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.allowComment = 0;

        /**
         * Content likeCount.
         * @member {string} likeCount
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.likeCount = "";

        /**
         * Content commentCount.
         * @member {string} commentCount
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.commentCount = "";

        /**
         * Content oauthUID.
         * @member {string} oauthUID
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.oauthUID = "";

        /**
         * Content allowProduct.
         * @member {number} allowProduct
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.allowProduct = 0;

        /**
         * Content productOrder.
         * @member {string} productOrder
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.productOrder = "";

        /**
         * Content products.
         * @member {Array.<Proto.IContentProduct>} products
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.products = $util.emptyArray;

        /**
         * Content commentVisible.
         * @member {number} commentVisible
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.commentVisible = 0;

        /**
         * Content playlistMapList.
         * @member {Array.<Proto.IPlaylistContentTitleMap>} playlistMapList
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.playlistMapList = $util.emptyArray;

        /**
         * Content publicPolicy.
         * @member {number} publicPolicy
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.publicPolicy = 0;

        /**
         * Content additionalFileList.
         * @member {Array.<Proto.IContentAdditionalFile>} additionalFileList
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.additionalFileList = $util.emptyArray;

        /**
         * Content fileStashList.
         * @member {Array.<Proto.IContentFileStash>} fileStashList
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.fileStashList = $util.emptyArray;

        /**
         * Content tagList.
         * @member {Array.<Proto.IHashTag>} tagList
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.tagList = $util.emptyArray;

        /**
         * Content commerceMapList.
         * @member {Array.<Proto.IContentCommerceMap>} commerceMapList
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.commerceMapList = $util.emptyArray;

        /**
         * Content commercePolicy.
         * @member {number} commercePolicy
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.commercePolicy = 0;

        /**
         * Content videoPolicy.
         * @member {number} videoPolicy
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.videoPolicy = 0;

        /**
         * Content adminTagList.
         * @member {Array.<Proto.IHashTag>} adminTagList
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.adminTagList = $util.emptyArray;

        /**
         * Content fundingPolicy.
         * @member {number} fundingPolicy
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.fundingPolicy = 0;

        /**
         * Content fundingMapList.
         * @member {Array.<Proto.IContentFundingMap>} fundingMapList
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.fundingMapList = $util.emptyArray;

        /**
         * Content hiddenDisplayDate.
         * @member {boolean} hiddenDisplayDate
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.hiddenDisplayDate = false;

        /**
         * Content mapPolicy.
         * @member {number} mapPolicy
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.mapPolicy = 0;

        /**
         * Content commentMapPolicy.
         * @member {number} commentMapPolicy
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.commentMapPolicy = 0;

        /**
         * Content mapGroupList.
         * @member {Array.<Proto.IContentMapGroup>} mapGroupList
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.mapGroupList = $util.emptyArray;

        /**
         * Content ticketPolicy.
         * @member {number} ticketPolicy
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.ticketPolicy = 0;

        /**
         * Content ticketMapList.
         * @member {Array.<Proto.IContentTicketMap>} ticketMapList
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.ticketMapList = $util.emptyArray;

        /**
         * Content readCount.
         * @member {string} readCount
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.readCount = "";

        /**
         * Content reactionCount.
         * @member {string} reactionCount
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.reactionCount = "";

        /**
         * Content reactionGroupList.
         * @member {Array.<Proto.IContentReaction>} reactionGroupList
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.reactionGroupList = $util.emptyArray;

        /**
         * Content read.
         * @member {boolean} read
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.read = false;

        /**
         * Content readUserList.
         * @member {Array.<Proto.IUser>} readUserList
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.readUserList = $util.emptyArray;

        /**
         * Content pollUID.
         * @member {string} pollUID
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.pollUID = "";

        /**
         * Content pollText.
         * @member {string} pollText
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.pollText = "";

        /**
         * Content paymentDescription.
         * @member {string} paymentDescription
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.paymentDescription = "";

        /**
         * Content paymentDescriptionVersion.
         * @member {number} paymentDescriptionVersion
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.paymentDescriptionVersion = 0;

        /**
         * Content hasMoreDescription.
         * @member {boolean} hasMoreDescription
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.hasMoreDescription = false;

        /**
         * Content viewerType.
         * @member {number} viewerType
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.viewerType = 0;

        /**
         * Content workflowPolicy.
         * @member {number} workflowPolicy
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.workflowPolicy = 0;

        /**
         * Content workflowMapList.
         * @member {Array.<Proto.IContentWorkflowMap>} workflowMapList
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.workflowMapList = $util.emptyArray;

        /**
         * Content playlistTVOD.
         * @member {Proto.IPlaylistTVOD|null|undefined} playlistTVOD
         * @memberof Proto.Content
         * @instance
         */
        Content.prototype.playlistTVOD = null;

        /**
         * Creates a new Content instance using the specified properties.
         * @function create
         * @memberof Proto.Content
         * @static
         * @param {Proto.IContent=} [properties] Properties to set
         * @returns {Proto.Content} Content instance
         */
        Content.create = function create(properties) {
            return new Content(properties);
        };

        /**
         * Encodes the specified Content message. Does not implicitly {@link Proto.Content.verify|verify} messages.
         * @function encode
         * @memberof Proto.Content
         * @static
         * @param {Proto.IContent} m Content message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Content.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(18).string(m.title);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(26).string(m.userUID);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(34).string(m.description);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(42).fork()).ldelim();
            if (m.distribution != null && Object.hasOwnProperty.call(m, "distribution"))
                w.uint32(48).int32(m.distribution);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(56).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(64).int64(m.endDate);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(72).int32(m.type);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(80).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(88).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(96).int32(m.status);
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(106).string(m.companyUID);
            if (m.endTime != null && Object.hasOwnProperty.call(m, "endTime"))
                w.uint32(113).double(m.endTime);
            if (m.ageRating != null && Object.hasOwnProperty.call(m, "ageRating"))
                w.uint32(128).int32(m.ageRating);
            if (m.playlistUID != null && Object.hasOwnProperty.call(m, "playlistUID"))
                w.uint32(138).string(m.playlistUID);
            if (m.accessCount != null && Object.hasOwnProperty.call(m, "accessCount"))
                w.uint32(146).string(m.accessCount);
            if (m.playlistUIDList != null && m.playlistUIDList.length) {
                for (var i = 0; i < m.playlistUIDList.length; ++i)
                    w.uint32(154).string(m.playlistUIDList[i]);
            }
            if (m.chapters != null && m.chapters.length) {
                for (var i = 0; i < m.chapters.length; ++i)
                    $root.Proto.ContentChapter.encode(m.chapters[i], w.uint32(162).fork()).ldelim();
            }
            if (m.events != null && m.events.length) {
                for (var i = 0; i < m.events.length; ++i)
                    $root.Proto.ContentEvent.encode(m.events[i], w.uint32(170).fork()).ldelim();
            }
            if (m.allowComment != null && Object.hasOwnProperty.call(m, "allowComment"))
                w.uint32(176).int32(m.allowComment);
            if (m.likeCount != null && Object.hasOwnProperty.call(m, "likeCount"))
                w.uint32(186).string(m.likeCount);
            if (m.commentCount != null && Object.hasOwnProperty.call(m, "commentCount"))
                w.uint32(194).string(m.commentCount);
            if (m.oauthUID != null && Object.hasOwnProperty.call(m, "oauthUID"))
                w.uint32(202).string(m.oauthUID);
            if (m.allowProduct != null && Object.hasOwnProperty.call(m, "allowProduct"))
                w.uint32(208).int32(m.allowProduct);
            if (m.productOrder != null && Object.hasOwnProperty.call(m, "productOrder"))
                w.uint32(218).string(m.productOrder);
            if (m.products != null && m.products.length) {
                for (var i = 0; i < m.products.length; ++i)
                    $root.Proto.ContentProduct.encode(m.products[i], w.uint32(226).fork()).ldelim();
            }
            if (m.commentVisible != null && Object.hasOwnProperty.call(m, "commentVisible"))
                w.uint32(232).int32(m.commentVisible);
            if (m.playlistMapList != null && m.playlistMapList.length) {
                for (var i = 0; i < m.playlistMapList.length; ++i)
                    $root.Proto.PlaylistContentTitleMap.encode(m.playlistMapList[i], w.uint32(242).fork()).ldelim();
            }
            if (m.publicPolicy != null && Object.hasOwnProperty.call(m, "publicPolicy"))
                w.uint32(248).int32(m.publicPolicy);
            if (m.additionalFileList != null && m.additionalFileList.length) {
                for (var i = 0; i < m.additionalFileList.length; ++i)
                    $root.Proto.ContentAdditionalFile.encode(m.additionalFileList[i], w.uint32(258).fork()).ldelim();
            }
            if (m.fileStashList != null && m.fileStashList.length) {
                for (var i = 0; i < m.fileStashList.length; ++i)
                    $root.Proto.ContentFileStash.encode(m.fileStashList[i], w.uint32(266).fork()).ldelim();
            }
            if (m.tagList != null && m.tagList.length) {
                for (var i = 0; i < m.tagList.length; ++i)
                    $root.Proto.HashTag.encode(m.tagList[i], w.uint32(274).fork()).ldelim();
            }
            if (m.commerceMapList != null && m.commerceMapList.length) {
                for (var i = 0; i < m.commerceMapList.length; ++i)
                    $root.Proto.ContentCommerceMap.encode(m.commerceMapList[i], w.uint32(282).fork()).ldelim();
            }
            if (m.commercePolicy != null && Object.hasOwnProperty.call(m, "commercePolicy"))
                w.uint32(288).int32(m.commercePolicy);
            if (m.videoPolicy != null && Object.hasOwnProperty.call(m, "videoPolicy"))
                w.uint32(296).int32(m.videoPolicy);
            if (m.adminTagList != null && m.adminTagList.length) {
                for (var i = 0; i < m.adminTagList.length; ++i)
                    $root.Proto.HashTag.encode(m.adminTagList[i], w.uint32(306).fork()).ldelim();
            }
            if (m.fundingPolicy != null && Object.hasOwnProperty.call(m, "fundingPolicy"))
                w.uint32(312).int32(m.fundingPolicy);
            if (m.fundingMapList != null && m.fundingMapList.length) {
                for (var i = 0; i < m.fundingMapList.length; ++i)
                    $root.Proto.ContentFundingMap.encode(m.fundingMapList[i], w.uint32(322).fork()).ldelim();
            }
            if (m.hiddenDisplayDate != null && Object.hasOwnProperty.call(m, "hiddenDisplayDate"))
                w.uint32(328).bool(m.hiddenDisplayDate);
            if (m.mapPolicy != null && Object.hasOwnProperty.call(m, "mapPolicy"))
                w.uint32(336).int32(m.mapPolicy);
            if (m.commentMapPolicy != null && Object.hasOwnProperty.call(m, "commentMapPolicy"))
                w.uint32(344).int32(m.commentMapPolicy);
            if (m.mapGroupList != null && m.mapGroupList.length) {
                for (var i = 0; i < m.mapGroupList.length; ++i)
                    $root.Proto.ContentMapGroup.encode(m.mapGroupList[i], w.uint32(370).fork()).ldelim();
            }
            if (m.ticketPolicy != null && Object.hasOwnProperty.call(m, "ticketPolicy"))
                w.uint32(376).int32(m.ticketPolicy);
            if (m.ticketMapList != null && m.ticketMapList.length) {
                for (var i = 0; i < m.ticketMapList.length; ++i)
                    $root.Proto.ContentTicketMap.encode(m.ticketMapList[i], w.uint32(386).fork()).ldelim();
            }
            if (m.readCount != null && Object.hasOwnProperty.call(m, "readCount"))
                w.uint32(394).string(m.readCount);
            if (m.reactionCount != null && Object.hasOwnProperty.call(m, "reactionCount"))
                w.uint32(402).string(m.reactionCount);
            if (m.reactionGroupList != null && m.reactionGroupList.length) {
                for (var i = 0; i < m.reactionGroupList.length; ++i)
                    $root.Proto.ContentReaction.encode(m.reactionGroupList[i], w.uint32(410).fork()).ldelim();
            }
            if (m.read != null && Object.hasOwnProperty.call(m, "read"))
                w.uint32(416).bool(m.read);
            if (m.readUserList != null && m.readUserList.length) {
                for (var i = 0; i < m.readUserList.length; ++i)
                    $root.Proto.User.encode(m.readUserList[i], w.uint32(426).fork()).ldelim();
            }
            if (m.pollUID != null && Object.hasOwnProperty.call(m, "pollUID"))
                w.uint32(434).string(m.pollUID);
            if (m.pollText != null && Object.hasOwnProperty.call(m, "pollText"))
                w.uint32(442).string(m.pollText);
            if (m.paymentDescription != null && Object.hasOwnProperty.call(m, "paymentDescription"))
                w.uint32(450).string(m.paymentDescription);
            if (m.paymentDescriptionVersion != null && Object.hasOwnProperty.call(m, "paymentDescriptionVersion"))
                w.uint32(456).int32(m.paymentDescriptionVersion);
            if (m.hasMoreDescription != null && Object.hasOwnProperty.call(m, "hasMoreDescription"))
                w.uint32(464).bool(m.hasMoreDescription);
            if (m.viewerType != null && Object.hasOwnProperty.call(m, "viewerType"))
                w.uint32(472).int32(m.viewerType);
            if (m.workflowPolicy != null && Object.hasOwnProperty.call(m, "workflowPolicy"))
                w.uint32(480).int32(m.workflowPolicy);
            if (m.workflowMapList != null && m.workflowMapList.length) {
                for (var i = 0; i < m.workflowMapList.length; ++i)
                    $root.Proto.ContentWorkflowMap.encode(m.workflowMapList[i], w.uint32(490).fork()).ldelim();
            }
            if (m.playlistTVOD != null && Object.hasOwnProperty.call(m, "playlistTVOD"))
                $root.Proto.PlaylistTVOD.encode(m.playlistTVOD, w.uint32(498).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a Content message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.Content
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.Content} Content
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Content.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.Content();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.title = r.string();
                    break;
                case 3:
                    m.userUID = r.string();
                    break;
                case 4:
                    m.description = r.string();
                    break;
                case 5:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 6:
                    m.distribution = r.int32();
                    break;
                case 7:
                    m.startDate = r.int64();
                    break;
                case 8:
                    m.endDate = r.int64();
                    break;
                case 9:
                    m.type = r.int32();
                    break;
                case 10:
                    m.registerDate = r.int64();
                    break;
                case 11:
                    m.modifyDate = r.int64();
                    break;
                case 12:
                    m.status = r.int32();
                    break;
                case 13:
                    m.companyUID = r.string();
                    break;
                case 14:
                    m.endTime = r.double();
                    break;
                case 16:
                    m.ageRating = r.int32();
                    break;
                case 17:
                    m.playlistUID = r.string();
                    break;
                case 18:
                    m.accessCount = r.string();
                    break;
                case 19:
                    if (!(m.playlistUIDList && m.playlistUIDList.length))
                        m.playlistUIDList = [];
                    m.playlistUIDList.push(r.string());
                    break;
                case 20:
                    if (!(m.chapters && m.chapters.length))
                        m.chapters = [];
                    m.chapters.push($root.Proto.ContentChapter.decode(r, r.uint32()));
                    break;
                case 21:
                    if (!(m.events && m.events.length))
                        m.events = [];
                    m.events.push($root.Proto.ContentEvent.decode(r, r.uint32()));
                    break;
                case 22:
                    m.allowComment = r.int32();
                    break;
                case 23:
                    m.likeCount = r.string();
                    break;
                case 24:
                    m.commentCount = r.string();
                    break;
                case 25:
                    m.oauthUID = r.string();
                    break;
                case 26:
                    m.allowProduct = r.int32();
                    break;
                case 27:
                    m.productOrder = r.string();
                    break;
                case 28:
                    if (!(m.products && m.products.length))
                        m.products = [];
                    m.products.push($root.Proto.ContentProduct.decode(r, r.uint32()));
                    break;
                case 29:
                    m.commentVisible = r.int32();
                    break;
                case 30:
                    if (!(m.playlistMapList && m.playlistMapList.length))
                        m.playlistMapList = [];
                    m.playlistMapList.push($root.Proto.PlaylistContentTitleMap.decode(r, r.uint32()));
                    break;
                case 31:
                    m.publicPolicy = r.int32();
                    break;
                case 32:
                    if (!(m.additionalFileList && m.additionalFileList.length))
                        m.additionalFileList = [];
                    m.additionalFileList.push($root.Proto.ContentAdditionalFile.decode(r, r.uint32()));
                    break;
                case 33:
                    if (!(m.fileStashList && m.fileStashList.length))
                        m.fileStashList = [];
                    m.fileStashList.push($root.Proto.ContentFileStash.decode(r, r.uint32()));
                    break;
                case 34:
                    if (!(m.tagList && m.tagList.length))
                        m.tagList = [];
                    m.tagList.push($root.Proto.HashTag.decode(r, r.uint32()));
                    break;
                case 35:
                    if (!(m.commerceMapList && m.commerceMapList.length))
                        m.commerceMapList = [];
                    m.commerceMapList.push($root.Proto.ContentCommerceMap.decode(r, r.uint32()));
                    break;
                case 36:
                    m.commercePolicy = r.int32();
                    break;
                case 37:
                    m.videoPolicy = r.int32();
                    break;
                case 38:
                    if (!(m.adminTagList && m.adminTagList.length))
                        m.adminTagList = [];
                    m.adminTagList.push($root.Proto.HashTag.decode(r, r.uint32()));
                    break;
                case 39:
                    m.fundingPolicy = r.int32();
                    break;
                case 40:
                    if (!(m.fundingMapList && m.fundingMapList.length))
                        m.fundingMapList = [];
                    m.fundingMapList.push($root.Proto.ContentFundingMap.decode(r, r.uint32()));
                    break;
                case 41:
                    m.hiddenDisplayDate = r.bool();
                    break;
                case 42:
                    m.mapPolicy = r.int32();
                    break;
                case 43:
                    m.commentMapPolicy = r.int32();
                    break;
                case 46:
                    if (!(m.mapGroupList && m.mapGroupList.length))
                        m.mapGroupList = [];
                    m.mapGroupList.push($root.Proto.ContentMapGroup.decode(r, r.uint32()));
                    break;
                case 47:
                    m.ticketPolicy = r.int32();
                    break;
                case 48:
                    if (!(m.ticketMapList && m.ticketMapList.length))
                        m.ticketMapList = [];
                    m.ticketMapList.push($root.Proto.ContentTicketMap.decode(r, r.uint32()));
                    break;
                case 49:
                    m.readCount = r.string();
                    break;
                case 50:
                    m.reactionCount = r.string();
                    break;
                case 51:
                    if (!(m.reactionGroupList && m.reactionGroupList.length))
                        m.reactionGroupList = [];
                    m.reactionGroupList.push($root.Proto.ContentReaction.decode(r, r.uint32()));
                    break;
                case 52:
                    m.read = r.bool();
                    break;
                case 53:
                    if (!(m.readUserList && m.readUserList.length))
                        m.readUserList = [];
                    m.readUserList.push($root.Proto.User.decode(r, r.uint32()));
                    break;
                case 54:
                    m.pollUID = r.string();
                    break;
                case 55:
                    m.pollText = r.string();
                    break;
                case 56:
                    m.paymentDescription = r.string();
                    break;
                case 57:
                    m.paymentDescriptionVersion = r.int32();
                    break;
                case 58:
                    m.hasMoreDescription = r.bool();
                    break;
                case 59:
                    m.viewerType = r.int32();
                    break;
                case 60:
                    m.workflowPolicy = r.int32();
                    break;
                case 61:
                    if (!(m.workflowMapList && m.workflowMapList.length))
                        m.workflowMapList = [];
                    m.workflowMapList.push($root.Proto.ContentWorkflowMap.decode(r, r.uint32()));
                    break;
                case 62:
                    m.playlistTVOD = $root.Proto.PlaylistTVOD.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Content message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.Content
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.Content} Content
         */
        Content.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.Content)
                return d;
            var m = new $root.Proto.Content();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.Content.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            if (d.distribution != null) {
                m.distribution = d.distribution | 0;
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.endTime != null) {
                m.endTime = Number(d.endTime);
            }
            if (d.ageRating != null) {
                m.ageRating = d.ageRating | 0;
            }
            if (d.playlistUID != null) {
                m.playlistUID = String(d.playlistUID);
            }
            if (d.accessCount != null) {
                m.accessCount = String(d.accessCount);
            }
            if (d.playlistUIDList) {
                if (!Array.isArray(d.playlistUIDList))
                    throw TypeError(".Proto.Content.playlistUIDList: array expected");
                m.playlistUIDList = [];
                for (var i = 0; i < d.playlistUIDList.length; ++i) {
                    m.playlistUIDList[i] = String(d.playlistUIDList[i]);
                }
            }
            if (d.chapters) {
                if (!Array.isArray(d.chapters))
                    throw TypeError(".Proto.Content.chapters: array expected");
                m.chapters = [];
                for (var i = 0; i < d.chapters.length; ++i) {
                    if (typeof d.chapters[i] !== "object")
                        throw TypeError(".Proto.Content.chapters: object expected");
                    m.chapters[i] = $root.Proto.ContentChapter.fromObject(d.chapters[i]);
                }
            }
            if (d.events) {
                if (!Array.isArray(d.events))
                    throw TypeError(".Proto.Content.events: array expected");
                m.events = [];
                for (var i = 0; i < d.events.length; ++i) {
                    if (typeof d.events[i] !== "object")
                        throw TypeError(".Proto.Content.events: object expected");
                    m.events[i] = $root.Proto.ContentEvent.fromObject(d.events[i]);
                }
            }
            if (d.allowComment != null) {
                m.allowComment = d.allowComment | 0;
            }
            if (d.likeCount != null) {
                m.likeCount = String(d.likeCount);
            }
            if (d.commentCount != null) {
                m.commentCount = String(d.commentCount);
            }
            if (d.oauthUID != null) {
                m.oauthUID = String(d.oauthUID);
            }
            if (d.allowProduct != null) {
                m.allowProduct = d.allowProduct | 0;
            }
            if (d.productOrder != null) {
                m.productOrder = String(d.productOrder);
            }
            if (d.products) {
                if (!Array.isArray(d.products))
                    throw TypeError(".Proto.Content.products: array expected");
                m.products = [];
                for (var i = 0; i < d.products.length; ++i) {
                    if (typeof d.products[i] !== "object")
                        throw TypeError(".Proto.Content.products: object expected");
                    m.products[i] = $root.Proto.ContentProduct.fromObject(d.products[i]);
                }
            }
            if (d.commentVisible != null) {
                m.commentVisible = d.commentVisible | 0;
            }
            if (d.playlistMapList) {
                if (!Array.isArray(d.playlistMapList))
                    throw TypeError(".Proto.Content.playlistMapList: array expected");
                m.playlistMapList = [];
                for (var i = 0; i < d.playlistMapList.length; ++i) {
                    if (typeof d.playlistMapList[i] !== "object")
                        throw TypeError(".Proto.Content.playlistMapList: object expected");
                    m.playlistMapList[i] = $root.Proto.PlaylistContentTitleMap.fromObject(d.playlistMapList[i]);
                }
            }
            if (d.publicPolicy != null) {
                m.publicPolicy = d.publicPolicy | 0;
            }
            if (d.additionalFileList) {
                if (!Array.isArray(d.additionalFileList))
                    throw TypeError(".Proto.Content.additionalFileList: array expected");
                m.additionalFileList = [];
                for (var i = 0; i < d.additionalFileList.length; ++i) {
                    if (typeof d.additionalFileList[i] !== "object")
                        throw TypeError(".Proto.Content.additionalFileList: object expected");
                    m.additionalFileList[i] = $root.Proto.ContentAdditionalFile.fromObject(d.additionalFileList[i]);
                }
            }
            if (d.fileStashList) {
                if (!Array.isArray(d.fileStashList))
                    throw TypeError(".Proto.Content.fileStashList: array expected");
                m.fileStashList = [];
                for (var i = 0; i < d.fileStashList.length; ++i) {
                    if (typeof d.fileStashList[i] !== "object")
                        throw TypeError(".Proto.Content.fileStashList: object expected");
                    m.fileStashList[i] = $root.Proto.ContentFileStash.fromObject(d.fileStashList[i]);
                }
            }
            if (d.tagList) {
                if (!Array.isArray(d.tagList))
                    throw TypeError(".Proto.Content.tagList: array expected");
                m.tagList = [];
                for (var i = 0; i < d.tagList.length; ++i) {
                    if (typeof d.tagList[i] !== "object")
                        throw TypeError(".Proto.Content.tagList: object expected");
                    m.tagList[i] = $root.Proto.HashTag.fromObject(d.tagList[i]);
                }
            }
            if (d.commerceMapList) {
                if (!Array.isArray(d.commerceMapList))
                    throw TypeError(".Proto.Content.commerceMapList: array expected");
                m.commerceMapList = [];
                for (var i = 0; i < d.commerceMapList.length; ++i) {
                    if (typeof d.commerceMapList[i] !== "object")
                        throw TypeError(".Proto.Content.commerceMapList: object expected");
                    m.commerceMapList[i] = $root.Proto.ContentCommerceMap.fromObject(d.commerceMapList[i]);
                }
            }
            if (d.commercePolicy != null) {
                m.commercePolicy = d.commercePolicy | 0;
            }
            if (d.videoPolicy != null) {
                m.videoPolicy = d.videoPolicy | 0;
            }
            if (d.adminTagList) {
                if (!Array.isArray(d.adminTagList))
                    throw TypeError(".Proto.Content.adminTagList: array expected");
                m.adminTagList = [];
                for (var i = 0; i < d.adminTagList.length; ++i) {
                    if (typeof d.adminTagList[i] !== "object")
                        throw TypeError(".Proto.Content.adminTagList: object expected");
                    m.adminTagList[i] = $root.Proto.HashTag.fromObject(d.adminTagList[i]);
                }
            }
            if (d.fundingPolicy != null) {
                m.fundingPolicy = d.fundingPolicy | 0;
            }
            if (d.fundingMapList) {
                if (!Array.isArray(d.fundingMapList))
                    throw TypeError(".Proto.Content.fundingMapList: array expected");
                m.fundingMapList = [];
                for (var i = 0; i < d.fundingMapList.length; ++i) {
                    if (typeof d.fundingMapList[i] !== "object")
                        throw TypeError(".Proto.Content.fundingMapList: object expected");
                    m.fundingMapList[i] = $root.Proto.ContentFundingMap.fromObject(d.fundingMapList[i]);
                }
            }
            if (d.hiddenDisplayDate != null) {
                m.hiddenDisplayDate = Boolean(d.hiddenDisplayDate);
            }
            if (d.mapPolicy != null) {
                m.mapPolicy = d.mapPolicy | 0;
            }
            if (d.commentMapPolicy != null) {
                m.commentMapPolicy = d.commentMapPolicy | 0;
            }
            if (d.mapGroupList) {
                if (!Array.isArray(d.mapGroupList))
                    throw TypeError(".Proto.Content.mapGroupList: array expected");
                m.mapGroupList = [];
                for (var i = 0; i < d.mapGroupList.length; ++i) {
                    if (typeof d.mapGroupList[i] !== "object")
                        throw TypeError(".Proto.Content.mapGroupList: object expected");
                    m.mapGroupList[i] = $root.Proto.ContentMapGroup.fromObject(d.mapGroupList[i]);
                }
            }
            if (d.ticketPolicy != null) {
                m.ticketPolicy = d.ticketPolicy | 0;
            }
            if (d.ticketMapList) {
                if (!Array.isArray(d.ticketMapList))
                    throw TypeError(".Proto.Content.ticketMapList: array expected");
                m.ticketMapList = [];
                for (var i = 0; i < d.ticketMapList.length; ++i) {
                    if (typeof d.ticketMapList[i] !== "object")
                        throw TypeError(".Proto.Content.ticketMapList: object expected");
                    m.ticketMapList[i] = $root.Proto.ContentTicketMap.fromObject(d.ticketMapList[i]);
                }
            }
            if (d.readCount != null) {
                m.readCount = String(d.readCount);
            }
            if (d.reactionCount != null) {
                m.reactionCount = String(d.reactionCount);
            }
            if (d.reactionGroupList) {
                if (!Array.isArray(d.reactionGroupList))
                    throw TypeError(".Proto.Content.reactionGroupList: array expected");
                m.reactionGroupList = [];
                for (var i = 0; i < d.reactionGroupList.length; ++i) {
                    if (typeof d.reactionGroupList[i] !== "object")
                        throw TypeError(".Proto.Content.reactionGroupList: object expected");
                    m.reactionGroupList[i] = $root.Proto.ContentReaction.fromObject(d.reactionGroupList[i]);
                }
            }
            if (d.read != null) {
                m.read = Boolean(d.read);
            }
            if (d.readUserList) {
                if (!Array.isArray(d.readUserList))
                    throw TypeError(".Proto.Content.readUserList: array expected");
                m.readUserList = [];
                for (var i = 0; i < d.readUserList.length; ++i) {
                    if (typeof d.readUserList[i] !== "object")
                        throw TypeError(".Proto.Content.readUserList: object expected");
                    m.readUserList[i] = $root.Proto.User.fromObject(d.readUserList[i]);
                }
            }
            if (d.pollUID != null) {
                m.pollUID = String(d.pollUID);
            }
            if (d.pollText != null) {
                m.pollText = String(d.pollText);
            }
            if (d.paymentDescription != null) {
                m.paymentDescription = String(d.paymentDescription);
            }
            if (d.paymentDescriptionVersion != null) {
                m.paymentDescriptionVersion = d.paymentDescriptionVersion | 0;
            }
            if (d.hasMoreDescription != null) {
                m.hasMoreDescription = Boolean(d.hasMoreDescription);
            }
            if (d.viewerType != null) {
                m.viewerType = d.viewerType | 0;
            }
            if (d.workflowPolicy != null) {
                m.workflowPolicy = d.workflowPolicy | 0;
            }
            if (d.workflowMapList) {
                if (!Array.isArray(d.workflowMapList))
                    throw TypeError(".Proto.Content.workflowMapList: array expected");
                m.workflowMapList = [];
                for (var i = 0; i < d.workflowMapList.length; ++i) {
                    if (typeof d.workflowMapList[i] !== "object")
                        throw TypeError(".Proto.Content.workflowMapList: object expected");
                    m.workflowMapList[i] = $root.Proto.ContentWorkflowMap.fromObject(d.workflowMapList[i]);
                }
            }
            if (d.playlistTVOD != null) {
                if (typeof d.playlistTVOD !== "object")
                    throw TypeError(".Proto.Content.playlistTVOD: object expected");
                m.playlistTVOD = $root.Proto.PlaylistTVOD.fromObject(d.playlistTVOD);
            }
            return m;
        };

        /**
         * Creates a plain object from a Content message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.Content
         * @static
         * @param {Proto.Content} m Content
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Content.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.playlistUIDList = [];
                d.chapters = [];
                d.events = [];
                d.products = [];
                d.playlistMapList = [];
                d.additionalFileList = [];
                d.fileStashList = [];
                d.tagList = [];
                d.commerceMapList = [];
                d.adminTagList = [];
                d.fundingMapList = [];
                d.mapGroupList = [];
                d.ticketMapList = [];
                d.reactionGroupList = [];
                d.readUserList = [];
                d.workflowMapList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.title = "";
                d.userUID = "";
                d.description = "";
                d.file = null;
                d.distribution = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                d.type = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.companyUID = "";
                d.endTime = 0;
                d.ageRating = 0;
                d.playlistUID = "";
                d.accessCount = "";
                d.allowComment = 0;
                d.likeCount = "";
                d.commentCount = "";
                d.oauthUID = "";
                d.allowProduct = 0;
                d.productOrder = "";
                d.commentVisible = 0;
                d.publicPolicy = 0;
                d.commercePolicy = 0;
                d.videoPolicy = 0;
                d.fundingPolicy = 0;
                d.hiddenDisplayDate = false;
                d.mapPolicy = 0;
                d.commentMapPolicy = 0;
                d.ticketPolicy = 0;
                d.readCount = "";
                d.reactionCount = "";
                d.read = false;
                d.pollUID = "";
                d.pollText = "";
                d.paymentDescription = "";
                d.paymentDescriptionVersion = 0;
                d.hasMoreDescription = false;
                d.viewerType = 0;
                d.workflowPolicy = 0;
                d.playlistTVOD = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            if (m.distribution != null && m.hasOwnProperty("distribution")) {
                d.distribution = m.distribution;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.endTime != null && m.hasOwnProperty("endTime")) {
                d.endTime = o.json && !isFinite(m.endTime) ? String(m.endTime) : m.endTime;
            }
            if (m.ageRating != null && m.hasOwnProperty("ageRating")) {
                d.ageRating = m.ageRating;
            }
            if (m.playlistUID != null && m.hasOwnProperty("playlistUID")) {
                d.playlistUID = m.playlistUID;
            }
            if (m.accessCount != null && m.hasOwnProperty("accessCount")) {
                d.accessCount = m.accessCount;
            }
            if (m.playlistUIDList && m.playlistUIDList.length) {
                d.playlistUIDList = [];
                for (var j = 0; j < m.playlistUIDList.length; ++j) {
                    d.playlistUIDList[j] = m.playlistUIDList[j];
                }
            }
            if (m.chapters && m.chapters.length) {
                d.chapters = [];
                for (var j = 0; j < m.chapters.length; ++j) {
                    d.chapters[j] = $root.Proto.ContentChapter.toObject(m.chapters[j], o);
                }
            }
            if (m.events && m.events.length) {
                d.events = [];
                for (var j = 0; j < m.events.length; ++j) {
                    d.events[j] = $root.Proto.ContentEvent.toObject(m.events[j], o);
                }
            }
            if (m.allowComment != null && m.hasOwnProperty("allowComment")) {
                d.allowComment = m.allowComment;
            }
            if (m.likeCount != null && m.hasOwnProperty("likeCount")) {
                d.likeCount = m.likeCount;
            }
            if (m.commentCount != null && m.hasOwnProperty("commentCount")) {
                d.commentCount = m.commentCount;
            }
            if (m.oauthUID != null && m.hasOwnProperty("oauthUID")) {
                d.oauthUID = m.oauthUID;
            }
            if (m.allowProduct != null && m.hasOwnProperty("allowProduct")) {
                d.allowProduct = m.allowProduct;
            }
            if (m.productOrder != null && m.hasOwnProperty("productOrder")) {
                d.productOrder = m.productOrder;
            }
            if (m.products && m.products.length) {
                d.products = [];
                for (var j = 0; j < m.products.length; ++j) {
                    d.products[j] = $root.Proto.ContentProduct.toObject(m.products[j], o);
                }
            }
            if (m.commentVisible != null && m.hasOwnProperty("commentVisible")) {
                d.commentVisible = m.commentVisible;
            }
            if (m.playlistMapList && m.playlistMapList.length) {
                d.playlistMapList = [];
                for (var j = 0; j < m.playlistMapList.length; ++j) {
                    d.playlistMapList[j] = $root.Proto.PlaylistContentTitleMap.toObject(m.playlistMapList[j], o);
                }
            }
            if (m.publicPolicy != null && m.hasOwnProperty("publicPolicy")) {
                d.publicPolicy = m.publicPolicy;
            }
            if (m.additionalFileList && m.additionalFileList.length) {
                d.additionalFileList = [];
                for (var j = 0; j < m.additionalFileList.length; ++j) {
                    d.additionalFileList[j] = $root.Proto.ContentAdditionalFile.toObject(m.additionalFileList[j], o);
                }
            }
            if (m.fileStashList && m.fileStashList.length) {
                d.fileStashList = [];
                for (var j = 0; j < m.fileStashList.length; ++j) {
                    d.fileStashList[j] = $root.Proto.ContentFileStash.toObject(m.fileStashList[j], o);
                }
            }
            if (m.tagList && m.tagList.length) {
                d.tagList = [];
                for (var j = 0; j < m.tagList.length; ++j) {
                    d.tagList[j] = $root.Proto.HashTag.toObject(m.tagList[j], o);
                }
            }
            if (m.commerceMapList && m.commerceMapList.length) {
                d.commerceMapList = [];
                for (var j = 0; j < m.commerceMapList.length; ++j) {
                    d.commerceMapList[j] = $root.Proto.ContentCommerceMap.toObject(m.commerceMapList[j], o);
                }
            }
            if (m.commercePolicy != null && m.hasOwnProperty("commercePolicy")) {
                d.commercePolicy = m.commercePolicy;
            }
            if (m.videoPolicy != null && m.hasOwnProperty("videoPolicy")) {
                d.videoPolicy = m.videoPolicy;
            }
            if (m.adminTagList && m.adminTagList.length) {
                d.adminTagList = [];
                for (var j = 0; j < m.adminTagList.length; ++j) {
                    d.adminTagList[j] = $root.Proto.HashTag.toObject(m.adminTagList[j], o);
                }
            }
            if (m.fundingPolicy != null && m.hasOwnProperty("fundingPolicy")) {
                d.fundingPolicy = m.fundingPolicy;
            }
            if (m.fundingMapList && m.fundingMapList.length) {
                d.fundingMapList = [];
                for (var j = 0; j < m.fundingMapList.length; ++j) {
                    d.fundingMapList[j] = $root.Proto.ContentFundingMap.toObject(m.fundingMapList[j], o);
                }
            }
            if (m.hiddenDisplayDate != null && m.hasOwnProperty("hiddenDisplayDate")) {
                d.hiddenDisplayDate = m.hiddenDisplayDate;
            }
            if (m.mapPolicy != null && m.hasOwnProperty("mapPolicy")) {
                d.mapPolicy = m.mapPolicy;
            }
            if (m.commentMapPolicy != null && m.hasOwnProperty("commentMapPolicy")) {
                d.commentMapPolicy = m.commentMapPolicy;
            }
            if (m.mapGroupList && m.mapGroupList.length) {
                d.mapGroupList = [];
                for (var j = 0; j < m.mapGroupList.length; ++j) {
                    d.mapGroupList[j] = $root.Proto.ContentMapGroup.toObject(m.mapGroupList[j], o);
                }
            }
            if (m.ticketPolicy != null && m.hasOwnProperty("ticketPolicy")) {
                d.ticketPolicy = m.ticketPolicy;
            }
            if (m.ticketMapList && m.ticketMapList.length) {
                d.ticketMapList = [];
                for (var j = 0; j < m.ticketMapList.length; ++j) {
                    d.ticketMapList[j] = $root.Proto.ContentTicketMap.toObject(m.ticketMapList[j], o);
                }
            }
            if (m.readCount != null && m.hasOwnProperty("readCount")) {
                d.readCount = m.readCount;
            }
            if (m.reactionCount != null && m.hasOwnProperty("reactionCount")) {
                d.reactionCount = m.reactionCount;
            }
            if (m.reactionGroupList && m.reactionGroupList.length) {
                d.reactionGroupList = [];
                for (var j = 0; j < m.reactionGroupList.length; ++j) {
                    d.reactionGroupList[j] = $root.Proto.ContentReaction.toObject(m.reactionGroupList[j], o);
                }
            }
            if (m.read != null && m.hasOwnProperty("read")) {
                d.read = m.read;
            }
            if (m.readUserList && m.readUserList.length) {
                d.readUserList = [];
                for (var j = 0; j < m.readUserList.length; ++j) {
                    d.readUserList[j] = $root.Proto.User.toObject(m.readUserList[j], o);
                }
            }
            if (m.pollUID != null && m.hasOwnProperty("pollUID")) {
                d.pollUID = m.pollUID;
            }
            if (m.pollText != null && m.hasOwnProperty("pollText")) {
                d.pollText = m.pollText;
            }
            if (m.paymentDescription != null && m.hasOwnProperty("paymentDescription")) {
                d.paymentDescription = m.paymentDescription;
            }
            if (m.paymentDescriptionVersion != null && m.hasOwnProperty("paymentDescriptionVersion")) {
                d.paymentDescriptionVersion = m.paymentDescriptionVersion;
            }
            if (m.hasMoreDescription != null && m.hasOwnProperty("hasMoreDescription")) {
                d.hasMoreDescription = m.hasMoreDescription;
            }
            if (m.viewerType != null && m.hasOwnProperty("viewerType")) {
                d.viewerType = m.viewerType;
            }
            if (m.workflowPolicy != null && m.hasOwnProperty("workflowPolicy")) {
                d.workflowPolicy = m.workflowPolicy;
            }
            if (m.workflowMapList && m.workflowMapList.length) {
                d.workflowMapList = [];
                for (var j = 0; j < m.workflowMapList.length; ++j) {
                    d.workflowMapList[j] = $root.Proto.ContentWorkflowMap.toObject(m.workflowMapList[j], o);
                }
            }
            if (m.playlistTVOD != null && m.hasOwnProperty("playlistTVOD")) {
                d.playlistTVOD = $root.Proto.PlaylistTVOD.toObject(m.playlistTVOD, o);
            }
            return d;
        };

        /**
         * Converts this Content to JSON.
         * @function toJSON
         * @memberof Proto.Content
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Content.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Content;
    })();

    Proto.ContentList = (function() {

        /**
         * Properties of a ContentList.
         * @memberof Proto
         * @interface IContentList
         * @property {Array.<Proto.IContent>|null} [value] ContentList value
         */

        /**
         * Constructs a new ContentList.
         * @memberof Proto
         * @classdesc Represents a ContentList.
         * @implements IContentList
         * @constructor
         * @param {Proto.IContentList=} [p] Properties to set
         */
        function ContentList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentList value.
         * @member {Array.<Proto.IContent>} value
         * @memberof Proto.ContentList
         * @instance
         */
        ContentList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentList
         * @static
         * @param {Proto.IContentList=} [properties] Properties to set
         * @returns {Proto.ContentList} ContentList instance
         */
        ContentList.create = function create(properties) {
            return new ContentList(properties);
        };

        /**
         * Encodes the specified ContentList message. Does not implicitly {@link Proto.ContentList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentList
         * @static
         * @param {Proto.IContentList} m ContentList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.Content.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentList} ContentList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.Content.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentList} ContentList
         */
        ContentList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentList)
                return d;
            var m = new $root.Proto.ContentList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentList.value: object expected");
                    m.value[i] = $root.Proto.Content.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentList
         * @static
         * @param {Proto.ContentList} m ContentList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.Content.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentList to JSON.
         * @function toJSON
         * @memberof Proto.ContentList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentList;
    })();

    Proto.Playlist = (function() {

        /**
         * Properties of a Playlist.
         * @memberof Proto
         * @interface IPlaylist
         * @property {string|null} [uid] Playlist uid
         * @property {string|null} [title] Playlist title
         * @property {string|null} [description] Playlist description
         * @property {number|null} [type] Playlist type
         * @property {number|Long|null} [registerDate] Playlist registerDate
         * @property {number|Long|null} [modifyDate] Playlist modifyDate
         * @property {number|null} [status] Playlist status
         * @property {string|null} [contentCount] Playlist contentCount
         * @property {Proto.IUploadFile|null} [image] Playlist image
         * @property {number|null} [progress] Playlist progress
         * @property {Proto.IContent|null} [historyContent] Playlist historyContent
         * @property {string|null} [author] Playlist author
         * @property {Proto.IUploadFile|null} [icon] Playlist icon
         * @property {Array.<string>|null} [contentUIDList] Playlist contentUIDList
         * @property {string|null} [totalAccessCount] Playlist totalAccessCount
         * @property {number|Long|null} [startDate] Playlist startDate
         * @property {number|Long|null} [endDate] Playlist endDate
         * @property {number|null} [distribution] Playlist distribution
         * @property {string|null} [link] Playlist link
         * @property {string|null} [fileUID] Playlist fileUID
         * @property {string|null} [iconUID] Playlist iconUID
         * @property {number|Long|null} [readCount] Playlist readCount
         * @property {string|null} [companyUID] Playlist companyUID
         * @property {string|null} [planUID] Playlist planUID
         * @property {number|null} [publicPolicy] Playlist publicPolicy
         * @property {boolean|null} [anonymous] Playlist anonymous
         * @property {boolean|null} [freePlan] Playlist freePlan
         * @property {string|null} [contentYoutubeCount] Playlist contentYoutubeCount
         * @property {Proto.IUploadFile|null} [topImage] Playlist topImage
         * @property {boolean|null} [alwaysDes] Playlist alwaysDes
         * @property {boolean|null} [showContentDes] Playlist showContentDes
         * @property {string|null} [contentDesTitle] Playlist contentDesTitle
         * @property {Proto.IUser|null} [authorUser] Playlist authorUser
         * @property {string|null} [pollUID] Playlist pollUID
         * @property {string|null} [pollTitle] Playlist pollTitle
         * @property {string|null} [pollText] Playlist pollText
         * @property {string|null} [edTime] Playlist edTime
         * @property {string|null} [note] Playlist note
         * @property {string|null} [authorTitle] Playlist authorTitle
         * @property {boolean|null} [showDuration] Playlist showDuration
         * @property {number|Long|null} [latestContentDate] Playlist latestContentDate
         * @property {Proto.IPlaylistTVOD|null} [tvod] Playlist tvod
         */

        /**
         * Constructs a new Playlist.
         * @memberof Proto
         * @classdesc Represents a Playlist.
         * @implements IPlaylist
         * @constructor
         * @param {Proto.IPlaylist=} [p] Properties to set
         */
        function Playlist(p) {
            this.contentUIDList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Playlist uid.
         * @member {string} uid
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.uid = "";

        /**
         * Playlist title.
         * @member {string} title
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.title = "";

        /**
         * Playlist description.
         * @member {string} description
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.description = "";

        /**
         * Playlist type.
         * @member {number} type
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.type = 0;

        /**
         * Playlist registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Playlist modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Playlist status.
         * @member {number} status
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.status = 0;

        /**
         * Playlist contentCount.
         * @member {string} contentCount
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.contentCount = "";

        /**
         * Playlist image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.image = null;

        /**
         * Playlist progress.
         * @member {number} progress
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.progress = 0;

        /**
         * Playlist historyContent.
         * @member {Proto.IContent|null|undefined} historyContent
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.historyContent = null;

        /**
         * Playlist author.
         * @member {string} author
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.author = "";

        /**
         * Playlist icon.
         * @member {Proto.IUploadFile|null|undefined} icon
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.icon = null;

        /**
         * Playlist contentUIDList.
         * @member {Array.<string>} contentUIDList
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.contentUIDList = $util.emptyArray;

        /**
         * Playlist totalAccessCount.
         * @member {string} totalAccessCount
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.totalAccessCount = "";

        /**
         * Playlist startDate.
         * @member {number|Long} startDate
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Playlist endDate.
         * @member {number|Long} endDate
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Playlist distribution.
         * @member {number} distribution
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.distribution = 0;

        /**
         * Playlist link.
         * @member {string} link
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.link = "";

        /**
         * Playlist fileUID.
         * @member {string} fileUID
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.fileUID = "";

        /**
         * Playlist iconUID.
         * @member {string} iconUID
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.iconUID = "";

        /**
         * Playlist readCount.
         * @member {number|Long} readCount
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.readCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Playlist companyUID.
         * @member {string} companyUID
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.companyUID = "";

        /**
         * Playlist planUID.
         * @member {string} planUID
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.planUID = "";

        /**
         * Playlist publicPolicy.
         * @member {number} publicPolicy
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.publicPolicy = 0;

        /**
         * Playlist anonymous.
         * @member {boolean} anonymous
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.anonymous = false;

        /**
         * Playlist freePlan.
         * @member {boolean} freePlan
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.freePlan = false;

        /**
         * Playlist contentYoutubeCount.
         * @member {string} contentYoutubeCount
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.contentYoutubeCount = "";

        /**
         * Playlist topImage.
         * @member {Proto.IUploadFile|null|undefined} topImage
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.topImage = null;

        /**
         * Playlist alwaysDes.
         * @member {boolean} alwaysDes
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.alwaysDes = false;

        /**
         * Playlist showContentDes.
         * @member {boolean} showContentDes
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.showContentDes = false;

        /**
         * Playlist contentDesTitle.
         * @member {string} contentDesTitle
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.contentDesTitle = "";

        /**
         * Playlist authorUser.
         * @member {Proto.IUser|null|undefined} authorUser
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.authorUser = null;

        /**
         * Playlist pollUID.
         * @member {string} pollUID
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.pollUID = "";

        /**
         * Playlist pollTitle.
         * @member {string} pollTitle
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.pollTitle = "";

        /**
         * Playlist pollText.
         * @member {string} pollText
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.pollText = "";

        /**
         * Playlist edTime.
         * @member {string} edTime
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.edTime = "";

        /**
         * Playlist note.
         * @member {string} note
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.note = "";

        /**
         * Playlist authorTitle.
         * @member {string} authorTitle
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.authorTitle = "";

        /**
         * Playlist showDuration.
         * @member {boolean} showDuration
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.showDuration = false;

        /**
         * Playlist latestContentDate.
         * @member {number|Long} latestContentDate
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.latestContentDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Playlist tvod.
         * @member {Proto.IPlaylistTVOD|null|undefined} tvod
         * @memberof Proto.Playlist
         * @instance
         */
        Playlist.prototype.tvod = null;

        /**
         * Creates a new Playlist instance using the specified properties.
         * @function create
         * @memberof Proto.Playlist
         * @static
         * @param {Proto.IPlaylist=} [properties] Properties to set
         * @returns {Proto.Playlist} Playlist instance
         */
        Playlist.create = function create(properties) {
            return new Playlist(properties);
        };

        /**
         * Encodes the specified Playlist message. Does not implicitly {@link Proto.Playlist.verify|verify} messages.
         * @function encode
         * @memberof Proto.Playlist
         * @static
         * @param {Proto.IPlaylist} m Playlist message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Playlist.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(26).string(m.title);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(34).string(m.description);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(40).int32(m.type);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(64).int32(m.status);
            if (m.contentCount != null && Object.hasOwnProperty.call(m, "contentCount"))
                w.uint32(74).string(m.contentCount);
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(82).fork()).ldelim();
            if (m.progress != null && Object.hasOwnProperty.call(m, "progress"))
                w.uint32(89).double(m.progress);
            if (m.historyContent != null && Object.hasOwnProperty.call(m, "historyContent"))
                $root.Proto.Content.encode(m.historyContent, w.uint32(98).fork()).ldelim();
            if (m.author != null && Object.hasOwnProperty.call(m, "author"))
                w.uint32(106).string(m.author);
            if (m.icon != null && Object.hasOwnProperty.call(m, "icon"))
                $root.Proto.UploadFile.encode(m.icon, w.uint32(114).fork()).ldelim();
            if (m.contentUIDList != null && m.contentUIDList.length) {
                for (var i = 0; i < m.contentUIDList.length; ++i)
                    w.uint32(122).string(m.contentUIDList[i]);
            }
            if (m.totalAccessCount != null && Object.hasOwnProperty.call(m, "totalAccessCount"))
                w.uint32(130).string(m.totalAccessCount);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(136).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(144).int64(m.endDate);
            if (m.distribution != null && Object.hasOwnProperty.call(m, "distribution"))
                w.uint32(152).int32(m.distribution);
            if (m.link != null && Object.hasOwnProperty.call(m, "link"))
                w.uint32(162).string(m.link);
            if (m.fileUID != null && Object.hasOwnProperty.call(m, "fileUID"))
                w.uint32(170).string(m.fileUID);
            if (m.iconUID != null && Object.hasOwnProperty.call(m, "iconUID"))
                w.uint32(178).string(m.iconUID);
            if (m.readCount != null && Object.hasOwnProperty.call(m, "readCount"))
                w.uint32(184).int64(m.readCount);
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(194).string(m.companyUID);
            if (m.planUID != null && Object.hasOwnProperty.call(m, "planUID"))
                w.uint32(202).string(m.planUID);
            if (m.publicPolicy != null && Object.hasOwnProperty.call(m, "publicPolicy"))
                w.uint32(208).int32(m.publicPolicy);
            if (m.anonymous != null && Object.hasOwnProperty.call(m, "anonymous"))
                w.uint32(216).bool(m.anonymous);
            if (m.freePlan != null && Object.hasOwnProperty.call(m, "freePlan"))
                w.uint32(224).bool(m.freePlan);
            if (m.contentYoutubeCount != null && Object.hasOwnProperty.call(m, "contentYoutubeCount"))
                w.uint32(234).string(m.contentYoutubeCount);
            if (m.topImage != null && Object.hasOwnProperty.call(m, "topImage"))
                $root.Proto.UploadFile.encode(m.topImage, w.uint32(242).fork()).ldelim();
            if (m.alwaysDes != null && Object.hasOwnProperty.call(m, "alwaysDes"))
                w.uint32(248).bool(m.alwaysDes);
            if (m.showContentDes != null && Object.hasOwnProperty.call(m, "showContentDes"))
                w.uint32(256).bool(m.showContentDes);
            if (m.contentDesTitle != null && Object.hasOwnProperty.call(m, "contentDesTitle"))
                w.uint32(266).string(m.contentDesTitle);
            if (m.authorUser != null && Object.hasOwnProperty.call(m, "authorUser"))
                $root.Proto.User.encode(m.authorUser, w.uint32(274).fork()).ldelim();
            if (m.pollUID != null && Object.hasOwnProperty.call(m, "pollUID"))
                w.uint32(282).string(m.pollUID);
            if (m.pollTitle != null && Object.hasOwnProperty.call(m, "pollTitle"))
                w.uint32(290).string(m.pollTitle);
            if (m.pollText != null && Object.hasOwnProperty.call(m, "pollText"))
                w.uint32(298).string(m.pollText);
            if (m.edTime != null && Object.hasOwnProperty.call(m, "edTime"))
                w.uint32(306).string(m.edTime);
            if (m.note != null && Object.hasOwnProperty.call(m, "note"))
                w.uint32(314).string(m.note);
            if (m.authorTitle != null && Object.hasOwnProperty.call(m, "authorTitle"))
                w.uint32(322).string(m.authorTitle);
            if (m.showDuration != null && Object.hasOwnProperty.call(m, "showDuration"))
                w.uint32(328).bool(m.showDuration);
            if (m.latestContentDate != null && Object.hasOwnProperty.call(m, "latestContentDate"))
                w.uint32(336).int64(m.latestContentDate);
            if (m.tvod != null && Object.hasOwnProperty.call(m, "tvod"))
                $root.Proto.PlaylistTVOD.encode(m.tvod, w.uint32(346).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a Playlist message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.Playlist
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.Playlist} Playlist
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Playlist.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.Playlist();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 3:
                    m.title = r.string();
                    break;
                case 4:
                    m.description = r.string();
                    break;
                case 5:
                    m.type = r.int32();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.status = r.int32();
                    break;
                case 9:
                    m.contentCount = r.string();
                    break;
                case 10:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 11:
                    m.progress = r.double();
                    break;
                case 12:
                    m.historyContent = $root.Proto.Content.decode(r, r.uint32());
                    break;
                case 13:
                    m.author = r.string();
                    break;
                case 14:
                    m.icon = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 15:
                    if (!(m.contentUIDList && m.contentUIDList.length))
                        m.contentUIDList = [];
                    m.contentUIDList.push(r.string());
                    break;
                case 16:
                    m.totalAccessCount = r.string();
                    break;
                case 17:
                    m.startDate = r.int64();
                    break;
                case 18:
                    m.endDate = r.int64();
                    break;
                case 19:
                    m.distribution = r.int32();
                    break;
                case 20:
                    m.link = r.string();
                    break;
                case 21:
                    m.fileUID = r.string();
                    break;
                case 22:
                    m.iconUID = r.string();
                    break;
                case 23:
                    m.readCount = r.int64();
                    break;
                case 24:
                    m.companyUID = r.string();
                    break;
                case 25:
                    m.planUID = r.string();
                    break;
                case 26:
                    m.publicPolicy = r.int32();
                    break;
                case 27:
                    m.anonymous = r.bool();
                    break;
                case 28:
                    m.freePlan = r.bool();
                    break;
                case 29:
                    m.contentYoutubeCount = r.string();
                    break;
                case 30:
                    m.topImage = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 31:
                    m.alwaysDes = r.bool();
                    break;
                case 32:
                    m.showContentDes = r.bool();
                    break;
                case 33:
                    m.contentDesTitle = r.string();
                    break;
                case 34:
                    m.authorUser = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 35:
                    m.pollUID = r.string();
                    break;
                case 36:
                    m.pollTitle = r.string();
                    break;
                case 37:
                    m.pollText = r.string();
                    break;
                case 38:
                    m.edTime = r.string();
                    break;
                case 39:
                    m.note = r.string();
                    break;
                case 40:
                    m.authorTitle = r.string();
                    break;
                case 41:
                    m.showDuration = r.bool();
                    break;
                case 42:
                    m.latestContentDate = r.int64();
                    break;
                case 43:
                    m.tvod = $root.Proto.PlaylistTVOD.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Playlist message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.Playlist
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.Playlist} Playlist
         */
        Playlist.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.Playlist)
                return d;
            var m = new $root.Proto.Playlist();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.contentCount != null) {
                m.contentCount = String(d.contentCount);
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.Playlist.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.progress != null) {
                m.progress = Number(d.progress);
            }
            if (d.historyContent != null) {
                if (typeof d.historyContent !== "object")
                    throw TypeError(".Proto.Playlist.historyContent: object expected");
                m.historyContent = $root.Proto.Content.fromObject(d.historyContent);
            }
            if (d.author != null) {
                m.author = String(d.author);
            }
            if (d.icon != null) {
                if (typeof d.icon !== "object")
                    throw TypeError(".Proto.Playlist.icon: object expected");
                m.icon = $root.Proto.UploadFile.fromObject(d.icon);
            }
            if (d.contentUIDList) {
                if (!Array.isArray(d.contentUIDList))
                    throw TypeError(".Proto.Playlist.contentUIDList: array expected");
                m.contentUIDList = [];
                for (var i = 0; i < d.contentUIDList.length; ++i) {
                    m.contentUIDList[i] = String(d.contentUIDList[i]);
                }
            }
            if (d.totalAccessCount != null) {
                m.totalAccessCount = String(d.totalAccessCount);
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.distribution != null) {
                m.distribution = d.distribution | 0;
            }
            if (d.link != null) {
                m.link = String(d.link);
            }
            if (d.fileUID != null) {
                m.fileUID = String(d.fileUID);
            }
            if (d.iconUID != null) {
                m.iconUID = String(d.iconUID);
            }
            if (d.readCount != null) {
                if ($util.Long)
                    (m.readCount = $util.Long.fromValue(d.readCount)).unsigned = false;
                else if (typeof d.readCount === "string")
                    m.readCount = parseInt(d.readCount, 10);
                else if (typeof d.readCount === "number")
                    m.readCount = d.readCount;
                else if (typeof d.readCount === "object")
                    m.readCount = new $util.LongBits(d.readCount.low >>> 0, d.readCount.high >>> 0).toNumber();
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.planUID != null) {
                m.planUID = String(d.planUID);
            }
            if (d.publicPolicy != null) {
                m.publicPolicy = d.publicPolicy | 0;
            }
            if (d.anonymous != null) {
                m.anonymous = Boolean(d.anonymous);
            }
            if (d.freePlan != null) {
                m.freePlan = Boolean(d.freePlan);
            }
            if (d.contentYoutubeCount != null) {
                m.contentYoutubeCount = String(d.contentYoutubeCount);
            }
            if (d.topImage != null) {
                if (typeof d.topImage !== "object")
                    throw TypeError(".Proto.Playlist.topImage: object expected");
                m.topImage = $root.Proto.UploadFile.fromObject(d.topImage);
            }
            if (d.alwaysDes != null) {
                m.alwaysDes = Boolean(d.alwaysDes);
            }
            if (d.showContentDes != null) {
                m.showContentDes = Boolean(d.showContentDes);
            }
            if (d.contentDesTitle != null) {
                m.contentDesTitle = String(d.contentDesTitle);
            }
            if (d.authorUser != null) {
                if (typeof d.authorUser !== "object")
                    throw TypeError(".Proto.Playlist.authorUser: object expected");
                m.authorUser = $root.Proto.User.fromObject(d.authorUser);
            }
            if (d.pollUID != null) {
                m.pollUID = String(d.pollUID);
            }
            if (d.pollTitle != null) {
                m.pollTitle = String(d.pollTitle);
            }
            if (d.pollText != null) {
                m.pollText = String(d.pollText);
            }
            if (d.edTime != null) {
                m.edTime = String(d.edTime);
            }
            if (d.note != null) {
                m.note = String(d.note);
            }
            if (d.authorTitle != null) {
                m.authorTitle = String(d.authorTitle);
            }
            if (d.showDuration != null) {
                m.showDuration = Boolean(d.showDuration);
            }
            if (d.latestContentDate != null) {
                if ($util.Long)
                    (m.latestContentDate = $util.Long.fromValue(d.latestContentDate)).unsigned = false;
                else if (typeof d.latestContentDate === "string")
                    m.latestContentDate = parseInt(d.latestContentDate, 10);
                else if (typeof d.latestContentDate === "number")
                    m.latestContentDate = d.latestContentDate;
                else if (typeof d.latestContentDate === "object")
                    m.latestContentDate = new $util.LongBits(d.latestContentDate.low >>> 0, d.latestContentDate.high >>> 0).toNumber();
            }
            if (d.tvod != null) {
                if (typeof d.tvod !== "object")
                    throw TypeError(".Proto.Playlist.tvod: object expected");
                m.tvod = $root.Proto.PlaylistTVOD.fromObject(d.tvod);
            }
            return m;
        };

        /**
         * Creates a plain object from a Playlist message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.Playlist
         * @static
         * @param {Proto.Playlist} m Playlist
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Playlist.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.contentUIDList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.title = "";
                d.description = "";
                d.type = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.contentCount = "";
                d.image = null;
                d.progress = 0;
                d.historyContent = null;
                d.author = "";
                d.icon = null;
                d.totalAccessCount = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                d.distribution = 0;
                d.link = "";
                d.fileUID = "";
                d.iconUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.readCount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.readCount = o.longs === String ? "0" : 0;
                d.companyUID = "";
                d.planUID = "";
                d.publicPolicy = 0;
                d.anonymous = false;
                d.freePlan = false;
                d.contentYoutubeCount = "";
                d.topImage = null;
                d.alwaysDes = false;
                d.showContentDes = false;
                d.contentDesTitle = "";
                d.authorUser = null;
                d.pollUID = "";
                d.pollTitle = "";
                d.pollText = "";
                d.edTime = "";
                d.note = "";
                d.authorTitle = "";
                d.showDuration = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.latestContentDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.latestContentDate = o.longs === String ? "0" : 0;
                d.tvod = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.contentCount != null && m.hasOwnProperty("contentCount")) {
                d.contentCount = m.contentCount;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.progress != null && m.hasOwnProperty("progress")) {
                d.progress = o.json && !isFinite(m.progress) ? String(m.progress) : m.progress;
            }
            if (m.historyContent != null && m.hasOwnProperty("historyContent")) {
                d.historyContent = $root.Proto.Content.toObject(m.historyContent, o);
            }
            if (m.author != null && m.hasOwnProperty("author")) {
                d.author = m.author;
            }
            if (m.icon != null && m.hasOwnProperty("icon")) {
                d.icon = $root.Proto.UploadFile.toObject(m.icon, o);
            }
            if (m.contentUIDList && m.contentUIDList.length) {
                d.contentUIDList = [];
                for (var j = 0; j < m.contentUIDList.length; ++j) {
                    d.contentUIDList[j] = m.contentUIDList[j];
                }
            }
            if (m.totalAccessCount != null && m.hasOwnProperty("totalAccessCount")) {
                d.totalAccessCount = m.totalAccessCount;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.distribution != null && m.hasOwnProperty("distribution")) {
                d.distribution = m.distribution;
            }
            if (m.link != null && m.hasOwnProperty("link")) {
                d.link = m.link;
            }
            if (m.fileUID != null && m.hasOwnProperty("fileUID")) {
                d.fileUID = m.fileUID;
            }
            if (m.iconUID != null && m.hasOwnProperty("iconUID")) {
                d.iconUID = m.iconUID;
            }
            if (m.readCount != null && m.hasOwnProperty("readCount")) {
                if (typeof m.readCount === "number")
                    d.readCount = o.longs === String ? String(m.readCount) : m.readCount;
                else
                    d.readCount = o.longs === String ? $util.Long.prototype.toString.call(m.readCount) : o.longs === Number ? new $util.LongBits(m.readCount.low >>> 0, m.readCount.high >>> 0).toNumber() : m.readCount;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.planUID != null && m.hasOwnProperty("planUID")) {
                d.planUID = m.planUID;
            }
            if (m.publicPolicy != null && m.hasOwnProperty("publicPolicy")) {
                d.publicPolicy = m.publicPolicy;
            }
            if (m.anonymous != null && m.hasOwnProperty("anonymous")) {
                d.anonymous = m.anonymous;
            }
            if (m.freePlan != null && m.hasOwnProperty("freePlan")) {
                d.freePlan = m.freePlan;
            }
            if (m.contentYoutubeCount != null && m.hasOwnProperty("contentYoutubeCount")) {
                d.contentYoutubeCount = m.contentYoutubeCount;
            }
            if (m.topImage != null && m.hasOwnProperty("topImage")) {
                d.topImage = $root.Proto.UploadFile.toObject(m.topImage, o);
            }
            if (m.alwaysDes != null && m.hasOwnProperty("alwaysDes")) {
                d.alwaysDes = m.alwaysDes;
            }
            if (m.showContentDes != null && m.hasOwnProperty("showContentDes")) {
                d.showContentDes = m.showContentDes;
            }
            if (m.contentDesTitle != null && m.hasOwnProperty("contentDesTitle")) {
                d.contentDesTitle = m.contentDesTitle;
            }
            if (m.authorUser != null && m.hasOwnProperty("authorUser")) {
                d.authorUser = $root.Proto.User.toObject(m.authorUser, o);
            }
            if (m.pollUID != null && m.hasOwnProperty("pollUID")) {
                d.pollUID = m.pollUID;
            }
            if (m.pollTitle != null && m.hasOwnProperty("pollTitle")) {
                d.pollTitle = m.pollTitle;
            }
            if (m.pollText != null && m.hasOwnProperty("pollText")) {
                d.pollText = m.pollText;
            }
            if (m.edTime != null && m.hasOwnProperty("edTime")) {
                d.edTime = m.edTime;
            }
            if (m.note != null && m.hasOwnProperty("note")) {
                d.note = m.note;
            }
            if (m.authorTitle != null && m.hasOwnProperty("authorTitle")) {
                d.authorTitle = m.authorTitle;
            }
            if (m.showDuration != null && m.hasOwnProperty("showDuration")) {
                d.showDuration = m.showDuration;
            }
            if (m.latestContentDate != null && m.hasOwnProperty("latestContentDate")) {
                if (typeof m.latestContentDate === "number")
                    d.latestContentDate = o.longs === String ? String(m.latestContentDate) : m.latestContentDate;
                else
                    d.latestContentDate = o.longs === String ? $util.Long.prototype.toString.call(m.latestContentDate) : o.longs === Number ? new $util.LongBits(m.latestContentDate.low >>> 0, m.latestContentDate.high >>> 0).toNumber() : m.latestContentDate;
            }
            if (m.tvod != null && m.hasOwnProperty("tvod")) {
                d.tvod = $root.Proto.PlaylistTVOD.toObject(m.tvod, o);
            }
            return d;
        };

        /**
         * Converts this Playlist to JSON.
         * @function toJSON
         * @memberof Proto.Playlist
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Playlist.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Playlist;
    })();

    Proto.PlaylistList = (function() {

        /**
         * Properties of a PlaylistList.
         * @memberof Proto
         * @interface IPlaylistList
         * @property {Array.<Proto.IPlaylist>|null} [value] PlaylistList value
         */

        /**
         * Constructs a new PlaylistList.
         * @memberof Proto
         * @classdesc Represents a PlaylistList.
         * @implements IPlaylistList
         * @constructor
         * @param {Proto.IPlaylistList=} [p] Properties to set
         */
        function PlaylistList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PlaylistList value.
         * @member {Array.<Proto.IPlaylist>} value
         * @memberof Proto.PlaylistList
         * @instance
         */
        PlaylistList.prototype.value = $util.emptyArray;

        /**
         * Creates a new PlaylistList instance using the specified properties.
         * @function create
         * @memberof Proto.PlaylistList
         * @static
         * @param {Proto.IPlaylistList=} [properties] Properties to set
         * @returns {Proto.PlaylistList} PlaylistList instance
         */
        PlaylistList.create = function create(properties) {
            return new PlaylistList(properties);
        };

        /**
         * Encodes the specified PlaylistList message. Does not implicitly {@link Proto.PlaylistList.verify|verify} messages.
         * @function encode
         * @memberof Proto.PlaylistList
         * @static
         * @param {Proto.IPlaylistList} m PlaylistList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlaylistList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.Playlist.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a PlaylistList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.PlaylistList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.PlaylistList} PlaylistList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlaylistList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.PlaylistList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.Playlist.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PlaylistList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.PlaylistList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.PlaylistList} PlaylistList
         */
        PlaylistList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.PlaylistList)
                return d;
            var m = new $root.Proto.PlaylistList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.PlaylistList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.PlaylistList.value: object expected");
                    m.value[i] = $root.Proto.Playlist.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a PlaylistList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.PlaylistList
         * @static
         * @param {Proto.PlaylistList} m PlaylistList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlaylistList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.Playlist.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this PlaylistList to JSON.
         * @function toJSON
         * @memberof Proto.PlaylistList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlaylistList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlaylistList;
    })();

    Proto.LoginRequest = (function() {

        /**
         * Properties of a LoginRequest.
         * @memberof Proto
         * @interface ILoginRequest
         * @property {string|null} [userId] LoginRequest userId
         * @property {string|null} [password] LoginRequest password
         * @property {string|null} [uuid] LoginRequest uuid
         * @property {string|null} [token] LoginRequest token
         * @property {number|null} [deviceType] LoginRequest deviceType
         * @property {number|null} [osType] LoginRequest osType
         * @property {number|null} [viewerType] LoginRequest viewerType
         * @property {string|null} [osVersion] LoginRequest osVersion
         * @property {string|null} [deviceModel] LoginRequest deviceModel
         * @property {string|null} [deviceVersion] LoginRequest deviceVersion
         * @property {string|null} [locale] LoginRequest locale
         * @property {string|null} [cc] LoginRequest cc
         * @property {string|null} [jwt] LoginRequest jwt
         * @property {number|null} [oauthType] LoginRequest oauthType
         * @property {string|null} [oauthToken] LoginRequest oauthToken
         * @property {string|null} [oauthTokenSecret] LoginRequest oauthTokenSecret
         * @property {number|null} [thirdOAuthType] LoginRequest thirdOAuthType
         * @property {string|null} [domain] LoginRequest domain
         * @property {Proto.INullBoolean|null} [asUser] LoginRequest asUser
         */

        /**
         * Constructs a new LoginRequest.
         * @memberof Proto
         * @classdesc Represents a LoginRequest.
         * @implements ILoginRequest
         * @constructor
         * @param {Proto.ILoginRequest=} [p] Properties to set
         */
        function LoginRequest(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * LoginRequest userId.
         * @member {string} userId
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.userId = "";

        /**
         * LoginRequest password.
         * @member {string} password
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.password = "";

        /**
         * LoginRequest uuid.
         * @member {string} uuid
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.uuid = "";

        /**
         * LoginRequest token.
         * @member {string} token
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.token = "";

        /**
         * LoginRequest deviceType.
         * @member {number} deviceType
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.deviceType = 0;

        /**
         * LoginRequest osType.
         * @member {number} osType
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.osType = 0;

        /**
         * LoginRequest viewerType.
         * @member {number} viewerType
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.viewerType = 0;

        /**
         * LoginRequest osVersion.
         * @member {string} osVersion
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.osVersion = "";

        /**
         * LoginRequest deviceModel.
         * @member {string} deviceModel
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.deviceModel = "";

        /**
         * LoginRequest deviceVersion.
         * @member {string} deviceVersion
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.deviceVersion = "";

        /**
         * LoginRequest locale.
         * @member {string} locale
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.locale = "";

        /**
         * LoginRequest cc.
         * @member {string} cc
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.cc = "";

        /**
         * LoginRequest jwt.
         * @member {string} jwt
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.jwt = "";

        /**
         * LoginRequest oauthType.
         * @member {number} oauthType
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.oauthType = 0;

        /**
         * LoginRequest oauthToken.
         * @member {string} oauthToken
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.oauthToken = "";

        /**
         * LoginRequest oauthTokenSecret.
         * @member {string} oauthTokenSecret
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.oauthTokenSecret = "";

        /**
         * LoginRequest thirdOAuthType.
         * @member {number} thirdOAuthType
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.thirdOAuthType = 0;

        /**
         * LoginRequest domain.
         * @member {string} domain
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.domain = "";

        /**
         * LoginRequest asUser.
         * @member {Proto.INullBoolean|null|undefined} asUser
         * @memberof Proto.LoginRequest
         * @instance
         */
        LoginRequest.prototype.asUser = null;

        /**
         * Creates a new LoginRequest instance using the specified properties.
         * @function create
         * @memberof Proto.LoginRequest
         * @static
         * @param {Proto.ILoginRequest=} [properties] Properties to set
         * @returns {Proto.LoginRequest} LoginRequest instance
         */
        LoginRequest.create = function create(properties) {
            return new LoginRequest(properties);
        };

        /**
         * Encodes the specified LoginRequest message. Does not implicitly {@link Proto.LoginRequest.verify|verify} messages.
         * @function encode
         * @memberof Proto.LoginRequest
         * @static
         * @param {Proto.ILoginRequest} m LoginRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(10).string(m.userId);
            if (m.password != null && Object.hasOwnProperty.call(m, "password"))
                w.uint32(18).string(m.password);
            if (m.uuid != null && Object.hasOwnProperty.call(m, "uuid"))
                w.uint32(26).string(m.uuid);
            if (m.token != null && Object.hasOwnProperty.call(m, "token"))
                w.uint32(34).string(m.token);
            if (m.deviceType != null && Object.hasOwnProperty.call(m, "deviceType"))
                w.uint32(40).int32(m.deviceType);
            if (m.osType != null && Object.hasOwnProperty.call(m, "osType"))
                w.uint32(48).int32(m.osType);
            if (m.viewerType != null && Object.hasOwnProperty.call(m, "viewerType"))
                w.uint32(56).int32(m.viewerType);
            if (m.osVersion != null && Object.hasOwnProperty.call(m, "osVersion"))
                w.uint32(66).string(m.osVersion);
            if (m.deviceModel != null && Object.hasOwnProperty.call(m, "deviceModel"))
                w.uint32(74).string(m.deviceModel);
            if (m.deviceVersion != null && Object.hasOwnProperty.call(m, "deviceVersion"))
                w.uint32(82).string(m.deviceVersion);
            if (m.locale != null && Object.hasOwnProperty.call(m, "locale"))
                w.uint32(90).string(m.locale);
            if (m.cc != null && Object.hasOwnProperty.call(m, "cc"))
                w.uint32(98).string(m.cc);
            if (m.jwt != null && Object.hasOwnProperty.call(m, "jwt"))
                w.uint32(106).string(m.jwt);
            if (m.oauthType != null && Object.hasOwnProperty.call(m, "oauthType"))
                w.uint32(112).int32(m.oauthType);
            if (m.oauthToken != null && Object.hasOwnProperty.call(m, "oauthToken"))
                w.uint32(122).string(m.oauthToken);
            if (m.oauthTokenSecret != null && Object.hasOwnProperty.call(m, "oauthTokenSecret"))
                w.uint32(130).string(m.oauthTokenSecret);
            if (m.thirdOAuthType != null && Object.hasOwnProperty.call(m, "thirdOAuthType"))
                w.uint32(136).int32(m.thirdOAuthType);
            if (m.domain != null && Object.hasOwnProperty.call(m, "domain"))
                w.uint32(146).string(m.domain);
            if (m.asUser != null && Object.hasOwnProperty.call(m, "asUser"))
                $root.Proto.NullBoolean.encode(m.asUser, w.uint32(154).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a LoginRequest message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.LoginRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.LoginRequest} LoginRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.LoginRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.userId = r.string();
                    break;
                case 2:
                    m.password = r.string();
                    break;
                case 3:
                    m.uuid = r.string();
                    break;
                case 4:
                    m.token = r.string();
                    break;
                case 5:
                    m.deviceType = r.int32();
                    break;
                case 6:
                    m.osType = r.int32();
                    break;
                case 7:
                    m.viewerType = r.int32();
                    break;
                case 8:
                    m.osVersion = r.string();
                    break;
                case 9:
                    m.deviceModel = r.string();
                    break;
                case 10:
                    m.deviceVersion = r.string();
                    break;
                case 11:
                    m.locale = r.string();
                    break;
                case 12:
                    m.cc = r.string();
                    break;
                case 13:
                    m.jwt = r.string();
                    break;
                case 14:
                    m.oauthType = r.int32();
                    break;
                case 15:
                    m.oauthToken = r.string();
                    break;
                case 16:
                    m.oauthTokenSecret = r.string();
                    break;
                case 17:
                    m.thirdOAuthType = r.int32();
                    break;
                case 18:
                    m.domain = r.string();
                    break;
                case 19:
                    m.asUser = $root.Proto.NullBoolean.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a LoginRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.LoginRequest
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.LoginRequest} LoginRequest
         */
        LoginRequest.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.LoginRequest)
                return d;
            var m = new $root.Proto.LoginRequest();
            if (d.userId != null) {
                m.userId = String(d.userId);
            }
            if (d.password != null) {
                m.password = String(d.password);
            }
            if (d.uuid != null) {
                m.uuid = String(d.uuid);
            }
            if (d.token != null) {
                m.token = String(d.token);
            }
            if (d.deviceType != null) {
                m.deviceType = d.deviceType | 0;
            }
            if (d.osType != null) {
                m.osType = d.osType | 0;
            }
            if (d.viewerType != null) {
                m.viewerType = d.viewerType | 0;
            }
            if (d.osVersion != null) {
                m.osVersion = String(d.osVersion);
            }
            if (d.deviceModel != null) {
                m.deviceModel = String(d.deviceModel);
            }
            if (d.deviceVersion != null) {
                m.deviceVersion = String(d.deviceVersion);
            }
            if (d.locale != null) {
                m.locale = String(d.locale);
            }
            if (d.cc != null) {
                m.cc = String(d.cc);
            }
            if (d.jwt != null) {
                m.jwt = String(d.jwt);
            }
            if (d.oauthType != null) {
                m.oauthType = d.oauthType | 0;
            }
            if (d.oauthToken != null) {
                m.oauthToken = String(d.oauthToken);
            }
            if (d.oauthTokenSecret != null) {
                m.oauthTokenSecret = String(d.oauthTokenSecret);
            }
            if (d.thirdOAuthType != null) {
                m.thirdOAuthType = d.thirdOAuthType | 0;
            }
            if (d.domain != null) {
                m.domain = String(d.domain);
            }
            if (d.asUser != null) {
                if (typeof d.asUser !== "object")
                    throw TypeError(".Proto.LoginRequest.asUser: object expected");
                m.asUser = $root.Proto.NullBoolean.fromObject(d.asUser);
            }
            return m;
        };

        /**
         * Creates a plain object from a LoginRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.LoginRequest
         * @static
         * @param {Proto.LoginRequest} m LoginRequest
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginRequest.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.userId = "";
                d.password = "";
                d.uuid = "";
                d.token = "";
                d.deviceType = 0;
                d.osType = 0;
                d.viewerType = 0;
                d.osVersion = "";
                d.deviceModel = "";
                d.deviceVersion = "";
                d.locale = "";
                d.cc = "";
                d.jwt = "";
                d.oauthType = 0;
                d.oauthToken = "";
                d.oauthTokenSecret = "";
                d.thirdOAuthType = 0;
                d.domain = "";
                d.asUser = null;
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                d.userId = m.userId;
            }
            if (m.password != null && m.hasOwnProperty("password")) {
                d.password = m.password;
            }
            if (m.uuid != null && m.hasOwnProperty("uuid")) {
                d.uuid = m.uuid;
            }
            if (m.token != null && m.hasOwnProperty("token")) {
                d.token = m.token;
            }
            if (m.deviceType != null && m.hasOwnProperty("deviceType")) {
                d.deviceType = m.deviceType;
            }
            if (m.osType != null && m.hasOwnProperty("osType")) {
                d.osType = m.osType;
            }
            if (m.viewerType != null && m.hasOwnProperty("viewerType")) {
                d.viewerType = m.viewerType;
            }
            if (m.osVersion != null && m.hasOwnProperty("osVersion")) {
                d.osVersion = m.osVersion;
            }
            if (m.deviceModel != null && m.hasOwnProperty("deviceModel")) {
                d.deviceModel = m.deviceModel;
            }
            if (m.deviceVersion != null && m.hasOwnProperty("deviceVersion")) {
                d.deviceVersion = m.deviceVersion;
            }
            if (m.locale != null && m.hasOwnProperty("locale")) {
                d.locale = m.locale;
            }
            if (m.cc != null && m.hasOwnProperty("cc")) {
                d.cc = m.cc;
            }
            if (m.jwt != null && m.hasOwnProperty("jwt")) {
                d.jwt = m.jwt;
            }
            if (m.oauthType != null && m.hasOwnProperty("oauthType")) {
                d.oauthType = m.oauthType;
            }
            if (m.oauthToken != null && m.hasOwnProperty("oauthToken")) {
                d.oauthToken = m.oauthToken;
            }
            if (m.oauthTokenSecret != null && m.hasOwnProperty("oauthTokenSecret")) {
                d.oauthTokenSecret = m.oauthTokenSecret;
            }
            if (m.thirdOAuthType != null && m.hasOwnProperty("thirdOAuthType")) {
                d.thirdOAuthType = m.thirdOAuthType;
            }
            if (m.domain != null && m.hasOwnProperty("domain")) {
                d.domain = m.domain;
            }
            if (m.asUser != null && m.hasOwnProperty("asUser")) {
                d.asUser = $root.Proto.NullBoolean.toObject(m.asUser, o);
            }
            return d;
        };

        /**
         * Converts this LoginRequest to JSON.
         * @function toJSON
         * @memberof Proto.LoginRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoginRequest;
    })();

    Proto.ViewerVideoListRequest = (function() {

        /**
         * Properties of a ViewerVideoListRequest.
         * @memberof Proto
         * @interface IViewerVideoListRequest
         * @property {boolean|null} [ios] ViewerVideoListRequest ios
         * @property {boolean|null} [android] ViewerVideoListRequest android
         * @property {boolean|null} [tablet] ViewerVideoListRequest tablet
         * @property {boolean|null} [pc] ViewerVideoListRequest pc
         * @property {boolean|null} [safari] ViewerVideoListRequest safari
         * @property {boolean|null} [eme] ViewerVideoListRequest eme
         * @property {boolean|null} [app] ViewerVideoListRequest app
         * @property {string|null} [osVersion] ViewerVideoListRequest osVersion
         * @property {string|null} [userAgent] ViewerVideoListRequest userAgent
         * @property {string|null} [locale] ViewerVideoListRequest locale
         * @property {Array.<number>|null} [types] ViewerVideoListRequest types
         * @property {string|null} [contentUID] ViewerVideoListRequest contentUID
         * @property {string|null} [rawCodec] ViewerVideoListRequest rawCodec
         * @property {string|null} [topicUID] ViewerVideoListRequest topicUID
         * @property {string|null} [commentUID] ViewerVideoListRequest commentUID
         * @property {boolean|null} [customDomain] ViewerVideoListRequest customDomain
         */

        /**
         * Constructs a new ViewerVideoListRequest.
         * @memberof Proto
         * @classdesc Represents a ViewerVideoListRequest.
         * @implements IViewerVideoListRequest
         * @constructor
         * @param {Proto.IViewerVideoListRequest=} [p] Properties to set
         */
        function ViewerVideoListRequest(p) {
            this.types = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ViewerVideoListRequest ios.
         * @member {boolean} ios
         * @memberof Proto.ViewerVideoListRequest
         * @instance
         */
        ViewerVideoListRequest.prototype.ios = false;

        /**
         * ViewerVideoListRequest android.
         * @member {boolean} android
         * @memberof Proto.ViewerVideoListRequest
         * @instance
         */
        ViewerVideoListRequest.prototype.android = false;

        /**
         * ViewerVideoListRequest tablet.
         * @member {boolean} tablet
         * @memberof Proto.ViewerVideoListRequest
         * @instance
         */
        ViewerVideoListRequest.prototype.tablet = false;

        /**
         * ViewerVideoListRequest pc.
         * @member {boolean} pc
         * @memberof Proto.ViewerVideoListRequest
         * @instance
         */
        ViewerVideoListRequest.prototype.pc = false;

        /**
         * ViewerVideoListRequest safari.
         * @member {boolean} safari
         * @memberof Proto.ViewerVideoListRequest
         * @instance
         */
        ViewerVideoListRequest.prototype.safari = false;

        /**
         * ViewerVideoListRequest eme.
         * @member {boolean} eme
         * @memberof Proto.ViewerVideoListRequest
         * @instance
         */
        ViewerVideoListRequest.prototype.eme = false;

        /**
         * ViewerVideoListRequest app.
         * @member {boolean} app
         * @memberof Proto.ViewerVideoListRequest
         * @instance
         */
        ViewerVideoListRequest.prototype.app = false;

        /**
         * ViewerVideoListRequest osVersion.
         * @member {string} osVersion
         * @memberof Proto.ViewerVideoListRequest
         * @instance
         */
        ViewerVideoListRequest.prototype.osVersion = "";

        /**
         * ViewerVideoListRequest userAgent.
         * @member {string} userAgent
         * @memberof Proto.ViewerVideoListRequest
         * @instance
         */
        ViewerVideoListRequest.prototype.userAgent = "";

        /**
         * ViewerVideoListRequest locale.
         * @member {string} locale
         * @memberof Proto.ViewerVideoListRequest
         * @instance
         */
        ViewerVideoListRequest.prototype.locale = "";

        /**
         * ViewerVideoListRequest types.
         * @member {Array.<number>} types
         * @memberof Proto.ViewerVideoListRequest
         * @instance
         */
        ViewerVideoListRequest.prototype.types = $util.emptyArray;

        /**
         * ViewerVideoListRequest contentUID.
         * @member {string} contentUID
         * @memberof Proto.ViewerVideoListRequest
         * @instance
         */
        ViewerVideoListRequest.prototype.contentUID = "";

        /**
         * ViewerVideoListRequest rawCodec.
         * @member {string} rawCodec
         * @memberof Proto.ViewerVideoListRequest
         * @instance
         */
        ViewerVideoListRequest.prototype.rawCodec = "";

        /**
         * ViewerVideoListRequest topicUID.
         * @member {string} topicUID
         * @memberof Proto.ViewerVideoListRequest
         * @instance
         */
        ViewerVideoListRequest.prototype.topicUID = "";

        /**
         * ViewerVideoListRequest commentUID.
         * @member {string} commentUID
         * @memberof Proto.ViewerVideoListRequest
         * @instance
         */
        ViewerVideoListRequest.prototype.commentUID = "";

        /**
         * ViewerVideoListRequest customDomain.
         * @member {boolean} customDomain
         * @memberof Proto.ViewerVideoListRequest
         * @instance
         */
        ViewerVideoListRequest.prototype.customDomain = false;

        /**
         * Creates a new ViewerVideoListRequest instance using the specified properties.
         * @function create
         * @memberof Proto.ViewerVideoListRequest
         * @static
         * @param {Proto.IViewerVideoListRequest=} [properties] Properties to set
         * @returns {Proto.ViewerVideoListRequest} ViewerVideoListRequest instance
         */
        ViewerVideoListRequest.create = function create(properties) {
            return new ViewerVideoListRequest(properties);
        };

        /**
         * Encodes the specified ViewerVideoListRequest message. Does not implicitly {@link Proto.ViewerVideoListRequest.verify|verify} messages.
         * @function encode
         * @memberof Proto.ViewerVideoListRequest
         * @static
         * @param {Proto.IViewerVideoListRequest} m ViewerVideoListRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ViewerVideoListRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.ios != null && Object.hasOwnProperty.call(m, "ios"))
                w.uint32(8).bool(m.ios);
            if (m.android != null && Object.hasOwnProperty.call(m, "android"))
                w.uint32(16).bool(m.android);
            if (m.tablet != null && Object.hasOwnProperty.call(m, "tablet"))
                w.uint32(24).bool(m.tablet);
            if (m.pc != null && Object.hasOwnProperty.call(m, "pc"))
                w.uint32(32).bool(m.pc);
            if (m.safari != null && Object.hasOwnProperty.call(m, "safari"))
                w.uint32(40).bool(m.safari);
            if (m.eme != null && Object.hasOwnProperty.call(m, "eme"))
                w.uint32(48).bool(m.eme);
            if (m.app != null && Object.hasOwnProperty.call(m, "app"))
                w.uint32(56).bool(m.app);
            if (m.osVersion != null && Object.hasOwnProperty.call(m, "osVersion"))
                w.uint32(66).string(m.osVersion);
            if (m.userAgent != null && Object.hasOwnProperty.call(m, "userAgent"))
                w.uint32(74).string(m.userAgent);
            if (m.locale != null && Object.hasOwnProperty.call(m, "locale"))
                w.uint32(82).string(m.locale);
            if (m.types != null && m.types.length) {
                w.uint32(90).fork();
                for (var i = 0; i < m.types.length; ++i)
                    w.int32(m.types[i]);
                w.ldelim();
            }
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(98).string(m.contentUID);
            if (m.rawCodec != null && Object.hasOwnProperty.call(m, "rawCodec"))
                w.uint32(106).string(m.rawCodec);
            if (m.topicUID != null && Object.hasOwnProperty.call(m, "topicUID"))
                w.uint32(114).string(m.topicUID);
            if (m.commentUID != null && Object.hasOwnProperty.call(m, "commentUID"))
                w.uint32(122).string(m.commentUID);
            if (m.customDomain != null && Object.hasOwnProperty.call(m, "customDomain"))
                w.uint32(128).bool(m.customDomain);
            return w;
        };

        /**
         * Decodes a ViewerVideoListRequest message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ViewerVideoListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ViewerVideoListRequest} ViewerVideoListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ViewerVideoListRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ViewerVideoListRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.ios = r.bool();
                    break;
                case 2:
                    m.android = r.bool();
                    break;
                case 3:
                    m.tablet = r.bool();
                    break;
                case 4:
                    m.pc = r.bool();
                    break;
                case 5:
                    m.safari = r.bool();
                    break;
                case 6:
                    m.eme = r.bool();
                    break;
                case 7:
                    m.app = r.bool();
                    break;
                case 8:
                    m.osVersion = r.string();
                    break;
                case 9:
                    m.userAgent = r.string();
                    break;
                case 10:
                    m.locale = r.string();
                    break;
                case 11:
                    if (!(m.types && m.types.length))
                        m.types = [];
                    if ((t & 7) === 2) {
                        var c2 = r.uint32() + r.pos;
                        while (r.pos < c2)
                            m.types.push(r.int32());
                    } else
                        m.types.push(r.int32());
                    break;
                case 12:
                    m.contentUID = r.string();
                    break;
                case 13:
                    m.rawCodec = r.string();
                    break;
                case 14:
                    m.topicUID = r.string();
                    break;
                case 15:
                    m.commentUID = r.string();
                    break;
                case 16:
                    m.customDomain = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ViewerVideoListRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ViewerVideoListRequest
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ViewerVideoListRequest} ViewerVideoListRequest
         */
        ViewerVideoListRequest.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ViewerVideoListRequest)
                return d;
            var m = new $root.Proto.ViewerVideoListRequest();
            if (d.ios != null) {
                m.ios = Boolean(d.ios);
            }
            if (d.android != null) {
                m.android = Boolean(d.android);
            }
            if (d.tablet != null) {
                m.tablet = Boolean(d.tablet);
            }
            if (d.pc != null) {
                m.pc = Boolean(d.pc);
            }
            if (d.safari != null) {
                m.safari = Boolean(d.safari);
            }
            if (d.eme != null) {
                m.eme = Boolean(d.eme);
            }
            if (d.app != null) {
                m.app = Boolean(d.app);
            }
            if (d.osVersion != null) {
                m.osVersion = String(d.osVersion);
            }
            if (d.userAgent != null) {
                m.userAgent = String(d.userAgent);
            }
            if (d.locale != null) {
                m.locale = String(d.locale);
            }
            if (d.types) {
                if (!Array.isArray(d.types))
                    throw TypeError(".Proto.ViewerVideoListRequest.types: array expected");
                m.types = [];
                for (var i = 0; i < d.types.length; ++i) {
                    m.types[i] = d.types[i] | 0;
                }
            }
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.rawCodec != null) {
                m.rawCodec = String(d.rawCodec);
            }
            if (d.topicUID != null) {
                m.topicUID = String(d.topicUID);
            }
            if (d.commentUID != null) {
                m.commentUID = String(d.commentUID);
            }
            if (d.customDomain != null) {
                m.customDomain = Boolean(d.customDomain);
            }
            return m;
        };

        /**
         * Creates a plain object from a ViewerVideoListRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ViewerVideoListRequest
         * @static
         * @param {Proto.ViewerVideoListRequest} m ViewerVideoListRequest
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ViewerVideoListRequest.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.types = [];
            }
            if (o.defaults) {
                d.ios = false;
                d.android = false;
                d.tablet = false;
                d.pc = false;
                d.safari = false;
                d.eme = false;
                d.app = false;
                d.osVersion = "";
                d.userAgent = "";
                d.locale = "";
                d.contentUID = "";
                d.rawCodec = "";
                d.topicUID = "";
                d.commentUID = "";
                d.customDomain = false;
            }
            if (m.ios != null && m.hasOwnProperty("ios")) {
                d.ios = m.ios;
            }
            if (m.android != null && m.hasOwnProperty("android")) {
                d.android = m.android;
            }
            if (m.tablet != null && m.hasOwnProperty("tablet")) {
                d.tablet = m.tablet;
            }
            if (m.pc != null && m.hasOwnProperty("pc")) {
                d.pc = m.pc;
            }
            if (m.safari != null && m.hasOwnProperty("safari")) {
                d.safari = m.safari;
            }
            if (m.eme != null && m.hasOwnProperty("eme")) {
                d.eme = m.eme;
            }
            if (m.app != null && m.hasOwnProperty("app")) {
                d.app = m.app;
            }
            if (m.osVersion != null && m.hasOwnProperty("osVersion")) {
                d.osVersion = m.osVersion;
            }
            if (m.userAgent != null && m.hasOwnProperty("userAgent")) {
                d.userAgent = m.userAgent;
            }
            if (m.locale != null && m.hasOwnProperty("locale")) {
                d.locale = m.locale;
            }
            if (m.types && m.types.length) {
                d.types = [];
                for (var j = 0; j < m.types.length; ++j) {
                    d.types[j] = m.types[j];
                }
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.rawCodec != null && m.hasOwnProperty("rawCodec")) {
                d.rawCodec = m.rawCodec;
            }
            if (m.topicUID != null && m.hasOwnProperty("topicUID")) {
                d.topicUID = m.topicUID;
            }
            if (m.commentUID != null && m.hasOwnProperty("commentUID")) {
                d.commentUID = m.commentUID;
            }
            if (m.customDomain != null && m.hasOwnProperty("customDomain")) {
                d.customDomain = m.customDomain;
            }
            return d;
        };

        /**
         * Converts this ViewerVideoListRequest to JSON.
         * @function toJSON
         * @memberof Proto.ViewerVideoListRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ViewerVideoListRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ViewerVideoListRequest;
    })();

    Proto.ContentPlayStatRequest = (function() {

        /**
         * Properties of a ContentPlayStatRequest.
         * @memberof Proto
         * @interface IContentPlayStatRequest
         * @property {string|null} [playlistUID] ContentPlayStatRequest playlistUID
         * @property {string|null} [contentUID] ContentPlayStatRequest contentUID
         * @property {string|null} [playId] ContentPlayStatRequest playId
         * @property {string|null} [bufferId] ContentPlayStatRequest bufferId
         * @property {number|null} [play] ContentPlayStatRequest play
         * @property {number|null} [buffer] ContentPlayStatRequest buffer
         * @property {number|null} [end] ContentPlayStatRequest end
         * @property {number|null} [duration] ContentPlayStatRequest duration
         * @property {number|null} [viewerType] ContentPlayStatRequest viewerType
         * @property {string|null} [deviceKey] ContentPlayStatRequest deviceKey
         * @property {number|Long|null} [ts] ContentPlayStatRequest ts
         */

        /**
         * Constructs a new ContentPlayStatRequest.
         * @memberof Proto
         * @classdesc Represents a ContentPlayStatRequest.
         * @implements IContentPlayStatRequest
         * @constructor
         * @param {Proto.IContentPlayStatRequest=} [p] Properties to set
         */
        function ContentPlayStatRequest(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentPlayStatRequest playlistUID.
         * @member {string} playlistUID
         * @memberof Proto.ContentPlayStatRequest
         * @instance
         */
        ContentPlayStatRequest.prototype.playlistUID = "";

        /**
         * ContentPlayStatRequest contentUID.
         * @member {string} contentUID
         * @memberof Proto.ContentPlayStatRequest
         * @instance
         */
        ContentPlayStatRequest.prototype.contentUID = "";

        /**
         * ContentPlayStatRequest playId.
         * @member {string} playId
         * @memberof Proto.ContentPlayStatRequest
         * @instance
         */
        ContentPlayStatRequest.prototype.playId = "";

        /**
         * ContentPlayStatRequest bufferId.
         * @member {string} bufferId
         * @memberof Proto.ContentPlayStatRequest
         * @instance
         */
        ContentPlayStatRequest.prototype.bufferId = "";

        /**
         * ContentPlayStatRequest play.
         * @member {number} play
         * @memberof Proto.ContentPlayStatRequest
         * @instance
         */
        ContentPlayStatRequest.prototype.play = 0;

        /**
         * ContentPlayStatRequest buffer.
         * @member {number} buffer
         * @memberof Proto.ContentPlayStatRequest
         * @instance
         */
        ContentPlayStatRequest.prototype.buffer = 0;

        /**
         * ContentPlayStatRequest end.
         * @member {number} end
         * @memberof Proto.ContentPlayStatRequest
         * @instance
         */
        ContentPlayStatRequest.prototype.end = 0;

        /**
         * ContentPlayStatRequest duration.
         * @member {number} duration
         * @memberof Proto.ContentPlayStatRequest
         * @instance
         */
        ContentPlayStatRequest.prototype.duration = 0;

        /**
         * ContentPlayStatRequest viewerType.
         * @member {number} viewerType
         * @memberof Proto.ContentPlayStatRequest
         * @instance
         */
        ContentPlayStatRequest.prototype.viewerType = 0;

        /**
         * ContentPlayStatRequest deviceKey.
         * @member {string} deviceKey
         * @memberof Proto.ContentPlayStatRequest
         * @instance
         */
        ContentPlayStatRequest.prototype.deviceKey = "";

        /**
         * ContentPlayStatRequest ts.
         * @member {number|Long} ts
         * @memberof Proto.ContentPlayStatRequest
         * @instance
         */
        ContentPlayStatRequest.prototype.ts = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new ContentPlayStatRequest instance using the specified properties.
         * @function create
         * @memberof Proto.ContentPlayStatRequest
         * @static
         * @param {Proto.IContentPlayStatRequest=} [properties] Properties to set
         * @returns {Proto.ContentPlayStatRequest} ContentPlayStatRequest instance
         */
        ContentPlayStatRequest.create = function create(properties) {
            return new ContentPlayStatRequest(properties);
        };

        /**
         * Encodes the specified ContentPlayStatRequest message. Does not implicitly {@link Proto.ContentPlayStatRequest.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentPlayStatRequest
         * @static
         * @param {Proto.IContentPlayStatRequest} m ContentPlayStatRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentPlayStatRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.playlistUID != null && Object.hasOwnProperty.call(m, "playlistUID"))
                w.uint32(10).string(m.playlistUID);
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(18).string(m.contentUID);
            if (m.playId != null && Object.hasOwnProperty.call(m, "playId"))
                w.uint32(26).string(m.playId);
            if (m.bufferId != null && Object.hasOwnProperty.call(m, "bufferId"))
                w.uint32(34).string(m.bufferId);
            if (m.play != null && Object.hasOwnProperty.call(m, "play"))
                w.uint32(41).double(m.play);
            if (m.buffer != null && Object.hasOwnProperty.call(m, "buffer"))
                w.uint32(49).double(m.buffer);
            if (m.end != null && Object.hasOwnProperty.call(m, "end"))
                w.uint32(57).double(m.end);
            if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                w.uint32(65).double(m.duration);
            if (m.viewerType != null && Object.hasOwnProperty.call(m, "viewerType"))
                w.uint32(72).int32(m.viewerType);
            if (m.deviceKey != null && Object.hasOwnProperty.call(m, "deviceKey"))
                w.uint32(82).string(m.deviceKey);
            if (m.ts != null && Object.hasOwnProperty.call(m, "ts"))
                w.uint32(88).int64(m.ts);
            return w;
        };

        /**
         * Decodes a ContentPlayStatRequest message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentPlayStatRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentPlayStatRequest} ContentPlayStatRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentPlayStatRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentPlayStatRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.playlistUID = r.string();
                    break;
                case 2:
                    m.contentUID = r.string();
                    break;
                case 3:
                    m.playId = r.string();
                    break;
                case 4:
                    m.bufferId = r.string();
                    break;
                case 5:
                    m.play = r.double();
                    break;
                case 6:
                    m.buffer = r.double();
                    break;
                case 7:
                    m.end = r.double();
                    break;
                case 8:
                    m.duration = r.double();
                    break;
                case 9:
                    m.viewerType = r.int32();
                    break;
                case 10:
                    m.deviceKey = r.string();
                    break;
                case 11:
                    m.ts = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentPlayStatRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentPlayStatRequest
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentPlayStatRequest} ContentPlayStatRequest
         */
        ContentPlayStatRequest.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentPlayStatRequest)
                return d;
            var m = new $root.Proto.ContentPlayStatRequest();
            if (d.playlistUID != null) {
                m.playlistUID = String(d.playlistUID);
            }
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.playId != null) {
                m.playId = String(d.playId);
            }
            if (d.bufferId != null) {
                m.bufferId = String(d.bufferId);
            }
            if (d.play != null) {
                m.play = Number(d.play);
            }
            if (d.buffer != null) {
                m.buffer = Number(d.buffer);
            }
            if (d.end != null) {
                m.end = Number(d.end);
            }
            if (d.duration != null) {
                m.duration = Number(d.duration);
            }
            if (d.viewerType != null) {
                m.viewerType = d.viewerType | 0;
            }
            if (d.deviceKey != null) {
                m.deviceKey = String(d.deviceKey);
            }
            if (d.ts != null) {
                if ($util.Long)
                    (m.ts = $util.Long.fromValue(d.ts)).unsigned = false;
                else if (typeof d.ts === "string")
                    m.ts = parseInt(d.ts, 10);
                else if (typeof d.ts === "number")
                    m.ts = d.ts;
                else if (typeof d.ts === "object")
                    m.ts = new $util.LongBits(d.ts.low >>> 0, d.ts.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentPlayStatRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentPlayStatRequest
         * @static
         * @param {Proto.ContentPlayStatRequest} m ContentPlayStatRequest
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentPlayStatRequest.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.playlistUID = "";
                d.contentUID = "";
                d.playId = "";
                d.bufferId = "";
                d.play = 0;
                d.buffer = 0;
                d.end = 0;
                d.duration = 0;
                d.viewerType = 0;
                d.deviceKey = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.ts = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.ts = o.longs === String ? "0" : 0;
            }
            if (m.playlistUID != null && m.hasOwnProperty("playlistUID")) {
                d.playlistUID = m.playlistUID;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.playId != null && m.hasOwnProperty("playId")) {
                d.playId = m.playId;
            }
            if (m.bufferId != null && m.hasOwnProperty("bufferId")) {
                d.bufferId = m.bufferId;
            }
            if (m.play != null && m.hasOwnProperty("play")) {
                d.play = o.json && !isFinite(m.play) ? String(m.play) : m.play;
            }
            if (m.buffer != null && m.hasOwnProperty("buffer")) {
                d.buffer = o.json && !isFinite(m.buffer) ? String(m.buffer) : m.buffer;
            }
            if (m.end != null && m.hasOwnProperty("end")) {
                d.end = o.json && !isFinite(m.end) ? String(m.end) : m.end;
            }
            if (m.duration != null && m.hasOwnProperty("duration")) {
                d.duration = o.json && !isFinite(m.duration) ? String(m.duration) : m.duration;
            }
            if (m.viewerType != null && m.hasOwnProperty("viewerType")) {
                d.viewerType = m.viewerType;
            }
            if (m.deviceKey != null && m.hasOwnProperty("deviceKey")) {
                d.deviceKey = m.deviceKey;
            }
            if (m.ts != null && m.hasOwnProperty("ts")) {
                if (typeof m.ts === "number")
                    d.ts = o.longs === String ? String(m.ts) : m.ts;
                else
                    d.ts = o.longs === String ? $util.Long.prototype.toString.call(m.ts) : o.longs === Number ? new $util.LongBits(m.ts.low >>> 0, m.ts.high >>> 0).toNumber() : m.ts;
            }
            return d;
        };

        /**
         * Converts this ContentPlayStatRequest to JSON.
         * @function toJSON
         * @memberof Proto.ContentPlayStatRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentPlayStatRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentPlayStatRequest;
    })();

    Proto.ContentPlayStatRequestList = (function() {

        /**
         * Properties of a ContentPlayStatRequestList.
         * @memberof Proto
         * @interface IContentPlayStatRequestList
         * @property {Array.<Proto.IContentPlayStatRequest>|null} [value] ContentPlayStatRequestList value
         */

        /**
         * Constructs a new ContentPlayStatRequestList.
         * @memberof Proto
         * @classdesc Represents a ContentPlayStatRequestList.
         * @implements IContentPlayStatRequestList
         * @constructor
         * @param {Proto.IContentPlayStatRequestList=} [p] Properties to set
         */
        function ContentPlayStatRequestList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentPlayStatRequestList value.
         * @member {Array.<Proto.IContentPlayStatRequest>} value
         * @memberof Proto.ContentPlayStatRequestList
         * @instance
         */
        ContentPlayStatRequestList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentPlayStatRequestList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentPlayStatRequestList
         * @static
         * @param {Proto.IContentPlayStatRequestList=} [properties] Properties to set
         * @returns {Proto.ContentPlayStatRequestList} ContentPlayStatRequestList instance
         */
        ContentPlayStatRequestList.create = function create(properties) {
            return new ContentPlayStatRequestList(properties);
        };

        /**
         * Encodes the specified ContentPlayStatRequestList message. Does not implicitly {@link Proto.ContentPlayStatRequestList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentPlayStatRequestList
         * @static
         * @param {Proto.IContentPlayStatRequestList} m ContentPlayStatRequestList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentPlayStatRequestList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentPlayStatRequest.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentPlayStatRequestList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentPlayStatRequestList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentPlayStatRequestList} ContentPlayStatRequestList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentPlayStatRequestList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentPlayStatRequestList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentPlayStatRequest.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentPlayStatRequestList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentPlayStatRequestList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentPlayStatRequestList} ContentPlayStatRequestList
         */
        ContentPlayStatRequestList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentPlayStatRequestList)
                return d;
            var m = new $root.Proto.ContentPlayStatRequestList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentPlayStatRequestList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentPlayStatRequestList.value: object expected");
                    m.value[i] = $root.Proto.ContentPlayStatRequest.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentPlayStatRequestList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentPlayStatRequestList
         * @static
         * @param {Proto.ContentPlayStatRequestList} m ContentPlayStatRequestList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentPlayStatRequestList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentPlayStatRequest.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentPlayStatRequestList to JSON.
         * @function toJSON
         * @memberof Proto.ContentPlayStatRequestList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentPlayStatRequestList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentPlayStatRequestList;
    })();

    Proto.ContentPlayStatResponse = (function() {

        /**
         * Properties of a ContentPlayStatResponse.
         * @memberof Proto
         * @interface IContentPlayStatResponse
         * @property {string|null} [playId] ContentPlayStatResponse playId
         * @property {string|null} [bufferId] ContentPlayStatResponse bufferId
         */

        /**
         * Constructs a new ContentPlayStatResponse.
         * @memberof Proto
         * @classdesc Represents a ContentPlayStatResponse.
         * @implements IContentPlayStatResponse
         * @constructor
         * @param {Proto.IContentPlayStatResponse=} [p] Properties to set
         */
        function ContentPlayStatResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentPlayStatResponse playId.
         * @member {string} playId
         * @memberof Proto.ContentPlayStatResponse
         * @instance
         */
        ContentPlayStatResponse.prototype.playId = "";

        /**
         * ContentPlayStatResponse bufferId.
         * @member {string} bufferId
         * @memberof Proto.ContentPlayStatResponse
         * @instance
         */
        ContentPlayStatResponse.prototype.bufferId = "";

        /**
         * Creates a new ContentPlayStatResponse instance using the specified properties.
         * @function create
         * @memberof Proto.ContentPlayStatResponse
         * @static
         * @param {Proto.IContentPlayStatResponse=} [properties] Properties to set
         * @returns {Proto.ContentPlayStatResponse} ContentPlayStatResponse instance
         */
        ContentPlayStatResponse.create = function create(properties) {
            return new ContentPlayStatResponse(properties);
        };

        /**
         * Encodes the specified ContentPlayStatResponse message. Does not implicitly {@link Proto.ContentPlayStatResponse.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentPlayStatResponse
         * @static
         * @param {Proto.IContentPlayStatResponse} m ContentPlayStatResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentPlayStatResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.playId != null && Object.hasOwnProperty.call(m, "playId"))
                w.uint32(10).string(m.playId);
            if (m.bufferId != null && Object.hasOwnProperty.call(m, "bufferId"))
                w.uint32(18).string(m.bufferId);
            return w;
        };

        /**
         * Decodes a ContentPlayStatResponse message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentPlayStatResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentPlayStatResponse} ContentPlayStatResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentPlayStatResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentPlayStatResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.playId = r.string();
                    break;
                case 2:
                    m.bufferId = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentPlayStatResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentPlayStatResponse
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentPlayStatResponse} ContentPlayStatResponse
         */
        ContentPlayStatResponse.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentPlayStatResponse)
                return d;
            var m = new $root.Proto.ContentPlayStatResponse();
            if (d.playId != null) {
                m.playId = String(d.playId);
            }
            if (d.bufferId != null) {
                m.bufferId = String(d.bufferId);
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentPlayStatResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentPlayStatResponse
         * @static
         * @param {Proto.ContentPlayStatResponse} m ContentPlayStatResponse
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentPlayStatResponse.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.playId = "";
                d.bufferId = "";
            }
            if (m.playId != null && m.hasOwnProperty("playId")) {
                d.playId = m.playId;
            }
            if (m.bufferId != null && m.hasOwnProperty("bufferId")) {
                d.bufferId = m.bufferId;
            }
            return d;
        };

        /**
         * Converts this ContentPlayStatResponse to JSON.
         * @function toJSON
         * @memberof Proto.ContentPlayStatResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentPlayStatResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentPlayStatResponse;
    })();

    Proto.ContentChapter = (function() {

        /**
         * Properties of a ContentChapter.
         * @memberof Proto
         * @interface IContentChapter
         * @property {string|null} [contentUID] ContentChapter contentUID
         * @property {number|null} [time] ContentChapter time
         * @property {string|null} [title] ContentChapter title
         * @property {number|Long|null} [registerDate] ContentChapter registerDate
         * @property {number|Long|null} [modifyDate] ContentChapter modifyDate
         * @property {number|null} [status] ContentChapter status
         */

        /**
         * Constructs a new ContentChapter.
         * @memberof Proto
         * @classdesc Represents a ContentChapter.
         * @implements IContentChapter
         * @constructor
         * @param {Proto.IContentChapter=} [p] Properties to set
         */
        function ContentChapter(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentChapter contentUID.
         * @member {string} contentUID
         * @memberof Proto.ContentChapter
         * @instance
         */
        ContentChapter.prototype.contentUID = "";

        /**
         * ContentChapter time.
         * @member {number} time
         * @memberof Proto.ContentChapter
         * @instance
         */
        ContentChapter.prototype.time = 0;

        /**
         * ContentChapter title.
         * @member {string} title
         * @memberof Proto.ContentChapter
         * @instance
         */
        ContentChapter.prototype.title = "";

        /**
         * ContentChapter registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentChapter
         * @instance
         */
        ContentChapter.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentChapter modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentChapter
         * @instance
         */
        ContentChapter.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentChapter status.
         * @member {number} status
         * @memberof Proto.ContentChapter
         * @instance
         */
        ContentChapter.prototype.status = 0;

        /**
         * Creates a new ContentChapter instance using the specified properties.
         * @function create
         * @memberof Proto.ContentChapter
         * @static
         * @param {Proto.IContentChapter=} [properties] Properties to set
         * @returns {Proto.ContentChapter} ContentChapter instance
         */
        ContentChapter.create = function create(properties) {
            return new ContentChapter(properties);
        };

        /**
         * Encodes the specified ContentChapter message. Does not implicitly {@link Proto.ContentChapter.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentChapter
         * @static
         * @param {Proto.IContentChapter} m ContentChapter message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentChapter.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(10).string(m.contentUID);
            if (m.time != null && Object.hasOwnProperty.call(m, "time"))
                w.uint32(17).double(m.time);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(26).string(m.title);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(48).int32(m.status);
            return w;
        };

        /**
         * Decodes a ContentChapter message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentChapter
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentChapter} ContentChapter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentChapter.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentChapter();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.contentUID = r.string();
                    break;
                case 2:
                    m.time = r.double();
                    break;
                case 3:
                    m.title = r.string();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                case 6:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentChapter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentChapter
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentChapter} ContentChapter
         */
        ContentChapter.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentChapter)
                return d;
            var m = new $root.Proto.ContentChapter();
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.time != null) {
                m.time = Number(d.time);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentChapter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentChapter
         * @static
         * @param {Proto.ContentChapter} m ContentChapter
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentChapter.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.contentUID = "";
                d.time = 0;
                d.title = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.time != null && m.hasOwnProperty("time")) {
                d.time = o.json && !isFinite(m.time) ? String(m.time) : m.time;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this ContentChapter to JSON.
         * @function toJSON
         * @memberof Proto.ContentChapter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentChapter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentChapter;
    })();

    Proto.ContentChapterList = (function() {

        /**
         * Properties of a ContentChapterList.
         * @memberof Proto
         * @interface IContentChapterList
         * @property {Array.<Proto.IContentChapter>|null} [value] ContentChapterList value
         */

        /**
         * Constructs a new ContentChapterList.
         * @memberof Proto
         * @classdesc Represents a ContentChapterList.
         * @implements IContentChapterList
         * @constructor
         * @param {Proto.IContentChapterList=} [p] Properties to set
         */
        function ContentChapterList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentChapterList value.
         * @member {Array.<Proto.IContentChapter>} value
         * @memberof Proto.ContentChapterList
         * @instance
         */
        ContentChapterList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentChapterList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentChapterList
         * @static
         * @param {Proto.IContentChapterList=} [properties] Properties to set
         * @returns {Proto.ContentChapterList} ContentChapterList instance
         */
        ContentChapterList.create = function create(properties) {
            return new ContentChapterList(properties);
        };

        /**
         * Encodes the specified ContentChapterList message. Does not implicitly {@link Proto.ContentChapterList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentChapterList
         * @static
         * @param {Proto.IContentChapterList} m ContentChapterList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentChapterList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentChapter.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentChapterList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentChapterList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentChapterList} ContentChapterList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentChapterList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentChapterList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentChapter.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentChapterList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentChapterList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentChapterList} ContentChapterList
         */
        ContentChapterList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentChapterList)
                return d;
            var m = new $root.Proto.ContentChapterList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentChapterList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentChapterList.value: object expected");
                    m.value[i] = $root.Proto.ContentChapter.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentChapterList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentChapterList
         * @static
         * @param {Proto.ContentChapterList} m ContentChapterList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentChapterList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentChapter.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentChapterList to JSON.
         * @function toJSON
         * @memberof Proto.ContentChapterList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentChapterList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentChapterList;
    })();

    Proto.ContentBookmark = (function() {

        /**
         * Properties of a ContentBookmark.
         * @memberof Proto
         * @interface IContentBookmark
         * @property {string|null} [contentUID] ContentBookmark contentUID
         * @property {number|null} [time] ContentBookmark time
         * @property {string|null} [count] ContentBookmark count
         * @property {Proto.IContent|null} [content] ContentBookmark content
         * @property {number|Long|null} [registerDate] ContentBookmark registerDate
         * @property {number|Long|null} [modifyDate] ContentBookmark modifyDate
         * @property {string|null} [playlistUID] ContentBookmark playlistUID
         */

        /**
         * Constructs a new ContentBookmark.
         * @memberof Proto
         * @classdesc Represents a ContentBookmark.
         * @implements IContentBookmark
         * @constructor
         * @param {Proto.IContentBookmark=} [p] Properties to set
         */
        function ContentBookmark(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentBookmark contentUID.
         * @member {string} contentUID
         * @memberof Proto.ContentBookmark
         * @instance
         */
        ContentBookmark.prototype.contentUID = "";

        /**
         * ContentBookmark time.
         * @member {number} time
         * @memberof Proto.ContentBookmark
         * @instance
         */
        ContentBookmark.prototype.time = 0;

        /**
         * ContentBookmark count.
         * @member {string} count
         * @memberof Proto.ContentBookmark
         * @instance
         */
        ContentBookmark.prototype.count = "";

        /**
         * ContentBookmark content.
         * @member {Proto.IContent|null|undefined} content
         * @memberof Proto.ContentBookmark
         * @instance
         */
        ContentBookmark.prototype.content = null;

        /**
         * ContentBookmark registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentBookmark
         * @instance
         */
        ContentBookmark.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentBookmark modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentBookmark
         * @instance
         */
        ContentBookmark.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentBookmark playlistUID.
         * @member {string} playlistUID
         * @memberof Proto.ContentBookmark
         * @instance
         */
        ContentBookmark.prototype.playlistUID = "";

        /**
         * Creates a new ContentBookmark instance using the specified properties.
         * @function create
         * @memberof Proto.ContentBookmark
         * @static
         * @param {Proto.IContentBookmark=} [properties] Properties to set
         * @returns {Proto.ContentBookmark} ContentBookmark instance
         */
        ContentBookmark.create = function create(properties) {
            return new ContentBookmark(properties);
        };

        /**
         * Encodes the specified ContentBookmark message. Does not implicitly {@link Proto.ContentBookmark.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentBookmark
         * @static
         * @param {Proto.IContentBookmark} m ContentBookmark message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentBookmark.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(10).string(m.contentUID);
            if (m.time != null && Object.hasOwnProperty.call(m, "time"))
                w.uint32(17).double(m.time);
            if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                w.uint32(26).string(m.count);
            if (m.content != null && Object.hasOwnProperty.call(m, "content"))
                $root.Proto.Content.encode(m.content, w.uint32(34).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(40).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(48).int64(m.modifyDate);
            if (m.playlistUID != null && Object.hasOwnProperty.call(m, "playlistUID"))
                w.uint32(58).string(m.playlistUID);
            return w;
        };

        /**
         * Decodes a ContentBookmark message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentBookmark
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentBookmark} ContentBookmark
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentBookmark.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentBookmark();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.contentUID = r.string();
                    break;
                case 2:
                    m.time = r.double();
                    break;
                case 3:
                    m.count = r.string();
                    break;
                case 4:
                    m.content = $root.Proto.Content.decode(r, r.uint32());
                    break;
                case 5:
                    m.registerDate = r.int64();
                    break;
                case 6:
                    m.modifyDate = r.int64();
                    break;
                case 7:
                    m.playlistUID = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentBookmark message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentBookmark
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentBookmark} ContentBookmark
         */
        ContentBookmark.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentBookmark)
                return d;
            var m = new $root.Proto.ContentBookmark();
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.time != null) {
                m.time = Number(d.time);
            }
            if (d.count != null) {
                m.count = String(d.count);
            }
            if (d.content != null) {
                if (typeof d.content !== "object")
                    throw TypeError(".Proto.ContentBookmark.content: object expected");
                m.content = $root.Proto.Content.fromObject(d.content);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.playlistUID != null) {
                m.playlistUID = String(d.playlistUID);
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentBookmark message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentBookmark
         * @static
         * @param {Proto.ContentBookmark} m ContentBookmark
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentBookmark.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.contentUID = "";
                d.time = 0;
                d.count = "";
                d.content = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.playlistUID = "";
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.time != null && m.hasOwnProperty("time")) {
                d.time = o.json && !isFinite(m.time) ? String(m.time) : m.time;
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                d.count = m.count;
            }
            if (m.content != null && m.hasOwnProperty("content")) {
                d.content = $root.Proto.Content.toObject(m.content, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.playlistUID != null && m.hasOwnProperty("playlistUID")) {
                d.playlistUID = m.playlistUID;
            }
            return d;
        };

        /**
         * Converts this ContentBookmark to JSON.
         * @function toJSON
         * @memberof Proto.ContentBookmark
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentBookmark.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentBookmark;
    })();

    Proto.ContentBookmarkList = (function() {

        /**
         * Properties of a ContentBookmarkList.
         * @memberof Proto
         * @interface IContentBookmarkList
         * @property {Array.<Proto.IContentBookmark>|null} [value] ContentBookmarkList value
         */

        /**
         * Constructs a new ContentBookmarkList.
         * @memberof Proto
         * @classdesc Represents a ContentBookmarkList.
         * @implements IContentBookmarkList
         * @constructor
         * @param {Proto.IContentBookmarkList=} [p] Properties to set
         */
        function ContentBookmarkList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentBookmarkList value.
         * @member {Array.<Proto.IContentBookmark>} value
         * @memberof Proto.ContentBookmarkList
         * @instance
         */
        ContentBookmarkList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentBookmarkList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentBookmarkList
         * @static
         * @param {Proto.IContentBookmarkList=} [properties] Properties to set
         * @returns {Proto.ContentBookmarkList} ContentBookmarkList instance
         */
        ContentBookmarkList.create = function create(properties) {
            return new ContentBookmarkList(properties);
        };

        /**
         * Encodes the specified ContentBookmarkList message. Does not implicitly {@link Proto.ContentBookmarkList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentBookmarkList
         * @static
         * @param {Proto.IContentBookmarkList} m ContentBookmarkList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentBookmarkList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentBookmark.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentBookmarkList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentBookmarkList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentBookmarkList} ContentBookmarkList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentBookmarkList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentBookmarkList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentBookmark.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentBookmarkList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentBookmarkList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentBookmarkList} ContentBookmarkList
         */
        ContentBookmarkList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentBookmarkList)
                return d;
            var m = new $root.Proto.ContentBookmarkList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentBookmarkList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentBookmarkList.value: object expected");
                    m.value[i] = $root.Proto.ContentBookmark.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentBookmarkList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentBookmarkList
         * @static
         * @param {Proto.ContentBookmarkList} m ContentBookmarkList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentBookmarkList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentBookmark.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentBookmarkList to JSON.
         * @function toJSON
         * @memberof Proto.ContentBookmarkList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentBookmarkList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentBookmarkList;
    })();

    Proto.ContentEventDateChat = (function() {

        /**
         * Properties of a ContentEventDateChat.
         * @memberof Proto
         * @interface IContentEventDateChat
         * @property {string|null} [uid] ContentEventDateChat uid
         * @property {string|null} [dateUID] ContentEventDateChat dateUID
         * @property {string|null} [userUID] ContentEventDateChat userUID
         * @property {string|null} [text] ContentEventDateChat text
         * @property {number|Long|null} [pinDate] ContentEventDateChat pinDate
         * @property {number|Long|null} [registerDate] ContentEventDateChat registerDate
         * @property {number|Long|null} [modifyDate] ContentEventDateChat modifyDate
         */

        /**
         * Constructs a new ContentEventDateChat.
         * @memberof Proto
         * @classdesc Represents a ContentEventDateChat.
         * @implements IContentEventDateChat
         * @constructor
         * @param {Proto.IContentEventDateChat=} [p] Properties to set
         */
        function ContentEventDateChat(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentEventDateChat uid.
         * @member {string} uid
         * @memberof Proto.ContentEventDateChat
         * @instance
         */
        ContentEventDateChat.prototype.uid = "";

        /**
         * ContentEventDateChat dateUID.
         * @member {string} dateUID
         * @memberof Proto.ContentEventDateChat
         * @instance
         */
        ContentEventDateChat.prototype.dateUID = "";

        /**
         * ContentEventDateChat userUID.
         * @member {string} userUID
         * @memberof Proto.ContentEventDateChat
         * @instance
         */
        ContentEventDateChat.prototype.userUID = "";

        /**
         * ContentEventDateChat text.
         * @member {string} text
         * @memberof Proto.ContentEventDateChat
         * @instance
         */
        ContentEventDateChat.prototype.text = "";

        /**
         * ContentEventDateChat pinDate.
         * @member {number|Long} pinDate
         * @memberof Proto.ContentEventDateChat
         * @instance
         */
        ContentEventDateChat.prototype.pinDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentEventDateChat registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentEventDateChat
         * @instance
         */
        ContentEventDateChat.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentEventDateChat modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentEventDateChat
         * @instance
         */
        ContentEventDateChat.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new ContentEventDateChat instance using the specified properties.
         * @function create
         * @memberof Proto.ContentEventDateChat
         * @static
         * @param {Proto.IContentEventDateChat=} [properties] Properties to set
         * @returns {Proto.ContentEventDateChat} ContentEventDateChat instance
         */
        ContentEventDateChat.create = function create(properties) {
            return new ContentEventDateChat(properties);
        };

        /**
         * Encodes the specified ContentEventDateChat message. Does not implicitly {@link Proto.ContentEventDateChat.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentEventDateChat
         * @static
         * @param {Proto.IContentEventDateChat} m ContentEventDateChat message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentEventDateChat.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.dateUID != null && Object.hasOwnProperty.call(m, "dateUID"))
                w.uint32(18).string(m.dateUID);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(26).string(m.userUID);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(34).string(m.text);
            if (m.pinDate != null && Object.hasOwnProperty.call(m, "pinDate"))
                w.uint32(40).int64(m.pinDate);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a ContentEventDateChat message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentEventDateChat
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentEventDateChat} ContentEventDateChat
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentEventDateChat.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentEventDateChat();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.dateUID = r.string();
                    break;
                case 3:
                    m.userUID = r.string();
                    break;
                case 4:
                    m.text = r.string();
                    break;
                case 5:
                    m.pinDate = r.int64();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentEventDateChat message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentEventDateChat
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentEventDateChat} ContentEventDateChat
         */
        ContentEventDateChat.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentEventDateChat)
                return d;
            var m = new $root.Proto.ContentEventDateChat();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.dateUID != null) {
                m.dateUID = String(d.dateUID);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.pinDate != null) {
                if ($util.Long)
                    (m.pinDate = $util.Long.fromValue(d.pinDate)).unsigned = false;
                else if (typeof d.pinDate === "string")
                    m.pinDate = parseInt(d.pinDate, 10);
                else if (typeof d.pinDate === "number")
                    m.pinDate = d.pinDate;
                else if (typeof d.pinDate === "object")
                    m.pinDate = new $util.LongBits(d.pinDate.low >>> 0, d.pinDate.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentEventDateChat message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentEventDateChat
         * @static
         * @param {Proto.ContentEventDateChat} m ContentEventDateChat
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentEventDateChat.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.dateUID = "";
                d.userUID = "";
                d.text = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.pinDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.pinDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.dateUID != null && m.hasOwnProperty("dateUID")) {
                d.dateUID = m.dateUID;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.pinDate != null && m.hasOwnProperty("pinDate")) {
                if (typeof m.pinDate === "number")
                    d.pinDate = o.longs === String ? String(m.pinDate) : m.pinDate;
                else
                    d.pinDate = o.longs === String ? $util.Long.prototype.toString.call(m.pinDate) : o.longs === Number ? new $util.LongBits(m.pinDate.low >>> 0, m.pinDate.high >>> 0).toNumber() : m.pinDate;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this ContentEventDateChat to JSON.
         * @function toJSON
         * @memberof Proto.ContentEventDateChat
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentEventDateChat.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentEventDateChat;
    })();

    Proto.ContentEventDateChatList = (function() {

        /**
         * Properties of a ContentEventDateChatList.
         * @memberof Proto
         * @interface IContentEventDateChatList
         * @property {Array.<Proto.IContentEventDateChat>|null} [value] ContentEventDateChatList value
         */

        /**
         * Constructs a new ContentEventDateChatList.
         * @memberof Proto
         * @classdesc Represents a ContentEventDateChatList.
         * @implements IContentEventDateChatList
         * @constructor
         * @param {Proto.IContentEventDateChatList=} [p] Properties to set
         */
        function ContentEventDateChatList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentEventDateChatList value.
         * @member {Array.<Proto.IContentEventDateChat>} value
         * @memberof Proto.ContentEventDateChatList
         * @instance
         */
        ContentEventDateChatList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentEventDateChatList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentEventDateChatList
         * @static
         * @param {Proto.IContentEventDateChatList=} [properties] Properties to set
         * @returns {Proto.ContentEventDateChatList} ContentEventDateChatList instance
         */
        ContentEventDateChatList.create = function create(properties) {
            return new ContentEventDateChatList(properties);
        };

        /**
         * Encodes the specified ContentEventDateChatList message. Does not implicitly {@link Proto.ContentEventDateChatList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentEventDateChatList
         * @static
         * @param {Proto.IContentEventDateChatList} m ContentEventDateChatList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentEventDateChatList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentEventDateChat.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentEventDateChatList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentEventDateChatList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentEventDateChatList} ContentEventDateChatList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentEventDateChatList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentEventDateChatList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentEventDateChat.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentEventDateChatList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentEventDateChatList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentEventDateChatList} ContentEventDateChatList
         */
        ContentEventDateChatList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentEventDateChatList)
                return d;
            var m = new $root.Proto.ContentEventDateChatList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentEventDateChatList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentEventDateChatList.value: object expected");
                    m.value[i] = $root.Proto.ContentEventDateChat.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentEventDateChatList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentEventDateChatList
         * @static
         * @param {Proto.ContentEventDateChatList} m ContentEventDateChatList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentEventDateChatList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentEventDateChat.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentEventDateChatList to JSON.
         * @function toJSON
         * @memberof Proto.ContentEventDateChatList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentEventDateChatList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentEventDateChatList;
    })();

    Proto.ContentEventDate = (function() {

        /**
         * Properties of a ContentEventDate.
         * @memberof Proto
         * @interface IContentEventDate
         * @property {string|null} [uid] ContentEventDate uid
         * @property {string|null} [eventUID] ContentEventDate eventUID
         * @property {number|null} [type] ContentEventDate type
         * @property {string|null} [startDate] ContentEventDate startDate
         * @property {string|null} [startTime] ContentEventDate startTime
         * @property {string|null} [endDate] ContentEventDate endDate
         * @property {string|null} [endTime] ContentEventDate endTime
         * @property {string|null} [repeatStart] ContentEventDate repeatStart
         * @property {string|null} [repeatEnd] ContentEventDate repeatEnd
         * @property {number|null} [repeatInterval] ContentEventDate repeatInterval
         * @property {number|null} [repeatYear] ContentEventDate repeatYear
         * @property {number|null} [repeatMonth] ContentEventDate repeatMonth
         * @property {number|null} [repeatDay] ContentEventDate repeatDay
         * @property {number|null} [repeatWeek] ContentEventDate repeatWeek
         * @property {string|null} [repeatWeekday] ContentEventDate repeatWeekday
         * @property {number|Long|null} [registerDate] ContentEventDate registerDate
         * @property {number|Long|null} [modifyDate] ContentEventDate modifyDate
         * @property {string|null} [zoomMeetingId] ContentEventDate zoomMeetingId
         * @property {number|null} [status] ContentEventDate status
         * @property {string|null} [text] ContentEventDate text
         * @property {string|null} [zoomStatus] ContentEventDate zoomStatus
         * @property {number|null} [vimeoType] ContentEventDate vimeoType
         * @property {string|null} [vimeoUrl] ContentEventDate vimeoUrl
         * @property {string|null} [vimeoId] ContentEventDate vimeoId
         * @property {string|null} [oauthUID] ContentEventDate oauthUID
         * @property {number|null} [youtubeType] ContentEventDate youtubeType
         * @property {string|null} [youtubeUrl] ContentEventDate youtubeUrl
         * @property {string|null} [youtubeId] ContentEventDate youtubeId
         * @property {string|null} [byteStreamId] ContentEventDate byteStreamId
         * @property {number|Long|null} [byteStreamStart] ContentEventDate byteStreamStart
         * @property {number|Long|null} [byteStreamEnd] ContentEventDate byteStreamEnd
         */

        /**
         * Constructs a new ContentEventDate.
         * @memberof Proto
         * @classdesc Represents a ContentEventDate.
         * @implements IContentEventDate
         * @constructor
         * @param {Proto.IContentEventDate=} [p] Properties to set
         */
        function ContentEventDate(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentEventDate uid.
         * @member {string} uid
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.uid = "";

        /**
         * ContentEventDate eventUID.
         * @member {string} eventUID
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.eventUID = "";

        /**
         * ContentEventDate type.
         * @member {number} type
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.type = 0;

        /**
         * ContentEventDate startDate.
         * @member {string} startDate
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.startDate = "";

        /**
         * ContentEventDate startTime.
         * @member {string} startTime
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.startTime = "";

        /**
         * ContentEventDate endDate.
         * @member {string} endDate
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.endDate = "";

        /**
         * ContentEventDate endTime.
         * @member {string} endTime
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.endTime = "";

        /**
         * ContentEventDate repeatStart.
         * @member {string} repeatStart
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.repeatStart = "";

        /**
         * ContentEventDate repeatEnd.
         * @member {string} repeatEnd
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.repeatEnd = "";

        /**
         * ContentEventDate repeatInterval.
         * @member {number} repeatInterval
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.repeatInterval = 0;

        /**
         * ContentEventDate repeatYear.
         * @member {number} repeatYear
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.repeatYear = 0;

        /**
         * ContentEventDate repeatMonth.
         * @member {number} repeatMonth
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.repeatMonth = 0;

        /**
         * ContentEventDate repeatDay.
         * @member {number} repeatDay
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.repeatDay = 0;

        /**
         * ContentEventDate repeatWeek.
         * @member {number} repeatWeek
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.repeatWeek = 0;

        /**
         * ContentEventDate repeatWeekday.
         * @member {string} repeatWeekday
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.repeatWeekday = "";

        /**
         * ContentEventDate registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentEventDate modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentEventDate zoomMeetingId.
         * @member {string} zoomMeetingId
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.zoomMeetingId = "";

        /**
         * ContentEventDate status.
         * @member {number} status
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.status = 0;

        /**
         * ContentEventDate text.
         * @member {string} text
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.text = "";

        /**
         * ContentEventDate zoomStatus.
         * @member {string} zoomStatus
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.zoomStatus = "";

        /**
         * ContentEventDate vimeoType.
         * @member {number} vimeoType
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.vimeoType = 0;

        /**
         * ContentEventDate vimeoUrl.
         * @member {string} vimeoUrl
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.vimeoUrl = "";

        /**
         * ContentEventDate vimeoId.
         * @member {string} vimeoId
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.vimeoId = "";

        /**
         * ContentEventDate oauthUID.
         * @member {string} oauthUID
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.oauthUID = "";

        /**
         * ContentEventDate youtubeType.
         * @member {number} youtubeType
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.youtubeType = 0;

        /**
         * ContentEventDate youtubeUrl.
         * @member {string} youtubeUrl
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.youtubeUrl = "";

        /**
         * ContentEventDate youtubeId.
         * @member {string} youtubeId
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.youtubeId = "";

        /**
         * ContentEventDate byteStreamId.
         * @member {string} byteStreamId
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.byteStreamId = "";

        /**
         * ContentEventDate byteStreamStart.
         * @member {number|Long} byteStreamStart
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.byteStreamStart = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentEventDate byteStreamEnd.
         * @member {number|Long} byteStreamEnd
         * @memberof Proto.ContentEventDate
         * @instance
         */
        ContentEventDate.prototype.byteStreamEnd = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new ContentEventDate instance using the specified properties.
         * @function create
         * @memberof Proto.ContentEventDate
         * @static
         * @param {Proto.IContentEventDate=} [properties] Properties to set
         * @returns {Proto.ContentEventDate} ContentEventDate instance
         */
        ContentEventDate.create = function create(properties) {
            return new ContentEventDate(properties);
        };

        /**
         * Encodes the specified ContentEventDate message. Does not implicitly {@link Proto.ContentEventDate.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentEventDate
         * @static
         * @param {Proto.IContentEventDate} m ContentEventDate message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentEventDate.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.eventUID != null && Object.hasOwnProperty.call(m, "eventUID"))
                w.uint32(18).string(m.eventUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(24).int32(m.type);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(34).string(m.startDate);
            if (m.startTime != null && Object.hasOwnProperty.call(m, "startTime"))
                w.uint32(42).string(m.startTime);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(50).string(m.endDate);
            if (m.endTime != null && Object.hasOwnProperty.call(m, "endTime"))
                w.uint32(58).string(m.endTime);
            if (m.repeatStart != null && Object.hasOwnProperty.call(m, "repeatStart"))
                w.uint32(66).string(m.repeatStart);
            if (m.repeatEnd != null && Object.hasOwnProperty.call(m, "repeatEnd"))
                w.uint32(74).string(m.repeatEnd);
            if (m.repeatInterval != null && Object.hasOwnProperty.call(m, "repeatInterval"))
                w.uint32(80).int32(m.repeatInterval);
            if (m.repeatYear != null && Object.hasOwnProperty.call(m, "repeatYear"))
                w.uint32(88).int32(m.repeatYear);
            if (m.repeatMonth != null && Object.hasOwnProperty.call(m, "repeatMonth"))
                w.uint32(96).int32(m.repeatMonth);
            if (m.repeatDay != null && Object.hasOwnProperty.call(m, "repeatDay"))
                w.uint32(104).int32(m.repeatDay);
            if (m.repeatWeek != null && Object.hasOwnProperty.call(m, "repeatWeek"))
                w.uint32(112).int32(m.repeatWeek);
            if (m.repeatWeekday != null && Object.hasOwnProperty.call(m, "repeatWeekday"))
                w.uint32(122).string(m.repeatWeekday);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(128).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(136).int64(m.modifyDate);
            if (m.zoomMeetingId != null && Object.hasOwnProperty.call(m, "zoomMeetingId"))
                w.uint32(146).string(m.zoomMeetingId);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(152).int32(m.status);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(162).string(m.text);
            if (m.zoomStatus != null && Object.hasOwnProperty.call(m, "zoomStatus"))
                w.uint32(170).string(m.zoomStatus);
            if (m.vimeoType != null && Object.hasOwnProperty.call(m, "vimeoType"))
                w.uint32(176).int32(m.vimeoType);
            if (m.vimeoUrl != null && Object.hasOwnProperty.call(m, "vimeoUrl"))
                w.uint32(186).string(m.vimeoUrl);
            if (m.vimeoId != null && Object.hasOwnProperty.call(m, "vimeoId"))
                w.uint32(194).string(m.vimeoId);
            if (m.oauthUID != null && Object.hasOwnProperty.call(m, "oauthUID"))
                w.uint32(202).string(m.oauthUID);
            if (m.youtubeType != null && Object.hasOwnProperty.call(m, "youtubeType"))
                w.uint32(208).int32(m.youtubeType);
            if (m.youtubeUrl != null && Object.hasOwnProperty.call(m, "youtubeUrl"))
                w.uint32(218).string(m.youtubeUrl);
            if (m.youtubeId != null && Object.hasOwnProperty.call(m, "youtubeId"))
                w.uint32(226).string(m.youtubeId);
            if (m.byteStreamId != null && Object.hasOwnProperty.call(m, "byteStreamId"))
                w.uint32(234).string(m.byteStreamId);
            if (m.byteStreamStart != null && Object.hasOwnProperty.call(m, "byteStreamStart"))
                w.uint32(240).int64(m.byteStreamStart);
            if (m.byteStreamEnd != null && Object.hasOwnProperty.call(m, "byteStreamEnd"))
                w.uint32(248).int64(m.byteStreamEnd);
            return w;
        };

        /**
         * Decodes a ContentEventDate message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentEventDate
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentEventDate} ContentEventDate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentEventDate.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentEventDate();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.eventUID = r.string();
                    break;
                case 3:
                    m.type = r.int32();
                    break;
                case 4:
                    m.startDate = r.string();
                    break;
                case 5:
                    m.startTime = r.string();
                    break;
                case 6:
                    m.endDate = r.string();
                    break;
                case 7:
                    m.endTime = r.string();
                    break;
                case 8:
                    m.repeatStart = r.string();
                    break;
                case 9:
                    m.repeatEnd = r.string();
                    break;
                case 10:
                    m.repeatInterval = r.int32();
                    break;
                case 11:
                    m.repeatYear = r.int32();
                    break;
                case 12:
                    m.repeatMonth = r.int32();
                    break;
                case 13:
                    m.repeatDay = r.int32();
                    break;
                case 14:
                    m.repeatWeek = r.int32();
                    break;
                case 15:
                    m.repeatWeekday = r.string();
                    break;
                case 16:
                    m.registerDate = r.int64();
                    break;
                case 17:
                    m.modifyDate = r.int64();
                    break;
                case 18:
                    m.zoomMeetingId = r.string();
                    break;
                case 19:
                    m.status = r.int32();
                    break;
                case 20:
                    m.text = r.string();
                    break;
                case 21:
                    m.zoomStatus = r.string();
                    break;
                case 22:
                    m.vimeoType = r.int32();
                    break;
                case 23:
                    m.vimeoUrl = r.string();
                    break;
                case 24:
                    m.vimeoId = r.string();
                    break;
                case 25:
                    m.oauthUID = r.string();
                    break;
                case 26:
                    m.youtubeType = r.int32();
                    break;
                case 27:
                    m.youtubeUrl = r.string();
                    break;
                case 28:
                    m.youtubeId = r.string();
                    break;
                case 29:
                    m.byteStreamId = r.string();
                    break;
                case 30:
                    m.byteStreamStart = r.int64();
                    break;
                case 31:
                    m.byteStreamEnd = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentEventDate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentEventDate
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentEventDate} ContentEventDate
         */
        ContentEventDate.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentEventDate)
                return d;
            var m = new $root.Proto.ContentEventDate();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.eventUID != null) {
                m.eventUID = String(d.eventUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.startDate != null) {
                m.startDate = String(d.startDate);
            }
            if (d.startTime != null) {
                m.startTime = String(d.startTime);
            }
            if (d.endDate != null) {
                m.endDate = String(d.endDate);
            }
            if (d.endTime != null) {
                m.endTime = String(d.endTime);
            }
            if (d.repeatStart != null) {
                m.repeatStart = String(d.repeatStart);
            }
            if (d.repeatEnd != null) {
                m.repeatEnd = String(d.repeatEnd);
            }
            if (d.repeatInterval != null) {
                m.repeatInterval = d.repeatInterval | 0;
            }
            if (d.repeatYear != null) {
                m.repeatYear = d.repeatYear | 0;
            }
            if (d.repeatMonth != null) {
                m.repeatMonth = d.repeatMonth | 0;
            }
            if (d.repeatDay != null) {
                m.repeatDay = d.repeatDay | 0;
            }
            if (d.repeatWeek != null) {
                m.repeatWeek = d.repeatWeek | 0;
            }
            if (d.repeatWeekday != null) {
                m.repeatWeekday = String(d.repeatWeekday);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.zoomMeetingId != null) {
                m.zoomMeetingId = String(d.zoomMeetingId);
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.zoomStatus != null) {
                m.zoomStatus = String(d.zoomStatus);
            }
            if (d.vimeoType != null) {
                m.vimeoType = d.vimeoType | 0;
            }
            if (d.vimeoUrl != null) {
                m.vimeoUrl = String(d.vimeoUrl);
            }
            if (d.vimeoId != null) {
                m.vimeoId = String(d.vimeoId);
            }
            if (d.oauthUID != null) {
                m.oauthUID = String(d.oauthUID);
            }
            if (d.youtubeType != null) {
                m.youtubeType = d.youtubeType | 0;
            }
            if (d.youtubeUrl != null) {
                m.youtubeUrl = String(d.youtubeUrl);
            }
            if (d.youtubeId != null) {
                m.youtubeId = String(d.youtubeId);
            }
            if (d.byteStreamId != null) {
                m.byteStreamId = String(d.byteStreamId);
            }
            if (d.byteStreamStart != null) {
                if ($util.Long)
                    (m.byteStreamStart = $util.Long.fromValue(d.byteStreamStart)).unsigned = false;
                else if (typeof d.byteStreamStart === "string")
                    m.byteStreamStart = parseInt(d.byteStreamStart, 10);
                else if (typeof d.byteStreamStart === "number")
                    m.byteStreamStart = d.byteStreamStart;
                else if (typeof d.byteStreamStart === "object")
                    m.byteStreamStart = new $util.LongBits(d.byteStreamStart.low >>> 0, d.byteStreamStart.high >>> 0).toNumber();
            }
            if (d.byteStreamEnd != null) {
                if ($util.Long)
                    (m.byteStreamEnd = $util.Long.fromValue(d.byteStreamEnd)).unsigned = false;
                else if (typeof d.byteStreamEnd === "string")
                    m.byteStreamEnd = parseInt(d.byteStreamEnd, 10);
                else if (typeof d.byteStreamEnd === "number")
                    m.byteStreamEnd = d.byteStreamEnd;
                else if (typeof d.byteStreamEnd === "object")
                    m.byteStreamEnd = new $util.LongBits(d.byteStreamEnd.low >>> 0, d.byteStreamEnd.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentEventDate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentEventDate
         * @static
         * @param {Proto.ContentEventDate} m ContentEventDate
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentEventDate.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.eventUID = "";
                d.type = 0;
                d.startDate = "";
                d.startTime = "";
                d.endDate = "";
                d.endTime = "";
                d.repeatStart = "";
                d.repeatEnd = "";
                d.repeatInterval = 0;
                d.repeatYear = 0;
                d.repeatMonth = 0;
                d.repeatDay = 0;
                d.repeatWeek = 0;
                d.repeatWeekday = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.zoomMeetingId = "";
                d.status = 0;
                d.text = "";
                d.zoomStatus = "";
                d.vimeoType = 0;
                d.vimeoUrl = "";
                d.vimeoId = "";
                d.oauthUID = "";
                d.youtubeType = 0;
                d.youtubeUrl = "";
                d.youtubeId = "";
                d.byteStreamId = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.byteStreamStart = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.byteStreamStart = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.byteStreamEnd = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.byteStreamEnd = o.longs === String ? "0" : 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.eventUID != null && m.hasOwnProperty("eventUID")) {
                d.eventUID = m.eventUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                d.startDate = m.startDate;
            }
            if (m.startTime != null && m.hasOwnProperty("startTime")) {
                d.startTime = m.startTime;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                d.endDate = m.endDate;
            }
            if (m.endTime != null && m.hasOwnProperty("endTime")) {
                d.endTime = m.endTime;
            }
            if (m.repeatStart != null && m.hasOwnProperty("repeatStart")) {
                d.repeatStart = m.repeatStart;
            }
            if (m.repeatEnd != null && m.hasOwnProperty("repeatEnd")) {
                d.repeatEnd = m.repeatEnd;
            }
            if (m.repeatInterval != null && m.hasOwnProperty("repeatInterval")) {
                d.repeatInterval = m.repeatInterval;
            }
            if (m.repeatYear != null && m.hasOwnProperty("repeatYear")) {
                d.repeatYear = m.repeatYear;
            }
            if (m.repeatMonth != null && m.hasOwnProperty("repeatMonth")) {
                d.repeatMonth = m.repeatMonth;
            }
            if (m.repeatDay != null && m.hasOwnProperty("repeatDay")) {
                d.repeatDay = m.repeatDay;
            }
            if (m.repeatWeek != null && m.hasOwnProperty("repeatWeek")) {
                d.repeatWeek = m.repeatWeek;
            }
            if (m.repeatWeekday != null && m.hasOwnProperty("repeatWeekday")) {
                d.repeatWeekday = m.repeatWeekday;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.zoomMeetingId != null && m.hasOwnProperty("zoomMeetingId")) {
                d.zoomMeetingId = m.zoomMeetingId;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.zoomStatus != null && m.hasOwnProperty("zoomStatus")) {
                d.zoomStatus = m.zoomStatus;
            }
            if (m.vimeoType != null && m.hasOwnProperty("vimeoType")) {
                d.vimeoType = m.vimeoType;
            }
            if (m.vimeoUrl != null && m.hasOwnProperty("vimeoUrl")) {
                d.vimeoUrl = m.vimeoUrl;
            }
            if (m.vimeoId != null && m.hasOwnProperty("vimeoId")) {
                d.vimeoId = m.vimeoId;
            }
            if (m.oauthUID != null && m.hasOwnProperty("oauthUID")) {
                d.oauthUID = m.oauthUID;
            }
            if (m.youtubeType != null && m.hasOwnProperty("youtubeType")) {
                d.youtubeType = m.youtubeType;
            }
            if (m.youtubeUrl != null && m.hasOwnProperty("youtubeUrl")) {
                d.youtubeUrl = m.youtubeUrl;
            }
            if (m.youtubeId != null && m.hasOwnProperty("youtubeId")) {
                d.youtubeId = m.youtubeId;
            }
            if (m.byteStreamId != null && m.hasOwnProperty("byteStreamId")) {
                d.byteStreamId = m.byteStreamId;
            }
            if (m.byteStreamStart != null && m.hasOwnProperty("byteStreamStart")) {
                if (typeof m.byteStreamStart === "number")
                    d.byteStreamStart = o.longs === String ? String(m.byteStreamStart) : m.byteStreamStart;
                else
                    d.byteStreamStart = o.longs === String ? $util.Long.prototype.toString.call(m.byteStreamStart) : o.longs === Number ? new $util.LongBits(m.byteStreamStart.low >>> 0, m.byteStreamStart.high >>> 0).toNumber() : m.byteStreamStart;
            }
            if (m.byteStreamEnd != null && m.hasOwnProperty("byteStreamEnd")) {
                if (typeof m.byteStreamEnd === "number")
                    d.byteStreamEnd = o.longs === String ? String(m.byteStreamEnd) : m.byteStreamEnd;
                else
                    d.byteStreamEnd = o.longs === String ? $util.Long.prototype.toString.call(m.byteStreamEnd) : o.longs === Number ? new $util.LongBits(m.byteStreamEnd.low >>> 0, m.byteStreamEnd.high >>> 0).toNumber() : m.byteStreamEnd;
            }
            return d;
        };

        /**
         * Converts this ContentEventDate to JSON.
         * @function toJSON
         * @memberof Proto.ContentEventDate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentEventDate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentEventDate;
    })();

    Proto.ContentEventProduct = (function() {

        /**
         * Properties of a ContentEventProduct.
         * @memberof Proto
         * @interface IContentEventProduct
         * @property {string|null} [uid] ContentEventProduct uid
         * @property {string|null} [eventUID] ContentEventProduct eventUID
         * @property {string|null} [name] ContentEventProduct name
         * @property {string|null} [link] ContentEventProduct link
         * @property {number|null} [price] ContentEventProduct price
         * @property {string|null} [currency] ContentEventProduct currency
         * @property {Proto.IUploadFile|null} [image] ContentEventProduct image
         * @property {number|Long|null} [registerDate] ContentEventProduct registerDate
         * @property {number|Long|null} [modifyDate] ContentEventProduct modifyDate
         * @property {number|null} [status] ContentEventProduct status
         */

        /**
         * Constructs a new ContentEventProduct.
         * @memberof Proto
         * @classdesc Represents a ContentEventProduct.
         * @implements IContentEventProduct
         * @constructor
         * @param {Proto.IContentEventProduct=} [p] Properties to set
         */
        function ContentEventProduct(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentEventProduct uid.
         * @member {string} uid
         * @memberof Proto.ContentEventProduct
         * @instance
         */
        ContentEventProduct.prototype.uid = "";

        /**
         * ContentEventProduct eventUID.
         * @member {string} eventUID
         * @memberof Proto.ContentEventProduct
         * @instance
         */
        ContentEventProduct.prototype.eventUID = "";

        /**
         * ContentEventProduct name.
         * @member {string} name
         * @memberof Proto.ContentEventProduct
         * @instance
         */
        ContentEventProduct.prototype.name = "";

        /**
         * ContentEventProduct link.
         * @member {string} link
         * @memberof Proto.ContentEventProduct
         * @instance
         */
        ContentEventProduct.prototype.link = "";

        /**
         * ContentEventProduct price.
         * @member {number} price
         * @memberof Proto.ContentEventProduct
         * @instance
         */
        ContentEventProduct.prototype.price = 0;

        /**
         * ContentEventProduct currency.
         * @member {string} currency
         * @memberof Proto.ContentEventProduct
         * @instance
         */
        ContentEventProduct.prototype.currency = "";

        /**
         * ContentEventProduct image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.ContentEventProduct
         * @instance
         */
        ContentEventProduct.prototype.image = null;

        /**
         * ContentEventProduct registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentEventProduct
         * @instance
         */
        ContentEventProduct.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentEventProduct modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentEventProduct
         * @instance
         */
        ContentEventProduct.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentEventProduct status.
         * @member {number} status
         * @memberof Proto.ContentEventProduct
         * @instance
         */
        ContentEventProduct.prototype.status = 0;

        /**
         * Creates a new ContentEventProduct instance using the specified properties.
         * @function create
         * @memberof Proto.ContentEventProduct
         * @static
         * @param {Proto.IContentEventProduct=} [properties] Properties to set
         * @returns {Proto.ContentEventProduct} ContentEventProduct instance
         */
        ContentEventProduct.create = function create(properties) {
            return new ContentEventProduct(properties);
        };

        /**
         * Encodes the specified ContentEventProduct message. Does not implicitly {@link Proto.ContentEventProduct.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentEventProduct
         * @static
         * @param {Proto.IContentEventProduct} m ContentEventProduct message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentEventProduct.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.eventUID != null && Object.hasOwnProperty.call(m, "eventUID"))
                w.uint32(18).string(m.eventUID);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.link != null && Object.hasOwnProperty.call(m, "link"))
                w.uint32(34).string(m.link);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(41).double(m.price);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(50).string(m.currency);
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(58).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(80).int32(m.status);
            return w;
        };

        /**
         * Decodes a ContentEventProduct message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentEventProduct
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentEventProduct} ContentEventProduct
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentEventProduct.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentEventProduct();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.eventUID = r.string();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.link = r.string();
                    break;
                case 5:
                    m.price = r.double();
                    break;
                case 6:
                    m.currency = r.string();
                    break;
                case 7:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                case 10:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentEventProduct message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentEventProduct
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentEventProduct} ContentEventProduct
         */
        ContentEventProduct.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentEventProduct)
                return d;
            var m = new $root.Proto.ContentEventProduct();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.eventUID != null) {
                m.eventUID = String(d.eventUID);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.link != null) {
                m.link = String(d.link);
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.ContentEventProduct.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentEventProduct message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentEventProduct
         * @static
         * @param {Proto.ContentEventProduct} m ContentEventProduct
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentEventProduct.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.eventUID = "";
                d.name = "";
                d.link = "";
                d.price = 0;
                d.currency = "";
                d.image = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.eventUID != null && m.hasOwnProperty("eventUID")) {
                d.eventUID = m.eventUID;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.link != null && m.hasOwnProperty("link")) {
                d.link = m.link;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this ContentEventProduct to JSON.
         * @function toJSON
         * @memberof Proto.ContentEventProduct
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentEventProduct.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentEventProduct;
    })();

    Proto.ContentEventProductList = (function() {

        /**
         * Properties of a ContentEventProductList.
         * @memberof Proto
         * @interface IContentEventProductList
         * @property {Array.<Proto.IContentEventProduct>|null} [value] ContentEventProductList value
         */

        /**
         * Constructs a new ContentEventProductList.
         * @memberof Proto
         * @classdesc Represents a ContentEventProductList.
         * @implements IContentEventProductList
         * @constructor
         * @param {Proto.IContentEventProductList=} [p] Properties to set
         */
        function ContentEventProductList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentEventProductList value.
         * @member {Array.<Proto.IContentEventProduct>} value
         * @memberof Proto.ContentEventProductList
         * @instance
         */
        ContentEventProductList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentEventProductList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentEventProductList
         * @static
         * @param {Proto.IContentEventProductList=} [properties] Properties to set
         * @returns {Proto.ContentEventProductList} ContentEventProductList instance
         */
        ContentEventProductList.create = function create(properties) {
            return new ContentEventProductList(properties);
        };

        /**
         * Encodes the specified ContentEventProductList message. Does not implicitly {@link Proto.ContentEventProductList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentEventProductList
         * @static
         * @param {Proto.IContentEventProductList} m ContentEventProductList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentEventProductList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentEventProduct.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentEventProductList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentEventProductList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentEventProductList} ContentEventProductList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentEventProductList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentEventProductList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentEventProduct.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentEventProductList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentEventProductList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentEventProductList} ContentEventProductList
         */
        ContentEventProductList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentEventProductList)
                return d;
            var m = new $root.Proto.ContentEventProductList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentEventProductList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentEventProductList.value: object expected");
                    m.value[i] = $root.Proto.ContentEventProduct.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentEventProductList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentEventProductList
         * @static
         * @param {Proto.ContentEventProductList} m ContentEventProductList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentEventProductList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentEventProduct.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentEventProductList to JSON.
         * @function toJSON
         * @memberof Proto.ContentEventProductList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentEventProductList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentEventProductList;
    })();

    Proto.ContentEvent = (function() {

        /**
         * Properties of a ContentEvent.
         * @memberof Proto
         * @interface IContentEvent
         * @property {string|null} [uid] ContentEvent uid
         * @property {string|null} [contentUID] ContentEvent contentUID
         * @property {string|null} [title] ContentEvent title
         * @property {number|null} [userLimit] ContentEvent userLimit
         * @property {number|null} [duration] ContentEvent duration
         * @property {string|null} [locationName] ContentEvent locationName
         * @property {number|null} [locationLat] ContentEvent locationLat
         * @property {number|null} [locationLng] ContentEvent locationLng
         * @property {string|null} [policy] ContentEvent policy
         * @property {string|null} [additionalOrder] ContentEvent additionalOrder
         * @property {number|Long|null} [registerDate] ContentEvent registerDate
         * @property {number|Long|null} [modifyDate] ContentEvent modifyDate
         * @property {Array.<Proto.IContentEventDate>|null} [date] ContentEvent date
         * @property {string|null} [description] ContentEvent description
         * @property {string|null} [address] ContentEvent address
         * @property {boolean|null} [waitingRoom] ContentEvent waitingRoom
         * @property {boolean|null} [autoApprove] ContentEvent autoApprove
         * @property {Array.<Proto.IContentEventDate>|null} [currentDate] ContentEvent currentDate
         * @property {string|null} [paymentDescription] ContentEvent paymentDescription
         * @property {string|null} [alert] ContentEvent alert
         * @property {string|null} [shortAddress] ContentEvent shortAddress
         * @property {boolean|null} [cameraOn] ContentEvent cameraOn
         * @property {boolean|null} [microOn] ContentEvent microOn
         * @property {number|null} [chatPolicy] ContentEvent chatPolicy
         * @property {number|null} [commercePolicy] ContentEvent commercePolicy
         * @property {string|null} [productOrder] ContentEvent productOrder
         * @property {Array.<Proto.IContentEventProduct>|null} [products] ContentEvent products
         * @property {number|null} [livePolicy] ContentEvent livePolicy
         * @property {number|Long|null} [minUserLimit] ContentEvent minUserLimit
         * @property {number|Long|null} [maxUserLimit] ContentEvent maxUserLimit
         * @property {string|null} [eventDescription1] ContentEvent eventDescription1
         * @property {string|null} [eventDescription2] ContentEvent eventDescription2
         * @property {number|null} [paymentDescriptionVersion] ContentEvent paymentDescriptionVersion
         * @property {boolean|null} [userCountOn] ContentEvent userCountOn
         * @property {boolean|null} [userListOn] ContentEvent userListOn
         * @property {boolean|null} [trackMoreDescription] ContentEvent trackMoreDescription
         * @property {string|null} [trackMoreTitle] ContentEvent trackMoreTitle
         * @property {string|null} [trackMoreButton] ContentEvent trackMoreButton
         * @property {number|null} [descriptionRemainCount] ContentEvent descriptionRemainCount
         * @property {Proto.IUploadFile|null} [wallpaper] ContentEvent wallpaper
         */

        /**
         * Constructs a new ContentEvent.
         * @memberof Proto
         * @classdesc Represents a ContentEvent.
         * @implements IContentEvent
         * @constructor
         * @param {Proto.IContentEvent=} [p] Properties to set
         */
        function ContentEvent(p) {
            this.date = [];
            this.currentDate = [];
            this.products = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentEvent uid.
         * @member {string} uid
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.uid = "";

        /**
         * ContentEvent contentUID.
         * @member {string} contentUID
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.contentUID = "";

        /**
         * ContentEvent title.
         * @member {string} title
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.title = "";

        /**
         * ContentEvent userLimit.
         * @member {number} userLimit
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.userLimit = 0;

        /**
         * ContentEvent duration.
         * @member {number} duration
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.duration = 0;

        /**
         * ContentEvent locationName.
         * @member {string} locationName
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.locationName = "";

        /**
         * ContentEvent locationLat.
         * @member {number} locationLat
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.locationLat = 0;

        /**
         * ContentEvent locationLng.
         * @member {number} locationLng
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.locationLng = 0;

        /**
         * ContentEvent policy.
         * @member {string} policy
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.policy = "";

        /**
         * ContentEvent additionalOrder.
         * @member {string} additionalOrder
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.additionalOrder = "";

        /**
         * ContentEvent registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentEvent modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentEvent date.
         * @member {Array.<Proto.IContentEventDate>} date
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.date = $util.emptyArray;

        /**
         * ContentEvent description.
         * @member {string} description
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.description = "";

        /**
         * ContentEvent address.
         * @member {string} address
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.address = "";

        /**
         * ContentEvent waitingRoom.
         * @member {boolean} waitingRoom
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.waitingRoom = false;

        /**
         * ContentEvent autoApprove.
         * @member {boolean} autoApprove
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.autoApprove = false;

        /**
         * ContentEvent currentDate.
         * @member {Array.<Proto.IContentEventDate>} currentDate
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.currentDate = $util.emptyArray;

        /**
         * ContentEvent paymentDescription.
         * @member {string} paymentDescription
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.paymentDescription = "";

        /**
         * ContentEvent alert.
         * @member {string} alert
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.alert = "";

        /**
         * ContentEvent shortAddress.
         * @member {string} shortAddress
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.shortAddress = "";

        /**
         * ContentEvent cameraOn.
         * @member {boolean} cameraOn
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.cameraOn = false;

        /**
         * ContentEvent microOn.
         * @member {boolean} microOn
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.microOn = false;

        /**
         * ContentEvent chatPolicy.
         * @member {number} chatPolicy
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.chatPolicy = 0;

        /**
         * ContentEvent commercePolicy.
         * @member {number} commercePolicy
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.commercePolicy = 0;

        /**
         * ContentEvent productOrder.
         * @member {string} productOrder
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.productOrder = "";

        /**
         * ContentEvent products.
         * @member {Array.<Proto.IContentEventProduct>} products
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.products = $util.emptyArray;

        /**
         * ContentEvent livePolicy.
         * @member {number} livePolicy
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.livePolicy = 0;

        /**
         * ContentEvent minUserLimit.
         * @member {number|Long} minUserLimit
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.minUserLimit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentEvent maxUserLimit.
         * @member {number|Long} maxUserLimit
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.maxUserLimit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentEvent eventDescription1.
         * @member {string} eventDescription1
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.eventDescription1 = "";

        /**
         * ContentEvent eventDescription2.
         * @member {string} eventDescription2
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.eventDescription2 = "";

        /**
         * ContentEvent paymentDescriptionVersion.
         * @member {number} paymentDescriptionVersion
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.paymentDescriptionVersion = 0;

        /**
         * ContentEvent userCountOn.
         * @member {boolean} userCountOn
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.userCountOn = false;

        /**
         * ContentEvent userListOn.
         * @member {boolean} userListOn
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.userListOn = false;

        /**
         * ContentEvent trackMoreDescription.
         * @member {boolean} trackMoreDescription
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.trackMoreDescription = false;

        /**
         * ContentEvent trackMoreTitle.
         * @member {string} trackMoreTitle
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.trackMoreTitle = "";

        /**
         * ContentEvent trackMoreButton.
         * @member {string} trackMoreButton
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.trackMoreButton = "";

        /**
         * ContentEvent descriptionRemainCount.
         * @member {number} descriptionRemainCount
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.descriptionRemainCount = 0;

        /**
         * ContentEvent wallpaper.
         * @member {Proto.IUploadFile|null|undefined} wallpaper
         * @memberof Proto.ContentEvent
         * @instance
         */
        ContentEvent.prototype.wallpaper = null;

        /**
         * Creates a new ContentEvent instance using the specified properties.
         * @function create
         * @memberof Proto.ContentEvent
         * @static
         * @param {Proto.IContentEvent=} [properties] Properties to set
         * @returns {Proto.ContentEvent} ContentEvent instance
         */
        ContentEvent.create = function create(properties) {
            return new ContentEvent(properties);
        };

        /**
         * Encodes the specified ContentEvent message. Does not implicitly {@link Proto.ContentEvent.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentEvent
         * @static
         * @param {Proto.IContentEvent} m ContentEvent message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentEvent.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(18).string(m.contentUID);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(26).string(m.title);
            if (m.userLimit != null && Object.hasOwnProperty.call(m, "userLimit"))
                w.uint32(32).int32(m.userLimit);
            if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                w.uint32(40).int32(m.duration);
            if (m.locationName != null && Object.hasOwnProperty.call(m, "locationName"))
                w.uint32(50).string(m.locationName);
            if (m.locationLat != null && Object.hasOwnProperty.call(m, "locationLat"))
                w.uint32(57).double(m.locationLat);
            if (m.locationLng != null && Object.hasOwnProperty.call(m, "locationLng"))
                w.uint32(65).double(m.locationLng);
            if (m.policy != null && Object.hasOwnProperty.call(m, "policy"))
                w.uint32(74).string(m.policy);
            if (m.additionalOrder != null && Object.hasOwnProperty.call(m, "additionalOrder"))
                w.uint32(82).string(m.additionalOrder);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(88).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(96).int64(m.modifyDate);
            if (m.date != null && m.date.length) {
                for (var i = 0; i < m.date.length; ++i)
                    $root.Proto.ContentEventDate.encode(m.date[i], w.uint32(106).fork()).ldelim();
            }
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(114).string(m.description);
            if (m.address != null && Object.hasOwnProperty.call(m, "address"))
                w.uint32(122).string(m.address);
            if (m.waitingRoom != null && Object.hasOwnProperty.call(m, "waitingRoom"))
                w.uint32(128).bool(m.waitingRoom);
            if (m.autoApprove != null && Object.hasOwnProperty.call(m, "autoApprove"))
                w.uint32(136).bool(m.autoApprove);
            if (m.currentDate != null && m.currentDate.length) {
                for (var i = 0; i < m.currentDate.length; ++i)
                    $root.Proto.ContentEventDate.encode(m.currentDate[i], w.uint32(146).fork()).ldelim();
            }
            if (m.paymentDescription != null && Object.hasOwnProperty.call(m, "paymentDescription"))
                w.uint32(154).string(m.paymentDescription);
            if (m.alert != null && Object.hasOwnProperty.call(m, "alert"))
                w.uint32(162).string(m.alert);
            if (m.shortAddress != null && Object.hasOwnProperty.call(m, "shortAddress"))
                w.uint32(170).string(m.shortAddress);
            if (m.cameraOn != null && Object.hasOwnProperty.call(m, "cameraOn"))
                w.uint32(176).bool(m.cameraOn);
            if (m.microOn != null && Object.hasOwnProperty.call(m, "microOn"))
                w.uint32(184).bool(m.microOn);
            if (m.chatPolicy != null && Object.hasOwnProperty.call(m, "chatPolicy"))
                w.uint32(192).int32(m.chatPolicy);
            if (m.commercePolicy != null && Object.hasOwnProperty.call(m, "commercePolicy"))
                w.uint32(200).int32(m.commercePolicy);
            if (m.productOrder != null && Object.hasOwnProperty.call(m, "productOrder"))
                w.uint32(210).string(m.productOrder);
            if (m.products != null && m.products.length) {
                for (var i = 0; i < m.products.length; ++i)
                    $root.Proto.ContentEventProduct.encode(m.products[i], w.uint32(218).fork()).ldelim();
            }
            if (m.livePolicy != null && Object.hasOwnProperty.call(m, "livePolicy"))
                w.uint32(224).int32(m.livePolicy);
            if (m.minUserLimit != null && Object.hasOwnProperty.call(m, "minUserLimit"))
                w.uint32(232).int64(m.minUserLimit);
            if (m.maxUserLimit != null && Object.hasOwnProperty.call(m, "maxUserLimit"))
                w.uint32(240).int64(m.maxUserLimit);
            if (m.eventDescription1 != null && Object.hasOwnProperty.call(m, "eventDescription1"))
                w.uint32(250).string(m.eventDescription1);
            if (m.eventDescription2 != null && Object.hasOwnProperty.call(m, "eventDescription2"))
                w.uint32(258).string(m.eventDescription2);
            if (m.paymentDescriptionVersion != null && Object.hasOwnProperty.call(m, "paymentDescriptionVersion"))
                w.uint32(264).int32(m.paymentDescriptionVersion);
            if (m.userCountOn != null && Object.hasOwnProperty.call(m, "userCountOn"))
                w.uint32(272).bool(m.userCountOn);
            if (m.userListOn != null && Object.hasOwnProperty.call(m, "userListOn"))
                w.uint32(280).bool(m.userListOn);
            if (m.trackMoreDescription != null && Object.hasOwnProperty.call(m, "trackMoreDescription"))
                w.uint32(288).bool(m.trackMoreDescription);
            if (m.trackMoreTitle != null && Object.hasOwnProperty.call(m, "trackMoreTitle"))
                w.uint32(298).string(m.trackMoreTitle);
            if (m.trackMoreButton != null && Object.hasOwnProperty.call(m, "trackMoreButton"))
                w.uint32(306).string(m.trackMoreButton);
            if (m.descriptionRemainCount != null && Object.hasOwnProperty.call(m, "descriptionRemainCount"))
                w.uint32(312).int32(m.descriptionRemainCount);
            if (m.wallpaper != null && Object.hasOwnProperty.call(m, "wallpaper"))
                $root.Proto.UploadFile.encode(m.wallpaper, w.uint32(322).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a ContentEvent message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentEvent} ContentEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentEvent.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentEvent();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.contentUID = r.string();
                    break;
                case 3:
                    m.title = r.string();
                    break;
                case 4:
                    m.userLimit = r.int32();
                    break;
                case 5:
                    m.duration = r.int32();
                    break;
                case 6:
                    m.locationName = r.string();
                    break;
                case 7:
                    m.locationLat = r.double();
                    break;
                case 8:
                    m.locationLng = r.double();
                    break;
                case 9:
                    m.policy = r.string();
                    break;
                case 10:
                    m.additionalOrder = r.string();
                    break;
                case 11:
                    m.registerDate = r.int64();
                    break;
                case 12:
                    m.modifyDate = r.int64();
                    break;
                case 13:
                    if (!(m.date && m.date.length))
                        m.date = [];
                    m.date.push($root.Proto.ContentEventDate.decode(r, r.uint32()));
                    break;
                case 14:
                    m.description = r.string();
                    break;
                case 15:
                    m.address = r.string();
                    break;
                case 16:
                    m.waitingRoom = r.bool();
                    break;
                case 17:
                    m.autoApprove = r.bool();
                    break;
                case 18:
                    if (!(m.currentDate && m.currentDate.length))
                        m.currentDate = [];
                    m.currentDate.push($root.Proto.ContentEventDate.decode(r, r.uint32()));
                    break;
                case 19:
                    m.paymentDescription = r.string();
                    break;
                case 20:
                    m.alert = r.string();
                    break;
                case 21:
                    m.shortAddress = r.string();
                    break;
                case 22:
                    m.cameraOn = r.bool();
                    break;
                case 23:
                    m.microOn = r.bool();
                    break;
                case 24:
                    m.chatPolicy = r.int32();
                    break;
                case 25:
                    m.commercePolicy = r.int32();
                    break;
                case 26:
                    m.productOrder = r.string();
                    break;
                case 27:
                    if (!(m.products && m.products.length))
                        m.products = [];
                    m.products.push($root.Proto.ContentEventProduct.decode(r, r.uint32()));
                    break;
                case 28:
                    m.livePolicy = r.int32();
                    break;
                case 29:
                    m.minUserLimit = r.int64();
                    break;
                case 30:
                    m.maxUserLimit = r.int64();
                    break;
                case 31:
                    m.eventDescription1 = r.string();
                    break;
                case 32:
                    m.eventDescription2 = r.string();
                    break;
                case 33:
                    m.paymentDescriptionVersion = r.int32();
                    break;
                case 34:
                    m.userCountOn = r.bool();
                    break;
                case 35:
                    m.userListOn = r.bool();
                    break;
                case 36:
                    m.trackMoreDescription = r.bool();
                    break;
                case 37:
                    m.trackMoreTitle = r.string();
                    break;
                case 38:
                    m.trackMoreButton = r.string();
                    break;
                case 39:
                    m.descriptionRemainCount = r.int32();
                    break;
                case 40:
                    m.wallpaper = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentEvent
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentEvent} ContentEvent
         */
        ContentEvent.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentEvent)
                return d;
            var m = new $root.Proto.ContentEvent();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.userLimit != null) {
                m.userLimit = d.userLimit | 0;
            }
            if (d.duration != null) {
                m.duration = d.duration | 0;
            }
            if (d.locationName != null) {
                m.locationName = String(d.locationName);
            }
            if (d.locationLat != null) {
                m.locationLat = Number(d.locationLat);
            }
            if (d.locationLng != null) {
                m.locationLng = Number(d.locationLng);
            }
            if (d.policy != null) {
                m.policy = String(d.policy);
            }
            if (d.additionalOrder != null) {
                m.additionalOrder = String(d.additionalOrder);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.date) {
                if (!Array.isArray(d.date))
                    throw TypeError(".Proto.ContentEvent.date: array expected");
                m.date = [];
                for (var i = 0; i < d.date.length; ++i) {
                    if (typeof d.date[i] !== "object")
                        throw TypeError(".Proto.ContentEvent.date: object expected");
                    m.date[i] = $root.Proto.ContentEventDate.fromObject(d.date[i]);
                }
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.address != null) {
                m.address = String(d.address);
            }
            if (d.waitingRoom != null) {
                m.waitingRoom = Boolean(d.waitingRoom);
            }
            if (d.autoApprove != null) {
                m.autoApprove = Boolean(d.autoApprove);
            }
            if (d.currentDate) {
                if (!Array.isArray(d.currentDate))
                    throw TypeError(".Proto.ContentEvent.currentDate: array expected");
                m.currentDate = [];
                for (var i = 0; i < d.currentDate.length; ++i) {
                    if (typeof d.currentDate[i] !== "object")
                        throw TypeError(".Proto.ContentEvent.currentDate: object expected");
                    m.currentDate[i] = $root.Proto.ContentEventDate.fromObject(d.currentDate[i]);
                }
            }
            if (d.paymentDescription != null) {
                m.paymentDescription = String(d.paymentDescription);
            }
            if (d.alert != null) {
                m.alert = String(d.alert);
            }
            if (d.shortAddress != null) {
                m.shortAddress = String(d.shortAddress);
            }
            if (d.cameraOn != null) {
                m.cameraOn = Boolean(d.cameraOn);
            }
            if (d.microOn != null) {
                m.microOn = Boolean(d.microOn);
            }
            if (d.chatPolicy != null) {
                m.chatPolicy = d.chatPolicy | 0;
            }
            if (d.commercePolicy != null) {
                m.commercePolicy = d.commercePolicy | 0;
            }
            if (d.productOrder != null) {
                m.productOrder = String(d.productOrder);
            }
            if (d.products) {
                if (!Array.isArray(d.products))
                    throw TypeError(".Proto.ContentEvent.products: array expected");
                m.products = [];
                for (var i = 0; i < d.products.length; ++i) {
                    if (typeof d.products[i] !== "object")
                        throw TypeError(".Proto.ContentEvent.products: object expected");
                    m.products[i] = $root.Proto.ContentEventProduct.fromObject(d.products[i]);
                }
            }
            if (d.livePolicy != null) {
                m.livePolicy = d.livePolicy | 0;
            }
            if (d.minUserLimit != null) {
                if ($util.Long)
                    (m.minUserLimit = $util.Long.fromValue(d.minUserLimit)).unsigned = false;
                else if (typeof d.minUserLimit === "string")
                    m.minUserLimit = parseInt(d.minUserLimit, 10);
                else if (typeof d.minUserLimit === "number")
                    m.minUserLimit = d.minUserLimit;
                else if (typeof d.minUserLimit === "object")
                    m.minUserLimit = new $util.LongBits(d.minUserLimit.low >>> 0, d.minUserLimit.high >>> 0).toNumber();
            }
            if (d.maxUserLimit != null) {
                if ($util.Long)
                    (m.maxUserLimit = $util.Long.fromValue(d.maxUserLimit)).unsigned = false;
                else if (typeof d.maxUserLimit === "string")
                    m.maxUserLimit = parseInt(d.maxUserLimit, 10);
                else if (typeof d.maxUserLimit === "number")
                    m.maxUserLimit = d.maxUserLimit;
                else if (typeof d.maxUserLimit === "object")
                    m.maxUserLimit = new $util.LongBits(d.maxUserLimit.low >>> 0, d.maxUserLimit.high >>> 0).toNumber();
            }
            if (d.eventDescription1 != null) {
                m.eventDescription1 = String(d.eventDescription1);
            }
            if (d.eventDescription2 != null) {
                m.eventDescription2 = String(d.eventDescription2);
            }
            if (d.paymentDescriptionVersion != null) {
                m.paymentDescriptionVersion = d.paymentDescriptionVersion | 0;
            }
            if (d.userCountOn != null) {
                m.userCountOn = Boolean(d.userCountOn);
            }
            if (d.userListOn != null) {
                m.userListOn = Boolean(d.userListOn);
            }
            if (d.trackMoreDescription != null) {
                m.trackMoreDescription = Boolean(d.trackMoreDescription);
            }
            if (d.trackMoreTitle != null) {
                m.trackMoreTitle = String(d.trackMoreTitle);
            }
            if (d.trackMoreButton != null) {
                m.trackMoreButton = String(d.trackMoreButton);
            }
            if (d.descriptionRemainCount != null) {
                m.descriptionRemainCount = d.descriptionRemainCount | 0;
            }
            if (d.wallpaper != null) {
                if (typeof d.wallpaper !== "object")
                    throw TypeError(".Proto.ContentEvent.wallpaper: object expected");
                m.wallpaper = $root.Proto.UploadFile.fromObject(d.wallpaper);
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentEvent
         * @static
         * @param {Proto.ContentEvent} m ContentEvent
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentEvent.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.date = [];
                d.currentDate = [];
                d.products = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.contentUID = "";
                d.title = "";
                d.userLimit = 0;
                d.duration = 0;
                d.locationName = "";
                d.locationLat = 0;
                d.locationLng = 0;
                d.policy = "";
                d.additionalOrder = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.description = "";
                d.address = "";
                d.waitingRoom = false;
                d.autoApprove = false;
                d.paymentDescription = "";
                d.alert = "";
                d.shortAddress = "";
                d.cameraOn = false;
                d.microOn = false;
                d.chatPolicy = 0;
                d.commercePolicy = 0;
                d.productOrder = "";
                d.livePolicy = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.minUserLimit = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.minUserLimit = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.maxUserLimit = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.maxUserLimit = o.longs === String ? "0" : 0;
                d.eventDescription1 = "";
                d.eventDescription2 = "";
                d.paymentDescriptionVersion = 0;
                d.userCountOn = false;
                d.userListOn = false;
                d.trackMoreDescription = false;
                d.trackMoreTitle = "";
                d.trackMoreButton = "";
                d.descriptionRemainCount = 0;
                d.wallpaper = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.userLimit != null && m.hasOwnProperty("userLimit")) {
                d.userLimit = m.userLimit;
            }
            if (m.duration != null && m.hasOwnProperty("duration")) {
                d.duration = m.duration;
            }
            if (m.locationName != null && m.hasOwnProperty("locationName")) {
                d.locationName = m.locationName;
            }
            if (m.locationLat != null && m.hasOwnProperty("locationLat")) {
                d.locationLat = o.json && !isFinite(m.locationLat) ? String(m.locationLat) : m.locationLat;
            }
            if (m.locationLng != null && m.hasOwnProperty("locationLng")) {
                d.locationLng = o.json && !isFinite(m.locationLng) ? String(m.locationLng) : m.locationLng;
            }
            if (m.policy != null && m.hasOwnProperty("policy")) {
                d.policy = m.policy;
            }
            if (m.additionalOrder != null && m.hasOwnProperty("additionalOrder")) {
                d.additionalOrder = m.additionalOrder;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.date && m.date.length) {
                d.date = [];
                for (var j = 0; j < m.date.length; ++j) {
                    d.date[j] = $root.Proto.ContentEventDate.toObject(m.date[j], o);
                }
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.address != null && m.hasOwnProperty("address")) {
                d.address = m.address;
            }
            if (m.waitingRoom != null && m.hasOwnProperty("waitingRoom")) {
                d.waitingRoom = m.waitingRoom;
            }
            if (m.autoApprove != null && m.hasOwnProperty("autoApprove")) {
                d.autoApprove = m.autoApprove;
            }
            if (m.currentDate && m.currentDate.length) {
                d.currentDate = [];
                for (var j = 0; j < m.currentDate.length; ++j) {
                    d.currentDate[j] = $root.Proto.ContentEventDate.toObject(m.currentDate[j], o);
                }
            }
            if (m.paymentDescription != null && m.hasOwnProperty("paymentDescription")) {
                d.paymentDescription = m.paymentDescription;
            }
            if (m.alert != null && m.hasOwnProperty("alert")) {
                d.alert = m.alert;
            }
            if (m.shortAddress != null && m.hasOwnProperty("shortAddress")) {
                d.shortAddress = m.shortAddress;
            }
            if (m.cameraOn != null && m.hasOwnProperty("cameraOn")) {
                d.cameraOn = m.cameraOn;
            }
            if (m.microOn != null && m.hasOwnProperty("microOn")) {
                d.microOn = m.microOn;
            }
            if (m.chatPolicy != null && m.hasOwnProperty("chatPolicy")) {
                d.chatPolicy = m.chatPolicy;
            }
            if (m.commercePolicy != null && m.hasOwnProperty("commercePolicy")) {
                d.commercePolicy = m.commercePolicy;
            }
            if (m.productOrder != null && m.hasOwnProperty("productOrder")) {
                d.productOrder = m.productOrder;
            }
            if (m.products && m.products.length) {
                d.products = [];
                for (var j = 0; j < m.products.length; ++j) {
                    d.products[j] = $root.Proto.ContentEventProduct.toObject(m.products[j], o);
                }
            }
            if (m.livePolicy != null && m.hasOwnProperty("livePolicy")) {
                d.livePolicy = m.livePolicy;
            }
            if (m.minUserLimit != null && m.hasOwnProperty("minUserLimit")) {
                if (typeof m.minUserLimit === "number")
                    d.minUserLimit = o.longs === String ? String(m.minUserLimit) : m.minUserLimit;
                else
                    d.minUserLimit = o.longs === String ? $util.Long.prototype.toString.call(m.minUserLimit) : o.longs === Number ? new $util.LongBits(m.minUserLimit.low >>> 0, m.minUserLimit.high >>> 0).toNumber() : m.minUserLimit;
            }
            if (m.maxUserLimit != null && m.hasOwnProperty("maxUserLimit")) {
                if (typeof m.maxUserLimit === "number")
                    d.maxUserLimit = o.longs === String ? String(m.maxUserLimit) : m.maxUserLimit;
                else
                    d.maxUserLimit = o.longs === String ? $util.Long.prototype.toString.call(m.maxUserLimit) : o.longs === Number ? new $util.LongBits(m.maxUserLimit.low >>> 0, m.maxUserLimit.high >>> 0).toNumber() : m.maxUserLimit;
            }
            if (m.eventDescription1 != null && m.hasOwnProperty("eventDescription1")) {
                d.eventDescription1 = m.eventDescription1;
            }
            if (m.eventDescription2 != null && m.hasOwnProperty("eventDescription2")) {
                d.eventDescription2 = m.eventDescription2;
            }
            if (m.paymentDescriptionVersion != null && m.hasOwnProperty("paymentDescriptionVersion")) {
                d.paymentDescriptionVersion = m.paymentDescriptionVersion;
            }
            if (m.userCountOn != null && m.hasOwnProperty("userCountOn")) {
                d.userCountOn = m.userCountOn;
            }
            if (m.userListOn != null && m.hasOwnProperty("userListOn")) {
                d.userListOn = m.userListOn;
            }
            if (m.trackMoreDescription != null && m.hasOwnProperty("trackMoreDescription")) {
                d.trackMoreDescription = m.trackMoreDescription;
            }
            if (m.trackMoreTitle != null && m.hasOwnProperty("trackMoreTitle")) {
                d.trackMoreTitle = m.trackMoreTitle;
            }
            if (m.trackMoreButton != null && m.hasOwnProperty("trackMoreButton")) {
                d.trackMoreButton = m.trackMoreButton;
            }
            if (m.descriptionRemainCount != null && m.hasOwnProperty("descriptionRemainCount")) {
                d.descriptionRemainCount = m.descriptionRemainCount;
            }
            if (m.wallpaper != null && m.hasOwnProperty("wallpaper")) {
                d.wallpaper = $root.Proto.UploadFile.toObject(m.wallpaper, o);
            }
            return d;
        };

        /**
         * Converts this ContentEvent to JSON.
         * @function toJSON
         * @memberof Proto.ContentEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentEvent;
    })();

    Proto.CompanyAppSetting = (function() {

        /**
         * Properties of a CompanyAppSetting.
         * @memberof Proto
         * @interface ICompanyAppSetting
         * @property {boolean|null} [allowChat] CompanyAppSetting allowChat
         * @property {string|null} [defaultMessage] CompanyAppSetting defaultMessage
         * @property {boolean|null} [allowEvent] CompanyAppSetting allowEvent
         * @property {string|null} [eventButton] CompanyAppSetting eventButton
         * @property {boolean|null} [allowEventAutoreply] CompanyAppSetting allowEventAutoreply
         * @property {string|null} [eventAutoreply] CompanyAppSetting eventAutoreply
         * @property {number|Long|null} [registerDate] CompanyAppSetting registerDate
         * @property {number|Long|null} [modifyDate] CompanyAppSetting modifyDate
         * @property {boolean|null} [callPhone] CompanyAppSetting callPhone
         * @property {boolean|null} [useRead] CompanyAppSetting useRead
         */

        /**
         * Constructs a new CompanyAppSetting.
         * @memberof Proto
         * @classdesc Represents a CompanyAppSetting.
         * @implements ICompanyAppSetting
         * @constructor
         * @param {Proto.ICompanyAppSetting=} [p] Properties to set
         */
        function CompanyAppSetting(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyAppSetting allowChat.
         * @member {boolean} allowChat
         * @memberof Proto.CompanyAppSetting
         * @instance
         */
        CompanyAppSetting.prototype.allowChat = false;

        /**
         * CompanyAppSetting defaultMessage.
         * @member {string} defaultMessage
         * @memberof Proto.CompanyAppSetting
         * @instance
         */
        CompanyAppSetting.prototype.defaultMessage = "";

        /**
         * CompanyAppSetting allowEvent.
         * @member {boolean} allowEvent
         * @memberof Proto.CompanyAppSetting
         * @instance
         */
        CompanyAppSetting.prototype.allowEvent = false;

        /**
         * CompanyAppSetting eventButton.
         * @member {string} eventButton
         * @memberof Proto.CompanyAppSetting
         * @instance
         */
        CompanyAppSetting.prototype.eventButton = "";

        /**
         * CompanyAppSetting allowEventAutoreply.
         * @member {boolean} allowEventAutoreply
         * @memberof Proto.CompanyAppSetting
         * @instance
         */
        CompanyAppSetting.prototype.allowEventAutoreply = false;

        /**
         * CompanyAppSetting eventAutoreply.
         * @member {string} eventAutoreply
         * @memberof Proto.CompanyAppSetting
         * @instance
         */
        CompanyAppSetting.prototype.eventAutoreply = "";

        /**
         * CompanyAppSetting registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CompanyAppSetting
         * @instance
         */
        CompanyAppSetting.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyAppSetting modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CompanyAppSetting
         * @instance
         */
        CompanyAppSetting.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyAppSetting callPhone.
         * @member {boolean} callPhone
         * @memberof Proto.CompanyAppSetting
         * @instance
         */
        CompanyAppSetting.prototype.callPhone = false;

        /**
         * CompanyAppSetting useRead.
         * @member {boolean} useRead
         * @memberof Proto.CompanyAppSetting
         * @instance
         */
        CompanyAppSetting.prototype.useRead = false;

        /**
         * Creates a new CompanyAppSetting instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyAppSetting
         * @static
         * @param {Proto.ICompanyAppSetting=} [properties] Properties to set
         * @returns {Proto.CompanyAppSetting} CompanyAppSetting instance
         */
        CompanyAppSetting.create = function create(properties) {
            return new CompanyAppSetting(properties);
        };

        /**
         * Encodes the specified CompanyAppSetting message. Does not implicitly {@link Proto.CompanyAppSetting.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyAppSetting
         * @static
         * @param {Proto.ICompanyAppSetting} m CompanyAppSetting message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyAppSetting.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.allowChat != null && Object.hasOwnProperty.call(m, "allowChat"))
                w.uint32(8).bool(m.allowChat);
            if (m.defaultMessage != null && Object.hasOwnProperty.call(m, "defaultMessage"))
                w.uint32(18).string(m.defaultMessage);
            if (m.allowEvent != null && Object.hasOwnProperty.call(m, "allowEvent"))
                w.uint32(24).bool(m.allowEvent);
            if (m.eventButton != null && Object.hasOwnProperty.call(m, "eventButton"))
                w.uint32(34).string(m.eventButton);
            if (m.allowEventAutoreply != null && Object.hasOwnProperty.call(m, "allowEventAutoreply"))
                w.uint32(40).bool(m.allowEventAutoreply);
            if (m.eventAutoreply != null && Object.hasOwnProperty.call(m, "eventAutoreply"))
                w.uint32(50).string(m.eventAutoreply);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(56).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(64).int64(m.modifyDate);
            if (m.callPhone != null && Object.hasOwnProperty.call(m, "callPhone"))
                w.uint32(72).bool(m.callPhone);
            if (m.useRead != null && Object.hasOwnProperty.call(m, "useRead"))
                w.uint32(80).bool(m.useRead);
            return w;
        };

        /**
         * Decodes a CompanyAppSetting message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyAppSetting
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyAppSetting} CompanyAppSetting
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyAppSetting.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyAppSetting();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.allowChat = r.bool();
                    break;
                case 2:
                    m.defaultMessage = r.string();
                    break;
                case 3:
                    m.allowEvent = r.bool();
                    break;
                case 4:
                    m.eventButton = r.string();
                    break;
                case 5:
                    m.allowEventAutoreply = r.bool();
                    break;
                case 6:
                    m.eventAutoreply = r.string();
                    break;
                case 7:
                    m.registerDate = r.int64();
                    break;
                case 8:
                    m.modifyDate = r.int64();
                    break;
                case 9:
                    m.callPhone = r.bool();
                    break;
                case 10:
                    m.useRead = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyAppSetting message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyAppSetting
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyAppSetting} CompanyAppSetting
         */
        CompanyAppSetting.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyAppSetting)
                return d;
            var m = new $root.Proto.CompanyAppSetting();
            if (d.allowChat != null) {
                m.allowChat = Boolean(d.allowChat);
            }
            if (d.defaultMessage != null) {
                m.defaultMessage = String(d.defaultMessage);
            }
            if (d.allowEvent != null) {
                m.allowEvent = Boolean(d.allowEvent);
            }
            if (d.eventButton != null) {
                m.eventButton = String(d.eventButton);
            }
            if (d.allowEventAutoreply != null) {
                m.allowEventAutoreply = Boolean(d.allowEventAutoreply);
            }
            if (d.eventAutoreply != null) {
                m.eventAutoreply = String(d.eventAutoreply);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.callPhone != null) {
                m.callPhone = Boolean(d.callPhone);
            }
            if (d.useRead != null) {
                m.useRead = Boolean(d.useRead);
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyAppSetting message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyAppSetting
         * @static
         * @param {Proto.CompanyAppSetting} m CompanyAppSetting
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyAppSetting.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.allowChat = false;
                d.defaultMessage = "";
                d.allowEvent = false;
                d.eventButton = "";
                d.allowEventAutoreply = false;
                d.eventAutoreply = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.callPhone = false;
                d.useRead = false;
            }
            if (m.allowChat != null && m.hasOwnProperty("allowChat")) {
                d.allowChat = m.allowChat;
            }
            if (m.defaultMessage != null && m.hasOwnProperty("defaultMessage")) {
                d.defaultMessage = m.defaultMessage;
            }
            if (m.allowEvent != null && m.hasOwnProperty("allowEvent")) {
                d.allowEvent = m.allowEvent;
            }
            if (m.eventButton != null && m.hasOwnProperty("eventButton")) {
                d.eventButton = m.eventButton;
            }
            if (m.allowEventAutoreply != null && m.hasOwnProperty("allowEventAutoreply")) {
                d.allowEventAutoreply = m.allowEventAutoreply;
            }
            if (m.eventAutoreply != null && m.hasOwnProperty("eventAutoreply")) {
                d.eventAutoreply = m.eventAutoreply;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.callPhone != null && m.hasOwnProperty("callPhone")) {
                d.callPhone = m.callPhone;
            }
            if (m.useRead != null && m.hasOwnProperty("useRead")) {
                d.useRead = m.useRead;
            }
            return d;
        };

        /**
         * Converts this CompanyAppSetting to JSON.
         * @function toJSON
         * @memberof Proto.CompanyAppSetting
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyAppSetting.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyAppSetting;
    })();

    Proto.AppMessageMeta = (function() {

        /**
         * Properties of an AppMessageMeta.
         * @memberof Proto
         * @interface IAppMessageMeta
         * @property {string|null} [meta1] AppMessageMeta meta1
         * @property {string|null} [meta2] AppMessageMeta meta2
         * @property {string|null} [meta3] AppMessageMeta meta3
         * @property {number|Long|null} [registerDate] AppMessageMeta registerDate
         * @property {number|Long|null} [modifyDate] AppMessageMeta modifyDate
         */

        /**
         * Constructs a new AppMessageMeta.
         * @memberof Proto
         * @classdesc Represents an AppMessageMeta.
         * @implements IAppMessageMeta
         * @constructor
         * @param {Proto.IAppMessageMeta=} [p] Properties to set
         */
        function AppMessageMeta(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AppMessageMeta meta1.
         * @member {string} meta1
         * @memberof Proto.AppMessageMeta
         * @instance
         */
        AppMessageMeta.prototype.meta1 = "";

        /**
         * AppMessageMeta meta2.
         * @member {string} meta2
         * @memberof Proto.AppMessageMeta
         * @instance
         */
        AppMessageMeta.prototype.meta2 = "";

        /**
         * AppMessageMeta meta3.
         * @member {string} meta3
         * @memberof Proto.AppMessageMeta
         * @instance
         */
        AppMessageMeta.prototype.meta3 = "";

        /**
         * AppMessageMeta registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.AppMessageMeta
         * @instance
         */
        AppMessageMeta.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * AppMessageMeta modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.AppMessageMeta
         * @instance
         */
        AppMessageMeta.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new AppMessageMeta instance using the specified properties.
         * @function create
         * @memberof Proto.AppMessageMeta
         * @static
         * @param {Proto.IAppMessageMeta=} [properties] Properties to set
         * @returns {Proto.AppMessageMeta} AppMessageMeta instance
         */
        AppMessageMeta.create = function create(properties) {
            return new AppMessageMeta(properties);
        };

        /**
         * Encodes the specified AppMessageMeta message. Does not implicitly {@link Proto.AppMessageMeta.verify|verify} messages.
         * @function encode
         * @memberof Proto.AppMessageMeta
         * @static
         * @param {Proto.IAppMessageMeta} m AppMessageMeta message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppMessageMeta.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.meta1 != null && Object.hasOwnProperty.call(m, "meta1"))
                w.uint32(10).string(m.meta1);
            if (m.meta2 != null && Object.hasOwnProperty.call(m, "meta2"))
                w.uint32(18).string(m.meta2);
            if (m.meta3 != null && Object.hasOwnProperty.call(m, "meta3"))
                w.uint32(26).string(m.meta3);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes an AppMessageMeta message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.AppMessageMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.AppMessageMeta} AppMessageMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppMessageMeta.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.AppMessageMeta();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.meta1 = r.string();
                    break;
                case 2:
                    m.meta2 = r.string();
                    break;
                case 3:
                    m.meta3 = r.string();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AppMessageMeta message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.AppMessageMeta
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.AppMessageMeta} AppMessageMeta
         */
        AppMessageMeta.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.AppMessageMeta)
                return d;
            var m = new $root.Proto.AppMessageMeta();
            if (d.meta1 != null) {
                m.meta1 = String(d.meta1);
            }
            if (d.meta2 != null) {
                m.meta2 = String(d.meta2);
            }
            if (d.meta3 != null) {
                m.meta3 = String(d.meta3);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from an AppMessageMeta message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.AppMessageMeta
         * @static
         * @param {Proto.AppMessageMeta} m AppMessageMeta
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppMessageMeta.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.meta1 = "";
                d.meta2 = "";
                d.meta3 = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.meta1 != null && m.hasOwnProperty("meta1")) {
                d.meta1 = m.meta1;
            }
            if (m.meta2 != null && m.hasOwnProperty("meta2")) {
                d.meta2 = m.meta2;
            }
            if (m.meta3 != null && m.hasOwnProperty("meta3")) {
                d.meta3 = m.meta3;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this AppMessageMeta to JSON.
         * @function toJSON
         * @memberof Proto.AppMessageMeta
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppMessageMeta.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppMessageMeta;
    })();

    Proto.AppMessage = (function() {

        /**
         * Properties of an AppMessage.
         * @memberof Proto
         * @interface IAppMessage
         * @property {string|null} [uid] AppMessage uid
         * @property {boolean|null} [sender] AppMessage sender
         * @property {number|null} [type] AppMessage type
         * @property {string|null} [text] AppMessage text
         * @property {Proto.IAppMessageMeta|null} [meta] AppMessage meta
         * @property {Proto.IUploadFile|null} [companyUserIcon] AppMessage companyUserIcon
         * @property {Proto.IUploadFile|null} [userIcon] AppMessage userIcon
         * @property {string|null} [companyUID] AppMessage companyUID
         * @property {Proto.ICompanyLP|null} [companyLP] AppMessage companyLP
         * @property {number|Long|null} [registerDate] AppMessage registerDate
         * @property {number|Long|null} [modifyDate] AppMessage modifyDate
         * @property {Proto.ICompanyLogo|null} [companyLogo] AppMessage companyLogo
         * @property {string|null} [username] AppMessage username
         * @property {string|null} [userUID] AppMessage userUID
         * @property {boolean|null} [unread] AppMessage unread
         */

        /**
         * Constructs a new AppMessage.
         * @memberof Proto
         * @classdesc Represents an AppMessage.
         * @implements IAppMessage
         * @constructor
         * @param {Proto.IAppMessage=} [p] Properties to set
         */
        function AppMessage(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AppMessage uid.
         * @member {string} uid
         * @memberof Proto.AppMessage
         * @instance
         */
        AppMessage.prototype.uid = "";

        /**
         * AppMessage sender.
         * @member {boolean} sender
         * @memberof Proto.AppMessage
         * @instance
         */
        AppMessage.prototype.sender = false;

        /**
         * AppMessage type.
         * @member {number} type
         * @memberof Proto.AppMessage
         * @instance
         */
        AppMessage.prototype.type = 0;

        /**
         * AppMessage text.
         * @member {string} text
         * @memberof Proto.AppMessage
         * @instance
         */
        AppMessage.prototype.text = "";

        /**
         * AppMessage meta.
         * @member {Proto.IAppMessageMeta|null|undefined} meta
         * @memberof Proto.AppMessage
         * @instance
         */
        AppMessage.prototype.meta = null;

        /**
         * AppMessage companyUserIcon.
         * @member {Proto.IUploadFile|null|undefined} companyUserIcon
         * @memberof Proto.AppMessage
         * @instance
         */
        AppMessage.prototype.companyUserIcon = null;

        /**
         * AppMessage userIcon.
         * @member {Proto.IUploadFile|null|undefined} userIcon
         * @memberof Proto.AppMessage
         * @instance
         */
        AppMessage.prototype.userIcon = null;

        /**
         * AppMessage companyUID.
         * @member {string} companyUID
         * @memberof Proto.AppMessage
         * @instance
         */
        AppMessage.prototype.companyUID = "";

        /**
         * AppMessage companyLP.
         * @member {Proto.ICompanyLP|null|undefined} companyLP
         * @memberof Proto.AppMessage
         * @instance
         */
        AppMessage.prototype.companyLP = null;

        /**
         * AppMessage registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.AppMessage
         * @instance
         */
        AppMessage.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * AppMessage modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.AppMessage
         * @instance
         */
        AppMessage.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * AppMessage companyLogo.
         * @member {Proto.ICompanyLogo|null|undefined} companyLogo
         * @memberof Proto.AppMessage
         * @instance
         */
        AppMessage.prototype.companyLogo = null;

        /**
         * AppMessage username.
         * @member {string} username
         * @memberof Proto.AppMessage
         * @instance
         */
        AppMessage.prototype.username = "";

        /**
         * AppMessage userUID.
         * @member {string} userUID
         * @memberof Proto.AppMessage
         * @instance
         */
        AppMessage.prototype.userUID = "";

        /**
         * AppMessage unread.
         * @member {boolean} unread
         * @memberof Proto.AppMessage
         * @instance
         */
        AppMessage.prototype.unread = false;

        /**
         * Creates a new AppMessage instance using the specified properties.
         * @function create
         * @memberof Proto.AppMessage
         * @static
         * @param {Proto.IAppMessage=} [properties] Properties to set
         * @returns {Proto.AppMessage} AppMessage instance
         */
        AppMessage.create = function create(properties) {
            return new AppMessage(properties);
        };

        /**
         * Encodes the specified AppMessage message. Does not implicitly {@link Proto.AppMessage.verify|verify} messages.
         * @function encode
         * @memberof Proto.AppMessage
         * @static
         * @param {Proto.IAppMessage} m AppMessage message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppMessage.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.sender != null && Object.hasOwnProperty.call(m, "sender"))
                w.uint32(16).bool(m.sender);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(24).int32(m.type);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(34).string(m.text);
            if (m.meta != null && Object.hasOwnProperty.call(m, "meta"))
                $root.Proto.AppMessageMeta.encode(m.meta, w.uint32(42).fork()).ldelim();
            if (m.companyUserIcon != null && Object.hasOwnProperty.call(m, "companyUserIcon"))
                $root.Proto.UploadFile.encode(m.companyUserIcon, w.uint32(50).fork()).ldelim();
            if (m.userIcon != null && Object.hasOwnProperty.call(m, "userIcon"))
                $root.Proto.UploadFile.encode(m.userIcon, w.uint32(58).fork()).ldelim();
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(66).string(m.companyUID);
            if (m.companyLP != null && Object.hasOwnProperty.call(m, "companyLP"))
                $root.Proto.CompanyLP.encode(m.companyLP, w.uint32(74).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(80).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(88).int64(m.modifyDate);
            if (m.companyLogo != null && Object.hasOwnProperty.call(m, "companyLogo"))
                $root.Proto.CompanyLogo.encode(m.companyLogo, w.uint32(98).fork()).ldelim();
            if (m.username != null && Object.hasOwnProperty.call(m, "username"))
                w.uint32(106).string(m.username);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(114).string(m.userUID);
            if (m.unread != null && Object.hasOwnProperty.call(m, "unread"))
                w.uint32(120).bool(m.unread);
            return w;
        };

        /**
         * Decodes an AppMessage message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.AppMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.AppMessage} AppMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppMessage.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.AppMessage();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.sender = r.bool();
                    break;
                case 3:
                    m.type = r.int32();
                    break;
                case 4:
                    m.text = r.string();
                    break;
                case 5:
                    m.meta = $root.Proto.AppMessageMeta.decode(r, r.uint32());
                    break;
                case 6:
                    m.companyUserIcon = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 7:
                    m.userIcon = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 8:
                    m.companyUID = r.string();
                    break;
                case 9:
                    m.companyLP = $root.Proto.CompanyLP.decode(r, r.uint32());
                    break;
                case 10:
                    m.registerDate = r.int64();
                    break;
                case 11:
                    m.modifyDate = r.int64();
                    break;
                case 12:
                    m.companyLogo = $root.Proto.CompanyLogo.decode(r, r.uint32());
                    break;
                case 13:
                    m.username = r.string();
                    break;
                case 14:
                    m.userUID = r.string();
                    break;
                case 15:
                    m.unread = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AppMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.AppMessage
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.AppMessage} AppMessage
         */
        AppMessage.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.AppMessage)
                return d;
            var m = new $root.Proto.AppMessage();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.sender != null) {
                m.sender = Boolean(d.sender);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.meta != null) {
                if (typeof d.meta !== "object")
                    throw TypeError(".Proto.AppMessage.meta: object expected");
                m.meta = $root.Proto.AppMessageMeta.fromObject(d.meta);
            }
            if (d.companyUserIcon != null) {
                if (typeof d.companyUserIcon !== "object")
                    throw TypeError(".Proto.AppMessage.companyUserIcon: object expected");
                m.companyUserIcon = $root.Proto.UploadFile.fromObject(d.companyUserIcon);
            }
            if (d.userIcon != null) {
                if (typeof d.userIcon !== "object")
                    throw TypeError(".Proto.AppMessage.userIcon: object expected");
                m.userIcon = $root.Proto.UploadFile.fromObject(d.userIcon);
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.companyLP != null) {
                if (typeof d.companyLP !== "object")
                    throw TypeError(".Proto.AppMessage.companyLP: object expected");
                m.companyLP = $root.Proto.CompanyLP.fromObject(d.companyLP);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.companyLogo != null) {
                if (typeof d.companyLogo !== "object")
                    throw TypeError(".Proto.AppMessage.companyLogo: object expected");
                m.companyLogo = $root.Proto.CompanyLogo.fromObject(d.companyLogo);
            }
            if (d.username != null) {
                m.username = String(d.username);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.unread != null) {
                m.unread = Boolean(d.unread);
            }
            return m;
        };

        /**
         * Creates a plain object from an AppMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.AppMessage
         * @static
         * @param {Proto.AppMessage} m AppMessage
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppMessage.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.sender = false;
                d.type = 0;
                d.text = "";
                d.meta = null;
                d.companyUserIcon = null;
                d.userIcon = null;
                d.companyUID = "";
                d.companyLP = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.companyLogo = null;
                d.username = "";
                d.userUID = "";
                d.unread = false;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.sender != null && m.hasOwnProperty("sender")) {
                d.sender = m.sender;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.meta != null && m.hasOwnProperty("meta")) {
                d.meta = $root.Proto.AppMessageMeta.toObject(m.meta, o);
            }
            if (m.companyUserIcon != null && m.hasOwnProperty("companyUserIcon")) {
                d.companyUserIcon = $root.Proto.UploadFile.toObject(m.companyUserIcon, o);
            }
            if (m.userIcon != null && m.hasOwnProperty("userIcon")) {
                d.userIcon = $root.Proto.UploadFile.toObject(m.userIcon, o);
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.companyLP != null && m.hasOwnProperty("companyLP")) {
                d.companyLP = $root.Proto.CompanyLP.toObject(m.companyLP, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.companyLogo != null && m.hasOwnProperty("companyLogo")) {
                d.companyLogo = $root.Proto.CompanyLogo.toObject(m.companyLogo, o);
            }
            if (m.username != null && m.hasOwnProperty("username")) {
                d.username = m.username;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.unread != null && m.hasOwnProperty("unread")) {
                d.unread = m.unread;
            }
            return d;
        };

        /**
         * Converts this AppMessage to JSON.
         * @function toJSON
         * @memberof Proto.AppMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppMessage;
    })();

    Proto.AppMessageList = (function() {

        /**
         * Properties of an AppMessageList.
         * @memberof Proto
         * @interface IAppMessageList
         * @property {Array.<Proto.IAppMessage>|null} [value] AppMessageList value
         * @property {Proto.IUploadFile|null} [companyUserIcon] AppMessageList companyUserIcon
         * @property {Proto.IUploadFile|null} [userIcon] AppMessageList userIcon
         * @property {Proto.ICompanyLP|null} [companyLP] AppMessageList companyLP
         * @property {Proto.ICompanyLogo|null} [companyLogo] AppMessageList companyLogo
         */

        /**
         * Constructs a new AppMessageList.
         * @memberof Proto
         * @classdesc Represents an AppMessageList.
         * @implements IAppMessageList
         * @constructor
         * @param {Proto.IAppMessageList=} [p] Properties to set
         */
        function AppMessageList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AppMessageList value.
         * @member {Array.<Proto.IAppMessage>} value
         * @memberof Proto.AppMessageList
         * @instance
         */
        AppMessageList.prototype.value = $util.emptyArray;

        /**
         * AppMessageList companyUserIcon.
         * @member {Proto.IUploadFile|null|undefined} companyUserIcon
         * @memberof Proto.AppMessageList
         * @instance
         */
        AppMessageList.prototype.companyUserIcon = null;

        /**
         * AppMessageList userIcon.
         * @member {Proto.IUploadFile|null|undefined} userIcon
         * @memberof Proto.AppMessageList
         * @instance
         */
        AppMessageList.prototype.userIcon = null;

        /**
         * AppMessageList companyLP.
         * @member {Proto.ICompanyLP|null|undefined} companyLP
         * @memberof Proto.AppMessageList
         * @instance
         */
        AppMessageList.prototype.companyLP = null;

        /**
         * AppMessageList companyLogo.
         * @member {Proto.ICompanyLogo|null|undefined} companyLogo
         * @memberof Proto.AppMessageList
         * @instance
         */
        AppMessageList.prototype.companyLogo = null;

        /**
         * Creates a new AppMessageList instance using the specified properties.
         * @function create
         * @memberof Proto.AppMessageList
         * @static
         * @param {Proto.IAppMessageList=} [properties] Properties to set
         * @returns {Proto.AppMessageList} AppMessageList instance
         */
        AppMessageList.create = function create(properties) {
            return new AppMessageList(properties);
        };

        /**
         * Encodes the specified AppMessageList message. Does not implicitly {@link Proto.AppMessageList.verify|verify} messages.
         * @function encode
         * @memberof Proto.AppMessageList
         * @static
         * @param {Proto.IAppMessageList} m AppMessageList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppMessageList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.AppMessage.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            if (m.companyUserIcon != null && Object.hasOwnProperty.call(m, "companyUserIcon"))
                $root.Proto.UploadFile.encode(m.companyUserIcon, w.uint32(18).fork()).ldelim();
            if (m.userIcon != null && Object.hasOwnProperty.call(m, "userIcon"))
                $root.Proto.UploadFile.encode(m.userIcon, w.uint32(26).fork()).ldelim();
            if (m.companyLP != null && Object.hasOwnProperty.call(m, "companyLP"))
                $root.Proto.CompanyLP.encode(m.companyLP, w.uint32(34).fork()).ldelim();
            if (m.companyLogo != null && Object.hasOwnProperty.call(m, "companyLogo"))
                $root.Proto.CompanyLogo.encode(m.companyLogo, w.uint32(42).fork()).ldelim();
            return w;
        };

        /**
         * Decodes an AppMessageList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.AppMessageList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.AppMessageList} AppMessageList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppMessageList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.AppMessageList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.AppMessage.decode(r, r.uint32()));
                    break;
                case 2:
                    m.companyUserIcon = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 3:
                    m.userIcon = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 4:
                    m.companyLP = $root.Proto.CompanyLP.decode(r, r.uint32());
                    break;
                case 5:
                    m.companyLogo = $root.Proto.CompanyLogo.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AppMessageList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.AppMessageList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.AppMessageList} AppMessageList
         */
        AppMessageList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.AppMessageList)
                return d;
            var m = new $root.Proto.AppMessageList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.AppMessageList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.AppMessageList.value: object expected");
                    m.value[i] = $root.Proto.AppMessage.fromObject(d.value[i]);
                }
            }
            if (d.companyUserIcon != null) {
                if (typeof d.companyUserIcon !== "object")
                    throw TypeError(".Proto.AppMessageList.companyUserIcon: object expected");
                m.companyUserIcon = $root.Proto.UploadFile.fromObject(d.companyUserIcon);
            }
            if (d.userIcon != null) {
                if (typeof d.userIcon !== "object")
                    throw TypeError(".Proto.AppMessageList.userIcon: object expected");
                m.userIcon = $root.Proto.UploadFile.fromObject(d.userIcon);
            }
            if (d.companyLP != null) {
                if (typeof d.companyLP !== "object")
                    throw TypeError(".Proto.AppMessageList.companyLP: object expected");
                m.companyLP = $root.Proto.CompanyLP.fromObject(d.companyLP);
            }
            if (d.companyLogo != null) {
                if (typeof d.companyLogo !== "object")
                    throw TypeError(".Proto.AppMessageList.companyLogo: object expected");
                m.companyLogo = $root.Proto.CompanyLogo.fromObject(d.companyLogo);
            }
            return m;
        };

        /**
         * Creates a plain object from an AppMessageList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.AppMessageList
         * @static
         * @param {Proto.AppMessageList} m AppMessageList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppMessageList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (o.defaults) {
                d.companyUserIcon = null;
                d.userIcon = null;
                d.companyLP = null;
                d.companyLogo = null;
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.AppMessage.toObject(m.value[j], o);
                }
            }
            if (m.companyUserIcon != null && m.hasOwnProperty("companyUserIcon")) {
                d.companyUserIcon = $root.Proto.UploadFile.toObject(m.companyUserIcon, o);
            }
            if (m.userIcon != null && m.hasOwnProperty("userIcon")) {
                d.userIcon = $root.Proto.UploadFile.toObject(m.userIcon, o);
            }
            if (m.companyLP != null && m.hasOwnProperty("companyLP")) {
                d.companyLP = $root.Proto.CompanyLP.toObject(m.companyLP, o);
            }
            if (m.companyLogo != null && m.hasOwnProperty("companyLogo")) {
                d.companyLogo = $root.Proto.CompanyLogo.toObject(m.companyLogo, o);
            }
            return d;
        };

        /**
         * Converts this AppMessageList to JSON.
         * @function toJSON
         * @memberof Proto.AppMessageList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppMessageList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppMessageList;
    })();

    Proto.ZoomMeetingSignature = (function() {

        /**
         * Properties of a ZoomMeetingSignature.
         * @memberof Proto
         * @interface IZoomMeetingSignature
         * @property {string|null} [apiKey] ZoomMeetingSignature apiKey
         * @property {string|null} [meetingId] ZoomMeetingSignature meetingId
         * @property {string|null} [signature] ZoomMeetingSignature signature
         * @property {string|null} [password] ZoomMeetingSignature password
         * @property {string|null} [nickname] ZoomMeetingSignature nickname
         * @property {string|null} [zak] ZoomMeetingSignature zak
         */

        /**
         * Constructs a new ZoomMeetingSignature.
         * @memberof Proto
         * @classdesc Represents a ZoomMeetingSignature.
         * @implements IZoomMeetingSignature
         * @constructor
         * @param {Proto.IZoomMeetingSignature=} [p] Properties to set
         */
        function ZoomMeetingSignature(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ZoomMeetingSignature apiKey.
         * @member {string} apiKey
         * @memberof Proto.ZoomMeetingSignature
         * @instance
         */
        ZoomMeetingSignature.prototype.apiKey = "";

        /**
         * ZoomMeetingSignature meetingId.
         * @member {string} meetingId
         * @memberof Proto.ZoomMeetingSignature
         * @instance
         */
        ZoomMeetingSignature.prototype.meetingId = "";

        /**
         * ZoomMeetingSignature signature.
         * @member {string} signature
         * @memberof Proto.ZoomMeetingSignature
         * @instance
         */
        ZoomMeetingSignature.prototype.signature = "";

        /**
         * ZoomMeetingSignature password.
         * @member {string} password
         * @memberof Proto.ZoomMeetingSignature
         * @instance
         */
        ZoomMeetingSignature.prototype.password = "";

        /**
         * ZoomMeetingSignature nickname.
         * @member {string} nickname
         * @memberof Proto.ZoomMeetingSignature
         * @instance
         */
        ZoomMeetingSignature.prototype.nickname = "";

        /**
         * ZoomMeetingSignature zak.
         * @member {string} zak
         * @memberof Proto.ZoomMeetingSignature
         * @instance
         */
        ZoomMeetingSignature.prototype.zak = "";

        /**
         * Creates a new ZoomMeetingSignature instance using the specified properties.
         * @function create
         * @memberof Proto.ZoomMeetingSignature
         * @static
         * @param {Proto.IZoomMeetingSignature=} [properties] Properties to set
         * @returns {Proto.ZoomMeetingSignature} ZoomMeetingSignature instance
         */
        ZoomMeetingSignature.create = function create(properties) {
            return new ZoomMeetingSignature(properties);
        };

        /**
         * Encodes the specified ZoomMeetingSignature message. Does not implicitly {@link Proto.ZoomMeetingSignature.verify|verify} messages.
         * @function encode
         * @memberof Proto.ZoomMeetingSignature
         * @static
         * @param {Proto.IZoomMeetingSignature} m ZoomMeetingSignature message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZoomMeetingSignature.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.apiKey != null && Object.hasOwnProperty.call(m, "apiKey"))
                w.uint32(10).string(m.apiKey);
            if (m.meetingId != null && Object.hasOwnProperty.call(m, "meetingId"))
                w.uint32(18).string(m.meetingId);
            if (m.signature != null && Object.hasOwnProperty.call(m, "signature"))
                w.uint32(26).string(m.signature);
            if (m.password != null && Object.hasOwnProperty.call(m, "password"))
                w.uint32(34).string(m.password);
            if (m.nickname != null && Object.hasOwnProperty.call(m, "nickname"))
                w.uint32(42).string(m.nickname);
            if (m.zak != null && Object.hasOwnProperty.call(m, "zak"))
                w.uint32(50).string(m.zak);
            return w;
        };

        /**
         * Decodes a ZoomMeetingSignature message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ZoomMeetingSignature
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ZoomMeetingSignature} ZoomMeetingSignature
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZoomMeetingSignature.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ZoomMeetingSignature();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.apiKey = r.string();
                    break;
                case 2:
                    m.meetingId = r.string();
                    break;
                case 3:
                    m.signature = r.string();
                    break;
                case 4:
                    m.password = r.string();
                    break;
                case 5:
                    m.nickname = r.string();
                    break;
                case 6:
                    m.zak = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ZoomMeetingSignature message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ZoomMeetingSignature
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ZoomMeetingSignature} ZoomMeetingSignature
         */
        ZoomMeetingSignature.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ZoomMeetingSignature)
                return d;
            var m = new $root.Proto.ZoomMeetingSignature();
            if (d.apiKey != null) {
                m.apiKey = String(d.apiKey);
            }
            if (d.meetingId != null) {
                m.meetingId = String(d.meetingId);
            }
            if (d.signature != null) {
                m.signature = String(d.signature);
            }
            if (d.password != null) {
                m.password = String(d.password);
            }
            if (d.nickname != null) {
                m.nickname = String(d.nickname);
            }
            if (d.zak != null) {
                m.zak = String(d.zak);
            }
            return m;
        };

        /**
         * Creates a plain object from a ZoomMeetingSignature message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ZoomMeetingSignature
         * @static
         * @param {Proto.ZoomMeetingSignature} m ZoomMeetingSignature
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ZoomMeetingSignature.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.apiKey = "";
                d.meetingId = "";
                d.signature = "";
                d.password = "";
                d.nickname = "";
                d.zak = "";
            }
            if (m.apiKey != null && m.hasOwnProperty("apiKey")) {
                d.apiKey = m.apiKey;
            }
            if (m.meetingId != null && m.hasOwnProperty("meetingId")) {
                d.meetingId = m.meetingId;
            }
            if (m.signature != null && m.hasOwnProperty("signature")) {
                d.signature = m.signature;
            }
            if (m.password != null && m.hasOwnProperty("password")) {
                d.password = m.password;
            }
            if (m.nickname != null && m.hasOwnProperty("nickname")) {
                d.nickname = m.nickname;
            }
            if (m.zak != null && m.hasOwnProperty("zak")) {
                d.zak = m.zak;
            }
            return d;
        };

        /**
         * Converts this ZoomMeetingSignature to JSON.
         * @function toJSON
         * @memberof Proto.ZoomMeetingSignature
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ZoomMeetingSignature.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ZoomMeetingSignature;
    })();

    Proto.CompanyReview = (function() {

        /**
         * Properties of a CompanyReview.
         * @memberof Proto
         * @interface ICompanyReview
         * @property {string|null} [uid] CompanyReview uid
         * @property {string|null} [companyUID] CompanyReview companyUID
         * @property {string|null} [userUID] CompanyReview userUID
         * @property {string|null} [planUID] CompanyReview planUID
         * @property {string|null} [text] CompanyReview text
         * @property {string|null} [thumbCount] CompanyReview thumbCount
         * @property {boolean|null} [thumb] CompanyReview thumb
         * @property {string|null} [planName] CompanyReview planName
         * @property {string|null} [userName] CompanyReview userName
         * @property {number|Long|null} [planStartDate] CompanyReview planStartDate
         * @property {Proto.IUploadFile|null} [userIcon] CompanyReview userIcon
         * @property {number|Long|null} [registerDate] CompanyReview registerDate
         * @property {number|Long|null} [modifyDate] CompanyReview modifyDate
         * @property {string|null} [parentUID] CompanyReview parentUID
         * @property {string|null} [likeCount] CompanyReview likeCount
         * @property {string|null} [commentCount] CompanyReview commentCount
         */

        /**
         * Constructs a new CompanyReview.
         * @memberof Proto
         * @classdesc Represents a CompanyReview.
         * @implements ICompanyReview
         * @constructor
         * @param {Proto.ICompanyReview=} [p] Properties to set
         */
        function CompanyReview(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyReview uid.
         * @member {string} uid
         * @memberof Proto.CompanyReview
         * @instance
         */
        CompanyReview.prototype.uid = "";

        /**
         * CompanyReview companyUID.
         * @member {string} companyUID
         * @memberof Proto.CompanyReview
         * @instance
         */
        CompanyReview.prototype.companyUID = "";

        /**
         * CompanyReview userUID.
         * @member {string} userUID
         * @memberof Proto.CompanyReview
         * @instance
         */
        CompanyReview.prototype.userUID = "";

        /**
         * CompanyReview planUID.
         * @member {string} planUID
         * @memberof Proto.CompanyReview
         * @instance
         */
        CompanyReview.prototype.planUID = "";

        /**
         * CompanyReview text.
         * @member {string} text
         * @memberof Proto.CompanyReview
         * @instance
         */
        CompanyReview.prototype.text = "";

        /**
         * CompanyReview thumbCount.
         * @member {string} thumbCount
         * @memberof Proto.CompanyReview
         * @instance
         */
        CompanyReview.prototype.thumbCount = "";

        /**
         * CompanyReview thumb.
         * @member {boolean} thumb
         * @memberof Proto.CompanyReview
         * @instance
         */
        CompanyReview.prototype.thumb = false;

        /**
         * CompanyReview planName.
         * @member {string} planName
         * @memberof Proto.CompanyReview
         * @instance
         */
        CompanyReview.prototype.planName = "";

        /**
         * CompanyReview userName.
         * @member {string} userName
         * @memberof Proto.CompanyReview
         * @instance
         */
        CompanyReview.prototype.userName = "";

        /**
         * CompanyReview planStartDate.
         * @member {number|Long} planStartDate
         * @memberof Proto.CompanyReview
         * @instance
         */
        CompanyReview.prototype.planStartDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyReview userIcon.
         * @member {Proto.IUploadFile|null|undefined} userIcon
         * @memberof Proto.CompanyReview
         * @instance
         */
        CompanyReview.prototype.userIcon = null;

        /**
         * CompanyReview registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CompanyReview
         * @instance
         */
        CompanyReview.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyReview modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CompanyReview
         * @instance
         */
        CompanyReview.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyReview parentUID.
         * @member {string} parentUID
         * @memberof Proto.CompanyReview
         * @instance
         */
        CompanyReview.prototype.parentUID = "";

        /**
         * CompanyReview likeCount.
         * @member {string} likeCount
         * @memberof Proto.CompanyReview
         * @instance
         */
        CompanyReview.prototype.likeCount = "";

        /**
         * CompanyReview commentCount.
         * @member {string} commentCount
         * @memberof Proto.CompanyReview
         * @instance
         */
        CompanyReview.prototype.commentCount = "";

        /**
         * Creates a new CompanyReview instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyReview
         * @static
         * @param {Proto.ICompanyReview=} [properties] Properties to set
         * @returns {Proto.CompanyReview} CompanyReview instance
         */
        CompanyReview.create = function create(properties) {
            return new CompanyReview(properties);
        };

        /**
         * Encodes the specified CompanyReview message. Does not implicitly {@link Proto.CompanyReview.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyReview
         * @static
         * @param {Proto.ICompanyReview} m CompanyReview message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyReview.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(18).string(m.companyUID);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(26).string(m.userUID);
            if (m.planUID != null && Object.hasOwnProperty.call(m, "planUID"))
                w.uint32(34).string(m.planUID);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(42).string(m.text);
            if (m.thumbCount != null && Object.hasOwnProperty.call(m, "thumbCount"))
                w.uint32(50).string(m.thumbCount);
            if (m.thumb != null && Object.hasOwnProperty.call(m, "thumb"))
                w.uint32(56).bool(m.thumb);
            if (m.planName != null && Object.hasOwnProperty.call(m, "planName"))
                w.uint32(66).string(m.planName);
            if (m.userName != null && Object.hasOwnProperty.call(m, "userName"))
                w.uint32(74).string(m.userName);
            if (m.planStartDate != null && Object.hasOwnProperty.call(m, "planStartDate"))
                w.uint32(80).int64(m.planStartDate);
            if (m.userIcon != null && Object.hasOwnProperty.call(m, "userIcon"))
                $root.Proto.UploadFile.encode(m.userIcon, w.uint32(90).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(96).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(104).int64(m.modifyDate);
            if (m.parentUID != null && Object.hasOwnProperty.call(m, "parentUID"))
                w.uint32(114).string(m.parentUID);
            if (m.likeCount != null && Object.hasOwnProperty.call(m, "likeCount"))
                w.uint32(122).string(m.likeCount);
            if (m.commentCount != null && Object.hasOwnProperty.call(m, "commentCount"))
                w.uint32(130).string(m.commentCount);
            return w;
        };

        /**
         * Decodes a CompanyReview message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyReview
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyReview} CompanyReview
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyReview.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyReview();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.companyUID = r.string();
                    break;
                case 3:
                    m.userUID = r.string();
                    break;
                case 4:
                    m.planUID = r.string();
                    break;
                case 5:
                    m.text = r.string();
                    break;
                case 6:
                    m.thumbCount = r.string();
                    break;
                case 7:
                    m.thumb = r.bool();
                    break;
                case 8:
                    m.planName = r.string();
                    break;
                case 9:
                    m.userName = r.string();
                    break;
                case 10:
                    m.planStartDate = r.int64();
                    break;
                case 11:
                    m.userIcon = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 12:
                    m.registerDate = r.int64();
                    break;
                case 13:
                    m.modifyDate = r.int64();
                    break;
                case 14:
                    m.parentUID = r.string();
                    break;
                case 15:
                    m.likeCount = r.string();
                    break;
                case 16:
                    m.commentCount = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyReview message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyReview
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyReview} CompanyReview
         */
        CompanyReview.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyReview)
                return d;
            var m = new $root.Proto.CompanyReview();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.planUID != null) {
                m.planUID = String(d.planUID);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.thumbCount != null) {
                m.thumbCount = String(d.thumbCount);
            }
            if (d.thumb != null) {
                m.thumb = Boolean(d.thumb);
            }
            if (d.planName != null) {
                m.planName = String(d.planName);
            }
            if (d.userName != null) {
                m.userName = String(d.userName);
            }
            if (d.planStartDate != null) {
                if ($util.Long)
                    (m.planStartDate = $util.Long.fromValue(d.planStartDate)).unsigned = false;
                else if (typeof d.planStartDate === "string")
                    m.planStartDate = parseInt(d.planStartDate, 10);
                else if (typeof d.planStartDate === "number")
                    m.planStartDate = d.planStartDate;
                else if (typeof d.planStartDate === "object")
                    m.planStartDate = new $util.LongBits(d.planStartDate.low >>> 0, d.planStartDate.high >>> 0).toNumber();
            }
            if (d.userIcon != null) {
                if (typeof d.userIcon !== "object")
                    throw TypeError(".Proto.CompanyReview.userIcon: object expected");
                m.userIcon = $root.Proto.UploadFile.fromObject(d.userIcon);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.parentUID != null) {
                m.parentUID = String(d.parentUID);
            }
            if (d.likeCount != null) {
                m.likeCount = String(d.likeCount);
            }
            if (d.commentCount != null) {
                m.commentCount = String(d.commentCount);
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyReview message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyReview
         * @static
         * @param {Proto.CompanyReview} m CompanyReview
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyReview.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.companyUID = "";
                d.userUID = "";
                d.planUID = "";
                d.text = "";
                d.thumbCount = "";
                d.thumb = false;
                d.planName = "";
                d.userName = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.planStartDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.planStartDate = o.longs === String ? "0" : 0;
                d.userIcon = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.parentUID = "";
                d.likeCount = "";
                d.commentCount = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.planUID != null && m.hasOwnProperty("planUID")) {
                d.planUID = m.planUID;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.thumbCount != null && m.hasOwnProperty("thumbCount")) {
                d.thumbCount = m.thumbCount;
            }
            if (m.thumb != null && m.hasOwnProperty("thumb")) {
                d.thumb = m.thumb;
            }
            if (m.planName != null && m.hasOwnProperty("planName")) {
                d.planName = m.planName;
            }
            if (m.userName != null && m.hasOwnProperty("userName")) {
                d.userName = m.userName;
            }
            if (m.planStartDate != null && m.hasOwnProperty("planStartDate")) {
                if (typeof m.planStartDate === "number")
                    d.planStartDate = o.longs === String ? String(m.planStartDate) : m.planStartDate;
                else
                    d.planStartDate = o.longs === String ? $util.Long.prototype.toString.call(m.planStartDate) : o.longs === Number ? new $util.LongBits(m.planStartDate.low >>> 0, m.planStartDate.high >>> 0).toNumber() : m.planStartDate;
            }
            if (m.userIcon != null && m.hasOwnProperty("userIcon")) {
                d.userIcon = $root.Proto.UploadFile.toObject(m.userIcon, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.parentUID != null && m.hasOwnProperty("parentUID")) {
                d.parentUID = m.parentUID;
            }
            if (m.likeCount != null && m.hasOwnProperty("likeCount")) {
                d.likeCount = m.likeCount;
            }
            if (m.commentCount != null && m.hasOwnProperty("commentCount")) {
                d.commentCount = m.commentCount;
            }
            return d;
        };

        /**
         * Converts this CompanyReview to JSON.
         * @function toJSON
         * @memberof Proto.CompanyReview
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyReview.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyReview;
    })();

    Proto.CompanyReviewList = (function() {

        /**
         * Properties of a CompanyReviewList.
         * @memberof Proto
         * @interface ICompanyReviewList
         * @property {Array.<Proto.ICompanyReview>|null} [value] CompanyReviewList value
         */

        /**
         * Constructs a new CompanyReviewList.
         * @memberof Proto
         * @classdesc Represents a CompanyReviewList.
         * @implements ICompanyReviewList
         * @constructor
         * @param {Proto.ICompanyReviewList=} [p] Properties to set
         */
        function CompanyReviewList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyReviewList value.
         * @member {Array.<Proto.ICompanyReview>} value
         * @memberof Proto.CompanyReviewList
         * @instance
         */
        CompanyReviewList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CompanyReviewList instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyReviewList
         * @static
         * @param {Proto.ICompanyReviewList=} [properties] Properties to set
         * @returns {Proto.CompanyReviewList} CompanyReviewList instance
         */
        CompanyReviewList.create = function create(properties) {
            return new CompanyReviewList(properties);
        };

        /**
         * Encodes the specified CompanyReviewList message. Does not implicitly {@link Proto.CompanyReviewList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyReviewList
         * @static
         * @param {Proto.ICompanyReviewList} m CompanyReviewList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyReviewList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CompanyReview.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CompanyReviewList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyReviewList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyReviewList} CompanyReviewList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyReviewList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyReviewList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CompanyReview.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyReviewList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyReviewList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyReviewList} CompanyReviewList
         */
        CompanyReviewList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyReviewList)
                return d;
            var m = new $root.Proto.CompanyReviewList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CompanyReviewList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CompanyReviewList.value: object expected");
                    m.value[i] = $root.Proto.CompanyReview.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyReviewList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyReviewList
         * @static
         * @param {Proto.CompanyReviewList} m CompanyReviewList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyReviewList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CompanyReview.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CompanyReviewList to JSON.
         * @function toJSON
         * @memberof Proto.CompanyReviewList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyReviewList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyReviewList;
    })();

    Proto.CompanyNotificationMap = (function() {

        /**
         * Properties of a CompanyNotificationMap.
         * @memberof Proto
         * @interface ICompanyNotificationMap
         * @property {string|null} [notificationUID] CompanyNotificationMap notificationUID
         * @property {number|null} [type] CompanyNotificationMap type
         * @property {string|null} [typeUID] CompanyNotificationMap typeUID
         * @property {string|null} [typeGuid] CompanyNotificationMap typeGuid
         * @property {string|null} [typeName] CompanyNotificationMap typeName
         * @property {string|null} [userUID] CompanyNotificationMap userUID
         * @property {string|null} [userName] CompanyNotificationMap userName
         * @property {number|Long|null} [registerDate] CompanyNotificationMap registerDate
         * @property {number|Long|null} [modifyDate] CompanyNotificationMap modifyDate
         */

        /**
         * Constructs a new CompanyNotificationMap.
         * @memberof Proto
         * @classdesc Represents a CompanyNotificationMap.
         * @implements ICompanyNotificationMap
         * @constructor
         * @param {Proto.ICompanyNotificationMap=} [p] Properties to set
         */
        function CompanyNotificationMap(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyNotificationMap notificationUID.
         * @member {string} notificationUID
         * @memberof Proto.CompanyNotificationMap
         * @instance
         */
        CompanyNotificationMap.prototype.notificationUID = "";

        /**
         * CompanyNotificationMap type.
         * @member {number} type
         * @memberof Proto.CompanyNotificationMap
         * @instance
         */
        CompanyNotificationMap.prototype.type = 0;

        /**
         * CompanyNotificationMap typeUID.
         * @member {string} typeUID
         * @memberof Proto.CompanyNotificationMap
         * @instance
         */
        CompanyNotificationMap.prototype.typeUID = "";

        /**
         * CompanyNotificationMap typeGuid.
         * @member {string} typeGuid
         * @memberof Proto.CompanyNotificationMap
         * @instance
         */
        CompanyNotificationMap.prototype.typeGuid = "";

        /**
         * CompanyNotificationMap typeName.
         * @member {string} typeName
         * @memberof Proto.CompanyNotificationMap
         * @instance
         */
        CompanyNotificationMap.prototype.typeName = "";

        /**
         * CompanyNotificationMap userUID.
         * @member {string} userUID
         * @memberof Proto.CompanyNotificationMap
         * @instance
         */
        CompanyNotificationMap.prototype.userUID = "";

        /**
         * CompanyNotificationMap userName.
         * @member {string} userName
         * @memberof Proto.CompanyNotificationMap
         * @instance
         */
        CompanyNotificationMap.prototype.userName = "";

        /**
         * CompanyNotificationMap registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CompanyNotificationMap
         * @instance
         */
        CompanyNotificationMap.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyNotificationMap modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CompanyNotificationMap
         * @instance
         */
        CompanyNotificationMap.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new CompanyNotificationMap instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyNotificationMap
         * @static
         * @param {Proto.ICompanyNotificationMap=} [properties] Properties to set
         * @returns {Proto.CompanyNotificationMap} CompanyNotificationMap instance
         */
        CompanyNotificationMap.create = function create(properties) {
            return new CompanyNotificationMap(properties);
        };

        /**
         * Encodes the specified CompanyNotificationMap message. Does not implicitly {@link Proto.CompanyNotificationMap.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyNotificationMap
         * @static
         * @param {Proto.ICompanyNotificationMap} m CompanyNotificationMap message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyNotificationMap.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.notificationUID != null && Object.hasOwnProperty.call(m, "notificationUID"))
                w.uint32(10).string(m.notificationUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.typeUID != null && Object.hasOwnProperty.call(m, "typeUID"))
                w.uint32(26).string(m.typeUID);
            if (m.typeGuid != null && Object.hasOwnProperty.call(m, "typeGuid"))
                w.uint32(34).string(m.typeGuid);
            if (m.typeName != null && Object.hasOwnProperty.call(m, "typeName"))
                w.uint32(42).string(m.typeName);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(50).string(m.userUID);
            if (m.userName != null && Object.hasOwnProperty.call(m, "userName"))
                w.uint32(58).string(m.userName);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a CompanyNotificationMap message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyNotificationMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyNotificationMap} CompanyNotificationMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyNotificationMap.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyNotificationMap();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.notificationUID = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.typeUID = r.string();
                    break;
                case 4:
                    m.typeGuid = r.string();
                    break;
                case 5:
                    m.typeName = r.string();
                    break;
                case 6:
                    m.userUID = r.string();
                    break;
                case 7:
                    m.userName = r.string();
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyNotificationMap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyNotificationMap
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyNotificationMap} CompanyNotificationMap
         */
        CompanyNotificationMap.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyNotificationMap)
                return d;
            var m = new $root.Proto.CompanyNotificationMap();
            if (d.notificationUID != null) {
                m.notificationUID = String(d.notificationUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.typeUID != null) {
                m.typeUID = String(d.typeUID);
            }
            if (d.typeGuid != null) {
                m.typeGuid = String(d.typeGuid);
            }
            if (d.typeName != null) {
                m.typeName = String(d.typeName);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.userName != null) {
                m.userName = String(d.userName);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyNotificationMap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyNotificationMap
         * @static
         * @param {Proto.CompanyNotificationMap} m CompanyNotificationMap
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyNotificationMap.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.notificationUID = "";
                d.type = 0;
                d.typeUID = "";
                d.typeGuid = "";
                d.typeName = "";
                d.userUID = "";
                d.userName = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.notificationUID != null && m.hasOwnProperty("notificationUID")) {
                d.notificationUID = m.notificationUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.typeUID != null && m.hasOwnProperty("typeUID")) {
                d.typeUID = m.typeUID;
            }
            if (m.typeGuid != null && m.hasOwnProperty("typeGuid")) {
                d.typeGuid = m.typeGuid;
            }
            if (m.typeName != null && m.hasOwnProperty("typeName")) {
                d.typeName = m.typeName;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.userName != null && m.hasOwnProperty("userName")) {
                d.userName = m.userName;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this CompanyNotificationMap to JSON.
         * @function toJSON
         * @memberof Proto.CompanyNotificationMap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyNotificationMap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyNotificationMap;
    })();

    Proto.CompanyNotification = (function() {

        /**
         * Properties of a CompanyNotification.
         * @memberof Proto
         * @interface ICompanyNotification
         * @property {string|null} [uid] CompanyNotification uid
         * @property {string|null} [companyUID] CompanyNotification companyUID
         * @property {string|null} [text] CompanyNotification text
         * @property {boolean|null} [all] CompanyNotification all
         * @property {Array.<Proto.ICompanyNotificationMap>|null} [mapList] CompanyNotification mapList
         * @property {number|Long|null} [registerDate] CompanyNotification registerDate
         * @property {number|Long|null} [modifyDate] CompanyNotification modifyDate
         */

        /**
         * Constructs a new CompanyNotification.
         * @memberof Proto
         * @classdesc Represents a CompanyNotification.
         * @implements ICompanyNotification
         * @constructor
         * @param {Proto.ICompanyNotification=} [p] Properties to set
         */
        function CompanyNotification(p) {
            this.mapList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyNotification uid.
         * @member {string} uid
         * @memberof Proto.CompanyNotification
         * @instance
         */
        CompanyNotification.prototype.uid = "";

        /**
         * CompanyNotification companyUID.
         * @member {string} companyUID
         * @memberof Proto.CompanyNotification
         * @instance
         */
        CompanyNotification.prototype.companyUID = "";

        /**
         * CompanyNotification text.
         * @member {string} text
         * @memberof Proto.CompanyNotification
         * @instance
         */
        CompanyNotification.prototype.text = "";

        /**
         * CompanyNotification all.
         * @member {boolean} all
         * @memberof Proto.CompanyNotification
         * @instance
         */
        CompanyNotification.prototype.all = false;

        /**
         * CompanyNotification mapList.
         * @member {Array.<Proto.ICompanyNotificationMap>} mapList
         * @memberof Proto.CompanyNotification
         * @instance
         */
        CompanyNotification.prototype.mapList = $util.emptyArray;

        /**
         * CompanyNotification registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CompanyNotification
         * @instance
         */
        CompanyNotification.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyNotification modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CompanyNotification
         * @instance
         */
        CompanyNotification.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new CompanyNotification instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyNotification
         * @static
         * @param {Proto.ICompanyNotification=} [properties] Properties to set
         * @returns {Proto.CompanyNotification} CompanyNotification instance
         */
        CompanyNotification.create = function create(properties) {
            return new CompanyNotification(properties);
        };

        /**
         * Encodes the specified CompanyNotification message. Does not implicitly {@link Proto.CompanyNotification.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyNotification
         * @static
         * @param {Proto.ICompanyNotification} m CompanyNotification message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyNotification.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(18).string(m.companyUID);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.all != null && Object.hasOwnProperty.call(m, "all"))
                w.uint32(32).bool(m.all);
            if (m.mapList != null && m.mapList.length) {
                for (var i = 0; i < m.mapList.length; ++i)
                    $root.Proto.CompanyNotificationMap.encode(m.mapList[i], w.uint32(42).fork()).ldelim();
            }
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a CompanyNotification message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyNotification
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyNotification} CompanyNotification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyNotification.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyNotification();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.companyUID = r.string();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.all = r.bool();
                    break;
                case 5:
                    if (!(m.mapList && m.mapList.length))
                        m.mapList = [];
                    m.mapList.push($root.Proto.CompanyNotificationMap.decode(r, r.uint32()));
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyNotification message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyNotification
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyNotification} CompanyNotification
         */
        CompanyNotification.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyNotification)
                return d;
            var m = new $root.Proto.CompanyNotification();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.all != null) {
                m.all = Boolean(d.all);
            }
            if (d.mapList) {
                if (!Array.isArray(d.mapList))
                    throw TypeError(".Proto.CompanyNotification.mapList: array expected");
                m.mapList = [];
                for (var i = 0; i < d.mapList.length; ++i) {
                    if (typeof d.mapList[i] !== "object")
                        throw TypeError(".Proto.CompanyNotification.mapList: object expected");
                    m.mapList[i] = $root.Proto.CompanyNotificationMap.fromObject(d.mapList[i]);
                }
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyNotification message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyNotification
         * @static
         * @param {Proto.CompanyNotification} m CompanyNotification
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyNotification.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.mapList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.companyUID = "";
                d.text = "";
                d.all = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.all != null && m.hasOwnProperty("all")) {
                d.all = m.all;
            }
            if (m.mapList && m.mapList.length) {
                d.mapList = [];
                for (var j = 0; j < m.mapList.length; ++j) {
                    d.mapList[j] = $root.Proto.CompanyNotificationMap.toObject(m.mapList[j], o);
                }
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this CompanyNotification to JSON.
         * @function toJSON
         * @memberof Proto.CompanyNotification
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyNotification.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyNotification;
    })();

    Proto.CompanyNotificationList = (function() {

        /**
         * Properties of a CompanyNotificationList.
         * @memberof Proto
         * @interface ICompanyNotificationList
         * @property {Array.<Proto.ICompanyNotification>|null} [value] CompanyNotificationList value
         */

        /**
         * Constructs a new CompanyNotificationList.
         * @memberof Proto
         * @classdesc Represents a CompanyNotificationList.
         * @implements ICompanyNotificationList
         * @constructor
         * @param {Proto.ICompanyNotificationList=} [p] Properties to set
         */
        function CompanyNotificationList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyNotificationList value.
         * @member {Array.<Proto.ICompanyNotification>} value
         * @memberof Proto.CompanyNotificationList
         * @instance
         */
        CompanyNotificationList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CompanyNotificationList instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyNotificationList
         * @static
         * @param {Proto.ICompanyNotificationList=} [properties] Properties to set
         * @returns {Proto.CompanyNotificationList} CompanyNotificationList instance
         */
        CompanyNotificationList.create = function create(properties) {
            return new CompanyNotificationList(properties);
        };

        /**
         * Encodes the specified CompanyNotificationList message. Does not implicitly {@link Proto.CompanyNotificationList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyNotificationList
         * @static
         * @param {Proto.ICompanyNotificationList} m CompanyNotificationList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyNotificationList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CompanyNotification.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CompanyNotificationList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyNotificationList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyNotificationList} CompanyNotificationList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyNotificationList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyNotificationList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CompanyNotification.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyNotificationList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyNotificationList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyNotificationList} CompanyNotificationList
         */
        CompanyNotificationList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyNotificationList)
                return d;
            var m = new $root.Proto.CompanyNotificationList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CompanyNotificationList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CompanyNotificationList.value: object expected");
                    m.value[i] = $root.Proto.CompanyNotification.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyNotificationList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyNotificationList
         * @static
         * @param {Proto.CompanyNotificationList} m CompanyNotificationList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyNotificationList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CompanyNotification.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CompanyNotificationList to JSON.
         * @function toJSON
         * @memberof Proto.CompanyNotificationList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyNotificationList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyNotificationList;
    })();

    Proto.ResultCompanyNotificationUserListMap = (function() {

        /**
         * Properties of a ResultCompanyNotificationUserListMap.
         * @memberof Proto
         * @interface IResultCompanyNotificationUserListMap
         * @property {number|null} [type] ResultCompanyNotificationUserListMap type
         * @property {string|null} [typeUID] ResultCompanyNotificationUserListMap typeUID
         * @property {string|null} [typeGuid] ResultCompanyNotificationUserListMap typeGuid
         * @property {string|null} [typeName] ResultCompanyNotificationUserListMap typeName
         * @property {string|null} [count] ResultCompanyNotificationUserListMap count
         */

        /**
         * Constructs a new ResultCompanyNotificationUserListMap.
         * @memberof Proto
         * @classdesc Represents a ResultCompanyNotificationUserListMap.
         * @implements IResultCompanyNotificationUserListMap
         * @constructor
         * @param {Proto.IResultCompanyNotificationUserListMap=} [p] Properties to set
         */
        function ResultCompanyNotificationUserListMap(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ResultCompanyNotificationUserListMap type.
         * @member {number} type
         * @memberof Proto.ResultCompanyNotificationUserListMap
         * @instance
         */
        ResultCompanyNotificationUserListMap.prototype.type = 0;

        /**
         * ResultCompanyNotificationUserListMap typeUID.
         * @member {string} typeUID
         * @memberof Proto.ResultCompanyNotificationUserListMap
         * @instance
         */
        ResultCompanyNotificationUserListMap.prototype.typeUID = "";

        /**
         * ResultCompanyNotificationUserListMap typeGuid.
         * @member {string} typeGuid
         * @memberof Proto.ResultCompanyNotificationUserListMap
         * @instance
         */
        ResultCompanyNotificationUserListMap.prototype.typeGuid = "";

        /**
         * ResultCompanyNotificationUserListMap typeName.
         * @member {string} typeName
         * @memberof Proto.ResultCompanyNotificationUserListMap
         * @instance
         */
        ResultCompanyNotificationUserListMap.prototype.typeName = "";

        /**
         * ResultCompanyNotificationUserListMap count.
         * @member {string} count
         * @memberof Proto.ResultCompanyNotificationUserListMap
         * @instance
         */
        ResultCompanyNotificationUserListMap.prototype.count = "";

        /**
         * Creates a new ResultCompanyNotificationUserListMap instance using the specified properties.
         * @function create
         * @memberof Proto.ResultCompanyNotificationUserListMap
         * @static
         * @param {Proto.IResultCompanyNotificationUserListMap=} [properties] Properties to set
         * @returns {Proto.ResultCompanyNotificationUserListMap} ResultCompanyNotificationUserListMap instance
         */
        ResultCompanyNotificationUserListMap.create = function create(properties) {
            return new ResultCompanyNotificationUserListMap(properties);
        };

        /**
         * Encodes the specified ResultCompanyNotificationUserListMap message. Does not implicitly {@link Proto.ResultCompanyNotificationUserListMap.verify|verify} messages.
         * @function encode
         * @memberof Proto.ResultCompanyNotificationUserListMap
         * @static
         * @param {Proto.IResultCompanyNotificationUserListMap} m ResultCompanyNotificationUserListMap message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResultCompanyNotificationUserListMap.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(8).int32(m.type);
            if (m.typeUID != null && Object.hasOwnProperty.call(m, "typeUID"))
                w.uint32(18).string(m.typeUID);
            if (m.typeGuid != null && Object.hasOwnProperty.call(m, "typeGuid"))
                w.uint32(26).string(m.typeGuid);
            if (m.typeName != null && Object.hasOwnProperty.call(m, "typeName"))
                w.uint32(34).string(m.typeName);
            if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                w.uint32(42).string(m.count);
            return w;
        };

        /**
         * Decodes a ResultCompanyNotificationUserListMap message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ResultCompanyNotificationUserListMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ResultCompanyNotificationUserListMap} ResultCompanyNotificationUserListMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResultCompanyNotificationUserListMap.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ResultCompanyNotificationUserListMap();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.type = r.int32();
                    break;
                case 2:
                    m.typeUID = r.string();
                    break;
                case 3:
                    m.typeGuid = r.string();
                    break;
                case 4:
                    m.typeName = r.string();
                    break;
                case 5:
                    m.count = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ResultCompanyNotificationUserListMap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ResultCompanyNotificationUserListMap
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ResultCompanyNotificationUserListMap} ResultCompanyNotificationUserListMap
         */
        ResultCompanyNotificationUserListMap.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ResultCompanyNotificationUserListMap)
                return d;
            var m = new $root.Proto.ResultCompanyNotificationUserListMap();
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.typeUID != null) {
                m.typeUID = String(d.typeUID);
            }
            if (d.typeGuid != null) {
                m.typeGuid = String(d.typeGuid);
            }
            if (d.typeName != null) {
                m.typeName = String(d.typeName);
            }
            if (d.count != null) {
                m.count = String(d.count);
            }
            return m;
        };

        /**
         * Creates a plain object from a ResultCompanyNotificationUserListMap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ResultCompanyNotificationUserListMap
         * @static
         * @param {Proto.ResultCompanyNotificationUserListMap} m ResultCompanyNotificationUserListMap
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResultCompanyNotificationUserListMap.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.type = 0;
                d.typeUID = "";
                d.typeGuid = "";
                d.typeName = "";
                d.count = "";
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.typeUID != null && m.hasOwnProperty("typeUID")) {
                d.typeUID = m.typeUID;
            }
            if (m.typeGuid != null && m.hasOwnProperty("typeGuid")) {
                d.typeGuid = m.typeGuid;
            }
            if (m.typeName != null && m.hasOwnProperty("typeName")) {
                d.typeName = m.typeName;
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                d.count = m.count;
            }
            return d;
        };

        /**
         * Converts this ResultCompanyNotificationUserListMap to JSON.
         * @function toJSON
         * @memberof Proto.ResultCompanyNotificationUserListMap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResultCompanyNotificationUserListMap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResultCompanyNotificationUserListMap;
    })();

    Proto.ResultCompanyNotificationUserList = (function() {

        /**
         * Properties of a ResultCompanyNotificationUserList.
         * @memberof Proto
         * @interface IResultCompanyNotificationUserList
         * @property {string|null} [allCount] ResultCompanyNotificationUserList allCount
         * @property {Array.<Proto.IResultCompanyNotificationUserListMap>|null} [list] ResultCompanyNotificationUserList list
         */

        /**
         * Constructs a new ResultCompanyNotificationUserList.
         * @memberof Proto
         * @classdesc Represents a ResultCompanyNotificationUserList.
         * @implements IResultCompanyNotificationUserList
         * @constructor
         * @param {Proto.IResultCompanyNotificationUserList=} [p] Properties to set
         */
        function ResultCompanyNotificationUserList(p) {
            this.list = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ResultCompanyNotificationUserList allCount.
         * @member {string} allCount
         * @memberof Proto.ResultCompanyNotificationUserList
         * @instance
         */
        ResultCompanyNotificationUserList.prototype.allCount = "";

        /**
         * ResultCompanyNotificationUserList list.
         * @member {Array.<Proto.IResultCompanyNotificationUserListMap>} list
         * @memberof Proto.ResultCompanyNotificationUserList
         * @instance
         */
        ResultCompanyNotificationUserList.prototype.list = $util.emptyArray;

        /**
         * Creates a new ResultCompanyNotificationUserList instance using the specified properties.
         * @function create
         * @memberof Proto.ResultCompanyNotificationUserList
         * @static
         * @param {Proto.IResultCompanyNotificationUserList=} [properties] Properties to set
         * @returns {Proto.ResultCompanyNotificationUserList} ResultCompanyNotificationUserList instance
         */
        ResultCompanyNotificationUserList.create = function create(properties) {
            return new ResultCompanyNotificationUserList(properties);
        };

        /**
         * Encodes the specified ResultCompanyNotificationUserList message. Does not implicitly {@link Proto.ResultCompanyNotificationUserList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ResultCompanyNotificationUserList
         * @static
         * @param {Proto.IResultCompanyNotificationUserList} m ResultCompanyNotificationUserList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResultCompanyNotificationUserList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.allCount != null && Object.hasOwnProperty.call(m, "allCount"))
                w.uint32(10).string(m.allCount);
            if (m.list != null && m.list.length) {
                for (var i = 0; i < m.list.length; ++i)
                    $root.Proto.ResultCompanyNotificationUserListMap.encode(m.list[i], w.uint32(18).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ResultCompanyNotificationUserList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ResultCompanyNotificationUserList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ResultCompanyNotificationUserList} ResultCompanyNotificationUserList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResultCompanyNotificationUserList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ResultCompanyNotificationUserList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.allCount = r.string();
                    break;
                case 2:
                    if (!(m.list && m.list.length))
                        m.list = [];
                    m.list.push($root.Proto.ResultCompanyNotificationUserListMap.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ResultCompanyNotificationUserList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ResultCompanyNotificationUserList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ResultCompanyNotificationUserList} ResultCompanyNotificationUserList
         */
        ResultCompanyNotificationUserList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ResultCompanyNotificationUserList)
                return d;
            var m = new $root.Proto.ResultCompanyNotificationUserList();
            if (d.allCount != null) {
                m.allCount = String(d.allCount);
            }
            if (d.list) {
                if (!Array.isArray(d.list))
                    throw TypeError(".Proto.ResultCompanyNotificationUserList.list: array expected");
                m.list = [];
                for (var i = 0; i < d.list.length; ++i) {
                    if (typeof d.list[i] !== "object")
                        throw TypeError(".Proto.ResultCompanyNotificationUserList.list: object expected");
                    m.list[i] = $root.Proto.ResultCompanyNotificationUserListMap.fromObject(d.list[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ResultCompanyNotificationUserList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ResultCompanyNotificationUserList
         * @static
         * @param {Proto.ResultCompanyNotificationUserList} m ResultCompanyNotificationUserList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResultCompanyNotificationUserList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.list = [];
            }
            if (o.defaults) {
                d.allCount = "";
            }
            if (m.allCount != null && m.hasOwnProperty("allCount")) {
                d.allCount = m.allCount;
            }
            if (m.list && m.list.length) {
                d.list = [];
                for (var j = 0; j < m.list.length; ++j) {
                    d.list[j] = $root.Proto.ResultCompanyNotificationUserListMap.toObject(m.list[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ResultCompanyNotificationUserList to JSON.
         * @function toJSON
         * @memberof Proto.ResultCompanyNotificationUserList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResultCompanyNotificationUserList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResultCompanyNotificationUserList;
    })();

    Proto.DRMKey = (function() {

        /**
         * Properties of a DRMKey.
         * @memberof Proto
         * @interface IDRMKey
         * @property {Uint8Array|null} [key] DRMKey key
         * @property {Uint8Array|null} [al] DRMKey al
         */

        /**
         * Constructs a new DRMKey.
         * @memberof Proto
         * @classdesc Represents a DRMKey.
         * @implements IDRMKey
         * @constructor
         * @param {Proto.IDRMKey=} [p] Properties to set
         */
        function DRMKey(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DRMKey key.
         * @member {Uint8Array} key
         * @memberof Proto.DRMKey
         * @instance
         */
        DRMKey.prototype.key = $util.newBuffer([]);

        /**
         * DRMKey al.
         * @member {Uint8Array} al
         * @memberof Proto.DRMKey
         * @instance
         */
        DRMKey.prototype.al = $util.newBuffer([]);

        /**
         * Creates a new DRMKey instance using the specified properties.
         * @function create
         * @memberof Proto.DRMKey
         * @static
         * @param {Proto.IDRMKey=} [properties] Properties to set
         * @returns {Proto.DRMKey} DRMKey instance
         */
        DRMKey.create = function create(properties) {
            return new DRMKey(properties);
        };

        /**
         * Encodes the specified DRMKey message. Does not implicitly {@link Proto.DRMKey.verify|verify} messages.
         * @function encode
         * @memberof Proto.DRMKey
         * @static
         * @param {Proto.IDRMKey} m DRMKey message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DRMKey.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.key != null && Object.hasOwnProperty.call(m, "key"))
                w.uint32(10).bytes(m.key);
            if (m.al != null && Object.hasOwnProperty.call(m, "al"))
                w.uint32(18).bytes(m.al);
            return w;
        };

        /**
         * Decodes a DRMKey message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.DRMKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.DRMKey} DRMKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DRMKey.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.DRMKey();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.key = r.bytes();
                    break;
                case 2:
                    m.al = r.bytes();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a DRMKey message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.DRMKey
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.DRMKey} DRMKey
         */
        DRMKey.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.DRMKey)
                return d;
            var m = new $root.Proto.DRMKey();
            if (d.key != null) {
                if (typeof d.key === "string")
                    $util.base64.decode(d.key, m.key = $util.newBuffer($util.base64.length(d.key)), 0);
                else if (d.key.length)
                    m.key = d.key;
            }
            if (d.al != null) {
                if (typeof d.al === "string")
                    $util.base64.decode(d.al, m.al = $util.newBuffer($util.base64.length(d.al)), 0);
                else if (d.al.length)
                    m.al = d.al;
            }
            return m;
        };

        /**
         * Creates a plain object from a DRMKey message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.DRMKey
         * @static
         * @param {Proto.DRMKey} m DRMKey
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DRMKey.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if (o.bytes === String)
                    d.key = "";
                else {
                    d.key = [];
                    if (o.bytes !== Array)
                        d.key = $util.newBuffer(d.key);
                }
                if (o.bytes === String)
                    d.al = "";
                else {
                    d.al = [];
                    if (o.bytes !== Array)
                        d.al = $util.newBuffer(d.al);
                }
            }
            if (m.key != null && m.hasOwnProperty("key")) {
                d.key = o.bytes === String ? $util.base64.encode(m.key, 0, m.key.length) : o.bytes === Array ? Array.prototype.slice.call(m.key) : m.key;
            }
            if (m.al != null && m.hasOwnProperty("al")) {
                d.al = o.bytes === String ? $util.base64.encode(m.al, 0, m.al.length) : o.bytes === Array ? Array.prototype.slice.call(m.al) : m.al;
            }
            return d;
        };

        /**
         * Converts this DRMKey to JSON.
         * @function toJSON
         * @memberof Proto.DRMKey
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DRMKey.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DRMKey;
    })();

    Proto.IconListRequest = (function() {

        /**
         * Properties of an IconListRequest.
         * @memberof Proto
         * @interface IIconListRequest
         * @property {Array.<string>|null} [userId] IconListRequest userId
         */

        /**
         * Constructs a new IconListRequest.
         * @memberof Proto
         * @classdesc Represents an IconListRequest.
         * @implements IIconListRequest
         * @constructor
         * @param {Proto.IIconListRequest=} [p] Properties to set
         */
        function IconListRequest(p) {
            this.userId = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * IconListRequest userId.
         * @member {Array.<string>} userId
         * @memberof Proto.IconListRequest
         * @instance
         */
        IconListRequest.prototype.userId = $util.emptyArray;

        /**
         * Creates a new IconListRequest instance using the specified properties.
         * @function create
         * @memberof Proto.IconListRequest
         * @static
         * @param {Proto.IIconListRequest=} [properties] Properties to set
         * @returns {Proto.IconListRequest} IconListRequest instance
         */
        IconListRequest.create = function create(properties) {
            return new IconListRequest(properties);
        };

        /**
         * Encodes the specified IconListRequest message. Does not implicitly {@link Proto.IconListRequest.verify|verify} messages.
         * @function encode
         * @memberof Proto.IconListRequest
         * @static
         * @param {Proto.IIconListRequest} m IconListRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IconListRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && m.userId.length) {
                for (var i = 0; i < m.userId.length; ++i)
                    w.uint32(10).string(m.userId[i]);
            }
            return w;
        };

        /**
         * Decodes an IconListRequest message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.IconListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.IconListRequest} IconListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IconListRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.IconListRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.userId && m.userId.length))
                        m.userId = [];
                    m.userId.push(r.string());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an IconListRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.IconListRequest
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.IconListRequest} IconListRequest
         */
        IconListRequest.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.IconListRequest)
                return d;
            var m = new $root.Proto.IconListRequest();
            if (d.userId) {
                if (!Array.isArray(d.userId))
                    throw TypeError(".Proto.IconListRequest.userId: array expected");
                m.userId = [];
                for (var i = 0; i < d.userId.length; ++i) {
                    m.userId[i] = String(d.userId[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from an IconListRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.IconListRequest
         * @static
         * @param {Proto.IconListRequest} m IconListRequest
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IconListRequest.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.userId = [];
            }
            if (m.userId && m.userId.length) {
                d.userId = [];
                for (var j = 0; j < m.userId.length; ++j) {
                    d.userId[j] = m.userId[j];
                }
            }
            return d;
        };

        /**
         * Converts this IconListRequest to JSON.
         * @function toJSON
         * @memberof Proto.IconListRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IconListRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return IconListRequest;
    })();

    Proto.LiveChat = (function() {

        /**
         * Properties of a LiveChat.
         * @memberof Proto
         * @interface ILiveChat
         * @property {string|null} [id] LiveChat id
         * @property {string|null} [roomId] LiveChat roomId
         * @property {number|Long|null} [ts] LiveChat ts
         * @property {string|null} [body] LiveChat body
         * @property {Proto.IUser|null} [user] LiveChat user
         * @property {boolean|null} [owner] LiveChat owner
         * @property {boolean|null} [sticky] LiveChat sticky
         */

        /**
         * Constructs a new LiveChat.
         * @memberof Proto
         * @classdesc Represents a LiveChat.
         * @implements ILiveChat
         * @constructor
         * @param {Proto.ILiveChat=} [p] Properties to set
         */
        function LiveChat(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * LiveChat id.
         * @member {string} id
         * @memberof Proto.LiveChat
         * @instance
         */
        LiveChat.prototype.id = "";

        /**
         * LiveChat roomId.
         * @member {string} roomId
         * @memberof Proto.LiveChat
         * @instance
         */
        LiveChat.prototype.roomId = "";

        /**
         * LiveChat ts.
         * @member {number|Long} ts
         * @memberof Proto.LiveChat
         * @instance
         */
        LiveChat.prototype.ts = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * LiveChat body.
         * @member {string} body
         * @memberof Proto.LiveChat
         * @instance
         */
        LiveChat.prototype.body = "";

        /**
         * LiveChat user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.LiveChat
         * @instance
         */
        LiveChat.prototype.user = null;

        /**
         * LiveChat owner.
         * @member {boolean} owner
         * @memberof Proto.LiveChat
         * @instance
         */
        LiveChat.prototype.owner = false;

        /**
         * LiveChat sticky.
         * @member {boolean} sticky
         * @memberof Proto.LiveChat
         * @instance
         */
        LiveChat.prototype.sticky = false;

        /**
         * Creates a new LiveChat instance using the specified properties.
         * @function create
         * @memberof Proto.LiveChat
         * @static
         * @param {Proto.ILiveChat=} [properties] Properties to set
         * @returns {Proto.LiveChat} LiveChat instance
         */
        LiveChat.create = function create(properties) {
            return new LiveChat(properties);
        };

        /**
         * Encodes the specified LiveChat message. Does not implicitly {@link Proto.LiveChat.verify|verify} messages.
         * @function encode
         * @memberof Proto.LiveChat
         * @static
         * @param {Proto.ILiveChat} m LiveChat message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LiveChat.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.roomId != null && Object.hasOwnProperty.call(m, "roomId"))
                w.uint32(18).string(m.roomId);
            if (m.ts != null && Object.hasOwnProperty.call(m, "ts"))
                w.uint32(24).int64(m.ts);
            if (m.body != null && Object.hasOwnProperty.call(m, "body"))
                w.uint32(34).string(m.body);
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(42).fork()).ldelim();
            if (m.owner != null && Object.hasOwnProperty.call(m, "owner"))
                w.uint32(48).bool(m.owner);
            if (m.sticky != null && Object.hasOwnProperty.call(m, "sticky"))
                w.uint32(56).bool(m.sticky);
            return w;
        };

        /**
         * Decodes a LiveChat message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.LiveChat
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.LiveChat} LiveChat
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LiveChat.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.LiveChat();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.id = r.string();
                    break;
                case 2:
                    m.roomId = r.string();
                    break;
                case 3:
                    m.ts = r.int64();
                    break;
                case 4:
                    m.body = r.string();
                    break;
                case 5:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 6:
                    m.owner = r.bool();
                    break;
                case 7:
                    m.sticky = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a LiveChat message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.LiveChat
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.LiveChat} LiveChat
         */
        LiveChat.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.LiveChat)
                return d;
            var m = new $root.Proto.LiveChat();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.roomId != null) {
                m.roomId = String(d.roomId);
            }
            if (d.ts != null) {
                if ($util.Long)
                    (m.ts = $util.Long.fromValue(d.ts)).unsigned = false;
                else if (typeof d.ts === "string")
                    m.ts = parseInt(d.ts, 10);
                else if (typeof d.ts === "number")
                    m.ts = d.ts;
                else if (typeof d.ts === "object")
                    m.ts = new $util.LongBits(d.ts.low >>> 0, d.ts.high >>> 0).toNumber();
            }
            if (d.body != null) {
                m.body = String(d.body);
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.LiveChat.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            if (d.owner != null) {
                m.owner = Boolean(d.owner);
            }
            if (d.sticky != null) {
                m.sticky = Boolean(d.sticky);
            }
            return m;
        };

        /**
         * Creates a plain object from a LiveChat message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.LiveChat
         * @static
         * @param {Proto.LiveChat} m LiveChat
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LiveChat.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.roomId = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.ts = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.ts = o.longs === String ? "0" : 0;
                d.body = "";
                d.user = null;
                d.owner = false;
                d.sticky = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.roomId != null && m.hasOwnProperty("roomId")) {
                d.roomId = m.roomId;
            }
            if (m.ts != null && m.hasOwnProperty("ts")) {
                if (typeof m.ts === "number")
                    d.ts = o.longs === String ? String(m.ts) : m.ts;
                else
                    d.ts = o.longs === String ? $util.Long.prototype.toString.call(m.ts) : o.longs === Number ? new $util.LongBits(m.ts.low >>> 0, m.ts.high >>> 0).toNumber() : m.ts;
            }
            if (m.body != null && m.hasOwnProperty("body")) {
                d.body = m.body;
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            if (m.owner != null && m.hasOwnProperty("owner")) {
                d.owner = m.owner;
            }
            if (m.sticky != null && m.hasOwnProperty("sticky")) {
                d.sticky = m.sticky;
            }
            return d;
        };

        /**
         * Converts this LiveChat to JSON.
         * @function toJSON
         * @memberof Proto.LiveChat
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LiveChat.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LiveChat;
    })();

    Proto.LiveChatList = (function() {

        /**
         * Properties of a LiveChatList.
         * @memberof Proto
         * @interface ILiveChatList
         * @property {Array.<Proto.ILiveChat>|null} [value] LiveChatList value
         */

        /**
         * Constructs a new LiveChatList.
         * @memberof Proto
         * @classdesc Represents a LiveChatList.
         * @implements ILiveChatList
         * @constructor
         * @param {Proto.ILiveChatList=} [p] Properties to set
         */
        function LiveChatList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * LiveChatList value.
         * @member {Array.<Proto.ILiveChat>} value
         * @memberof Proto.LiveChatList
         * @instance
         */
        LiveChatList.prototype.value = $util.emptyArray;

        /**
         * Creates a new LiveChatList instance using the specified properties.
         * @function create
         * @memberof Proto.LiveChatList
         * @static
         * @param {Proto.ILiveChatList=} [properties] Properties to set
         * @returns {Proto.LiveChatList} LiveChatList instance
         */
        LiveChatList.create = function create(properties) {
            return new LiveChatList(properties);
        };

        /**
         * Encodes the specified LiveChatList message. Does not implicitly {@link Proto.LiveChatList.verify|verify} messages.
         * @function encode
         * @memberof Proto.LiveChatList
         * @static
         * @param {Proto.ILiveChatList} m LiveChatList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LiveChatList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.LiveChat.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a LiveChatList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.LiveChatList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.LiveChatList} LiveChatList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LiveChatList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.LiveChatList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.LiveChat.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a LiveChatList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.LiveChatList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.LiveChatList} LiveChatList
         */
        LiveChatList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.LiveChatList)
                return d;
            var m = new $root.Proto.LiveChatList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.LiveChatList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.LiveChatList.value: object expected");
                    m.value[i] = $root.Proto.LiveChat.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a LiveChatList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.LiveChatList
         * @static
         * @param {Proto.LiveChatList} m LiveChatList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LiveChatList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.LiveChat.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this LiveChatList to JSON.
         * @function toJSON
         * @memberof Proto.LiveChatList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LiveChatList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LiveChatList;
    })();

    Proto.ZoomZAK = (function() {

        /**
         * Properties of a ZoomZAK.
         * @memberof Proto
         * @interface IZoomZAK
         * @property {string|null} [userId] ZoomZAK userId
         * @property {string|null} [token] ZoomZAK token
         * @property {string|null} [name] ZoomZAK name
         * @property {string|null} [meetingId] ZoomZAK meetingId
         */

        /**
         * Constructs a new ZoomZAK.
         * @memberof Proto
         * @classdesc Represents a ZoomZAK.
         * @implements IZoomZAK
         * @constructor
         * @param {Proto.IZoomZAK=} [p] Properties to set
         */
        function ZoomZAK(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ZoomZAK userId.
         * @member {string} userId
         * @memberof Proto.ZoomZAK
         * @instance
         */
        ZoomZAK.prototype.userId = "";

        /**
         * ZoomZAK token.
         * @member {string} token
         * @memberof Proto.ZoomZAK
         * @instance
         */
        ZoomZAK.prototype.token = "";

        /**
         * ZoomZAK name.
         * @member {string} name
         * @memberof Proto.ZoomZAK
         * @instance
         */
        ZoomZAK.prototype.name = "";

        /**
         * ZoomZAK meetingId.
         * @member {string} meetingId
         * @memberof Proto.ZoomZAK
         * @instance
         */
        ZoomZAK.prototype.meetingId = "";

        /**
         * Creates a new ZoomZAK instance using the specified properties.
         * @function create
         * @memberof Proto.ZoomZAK
         * @static
         * @param {Proto.IZoomZAK=} [properties] Properties to set
         * @returns {Proto.ZoomZAK} ZoomZAK instance
         */
        ZoomZAK.create = function create(properties) {
            return new ZoomZAK(properties);
        };

        /**
         * Encodes the specified ZoomZAK message. Does not implicitly {@link Proto.ZoomZAK.verify|verify} messages.
         * @function encode
         * @memberof Proto.ZoomZAK
         * @static
         * @param {Proto.IZoomZAK} m ZoomZAK message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZoomZAK.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(10).string(m.userId);
            if (m.token != null && Object.hasOwnProperty.call(m, "token"))
                w.uint32(18).string(m.token);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.meetingId != null && Object.hasOwnProperty.call(m, "meetingId"))
                w.uint32(34).string(m.meetingId);
            return w;
        };

        /**
         * Decodes a ZoomZAK message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ZoomZAK
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ZoomZAK} ZoomZAK
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZoomZAK.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ZoomZAK();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.userId = r.string();
                    break;
                case 2:
                    m.token = r.string();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.meetingId = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ZoomZAK message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ZoomZAK
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ZoomZAK} ZoomZAK
         */
        ZoomZAK.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ZoomZAK)
                return d;
            var m = new $root.Proto.ZoomZAK();
            if (d.userId != null) {
                m.userId = String(d.userId);
            }
            if (d.token != null) {
                m.token = String(d.token);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.meetingId != null) {
                m.meetingId = String(d.meetingId);
            }
            return m;
        };

        /**
         * Creates a plain object from a ZoomZAK message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ZoomZAK
         * @static
         * @param {Proto.ZoomZAK} m ZoomZAK
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ZoomZAK.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.userId = "";
                d.token = "";
                d.name = "";
                d.meetingId = "";
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                d.userId = m.userId;
            }
            if (m.token != null && m.hasOwnProperty("token")) {
                d.token = m.token;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.meetingId != null && m.hasOwnProperty("meetingId")) {
                d.meetingId = m.meetingId;
            }
            return d;
        };

        /**
         * Converts this ZoomZAK to JSON.
         * @function toJSON
         * @memberof Proto.ZoomZAK
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ZoomZAK.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ZoomZAK;
    })();

    Proto.ViewerPlan = (function() {

        /**
         * Properties of a ViewerPlan.
         * @memberof Proto
         * @interface IViewerPlan
         * @property {string|null} [uid] ViewerPlan uid
         * @property {string|null} [companyUID] ViewerPlan companyUID
         * @property {number|null} [price] ViewerPlan price
         * @property {Proto.INullInt32|null} [trialDay] ViewerPlan trialDay
         * @property {Proto.INullInt32|null} [ageRating] ViewerPlan ageRating
         * @property {string|null} [name] ViewerPlan name
         * @property {number|Long|null} [registerDate] ViewerPlan registerDate
         * @property {number|Long|null} [modifyDate] ViewerPlan modifyDate
         * @property {string|null} [currency] ViewerPlan currency
         * @property {number|null} [type] ViewerPlan type
         * @property {number|null} [duration] ViewerPlan duration
         * @property {string|null} [tierId] ViewerPlan tierId
         * @property {string|null} [tierName] ViewerPlan tierName
         * @property {string|null} [offerId] ViewerPlan offerId
         */

        /**
         * Constructs a new ViewerPlan.
         * @memberof Proto
         * @classdesc Represents a ViewerPlan.
         * @implements IViewerPlan
         * @constructor
         * @param {Proto.IViewerPlan=} [p] Properties to set
         */
        function ViewerPlan(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ViewerPlan uid.
         * @member {string} uid
         * @memberof Proto.ViewerPlan
         * @instance
         */
        ViewerPlan.prototype.uid = "";

        /**
         * ViewerPlan companyUID.
         * @member {string} companyUID
         * @memberof Proto.ViewerPlan
         * @instance
         */
        ViewerPlan.prototype.companyUID = "";

        /**
         * ViewerPlan price.
         * @member {number} price
         * @memberof Proto.ViewerPlan
         * @instance
         */
        ViewerPlan.prototype.price = 0;

        /**
         * ViewerPlan trialDay.
         * @member {Proto.INullInt32|null|undefined} trialDay
         * @memberof Proto.ViewerPlan
         * @instance
         */
        ViewerPlan.prototype.trialDay = null;

        /**
         * ViewerPlan ageRating.
         * @member {Proto.INullInt32|null|undefined} ageRating
         * @memberof Proto.ViewerPlan
         * @instance
         */
        ViewerPlan.prototype.ageRating = null;

        /**
         * ViewerPlan name.
         * @member {string} name
         * @memberof Proto.ViewerPlan
         * @instance
         */
        ViewerPlan.prototype.name = "";

        /**
         * ViewerPlan registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ViewerPlan
         * @instance
         */
        ViewerPlan.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ViewerPlan modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ViewerPlan
         * @instance
         */
        ViewerPlan.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ViewerPlan currency.
         * @member {string} currency
         * @memberof Proto.ViewerPlan
         * @instance
         */
        ViewerPlan.prototype.currency = "";

        /**
         * ViewerPlan type.
         * @member {number} type
         * @memberof Proto.ViewerPlan
         * @instance
         */
        ViewerPlan.prototype.type = 0;

        /**
         * ViewerPlan duration.
         * @member {number} duration
         * @memberof Proto.ViewerPlan
         * @instance
         */
        ViewerPlan.prototype.duration = 0;

        /**
         * ViewerPlan tierId.
         * @member {string} tierId
         * @memberof Proto.ViewerPlan
         * @instance
         */
        ViewerPlan.prototype.tierId = "";

        /**
         * ViewerPlan tierName.
         * @member {string} tierName
         * @memberof Proto.ViewerPlan
         * @instance
         */
        ViewerPlan.prototype.tierName = "";

        /**
         * ViewerPlan offerId.
         * @member {string} offerId
         * @memberof Proto.ViewerPlan
         * @instance
         */
        ViewerPlan.prototype.offerId = "";

        /**
         * Creates a new ViewerPlan instance using the specified properties.
         * @function create
         * @memberof Proto.ViewerPlan
         * @static
         * @param {Proto.IViewerPlan=} [properties] Properties to set
         * @returns {Proto.ViewerPlan} ViewerPlan instance
         */
        ViewerPlan.create = function create(properties) {
            return new ViewerPlan(properties);
        };

        /**
         * Encodes the specified ViewerPlan message. Does not implicitly {@link Proto.ViewerPlan.verify|verify} messages.
         * @function encode
         * @memberof Proto.ViewerPlan
         * @static
         * @param {Proto.IViewerPlan} m ViewerPlan message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ViewerPlan.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(18).string(m.companyUID);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(25).double(m.price);
            if (m.trialDay != null && Object.hasOwnProperty.call(m, "trialDay"))
                $root.Proto.NullInt32.encode(m.trialDay, w.uint32(34).fork()).ldelim();
            if (m.ageRating != null && Object.hasOwnProperty.call(m, "ageRating"))
                $root.Proto.NullInt32.encode(m.ageRating, w.uint32(42).fork()).ldelim();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(50).string(m.name);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(56).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(64).int64(m.modifyDate);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(74).string(m.currency);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(80).int32(m.type);
            if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                w.uint32(88).int32(m.duration);
            if (m.tierId != null && Object.hasOwnProperty.call(m, "tierId"))
                w.uint32(98).string(m.tierId);
            if (m.tierName != null && Object.hasOwnProperty.call(m, "tierName"))
                w.uint32(106).string(m.tierName);
            if (m.offerId != null && Object.hasOwnProperty.call(m, "offerId"))
                w.uint32(114).string(m.offerId);
            return w;
        };

        /**
         * Decodes a ViewerPlan message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ViewerPlan
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ViewerPlan} ViewerPlan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ViewerPlan.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ViewerPlan();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.companyUID = r.string();
                    break;
                case 3:
                    m.price = r.double();
                    break;
                case 4:
                    m.trialDay = $root.Proto.NullInt32.decode(r, r.uint32());
                    break;
                case 5:
                    m.ageRating = $root.Proto.NullInt32.decode(r, r.uint32());
                    break;
                case 6:
                    m.name = r.string();
                    break;
                case 7:
                    m.registerDate = r.int64();
                    break;
                case 8:
                    m.modifyDate = r.int64();
                    break;
                case 9:
                    m.currency = r.string();
                    break;
                case 10:
                    m.type = r.int32();
                    break;
                case 11:
                    m.duration = r.int32();
                    break;
                case 12:
                    m.tierId = r.string();
                    break;
                case 13:
                    m.tierName = r.string();
                    break;
                case 14:
                    m.offerId = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ViewerPlan message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ViewerPlan
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ViewerPlan} ViewerPlan
         */
        ViewerPlan.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ViewerPlan)
                return d;
            var m = new $root.Proto.ViewerPlan();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.trialDay != null) {
                if (typeof d.trialDay !== "object")
                    throw TypeError(".Proto.ViewerPlan.trialDay: object expected");
                m.trialDay = $root.Proto.NullInt32.fromObject(d.trialDay);
            }
            if (d.ageRating != null) {
                if (typeof d.ageRating !== "object")
                    throw TypeError(".Proto.ViewerPlan.ageRating: object expected");
                m.ageRating = $root.Proto.NullInt32.fromObject(d.ageRating);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.duration != null) {
                m.duration = d.duration | 0;
            }
            if (d.tierId != null) {
                m.tierId = String(d.tierId);
            }
            if (d.tierName != null) {
                m.tierName = String(d.tierName);
            }
            if (d.offerId != null) {
                m.offerId = String(d.offerId);
            }
            return m;
        };

        /**
         * Creates a plain object from a ViewerPlan message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ViewerPlan
         * @static
         * @param {Proto.ViewerPlan} m ViewerPlan
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ViewerPlan.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.companyUID = "";
                d.price = 0;
                d.trialDay = null;
                d.ageRating = null;
                d.name = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.currency = "";
                d.type = 0;
                d.duration = 0;
                d.tierId = "";
                d.tierName = "";
                d.offerId = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.trialDay != null && m.hasOwnProperty("trialDay")) {
                d.trialDay = $root.Proto.NullInt32.toObject(m.trialDay, o);
            }
            if (m.ageRating != null && m.hasOwnProperty("ageRating")) {
                d.ageRating = $root.Proto.NullInt32.toObject(m.ageRating, o);
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.duration != null && m.hasOwnProperty("duration")) {
                d.duration = m.duration;
            }
            if (m.tierId != null && m.hasOwnProperty("tierId")) {
                d.tierId = m.tierId;
            }
            if (m.tierName != null && m.hasOwnProperty("tierName")) {
                d.tierName = m.tierName;
            }
            if (m.offerId != null && m.hasOwnProperty("offerId")) {
                d.offerId = m.offerId;
            }
            return d;
        };

        /**
         * Converts this ViewerPlan to JSON.
         * @function toJSON
         * @memberof Proto.ViewerPlan
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ViewerPlan.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ViewerPlan;
    })();

    Proto.ViewerPlanList = (function() {

        /**
         * Properties of a ViewerPlanList.
         * @memberof Proto
         * @interface IViewerPlanList
         * @property {Array.<Proto.IViewerPlan>|null} [value] ViewerPlanList value
         */

        /**
         * Constructs a new ViewerPlanList.
         * @memberof Proto
         * @classdesc Represents a ViewerPlanList.
         * @implements IViewerPlanList
         * @constructor
         * @param {Proto.IViewerPlanList=} [p] Properties to set
         */
        function ViewerPlanList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ViewerPlanList value.
         * @member {Array.<Proto.IViewerPlan>} value
         * @memberof Proto.ViewerPlanList
         * @instance
         */
        ViewerPlanList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ViewerPlanList instance using the specified properties.
         * @function create
         * @memberof Proto.ViewerPlanList
         * @static
         * @param {Proto.IViewerPlanList=} [properties] Properties to set
         * @returns {Proto.ViewerPlanList} ViewerPlanList instance
         */
        ViewerPlanList.create = function create(properties) {
            return new ViewerPlanList(properties);
        };

        /**
         * Encodes the specified ViewerPlanList message. Does not implicitly {@link Proto.ViewerPlanList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ViewerPlanList
         * @static
         * @param {Proto.IViewerPlanList} m ViewerPlanList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ViewerPlanList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ViewerPlan.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ViewerPlanList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ViewerPlanList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ViewerPlanList} ViewerPlanList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ViewerPlanList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ViewerPlanList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ViewerPlan.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ViewerPlanList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ViewerPlanList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ViewerPlanList} ViewerPlanList
         */
        ViewerPlanList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ViewerPlanList)
                return d;
            var m = new $root.Proto.ViewerPlanList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ViewerPlanList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ViewerPlanList.value: object expected");
                    m.value[i] = $root.Proto.ViewerPlan.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ViewerPlanList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ViewerPlanList
         * @static
         * @param {Proto.ViewerPlanList} m ViewerPlanList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ViewerPlanList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ViewerPlan.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ViewerPlanList to JSON.
         * @function toJSON
         * @memberof Proto.ViewerPlanList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ViewerPlanList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ViewerPlanList;
    })();

    Proto.ViewerUserForm = (function() {

        /**
         * Properties of a ViewerUserForm.
         * @memberof Proto
         * @interface IViewerUserForm
         * @property {string|null} [uid] ViewerUserForm uid
         * @property {number|null} [type] ViewerUserForm type
         * @property {string|null} [name] ViewerUserForm name
         * @property {boolean|null} [optional] ViewerUserForm optional
         * @property {string|null} [setting] ViewerUserForm setting
         * @property {number|null} [order] ViewerUserForm order
         * @property {number|Long|null} [registerDate] ViewerUserForm registerDate
         * @property {number|Long|null} [modifyDate] ViewerUserForm modifyDate
         * @property {boolean|null} [fixed] ViewerUserForm fixed
         * @property {string|null} [parent] ViewerUserForm parent
         * @property {Array.<Proto.IViewerUserForm>|null} [childList] ViewerUserForm childList
         * @property {boolean|null} [editable] ViewerUserForm editable
         */

        /**
         * Constructs a new ViewerUserForm.
         * @memberof Proto
         * @classdesc Represents a ViewerUserForm.
         * @implements IViewerUserForm
         * @constructor
         * @param {Proto.IViewerUserForm=} [p] Properties to set
         */
        function ViewerUserForm(p) {
            this.childList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ViewerUserForm uid.
         * @member {string} uid
         * @memberof Proto.ViewerUserForm
         * @instance
         */
        ViewerUserForm.prototype.uid = "";

        /**
         * ViewerUserForm type.
         * @member {number} type
         * @memberof Proto.ViewerUserForm
         * @instance
         */
        ViewerUserForm.prototype.type = 0;

        /**
         * ViewerUserForm name.
         * @member {string} name
         * @memberof Proto.ViewerUserForm
         * @instance
         */
        ViewerUserForm.prototype.name = "";

        /**
         * ViewerUserForm optional.
         * @member {boolean} optional
         * @memberof Proto.ViewerUserForm
         * @instance
         */
        ViewerUserForm.prototype.optional = false;

        /**
         * ViewerUserForm setting.
         * @member {string} setting
         * @memberof Proto.ViewerUserForm
         * @instance
         */
        ViewerUserForm.prototype.setting = "";

        /**
         * ViewerUserForm order.
         * @member {number} order
         * @memberof Proto.ViewerUserForm
         * @instance
         */
        ViewerUserForm.prototype.order = 0;

        /**
         * ViewerUserForm registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ViewerUserForm
         * @instance
         */
        ViewerUserForm.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ViewerUserForm modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ViewerUserForm
         * @instance
         */
        ViewerUserForm.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ViewerUserForm fixed.
         * @member {boolean} fixed
         * @memberof Proto.ViewerUserForm
         * @instance
         */
        ViewerUserForm.prototype.fixed = false;

        /**
         * ViewerUserForm parent.
         * @member {string} parent
         * @memberof Proto.ViewerUserForm
         * @instance
         */
        ViewerUserForm.prototype.parent = "";

        /**
         * ViewerUserForm childList.
         * @member {Array.<Proto.IViewerUserForm>} childList
         * @memberof Proto.ViewerUserForm
         * @instance
         */
        ViewerUserForm.prototype.childList = $util.emptyArray;

        /**
         * ViewerUserForm editable.
         * @member {boolean} editable
         * @memberof Proto.ViewerUserForm
         * @instance
         */
        ViewerUserForm.prototype.editable = false;

        /**
         * Creates a new ViewerUserForm instance using the specified properties.
         * @function create
         * @memberof Proto.ViewerUserForm
         * @static
         * @param {Proto.IViewerUserForm=} [properties] Properties to set
         * @returns {Proto.ViewerUserForm} ViewerUserForm instance
         */
        ViewerUserForm.create = function create(properties) {
            return new ViewerUserForm(properties);
        };

        /**
         * Encodes the specified ViewerUserForm message. Does not implicitly {@link Proto.ViewerUserForm.verify|verify} messages.
         * @function encode
         * @memberof Proto.ViewerUserForm
         * @static
         * @param {Proto.IViewerUserForm} m ViewerUserForm message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ViewerUserForm.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.optional != null && Object.hasOwnProperty.call(m, "optional"))
                w.uint32(32).bool(m.optional);
            if (m.setting != null && Object.hasOwnProperty.call(m, "setting"))
                w.uint32(42).string(m.setting);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(48).int32(m.order);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(56).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(64).int64(m.modifyDate);
            if (m.fixed != null && Object.hasOwnProperty.call(m, "fixed"))
                w.uint32(72).bool(m.fixed);
            if (m.parent != null && Object.hasOwnProperty.call(m, "parent"))
                w.uint32(82).string(m.parent);
            if (m.childList != null && m.childList.length) {
                for (var i = 0; i < m.childList.length; ++i)
                    $root.Proto.ViewerUserForm.encode(m.childList[i], w.uint32(90).fork()).ldelim();
            }
            if (m.editable != null && Object.hasOwnProperty.call(m, "editable"))
                w.uint32(96).bool(m.editable);
            return w;
        };

        /**
         * Decodes a ViewerUserForm message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ViewerUserForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ViewerUserForm} ViewerUserForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ViewerUserForm.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ViewerUserForm();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.optional = r.bool();
                    break;
                case 5:
                    m.setting = r.string();
                    break;
                case 6:
                    m.order = r.int32();
                    break;
                case 7:
                    m.registerDate = r.int64();
                    break;
                case 8:
                    m.modifyDate = r.int64();
                    break;
                case 9:
                    m.fixed = r.bool();
                    break;
                case 10:
                    m.parent = r.string();
                    break;
                case 11:
                    if (!(m.childList && m.childList.length))
                        m.childList = [];
                    m.childList.push($root.Proto.ViewerUserForm.decode(r, r.uint32()));
                    break;
                case 12:
                    m.editable = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ViewerUserForm message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ViewerUserForm
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ViewerUserForm} ViewerUserForm
         */
        ViewerUserForm.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ViewerUserForm)
                return d;
            var m = new $root.Proto.ViewerUserForm();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.optional != null) {
                m.optional = Boolean(d.optional);
            }
            if (d.setting != null) {
                m.setting = String(d.setting);
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.fixed != null) {
                m.fixed = Boolean(d.fixed);
            }
            if (d.parent != null) {
                m.parent = String(d.parent);
            }
            if (d.childList) {
                if (!Array.isArray(d.childList))
                    throw TypeError(".Proto.ViewerUserForm.childList: array expected");
                m.childList = [];
                for (var i = 0; i < d.childList.length; ++i) {
                    if (typeof d.childList[i] !== "object")
                        throw TypeError(".Proto.ViewerUserForm.childList: object expected");
                    m.childList[i] = $root.Proto.ViewerUserForm.fromObject(d.childList[i]);
                }
            }
            if (d.editable != null) {
                m.editable = Boolean(d.editable);
            }
            return m;
        };

        /**
         * Creates a plain object from a ViewerUserForm message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ViewerUserForm
         * @static
         * @param {Proto.ViewerUserForm} m ViewerUserForm
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ViewerUserForm.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.childList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.name = "";
                d.optional = false;
                d.setting = "";
                d.order = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.fixed = false;
                d.parent = "";
                d.editable = false;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.optional != null && m.hasOwnProperty("optional")) {
                d.optional = m.optional;
            }
            if (m.setting != null && m.hasOwnProperty("setting")) {
                d.setting = m.setting;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.fixed != null && m.hasOwnProperty("fixed")) {
                d.fixed = m.fixed;
            }
            if (m.parent != null && m.hasOwnProperty("parent")) {
                d.parent = m.parent;
            }
            if (m.childList && m.childList.length) {
                d.childList = [];
                for (var j = 0; j < m.childList.length; ++j) {
                    d.childList[j] = $root.Proto.ViewerUserForm.toObject(m.childList[j], o);
                }
            }
            if (m.editable != null && m.hasOwnProperty("editable")) {
                d.editable = m.editable;
            }
            return d;
        };

        /**
         * Converts this ViewerUserForm to JSON.
         * @function toJSON
         * @memberof Proto.ViewerUserForm
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ViewerUserForm.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ViewerUserForm;
    })();

    Proto.ViewerUserFormList = (function() {

        /**
         * Properties of a ViewerUserFormList.
         * @memberof Proto
         * @interface IViewerUserFormList
         * @property {Array.<Proto.IViewerUserForm>|null} [value] ViewerUserFormList value
         */

        /**
         * Constructs a new ViewerUserFormList.
         * @memberof Proto
         * @classdesc Represents a ViewerUserFormList.
         * @implements IViewerUserFormList
         * @constructor
         * @param {Proto.IViewerUserFormList=} [p] Properties to set
         */
        function ViewerUserFormList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ViewerUserFormList value.
         * @member {Array.<Proto.IViewerUserForm>} value
         * @memberof Proto.ViewerUserFormList
         * @instance
         */
        ViewerUserFormList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ViewerUserFormList instance using the specified properties.
         * @function create
         * @memberof Proto.ViewerUserFormList
         * @static
         * @param {Proto.IViewerUserFormList=} [properties] Properties to set
         * @returns {Proto.ViewerUserFormList} ViewerUserFormList instance
         */
        ViewerUserFormList.create = function create(properties) {
            return new ViewerUserFormList(properties);
        };

        /**
         * Encodes the specified ViewerUserFormList message. Does not implicitly {@link Proto.ViewerUserFormList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ViewerUserFormList
         * @static
         * @param {Proto.IViewerUserFormList} m ViewerUserFormList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ViewerUserFormList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ViewerUserForm.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ViewerUserFormList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ViewerUserFormList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ViewerUserFormList} ViewerUserFormList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ViewerUserFormList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ViewerUserFormList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ViewerUserForm.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ViewerUserFormList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ViewerUserFormList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ViewerUserFormList} ViewerUserFormList
         */
        ViewerUserFormList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ViewerUserFormList)
                return d;
            var m = new $root.Proto.ViewerUserFormList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ViewerUserFormList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ViewerUserFormList.value: object expected");
                    m.value[i] = $root.Proto.ViewerUserForm.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ViewerUserFormList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ViewerUserFormList
         * @static
         * @param {Proto.ViewerUserFormList} m ViewerUserFormList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ViewerUserFormList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ViewerUserForm.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ViewerUserFormList to JSON.
         * @function toJSON
         * @memberof Proto.ViewerUserFormList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ViewerUserFormList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ViewerUserFormList;
    })();

    Proto.ViewerUserInfo = (function() {

        /**
         * Properties of a ViewerUserInfo.
         * @memberof Proto
         * @interface IViewerUserInfo
         * @property {string|null} [infoUID] ViewerUserInfo infoUID
         * @property {string|null} [text] ViewerUserInfo text
         * @property {number|null} [number] ViewerUserInfo number
         * @property {number|Long|null} [date] ViewerUserInfo date
         * @property {number|Long|null} [registerDate] ViewerUserInfo registerDate
         * @property {number|Long|null} [modifyDate] ViewerUserInfo modifyDate
         * @property {string|null} [additional] ViewerUserInfo additional
         * @property {Proto.IUploadFile|null} [file] ViewerUserInfo file
         */

        /**
         * Constructs a new ViewerUserInfo.
         * @memberof Proto
         * @classdesc Represents a ViewerUserInfo.
         * @implements IViewerUserInfo
         * @constructor
         * @param {Proto.IViewerUserInfo=} [p] Properties to set
         */
        function ViewerUserInfo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ViewerUserInfo infoUID.
         * @member {string} infoUID
         * @memberof Proto.ViewerUserInfo
         * @instance
         */
        ViewerUserInfo.prototype.infoUID = "";

        /**
         * ViewerUserInfo text.
         * @member {string} text
         * @memberof Proto.ViewerUserInfo
         * @instance
         */
        ViewerUserInfo.prototype.text = "";

        /**
         * ViewerUserInfo number.
         * @member {number} number
         * @memberof Proto.ViewerUserInfo
         * @instance
         */
        ViewerUserInfo.prototype.number = 0;

        /**
         * ViewerUserInfo date.
         * @member {number|Long} date
         * @memberof Proto.ViewerUserInfo
         * @instance
         */
        ViewerUserInfo.prototype.date = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ViewerUserInfo registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ViewerUserInfo
         * @instance
         */
        ViewerUserInfo.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ViewerUserInfo modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ViewerUserInfo
         * @instance
         */
        ViewerUserInfo.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ViewerUserInfo additional.
         * @member {string} additional
         * @memberof Proto.ViewerUserInfo
         * @instance
         */
        ViewerUserInfo.prototype.additional = "";

        /**
         * ViewerUserInfo file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.ViewerUserInfo
         * @instance
         */
        ViewerUserInfo.prototype.file = null;

        /**
         * Creates a new ViewerUserInfo instance using the specified properties.
         * @function create
         * @memberof Proto.ViewerUserInfo
         * @static
         * @param {Proto.IViewerUserInfo=} [properties] Properties to set
         * @returns {Proto.ViewerUserInfo} ViewerUserInfo instance
         */
        ViewerUserInfo.create = function create(properties) {
            return new ViewerUserInfo(properties);
        };

        /**
         * Encodes the specified ViewerUserInfo message. Does not implicitly {@link Proto.ViewerUserInfo.verify|verify} messages.
         * @function encode
         * @memberof Proto.ViewerUserInfo
         * @static
         * @param {Proto.IViewerUserInfo} m ViewerUserInfo message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ViewerUserInfo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.infoUID != null && Object.hasOwnProperty.call(m, "infoUID"))
                w.uint32(10).string(m.infoUID);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(18).string(m.text);
            if (m.number != null && Object.hasOwnProperty.call(m, "number"))
                w.uint32(24).int32(m.number);
            if (m.date != null && Object.hasOwnProperty.call(m, "date"))
                w.uint32(32).int64(m.date);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(40).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(48).int64(m.modifyDate);
            if (m.additional != null && Object.hasOwnProperty.call(m, "additional"))
                w.uint32(58).string(m.additional);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(66).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a ViewerUserInfo message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ViewerUserInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ViewerUserInfo} ViewerUserInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ViewerUserInfo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ViewerUserInfo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.infoUID = r.string();
                    break;
                case 2:
                    m.text = r.string();
                    break;
                case 3:
                    m.number = r.int32();
                    break;
                case 4:
                    m.date = r.int64();
                    break;
                case 5:
                    m.registerDate = r.int64();
                    break;
                case 6:
                    m.modifyDate = r.int64();
                    break;
                case 7:
                    m.additional = r.string();
                    break;
                case 8:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ViewerUserInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ViewerUserInfo
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ViewerUserInfo} ViewerUserInfo
         */
        ViewerUserInfo.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ViewerUserInfo)
                return d;
            var m = new $root.Proto.ViewerUserInfo();
            if (d.infoUID != null) {
                m.infoUID = String(d.infoUID);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.number != null) {
                m.number = d.number | 0;
            }
            if (d.date != null) {
                if ($util.Long)
                    (m.date = $util.Long.fromValue(d.date)).unsigned = false;
                else if (typeof d.date === "string")
                    m.date = parseInt(d.date, 10);
                else if (typeof d.date === "number")
                    m.date = d.date;
                else if (typeof d.date === "object")
                    m.date = new $util.LongBits(d.date.low >>> 0, d.date.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.additional != null) {
                m.additional = String(d.additional);
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.ViewerUserInfo.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            return m;
        };

        /**
         * Creates a plain object from a ViewerUserInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ViewerUserInfo
         * @static
         * @param {Proto.ViewerUserInfo} m ViewerUserInfo
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ViewerUserInfo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.infoUID = "";
                d.text = "";
                d.number = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.date = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.date = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.additional = "";
                d.file = null;
            }
            if (m.infoUID != null && m.hasOwnProperty("infoUID")) {
                d.infoUID = m.infoUID;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.number != null && m.hasOwnProperty("number")) {
                d.number = m.number;
            }
            if (m.date != null && m.hasOwnProperty("date")) {
                if (typeof m.date === "number")
                    d.date = o.longs === String ? String(m.date) : m.date;
                else
                    d.date = o.longs === String ? $util.Long.prototype.toString.call(m.date) : o.longs === Number ? new $util.LongBits(m.date.low >>> 0, m.date.high >>> 0).toNumber() : m.date;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.additional != null && m.hasOwnProperty("additional")) {
                d.additional = m.additional;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            return d;
        };

        /**
         * Converts this ViewerUserInfo to JSON.
         * @function toJSON
         * @memberof Proto.ViewerUserInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ViewerUserInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ViewerUserInfo;
    })();

    Proto.ViewerUserInfoList = (function() {

        /**
         * Properties of a ViewerUserInfoList.
         * @memberof Proto
         * @interface IViewerUserInfoList
         * @property {Array.<Proto.IViewerUserInfo>|null} [value] ViewerUserInfoList value
         * @property {Proto.IUser|null} [user] ViewerUserInfoList user
         */

        /**
         * Constructs a new ViewerUserInfoList.
         * @memberof Proto
         * @classdesc Represents a ViewerUserInfoList.
         * @implements IViewerUserInfoList
         * @constructor
         * @param {Proto.IViewerUserInfoList=} [p] Properties to set
         */
        function ViewerUserInfoList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ViewerUserInfoList value.
         * @member {Array.<Proto.IViewerUserInfo>} value
         * @memberof Proto.ViewerUserInfoList
         * @instance
         */
        ViewerUserInfoList.prototype.value = $util.emptyArray;

        /**
         * ViewerUserInfoList user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.ViewerUserInfoList
         * @instance
         */
        ViewerUserInfoList.prototype.user = null;

        /**
         * Creates a new ViewerUserInfoList instance using the specified properties.
         * @function create
         * @memberof Proto.ViewerUserInfoList
         * @static
         * @param {Proto.IViewerUserInfoList=} [properties] Properties to set
         * @returns {Proto.ViewerUserInfoList} ViewerUserInfoList instance
         */
        ViewerUserInfoList.create = function create(properties) {
            return new ViewerUserInfoList(properties);
        };

        /**
         * Encodes the specified ViewerUserInfoList message. Does not implicitly {@link Proto.ViewerUserInfoList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ViewerUserInfoList
         * @static
         * @param {Proto.IViewerUserInfoList} m ViewerUserInfoList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ViewerUserInfoList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ViewerUserInfo.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(18).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a ViewerUserInfoList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ViewerUserInfoList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ViewerUserInfoList} ViewerUserInfoList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ViewerUserInfoList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ViewerUserInfoList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ViewerUserInfo.decode(r, r.uint32()));
                    break;
                case 2:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ViewerUserInfoList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ViewerUserInfoList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ViewerUserInfoList} ViewerUserInfoList
         */
        ViewerUserInfoList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ViewerUserInfoList)
                return d;
            var m = new $root.Proto.ViewerUserInfoList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ViewerUserInfoList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ViewerUserInfoList.value: object expected");
                    m.value[i] = $root.Proto.ViewerUserInfo.fromObject(d.value[i]);
                }
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.ViewerUserInfoList.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            return m;
        };

        /**
         * Creates a plain object from a ViewerUserInfoList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ViewerUserInfoList
         * @static
         * @param {Proto.ViewerUserInfoList} m ViewerUserInfoList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ViewerUserInfoList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (o.defaults) {
                d.user = null;
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ViewerUserInfo.toObject(m.value[j], o);
                }
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            return d;
        };

        /**
         * Converts this ViewerUserInfoList to JSON.
         * @function toJSON
         * @memberof Proto.ViewerUserInfoList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ViewerUserInfoList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ViewerUserInfoList;
    })();

    Proto.ViewerUserInfoPair = (function() {

        /**
         * Properties of a ViewerUserInfoPair.
         * @memberof Proto
         * @interface IViewerUserInfoPair
         * @property {Array.<Proto.IViewerUserForm>|null} [companyUserInfo] ViewerUserInfoPair companyUserInfo
         * @property {Array.<Proto.IViewerUserInfo>|null} [userInfo] ViewerUserInfoPair userInfo
         * @property {Proto.IUser|null} [user] ViewerUserInfoPair user
         */

        /**
         * Constructs a new ViewerUserInfoPair.
         * @memberof Proto
         * @classdesc Represents a ViewerUserInfoPair.
         * @implements IViewerUserInfoPair
         * @constructor
         * @param {Proto.IViewerUserInfoPair=} [p] Properties to set
         */
        function ViewerUserInfoPair(p) {
            this.companyUserInfo = [];
            this.userInfo = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ViewerUserInfoPair companyUserInfo.
         * @member {Array.<Proto.IViewerUserForm>} companyUserInfo
         * @memberof Proto.ViewerUserInfoPair
         * @instance
         */
        ViewerUserInfoPair.prototype.companyUserInfo = $util.emptyArray;

        /**
         * ViewerUserInfoPair userInfo.
         * @member {Array.<Proto.IViewerUserInfo>} userInfo
         * @memberof Proto.ViewerUserInfoPair
         * @instance
         */
        ViewerUserInfoPair.prototype.userInfo = $util.emptyArray;

        /**
         * ViewerUserInfoPair user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.ViewerUserInfoPair
         * @instance
         */
        ViewerUserInfoPair.prototype.user = null;

        /**
         * Creates a new ViewerUserInfoPair instance using the specified properties.
         * @function create
         * @memberof Proto.ViewerUserInfoPair
         * @static
         * @param {Proto.IViewerUserInfoPair=} [properties] Properties to set
         * @returns {Proto.ViewerUserInfoPair} ViewerUserInfoPair instance
         */
        ViewerUserInfoPair.create = function create(properties) {
            return new ViewerUserInfoPair(properties);
        };

        /**
         * Encodes the specified ViewerUserInfoPair message. Does not implicitly {@link Proto.ViewerUserInfoPair.verify|verify} messages.
         * @function encode
         * @memberof Proto.ViewerUserInfoPair
         * @static
         * @param {Proto.IViewerUserInfoPair} m ViewerUserInfoPair message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ViewerUserInfoPair.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.companyUserInfo != null && m.companyUserInfo.length) {
                for (var i = 0; i < m.companyUserInfo.length; ++i)
                    $root.Proto.ViewerUserForm.encode(m.companyUserInfo[i], w.uint32(10).fork()).ldelim();
            }
            if (m.userInfo != null && m.userInfo.length) {
                for (var i = 0; i < m.userInfo.length; ++i)
                    $root.Proto.ViewerUserInfo.encode(m.userInfo[i], w.uint32(18).fork()).ldelim();
            }
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(26).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a ViewerUserInfoPair message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ViewerUserInfoPair
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ViewerUserInfoPair} ViewerUserInfoPair
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ViewerUserInfoPair.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ViewerUserInfoPair();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.companyUserInfo && m.companyUserInfo.length))
                        m.companyUserInfo = [];
                    m.companyUserInfo.push($root.Proto.ViewerUserForm.decode(r, r.uint32()));
                    break;
                case 2:
                    if (!(m.userInfo && m.userInfo.length))
                        m.userInfo = [];
                    m.userInfo.push($root.Proto.ViewerUserInfo.decode(r, r.uint32()));
                    break;
                case 3:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ViewerUserInfoPair message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ViewerUserInfoPair
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ViewerUserInfoPair} ViewerUserInfoPair
         */
        ViewerUserInfoPair.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ViewerUserInfoPair)
                return d;
            var m = new $root.Proto.ViewerUserInfoPair();
            if (d.companyUserInfo) {
                if (!Array.isArray(d.companyUserInfo))
                    throw TypeError(".Proto.ViewerUserInfoPair.companyUserInfo: array expected");
                m.companyUserInfo = [];
                for (var i = 0; i < d.companyUserInfo.length; ++i) {
                    if (typeof d.companyUserInfo[i] !== "object")
                        throw TypeError(".Proto.ViewerUserInfoPair.companyUserInfo: object expected");
                    m.companyUserInfo[i] = $root.Proto.ViewerUserForm.fromObject(d.companyUserInfo[i]);
                }
            }
            if (d.userInfo) {
                if (!Array.isArray(d.userInfo))
                    throw TypeError(".Proto.ViewerUserInfoPair.userInfo: array expected");
                m.userInfo = [];
                for (var i = 0; i < d.userInfo.length; ++i) {
                    if (typeof d.userInfo[i] !== "object")
                        throw TypeError(".Proto.ViewerUserInfoPair.userInfo: object expected");
                    m.userInfo[i] = $root.Proto.ViewerUserInfo.fromObject(d.userInfo[i]);
                }
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.ViewerUserInfoPair.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            return m;
        };

        /**
         * Creates a plain object from a ViewerUserInfoPair message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ViewerUserInfoPair
         * @static
         * @param {Proto.ViewerUserInfoPair} m ViewerUserInfoPair
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ViewerUserInfoPair.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.companyUserInfo = [];
                d.userInfo = [];
            }
            if (o.defaults) {
                d.user = null;
            }
            if (m.companyUserInfo && m.companyUserInfo.length) {
                d.companyUserInfo = [];
                for (var j = 0; j < m.companyUserInfo.length; ++j) {
                    d.companyUserInfo[j] = $root.Proto.ViewerUserForm.toObject(m.companyUserInfo[j], o);
                }
            }
            if (m.userInfo && m.userInfo.length) {
                d.userInfo = [];
                for (var j = 0; j < m.userInfo.length; ++j) {
                    d.userInfo[j] = $root.Proto.ViewerUserInfo.toObject(m.userInfo[j], o);
                }
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            return d;
        };

        /**
         * Converts this ViewerUserInfoPair to JSON.
         * @function toJSON
         * @memberof Proto.ViewerUserInfoPair
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ViewerUserInfoPair.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ViewerUserInfoPair;
    })();

    Proto.PaymentCharge = (function() {

        /**
         * Properties of a PaymentCharge.
         * @memberof Proto
         * @interface IPaymentCharge
         * @property {string|null} [orderId] PaymentCharge orderId
         * @property {string|null} [transactionId] PaymentCharge transactionId
         * @property {string|null} [receipt] PaymentCharge receipt
         * @property {string|null} [originalId] PaymentCharge originalId
         */

        /**
         * Constructs a new PaymentCharge.
         * @memberof Proto
         * @classdesc Represents a PaymentCharge.
         * @implements IPaymentCharge
         * @constructor
         * @param {Proto.IPaymentCharge=} [p] Properties to set
         */
        function PaymentCharge(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PaymentCharge orderId.
         * @member {string} orderId
         * @memberof Proto.PaymentCharge
         * @instance
         */
        PaymentCharge.prototype.orderId = "";

        /**
         * PaymentCharge transactionId.
         * @member {string} transactionId
         * @memberof Proto.PaymentCharge
         * @instance
         */
        PaymentCharge.prototype.transactionId = "";

        /**
         * PaymentCharge receipt.
         * @member {string} receipt
         * @memberof Proto.PaymentCharge
         * @instance
         */
        PaymentCharge.prototype.receipt = "";

        /**
         * PaymentCharge originalId.
         * @member {string} originalId
         * @memberof Proto.PaymentCharge
         * @instance
         */
        PaymentCharge.prototype.originalId = "";

        /**
         * Creates a new PaymentCharge instance using the specified properties.
         * @function create
         * @memberof Proto.PaymentCharge
         * @static
         * @param {Proto.IPaymentCharge=} [properties] Properties to set
         * @returns {Proto.PaymentCharge} PaymentCharge instance
         */
        PaymentCharge.create = function create(properties) {
            return new PaymentCharge(properties);
        };

        /**
         * Encodes the specified PaymentCharge message. Does not implicitly {@link Proto.PaymentCharge.verify|verify} messages.
         * @function encode
         * @memberof Proto.PaymentCharge
         * @static
         * @param {Proto.IPaymentCharge} m PaymentCharge message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentCharge.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.orderId != null && Object.hasOwnProperty.call(m, "orderId"))
                w.uint32(10).string(m.orderId);
            if (m.transactionId != null && Object.hasOwnProperty.call(m, "transactionId"))
                w.uint32(18).string(m.transactionId);
            if (m.receipt != null && Object.hasOwnProperty.call(m, "receipt"))
                w.uint32(26).string(m.receipt);
            if (m.originalId != null && Object.hasOwnProperty.call(m, "originalId"))
                w.uint32(34).string(m.originalId);
            return w;
        };

        /**
         * Decodes a PaymentCharge message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.PaymentCharge
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.PaymentCharge} PaymentCharge
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentCharge.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.PaymentCharge();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.orderId = r.string();
                    break;
                case 2:
                    m.transactionId = r.string();
                    break;
                case 3:
                    m.receipt = r.string();
                    break;
                case 4:
                    m.originalId = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PaymentCharge message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.PaymentCharge
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.PaymentCharge} PaymentCharge
         */
        PaymentCharge.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.PaymentCharge)
                return d;
            var m = new $root.Proto.PaymentCharge();
            if (d.orderId != null) {
                m.orderId = String(d.orderId);
            }
            if (d.transactionId != null) {
                m.transactionId = String(d.transactionId);
            }
            if (d.receipt != null) {
                m.receipt = String(d.receipt);
            }
            if (d.originalId != null) {
                m.originalId = String(d.originalId);
            }
            return m;
        };

        /**
         * Creates a plain object from a PaymentCharge message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.PaymentCharge
         * @static
         * @param {Proto.PaymentCharge} m PaymentCharge
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaymentCharge.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.orderId = "";
                d.transactionId = "";
                d.receipt = "";
                d.originalId = "";
            }
            if (m.orderId != null && m.hasOwnProperty("orderId")) {
                d.orderId = m.orderId;
            }
            if (m.transactionId != null && m.hasOwnProperty("transactionId")) {
                d.transactionId = m.transactionId;
            }
            if (m.receipt != null && m.hasOwnProperty("receipt")) {
                d.receipt = m.receipt;
            }
            if (m.originalId != null && m.hasOwnProperty("originalId")) {
                d.originalId = m.originalId;
            }
            return d;
        };

        /**
         * Converts this PaymentCharge to JSON.
         * @function toJSON
         * @memberof Proto.PaymentCharge
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaymentCharge.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PaymentCharge;
    })();

    Proto.ContentComment = (function() {

        /**
         * Properties of a ContentComment.
         * @memberof Proto
         * @interface IContentComment
         * @property {string|null} [uid] ContentComment uid
         * @property {string|null} [text] ContentComment text
         * @property {boolean|null} [owner] ContentComment owner
         * @property {Proto.IUploadFile|null} [userIcon] ContentComment userIcon
         * @property {string|null} [userName] ContentComment userName
         * @property {number|Long|null} [registerDate] ContentComment registerDate
         * @property {number|Long|null} [modifyDate] ContentComment modifyDate
         * @property {string|null} [parentUID] ContentComment parentUID
         * @property {string|null} [likeCount] ContentComment likeCount
         * @property {string|null} [commentCount] ContentComment commentCount
         * @property {boolean|null} [liked] ContentComment liked
         * @property {boolean|null} [visible] ContentComment visible
         * @property {string|null} [contentUID] ContentComment contentUID
         * @property {boolean|null} [blocked] ContentComment blocked
         * @property {string|null} [reactionCount] ContentComment reactionCount
         * @property {Array.<Proto.IContentMapGroup>|null} [mapGroupList] ContentComment mapGroupList
         * @property {Array.<Proto.IContentCommentReaction>|null} [reactionGroupList] ContentComment reactionGroupList
         * @property {Array.<Proto.IUploadFile>|null} [fileList] ContentComment fileList
         * @property {string|null} [targetUserUIDJson] ContentComment targetUserUIDJson
         * @property {Array.<Proto.IUser>|null} [targetUser] ContentComment targetUser
         */

        /**
         * Constructs a new ContentComment.
         * @memberof Proto
         * @classdesc Represents a ContentComment.
         * @implements IContentComment
         * @constructor
         * @param {Proto.IContentComment=} [p] Properties to set
         */
        function ContentComment(p) {
            this.mapGroupList = [];
            this.reactionGroupList = [];
            this.fileList = [];
            this.targetUser = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentComment uid.
         * @member {string} uid
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.uid = "";

        /**
         * ContentComment text.
         * @member {string} text
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.text = "";

        /**
         * ContentComment owner.
         * @member {boolean} owner
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.owner = false;

        /**
         * ContentComment userIcon.
         * @member {Proto.IUploadFile|null|undefined} userIcon
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.userIcon = null;

        /**
         * ContentComment userName.
         * @member {string} userName
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.userName = "";

        /**
         * ContentComment registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentComment modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentComment parentUID.
         * @member {string} parentUID
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.parentUID = "";

        /**
         * ContentComment likeCount.
         * @member {string} likeCount
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.likeCount = "";

        /**
         * ContentComment commentCount.
         * @member {string} commentCount
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.commentCount = "";

        /**
         * ContentComment liked.
         * @member {boolean} liked
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.liked = false;

        /**
         * ContentComment visible.
         * @member {boolean} visible
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.visible = false;

        /**
         * ContentComment contentUID.
         * @member {string} contentUID
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.contentUID = "";

        /**
         * ContentComment blocked.
         * @member {boolean} blocked
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.blocked = false;

        /**
         * ContentComment reactionCount.
         * @member {string} reactionCount
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.reactionCount = "";

        /**
         * ContentComment mapGroupList.
         * @member {Array.<Proto.IContentMapGroup>} mapGroupList
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.mapGroupList = $util.emptyArray;

        /**
         * ContentComment reactionGroupList.
         * @member {Array.<Proto.IContentCommentReaction>} reactionGroupList
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.reactionGroupList = $util.emptyArray;

        /**
         * ContentComment fileList.
         * @member {Array.<Proto.IUploadFile>} fileList
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.fileList = $util.emptyArray;

        /**
         * ContentComment targetUserUIDJson.
         * @member {string} targetUserUIDJson
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.targetUserUIDJson = "";

        /**
         * ContentComment targetUser.
         * @member {Array.<Proto.IUser>} targetUser
         * @memberof Proto.ContentComment
         * @instance
         */
        ContentComment.prototype.targetUser = $util.emptyArray;

        /**
         * Creates a new ContentComment instance using the specified properties.
         * @function create
         * @memberof Proto.ContentComment
         * @static
         * @param {Proto.IContentComment=} [properties] Properties to set
         * @returns {Proto.ContentComment} ContentComment instance
         */
        ContentComment.create = function create(properties) {
            return new ContentComment(properties);
        };

        /**
         * Encodes the specified ContentComment message. Does not implicitly {@link Proto.ContentComment.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentComment
         * @static
         * @param {Proto.IContentComment} m ContentComment message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentComment.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(18).string(m.text);
            if (m.owner != null && Object.hasOwnProperty.call(m, "owner"))
                w.uint32(24).bool(m.owner);
            if (m.userIcon != null && Object.hasOwnProperty.call(m, "userIcon"))
                $root.Proto.UploadFile.encode(m.userIcon, w.uint32(34).fork()).ldelim();
            if (m.userName != null && Object.hasOwnProperty.call(m, "userName"))
                w.uint32(42).string(m.userName);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.parentUID != null && Object.hasOwnProperty.call(m, "parentUID"))
                w.uint32(66).string(m.parentUID);
            if (m.likeCount != null && Object.hasOwnProperty.call(m, "likeCount"))
                w.uint32(74).string(m.likeCount);
            if (m.commentCount != null && Object.hasOwnProperty.call(m, "commentCount"))
                w.uint32(82).string(m.commentCount);
            if (m.liked != null && Object.hasOwnProperty.call(m, "liked"))
                w.uint32(88).bool(m.liked);
            if (m.visible != null && Object.hasOwnProperty.call(m, "visible"))
                w.uint32(96).bool(m.visible);
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(106).string(m.contentUID);
            if (m.blocked != null && Object.hasOwnProperty.call(m, "blocked"))
                w.uint32(112).bool(m.blocked);
            if (m.reactionCount != null && Object.hasOwnProperty.call(m, "reactionCount"))
                w.uint32(122).string(m.reactionCount);
            if (m.mapGroupList != null && m.mapGroupList.length) {
                for (var i = 0; i < m.mapGroupList.length; ++i)
                    $root.Proto.ContentMapGroup.encode(m.mapGroupList[i], w.uint32(274).fork()).ldelim();
            }
            if (m.reactionGroupList != null && m.reactionGroupList.length) {
                for (var i = 0; i < m.reactionGroupList.length; ++i)
                    $root.Proto.ContentCommentReaction.encode(m.reactionGroupList[i], w.uint32(282).fork()).ldelim();
            }
            if (m.fileList != null && m.fileList.length) {
                for (var i = 0; i < m.fileList.length; ++i)
                    $root.Proto.UploadFile.encode(m.fileList[i], w.uint32(290).fork()).ldelim();
            }
            if (m.targetUserUIDJson != null && Object.hasOwnProperty.call(m, "targetUserUIDJson"))
                w.uint32(298).string(m.targetUserUIDJson);
            if (m.targetUser != null && m.targetUser.length) {
                for (var i = 0; i < m.targetUser.length; ++i)
                    $root.Proto.User.encode(m.targetUser[i], w.uint32(306).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentComment message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentComment} ContentComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentComment.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentComment();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.text = r.string();
                    break;
                case 3:
                    m.owner = r.bool();
                    break;
                case 4:
                    m.userIcon = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 5:
                    m.userName = r.string();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.parentUID = r.string();
                    break;
                case 9:
                    m.likeCount = r.string();
                    break;
                case 10:
                    m.commentCount = r.string();
                    break;
                case 11:
                    m.liked = r.bool();
                    break;
                case 12:
                    m.visible = r.bool();
                    break;
                case 13:
                    m.contentUID = r.string();
                    break;
                case 14:
                    m.blocked = r.bool();
                    break;
                case 15:
                    m.reactionCount = r.string();
                    break;
                case 34:
                    if (!(m.mapGroupList && m.mapGroupList.length))
                        m.mapGroupList = [];
                    m.mapGroupList.push($root.Proto.ContentMapGroup.decode(r, r.uint32()));
                    break;
                case 35:
                    if (!(m.reactionGroupList && m.reactionGroupList.length))
                        m.reactionGroupList = [];
                    m.reactionGroupList.push($root.Proto.ContentCommentReaction.decode(r, r.uint32()));
                    break;
                case 36:
                    if (!(m.fileList && m.fileList.length))
                        m.fileList = [];
                    m.fileList.push($root.Proto.UploadFile.decode(r, r.uint32()));
                    break;
                case 37:
                    m.targetUserUIDJson = r.string();
                    break;
                case 38:
                    if (!(m.targetUser && m.targetUser.length))
                        m.targetUser = [];
                    m.targetUser.push($root.Proto.User.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentComment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentComment
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentComment} ContentComment
         */
        ContentComment.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentComment)
                return d;
            var m = new $root.Proto.ContentComment();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.owner != null) {
                m.owner = Boolean(d.owner);
            }
            if (d.userIcon != null) {
                if (typeof d.userIcon !== "object")
                    throw TypeError(".Proto.ContentComment.userIcon: object expected");
                m.userIcon = $root.Proto.UploadFile.fromObject(d.userIcon);
            }
            if (d.userName != null) {
                m.userName = String(d.userName);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.parentUID != null) {
                m.parentUID = String(d.parentUID);
            }
            if (d.likeCount != null) {
                m.likeCount = String(d.likeCount);
            }
            if (d.commentCount != null) {
                m.commentCount = String(d.commentCount);
            }
            if (d.liked != null) {
                m.liked = Boolean(d.liked);
            }
            if (d.visible != null) {
                m.visible = Boolean(d.visible);
            }
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.blocked != null) {
                m.blocked = Boolean(d.blocked);
            }
            if (d.reactionCount != null) {
                m.reactionCount = String(d.reactionCount);
            }
            if (d.mapGroupList) {
                if (!Array.isArray(d.mapGroupList))
                    throw TypeError(".Proto.ContentComment.mapGroupList: array expected");
                m.mapGroupList = [];
                for (var i = 0; i < d.mapGroupList.length; ++i) {
                    if (typeof d.mapGroupList[i] !== "object")
                        throw TypeError(".Proto.ContentComment.mapGroupList: object expected");
                    m.mapGroupList[i] = $root.Proto.ContentMapGroup.fromObject(d.mapGroupList[i]);
                }
            }
            if (d.reactionGroupList) {
                if (!Array.isArray(d.reactionGroupList))
                    throw TypeError(".Proto.ContentComment.reactionGroupList: array expected");
                m.reactionGroupList = [];
                for (var i = 0; i < d.reactionGroupList.length; ++i) {
                    if (typeof d.reactionGroupList[i] !== "object")
                        throw TypeError(".Proto.ContentComment.reactionGroupList: object expected");
                    m.reactionGroupList[i] = $root.Proto.ContentCommentReaction.fromObject(d.reactionGroupList[i]);
                }
            }
            if (d.fileList) {
                if (!Array.isArray(d.fileList))
                    throw TypeError(".Proto.ContentComment.fileList: array expected");
                m.fileList = [];
                for (var i = 0; i < d.fileList.length; ++i) {
                    if (typeof d.fileList[i] !== "object")
                        throw TypeError(".Proto.ContentComment.fileList: object expected");
                    m.fileList[i] = $root.Proto.UploadFile.fromObject(d.fileList[i]);
                }
            }
            if (d.targetUserUIDJson != null) {
                m.targetUserUIDJson = String(d.targetUserUIDJson);
            }
            if (d.targetUser) {
                if (!Array.isArray(d.targetUser))
                    throw TypeError(".Proto.ContentComment.targetUser: array expected");
                m.targetUser = [];
                for (var i = 0; i < d.targetUser.length; ++i) {
                    if (typeof d.targetUser[i] !== "object")
                        throw TypeError(".Proto.ContentComment.targetUser: object expected");
                    m.targetUser[i] = $root.Proto.User.fromObject(d.targetUser[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentComment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentComment
         * @static
         * @param {Proto.ContentComment} m ContentComment
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentComment.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.mapGroupList = [];
                d.reactionGroupList = [];
                d.fileList = [];
                d.targetUser = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.text = "";
                d.owner = false;
                d.userIcon = null;
                d.userName = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.parentUID = "";
                d.likeCount = "";
                d.commentCount = "";
                d.liked = false;
                d.visible = false;
                d.contentUID = "";
                d.blocked = false;
                d.reactionCount = "";
                d.targetUserUIDJson = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.owner != null && m.hasOwnProperty("owner")) {
                d.owner = m.owner;
            }
            if (m.userIcon != null && m.hasOwnProperty("userIcon")) {
                d.userIcon = $root.Proto.UploadFile.toObject(m.userIcon, o);
            }
            if (m.userName != null && m.hasOwnProperty("userName")) {
                d.userName = m.userName;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.parentUID != null && m.hasOwnProperty("parentUID")) {
                d.parentUID = m.parentUID;
            }
            if (m.likeCount != null && m.hasOwnProperty("likeCount")) {
                d.likeCount = m.likeCount;
            }
            if (m.commentCount != null && m.hasOwnProperty("commentCount")) {
                d.commentCount = m.commentCount;
            }
            if (m.liked != null && m.hasOwnProperty("liked")) {
                d.liked = m.liked;
            }
            if (m.visible != null && m.hasOwnProperty("visible")) {
                d.visible = m.visible;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.blocked != null && m.hasOwnProperty("blocked")) {
                d.blocked = m.blocked;
            }
            if (m.reactionCount != null && m.hasOwnProperty("reactionCount")) {
                d.reactionCount = m.reactionCount;
            }
            if (m.mapGroupList && m.mapGroupList.length) {
                d.mapGroupList = [];
                for (var j = 0; j < m.mapGroupList.length; ++j) {
                    d.mapGroupList[j] = $root.Proto.ContentMapGroup.toObject(m.mapGroupList[j], o);
                }
            }
            if (m.reactionGroupList && m.reactionGroupList.length) {
                d.reactionGroupList = [];
                for (var j = 0; j < m.reactionGroupList.length; ++j) {
                    d.reactionGroupList[j] = $root.Proto.ContentCommentReaction.toObject(m.reactionGroupList[j], o);
                }
            }
            if (m.fileList && m.fileList.length) {
                d.fileList = [];
                for (var j = 0; j < m.fileList.length; ++j) {
                    d.fileList[j] = $root.Proto.UploadFile.toObject(m.fileList[j], o);
                }
            }
            if (m.targetUserUIDJson != null && m.hasOwnProperty("targetUserUIDJson")) {
                d.targetUserUIDJson = m.targetUserUIDJson;
            }
            if (m.targetUser && m.targetUser.length) {
                d.targetUser = [];
                for (var j = 0; j < m.targetUser.length; ++j) {
                    d.targetUser[j] = $root.Proto.User.toObject(m.targetUser[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentComment to JSON.
         * @function toJSON
         * @memberof Proto.ContentComment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentComment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentComment;
    })();

    Proto.ContentCommentList = (function() {

        /**
         * Properties of a ContentCommentList.
         * @memberof Proto
         * @interface IContentCommentList
         * @property {Array.<Proto.IContentComment>|null} [value] ContentCommentList value
         */

        /**
         * Constructs a new ContentCommentList.
         * @memberof Proto
         * @classdesc Represents a ContentCommentList.
         * @implements IContentCommentList
         * @constructor
         * @param {Proto.IContentCommentList=} [p] Properties to set
         */
        function ContentCommentList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentCommentList value.
         * @member {Array.<Proto.IContentComment>} value
         * @memberof Proto.ContentCommentList
         * @instance
         */
        ContentCommentList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentCommentList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentCommentList
         * @static
         * @param {Proto.IContentCommentList=} [properties] Properties to set
         * @returns {Proto.ContentCommentList} ContentCommentList instance
         */
        ContentCommentList.create = function create(properties) {
            return new ContentCommentList(properties);
        };

        /**
         * Encodes the specified ContentCommentList message. Does not implicitly {@link Proto.ContentCommentList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentCommentList
         * @static
         * @param {Proto.IContentCommentList} m ContentCommentList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentCommentList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentComment.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentCommentList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentCommentList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentCommentList} ContentCommentList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentCommentList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentCommentList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentComment.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentCommentList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentCommentList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentCommentList} ContentCommentList
         */
        ContentCommentList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentCommentList)
                return d;
            var m = new $root.Proto.ContentCommentList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentCommentList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentCommentList.value: object expected");
                    m.value[i] = $root.Proto.ContentComment.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentCommentList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentCommentList
         * @static
         * @param {Proto.ContentCommentList} m ContentCommentList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentCommentList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentComment.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentCommentList to JSON.
         * @function toJSON
         * @memberof Proto.ContentCommentList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentCommentList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentCommentList;
    })();

    Proto.ContentViolate = (function() {

        /**
         * Properties of a ContentViolate.
         * @memberof Proto
         * @interface IContentViolate
         * @property {string|null} [contentUID] ContentViolate contentUID
         * @property {number|null} [type] ContentViolate type
         * @property {string|null} [text] ContentViolate text
         * @property {Proto.IContent|null} [content] ContentViolate content
         * @property {string|null} [companyUID] ContentViolate companyUID
         * @property {string|null} [companyName] ContentViolate companyName
         * @property {number|Long|null} [registerDate] ContentViolate registerDate
         * @property {number|Long|null} [modifyDate] ContentViolate modifyDate
         */

        /**
         * Constructs a new ContentViolate.
         * @memberof Proto
         * @classdesc Represents a ContentViolate.
         * @implements IContentViolate
         * @constructor
         * @param {Proto.IContentViolate=} [p] Properties to set
         */
        function ContentViolate(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentViolate contentUID.
         * @member {string} contentUID
         * @memberof Proto.ContentViolate
         * @instance
         */
        ContentViolate.prototype.contentUID = "";

        /**
         * ContentViolate type.
         * @member {number} type
         * @memberof Proto.ContentViolate
         * @instance
         */
        ContentViolate.prototype.type = 0;

        /**
         * ContentViolate text.
         * @member {string} text
         * @memberof Proto.ContentViolate
         * @instance
         */
        ContentViolate.prototype.text = "";

        /**
         * ContentViolate content.
         * @member {Proto.IContent|null|undefined} content
         * @memberof Proto.ContentViolate
         * @instance
         */
        ContentViolate.prototype.content = null;

        /**
         * ContentViolate companyUID.
         * @member {string} companyUID
         * @memberof Proto.ContentViolate
         * @instance
         */
        ContentViolate.prototype.companyUID = "";

        /**
         * ContentViolate companyName.
         * @member {string} companyName
         * @memberof Proto.ContentViolate
         * @instance
         */
        ContentViolate.prototype.companyName = "";

        /**
         * ContentViolate registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentViolate
         * @instance
         */
        ContentViolate.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentViolate modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentViolate
         * @instance
         */
        ContentViolate.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new ContentViolate instance using the specified properties.
         * @function create
         * @memberof Proto.ContentViolate
         * @static
         * @param {Proto.IContentViolate=} [properties] Properties to set
         * @returns {Proto.ContentViolate} ContentViolate instance
         */
        ContentViolate.create = function create(properties) {
            return new ContentViolate(properties);
        };

        /**
         * Encodes the specified ContentViolate message. Does not implicitly {@link Proto.ContentViolate.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentViolate
         * @static
         * @param {Proto.IContentViolate} m ContentViolate message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentViolate.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(10).string(m.contentUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.content != null && Object.hasOwnProperty.call(m, "content"))
                $root.Proto.Content.encode(m.content, w.uint32(34).fork()).ldelim();
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(42).string(m.companyUID);
            if (m.companyName != null && Object.hasOwnProperty.call(m, "companyName"))
                w.uint32(50).string(m.companyName);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(56).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(64).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a ContentViolate message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentViolate
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentViolate} ContentViolate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentViolate.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentViolate();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.contentUID = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.content = $root.Proto.Content.decode(r, r.uint32());
                    break;
                case 5:
                    m.companyUID = r.string();
                    break;
                case 6:
                    m.companyName = r.string();
                    break;
                case 7:
                    m.registerDate = r.int64();
                    break;
                case 8:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentViolate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentViolate
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentViolate} ContentViolate
         */
        ContentViolate.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentViolate)
                return d;
            var m = new $root.Proto.ContentViolate();
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.content != null) {
                if (typeof d.content !== "object")
                    throw TypeError(".Proto.ContentViolate.content: object expected");
                m.content = $root.Proto.Content.fromObject(d.content);
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.companyName != null) {
                m.companyName = String(d.companyName);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentViolate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentViolate
         * @static
         * @param {Proto.ContentViolate} m ContentViolate
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentViolate.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.contentUID = "";
                d.type = 0;
                d.text = "";
                d.content = null;
                d.companyUID = "";
                d.companyName = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.content != null && m.hasOwnProperty("content")) {
                d.content = $root.Proto.Content.toObject(m.content, o);
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.companyName != null && m.hasOwnProperty("companyName")) {
                d.companyName = m.companyName;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this ContentViolate to JSON.
         * @function toJSON
         * @memberof Proto.ContentViolate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentViolate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentViolate;
    })();

    Proto.ContentViolateList = (function() {

        /**
         * Properties of a ContentViolateList.
         * @memberof Proto
         * @interface IContentViolateList
         * @property {Array.<Proto.IContentViolate>|null} [value] ContentViolateList value
         */

        /**
         * Constructs a new ContentViolateList.
         * @memberof Proto
         * @classdesc Represents a ContentViolateList.
         * @implements IContentViolateList
         * @constructor
         * @param {Proto.IContentViolateList=} [p] Properties to set
         */
        function ContentViolateList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentViolateList value.
         * @member {Array.<Proto.IContentViolate>} value
         * @memberof Proto.ContentViolateList
         * @instance
         */
        ContentViolateList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentViolateList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentViolateList
         * @static
         * @param {Proto.IContentViolateList=} [properties] Properties to set
         * @returns {Proto.ContentViolateList} ContentViolateList instance
         */
        ContentViolateList.create = function create(properties) {
            return new ContentViolateList(properties);
        };

        /**
         * Encodes the specified ContentViolateList message. Does not implicitly {@link Proto.ContentViolateList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentViolateList
         * @static
         * @param {Proto.IContentViolateList} m ContentViolateList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentViolateList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentViolate.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentViolateList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentViolateList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentViolateList} ContentViolateList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentViolateList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentViolateList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentViolate.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentViolateList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentViolateList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentViolateList} ContentViolateList
         */
        ContentViolateList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentViolateList)
                return d;
            var m = new $root.Proto.ContentViolateList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentViolateList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentViolateList.value: object expected");
                    m.value[i] = $root.Proto.ContentViolate.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentViolateList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentViolateList
         * @static
         * @param {Proto.ContentViolateList} m ContentViolateList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentViolateList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentViolate.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentViolateList to JSON.
         * @function toJSON
         * @memberof Proto.ContentViolateList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentViolateList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentViolateList;
    })();

    Proto.TopicViolate = (function() {

        /**
         * Properties of a TopicViolate.
         * @memberof Proto
         * @interface ITopicViolate
         * @property {string|null} [topicUID] TopicViolate topicUID
         * @property {number|null} [type] TopicViolate type
         * @property {string|null} [text] TopicViolate text
         * @property {string|null} [companyUID] TopicViolate companyUID
         * @property {string|null} [companyName] TopicViolate companyName
         * @property {number|Long|null} [registerDate] TopicViolate registerDate
         * @property {number|Long|null} [modifyDate] TopicViolate modifyDate
         * @property {Proto.ICommunityBoardTopic|null} [topic] TopicViolate topic
         */

        /**
         * Constructs a new TopicViolate.
         * @memberof Proto
         * @classdesc Represents a TopicViolate.
         * @implements ITopicViolate
         * @constructor
         * @param {Proto.ITopicViolate=} [p] Properties to set
         */
        function TopicViolate(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TopicViolate topicUID.
         * @member {string} topicUID
         * @memberof Proto.TopicViolate
         * @instance
         */
        TopicViolate.prototype.topicUID = "";

        /**
         * TopicViolate type.
         * @member {number} type
         * @memberof Proto.TopicViolate
         * @instance
         */
        TopicViolate.prototype.type = 0;

        /**
         * TopicViolate text.
         * @member {string} text
         * @memberof Proto.TopicViolate
         * @instance
         */
        TopicViolate.prototype.text = "";

        /**
         * TopicViolate companyUID.
         * @member {string} companyUID
         * @memberof Proto.TopicViolate
         * @instance
         */
        TopicViolate.prototype.companyUID = "";

        /**
         * TopicViolate companyName.
         * @member {string} companyName
         * @memberof Proto.TopicViolate
         * @instance
         */
        TopicViolate.prototype.companyName = "";

        /**
         * TopicViolate registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.TopicViolate
         * @instance
         */
        TopicViolate.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TopicViolate modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.TopicViolate
         * @instance
         */
        TopicViolate.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TopicViolate topic.
         * @member {Proto.ICommunityBoardTopic|null|undefined} topic
         * @memberof Proto.TopicViolate
         * @instance
         */
        TopicViolate.prototype.topic = null;

        /**
         * Creates a new TopicViolate instance using the specified properties.
         * @function create
         * @memberof Proto.TopicViolate
         * @static
         * @param {Proto.ITopicViolate=} [properties] Properties to set
         * @returns {Proto.TopicViolate} TopicViolate instance
         */
        TopicViolate.create = function create(properties) {
            return new TopicViolate(properties);
        };

        /**
         * Encodes the specified TopicViolate message. Does not implicitly {@link Proto.TopicViolate.verify|verify} messages.
         * @function encode
         * @memberof Proto.TopicViolate
         * @static
         * @param {Proto.ITopicViolate} m TopicViolate message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TopicViolate.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.topicUID != null && Object.hasOwnProperty.call(m, "topicUID"))
                w.uint32(10).string(m.topicUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(34).string(m.companyUID);
            if (m.companyName != null && Object.hasOwnProperty.call(m, "companyName"))
                w.uint32(42).string(m.companyName);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.topic != null && Object.hasOwnProperty.call(m, "topic"))
                $root.Proto.CommunityBoardTopic.encode(m.topic, w.uint32(66).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a TopicViolate message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TopicViolate
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TopicViolate} TopicViolate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TopicViolate.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TopicViolate();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.topicUID = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.companyUID = r.string();
                    break;
                case 5:
                    m.companyName = r.string();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.topic = $root.Proto.CommunityBoardTopic.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TopicViolate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TopicViolate
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TopicViolate} TopicViolate
         */
        TopicViolate.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TopicViolate)
                return d;
            var m = new $root.Proto.TopicViolate();
            if (d.topicUID != null) {
                m.topicUID = String(d.topicUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.companyName != null) {
                m.companyName = String(d.companyName);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.topic != null) {
                if (typeof d.topic !== "object")
                    throw TypeError(".Proto.TopicViolate.topic: object expected");
                m.topic = $root.Proto.CommunityBoardTopic.fromObject(d.topic);
            }
            return m;
        };

        /**
         * Creates a plain object from a TopicViolate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TopicViolate
         * @static
         * @param {Proto.TopicViolate} m TopicViolate
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TopicViolate.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.topicUID = "";
                d.type = 0;
                d.text = "";
                d.companyUID = "";
                d.companyName = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.topic = null;
            }
            if (m.topicUID != null && m.hasOwnProperty("topicUID")) {
                d.topicUID = m.topicUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.companyName != null && m.hasOwnProperty("companyName")) {
                d.companyName = m.companyName;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.topic != null && m.hasOwnProperty("topic")) {
                d.topic = $root.Proto.CommunityBoardTopic.toObject(m.topic, o);
            }
            return d;
        };

        /**
         * Converts this TopicViolate to JSON.
         * @function toJSON
         * @memberof Proto.TopicViolate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TopicViolate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TopicViolate;
    })();

    Proto.TopicViolateList = (function() {

        /**
         * Properties of a TopicViolateList.
         * @memberof Proto
         * @interface ITopicViolateList
         * @property {Array.<Proto.ITopicViolate>|null} [value] TopicViolateList value
         */

        /**
         * Constructs a new TopicViolateList.
         * @memberof Proto
         * @classdesc Represents a TopicViolateList.
         * @implements ITopicViolateList
         * @constructor
         * @param {Proto.ITopicViolateList=} [p] Properties to set
         */
        function TopicViolateList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TopicViolateList value.
         * @member {Array.<Proto.ITopicViolate>} value
         * @memberof Proto.TopicViolateList
         * @instance
         */
        TopicViolateList.prototype.value = $util.emptyArray;

        /**
         * Creates a new TopicViolateList instance using the specified properties.
         * @function create
         * @memberof Proto.TopicViolateList
         * @static
         * @param {Proto.ITopicViolateList=} [properties] Properties to set
         * @returns {Proto.TopicViolateList} TopicViolateList instance
         */
        TopicViolateList.create = function create(properties) {
            return new TopicViolateList(properties);
        };

        /**
         * Encodes the specified TopicViolateList message. Does not implicitly {@link Proto.TopicViolateList.verify|verify} messages.
         * @function encode
         * @memberof Proto.TopicViolateList
         * @static
         * @param {Proto.ITopicViolateList} m TopicViolateList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TopicViolateList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.TopicViolate.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a TopicViolateList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TopicViolateList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TopicViolateList} TopicViolateList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TopicViolateList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TopicViolateList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.TopicViolate.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TopicViolateList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TopicViolateList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TopicViolateList} TopicViolateList
         */
        TopicViolateList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TopicViolateList)
                return d;
            var m = new $root.Proto.TopicViolateList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.TopicViolateList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.TopicViolateList.value: object expected");
                    m.value[i] = $root.Proto.TopicViolate.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a TopicViolateList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TopicViolateList
         * @static
         * @param {Proto.TopicViolateList} m TopicViolateList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TopicViolateList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.TopicViolate.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this TopicViolateList to JSON.
         * @function toJSON
         * @memberof Proto.TopicViolateList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TopicViolateList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TopicViolateList;
    })();

    Proto.CommentViolate = (function() {

        /**
         * Properties of a CommentViolate.
         * @memberof Proto
         * @interface ICommentViolate
         * @property {string|null} [commentUID] CommentViolate commentUID
         * @property {number|null} [type] CommentViolate type
         * @property {string|null} [text] CommentViolate text
         * @property {string|null} [companyUID] CommentViolate companyUID
         * @property {string|null} [companyName] CommentViolate companyName
         * @property {number|Long|null} [registerDate] CommentViolate registerDate
         * @property {number|Long|null} [modifyDate] CommentViolate modifyDate
         * @property {Proto.ICommunityBoardTopic|null} [topic] CommentViolate topic
         * @property {Proto.ICommunityBoardComment|null} [comment] CommentViolate comment
         */

        /**
         * Constructs a new CommentViolate.
         * @memberof Proto
         * @classdesc Represents a CommentViolate.
         * @implements ICommentViolate
         * @constructor
         * @param {Proto.ICommentViolate=} [p] Properties to set
         */
        function CommentViolate(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommentViolate commentUID.
         * @member {string} commentUID
         * @memberof Proto.CommentViolate
         * @instance
         */
        CommentViolate.prototype.commentUID = "";

        /**
         * CommentViolate type.
         * @member {number} type
         * @memberof Proto.CommentViolate
         * @instance
         */
        CommentViolate.prototype.type = 0;

        /**
         * CommentViolate text.
         * @member {string} text
         * @memberof Proto.CommentViolate
         * @instance
         */
        CommentViolate.prototype.text = "";

        /**
         * CommentViolate companyUID.
         * @member {string} companyUID
         * @memberof Proto.CommentViolate
         * @instance
         */
        CommentViolate.prototype.companyUID = "";

        /**
         * CommentViolate companyName.
         * @member {string} companyName
         * @memberof Proto.CommentViolate
         * @instance
         */
        CommentViolate.prototype.companyName = "";

        /**
         * CommentViolate registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommentViolate
         * @instance
         */
        CommentViolate.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommentViolate modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommentViolate
         * @instance
         */
        CommentViolate.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommentViolate topic.
         * @member {Proto.ICommunityBoardTopic|null|undefined} topic
         * @memberof Proto.CommentViolate
         * @instance
         */
        CommentViolate.prototype.topic = null;

        /**
         * CommentViolate comment.
         * @member {Proto.ICommunityBoardComment|null|undefined} comment
         * @memberof Proto.CommentViolate
         * @instance
         */
        CommentViolate.prototype.comment = null;

        /**
         * Creates a new CommentViolate instance using the specified properties.
         * @function create
         * @memberof Proto.CommentViolate
         * @static
         * @param {Proto.ICommentViolate=} [properties] Properties to set
         * @returns {Proto.CommentViolate} CommentViolate instance
         */
        CommentViolate.create = function create(properties) {
            return new CommentViolate(properties);
        };

        /**
         * Encodes the specified CommentViolate message. Does not implicitly {@link Proto.CommentViolate.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommentViolate
         * @static
         * @param {Proto.ICommentViolate} m CommentViolate message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommentViolate.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.commentUID != null && Object.hasOwnProperty.call(m, "commentUID"))
                w.uint32(10).string(m.commentUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(34).string(m.companyUID);
            if (m.companyName != null && Object.hasOwnProperty.call(m, "companyName"))
                w.uint32(42).string(m.companyName);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.topic != null && Object.hasOwnProperty.call(m, "topic"))
                $root.Proto.CommunityBoardTopic.encode(m.topic, w.uint32(66).fork()).ldelim();
            if (m.comment != null && Object.hasOwnProperty.call(m, "comment"))
                $root.Proto.CommunityBoardComment.encode(m.comment, w.uint32(74).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a CommentViolate message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommentViolate
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommentViolate} CommentViolate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommentViolate.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommentViolate();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.commentUID = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.companyUID = r.string();
                    break;
                case 5:
                    m.companyName = r.string();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.topic = $root.Proto.CommunityBoardTopic.decode(r, r.uint32());
                    break;
                case 9:
                    m.comment = $root.Proto.CommunityBoardComment.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommentViolate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommentViolate
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommentViolate} CommentViolate
         */
        CommentViolate.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommentViolate)
                return d;
            var m = new $root.Proto.CommentViolate();
            if (d.commentUID != null) {
                m.commentUID = String(d.commentUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.companyName != null) {
                m.companyName = String(d.companyName);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.topic != null) {
                if (typeof d.topic !== "object")
                    throw TypeError(".Proto.CommentViolate.topic: object expected");
                m.topic = $root.Proto.CommunityBoardTopic.fromObject(d.topic);
            }
            if (d.comment != null) {
                if (typeof d.comment !== "object")
                    throw TypeError(".Proto.CommentViolate.comment: object expected");
                m.comment = $root.Proto.CommunityBoardComment.fromObject(d.comment);
            }
            return m;
        };

        /**
         * Creates a plain object from a CommentViolate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommentViolate
         * @static
         * @param {Proto.CommentViolate} m CommentViolate
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommentViolate.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.commentUID = "";
                d.type = 0;
                d.text = "";
                d.companyUID = "";
                d.companyName = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.topic = null;
                d.comment = null;
            }
            if (m.commentUID != null && m.hasOwnProperty("commentUID")) {
                d.commentUID = m.commentUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.companyName != null && m.hasOwnProperty("companyName")) {
                d.companyName = m.companyName;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.topic != null && m.hasOwnProperty("topic")) {
                d.topic = $root.Proto.CommunityBoardTopic.toObject(m.topic, o);
            }
            if (m.comment != null && m.hasOwnProperty("comment")) {
                d.comment = $root.Proto.CommunityBoardComment.toObject(m.comment, o);
            }
            return d;
        };

        /**
         * Converts this CommentViolate to JSON.
         * @function toJSON
         * @memberof Proto.CommentViolate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommentViolate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommentViolate;
    })();

    Proto.CommentViolateList = (function() {

        /**
         * Properties of a CommentViolateList.
         * @memberof Proto
         * @interface ICommentViolateList
         * @property {Array.<Proto.ICommentViolate>|null} [value] CommentViolateList value
         */

        /**
         * Constructs a new CommentViolateList.
         * @memberof Proto
         * @classdesc Represents a CommentViolateList.
         * @implements ICommentViolateList
         * @constructor
         * @param {Proto.ICommentViolateList=} [p] Properties to set
         */
        function CommentViolateList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommentViolateList value.
         * @member {Array.<Proto.ICommentViolate>} value
         * @memberof Proto.CommentViolateList
         * @instance
         */
        CommentViolateList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CommentViolateList instance using the specified properties.
         * @function create
         * @memberof Proto.CommentViolateList
         * @static
         * @param {Proto.ICommentViolateList=} [properties] Properties to set
         * @returns {Proto.CommentViolateList} CommentViolateList instance
         */
        CommentViolateList.create = function create(properties) {
            return new CommentViolateList(properties);
        };

        /**
         * Encodes the specified CommentViolateList message. Does not implicitly {@link Proto.CommentViolateList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommentViolateList
         * @static
         * @param {Proto.ICommentViolateList} m CommentViolateList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommentViolateList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CommentViolate.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CommentViolateList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommentViolateList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommentViolateList} CommentViolateList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommentViolateList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommentViolateList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CommentViolate.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommentViolateList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommentViolateList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommentViolateList} CommentViolateList
         */
        CommentViolateList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommentViolateList)
                return d;
            var m = new $root.Proto.CommentViolateList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CommentViolateList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CommentViolateList.value: object expected");
                    m.value[i] = $root.Proto.CommentViolate.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CommentViolateList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommentViolateList
         * @static
         * @param {Proto.CommentViolateList} m CommentViolateList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommentViolateList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CommentViolate.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CommentViolateList to JSON.
         * @function toJSON
         * @memberof Proto.CommentViolateList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommentViolateList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommentViolateList;
    })();

    Proto.ContentScheduleDateUser = (function() {

        /**
         * Properties of a ContentScheduleDateUser.
         * @memberof Proto
         * @interface IContentScheduleDateUser
         * @property {string|null} [uid] ContentScheduleDateUser uid
         * @property {string|null} [scheduleUID] ContentScheduleDateUser scheduleUID
         * @property {string|null} [userUID] ContentScheduleDateUser userUID
         * @property {number|Long|null} [startDate] ContentScheduleDateUser startDate
         * @property {number|Long|null} [endDate] ContentScheduleDateUser endDate
         * @property {string|null} [question] ContentScheduleDateUser question
         * @property {boolean|null} [participate] ContentScheduleDateUser participate
         * @property {Proto.IUser|null} [user] ContentScheduleDateUser user
         * @property {number|Long|null} [registerDate] ContentScheduleDateUser registerDate
         * @property {number|Long|null} [modifyDate] ContentScheduleDateUser modifyDate
         * @property {number|null} [status] ContentScheduleDateUser status
         * @property {string|null} [userCount] ContentScheduleDateUser userCount
         * @property {string|null} [userSuccessCount] ContentScheduleDateUser userSuccessCount
         * @property {string|null} [userApplyCount] ContentScheduleDateUser userApplyCount
         * @property {Array.<Proto.IUserInfo>|null} [userInfoList] ContentScheduleDateUser userInfoList
         * @property {Array.<Proto.IContentScheduleDateUserInfo>|null} [questionInfoList] ContentScheduleDateUser questionInfoList
         */

        /**
         * Constructs a new ContentScheduleDateUser.
         * @memberof Proto
         * @classdesc Represents a ContentScheduleDateUser.
         * @implements IContentScheduleDateUser
         * @constructor
         * @param {Proto.IContentScheduleDateUser=} [p] Properties to set
         */
        function ContentScheduleDateUser(p) {
            this.userInfoList = [];
            this.questionInfoList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentScheduleDateUser uid.
         * @member {string} uid
         * @memberof Proto.ContentScheduleDateUser
         * @instance
         */
        ContentScheduleDateUser.prototype.uid = "";

        /**
         * ContentScheduleDateUser scheduleUID.
         * @member {string} scheduleUID
         * @memberof Proto.ContentScheduleDateUser
         * @instance
         */
        ContentScheduleDateUser.prototype.scheduleUID = "";

        /**
         * ContentScheduleDateUser userUID.
         * @member {string} userUID
         * @memberof Proto.ContentScheduleDateUser
         * @instance
         */
        ContentScheduleDateUser.prototype.userUID = "";

        /**
         * ContentScheduleDateUser startDate.
         * @member {number|Long} startDate
         * @memberof Proto.ContentScheduleDateUser
         * @instance
         */
        ContentScheduleDateUser.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleDateUser endDate.
         * @member {number|Long} endDate
         * @memberof Proto.ContentScheduleDateUser
         * @instance
         */
        ContentScheduleDateUser.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleDateUser question.
         * @member {string} question
         * @memberof Proto.ContentScheduleDateUser
         * @instance
         */
        ContentScheduleDateUser.prototype.question = "";

        /**
         * ContentScheduleDateUser participate.
         * @member {boolean} participate
         * @memberof Proto.ContentScheduleDateUser
         * @instance
         */
        ContentScheduleDateUser.prototype.participate = false;

        /**
         * ContentScheduleDateUser user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.ContentScheduleDateUser
         * @instance
         */
        ContentScheduleDateUser.prototype.user = null;

        /**
         * ContentScheduleDateUser registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentScheduleDateUser
         * @instance
         */
        ContentScheduleDateUser.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleDateUser modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentScheduleDateUser
         * @instance
         */
        ContentScheduleDateUser.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleDateUser status.
         * @member {number} status
         * @memberof Proto.ContentScheduleDateUser
         * @instance
         */
        ContentScheduleDateUser.prototype.status = 0;

        /**
         * ContentScheduleDateUser userCount.
         * @member {string} userCount
         * @memberof Proto.ContentScheduleDateUser
         * @instance
         */
        ContentScheduleDateUser.prototype.userCount = "";

        /**
         * ContentScheduleDateUser userSuccessCount.
         * @member {string} userSuccessCount
         * @memberof Proto.ContentScheduleDateUser
         * @instance
         */
        ContentScheduleDateUser.prototype.userSuccessCount = "";

        /**
         * ContentScheduleDateUser userApplyCount.
         * @member {string} userApplyCount
         * @memberof Proto.ContentScheduleDateUser
         * @instance
         */
        ContentScheduleDateUser.prototype.userApplyCount = "";

        /**
         * ContentScheduleDateUser userInfoList.
         * @member {Array.<Proto.IUserInfo>} userInfoList
         * @memberof Proto.ContentScheduleDateUser
         * @instance
         */
        ContentScheduleDateUser.prototype.userInfoList = $util.emptyArray;

        /**
         * ContentScheduleDateUser questionInfoList.
         * @member {Array.<Proto.IContentScheduleDateUserInfo>} questionInfoList
         * @memberof Proto.ContentScheduleDateUser
         * @instance
         */
        ContentScheduleDateUser.prototype.questionInfoList = $util.emptyArray;

        /**
         * Creates a new ContentScheduleDateUser instance using the specified properties.
         * @function create
         * @memberof Proto.ContentScheduleDateUser
         * @static
         * @param {Proto.IContentScheduleDateUser=} [properties] Properties to set
         * @returns {Proto.ContentScheduleDateUser} ContentScheduleDateUser instance
         */
        ContentScheduleDateUser.create = function create(properties) {
            return new ContentScheduleDateUser(properties);
        };

        /**
         * Encodes the specified ContentScheduleDateUser message. Does not implicitly {@link Proto.ContentScheduleDateUser.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentScheduleDateUser
         * @static
         * @param {Proto.IContentScheduleDateUser} m ContentScheduleDateUser message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentScheduleDateUser.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.scheduleUID != null && Object.hasOwnProperty.call(m, "scheduleUID"))
                w.uint32(18).string(m.scheduleUID);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(26).string(m.userUID);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(32).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(40).int64(m.endDate);
            if (m.question != null && Object.hasOwnProperty.call(m, "question"))
                w.uint32(50).string(m.question);
            if (m.participate != null && Object.hasOwnProperty.call(m, "participate"))
                w.uint32(56).bool(m.participate);
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(66).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(72).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(80).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(88).int32(m.status);
            if (m.userCount != null && Object.hasOwnProperty.call(m, "userCount"))
                w.uint32(98).string(m.userCount);
            if (m.userSuccessCount != null && Object.hasOwnProperty.call(m, "userSuccessCount"))
                w.uint32(106).string(m.userSuccessCount);
            if (m.userApplyCount != null && Object.hasOwnProperty.call(m, "userApplyCount"))
                w.uint32(114).string(m.userApplyCount);
            if (m.userInfoList != null && m.userInfoList.length) {
                for (var i = 0; i < m.userInfoList.length; ++i)
                    $root.Proto.UserInfo.encode(m.userInfoList[i], w.uint32(122).fork()).ldelim();
            }
            if (m.questionInfoList != null && m.questionInfoList.length) {
                for (var i = 0; i < m.questionInfoList.length; ++i)
                    $root.Proto.ContentScheduleDateUserInfo.encode(m.questionInfoList[i], w.uint32(130).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentScheduleDateUser message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentScheduleDateUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentScheduleDateUser} ContentScheduleDateUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentScheduleDateUser.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentScheduleDateUser();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.scheduleUID = r.string();
                    break;
                case 3:
                    m.userUID = r.string();
                    break;
                case 4:
                    m.startDate = r.int64();
                    break;
                case 5:
                    m.endDate = r.int64();
                    break;
                case 6:
                    m.question = r.string();
                    break;
                case 7:
                    m.participate = r.bool();
                    break;
                case 8:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 9:
                    m.registerDate = r.int64();
                    break;
                case 10:
                    m.modifyDate = r.int64();
                    break;
                case 11:
                    m.status = r.int32();
                    break;
                case 12:
                    m.userCount = r.string();
                    break;
                case 13:
                    m.userSuccessCount = r.string();
                    break;
                case 14:
                    m.userApplyCount = r.string();
                    break;
                case 15:
                    if (!(m.userInfoList && m.userInfoList.length))
                        m.userInfoList = [];
                    m.userInfoList.push($root.Proto.UserInfo.decode(r, r.uint32()));
                    break;
                case 16:
                    if (!(m.questionInfoList && m.questionInfoList.length))
                        m.questionInfoList = [];
                    m.questionInfoList.push($root.Proto.ContentScheduleDateUserInfo.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentScheduleDateUser message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentScheduleDateUser
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentScheduleDateUser} ContentScheduleDateUser
         */
        ContentScheduleDateUser.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentScheduleDateUser)
                return d;
            var m = new $root.Proto.ContentScheduleDateUser();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.scheduleUID != null) {
                m.scheduleUID = String(d.scheduleUID);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.question != null) {
                m.question = String(d.question);
            }
            if (d.participate != null) {
                m.participate = Boolean(d.participate);
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.ContentScheduleDateUser.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.userCount != null) {
                m.userCount = String(d.userCount);
            }
            if (d.userSuccessCount != null) {
                m.userSuccessCount = String(d.userSuccessCount);
            }
            if (d.userApplyCount != null) {
                m.userApplyCount = String(d.userApplyCount);
            }
            if (d.userInfoList) {
                if (!Array.isArray(d.userInfoList))
                    throw TypeError(".Proto.ContentScheduleDateUser.userInfoList: array expected");
                m.userInfoList = [];
                for (var i = 0; i < d.userInfoList.length; ++i) {
                    if (typeof d.userInfoList[i] !== "object")
                        throw TypeError(".Proto.ContentScheduleDateUser.userInfoList: object expected");
                    m.userInfoList[i] = $root.Proto.UserInfo.fromObject(d.userInfoList[i]);
                }
            }
            if (d.questionInfoList) {
                if (!Array.isArray(d.questionInfoList))
                    throw TypeError(".Proto.ContentScheduleDateUser.questionInfoList: array expected");
                m.questionInfoList = [];
                for (var i = 0; i < d.questionInfoList.length; ++i) {
                    if (typeof d.questionInfoList[i] !== "object")
                        throw TypeError(".Proto.ContentScheduleDateUser.questionInfoList: object expected");
                    m.questionInfoList[i] = $root.Proto.ContentScheduleDateUserInfo.fromObject(d.questionInfoList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentScheduleDateUser message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentScheduleDateUser
         * @static
         * @param {Proto.ContentScheduleDateUser} m ContentScheduleDateUser
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentScheduleDateUser.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.userInfoList = [];
                d.questionInfoList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.scheduleUID = "";
                d.userUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                d.question = "";
                d.participate = false;
                d.user = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.userCount = "";
                d.userSuccessCount = "";
                d.userApplyCount = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.scheduleUID != null && m.hasOwnProperty("scheduleUID")) {
                d.scheduleUID = m.scheduleUID;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.question != null && m.hasOwnProperty("question")) {
                d.question = m.question;
            }
            if (m.participate != null && m.hasOwnProperty("participate")) {
                d.participate = m.participate;
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.userCount != null && m.hasOwnProperty("userCount")) {
                d.userCount = m.userCount;
            }
            if (m.userSuccessCount != null && m.hasOwnProperty("userSuccessCount")) {
                d.userSuccessCount = m.userSuccessCount;
            }
            if (m.userApplyCount != null && m.hasOwnProperty("userApplyCount")) {
                d.userApplyCount = m.userApplyCount;
            }
            if (m.userInfoList && m.userInfoList.length) {
                d.userInfoList = [];
                for (var j = 0; j < m.userInfoList.length; ++j) {
                    d.userInfoList[j] = $root.Proto.UserInfo.toObject(m.userInfoList[j], o);
                }
            }
            if (m.questionInfoList && m.questionInfoList.length) {
                d.questionInfoList = [];
                for (var j = 0; j < m.questionInfoList.length; ++j) {
                    d.questionInfoList[j] = $root.Proto.ContentScheduleDateUserInfo.toObject(m.questionInfoList[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentScheduleDateUser to JSON.
         * @function toJSON
         * @memberof Proto.ContentScheduleDateUser
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentScheduleDateUser.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentScheduleDateUser;
    })();

    Proto.ContentScheduleDateUserList = (function() {

        /**
         * Properties of a ContentScheduleDateUserList.
         * @memberof Proto
         * @interface IContentScheduleDateUserList
         * @property {Array.<Proto.IContentScheduleDateUser>|null} [value] ContentScheduleDateUserList value
         */

        /**
         * Constructs a new ContentScheduleDateUserList.
         * @memberof Proto
         * @classdesc Represents a ContentScheduleDateUserList.
         * @implements IContentScheduleDateUserList
         * @constructor
         * @param {Proto.IContentScheduleDateUserList=} [p] Properties to set
         */
        function ContentScheduleDateUserList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentScheduleDateUserList value.
         * @member {Array.<Proto.IContentScheduleDateUser>} value
         * @memberof Proto.ContentScheduleDateUserList
         * @instance
         */
        ContentScheduleDateUserList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentScheduleDateUserList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentScheduleDateUserList
         * @static
         * @param {Proto.IContentScheduleDateUserList=} [properties] Properties to set
         * @returns {Proto.ContentScheduleDateUserList} ContentScheduleDateUserList instance
         */
        ContentScheduleDateUserList.create = function create(properties) {
            return new ContentScheduleDateUserList(properties);
        };

        /**
         * Encodes the specified ContentScheduleDateUserList message. Does not implicitly {@link Proto.ContentScheduleDateUserList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentScheduleDateUserList
         * @static
         * @param {Proto.IContentScheduleDateUserList} m ContentScheduleDateUserList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentScheduleDateUserList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentScheduleDateUser.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentScheduleDateUserList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentScheduleDateUserList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentScheduleDateUserList} ContentScheduleDateUserList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentScheduleDateUserList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentScheduleDateUserList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentScheduleDateUser.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentScheduleDateUserList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentScheduleDateUserList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentScheduleDateUserList} ContentScheduleDateUserList
         */
        ContentScheduleDateUserList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentScheduleDateUserList)
                return d;
            var m = new $root.Proto.ContentScheduleDateUserList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentScheduleDateUserList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentScheduleDateUserList.value: object expected");
                    m.value[i] = $root.Proto.ContentScheduleDateUser.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentScheduleDateUserList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentScheduleDateUserList
         * @static
         * @param {Proto.ContentScheduleDateUserList} m ContentScheduleDateUserList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentScheduleDateUserList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentScheduleDateUser.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentScheduleDateUserList to JSON.
         * @function toJSON
         * @memberof Proto.ContentScheduleDateUserList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentScheduleDateUserList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentScheduleDateUserList;
    })();

    Proto.ContentScheduleRepeatExcept = (function() {

        /**
         * Properties of a ContentScheduleRepeatExcept.
         * @memberof Proto
         * @interface IContentScheduleRepeatExcept
         * @property {string|null} [scheduleUID] ContentScheduleRepeatExcept scheduleUID
         * @property {number|Long|null} [startDate] ContentScheduleRepeatExcept startDate
         * @property {number|Long|null} [endDate] ContentScheduleRepeatExcept endDate
         * @property {number|Long|null} [registerDate] ContentScheduleRepeatExcept registerDate
         * @property {number|Long|null} [modifyDate] ContentScheduleRepeatExcept modifyDate
         * @property {number|null} [status] ContentScheduleRepeatExcept status
         */

        /**
         * Constructs a new ContentScheduleRepeatExcept.
         * @memberof Proto
         * @classdesc Represents a ContentScheduleRepeatExcept.
         * @implements IContentScheduleRepeatExcept
         * @constructor
         * @param {Proto.IContentScheduleRepeatExcept=} [p] Properties to set
         */
        function ContentScheduleRepeatExcept(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentScheduleRepeatExcept scheduleUID.
         * @member {string} scheduleUID
         * @memberof Proto.ContentScheduleRepeatExcept
         * @instance
         */
        ContentScheduleRepeatExcept.prototype.scheduleUID = "";

        /**
         * ContentScheduleRepeatExcept startDate.
         * @member {number|Long} startDate
         * @memberof Proto.ContentScheduleRepeatExcept
         * @instance
         */
        ContentScheduleRepeatExcept.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleRepeatExcept endDate.
         * @member {number|Long} endDate
         * @memberof Proto.ContentScheduleRepeatExcept
         * @instance
         */
        ContentScheduleRepeatExcept.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleRepeatExcept registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentScheduleRepeatExcept
         * @instance
         */
        ContentScheduleRepeatExcept.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleRepeatExcept modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentScheduleRepeatExcept
         * @instance
         */
        ContentScheduleRepeatExcept.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleRepeatExcept status.
         * @member {number} status
         * @memberof Proto.ContentScheduleRepeatExcept
         * @instance
         */
        ContentScheduleRepeatExcept.prototype.status = 0;

        /**
         * Creates a new ContentScheduleRepeatExcept instance using the specified properties.
         * @function create
         * @memberof Proto.ContentScheduleRepeatExcept
         * @static
         * @param {Proto.IContentScheduleRepeatExcept=} [properties] Properties to set
         * @returns {Proto.ContentScheduleRepeatExcept} ContentScheduleRepeatExcept instance
         */
        ContentScheduleRepeatExcept.create = function create(properties) {
            return new ContentScheduleRepeatExcept(properties);
        };

        /**
         * Encodes the specified ContentScheduleRepeatExcept message. Does not implicitly {@link Proto.ContentScheduleRepeatExcept.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentScheduleRepeatExcept
         * @static
         * @param {Proto.IContentScheduleRepeatExcept} m ContentScheduleRepeatExcept message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentScheduleRepeatExcept.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.scheduleUID != null && Object.hasOwnProperty.call(m, "scheduleUID"))
                w.uint32(10).string(m.scheduleUID);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(16).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(24).int64(m.endDate);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(48).int32(m.status);
            return w;
        };

        /**
         * Decodes a ContentScheduleRepeatExcept message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentScheduleRepeatExcept
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentScheduleRepeatExcept} ContentScheduleRepeatExcept
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentScheduleRepeatExcept.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentScheduleRepeatExcept();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.scheduleUID = r.string();
                    break;
                case 2:
                    m.startDate = r.int64();
                    break;
                case 3:
                    m.endDate = r.int64();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                case 6:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentScheduleRepeatExcept message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentScheduleRepeatExcept
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentScheduleRepeatExcept} ContentScheduleRepeatExcept
         */
        ContentScheduleRepeatExcept.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentScheduleRepeatExcept)
                return d;
            var m = new $root.Proto.ContentScheduleRepeatExcept();
            if (d.scheduleUID != null) {
                m.scheduleUID = String(d.scheduleUID);
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentScheduleRepeatExcept message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentScheduleRepeatExcept
         * @static
         * @param {Proto.ContentScheduleRepeatExcept} m ContentScheduleRepeatExcept
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentScheduleRepeatExcept.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.scheduleUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.scheduleUID != null && m.hasOwnProperty("scheduleUID")) {
                d.scheduleUID = m.scheduleUID;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this ContentScheduleRepeatExcept to JSON.
         * @function toJSON
         * @memberof Proto.ContentScheduleRepeatExcept
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentScheduleRepeatExcept.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentScheduleRepeatExcept;
    })();

    Proto.ContentScheduleRepeat = (function() {

        /**
         * Properties of a ContentScheduleRepeat.
         * @memberof Proto
         * @interface IContentScheduleRepeat
         * @property {string|null} [scheduleUID] ContentScheduleRepeat scheduleUID
         * @property {string|null} [startDate] ContentScheduleRepeat startDate
         * @property {string|null} [startTime] ContentScheduleRepeat startTime
         * @property {number|null} [duration] ContentScheduleRepeat duration
         * @property {string|null} [repeatEnd] ContentScheduleRepeat repeatEnd
         * @property {number|null} [repeatInterval] ContentScheduleRepeat repeatInterval
         * @property {number|null} [repeatWeek] ContentScheduleRepeat repeatWeek
         * @property {string|null} [repeatWeekday] ContentScheduleRepeat repeatWeekday
         * @property {number|null} [repeatDay] ContentScheduleRepeat repeatDay
         * @property {number|Long|null} [registerDate] ContentScheduleRepeat registerDate
         * @property {number|Long|null} [modifyDate] ContentScheduleRepeat modifyDate
         * @property {number|null} [status] ContentScheduleRepeat status
         * @property {Array.<Proto.IContentScheduleRepeatExcept>|null} [exceptList] ContentScheduleRepeat exceptList
         */

        /**
         * Constructs a new ContentScheduleRepeat.
         * @memberof Proto
         * @classdesc Represents a ContentScheduleRepeat.
         * @implements IContentScheduleRepeat
         * @constructor
         * @param {Proto.IContentScheduleRepeat=} [p] Properties to set
         */
        function ContentScheduleRepeat(p) {
            this.exceptList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentScheduleRepeat scheduleUID.
         * @member {string} scheduleUID
         * @memberof Proto.ContentScheduleRepeat
         * @instance
         */
        ContentScheduleRepeat.prototype.scheduleUID = "";

        /**
         * ContentScheduleRepeat startDate.
         * @member {string} startDate
         * @memberof Proto.ContentScheduleRepeat
         * @instance
         */
        ContentScheduleRepeat.prototype.startDate = "";

        /**
         * ContentScheduleRepeat startTime.
         * @member {string} startTime
         * @memberof Proto.ContentScheduleRepeat
         * @instance
         */
        ContentScheduleRepeat.prototype.startTime = "";

        /**
         * ContentScheduleRepeat duration.
         * @member {number} duration
         * @memberof Proto.ContentScheduleRepeat
         * @instance
         */
        ContentScheduleRepeat.prototype.duration = 0;

        /**
         * ContentScheduleRepeat repeatEnd.
         * @member {string} repeatEnd
         * @memberof Proto.ContentScheduleRepeat
         * @instance
         */
        ContentScheduleRepeat.prototype.repeatEnd = "";

        /**
         * ContentScheduleRepeat repeatInterval.
         * @member {number} repeatInterval
         * @memberof Proto.ContentScheduleRepeat
         * @instance
         */
        ContentScheduleRepeat.prototype.repeatInterval = 0;

        /**
         * ContentScheduleRepeat repeatWeek.
         * @member {number} repeatWeek
         * @memberof Proto.ContentScheduleRepeat
         * @instance
         */
        ContentScheduleRepeat.prototype.repeatWeek = 0;

        /**
         * ContentScheduleRepeat repeatWeekday.
         * @member {string} repeatWeekday
         * @memberof Proto.ContentScheduleRepeat
         * @instance
         */
        ContentScheduleRepeat.prototype.repeatWeekday = "";

        /**
         * ContentScheduleRepeat repeatDay.
         * @member {number} repeatDay
         * @memberof Proto.ContentScheduleRepeat
         * @instance
         */
        ContentScheduleRepeat.prototype.repeatDay = 0;

        /**
         * ContentScheduleRepeat registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentScheduleRepeat
         * @instance
         */
        ContentScheduleRepeat.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleRepeat modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentScheduleRepeat
         * @instance
         */
        ContentScheduleRepeat.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleRepeat status.
         * @member {number} status
         * @memberof Proto.ContentScheduleRepeat
         * @instance
         */
        ContentScheduleRepeat.prototype.status = 0;

        /**
         * ContentScheduleRepeat exceptList.
         * @member {Array.<Proto.IContentScheduleRepeatExcept>} exceptList
         * @memberof Proto.ContentScheduleRepeat
         * @instance
         */
        ContentScheduleRepeat.prototype.exceptList = $util.emptyArray;

        /**
         * Creates a new ContentScheduleRepeat instance using the specified properties.
         * @function create
         * @memberof Proto.ContentScheduleRepeat
         * @static
         * @param {Proto.IContentScheduleRepeat=} [properties] Properties to set
         * @returns {Proto.ContentScheduleRepeat} ContentScheduleRepeat instance
         */
        ContentScheduleRepeat.create = function create(properties) {
            return new ContentScheduleRepeat(properties);
        };

        /**
         * Encodes the specified ContentScheduleRepeat message. Does not implicitly {@link Proto.ContentScheduleRepeat.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentScheduleRepeat
         * @static
         * @param {Proto.IContentScheduleRepeat} m ContentScheduleRepeat message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentScheduleRepeat.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.scheduleUID != null && Object.hasOwnProperty.call(m, "scheduleUID"))
                w.uint32(10).string(m.scheduleUID);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(18).string(m.startDate);
            if (m.startTime != null && Object.hasOwnProperty.call(m, "startTime"))
                w.uint32(26).string(m.startTime);
            if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                w.uint32(32).int32(m.duration);
            if (m.repeatEnd != null && Object.hasOwnProperty.call(m, "repeatEnd"))
                w.uint32(42).string(m.repeatEnd);
            if (m.repeatInterval != null && Object.hasOwnProperty.call(m, "repeatInterval"))
                w.uint32(48).int32(m.repeatInterval);
            if (m.repeatWeek != null && Object.hasOwnProperty.call(m, "repeatWeek"))
                w.uint32(56).int32(m.repeatWeek);
            if (m.repeatWeekday != null && Object.hasOwnProperty.call(m, "repeatWeekday"))
                w.uint32(66).string(m.repeatWeekday);
            if (m.repeatDay != null && Object.hasOwnProperty.call(m, "repeatDay"))
                w.uint32(72).int32(m.repeatDay);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(80).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(88).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(96).int32(m.status);
            if (m.exceptList != null && m.exceptList.length) {
                for (var i = 0; i < m.exceptList.length; ++i)
                    $root.Proto.ContentScheduleRepeatExcept.encode(m.exceptList[i], w.uint32(106).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentScheduleRepeat message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentScheduleRepeat
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentScheduleRepeat} ContentScheduleRepeat
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentScheduleRepeat.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentScheduleRepeat();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.scheduleUID = r.string();
                    break;
                case 2:
                    m.startDate = r.string();
                    break;
                case 3:
                    m.startTime = r.string();
                    break;
                case 4:
                    m.duration = r.int32();
                    break;
                case 5:
                    m.repeatEnd = r.string();
                    break;
                case 6:
                    m.repeatInterval = r.int32();
                    break;
                case 7:
                    m.repeatWeek = r.int32();
                    break;
                case 8:
                    m.repeatWeekday = r.string();
                    break;
                case 9:
                    m.repeatDay = r.int32();
                    break;
                case 10:
                    m.registerDate = r.int64();
                    break;
                case 11:
                    m.modifyDate = r.int64();
                    break;
                case 12:
                    m.status = r.int32();
                    break;
                case 13:
                    if (!(m.exceptList && m.exceptList.length))
                        m.exceptList = [];
                    m.exceptList.push($root.Proto.ContentScheduleRepeatExcept.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentScheduleRepeat message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentScheduleRepeat
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentScheduleRepeat} ContentScheduleRepeat
         */
        ContentScheduleRepeat.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentScheduleRepeat)
                return d;
            var m = new $root.Proto.ContentScheduleRepeat();
            if (d.scheduleUID != null) {
                m.scheduleUID = String(d.scheduleUID);
            }
            if (d.startDate != null) {
                m.startDate = String(d.startDate);
            }
            if (d.startTime != null) {
                m.startTime = String(d.startTime);
            }
            if (d.duration != null) {
                m.duration = d.duration | 0;
            }
            if (d.repeatEnd != null) {
                m.repeatEnd = String(d.repeatEnd);
            }
            if (d.repeatInterval != null) {
                m.repeatInterval = d.repeatInterval | 0;
            }
            if (d.repeatWeek != null) {
                m.repeatWeek = d.repeatWeek | 0;
            }
            if (d.repeatWeekday != null) {
                m.repeatWeekday = String(d.repeatWeekday);
            }
            if (d.repeatDay != null) {
                m.repeatDay = d.repeatDay | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.exceptList) {
                if (!Array.isArray(d.exceptList))
                    throw TypeError(".Proto.ContentScheduleRepeat.exceptList: array expected");
                m.exceptList = [];
                for (var i = 0; i < d.exceptList.length; ++i) {
                    if (typeof d.exceptList[i] !== "object")
                        throw TypeError(".Proto.ContentScheduleRepeat.exceptList: object expected");
                    m.exceptList[i] = $root.Proto.ContentScheduleRepeatExcept.fromObject(d.exceptList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentScheduleRepeat message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentScheduleRepeat
         * @static
         * @param {Proto.ContentScheduleRepeat} m ContentScheduleRepeat
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentScheduleRepeat.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.exceptList = [];
            }
            if (o.defaults) {
                d.scheduleUID = "";
                d.startDate = "";
                d.startTime = "";
                d.duration = 0;
                d.repeatEnd = "";
                d.repeatInterval = 0;
                d.repeatWeek = 0;
                d.repeatWeekday = "";
                d.repeatDay = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.scheduleUID != null && m.hasOwnProperty("scheduleUID")) {
                d.scheduleUID = m.scheduleUID;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                d.startDate = m.startDate;
            }
            if (m.startTime != null && m.hasOwnProperty("startTime")) {
                d.startTime = m.startTime;
            }
            if (m.duration != null && m.hasOwnProperty("duration")) {
                d.duration = m.duration;
            }
            if (m.repeatEnd != null && m.hasOwnProperty("repeatEnd")) {
                d.repeatEnd = m.repeatEnd;
            }
            if (m.repeatInterval != null && m.hasOwnProperty("repeatInterval")) {
                d.repeatInterval = m.repeatInterval;
            }
            if (m.repeatWeek != null && m.hasOwnProperty("repeatWeek")) {
                d.repeatWeek = m.repeatWeek;
            }
            if (m.repeatWeekday != null && m.hasOwnProperty("repeatWeekday")) {
                d.repeatWeekday = m.repeatWeekday;
            }
            if (m.repeatDay != null && m.hasOwnProperty("repeatDay")) {
                d.repeatDay = m.repeatDay;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.exceptList && m.exceptList.length) {
                d.exceptList = [];
                for (var j = 0; j < m.exceptList.length; ++j) {
                    d.exceptList[j] = $root.Proto.ContentScheduleRepeatExcept.toObject(m.exceptList[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentScheduleRepeat to JSON.
         * @function toJSON
         * @memberof Proto.ContentScheduleRepeat
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentScheduleRepeat.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentScheduleRepeat;
    })();

    Proto.ContentSchedule = (function() {

        /**
         * Properties of a ContentSchedule.
         * @memberof Proto
         * @interface IContentSchedule
         * @property {string|null} [uid] ContentSchedule uid
         * @property {string|null} [contentUID] ContentSchedule contentUID
         * @property {number|null} [userLimit] ContentSchedule userLimit
         * @property {number|null} [dateInterval] ContentSchedule dateInterval
         * @property {number|Long|null} [startDate] ContentSchedule startDate
         * @property {number|Long|null} [endDate] ContentSchedule endDate
         * @property {string|null} [question] ContentSchedule question
         * @property {boolean|null} [questionRequired] ContentSchedule questionRequired
         * @property {number|Long|null} [registerDate] ContentSchedule registerDate
         * @property {number|Long|null} [modifyDate] ContentSchedule modifyDate
         * @property {number|null} [status] ContentSchedule status
         * @property {Proto.IContentScheduleRepeat|null} [repeat] ContentSchedule repeat
         * @property {string|null} [userCount] ContentSchedule userCount
         * @property {string|null} [parentUID] ContentSchedule parentUID
         * @property {Array.<Proto.IContentScheduleDateUser>|null} [userList] ContentSchedule userList
         * @property {Array.<Proto.IContentScheduleDateUser>|null} [userCountList] ContentSchedule userCountList
         * @property {number|null} [oneTime] ContentSchedule oneTime
         * @property {Array.<string>|null} [autoPlanUIDList] ContentSchedule autoPlanUIDList
         * @property {Array.<Proto.IContentScheduleUserInfo>|null} [userInfoList] ContentSchedule userInfoList
         */

        /**
         * Constructs a new ContentSchedule.
         * @memberof Proto
         * @classdesc Represents a ContentSchedule.
         * @implements IContentSchedule
         * @constructor
         * @param {Proto.IContentSchedule=} [p] Properties to set
         */
        function ContentSchedule(p) {
            this.userList = [];
            this.userCountList = [];
            this.autoPlanUIDList = [];
            this.userInfoList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentSchedule uid.
         * @member {string} uid
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.uid = "";

        /**
         * ContentSchedule contentUID.
         * @member {string} contentUID
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.contentUID = "";

        /**
         * ContentSchedule userLimit.
         * @member {number} userLimit
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.userLimit = 0;

        /**
         * ContentSchedule dateInterval.
         * @member {number} dateInterval
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.dateInterval = 0;

        /**
         * ContentSchedule startDate.
         * @member {number|Long} startDate
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentSchedule endDate.
         * @member {number|Long} endDate
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentSchedule question.
         * @member {string} question
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.question = "";

        /**
         * ContentSchedule questionRequired.
         * @member {boolean} questionRequired
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.questionRequired = false;

        /**
         * ContentSchedule registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentSchedule modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentSchedule status.
         * @member {number} status
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.status = 0;

        /**
         * ContentSchedule repeat.
         * @member {Proto.IContentScheduleRepeat|null|undefined} repeat
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.repeat = null;

        /**
         * ContentSchedule userCount.
         * @member {string} userCount
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.userCount = "";

        /**
         * ContentSchedule parentUID.
         * @member {string} parentUID
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.parentUID = "";

        /**
         * ContentSchedule userList.
         * @member {Array.<Proto.IContentScheduleDateUser>} userList
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.userList = $util.emptyArray;

        /**
         * ContentSchedule userCountList.
         * @member {Array.<Proto.IContentScheduleDateUser>} userCountList
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.userCountList = $util.emptyArray;

        /**
         * ContentSchedule oneTime.
         * @member {number} oneTime
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.oneTime = 0;

        /**
         * ContentSchedule autoPlanUIDList.
         * @member {Array.<string>} autoPlanUIDList
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.autoPlanUIDList = $util.emptyArray;

        /**
         * ContentSchedule userInfoList.
         * @member {Array.<Proto.IContentScheduleUserInfo>} userInfoList
         * @memberof Proto.ContentSchedule
         * @instance
         */
        ContentSchedule.prototype.userInfoList = $util.emptyArray;

        /**
         * Creates a new ContentSchedule instance using the specified properties.
         * @function create
         * @memberof Proto.ContentSchedule
         * @static
         * @param {Proto.IContentSchedule=} [properties] Properties to set
         * @returns {Proto.ContentSchedule} ContentSchedule instance
         */
        ContentSchedule.create = function create(properties) {
            return new ContentSchedule(properties);
        };

        /**
         * Encodes the specified ContentSchedule message. Does not implicitly {@link Proto.ContentSchedule.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentSchedule
         * @static
         * @param {Proto.IContentSchedule} m ContentSchedule message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentSchedule.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(18).string(m.contentUID);
            if (m.userLimit != null && Object.hasOwnProperty.call(m, "userLimit"))
                w.uint32(24).int32(m.userLimit);
            if (m.dateInterval != null && Object.hasOwnProperty.call(m, "dateInterval"))
                w.uint32(32).int32(m.dateInterval);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(56).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(64).int64(m.endDate);
            if (m.question != null && Object.hasOwnProperty.call(m, "question"))
                w.uint32(82).string(m.question);
            if (m.questionRequired != null && Object.hasOwnProperty.call(m, "questionRequired"))
                w.uint32(88).bool(m.questionRequired);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(96).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(104).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(112).int32(m.status);
            if (m.repeat != null && Object.hasOwnProperty.call(m, "repeat"))
                $root.Proto.ContentScheduleRepeat.encode(m.repeat, w.uint32(122).fork()).ldelim();
            if (m.userCount != null && Object.hasOwnProperty.call(m, "userCount"))
                w.uint32(130).string(m.userCount);
            if (m.parentUID != null && Object.hasOwnProperty.call(m, "parentUID"))
                w.uint32(138).string(m.parentUID);
            if (m.userList != null && m.userList.length) {
                for (var i = 0; i < m.userList.length; ++i)
                    $root.Proto.ContentScheduleDateUser.encode(m.userList[i], w.uint32(146).fork()).ldelim();
            }
            if (m.userCountList != null && m.userCountList.length) {
                for (var i = 0; i < m.userCountList.length; ++i)
                    $root.Proto.ContentScheduleDateUser.encode(m.userCountList[i], w.uint32(154).fork()).ldelim();
            }
            if (m.oneTime != null && Object.hasOwnProperty.call(m, "oneTime"))
                w.uint32(160).int32(m.oneTime);
            if (m.autoPlanUIDList != null && m.autoPlanUIDList.length) {
                for (var i = 0; i < m.autoPlanUIDList.length; ++i)
                    w.uint32(170).string(m.autoPlanUIDList[i]);
            }
            if (m.userInfoList != null && m.userInfoList.length) {
                for (var i = 0; i < m.userInfoList.length; ++i)
                    $root.Proto.ContentScheduleUserInfo.encode(m.userInfoList[i], w.uint32(178).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentSchedule message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentSchedule
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentSchedule} ContentSchedule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentSchedule.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentSchedule();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.contentUID = r.string();
                    break;
                case 3:
                    m.userLimit = r.int32();
                    break;
                case 4:
                    m.dateInterval = r.int32();
                    break;
                case 7:
                    m.startDate = r.int64();
                    break;
                case 8:
                    m.endDate = r.int64();
                    break;
                case 10:
                    m.question = r.string();
                    break;
                case 11:
                    m.questionRequired = r.bool();
                    break;
                case 12:
                    m.registerDate = r.int64();
                    break;
                case 13:
                    m.modifyDate = r.int64();
                    break;
                case 14:
                    m.status = r.int32();
                    break;
                case 15:
                    m.repeat = $root.Proto.ContentScheduleRepeat.decode(r, r.uint32());
                    break;
                case 16:
                    m.userCount = r.string();
                    break;
                case 17:
                    m.parentUID = r.string();
                    break;
                case 18:
                    if (!(m.userList && m.userList.length))
                        m.userList = [];
                    m.userList.push($root.Proto.ContentScheduleDateUser.decode(r, r.uint32()));
                    break;
                case 19:
                    if (!(m.userCountList && m.userCountList.length))
                        m.userCountList = [];
                    m.userCountList.push($root.Proto.ContentScheduleDateUser.decode(r, r.uint32()));
                    break;
                case 20:
                    m.oneTime = r.int32();
                    break;
                case 21:
                    if (!(m.autoPlanUIDList && m.autoPlanUIDList.length))
                        m.autoPlanUIDList = [];
                    m.autoPlanUIDList.push(r.string());
                    break;
                case 22:
                    if (!(m.userInfoList && m.userInfoList.length))
                        m.userInfoList = [];
                    m.userInfoList.push($root.Proto.ContentScheduleUserInfo.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentSchedule message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentSchedule
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentSchedule} ContentSchedule
         */
        ContentSchedule.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentSchedule)
                return d;
            var m = new $root.Proto.ContentSchedule();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.userLimit != null) {
                m.userLimit = d.userLimit | 0;
            }
            if (d.dateInterval != null) {
                m.dateInterval = d.dateInterval | 0;
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.question != null) {
                m.question = String(d.question);
            }
            if (d.questionRequired != null) {
                m.questionRequired = Boolean(d.questionRequired);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.repeat != null) {
                if (typeof d.repeat !== "object")
                    throw TypeError(".Proto.ContentSchedule.repeat: object expected");
                m.repeat = $root.Proto.ContentScheduleRepeat.fromObject(d.repeat);
            }
            if (d.userCount != null) {
                m.userCount = String(d.userCount);
            }
            if (d.parentUID != null) {
                m.parentUID = String(d.parentUID);
            }
            if (d.userList) {
                if (!Array.isArray(d.userList))
                    throw TypeError(".Proto.ContentSchedule.userList: array expected");
                m.userList = [];
                for (var i = 0; i < d.userList.length; ++i) {
                    if (typeof d.userList[i] !== "object")
                        throw TypeError(".Proto.ContentSchedule.userList: object expected");
                    m.userList[i] = $root.Proto.ContentScheduleDateUser.fromObject(d.userList[i]);
                }
            }
            if (d.userCountList) {
                if (!Array.isArray(d.userCountList))
                    throw TypeError(".Proto.ContentSchedule.userCountList: array expected");
                m.userCountList = [];
                for (var i = 0; i < d.userCountList.length; ++i) {
                    if (typeof d.userCountList[i] !== "object")
                        throw TypeError(".Proto.ContentSchedule.userCountList: object expected");
                    m.userCountList[i] = $root.Proto.ContentScheduleDateUser.fromObject(d.userCountList[i]);
                }
            }
            if (d.oneTime != null) {
                m.oneTime = d.oneTime | 0;
            }
            if (d.autoPlanUIDList) {
                if (!Array.isArray(d.autoPlanUIDList))
                    throw TypeError(".Proto.ContentSchedule.autoPlanUIDList: array expected");
                m.autoPlanUIDList = [];
                for (var i = 0; i < d.autoPlanUIDList.length; ++i) {
                    m.autoPlanUIDList[i] = String(d.autoPlanUIDList[i]);
                }
            }
            if (d.userInfoList) {
                if (!Array.isArray(d.userInfoList))
                    throw TypeError(".Proto.ContentSchedule.userInfoList: array expected");
                m.userInfoList = [];
                for (var i = 0; i < d.userInfoList.length; ++i) {
                    if (typeof d.userInfoList[i] !== "object")
                        throw TypeError(".Proto.ContentSchedule.userInfoList: object expected");
                    m.userInfoList[i] = $root.Proto.ContentScheduleUserInfo.fromObject(d.userInfoList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentSchedule message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentSchedule
         * @static
         * @param {Proto.ContentSchedule} m ContentSchedule
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentSchedule.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.userList = [];
                d.userCountList = [];
                d.autoPlanUIDList = [];
                d.userInfoList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.contentUID = "";
                d.userLimit = 0;
                d.dateInterval = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                d.question = "";
                d.questionRequired = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.repeat = null;
                d.userCount = "";
                d.parentUID = "";
                d.oneTime = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.userLimit != null && m.hasOwnProperty("userLimit")) {
                d.userLimit = m.userLimit;
            }
            if (m.dateInterval != null && m.hasOwnProperty("dateInterval")) {
                d.dateInterval = m.dateInterval;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.question != null && m.hasOwnProperty("question")) {
                d.question = m.question;
            }
            if (m.questionRequired != null && m.hasOwnProperty("questionRequired")) {
                d.questionRequired = m.questionRequired;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.repeat != null && m.hasOwnProperty("repeat")) {
                d.repeat = $root.Proto.ContentScheduleRepeat.toObject(m.repeat, o);
            }
            if (m.userCount != null && m.hasOwnProperty("userCount")) {
                d.userCount = m.userCount;
            }
            if (m.parentUID != null && m.hasOwnProperty("parentUID")) {
                d.parentUID = m.parentUID;
            }
            if (m.userList && m.userList.length) {
                d.userList = [];
                for (var j = 0; j < m.userList.length; ++j) {
                    d.userList[j] = $root.Proto.ContentScheduleDateUser.toObject(m.userList[j], o);
                }
            }
            if (m.userCountList && m.userCountList.length) {
                d.userCountList = [];
                for (var j = 0; j < m.userCountList.length; ++j) {
                    d.userCountList[j] = $root.Proto.ContentScheduleDateUser.toObject(m.userCountList[j], o);
                }
            }
            if (m.oneTime != null && m.hasOwnProperty("oneTime")) {
                d.oneTime = m.oneTime;
            }
            if (m.autoPlanUIDList && m.autoPlanUIDList.length) {
                d.autoPlanUIDList = [];
                for (var j = 0; j < m.autoPlanUIDList.length; ++j) {
                    d.autoPlanUIDList[j] = m.autoPlanUIDList[j];
                }
            }
            if (m.userInfoList && m.userInfoList.length) {
                d.userInfoList = [];
                for (var j = 0; j < m.userInfoList.length; ++j) {
                    d.userInfoList[j] = $root.Proto.ContentScheduleUserInfo.toObject(m.userInfoList[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentSchedule to JSON.
         * @function toJSON
         * @memberof Proto.ContentSchedule
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentSchedule.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentSchedule;
    })();

    Proto.ContentScheduleList = (function() {

        /**
         * Properties of a ContentScheduleList.
         * @memberof Proto
         * @interface IContentScheduleList
         * @property {Array.<Proto.IContentSchedule>|null} [value] ContentScheduleList value
         */

        /**
         * Constructs a new ContentScheduleList.
         * @memberof Proto
         * @classdesc Represents a ContentScheduleList.
         * @implements IContentScheduleList
         * @constructor
         * @param {Proto.IContentScheduleList=} [p] Properties to set
         */
        function ContentScheduleList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentScheduleList value.
         * @member {Array.<Proto.IContentSchedule>} value
         * @memberof Proto.ContentScheduleList
         * @instance
         */
        ContentScheduleList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentScheduleList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentScheduleList
         * @static
         * @param {Proto.IContentScheduleList=} [properties] Properties to set
         * @returns {Proto.ContentScheduleList} ContentScheduleList instance
         */
        ContentScheduleList.create = function create(properties) {
            return new ContentScheduleList(properties);
        };

        /**
         * Encodes the specified ContentScheduleList message. Does not implicitly {@link Proto.ContentScheduleList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentScheduleList
         * @static
         * @param {Proto.IContentScheduleList} m ContentScheduleList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentScheduleList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentSchedule.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentScheduleList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentScheduleList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentScheduleList} ContentScheduleList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentScheduleList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentScheduleList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentSchedule.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentScheduleList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentScheduleList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentScheduleList} ContentScheduleList
         */
        ContentScheduleList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentScheduleList)
                return d;
            var m = new $root.Proto.ContentScheduleList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentScheduleList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentScheduleList.value: object expected");
                    m.value[i] = $root.Proto.ContentSchedule.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentScheduleList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentScheduleList
         * @static
         * @param {Proto.ContentScheduleList} m ContentScheduleList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentScheduleList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentSchedule.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentScheduleList to JSON.
         * @function toJSON
         * @memberof Proto.ContentScheduleList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentScheduleList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentScheduleList;
    })();

    Proto.ContentProduct = (function() {

        /**
         * Properties of a ContentProduct.
         * @memberof Proto
         * @interface IContentProduct
         * @property {string|null} [uid] ContentProduct uid
         * @property {string|null} [contentUID] ContentProduct contentUID
         * @property {string|null} [name] ContentProduct name
         * @property {string|null} [link] ContentProduct link
         * @property {number|null} [price] ContentProduct price
         * @property {string|null} [currency] ContentProduct currency
         * @property {Proto.IUploadFile|null} [image] ContentProduct image
         * @property {number|Long|null} [registerDate] ContentProduct registerDate
         * @property {number|Long|null} [modifyDate] ContentProduct modifyDate
         * @property {number|null} [status] ContentProduct status
         */

        /**
         * Constructs a new ContentProduct.
         * @memberof Proto
         * @classdesc Represents a ContentProduct.
         * @implements IContentProduct
         * @constructor
         * @param {Proto.IContentProduct=} [p] Properties to set
         */
        function ContentProduct(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentProduct uid.
         * @member {string} uid
         * @memberof Proto.ContentProduct
         * @instance
         */
        ContentProduct.prototype.uid = "";

        /**
         * ContentProduct contentUID.
         * @member {string} contentUID
         * @memberof Proto.ContentProduct
         * @instance
         */
        ContentProduct.prototype.contentUID = "";

        /**
         * ContentProduct name.
         * @member {string} name
         * @memberof Proto.ContentProduct
         * @instance
         */
        ContentProduct.prototype.name = "";

        /**
         * ContentProduct link.
         * @member {string} link
         * @memberof Proto.ContentProduct
         * @instance
         */
        ContentProduct.prototype.link = "";

        /**
         * ContentProduct price.
         * @member {number} price
         * @memberof Proto.ContentProduct
         * @instance
         */
        ContentProduct.prototype.price = 0;

        /**
         * ContentProduct currency.
         * @member {string} currency
         * @memberof Proto.ContentProduct
         * @instance
         */
        ContentProduct.prototype.currency = "";

        /**
         * ContentProduct image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.ContentProduct
         * @instance
         */
        ContentProduct.prototype.image = null;

        /**
         * ContentProduct registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentProduct
         * @instance
         */
        ContentProduct.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentProduct modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentProduct
         * @instance
         */
        ContentProduct.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentProduct status.
         * @member {number} status
         * @memberof Proto.ContentProduct
         * @instance
         */
        ContentProduct.prototype.status = 0;

        /**
         * Creates a new ContentProduct instance using the specified properties.
         * @function create
         * @memberof Proto.ContentProduct
         * @static
         * @param {Proto.IContentProduct=} [properties] Properties to set
         * @returns {Proto.ContentProduct} ContentProduct instance
         */
        ContentProduct.create = function create(properties) {
            return new ContentProduct(properties);
        };

        /**
         * Encodes the specified ContentProduct message. Does not implicitly {@link Proto.ContentProduct.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentProduct
         * @static
         * @param {Proto.IContentProduct} m ContentProduct message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentProduct.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(18).string(m.contentUID);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.link != null && Object.hasOwnProperty.call(m, "link"))
                w.uint32(34).string(m.link);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(41).double(m.price);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(50).string(m.currency);
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(58).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(80).int32(m.status);
            return w;
        };

        /**
         * Decodes a ContentProduct message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentProduct
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentProduct} ContentProduct
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentProduct.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentProduct();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.contentUID = r.string();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.link = r.string();
                    break;
                case 5:
                    m.price = r.double();
                    break;
                case 6:
                    m.currency = r.string();
                    break;
                case 7:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                case 10:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentProduct message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentProduct
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentProduct} ContentProduct
         */
        ContentProduct.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentProduct)
                return d;
            var m = new $root.Proto.ContentProduct();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.link != null) {
                m.link = String(d.link);
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.ContentProduct.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentProduct message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentProduct
         * @static
         * @param {Proto.ContentProduct} m ContentProduct
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentProduct.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.contentUID = "";
                d.name = "";
                d.link = "";
                d.price = 0;
                d.currency = "";
                d.image = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.link != null && m.hasOwnProperty("link")) {
                d.link = m.link;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this ContentProduct to JSON.
         * @function toJSON
         * @memberof Proto.ContentProduct
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentProduct.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentProduct;
    })();

    Proto.ContentProductList = (function() {

        /**
         * Properties of a ContentProductList.
         * @memberof Proto
         * @interface IContentProductList
         * @property {Array.<Proto.IContentProduct>|null} [value] ContentProductList value
         */

        /**
         * Constructs a new ContentProductList.
         * @memberof Proto
         * @classdesc Represents a ContentProductList.
         * @implements IContentProductList
         * @constructor
         * @param {Proto.IContentProductList=} [p] Properties to set
         */
        function ContentProductList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentProductList value.
         * @member {Array.<Proto.IContentProduct>} value
         * @memberof Proto.ContentProductList
         * @instance
         */
        ContentProductList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentProductList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentProductList
         * @static
         * @param {Proto.IContentProductList=} [properties] Properties to set
         * @returns {Proto.ContentProductList} ContentProductList instance
         */
        ContentProductList.create = function create(properties) {
            return new ContentProductList(properties);
        };

        /**
         * Encodes the specified ContentProductList message. Does not implicitly {@link Proto.ContentProductList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentProductList
         * @static
         * @param {Proto.IContentProductList} m ContentProductList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentProductList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentProduct.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentProductList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentProductList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentProductList} ContentProductList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentProductList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentProductList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentProduct.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentProductList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentProductList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentProductList} ContentProductList
         */
        ContentProductList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentProductList)
                return d;
            var m = new $root.Proto.ContentProductList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentProductList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentProductList.value: object expected");
                    m.value[i] = $root.Proto.ContentProduct.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentProductList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentProductList
         * @static
         * @param {Proto.ContentProductList} m ContentProductList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentProductList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentProduct.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentProductList to JSON.
         * @function toJSON
         * @memberof Proto.ContentProductList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentProductList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentProductList;
    })();

    Proto.ContentCommerceVariationOrder = (function() {

        /**
         * Properties of a ContentCommerceVariationOrder.
         * @memberof Proto
         * @interface IContentCommerceVariationOrder
         * @property {string|null} [uid] ContentCommerceVariationOrder uid
         * @property {string|null} [productUID] ContentCommerceVariationOrder productUID
         * @property {string|null} [userUID] ContentCommerceVariationOrder userUID
         * @property {string|null} [thirdCheckoutId] ContentCommerceVariationOrder thirdCheckoutId
         * @property {string|null} [thirdPaymentId] ContentCommerceVariationOrder thirdPaymentId
         * @property {number|null} [quantity] ContentCommerceVariationOrder quantity
         * @property {number|null} [price] ContentCommerceVariationOrder price
         * @property {string|null} [currency] ContentCommerceVariationOrder currency
         * @property {string|null} [trackingNumber] ContentCommerceVariationOrder trackingNumber
         * @property {number|null} [trackingStatus] ContentCommerceVariationOrder trackingStatus
         * @property {number|Long|null} [registerDate] ContentCommerceVariationOrder registerDate
         * @property {number|Long|null} [modifyDate] ContentCommerceVariationOrder modifyDate
         * @property {number|null} [status] ContentCommerceVariationOrder status
         * @property {Proto.IUser|null} [user] ContentCommerceVariationOrder user
         * @property {Proto.IContentCommerce|null} [commerce] ContentCommerceVariationOrder commerce
         * @property {string|null} [phone] ContentCommerceVariationOrder phone
         * @property {string|null} [postalCode] ContentCommerceVariationOrder postalCode
         * @property {string|null} [country] ContentCommerceVariationOrder country
         * @property {string|null} [state] ContentCommerceVariationOrder state
         * @property {string|null} [city] ContentCommerceVariationOrder city
         * @property {string|null} [address] ContentCommerceVariationOrder address
         * @property {number|null} [fee] ContentCommerceVariationOrder fee
         * @property {number|null} [total] ContentCommerceVariationOrder total
         * @property {number|null} [trackingCompany] ContentCommerceVariationOrder trackingCompany
         * @property {number|null} [payFee] ContentCommerceVariationOrder payFee
         * @property {boolean|null} [userCancel] ContentCommerceVariationOrder userCancel
         * @property {number|Long|null} [refundDate] ContentCommerceVariationOrder refundDate
         * @property {string|null} [receiptName] ContentCommerceVariationOrder receiptName
         * @property {string|null} [addressDetail] ContentCommerceVariationOrder addressDetail
         * @property {boolean|null} [invoiceFixed] ContentCommerceVariationOrder invoiceFixed
         * @property {number|null} [paymentType] ContentCommerceVariationOrder paymentType
         * @property {number|null} [thirdType] ContentCommerceVariationOrder thirdType
         * @property {number|null} [cashFee] ContentCommerceVariationOrder cashFee
         * @property {Proto.ICryptoContract|null} [nftContract] ContentCommerceVariationOrder nftContract
         * @property {Array.<Proto.ICryptoNFT>|null} [nftList] ContentCommerceVariationOrder nftList
         * @property {Proto.ICryptoWallet|null} [userWallet] ContentCommerceVariationOrder userWallet
         * @property {number|null} [orderType] ContentCommerceVariationOrder orderType
         * @property {Proto.IUser|null} [transferUser] ContentCommerceVariationOrder transferUser
         * @property {Proto.ICryptoWallet|null} [transferUserWallet] ContentCommerceVariationOrder transferUserWallet
         * @property {string|null} [companyUID] ContentCommerceVariationOrder companyUID
         * @property {string|null} [variationName] ContentCommerceVariationOrder variationName
         * @property {string|null} [productName] ContentCommerceVariationOrder productName
         * @property {number|null} [failType] ContentCommerceVariationOrder failType
         * @property {number|null} [failCode] ContentCommerceVariationOrder failCode
         * @property {number|null} [point] ContentCommerceVariationOrder point
         */

        /**
         * Constructs a new ContentCommerceVariationOrder.
         * @memberof Proto
         * @classdesc Represents a ContentCommerceVariationOrder.
         * @implements IContentCommerceVariationOrder
         * @constructor
         * @param {Proto.IContentCommerceVariationOrder=} [p] Properties to set
         */
        function ContentCommerceVariationOrder(p) {
            this.nftList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentCommerceVariationOrder uid.
         * @member {string} uid
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.uid = "";

        /**
         * ContentCommerceVariationOrder productUID.
         * @member {string} productUID
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.productUID = "";

        /**
         * ContentCommerceVariationOrder userUID.
         * @member {string} userUID
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.userUID = "";

        /**
         * ContentCommerceVariationOrder thirdCheckoutId.
         * @member {string} thirdCheckoutId
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.thirdCheckoutId = "";

        /**
         * ContentCommerceVariationOrder thirdPaymentId.
         * @member {string} thirdPaymentId
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.thirdPaymentId = "";

        /**
         * ContentCommerceVariationOrder quantity.
         * @member {number} quantity
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.quantity = 0;

        /**
         * ContentCommerceVariationOrder price.
         * @member {number} price
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.price = 0;

        /**
         * ContentCommerceVariationOrder currency.
         * @member {string} currency
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.currency = "";

        /**
         * ContentCommerceVariationOrder trackingNumber.
         * @member {string} trackingNumber
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.trackingNumber = "";

        /**
         * ContentCommerceVariationOrder trackingStatus.
         * @member {number} trackingStatus
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.trackingStatus = 0;

        /**
         * ContentCommerceVariationOrder registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerceVariationOrder modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerceVariationOrder status.
         * @member {number} status
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.status = 0;

        /**
         * ContentCommerceVariationOrder user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.user = null;

        /**
         * ContentCommerceVariationOrder commerce.
         * @member {Proto.IContentCommerce|null|undefined} commerce
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.commerce = null;

        /**
         * ContentCommerceVariationOrder phone.
         * @member {string} phone
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.phone = "";

        /**
         * ContentCommerceVariationOrder postalCode.
         * @member {string} postalCode
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.postalCode = "";

        /**
         * ContentCommerceVariationOrder country.
         * @member {string} country
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.country = "";

        /**
         * ContentCommerceVariationOrder state.
         * @member {string} state
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.state = "";

        /**
         * ContentCommerceVariationOrder city.
         * @member {string} city
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.city = "";

        /**
         * ContentCommerceVariationOrder address.
         * @member {string} address
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.address = "";

        /**
         * ContentCommerceVariationOrder fee.
         * @member {number} fee
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.fee = 0;

        /**
         * ContentCommerceVariationOrder total.
         * @member {number} total
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.total = 0;

        /**
         * ContentCommerceVariationOrder trackingCompany.
         * @member {number} trackingCompany
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.trackingCompany = 0;

        /**
         * ContentCommerceVariationOrder payFee.
         * @member {number} payFee
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.payFee = 0;

        /**
         * ContentCommerceVariationOrder userCancel.
         * @member {boolean} userCancel
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.userCancel = false;

        /**
         * ContentCommerceVariationOrder refundDate.
         * @member {number|Long} refundDate
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.refundDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerceVariationOrder receiptName.
         * @member {string} receiptName
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.receiptName = "";

        /**
         * ContentCommerceVariationOrder addressDetail.
         * @member {string} addressDetail
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.addressDetail = "";

        /**
         * ContentCommerceVariationOrder invoiceFixed.
         * @member {boolean} invoiceFixed
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.invoiceFixed = false;

        /**
         * ContentCommerceVariationOrder paymentType.
         * @member {number} paymentType
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.paymentType = 0;

        /**
         * ContentCommerceVariationOrder thirdType.
         * @member {number} thirdType
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.thirdType = 0;

        /**
         * ContentCommerceVariationOrder cashFee.
         * @member {number} cashFee
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.cashFee = 0;

        /**
         * ContentCommerceVariationOrder nftContract.
         * @member {Proto.ICryptoContract|null|undefined} nftContract
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.nftContract = null;

        /**
         * ContentCommerceVariationOrder nftList.
         * @member {Array.<Proto.ICryptoNFT>} nftList
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.nftList = $util.emptyArray;

        /**
         * ContentCommerceVariationOrder userWallet.
         * @member {Proto.ICryptoWallet|null|undefined} userWallet
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.userWallet = null;

        /**
         * ContentCommerceVariationOrder orderType.
         * @member {number} orderType
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.orderType = 0;

        /**
         * ContentCommerceVariationOrder transferUser.
         * @member {Proto.IUser|null|undefined} transferUser
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.transferUser = null;

        /**
         * ContentCommerceVariationOrder transferUserWallet.
         * @member {Proto.ICryptoWallet|null|undefined} transferUserWallet
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.transferUserWallet = null;

        /**
         * ContentCommerceVariationOrder companyUID.
         * @member {string} companyUID
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.companyUID = "";

        /**
         * ContentCommerceVariationOrder variationName.
         * @member {string} variationName
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.variationName = "";

        /**
         * ContentCommerceVariationOrder productName.
         * @member {string} productName
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.productName = "";

        /**
         * ContentCommerceVariationOrder failType.
         * @member {number} failType
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.failType = 0;

        /**
         * ContentCommerceVariationOrder failCode.
         * @member {number} failCode
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.failCode = 0;

        /**
         * ContentCommerceVariationOrder point.
         * @member {number} point
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         */
        ContentCommerceVariationOrder.prototype.point = 0;

        /**
         * Creates a new ContentCommerceVariationOrder instance using the specified properties.
         * @function create
         * @memberof Proto.ContentCommerceVariationOrder
         * @static
         * @param {Proto.IContentCommerceVariationOrder=} [properties] Properties to set
         * @returns {Proto.ContentCommerceVariationOrder} ContentCommerceVariationOrder instance
         */
        ContentCommerceVariationOrder.create = function create(properties) {
            return new ContentCommerceVariationOrder(properties);
        };

        /**
         * Encodes the specified ContentCommerceVariationOrder message. Does not implicitly {@link Proto.ContentCommerceVariationOrder.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentCommerceVariationOrder
         * @static
         * @param {Proto.IContentCommerceVariationOrder} m ContentCommerceVariationOrder message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentCommerceVariationOrder.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.productUID != null && Object.hasOwnProperty.call(m, "productUID"))
                w.uint32(18).string(m.productUID);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(26).string(m.userUID);
            if (m.thirdCheckoutId != null && Object.hasOwnProperty.call(m, "thirdCheckoutId"))
                w.uint32(34).string(m.thirdCheckoutId);
            if (m.thirdPaymentId != null && Object.hasOwnProperty.call(m, "thirdPaymentId"))
                w.uint32(42).string(m.thirdPaymentId);
            if (m.quantity != null && Object.hasOwnProperty.call(m, "quantity"))
                w.uint32(48).int32(m.quantity);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(57).double(m.price);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(66).string(m.currency);
            if (m.trackingNumber != null && Object.hasOwnProperty.call(m, "trackingNumber"))
                w.uint32(74).string(m.trackingNumber);
            if (m.trackingStatus != null && Object.hasOwnProperty.call(m, "trackingStatus"))
                w.uint32(80).int32(m.trackingStatus);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(88).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(96).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(104).int32(m.status);
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(114).fork()).ldelim();
            if (m.commerce != null && Object.hasOwnProperty.call(m, "commerce"))
                $root.Proto.ContentCommerce.encode(m.commerce, w.uint32(122).fork()).ldelim();
            if (m.phone != null && Object.hasOwnProperty.call(m, "phone"))
                w.uint32(130).string(m.phone);
            if (m.postalCode != null && Object.hasOwnProperty.call(m, "postalCode"))
                w.uint32(138).string(m.postalCode);
            if (m.country != null && Object.hasOwnProperty.call(m, "country"))
                w.uint32(146).string(m.country);
            if (m.state != null && Object.hasOwnProperty.call(m, "state"))
                w.uint32(154).string(m.state);
            if (m.city != null && Object.hasOwnProperty.call(m, "city"))
                w.uint32(162).string(m.city);
            if (m.address != null && Object.hasOwnProperty.call(m, "address"))
                w.uint32(170).string(m.address);
            if (m.fee != null && Object.hasOwnProperty.call(m, "fee"))
                w.uint32(177).double(m.fee);
            if (m.total != null && Object.hasOwnProperty.call(m, "total"))
                w.uint32(185).double(m.total);
            if (m.trackingCompany != null && Object.hasOwnProperty.call(m, "trackingCompany"))
                w.uint32(192).int32(m.trackingCompany);
            if (m.payFee != null && Object.hasOwnProperty.call(m, "payFee"))
                w.uint32(201).double(m.payFee);
            if (m.userCancel != null && Object.hasOwnProperty.call(m, "userCancel"))
                w.uint32(208).bool(m.userCancel);
            if (m.refundDate != null && Object.hasOwnProperty.call(m, "refundDate"))
                w.uint32(216).int64(m.refundDate);
            if (m.receiptName != null && Object.hasOwnProperty.call(m, "receiptName"))
                w.uint32(226).string(m.receiptName);
            if (m.addressDetail != null && Object.hasOwnProperty.call(m, "addressDetail"))
                w.uint32(234).string(m.addressDetail);
            if (m.invoiceFixed != null && Object.hasOwnProperty.call(m, "invoiceFixed"))
                w.uint32(240).bool(m.invoiceFixed);
            if (m.paymentType != null && Object.hasOwnProperty.call(m, "paymentType"))
                w.uint32(248).int32(m.paymentType);
            if (m.thirdType != null && Object.hasOwnProperty.call(m, "thirdType"))
                w.uint32(256).int32(m.thirdType);
            if (m.cashFee != null && Object.hasOwnProperty.call(m, "cashFee"))
                w.uint32(265).double(m.cashFee);
            if (m.nftContract != null && Object.hasOwnProperty.call(m, "nftContract"))
                $root.Proto.CryptoContract.encode(m.nftContract, w.uint32(274).fork()).ldelim();
            if (m.nftList != null && m.nftList.length) {
                for (var i = 0; i < m.nftList.length; ++i)
                    $root.Proto.CryptoNFT.encode(m.nftList[i], w.uint32(282).fork()).ldelim();
            }
            if (m.userWallet != null && Object.hasOwnProperty.call(m, "userWallet"))
                $root.Proto.CryptoWallet.encode(m.userWallet, w.uint32(290).fork()).ldelim();
            if (m.orderType != null && Object.hasOwnProperty.call(m, "orderType"))
                w.uint32(296).int32(m.orderType);
            if (m.transferUser != null && Object.hasOwnProperty.call(m, "transferUser"))
                $root.Proto.User.encode(m.transferUser, w.uint32(306).fork()).ldelim();
            if (m.transferUserWallet != null && Object.hasOwnProperty.call(m, "transferUserWallet"))
                $root.Proto.CryptoWallet.encode(m.transferUserWallet, w.uint32(314).fork()).ldelim();
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(322).string(m.companyUID);
            if (m.variationName != null && Object.hasOwnProperty.call(m, "variationName"))
                w.uint32(330).string(m.variationName);
            if (m.productName != null && Object.hasOwnProperty.call(m, "productName"))
                w.uint32(338).string(m.productName);
            if (m.failType != null && Object.hasOwnProperty.call(m, "failType"))
                w.uint32(344).int32(m.failType);
            if (m.failCode != null && Object.hasOwnProperty.call(m, "failCode"))
                w.uint32(352).int32(m.failCode);
            if (m.point != null && Object.hasOwnProperty.call(m, "point"))
                w.uint32(361).double(m.point);
            return w;
        };

        /**
         * Decodes a ContentCommerceVariationOrder message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentCommerceVariationOrder
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentCommerceVariationOrder} ContentCommerceVariationOrder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentCommerceVariationOrder.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentCommerceVariationOrder();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.productUID = r.string();
                    break;
                case 3:
                    m.userUID = r.string();
                    break;
                case 4:
                    m.thirdCheckoutId = r.string();
                    break;
                case 5:
                    m.thirdPaymentId = r.string();
                    break;
                case 6:
                    m.quantity = r.int32();
                    break;
                case 7:
                    m.price = r.double();
                    break;
                case 8:
                    m.currency = r.string();
                    break;
                case 9:
                    m.trackingNumber = r.string();
                    break;
                case 10:
                    m.trackingStatus = r.int32();
                    break;
                case 11:
                    m.registerDate = r.int64();
                    break;
                case 12:
                    m.modifyDate = r.int64();
                    break;
                case 13:
                    m.status = r.int32();
                    break;
                case 14:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 15:
                    m.commerce = $root.Proto.ContentCommerce.decode(r, r.uint32());
                    break;
                case 16:
                    m.phone = r.string();
                    break;
                case 17:
                    m.postalCode = r.string();
                    break;
                case 18:
                    m.country = r.string();
                    break;
                case 19:
                    m.state = r.string();
                    break;
                case 20:
                    m.city = r.string();
                    break;
                case 21:
                    m.address = r.string();
                    break;
                case 22:
                    m.fee = r.double();
                    break;
                case 23:
                    m.total = r.double();
                    break;
                case 24:
                    m.trackingCompany = r.int32();
                    break;
                case 25:
                    m.payFee = r.double();
                    break;
                case 26:
                    m.userCancel = r.bool();
                    break;
                case 27:
                    m.refundDate = r.int64();
                    break;
                case 28:
                    m.receiptName = r.string();
                    break;
                case 29:
                    m.addressDetail = r.string();
                    break;
                case 30:
                    m.invoiceFixed = r.bool();
                    break;
                case 31:
                    m.paymentType = r.int32();
                    break;
                case 32:
                    m.thirdType = r.int32();
                    break;
                case 33:
                    m.cashFee = r.double();
                    break;
                case 34:
                    m.nftContract = $root.Proto.CryptoContract.decode(r, r.uint32());
                    break;
                case 35:
                    if (!(m.nftList && m.nftList.length))
                        m.nftList = [];
                    m.nftList.push($root.Proto.CryptoNFT.decode(r, r.uint32()));
                    break;
                case 36:
                    m.userWallet = $root.Proto.CryptoWallet.decode(r, r.uint32());
                    break;
                case 37:
                    m.orderType = r.int32();
                    break;
                case 38:
                    m.transferUser = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 39:
                    m.transferUserWallet = $root.Proto.CryptoWallet.decode(r, r.uint32());
                    break;
                case 40:
                    m.companyUID = r.string();
                    break;
                case 41:
                    m.variationName = r.string();
                    break;
                case 42:
                    m.productName = r.string();
                    break;
                case 43:
                    m.failType = r.int32();
                    break;
                case 44:
                    m.failCode = r.int32();
                    break;
                case 45:
                    m.point = r.double();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentCommerceVariationOrder message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentCommerceVariationOrder
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentCommerceVariationOrder} ContentCommerceVariationOrder
         */
        ContentCommerceVariationOrder.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentCommerceVariationOrder)
                return d;
            var m = new $root.Proto.ContentCommerceVariationOrder();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.productUID != null) {
                m.productUID = String(d.productUID);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.thirdCheckoutId != null) {
                m.thirdCheckoutId = String(d.thirdCheckoutId);
            }
            if (d.thirdPaymentId != null) {
                m.thirdPaymentId = String(d.thirdPaymentId);
            }
            if (d.quantity != null) {
                m.quantity = d.quantity | 0;
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.trackingNumber != null) {
                m.trackingNumber = String(d.trackingNumber);
            }
            if (d.trackingStatus != null) {
                m.trackingStatus = d.trackingStatus | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.ContentCommerceVariationOrder.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            if (d.commerce != null) {
                if (typeof d.commerce !== "object")
                    throw TypeError(".Proto.ContentCommerceVariationOrder.commerce: object expected");
                m.commerce = $root.Proto.ContentCommerce.fromObject(d.commerce);
            }
            if (d.phone != null) {
                m.phone = String(d.phone);
            }
            if (d.postalCode != null) {
                m.postalCode = String(d.postalCode);
            }
            if (d.country != null) {
                m.country = String(d.country);
            }
            if (d.state != null) {
                m.state = String(d.state);
            }
            if (d.city != null) {
                m.city = String(d.city);
            }
            if (d.address != null) {
                m.address = String(d.address);
            }
            if (d.fee != null) {
                m.fee = Number(d.fee);
            }
            if (d.total != null) {
                m.total = Number(d.total);
            }
            if (d.trackingCompany != null) {
                m.trackingCompany = d.trackingCompany | 0;
            }
            if (d.payFee != null) {
                m.payFee = Number(d.payFee);
            }
            if (d.userCancel != null) {
                m.userCancel = Boolean(d.userCancel);
            }
            if (d.refundDate != null) {
                if ($util.Long)
                    (m.refundDate = $util.Long.fromValue(d.refundDate)).unsigned = false;
                else if (typeof d.refundDate === "string")
                    m.refundDate = parseInt(d.refundDate, 10);
                else if (typeof d.refundDate === "number")
                    m.refundDate = d.refundDate;
                else if (typeof d.refundDate === "object")
                    m.refundDate = new $util.LongBits(d.refundDate.low >>> 0, d.refundDate.high >>> 0).toNumber();
            }
            if (d.receiptName != null) {
                m.receiptName = String(d.receiptName);
            }
            if (d.addressDetail != null) {
                m.addressDetail = String(d.addressDetail);
            }
            if (d.invoiceFixed != null) {
                m.invoiceFixed = Boolean(d.invoiceFixed);
            }
            if (d.paymentType != null) {
                m.paymentType = d.paymentType | 0;
            }
            if (d.thirdType != null) {
                m.thirdType = d.thirdType | 0;
            }
            if (d.cashFee != null) {
                m.cashFee = Number(d.cashFee);
            }
            if (d.nftContract != null) {
                if (typeof d.nftContract !== "object")
                    throw TypeError(".Proto.ContentCommerceVariationOrder.nftContract: object expected");
                m.nftContract = $root.Proto.CryptoContract.fromObject(d.nftContract);
            }
            if (d.nftList) {
                if (!Array.isArray(d.nftList))
                    throw TypeError(".Proto.ContentCommerceVariationOrder.nftList: array expected");
                m.nftList = [];
                for (var i = 0; i < d.nftList.length; ++i) {
                    if (typeof d.nftList[i] !== "object")
                        throw TypeError(".Proto.ContentCommerceVariationOrder.nftList: object expected");
                    m.nftList[i] = $root.Proto.CryptoNFT.fromObject(d.nftList[i]);
                }
            }
            if (d.userWallet != null) {
                if (typeof d.userWallet !== "object")
                    throw TypeError(".Proto.ContentCommerceVariationOrder.userWallet: object expected");
                m.userWallet = $root.Proto.CryptoWallet.fromObject(d.userWallet);
            }
            if (d.orderType != null) {
                m.orderType = d.orderType | 0;
            }
            if (d.transferUser != null) {
                if (typeof d.transferUser !== "object")
                    throw TypeError(".Proto.ContentCommerceVariationOrder.transferUser: object expected");
                m.transferUser = $root.Proto.User.fromObject(d.transferUser);
            }
            if (d.transferUserWallet != null) {
                if (typeof d.transferUserWallet !== "object")
                    throw TypeError(".Proto.ContentCommerceVariationOrder.transferUserWallet: object expected");
                m.transferUserWallet = $root.Proto.CryptoWallet.fromObject(d.transferUserWallet);
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.variationName != null) {
                m.variationName = String(d.variationName);
            }
            if (d.productName != null) {
                m.productName = String(d.productName);
            }
            if (d.failType != null) {
                m.failType = d.failType | 0;
            }
            if (d.failCode != null) {
                m.failCode = d.failCode | 0;
            }
            if (d.point != null) {
                m.point = Number(d.point);
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentCommerceVariationOrder message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentCommerceVariationOrder
         * @static
         * @param {Proto.ContentCommerceVariationOrder} m ContentCommerceVariationOrder
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentCommerceVariationOrder.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.nftList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.productUID = "";
                d.userUID = "";
                d.thirdCheckoutId = "";
                d.thirdPaymentId = "";
                d.quantity = 0;
                d.price = 0;
                d.currency = "";
                d.trackingNumber = "";
                d.trackingStatus = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.user = null;
                d.commerce = null;
                d.phone = "";
                d.postalCode = "";
                d.country = "";
                d.state = "";
                d.city = "";
                d.address = "";
                d.fee = 0;
                d.total = 0;
                d.trackingCompany = 0;
                d.payFee = 0;
                d.userCancel = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.refundDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.refundDate = o.longs === String ? "0" : 0;
                d.receiptName = "";
                d.addressDetail = "";
                d.invoiceFixed = false;
                d.paymentType = 0;
                d.thirdType = 0;
                d.cashFee = 0;
                d.nftContract = null;
                d.userWallet = null;
                d.orderType = 0;
                d.transferUser = null;
                d.transferUserWallet = null;
                d.companyUID = "";
                d.variationName = "";
                d.productName = "";
                d.failType = 0;
                d.failCode = 0;
                d.point = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.productUID != null && m.hasOwnProperty("productUID")) {
                d.productUID = m.productUID;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.thirdCheckoutId != null && m.hasOwnProperty("thirdCheckoutId")) {
                d.thirdCheckoutId = m.thirdCheckoutId;
            }
            if (m.thirdPaymentId != null && m.hasOwnProperty("thirdPaymentId")) {
                d.thirdPaymentId = m.thirdPaymentId;
            }
            if (m.quantity != null && m.hasOwnProperty("quantity")) {
                d.quantity = m.quantity;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.trackingNumber != null && m.hasOwnProperty("trackingNumber")) {
                d.trackingNumber = m.trackingNumber;
            }
            if (m.trackingStatus != null && m.hasOwnProperty("trackingStatus")) {
                d.trackingStatus = m.trackingStatus;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            if (m.commerce != null && m.hasOwnProperty("commerce")) {
                d.commerce = $root.Proto.ContentCommerce.toObject(m.commerce, o);
            }
            if (m.phone != null && m.hasOwnProperty("phone")) {
                d.phone = m.phone;
            }
            if (m.postalCode != null && m.hasOwnProperty("postalCode")) {
                d.postalCode = m.postalCode;
            }
            if (m.country != null && m.hasOwnProperty("country")) {
                d.country = m.country;
            }
            if (m.state != null && m.hasOwnProperty("state")) {
                d.state = m.state;
            }
            if (m.city != null && m.hasOwnProperty("city")) {
                d.city = m.city;
            }
            if (m.address != null && m.hasOwnProperty("address")) {
                d.address = m.address;
            }
            if (m.fee != null && m.hasOwnProperty("fee")) {
                d.fee = o.json && !isFinite(m.fee) ? String(m.fee) : m.fee;
            }
            if (m.total != null && m.hasOwnProperty("total")) {
                d.total = o.json && !isFinite(m.total) ? String(m.total) : m.total;
            }
            if (m.trackingCompany != null && m.hasOwnProperty("trackingCompany")) {
                d.trackingCompany = m.trackingCompany;
            }
            if (m.payFee != null && m.hasOwnProperty("payFee")) {
                d.payFee = o.json && !isFinite(m.payFee) ? String(m.payFee) : m.payFee;
            }
            if (m.userCancel != null && m.hasOwnProperty("userCancel")) {
                d.userCancel = m.userCancel;
            }
            if (m.refundDate != null && m.hasOwnProperty("refundDate")) {
                if (typeof m.refundDate === "number")
                    d.refundDate = o.longs === String ? String(m.refundDate) : m.refundDate;
                else
                    d.refundDate = o.longs === String ? $util.Long.prototype.toString.call(m.refundDate) : o.longs === Number ? new $util.LongBits(m.refundDate.low >>> 0, m.refundDate.high >>> 0).toNumber() : m.refundDate;
            }
            if (m.receiptName != null && m.hasOwnProperty("receiptName")) {
                d.receiptName = m.receiptName;
            }
            if (m.addressDetail != null && m.hasOwnProperty("addressDetail")) {
                d.addressDetail = m.addressDetail;
            }
            if (m.invoiceFixed != null && m.hasOwnProperty("invoiceFixed")) {
                d.invoiceFixed = m.invoiceFixed;
            }
            if (m.paymentType != null && m.hasOwnProperty("paymentType")) {
                d.paymentType = m.paymentType;
            }
            if (m.thirdType != null && m.hasOwnProperty("thirdType")) {
                d.thirdType = m.thirdType;
            }
            if (m.cashFee != null && m.hasOwnProperty("cashFee")) {
                d.cashFee = o.json && !isFinite(m.cashFee) ? String(m.cashFee) : m.cashFee;
            }
            if (m.nftContract != null && m.hasOwnProperty("nftContract")) {
                d.nftContract = $root.Proto.CryptoContract.toObject(m.nftContract, o);
            }
            if (m.nftList && m.nftList.length) {
                d.nftList = [];
                for (var j = 0; j < m.nftList.length; ++j) {
                    d.nftList[j] = $root.Proto.CryptoNFT.toObject(m.nftList[j], o);
                }
            }
            if (m.userWallet != null && m.hasOwnProperty("userWallet")) {
                d.userWallet = $root.Proto.CryptoWallet.toObject(m.userWallet, o);
            }
            if (m.orderType != null && m.hasOwnProperty("orderType")) {
                d.orderType = m.orderType;
            }
            if (m.transferUser != null && m.hasOwnProperty("transferUser")) {
                d.transferUser = $root.Proto.User.toObject(m.transferUser, o);
            }
            if (m.transferUserWallet != null && m.hasOwnProperty("transferUserWallet")) {
                d.transferUserWallet = $root.Proto.CryptoWallet.toObject(m.transferUserWallet, o);
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.variationName != null && m.hasOwnProperty("variationName")) {
                d.variationName = m.variationName;
            }
            if (m.productName != null && m.hasOwnProperty("productName")) {
                d.productName = m.productName;
            }
            if (m.failType != null && m.hasOwnProperty("failType")) {
                d.failType = m.failType;
            }
            if (m.failCode != null && m.hasOwnProperty("failCode")) {
                d.failCode = m.failCode;
            }
            if (m.point != null && m.hasOwnProperty("point")) {
                d.point = o.json && !isFinite(m.point) ? String(m.point) : m.point;
            }
            return d;
        };

        /**
         * Converts this ContentCommerceVariationOrder to JSON.
         * @function toJSON
         * @memberof Proto.ContentCommerceVariationOrder
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentCommerceVariationOrder.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentCommerceVariationOrder;
    })();

    Proto.ContentCommerceVariationOrderList = (function() {

        /**
         * Properties of a ContentCommerceVariationOrderList.
         * @memberof Proto
         * @interface IContentCommerceVariationOrderList
         * @property {Array.<Proto.IContentCommerceVariationOrder>|null} [value] ContentCommerceVariationOrderList value
         */

        /**
         * Constructs a new ContentCommerceVariationOrderList.
         * @memberof Proto
         * @classdesc Represents a ContentCommerceVariationOrderList.
         * @implements IContentCommerceVariationOrderList
         * @constructor
         * @param {Proto.IContentCommerceVariationOrderList=} [p] Properties to set
         */
        function ContentCommerceVariationOrderList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentCommerceVariationOrderList value.
         * @member {Array.<Proto.IContentCommerceVariationOrder>} value
         * @memberof Proto.ContentCommerceVariationOrderList
         * @instance
         */
        ContentCommerceVariationOrderList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentCommerceVariationOrderList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentCommerceVariationOrderList
         * @static
         * @param {Proto.IContentCommerceVariationOrderList=} [properties] Properties to set
         * @returns {Proto.ContentCommerceVariationOrderList} ContentCommerceVariationOrderList instance
         */
        ContentCommerceVariationOrderList.create = function create(properties) {
            return new ContentCommerceVariationOrderList(properties);
        };

        /**
         * Encodes the specified ContentCommerceVariationOrderList message. Does not implicitly {@link Proto.ContentCommerceVariationOrderList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentCommerceVariationOrderList
         * @static
         * @param {Proto.IContentCommerceVariationOrderList} m ContentCommerceVariationOrderList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentCommerceVariationOrderList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentCommerceVariationOrder.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentCommerceVariationOrderList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentCommerceVariationOrderList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentCommerceVariationOrderList} ContentCommerceVariationOrderList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentCommerceVariationOrderList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentCommerceVariationOrderList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentCommerceVariationOrder.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentCommerceVariationOrderList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentCommerceVariationOrderList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentCommerceVariationOrderList} ContentCommerceVariationOrderList
         */
        ContentCommerceVariationOrderList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentCommerceVariationOrderList)
                return d;
            var m = new $root.Proto.ContentCommerceVariationOrderList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentCommerceVariationOrderList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentCommerceVariationOrderList.value: object expected");
                    m.value[i] = $root.Proto.ContentCommerceVariationOrder.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentCommerceVariationOrderList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentCommerceVariationOrderList
         * @static
         * @param {Proto.ContentCommerceVariationOrderList} m ContentCommerceVariationOrderList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentCommerceVariationOrderList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentCommerceVariationOrder.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentCommerceVariationOrderList to JSON.
         * @function toJSON
         * @memberof Proto.ContentCommerceVariationOrderList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentCommerceVariationOrderList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentCommerceVariationOrderList;
    })();

    Proto.ContentCommerceVariationProduct = (function() {

        /**
         * Properties of a ContentCommerceVariationProduct.
         * @memberof Proto
         * @interface IContentCommerceVariationProduct
         * @property {string|null} [uid] ContentCommerceVariationProduct uid
         * @property {string|null} [variationUID] ContentCommerceVariationProduct variationUID
         * @property {string|null} [name] ContentCommerceVariationProduct name
         * @property {number|null} [quantity] ContentCommerceVariationProduct quantity
         * @property {number|null} [price] ContentCommerceVariationProduct price
         * @property {string|null} [currency] ContentCommerceVariationProduct currency
         * @property {string|null} [thirdProductUID] ContentCommerceVariationProduct thirdProductUID
         * @property {string|null} [thirdPriceUID] ContentCommerceVariationProduct thirdPriceUID
         * @property {string|null} [trackingNumber] ContentCommerceVariationProduct trackingNumber
         * @property {number|Long|null} [registerDate] ContentCommerceVariationProduct registerDate
         * @property {number|Long|null} [modifyDate] ContentCommerceVariationProduct modifyDate
         * @property {number|null} [status] ContentCommerceVariationProduct status
         * @property {Proto.ICommerceOrderCount|null} [orderCount] ContentCommerceVariationProduct orderCount
         * @property {number|null} [quantityLimit] ContentCommerceVariationProduct quantityLimit
         * @property {number|null} [quantityFirst] ContentCommerceVariationProduct quantityFirst
         */

        /**
         * Constructs a new ContentCommerceVariationProduct.
         * @memberof Proto
         * @classdesc Represents a ContentCommerceVariationProduct.
         * @implements IContentCommerceVariationProduct
         * @constructor
         * @param {Proto.IContentCommerceVariationProduct=} [p] Properties to set
         */
        function ContentCommerceVariationProduct(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentCommerceVariationProduct uid.
         * @member {string} uid
         * @memberof Proto.ContentCommerceVariationProduct
         * @instance
         */
        ContentCommerceVariationProduct.prototype.uid = "";

        /**
         * ContentCommerceVariationProduct variationUID.
         * @member {string} variationUID
         * @memberof Proto.ContentCommerceVariationProduct
         * @instance
         */
        ContentCommerceVariationProduct.prototype.variationUID = "";

        /**
         * ContentCommerceVariationProduct name.
         * @member {string} name
         * @memberof Proto.ContentCommerceVariationProduct
         * @instance
         */
        ContentCommerceVariationProduct.prototype.name = "";

        /**
         * ContentCommerceVariationProduct quantity.
         * @member {number} quantity
         * @memberof Proto.ContentCommerceVariationProduct
         * @instance
         */
        ContentCommerceVariationProduct.prototype.quantity = 0;

        /**
         * ContentCommerceVariationProduct price.
         * @member {number} price
         * @memberof Proto.ContentCommerceVariationProduct
         * @instance
         */
        ContentCommerceVariationProduct.prototype.price = 0;

        /**
         * ContentCommerceVariationProduct currency.
         * @member {string} currency
         * @memberof Proto.ContentCommerceVariationProduct
         * @instance
         */
        ContentCommerceVariationProduct.prototype.currency = "";

        /**
         * ContentCommerceVariationProduct thirdProductUID.
         * @member {string} thirdProductUID
         * @memberof Proto.ContentCommerceVariationProduct
         * @instance
         */
        ContentCommerceVariationProduct.prototype.thirdProductUID = "";

        /**
         * ContentCommerceVariationProduct thirdPriceUID.
         * @member {string} thirdPriceUID
         * @memberof Proto.ContentCommerceVariationProduct
         * @instance
         */
        ContentCommerceVariationProduct.prototype.thirdPriceUID = "";

        /**
         * ContentCommerceVariationProduct trackingNumber.
         * @member {string} trackingNumber
         * @memberof Proto.ContentCommerceVariationProduct
         * @instance
         */
        ContentCommerceVariationProduct.prototype.trackingNumber = "";

        /**
         * ContentCommerceVariationProduct registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentCommerceVariationProduct
         * @instance
         */
        ContentCommerceVariationProduct.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerceVariationProduct modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentCommerceVariationProduct
         * @instance
         */
        ContentCommerceVariationProduct.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerceVariationProduct status.
         * @member {number} status
         * @memberof Proto.ContentCommerceVariationProduct
         * @instance
         */
        ContentCommerceVariationProduct.prototype.status = 0;

        /**
         * ContentCommerceVariationProduct orderCount.
         * @member {Proto.ICommerceOrderCount|null|undefined} orderCount
         * @memberof Proto.ContentCommerceVariationProduct
         * @instance
         */
        ContentCommerceVariationProduct.prototype.orderCount = null;

        /**
         * ContentCommerceVariationProduct quantityLimit.
         * @member {number} quantityLimit
         * @memberof Proto.ContentCommerceVariationProduct
         * @instance
         */
        ContentCommerceVariationProduct.prototype.quantityLimit = 0;

        /**
         * ContentCommerceVariationProduct quantityFirst.
         * @member {number} quantityFirst
         * @memberof Proto.ContentCommerceVariationProduct
         * @instance
         */
        ContentCommerceVariationProduct.prototype.quantityFirst = 0;

        /**
         * Creates a new ContentCommerceVariationProduct instance using the specified properties.
         * @function create
         * @memberof Proto.ContentCommerceVariationProduct
         * @static
         * @param {Proto.IContentCommerceVariationProduct=} [properties] Properties to set
         * @returns {Proto.ContentCommerceVariationProduct} ContentCommerceVariationProduct instance
         */
        ContentCommerceVariationProduct.create = function create(properties) {
            return new ContentCommerceVariationProduct(properties);
        };

        /**
         * Encodes the specified ContentCommerceVariationProduct message. Does not implicitly {@link Proto.ContentCommerceVariationProduct.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentCommerceVariationProduct
         * @static
         * @param {Proto.IContentCommerceVariationProduct} m ContentCommerceVariationProduct message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentCommerceVariationProduct.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.variationUID != null && Object.hasOwnProperty.call(m, "variationUID"))
                w.uint32(18).string(m.variationUID);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.quantity != null && Object.hasOwnProperty.call(m, "quantity"))
                w.uint32(32).int32(m.quantity);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(41).double(m.price);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(50).string(m.currency);
            if (m.thirdProductUID != null && Object.hasOwnProperty.call(m, "thirdProductUID"))
                w.uint32(58).string(m.thirdProductUID);
            if (m.thirdPriceUID != null && Object.hasOwnProperty.call(m, "thirdPriceUID"))
                w.uint32(66).string(m.thirdPriceUID);
            if (m.trackingNumber != null && Object.hasOwnProperty.call(m, "trackingNumber"))
                w.uint32(74).string(m.trackingNumber);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(80).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(88).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(96).int32(m.status);
            if (m.orderCount != null && Object.hasOwnProperty.call(m, "orderCount"))
                $root.Proto.CommerceOrderCount.encode(m.orderCount, w.uint32(106).fork()).ldelim();
            if (m.quantityLimit != null && Object.hasOwnProperty.call(m, "quantityLimit"))
                w.uint32(112).int32(m.quantityLimit);
            if (m.quantityFirst != null && Object.hasOwnProperty.call(m, "quantityFirst"))
                w.uint32(120).int32(m.quantityFirst);
            return w;
        };

        /**
         * Decodes a ContentCommerceVariationProduct message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentCommerceVariationProduct
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentCommerceVariationProduct} ContentCommerceVariationProduct
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentCommerceVariationProduct.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentCommerceVariationProduct();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.variationUID = r.string();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.quantity = r.int32();
                    break;
                case 5:
                    m.price = r.double();
                    break;
                case 6:
                    m.currency = r.string();
                    break;
                case 7:
                    m.thirdProductUID = r.string();
                    break;
                case 8:
                    m.thirdPriceUID = r.string();
                    break;
                case 9:
                    m.trackingNumber = r.string();
                    break;
                case 10:
                    m.registerDate = r.int64();
                    break;
                case 11:
                    m.modifyDate = r.int64();
                    break;
                case 12:
                    m.status = r.int32();
                    break;
                case 13:
                    m.orderCount = $root.Proto.CommerceOrderCount.decode(r, r.uint32());
                    break;
                case 14:
                    m.quantityLimit = r.int32();
                    break;
                case 15:
                    m.quantityFirst = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentCommerceVariationProduct message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentCommerceVariationProduct
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentCommerceVariationProduct} ContentCommerceVariationProduct
         */
        ContentCommerceVariationProduct.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentCommerceVariationProduct)
                return d;
            var m = new $root.Proto.ContentCommerceVariationProduct();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.variationUID != null) {
                m.variationUID = String(d.variationUID);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.quantity != null) {
                m.quantity = d.quantity | 0;
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.thirdProductUID != null) {
                m.thirdProductUID = String(d.thirdProductUID);
            }
            if (d.thirdPriceUID != null) {
                m.thirdPriceUID = String(d.thirdPriceUID);
            }
            if (d.trackingNumber != null) {
                m.trackingNumber = String(d.trackingNumber);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.orderCount != null) {
                if (typeof d.orderCount !== "object")
                    throw TypeError(".Proto.ContentCommerceVariationProduct.orderCount: object expected");
                m.orderCount = $root.Proto.CommerceOrderCount.fromObject(d.orderCount);
            }
            if (d.quantityLimit != null) {
                m.quantityLimit = d.quantityLimit | 0;
            }
            if (d.quantityFirst != null) {
                m.quantityFirst = d.quantityFirst | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentCommerceVariationProduct message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentCommerceVariationProduct
         * @static
         * @param {Proto.ContentCommerceVariationProduct} m ContentCommerceVariationProduct
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentCommerceVariationProduct.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.variationUID = "";
                d.name = "";
                d.quantity = 0;
                d.price = 0;
                d.currency = "";
                d.thirdProductUID = "";
                d.thirdPriceUID = "";
                d.trackingNumber = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.orderCount = null;
                d.quantityLimit = 0;
                d.quantityFirst = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.variationUID != null && m.hasOwnProperty("variationUID")) {
                d.variationUID = m.variationUID;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.quantity != null && m.hasOwnProperty("quantity")) {
                d.quantity = m.quantity;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.thirdProductUID != null && m.hasOwnProperty("thirdProductUID")) {
                d.thirdProductUID = m.thirdProductUID;
            }
            if (m.thirdPriceUID != null && m.hasOwnProperty("thirdPriceUID")) {
                d.thirdPriceUID = m.thirdPriceUID;
            }
            if (m.trackingNumber != null && m.hasOwnProperty("trackingNumber")) {
                d.trackingNumber = m.trackingNumber;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.orderCount != null && m.hasOwnProperty("orderCount")) {
                d.orderCount = $root.Proto.CommerceOrderCount.toObject(m.orderCount, o);
            }
            if (m.quantityLimit != null && m.hasOwnProperty("quantityLimit")) {
                d.quantityLimit = m.quantityLimit;
            }
            if (m.quantityFirst != null && m.hasOwnProperty("quantityFirst")) {
                d.quantityFirst = m.quantityFirst;
            }
            return d;
        };

        /**
         * Converts this ContentCommerceVariationProduct to JSON.
         * @function toJSON
         * @memberof Proto.ContentCommerceVariationProduct
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentCommerceVariationProduct.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentCommerceVariationProduct;
    })();

    Proto.ContentCommerceVariation = (function() {

        /**
         * Properties of a ContentCommerceVariation.
         * @memberof Proto
         * @interface IContentCommerceVariation
         * @property {string|null} [uid] ContentCommerceVariation uid
         * @property {string|null} [commerceUID] ContentCommerceVariation commerceUID
         * @property {string|null} [name] ContentCommerceVariation name
         * @property {Proto.IUploadFile|null} [image] ContentCommerceVariation image
         * @property {string|null} [productOrder] ContentCommerceVariation productOrder
         * @property {number|Long|null} [registerDate] ContentCommerceVariation registerDate
         * @property {number|Long|null} [modifyDate] ContentCommerceVariation modifyDate
         * @property {number|null} [status] ContentCommerceVariation status
         * @property {Array.<Proto.IContentCommerceVariationProduct>|null} [productList] ContentCommerceVariation productList
         */

        /**
         * Constructs a new ContentCommerceVariation.
         * @memberof Proto
         * @classdesc Represents a ContentCommerceVariation.
         * @implements IContentCommerceVariation
         * @constructor
         * @param {Proto.IContentCommerceVariation=} [p] Properties to set
         */
        function ContentCommerceVariation(p) {
            this.productList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentCommerceVariation uid.
         * @member {string} uid
         * @memberof Proto.ContentCommerceVariation
         * @instance
         */
        ContentCommerceVariation.prototype.uid = "";

        /**
         * ContentCommerceVariation commerceUID.
         * @member {string} commerceUID
         * @memberof Proto.ContentCommerceVariation
         * @instance
         */
        ContentCommerceVariation.prototype.commerceUID = "";

        /**
         * ContentCommerceVariation name.
         * @member {string} name
         * @memberof Proto.ContentCommerceVariation
         * @instance
         */
        ContentCommerceVariation.prototype.name = "";

        /**
         * ContentCommerceVariation image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.ContentCommerceVariation
         * @instance
         */
        ContentCommerceVariation.prototype.image = null;

        /**
         * ContentCommerceVariation productOrder.
         * @member {string} productOrder
         * @memberof Proto.ContentCommerceVariation
         * @instance
         */
        ContentCommerceVariation.prototype.productOrder = "";

        /**
         * ContentCommerceVariation registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentCommerceVariation
         * @instance
         */
        ContentCommerceVariation.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerceVariation modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentCommerceVariation
         * @instance
         */
        ContentCommerceVariation.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerceVariation status.
         * @member {number} status
         * @memberof Proto.ContentCommerceVariation
         * @instance
         */
        ContentCommerceVariation.prototype.status = 0;

        /**
         * ContentCommerceVariation productList.
         * @member {Array.<Proto.IContentCommerceVariationProduct>} productList
         * @memberof Proto.ContentCommerceVariation
         * @instance
         */
        ContentCommerceVariation.prototype.productList = $util.emptyArray;

        /**
         * Creates a new ContentCommerceVariation instance using the specified properties.
         * @function create
         * @memberof Proto.ContentCommerceVariation
         * @static
         * @param {Proto.IContentCommerceVariation=} [properties] Properties to set
         * @returns {Proto.ContentCommerceVariation} ContentCommerceVariation instance
         */
        ContentCommerceVariation.create = function create(properties) {
            return new ContentCommerceVariation(properties);
        };

        /**
         * Encodes the specified ContentCommerceVariation message. Does not implicitly {@link Proto.ContentCommerceVariation.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentCommerceVariation
         * @static
         * @param {Proto.IContentCommerceVariation} m ContentCommerceVariation message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentCommerceVariation.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.commerceUID != null && Object.hasOwnProperty.call(m, "commerceUID"))
                w.uint32(18).string(m.commerceUID);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(34).fork()).ldelim();
            if (m.productOrder != null && Object.hasOwnProperty.call(m, "productOrder"))
                w.uint32(42).string(m.productOrder);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(64).int32(m.status);
            if (m.productList != null && m.productList.length) {
                for (var i = 0; i < m.productList.length; ++i)
                    $root.Proto.ContentCommerceVariationProduct.encode(m.productList[i], w.uint32(74).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentCommerceVariation message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentCommerceVariation
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentCommerceVariation} ContentCommerceVariation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentCommerceVariation.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentCommerceVariation();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.commerceUID = r.string();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 5:
                    m.productOrder = r.string();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.status = r.int32();
                    break;
                case 9:
                    if (!(m.productList && m.productList.length))
                        m.productList = [];
                    m.productList.push($root.Proto.ContentCommerceVariationProduct.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentCommerceVariation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentCommerceVariation
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentCommerceVariation} ContentCommerceVariation
         */
        ContentCommerceVariation.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentCommerceVariation)
                return d;
            var m = new $root.Proto.ContentCommerceVariation();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.commerceUID != null) {
                m.commerceUID = String(d.commerceUID);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.ContentCommerceVariation.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.productOrder != null) {
                m.productOrder = String(d.productOrder);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.productList) {
                if (!Array.isArray(d.productList))
                    throw TypeError(".Proto.ContentCommerceVariation.productList: array expected");
                m.productList = [];
                for (var i = 0; i < d.productList.length; ++i) {
                    if (typeof d.productList[i] !== "object")
                        throw TypeError(".Proto.ContentCommerceVariation.productList: object expected");
                    m.productList[i] = $root.Proto.ContentCommerceVariationProduct.fromObject(d.productList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentCommerceVariation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentCommerceVariation
         * @static
         * @param {Proto.ContentCommerceVariation} m ContentCommerceVariation
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentCommerceVariation.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.productList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.commerceUID = "";
                d.name = "";
                d.image = null;
                d.productOrder = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.commerceUID != null && m.hasOwnProperty("commerceUID")) {
                d.commerceUID = m.commerceUID;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.productOrder != null && m.hasOwnProperty("productOrder")) {
                d.productOrder = m.productOrder;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.productList && m.productList.length) {
                d.productList = [];
                for (var j = 0; j < m.productList.length; ++j) {
                    d.productList[j] = $root.Proto.ContentCommerceVariationProduct.toObject(m.productList[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentCommerceVariation to JSON.
         * @function toJSON
         * @memberof Proto.ContentCommerceVariation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentCommerceVariation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentCommerceVariation;
    })();

    Proto.ContentCommerceFee = (function() {

        /**
         * Properties of a ContentCommerceFee.
         * @memberof Proto
         * @interface IContentCommerceFee
         * @property {string|null} [commerceUID] ContentCommerceFee commerceUID
         * @property {number|null} [code] ContentCommerceFee code
         * @property {number|null} [price] ContentCommerceFee price
         * @property {string|null} [currency] ContentCommerceFee currency
         * @property {number|Long|null} [registerDate] ContentCommerceFee registerDate
         * @property {number|Long|null} [modifyDate] ContentCommerceFee modifyDate
         * @property {number|null} [status] ContentCommerceFee status
         * @property {number|null} [quantity] ContentCommerceFee quantity
         * @property {number|null} [basicPrice] ContentCommerceFee basicPrice
         */

        /**
         * Constructs a new ContentCommerceFee.
         * @memberof Proto
         * @classdesc Represents a ContentCommerceFee.
         * @implements IContentCommerceFee
         * @constructor
         * @param {Proto.IContentCommerceFee=} [p] Properties to set
         */
        function ContentCommerceFee(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentCommerceFee commerceUID.
         * @member {string} commerceUID
         * @memberof Proto.ContentCommerceFee
         * @instance
         */
        ContentCommerceFee.prototype.commerceUID = "";

        /**
         * ContentCommerceFee code.
         * @member {number} code
         * @memberof Proto.ContentCommerceFee
         * @instance
         */
        ContentCommerceFee.prototype.code = 0;

        /**
         * ContentCommerceFee price.
         * @member {number} price
         * @memberof Proto.ContentCommerceFee
         * @instance
         */
        ContentCommerceFee.prototype.price = 0;

        /**
         * ContentCommerceFee currency.
         * @member {string} currency
         * @memberof Proto.ContentCommerceFee
         * @instance
         */
        ContentCommerceFee.prototype.currency = "";

        /**
         * ContentCommerceFee registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentCommerceFee
         * @instance
         */
        ContentCommerceFee.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerceFee modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentCommerceFee
         * @instance
         */
        ContentCommerceFee.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerceFee status.
         * @member {number} status
         * @memberof Proto.ContentCommerceFee
         * @instance
         */
        ContentCommerceFee.prototype.status = 0;

        /**
         * ContentCommerceFee quantity.
         * @member {number} quantity
         * @memberof Proto.ContentCommerceFee
         * @instance
         */
        ContentCommerceFee.prototype.quantity = 0;

        /**
         * ContentCommerceFee basicPrice.
         * @member {number} basicPrice
         * @memberof Proto.ContentCommerceFee
         * @instance
         */
        ContentCommerceFee.prototype.basicPrice = 0;

        /**
         * Creates a new ContentCommerceFee instance using the specified properties.
         * @function create
         * @memberof Proto.ContentCommerceFee
         * @static
         * @param {Proto.IContentCommerceFee=} [properties] Properties to set
         * @returns {Proto.ContentCommerceFee} ContentCommerceFee instance
         */
        ContentCommerceFee.create = function create(properties) {
            return new ContentCommerceFee(properties);
        };

        /**
         * Encodes the specified ContentCommerceFee message. Does not implicitly {@link Proto.ContentCommerceFee.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentCommerceFee
         * @static
         * @param {Proto.IContentCommerceFee} m ContentCommerceFee message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentCommerceFee.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.commerceUID != null && Object.hasOwnProperty.call(m, "commerceUID"))
                w.uint32(10).string(m.commerceUID);
            if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                w.uint32(16).int32(m.code);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(25).double(m.price);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(34).string(m.currency);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(40).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(48).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(56).int32(m.status);
            if (m.quantity != null && Object.hasOwnProperty.call(m, "quantity"))
                w.uint32(64).int32(m.quantity);
            if (m.basicPrice != null && Object.hasOwnProperty.call(m, "basicPrice"))
                w.uint32(73).double(m.basicPrice);
            return w;
        };

        /**
         * Decodes a ContentCommerceFee message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentCommerceFee
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentCommerceFee} ContentCommerceFee
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentCommerceFee.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentCommerceFee();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.commerceUID = r.string();
                    break;
                case 2:
                    m.code = r.int32();
                    break;
                case 3:
                    m.price = r.double();
                    break;
                case 4:
                    m.currency = r.string();
                    break;
                case 5:
                    m.registerDate = r.int64();
                    break;
                case 6:
                    m.modifyDate = r.int64();
                    break;
                case 7:
                    m.status = r.int32();
                    break;
                case 8:
                    m.quantity = r.int32();
                    break;
                case 9:
                    m.basicPrice = r.double();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentCommerceFee message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentCommerceFee
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentCommerceFee} ContentCommerceFee
         */
        ContentCommerceFee.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentCommerceFee)
                return d;
            var m = new $root.Proto.ContentCommerceFee();
            if (d.commerceUID != null) {
                m.commerceUID = String(d.commerceUID);
            }
            if (d.code != null) {
                m.code = d.code | 0;
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.quantity != null) {
                m.quantity = d.quantity | 0;
            }
            if (d.basicPrice != null) {
                m.basicPrice = Number(d.basicPrice);
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentCommerceFee message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentCommerceFee
         * @static
         * @param {Proto.ContentCommerceFee} m ContentCommerceFee
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentCommerceFee.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.commerceUID = "";
                d.code = 0;
                d.price = 0;
                d.currency = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.quantity = 0;
                d.basicPrice = 0;
            }
            if (m.commerceUID != null && m.hasOwnProperty("commerceUID")) {
                d.commerceUID = m.commerceUID;
            }
            if (m.code != null && m.hasOwnProperty("code")) {
                d.code = m.code;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.quantity != null && m.hasOwnProperty("quantity")) {
                d.quantity = m.quantity;
            }
            if (m.basicPrice != null && m.hasOwnProperty("basicPrice")) {
                d.basicPrice = o.json && !isFinite(m.basicPrice) ? String(m.basicPrice) : m.basicPrice;
            }
            return d;
        };

        /**
         * Converts this ContentCommerceFee to JSON.
         * @function toJSON
         * @memberof Proto.ContentCommerceFee
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentCommerceFee.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentCommerceFee;
    })();

    Proto.ContentCommerce = (function() {

        /**
         * Properties of a ContentCommerce.
         * @memberof Proto
         * @interface IContentCommerce
         * @property {string|null} [uid] ContentCommerce uid
         * @property {string|null} [title] ContentCommerce title
         * @property {string|null} [description] ContentCommerce description
         * @property {number|null} [price] ContentCommerce price
         * @property {string|null} [currency] ContentCommerce currency
         * @property {number|Long|null} [startDate] ContentCommerce startDate
         * @property {number|Long|null} [endDate] ContentCommerce endDate
         * @property {number|null} [distribution] ContentCommerce distribution
         * @property {Proto.IUploadFile|null} [image] ContentCommerce image
         * @property {Proto.IUploadFile|null} [listImage] ContentCommerce listImage
         * @property {string|null} [listImageOrder] ContentCommerce listImageOrder
         * @property {string|null} [variationOrder] ContentCommerce variationOrder
         * @property {number|Long|null} [registerDate] ContentCommerce registerDate
         * @property {number|Long|null} [modifyDate] ContentCommerce modifyDate
         * @property {number|null} [status] ContentCommerce status
         * @property {Array.<Proto.IContentCommerceVariation>|null} [variationList] ContentCommerce variationList
         * @property {Array.<Proto.IContentCommerceFee>|null} [feeList] ContentCommerce feeList
         * @property {string|null} [feeDescription] ContentCommerce feeDescription
         * @property {Array.<Proto.IContentCommerceMap>|null} [contentMapList] ContentCommerce contentMapList
         * @property {boolean|null} [main] ContentCommerce main
         * @property {Array.<Proto.IHashTag>|null} [adminTagList] ContentCommerce adminTagList
         * @property {string|null} [targetContentUID] ContentCommerce targetContentUID
         * @property {number|Long|null} [allQuantity] ContentCommerce allQuantity
         * @property {boolean|null} [useNft] ContentCommerce useNft
         * @property {string|null} [contractUID] ContentCommerce contractUID
         * @property {number|Long|null} [nftIssuingDate] ContentCommerce nftIssuingDate
         * @property {number|null} [showQuantity] ContentCommerce showQuantity
         * @property {Array.<Proto.ICommerceTVODMethod>|null} [tvodMethodList] ContentCommerce tvodMethodList
         */

        /**
         * Constructs a new ContentCommerce.
         * @memberof Proto
         * @classdesc Represents a ContentCommerce.
         * @implements IContentCommerce
         * @constructor
         * @param {Proto.IContentCommerce=} [p] Properties to set
         */
        function ContentCommerce(p) {
            this.variationList = [];
            this.feeList = [];
            this.contentMapList = [];
            this.adminTagList = [];
            this.tvodMethodList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentCommerce uid.
         * @member {string} uid
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.uid = "";

        /**
         * ContentCommerce title.
         * @member {string} title
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.title = "";

        /**
         * ContentCommerce description.
         * @member {string} description
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.description = "";

        /**
         * ContentCommerce price.
         * @member {number} price
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.price = 0;

        /**
         * ContentCommerce currency.
         * @member {string} currency
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.currency = "";

        /**
         * ContentCommerce startDate.
         * @member {number|Long} startDate
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerce endDate.
         * @member {number|Long} endDate
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerce distribution.
         * @member {number} distribution
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.distribution = 0;

        /**
         * ContentCommerce image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.image = null;

        /**
         * ContentCommerce listImage.
         * @member {Proto.IUploadFile|null|undefined} listImage
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.listImage = null;

        /**
         * ContentCommerce listImageOrder.
         * @member {string} listImageOrder
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.listImageOrder = "";

        /**
         * ContentCommerce variationOrder.
         * @member {string} variationOrder
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.variationOrder = "";

        /**
         * ContentCommerce registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerce modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerce status.
         * @member {number} status
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.status = 0;

        /**
         * ContentCommerce variationList.
         * @member {Array.<Proto.IContentCommerceVariation>} variationList
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.variationList = $util.emptyArray;

        /**
         * ContentCommerce feeList.
         * @member {Array.<Proto.IContentCommerceFee>} feeList
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.feeList = $util.emptyArray;

        /**
         * ContentCommerce feeDescription.
         * @member {string} feeDescription
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.feeDescription = "";

        /**
         * ContentCommerce contentMapList.
         * @member {Array.<Proto.IContentCommerceMap>} contentMapList
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.contentMapList = $util.emptyArray;

        /**
         * ContentCommerce main.
         * @member {boolean} main
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.main = false;

        /**
         * ContentCommerce adminTagList.
         * @member {Array.<Proto.IHashTag>} adminTagList
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.adminTagList = $util.emptyArray;

        /**
         * ContentCommerce targetContentUID.
         * @member {string} targetContentUID
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.targetContentUID = "";

        /**
         * ContentCommerce allQuantity.
         * @member {number|Long} allQuantity
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.allQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerce useNft.
         * @member {boolean} useNft
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.useNft = false;

        /**
         * ContentCommerce contractUID.
         * @member {string} contractUID
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.contractUID = "";

        /**
         * ContentCommerce nftIssuingDate.
         * @member {number|Long} nftIssuingDate
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.nftIssuingDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerce showQuantity.
         * @member {number} showQuantity
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.showQuantity = 0;

        /**
         * ContentCommerce tvodMethodList.
         * @member {Array.<Proto.ICommerceTVODMethod>} tvodMethodList
         * @memberof Proto.ContentCommerce
         * @instance
         */
        ContentCommerce.prototype.tvodMethodList = $util.emptyArray;

        /**
         * Creates a new ContentCommerce instance using the specified properties.
         * @function create
         * @memberof Proto.ContentCommerce
         * @static
         * @param {Proto.IContentCommerce=} [properties] Properties to set
         * @returns {Proto.ContentCommerce} ContentCommerce instance
         */
        ContentCommerce.create = function create(properties) {
            return new ContentCommerce(properties);
        };

        /**
         * Encodes the specified ContentCommerce message. Does not implicitly {@link Proto.ContentCommerce.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentCommerce
         * @static
         * @param {Proto.IContentCommerce} m ContentCommerce message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentCommerce.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(26).string(m.title);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(34).string(m.description);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(41).double(m.price);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(50).string(m.currency);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(56).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(64).int64(m.endDate);
            if (m.distribution != null && Object.hasOwnProperty.call(m, "distribution"))
                w.uint32(72).int32(m.distribution);
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(82).fork()).ldelim();
            if (m.listImage != null && Object.hasOwnProperty.call(m, "listImage"))
                $root.Proto.UploadFile.encode(m.listImage, w.uint32(90).fork()).ldelim();
            if (m.listImageOrder != null && Object.hasOwnProperty.call(m, "listImageOrder"))
                w.uint32(98).string(m.listImageOrder);
            if (m.variationOrder != null && Object.hasOwnProperty.call(m, "variationOrder"))
                w.uint32(106).string(m.variationOrder);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(112).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(120).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(128).int32(m.status);
            if (m.variationList != null && m.variationList.length) {
                for (var i = 0; i < m.variationList.length; ++i)
                    $root.Proto.ContentCommerceVariation.encode(m.variationList[i], w.uint32(138).fork()).ldelim();
            }
            if (m.feeList != null && m.feeList.length) {
                for (var i = 0; i < m.feeList.length; ++i)
                    $root.Proto.ContentCommerceFee.encode(m.feeList[i], w.uint32(146).fork()).ldelim();
            }
            if (m.feeDescription != null && Object.hasOwnProperty.call(m, "feeDescription"))
                w.uint32(154).string(m.feeDescription);
            if (m.contentMapList != null && m.contentMapList.length) {
                for (var i = 0; i < m.contentMapList.length; ++i)
                    $root.Proto.ContentCommerceMap.encode(m.contentMapList[i], w.uint32(162).fork()).ldelim();
            }
            if (m.main != null && Object.hasOwnProperty.call(m, "main"))
                w.uint32(168).bool(m.main);
            if (m.adminTagList != null && m.adminTagList.length) {
                for (var i = 0; i < m.adminTagList.length; ++i)
                    $root.Proto.HashTag.encode(m.adminTagList[i], w.uint32(178).fork()).ldelim();
            }
            if (m.targetContentUID != null && Object.hasOwnProperty.call(m, "targetContentUID"))
                w.uint32(186).string(m.targetContentUID);
            if (m.allQuantity != null && Object.hasOwnProperty.call(m, "allQuantity"))
                w.uint32(192).int64(m.allQuantity);
            if (m.useNft != null && Object.hasOwnProperty.call(m, "useNft"))
                w.uint32(200).bool(m.useNft);
            if (m.contractUID != null && Object.hasOwnProperty.call(m, "contractUID"))
                w.uint32(210).string(m.contractUID);
            if (m.nftIssuingDate != null && Object.hasOwnProperty.call(m, "nftIssuingDate"))
                w.uint32(216).int64(m.nftIssuingDate);
            if (m.showQuantity != null && Object.hasOwnProperty.call(m, "showQuantity"))
                w.uint32(224).int32(m.showQuantity);
            if (m.tvodMethodList != null && m.tvodMethodList.length) {
                for (var i = 0; i < m.tvodMethodList.length; ++i)
                    $root.Proto.CommerceTVODMethod.encode(m.tvodMethodList[i], w.uint32(234).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentCommerce message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentCommerce
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentCommerce} ContentCommerce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentCommerce.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentCommerce();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 3:
                    m.title = r.string();
                    break;
                case 4:
                    m.description = r.string();
                    break;
                case 5:
                    m.price = r.double();
                    break;
                case 6:
                    m.currency = r.string();
                    break;
                case 7:
                    m.startDate = r.int64();
                    break;
                case 8:
                    m.endDate = r.int64();
                    break;
                case 9:
                    m.distribution = r.int32();
                    break;
                case 10:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 11:
                    m.listImage = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 12:
                    m.listImageOrder = r.string();
                    break;
                case 13:
                    m.variationOrder = r.string();
                    break;
                case 14:
                    m.registerDate = r.int64();
                    break;
                case 15:
                    m.modifyDate = r.int64();
                    break;
                case 16:
                    m.status = r.int32();
                    break;
                case 17:
                    if (!(m.variationList && m.variationList.length))
                        m.variationList = [];
                    m.variationList.push($root.Proto.ContentCommerceVariation.decode(r, r.uint32()));
                    break;
                case 18:
                    if (!(m.feeList && m.feeList.length))
                        m.feeList = [];
                    m.feeList.push($root.Proto.ContentCommerceFee.decode(r, r.uint32()));
                    break;
                case 19:
                    m.feeDescription = r.string();
                    break;
                case 20:
                    if (!(m.contentMapList && m.contentMapList.length))
                        m.contentMapList = [];
                    m.contentMapList.push($root.Proto.ContentCommerceMap.decode(r, r.uint32()));
                    break;
                case 21:
                    m.main = r.bool();
                    break;
                case 22:
                    if (!(m.adminTagList && m.adminTagList.length))
                        m.adminTagList = [];
                    m.adminTagList.push($root.Proto.HashTag.decode(r, r.uint32()));
                    break;
                case 23:
                    m.targetContentUID = r.string();
                    break;
                case 24:
                    m.allQuantity = r.int64();
                    break;
                case 25:
                    m.useNft = r.bool();
                    break;
                case 26:
                    m.contractUID = r.string();
                    break;
                case 27:
                    m.nftIssuingDate = r.int64();
                    break;
                case 28:
                    m.showQuantity = r.int32();
                    break;
                case 29:
                    if (!(m.tvodMethodList && m.tvodMethodList.length))
                        m.tvodMethodList = [];
                    m.tvodMethodList.push($root.Proto.CommerceTVODMethod.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentCommerce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentCommerce
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentCommerce} ContentCommerce
         */
        ContentCommerce.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentCommerce)
                return d;
            var m = new $root.Proto.ContentCommerce();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.distribution != null) {
                m.distribution = d.distribution | 0;
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.ContentCommerce.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.listImage != null) {
                if (typeof d.listImage !== "object")
                    throw TypeError(".Proto.ContentCommerce.listImage: object expected");
                m.listImage = $root.Proto.UploadFile.fromObject(d.listImage);
            }
            if (d.listImageOrder != null) {
                m.listImageOrder = String(d.listImageOrder);
            }
            if (d.variationOrder != null) {
                m.variationOrder = String(d.variationOrder);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.variationList) {
                if (!Array.isArray(d.variationList))
                    throw TypeError(".Proto.ContentCommerce.variationList: array expected");
                m.variationList = [];
                for (var i = 0; i < d.variationList.length; ++i) {
                    if (typeof d.variationList[i] !== "object")
                        throw TypeError(".Proto.ContentCommerce.variationList: object expected");
                    m.variationList[i] = $root.Proto.ContentCommerceVariation.fromObject(d.variationList[i]);
                }
            }
            if (d.feeList) {
                if (!Array.isArray(d.feeList))
                    throw TypeError(".Proto.ContentCommerce.feeList: array expected");
                m.feeList = [];
                for (var i = 0; i < d.feeList.length; ++i) {
                    if (typeof d.feeList[i] !== "object")
                        throw TypeError(".Proto.ContentCommerce.feeList: object expected");
                    m.feeList[i] = $root.Proto.ContentCommerceFee.fromObject(d.feeList[i]);
                }
            }
            if (d.feeDescription != null) {
                m.feeDescription = String(d.feeDescription);
            }
            if (d.contentMapList) {
                if (!Array.isArray(d.contentMapList))
                    throw TypeError(".Proto.ContentCommerce.contentMapList: array expected");
                m.contentMapList = [];
                for (var i = 0; i < d.contentMapList.length; ++i) {
                    if (typeof d.contentMapList[i] !== "object")
                        throw TypeError(".Proto.ContentCommerce.contentMapList: object expected");
                    m.contentMapList[i] = $root.Proto.ContentCommerceMap.fromObject(d.contentMapList[i]);
                }
            }
            if (d.main != null) {
                m.main = Boolean(d.main);
            }
            if (d.adminTagList) {
                if (!Array.isArray(d.adminTagList))
                    throw TypeError(".Proto.ContentCommerce.adminTagList: array expected");
                m.adminTagList = [];
                for (var i = 0; i < d.adminTagList.length; ++i) {
                    if (typeof d.adminTagList[i] !== "object")
                        throw TypeError(".Proto.ContentCommerce.adminTagList: object expected");
                    m.adminTagList[i] = $root.Proto.HashTag.fromObject(d.adminTagList[i]);
                }
            }
            if (d.targetContentUID != null) {
                m.targetContentUID = String(d.targetContentUID);
            }
            if (d.allQuantity != null) {
                if ($util.Long)
                    (m.allQuantity = $util.Long.fromValue(d.allQuantity)).unsigned = false;
                else if (typeof d.allQuantity === "string")
                    m.allQuantity = parseInt(d.allQuantity, 10);
                else if (typeof d.allQuantity === "number")
                    m.allQuantity = d.allQuantity;
                else if (typeof d.allQuantity === "object")
                    m.allQuantity = new $util.LongBits(d.allQuantity.low >>> 0, d.allQuantity.high >>> 0).toNumber();
            }
            if (d.useNft != null) {
                m.useNft = Boolean(d.useNft);
            }
            if (d.contractUID != null) {
                m.contractUID = String(d.contractUID);
            }
            if (d.nftIssuingDate != null) {
                if ($util.Long)
                    (m.nftIssuingDate = $util.Long.fromValue(d.nftIssuingDate)).unsigned = false;
                else if (typeof d.nftIssuingDate === "string")
                    m.nftIssuingDate = parseInt(d.nftIssuingDate, 10);
                else if (typeof d.nftIssuingDate === "number")
                    m.nftIssuingDate = d.nftIssuingDate;
                else if (typeof d.nftIssuingDate === "object")
                    m.nftIssuingDate = new $util.LongBits(d.nftIssuingDate.low >>> 0, d.nftIssuingDate.high >>> 0).toNumber();
            }
            if (d.showQuantity != null) {
                m.showQuantity = d.showQuantity | 0;
            }
            if (d.tvodMethodList) {
                if (!Array.isArray(d.tvodMethodList))
                    throw TypeError(".Proto.ContentCommerce.tvodMethodList: array expected");
                m.tvodMethodList = [];
                for (var i = 0; i < d.tvodMethodList.length; ++i) {
                    if (typeof d.tvodMethodList[i] !== "object")
                        throw TypeError(".Proto.ContentCommerce.tvodMethodList: object expected");
                    m.tvodMethodList[i] = $root.Proto.CommerceTVODMethod.fromObject(d.tvodMethodList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentCommerce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentCommerce
         * @static
         * @param {Proto.ContentCommerce} m ContentCommerce
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentCommerce.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.variationList = [];
                d.feeList = [];
                d.contentMapList = [];
                d.adminTagList = [];
                d.tvodMethodList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.title = "";
                d.description = "";
                d.price = 0;
                d.currency = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                d.distribution = 0;
                d.image = null;
                d.listImage = null;
                d.listImageOrder = "";
                d.variationOrder = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.feeDescription = "";
                d.main = false;
                d.targetContentUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.allQuantity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.allQuantity = o.longs === String ? "0" : 0;
                d.useNft = false;
                d.contractUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.nftIssuingDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.nftIssuingDate = o.longs === String ? "0" : 0;
                d.showQuantity = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.distribution != null && m.hasOwnProperty("distribution")) {
                d.distribution = m.distribution;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.listImage != null && m.hasOwnProperty("listImage")) {
                d.listImage = $root.Proto.UploadFile.toObject(m.listImage, o);
            }
            if (m.listImageOrder != null && m.hasOwnProperty("listImageOrder")) {
                d.listImageOrder = m.listImageOrder;
            }
            if (m.variationOrder != null && m.hasOwnProperty("variationOrder")) {
                d.variationOrder = m.variationOrder;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.variationList && m.variationList.length) {
                d.variationList = [];
                for (var j = 0; j < m.variationList.length; ++j) {
                    d.variationList[j] = $root.Proto.ContentCommerceVariation.toObject(m.variationList[j], o);
                }
            }
            if (m.feeList && m.feeList.length) {
                d.feeList = [];
                for (var j = 0; j < m.feeList.length; ++j) {
                    d.feeList[j] = $root.Proto.ContentCommerceFee.toObject(m.feeList[j], o);
                }
            }
            if (m.feeDescription != null && m.hasOwnProperty("feeDescription")) {
                d.feeDescription = m.feeDescription;
            }
            if (m.contentMapList && m.contentMapList.length) {
                d.contentMapList = [];
                for (var j = 0; j < m.contentMapList.length; ++j) {
                    d.contentMapList[j] = $root.Proto.ContentCommerceMap.toObject(m.contentMapList[j], o);
                }
            }
            if (m.main != null && m.hasOwnProperty("main")) {
                d.main = m.main;
            }
            if (m.adminTagList && m.adminTagList.length) {
                d.adminTagList = [];
                for (var j = 0; j < m.adminTagList.length; ++j) {
                    d.adminTagList[j] = $root.Proto.HashTag.toObject(m.adminTagList[j], o);
                }
            }
            if (m.targetContentUID != null && m.hasOwnProperty("targetContentUID")) {
                d.targetContentUID = m.targetContentUID;
            }
            if (m.allQuantity != null && m.hasOwnProperty("allQuantity")) {
                if (typeof m.allQuantity === "number")
                    d.allQuantity = o.longs === String ? String(m.allQuantity) : m.allQuantity;
                else
                    d.allQuantity = o.longs === String ? $util.Long.prototype.toString.call(m.allQuantity) : o.longs === Number ? new $util.LongBits(m.allQuantity.low >>> 0, m.allQuantity.high >>> 0).toNumber() : m.allQuantity;
            }
            if (m.useNft != null && m.hasOwnProperty("useNft")) {
                d.useNft = m.useNft;
            }
            if (m.contractUID != null && m.hasOwnProperty("contractUID")) {
                d.contractUID = m.contractUID;
            }
            if (m.nftIssuingDate != null && m.hasOwnProperty("nftIssuingDate")) {
                if (typeof m.nftIssuingDate === "number")
                    d.nftIssuingDate = o.longs === String ? String(m.nftIssuingDate) : m.nftIssuingDate;
                else
                    d.nftIssuingDate = o.longs === String ? $util.Long.prototype.toString.call(m.nftIssuingDate) : o.longs === Number ? new $util.LongBits(m.nftIssuingDate.low >>> 0, m.nftIssuingDate.high >>> 0).toNumber() : m.nftIssuingDate;
            }
            if (m.showQuantity != null && m.hasOwnProperty("showQuantity")) {
                d.showQuantity = m.showQuantity;
            }
            if (m.tvodMethodList && m.tvodMethodList.length) {
                d.tvodMethodList = [];
                for (var j = 0; j < m.tvodMethodList.length; ++j) {
                    d.tvodMethodList[j] = $root.Proto.CommerceTVODMethod.toObject(m.tvodMethodList[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentCommerce to JSON.
         * @function toJSON
         * @memberof Proto.ContentCommerce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentCommerce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentCommerce;
    })();

    Proto.ContentCommerceList = (function() {

        /**
         * Properties of a ContentCommerceList.
         * @memberof Proto
         * @interface IContentCommerceList
         * @property {Array.<Proto.IContentCommerce>|null} [value] ContentCommerceList value
         */

        /**
         * Constructs a new ContentCommerceList.
         * @memberof Proto
         * @classdesc Represents a ContentCommerceList.
         * @implements IContentCommerceList
         * @constructor
         * @param {Proto.IContentCommerceList=} [p] Properties to set
         */
        function ContentCommerceList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentCommerceList value.
         * @member {Array.<Proto.IContentCommerce>} value
         * @memberof Proto.ContentCommerceList
         * @instance
         */
        ContentCommerceList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentCommerceList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentCommerceList
         * @static
         * @param {Proto.IContentCommerceList=} [properties] Properties to set
         * @returns {Proto.ContentCommerceList} ContentCommerceList instance
         */
        ContentCommerceList.create = function create(properties) {
            return new ContentCommerceList(properties);
        };

        /**
         * Encodes the specified ContentCommerceList message. Does not implicitly {@link Proto.ContentCommerceList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentCommerceList
         * @static
         * @param {Proto.IContentCommerceList} m ContentCommerceList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentCommerceList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentCommerce.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentCommerceList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentCommerceList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentCommerceList} ContentCommerceList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentCommerceList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentCommerceList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentCommerce.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentCommerceList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentCommerceList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentCommerceList} ContentCommerceList
         */
        ContentCommerceList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentCommerceList)
                return d;
            var m = new $root.Proto.ContentCommerceList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentCommerceList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentCommerceList.value: object expected");
                    m.value[i] = $root.Proto.ContentCommerce.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentCommerceList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentCommerceList
         * @static
         * @param {Proto.ContentCommerceList} m ContentCommerceList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentCommerceList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentCommerce.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentCommerceList to JSON.
         * @function toJSON
         * @memberof Proto.ContentCommerceList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentCommerceList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentCommerceList;
    })();

    Proto.CommunityBoard = (function() {

        /**
         * Properties of a CommunityBoard.
         * @memberof Proto
         * @interface ICommunityBoard
         * @property {string|null} [uid] CommunityBoard uid
         * @property {number|null} [topicPolicy] CommunityBoard topicPolicy
         * @property {number|null} [commentPolicy] CommunityBoard commentPolicy
         * @property {number|null} [commentCreatePolicy] CommunityBoard commentCreatePolicy
         * @property {number|null} [commentVisible] CommunityBoard commentVisible
         * @property {number|Long|null} [registerDate] CommunityBoard registerDate
         * @property {number|Long|null} [modifyDate] CommunityBoard modifyDate
         * @property {number|null} [status] CommunityBoard status
         * @property {string|null} [companyUID] CommunityBoard companyUID
         * @property {string|null} [unreadCount] CommunityBoard unreadCount
         * @property {number|null} [appTheme] CommunityBoard appTheme
         * @property {number|null} [groupCreatePolicy] CommunityBoard groupCreatePolicy
         * @property {number|null} [groupPolicy] CommunityBoard groupPolicy
         * @property {number|null} [topicScope] CommunityBoard topicScope
         * @property {Array.<Proto.ICommunityBoardPolicy>|null} [policyList] CommunityBoard policyList
         * @property {boolean|null} [commentCreatable] CommunityBoard commentCreatable
         * @property {number|null} [topicMapPolicy] CommunityBoard topicMapPolicy
         * @property {number|null} [commentMapPolicy] CommunityBoard commentMapPolicy
         * @property {number|null} [placesAPIType] CommunityBoard placesAPIType
         * @property {string|null} [placesAPIKey] CommunityBoard placesAPIKey
         * @property {number|null} [topicVideoPolicy] CommunityBoard topicVideoPolicy
         * @property {number|null} [commentVideoPolicy] CommunityBoard commentVideoPolicy
         * @property {number|null} [webTalkType] CommunityBoard webTalkType
         * @property {number|null} [webTalkPolicy] CommunityBoard webTalkPolicy
         * @property {number|null} [groupRealtimePolicy] CommunityBoard groupRealtimePolicy
         * @property {number|null} [groupUploadPolicy] CommunityBoard groupUploadPolicy
         * @property {number|null} [groupUploadEnv] CommunityBoard groupUploadEnv
         * @property {number|null} [groupPlayEnv] CommunityBoard groupPlayEnv
         * @property {boolean|null} [groupCreatable] CommunityBoard groupCreatable
         * @property {boolean|null} [groupUploadable] CommunityBoard groupUploadable
         * @property {number|null} [groupUserListPolicy] CommunityBoard groupUserListPolicy
         * @property {number|null} [profilePlanlistPolicy] CommunityBoard profilePlanlistPolicy
         * @property {boolean|null} [forumAdmin] CommunityBoard forumAdmin
         * @property {boolean|null} [topicCreatable] CommunityBoard topicCreatable
         * @property {number|null} [profileForumPolicy] CommunityBoard profileForumPolicy
         * @property {number|null} [profileChatPolicy] CommunityBoard profileChatPolicy
         * @property {boolean|null} [useStamp] CommunityBoard useStamp
         * @property {string|null} [contentSetting] CommunityBoard contentSetting
         * @property {string|null} [forumListTitle] CommunityBoard forumListTitle
         * @property {string|null} [forumTitle] CommunityBoard forumTitle
         * @property {string|null} [topicTitle] CommunityBoard topicTitle
         * @property {string|null} [commentTitle] CommunityBoard commentTitle
         * @property {number|null} [reactionUserGroup] CommunityBoard reactionUserGroup
         * @property {number|null} [listReadCount] CommunityBoard listReadCount
         * @property {boolean|null} [allowMention] CommunityBoard allowMention
         */

        /**
         * Constructs a new CommunityBoard.
         * @memberof Proto
         * @classdesc Represents a CommunityBoard.
         * @implements ICommunityBoard
         * @constructor
         * @param {Proto.ICommunityBoard=} [p] Properties to set
         */
        function CommunityBoard(p) {
            this.policyList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoard uid.
         * @member {string} uid
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.uid = "";

        /**
         * CommunityBoard topicPolicy.
         * @member {number} topicPolicy
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.topicPolicy = 0;

        /**
         * CommunityBoard commentPolicy.
         * @member {number} commentPolicy
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.commentPolicy = 0;

        /**
         * CommunityBoard commentCreatePolicy.
         * @member {number} commentCreatePolicy
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.commentCreatePolicy = 0;

        /**
         * CommunityBoard commentVisible.
         * @member {number} commentVisible
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.commentVisible = 0;

        /**
         * CommunityBoard registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoard modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoard status.
         * @member {number} status
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.status = 0;

        /**
         * CommunityBoard companyUID.
         * @member {string} companyUID
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.companyUID = "";

        /**
         * CommunityBoard unreadCount.
         * @member {string} unreadCount
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.unreadCount = "";

        /**
         * CommunityBoard appTheme.
         * @member {number} appTheme
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.appTheme = 0;

        /**
         * CommunityBoard groupCreatePolicy.
         * @member {number} groupCreatePolicy
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.groupCreatePolicy = 0;

        /**
         * CommunityBoard groupPolicy.
         * @member {number} groupPolicy
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.groupPolicy = 0;

        /**
         * CommunityBoard topicScope.
         * @member {number} topicScope
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.topicScope = 0;

        /**
         * CommunityBoard policyList.
         * @member {Array.<Proto.ICommunityBoardPolicy>} policyList
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.policyList = $util.emptyArray;

        /**
         * CommunityBoard commentCreatable.
         * @member {boolean} commentCreatable
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.commentCreatable = false;

        /**
         * CommunityBoard topicMapPolicy.
         * @member {number} topicMapPolicy
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.topicMapPolicy = 0;

        /**
         * CommunityBoard commentMapPolicy.
         * @member {number} commentMapPolicy
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.commentMapPolicy = 0;

        /**
         * CommunityBoard placesAPIType.
         * @member {number} placesAPIType
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.placesAPIType = 0;

        /**
         * CommunityBoard placesAPIKey.
         * @member {string} placesAPIKey
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.placesAPIKey = "";

        /**
         * CommunityBoard topicVideoPolicy.
         * @member {number} topicVideoPolicy
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.topicVideoPolicy = 0;

        /**
         * CommunityBoard commentVideoPolicy.
         * @member {number} commentVideoPolicy
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.commentVideoPolicy = 0;

        /**
         * CommunityBoard webTalkType.
         * @member {number} webTalkType
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.webTalkType = 0;

        /**
         * CommunityBoard webTalkPolicy.
         * @member {number} webTalkPolicy
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.webTalkPolicy = 0;

        /**
         * CommunityBoard groupRealtimePolicy.
         * @member {number} groupRealtimePolicy
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.groupRealtimePolicy = 0;

        /**
         * CommunityBoard groupUploadPolicy.
         * @member {number} groupUploadPolicy
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.groupUploadPolicy = 0;

        /**
         * CommunityBoard groupUploadEnv.
         * @member {number} groupUploadEnv
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.groupUploadEnv = 0;

        /**
         * CommunityBoard groupPlayEnv.
         * @member {number} groupPlayEnv
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.groupPlayEnv = 0;

        /**
         * CommunityBoard groupCreatable.
         * @member {boolean} groupCreatable
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.groupCreatable = false;

        /**
         * CommunityBoard groupUploadable.
         * @member {boolean} groupUploadable
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.groupUploadable = false;

        /**
         * CommunityBoard groupUserListPolicy.
         * @member {number} groupUserListPolicy
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.groupUserListPolicy = 0;

        /**
         * CommunityBoard profilePlanlistPolicy.
         * @member {number} profilePlanlistPolicy
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.profilePlanlistPolicy = 0;

        /**
         * CommunityBoard forumAdmin.
         * @member {boolean} forumAdmin
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.forumAdmin = false;

        /**
         * CommunityBoard topicCreatable.
         * @member {boolean} topicCreatable
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.topicCreatable = false;

        /**
         * CommunityBoard profileForumPolicy.
         * @member {number} profileForumPolicy
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.profileForumPolicy = 0;

        /**
         * CommunityBoard profileChatPolicy.
         * @member {number} profileChatPolicy
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.profileChatPolicy = 0;

        /**
         * CommunityBoard useStamp.
         * @member {boolean} useStamp
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.useStamp = false;

        /**
         * CommunityBoard contentSetting.
         * @member {string} contentSetting
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.contentSetting = "";

        /**
         * CommunityBoard forumListTitle.
         * @member {string} forumListTitle
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.forumListTitle = "";

        /**
         * CommunityBoard forumTitle.
         * @member {string} forumTitle
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.forumTitle = "";

        /**
         * CommunityBoard topicTitle.
         * @member {string} topicTitle
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.topicTitle = "";

        /**
         * CommunityBoard commentTitle.
         * @member {string} commentTitle
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.commentTitle = "";

        /**
         * CommunityBoard reactionUserGroup.
         * @member {number} reactionUserGroup
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.reactionUserGroup = 0;

        /**
         * CommunityBoard listReadCount.
         * @member {number} listReadCount
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.listReadCount = 0;

        /**
         * CommunityBoard allowMention.
         * @member {boolean} allowMention
         * @memberof Proto.CommunityBoard
         * @instance
         */
        CommunityBoard.prototype.allowMention = false;

        /**
         * Creates a new CommunityBoard instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoard
         * @static
         * @param {Proto.ICommunityBoard=} [properties] Properties to set
         * @returns {Proto.CommunityBoard} CommunityBoard instance
         */
        CommunityBoard.create = function create(properties) {
            return new CommunityBoard(properties);
        };

        /**
         * Encodes the specified CommunityBoard message. Does not implicitly {@link Proto.CommunityBoard.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoard
         * @static
         * @param {Proto.ICommunityBoard} m CommunityBoard message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoard.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.topicPolicy != null && Object.hasOwnProperty.call(m, "topicPolicy"))
                w.uint32(16).int32(m.topicPolicy);
            if (m.commentPolicy != null && Object.hasOwnProperty.call(m, "commentPolicy"))
                w.uint32(24).int32(m.commentPolicy);
            if (m.commentCreatePolicy != null && Object.hasOwnProperty.call(m, "commentCreatePolicy"))
                w.uint32(32).int32(m.commentCreatePolicy);
            if (m.commentVisible != null && Object.hasOwnProperty.call(m, "commentVisible"))
                w.uint32(40).int32(m.commentVisible);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(64).int32(m.status);
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(74).string(m.companyUID);
            if (m.unreadCount != null && Object.hasOwnProperty.call(m, "unreadCount"))
                w.uint32(82).string(m.unreadCount);
            if (m.appTheme != null && Object.hasOwnProperty.call(m, "appTheme"))
                w.uint32(88).int32(m.appTheme);
            if (m.groupCreatePolicy != null && Object.hasOwnProperty.call(m, "groupCreatePolicy"))
                w.uint32(96).int32(m.groupCreatePolicy);
            if (m.groupPolicy != null && Object.hasOwnProperty.call(m, "groupPolicy"))
                w.uint32(104).int32(m.groupPolicy);
            if (m.topicScope != null && Object.hasOwnProperty.call(m, "topicScope"))
                w.uint32(112).int32(m.topicScope);
            if (m.policyList != null && m.policyList.length) {
                for (var i = 0; i < m.policyList.length; ++i)
                    $root.Proto.CommunityBoardPolicy.encode(m.policyList[i], w.uint32(122).fork()).ldelim();
            }
            if (m.commentCreatable != null && Object.hasOwnProperty.call(m, "commentCreatable"))
                w.uint32(128).bool(m.commentCreatable);
            if (m.topicMapPolicy != null && Object.hasOwnProperty.call(m, "topicMapPolicy"))
                w.uint32(136).int32(m.topicMapPolicy);
            if (m.commentMapPolicy != null && Object.hasOwnProperty.call(m, "commentMapPolicy"))
                w.uint32(144).int32(m.commentMapPolicy);
            if (m.placesAPIType != null && Object.hasOwnProperty.call(m, "placesAPIType"))
                w.uint32(152).int32(m.placesAPIType);
            if (m.placesAPIKey != null && Object.hasOwnProperty.call(m, "placesAPIKey"))
                w.uint32(162).string(m.placesAPIKey);
            if (m.topicVideoPolicy != null && Object.hasOwnProperty.call(m, "topicVideoPolicy"))
                w.uint32(168).int32(m.topicVideoPolicy);
            if (m.commentVideoPolicy != null && Object.hasOwnProperty.call(m, "commentVideoPolicy"))
                w.uint32(176).int32(m.commentVideoPolicy);
            if (m.webTalkType != null && Object.hasOwnProperty.call(m, "webTalkType"))
                w.uint32(184).int32(m.webTalkType);
            if (m.webTalkPolicy != null && Object.hasOwnProperty.call(m, "webTalkPolicy"))
                w.uint32(192).int32(m.webTalkPolicy);
            if (m.groupRealtimePolicy != null && Object.hasOwnProperty.call(m, "groupRealtimePolicy"))
                w.uint32(200).int32(m.groupRealtimePolicy);
            if (m.groupUploadPolicy != null && Object.hasOwnProperty.call(m, "groupUploadPolicy"))
                w.uint32(208).int32(m.groupUploadPolicy);
            if (m.groupUploadEnv != null && Object.hasOwnProperty.call(m, "groupUploadEnv"))
                w.uint32(216).int32(m.groupUploadEnv);
            if (m.groupPlayEnv != null && Object.hasOwnProperty.call(m, "groupPlayEnv"))
                w.uint32(224).int32(m.groupPlayEnv);
            if (m.groupCreatable != null && Object.hasOwnProperty.call(m, "groupCreatable"))
                w.uint32(232).bool(m.groupCreatable);
            if (m.groupUploadable != null && Object.hasOwnProperty.call(m, "groupUploadable"))
                w.uint32(240).bool(m.groupUploadable);
            if (m.groupUserListPolicy != null && Object.hasOwnProperty.call(m, "groupUserListPolicy"))
                w.uint32(248).int32(m.groupUserListPolicy);
            if (m.profilePlanlistPolicy != null && Object.hasOwnProperty.call(m, "profilePlanlistPolicy"))
                w.uint32(256).int32(m.profilePlanlistPolicy);
            if (m.forumAdmin != null && Object.hasOwnProperty.call(m, "forumAdmin"))
                w.uint32(264).bool(m.forumAdmin);
            if (m.topicCreatable != null && Object.hasOwnProperty.call(m, "topicCreatable"))
                w.uint32(272).bool(m.topicCreatable);
            if (m.profileForumPolicy != null && Object.hasOwnProperty.call(m, "profileForumPolicy"))
                w.uint32(280).int32(m.profileForumPolicy);
            if (m.profileChatPolicy != null && Object.hasOwnProperty.call(m, "profileChatPolicy"))
                w.uint32(288).int32(m.profileChatPolicy);
            if (m.useStamp != null && Object.hasOwnProperty.call(m, "useStamp"))
                w.uint32(296).bool(m.useStamp);
            if (m.contentSetting != null && Object.hasOwnProperty.call(m, "contentSetting"))
                w.uint32(306).string(m.contentSetting);
            if (m.forumListTitle != null && Object.hasOwnProperty.call(m, "forumListTitle"))
                w.uint32(314).string(m.forumListTitle);
            if (m.forumTitle != null && Object.hasOwnProperty.call(m, "forumTitle"))
                w.uint32(322).string(m.forumTitle);
            if (m.topicTitle != null && Object.hasOwnProperty.call(m, "topicTitle"))
                w.uint32(330).string(m.topicTitle);
            if (m.commentTitle != null && Object.hasOwnProperty.call(m, "commentTitle"))
                w.uint32(338).string(m.commentTitle);
            if (m.reactionUserGroup != null && Object.hasOwnProperty.call(m, "reactionUserGroup"))
                w.uint32(344).int32(m.reactionUserGroup);
            if (m.listReadCount != null && Object.hasOwnProperty.call(m, "listReadCount"))
                w.uint32(352).int32(m.listReadCount);
            if (m.allowMention != null && Object.hasOwnProperty.call(m, "allowMention"))
                w.uint32(360).bool(m.allowMention);
            return w;
        };

        /**
         * Decodes a CommunityBoard message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoard
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoard} CommunityBoard
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoard.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoard();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.topicPolicy = r.int32();
                    break;
                case 3:
                    m.commentPolicy = r.int32();
                    break;
                case 4:
                    m.commentCreatePolicy = r.int32();
                    break;
                case 5:
                    m.commentVisible = r.int32();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.status = r.int32();
                    break;
                case 9:
                    m.companyUID = r.string();
                    break;
                case 10:
                    m.unreadCount = r.string();
                    break;
                case 11:
                    m.appTheme = r.int32();
                    break;
                case 12:
                    m.groupCreatePolicy = r.int32();
                    break;
                case 13:
                    m.groupPolicy = r.int32();
                    break;
                case 14:
                    m.topicScope = r.int32();
                    break;
                case 15:
                    if (!(m.policyList && m.policyList.length))
                        m.policyList = [];
                    m.policyList.push($root.Proto.CommunityBoardPolicy.decode(r, r.uint32()));
                    break;
                case 16:
                    m.commentCreatable = r.bool();
                    break;
                case 17:
                    m.topicMapPolicy = r.int32();
                    break;
                case 18:
                    m.commentMapPolicy = r.int32();
                    break;
                case 19:
                    m.placesAPIType = r.int32();
                    break;
                case 20:
                    m.placesAPIKey = r.string();
                    break;
                case 21:
                    m.topicVideoPolicy = r.int32();
                    break;
                case 22:
                    m.commentVideoPolicy = r.int32();
                    break;
                case 23:
                    m.webTalkType = r.int32();
                    break;
                case 24:
                    m.webTalkPolicy = r.int32();
                    break;
                case 25:
                    m.groupRealtimePolicy = r.int32();
                    break;
                case 26:
                    m.groupUploadPolicy = r.int32();
                    break;
                case 27:
                    m.groupUploadEnv = r.int32();
                    break;
                case 28:
                    m.groupPlayEnv = r.int32();
                    break;
                case 29:
                    m.groupCreatable = r.bool();
                    break;
                case 30:
                    m.groupUploadable = r.bool();
                    break;
                case 31:
                    m.groupUserListPolicy = r.int32();
                    break;
                case 32:
                    m.profilePlanlistPolicy = r.int32();
                    break;
                case 33:
                    m.forumAdmin = r.bool();
                    break;
                case 34:
                    m.topicCreatable = r.bool();
                    break;
                case 35:
                    m.profileForumPolicy = r.int32();
                    break;
                case 36:
                    m.profileChatPolicy = r.int32();
                    break;
                case 37:
                    m.useStamp = r.bool();
                    break;
                case 38:
                    m.contentSetting = r.string();
                    break;
                case 39:
                    m.forumListTitle = r.string();
                    break;
                case 40:
                    m.forumTitle = r.string();
                    break;
                case 41:
                    m.topicTitle = r.string();
                    break;
                case 42:
                    m.commentTitle = r.string();
                    break;
                case 43:
                    m.reactionUserGroup = r.int32();
                    break;
                case 44:
                    m.listReadCount = r.int32();
                    break;
                case 45:
                    m.allowMention = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoard message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoard
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoard} CommunityBoard
         */
        CommunityBoard.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoard)
                return d;
            var m = new $root.Proto.CommunityBoard();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.topicPolicy != null) {
                m.topicPolicy = d.topicPolicy | 0;
            }
            if (d.commentPolicy != null) {
                m.commentPolicy = d.commentPolicy | 0;
            }
            if (d.commentCreatePolicy != null) {
                m.commentCreatePolicy = d.commentCreatePolicy | 0;
            }
            if (d.commentVisible != null) {
                m.commentVisible = d.commentVisible | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.unreadCount != null) {
                m.unreadCount = String(d.unreadCount);
            }
            if (d.appTheme != null) {
                m.appTheme = d.appTheme | 0;
            }
            if (d.groupCreatePolicy != null) {
                m.groupCreatePolicy = d.groupCreatePolicy | 0;
            }
            if (d.groupPolicy != null) {
                m.groupPolicy = d.groupPolicy | 0;
            }
            if (d.topicScope != null) {
                m.topicScope = d.topicScope | 0;
            }
            if (d.policyList) {
                if (!Array.isArray(d.policyList))
                    throw TypeError(".Proto.CommunityBoard.policyList: array expected");
                m.policyList = [];
                for (var i = 0; i < d.policyList.length; ++i) {
                    if (typeof d.policyList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoard.policyList: object expected");
                    m.policyList[i] = $root.Proto.CommunityBoardPolicy.fromObject(d.policyList[i]);
                }
            }
            if (d.commentCreatable != null) {
                m.commentCreatable = Boolean(d.commentCreatable);
            }
            if (d.topicMapPolicy != null) {
                m.topicMapPolicy = d.topicMapPolicy | 0;
            }
            if (d.commentMapPolicy != null) {
                m.commentMapPolicy = d.commentMapPolicy | 0;
            }
            if (d.placesAPIType != null) {
                m.placesAPIType = d.placesAPIType | 0;
            }
            if (d.placesAPIKey != null) {
                m.placesAPIKey = String(d.placesAPIKey);
            }
            if (d.topicVideoPolicy != null) {
                m.topicVideoPolicy = d.topicVideoPolicy | 0;
            }
            if (d.commentVideoPolicy != null) {
                m.commentVideoPolicy = d.commentVideoPolicy | 0;
            }
            if (d.webTalkType != null) {
                m.webTalkType = d.webTalkType | 0;
            }
            if (d.webTalkPolicy != null) {
                m.webTalkPolicy = d.webTalkPolicy | 0;
            }
            if (d.groupRealtimePolicy != null) {
                m.groupRealtimePolicy = d.groupRealtimePolicy | 0;
            }
            if (d.groupUploadPolicy != null) {
                m.groupUploadPolicy = d.groupUploadPolicy | 0;
            }
            if (d.groupUploadEnv != null) {
                m.groupUploadEnv = d.groupUploadEnv | 0;
            }
            if (d.groupPlayEnv != null) {
                m.groupPlayEnv = d.groupPlayEnv | 0;
            }
            if (d.groupCreatable != null) {
                m.groupCreatable = Boolean(d.groupCreatable);
            }
            if (d.groupUploadable != null) {
                m.groupUploadable = Boolean(d.groupUploadable);
            }
            if (d.groupUserListPolicy != null) {
                m.groupUserListPolicy = d.groupUserListPolicy | 0;
            }
            if (d.profilePlanlistPolicy != null) {
                m.profilePlanlistPolicy = d.profilePlanlistPolicy | 0;
            }
            if (d.forumAdmin != null) {
                m.forumAdmin = Boolean(d.forumAdmin);
            }
            if (d.topicCreatable != null) {
                m.topicCreatable = Boolean(d.topicCreatable);
            }
            if (d.profileForumPolicy != null) {
                m.profileForumPolicy = d.profileForumPolicy | 0;
            }
            if (d.profileChatPolicy != null) {
                m.profileChatPolicy = d.profileChatPolicy | 0;
            }
            if (d.useStamp != null) {
                m.useStamp = Boolean(d.useStamp);
            }
            if (d.contentSetting != null) {
                m.contentSetting = String(d.contentSetting);
            }
            if (d.forumListTitle != null) {
                m.forumListTitle = String(d.forumListTitle);
            }
            if (d.forumTitle != null) {
                m.forumTitle = String(d.forumTitle);
            }
            if (d.topicTitle != null) {
                m.topicTitle = String(d.topicTitle);
            }
            if (d.commentTitle != null) {
                m.commentTitle = String(d.commentTitle);
            }
            if (d.reactionUserGroup != null) {
                m.reactionUserGroup = d.reactionUserGroup | 0;
            }
            if (d.listReadCount != null) {
                m.listReadCount = d.listReadCount | 0;
            }
            if (d.allowMention != null) {
                m.allowMention = Boolean(d.allowMention);
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoard message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoard
         * @static
         * @param {Proto.CommunityBoard} m CommunityBoard
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoard.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.policyList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.topicPolicy = 0;
                d.commentPolicy = 0;
                d.commentCreatePolicy = 0;
                d.commentVisible = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.companyUID = "";
                d.unreadCount = "";
                d.appTheme = 0;
                d.groupCreatePolicy = 0;
                d.groupPolicy = 0;
                d.topicScope = 0;
                d.commentCreatable = false;
                d.topicMapPolicy = 0;
                d.commentMapPolicy = 0;
                d.placesAPIType = 0;
                d.placesAPIKey = "";
                d.topicVideoPolicy = 0;
                d.commentVideoPolicy = 0;
                d.webTalkType = 0;
                d.webTalkPolicy = 0;
                d.groupRealtimePolicy = 0;
                d.groupUploadPolicy = 0;
                d.groupUploadEnv = 0;
                d.groupPlayEnv = 0;
                d.groupCreatable = false;
                d.groupUploadable = false;
                d.groupUserListPolicy = 0;
                d.profilePlanlistPolicy = 0;
                d.forumAdmin = false;
                d.topicCreatable = false;
                d.profileForumPolicy = 0;
                d.profileChatPolicy = 0;
                d.useStamp = false;
                d.contentSetting = "";
                d.forumListTitle = "";
                d.forumTitle = "";
                d.topicTitle = "";
                d.commentTitle = "";
                d.reactionUserGroup = 0;
                d.listReadCount = 0;
                d.allowMention = false;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.topicPolicy != null && m.hasOwnProperty("topicPolicy")) {
                d.topicPolicy = m.topicPolicy;
            }
            if (m.commentPolicy != null && m.hasOwnProperty("commentPolicy")) {
                d.commentPolicy = m.commentPolicy;
            }
            if (m.commentCreatePolicy != null && m.hasOwnProperty("commentCreatePolicy")) {
                d.commentCreatePolicy = m.commentCreatePolicy;
            }
            if (m.commentVisible != null && m.hasOwnProperty("commentVisible")) {
                d.commentVisible = m.commentVisible;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.unreadCount != null && m.hasOwnProperty("unreadCount")) {
                d.unreadCount = m.unreadCount;
            }
            if (m.appTheme != null && m.hasOwnProperty("appTheme")) {
                d.appTheme = m.appTheme;
            }
            if (m.groupCreatePolicy != null && m.hasOwnProperty("groupCreatePolicy")) {
                d.groupCreatePolicy = m.groupCreatePolicy;
            }
            if (m.groupPolicy != null && m.hasOwnProperty("groupPolicy")) {
                d.groupPolicy = m.groupPolicy;
            }
            if (m.topicScope != null && m.hasOwnProperty("topicScope")) {
                d.topicScope = m.topicScope;
            }
            if (m.policyList && m.policyList.length) {
                d.policyList = [];
                for (var j = 0; j < m.policyList.length; ++j) {
                    d.policyList[j] = $root.Proto.CommunityBoardPolicy.toObject(m.policyList[j], o);
                }
            }
            if (m.commentCreatable != null && m.hasOwnProperty("commentCreatable")) {
                d.commentCreatable = m.commentCreatable;
            }
            if (m.topicMapPolicy != null && m.hasOwnProperty("topicMapPolicy")) {
                d.topicMapPolicy = m.topicMapPolicy;
            }
            if (m.commentMapPolicy != null && m.hasOwnProperty("commentMapPolicy")) {
                d.commentMapPolicy = m.commentMapPolicy;
            }
            if (m.placesAPIType != null && m.hasOwnProperty("placesAPIType")) {
                d.placesAPIType = m.placesAPIType;
            }
            if (m.placesAPIKey != null && m.hasOwnProperty("placesAPIKey")) {
                d.placesAPIKey = m.placesAPIKey;
            }
            if (m.topicVideoPolicy != null && m.hasOwnProperty("topicVideoPolicy")) {
                d.topicVideoPolicy = m.topicVideoPolicy;
            }
            if (m.commentVideoPolicy != null && m.hasOwnProperty("commentVideoPolicy")) {
                d.commentVideoPolicy = m.commentVideoPolicy;
            }
            if (m.webTalkType != null && m.hasOwnProperty("webTalkType")) {
                d.webTalkType = m.webTalkType;
            }
            if (m.webTalkPolicy != null && m.hasOwnProperty("webTalkPolicy")) {
                d.webTalkPolicy = m.webTalkPolicy;
            }
            if (m.groupRealtimePolicy != null && m.hasOwnProperty("groupRealtimePolicy")) {
                d.groupRealtimePolicy = m.groupRealtimePolicy;
            }
            if (m.groupUploadPolicy != null && m.hasOwnProperty("groupUploadPolicy")) {
                d.groupUploadPolicy = m.groupUploadPolicy;
            }
            if (m.groupUploadEnv != null && m.hasOwnProperty("groupUploadEnv")) {
                d.groupUploadEnv = m.groupUploadEnv;
            }
            if (m.groupPlayEnv != null && m.hasOwnProperty("groupPlayEnv")) {
                d.groupPlayEnv = m.groupPlayEnv;
            }
            if (m.groupCreatable != null && m.hasOwnProperty("groupCreatable")) {
                d.groupCreatable = m.groupCreatable;
            }
            if (m.groupUploadable != null && m.hasOwnProperty("groupUploadable")) {
                d.groupUploadable = m.groupUploadable;
            }
            if (m.groupUserListPolicy != null && m.hasOwnProperty("groupUserListPolicy")) {
                d.groupUserListPolicy = m.groupUserListPolicy;
            }
            if (m.profilePlanlistPolicy != null && m.hasOwnProperty("profilePlanlistPolicy")) {
                d.profilePlanlistPolicy = m.profilePlanlistPolicy;
            }
            if (m.forumAdmin != null && m.hasOwnProperty("forumAdmin")) {
                d.forumAdmin = m.forumAdmin;
            }
            if (m.topicCreatable != null && m.hasOwnProperty("topicCreatable")) {
                d.topicCreatable = m.topicCreatable;
            }
            if (m.profileForumPolicy != null && m.hasOwnProperty("profileForumPolicy")) {
                d.profileForumPolicy = m.profileForumPolicy;
            }
            if (m.profileChatPolicy != null && m.hasOwnProperty("profileChatPolicy")) {
                d.profileChatPolicy = m.profileChatPolicy;
            }
            if (m.useStamp != null && m.hasOwnProperty("useStamp")) {
                d.useStamp = m.useStamp;
            }
            if (m.contentSetting != null && m.hasOwnProperty("contentSetting")) {
                d.contentSetting = m.contentSetting;
            }
            if (m.forumListTitle != null && m.hasOwnProperty("forumListTitle")) {
                d.forumListTitle = m.forumListTitle;
            }
            if (m.forumTitle != null && m.hasOwnProperty("forumTitle")) {
                d.forumTitle = m.forumTitle;
            }
            if (m.topicTitle != null && m.hasOwnProperty("topicTitle")) {
                d.topicTitle = m.topicTitle;
            }
            if (m.commentTitle != null && m.hasOwnProperty("commentTitle")) {
                d.commentTitle = m.commentTitle;
            }
            if (m.reactionUserGroup != null && m.hasOwnProperty("reactionUserGroup")) {
                d.reactionUserGroup = m.reactionUserGroup;
            }
            if (m.listReadCount != null && m.hasOwnProperty("listReadCount")) {
                d.listReadCount = m.listReadCount;
            }
            if (m.allowMention != null && m.hasOwnProperty("allowMention")) {
                d.allowMention = m.allowMention;
            }
            return d;
        };

        /**
         * Converts this CommunityBoard to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoard
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoard.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoard;
    })();

    Proto.CommunityBoardPolicy = (function() {

        /**
         * Properties of a CommunityBoardPolicy.
         * @memberof Proto
         * @interface ICommunityBoardPolicy
         * @property {number|null} [type] CommunityBoardPolicy type
         * @property {string|null} [planUID] CommunityBoardPolicy planUID
         * @property {string|null} [groupUID] CommunityBoardPolicy groupUID
         * @property {boolean|null} [active] CommunityBoardPolicy active
         * @property {number|Long|null} [registerDate] CommunityBoardPolicy registerDate
         * @property {number|Long|null} [modifyDate] CommunityBoardPolicy modifyDate
         * @property {boolean|null} [joined] CommunityBoardPolicy joined
         */

        /**
         * Constructs a new CommunityBoardPolicy.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardPolicy.
         * @implements ICommunityBoardPolicy
         * @constructor
         * @param {Proto.ICommunityBoardPolicy=} [p] Properties to set
         */
        function CommunityBoardPolicy(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardPolicy type.
         * @member {number} type
         * @memberof Proto.CommunityBoardPolicy
         * @instance
         */
        CommunityBoardPolicy.prototype.type = 0;

        /**
         * CommunityBoardPolicy planUID.
         * @member {string} planUID
         * @memberof Proto.CommunityBoardPolicy
         * @instance
         */
        CommunityBoardPolicy.prototype.planUID = "";

        /**
         * CommunityBoardPolicy groupUID.
         * @member {string} groupUID
         * @memberof Proto.CommunityBoardPolicy
         * @instance
         */
        CommunityBoardPolicy.prototype.groupUID = "";

        /**
         * CommunityBoardPolicy active.
         * @member {boolean} active
         * @memberof Proto.CommunityBoardPolicy
         * @instance
         */
        CommunityBoardPolicy.prototype.active = false;

        /**
         * CommunityBoardPolicy registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommunityBoardPolicy
         * @instance
         */
        CommunityBoardPolicy.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardPolicy modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommunityBoardPolicy
         * @instance
         */
        CommunityBoardPolicy.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardPolicy joined.
         * @member {boolean} joined
         * @memberof Proto.CommunityBoardPolicy
         * @instance
         */
        CommunityBoardPolicy.prototype.joined = false;

        /**
         * Creates a new CommunityBoardPolicy instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardPolicy
         * @static
         * @param {Proto.ICommunityBoardPolicy=} [properties] Properties to set
         * @returns {Proto.CommunityBoardPolicy} CommunityBoardPolicy instance
         */
        CommunityBoardPolicy.create = function create(properties) {
            return new CommunityBoardPolicy(properties);
        };

        /**
         * Encodes the specified CommunityBoardPolicy message. Does not implicitly {@link Proto.CommunityBoardPolicy.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardPolicy
         * @static
         * @param {Proto.ICommunityBoardPolicy} m CommunityBoardPolicy message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardPolicy.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(8).int32(m.type);
            if (m.planUID != null && Object.hasOwnProperty.call(m, "planUID"))
                w.uint32(18).string(m.planUID);
            if (m.groupUID != null && Object.hasOwnProperty.call(m, "groupUID"))
                w.uint32(26).string(m.groupUID);
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(32).bool(m.active);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(40).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(48).int64(m.modifyDate);
            if (m.joined != null && Object.hasOwnProperty.call(m, "joined"))
                w.uint32(56).bool(m.joined);
            return w;
        };

        /**
         * Decodes a CommunityBoardPolicy message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardPolicy
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardPolicy} CommunityBoardPolicy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardPolicy.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardPolicy();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.type = r.int32();
                    break;
                case 2:
                    m.planUID = r.string();
                    break;
                case 3:
                    m.groupUID = r.string();
                    break;
                case 4:
                    m.active = r.bool();
                    break;
                case 5:
                    m.registerDate = r.int64();
                    break;
                case 6:
                    m.modifyDate = r.int64();
                    break;
                case 7:
                    m.joined = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardPolicy message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardPolicy
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardPolicy} CommunityBoardPolicy
         */
        CommunityBoardPolicy.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardPolicy)
                return d;
            var m = new $root.Proto.CommunityBoardPolicy();
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.planUID != null) {
                m.planUID = String(d.planUID);
            }
            if (d.groupUID != null) {
                m.groupUID = String(d.groupUID);
            }
            if (d.active != null) {
                m.active = Boolean(d.active);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.joined != null) {
                m.joined = Boolean(d.joined);
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardPolicy message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardPolicy
         * @static
         * @param {Proto.CommunityBoardPolicy} m CommunityBoardPolicy
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardPolicy.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.type = 0;
                d.planUID = "";
                d.groupUID = "";
                d.active = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.joined = false;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.planUID != null && m.hasOwnProperty("planUID")) {
                d.planUID = m.planUID;
            }
            if (m.groupUID != null && m.hasOwnProperty("groupUID")) {
                d.groupUID = m.groupUID;
            }
            if (m.active != null && m.hasOwnProperty("active")) {
                d.active = m.active;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.joined != null && m.hasOwnProperty("joined")) {
                d.joined = m.joined;
            }
            return d;
        };

        /**
         * Converts this CommunityBoardPolicy to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardPolicy
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardPolicy.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardPolicy;
    })();

    Proto.CommunityBoardForumPolicy = (function() {

        /**
         * Properties of a CommunityBoardForumPolicy.
         * @memberof Proto
         * @interface ICommunityBoardForumPolicy
         * @property {string|null} [forumUID] CommunityBoardForumPolicy forumUID
         * @property {string|null} [userGroupUID] CommunityBoardForumPolicy userGroupUID
         * @property {boolean|null} [admin] CommunityBoardForumPolicy admin
         * @property {number|null} [scope] CommunityBoardForumPolicy scope
         * @property {boolean|null} [topicPolicy] CommunityBoardForumPolicy topicPolicy
         * @property {number|null} [commentPolicy] CommunityBoardForumPolicy commentPolicy
         * @property {boolean|null} [commentCreatePolicy] CommunityBoardForumPolicy commentCreatePolicy
         * @property {number|null} [playEnv] CommunityBoardForumPolicy playEnv
         * @property {number|Long|null} [registerDate] CommunityBoardForumPolicy registerDate
         * @property {number|Long|null} [modifyDate] CommunityBoardForumPolicy modifyDate
         * @property {string|null} [forumTitle] CommunityBoardForumPolicy forumTitle
         * @property {Proto.ICompanyUserGroup|null} [userGroup] CommunityBoardForumPolicy userGroup
         * @property {boolean|null} [forumUseComment] CommunityBoardForumPolicy forumUseComment
         */

        /**
         * Constructs a new CommunityBoardForumPolicy.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardForumPolicy.
         * @implements ICommunityBoardForumPolicy
         * @constructor
         * @param {Proto.ICommunityBoardForumPolicy=} [p] Properties to set
         */
        function CommunityBoardForumPolicy(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardForumPolicy forumUID.
         * @member {string} forumUID
         * @memberof Proto.CommunityBoardForumPolicy
         * @instance
         */
        CommunityBoardForumPolicy.prototype.forumUID = "";

        /**
         * CommunityBoardForumPolicy userGroupUID.
         * @member {string} userGroupUID
         * @memberof Proto.CommunityBoardForumPolicy
         * @instance
         */
        CommunityBoardForumPolicy.prototype.userGroupUID = "";

        /**
         * CommunityBoardForumPolicy admin.
         * @member {boolean} admin
         * @memberof Proto.CommunityBoardForumPolicy
         * @instance
         */
        CommunityBoardForumPolicy.prototype.admin = false;

        /**
         * CommunityBoardForumPolicy scope.
         * @member {number} scope
         * @memberof Proto.CommunityBoardForumPolicy
         * @instance
         */
        CommunityBoardForumPolicy.prototype.scope = 0;

        /**
         * CommunityBoardForumPolicy topicPolicy.
         * @member {boolean} topicPolicy
         * @memberof Proto.CommunityBoardForumPolicy
         * @instance
         */
        CommunityBoardForumPolicy.prototype.topicPolicy = false;

        /**
         * CommunityBoardForumPolicy commentPolicy.
         * @member {number} commentPolicy
         * @memberof Proto.CommunityBoardForumPolicy
         * @instance
         */
        CommunityBoardForumPolicy.prototype.commentPolicy = 0;

        /**
         * CommunityBoardForumPolicy commentCreatePolicy.
         * @member {boolean} commentCreatePolicy
         * @memberof Proto.CommunityBoardForumPolicy
         * @instance
         */
        CommunityBoardForumPolicy.prototype.commentCreatePolicy = false;

        /**
         * CommunityBoardForumPolicy playEnv.
         * @member {number} playEnv
         * @memberof Proto.CommunityBoardForumPolicy
         * @instance
         */
        CommunityBoardForumPolicy.prototype.playEnv = 0;

        /**
         * CommunityBoardForumPolicy registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommunityBoardForumPolicy
         * @instance
         */
        CommunityBoardForumPolicy.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardForumPolicy modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommunityBoardForumPolicy
         * @instance
         */
        CommunityBoardForumPolicy.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardForumPolicy forumTitle.
         * @member {string} forumTitle
         * @memberof Proto.CommunityBoardForumPolicy
         * @instance
         */
        CommunityBoardForumPolicy.prototype.forumTitle = "";

        /**
         * CommunityBoardForumPolicy userGroup.
         * @member {Proto.ICompanyUserGroup|null|undefined} userGroup
         * @memberof Proto.CommunityBoardForumPolicy
         * @instance
         */
        CommunityBoardForumPolicy.prototype.userGroup = null;

        /**
         * CommunityBoardForumPolicy forumUseComment.
         * @member {boolean} forumUseComment
         * @memberof Proto.CommunityBoardForumPolicy
         * @instance
         */
        CommunityBoardForumPolicy.prototype.forumUseComment = false;

        /**
         * Creates a new CommunityBoardForumPolicy instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardForumPolicy
         * @static
         * @param {Proto.ICommunityBoardForumPolicy=} [properties] Properties to set
         * @returns {Proto.CommunityBoardForumPolicy} CommunityBoardForumPolicy instance
         */
        CommunityBoardForumPolicy.create = function create(properties) {
            return new CommunityBoardForumPolicy(properties);
        };

        /**
         * Encodes the specified CommunityBoardForumPolicy message. Does not implicitly {@link Proto.CommunityBoardForumPolicy.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardForumPolicy
         * @static
         * @param {Proto.ICommunityBoardForumPolicy} m CommunityBoardForumPolicy message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardForumPolicy.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.forumUID != null && Object.hasOwnProperty.call(m, "forumUID"))
                w.uint32(10).string(m.forumUID);
            if (m.userGroupUID != null && Object.hasOwnProperty.call(m, "userGroupUID"))
                w.uint32(18).string(m.userGroupUID);
            if (m.admin != null && Object.hasOwnProperty.call(m, "admin"))
                w.uint32(24).bool(m.admin);
            if (m.scope != null && Object.hasOwnProperty.call(m, "scope"))
                w.uint32(32).int32(m.scope);
            if (m.topicPolicy != null && Object.hasOwnProperty.call(m, "topicPolicy"))
                w.uint32(40).bool(m.topicPolicy);
            if (m.commentPolicy != null && Object.hasOwnProperty.call(m, "commentPolicy"))
                w.uint32(48).int32(m.commentPolicy);
            if (m.commentCreatePolicy != null && Object.hasOwnProperty.call(m, "commentCreatePolicy"))
                w.uint32(56).bool(m.commentCreatePolicy);
            if (m.playEnv != null && Object.hasOwnProperty.call(m, "playEnv"))
                w.uint32(64).int32(m.playEnv);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(72).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(80).int64(m.modifyDate);
            if (m.forumTitle != null && Object.hasOwnProperty.call(m, "forumTitle"))
                w.uint32(90).string(m.forumTitle);
            if (m.userGroup != null && Object.hasOwnProperty.call(m, "userGroup"))
                $root.Proto.CompanyUserGroup.encode(m.userGroup, w.uint32(98).fork()).ldelim();
            if (m.forumUseComment != null && Object.hasOwnProperty.call(m, "forumUseComment"))
                w.uint32(104).bool(m.forumUseComment);
            return w;
        };

        /**
         * Decodes a CommunityBoardForumPolicy message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardForumPolicy
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardForumPolicy} CommunityBoardForumPolicy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardForumPolicy.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardForumPolicy();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.forumUID = r.string();
                    break;
                case 2:
                    m.userGroupUID = r.string();
                    break;
                case 3:
                    m.admin = r.bool();
                    break;
                case 4:
                    m.scope = r.int32();
                    break;
                case 5:
                    m.topicPolicy = r.bool();
                    break;
                case 6:
                    m.commentPolicy = r.int32();
                    break;
                case 7:
                    m.commentCreatePolicy = r.bool();
                    break;
                case 8:
                    m.playEnv = r.int32();
                    break;
                case 9:
                    m.registerDate = r.int64();
                    break;
                case 10:
                    m.modifyDate = r.int64();
                    break;
                case 11:
                    m.forumTitle = r.string();
                    break;
                case 12:
                    m.userGroup = $root.Proto.CompanyUserGroup.decode(r, r.uint32());
                    break;
                case 13:
                    m.forumUseComment = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardForumPolicy message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardForumPolicy
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardForumPolicy} CommunityBoardForumPolicy
         */
        CommunityBoardForumPolicy.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardForumPolicy)
                return d;
            var m = new $root.Proto.CommunityBoardForumPolicy();
            if (d.forumUID != null) {
                m.forumUID = String(d.forumUID);
            }
            if (d.userGroupUID != null) {
                m.userGroupUID = String(d.userGroupUID);
            }
            if (d.admin != null) {
                m.admin = Boolean(d.admin);
            }
            if (d.scope != null) {
                m.scope = d.scope | 0;
            }
            if (d.topicPolicy != null) {
                m.topicPolicy = Boolean(d.topicPolicy);
            }
            if (d.commentPolicy != null) {
                m.commentPolicy = d.commentPolicy | 0;
            }
            if (d.commentCreatePolicy != null) {
                m.commentCreatePolicy = Boolean(d.commentCreatePolicy);
            }
            if (d.playEnv != null) {
                m.playEnv = d.playEnv | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.forumTitle != null) {
                m.forumTitle = String(d.forumTitle);
            }
            if (d.userGroup != null) {
                if (typeof d.userGroup !== "object")
                    throw TypeError(".Proto.CommunityBoardForumPolicy.userGroup: object expected");
                m.userGroup = $root.Proto.CompanyUserGroup.fromObject(d.userGroup);
            }
            if (d.forumUseComment != null) {
                m.forumUseComment = Boolean(d.forumUseComment);
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardForumPolicy message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardForumPolicy
         * @static
         * @param {Proto.CommunityBoardForumPolicy} m CommunityBoardForumPolicy
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardForumPolicy.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.forumUID = "";
                d.userGroupUID = "";
                d.admin = false;
                d.scope = 0;
                d.topicPolicy = false;
                d.commentPolicy = 0;
                d.commentCreatePolicy = false;
                d.playEnv = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.forumTitle = "";
                d.userGroup = null;
                d.forumUseComment = false;
            }
            if (m.forumUID != null && m.hasOwnProperty("forumUID")) {
                d.forumUID = m.forumUID;
            }
            if (m.userGroupUID != null && m.hasOwnProperty("userGroupUID")) {
                d.userGroupUID = m.userGroupUID;
            }
            if (m.admin != null && m.hasOwnProperty("admin")) {
                d.admin = m.admin;
            }
            if (m.scope != null && m.hasOwnProperty("scope")) {
                d.scope = m.scope;
            }
            if (m.topicPolicy != null && m.hasOwnProperty("topicPolicy")) {
                d.topicPolicy = m.topicPolicy;
            }
            if (m.commentPolicy != null && m.hasOwnProperty("commentPolicy")) {
                d.commentPolicy = m.commentPolicy;
            }
            if (m.commentCreatePolicy != null && m.hasOwnProperty("commentCreatePolicy")) {
                d.commentCreatePolicy = m.commentCreatePolicy;
            }
            if (m.playEnv != null && m.hasOwnProperty("playEnv")) {
                d.playEnv = m.playEnv;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.forumTitle != null && m.hasOwnProperty("forumTitle")) {
                d.forumTitle = m.forumTitle;
            }
            if (m.userGroup != null && m.hasOwnProperty("userGroup")) {
                d.userGroup = $root.Proto.CompanyUserGroup.toObject(m.userGroup, o);
            }
            if (m.forumUseComment != null && m.hasOwnProperty("forumUseComment")) {
                d.forumUseComment = m.forumUseComment;
            }
            return d;
        };

        /**
         * Converts this CommunityBoardForumPolicy to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardForumPolicy
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardForumPolicy.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardForumPolicy;
    })();

    Proto.CommunityBoardForumPolicyList = (function() {

        /**
         * Properties of a CommunityBoardForumPolicyList.
         * @memberof Proto
         * @interface ICommunityBoardForumPolicyList
         * @property {Array.<Proto.ICommunityBoardForumPolicy>|null} [value] CommunityBoardForumPolicyList value
         */

        /**
         * Constructs a new CommunityBoardForumPolicyList.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardForumPolicyList.
         * @implements ICommunityBoardForumPolicyList
         * @constructor
         * @param {Proto.ICommunityBoardForumPolicyList=} [p] Properties to set
         */
        function CommunityBoardForumPolicyList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardForumPolicyList value.
         * @member {Array.<Proto.ICommunityBoardForumPolicy>} value
         * @memberof Proto.CommunityBoardForumPolicyList
         * @instance
         */
        CommunityBoardForumPolicyList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CommunityBoardForumPolicyList instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardForumPolicyList
         * @static
         * @param {Proto.ICommunityBoardForumPolicyList=} [properties] Properties to set
         * @returns {Proto.CommunityBoardForumPolicyList} CommunityBoardForumPolicyList instance
         */
        CommunityBoardForumPolicyList.create = function create(properties) {
            return new CommunityBoardForumPolicyList(properties);
        };

        /**
         * Encodes the specified CommunityBoardForumPolicyList message. Does not implicitly {@link Proto.CommunityBoardForumPolicyList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardForumPolicyList
         * @static
         * @param {Proto.ICommunityBoardForumPolicyList} m CommunityBoardForumPolicyList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardForumPolicyList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CommunityBoardForumPolicy.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CommunityBoardForumPolicyList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardForumPolicyList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardForumPolicyList} CommunityBoardForumPolicyList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardForumPolicyList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardForumPolicyList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CommunityBoardForumPolicy.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardForumPolicyList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardForumPolicyList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardForumPolicyList} CommunityBoardForumPolicyList
         */
        CommunityBoardForumPolicyList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardForumPolicyList)
                return d;
            var m = new $root.Proto.CommunityBoardForumPolicyList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CommunityBoardForumPolicyList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardForumPolicyList.value: object expected");
                    m.value[i] = $root.Proto.CommunityBoardForumPolicy.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardForumPolicyList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardForumPolicyList
         * @static
         * @param {Proto.CommunityBoardForumPolicyList} m CommunityBoardForumPolicyList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardForumPolicyList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CommunityBoardForumPolicy.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CommunityBoardForumPolicyList to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardForumPolicyList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardForumPolicyList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardForumPolicyList;
    })();

    Proto.CommunityBoardForum = (function() {

        /**
         * Properties of a CommunityBoardForum.
         * @memberof Proto
         * @interface ICommunityBoardForum
         * @property {string|null} [uid] CommunityBoardForum uid
         * @property {string|null} [boardUID] CommunityBoardForum boardUID
         * @property {string|null} [title] CommunityBoardForum title
         * @property {string|null} [text] CommunityBoardForum text
         * @property {number|null} [topicPolicy] CommunityBoardForum topicPolicy
         * @property {number|null} [commentPolicy] CommunityBoardForum commentPolicy
         * @property {number|null} [commentCreatePolicy] CommunityBoardForum commentCreatePolicy
         * @property {number|null} [commentVisible] CommunityBoardForum commentVisible
         * @property {number|Long|null} [registerDate] CommunityBoardForum registerDate
         * @property {number|Long|null} [modifyDate] CommunityBoardForum modifyDate
         * @property {number|null} [status] CommunityBoardForum status
         * @property {string|null} [unreadCount] CommunityBoardForum unreadCount
         * @property {number|null} [topicScope] CommunityBoardForum topicScope
         * @property {boolean|null} [commentCreatable] CommunityBoardForum commentCreatable
         * @property {number|null} [topicMapPolicy] CommunityBoardForum topicMapPolicy
         * @property {number|null} [commentMapPolicy] CommunityBoardForum commentMapPolicy
         * @property {number|null} [topicVideoPolicy] CommunityBoardForum topicVideoPolicy
         * @property {number|null} [commentVideoPolicy] CommunityBoardForum commentVideoPolicy
         * @property {Array.<Proto.ICommunityBoardForumPolicy>|null} [policyList] CommunityBoardForum policyList
         * @property {boolean|null} [topicPreview] CommunityBoardForum topicPreview
         * @property {string|null} [topicTitle] CommunityBoardForum topicTitle
         * @property {string|null} [commentTitle] CommunityBoardForum commentTitle
         * @property {Proto.IForumPolicyGroupCount|null} [policyCount] CommunityBoardForum policyCount
         * @property {boolean|null} [showGroup] CommunityBoardForum showGroup
         * @property {Proto.IUploadFile|null} [image] CommunityBoardForum image
         * @property {Array.<Proto.ICommunityBoardForumItem>|null} [itemList] CommunityBoardForum itemList
         * @property {boolean|null} [useTemplate] CommunityBoardForum useTemplate
         * @property {string|null} [topicPolicyGroupCount] CommunityBoardForum topicPolicyGroupCount
         * @property {Array.<Proto.ICompanyUserGroup>|null} [topicPolicyGroupList] CommunityBoardForum topicPolicyGroupList
         * @property {number|Long|null} [templateDate] CommunityBoardForum templateDate
         * @property {boolean|null} [admin] CommunityBoardForum admin
         * @property {boolean|null} [topicCreatable] CommunityBoardForum topicCreatable
         * @property {Array.<Proto.ICompanyUserGroup>|null} [exclusiveGroupList] CommunityBoardForum exclusiveGroupList
         * @property {boolean|null} [useComment] CommunityBoardForum useComment
         */

        /**
         * Constructs a new CommunityBoardForum.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardForum.
         * @implements ICommunityBoardForum
         * @constructor
         * @param {Proto.ICommunityBoardForum=} [p] Properties to set
         */
        function CommunityBoardForum(p) {
            this.policyList = [];
            this.itemList = [];
            this.topicPolicyGroupList = [];
            this.exclusiveGroupList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardForum uid.
         * @member {string} uid
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.uid = "";

        /**
         * CommunityBoardForum boardUID.
         * @member {string} boardUID
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.boardUID = "";

        /**
         * CommunityBoardForum title.
         * @member {string} title
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.title = "";

        /**
         * CommunityBoardForum text.
         * @member {string} text
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.text = "";

        /**
         * CommunityBoardForum topicPolicy.
         * @member {number} topicPolicy
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.topicPolicy = 0;

        /**
         * CommunityBoardForum commentPolicy.
         * @member {number} commentPolicy
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.commentPolicy = 0;

        /**
         * CommunityBoardForum commentCreatePolicy.
         * @member {number} commentCreatePolicy
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.commentCreatePolicy = 0;

        /**
         * CommunityBoardForum commentVisible.
         * @member {number} commentVisible
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.commentVisible = 0;

        /**
         * CommunityBoardForum registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardForum modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardForum status.
         * @member {number} status
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.status = 0;

        /**
         * CommunityBoardForum unreadCount.
         * @member {string} unreadCount
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.unreadCount = "";

        /**
         * CommunityBoardForum topicScope.
         * @member {number} topicScope
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.topicScope = 0;

        /**
         * CommunityBoardForum commentCreatable.
         * @member {boolean} commentCreatable
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.commentCreatable = false;

        /**
         * CommunityBoardForum topicMapPolicy.
         * @member {number} topicMapPolicy
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.topicMapPolicy = 0;

        /**
         * CommunityBoardForum commentMapPolicy.
         * @member {number} commentMapPolicy
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.commentMapPolicy = 0;

        /**
         * CommunityBoardForum topicVideoPolicy.
         * @member {number} topicVideoPolicy
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.topicVideoPolicy = 0;

        /**
         * CommunityBoardForum commentVideoPolicy.
         * @member {number} commentVideoPolicy
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.commentVideoPolicy = 0;

        /**
         * CommunityBoardForum policyList.
         * @member {Array.<Proto.ICommunityBoardForumPolicy>} policyList
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.policyList = $util.emptyArray;

        /**
         * CommunityBoardForum topicPreview.
         * @member {boolean} topicPreview
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.topicPreview = false;

        /**
         * CommunityBoardForum topicTitle.
         * @member {string} topicTitle
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.topicTitle = "";

        /**
         * CommunityBoardForum commentTitle.
         * @member {string} commentTitle
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.commentTitle = "";

        /**
         * CommunityBoardForum policyCount.
         * @member {Proto.IForumPolicyGroupCount|null|undefined} policyCount
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.policyCount = null;

        /**
         * CommunityBoardForum showGroup.
         * @member {boolean} showGroup
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.showGroup = false;

        /**
         * CommunityBoardForum image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.image = null;

        /**
         * CommunityBoardForum itemList.
         * @member {Array.<Proto.ICommunityBoardForumItem>} itemList
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.itemList = $util.emptyArray;

        /**
         * CommunityBoardForum useTemplate.
         * @member {boolean} useTemplate
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.useTemplate = false;

        /**
         * CommunityBoardForum topicPolicyGroupCount.
         * @member {string} topicPolicyGroupCount
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.topicPolicyGroupCount = "";

        /**
         * CommunityBoardForum topicPolicyGroupList.
         * @member {Array.<Proto.ICompanyUserGroup>} topicPolicyGroupList
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.topicPolicyGroupList = $util.emptyArray;

        /**
         * CommunityBoardForum templateDate.
         * @member {number|Long} templateDate
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.templateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardForum admin.
         * @member {boolean} admin
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.admin = false;

        /**
         * CommunityBoardForum topicCreatable.
         * @member {boolean} topicCreatable
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.topicCreatable = false;

        /**
         * CommunityBoardForum exclusiveGroupList.
         * @member {Array.<Proto.ICompanyUserGroup>} exclusiveGroupList
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.exclusiveGroupList = $util.emptyArray;

        /**
         * CommunityBoardForum useComment.
         * @member {boolean} useComment
         * @memberof Proto.CommunityBoardForum
         * @instance
         */
        CommunityBoardForum.prototype.useComment = false;

        /**
         * Creates a new CommunityBoardForum instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardForum
         * @static
         * @param {Proto.ICommunityBoardForum=} [properties] Properties to set
         * @returns {Proto.CommunityBoardForum} CommunityBoardForum instance
         */
        CommunityBoardForum.create = function create(properties) {
            return new CommunityBoardForum(properties);
        };

        /**
         * Encodes the specified CommunityBoardForum message. Does not implicitly {@link Proto.CommunityBoardForum.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardForum
         * @static
         * @param {Proto.ICommunityBoardForum} m CommunityBoardForum message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardForum.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.boardUID != null && Object.hasOwnProperty.call(m, "boardUID"))
                w.uint32(18).string(m.boardUID);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(26).string(m.title);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(34).string(m.text);
            if (m.topicPolicy != null && Object.hasOwnProperty.call(m, "topicPolicy"))
                w.uint32(40).int32(m.topicPolicy);
            if (m.commentPolicy != null && Object.hasOwnProperty.call(m, "commentPolicy"))
                w.uint32(48).int32(m.commentPolicy);
            if (m.commentCreatePolicy != null && Object.hasOwnProperty.call(m, "commentCreatePolicy"))
                w.uint32(56).int32(m.commentCreatePolicy);
            if (m.commentVisible != null && Object.hasOwnProperty.call(m, "commentVisible"))
                w.uint32(64).int32(m.commentVisible);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(72).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(80).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(88).int32(m.status);
            if (m.unreadCount != null && Object.hasOwnProperty.call(m, "unreadCount"))
                w.uint32(98).string(m.unreadCount);
            if (m.topicScope != null && Object.hasOwnProperty.call(m, "topicScope"))
                w.uint32(104).int32(m.topicScope);
            if (m.commentCreatable != null && Object.hasOwnProperty.call(m, "commentCreatable"))
                w.uint32(112).bool(m.commentCreatable);
            if (m.topicMapPolicy != null && Object.hasOwnProperty.call(m, "topicMapPolicy"))
                w.uint32(120).int32(m.topicMapPolicy);
            if (m.commentMapPolicy != null && Object.hasOwnProperty.call(m, "commentMapPolicy"))
                w.uint32(128).int32(m.commentMapPolicy);
            if (m.topicVideoPolicy != null && Object.hasOwnProperty.call(m, "topicVideoPolicy"))
                w.uint32(136).int32(m.topicVideoPolicy);
            if (m.commentVideoPolicy != null && Object.hasOwnProperty.call(m, "commentVideoPolicy"))
                w.uint32(144).int32(m.commentVideoPolicy);
            if (m.policyList != null && m.policyList.length) {
                for (var i = 0; i < m.policyList.length; ++i)
                    $root.Proto.CommunityBoardForumPolicy.encode(m.policyList[i], w.uint32(154).fork()).ldelim();
            }
            if (m.topicPreview != null && Object.hasOwnProperty.call(m, "topicPreview"))
                w.uint32(160).bool(m.topicPreview);
            if (m.topicTitle != null && Object.hasOwnProperty.call(m, "topicTitle"))
                w.uint32(170).string(m.topicTitle);
            if (m.commentTitle != null && Object.hasOwnProperty.call(m, "commentTitle"))
                w.uint32(178).string(m.commentTitle);
            if (m.policyCount != null && Object.hasOwnProperty.call(m, "policyCount"))
                $root.Proto.ForumPolicyGroupCount.encode(m.policyCount, w.uint32(186).fork()).ldelim();
            if (m.showGroup != null && Object.hasOwnProperty.call(m, "showGroup"))
                w.uint32(192).bool(m.showGroup);
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(202).fork()).ldelim();
            if (m.itemList != null && m.itemList.length) {
                for (var i = 0; i < m.itemList.length; ++i)
                    $root.Proto.CommunityBoardForumItem.encode(m.itemList[i], w.uint32(210).fork()).ldelim();
            }
            if (m.useTemplate != null && Object.hasOwnProperty.call(m, "useTemplate"))
                w.uint32(216).bool(m.useTemplate);
            if (m.topicPolicyGroupCount != null && Object.hasOwnProperty.call(m, "topicPolicyGroupCount"))
                w.uint32(226).string(m.topicPolicyGroupCount);
            if (m.topicPolicyGroupList != null && m.topicPolicyGroupList.length) {
                for (var i = 0; i < m.topicPolicyGroupList.length; ++i)
                    $root.Proto.CompanyUserGroup.encode(m.topicPolicyGroupList[i], w.uint32(234).fork()).ldelim();
            }
            if (m.templateDate != null && Object.hasOwnProperty.call(m, "templateDate"))
                w.uint32(240).int64(m.templateDate);
            if (m.admin != null && Object.hasOwnProperty.call(m, "admin"))
                w.uint32(248).bool(m.admin);
            if (m.topicCreatable != null && Object.hasOwnProperty.call(m, "topicCreatable"))
                w.uint32(256).bool(m.topicCreatable);
            if (m.exclusiveGroupList != null && m.exclusiveGroupList.length) {
                for (var i = 0; i < m.exclusiveGroupList.length; ++i)
                    $root.Proto.CompanyUserGroup.encode(m.exclusiveGroupList[i], w.uint32(266).fork()).ldelim();
            }
            if (m.useComment != null && Object.hasOwnProperty.call(m, "useComment"))
                w.uint32(272).bool(m.useComment);
            return w;
        };

        /**
         * Decodes a CommunityBoardForum message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardForum
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardForum} CommunityBoardForum
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardForum.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardForum();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.boardUID = r.string();
                    break;
                case 3:
                    m.title = r.string();
                    break;
                case 4:
                    m.text = r.string();
                    break;
                case 5:
                    m.topicPolicy = r.int32();
                    break;
                case 6:
                    m.commentPolicy = r.int32();
                    break;
                case 7:
                    m.commentCreatePolicy = r.int32();
                    break;
                case 8:
                    m.commentVisible = r.int32();
                    break;
                case 9:
                    m.registerDate = r.int64();
                    break;
                case 10:
                    m.modifyDate = r.int64();
                    break;
                case 11:
                    m.status = r.int32();
                    break;
                case 12:
                    m.unreadCount = r.string();
                    break;
                case 13:
                    m.topicScope = r.int32();
                    break;
                case 14:
                    m.commentCreatable = r.bool();
                    break;
                case 15:
                    m.topicMapPolicy = r.int32();
                    break;
                case 16:
                    m.commentMapPolicy = r.int32();
                    break;
                case 17:
                    m.topicVideoPolicy = r.int32();
                    break;
                case 18:
                    m.commentVideoPolicy = r.int32();
                    break;
                case 19:
                    if (!(m.policyList && m.policyList.length))
                        m.policyList = [];
                    m.policyList.push($root.Proto.CommunityBoardForumPolicy.decode(r, r.uint32()));
                    break;
                case 20:
                    m.topicPreview = r.bool();
                    break;
                case 21:
                    m.topicTitle = r.string();
                    break;
                case 22:
                    m.commentTitle = r.string();
                    break;
                case 23:
                    m.policyCount = $root.Proto.ForumPolicyGroupCount.decode(r, r.uint32());
                    break;
                case 24:
                    m.showGroup = r.bool();
                    break;
                case 25:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 26:
                    if (!(m.itemList && m.itemList.length))
                        m.itemList = [];
                    m.itemList.push($root.Proto.CommunityBoardForumItem.decode(r, r.uint32()));
                    break;
                case 27:
                    m.useTemplate = r.bool();
                    break;
                case 28:
                    m.topicPolicyGroupCount = r.string();
                    break;
                case 29:
                    if (!(m.topicPolicyGroupList && m.topicPolicyGroupList.length))
                        m.topicPolicyGroupList = [];
                    m.topicPolicyGroupList.push($root.Proto.CompanyUserGroup.decode(r, r.uint32()));
                    break;
                case 30:
                    m.templateDate = r.int64();
                    break;
                case 31:
                    m.admin = r.bool();
                    break;
                case 32:
                    m.topicCreatable = r.bool();
                    break;
                case 33:
                    if (!(m.exclusiveGroupList && m.exclusiveGroupList.length))
                        m.exclusiveGroupList = [];
                    m.exclusiveGroupList.push($root.Proto.CompanyUserGroup.decode(r, r.uint32()));
                    break;
                case 34:
                    m.useComment = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardForum message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardForum
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardForum} CommunityBoardForum
         */
        CommunityBoardForum.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardForum)
                return d;
            var m = new $root.Proto.CommunityBoardForum();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.boardUID != null) {
                m.boardUID = String(d.boardUID);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.topicPolicy != null) {
                m.topicPolicy = d.topicPolicy | 0;
            }
            if (d.commentPolicy != null) {
                m.commentPolicy = d.commentPolicy | 0;
            }
            if (d.commentCreatePolicy != null) {
                m.commentCreatePolicy = d.commentCreatePolicy | 0;
            }
            if (d.commentVisible != null) {
                m.commentVisible = d.commentVisible | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.unreadCount != null) {
                m.unreadCount = String(d.unreadCount);
            }
            if (d.topicScope != null) {
                m.topicScope = d.topicScope | 0;
            }
            if (d.commentCreatable != null) {
                m.commentCreatable = Boolean(d.commentCreatable);
            }
            if (d.topicMapPolicy != null) {
                m.topicMapPolicy = d.topicMapPolicy | 0;
            }
            if (d.commentMapPolicy != null) {
                m.commentMapPolicy = d.commentMapPolicy | 0;
            }
            if (d.topicVideoPolicy != null) {
                m.topicVideoPolicy = d.topicVideoPolicy | 0;
            }
            if (d.commentVideoPolicy != null) {
                m.commentVideoPolicy = d.commentVideoPolicy | 0;
            }
            if (d.policyList) {
                if (!Array.isArray(d.policyList))
                    throw TypeError(".Proto.CommunityBoardForum.policyList: array expected");
                m.policyList = [];
                for (var i = 0; i < d.policyList.length; ++i) {
                    if (typeof d.policyList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardForum.policyList: object expected");
                    m.policyList[i] = $root.Proto.CommunityBoardForumPolicy.fromObject(d.policyList[i]);
                }
            }
            if (d.topicPreview != null) {
                m.topicPreview = Boolean(d.topicPreview);
            }
            if (d.topicTitle != null) {
                m.topicTitle = String(d.topicTitle);
            }
            if (d.commentTitle != null) {
                m.commentTitle = String(d.commentTitle);
            }
            if (d.policyCount != null) {
                if (typeof d.policyCount !== "object")
                    throw TypeError(".Proto.CommunityBoardForum.policyCount: object expected");
                m.policyCount = $root.Proto.ForumPolicyGroupCount.fromObject(d.policyCount);
            }
            if (d.showGroup != null) {
                m.showGroup = Boolean(d.showGroup);
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.CommunityBoardForum.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.itemList) {
                if (!Array.isArray(d.itemList))
                    throw TypeError(".Proto.CommunityBoardForum.itemList: array expected");
                m.itemList = [];
                for (var i = 0; i < d.itemList.length; ++i) {
                    if (typeof d.itemList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardForum.itemList: object expected");
                    m.itemList[i] = $root.Proto.CommunityBoardForumItem.fromObject(d.itemList[i]);
                }
            }
            if (d.useTemplate != null) {
                m.useTemplate = Boolean(d.useTemplate);
            }
            if (d.topicPolicyGroupCount != null) {
                m.topicPolicyGroupCount = String(d.topicPolicyGroupCount);
            }
            if (d.topicPolicyGroupList) {
                if (!Array.isArray(d.topicPolicyGroupList))
                    throw TypeError(".Proto.CommunityBoardForum.topicPolicyGroupList: array expected");
                m.topicPolicyGroupList = [];
                for (var i = 0; i < d.topicPolicyGroupList.length; ++i) {
                    if (typeof d.topicPolicyGroupList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardForum.topicPolicyGroupList: object expected");
                    m.topicPolicyGroupList[i] = $root.Proto.CompanyUserGroup.fromObject(d.topicPolicyGroupList[i]);
                }
            }
            if (d.templateDate != null) {
                if ($util.Long)
                    (m.templateDate = $util.Long.fromValue(d.templateDate)).unsigned = false;
                else if (typeof d.templateDate === "string")
                    m.templateDate = parseInt(d.templateDate, 10);
                else if (typeof d.templateDate === "number")
                    m.templateDate = d.templateDate;
                else if (typeof d.templateDate === "object")
                    m.templateDate = new $util.LongBits(d.templateDate.low >>> 0, d.templateDate.high >>> 0).toNumber();
            }
            if (d.admin != null) {
                m.admin = Boolean(d.admin);
            }
            if (d.topicCreatable != null) {
                m.topicCreatable = Boolean(d.topicCreatable);
            }
            if (d.exclusiveGroupList) {
                if (!Array.isArray(d.exclusiveGroupList))
                    throw TypeError(".Proto.CommunityBoardForum.exclusiveGroupList: array expected");
                m.exclusiveGroupList = [];
                for (var i = 0; i < d.exclusiveGroupList.length; ++i) {
                    if (typeof d.exclusiveGroupList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardForum.exclusiveGroupList: object expected");
                    m.exclusiveGroupList[i] = $root.Proto.CompanyUserGroup.fromObject(d.exclusiveGroupList[i]);
                }
            }
            if (d.useComment != null) {
                m.useComment = Boolean(d.useComment);
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardForum message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardForum
         * @static
         * @param {Proto.CommunityBoardForum} m CommunityBoardForum
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardForum.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.policyList = [];
                d.itemList = [];
                d.topicPolicyGroupList = [];
                d.exclusiveGroupList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.boardUID = "";
                d.title = "";
                d.text = "";
                d.topicPolicy = 0;
                d.commentPolicy = 0;
                d.commentCreatePolicy = 0;
                d.commentVisible = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.unreadCount = "";
                d.topicScope = 0;
                d.commentCreatable = false;
                d.topicMapPolicy = 0;
                d.commentMapPolicy = 0;
                d.topicVideoPolicy = 0;
                d.commentVideoPolicy = 0;
                d.topicPreview = false;
                d.topicTitle = "";
                d.commentTitle = "";
                d.policyCount = null;
                d.showGroup = false;
                d.image = null;
                d.useTemplate = false;
                d.topicPolicyGroupCount = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.templateDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.templateDate = o.longs === String ? "0" : 0;
                d.admin = false;
                d.topicCreatable = false;
                d.useComment = false;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.boardUID != null && m.hasOwnProperty("boardUID")) {
                d.boardUID = m.boardUID;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.topicPolicy != null && m.hasOwnProperty("topicPolicy")) {
                d.topicPolicy = m.topicPolicy;
            }
            if (m.commentPolicy != null && m.hasOwnProperty("commentPolicy")) {
                d.commentPolicy = m.commentPolicy;
            }
            if (m.commentCreatePolicy != null && m.hasOwnProperty("commentCreatePolicy")) {
                d.commentCreatePolicy = m.commentCreatePolicy;
            }
            if (m.commentVisible != null && m.hasOwnProperty("commentVisible")) {
                d.commentVisible = m.commentVisible;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.unreadCount != null && m.hasOwnProperty("unreadCount")) {
                d.unreadCount = m.unreadCount;
            }
            if (m.topicScope != null && m.hasOwnProperty("topicScope")) {
                d.topicScope = m.topicScope;
            }
            if (m.commentCreatable != null && m.hasOwnProperty("commentCreatable")) {
                d.commentCreatable = m.commentCreatable;
            }
            if (m.topicMapPolicy != null && m.hasOwnProperty("topicMapPolicy")) {
                d.topicMapPolicy = m.topicMapPolicy;
            }
            if (m.commentMapPolicy != null && m.hasOwnProperty("commentMapPolicy")) {
                d.commentMapPolicy = m.commentMapPolicy;
            }
            if (m.topicVideoPolicy != null && m.hasOwnProperty("topicVideoPolicy")) {
                d.topicVideoPolicy = m.topicVideoPolicy;
            }
            if (m.commentVideoPolicy != null && m.hasOwnProperty("commentVideoPolicy")) {
                d.commentVideoPolicy = m.commentVideoPolicy;
            }
            if (m.policyList && m.policyList.length) {
                d.policyList = [];
                for (var j = 0; j < m.policyList.length; ++j) {
                    d.policyList[j] = $root.Proto.CommunityBoardForumPolicy.toObject(m.policyList[j], o);
                }
            }
            if (m.topicPreview != null && m.hasOwnProperty("topicPreview")) {
                d.topicPreview = m.topicPreview;
            }
            if (m.topicTitle != null && m.hasOwnProperty("topicTitle")) {
                d.topicTitle = m.topicTitle;
            }
            if (m.commentTitle != null && m.hasOwnProperty("commentTitle")) {
                d.commentTitle = m.commentTitle;
            }
            if (m.policyCount != null && m.hasOwnProperty("policyCount")) {
                d.policyCount = $root.Proto.ForumPolicyGroupCount.toObject(m.policyCount, o);
            }
            if (m.showGroup != null && m.hasOwnProperty("showGroup")) {
                d.showGroup = m.showGroup;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.itemList && m.itemList.length) {
                d.itemList = [];
                for (var j = 0; j < m.itemList.length; ++j) {
                    d.itemList[j] = $root.Proto.CommunityBoardForumItem.toObject(m.itemList[j], o);
                }
            }
            if (m.useTemplate != null && m.hasOwnProperty("useTemplate")) {
                d.useTemplate = m.useTemplate;
            }
            if (m.topicPolicyGroupCount != null && m.hasOwnProperty("topicPolicyGroupCount")) {
                d.topicPolicyGroupCount = m.topicPolicyGroupCount;
            }
            if (m.topicPolicyGroupList && m.topicPolicyGroupList.length) {
                d.topicPolicyGroupList = [];
                for (var j = 0; j < m.topicPolicyGroupList.length; ++j) {
                    d.topicPolicyGroupList[j] = $root.Proto.CompanyUserGroup.toObject(m.topicPolicyGroupList[j], o);
                }
            }
            if (m.templateDate != null && m.hasOwnProperty("templateDate")) {
                if (typeof m.templateDate === "number")
                    d.templateDate = o.longs === String ? String(m.templateDate) : m.templateDate;
                else
                    d.templateDate = o.longs === String ? $util.Long.prototype.toString.call(m.templateDate) : o.longs === Number ? new $util.LongBits(m.templateDate.low >>> 0, m.templateDate.high >>> 0).toNumber() : m.templateDate;
            }
            if (m.admin != null && m.hasOwnProperty("admin")) {
                d.admin = m.admin;
            }
            if (m.topicCreatable != null && m.hasOwnProperty("topicCreatable")) {
                d.topicCreatable = m.topicCreatable;
            }
            if (m.exclusiveGroupList && m.exclusiveGroupList.length) {
                d.exclusiveGroupList = [];
                for (var j = 0; j < m.exclusiveGroupList.length; ++j) {
                    d.exclusiveGroupList[j] = $root.Proto.CompanyUserGroup.toObject(m.exclusiveGroupList[j], o);
                }
            }
            if (m.useComment != null && m.hasOwnProperty("useComment")) {
                d.useComment = m.useComment;
            }
            return d;
        };

        /**
         * Converts this CommunityBoardForum to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardForum
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardForum.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardForum;
    })();

    Proto.CommunityBoardForumList = (function() {

        /**
         * Properties of a CommunityBoardForumList.
         * @memberof Proto
         * @interface ICommunityBoardForumList
         * @property {Array.<Proto.ICommunityBoardForum>|null} [value] CommunityBoardForumList value
         */

        /**
         * Constructs a new CommunityBoardForumList.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardForumList.
         * @implements ICommunityBoardForumList
         * @constructor
         * @param {Proto.ICommunityBoardForumList=} [p] Properties to set
         */
        function CommunityBoardForumList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardForumList value.
         * @member {Array.<Proto.ICommunityBoardForum>} value
         * @memberof Proto.CommunityBoardForumList
         * @instance
         */
        CommunityBoardForumList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CommunityBoardForumList instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardForumList
         * @static
         * @param {Proto.ICommunityBoardForumList=} [properties] Properties to set
         * @returns {Proto.CommunityBoardForumList} CommunityBoardForumList instance
         */
        CommunityBoardForumList.create = function create(properties) {
            return new CommunityBoardForumList(properties);
        };

        /**
         * Encodes the specified CommunityBoardForumList message. Does not implicitly {@link Proto.CommunityBoardForumList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardForumList
         * @static
         * @param {Proto.ICommunityBoardForumList} m CommunityBoardForumList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardForumList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CommunityBoardForum.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CommunityBoardForumList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardForumList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardForumList} CommunityBoardForumList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardForumList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardForumList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CommunityBoardForum.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardForumList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardForumList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardForumList} CommunityBoardForumList
         */
        CommunityBoardForumList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardForumList)
                return d;
            var m = new $root.Proto.CommunityBoardForumList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CommunityBoardForumList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardForumList.value: object expected");
                    m.value[i] = $root.Proto.CommunityBoardForum.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardForumList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardForumList
         * @static
         * @param {Proto.CommunityBoardForumList} m CommunityBoardForumList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardForumList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CommunityBoardForum.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CommunityBoardForumList to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardForumList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardForumList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardForumList;
    })();

    Proto.CommunityBoardTopic = (function() {

        /**
         * Properties of a CommunityBoardTopic.
         * @memberof Proto
         * @interface ICommunityBoardTopic
         * @property {string|null} [uid] CommunityBoardTopic uid
         * @property {string|null} [boardUID] CommunityBoardTopic boardUID
         * @property {string|null} [userUID] CommunityBoardTopic userUID
         * @property {string|null} [title] CommunityBoardTopic title
         * @property {string|null} [text] CommunityBoardTopic text
         * @property {string|null} [commentCount] CommunityBoardTopic commentCount
         * @property {string|null} [unreadCount] CommunityBoardTopic unreadCount
         * @property {number|Long|null} [registerDate] CommunityBoardTopic registerDate
         * @property {number|Long|null} [modifyDate] CommunityBoardTopic modifyDate
         * @property {Proto.IUploadFile|null} [userIcon] CommunityBoardTopic userIcon
         * @property {string|null} [userName] CommunityBoardTopic userName
         * @property {boolean|null} [owner] CommunityBoardTopic owner
         * @property {Array.<Proto.IUploadFile>|null} [fileList] CommunityBoardTopic fileList
         * @property {number|null} [policy] CommunityBoardTopic policy
         * @property {Array.<Proto.IHashTag>|null} [tagList] CommunityBoardTopic tagList
         * @property {string|null} [companyUID] CommunityBoardTopic companyUID
         * @property {Proto.ICommunityBoardComment|null} [recentComment] CommunityBoardTopic recentComment
         * @property {string|null} [likeCount] CommunityBoardTopic likeCount
         * @property {boolean|null} [liked] CommunityBoardTopic liked
         * @property {boolean|null} [policyAll] CommunityBoardTopic policyAll
         * @property {Array.<Proto.ICommunityBoardTopicPolicy>|null} [policyList] CommunityBoardTopic policyList
         * @property {Proto.ICommunityBoardPoll|null} [poll] CommunityBoardTopic poll
         * @property {Array.<Proto.ICommunityBoardMapGroup>|null} [mapGroupList] CommunityBoardTopic mapGroupList
         * @property {boolean|null} [blocked] CommunityBoardTopic blocked
         * @property {string|null} [readCount] CommunityBoardTopic readCount
         * @property {string|null} [reactionCount] CommunityBoardTopic reactionCount
         * @property {Array.<Proto.ICommunityBoardTopicReaction>|null} [reactionGroupList] CommunityBoardTopic reactionGroupList
         * @property {boolean|null} [read] CommunityBoardTopic read
         * @property {string|null} [mapGroupCount] CommunityBoardTopic mapGroupCount
         * @property {Array.<Proto.IUser>|null} [readUserList] CommunityBoardTopic readUserList
         * @property {string|null} [forumUID] CommunityBoardTopic forumUID
         * @property {boolean|null} [preview] CommunityBoardTopic preview
         * @property {string|null} [forumTitle] CommunityBoardTopic forumTitle
         * @property {Array.<Proto.ICommunityBoardTopicItem>|null} [itemList] CommunityBoardTopic itemList
         * @property {boolean|null} [mustRead] CommunityBoardTopic mustRead
         * @property {boolean|null} [bookmarked] CommunityBoardTopic bookmarked
         * @property {number|Long|null} [templateDate] CommunityBoardTopic templateDate
         * @property {number|null} [textVersion] CommunityBoardTopic textVersion
         */

        /**
         * Constructs a new CommunityBoardTopic.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardTopic.
         * @implements ICommunityBoardTopic
         * @constructor
         * @param {Proto.ICommunityBoardTopic=} [p] Properties to set
         */
        function CommunityBoardTopic(p) {
            this.fileList = [];
            this.tagList = [];
            this.policyList = [];
            this.mapGroupList = [];
            this.reactionGroupList = [];
            this.readUserList = [];
            this.itemList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardTopic uid.
         * @member {string} uid
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.uid = "";

        /**
         * CommunityBoardTopic boardUID.
         * @member {string} boardUID
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.boardUID = "";

        /**
         * CommunityBoardTopic userUID.
         * @member {string} userUID
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.userUID = "";

        /**
         * CommunityBoardTopic title.
         * @member {string} title
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.title = "";

        /**
         * CommunityBoardTopic text.
         * @member {string} text
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.text = "";

        /**
         * CommunityBoardTopic commentCount.
         * @member {string} commentCount
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.commentCount = "";

        /**
         * CommunityBoardTopic unreadCount.
         * @member {string} unreadCount
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.unreadCount = "";

        /**
         * CommunityBoardTopic registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardTopic modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardTopic userIcon.
         * @member {Proto.IUploadFile|null|undefined} userIcon
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.userIcon = null;

        /**
         * CommunityBoardTopic userName.
         * @member {string} userName
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.userName = "";

        /**
         * CommunityBoardTopic owner.
         * @member {boolean} owner
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.owner = false;

        /**
         * CommunityBoardTopic fileList.
         * @member {Array.<Proto.IUploadFile>} fileList
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.fileList = $util.emptyArray;

        /**
         * CommunityBoardTopic policy.
         * @member {number} policy
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.policy = 0;

        /**
         * CommunityBoardTopic tagList.
         * @member {Array.<Proto.IHashTag>} tagList
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.tagList = $util.emptyArray;

        /**
         * CommunityBoardTopic companyUID.
         * @member {string} companyUID
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.companyUID = "";

        /**
         * CommunityBoardTopic recentComment.
         * @member {Proto.ICommunityBoardComment|null|undefined} recentComment
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.recentComment = null;

        /**
         * CommunityBoardTopic likeCount.
         * @member {string} likeCount
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.likeCount = "";

        /**
         * CommunityBoardTopic liked.
         * @member {boolean} liked
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.liked = false;

        /**
         * CommunityBoardTopic policyAll.
         * @member {boolean} policyAll
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.policyAll = false;

        /**
         * CommunityBoardTopic policyList.
         * @member {Array.<Proto.ICommunityBoardTopicPolicy>} policyList
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.policyList = $util.emptyArray;

        /**
         * CommunityBoardTopic poll.
         * @member {Proto.ICommunityBoardPoll|null|undefined} poll
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.poll = null;

        /**
         * CommunityBoardTopic mapGroupList.
         * @member {Array.<Proto.ICommunityBoardMapGroup>} mapGroupList
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.mapGroupList = $util.emptyArray;

        /**
         * CommunityBoardTopic blocked.
         * @member {boolean} blocked
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.blocked = false;

        /**
         * CommunityBoardTopic readCount.
         * @member {string} readCount
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.readCount = "";

        /**
         * CommunityBoardTopic reactionCount.
         * @member {string} reactionCount
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.reactionCount = "";

        /**
         * CommunityBoardTopic reactionGroupList.
         * @member {Array.<Proto.ICommunityBoardTopicReaction>} reactionGroupList
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.reactionGroupList = $util.emptyArray;

        /**
         * CommunityBoardTopic read.
         * @member {boolean} read
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.read = false;

        /**
         * CommunityBoardTopic mapGroupCount.
         * @member {string} mapGroupCount
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.mapGroupCount = "";

        /**
         * CommunityBoardTopic readUserList.
         * @member {Array.<Proto.IUser>} readUserList
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.readUserList = $util.emptyArray;

        /**
         * CommunityBoardTopic forumUID.
         * @member {string} forumUID
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.forumUID = "";

        /**
         * CommunityBoardTopic preview.
         * @member {boolean} preview
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.preview = false;

        /**
         * CommunityBoardTopic forumTitle.
         * @member {string} forumTitle
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.forumTitle = "";

        /**
         * CommunityBoardTopic itemList.
         * @member {Array.<Proto.ICommunityBoardTopicItem>} itemList
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.itemList = $util.emptyArray;

        /**
         * CommunityBoardTopic mustRead.
         * @member {boolean} mustRead
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.mustRead = false;

        /**
         * CommunityBoardTopic bookmarked.
         * @member {boolean} bookmarked
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.bookmarked = false;

        /**
         * CommunityBoardTopic templateDate.
         * @member {number|Long} templateDate
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.templateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardTopic textVersion.
         * @member {number} textVersion
         * @memberof Proto.CommunityBoardTopic
         * @instance
         */
        CommunityBoardTopic.prototype.textVersion = 0;

        /**
         * Creates a new CommunityBoardTopic instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardTopic
         * @static
         * @param {Proto.ICommunityBoardTopic=} [properties] Properties to set
         * @returns {Proto.CommunityBoardTopic} CommunityBoardTopic instance
         */
        CommunityBoardTopic.create = function create(properties) {
            return new CommunityBoardTopic(properties);
        };

        /**
         * Encodes the specified CommunityBoardTopic message. Does not implicitly {@link Proto.CommunityBoardTopic.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardTopic
         * @static
         * @param {Proto.ICommunityBoardTopic} m CommunityBoardTopic message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardTopic.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.boardUID != null && Object.hasOwnProperty.call(m, "boardUID"))
                w.uint32(18).string(m.boardUID);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(26).string(m.userUID);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(34).string(m.title);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(42).string(m.text);
            if (m.commentCount != null && Object.hasOwnProperty.call(m, "commentCount"))
                w.uint32(50).string(m.commentCount);
            if (m.unreadCount != null && Object.hasOwnProperty.call(m, "unreadCount"))
                w.uint32(58).string(m.unreadCount);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            if (m.userIcon != null && Object.hasOwnProperty.call(m, "userIcon"))
                $root.Proto.UploadFile.encode(m.userIcon, w.uint32(82).fork()).ldelim();
            if (m.userName != null && Object.hasOwnProperty.call(m, "userName"))
                w.uint32(90).string(m.userName);
            if (m.owner != null && Object.hasOwnProperty.call(m, "owner"))
                w.uint32(96).bool(m.owner);
            if (m.fileList != null && m.fileList.length) {
                for (var i = 0; i < m.fileList.length; ++i)
                    $root.Proto.UploadFile.encode(m.fileList[i], w.uint32(106).fork()).ldelim();
            }
            if (m.policy != null && Object.hasOwnProperty.call(m, "policy"))
                w.uint32(112).int32(m.policy);
            if (m.tagList != null && m.tagList.length) {
                for (var i = 0; i < m.tagList.length; ++i)
                    $root.Proto.HashTag.encode(m.tagList[i], w.uint32(122).fork()).ldelim();
            }
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(130).string(m.companyUID);
            if (m.recentComment != null && Object.hasOwnProperty.call(m, "recentComment"))
                $root.Proto.CommunityBoardComment.encode(m.recentComment, w.uint32(138).fork()).ldelim();
            if (m.likeCount != null && Object.hasOwnProperty.call(m, "likeCount"))
                w.uint32(146).string(m.likeCount);
            if (m.liked != null && Object.hasOwnProperty.call(m, "liked"))
                w.uint32(152).bool(m.liked);
            if (m.policyAll != null && Object.hasOwnProperty.call(m, "policyAll"))
                w.uint32(160).bool(m.policyAll);
            if (m.policyList != null && m.policyList.length) {
                for (var i = 0; i < m.policyList.length; ++i)
                    $root.Proto.CommunityBoardTopicPolicy.encode(m.policyList[i], w.uint32(170).fork()).ldelim();
            }
            if (m.poll != null && Object.hasOwnProperty.call(m, "poll"))
                $root.Proto.CommunityBoardPoll.encode(m.poll, w.uint32(178).fork()).ldelim();
            if (m.mapGroupList != null && m.mapGroupList.length) {
                for (var i = 0; i < m.mapGroupList.length; ++i)
                    $root.Proto.CommunityBoardMapGroup.encode(m.mapGroupList[i], w.uint32(186).fork()).ldelim();
            }
            if (m.blocked != null && Object.hasOwnProperty.call(m, "blocked"))
                w.uint32(192).bool(m.blocked);
            if (m.readCount != null && Object.hasOwnProperty.call(m, "readCount"))
                w.uint32(202).string(m.readCount);
            if (m.reactionCount != null && Object.hasOwnProperty.call(m, "reactionCount"))
                w.uint32(210).string(m.reactionCount);
            if (m.reactionGroupList != null && m.reactionGroupList.length) {
                for (var i = 0; i < m.reactionGroupList.length; ++i)
                    $root.Proto.CommunityBoardTopicReaction.encode(m.reactionGroupList[i], w.uint32(218).fork()).ldelim();
            }
            if (m.read != null && Object.hasOwnProperty.call(m, "read"))
                w.uint32(224).bool(m.read);
            if (m.mapGroupCount != null && Object.hasOwnProperty.call(m, "mapGroupCount"))
                w.uint32(234).string(m.mapGroupCount);
            if (m.readUserList != null && m.readUserList.length) {
                for (var i = 0; i < m.readUserList.length; ++i)
                    $root.Proto.User.encode(m.readUserList[i], w.uint32(242).fork()).ldelim();
            }
            if (m.forumUID != null && Object.hasOwnProperty.call(m, "forumUID"))
                w.uint32(250).string(m.forumUID);
            if (m.preview != null && Object.hasOwnProperty.call(m, "preview"))
                w.uint32(256).bool(m.preview);
            if (m.forumTitle != null && Object.hasOwnProperty.call(m, "forumTitle"))
                w.uint32(266).string(m.forumTitle);
            if (m.itemList != null && m.itemList.length) {
                for (var i = 0; i < m.itemList.length; ++i)
                    $root.Proto.CommunityBoardTopicItem.encode(m.itemList[i], w.uint32(274).fork()).ldelim();
            }
            if (m.mustRead != null && Object.hasOwnProperty.call(m, "mustRead"))
                w.uint32(280).bool(m.mustRead);
            if (m.bookmarked != null && Object.hasOwnProperty.call(m, "bookmarked"))
                w.uint32(288).bool(m.bookmarked);
            if (m.templateDate != null && Object.hasOwnProperty.call(m, "templateDate"))
                w.uint32(296).int64(m.templateDate);
            if (m.textVersion != null && Object.hasOwnProperty.call(m, "textVersion"))
                w.uint32(304).int32(m.textVersion);
            return w;
        };

        /**
         * Decodes a CommunityBoardTopic message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardTopic
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardTopic} CommunityBoardTopic
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardTopic.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardTopic();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.boardUID = r.string();
                    break;
                case 3:
                    m.userUID = r.string();
                    break;
                case 4:
                    m.title = r.string();
                    break;
                case 5:
                    m.text = r.string();
                    break;
                case 6:
                    m.commentCount = r.string();
                    break;
                case 7:
                    m.unreadCount = r.string();
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                case 10:
                    m.userIcon = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 11:
                    m.userName = r.string();
                    break;
                case 12:
                    m.owner = r.bool();
                    break;
                case 13:
                    if (!(m.fileList && m.fileList.length))
                        m.fileList = [];
                    m.fileList.push($root.Proto.UploadFile.decode(r, r.uint32()));
                    break;
                case 14:
                    m.policy = r.int32();
                    break;
                case 15:
                    if (!(m.tagList && m.tagList.length))
                        m.tagList = [];
                    m.tagList.push($root.Proto.HashTag.decode(r, r.uint32()));
                    break;
                case 16:
                    m.companyUID = r.string();
                    break;
                case 17:
                    m.recentComment = $root.Proto.CommunityBoardComment.decode(r, r.uint32());
                    break;
                case 18:
                    m.likeCount = r.string();
                    break;
                case 19:
                    m.liked = r.bool();
                    break;
                case 20:
                    m.policyAll = r.bool();
                    break;
                case 21:
                    if (!(m.policyList && m.policyList.length))
                        m.policyList = [];
                    m.policyList.push($root.Proto.CommunityBoardTopicPolicy.decode(r, r.uint32()));
                    break;
                case 22:
                    m.poll = $root.Proto.CommunityBoardPoll.decode(r, r.uint32());
                    break;
                case 23:
                    if (!(m.mapGroupList && m.mapGroupList.length))
                        m.mapGroupList = [];
                    m.mapGroupList.push($root.Proto.CommunityBoardMapGroup.decode(r, r.uint32()));
                    break;
                case 24:
                    m.blocked = r.bool();
                    break;
                case 25:
                    m.readCount = r.string();
                    break;
                case 26:
                    m.reactionCount = r.string();
                    break;
                case 27:
                    if (!(m.reactionGroupList && m.reactionGroupList.length))
                        m.reactionGroupList = [];
                    m.reactionGroupList.push($root.Proto.CommunityBoardTopicReaction.decode(r, r.uint32()));
                    break;
                case 28:
                    m.read = r.bool();
                    break;
                case 29:
                    m.mapGroupCount = r.string();
                    break;
                case 30:
                    if (!(m.readUserList && m.readUserList.length))
                        m.readUserList = [];
                    m.readUserList.push($root.Proto.User.decode(r, r.uint32()));
                    break;
                case 31:
                    m.forumUID = r.string();
                    break;
                case 32:
                    m.preview = r.bool();
                    break;
                case 33:
                    m.forumTitle = r.string();
                    break;
                case 34:
                    if (!(m.itemList && m.itemList.length))
                        m.itemList = [];
                    m.itemList.push($root.Proto.CommunityBoardTopicItem.decode(r, r.uint32()));
                    break;
                case 35:
                    m.mustRead = r.bool();
                    break;
                case 36:
                    m.bookmarked = r.bool();
                    break;
                case 37:
                    m.templateDate = r.int64();
                    break;
                case 38:
                    m.textVersion = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardTopic message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardTopic
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardTopic} CommunityBoardTopic
         */
        CommunityBoardTopic.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardTopic)
                return d;
            var m = new $root.Proto.CommunityBoardTopic();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.boardUID != null) {
                m.boardUID = String(d.boardUID);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.commentCount != null) {
                m.commentCount = String(d.commentCount);
            }
            if (d.unreadCount != null) {
                m.unreadCount = String(d.unreadCount);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.userIcon != null) {
                if (typeof d.userIcon !== "object")
                    throw TypeError(".Proto.CommunityBoardTopic.userIcon: object expected");
                m.userIcon = $root.Proto.UploadFile.fromObject(d.userIcon);
            }
            if (d.userName != null) {
                m.userName = String(d.userName);
            }
            if (d.owner != null) {
                m.owner = Boolean(d.owner);
            }
            if (d.fileList) {
                if (!Array.isArray(d.fileList))
                    throw TypeError(".Proto.CommunityBoardTopic.fileList: array expected");
                m.fileList = [];
                for (var i = 0; i < d.fileList.length; ++i) {
                    if (typeof d.fileList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardTopic.fileList: object expected");
                    m.fileList[i] = $root.Proto.UploadFile.fromObject(d.fileList[i]);
                }
            }
            if (d.policy != null) {
                m.policy = d.policy | 0;
            }
            if (d.tagList) {
                if (!Array.isArray(d.tagList))
                    throw TypeError(".Proto.CommunityBoardTopic.tagList: array expected");
                m.tagList = [];
                for (var i = 0; i < d.tagList.length; ++i) {
                    if (typeof d.tagList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardTopic.tagList: object expected");
                    m.tagList[i] = $root.Proto.HashTag.fromObject(d.tagList[i]);
                }
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.recentComment != null) {
                if (typeof d.recentComment !== "object")
                    throw TypeError(".Proto.CommunityBoardTopic.recentComment: object expected");
                m.recentComment = $root.Proto.CommunityBoardComment.fromObject(d.recentComment);
            }
            if (d.likeCount != null) {
                m.likeCount = String(d.likeCount);
            }
            if (d.liked != null) {
                m.liked = Boolean(d.liked);
            }
            if (d.policyAll != null) {
                m.policyAll = Boolean(d.policyAll);
            }
            if (d.policyList) {
                if (!Array.isArray(d.policyList))
                    throw TypeError(".Proto.CommunityBoardTopic.policyList: array expected");
                m.policyList = [];
                for (var i = 0; i < d.policyList.length; ++i) {
                    if (typeof d.policyList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardTopic.policyList: object expected");
                    m.policyList[i] = $root.Proto.CommunityBoardTopicPolicy.fromObject(d.policyList[i]);
                }
            }
            if (d.poll != null) {
                if (typeof d.poll !== "object")
                    throw TypeError(".Proto.CommunityBoardTopic.poll: object expected");
                m.poll = $root.Proto.CommunityBoardPoll.fromObject(d.poll);
            }
            if (d.mapGroupList) {
                if (!Array.isArray(d.mapGroupList))
                    throw TypeError(".Proto.CommunityBoardTopic.mapGroupList: array expected");
                m.mapGroupList = [];
                for (var i = 0; i < d.mapGroupList.length; ++i) {
                    if (typeof d.mapGroupList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardTopic.mapGroupList: object expected");
                    m.mapGroupList[i] = $root.Proto.CommunityBoardMapGroup.fromObject(d.mapGroupList[i]);
                }
            }
            if (d.blocked != null) {
                m.blocked = Boolean(d.blocked);
            }
            if (d.readCount != null) {
                m.readCount = String(d.readCount);
            }
            if (d.reactionCount != null) {
                m.reactionCount = String(d.reactionCount);
            }
            if (d.reactionGroupList) {
                if (!Array.isArray(d.reactionGroupList))
                    throw TypeError(".Proto.CommunityBoardTopic.reactionGroupList: array expected");
                m.reactionGroupList = [];
                for (var i = 0; i < d.reactionGroupList.length; ++i) {
                    if (typeof d.reactionGroupList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardTopic.reactionGroupList: object expected");
                    m.reactionGroupList[i] = $root.Proto.CommunityBoardTopicReaction.fromObject(d.reactionGroupList[i]);
                }
            }
            if (d.read != null) {
                m.read = Boolean(d.read);
            }
            if (d.mapGroupCount != null) {
                m.mapGroupCount = String(d.mapGroupCount);
            }
            if (d.readUserList) {
                if (!Array.isArray(d.readUserList))
                    throw TypeError(".Proto.CommunityBoardTopic.readUserList: array expected");
                m.readUserList = [];
                for (var i = 0; i < d.readUserList.length; ++i) {
                    if (typeof d.readUserList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardTopic.readUserList: object expected");
                    m.readUserList[i] = $root.Proto.User.fromObject(d.readUserList[i]);
                }
            }
            if (d.forumUID != null) {
                m.forumUID = String(d.forumUID);
            }
            if (d.preview != null) {
                m.preview = Boolean(d.preview);
            }
            if (d.forumTitle != null) {
                m.forumTitle = String(d.forumTitle);
            }
            if (d.itemList) {
                if (!Array.isArray(d.itemList))
                    throw TypeError(".Proto.CommunityBoardTopic.itemList: array expected");
                m.itemList = [];
                for (var i = 0; i < d.itemList.length; ++i) {
                    if (typeof d.itemList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardTopic.itemList: object expected");
                    m.itemList[i] = $root.Proto.CommunityBoardTopicItem.fromObject(d.itemList[i]);
                }
            }
            if (d.mustRead != null) {
                m.mustRead = Boolean(d.mustRead);
            }
            if (d.bookmarked != null) {
                m.bookmarked = Boolean(d.bookmarked);
            }
            if (d.templateDate != null) {
                if ($util.Long)
                    (m.templateDate = $util.Long.fromValue(d.templateDate)).unsigned = false;
                else if (typeof d.templateDate === "string")
                    m.templateDate = parseInt(d.templateDate, 10);
                else if (typeof d.templateDate === "number")
                    m.templateDate = d.templateDate;
                else if (typeof d.templateDate === "object")
                    m.templateDate = new $util.LongBits(d.templateDate.low >>> 0, d.templateDate.high >>> 0).toNumber();
            }
            if (d.textVersion != null) {
                m.textVersion = d.textVersion | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardTopic message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardTopic
         * @static
         * @param {Proto.CommunityBoardTopic} m CommunityBoardTopic
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardTopic.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.fileList = [];
                d.tagList = [];
                d.policyList = [];
                d.mapGroupList = [];
                d.reactionGroupList = [];
                d.readUserList = [];
                d.itemList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.boardUID = "";
                d.userUID = "";
                d.title = "";
                d.text = "";
                d.commentCount = "";
                d.unreadCount = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.userIcon = null;
                d.userName = "";
                d.owner = false;
                d.policy = 0;
                d.companyUID = "";
                d.recentComment = null;
                d.likeCount = "";
                d.liked = false;
                d.policyAll = false;
                d.poll = null;
                d.blocked = false;
                d.readCount = "";
                d.reactionCount = "";
                d.read = false;
                d.mapGroupCount = "";
                d.forumUID = "";
                d.preview = false;
                d.forumTitle = "";
                d.mustRead = false;
                d.bookmarked = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.templateDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.templateDate = o.longs === String ? "0" : 0;
                d.textVersion = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.boardUID != null && m.hasOwnProperty("boardUID")) {
                d.boardUID = m.boardUID;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.commentCount != null && m.hasOwnProperty("commentCount")) {
                d.commentCount = m.commentCount;
            }
            if (m.unreadCount != null && m.hasOwnProperty("unreadCount")) {
                d.unreadCount = m.unreadCount;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.userIcon != null && m.hasOwnProperty("userIcon")) {
                d.userIcon = $root.Proto.UploadFile.toObject(m.userIcon, o);
            }
            if (m.userName != null && m.hasOwnProperty("userName")) {
                d.userName = m.userName;
            }
            if (m.owner != null && m.hasOwnProperty("owner")) {
                d.owner = m.owner;
            }
            if (m.fileList && m.fileList.length) {
                d.fileList = [];
                for (var j = 0; j < m.fileList.length; ++j) {
                    d.fileList[j] = $root.Proto.UploadFile.toObject(m.fileList[j], o);
                }
            }
            if (m.policy != null && m.hasOwnProperty("policy")) {
                d.policy = m.policy;
            }
            if (m.tagList && m.tagList.length) {
                d.tagList = [];
                for (var j = 0; j < m.tagList.length; ++j) {
                    d.tagList[j] = $root.Proto.HashTag.toObject(m.tagList[j], o);
                }
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.recentComment != null && m.hasOwnProperty("recentComment")) {
                d.recentComment = $root.Proto.CommunityBoardComment.toObject(m.recentComment, o);
            }
            if (m.likeCount != null && m.hasOwnProperty("likeCount")) {
                d.likeCount = m.likeCount;
            }
            if (m.liked != null && m.hasOwnProperty("liked")) {
                d.liked = m.liked;
            }
            if (m.policyAll != null && m.hasOwnProperty("policyAll")) {
                d.policyAll = m.policyAll;
            }
            if (m.policyList && m.policyList.length) {
                d.policyList = [];
                for (var j = 0; j < m.policyList.length; ++j) {
                    d.policyList[j] = $root.Proto.CommunityBoardTopicPolicy.toObject(m.policyList[j], o);
                }
            }
            if (m.poll != null && m.hasOwnProperty("poll")) {
                d.poll = $root.Proto.CommunityBoardPoll.toObject(m.poll, o);
            }
            if (m.mapGroupList && m.mapGroupList.length) {
                d.mapGroupList = [];
                for (var j = 0; j < m.mapGroupList.length; ++j) {
                    d.mapGroupList[j] = $root.Proto.CommunityBoardMapGroup.toObject(m.mapGroupList[j], o);
                }
            }
            if (m.blocked != null && m.hasOwnProperty("blocked")) {
                d.blocked = m.blocked;
            }
            if (m.readCount != null && m.hasOwnProperty("readCount")) {
                d.readCount = m.readCount;
            }
            if (m.reactionCount != null && m.hasOwnProperty("reactionCount")) {
                d.reactionCount = m.reactionCount;
            }
            if (m.reactionGroupList && m.reactionGroupList.length) {
                d.reactionGroupList = [];
                for (var j = 0; j < m.reactionGroupList.length; ++j) {
                    d.reactionGroupList[j] = $root.Proto.CommunityBoardTopicReaction.toObject(m.reactionGroupList[j], o);
                }
            }
            if (m.read != null && m.hasOwnProperty("read")) {
                d.read = m.read;
            }
            if (m.mapGroupCount != null && m.hasOwnProperty("mapGroupCount")) {
                d.mapGroupCount = m.mapGroupCount;
            }
            if (m.readUserList && m.readUserList.length) {
                d.readUserList = [];
                for (var j = 0; j < m.readUserList.length; ++j) {
                    d.readUserList[j] = $root.Proto.User.toObject(m.readUserList[j], o);
                }
            }
            if (m.forumUID != null && m.hasOwnProperty("forumUID")) {
                d.forumUID = m.forumUID;
            }
            if (m.preview != null && m.hasOwnProperty("preview")) {
                d.preview = m.preview;
            }
            if (m.forumTitle != null && m.hasOwnProperty("forumTitle")) {
                d.forumTitle = m.forumTitle;
            }
            if (m.itemList && m.itemList.length) {
                d.itemList = [];
                for (var j = 0; j < m.itemList.length; ++j) {
                    d.itemList[j] = $root.Proto.CommunityBoardTopicItem.toObject(m.itemList[j], o);
                }
            }
            if (m.mustRead != null && m.hasOwnProperty("mustRead")) {
                d.mustRead = m.mustRead;
            }
            if (m.bookmarked != null && m.hasOwnProperty("bookmarked")) {
                d.bookmarked = m.bookmarked;
            }
            if (m.templateDate != null && m.hasOwnProperty("templateDate")) {
                if (typeof m.templateDate === "number")
                    d.templateDate = o.longs === String ? String(m.templateDate) : m.templateDate;
                else
                    d.templateDate = o.longs === String ? $util.Long.prototype.toString.call(m.templateDate) : o.longs === Number ? new $util.LongBits(m.templateDate.low >>> 0, m.templateDate.high >>> 0).toNumber() : m.templateDate;
            }
            if (m.textVersion != null && m.hasOwnProperty("textVersion")) {
                d.textVersion = m.textVersion;
            }
            return d;
        };

        /**
         * Converts this CommunityBoardTopic to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardTopic
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardTopic.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardTopic;
    })();

    Proto.CommunityBoardTopicList = (function() {

        /**
         * Properties of a CommunityBoardTopicList.
         * @memberof Proto
         * @interface ICommunityBoardTopicList
         * @property {Array.<Proto.ICommunityBoardTopic>|null} [value] CommunityBoardTopicList value
         */

        /**
         * Constructs a new CommunityBoardTopicList.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardTopicList.
         * @implements ICommunityBoardTopicList
         * @constructor
         * @param {Proto.ICommunityBoardTopicList=} [p] Properties to set
         */
        function CommunityBoardTopicList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardTopicList value.
         * @member {Array.<Proto.ICommunityBoardTopic>} value
         * @memberof Proto.CommunityBoardTopicList
         * @instance
         */
        CommunityBoardTopicList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CommunityBoardTopicList instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardTopicList
         * @static
         * @param {Proto.ICommunityBoardTopicList=} [properties] Properties to set
         * @returns {Proto.CommunityBoardTopicList} CommunityBoardTopicList instance
         */
        CommunityBoardTopicList.create = function create(properties) {
            return new CommunityBoardTopicList(properties);
        };

        /**
         * Encodes the specified CommunityBoardTopicList message. Does not implicitly {@link Proto.CommunityBoardTopicList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardTopicList
         * @static
         * @param {Proto.ICommunityBoardTopicList} m CommunityBoardTopicList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardTopicList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CommunityBoardTopic.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CommunityBoardTopicList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardTopicList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardTopicList} CommunityBoardTopicList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardTopicList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardTopicList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CommunityBoardTopic.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardTopicList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardTopicList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardTopicList} CommunityBoardTopicList
         */
        CommunityBoardTopicList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardTopicList)
                return d;
            var m = new $root.Proto.CommunityBoardTopicList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CommunityBoardTopicList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardTopicList.value: object expected");
                    m.value[i] = $root.Proto.CommunityBoardTopic.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardTopicList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardTopicList
         * @static
         * @param {Proto.CommunityBoardTopicList} m CommunityBoardTopicList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardTopicList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CommunityBoardTopic.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CommunityBoardTopicList to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardTopicList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardTopicList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardTopicList;
    })();

    Proto.CommunityBoardTopicReaction = (function() {

        /**
         * Properties of a CommunityBoardTopicReaction.
         * @memberof Proto
         * @interface ICommunityBoardTopicReaction
         * @property {string|null} [reactionUID] CommunityBoardTopicReaction reactionUID
         * @property {string|null} [reactionCount] CommunityBoardTopicReaction reactionCount
         * @property {number|Long|null} [registerDate] CommunityBoardTopicReaction registerDate
         * @property {number|Long|null} [modifyDate] CommunityBoardTopicReaction modifyDate
         * @property {string|null} [topicUID] CommunityBoardTopicReaction topicUID
         */

        /**
         * Constructs a new CommunityBoardTopicReaction.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardTopicReaction.
         * @implements ICommunityBoardTopicReaction
         * @constructor
         * @param {Proto.ICommunityBoardTopicReaction=} [p] Properties to set
         */
        function CommunityBoardTopicReaction(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardTopicReaction reactionUID.
         * @member {string} reactionUID
         * @memberof Proto.CommunityBoardTopicReaction
         * @instance
         */
        CommunityBoardTopicReaction.prototype.reactionUID = "";

        /**
         * CommunityBoardTopicReaction reactionCount.
         * @member {string} reactionCount
         * @memberof Proto.CommunityBoardTopicReaction
         * @instance
         */
        CommunityBoardTopicReaction.prototype.reactionCount = "";

        /**
         * CommunityBoardTopicReaction registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommunityBoardTopicReaction
         * @instance
         */
        CommunityBoardTopicReaction.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardTopicReaction modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommunityBoardTopicReaction
         * @instance
         */
        CommunityBoardTopicReaction.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardTopicReaction topicUID.
         * @member {string} topicUID
         * @memberof Proto.CommunityBoardTopicReaction
         * @instance
         */
        CommunityBoardTopicReaction.prototype.topicUID = "";

        /**
         * Creates a new CommunityBoardTopicReaction instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardTopicReaction
         * @static
         * @param {Proto.ICommunityBoardTopicReaction=} [properties] Properties to set
         * @returns {Proto.CommunityBoardTopicReaction} CommunityBoardTopicReaction instance
         */
        CommunityBoardTopicReaction.create = function create(properties) {
            return new CommunityBoardTopicReaction(properties);
        };

        /**
         * Encodes the specified CommunityBoardTopicReaction message. Does not implicitly {@link Proto.CommunityBoardTopicReaction.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardTopicReaction
         * @static
         * @param {Proto.ICommunityBoardTopicReaction} m CommunityBoardTopicReaction message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardTopicReaction.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.reactionUID != null && Object.hasOwnProperty.call(m, "reactionUID"))
                w.uint32(10).string(m.reactionUID);
            if (m.reactionCount != null && Object.hasOwnProperty.call(m, "reactionCount"))
                w.uint32(18).string(m.reactionCount);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(32).int64(m.modifyDate);
            if (m.topicUID != null && Object.hasOwnProperty.call(m, "topicUID"))
                w.uint32(42).string(m.topicUID);
            return w;
        };

        /**
         * Decodes a CommunityBoardTopicReaction message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardTopicReaction
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardTopicReaction} CommunityBoardTopicReaction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardTopicReaction.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardTopicReaction();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.reactionUID = r.string();
                    break;
                case 2:
                    m.reactionCount = r.string();
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    m.modifyDate = r.int64();
                    break;
                case 5:
                    m.topicUID = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardTopicReaction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardTopicReaction
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardTopicReaction} CommunityBoardTopicReaction
         */
        CommunityBoardTopicReaction.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardTopicReaction)
                return d;
            var m = new $root.Proto.CommunityBoardTopicReaction();
            if (d.reactionUID != null) {
                m.reactionUID = String(d.reactionUID);
            }
            if (d.reactionCount != null) {
                m.reactionCount = String(d.reactionCount);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.topicUID != null) {
                m.topicUID = String(d.topicUID);
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardTopicReaction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardTopicReaction
         * @static
         * @param {Proto.CommunityBoardTopicReaction} m CommunityBoardTopicReaction
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardTopicReaction.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.reactionUID = "";
                d.reactionCount = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.topicUID = "";
            }
            if (m.reactionUID != null && m.hasOwnProperty("reactionUID")) {
                d.reactionUID = m.reactionUID;
            }
            if (m.reactionCount != null && m.hasOwnProperty("reactionCount")) {
                d.reactionCount = m.reactionCount;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.topicUID != null && m.hasOwnProperty("topicUID")) {
                d.topicUID = m.topicUID;
            }
            return d;
        };

        /**
         * Converts this CommunityBoardTopicReaction to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardTopicReaction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardTopicReaction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardTopicReaction;
    })();

    Proto.CommunityBoardTopicReactionList = (function() {

        /**
         * Properties of a CommunityBoardTopicReactionList.
         * @memberof Proto
         * @interface ICommunityBoardTopicReactionList
         * @property {Array.<Proto.ICommunityBoardTopicReaction>|null} [value] CommunityBoardTopicReactionList value
         */

        /**
         * Constructs a new CommunityBoardTopicReactionList.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardTopicReactionList.
         * @implements ICommunityBoardTopicReactionList
         * @constructor
         * @param {Proto.ICommunityBoardTopicReactionList=} [p] Properties to set
         */
        function CommunityBoardTopicReactionList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardTopicReactionList value.
         * @member {Array.<Proto.ICommunityBoardTopicReaction>} value
         * @memberof Proto.CommunityBoardTopicReactionList
         * @instance
         */
        CommunityBoardTopicReactionList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CommunityBoardTopicReactionList instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardTopicReactionList
         * @static
         * @param {Proto.ICommunityBoardTopicReactionList=} [properties] Properties to set
         * @returns {Proto.CommunityBoardTopicReactionList} CommunityBoardTopicReactionList instance
         */
        CommunityBoardTopicReactionList.create = function create(properties) {
            return new CommunityBoardTopicReactionList(properties);
        };

        /**
         * Encodes the specified CommunityBoardTopicReactionList message. Does not implicitly {@link Proto.CommunityBoardTopicReactionList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardTopicReactionList
         * @static
         * @param {Proto.ICommunityBoardTopicReactionList} m CommunityBoardTopicReactionList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardTopicReactionList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CommunityBoardTopicReaction.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CommunityBoardTopicReactionList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardTopicReactionList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardTopicReactionList} CommunityBoardTopicReactionList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardTopicReactionList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardTopicReactionList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CommunityBoardTopicReaction.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardTopicReactionList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardTopicReactionList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardTopicReactionList} CommunityBoardTopicReactionList
         */
        CommunityBoardTopicReactionList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardTopicReactionList)
                return d;
            var m = new $root.Proto.CommunityBoardTopicReactionList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CommunityBoardTopicReactionList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardTopicReactionList.value: object expected");
                    m.value[i] = $root.Proto.CommunityBoardTopicReaction.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardTopicReactionList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardTopicReactionList
         * @static
         * @param {Proto.CommunityBoardTopicReactionList} m CommunityBoardTopicReactionList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardTopicReactionList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CommunityBoardTopicReaction.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CommunityBoardTopicReactionList to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardTopicReactionList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardTopicReactionList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardTopicReactionList;
    })();

    Proto.CommunityBoardTopicPolicy = (function() {

        /**
         * Properties of a CommunityBoardTopicPolicy.
         * @memberof Proto
         * @interface ICommunityBoardTopicPolicy
         * @property {string|null} [planUID] CommunityBoardTopicPolicy planUID
         * @property {string|null} [groupUID] CommunityBoardTopicPolicy groupUID
         * @property {boolean|null} [active] CommunityBoardTopicPolicy active
         * @property {number|Long|null} [registerDate] CommunityBoardTopicPolicy registerDate
         * @property {number|Long|null} [modifyDate] CommunityBoardTopicPolicy modifyDate
         * @property {number|null} [type] CommunityBoardTopicPolicy type
         */

        /**
         * Constructs a new CommunityBoardTopicPolicy.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardTopicPolicy.
         * @implements ICommunityBoardTopicPolicy
         * @constructor
         * @param {Proto.ICommunityBoardTopicPolicy=} [p] Properties to set
         */
        function CommunityBoardTopicPolicy(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardTopicPolicy planUID.
         * @member {string} planUID
         * @memberof Proto.CommunityBoardTopicPolicy
         * @instance
         */
        CommunityBoardTopicPolicy.prototype.planUID = "";

        /**
         * CommunityBoardTopicPolicy groupUID.
         * @member {string} groupUID
         * @memberof Proto.CommunityBoardTopicPolicy
         * @instance
         */
        CommunityBoardTopicPolicy.prototype.groupUID = "";

        /**
         * CommunityBoardTopicPolicy active.
         * @member {boolean} active
         * @memberof Proto.CommunityBoardTopicPolicy
         * @instance
         */
        CommunityBoardTopicPolicy.prototype.active = false;

        /**
         * CommunityBoardTopicPolicy registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommunityBoardTopicPolicy
         * @instance
         */
        CommunityBoardTopicPolicy.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardTopicPolicy modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommunityBoardTopicPolicy
         * @instance
         */
        CommunityBoardTopicPolicy.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardTopicPolicy type.
         * @member {number} type
         * @memberof Proto.CommunityBoardTopicPolicy
         * @instance
         */
        CommunityBoardTopicPolicy.prototype.type = 0;

        /**
         * Creates a new CommunityBoardTopicPolicy instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardTopicPolicy
         * @static
         * @param {Proto.ICommunityBoardTopicPolicy=} [properties] Properties to set
         * @returns {Proto.CommunityBoardTopicPolicy} CommunityBoardTopicPolicy instance
         */
        CommunityBoardTopicPolicy.create = function create(properties) {
            return new CommunityBoardTopicPolicy(properties);
        };

        /**
         * Encodes the specified CommunityBoardTopicPolicy message. Does not implicitly {@link Proto.CommunityBoardTopicPolicy.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardTopicPolicy
         * @static
         * @param {Proto.ICommunityBoardTopicPolicy} m CommunityBoardTopicPolicy message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardTopicPolicy.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.planUID != null && Object.hasOwnProperty.call(m, "planUID"))
                w.uint32(10).string(m.planUID);
            if (m.groupUID != null && Object.hasOwnProperty.call(m, "groupUID"))
                w.uint32(18).string(m.groupUID);
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(24).bool(m.active);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(48).int32(m.type);
            return w;
        };

        /**
         * Decodes a CommunityBoardTopicPolicy message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardTopicPolicy
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardTopicPolicy} CommunityBoardTopicPolicy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardTopicPolicy.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardTopicPolicy();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.planUID = r.string();
                    break;
                case 2:
                    m.groupUID = r.string();
                    break;
                case 3:
                    m.active = r.bool();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                case 6:
                    m.type = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardTopicPolicy message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardTopicPolicy
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardTopicPolicy} CommunityBoardTopicPolicy
         */
        CommunityBoardTopicPolicy.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardTopicPolicy)
                return d;
            var m = new $root.Proto.CommunityBoardTopicPolicy();
            if (d.planUID != null) {
                m.planUID = String(d.planUID);
            }
            if (d.groupUID != null) {
                m.groupUID = String(d.groupUID);
            }
            if (d.active != null) {
                m.active = Boolean(d.active);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardTopicPolicy message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardTopicPolicy
         * @static
         * @param {Proto.CommunityBoardTopicPolicy} m CommunityBoardTopicPolicy
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardTopicPolicy.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.planUID = "";
                d.groupUID = "";
                d.active = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.type = 0;
            }
            if (m.planUID != null && m.hasOwnProperty("planUID")) {
                d.planUID = m.planUID;
            }
            if (m.groupUID != null && m.hasOwnProperty("groupUID")) {
                d.groupUID = m.groupUID;
            }
            if (m.active != null && m.hasOwnProperty("active")) {
                d.active = m.active;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            return d;
        };

        /**
         * Converts this CommunityBoardTopicPolicy to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardTopicPolicy
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardTopicPolicy.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardTopicPolicy;
    })();

    Proto.CommunityBoardComment = (function() {

        /**
         * Properties of a CommunityBoardComment.
         * @memberof Proto
         * @interface ICommunityBoardComment
         * @property {string|null} [uid] CommunityBoardComment uid
         * @property {string|null} [topicUID] CommunityBoardComment topicUID
         * @property {string|null} [userUID] CommunityBoardComment userUID
         * @property {string|null} [text] CommunityBoardComment text
         * @property {string|null} [parentUID] CommunityBoardComment parentUID
         * @property {string|null} [likeCount] CommunityBoardComment likeCount
         * @property {string|null} [commentCount] CommunityBoardComment commentCount
         * @property {number|Long|null} [registerDate] CommunityBoardComment registerDate
         * @property {number|Long|null} [modifyDate] CommunityBoardComment modifyDate
         * @property {boolean|null} [owner] CommunityBoardComment owner
         * @property {boolean|null} [visible] CommunityBoardComment visible
         * @property {boolean|null} [liked] CommunityBoardComment liked
         * @property {Proto.IUploadFile|null} [userIcon] CommunityBoardComment userIcon
         * @property {string|null} [userName] CommunityBoardComment userName
         * @property {Array.<Proto.IUploadFile>|null} [fileList] CommunityBoardComment fileList
         * @property {number|null} [policy] CommunityBoardComment policy
         * @property {Proto.ICommunityBoardPoll|null} [poll] CommunityBoardComment poll
         * @property {Array.<Proto.ICommunityBoardMapGroup>|null} [mapGroupList] CommunityBoardComment mapGroupList
         * @property {boolean|null} [blocked] CommunityBoardComment blocked
         * @property {string|null} [reactionCount] CommunityBoardComment reactionCount
         * @property {Array.<Proto.ICommunityBoardCommentReaction>|null} [reactionGroupList] CommunityBoardComment reactionGroupList
         * @property {boolean|null} [admin] CommunityBoardComment admin
         * @property {boolean|null} [topicOwner] CommunityBoardComment topicOwner
         * @property {number|null} [type] CommunityBoardComment type
         * @property {Proto.IStamp|null} [stamp] CommunityBoardComment stamp
         * @property {string|null} [targetUserUIDJson] CommunityBoardComment targetUserUIDJson
         * @property {Array.<Proto.IUser>|null} [targetUser] CommunityBoardComment targetUser
         */

        /**
         * Constructs a new CommunityBoardComment.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardComment.
         * @implements ICommunityBoardComment
         * @constructor
         * @param {Proto.ICommunityBoardComment=} [p] Properties to set
         */
        function CommunityBoardComment(p) {
            this.fileList = [];
            this.mapGroupList = [];
            this.reactionGroupList = [];
            this.targetUser = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardComment uid.
         * @member {string} uid
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.uid = "";

        /**
         * CommunityBoardComment topicUID.
         * @member {string} topicUID
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.topicUID = "";

        /**
         * CommunityBoardComment userUID.
         * @member {string} userUID
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.userUID = "";

        /**
         * CommunityBoardComment text.
         * @member {string} text
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.text = "";

        /**
         * CommunityBoardComment parentUID.
         * @member {string} parentUID
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.parentUID = "";

        /**
         * CommunityBoardComment likeCount.
         * @member {string} likeCount
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.likeCount = "";

        /**
         * CommunityBoardComment commentCount.
         * @member {string} commentCount
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.commentCount = "";

        /**
         * CommunityBoardComment registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardComment modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardComment owner.
         * @member {boolean} owner
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.owner = false;

        /**
         * CommunityBoardComment visible.
         * @member {boolean} visible
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.visible = false;

        /**
         * CommunityBoardComment liked.
         * @member {boolean} liked
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.liked = false;

        /**
         * CommunityBoardComment userIcon.
         * @member {Proto.IUploadFile|null|undefined} userIcon
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.userIcon = null;

        /**
         * CommunityBoardComment userName.
         * @member {string} userName
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.userName = "";

        /**
         * CommunityBoardComment fileList.
         * @member {Array.<Proto.IUploadFile>} fileList
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.fileList = $util.emptyArray;

        /**
         * CommunityBoardComment policy.
         * @member {number} policy
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.policy = 0;

        /**
         * CommunityBoardComment poll.
         * @member {Proto.ICommunityBoardPoll|null|undefined} poll
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.poll = null;

        /**
         * CommunityBoardComment mapGroupList.
         * @member {Array.<Proto.ICommunityBoardMapGroup>} mapGroupList
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.mapGroupList = $util.emptyArray;

        /**
         * CommunityBoardComment blocked.
         * @member {boolean} blocked
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.blocked = false;

        /**
         * CommunityBoardComment reactionCount.
         * @member {string} reactionCount
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.reactionCount = "";

        /**
         * CommunityBoardComment reactionGroupList.
         * @member {Array.<Proto.ICommunityBoardCommentReaction>} reactionGroupList
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.reactionGroupList = $util.emptyArray;

        /**
         * CommunityBoardComment admin.
         * @member {boolean} admin
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.admin = false;

        /**
         * CommunityBoardComment topicOwner.
         * @member {boolean} topicOwner
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.topicOwner = false;

        /**
         * CommunityBoardComment type.
         * @member {number} type
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.type = 0;

        /**
         * CommunityBoardComment stamp.
         * @member {Proto.IStamp|null|undefined} stamp
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.stamp = null;

        /**
         * CommunityBoardComment targetUserUIDJson.
         * @member {string} targetUserUIDJson
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.targetUserUIDJson = "";

        /**
         * CommunityBoardComment targetUser.
         * @member {Array.<Proto.IUser>} targetUser
         * @memberof Proto.CommunityBoardComment
         * @instance
         */
        CommunityBoardComment.prototype.targetUser = $util.emptyArray;

        /**
         * Creates a new CommunityBoardComment instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardComment
         * @static
         * @param {Proto.ICommunityBoardComment=} [properties] Properties to set
         * @returns {Proto.CommunityBoardComment} CommunityBoardComment instance
         */
        CommunityBoardComment.create = function create(properties) {
            return new CommunityBoardComment(properties);
        };

        /**
         * Encodes the specified CommunityBoardComment message. Does not implicitly {@link Proto.CommunityBoardComment.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardComment
         * @static
         * @param {Proto.ICommunityBoardComment} m CommunityBoardComment message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardComment.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.topicUID != null && Object.hasOwnProperty.call(m, "topicUID"))
                w.uint32(18).string(m.topicUID);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(26).string(m.userUID);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(34).string(m.text);
            if (m.parentUID != null && Object.hasOwnProperty.call(m, "parentUID"))
                w.uint32(42).string(m.parentUID);
            if (m.likeCount != null && Object.hasOwnProperty.call(m, "likeCount"))
                w.uint32(50).string(m.likeCount);
            if (m.commentCount != null && Object.hasOwnProperty.call(m, "commentCount"))
                w.uint32(58).string(m.commentCount);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            if (m.owner != null && Object.hasOwnProperty.call(m, "owner"))
                w.uint32(80).bool(m.owner);
            if (m.visible != null && Object.hasOwnProperty.call(m, "visible"))
                w.uint32(88).bool(m.visible);
            if (m.liked != null && Object.hasOwnProperty.call(m, "liked"))
                w.uint32(96).bool(m.liked);
            if (m.userIcon != null && Object.hasOwnProperty.call(m, "userIcon"))
                $root.Proto.UploadFile.encode(m.userIcon, w.uint32(106).fork()).ldelim();
            if (m.userName != null && Object.hasOwnProperty.call(m, "userName"))
                w.uint32(114).string(m.userName);
            if (m.fileList != null && m.fileList.length) {
                for (var i = 0; i < m.fileList.length; ++i)
                    $root.Proto.UploadFile.encode(m.fileList[i], w.uint32(122).fork()).ldelim();
            }
            if (m.policy != null && Object.hasOwnProperty.call(m, "policy"))
                w.uint32(128).int32(m.policy);
            if (m.poll != null && Object.hasOwnProperty.call(m, "poll"))
                $root.Proto.CommunityBoardPoll.encode(m.poll, w.uint32(138).fork()).ldelim();
            if (m.mapGroupList != null && m.mapGroupList.length) {
                for (var i = 0; i < m.mapGroupList.length; ++i)
                    $root.Proto.CommunityBoardMapGroup.encode(m.mapGroupList[i], w.uint32(146).fork()).ldelim();
            }
            if (m.blocked != null && Object.hasOwnProperty.call(m, "blocked"))
                w.uint32(152).bool(m.blocked);
            if (m.reactionCount != null && Object.hasOwnProperty.call(m, "reactionCount"))
                w.uint32(162).string(m.reactionCount);
            if (m.reactionGroupList != null && m.reactionGroupList.length) {
                for (var i = 0; i < m.reactionGroupList.length; ++i)
                    $root.Proto.CommunityBoardCommentReaction.encode(m.reactionGroupList[i], w.uint32(170).fork()).ldelim();
            }
            if (m.admin != null && Object.hasOwnProperty.call(m, "admin"))
                w.uint32(176).bool(m.admin);
            if (m.topicOwner != null && Object.hasOwnProperty.call(m, "topicOwner"))
                w.uint32(184).bool(m.topicOwner);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(192).int32(m.type);
            if (m.stamp != null && Object.hasOwnProperty.call(m, "stamp"))
                $root.Proto.Stamp.encode(m.stamp, w.uint32(202).fork()).ldelim();
            if (m.targetUserUIDJson != null && Object.hasOwnProperty.call(m, "targetUserUIDJson"))
                w.uint32(210).string(m.targetUserUIDJson);
            if (m.targetUser != null && m.targetUser.length) {
                for (var i = 0; i < m.targetUser.length; ++i)
                    $root.Proto.User.encode(m.targetUser[i], w.uint32(218).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CommunityBoardComment message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardComment} CommunityBoardComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardComment.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardComment();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.topicUID = r.string();
                    break;
                case 3:
                    m.userUID = r.string();
                    break;
                case 4:
                    m.text = r.string();
                    break;
                case 5:
                    m.parentUID = r.string();
                    break;
                case 6:
                    m.likeCount = r.string();
                    break;
                case 7:
                    m.commentCount = r.string();
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                case 10:
                    m.owner = r.bool();
                    break;
                case 11:
                    m.visible = r.bool();
                    break;
                case 12:
                    m.liked = r.bool();
                    break;
                case 13:
                    m.userIcon = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 14:
                    m.userName = r.string();
                    break;
                case 15:
                    if (!(m.fileList && m.fileList.length))
                        m.fileList = [];
                    m.fileList.push($root.Proto.UploadFile.decode(r, r.uint32()));
                    break;
                case 16:
                    m.policy = r.int32();
                    break;
                case 17:
                    m.poll = $root.Proto.CommunityBoardPoll.decode(r, r.uint32());
                    break;
                case 18:
                    if (!(m.mapGroupList && m.mapGroupList.length))
                        m.mapGroupList = [];
                    m.mapGroupList.push($root.Proto.CommunityBoardMapGroup.decode(r, r.uint32()));
                    break;
                case 19:
                    m.blocked = r.bool();
                    break;
                case 20:
                    m.reactionCount = r.string();
                    break;
                case 21:
                    if (!(m.reactionGroupList && m.reactionGroupList.length))
                        m.reactionGroupList = [];
                    m.reactionGroupList.push($root.Proto.CommunityBoardCommentReaction.decode(r, r.uint32()));
                    break;
                case 22:
                    m.admin = r.bool();
                    break;
                case 23:
                    m.topicOwner = r.bool();
                    break;
                case 24:
                    m.type = r.int32();
                    break;
                case 25:
                    m.stamp = $root.Proto.Stamp.decode(r, r.uint32());
                    break;
                case 26:
                    m.targetUserUIDJson = r.string();
                    break;
                case 27:
                    if (!(m.targetUser && m.targetUser.length))
                        m.targetUser = [];
                    m.targetUser.push($root.Proto.User.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardComment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardComment
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardComment} CommunityBoardComment
         */
        CommunityBoardComment.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardComment)
                return d;
            var m = new $root.Proto.CommunityBoardComment();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.topicUID != null) {
                m.topicUID = String(d.topicUID);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.parentUID != null) {
                m.parentUID = String(d.parentUID);
            }
            if (d.likeCount != null) {
                m.likeCount = String(d.likeCount);
            }
            if (d.commentCount != null) {
                m.commentCount = String(d.commentCount);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.owner != null) {
                m.owner = Boolean(d.owner);
            }
            if (d.visible != null) {
                m.visible = Boolean(d.visible);
            }
            if (d.liked != null) {
                m.liked = Boolean(d.liked);
            }
            if (d.userIcon != null) {
                if (typeof d.userIcon !== "object")
                    throw TypeError(".Proto.CommunityBoardComment.userIcon: object expected");
                m.userIcon = $root.Proto.UploadFile.fromObject(d.userIcon);
            }
            if (d.userName != null) {
                m.userName = String(d.userName);
            }
            if (d.fileList) {
                if (!Array.isArray(d.fileList))
                    throw TypeError(".Proto.CommunityBoardComment.fileList: array expected");
                m.fileList = [];
                for (var i = 0; i < d.fileList.length; ++i) {
                    if (typeof d.fileList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardComment.fileList: object expected");
                    m.fileList[i] = $root.Proto.UploadFile.fromObject(d.fileList[i]);
                }
            }
            if (d.policy != null) {
                m.policy = d.policy | 0;
            }
            if (d.poll != null) {
                if (typeof d.poll !== "object")
                    throw TypeError(".Proto.CommunityBoardComment.poll: object expected");
                m.poll = $root.Proto.CommunityBoardPoll.fromObject(d.poll);
            }
            if (d.mapGroupList) {
                if (!Array.isArray(d.mapGroupList))
                    throw TypeError(".Proto.CommunityBoardComment.mapGroupList: array expected");
                m.mapGroupList = [];
                for (var i = 0; i < d.mapGroupList.length; ++i) {
                    if (typeof d.mapGroupList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardComment.mapGroupList: object expected");
                    m.mapGroupList[i] = $root.Proto.CommunityBoardMapGroup.fromObject(d.mapGroupList[i]);
                }
            }
            if (d.blocked != null) {
                m.blocked = Boolean(d.blocked);
            }
            if (d.reactionCount != null) {
                m.reactionCount = String(d.reactionCount);
            }
            if (d.reactionGroupList) {
                if (!Array.isArray(d.reactionGroupList))
                    throw TypeError(".Proto.CommunityBoardComment.reactionGroupList: array expected");
                m.reactionGroupList = [];
                for (var i = 0; i < d.reactionGroupList.length; ++i) {
                    if (typeof d.reactionGroupList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardComment.reactionGroupList: object expected");
                    m.reactionGroupList[i] = $root.Proto.CommunityBoardCommentReaction.fromObject(d.reactionGroupList[i]);
                }
            }
            if (d.admin != null) {
                m.admin = Boolean(d.admin);
            }
            if (d.topicOwner != null) {
                m.topicOwner = Boolean(d.topicOwner);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.stamp != null) {
                if (typeof d.stamp !== "object")
                    throw TypeError(".Proto.CommunityBoardComment.stamp: object expected");
                m.stamp = $root.Proto.Stamp.fromObject(d.stamp);
            }
            if (d.targetUserUIDJson != null) {
                m.targetUserUIDJson = String(d.targetUserUIDJson);
            }
            if (d.targetUser) {
                if (!Array.isArray(d.targetUser))
                    throw TypeError(".Proto.CommunityBoardComment.targetUser: array expected");
                m.targetUser = [];
                for (var i = 0; i < d.targetUser.length; ++i) {
                    if (typeof d.targetUser[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardComment.targetUser: object expected");
                    m.targetUser[i] = $root.Proto.User.fromObject(d.targetUser[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardComment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardComment
         * @static
         * @param {Proto.CommunityBoardComment} m CommunityBoardComment
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardComment.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.fileList = [];
                d.mapGroupList = [];
                d.reactionGroupList = [];
                d.targetUser = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.topicUID = "";
                d.userUID = "";
                d.text = "";
                d.parentUID = "";
                d.likeCount = "";
                d.commentCount = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.owner = false;
                d.visible = false;
                d.liked = false;
                d.userIcon = null;
                d.userName = "";
                d.policy = 0;
                d.poll = null;
                d.blocked = false;
                d.reactionCount = "";
                d.admin = false;
                d.topicOwner = false;
                d.type = 0;
                d.stamp = null;
                d.targetUserUIDJson = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.topicUID != null && m.hasOwnProperty("topicUID")) {
                d.topicUID = m.topicUID;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.parentUID != null && m.hasOwnProperty("parentUID")) {
                d.parentUID = m.parentUID;
            }
            if (m.likeCount != null && m.hasOwnProperty("likeCount")) {
                d.likeCount = m.likeCount;
            }
            if (m.commentCount != null && m.hasOwnProperty("commentCount")) {
                d.commentCount = m.commentCount;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.owner != null && m.hasOwnProperty("owner")) {
                d.owner = m.owner;
            }
            if (m.visible != null && m.hasOwnProperty("visible")) {
                d.visible = m.visible;
            }
            if (m.liked != null && m.hasOwnProperty("liked")) {
                d.liked = m.liked;
            }
            if (m.userIcon != null && m.hasOwnProperty("userIcon")) {
                d.userIcon = $root.Proto.UploadFile.toObject(m.userIcon, o);
            }
            if (m.userName != null && m.hasOwnProperty("userName")) {
                d.userName = m.userName;
            }
            if (m.fileList && m.fileList.length) {
                d.fileList = [];
                for (var j = 0; j < m.fileList.length; ++j) {
                    d.fileList[j] = $root.Proto.UploadFile.toObject(m.fileList[j], o);
                }
            }
            if (m.policy != null && m.hasOwnProperty("policy")) {
                d.policy = m.policy;
            }
            if (m.poll != null && m.hasOwnProperty("poll")) {
                d.poll = $root.Proto.CommunityBoardPoll.toObject(m.poll, o);
            }
            if (m.mapGroupList && m.mapGroupList.length) {
                d.mapGroupList = [];
                for (var j = 0; j < m.mapGroupList.length; ++j) {
                    d.mapGroupList[j] = $root.Proto.CommunityBoardMapGroup.toObject(m.mapGroupList[j], o);
                }
            }
            if (m.blocked != null && m.hasOwnProperty("blocked")) {
                d.blocked = m.blocked;
            }
            if (m.reactionCount != null && m.hasOwnProperty("reactionCount")) {
                d.reactionCount = m.reactionCount;
            }
            if (m.reactionGroupList && m.reactionGroupList.length) {
                d.reactionGroupList = [];
                for (var j = 0; j < m.reactionGroupList.length; ++j) {
                    d.reactionGroupList[j] = $root.Proto.CommunityBoardCommentReaction.toObject(m.reactionGroupList[j], o);
                }
            }
            if (m.admin != null && m.hasOwnProperty("admin")) {
                d.admin = m.admin;
            }
            if (m.topicOwner != null && m.hasOwnProperty("topicOwner")) {
                d.topicOwner = m.topicOwner;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.stamp != null && m.hasOwnProperty("stamp")) {
                d.stamp = $root.Proto.Stamp.toObject(m.stamp, o);
            }
            if (m.targetUserUIDJson != null && m.hasOwnProperty("targetUserUIDJson")) {
                d.targetUserUIDJson = m.targetUserUIDJson;
            }
            if (m.targetUser && m.targetUser.length) {
                d.targetUser = [];
                for (var j = 0; j < m.targetUser.length; ++j) {
                    d.targetUser[j] = $root.Proto.User.toObject(m.targetUser[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CommunityBoardComment to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardComment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardComment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardComment;
    })();

    Proto.CommunityBoardCommentList = (function() {

        /**
         * Properties of a CommunityBoardCommentList.
         * @memberof Proto
         * @interface ICommunityBoardCommentList
         * @property {Array.<Proto.ICommunityBoardComment>|null} [value] CommunityBoardCommentList value
         */

        /**
         * Constructs a new CommunityBoardCommentList.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardCommentList.
         * @implements ICommunityBoardCommentList
         * @constructor
         * @param {Proto.ICommunityBoardCommentList=} [p] Properties to set
         */
        function CommunityBoardCommentList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardCommentList value.
         * @member {Array.<Proto.ICommunityBoardComment>} value
         * @memberof Proto.CommunityBoardCommentList
         * @instance
         */
        CommunityBoardCommentList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CommunityBoardCommentList instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardCommentList
         * @static
         * @param {Proto.ICommunityBoardCommentList=} [properties] Properties to set
         * @returns {Proto.CommunityBoardCommentList} CommunityBoardCommentList instance
         */
        CommunityBoardCommentList.create = function create(properties) {
            return new CommunityBoardCommentList(properties);
        };

        /**
         * Encodes the specified CommunityBoardCommentList message. Does not implicitly {@link Proto.CommunityBoardCommentList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardCommentList
         * @static
         * @param {Proto.ICommunityBoardCommentList} m CommunityBoardCommentList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardCommentList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CommunityBoardComment.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CommunityBoardCommentList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardCommentList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardCommentList} CommunityBoardCommentList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardCommentList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardCommentList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CommunityBoardComment.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardCommentList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardCommentList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardCommentList} CommunityBoardCommentList
         */
        CommunityBoardCommentList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardCommentList)
                return d;
            var m = new $root.Proto.CommunityBoardCommentList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CommunityBoardCommentList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardCommentList.value: object expected");
                    m.value[i] = $root.Proto.CommunityBoardComment.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardCommentList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardCommentList
         * @static
         * @param {Proto.CommunityBoardCommentList} m CommunityBoardCommentList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardCommentList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CommunityBoardComment.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CommunityBoardCommentList to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardCommentList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardCommentList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardCommentList;
    })();

    Proto.CommunityBoardCommentReaction = (function() {

        /**
         * Properties of a CommunityBoardCommentReaction.
         * @memberof Proto
         * @interface ICommunityBoardCommentReaction
         * @property {string|null} [reactionUID] CommunityBoardCommentReaction reactionUID
         * @property {string|null} [reactionCount] CommunityBoardCommentReaction reactionCount
         * @property {number|Long|null} [registerDate] CommunityBoardCommentReaction registerDate
         * @property {number|Long|null} [modifyDate] CommunityBoardCommentReaction modifyDate
         * @property {string|null} [commentUID] CommunityBoardCommentReaction commentUID
         */

        /**
         * Constructs a new CommunityBoardCommentReaction.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardCommentReaction.
         * @implements ICommunityBoardCommentReaction
         * @constructor
         * @param {Proto.ICommunityBoardCommentReaction=} [p] Properties to set
         */
        function CommunityBoardCommentReaction(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardCommentReaction reactionUID.
         * @member {string} reactionUID
         * @memberof Proto.CommunityBoardCommentReaction
         * @instance
         */
        CommunityBoardCommentReaction.prototype.reactionUID = "";

        /**
         * CommunityBoardCommentReaction reactionCount.
         * @member {string} reactionCount
         * @memberof Proto.CommunityBoardCommentReaction
         * @instance
         */
        CommunityBoardCommentReaction.prototype.reactionCount = "";

        /**
         * CommunityBoardCommentReaction registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommunityBoardCommentReaction
         * @instance
         */
        CommunityBoardCommentReaction.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardCommentReaction modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommunityBoardCommentReaction
         * @instance
         */
        CommunityBoardCommentReaction.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardCommentReaction commentUID.
         * @member {string} commentUID
         * @memberof Proto.CommunityBoardCommentReaction
         * @instance
         */
        CommunityBoardCommentReaction.prototype.commentUID = "";

        /**
         * Creates a new CommunityBoardCommentReaction instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardCommentReaction
         * @static
         * @param {Proto.ICommunityBoardCommentReaction=} [properties] Properties to set
         * @returns {Proto.CommunityBoardCommentReaction} CommunityBoardCommentReaction instance
         */
        CommunityBoardCommentReaction.create = function create(properties) {
            return new CommunityBoardCommentReaction(properties);
        };

        /**
         * Encodes the specified CommunityBoardCommentReaction message. Does not implicitly {@link Proto.CommunityBoardCommentReaction.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardCommentReaction
         * @static
         * @param {Proto.ICommunityBoardCommentReaction} m CommunityBoardCommentReaction message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardCommentReaction.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.reactionUID != null && Object.hasOwnProperty.call(m, "reactionUID"))
                w.uint32(10).string(m.reactionUID);
            if (m.reactionCount != null && Object.hasOwnProperty.call(m, "reactionCount"))
                w.uint32(18).string(m.reactionCount);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(32).int64(m.modifyDate);
            if (m.commentUID != null && Object.hasOwnProperty.call(m, "commentUID"))
                w.uint32(42).string(m.commentUID);
            return w;
        };

        /**
         * Decodes a CommunityBoardCommentReaction message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardCommentReaction
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardCommentReaction} CommunityBoardCommentReaction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardCommentReaction.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardCommentReaction();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.reactionUID = r.string();
                    break;
                case 2:
                    m.reactionCount = r.string();
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    m.modifyDate = r.int64();
                    break;
                case 5:
                    m.commentUID = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardCommentReaction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardCommentReaction
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardCommentReaction} CommunityBoardCommentReaction
         */
        CommunityBoardCommentReaction.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardCommentReaction)
                return d;
            var m = new $root.Proto.CommunityBoardCommentReaction();
            if (d.reactionUID != null) {
                m.reactionUID = String(d.reactionUID);
            }
            if (d.reactionCount != null) {
                m.reactionCount = String(d.reactionCount);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.commentUID != null) {
                m.commentUID = String(d.commentUID);
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardCommentReaction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardCommentReaction
         * @static
         * @param {Proto.CommunityBoardCommentReaction} m CommunityBoardCommentReaction
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardCommentReaction.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.reactionUID = "";
                d.reactionCount = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.commentUID = "";
            }
            if (m.reactionUID != null && m.hasOwnProperty("reactionUID")) {
                d.reactionUID = m.reactionUID;
            }
            if (m.reactionCount != null && m.hasOwnProperty("reactionCount")) {
                d.reactionCount = m.reactionCount;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.commentUID != null && m.hasOwnProperty("commentUID")) {
                d.commentUID = m.commentUID;
            }
            return d;
        };

        /**
         * Converts this CommunityBoardCommentReaction to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardCommentReaction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardCommentReaction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardCommentReaction;
    })();

    Proto.CommunityBoardCommentReactionList = (function() {

        /**
         * Properties of a CommunityBoardCommentReactionList.
         * @memberof Proto
         * @interface ICommunityBoardCommentReactionList
         * @property {Array.<Proto.ICommunityBoardCommentReaction>|null} [value] CommunityBoardCommentReactionList value
         */

        /**
         * Constructs a new CommunityBoardCommentReactionList.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardCommentReactionList.
         * @implements ICommunityBoardCommentReactionList
         * @constructor
         * @param {Proto.ICommunityBoardCommentReactionList=} [p] Properties to set
         */
        function CommunityBoardCommentReactionList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardCommentReactionList value.
         * @member {Array.<Proto.ICommunityBoardCommentReaction>} value
         * @memberof Proto.CommunityBoardCommentReactionList
         * @instance
         */
        CommunityBoardCommentReactionList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CommunityBoardCommentReactionList instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardCommentReactionList
         * @static
         * @param {Proto.ICommunityBoardCommentReactionList=} [properties] Properties to set
         * @returns {Proto.CommunityBoardCommentReactionList} CommunityBoardCommentReactionList instance
         */
        CommunityBoardCommentReactionList.create = function create(properties) {
            return new CommunityBoardCommentReactionList(properties);
        };

        /**
         * Encodes the specified CommunityBoardCommentReactionList message. Does not implicitly {@link Proto.CommunityBoardCommentReactionList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardCommentReactionList
         * @static
         * @param {Proto.ICommunityBoardCommentReactionList} m CommunityBoardCommentReactionList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardCommentReactionList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CommunityBoardCommentReaction.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CommunityBoardCommentReactionList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardCommentReactionList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardCommentReactionList} CommunityBoardCommentReactionList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardCommentReactionList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardCommentReactionList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CommunityBoardCommentReaction.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardCommentReactionList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardCommentReactionList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardCommentReactionList} CommunityBoardCommentReactionList
         */
        CommunityBoardCommentReactionList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardCommentReactionList)
                return d;
            var m = new $root.Proto.CommunityBoardCommentReactionList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CommunityBoardCommentReactionList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardCommentReactionList.value: object expected");
                    m.value[i] = $root.Proto.CommunityBoardCommentReaction.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardCommentReactionList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardCommentReactionList
         * @static
         * @param {Proto.CommunityBoardCommentReactionList} m CommunityBoardCommentReactionList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardCommentReactionList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CommunityBoardCommentReaction.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CommunityBoardCommentReactionList to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardCommentReactionList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardCommentReactionList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardCommentReactionList;
    })();

    Proto.DeviceCompanySetting = (function() {

        /**
         * Properties of a DeviceCompanySetting.
         * @memberof Proto
         * @interface IDeviceCompanySetting
         * @property {string|null} [deviceUID] DeviceCompanySetting deviceUID
         * @property {string|null} [companyUID] DeviceCompanySetting companyUID
         * @property {boolean|null} [visible] DeviceCompanySetting visible
         * @property {number|Long|null} [registerDate] DeviceCompanySetting registerDate
         * @property {number|Long|null} [modifyDate] DeviceCompanySetting modifyDate
         */

        /**
         * Constructs a new DeviceCompanySetting.
         * @memberof Proto
         * @classdesc Represents a DeviceCompanySetting.
         * @implements IDeviceCompanySetting
         * @constructor
         * @param {Proto.IDeviceCompanySetting=} [p] Properties to set
         */
        function DeviceCompanySetting(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DeviceCompanySetting deviceUID.
         * @member {string} deviceUID
         * @memberof Proto.DeviceCompanySetting
         * @instance
         */
        DeviceCompanySetting.prototype.deviceUID = "";

        /**
         * DeviceCompanySetting companyUID.
         * @member {string} companyUID
         * @memberof Proto.DeviceCompanySetting
         * @instance
         */
        DeviceCompanySetting.prototype.companyUID = "";

        /**
         * DeviceCompanySetting visible.
         * @member {boolean} visible
         * @memberof Proto.DeviceCompanySetting
         * @instance
         */
        DeviceCompanySetting.prototype.visible = false;

        /**
         * DeviceCompanySetting registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.DeviceCompanySetting
         * @instance
         */
        DeviceCompanySetting.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * DeviceCompanySetting modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.DeviceCompanySetting
         * @instance
         */
        DeviceCompanySetting.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new DeviceCompanySetting instance using the specified properties.
         * @function create
         * @memberof Proto.DeviceCompanySetting
         * @static
         * @param {Proto.IDeviceCompanySetting=} [properties] Properties to set
         * @returns {Proto.DeviceCompanySetting} DeviceCompanySetting instance
         */
        DeviceCompanySetting.create = function create(properties) {
            return new DeviceCompanySetting(properties);
        };

        /**
         * Encodes the specified DeviceCompanySetting message. Does not implicitly {@link Proto.DeviceCompanySetting.verify|verify} messages.
         * @function encode
         * @memberof Proto.DeviceCompanySetting
         * @static
         * @param {Proto.IDeviceCompanySetting} m DeviceCompanySetting message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeviceCompanySetting.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.deviceUID != null && Object.hasOwnProperty.call(m, "deviceUID"))
                w.uint32(10).string(m.deviceUID);
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(18).string(m.companyUID);
            if (m.visible != null && Object.hasOwnProperty.call(m, "visible"))
                w.uint32(24).bool(m.visible);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a DeviceCompanySetting message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.DeviceCompanySetting
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.DeviceCompanySetting} DeviceCompanySetting
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeviceCompanySetting.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.DeviceCompanySetting();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.deviceUID = r.string();
                    break;
                case 2:
                    m.companyUID = r.string();
                    break;
                case 3:
                    m.visible = r.bool();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a DeviceCompanySetting message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.DeviceCompanySetting
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.DeviceCompanySetting} DeviceCompanySetting
         */
        DeviceCompanySetting.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.DeviceCompanySetting)
                return d;
            var m = new $root.Proto.DeviceCompanySetting();
            if (d.deviceUID != null) {
                m.deviceUID = String(d.deviceUID);
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.visible != null) {
                m.visible = Boolean(d.visible);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a DeviceCompanySetting message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.DeviceCompanySetting
         * @static
         * @param {Proto.DeviceCompanySetting} m DeviceCompanySetting
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeviceCompanySetting.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.deviceUID = "";
                d.companyUID = "";
                d.visible = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.deviceUID != null && m.hasOwnProperty("deviceUID")) {
                d.deviceUID = m.deviceUID;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.visible != null && m.hasOwnProperty("visible")) {
                d.visible = m.visible;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this DeviceCompanySetting to JSON.
         * @function toJSON
         * @memberof Proto.DeviceCompanySetting
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeviceCompanySetting.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeviceCompanySetting;
    })();

    Proto.MemberId = (function() {

        /**
         * Properties of a MemberId.
         * @memberof Proto
         * @interface IMemberId
         * @property {string|null} [planUID] MemberId planUID
         * @property {string|null} [groupUID] MemberId groupUID
         * @property {string|null} [name] MemberId name
         * @property {string|null} [memberId] MemberId memberId
         * @property {number|Long|null} [registerDate] MemberId registerDate
         * @property {number|Long|null} [modifyDate] MemberId modifyDate
         * @property {string|null} [userCount] MemberId userCount
         * @property {Array.<Proto.IUser>|null} [profileUserList] MemberId profileUserList
         */

        /**
         * Constructs a new MemberId.
         * @memberof Proto
         * @classdesc Represents a MemberId.
         * @implements IMemberId
         * @constructor
         * @param {Proto.IMemberId=} [p] Properties to set
         */
        function MemberId(p) {
            this.profileUserList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * MemberId planUID.
         * @member {string} planUID
         * @memberof Proto.MemberId
         * @instance
         */
        MemberId.prototype.planUID = "";

        /**
         * MemberId groupUID.
         * @member {string} groupUID
         * @memberof Proto.MemberId
         * @instance
         */
        MemberId.prototype.groupUID = "";

        /**
         * MemberId name.
         * @member {string} name
         * @memberof Proto.MemberId
         * @instance
         */
        MemberId.prototype.name = "";

        /**
         * MemberId memberId.
         * @member {string} memberId
         * @memberof Proto.MemberId
         * @instance
         */
        MemberId.prototype.memberId = "";

        /**
         * MemberId registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.MemberId
         * @instance
         */
        MemberId.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * MemberId modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.MemberId
         * @instance
         */
        MemberId.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * MemberId userCount.
         * @member {string} userCount
         * @memberof Proto.MemberId
         * @instance
         */
        MemberId.prototype.userCount = "";

        /**
         * MemberId profileUserList.
         * @member {Array.<Proto.IUser>} profileUserList
         * @memberof Proto.MemberId
         * @instance
         */
        MemberId.prototype.profileUserList = $util.emptyArray;

        /**
         * Creates a new MemberId instance using the specified properties.
         * @function create
         * @memberof Proto.MemberId
         * @static
         * @param {Proto.IMemberId=} [properties] Properties to set
         * @returns {Proto.MemberId} MemberId instance
         */
        MemberId.create = function create(properties) {
            return new MemberId(properties);
        };

        /**
         * Encodes the specified MemberId message. Does not implicitly {@link Proto.MemberId.verify|verify} messages.
         * @function encode
         * @memberof Proto.MemberId
         * @static
         * @param {Proto.IMemberId} m MemberId message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberId.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.planUID != null && Object.hasOwnProperty.call(m, "planUID"))
                w.uint32(10).string(m.planUID);
            if (m.groupUID != null && Object.hasOwnProperty.call(m, "groupUID"))
                w.uint32(18).string(m.groupUID);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.memberId != null && Object.hasOwnProperty.call(m, "memberId"))
                w.uint32(34).string(m.memberId);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(40).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(48).int64(m.modifyDate);
            if (m.userCount != null && Object.hasOwnProperty.call(m, "userCount"))
                w.uint32(58).string(m.userCount);
            if (m.profileUserList != null && m.profileUserList.length) {
                for (var i = 0; i < m.profileUserList.length; ++i)
                    $root.Proto.User.encode(m.profileUserList[i], w.uint32(66).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a MemberId message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.MemberId
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.MemberId} MemberId
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberId.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.MemberId();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.planUID = r.string();
                    break;
                case 2:
                    m.groupUID = r.string();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.memberId = r.string();
                    break;
                case 5:
                    m.registerDate = r.int64();
                    break;
                case 6:
                    m.modifyDate = r.int64();
                    break;
                case 7:
                    m.userCount = r.string();
                    break;
                case 8:
                    if (!(m.profileUserList && m.profileUserList.length))
                        m.profileUserList = [];
                    m.profileUserList.push($root.Proto.User.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a MemberId message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.MemberId
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.MemberId} MemberId
         */
        MemberId.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.MemberId)
                return d;
            var m = new $root.Proto.MemberId();
            if (d.planUID != null) {
                m.planUID = String(d.planUID);
            }
            if (d.groupUID != null) {
                m.groupUID = String(d.groupUID);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.memberId != null) {
                m.memberId = String(d.memberId);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.userCount != null) {
                m.userCount = String(d.userCount);
            }
            if (d.profileUserList) {
                if (!Array.isArray(d.profileUserList))
                    throw TypeError(".Proto.MemberId.profileUserList: array expected");
                m.profileUserList = [];
                for (var i = 0; i < d.profileUserList.length; ++i) {
                    if (typeof d.profileUserList[i] !== "object")
                        throw TypeError(".Proto.MemberId.profileUserList: object expected");
                    m.profileUserList[i] = $root.Proto.User.fromObject(d.profileUserList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a MemberId message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.MemberId
         * @static
         * @param {Proto.MemberId} m MemberId
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberId.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.profileUserList = [];
            }
            if (o.defaults) {
                d.planUID = "";
                d.groupUID = "";
                d.name = "";
                d.memberId = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.userCount = "";
            }
            if (m.planUID != null && m.hasOwnProperty("planUID")) {
                d.planUID = m.planUID;
            }
            if (m.groupUID != null && m.hasOwnProperty("groupUID")) {
                d.groupUID = m.groupUID;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.memberId != null && m.hasOwnProperty("memberId")) {
                d.memberId = m.memberId;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.userCount != null && m.hasOwnProperty("userCount")) {
                d.userCount = m.userCount;
            }
            if (m.profileUserList && m.profileUserList.length) {
                d.profileUserList = [];
                for (var j = 0; j < m.profileUserList.length; ++j) {
                    d.profileUserList[j] = $root.Proto.User.toObject(m.profileUserList[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this MemberId to JSON.
         * @function toJSON
         * @memberof Proto.MemberId
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberId.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MemberId;
    })();

    Proto.MemberIdList = (function() {

        /**
         * Properties of a MemberIdList.
         * @memberof Proto
         * @interface IMemberIdList
         * @property {Array.<Proto.IMemberId>|null} [value] MemberIdList value
         */

        /**
         * Constructs a new MemberIdList.
         * @memberof Proto
         * @classdesc Represents a MemberIdList.
         * @implements IMemberIdList
         * @constructor
         * @param {Proto.IMemberIdList=} [p] Properties to set
         */
        function MemberIdList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * MemberIdList value.
         * @member {Array.<Proto.IMemberId>} value
         * @memberof Proto.MemberIdList
         * @instance
         */
        MemberIdList.prototype.value = $util.emptyArray;

        /**
         * Creates a new MemberIdList instance using the specified properties.
         * @function create
         * @memberof Proto.MemberIdList
         * @static
         * @param {Proto.IMemberIdList=} [properties] Properties to set
         * @returns {Proto.MemberIdList} MemberIdList instance
         */
        MemberIdList.create = function create(properties) {
            return new MemberIdList(properties);
        };

        /**
         * Encodes the specified MemberIdList message. Does not implicitly {@link Proto.MemberIdList.verify|verify} messages.
         * @function encode
         * @memberof Proto.MemberIdList
         * @static
         * @param {Proto.IMemberIdList} m MemberIdList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberIdList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.MemberId.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a MemberIdList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.MemberIdList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.MemberIdList} MemberIdList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberIdList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.MemberIdList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.MemberId.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a MemberIdList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.MemberIdList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.MemberIdList} MemberIdList
         */
        MemberIdList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.MemberIdList)
                return d;
            var m = new $root.Proto.MemberIdList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.MemberIdList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.MemberIdList.value: object expected");
                    m.value[i] = $root.Proto.MemberId.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a MemberIdList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.MemberIdList
         * @static
         * @param {Proto.MemberIdList} m MemberIdList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberIdList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.MemberId.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this MemberIdList to JSON.
         * @function toJSON
         * @memberof Proto.MemberIdList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberIdList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MemberIdList;
    })();

    Proto.ContentAdditionalFile = (function() {

        /**
         * Properties of a ContentAdditionalFile.
         * @memberof Proto
         * @interface IContentAdditionalFile
         * @property {string|null} [uid] ContentAdditionalFile uid
         * @property {string|null} [contentUID] ContentAdditionalFile contentUID
         * @property {number|null} [fileSize] ContentAdditionalFile fileSize
         * @property {string|null} [name] ContentAdditionalFile name
         * @property {number|null} [policy] ContentAdditionalFile policy
         * @property {number|null} [order] ContentAdditionalFile order
         * @property {Proto.IUploadFile|null} [file] ContentAdditionalFile file
         * @property {number|Long|null} [registerDate] ContentAdditionalFile registerDate
         * @property {number|Long|null} [modifyDate] ContentAdditionalFile modifyDate
         * @property {number|null} [status] ContentAdditionalFile status
         */

        /**
         * Constructs a new ContentAdditionalFile.
         * @memberof Proto
         * @classdesc Represents a ContentAdditionalFile.
         * @implements IContentAdditionalFile
         * @constructor
         * @param {Proto.IContentAdditionalFile=} [p] Properties to set
         */
        function ContentAdditionalFile(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentAdditionalFile uid.
         * @member {string} uid
         * @memberof Proto.ContentAdditionalFile
         * @instance
         */
        ContentAdditionalFile.prototype.uid = "";

        /**
         * ContentAdditionalFile contentUID.
         * @member {string} contentUID
         * @memberof Proto.ContentAdditionalFile
         * @instance
         */
        ContentAdditionalFile.prototype.contentUID = "";

        /**
         * ContentAdditionalFile fileSize.
         * @member {number} fileSize
         * @memberof Proto.ContentAdditionalFile
         * @instance
         */
        ContentAdditionalFile.prototype.fileSize = 0;

        /**
         * ContentAdditionalFile name.
         * @member {string} name
         * @memberof Proto.ContentAdditionalFile
         * @instance
         */
        ContentAdditionalFile.prototype.name = "";

        /**
         * ContentAdditionalFile policy.
         * @member {number} policy
         * @memberof Proto.ContentAdditionalFile
         * @instance
         */
        ContentAdditionalFile.prototype.policy = 0;

        /**
         * ContentAdditionalFile order.
         * @member {number} order
         * @memberof Proto.ContentAdditionalFile
         * @instance
         */
        ContentAdditionalFile.prototype.order = 0;

        /**
         * ContentAdditionalFile file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.ContentAdditionalFile
         * @instance
         */
        ContentAdditionalFile.prototype.file = null;

        /**
         * ContentAdditionalFile registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentAdditionalFile
         * @instance
         */
        ContentAdditionalFile.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentAdditionalFile modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentAdditionalFile
         * @instance
         */
        ContentAdditionalFile.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentAdditionalFile status.
         * @member {number} status
         * @memberof Proto.ContentAdditionalFile
         * @instance
         */
        ContentAdditionalFile.prototype.status = 0;

        /**
         * Creates a new ContentAdditionalFile instance using the specified properties.
         * @function create
         * @memberof Proto.ContentAdditionalFile
         * @static
         * @param {Proto.IContentAdditionalFile=} [properties] Properties to set
         * @returns {Proto.ContentAdditionalFile} ContentAdditionalFile instance
         */
        ContentAdditionalFile.create = function create(properties) {
            return new ContentAdditionalFile(properties);
        };

        /**
         * Encodes the specified ContentAdditionalFile message. Does not implicitly {@link Proto.ContentAdditionalFile.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentAdditionalFile
         * @static
         * @param {Proto.IContentAdditionalFile} m ContentAdditionalFile message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentAdditionalFile.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(18).string(m.contentUID);
            if (m.fileSize != null && Object.hasOwnProperty.call(m, "fileSize"))
                w.uint32(25).double(m.fileSize);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(34).string(m.name);
            if (m.policy != null && Object.hasOwnProperty.call(m, "policy"))
                w.uint32(40).int32(m.policy);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(48).int32(m.order);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(58).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(80).int32(m.status);
            return w;
        };

        /**
         * Decodes a ContentAdditionalFile message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentAdditionalFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentAdditionalFile} ContentAdditionalFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentAdditionalFile.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentAdditionalFile();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.contentUID = r.string();
                    break;
                case 3:
                    m.fileSize = r.double();
                    break;
                case 4:
                    m.name = r.string();
                    break;
                case 5:
                    m.policy = r.int32();
                    break;
                case 6:
                    m.order = r.int32();
                    break;
                case 7:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                case 10:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentAdditionalFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentAdditionalFile
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentAdditionalFile} ContentAdditionalFile
         */
        ContentAdditionalFile.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentAdditionalFile)
                return d;
            var m = new $root.Proto.ContentAdditionalFile();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.fileSize != null) {
                m.fileSize = Number(d.fileSize);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.policy != null) {
                m.policy = d.policy | 0;
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.ContentAdditionalFile.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentAdditionalFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentAdditionalFile
         * @static
         * @param {Proto.ContentAdditionalFile} m ContentAdditionalFile
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentAdditionalFile.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.contentUID = "";
                d.fileSize = 0;
                d.name = "";
                d.policy = 0;
                d.order = 0;
                d.file = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.fileSize != null && m.hasOwnProperty("fileSize")) {
                d.fileSize = o.json && !isFinite(m.fileSize) ? String(m.fileSize) : m.fileSize;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.policy != null && m.hasOwnProperty("policy")) {
                d.policy = m.policy;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this ContentAdditionalFile to JSON.
         * @function toJSON
         * @memberof Proto.ContentAdditionalFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentAdditionalFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentAdditionalFile;
    })();

    Proto.ContentAdditionalFileList = (function() {

        /**
         * Properties of a ContentAdditionalFileList.
         * @memberof Proto
         * @interface IContentAdditionalFileList
         * @property {Array.<Proto.IContentAdditionalFile>|null} [value] ContentAdditionalFileList value
         */

        /**
         * Constructs a new ContentAdditionalFileList.
         * @memberof Proto
         * @classdesc Represents a ContentAdditionalFileList.
         * @implements IContentAdditionalFileList
         * @constructor
         * @param {Proto.IContentAdditionalFileList=} [p] Properties to set
         */
        function ContentAdditionalFileList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentAdditionalFileList value.
         * @member {Array.<Proto.IContentAdditionalFile>} value
         * @memberof Proto.ContentAdditionalFileList
         * @instance
         */
        ContentAdditionalFileList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentAdditionalFileList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentAdditionalFileList
         * @static
         * @param {Proto.IContentAdditionalFileList=} [properties] Properties to set
         * @returns {Proto.ContentAdditionalFileList} ContentAdditionalFileList instance
         */
        ContentAdditionalFileList.create = function create(properties) {
            return new ContentAdditionalFileList(properties);
        };

        /**
         * Encodes the specified ContentAdditionalFileList message. Does not implicitly {@link Proto.ContentAdditionalFileList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentAdditionalFileList
         * @static
         * @param {Proto.IContentAdditionalFileList} m ContentAdditionalFileList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentAdditionalFileList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentAdditionalFile.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentAdditionalFileList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentAdditionalFileList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentAdditionalFileList} ContentAdditionalFileList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentAdditionalFileList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentAdditionalFileList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentAdditionalFile.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentAdditionalFileList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentAdditionalFileList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentAdditionalFileList} ContentAdditionalFileList
         */
        ContentAdditionalFileList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentAdditionalFileList)
                return d;
            var m = new $root.Proto.ContentAdditionalFileList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentAdditionalFileList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentAdditionalFileList.value: object expected");
                    m.value[i] = $root.Proto.ContentAdditionalFile.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentAdditionalFileList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentAdditionalFileList
         * @static
         * @param {Proto.ContentAdditionalFileList} m ContentAdditionalFileList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentAdditionalFileList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentAdditionalFile.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentAdditionalFileList to JSON.
         * @function toJSON
         * @memberof Proto.ContentAdditionalFileList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentAdditionalFileList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentAdditionalFileList;
    })();

    Proto.SigningUser = (function() {

        /**
         * Properties of a SigningUser.
         * @memberof Proto
         * @interface ISigningUser
         * @property {string|null} [id] SigningUser id
         * @property {string|null} [iconUrl] SigningUser iconUrl
         * @property {string|null} [username] SigningUser username
         * @property {string|null} [planName] SigningUser planName
         * @property {number|null} [planCount] SigningUser planCount
         * @property {number|Long|null} [planRegisterDate] SigningUser planRegisterDate
         * @property {string|null} [joinCount] SigningUser joinCount
         * @property {number|Long|null} [registerDate] SigningUser registerDate
         * @property {number|Long|null} [modifyDate] SigningUser modifyDate
         */

        /**
         * Constructs a new SigningUser.
         * @memberof Proto
         * @classdesc Represents a SigningUser.
         * @implements ISigningUser
         * @constructor
         * @param {Proto.ISigningUser=} [p] Properties to set
         */
        function SigningUser(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * SigningUser id.
         * @member {string} id
         * @memberof Proto.SigningUser
         * @instance
         */
        SigningUser.prototype.id = "";

        /**
         * SigningUser iconUrl.
         * @member {string} iconUrl
         * @memberof Proto.SigningUser
         * @instance
         */
        SigningUser.prototype.iconUrl = "";

        /**
         * SigningUser username.
         * @member {string} username
         * @memberof Proto.SigningUser
         * @instance
         */
        SigningUser.prototype.username = "";

        /**
         * SigningUser planName.
         * @member {string} planName
         * @memberof Proto.SigningUser
         * @instance
         */
        SigningUser.prototype.planName = "";

        /**
         * SigningUser planCount.
         * @member {number} planCount
         * @memberof Proto.SigningUser
         * @instance
         */
        SigningUser.prototype.planCount = 0;

        /**
         * SigningUser planRegisterDate.
         * @member {number|Long} planRegisterDate
         * @memberof Proto.SigningUser
         * @instance
         */
        SigningUser.prototype.planRegisterDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SigningUser joinCount.
         * @member {string} joinCount
         * @memberof Proto.SigningUser
         * @instance
         */
        SigningUser.prototype.joinCount = "";

        /**
         * SigningUser registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.SigningUser
         * @instance
         */
        SigningUser.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SigningUser modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.SigningUser
         * @instance
         */
        SigningUser.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new SigningUser instance using the specified properties.
         * @function create
         * @memberof Proto.SigningUser
         * @static
         * @param {Proto.ISigningUser=} [properties] Properties to set
         * @returns {Proto.SigningUser} SigningUser instance
         */
        SigningUser.create = function create(properties) {
            return new SigningUser(properties);
        };

        /**
         * Encodes the specified SigningUser message. Does not implicitly {@link Proto.SigningUser.verify|verify} messages.
         * @function encode
         * @memberof Proto.SigningUser
         * @static
         * @param {Proto.ISigningUser} m SigningUser message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SigningUser.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.iconUrl != null && Object.hasOwnProperty.call(m, "iconUrl"))
                w.uint32(18).string(m.iconUrl);
            if (m.username != null && Object.hasOwnProperty.call(m, "username"))
                w.uint32(26).string(m.username);
            if (m.planName != null && Object.hasOwnProperty.call(m, "planName"))
                w.uint32(34).string(m.planName);
            if (m.planCount != null && Object.hasOwnProperty.call(m, "planCount"))
                w.uint32(40).int32(m.planCount);
            if (m.planRegisterDate != null && Object.hasOwnProperty.call(m, "planRegisterDate"))
                w.uint32(48).int64(m.planRegisterDate);
            if (m.joinCount != null && Object.hasOwnProperty.call(m, "joinCount"))
                w.uint32(58).string(m.joinCount);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a SigningUser message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.SigningUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.SigningUser} SigningUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SigningUser.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.SigningUser();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.id = r.string();
                    break;
                case 2:
                    m.iconUrl = r.string();
                    break;
                case 3:
                    m.username = r.string();
                    break;
                case 4:
                    m.planName = r.string();
                    break;
                case 5:
                    m.planCount = r.int32();
                    break;
                case 6:
                    m.planRegisterDate = r.int64();
                    break;
                case 7:
                    m.joinCount = r.string();
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a SigningUser message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.SigningUser
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.SigningUser} SigningUser
         */
        SigningUser.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.SigningUser)
                return d;
            var m = new $root.Proto.SigningUser();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.iconUrl != null) {
                m.iconUrl = String(d.iconUrl);
            }
            if (d.username != null) {
                m.username = String(d.username);
            }
            if (d.planName != null) {
                m.planName = String(d.planName);
            }
            if (d.planCount != null) {
                m.planCount = d.planCount | 0;
            }
            if (d.planRegisterDate != null) {
                if ($util.Long)
                    (m.planRegisterDate = $util.Long.fromValue(d.planRegisterDate)).unsigned = false;
                else if (typeof d.planRegisterDate === "string")
                    m.planRegisterDate = parseInt(d.planRegisterDate, 10);
                else if (typeof d.planRegisterDate === "number")
                    m.planRegisterDate = d.planRegisterDate;
                else if (typeof d.planRegisterDate === "object")
                    m.planRegisterDate = new $util.LongBits(d.planRegisterDate.low >>> 0, d.planRegisterDate.high >>> 0).toNumber();
            }
            if (d.joinCount != null) {
                m.joinCount = String(d.joinCount);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a SigningUser message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.SigningUser
         * @static
         * @param {Proto.SigningUser} m SigningUser
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SigningUser.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.iconUrl = "";
                d.username = "";
                d.planName = "";
                d.planCount = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.planRegisterDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.planRegisterDate = o.longs === String ? "0" : 0;
                d.joinCount = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.iconUrl != null && m.hasOwnProperty("iconUrl")) {
                d.iconUrl = m.iconUrl;
            }
            if (m.username != null && m.hasOwnProperty("username")) {
                d.username = m.username;
            }
            if (m.planName != null && m.hasOwnProperty("planName")) {
                d.planName = m.planName;
            }
            if (m.planCount != null && m.hasOwnProperty("planCount")) {
                d.planCount = m.planCount;
            }
            if (m.planRegisterDate != null && m.hasOwnProperty("planRegisterDate")) {
                if (typeof m.planRegisterDate === "number")
                    d.planRegisterDate = o.longs === String ? String(m.planRegisterDate) : m.planRegisterDate;
                else
                    d.planRegisterDate = o.longs === String ? $util.Long.prototype.toString.call(m.planRegisterDate) : o.longs === Number ? new $util.LongBits(m.planRegisterDate.low >>> 0, m.planRegisterDate.high >>> 0).toNumber() : m.planRegisterDate;
            }
            if (m.joinCount != null && m.hasOwnProperty("joinCount")) {
                d.joinCount = m.joinCount;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this SigningUser to JSON.
         * @function toJSON
         * @memberof Proto.SigningUser
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SigningUser.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SigningUser;
    })();

    Proto.UserInfo = (function() {

        /**
         * Properties of a UserInfo.
         * @memberof Proto
         * @interface IUserInfo
         * @property {string|null} [userUID] UserInfo userUID
         * @property {string|null} [infoUID] UserInfo infoUID
         * @property {string|null} [text] UserInfo text
         * @property {number|null} [number] UserInfo number
         * @property {number|Long|null} [date] UserInfo date
         * @property {number|Long|null} [registerDate] UserInfo registerDate
         * @property {number|Long|null} [modifyDate] UserInfo modifyDate
         * @property {Proto.IUploadFile|null} [file] UserInfo file
         * @property {string|null} [additional] UserInfo additional
         * @property {boolean|null} [readonly] UserInfo readonly
         */

        /**
         * Constructs a new UserInfo.
         * @memberof Proto
         * @classdesc Represents a UserInfo.
         * @implements IUserInfo
         * @constructor
         * @param {Proto.IUserInfo=} [p] Properties to set
         */
        function UserInfo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserInfo userUID.
         * @member {string} userUID
         * @memberof Proto.UserInfo
         * @instance
         */
        UserInfo.prototype.userUID = "";

        /**
         * UserInfo infoUID.
         * @member {string} infoUID
         * @memberof Proto.UserInfo
         * @instance
         */
        UserInfo.prototype.infoUID = "";

        /**
         * UserInfo text.
         * @member {string} text
         * @memberof Proto.UserInfo
         * @instance
         */
        UserInfo.prototype.text = "";

        /**
         * UserInfo number.
         * @member {number} number
         * @memberof Proto.UserInfo
         * @instance
         */
        UserInfo.prototype.number = 0;

        /**
         * UserInfo date.
         * @member {number|Long} date
         * @memberof Proto.UserInfo
         * @instance
         */
        UserInfo.prototype.date = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UserInfo registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.UserInfo
         * @instance
         */
        UserInfo.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UserInfo modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.UserInfo
         * @instance
         */
        UserInfo.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UserInfo file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.UserInfo
         * @instance
         */
        UserInfo.prototype.file = null;

        /**
         * UserInfo additional.
         * @member {string} additional
         * @memberof Proto.UserInfo
         * @instance
         */
        UserInfo.prototype.additional = "";

        /**
         * UserInfo readonly.
         * @member {boolean} readonly
         * @memberof Proto.UserInfo
         * @instance
         */
        UserInfo.prototype.readonly = false;

        /**
         * Creates a new UserInfo instance using the specified properties.
         * @function create
         * @memberof Proto.UserInfo
         * @static
         * @param {Proto.IUserInfo=} [properties] Properties to set
         * @returns {Proto.UserInfo} UserInfo instance
         */
        UserInfo.create = function create(properties) {
            return new UserInfo(properties);
        };

        /**
         * Encodes the specified UserInfo message. Does not implicitly {@link Proto.UserInfo.verify|verify} messages.
         * @function encode
         * @memberof Proto.UserInfo
         * @static
         * @param {Proto.IUserInfo} m UserInfo message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserInfo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(10).string(m.userUID);
            if (m.infoUID != null && Object.hasOwnProperty.call(m, "infoUID"))
                w.uint32(18).string(m.infoUID);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.number != null && Object.hasOwnProperty.call(m, "number"))
                w.uint32(32).int32(m.number);
            if (m.date != null && Object.hasOwnProperty.call(m, "date"))
                w.uint32(40).int64(m.date);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(66).fork()).ldelim();
            if (m.additional != null && Object.hasOwnProperty.call(m, "additional"))
                w.uint32(74).string(m.additional);
            if (m.readonly != null && Object.hasOwnProperty.call(m, "readonly"))
                w.uint32(80).bool(m.readonly);
            return w;
        };

        /**
         * Decodes a UserInfo message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.UserInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.UserInfo} UserInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserInfo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.UserInfo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.userUID = r.string();
                    break;
                case 2:
                    m.infoUID = r.string();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.number = r.int32();
                    break;
                case 5:
                    m.date = r.int64();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 9:
                    m.additional = r.string();
                    break;
                case 10:
                    m.readonly = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.UserInfo
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.UserInfo} UserInfo
         */
        UserInfo.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.UserInfo)
                return d;
            var m = new $root.Proto.UserInfo();
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.infoUID != null) {
                m.infoUID = String(d.infoUID);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.number != null) {
                m.number = d.number | 0;
            }
            if (d.date != null) {
                if ($util.Long)
                    (m.date = $util.Long.fromValue(d.date)).unsigned = false;
                else if (typeof d.date === "string")
                    m.date = parseInt(d.date, 10);
                else if (typeof d.date === "number")
                    m.date = d.date;
                else if (typeof d.date === "object")
                    m.date = new $util.LongBits(d.date.low >>> 0, d.date.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.UserInfo.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            if (d.additional != null) {
                m.additional = String(d.additional);
            }
            if (d.readonly != null) {
                m.readonly = Boolean(d.readonly);
            }
            return m;
        };

        /**
         * Creates a plain object from a UserInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.UserInfo
         * @static
         * @param {Proto.UserInfo} m UserInfo
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserInfo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.userUID = "";
                d.infoUID = "";
                d.text = "";
                d.number = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.date = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.date = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.file = null;
                d.additional = "";
                d.readonly = false;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.infoUID != null && m.hasOwnProperty("infoUID")) {
                d.infoUID = m.infoUID;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.number != null && m.hasOwnProperty("number")) {
                d.number = m.number;
            }
            if (m.date != null && m.hasOwnProperty("date")) {
                if (typeof m.date === "number")
                    d.date = o.longs === String ? String(m.date) : m.date;
                else
                    d.date = o.longs === String ? $util.Long.prototype.toString.call(m.date) : o.longs === Number ? new $util.LongBits(m.date.low >>> 0, m.date.high >>> 0).toNumber() : m.date;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            if (m.additional != null && m.hasOwnProperty("additional")) {
                d.additional = m.additional;
            }
            if (m.readonly != null && m.hasOwnProperty("readonly")) {
                d.readonly = m.readonly;
            }
            return d;
        };

        /**
         * Converts this UserInfo to JSON.
         * @function toJSON
         * @memberof Proto.UserInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserInfo;
    })();

    Proto.UserInfoList = (function() {

        /**
         * Properties of a UserInfoList.
         * @memberof Proto
         * @interface IUserInfoList
         * @property {Array.<Proto.IUserInfo>|null} [value] UserInfoList value
         */

        /**
         * Constructs a new UserInfoList.
         * @memberof Proto
         * @classdesc Represents a UserInfoList.
         * @implements IUserInfoList
         * @constructor
         * @param {Proto.IUserInfoList=} [p] Properties to set
         */
        function UserInfoList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserInfoList value.
         * @member {Array.<Proto.IUserInfo>} value
         * @memberof Proto.UserInfoList
         * @instance
         */
        UserInfoList.prototype.value = $util.emptyArray;

        /**
         * Creates a new UserInfoList instance using the specified properties.
         * @function create
         * @memberof Proto.UserInfoList
         * @static
         * @param {Proto.IUserInfoList=} [properties] Properties to set
         * @returns {Proto.UserInfoList} UserInfoList instance
         */
        UserInfoList.create = function create(properties) {
            return new UserInfoList(properties);
        };

        /**
         * Encodes the specified UserInfoList message. Does not implicitly {@link Proto.UserInfoList.verify|verify} messages.
         * @function encode
         * @memberof Proto.UserInfoList
         * @static
         * @param {Proto.IUserInfoList} m UserInfoList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserInfoList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.UserInfo.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a UserInfoList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.UserInfoList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.UserInfoList} UserInfoList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserInfoList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.UserInfoList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.UserInfo.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserInfoList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.UserInfoList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.UserInfoList} UserInfoList
         */
        UserInfoList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.UserInfoList)
                return d;
            var m = new $root.Proto.UserInfoList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.UserInfoList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.UserInfoList.value: object expected");
                    m.value[i] = $root.Proto.UserInfo.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a UserInfoList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.UserInfoList
         * @static
         * @param {Proto.UserInfoList} m UserInfoList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserInfoList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.UserInfo.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this UserInfoList to JSON.
         * @function toJSON
         * @memberof Proto.UserInfoList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserInfoList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserInfoList;
    })();

    Proto.ContentFileStash = (function() {

        /**
         * Properties of a ContentFileStash.
         * @memberof Proto
         * @interface IContentFileStash
         * @property {string|null} [contentUID] ContentFileStash contentUID
         * @property {string|null} [fileUID] ContentFileStash fileUID
         * @property {number|null} [type] ContentFileStash type
         * @property {number|Long|null} [registerDate] ContentFileStash registerDate
         * @property {number|Long|null} [modifyDate] ContentFileStash modifyDate
         * @property {number|null} [status] ContentFileStash status
         * @property {Proto.IUploadFile|null} [file] ContentFileStash file
         */

        /**
         * Constructs a new ContentFileStash.
         * @memberof Proto
         * @classdesc Represents a ContentFileStash.
         * @implements IContentFileStash
         * @constructor
         * @param {Proto.IContentFileStash=} [p] Properties to set
         */
        function ContentFileStash(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentFileStash contentUID.
         * @member {string} contentUID
         * @memberof Proto.ContentFileStash
         * @instance
         */
        ContentFileStash.prototype.contentUID = "";

        /**
         * ContentFileStash fileUID.
         * @member {string} fileUID
         * @memberof Proto.ContentFileStash
         * @instance
         */
        ContentFileStash.prototype.fileUID = "";

        /**
         * ContentFileStash type.
         * @member {number} type
         * @memberof Proto.ContentFileStash
         * @instance
         */
        ContentFileStash.prototype.type = 0;

        /**
         * ContentFileStash registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentFileStash
         * @instance
         */
        ContentFileStash.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentFileStash modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentFileStash
         * @instance
         */
        ContentFileStash.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentFileStash status.
         * @member {number} status
         * @memberof Proto.ContentFileStash
         * @instance
         */
        ContentFileStash.prototype.status = 0;

        /**
         * ContentFileStash file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.ContentFileStash
         * @instance
         */
        ContentFileStash.prototype.file = null;

        /**
         * Creates a new ContentFileStash instance using the specified properties.
         * @function create
         * @memberof Proto.ContentFileStash
         * @static
         * @param {Proto.IContentFileStash=} [properties] Properties to set
         * @returns {Proto.ContentFileStash} ContentFileStash instance
         */
        ContentFileStash.create = function create(properties) {
            return new ContentFileStash(properties);
        };

        /**
         * Encodes the specified ContentFileStash message. Does not implicitly {@link Proto.ContentFileStash.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentFileStash
         * @static
         * @param {Proto.IContentFileStash} m ContentFileStash message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentFileStash.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(10).string(m.contentUID);
            if (m.fileUID != null && Object.hasOwnProperty.call(m, "fileUID"))
                w.uint32(18).string(m.fileUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(24).int32(m.type);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(48).int32(m.status);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(58).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a ContentFileStash message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentFileStash
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentFileStash} ContentFileStash
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentFileStash.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentFileStash();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.contentUID = r.string();
                    break;
                case 2:
                    m.fileUID = r.string();
                    break;
                case 3:
                    m.type = r.int32();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                case 6:
                    m.status = r.int32();
                    break;
                case 7:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentFileStash message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentFileStash
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentFileStash} ContentFileStash
         */
        ContentFileStash.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentFileStash)
                return d;
            var m = new $root.Proto.ContentFileStash();
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.fileUID != null) {
                m.fileUID = String(d.fileUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.ContentFileStash.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentFileStash message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentFileStash
         * @static
         * @param {Proto.ContentFileStash} m ContentFileStash
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentFileStash.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.contentUID = "";
                d.fileUID = "";
                d.type = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.file = null;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.fileUID != null && m.hasOwnProperty("fileUID")) {
                d.fileUID = m.fileUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            return d;
        };

        /**
         * Converts this ContentFileStash to JSON.
         * @function toJSON
         * @memberof Proto.ContentFileStash
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentFileStash.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentFileStash;
    })();

    Proto.ContentFileStashList = (function() {

        /**
         * Properties of a ContentFileStashList.
         * @memberof Proto
         * @interface IContentFileStashList
         * @property {Array.<Proto.IContentFileStash>|null} [value] ContentFileStashList value
         */

        /**
         * Constructs a new ContentFileStashList.
         * @memberof Proto
         * @classdesc Represents a ContentFileStashList.
         * @implements IContentFileStashList
         * @constructor
         * @param {Proto.IContentFileStashList=} [p] Properties to set
         */
        function ContentFileStashList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentFileStashList value.
         * @member {Array.<Proto.IContentFileStash>} value
         * @memberof Proto.ContentFileStashList
         * @instance
         */
        ContentFileStashList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentFileStashList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentFileStashList
         * @static
         * @param {Proto.IContentFileStashList=} [properties] Properties to set
         * @returns {Proto.ContentFileStashList} ContentFileStashList instance
         */
        ContentFileStashList.create = function create(properties) {
            return new ContentFileStashList(properties);
        };

        /**
         * Encodes the specified ContentFileStashList message. Does not implicitly {@link Proto.ContentFileStashList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentFileStashList
         * @static
         * @param {Proto.IContentFileStashList} m ContentFileStashList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentFileStashList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentFileStash.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentFileStashList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentFileStashList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentFileStashList} ContentFileStashList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentFileStashList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentFileStashList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentFileStash.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentFileStashList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentFileStashList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentFileStashList} ContentFileStashList
         */
        ContentFileStashList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentFileStashList)
                return d;
            var m = new $root.Proto.ContentFileStashList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentFileStashList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentFileStashList.value: object expected");
                    m.value[i] = $root.Proto.ContentFileStash.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentFileStashList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentFileStashList
         * @static
         * @param {Proto.ContentFileStashList} m ContentFileStashList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentFileStashList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentFileStash.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentFileStashList to JSON.
         * @function toJSON
         * @memberof Proto.ContentFileStashList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentFileStashList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentFileStashList;
    })();

    Proto.CommerceCheckout = (function() {

        /**
         * Properties of a CommerceCheckout.
         * @memberof Proto
         * @interface ICommerceCheckout
         * @property {number|null} [quantity] CommerceCheckout quantity
         * @property {string|null} [postalCode] CommerceCheckout postalCode
         * @property {string|null} [state] CommerceCheckout state
         * @property {string|null} [city] CommerceCheckout city
         * @property {string|null} [address] CommerceCheckout address
         * @property {string|null} [country] CommerceCheckout country
         * @property {string|null} [name] CommerceCheckout name
         * @property {number|null} [feeCode] CommerceCheckout feeCode
         * @property {string|null} [phone] CommerceCheckout phone
         * @property {string|null} [addressDetail] CommerceCheckout addressDetail
         * @property {string|null} [contentUID] CommerceCheckout contentUID
         * @property {string|null} [paymentMethodId] CommerceCheckout paymentMethodId
         * @property {boolean|null} [saveCard] CommerceCheckout saveCard
         * @property {number|null} [paymentType] CommerceCheckout paymentType
         */

        /**
         * Constructs a new CommerceCheckout.
         * @memberof Proto
         * @classdesc Represents a CommerceCheckout.
         * @implements ICommerceCheckout
         * @constructor
         * @param {Proto.ICommerceCheckout=} [p] Properties to set
         */
        function CommerceCheckout(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommerceCheckout quantity.
         * @member {number} quantity
         * @memberof Proto.CommerceCheckout
         * @instance
         */
        CommerceCheckout.prototype.quantity = 0;

        /**
         * CommerceCheckout postalCode.
         * @member {string} postalCode
         * @memberof Proto.CommerceCheckout
         * @instance
         */
        CommerceCheckout.prototype.postalCode = "";

        /**
         * CommerceCheckout state.
         * @member {string} state
         * @memberof Proto.CommerceCheckout
         * @instance
         */
        CommerceCheckout.prototype.state = "";

        /**
         * CommerceCheckout city.
         * @member {string} city
         * @memberof Proto.CommerceCheckout
         * @instance
         */
        CommerceCheckout.prototype.city = "";

        /**
         * CommerceCheckout address.
         * @member {string} address
         * @memberof Proto.CommerceCheckout
         * @instance
         */
        CommerceCheckout.prototype.address = "";

        /**
         * CommerceCheckout country.
         * @member {string} country
         * @memberof Proto.CommerceCheckout
         * @instance
         */
        CommerceCheckout.prototype.country = "";

        /**
         * CommerceCheckout name.
         * @member {string} name
         * @memberof Proto.CommerceCheckout
         * @instance
         */
        CommerceCheckout.prototype.name = "";

        /**
         * CommerceCheckout feeCode.
         * @member {number} feeCode
         * @memberof Proto.CommerceCheckout
         * @instance
         */
        CommerceCheckout.prototype.feeCode = 0;

        /**
         * CommerceCheckout phone.
         * @member {string} phone
         * @memberof Proto.CommerceCheckout
         * @instance
         */
        CommerceCheckout.prototype.phone = "";

        /**
         * CommerceCheckout addressDetail.
         * @member {string} addressDetail
         * @memberof Proto.CommerceCheckout
         * @instance
         */
        CommerceCheckout.prototype.addressDetail = "";

        /**
         * CommerceCheckout contentUID.
         * @member {string} contentUID
         * @memberof Proto.CommerceCheckout
         * @instance
         */
        CommerceCheckout.prototype.contentUID = "";

        /**
         * CommerceCheckout paymentMethodId.
         * @member {string} paymentMethodId
         * @memberof Proto.CommerceCheckout
         * @instance
         */
        CommerceCheckout.prototype.paymentMethodId = "";

        /**
         * CommerceCheckout saveCard.
         * @member {boolean} saveCard
         * @memberof Proto.CommerceCheckout
         * @instance
         */
        CommerceCheckout.prototype.saveCard = false;

        /**
         * CommerceCheckout paymentType.
         * @member {number} paymentType
         * @memberof Proto.CommerceCheckout
         * @instance
         */
        CommerceCheckout.prototype.paymentType = 0;

        /**
         * Creates a new CommerceCheckout instance using the specified properties.
         * @function create
         * @memberof Proto.CommerceCheckout
         * @static
         * @param {Proto.ICommerceCheckout=} [properties] Properties to set
         * @returns {Proto.CommerceCheckout} CommerceCheckout instance
         */
        CommerceCheckout.create = function create(properties) {
            return new CommerceCheckout(properties);
        };

        /**
         * Encodes the specified CommerceCheckout message. Does not implicitly {@link Proto.CommerceCheckout.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommerceCheckout
         * @static
         * @param {Proto.ICommerceCheckout} m CommerceCheckout message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommerceCheckout.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.quantity != null && Object.hasOwnProperty.call(m, "quantity"))
                w.uint32(8).int32(m.quantity);
            if (m.postalCode != null && Object.hasOwnProperty.call(m, "postalCode"))
                w.uint32(18).string(m.postalCode);
            if (m.state != null && Object.hasOwnProperty.call(m, "state"))
                w.uint32(26).string(m.state);
            if (m.city != null && Object.hasOwnProperty.call(m, "city"))
                w.uint32(34).string(m.city);
            if (m.address != null && Object.hasOwnProperty.call(m, "address"))
                w.uint32(42).string(m.address);
            if (m.country != null && Object.hasOwnProperty.call(m, "country"))
                w.uint32(50).string(m.country);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(58).string(m.name);
            if (m.feeCode != null && Object.hasOwnProperty.call(m, "feeCode"))
                w.uint32(64).int32(m.feeCode);
            if (m.phone != null && Object.hasOwnProperty.call(m, "phone"))
                w.uint32(74).string(m.phone);
            if (m.addressDetail != null && Object.hasOwnProperty.call(m, "addressDetail"))
                w.uint32(82).string(m.addressDetail);
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(90).string(m.contentUID);
            if (m.paymentMethodId != null && Object.hasOwnProperty.call(m, "paymentMethodId"))
                w.uint32(98).string(m.paymentMethodId);
            if (m.saveCard != null && Object.hasOwnProperty.call(m, "saveCard"))
                w.uint32(104).bool(m.saveCard);
            if (m.paymentType != null && Object.hasOwnProperty.call(m, "paymentType"))
                w.uint32(112).int32(m.paymentType);
            return w;
        };

        /**
         * Decodes a CommerceCheckout message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommerceCheckout
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommerceCheckout} CommerceCheckout
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommerceCheckout.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommerceCheckout();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.quantity = r.int32();
                    break;
                case 2:
                    m.postalCode = r.string();
                    break;
                case 3:
                    m.state = r.string();
                    break;
                case 4:
                    m.city = r.string();
                    break;
                case 5:
                    m.address = r.string();
                    break;
                case 6:
                    m.country = r.string();
                    break;
                case 7:
                    m.name = r.string();
                    break;
                case 8:
                    m.feeCode = r.int32();
                    break;
                case 9:
                    m.phone = r.string();
                    break;
                case 10:
                    m.addressDetail = r.string();
                    break;
                case 11:
                    m.contentUID = r.string();
                    break;
                case 12:
                    m.paymentMethodId = r.string();
                    break;
                case 13:
                    m.saveCard = r.bool();
                    break;
                case 14:
                    m.paymentType = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommerceCheckout message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommerceCheckout
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommerceCheckout} CommerceCheckout
         */
        CommerceCheckout.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommerceCheckout)
                return d;
            var m = new $root.Proto.CommerceCheckout();
            if (d.quantity != null) {
                m.quantity = d.quantity | 0;
            }
            if (d.postalCode != null) {
                m.postalCode = String(d.postalCode);
            }
            if (d.state != null) {
                m.state = String(d.state);
            }
            if (d.city != null) {
                m.city = String(d.city);
            }
            if (d.address != null) {
                m.address = String(d.address);
            }
            if (d.country != null) {
                m.country = String(d.country);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.feeCode != null) {
                m.feeCode = d.feeCode | 0;
            }
            if (d.phone != null) {
                m.phone = String(d.phone);
            }
            if (d.addressDetail != null) {
                m.addressDetail = String(d.addressDetail);
            }
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.paymentMethodId != null) {
                m.paymentMethodId = String(d.paymentMethodId);
            }
            if (d.saveCard != null) {
                m.saveCard = Boolean(d.saveCard);
            }
            if (d.paymentType != null) {
                m.paymentType = d.paymentType | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a CommerceCheckout message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommerceCheckout
         * @static
         * @param {Proto.CommerceCheckout} m CommerceCheckout
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommerceCheckout.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.quantity = 0;
                d.postalCode = "";
                d.state = "";
                d.city = "";
                d.address = "";
                d.country = "";
                d.name = "";
                d.feeCode = 0;
                d.phone = "";
                d.addressDetail = "";
                d.contentUID = "";
                d.paymentMethodId = "";
                d.saveCard = false;
                d.paymentType = 0;
            }
            if (m.quantity != null && m.hasOwnProperty("quantity")) {
                d.quantity = m.quantity;
            }
            if (m.postalCode != null && m.hasOwnProperty("postalCode")) {
                d.postalCode = m.postalCode;
            }
            if (m.state != null && m.hasOwnProperty("state")) {
                d.state = m.state;
            }
            if (m.city != null && m.hasOwnProperty("city")) {
                d.city = m.city;
            }
            if (m.address != null && m.hasOwnProperty("address")) {
                d.address = m.address;
            }
            if (m.country != null && m.hasOwnProperty("country")) {
                d.country = m.country;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.feeCode != null && m.hasOwnProperty("feeCode")) {
                d.feeCode = m.feeCode;
            }
            if (m.phone != null && m.hasOwnProperty("phone")) {
                d.phone = m.phone;
            }
            if (m.addressDetail != null && m.hasOwnProperty("addressDetail")) {
                d.addressDetail = m.addressDetail;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.paymentMethodId != null && m.hasOwnProperty("paymentMethodId")) {
                d.paymentMethodId = m.paymentMethodId;
            }
            if (m.saveCard != null && m.hasOwnProperty("saveCard")) {
                d.saveCard = m.saveCard;
            }
            if (m.paymentType != null && m.hasOwnProperty("paymentType")) {
                d.paymentType = m.paymentType;
            }
            return d;
        };

        /**
         * Converts this CommerceCheckout to JSON.
         * @function toJSON
         * @memberof Proto.CommerceCheckout
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommerceCheckout.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommerceCheckout;
    })();

    Proto.CommerceService = (function() {

        /**
         * Properties of a CommerceService.
         * @memberof Proto
         * @interface ICommerceService
         * @property {string|null} [publicAPI] CommerceService publicAPI
         * @property {string|null} [clientSecret] CommerceService clientSecret
         * @property {string|null} [callbackUrl] CommerceService callbackUrl
         * @property {Proto.ICommerceCheckout|null} [checkout] CommerceService checkout
         * @property {Proto.IContentCommerceVariationProduct|null} [product] CommerceService product
         * @property {string|null} [paymentId] CommerceService paymentId
         * @property {Array.<string>|null} [orderUIDList] CommerceService orderUIDList
         * @property {number|null} [orderStatus] CommerceService orderStatus
         * @property {Array.<Proto.ICommerceServiceCard>|null} [cardList] CommerceService cardList
         * @property {Proto.IFundingVariation|null} [fundingVariation] CommerceService fundingVariation
         * @property {Proto.ITicketVariationScheduleDateUser|null} [ticketDateUser] CommerceService ticketDateUser
         * @property {Proto.ITicketVariation|null} [ticketVariation] CommerceService ticketVariation
         * @property {number|null} [thirdType] CommerceService thirdType
         * @property {string|null} [usedPoint] CommerceService usedPoint
         */

        /**
         * Constructs a new CommerceService.
         * @memberof Proto
         * @classdesc Represents a CommerceService.
         * @implements ICommerceService
         * @constructor
         * @param {Proto.ICommerceService=} [p] Properties to set
         */
        function CommerceService(p) {
            this.orderUIDList = [];
            this.cardList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommerceService publicAPI.
         * @member {string} publicAPI
         * @memberof Proto.CommerceService
         * @instance
         */
        CommerceService.prototype.publicAPI = "";

        /**
         * CommerceService clientSecret.
         * @member {string} clientSecret
         * @memberof Proto.CommerceService
         * @instance
         */
        CommerceService.prototype.clientSecret = "";

        /**
         * CommerceService callbackUrl.
         * @member {string} callbackUrl
         * @memberof Proto.CommerceService
         * @instance
         */
        CommerceService.prototype.callbackUrl = "";

        /**
         * CommerceService checkout.
         * @member {Proto.ICommerceCheckout|null|undefined} checkout
         * @memberof Proto.CommerceService
         * @instance
         */
        CommerceService.prototype.checkout = null;

        /**
         * CommerceService product.
         * @member {Proto.IContentCommerceVariationProduct|null|undefined} product
         * @memberof Proto.CommerceService
         * @instance
         */
        CommerceService.prototype.product = null;

        /**
         * CommerceService paymentId.
         * @member {string} paymentId
         * @memberof Proto.CommerceService
         * @instance
         */
        CommerceService.prototype.paymentId = "";

        /**
         * CommerceService orderUIDList.
         * @member {Array.<string>} orderUIDList
         * @memberof Proto.CommerceService
         * @instance
         */
        CommerceService.prototype.orderUIDList = $util.emptyArray;

        /**
         * CommerceService orderStatus.
         * @member {number} orderStatus
         * @memberof Proto.CommerceService
         * @instance
         */
        CommerceService.prototype.orderStatus = 0;

        /**
         * CommerceService cardList.
         * @member {Array.<Proto.ICommerceServiceCard>} cardList
         * @memberof Proto.CommerceService
         * @instance
         */
        CommerceService.prototype.cardList = $util.emptyArray;

        /**
         * CommerceService fundingVariation.
         * @member {Proto.IFundingVariation|null|undefined} fundingVariation
         * @memberof Proto.CommerceService
         * @instance
         */
        CommerceService.prototype.fundingVariation = null;

        /**
         * CommerceService ticketDateUser.
         * @member {Proto.ITicketVariationScheduleDateUser|null|undefined} ticketDateUser
         * @memberof Proto.CommerceService
         * @instance
         */
        CommerceService.prototype.ticketDateUser = null;

        /**
         * CommerceService ticketVariation.
         * @member {Proto.ITicketVariation|null|undefined} ticketVariation
         * @memberof Proto.CommerceService
         * @instance
         */
        CommerceService.prototype.ticketVariation = null;

        /**
         * CommerceService thirdType.
         * @member {number} thirdType
         * @memberof Proto.CommerceService
         * @instance
         */
        CommerceService.prototype.thirdType = 0;

        /**
         * CommerceService usedPoint.
         * @member {string} usedPoint
         * @memberof Proto.CommerceService
         * @instance
         */
        CommerceService.prototype.usedPoint = "";

        /**
         * Creates a new CommerceService instance using the specified properties.
         * @function create
         * @memberof Proto.CommerceService
         * @static
         * @param {Proto.ICommerceService=} [properties] Properties to set
         * @returns {Proto.CommerceService} CommerceService instance
         */
        CommerceService.create = function create(properties) {
            return new CommerceService(properties);
        };

        /**
         * Encodes the specified CommerceService message. Does not implicitly {@link Proto.CommerceService.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommerceService
         * @static
         * @param {Proto.ICommerceService} m CommerceService message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommerceService.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.publicAPI != null && Object.hasOwnProperty.call(m, "publicAPI"))
                w.uint32(10).string(m.publicAPI);
            if (m.clientSecret != null && Object.hasOwnProperty.call(m, "clientSecret"))
                w.uint32(18).string(m.clientSecret);
            if (m.callbackUrl != null && Object.hasOwnProperty.call(m, "callbackUrl"))
                w.uint32(26).string(m.callbackUrl);
            if (m.checkout != null && Object.hasOwnProperty.call(m, "checkout"))
                $root.Proto.CommerceCheckout.encode(m.checkout, w.uint32(34).fork()).ldelim();
            if (m.product != null && Object.hasOwnProperty.call(m, "product"))
                $root.Proto.ContentCommerceVariationProduct.encode(m.product, w.uint32(42).fork()).ldelim();
            if (m.paymentId != null && Object.hasOwnProperty.call(m, "paymentId"))
                w.uint32(50).string(m.paymentId);
            if (m.orderUIDList != null && m.orderUIDList.length) {
                for (var i = 0; i < m.orderUIDList.length; ++i)
                    w.uint32(58).string(m.orderUIDList[i]);
            }
            if (m.orderStatus != null && Object.hasOwnProperty.call(m, "orderStatus"))
                w.uint32(64).int32(m.orderStatus);
            if (m.cardList != null && m.cardList.length) {
                for (var i = 0; i < m.cardList.length; ++i)
                    $root.Proto.CommerceServiceCard.encode(m.cardList[i], w.uint32(74).fork()).ldelim();
            }
            if (m.fundingVariation != null && Object.hasOwnProperty.call(m, "fundingVariation"))
                $root.Proto.FundingVariation.encode(m.fundingVariation, w.uint32(82).fork()).ldelim();
            if (m.ticketDateUser != null && Object.hasOwnProperty.call(m, "ticketDateUser"))
                $root.Proto.TicketVariationScheduleDateUser.encode(m.ticketDateUser, w.uint32(90).fork()).ldelim();
            if (m.ticketVariation != null && Object.hasOwnProperty.call(m, "ticketVariation"))
                $root.Proto.TicketVariation.encode(m.ticketVariation, w.uint32(98).fork()).ldelim();
            if (m.thirdType != null && Object.hasOwnProperty.call(m, "thirdType"))
                w.uint32(104).int32(m.thirdType);
            if (m.usedPoint != null && Object.hasOwnProperty.call(m, "usedPoint"))
                w.uint32(114).string(m.usedPoint);
            return w;
        };

        /**
         * Decodes a CommerceService message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommerceService
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommerceService} CommerceService
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommerceService.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommerceService();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.publicAPI = r.string();
                    break;
                case 2:
                    m.clientSecret = r.string();
                    break;
                case 3:
                    m.callbackUrl = r.string();
                    break;
                case 4:
                    m.checkout = $root.Proto.CommerceCheckout.decode(r, r.uint32());
                    break;
                case 5:
                    m.product = $root.Proto.ContentCommerceVariationProduct.decode(r, r.uint32());
                    break;
                case 6:
                    m.paymentId = r.string();
                    break;
                case 7:
                    if (!(m.orderUIDList && m.orderUIDList.length))
                        m.orderUIDList = [];
                    m.orderUIDList.push(r.string());
                    break;
                case 8:
                    m.orderStatus = r.int32();
                    break;
                case 9:
                    if (!(m.cardList && m.cardList.length))
                        m.cardList = [];
                    m.cardList.push($root.Proto.CommerceServiceCard.decode(r, r.uint32()));
                    break;
                case 10:
                    m.fundingVariation = $root.Proto.FundingVariation.decode(r, r.uint32());
                    break;
                case 11:
                    m.ticketDateUser = $root.Proto.TicketVariationScheduleDateUser.decode(r, r.uint32());
                    break;
                case 12:
                    m.ticketVariation = $root.Proto.TicketVariation.decode(r, r.uint32());
                    break;
                case 13:
                    m.thirdType = r.int32();
                    break;
                case 14:
                    m.usedPoint = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommerceService message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommerceService
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommerceService} CommerceService
         */
        CommerceService.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommerceService)
                return d;
            var m = new $root.Proto.CommerceService();
            if (d.publicAPI != null) {
                m.publicAPI = String(d.publicAPI);
            }
            if (d.clientSecret != null) {
                m.clientSecret = String(d.clientSecret);
            }
            if (d.callbackUrl != null) {
                m.callbackUrl = String(d.callbackUrl);
            }
            if (d.checkout != null) {
                if (typeof d.checkout !== "object")
                    throw TypeError(".Proto.CommerceService.checkout: object expected");
                m.checkout = $root.Proto.CommerceCheckout.fromObject(d.checkout);
            }
            if (d.product != null) {
                if (typeof d.product !== "object")
                    throw TypeError(".Proto.CommerceService.product: object expected");
                m.product = $root.Proto.ContentCommerceVariationProduct.fromObject(d.product);
            }
            if (d.paymentId != null) {
                m.paymentId = String(d.paymentId);
            }
            if (d.orderUIDList) {
                if (!Array.isArray(d.orderUIDList))
                    throw TypeError(".Proto.CommerceService.orderUIDList: array expected");
                m.orderUIDList = [];
                for (var i = 0; i < d.orderUIDList.length; ++i) {
                    m.orderUIDList[i] = String(d.orderUIDList[i]);
                }
            }
            if (d.orderStatus != null) {
                m.orderStatus = d.orderStatus | 0;
            }
            if (d.cardList) {
                if (!Array.isArray(d.cardList))
                    throw TypeError(".Proto.CommerceService.cardList: array expected");
                m.cardList = [];
                for (var i = 0; i < d.cardList.length; ++i) {
                    if (typeof d.cardList[i] !== "object")
                        throw TypeError(".Proto.CommerceService.cardList: object expected");
                    m.cardList[i] = $root.Proto.CommerceServiceCard.fromObject(d.cardList[i]);
                }
            }
            if (d.fundingVariation != null) {
                if (typeof d.fundingVariation !== "object")
                    throw TypeError(".Proto.CommerceService.fundingVariation: object expected");
                m.fundingVariation = $root.Proto.FundingVariation.fromObject(d.fundingVariation);
            }
            if (d.ticketDateUser != null) {
                if (typeof d.ticketDateUser !== "object")
                    throw TypeError(".Proto.CommerceService.ticketDateUser: object expected");
                m.ticketDateUser = $root.Proto.TicketVariationScheduleDateUser.fromObject(d.ticketDateUser);
            }
            if (d.ticketVariation != null) {
                if (typeof d.ticketVariation !== "object")
                    throw TypeError(".Proto.CommerceService.ticketVariation: object expected");
                m.ticketVariation = $root.Proto.TicketVariation.fromObject(d.ticketVariation);
            }
            if (d.thirdType != null) {
                m.thirdType = d.thirdType | 0;
            }
            if (d.usedPoint != null) {
                m.usedPoint = String(d.usedPoint);
            }
            return m;
        };

        /**
         * Creates a plain object from a CommerceService message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommerceService
         * @static
         * @param {Proto.CommerceService} m CommerceService
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommerceService.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.orderUIDList = [];
                d.cardList = [];
            }
            if (o.defaults) {
                d.publicAPI = "";
                d.clientSecret = "";
                d.callbackUrl = "";
                d.checkout = null;
                d.product = null;
                d.paymentId = "";
                d.orderStatus = 0;
                d.fundingVariation = null;
                d.ticketDateUser = null;
                d.ticketVariation = null;
                d.thirdType = 0;
                d.usedPoint = "";
            }
            if (m.publicAPI != null && m.hasOwnProperty("publicAPI")) {
                d.publicAPI = m.publicAPI;
            }
            if (m.clientSecret != null && m.hasOwnProperty("clientSecret")) {
                d.clientSecret = m.clientSecret;
            }
            if (m.callbackUrl != null && m.hasOwnProperty("callbackUrl")) {
                d.callbackUrl = m.callbackUrl;
            }
            if (m.checkout != null && m.hasOwnProperty("checkout")) {
                d.checkout = $root.Proto.CommerceCheckout.toObject(m.checkout, o);
            }
            if (m.product != null && m.hasOwnProperty("product")) {
                d.product = $root.Proto.ContentCommerceVariationProduct.toObject(m.product, o);
            }
            if (m.paymentId != null && m.hasOwnProperty("paymentId")) {
                d.paymentId = m.paymentId;
            }
            if (m.orderUIDList && m.orderUIDList.length) {
                d.orderUIDList = [];
                for (var j = 0; j < m.orderUIDList.length; ++j) {
                    d.orderUIDList[j] = m.orderUIDList[j];
                }
            }
            if (m.orderStatus != null && m.hasOwnProperty("orderStatus")) {
                d.orderStatus = m.orderStatus;
            }
            if (m.cardList && m.cardList.length) {
                d.cardList = [];
                for (var j = 0; j < m.cardList.length; ++j) {
                    d.cardList[j] = $root.Proto.CommerceServiceCard.toObject(m.cardList[j], o);
                }
            }
            if (m.fundingVariation != null && m.hasOwnProperty("fundingVariation")) {
                d.fundingVariation = $root.Proto.FundingVariation.toObject(m.fundingVariation, o);
            }
            if (m.ticketDateUser != null && m.hasOwnProperty("ticketDateUser")) {
                d.ticketDateUser = $root.Proto.TicketVariationScheduleDateUser.toObject(m.ticketDateUser, o);
            }
            if (m.ticketVariation != null && m.hasOwnProperty("ticketVariation")) {
                d.ticketVariation = $root.Proto.TicketVariation.toObject(m.ticketVariation, o);
            }
            if (m.thirdType != null && m.hasOwnProperty("thirdType")) {
                d.thirdType = m.thirdType;
            }
            if (m.usedPoint != null && m.hasOwnProperty("usedPoint")) {
                d.usedPoint = m.usedPoint;
            }
            return d;
        };

        /**
         * Converts this CommerceService to JSON.
         * @function toJSON
         * @memberof Proto.CommerceService
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommerceService.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommerceService;
    })();

    Proto.CommerceServiceCard = (function() {

        /**
         * Properties of a CommerceServiceCard.
         * @memberof Proto
         * @interface ICommerceServiceCard
         * @property {string|null} [id] CommerceServiceCard id
         * @property {string|null} [type] CommerceServiceCard type
         * @property {string|null} [country] CommerceServiceCard country
         * @property {string|null} [card] CommerceServiceCard card
         * @property {string|null} [customer] CommerceServiceCard customer
         * @property {Proto.CommerceServiceCard.IThreeDSecure|null} [threeDSecure] CommerceServiceCard threeDSecure
         */

        /**
         * Constructs a new CommerceServiceCard.
         * @memberof Proto
         * @classdesc Represents a CommerceServiceCard.
         * @implements ICommerceServiceCard
         * @constructor
         * @param {Proto.ICommerceServiceCard=} [p] Properties to set
         */
        function CommerceServiceCard(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommerceServiceCard id.
         * @member {string} id
         * @memberof Proto.CommerceServiceCard
         * @instance
         */
        CommerceServiceCard.prototype.id = "";

        /**
         * CommerceServiceCard type.
         * @member {string} type
         * @memberof Proto.CommerceServiceCard
         * @instance
         */
        CommerceServiceCard.prototype.type = "";

        /**
         * CommerceServiceCard country.
         * @member {string} country
         * @memberof Proto.CommerceServiceCard
         * @instance
         */
        CommerceServiceCard.prototype.country = "";

        /**
         * CommerceServiceCard card.
         * @member {string} card
         * @memberof Proto.CommerceServiceCard
         * @instance
         */
        CommerceServiceCard.prototype.card = "";

        /**
         * CommerceServiceCard customer.
         * @member {string} customer
         * @memberof Proto.CommerceServiceCard
         * @instance
         */
        CommerceServiceCard.prototype.customer = "";

        /**
         * CommerceServiceCard threeDSecure.
         * @member {Proto.CommerceServiceCard.IThreeDSecure|null|undefined} threeDSecure
         * @memberof Proto.CommerceServiceCard
         * @instance
         */
        CommerceServiceCard.prototype.threeDSecure = null;

        /**
         * Creates a new CommerceServiceCard instance using the specified properties.
         * @function create
         * @memberof Proto.CommerceServiceCard
         * @static
         * @param {Proto.ICommerceServiceCard=} [properties] Properties to set
         * @returns {Proto.CommerceServiceCard} CommerceServiceCard instance
         */
        CommerceServiceCard.create = function create(properties) {
            return new CommerceServiceCard(properties);
        };

        /**
         * Encodes the specified CommerceServiceCard message. Does not implicitly {@link Proto.CommerceServiceCard.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommerceServiceCard
         * @static
         * @param {Proto.ICommerceServiceCard} m CommerceServiceCard message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommerceServiceCard.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(18).string(m.type);
            if (m.country != null && Object.hasOwnProperty.call(m, "country"))
                w.uint32(26).string(m.country);
            if (m.card != null && Object.hasOwnProperty.call(m, "card"))
                w.uint32(34).string(m.card);
            if (m.customer != null && Object.hasOwnProperty.call(m, "customer"))
                w.uint32(42).string(m.customer);
            if (m.threeDSecure != null && Object.hasOwnProperty.call(m, "threeDSecure"))
                $root.Proto.CommerceServiceCard.ThreeDSecure.encode(m.threeDSecure, w.uint32(50).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a CommerceServiceCard message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommerceServiceCard
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommerceServiceCard} CommerceServiceCard
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommerceServiceCard.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommerceServiceCard();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.id = r.string();
                    break;
                case 2:
                    m.type = r.string();
                    break;
                case 3:
                    m.country = r.string();
                    break;
                case 4:
                    m.card = r.string();
                    break;
                case 5:
                    m.customer = r.string();
                    break;
                case 6:
                    m.threeDSecure = $root.Proto.CommerceServiceCard.ThreeDSecure.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommerceServiceCard message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommerceServiceCard
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommerceServiceCard} CommerceServiceCard
         */
        CommerceServiceCard.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommerceServiceCard)
                return d;
            var m = new $root.Proto.CommerceServiceCard();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.type != null) {
                m.type = String(d.type);
            }
            if (d.country != null) {
                m.country = String(d.country);
            }
            if (d.card != null) {
                m.card = String(d.card);
            }
            if (d.customer != null) {
                m.customer = String(d.customer);
            }
            if (d.threeDSecure != null) {
                if (typeof d.threeDSecure !== "object")
                    throw TypeError(".Proto.CommerceServiceCard.threeDSecure: object expected");
                m.threeDSecure = $root.Proto.CommerceServiceCard.ThreeDSecure.fromObject(d.threeDSecure);
            }
            return m;
        };

        /**
         * Creates a plain object from a CommerceServiceCard message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommerceServiceCard
         * @static
         * @param {Proto.CommerceServiceCard} m CommerceServiceCard
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommerceServiceCard.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.type = "";
                d.country = "";
                d.card = "";
                d.customer = "";
                d.threeDSecure = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.country != null && m.hasOwnProperty("country")) {
                d.country = m.country;
            }
            if (m.card != null && m.hasOwnProperty("card")) {
                d.card = m.card;
            }
            if (m.customer != null && m.hasOwnProperty("customer")) {
                d.customer = m.customer;
            }
            if (m.threeDSecure != null && m.hasOwnProperty("threeDSecure")) {
                d.threeDSecure = $root.Proto.CommerceServiceCard.ThreeDSecure.toObject(m.threeDSecure, o);
            }
            return d;
        };

        /**
         * Converts this CommerceServiceCard to JSON.
         * @function toJSON
         * @memberof Proto.CommerceServiceCard
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommerceServiceCard.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        CommerceServiceCard.ThreeDSecure = (function() {

            /**
             * Properties of a ThreeDSecure.
             * @memberof Proto.CommerceServiceCard
             * @interface IThreeDSecure
             * @property {boolean|null} [supported] ThreeDSecure supported
             */

            /**
             * Constructs a new ThreeDSecure.
             * @memberof Proto.CommerceServiceCard
             * @classdesc Represents a ThreeDSecure.
             * @implements IThreeDSecure
             * @constructor
             * @param {Proto.CommerceServiceCard.IThreeDSecure=} [p] Properties to set
             */
            function ThreeDSecure(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ThreeDSecure supported.
             * @member {boolean} supported
             * @memberof Proto.CommerceServiceCard.ThreeDSecure
             * @instance
             */
            ThreeDSecure.prototype.supported = false;

            /**
             * Creates a new ThreeDSecure instance using the specified properties.
             * @function create
             * @memberof Proto.CommerceServiceCard.ThreeDSecure
             * @static
             * @param {Proto.CommerceServiceCard.IThreeDSecure=} [properties] Properties to set
             * @returns {Proto.CommerceServiceCard.ThreeDSecure} ThreeDSecure instance
             */
            ThreeDSecure.create = function create(properties) {
                return new ThreeDSecure(properties);
            };

            /**
             * Encodes the specified ThreeDSecure message. Does not implicitly {@link Proto.CommerceServiceCard.ThreeDSecure.verify|verify} messages.
             * @function encode
             * @memberof Proto.CommerceServiceCard.ThreeDSecure
             * @static
             * @param {Proto.CommerceServiceCard.IThreeDSecure} m ThreeDSecure message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ThreeDSecure.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.supported != null && Object.hasOwnProperty.call(m, "supported"))
                    w.uint32(8).bool(m.supported);
                return w;
            };

            /**
             * Decodes a ThreeDSecure message from the specified reader or buffer.
             * @function decode
             * @memberof Proto.CommerceServiceCard.ThreeDSecure
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {Proto.CommerceServiceCard.ThreeDSecure} ThreeDSecure
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ThreeDSecure.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommerceServiceCard.ThreeDSecure();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.supported = r.bool();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ThreeDSecure message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Proto.CommerceServiceCard.ThreeDSecure
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {Proto.CommerceServiceCard.ThreeDSecure} ThreeDSecure
             */
            ThreeDSecure.fromObject = function fromObject(d) {
                if (d instanceof $root.Proto.CommerceServiceCard.ThreeDSecure)
                    return d;
                var m = new $root.Proto.CommerceServiceCard.ThreeDSecure();
                if (d.supported != null) {
                    m.supported = Boolean(d.supported);
                }
                return m;
            };

            /**
             * Creates a plain object from a ThreeDSecure message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Proto.CommerceServiceCard.ThreeDSecure
             * @static
             * @param {Proto.CommerceServiceCard.ThreeDSecure} m ThreeDSecure
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ThreeDSecure.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.supported = false;
                }
                if (m.supported != null && m.hasOwnProperty("supported")) {
                    d.supported = m.supported;
                }
                return d;
            };

            /**
             * Converts this ThreeDSecure to JSON.
             * @function toJSON
             * @memberof Proto.CommerceServiceCard.ThreeDSecure
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ThreeDSecure.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ThreeDSecure;
        })();

        return CommerceServiceCard;
    })();

    Proto.UserNotification = (function() {

        /**
         * Properties of a UserNotification.
         * @memberof Proto
         * @interface IUserNotification
         * @property {string|null} [uid] UserNotification uid
         * @property {string|null} [companyUID] UserNotification companyUID
         * @property {number|null} [type] UserNotification type
         * @property {number|Long|null} [date] UserNotification date
         * @property {string|null} [title] UserNotification title
         * @property {string|null} [text] UserNotification text
         * @property {string|null} [metaUID] UserNotification metaUID
         * @property {string|null} [metaGUID] UserNotification metaGUID
         * @property {string|null} [metaPlaylistUID] UserNotification metaPlaylistUID
         * @property {string|null} [meta] UserNotification meta
         * @property {boolean|null} [read] UserNotification read
         * @property {number|Long|null} [registerDate] UserNotification registerDate
         * @property {number|Long|null} [modifyDate] UserNotification modifyDate
         * @property {Proto.IContent|null} [content] UserNotification content
         * @property {Proto.IUploadFile|null} [thumbnail] UserNotification thumbnail
         */

        /**
         * Constructs a new UserNotification.
         * @memberof Proto
         * @classdesc Represents a UserNotification.
         * @implements IUserNotification
         * @constructor
         * @param {Proto.IUserNotification=} [p] Properties to set
         */
        function UserNotification(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserNotification uid.
         * @member {string} uid
         * @memberof Proto.UserNotification
         * @instance
         */
        UserNotification.prototype.uid = "";

        /**
         * UserNotification companyUID.
         * @member {string} companyUID
         * @memberof Proto.UserNotification
         * @instance
         */
        UserNotification.prototype.companyUID = "";

        /**
         * UserNotification type.
         * @member {number} type
         * @memberof Proto.UserNotification
         * @instance
         */
        UserNotification.prototype.type = 0;

        /**
         * UserNotification date.
         * @member {number|Long} date
         * @memberof Proto.UserNotification
         * @instance
         */
        UserNotification.prototype.date = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UserNotification title.
         * @member {string} title
         * @memberof Proto.UserNotification
         * @instance
         */
        UserNotification.prototype.title = "";

        /**
         * UserNotification text.
         * @member {string} text
         * @memberof Proto.UserNotification
         * @instance
         */
        UserNotification.prototype.text = "";

        /**
         * UserNotification metaUID.
         * @member {string} metaUID
         * @memberof Proto.UserNotification
         * @instance
         */
        UserNotification.prototype.metaUID = "";

        /**
         * UserNotification metaGUID.
         * @member {string} metaGUID
         * @memberof Proto.UserNotification
         * @instance
         */
        UserNotification.prototype.metaGUID = "";

        /**
         * UserNotification metaPlaylistUID.
         * @member {string} metaPlaylistUID
         * @memberof Proto.UserNotification
         * @instance
         */
        UserNotification.prototype.metaPlaylistUID = "";

        /**
         * UserNotification meta.
         * @member {string} meta
         * @memberof Proto.UserNotification
         * @instance
         */
        UserNotification.prototype.meta = "";

        /**
         * UserNotification read.
         * @member {boolean} read
         * @memberof Proto.UserNotification
         * @instance
         */
        UserNotification.prototype.read = false;

        /**
         * UserNotification registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.UserNotification
         * @instance
         */
        UserNotification.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UserNotification modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.UserNotification
         * @instance
         */
        UserNotification.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UserNotification content.
         * @member {Proto.IContent|null|undefined} content
         * @memberof Proto.UserNotification
         * @instance
         */
        UserNotification.prototype.content = null;

        /**
         * UserNotification thumbnail.
         * @member {Proto.IUploadFile|null|undefined} thumbnail
         * @memberof Proto.UserNotification
         * @instance
         */
        UserNotification.prototype.thumbnail = null;

        /**
         * Creates a new UserNotification instance using the specified properties.
         * @function create
         * @memberof Proto.UserNotification
         * @static
         * @param {Proto.IUserNotification=} [properties] Properties to set
         * @returns {Proto.UserNotification} UserNotification instance
         */
        UserNotification.create = function create(properties) {
            return new UserNotification(properties);
        };

        /**
         * Encodes the specified UserNotification message. Does not implicitly {@link Proto.UserNotification.verify|verify} messages.
         * @function encode
         * @memberof Proto.UserNotification
         * @static
         * @param {Proto.IUserNotification} m UserNotification message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserNotification.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(18).string(m.companyUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(24).int32(m.type);
            if (m.date != null && Object.hasOwnProperty.call(m, "date"))
                w.uint32(32).int64(m.date);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(42).string(m.title);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(50).string(m.text);
            if (m.metaUID != null && Object.hasOwnProperty.call(m, "metaUID"))
                w.uint32(58).string(m.metaUID);
            if (m.metaGUID != null && Object.hasOwnProperty.call(m, "metaGUID"))
                w.uint32(66).string(m.metaGUID);
            if (m.metaPlaylistUID != null && Object.hasOwnProperty.call(m, "metaPlaylistUID"))
                w.uint32(74).string(m.metaPlaylistUID);
            if (m.meta != null && Object.hasOwnProperty.call(m, "meta"))
                w.uint32(82).string(m.meta);
            if (m.read != null && Object.hasOwnProperty.call(m, "read"))
                w.uint32(88).bool(m.read);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(96).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(104).int64(m.modifyDate);
            if (m.content != null && Object.hasOwnProperty.call(m, "content"))
                $root.Proto.Content.encode(m.content, w.uint32(114).fork()).ldelim();
            if (m.thumbnail != null && Object.hasOwnProperty.call(m, "thumbnail"))
                $root.Proto.UploadFile.encode(m.thumbnail, w.uint32(122).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a UserNotification message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.UserNotification
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.UserNotification} UserNotification
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserNotification.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.UserNotification();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.companyUID = r.string();
                    break;
                case 3:
                    m.type = r.int32();
                    break;
                case 4:
                    m.date = r.int64();
                    break;
                case 5:
                    m.title = r.string();
                    break;
                case 6:
                    m.text = r.string();
                    break;
                case 7:
                    m.metaUID = r.string();
                    break;
                case 8:
                    m.metaGUID = r.string();
                    break;
                case 9:
                    m.metaPlaylistUID = r.string();
                    break;
                case 10:
                    m.meta = r.string();
                    break;
                case 11:
                    m.read = r.bool();
                    break;
                case 12:
                    m.registerDate = r.int64();
                    break;
                case 13:
                    m.modifyDate = r.int64();
                    break;
                case 14:
                    m.content = $root.Proto.Content.decode(r, r.uint32());
                    break;
                case 15:
                    m.thumbnail = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserNotification message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.UserNotification
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.UserNotification} UserNotification
         */
        UserNotification.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.UserNotification)
                return d;
            var m = new $root.Proto.UserNotification();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.date != null) {
                if ($util.Long)
                    (m.date = $util.Long.fromValue(d.date)).unsigned = false;
                else if (typeof d.date === "string")
                    m.date = parseInt(d.date, 10);
                else if (typeof d.date === "number")
                    m.date = d.date;
                else if (typeof d.date === "object")
                    m.date = new $util.LongBits(d.date.low >>> 0, d.date.high >>> 0).toNumber();
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.metaUID != null) {
                m.metaUID = String(d.metaUID);
            }
            if (d.metaGUID != null) {
                m.metaGUID = String(d.metaGUID);
            }
            if (d.metaPlaylistUID != null) {
                m.metaPlaylistUID = String(d.metaPlaylistUID);
            }
            if (d.meta != null) {
                m.meta = String(d.meta);
            }
            if (d.read != null) {
                m.read = Boolean(d.read);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.content != null) {
                if (typeof d.content !== "object")
                    throw TypeError(".Proto.UserNotification.content: object expected");
                m.content = $root.Proto.Content.fromObject(d.content);
            }
            if (d.thumbnail != null) {
                if (typeof d.thumbnail !== "object")
                    throw TypeError(".Proto.UserNotification.thumbnail: object expected");
                m.thumbnail = $root.Proto.UploadFile.fromObject(d.thumbnail);
            }
            return m;
        };

        /**
         * Creates a plain object from a UserNotification message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.UserNotification
         * @static
         * @param {Proto.UserNotification} m UserNotification
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserNotification.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.companyUID = "";
                d.type = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.date = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.date = o.longs === String ? "0" : 0;
                d.title = "";
                d.text = "";
                d.metaUID = "";
                d.metaGUID = "";
                d.metaPlaylistUID = "";
                d.meta = "";
                d.read = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.content = null;
                d.thumbnail = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.date != null && m.hasOwnProperty("date")) {
                if (typeof m.date === "number")
                    d.date = o.longs === String ? String(m.date) : m.date;
                else
                    d.date = o.longs === String ? $util.Long.prototype.toString.call(m.date) : o.longs === Number ? new $util.LongBits(m.date.low >>> 0, m.date.high >>> 0).toNumber() : m.date;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.metaUID != null && m.hasOwnProperty("metaUID")) {
                d.metaUID = m.metaUID;
            }
            if (m.metaGUID != null && m.hasOwnProperty("metaGUID")) {
                d.metaGUID = m.metaGUID;
            }
            if (m.metaPlaylistUID != null && m.hasOwnProperty("metaPlaylistUID")) {
                d.metaPlaylistUID = m.metaPlaylistUID;
            }
            if (m.meta != null && m.hasOwnProperty("meta")) {
                d.meta = m.meta;
            }
            if (m.read != null && m.hasOwnProperty("read")) {
                d.read = m.read;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.content != null && m.hasOwnProperty("content")) {
                d.content = $root.Proto.Content.toObject(m.content, o);
            }
            if (m.thumbnail != null && m.hasOwnProperty("thumbnail")) {
                d.thumbnail = $root.Proto.UploadFile.toObject(m.thumbnail, o);
            }
            return d;
        };

        /**
         * Converts this UserNotification to JSON.
         * @function toJSON
         * @memberof Proto.UserNotification
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserNotification.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserNotification;
    })();

    Proto.UserNotificationList = (function() {

        /**
         * Properties of a UserNotificationList.
         * @memberof Proto
         * @interface IUserNotificationList
         * @property {Array.<Proto.IUserNotification>|null} [value] UserNotificationList value
         */

        /**
         * Constructs a new UserNotificationList.
         * @memberof Proto
         * @classdesc Represents a UserNotificationList.
         * @implements IUserNotificationList
         * @constructor
         * @param {Proto.IUserNotificationList=} [p] Properties to set
         */
        function UserNotificationList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserNotificationList value.
         * @member {Array.<Proto.IUserNotification>} value
         * @memberof Proto.UserNotificationList
         * @instance
         */
        UserNotificationList.prototype.value = $util.emptyArray;

        /**
         * Creates a new UserNotificationList instance using the specified properties.
         * @function create
         * @memberof Proto.UserNotificationList
         * @static
         * @param {Proto.IUserNotificationList=} [properties] Properties to set
         * @returns {Proto.UserNotificationList} UserNotificationList instance
         */
        UserNotificationList.create = function create(properties) {
            return new UserNotificationList(properties);
        };

        /**
         * Encodes the specified UserNotificationList message. Does not implicitly {@link Proto.UserNotificationList.verify|verify} messages.
         * @function encode
         * @memberof Proto.UserNotificationList
         * @static
         * @param {Proto.IUserNotificationList} m UserNotificationList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserNotificationList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.UserNotification.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a UserNotificationList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.UserNotificationList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.UserNotificationList} UserNotificationList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserNotificationList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.UserNotificationList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.UserNotification.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserNotificationList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.UserNotificationList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.UserNotificationList} UserNotificationList
         */
        UserNotificationList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.UserNotificationList)
                return d;
            var m = new $root.Proto.UserNotificationList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.UserNotificationList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.UserNotificationList.value: object expected");
                    m.value[i] = $root.Proto.UserNotification.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a UserNotificationList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.UserNotificationList
         * @static
         * @param {Proto.UserNotificationList} m UserNotificationList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserNotificationList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.UserNotification.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this UserNotificationList to JSON.
         * @function toJSON
         * @memberof Proto.UserNotificationList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserNotificationList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserNotificationList;
    })();

    Proto.ContentScheduleHistory = (function() {

        /**
         * Properties of a ContentScheduleHistory.
         * @memberof Proto
         * @interface IContentScheduleHistory
         * @property {string|null} [uid] ContentScheduleHistory uid
         * @property {string|null} [scheduleUID] ContentScheduleHistory scheduleUID
         * @property {string|null} [contentUID] ContentScheduleHistory contentUID
         * @property {string|null} [playlistUID] ContentScheduleHistory playlistUID
         * @property {number|Long|null} [startDate] ContentScheduleHistory startDate
         * @property {number|Long|null} [endDate] ContentScheduleHistory endDate
         * @property {boolean|null} [participate] ContentScheduleHistory participate
         * @property {number|Long|null} [registerDate] ContentScheduleHistory registerDate
         * @property {number|Long|null} [modifyDate] ContentScheduleHistory modifyDate
         * @property {number|null} [status] ContentScheduleHistory status
         * @property {Proto.IContent|null} [content] ContentScheduleHistory content
         */

        /**
         * Constructs a new ContentScheduleHistory.
         * @memberof Proto
         * @classdesc Represents a ContentScheduleHistory.
         * @implements IContentScheduleHistory
         * @constructor
         * @param {Proto.IContentScheduleHistory=} [p] Properties to set
         */
        function ContentScheduleHistory(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentScheduleHistory uid.
         * @member {string} uid
         * @memberof Proto.ContentScheduleHistory
         * @instance
         */
        ContentScheduleHistory.prototype.uid = "";

        /**
         * ContentScheduleHistory scheduleUID.
         * @member {string} scheduleUID
         * @memberof Proto.ContentScheduleHistory
         * @instance
         */
        ContentScheduleHistory.prototype.scheduleUID = "";

        /**
         * ContentScheduleHistory contentUID.
         * @member {string} contentUID
         * @memberof Proto.ContentScheduleHistory
         * @instance
         */
        ContentScheduleHistory.prototype.contentUID = "";

        /**
         * ContentScheduleHistory playlistUID.
         * @member {string} playlistUID
         * @memberof Proto.ContentScheduleHistory
         * @instance
         */
        ContentScheduleHistory.prototype.playlistUID = "";

        /**
         * ContentScheduleHistory startDate.
         * @member {number|Long} startDate
         * @memberof Proto.ContentScheduleHistory
         * @instance
         */
        ContentScheduleHistory.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleHistory endDate.
         * @member {number|Long} endDate
         * @memberof Proto.ContentScheduleHistory
         * @instance
         */
        ContentScheduleHistory.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleHistory participate.
         * @member {boolean} participate
         * @memberof Proto.ContentScheduleHistory
         * @instance
         */
        ContentScheduleHistory.prototype.participate = false;

        /**
         * ContentScheduleHistory registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentScheduleHistory
         * @instance
         */
        ContentScheduleHistory.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleHistory modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentScheduleHistory
         * @instance
         */
        ContentScheduleHistory.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleHistory status.
         * @member {number} status
         * @memberof Proto.ContentScheduleHistory
         * @instance
         */
        ContentScheduleHistory.prototype.status = 0;

        /**
         * ContentScheduleHistory content.
         * @member {Proto.IContent|null|undefined} content
         * @memberof Proto.ContentScheduleHistory
         * @instance
         */
        ContentScheduleHistory.prototype.content = null;

        /**
         * Creates a new ContentScheduleHistory instance using the specified properties.
         * @function create
         * @memberof Proto.ContentScheduleHistory
         * @static
         * @param {Proto.IContentScheduleHistory=} [properties] Properties to set
         * @returns {Proto.ContentScheduleHistory} ContentScheduleHistory instance
         */
        ContentScheduleHistory.create = function create(properties) {
            return new ContentScheduleHistory(properties);
        };

        /**
         * Encodes the specified ContentScheduleHistory message. Does not implicitly {@link Proto.ContentScheduleHistory.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentScheduleHistory
         * @static
         * @param {Proto.IContentScheduleHistory} m ContentScheduleHistory message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentScheduleHistory.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.scheduleUID != null && Object.hasOwnProperty.call(m, "scheduleUID"))
                w.uint32(18).string(m.scheduleUID);
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(26).string(m.contentUID);
            if (m.playlistUID != null && Object.hasOwnProperty.call(m, "playlistUID"))
                w.uint32(34).string(m.playlistUID);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(40).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(48).int64(m.endDate);
            if (m.participate != null && Object.hasOwnProperty.call(m, "participate"))
                w.uint32(56).bool(m.participate);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(80).int32(m.status);
            if (m.content != null && Object.hasOwnProperty.call(m, "content"))
                $root.Proto.Content.encode(m.content, w.uint32(90).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a ContentScheduleHistory message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentScheduleHistory
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentScheduleHistory} ContentScheduleHistory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentScheduleHistory.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentScheduleHistory();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.scheduleUID = r.string();
                    break;
                case 3:
                    m.contentUID = r.string();
                    break;
                case 4:
                    m.playlistUID = r.string();
                    break;
                case 5:
                    m.startDate = r.int64();
                    break;
                case 6:
                    m.endDate = r.int64();
                    break;
                case 7:
                    m.participate = r.bool();
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                case 10:
                    m.status = r.int32();
                    break;
                case 11:
                    m.content = $root.Proto.Content.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentScheduleHistory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentScheduleHistory
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentScheduleHistory} ContentScheduleHistory
         */
        ContentScheduleHistory.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentScheduleHistory)
                return d;
            var m = new $root.Proto.ContentScheduleHistory();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.scheduleUID != null) {
                m.scheduleUID = String(d.scheduleUID);
            }
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.playlistUID != null) {
                m.playlistUID = String(d.playlistUID);
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.participate != null) {
                m.participate = Boolean(d.participate);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.content != null) {
                if (typeof d.content !== "object")
                    throw TypeError(".Proto.ContentScheduleHistory.content: object expected");
                m.content = $root.Proto.Content.fromObject(d.content);
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentScheduleHistory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentScheduleHistory
         * @static
         * @param {Proto.ContentScheduleHistory} m ContentScheduleHistory
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentScheduleHistory.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.scheduleUID = "";
                d.contentUID = "";
                d.playlistUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                d.participate = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.content = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.scheduleUID != null && m.hasOwnProperty("scheduleUID")) {
                d.scheduleUID = m.scheduleUID;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.playlistUID != null && m.hasOwnProperty("playlistUID")) {
                d.playlistUID = m.playlistUID;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.participate != null && m.hasOwnProperty("participate")) {
                d.participate = m.participate;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.content != null && m.hasOwnProperty("content")) {
                d.content = $root.Proto.Content.toObject(m.content, o);
            }
            return d;
        };

        /**
         * Converts this ContentScheduleHistory to JSON.
         * @function toJSON
         * @memberof Proto.ContentScheduleHistory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentScheduleHistory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentScheduleHistory;
    })();

    Proto.ContentScheduleHistoryList = (function() {

        /**
         * Properties of a ContentScheduleHistoryList.
         * @memberof Proto
         * @interface IContentScheduleHistoryList
         * @property {Array.<Proto.IContentScheduleHistory>|null} [value] ContentScheduleHistoryList value
         */

        /**
         * Constructs a new ContentScheduleHistoryList.
         * @memberof Proto
         * @classdesc Represents a ContentScheduleHistoryList.
         * @implements IContentScheduleHistoryList
         * @constructor
         * @param {Proto.IContentScheduleHistoryList=} [p] Properties to set
         */
        function ContentScheduleHistoryList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentScheduleHistoryList value.
         * @member {Array.<Proto.IContentScheduleHistory>} value
         * @memberof Proto.ContentScheduleHistoryList
         * @instance
         */
        ContentScheduleHistoryList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentScheduleHistoryList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentScheduleHistoryList
         * @static
         * @param {Proto.IContentScheduleHistoryList=} [properties] Properties to set
         * @returns {Proto.ContentScheduleHistoryList} ContentScheduleHistoryList instance
         */
        ContentScheduleHistoryList.create = function create(properties) {
            return new ContentScheduleHistoryList(properties);
        };

        /**
         * Encodes the specified ContentScheduleHistoryList message. Does not implicitly {@link Proto.ContentScheduleHistoryList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentScheduleHistoryList
         * @static
         * @param {Proto.IContentScheduleHistoryList} m ContentScheduleHistoryList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentScheduleHistoryList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentScheduleHistory.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentScheduleHistoryList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentScheduleHistoryList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentScheduleHistoryList} ContentScheduleHistoryList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentScheduleHistoryList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentScheduleHistoryList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentScheduleHistory.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentScheduleHistoryList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentScheduleHistoryList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentScheduleHistoryList} ContentScheduleHistoryList
         */
        ContentScheduleHistoryList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentScheduleHistoryList)
                return d;
            var m = new $root.Proto.ContentScheduleHistoryList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentScheduleHistoryList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentScheduleHistoryList.value: object expected");
                    m.value[i] = $root.Proto.ContentScheduleHistory.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentScheduleHistoryList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentScheduleHistoryList
         * @static
         * @param {Proto.ContentScheduleHistoryList} m ContentScheduleHistoryList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentScheduleHistoryList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentScheduleHistory.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentScheduleHistoryList to JSON.
         * @function toJSON
         * @memberof Proto.ContentScheduleHistoryList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentScheduleHistoryList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentScheduleHistoryList;
    })();

    Proto.CommerceOrderCount = (function() {

        /**
         * Properties of a CommerceOrderCount.
         * @memberof Proto
         * @interface ICommerceOrderCount
         * @property {string|null} [productUID] CommerceOrderCount productUID
         * @property {string|null} [orderCount] CommerceOrderCount orderCount
         * @property {string|null} [orderSuccessCount] CommerceOrderCount orderSuccessCount
         * @property {string|null} [orderQuantityCount] CommerceOrderCount orderQuantityCount
         * @property {string|null} [orderQuantitySuccessCount] CommerceOrderCount orderQuantitySuccessCount
         */

        /**
         * Constructs a new CommerceOrderCount.
         * @memberof Proto
         * @classdesc Represents a CommerceOrderCount.
         * @implements ICommerceOrderCount
         * @constructor
         * @param {Proto.ICommerceOrderCount=} [p] Properties to set
         */
        function CommerceOrderCount(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommerceOrderCount productUID.
         * @member {string} productUID
         * @memberof Proto.CommerceOrderCount
         * @instance
         */
        CommerceOrderCount.prototype.productUID = "";

        /**
         * CommerceOrderCount orderCount.
         * @member {string} orderCount
         * @memberof Proto.CommerceOrderCount
         * @instance
         */
        CommerceOrderCount.prototype.orderCount = "";

        /**
         * CommerceOrderCount orderSuccessCount.
         * @member {string} orderSuccessCount
         * @memberof Proto.CommerceOrderCount
         * @instance
         */
        CommerceOrderCount.prototype.orderSuccessCount = "";

        /**
         * CommerceOrderCount orderQuantityCount.
         * @member {string} orderQuantityCount
         * @memberof Proto.CommerceOrderCount
         * @instance
         */
        CommerceOrderCount.prototype.orderQuantityCount = "";

        /**
         * CommerceOrderCount orderQuantitySuccessCount.
         * @member {string} orderQuantitySuccessCount
         * @memberof Proto.CommerceOrderCount
         * @instance
         */
        CommerceOrderCount.prototype.orderQuantitySuccessCount = "";

        /**
         * Creates a new CommerceOrderCount instance using the specified properties.
         * @function create
         * @memberof Proto.CommerceOrderCount
         * @static
         * @param {Proto.ICommerceOrderCount=} [properties] Properties to set
         * @returns {Proto.CommerceOrderCount} CommerceOrderCount instance
         */
        CommerceOrderCount.create = function create(properties) {
            return new CommerceOrderCount(properties);
        };

        /**
         * Encodes the specified CommerceOrderCount message. Does not implicitly {@link Proto.CommerceOrderCount.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommerceOrderCount
         * @static
         * @param {Proto.ICommerceOrderCount} m CommerceOrderCount message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommerceOrderCount.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.productUID != null && Object.hasOwnProperty.call(m, "productUID"))
                w.uint32(10).string(m.productUID);
            if (m.orderCount != null && Object.hasOwnProperty.call(m, "orderCount"))
                w.uint32(18).string(m.orderCount);
            if (m.orderSuccessCount != null && Object.hasOwnProperty.call(m, "orderSuccessCount"))
                w.uint32(26).string(m.orderSuccessCount);
            if (m.orderQuantityCount != null && Object.hasOwnProperty.call(m, "orderQuantityCount"))
                w.uint32(34).string(m.orderQuantityCount);
            if (m.orderQuantitySuccessCount != null && Object.hasOwnProperty.call(m, "orderQuantitySuccessCount"))
                w.uint32(42).string(m.orderQuantitySuccessCount);
            return w;
        };

        /**
         * Decodes a CommerceOrderCount message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommerceOrderCount
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommerceOrderCount} CommerceOrderCount
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommerceOrderCount.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommerceOrderCount();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.productUID = r.string();
                    break;
                case 2:
                    m.orderCount = r.string();
                    break;
                case 3:
                    m.orderSuccessCount = r.string();
                    break;
                case 4:
                    m.orderQuantityCount = r.string();
                    break;
                case 5:
                    m.orderQuantitySuccessCount = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommerceOrderCount message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommerceOrderCount
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommerceOrderCount} CommerceOrderCount
         */
        CommerceOrderCount.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommerceOrderCount)
                return d;
            var m = new $root.Proto.CommerceOrderCount();
            if (d.productUID != null) {
                m.productUID = String(d.productUID);
            }
            if (d.orderCount != null) {
                m.orderCount = String(d.orderCount);
            }
            if (d.orderSuccessCount != null) {
                m.orderSuccessCount = String(d.orderSuccessCount);
            }
            if (d.orderQuantityCount != null) {
                m.orderQuantityCount = String(d.orderQuantityCount);
            }
            if (d.orderQuantitySuccessCount != null) {
                m.orderQuantitySuccessCount = String(d.orderQuantitySuccessCount);
            }
            return m;
        };

        /**
         * Creates a plain object from a CommerceOrderCount message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommerceOrderCount
         * @static
         * @param {Proto.CommerceOrderCount} m CommerceOrderCount
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommerceOrderCount.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.productUID = "";
                d.orderCount = "";
                d.orderSuccessCount = "";
                d.orderQuantityCount = "";
                d.orderQuantitySuccessCount = "";
            }
            if (m.productUID != null && m.hasOwnProperty("productUID")) {
                d.productUID = m.productUID;
            }
            if (m.orderCount != null && m.hasOwnProperty("orderCount")) {
                d.orderCount = m.orderCount;
            }
            if (m.orderSuccessCount != null && m.hasOwnProperty("orderSuccessCount")) {
                d.orderSuccessCount = m.orderSuccessCount;
            }
            if (m.orderQuantityCount != null && m.hasOwnProperty("orderQuantityCount")) {
                d.orderQuantityCount = m.orderQuantityCount;
            }
            if (m.orderQuantitySuccessCount != null && m.hasOwnProperty("orderQuantitySuccessCount")) {
                d.orderQuantitySuccessCount = m.orderQuantitySuccessCount;
            }
            return d;
        };

        /**
         * Converts this CommerceOrderCount to JSON.
         * @function toJSON
         * @memberof Proto.CommerceOrderCount
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommerceOrderCount.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommerceOrderCount;
    })();

    Proto.HomeTimeline = (function() {

        /**
         * Properties of a HomeTimeline.
         * @memberof Proto
         * @interface IHomeTimeline
         * @property {string|null} [uid] HomeTimeline uid
         * @property {number|null} [type] HomeTimeline type
         * @property {string|null} [title] HomeTimeline title
         * @property {string|null} [text] HomeTimeline text
         * @property {number|Long|null} [registerDate] HomeTimeline registerDate
         * @property {number|Long|null} [modifyDate] HomeTimeline modifyDate
         * @property {Proto.IContent|null} [content] HomeTimeline content
         * @property {boolean|null} [contentLiked] HomeTimeline contentLiked
         * @property {Proto.ICommunityBoard|null} [board] HomeTimeline board
         * @property {Proto.ICommunityBoardTopic|null} [boardTopic] HomeTimeline boardTopic
         * @property {string|null} [userName] HomeTimeline userName
         * @property {Proto.IUploadFile|null} [userIcon] HomeTimeline userIcon
         * @property {Array.<string>|null} [imageList] HomeTimeline imageList
         * @property {boolean|null} [pin] HomeTimeline pin
         * @property {Array.<Proto.IHashTag>|null} [tagList] HomeTimeline tagList
         * @property {boolean|null} [owner] HomeTimeline owner
         * @property {Array.<number>|null} [imageFileTypeList] HomeTimeline imageFileTypeList
         */

        /**
         * Constructs a new HomeTimeline.
         * @memberof Proto
         * @classdesc Represents a HomeTimeline.
         * @implements IHomeTimeline
         * @constructor
         * @param {Proto.IHomeTimeline=} [p] Properties to set
         */
        function HomeTimeline(p) {
            this.imageList = [];
            this.tagList = [];
            this.imageFileTypeList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * HomeTimeline uid.
         * @member {string} uid
         * @memberof Proto.HomeTimeline
         * @instance
         */
        HomeTimeline.prototype.uid = "";

        /**
         * HomeTimeline type.
         * @member {number} type
         * @memberof Proto.HomeTimeline
         * @instance
         */
        HomeTimeline.prototype.type = 0;

        /**
         * HomeTimeline title.
         * @member {string} title
         * @memberof Proto.HomeTimeline
         * @instance
         */
        HomeTimeline.prototype.title = "";

        /**
         * HomeTimeline text.
         * @member {string} text
         * @memberof Proto.HomeTimeline
         * @instance
         */
        HomeTimeline.prototype.text = "";

        /**
         * HomeTimeline registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.HomeTimeline
         * @instance
         */
        HomeTimeline.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * HomeTimeline modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.HomeTimeline
         * @instance
         */
        HomeTimeline.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * HomeTimeline content.
         * @member {Proto.IContent|null|undefined} content
         * @memberof Proto.HomeTimeline
         * @instance
         */
        HomeTimeline.prototype.content = null;

        /**
         * HomeTimeline contentLiked.
         * @member {boolean} contentLiked
         * @memberof Proto.HomeTimeline
         * @instance
         */
        HomeTimeline.prototype.contentLiked = false;

        /**
         * HomeTimeline board.
         * @member {Proto.ICommunityBoard|null|undefined} board
         * @memberof Proto.HomeTimeline
         * @instance
         */
        HomeTimeline.prototype.board = null;

        /**
         * HomeTimeline boardTopic.
         * @member {Proto.ICommunityBoardTopic|null|undefined} boardTopic
         * @memberof Proto.HomeTimeline
         * @instance
         */
        HomeTimeline.prototype.boardTopic = null;

        /**
         * HomeTimeline userName.
         * @member {string} userName
         * @memberof Proto.HomeTimeline
         * @instance
         */
        HomeTimeline.prototype.userName = "";

        /**
         * HomeTimeline userIcon.
         * @member {Proto.IUploadFile|null|undefined} userIcon
         * @memberof Proto.HomeTimeline
         * @instance
         */
        HomeTimeline.prototype.userIcon = null;

        /**
         * HomeTimeline imageList.
         * @member {Array.<string>} imageList
         * @memberof Proto.HomeTimeline
         * @instance
         */
        HomeTimeline.prototype.imageList = $util.emptyArray;

        /**
         * HomeTimeline pin.
         * @member {boolean} pin
         * @memberof Proto.HomeTimeline
         * @instance
         */
        HomeTimeline.prototype.pin = false;

        /**
         * HomeTimeline tagList.
         * @member {Array.<Proto.IHashTag>} tagList
         * @memberof Proto.HomeTimeline
         * @instance
         */
        HomeTimeline.prototype.tagList = $util.emptyArray;

        /**
         * HomeTimeline owner.
         * @member {boolean} owner
         * @memberof Proto.HomeTimeline
         * @instance
         */
        HomeTimeline.prototype.owner = false;

        /**
         * HomeTimeline imageFileTypeList.
         * @member {Array.<number>} imageFileTypeList
         * @memberof Proto.HomeTimeline
         * @instance
         */
        HomeTimeline.prototype.imageFileTypeList = $util.emptyArray;

        /**
         * Creates a new HomeTimeline instance using the specified properties.
         * @function create
         * @memberof Proto.HomeTimeline
         * @static
         * @param {Proto.IHomeTimeline=} [properties] Properties to set
         * @returns {Proto.HomeTimeline} HomeTimeline instance
         */
        HomeTimeline.create = function create(properties) {
            return new HomeTimeline(properties);
        };

        /**
         * Encodes the specified HomeTimeline message. Does not implicitly {@link Proto.HomeTimeline.verify|verify} messages.
         * @function encode
         * @memberof Proto.HomeTimeline
         * @static
         * @param {Proto.IHomeTimeline} m HomeTimeline message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HomeTimeline.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(26).string(m.title);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(34).string(m.text);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(40).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(48).int64(m.modifyDate);
            if (m.content != null && Object.hasOwnProperty.call(m, "content"))
                $root.Proto.Content.encode(m.content, w.uint32(58).fork()).ldelim();
            if (m.contentLiked != null && Object.hasOwnProperty.call(m, "contentLiked"))
                w.uint32(64).bool(m.contentLiked);
            if (m.board != null && Object.hasOwnProperty.call(m, "board"))
                $root.Proto.CommunityBoard.encode(m.board, w.uint32(74).fork()).ldelim();
            if (m.boardTopic != null && Object.hasOwnProperty.call(m, "boardTopic"))
                $root.Proto.CommunityBoardTopic.encode(m.boardTopic, w.uint32(82).fork()).ldelim();
            if (m.userName != null && Object.hasOwnProperty.call(m, "userName"))
                w.uint32(90).string(m.userName);
            if (m.userIcon != null && Object.hasOwnProperty.call(m, "userIcon"))
                $root.Proto.UploadFile.encode(m.userIcon, w.uint32(98).fork()).ldelim();
            if (m.imageList != null && m.imageList.length) {
                for (var i = 0; i < m.imageList.length; ++i)
                    w.uint32(106).string(m.imageList[i]);
            }
            if (m.pin != null && Object.hasOwnProperty.call(m, "pin"))
                w.uint32(112).bool(m.pin);
            if (m.tagList != null && m.tagList.length) {
                for (var i = 0; i < m.tagList.length; ++i)
                    $root.Proto.HashTag.encode(m.tagList[i], w.uint32(122).fork()).ldelim();
            }
            if (m.owner != null && Object.hasOwnProperty.call(m, "owner"))
                w.uint32(128).bool(m.owner);
            if (m.imageFileTypeList != null && m.imageFileTypeList.length) {
                w.uint32(138).fork();
                for (var i = 0; i < m.imageFileTypeList.length; ++i)
                    w.int32(m.imageFileTypeList[i]);
                w.ldelim();
            }
            return w;
        };

        /**
         * Decodes a HomeTimeline message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.HomeTimeline
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.HomeTimeline} HomeTimeline
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HomeTimeline.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.HomeTimeline();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.title = r.string();
                    break;
                case 4:
                    m.text = r.string();
                    break;
                case 5:
                    m.registerDate = r.int64();
                    break;
                case 6:
                    m.modifyDate = r.int64();
                    break;
                case 7:
                    m.content = $root.Proto.Content.decode(r, r.uint32());
                    break;
                case 8:
                    m.contentLiked = r.bool();
                    break;
                case 9:
                    m.board = $root.Proto.CommunityBoard.decode(r, r.uint32());
                    break;
                case 10:
                    m.boardTopic = $root.Proto.CommunityBoardTopic.decode(r, r.uint32());
                    break;
                case 11:
                    m.userName = r.string();
                    break;
                case 12:
                    m.userIcon = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 13:
                    if (!(m.imageList && m.imageList.length))
                        m.imageList = [];
                    m.imageList.push(r.string());
                    break;
                case 14:
                    m.pin = r.bool();
                    break;
                case 15:
                    if (!(m.tagList && m.tagList.length))
                        m.tagList = [];
                    m.tagList.push($root.Proto.HashTag.decode(r, r.uint32()));
                    break;
                case 16:
                    m.owner = r.bool();
                    break;
                case 17:
                    if (!(m.imageFileTypeList && m.imageFileTypeList.length))
                        m.imageFileTypeList = [];
                    if ((t & 7) === 2) {
                        var c2 = r.uint32() + r.pos;
                        while (r.pos < c2)
                            m.imageFileTypeList.push(r.int32());
                    } else
                        m.imageFileTypeList.push(r.int32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a HomeTimeline message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.HomeTimeline
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.HomeTimeline} HomeTimeline
         */
        HomeTimeline.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.HomeTimeline)
                return d;
            var m = new $root.Proto.HomeTimeline();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.content != null) {
                if (typeof d.content !== "object")
                    throw TypeError(".Proto.HomeTimeline.content: object expected");
                m.content = $root.Proto.Content.fromObject(d.content);
            }
            if (d.contentLiked != null) {
                m.contentLiked = Boolean(d.contentLiked);
            }
            if (d.board != null) {
                if (typeof d.board !== "object")
                    throw TypeError(".Proto.HomeTimeline.board: object expected");
                m.board = $root.Proto.CommunityBoard.fromObject(d.board);
            }
            if (d.boardTopic != null) {
                if (typeof d.boardTopic !== "object")
                    throw TypeError(".Proto.HomeTimeline.boardTopic: object expected");
                m.boardTopic = $root.Proto.CommunityBoardTopic.fromObject(d.boardTopic);
            }
            if (d.userName != null) {
                m.userName = String(d.userName);
            }
            if (d.userIcon != null) {
                if (typeof d.userIcon !== "object")
                    throw TypeError(".Proto.HomeTimeline.userIcon: object expected");
                m.userIcon = $root.Proto.UploadFile.fromObject(d.userIcon);
            }
            if (d.imageList) {
                if (!Array.isArray(d.imageList))
                    throw TypeError(".Proto.HomeTimeline.imageList: array expected");
                m.imageList = [];
                for (var i = 0; i < d.imageList.length; ++i) {
                    m.imageList[i] = String(d.imageList[i]);
                }
            }
            if (d.pin != null) {
                m.pin = Boolean(d.pin);
            }
            if (d.tagList) {
                if (!Array.isArray(d.tagList))
                    throw TypeError(".Proto.HomeTimeline.tagList: array expected");
                m.tagList = [];
                for (var i = 0; i < d.tagList.length; ++i) {
                    if (typeof d.tagList[i] !== "object")
                        throw TypeError(".Proto.HomeTimeline.tagList: object expected");
                    m.tagList[i] = $root.Proto.HashTag.fromObject(d.tagList[i]);
                }
            }
            if (d.owner != null) {
                m.owner = Boolean(d.owner);
            }
            if (d.imageFileTypeList) {
                if (!Array.isArray(d.imageFileTypeList))
                    throw TypeError(".Proto.HomeTimeline.imageFileTypeList: array expected");
                m.imageFileTypeList = [];
                for (var i = 0; i < d.imageFileTypeList.length; ++i) {
                    m.imageFileTypeList[i] = d.imageFileTypeList[i] | 0;
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a HomeTimeline message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.HomeTimeline
         * @static
         * @param {Proto.HomeTimeline} m HomeTimeline
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HomeTimeline.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.imageList = [];
                d.tagList = [];
                d.imageFileTypeList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.title = "";
                d.text = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.content = null;
                d.contentLiked = false;
                d.board = null;
                d.boardTopic = null;
                d.userName = "";
                d.userIcon = null;
                d.pin = false;
                d.owner = false;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.content != null && m.hasOwnProperty("content")) {
                d.content = $root.Proto.Content.toObject(m.content, o);
            }
            if (m.contentLiked != null && m.hasOwnProperty("contentLiked")) {
                d.contentLiked = m.contentLiked;
            }
            if (m.board != null && m.hasOwnProperty("board")) {
                d.board = $root.Proto.CommunityBoard.toObject(m.board, o);
            }
            if (m.boardTopic != null && m.hasOwnProperty("boardTopic")) {
                d.boardTopic = $root.Proto.CommunityBoardTopic.toObject(m.boardTopic, o);
            }
            if (m.userName != null && m.hasOwnProperty("userName")) {
                d.userName = m.userName;
            }
            if (m.userIcon != null && m.hasOwnProperty("userIcon")) {
                d.userIcon = $root.Proto.UploadFile.toObject(m.userIcon, o);
            }
            if (m.imageList && m.imageList.length) {
                d.imageList = [];
                for (var j = 0; j < m.imageList.length; ++j) {
                    d.imageList[j] = m.imageList[j];
                }
            }
            if (m.pin != null && m.hasOwnProperty("pin")) {
                d.pin = m.pin;
            }
            if (m.tagList && m.tagList.length) {
                d.tagList = [];
                for (var j = 0; j < m.tagList.length; ++j) {
                    d.tagList[j] = $root.Proto.HashTag.toObject(m.tagList[j], o);
                }
            }
            if (m.owner != null && m.hasOwnProperty("owner")) {
                d.owner = m.owner;
            }
            if (m.imageFileTypeList && m.imageFileTypeList.length) {
                d.imageFileTypeList = [];
                for (var j = 0; j < m.imageFileTypeList.length; ++j) {
                    d.imageFileTypeList[j] = m.imageFileTypeList[j];
                }
            }
            return d;
        };

        /**
         * Converts this HomeTimeline to JSON.
         * @function toJSON
         * @memberof Proto.HomeTimeline
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HomeTimeline.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HomeTimeline;
    })();

    Proto.HomeTimelineList = (function() {

        /**
         * Properties of a HomeTimelineList.
         * @memberof Proto
         * @interface IHomeTimelineList
         * @property {Array.<Proto.IHomeTimeline>|null} [value] HomeTimelineList value
         */

        /**
         * Constructs a new HomeTimelineList.
         * @memberof Proto
         * @classdesc Represents a HomeTimelineList.
         * @implements IHomeTimelineList
         * @constructor
         * @param {Proto.IHomeTimelineList=} [p] Properties to set
         */
        function HomeTimelineList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * HomeTimelineList value.
         * @member {Array.<Proto.IHomeTimeline>} value
         * @memberof Proto.HomeTimelineList
         * @instance
         */
        HomeTimelineList.prototype.value = $util.emptyArray;

        /**
         * Creates a new HomeTimelineList instance using the specified properties.
         * @function create
         * @memberof Proto.HomeTimelineList
         * @static
         * @param {Proto.IHomeTimelineList=} [properties] Properties to set
         * @returns {Proto.HomeTimelineList} HomeTimelineList instance
         */
        HomeTimelineList.create = function create(properties) {
            return new HomeTimelineList(properties);
        };

        /**
         * Encodes the specified HomeTimelineList message. Does not implicitly {@link Proto.HomeTimelineList.verify|verify} messages.
         * @function encode
         * @memberof Proto.HomeTimelineList
         * @static
         * @param {Proto.IHomeTimelineList} m HomeTimelineList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HomeTimelineList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.HomeTimeline.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a HomeTimelineList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.HomeTimelineList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.HomeTimelineList} HomeTimelineList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HomeTimelineList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.HomeTimelineList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.HomeTimeline.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a HomeTimelineList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.HomeTimelineList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.HomeTimelineList} HomeTimelineList
         */
        HomeTimelineList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.HomeTimelineList)
                return d;
            var m = new $root.Proto.HomeTimelineList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.HomeTimelineList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.HomeTimelineList.value: object expected");
                    m.value[i] = $root.Proto.HomeTimeline.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a HomeTimelineList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.HomeTimelineList
         * @static
         * @param {Proto.HomeTimelineList} m HomeTimelineList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HomeTimelineList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.HomeTimeline.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this HomeTimelineList to JSON.
         * @function toJSON
         * @memberof Proto.HomeTimelineList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HomeTimelineList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HomeTimelineList;
    })();

    Proto.HashTag = (function() {

        /**
         * Properties of a HashTag.
         * @memberof Proto
         * @interface IHashTag
         * @property {string|null} [uid] HashTag uid
         * @property {string|null} [text] HashTag text
         * @property {number|Long|null} [registerDate] HashTag registerDate
         * @property {number|Long|null} [modifyDate] HashTag modifyDate
         * @property {string|null} [count] HashTag count
         */

        /**
         * Constructs a new HashTag.
         * @memberof Proto
         * @classdesc Represents a HashTag.
         * @implements IHashTag
         * @constructor
         * @param {Proto.IHashTag=} [p] Properties to set
         */
        function HashTag(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * HashTag uid.
         * @member {string} uid
         * @memberof Proto.HashTag
         * @instance
         */
        HashTag.prototype.uid = "";

        /**
         * HashTag text.
         * @member {string} text
         * @memberof Proto.HashTag
         * @instance
         */
        HashTag.prototype.text = "";

        /**
         * HashTag registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.HashTag
         * @instance
         */
        HashTag.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * HashTag modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.HashTag
         * @instance
         */
        HashTag.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * HashTag count.
         * @member {string} count
         * @memberof Proto.HashTag
         * @instance
         */
        HashTag.prototype.count = "";

        /**
         * Creates a new HashTag instance using the specified properties.
         * @function create
         * @memberof Proto.HashTag
         * @static
         * @param {Proto.IHashTag=} [properties] Properties to set
         * @returns {Proto.HashTag} HashTag instance
         */
        HashTag.create = function create(properties) {
            return new HashTag(properties);
        };

        /**
         * Encodes the specified HashTag message. Does not implicitly {@link Proto.HashTag.verify|verify} messages.
         * @function encode
         * @memberof Proto.HashTag
         * @static
         * @param {Proto.IHashTag} m HashTag message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HashTag.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(18).string(m.text);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(32).int64(m.modifyDate);
            if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                w.uint32(42).string(m.count);
            return w;
        };

        /**
         * Decodes a HashTag message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.HashTag
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.HashTag} HashTag
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HashTag.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.HashTag();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.text = r.string();
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    m.modifyDate = r.int64();
                    break;
                case 5:
                    m.count = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a HashTag message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.HashTag
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.HashTag} HashTag
         */
        HashTag.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.HashTag)
                return d;
            var m = new $root.Proto.HashTag();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.count != null) {
                m.count = String(d.count);
            }
            return m;
        };

        /**
         * Creates a plain object from a HashTag message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.HashTag
         * @static
         * @param {Proto.HashTag} m HashTag
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HashTag.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.text = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.count = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                d.count = m.count;
            }
            return d;
        };

        /**
         * Converts this HashTag to JSON.
         * @function toJSON
         * @memberof Proto.HashTag
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HashTag.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HashTag;
    })();

    Proto.HashTagList = (function() {

        /**
         * Properties of a HashTagList.
         * @memberof Proto
         * @interface IHashTagList
         * @property {Array.<Proto.IHashTag>|null} [value] HashTagList value
         */

        /**
         * Constructs a new HashTagList.
         * @memberof Proto
         * @classdesc Represents a HashTagList.
         * @implements IHashTagList
         * @constructor
         * @param {Proto.IHashTagList=} [p] Properties to set
         */
        function HashTagList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * HashTagList value.
         * @member {Array.<Proto.IHashTag>} value
         * @memberof Proto.HashTagList
         * @instance
         */
        HashTagList.prototype.value = $util.emptyArray;

        /**
         * Creates a new HashTagList instance using the specified properties.
         * @function create
         * @memberof Proto.HashTagList
         * @static
         * @param {Proto.IHashTagList=} [properties] Properties to set
         * @returns {Proto.HashTagList} HashTagList instance
         */
        HashTagList.create = function create(properties) {
            return new HashTagList(properties);
        };

        /**
         * Encodes the specified HashTagList message. Does not implicitly {@link Proto.HashTagList.verify|verify} messages.
         * @function encode
         * @memberof Proto.HashTagList
         * @static
         * @param {Proto.IHashTagList} m HashTagList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HashTagList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.HashTag.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a HashTagList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.HashTagList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.HashTagList} HashTagList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HashTagList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.HashTagList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.HashTag.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a HashTagList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.HashTagList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.HashTagList} HashTagList
         */
        HashTagList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.HashTagList)
                return d;
            var m = new $root.Proto.HashTagList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.HashTagList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.HashTagList.value: object expected");
                    m.value[i] = $root.Proto.HashTag.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a HashTagList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.HashTagList
         * @static
         * @param {Proto.HashTagList} m HashTagList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HashTagList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.HashTag.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this HashTagList to JSON.
         * @function toJSON
         * @memberof Proto.HashTagList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HashTagList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HashTagList;
    })();

    Proto.TextSearch = (function() {

        /**
         * Properties of a TextSearch.
         * @memberof Proto
         * @interface ITextSearch
         * @property {Proto.IPlaylist|null} [playlist] TextSearch playlist
         * @property {Proto.ICommunityBoardTopic|null} [topic] TextSearch topic
         * @property {Proto.IHashTag|null} [tag] TextSearch tag
         * @property {Proto.IContent|null} [content] TextSearch content
         * @property {Proto.IUser|null} [user] TextSearch user
         * @property {Proto.ICompanyUserGroup|null} [userGroup] TextSearch userGroup
         */

        /**
         * Constructs a new TextSearch.
         * @memberof Proto
         * @classdesc Represents a TextSearch.
         * @implements ITextSearch
         * @constructor
         * @param {Proto.ITextSearch=} [p] Properties to set
         */
        function TextSearch(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TextSearch playlist.
         * @member {Proto.IPlaylist|null|undefined} playlist
         * @memberof Proto.TextSearch
         * @instance
         */
        TextSearch.prototype.playlist = null;

        /**
         * TextSearch topic.
         * @member {Proto.ICommunityBoardTopic|null|undefined} topic
         * @memberof Proto.TextSearch
         * @instance
         */
        TextSearch.prototype.topic = null;

        /**
         * TextSearch tag.
         * @member {Proto.IHashTag|null|undefined} tag
         * @memberof Proto.TextSearch
         * @instance
         */
        TextSearch.prototype.tag = null;

        /**
         * TextSearch content.
         * @member {Proto.IContent|null|undefined} content
         * @memberof Proto.TextSearch
         * @instance
         */
        TextSearch.prototype.content = null;

        /**
         * TextSearch user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.TextSearch
         * @instance
         */
        TextSearch.prototype.user = null;

        /**
         * TextSearch userGroup.
         * @member {Proto.ICompanyUserGroup|null|undefined} userGroup
         * @memberof Proto.TextSearch
         * @instance
         */
        TextSearch.prototype.userGroup = null;

        /**
         * Creates a new TextSearch instance using the specified properties.
         * @function create
         * @memberof Proto.TextSearch
         * @static
         * @param {Proto.ITextSearch=} [properties] Properties to set
         * @returns {Proto.TextSearch} TextSearch instance
         */
        TextSearch.create = function create(properties) {
            return new TextSearch(properties);
        };

        /**
         * Encodes the specified TextSearch message. Does not implicitly {@link Proto.TextSearch.verify|verify} messages.
         * @function encode
         * @memberof Proto.TextSearch
         * @static
         * @param {Proto.ITextSearch} m TextSearch message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TextSearch.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.playlist != null && Object.hasOwnProperty.call(m, "playlist"))
                $root.Proto.Playlist.encode(m.playlist, w.uint32(10).fork()).ldelim();
            if (m.topic != null && Object.hasOwnProperty.call(m, "topic"))
                $root.Proto.CommunityBoardTopic.encode(m.topic, w.uint32(18).fork()).ldelim();
            if (m.tag != null && Object.hasOwnProperty.call(m, "tag"))
                $root.Proto.HashTag.encode(m.tag, w.uint32(26).fork()).ldelim();
            if (m.content != null && Object.hasOwnProperty.call(m, "content"))
                $root.Proto.Content.encode(m.content, w.uint32(34).fork()).ldelim();
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(42).fork()).ldelim();
            if (m.userGroup != null && Object.hasOwnProperty.call(m, "userGroup"))
                $root.Proto.CompanyUserGroup.encode(m.userGroup, w.uint32(50).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a TextSearch message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TextSearch
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TextSearch} TextSearch
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TextSearch.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TextSearch();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.playlist = $root.Proto.Playlist.decode(r, r.uint32());
                    break;
                case 2:
                    m.topic = $root.Proto.CommunityBoardTopic.decode(r, r.uint32());
                    break;
                case 3:
                    m.tag = $root.Proto.HashTag.decode(r, r.uint32());
                    break;
                case 4:
                    m.content = $root.Proto.Content.decode(r, r.uint32());
                    break;
                case 5:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 6:
                    m.userGroup = $root.Proto.CompanyUserGroup.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TextSearch message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TextSearch
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TextSearch} TextSearch
         */
        TextSearch.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TextSearch)
                return d;
            var m = new $root.Proto.TextSearch();
            if (d.playlist != null) {
                if (typeof d.playlist !== "object")
                    throw TypeError(".Proto.TextSearch.playlist: object expected");
                m.playlist = $root.Proto.Playlist.fromObject(d.playlist);
            }
            if (d.topic != null) {
                if (typeof d.topic !== "object")
                    throw TypeError(".Proto.TextSearch.topic: object expected");
                m.topic = $root.Proto.CommunityBoardTopic.fromObject(d.topic);
            }
            if (d.tag != null) {
                if (typeof d.tag !== "object")
                    throw TypeError(".Proto.TextSearch.tag: object expected");
                m.tag = $root.Proto.HashTag.fromObject(d.tag);
            }
            if (d.content != null) {
                if (typeof d.content !== "object")
                    throw TypeError(".Proto.TextSearch.content: object expected");
                m.content = $root.Proto.Content.fromObject(d.content);
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.TextSearch.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            if (d.userGroup != null) {
                if (typeof d.userGroup !== "object")
                    throw TypeError(".Proto.TextSearch.userGroup: object expected");
                m.userGroup = $root.Proto.CompanyUserGroup.fromObject(d.userGroup);
            }
            return m;
        };

        /**
         * Creates a plain object from a TextSearch message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TextSearch
         * @static
         * @param {Proto.TextSearch} m TextSearch
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TextSearch.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.playlist = null;
                d.topic = null;
                d.tag = null;
                d.content = null;
                d.user = null;
                d.userGroup = null;
            }
            if (m.playlist != null && m.hasOwnProperty("playlist")) {
                d.playlist = $root.Proto.Playlist.toObject(m.playlist, o);
            }
            if (m.topic != null && m.hasOwnProperty("topic")) {
                d.topic = $root.Proto.CommunityBoardTopic.toObject(m.topic, o);
            }
            if (m.tag != null && m.hasOwnProperty("tag")) {
                d.tag = $root.Proto.HashTag.toObject(m.tag, o);
            }
            if (m.content != null && m.hasOwnProperty("content")) {
                d.content = $root.Proto.Content.toObject(m.content, o);
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            if (m.userGroup != null && m.hasOwnProperty("userGroup")) {
                d.userGroup = $root.Proto.CompanyUserGroup.toObject(m.userGroup, o);
            }
            return d;
        };

        /**
         * Converts this TextSearch to JSON.
         * @function toJSON
         * @memberof Proto.TextSearch
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TextSearch.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TextSearch;
    })();

    Proto.TextSearchList = (function() {

        /**
         * Properties of a TextSearchList.
         * @memberof Proto
         * @interface ITextSearchList
         * @property {Array.<Proto.ITextSearch>|null} [value] TextSearchList value
         */

        /**
         * Constructs a new TextSearchList.
         * @memberof Proto
         * @classdesc Represents a TextSearchList.
         * @implements ITextSearchList
         * @constructor
         * @param {Proto.ITextSearchList=} [p] Properties to set
         */
        function TextSearchList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TextSearchList value.
         * @member {Array.<Proto.ITextSearch>} value
         * @memberof Proto.TextSearchList
         * @instance
         */
        TextSearchList.prototype.value = $util.emptyArray;

        /**
         * Creates a new TextSearchList instance using the specified properties.
         * @function create
         * @memberof Proto.TextSearchList
         * @static
         * @param {Proto.ITextSearchList=} [properties] Properties to set
         * @returns {Proto.TextSearchList} TextSearchList instance
         */
        TextSearchList.create = function create(properties) {
            return new TextSearchList(properties);
        };

        /**
         * Encodes the specified TextSearchList message. Does not implicitly {@link Proto.TextSearchList.verify|verify} messages.
         * @function encode
         * @memberof Proto.TextSearchList
         * @static
         * @param {Proto.ITextSearchList} m TextSearchList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TextSearchList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.TextSearch.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a TextSearchList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TextSearchList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TextSearchList} TextSearchList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TextSearchList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TextSearchList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.TextSearch.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TextSearchList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TextSearchList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TextSearchList} TextSearchList
         */
        TextSearchList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TextSearchList)
                return d;
            var m = new $root.Proto.TextSearchList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.TextSearchList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.TextSearchList.value: object expected");
                    m.value[i] = $root.Proto.TextSearch.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a TextSearchList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TextSearchList
         * @static
         * @param {Proto.TextSearchList} m TextSearchList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TextSearchList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.TextSearch.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this TextSearchList to JSON.
         * @function toJSON
         * @memberof Proto.TextSearchList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TextSearchList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TextSearchList;
    })();

    Proto.Poll = (function() {

        /**
         * Properties of a Poll.
         * @memberof Proto
         * @interface IPoll
         * @property {string|null} [uid] Poll uid
         * @property {string|null} [title] Poll title
         * @property {string|null} [text] Poll text
         * @property {number|null} [targetPolicy] Poll targetPolicy
         * @property {number|null} [buttonType] Poll buttonType
         * @property {string|null} [buttonUrl] Poll buttonUrl
         * @property {string|null} [buttonUID] Poll buttonUID
         * @property {number|Long|null} [startDate] Poll startDate
         * @property {number|Long|null} [endDate] Poll endDate
         * @property {number|null} [distribution] Poll distribution
         * @property {Proto.IUploadFile|null} [image] Poll image
         * @property {Array.<Proto.IPollFile>|null} [fileList] Poll fileList
         * @property {Array.<Proto.IPollItem>|null} [itemList] Poll itemList
         * @property {Array.<Proto.IPollTarget>|null} [targetList] Poll targetList
         * @property {number|Long|null} [registerDate] Poll registerDate
         * @property {number|Long|null} [modifyDate] Poll modifyDate
         * @property {string|null} [buttonText] Poll buttonText
         * @property {number|null} [order] Poll order
         * @property {boolean|null} [targetAll] Poll targetAll
         * @property {boolean|null} [showHome] Poll showHome
         * @property {boolean|null} [targetMode] Poll targetMode
         */

        /**
         * Constructs a new Poll.
         * @memberof Proto
         * @classdesc Represents a Poll.
         * @implements IPoll
         * @constructor
         * @param {Proto.IPoll=} [p] Properties to set
         */
        function Poll(p) {
            this.fileList = [];
            this.itemList = [];
            this.targetList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Poll uid.
         * @member {string} uid
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.uid = "";

        /**
         * Poll title.
         * @member {string} title
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.title = "";

        /**
         * Poll text.
         * @member {string} text
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.text = "";

        /**
         * Poll targetPolicy.
         * @member {number} targetPolicy
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.targetPolicy = 0;

        /**
         * Poll buttonType.
         * @member {number} buttonType
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.buttonType = 0;

        /**
         * Poll buttonUrl.
         * @member {string} buttonUrl
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.buttonUrl = "";

        /**
         * Poll buttonUID.
         * @member {string} buttonUID
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.buttonUID = "";

        /**
         * Poll startDate.
         * @member {number|Long} startDate
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Poll endDate.
         * @member {number|Long} endDate
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Poll distribution.
         * @member {number} distribution
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.distribution = 0;

        /**
         * Poll image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.image = null;

        /**
         * Poll fileList.
         * @member {Array.<Proto.IPollFile>} fileList
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.fileList = $util.emptyArray;

        /**
         * Poll itemList.
         * @member {Array.<Proto.IPollItem>} itemList
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.itemList = $util.emptyArray;

        /**
         * Poll targetList.
         * @member {Array.<Proto.IPollTarget>} targetList
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.targetList = $util.emptyArray;

        /**
         * Poll registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Poll modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Poll buttonText.
         * @member {string} buttonText
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.buttonText = "";

        /**
         * Poll order.
         * @member {number} order
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.order = 0;

        /**
         * Poll targetAll.
         * @member {boolean} targetAll
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.targetAll = false;

        /**
         * Poll showHome.
         * @member {boolean} showHome
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.showHome = false;

        /**
         * Poll targetMode.
         * @member {boolean} targetMode
         * @memberof Proto.Poll
         * @instance
         */
        Poll.prototype.targetMode = false;

        /**
         * Creates a new Poll instance using the specified properties.
         * @function create
         * @memberof Proto.Poll
         * @static
         * @param {Proto.IPoll=} [properties] Properties to set
         * @returns {Proto.Poll} Poll instance
         */
        Poll.create = function create(properties) {
            return new Poll(properties);
        };

        /**
         * Encodes the specified Poll message. Does not implicitly {@link Proto.Poll.verify|verify} messages.
         * @function encode
         * @memberof Proto.Poll
         * @static
         * @param {Proto.IPoll} m Poll message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Poll.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(18).string(m.title);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.targetPolicy != null && Object.hasOwnProperty.call(m, "targetPolicy"))
                w.uint32(32).int32(m.targetPolicy);
            if (m.buttonType != null && Object.hasOwnProperty.call(m, "buttonType"))
                w.uint32(40).int32(m.buttonType);
            if (m.buttonUrl != null && Object.hasOwnProperty.call(m, "buttonUrl"))
                w.uint32(50).string(m.buttonUrl);
            if (m.buttonUID != null && Object.hasOwnProperty.call(m, "buttonUID"))
                w.uint32(58).string(m.buttonUID);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(64).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(72).int64(m.endDate);
            if (m.distribution != null && Object.hasOwnProperty.call(m, "distribution"))
                w.uint32(80).int32(m.distribution);
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(90).fork()).ldelim();
            if (m.fileList != null && m.fileList.length) {
                for (var i = 0; i < m.fileList.length; ++i)
                    $root.Proto.PollFile.encode(m.fileList[i], w.uint32(98).fork()).ldelim();
            }
            if (m.itemList != null && m.itemList.length) {
                for (var i = 0; i < m.itemList.length; ++i)
                    $root.Proto.PollItem.encode(m.itemList[i], w.uint32(106).fork()).ldelim();
            }
            if (m.targetList != null && m.targetList.length) {
                for (var i = 0; i < m.targetList.length; ++i)
                    $root.Proto.PollTarget.encode(m.targetList[i], w.uint32(114).fork()).ldelim();
            }
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(120).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(128).int64(m.modifyDate);
            if (m.buttonText != null && Object.hasOwnProperty.call(m, "buttonText"))
                w.uint32(138).string(m.buttonText);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(144).int32(m.order);
            if (m.targetAll != null && Object.hasOwnProperty.call(m, "targetAll"))
                w.uint32(152).bool(m.targetAll);
            if (m.showHome != null && Object.hasOwnProperty.call(m, "showHome"))
                w.uint32(160).bool(m.showHome);
            if (m.targetMode != null && Object.hasOwnProperty.call(m, "targetMode"))
                w.uint32(168).bool(m.targetMode);
            return w;
        };

        /**
         * Decodes a Poll message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.Poll
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.Poll} Poll
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Poll.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.Poll();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.title = r.string();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.targetPolicy = r.int32();
                    break;
                case 5:
                    m.buttonType = r.int32();
                    break;
                case 6:
                    m.buttonUrl = r.string();
                    break;
                case 7:
                    m.buttonUID = r.string();
                    break;
                case 8:
                    m.startDate = r.int64();
                    break;
                case 9:
                    m.endDate = r.int64();
                    break;
                case 10:
                    m.distribution = r.int32();
                    break;
                case 11:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 12:
                    if (!(m.fileList && m.fileList.length))
                        m.fileList = [];
                    m.fileList.push($root.Proto.PollFile.decode(r, r.uint32()));
                    break;
                case 13:
                    if (!(m.itemList && m.itemList.length))
                        m.itemList = [];
                    m.itemList.push($root.Proto.PollItem.decode(r, r.uint32()));
                    break;
                case 14:
                    if (!(m.targetList && m.targetList.length))
                        m.targetList = [];
                    m.targetList.push($root.Proto.PollTarget.decode(r, r.uint32()));
                    break;
                case 15:
                    m.registerDate = r.int64();
                    break;
                case 16:
                    m.modifyDate = r.int64();
                    break;
                case 17:
                    m.buttonText = r.string();
                    break;
                case 18:
                    m.order = r.int32();
                    break;
                case 19:
                    m.targetAll = r.bool();
                    break;
                case 20:
                    m.showHome = r.bool();
                    break;
                case 21:
                    m.targetMode = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Poll message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.Poll
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.Poll} Poll
         */
        Poll.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.Poll)
                return d;
            var m = new $root.Proto.Poll();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.targetPolicy != null) {
                m.targetPolicy = d.targetPolicy | 0;
            }
            if (d.buttonType != null) {
                m.buttonType = d.buttonType | 0;
            }
            if (d.buttonUrl != null) {
                m.buttonUrl = String(d.buttonUrl);
            }
            if (d.buttonUID != null) {
                m.buttonUID = String(d.buttonUID);
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.distribution != null) {
                m.distribution = d.distribution | 0;
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.Poll.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.fileList) {
                if (!Array.isArray(d.fileList))
                    throw TypeError(".Proto.Poll.fileList: array expected");
                m.fileList = [];
                for (var i = 0; i < d.fileList.length; ++i) {
                    if (typeof d.fileList[i] !== "object")
                        throw TypeError(".Proto.Poll.fileList: object expected");
                    m.fileList[i] = $root.Proto.PollFile.fromObject(d.fileList[i]);
                }
            }
            if (d.itemList) {
                if (!Array.isArray(d.itemList))
                    throw TypeError(".Proto.Poll.itemList: array expected");
                m.itemList = [];
                for (var i = 0; i < d.itemList.length; ++i) {
                    if (typeof d.itemList[i] !== "object")
                        throw TypeError(".Proto.Poll.itemList: object expected");
                    m.itemList[i] = $root.Proto.PollItem.fromObject(d.itemList[i]);
                }
            }
            if (d.targetList) {
                if (!Array.isArray(d.targetList))
                    throw TypeError(".Proto.Poll.targetList: array expected");
                m.targetList = [];
                for (var i = 0; i < d.targetList.length; ++i) {
                    if (typeof d.targetList[i] !== "object")
                        throw TypeError(".Proto.Poll.targetList: object expected");
                    m.targetList[i] = $root.Proto.PollTarget.fromObject(d.targetList[i]);
                }
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.buttonText != null) {
                m.buttonText = String(d.buttonText);
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.targetAll != null) {
                m.targetAll = Boolean(d.targetAll);
            }
            if (d.showHome != null) {
                m.showHome = Boolean(d.showHome);
            }
            if (d.targetMode != null) {
                m.targetMode = Boolean(d.targetMode);
            }
            return m;
        };

        /**
         * Creates a plain object from a Poll message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.Poll
         * @static
         * @param {Proto.Poll} m Poll
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Poll.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.fileList = [];
                d.itemList = [];
                d.targetList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.title = "";
                d.text = "";
                d.targetPolicy = 0;
                d.buttonType = 0;
                d.buttonUrl = "";
                d.buttonUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                d.distribution = 0;
                d.image = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.buttonText = "";
                d.order = 0;
                d.targetAll = false;
                d.showHome = false;
                d.targetMode = false;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.targetPolicy != null && m.hasOwnProperty("targetPolicy")) {
                d.targetPolicy = m.targetPolicy;
            }
            if (m.buttonType != null && m.hasOwnProperty("buttonType")) {
                d.buttonType = m.buttonType;
            }
            if (m.buttonUrl != null && m.hasOwnProperty("buttonUrl")) {
                d.buttonUrl = m.buttonUrl;
            }
            if (m.buttonUID != null && m.hasOwnProperty("buttonUID")) {
                d.buttonUID = m.buttonUID;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.distribution != null && m.hasOwnProperty("distribution")) {
                d.distribution = m.distribution;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.fileList && m.fileList.length) {
                d.fileList = [];
                for (var j = 0; j < m.fileList.length; ++j) {
                    d.fileList[j] = $root.Proto.PollFile.toObject(m.fileList[j], o);
                }
            }
            if (m.itemList && m.itemList.length) {
                d.itemList = [];
                for (var j = 0; j < m.itemList.length; ++j) {
                    d.itemList[j] = $root.Proto.PollItem.toObject(m.itemList[j], o);
                }
            }
            if (m.targetList && m.targetList.length) {
                d.targetList = [];
                for (var j = 0; j < m.targetList.length; ++j) {
                    d.targetList[j] = $root.Proto.PollTarget.toObject(m.targetList[j], o);
                }
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.buttonText != null && m.hasOwnProperty("buttonText")) {
                d.buttonText = m.buttonText;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.targetAll != null && m.hasOwnProperty("targetAll")) {
                d.targetAll = m.targetAll;
            }
            if (m.showHome != null && m.hasOwnProperty("showHome")) {
                d.showHome = m.showHome;
            }
            if (m.targetMode != null && m.hasOwnProperty("targetMode")) {
                d.targetMode = m.targetMode;
            }
            return d;
        };

        /**
         * Converts this Poll to JSON.
         * @function toJSON
         * @memberof Proto.Poll
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Poll.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Poll;
    })();

    Proto.PollList = (function() {

        /**
         * Properties of a PollList.
         * @memberof Proto
         * @interface IPollList
         * @property {Array.<Proto.IPoll>|null} [value] PollList value
         */

        /**
         * Constructs a new PollList.
         * @memberof Proto
         * @classdesc Represents a PollList.
         * @implements IPollList
         * @constructor
         * @param {Proto.IPollList=} [p] Properties to set
         */
        function PollList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PollList value.
         * @member {Array.<Proto.IPoll>} value
         * @memberof Proto.PollList
         * @instance
         */
        PollList.prototype.value = $util.emptyArray;

        /**
         * Creates a new PollList instance using the specified properties.
         * @function create
         * @memberof Proto.PollList
         * @static
         * @param {Proto.IPollList=} [properties] Properties to set
         * @returns {Proto.PollList} PollList instance
         */
        PollList.create = function create(properties) {
            return new PollList(properties);
        };

        /**
         * Encodes the specified PollList message. Does not implicitly {@link Proto.PollList.verify|verify} messages.
         * @function encode
         * @memberof Proto.PollList
         * @static
         * @param {Proto.IPollList} m PollList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PollList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.Poll.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a PollList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.PollList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.PollList} PollList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PollList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.PollList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.Poll.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PollList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.PollList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.PollList} PollList
         */
        PollList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.PollList)
                return d;
            var m = new $root.Proto.PollList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.PollList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.PollList.value: object expected");
                    m.value[i] = $root.Proto.Poll.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a PollList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.PollList
         * @static
         * @param {Proto.PollList} m PollList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PollList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.Poll.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this PollList to JSON.
         * @function toJSON
         * @memberof Proto.PollList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PollList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PollList;
    })();

    Proto.PollFile = (function() {

        /**
         * Properties of a PollFile.
         * @memberof Proto
         * @interface IPollFile
         * @property {string|null} [uid] PollFile uid
         * @property {string|null} [pollUID] PollFile pollUID
         * @property {number|null} [fileSize] PollFile fileSize
         * @property {string|null} [name] PollFile name
         * @property {number|null} [policy] PollFile policy
         * @property {number|null} [order] PollFile order
         * @property {Proto.IUploadFile|null} [file] PollFile file
         * @property {number|Long|null} [registerDate] PollFile registerDate
         * @property {number|Long|null} [modifyDate] PollFile modifyDate
         * @property {number|null} [status] PollFile status
         */

        /**
         * Constructs a new PollFile.
         * @memberof Proto
         * @classdesc Represents a PollFile.
         * @implements IPollFile
         * @constructor
         * @param {Proto.IPollFile=} [p] Properties to set
         */
        function PollFile(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PollFile uid.
         * @member {string} uid
         * @memberof Proto.PollFile
         * @instance
         */
        PollFile.prototype.uid = "";

        /**
         * PollFile pollUID.
         * @member {string} pollUID
         * @memberof Proto.PollFile
         * @instance
         */
        PollFile.prototype.pollUID = "";

        /**
         * PollFile fileSize.
         * @member {number} fileSize
         * @memberof Proto.PollFile
         * @instance
         */
        PollFile.prototype.fileSize = 0;

        /**
         * PollFile name.
         * @member {string} name
         * @memberof Proto.PollFile
         * @instance
         */
        PollFile.prototype.name = "";

        /**
         * PollFile policy.
         * @member {number} policy
         * @memberof Proto.PollFile
         * @instance
         */
        PollFile.prototype.policy = 0;

        /**
         * PollFile order.
         * @member {number} order
         * @memberof Proto.PollFile
         * @instance
         */
        PollFile.prototype.order = 0;

        /**
         * PollFile file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.PollFile
         * @instance
         */
        PollFile.prototype.file = null;

        /**
         * PollFile registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.PollFile
         * @instance
         */
        PollFile.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PollFile modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.PollFile
         * @instance
         */
        PollFile.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PollFile status.
         * @member {number} status
         * @memberof Proto.PollFile
         * @instance
         */
        PollFile.prototype.status = 0;

        /**
         * Creates a new PollFile instance using the specified properties.
         * @function create
         * @memberof Proto.PollFile
         * @static
         * @param {Proto.IPollFile=} [properties] Properties to set
         * @returns {Proto.PollFile} PollFile instance
         */
        PollFile.create = function create(properties) {
            return new PollFile(properties);
        };

        /**
         * Encodes the specified PollFile message. Does not implicitly {@link Proto.PollFile.verify|verify} messages.
         * @function encode
         * @memberof Proto.PollFile
         * @static
         * @param {Proto.IPollFile} m PollFile message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PollFile.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.pollUID != null && Object.hasOwnProperty.call(m, "pollUID"))
                w.uint32(18).string(m.pollUID);
            if (m.fileSize != null && Object.hasOwnProperty.call(m, "fileSize"))
                w.uint32(25).double(m.fileSize);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(34).string(m.name);
            if (m.policy != null && Object.hasOwnProperty.call(m, "policy"))
                w.uint32(40).int32(m.policy);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(48).int32(m.order);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(58).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(80).int32(m.status);
            return w;
        };

        /**
         * Decodes a PollFile message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.PollFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.PollFile} PollFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PollFile.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.PollFile();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.pollUID = r.string();
                    break;
                case 3:
                    m.fileSize = r.double();
                    break;
                case 4:
                    m.name = r.string();
                    break;
                case 5:
                    m.policy = r.int32();
                    break;
                case 6:
                    m.order = r.int32();
                    break;
                case 7:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                case 10:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PollFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.PollFile
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.PollFile} PollFile
         */
        PollFile.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.PollFile)
                return d;
            var m = new $root.Proto.PollFile();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.pollUID != null) {
                m.pollUID = String(d.pollUID);
            }
            if (d.fileSize != null) {
                m.fileSize = Number(d.fileSize);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.policy != null) {
                m.policy = d.policy | 0;
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.PollFile.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a PollFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.PollFile
         * @static
         * @param {Proto.PollFile} m PollFile
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PollFile.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.pollUID = "";
                d.fileSize = 0;
                d.name = "";
                d.policy = 0;
                d.order = 0;
                d.file = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.pollUID != null && m.hasOwnProperty("pollUID")) {
                d.pollUID = m.pollUID;
            }
            if (m.fileSize != null && m.hasOwnProperty("fileSize")) {
                d.fileSize = o.json && !isFinite(m.fileSize) ? String(m.fileSize) : m.fileSize;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.policy != null && m.hasOwnProperty("policy")) {
                d.policy = m.policy;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this PollFile to JSON.
         * @function toJSON
         * @memberof Proto.PollFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PollFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PollFile;
    })();

    Proto.PollItem = (function() {

        /**
         * Properties of a PollItem.
         * @memberof Proto
         * @interface IPollItem
         * @property {string|null} [uid] PollItem uid
         * @property {string|null} [pollUID] PollItem pollUID
         * @property {number|null} [type] PollItem type
         * @property {string|null} [name] PollItem name
         * @property {boolean|null} [optional] PollItem optional
         * @property {string|null} [setting] PollItem setting
         * @property {number|null} [order] PollItem order
         * @property {number|Long|null} [registerDate] PollItem registerDate
         * @property {number|Long|null} [modifyDate] PollItem modifyDate
         * @property {number|null} [status] PollItem status
         */

        /**
         * Constructs a new PollItem.
         * @memberof Proto
         * @classdesc Represents a PollItem.
         * @implements IPollItem
         * @constructor
         * @param {Proto.IPollItem=} [p] Properties to set
         */
        function PollItem(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PollItem uid.
         * @member {string} uid
         * @memberof Proto.PollItem
         * @instance
         */
        PollItem.prototype.uid = "";

        /**
         * PollItem pollUID.
         * @member {string} pollUID
         * @memberof Proto.PollItem
         * @instance
         */
        PollItem.prototype.pollUID = "";

        /**
         * PollItem type.
         * @member {number} type
         * @memberof Proto.PollItem
         * @instance
         */
        PollItem.prototype.type = 0;

        /**
         * PollItem name.
         * @member {string} name
         * @memberof Proto.PollItem
         * @instance
         */
        PollItem.prototype.name = "";

        /**
         * PollItem optional.
         * @member {boolean} optional
         * @memberof Proto.PollItem
         * @instance
         */
        PollItem.prototype.optional = false;

        /**
         * PollItem setting.
         * @member {string} setting
         * @memberof Proto.PollItem
         * @instance
         */
        PollItem.prototype.setting = "";

        /**
         * PollItem order.
         * @member {number} order
         * @memberof Proto.PollItem
         * @instance
         */
        PollItem.prototype.order = 0;

        /**
         * PollItem registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.PollItem
         * @instance
         */
        PollItem.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PollItem modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.PollItem
         * @instance
         */
        PollItem.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PollItem status.
         * @member {number} status
         * @memberof Proto.PollItem
         * @instance
         */
        PollItem.prototype.status = 0;

        /**
         * Creates a new PollItem instance using the specified properties.
         * @function create
         * @memberof Proto.PollItem
         * @static
         * @param {Proto.IPollItem=} [properties] Properties to set
         * @returns {Proto.PollItem} PollItem instance
         */
        PollItem.create = function create(properties) {
            return new PollItem(properties);
        };

        /**
         * Encodes the specified PollItem message. Does not implicitly {@link Proto.PollItem.verify|verify} messages.
         * @function encode
         * @memberof Proto.PollItem
         * @static
         * @param {Proto.IPollItem} m PollItem message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PollItem.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.pollUID != null && Object.hasOwnProperty.call(m, "pollUID"))
                w.uint32(18).string(m.pollUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(24).int32(m.type);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(34).string(m.name);
            if (m.optional != null && Object.hasOwnProperty.call(m, "optional"))
                w.uint32(40).bool(m.optional);
            if (m.setting != null && Object.hasOwnProperty.call(m, "setting"))
                w.uint32(50).string(m.setting);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(56).int32(m.order);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(80).int32(m.status);
            return w;
        };

        /**
         * Decodes a PollItem message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.PollItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.PollItem} PollItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PollItem.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.PollItem();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.pollUID = r.string();
                    break;
                case 3:
                    m.type = r.int32();
                    break;
                case 4:
                    m.name = r.string();
                    break;
                case 5:
                    m.optional = r.bool();
                    break;
                case 6:
                    m.setting = r.string();
                    break;
                case 7:
                    m.order = r.int32();
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                case 10:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PollItem message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.PollItem
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.PollItem} PollItem
         */
        PollItem.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.PollItem)
                return d;
            var m = new $root.Proto.PollItem();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.pollUID != null) {
                m.pollUID = String(d.pollUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.optional != null) {
                m.optional = Boolean(d.optional);
            }
            if (d.setting != null) {
                m.setting = String(d.setting);
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a PollItem message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.PollItem
         * @static
         * @param {Proto.PollItem} m PollItem
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PollItem.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.pollUID = "";
                d.type = 0;
                d.name = "";
                d.optional = false;
                d.setting = "";
                d.order = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.pollUID != null && m.hasOwnProperty("pollUID")) {
                d.pollUID = m.pollUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.optional != null && m.hasOwnProperty("optional")) {
                d.optional = m.optional;
            }
            if (m.setting != null && m.hasOwnProperty("setting")) {
                d.setting = m.setting;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this PollItem to JSON.
         * @function toJSON
         * @memberof Proto.PollItem
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PollItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PollItem;
    })();

    Proto.PollAnswer = (function() {

        /**
         * Properties of a PollAnswer.
         * @memberof Proto
         * @interface IPollAnswer
         * @property {string|null} [uid] PollAnswer uid
         * @property {string|null} [pollUID] PollAnswer pollUID
         * @property {string|null} [userUID] PollAnswer userUID
         * @property {number|Long|null} [registerDate] PollAnswer registerDate
         * @property {number|Long|null} [modifyDate] PollAnswer modifyDate
         * @property {Proto.IUser|null} [user] PollAnswer user
         * @property {Array.<Proto.IPollAnswerItem>|null} [itemList] PollAnswer itemList
         */

        /**
         * Constructs a new PollAnswer.
         * @memberof Proto
         * @classdesc Represents a PollAnswer.
         * @implements IPollAnswer
         * @constructor
         * @param {Proto.IPollAnswer=} [p] Properties to set
         */
        function PollAnswer(p) {
            this.itemList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PollAnswer uid.
         * @member {string} uid
         * @memberof Proto.PollAnswer
         * @instance
         */
        PollAnswer.prototype.uid = "";

        /**
         * PollAnswer pollUID.
         * @member {string} pollUID
         * @memberof Proto.PollAnswer
         * @instance
         */
        PollAnswer.prototype.pollUID = "";

        /**
         * PollAnswer userUID.
         * @member {string} userUID
         * @memberof Proto.PollAnswer
         * @instance
         */
        PollAnswer.prototype.userUID = "";

        /**
         * PollAnswer registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.PollAnswer
         * @instance
         */
        PollAnswer.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PollAnswer modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.PollAnswer
         * @instance
         */
        PollAnswer.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PollAnswer user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.PollAnswer
         * @instance
         */
        PollAnswer.prototype.user = null;

        /**
         * PollAnswer itemList.
         * @member {Array.<Proto.IPollAnswerItem>} itemList
         * @memberof Proto.PollAnswer
         * @instance
         */
        PollAnswer.prototype.itemList = $util.emptyArray;

        /**
         * Creates a new PollAnswer instance using the specified properties.
         * @function create
         * @memberof Proto.PollAnswer
         * @static
         * @param {Proto.IPollAnswer=} [properties] Properties to set
         * @returns {Proto.PollAnswer} PollAnswer instance
         */
        PollAnswer.create = function create(properties) {
            return new PollAnswer(properties);
        };

        /**
         * Encodes the specified PollAnswer message. Does not implicitly {@link Proto.PollAnswer.verify|verify} messages.
         * @function encode
         * @memberof Proto.PollAnswer
         * @static
         * @param {Proto.IPollAnswer} m PollAnswer message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PollAnswer.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.pollUID != null && Object.hasOwnProperty.call(m, "pollUID"))
                w.uint32(18).string(m.pollUID);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(26).string(m.userUID);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(50).fork()).ldelim();
            if (m.itemList != null && m.itemList.length) {
                for (var i = 0; i < m.itemList.length; ++i)
                    $root.Proto.PollAnswerItem.encode(m.itemList[i], w.uint32(58).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a PollAnswer message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.PollAnswer
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.PollAnswer} PollAnswer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PollAnswer.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.PollAnswer();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.pollUID = r.string();
                    break;
                case 3:
                    m.userUID = r.string();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                case 6:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 7:
                    if (!(m.itemList && m.itemList.length))
                        m.itemList = [];
                    m.itemList.push($root.Proto.PollAnswerItem.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PollAnswer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.PollAnswer
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.PollAnswer} PollAnswer
         */
        PollAnswer.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.PollAnswer)
                return d;
            var m = new $root.Proto.PollAnswer();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.pollUID != null) {
                m.pollUID = String(d.pollUID);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.PollAnswer.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            if (d.itemList) {
                if (!Array.isArray(d.itemList))
                    throw TypeError(".Proto.PollAnswer.itemList: array expected");
                m.itemList = [];
                for (var i = 0; i < d.itemList.length; ++i) {
                    if (typeof d.itemList[i] !== "object")
                        throw TypeError(".Proto.PollAnswer.itemList: object expected");
                    m.itemList[i] = $root.Proto.PollAnswerItem.fromObject(d.itemList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a PollAnswer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.PollAnswer
         * @static
         * @param {Proto.PollAnswer} m PollAnswer
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PollAnswer.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.itemList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.pollUID = "";
                d.userUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.user = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.pollUID != null && m.hasOwnProperty("pollUID")) {
                d.pollUID = m.pollUID;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            if (m.itemList && m.itemList.length) {
                d.itemList = [];
                for (var j = 0; j < m.itemList.length; ++j) {
                    d.itemList[j] = $root.Proto.PollAnswerItem.toObject(m.itemList[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this PollAnswer to JSON.
         * @function toJSON
         * @memberof Proto.PollAnswer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PollAnswer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PollAnswer;
    })();

    Proto.PollAnswerList = (function() {

        /**
         * Properties of a PollAnswerList.
         * @memberof Proto
         * @interface IPollAnswerList
         * @property {Array.<Proto.IPollAnswer>|null} [value] PollAnswerList value
         */

        /**
         * Constructs a new PollAnswerList.
         * @memberof Proto
         * @classdesc Represents a PollAnswerList.
         * @implements IPollAnswerList
         * @constructor
         * @param {Proto.IPollAnswerList=} [p] Properties to set
         */
        function PollAnswerList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PollAnswerList value.
         * @member {Array.<Proto.IPollAnswer>} value
         * @memberof Proto.PollAnswerList
         * @instance
         */
        PollAnswerList.prototype.value = $util.emptyArray;

        /**
         * Creates a new PollAnswerList instance using the specified properties.
         * @function create
         * @memberof Proto.PollAnswerList
         * @static
         * @param {Proto.IPollAnswerList=} [properties] Properties to set
         * @returns {Proto.PollAnswerList} PollAnswerList instance
         */
        PollAnswerList.create = function create(properties) {
            return new PollAnswerList(properties);
        };

        /**
         * Encodes the specified PollAnswerList message. Does not implicitly {@link Proto.PollAnswerList.verify|verify} messages.
         * @function encode
         * @memberof Proto.PollAnswerList
         * @static
         * @param {Proto.IPollAnswerList} m PollAnswerList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PollAnswerList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.PollAnswer.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a PollAnswerList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.PollAnswerList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.PollAnswerList} PollAnswerList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PollAnswerList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.PollAnswerList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.PollAnswer.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PollAnswerList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.PollAnswerList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.PollAnswerList} PollAnswerList
         */
        PollAnswerList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.PollAnswerList)
                return d;
            var m = new $root.Proto.PollAnswerList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.PollAnswerList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.PollAnswerList.value: object expected");
                    m.value[i] = $root.Proto.PollAnswer.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a PollAnswerList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.PollAnswerList
         * @static
         * @param {Proto.PollAnswerList} m PollAnswerList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PollAnswerList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.PollAnswer.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this PollAnswerList to JSON.
         * @function toJSON
         * @memberof Proto.PollAnswerList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PollAnswerList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PollAnswerList;
    })();

    Proto.PollAnswerItem = (function() {

        /**
         * Properties of a PollAnswerItem.
         * @memberof Proto
         * @interface IPollAnswerItem
         * @property {string|null} [ansUID] PollAnswerItem ansUID
         * @property {string|null} [itemUID] PollAnswerItem itemUID
         * @property {string|null} [text] PollAnswerItem text
         * @property {number|null} [number] PollAnswerItem number
         * @property {number|Long|null} [date] PollAnswerItem date
         * @property {number|Long|null} [registerDate] PollAnswerItem registerDate
         * @property {number|Long|null} [modifyDate] PollAnswerItem modifyDate
         * @property {Proto.IUploadFile|null} [file] PollAnswerItem file
         */

        /**
         * Constructs a new PollAnswerItem.
         * @memberof Proto
         * @classdesc Represents a PollAnswerItem.
         * @implements IPollAnswerItem
         * @constructor
         * @param {Proto.IPollAnswerItem=} [p] Properties to set
         */
        function PollAnswerItem(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PollAnswerItem ansUID.
         * @member {string} ansUID
         * @memberof Proto.PollAnswerItem
         * @instance
         */
        PollAnswerItem.prototype.ansUID = "";

        /**
         * PollAnswerItem itemUID.
         * @member {string} itemUID
         * @memberof Proto.PollAnswerItem
         * @instance
         */
        PollAnswerItem.prototype.itemUID = "";

        /**
         * PollAnswerItem text.
         * @member {string} text
         * @memberof Proto.PollAnswerItem
         * @instance
         */
        PollAnswerItem.prototype.text = "";

        /**
         * PollAnswerItem number.
         * @member {number} number
         * @memberof Proto.PollAnswerItem
         * @instance
         */
        PollAnswerItem.prototype.number = 0;

        /**
         * PollAnswerItem date.
         * @member {number|Long} date
         * @memberof Proto.PollAnswerItem
         * @instance
         */
        PollAnswerItem.prototype.date = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PollAnswerItem registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.PollAnswerItem
         * @instance
         */
        PollAnswerItem.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PollAnswerItem modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.PollAnswerItem
         * @instance
         */
        PollAnswerItem.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PollAnswerItem file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.PollAnswerItem
         * @instance
         */
        PollAnswerItem.prototype.file = null;

        /**
         * Creates a new PollAnswerItem instance using the specified properties.
         * @function create
         * @memberof Proto.PollAnswerItem
         * @static
         * @param {Proto.IPollAnswerItem=} [properties] Properties to set
         * @returns {Proto.PollAnswerItem} PollAnswerItem instance
         */
        PollAnswerItem.create = function create(properties) {
            return new PollAnswerItem(properties);
        };

        /**
         * Encodes the specified PollAnswerItem message. Does not implicitly {@link Proto.PollAnswerItem.verify|verify} messages.
         * @function encode
         * @memberof Proto.PollAnswerItem
         * @static
         * @param {Proto.IPollAnswerItem} m PollAnswerItem message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PollAnswerItem.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.ansUID != null && Object.hasOwnProperty.call(m, "ansUID"))
                w.uint32(10).string(m.ansUID);
            if (m.itemUID != null && Object.hasOwnProperty.call(m, "itemUID"))
                w.uint32(18).string(m.itemUID);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.number != null && Object.hasOwnProperty.call(m, "number"))
                w.uint32(32).int32(m.number);
            if (m.date != null && Object.hasOwnProperty.call(m, "date"))
                w.uint32(40).int64(m.date);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(66).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a PollAnswerItem message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.PollAnswerItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.PollAnswerItem} PollAnswerItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PollAnswerItem.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.PollAnswerItem();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.ansUID = r.string();
                    break;
                case 2:
                    m.itemUID = r.string();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.number = r.int32();
                    break;
                case 5:
                    m.date = r.int64();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PollAnswerItem message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.PollAnswerItem
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.PollAnswerItem} PollAnswerItem
         */
        PollAnswerItem.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.PollAnswerItem)
                return d;
            var m = new $root.Proto.PollAnswerItem();
            if (d.ansUID != null) {
                m.ansUID = String(d.ansUID);
            }
            if (d.itemUID != null) {
                m.itemUID = String(d.itemUID);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.number != null) {
                m.number = d.number | 0;
            }
            if (d.date != null) {
                if ($util.Long)
                    (m.date = $util.Long.fromValue(d.date)).unsigned = false;
                else if (typeof d.date === "string")
                    m.date = parseInt(d.date, 10);
                else if (typeof d.date === "number")
                    m.date = d.date;
                else if (typeof d.date === "object")
                    m.date = new $util.LongBits(d.date.low >>> 0, d.date.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.PollAnswerItem.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            return m;
        };

        /**
         * Creates a plain object from a PollAnswerItem message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.PollAnswerItem
         * @static
         * @param {Proto.PollAnswerItem} m PollAnswerItem
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PollAnswerItem.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.ansUID = "";
                d.itemUID = "";
                d.text = "";
                d.number = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.date = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.date = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.file = null;
            }
            if (m.ansUID != null && m.hasOwnProperty("ansUID")) {
                d.ansUID = m.ansUID;
            }
            if (m.itemUID != null && m.hasOwnProperty("itemUID")) {
                d.itemUID = m.itemUID;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.number != null && m.hasOwnProperty("number")) {
                d.number = m.number;
            }
            if (m.date != null && m.hasOwnProperty("date")) {
                if (typeof m.date === "number")
                    d.date = o.longs === String ? String(m.date) : m.date;
                else
                    d.date = o.longs === String ? $util.Long.prototype.toString.call(m.date) : o.longs === Number ? new $util.LongBits(m.date.low >>> 0, m.date.high >>> 0).toNumber() : m.date;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            return d;
        };

        /**
         * Converts this PollAnswerItem to JSON.
         * @function toJSON
         * @memberof Proto.PollAnswerItem
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PollAnswerItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PollAnswerItem;
    })();

    Proto.PollAnswerItemList = (function() {

        /**
         * Properties of a PollAnswerItemList.
         * @memberof Proto
         * @interface IPollAnswerItemList
         * @property {Array.<Proto.IPollAnswerItem>|null} [value] PollAnswerItemList value
         */

        /**
         * Constructs a new PollAnswerItemList.
         * @memberof Proto
         * @classdesc Represents a PollAnswerItemList.
         * @implements IPollAnswerItemList
         * @constructor
         * @param {Proto.IPollAnswerItemList=} [p] Properties to set
         */
        function PollAnswerItemList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PollAnswerItemList value.
         * @member {Array.<Proto.IPollAnswerItem>} value
         * @memberof Proto.PollAnswerItemList
         * @instance
         */
        PollAnswerItemList.prototype.value = $util.emptyArray;

        /**
         * Creates a new PollAnswerItemList instance using the specified properties.
         * @function create
         * @memberof Proto.PollAnswerItemList
         * @static
         * @param {Proto.IPollAnswerItemList=} [properties] Properties to set
         * @returns {Proto.PollAnswerItemList} PollAnswerItemList instance
         */
        PollAnswerItemList.create = function create(properties) {
            return new PollAnswerItemList(properties);
        };

        /**
         * Encodes the specified PollAnswerItemList message. Does not implicitly {@link Proto.PollAnswerItemList.verify|verify} messages.
         * @function encode
         * @memberof Proto.PollAnswerItemList
         * @static
         * @param {Proto.IPollAnswerItemList} m PollAnswerItemList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PollAnswerItemList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.PollAnswerItem.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a PollAnswerItemList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.PollAnswerItemList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.PollAnswerItemList} PollAnswerItemList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PollAnswerItemList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.PollAnswerItemList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.PollAnswerItem.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PollAnswerItemList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.PollAnswerItemList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.PollAnswerItemList} PollAnswerItemList
         */
        PollAnswerItemList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.PollAnswerItemList)
                return d;
            var m = new $root.Proto.PollAnswerItemList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.PollAnswerItemList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.PollAnswerItemList.value: object expected");
                    m.value[i] = $root.Proto.PollAnswerItem.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a PollAnswerItemList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.PollAnswerItemList
         * @static
         * @param {Proto.PollAnswerItemList} m PollAnswerItemList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PollAnswerItemList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.PollAnswerItem.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this PollAnswerItemList to JSON.
         * @function toJSON
         * @memberof Proto.PollAnswerItemList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PollAnswerItemList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PollAnswerItemList;
    })();

    Proto.PollTarget = (function() {

        /**
         * Properties of a PollTarget.
         * @memberof Proto
         * @interface IPollTarget
         * @property {string|null} [pollUID] PollTarget pollUID
         * @property {string|null} [planUID] PollTarget planUID
         * @property {string|null} [groupUID] PollTarget groupUID
         * @property {number|Long|null} [registerDate] PollTarget registerDate
         * @property {number|Long|null} [modifyDate] PollTarget modifyDate
         * @property {number|null} [status] PollTarget status
         * @property {string|null} [planName] PollTarget planName
         */

        /**
         * Constructs a new PollTarget.
         * @memberof Proto
         * @classdesc Represents a PollTarget.
         * @implements IPollTarget
         * @constructor
         * @param {Proto.IPollTarget=} [p] Properties to set
         */
        function PollTarget(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PollTarget pollUID.
         * @member {string} pollUID
         * @memberof Proto.PollTarget
         * @instance
         */
        PollTarget.prototype.pollUID = "";

        /**
         * PollTarget planUID.
         * @member {string} planUID
         * @memberof Proto.PollTarget
         * @instance
         */
        PollTarget.prototype.planUID = "";

        /**
         * PollTarget groupUID.
         * @member {string} groupUID
         * @memberof Proto.PollTarget
         * @instance
         */
        PollTarget.prototype.groupUID = "";

        /**
         * PollTarget registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.PollTarget
         * @instance
         */
        PollTarget.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PollTarget modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.PollTarget
         * @instance
         */
        PollTarget.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PollTarget status.
         * @member {number} status
         * @memberof Proto.PollTarget
         * @instance
         */
        PollTarget.prototype.status = 0;

        /**
         * PollTarget planName.
         * @member {string} planName
         * @memberof Proto.PollTarget
         * @instance
         */
        PollTarget.prototype.planName = "";

        /**
         * Creates a new PollTarget instance using the specified properties.
         * @function create
         * @memberof Proto.PollTarget
         * @static
         * @param {Proto.IPollTarget=} [properties] Properties to set
         * @returns {Proto.PollTarget} PollTarget instance
         */
        PollTarget.create = function create(properties) {
            return new PollTarget(properties);
        };

        /**
         * Encodes the specified PollTarget message. Does not implicitly {@link Proto.PollTarget.verify|verify} messages.
         * @function encode
         * @memberof Proto.PollTarget
         * @static
         * @param {Proto.IPollTarget} m PollTarget message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PollTarget.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.pollUID != null && Object.hasOwnProperty.call(m, "pollUID"))
                w.uint32(10).string(m.pollUID);
            if (m.planUID != null && Object.hasOwnProperty.call(m, "planUID"))
                w.uint32(18).string(m.planUID);
            if (m.groupUID != null && Object.hasOwnProperty.call(m, "groupUID"))
                w.uint32(26).string(m.groupUID);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(48).int32(m.status);
            if (m.planName != null && Object.hasOwnProperty.call(m, "planName"))
                w.uint32(58).string(m.planName);
            return w;
        };

        /**
         * Decodes a PollTarget message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.PollTarget
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.PollTarget} PollTarget
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PollTarget.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.PollTarget();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.pollUID = r.string();
                    break;
                case 2:
                    m.planUID = r.string();
                    break;
                case 3:
                    m.groupUID = r.string();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                case 6:
                    m.status = r.int32();
                    break;
                case 7:
                    m.planName = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PollTarget message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.PollTarget
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.PollTarget} PollTarget
         */
        PollTarget.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.PollTarget)
                return d;
            var m = new $root.Proto.PollTarget();
            if (d.pollUID != null) {
                m.pollUID = String(d.pollUID);
            }
            if (d.planUID != null) {
                m.planUID = String(d.planUID);
            }
            if (d.groupUID != null) {
                m.groupUID = String(d.groupUID);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.planName != null) {
                m.planName = String(d.planName);
            }
            return m;
        };

        /**
         * Creates a plain object from a PollTarget message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.PollTarget
         * @static
         * @param {Proto.PollTarget} m PollTarget
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PollTarget.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.pollUID = "";
                d.planUID = "";
                d.groupUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.planName = "";
            }
            if (m.pollUID != null && m.hasOwnProperty("pollUID")) {
                d.pollUID = m.pollUID;
            }
            if (m.planUID != null && m.hasOwnProperty("planUID")) {
                d.planUID = m.planUID;
            }
            if (m.groupUID != null && m.hasOwnProperty("groupUID")) {
                d.groupUID = m.groupUID;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.planName != null && m.hasOwnProperty("planName")) {
                d.planName = m.planName;
            }
            return d;
        };

        /**
         * Converts this PollTarget to JSON.
         * @function toJSON
         * @memberof Proto.PollTarget
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PollTarget.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PollTarget;
    })();

    Proto.PollPlayerResponse = (function() {

        /**
         * Properties of a PollPlayerResponse.
         * @memberof Proto
         * @interface IPollPlayerResponse
         * @property {string|null} [companyUID] PollPlayerResponse companyUID
         * @property {string|null} [userUID] PollPlayerResponse userUID
         * @property {Array.<Proto.IPoll>|null} [pollList] PollPlayerResponse pollList
         */

        /**
         * Constructs a new PollPlayerResponse.
         * @memberof Proto
         * @classdesc Represents a PollPlayerResponse.
         * @implements IPollPlayerResponse
         * @constructor
         * @param {Proto.IPollPlayerResponse=} [p] Properties to set
         */
        function PollPlayerResponse(p) {
            this.pollList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PollPlayerResponse companyUID.
         * @member {string} companyUID
         * @memberof Proto.PollPlayerResponse
         * @instance
         */
        PollPlayerResponse.prototype.companyUID = "";

        /**
         * PollPlayerResponse userUID.
         * @member {string} userUID
         * @memberof Proto.PollPlayerResponse
         * @instance
         */
        PollPlayerResponse.prototype.userUID = "";

        /**
         * PollPlayerResponse pollList.
         * @member {Array.<Proto.IPoll>} pollList
         * @memberof Proto.PollPlayerResponse
         * @instance
         */
        PollPlayerResponse.prototype.pollList = $util.emptyArray;

        /**
         * Creates a new PollPlayerResponse instance using the specified properties.
         * @function create
         * @memberof Proto.PollPlayerResponse
         * @static
         * @param {Proto.IPollPlayerResponse=} [properties] Properties to set
         * @returns {Proto.PollPlayerResponse} PollPlayerResponse instance
         */
        PollPlayerResponse.create = function create(properties) {
            return new PollPlayerResponse(properties);
        };

        /**
         * Encodes the specified PollPlayerResponse message. Does not implicitly {@link Proto.PollPlayerResponse.verify|verify} messages.
         * @function encode
         * @memberof Proto.PollPlayerResponse
         * @static
         * @param {Proto.IPollPlayerResponse} m PollPlayerResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PollPlayerResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(10).string(m.companyUID);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(18).string(m.userUID);
            if (m.pollList != null && m.pollList.length) {
                for (var i = 0; i < m.pollList.length; ++i)
                    $root.Proto.Poll.encode(m.pollList[i], w.uint32(26).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a PollPlayerResponse message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.PollPlayerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.PollPlayerResponse} PollPlayerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PollPlayerResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.PollPlayerResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.companyUID = r.string();
                    break;
                case 2:
                    m.userUID = r.string();
                    break;
                case 3:
                    if (!(m.pollList && m.pollList.length))
                        m.pollList = [];
                    m.pollList.push($root.Proto.Poll.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PollPlayerResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.PollPlayerResponse
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.PollPlayerResponse} PollPlayerResponse
         */
        PollPlayerResponse.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.PollPlayerResponse)
                return d;
            var m = new $root.Proto.PollPlayerResponse();
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.pollList) {
                if (!Array.isArray(d.pollList))
                    throw TypeError(".Proto.PollPlayerResponse.pollList: array expected");
                m.pollList = [];
                for (var i = 0; i < d.pollList.length; ++i) {
                    if (typeof d.pollList[i] !== "object")
                        throw TypeError(".Proto.PollPlayerResponse.pollList: object expected");
                    m.pollList[i] = $root.Proto.Poll.fromObject(d.pollList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a PollPlayerResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.PollPlayerResponse
         * @static
         * @param {Proto.PollPlayerResponse} m PollPlayerResponse
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PollPlayerResponse.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.pollList = [];
            }
            if (o.defaults) {
                d.companyUID = "";
                d.userUID = "";
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.pollList && m.pollList.length) {
                d.pollList = [];
                for (var j = 0; j < m.pollList.length; ++j) {
                    d.pollList[j] = $root.Proto.Poll.toObject(m.pollList[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this PollPlayerResponse to JSON.
         * @function toJSON
         * @memberof Proto.PollPlayerResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PollPlayerResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PollPlayerResponse;
    })();

    Proto.ContentCommerceMap = (function() {

        /**
         * Properties of a ContentCommerceMap.
         * @memberof Proto
         * @interface IContentCommerceMap
         * @property {string|null} [contentUID] ContentCommerceMap contentUID
         * @property {string|null} [commerceUID] ContentCommerceMap commerceUID
         * @property {number|Long|null} [registerDate] ContentCommerceMap registerDate
         * @property {number|Long|null} [modifyDate] ContentCommerceMap modifyDate
         * @property {number|null} [status] ContentCommerceMap status
         * @property {string|null} [contentTitle] ContentCommerceMap contentTitle
         * @property {number|null} [order] ContentCommerceMap order
         * @property {Proto.IContentCommerce|null} [commerce] ContentCommerceMap commerce
         * @property {boolean|null} [main] ContentCommerceMap main
         * @property {number|null} [distribution] ContentCommerceMap distribution
         * @property {Proto.IUploadFile|null} [contentFile] ContentCommerceMap contentFile
         * @property {number|null} [contentDistribution] ContentCommerceMap contentDistribution
         * @property {number|Long|null} [contentStartDate] ContentCommerceMap contentStartDate
         * @property {number|Long|null} [contentEndDate] ContentCommerceMap contentEndDate
         * @property {number|null} [contentCommercePolicy] ContentCommerceMap contentCommercePolicy
         * @property {number|null} [contentPublicPolicy] ContentCommerceMap contentPublicPolicy
         */

        /**
         * Constructs a new ContentCommerceMap.
         * @memberof Proto
         * @classdesc Represents a ContentCommerceMap.
         * @implements IContentCommerceMap
         * @constructor
         * @param {Proto.IContentCommerceMap=} [p] Properties to set
         */
        function ContentCommerceMap(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentCommerceMap contentUID.
         * @member {string} contentUID
         * @memberof Proto.ContentCommerceMap
         * @instance
         */
        ContentCommerceMap.prototype.contentUID = "";

        /**
         * ContentCommerceMap commerceUID.
         * @member {string} commerceUID
         * @memberof Proto.ContentCommerceMap
         * @instance
         */
        ContentCommerceMap.prototype.commerceUID = "";

        /**
         * ContentCommerceMap registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentCommerceMap
         * @instance
         */
        ContentCommerceMap.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerceMap modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentCommerceMap
         * @instance
         */
        ContentCommerceMap.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerceMap status.
         * @member {number} status
         * @memberof Proto.ContentCommerceMap
         * @instance
         */
        ContentCommerceMap.prototype.status = 0;

        /**
         * ContentCommerceMap contentTitle.
         * @member {string} contentTitle
         * @memberof Proto.ContentCommerceMap
         * @instance
         */
        ContentCommerceMap.prototype.contentTitle = "";

        /**
         * ContentCommerceMap order.
         * @member {number} order
         * @memberof Proto.ContentCommerceMap
         * @instance
         */
        ContentCommerceMap.prototype.order = 0;

        /**
         * ContentCommerceMap commerce.
         * @member {Proto.IContentCommerce|null|undefined} commerce
         * @memberof Proto.ContentCommerceMap
         * @instance
         */
        ContentCommerceMap.prototype.commerce = null;

        /**
         * ContentCommerceMap main.
         * @member {boolean} main
         * @memberof Proto.ContentCommerceMap
         * @instance
         */
        ContentCommerceMap.prototype.main = false;

        /**
         * ContentCommerceMap distribution.
         * @member {number} distribution
         * @memberof Proto.ContentCommerceMap
         * @instance
         */
        ContentCommerceMap.prototype.distribution = 0;

        /**
         * ContentCommerceMap contentFile.
         * @member {Proto.IUploadFile|null|undefined} contentFile
         * @memberof Proto.ContentCommerceMap
         * @instance
         */
        ContentCommerceMap.prototype.contentFile = null;

        /**
         * ContentCommerceMap contentDistribution.
         * @member {number} contentDistribution
         * @memberof Proto.ContentCommerceMap
         * @instance
         */
        ContentCommerceMap.prototype.contentDistribution = 0;

        /**
         * ContentCommerceMap contentStartDate.
         * @member {number|Long} contentStartDate
         * @memberof Proto.ContentCommerceMap
         * @instance
         */
        ContentCommerceMap.prototype.contentStartDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerceMap contentEndDate.
         * @member {number|Long} contentEndDate
         * @memberof Proto.ContentCommerceMap
         * @instance
         */
        ContentCommerceMap.prototype.contentEndDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommerceMap contentCommercePolicy.
         * @member {number} contentCommercePolicy
         * @memberof Proto.ContentCommerceMap
         * @instance
         */
        ContentCommerceMap.prototype.contentCommercePolicy = 0;

        /**
         * ContentCommerceMap contentPublicPolicy.
         * @member {number} contentPublicPolicy
         * @memberof Proto.ContentCommerceMap
         * @instance
         */
        ContentCommerceMap.prototype.contentPublicPolicy = 0;

        /**
         * Creates a new ContentCommerceMap instance using the specified properties.
         * @function create
         * @memberof Proto.ContentCommerceMap
         * @static
         * @param {Proto.IContentCommerceMap=} [properties] Properties to set
         * @returns {Proto.ContentCommerceMap} ContentCommerceMap instance
         */
        ContentCommerceMap.create = function create(properties) {
            return new ContentCommerceMap(properties);
        };

        /**
         * Encodes the specified ContentCommerceMap message. Does not implicitly {@link Proto.ContentCommerceMap.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentCommerceMap
         * @static
         * @param {Proto.IContentCommerceMap} m ContentCommerceMap message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentCommerceMap.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(10).string(m.contentUID);
            if (m.commerceUID != null && Object.hasOwnProperty.call(m, "commerceUID"))
                w.uint32(18).string(m.commerceUID);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(32).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(40).int32(m.status);
            if (m.contentTitle != null && Object.hasOwnProperty.call(m, "contentTitle"))
                w.uint32(50).string(m.contentTitle);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(56).int32(m.order);
            if (m.commerce != null && Object.hasOwnProperty.call(m, "commerce"))
                $root.Proto.ContentCommerce.encode(m.commerce, w.uint32(66).fork()).ldelim();
            if (m.main != null && Object.hasOwnProperty.call(m, "main"))
                w.uint32(72).bool(m.main);
            if (m.distribution != null && Object.hasOwnProperty.call(m, "distribution"))
                w.uint32(80).int32(m.distribution);
            if (m.contentFile != null && Object.hasOwnProperty.call(m, "contentFile"))
                $root.Proto.UploadFile.encode(m.contentFile, w.uint32(90).fork()).ldelim();
            if (m.contentDistribution != null && Object.hasOwnProperty.call(m, "contentDistribution"))
                w.uint32(96).int32(m.contentDistribution);
            if (m.contentStartDate != null && Object.hasOwnProperty.call(m, "contentStartDate"))
                w.uint32(104).int64(m.contentStartDate);
            if (m.contentEndDate != null && Object.hasOwnProperty.call(m, "contentEndDate"))
                w.uint32(120).int64(m.contentEndDate);
            if (m.contentCommercePolicy != null && Object.hasOwnProperty.call(m, "contentCommercePolicy"))
                w.uint32(128).int32(m.contentCommercePolicy);
            if (m.contentPublicPolicy != null && Object.hasOwnProperty.call(m, "contentPublicPolicy"))
                w.uint32(136).int32(m.contentPublicPolicy);
            return w;
        };

        /**
         * Decodes a ContentCommerceMap message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentCommerceMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentCommerceMap} ContentCommerceMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentCommerceMap.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentCommerceMap();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.contentUID = r.string();
                    break;
                case 2:
                    m.commerceUID = r.string();
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    m.modifyDate = r.int64();
                    break;
                case 5:
                    m.status = r.int32();
                    break;
                case 6:
                    m.contentTitle = r.string();
                    break;
                case 7:
                    m.order = r.int32();
                    break;
                case 8:
                    m.commerce = $root.Proto.ContentCommerce.decode(r, r.uint32());
                    break;
                case 9:
                    m.main = r.bool();
                    break;
                case 10:
                    m.distribution = r.int32();
                    break;
                case 11:
                    m.contentFile = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 12:
                    m.contentDistribution = r.int32();
                    break;
                case 13:
                    m.contentStartDate = r.int64();
                    break;
                case 15:
                    m.contentEndDate = r.int64();
                    break;
                case 16:
                    m.contentCommercePolicy = r.int32();
                    break;
                case 17:
                    m.contentPublicPolicy = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentCommerceMap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentCommerceMap
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentCommerceMap} ContentCommerceMap
         */
        ContentCommerceMap.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentCommerceMap)
                return d;
            var m = new $root.Proto.ContentCommerceMap();
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.commerceUID != null) {
                m.commerceUID = String(d.commerceUID);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.contentTitle != null) {
                m.contentTitle = String(d.contentTitle);
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.commerce != null) {
                if (typeof d.commerce !== "object")
                    throw TypeError(".Proto.ContentCommerceMap.commerce: object expected");
                m.commerce = $root.Proto.ContentCommerce.fromObject(d.commerce);
            }
            if (d.main != null) {
                m.main = Boolean(d.main);
            }
            if (d.distribution != null) {
                m.distribution = d.distribution | 0;
            }
            if (d.contentFile != null) {
                if (typeof d.contentFile !== "object")
                    throw TypeError(".Proto.ContentCommerceMap.contentFile: object expected");
                m.contentFile = $root.Proto.UploadFile.fromObject(d.contentFile);
            }
            if (d.contentDistribution != null) {
                m.contentDistribution = d.contentDistribution | 0;
            }
            if (d.contentStartDate != null) {
                if ($util.Long)
                    (m.contentStartDate = $util.Long.fromValue(d.contentStartDate)).unsigned = false;
                else if (typeof d.contentStartDate === "string")
                    m.contentStartDate = parseInt(d.contentStartDate, 10);
                else if (typeof d.contentStartDate === "number")
                    m.contentStartDate = d.contentStartDate;
                else if (typeof d.contentStartDate === "object")
                    m.contentStartDate = new $util.LongBits(d.contentStartDate.low >>> 0, d.contentStartDate.high >>> 0).toNumber();
            }
            if (d.contentEndDate != null) {
                if ($util.Long)
                    (m.contentEndDate = $util.Long.fromValue(d.contentEndDate)).unsigned = false;
                else if (typeof d.contentEndDate === "string")
                    m.contentEndDate = parseInt(d.contentEndDate, 10);
                else if (typeof d.contentEndDate === "number")
                    m.contentEndDate = d.contentEndDate;
                else if (typeof d.contentEndDate === "object")
                    m.contentEndDate = new $util.LongBits(d.contentEndDate.low >>> 0, d.contentEndDate.high >>> 0).toNumber();
            }
            if (d.contentCommercePolicy != null) {
                m.contentCommercePolicy = d.contentCommercePolicy | 0;
            }
            if (d.contentPublicPolicy != null) {
                m.contentPublicPolicy = d.contentPublicPolicy | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentCommerceMap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentCommerceMap
         * @static
         * @param {Proto.ContentCommerceMap} m ContentCommerceMap
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentCommerceMap.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.contentUID = "";
                d.commerceUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.contentTitle = "";
                d.order = 0;
                d.commerce = null;
                d.main = false;
                d.distribution = 0;
                d.contentFile = null;
                d.contentDistribution = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.contentStartDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.contentStartDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.contentEndDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.contentEndDate = o.longs === String ? "0" : 0;
                d.contentCommercePolicy = 0;
                d.contentPublicPolicy = 0;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.commerceUID != null && m.hasOwnProperty("commerceUID")) {
                d.commerceUID = m.commerceUID;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.contentTitle != null && m.hasOwnProperty("contentTitle")) {
                d.contentTitle = m.contentTitle;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.commerce != null && m.hasOwnProperty("commerce")) {
                d.commerce = $root.Proto.ContentCommerce.toObject(m.commerce, o);
            }
            if (m.main != null && m.hasOwnProperty("main")) {
                d.main = m.main;
            }
            if (m.distribution != null && m.hasOwnProperty("distribution")) {
                d.distribution = m.distribution;
            }
            if (m.contentFile != null && m.hasOwnProperty("contentFile")) {
                d.contentFile = $root.Proto.UploadFile.toObject(m.contentFile, o);
            }
            if (m.contentDistribution != null && m.hasOwnProperty("contentDistribution")) {
                d.contentDistribution = m.contentDistribution;
            }
            if (m.contentStartDate != null && m.hasOwnProperty("contentStartDate")) {
                if (typeof m.contentStartDate === "number")
                    d.contentStartDate = o.longs === String ? String(m.contentStartDate) : m.contentStartDate;
                else
                    d.contentStartDate = o.longs === String ? $util.Long.prototype.toString.call(m.contentStartDate) : o.longs === Number ? new $util.LongBits(m.contentStartDate.low >>> 0, m.contentStartDate.high >>> 0).toNumber() : m.contentStartDate;
            }
            if (m.contentEndDate != null && m.hasOwnProperty("contentEndDate")) {
                if (typeof m.contentEndDate === "number")
                    d.contentEndDate = o.longs === String ? String(m.contentEndDate) : m.contentEndDate;
                else
                    d.contentEndDate = o.longs === String ? $util.Long.prototype.toString.call(m.contentEndDate) : o.longs === Number ? new $util.LongBits(m.contentEndDate.low >>> 0, m.contentEndDate.high >>> 0).toNumber() : m.contentEndDate;
            }
            if (m.contentCommercePolicy != null && m.hasOwnProperty("contentCommercePolicy")) {
                d.contentCommercePolicy = m.contentCommercePolicy;
            }
            if (m.contentPublicPolicy != null && m.hasOwnProperty("contentPublicPolicy")) {
                d.contentPublicPolicy = m.contentPublicPolicy;
            }
            return d;
        };

        /**
         * Converts this ContentCommerceMap to JSON.
         * @function toJSON
         * @memberof Proto.ContentCommerceMap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentCommerceMap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentCommerceMap;
    })();

    Proto.ContentCommerceMapList = (function() {

        /**
         * Properties of a ContentCommerceMapList.
         * @memberof Proto
         * @interface IContentCommerceMapList
         * @property {Array.<Proto.IContentCommerceMap>|null} [value] ContentCommerceMapList value
         */

        /**
         * Constructs a new ContentCommerceMapList.
         * @memberof Proto
         * @classdesc Represents a ContentCommerceMapList.
         * @implements IContentCommerceMapList
         * @constructor
         * @param {Proto.IContentCommerceMapList=} [p] Properties to set
         */
        function ContentCommerceMapList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentCommerceMapList value.
         * @member {Array.<Proto.IContentCommerceMap>} value
         * @memberof Proto.ContentCommerceMapList
         * @instance
         */
        ContentCommerceMapList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentCommerceMapList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentCommerceMapList
         * @static
         * @param {Proto.IContentCommerceMapList=} [properties] Properties to set
         * @returns {Proto.ContentCommerceMapList} ContentCommerceMapList instance
         */
        ContentCommerceMapList.create = function create(properties) {
            return new ContentCommerceMapList(properties);
        };

        /**
         * Encodes the specified ContentCommerceMapList message. Does not implicitly {@link Proto.ContentCommerceMapList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentCommerceMapList
         * @static
         * @param {Proto.IContentCommerceMapList} m ContentCommerceMapList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentCommerceMapList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentCommerceMap.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentCommerceMapList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentCommerceMapList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentCommerceMapList} ContentCommerceMapList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentCommerceMapList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentCommerceMapList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentCommerceMap.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentCommerceMapList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentCommerceMapList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentCommerceMapList} ContentCommerceMapList
         */
        ContentCommerceMapList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentCommerceMapList)
                return d;
            var m = new $root.Proto.ContentCommerceMapList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentCommerceMapList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentCommerceMapList.value: object expected");
                    m.value[i] = $root.Proto.ContentCommerceMap.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentCommerceMapList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentCommerceMapList
         * @static
         * @param {Proto.ContentCommerceMapList} m ContentCommerceMapList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentCommerceMapList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentCommerceMap.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentCommerceMapList to JSON.
         * @function toJSON
         * @memberof Proto.ContentCommerceMapList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentCommerceMapList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentCommerceMapList;
    })();

    Proto.CommunityBoardGroup = (function() {

        /**
         * Properties of a CommunityBoardGroup.
         * @memberof Proto
         * @interface ICommunityBoardGroup
         * @property {string|null} [uid] CommunityBoardGroup uid
         * @property {string|null} [boardUID] CommunityBoardGroup boardUID
         * @property {string|null} [userUID] CommunityBoardGroup userUID
         * @property {string|null} [name] CommunityBoardGroup name
         * @property {number|null} [policy] CommunityBoardGroup policy
         * @property {string|null} [password] CommunityBoardGroup password
         * @property {string|null} [userCount] CommunityBoardGroup userCount
         * @property {boolean|null} [isOwner] CommunityBoardGroup isOwner
         * @property {boolean|null} [isCompanyOwner] CommunityBoardGroup isCompanyOwner
         * @property {boolean|null} [joined] CommunityBoardGroup joined
         * @property {number|Long|null} [registerDate] CommunityBoardGroup registerDate
         * @property {number|Long|null} [modifyDate] CommunityBoardGroup modifyDate
         * @property {boolean|null} [secured] CommunityBoardGroup secured
         * @property {number|null} [type] CommunityBoardGroup type
         * @property {Array.<string>|null} [insertUserList] CommunityBoardGroup insertUserList
         * @property {boolean|null} [autoName] CommunityBoardGroup autoName
         * @property {number|Long|null} [messageDate] CommunityBoardGroup messageDate
         * @property {Proto.IBoardChat|null} [msg] CommunityBoardGroup msg
         * @property {Array.<Proto.IUser>|null} [profileUserList] CommunityBoardGroup profileUserList
         * @property {boolean|null} [fcm] CommunityBoardGroup fcm
         * @property {boolean|null} [fixed] CommunityBoardGroup fixed
         * @property {Proto.IUploadFile|null} [icon] CommunityBoardGroup icon
         * @property {Array.<string>|null} [insertGroupList] CommunityBoardGroup insertGroupList
         */

        /**
         * Constructs a new CommunityBoardGroup.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardGroup.
         * @implements ICommunityBoardGroup
         * @constructor
         * @param {Proto.ICommunityBoardGroup=} [p] Properties to set
         */
        function CommunityBoardGroup(p) {
            this.insertUserList = [];
            this.profileUserList = [];
            this.insertGroupList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardGroup uid.
         * @member {string} uid
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.uid = "";

        /**
         * CommunityBoardGroup boardUID.
         * @member {string} boardUID
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.boardUID = "";

        /**
         * CommunityBoardGroup userUID.
         * @member {string} userUID
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.userUID = "";

        /**
         * CommunityBoardGroup name.
         * @member {string} name
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.name = "";

        /**
         * CommunityBoardGroup policy.
         * @member {number} policy
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.policy = 0;

        /**
         * CommunityBoardGroup password.
         * @member {string} password
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.password = "";

        /**
         * CommunityBoardGroup userCount.
         * @member {string} userCount
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.userCount = "";

        /**
         * CommunityBoardGroup isOwner.
         * @member {boolean} isOwner
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.isOwner = false;

        /**
         * CommunityBoardGroup isCompanyOwner.
         * @member {boolean} isCompanyOwner
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.isCompanyOwner = false;

        /**
         * CommunityBoardGroup joined.
         * @member {boolean} joined
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.joined = false;

        /**
         * CommunityBoardGroup registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardGroup modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardGroup secured.
         * @member {boolean} secured
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.secured = false;

        /**
         * CommunityBoardGroup type.
         * @member {number} type
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.type = 0;

        /**
         * CommunityBoardGroup insertUserList.
         * @member {Array.<string>} insertUserList
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.insertUserList = $util.emptyArray;

        /**
         * CommunityBoardGroup autoName.
         * @member {boolean} autoName
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.autoName = false;

        /**
         * CommunityBoardGroup messageDate.
         * @member {number|Long} messageDate
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.messageDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardGroup msg.
         * @member {Proto.IBoardChat|null|undefined} msg
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.msg = null;

        /**
         * CommunityBoardGroup profileUserList.
         * @member {Array.<Proto.IUser>} profileUserList
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.profileUserList = $util.emptyArray;

        /**
         * CommunityBoardGroup fcm.
         * @member {boolean} fcm
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.fcm = false;

        /**
         * CommunityBoardGroup fixed.
         * @member {boolean} fixed
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.fixed = false;

        /**
         * CommunityBoardGroup icon.
         * @member {Proto.IUploadFile|null|undefined} icon
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.icon = null;

        /**
         * CommunityBoardGroup insertGroupList.
         * @member {Array.<string>} insertGroupList
         * @memberof Proto.CommunityBoardGroup
         * @instance
         */
        CommunityBoardGroup.prototype.insertGroupList = $util.emptyArray;

        /**
         * Creates a new CommunityBoardGroup instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardGroup
         * @static
         * @param {Proto.ICommunityBoardGroup=} [properties] Properties to set
         * @returns {Proto.CommunityBoardGroup} CommunityBoardGroup instance
         */
        CommunityBoardGroup.create = function create(properties) {
            return new CommunityBoardGroup(properties);
        };

        /**
         * Encodes the specified CommunityBoardGroup message. Does not implicitly {@link Proto.CommunityBoardGroup.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardGroup
         * @static
         * @param {Proto.ICommunityBoardGroup} m CommunityBoardGroup message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardGroup.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.boardUID != null && Object.hasOwnProperty.call(m, "boardUID"))
                w.uint32(18).string(m.boardUID);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(26).string(m.userUID);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(34).string(m.name);
            if (m.policy != null && Object.hasOwnProperty.call(m, "policy"))
                w.uint32(40).int32(m.policy);
            if (m.password != null && Object.hasOwnProperty.call(m, "password"))
                w.uint32(50).string(m.password);
            if (m.userCount != null && Object.hasOwnProperty.call(m, "userCount"))
                w.uint32(58).string(m.userCount);
            if (m.isOwner != null && Object.hasOwnProperty.call(m, "isOwner"))
                w.uint32(64).bool(m.isOwner);
            if (m.isCompanyOwner != null && Object.hasOwnProperty.call(m, "isCompanyOwner"))
                w.uint32(72).bool(m.isCompanyOwner);
            if (m.joined != null && Object.hasOwnProperty.call(m, "joined"))
                w.uint32(80).bool(m.joined);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(88).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(96).int64(m.modifyDate);
            if (m.secured != null && Object.hasOwnProperty.call(m, "secured"))
                w.uint32(104).bool(m.secured);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(112).int32(m.type);
            if (m.insertUserList != null && m.insertUserList.length) {
                for (var i = 0; i < m.insertUserList.length; ++i)
                    w.uint32(122).string(m.insertUserList[i]);
            }
            if (m.autoName != null && Object.hasOwnProperty.call(m, "autoName"))
                w.uint32(128).bool(m.autoName);
            if (m.messageDate != null && Object.hasOwnProperty.call(m, "messageDate"))
                w.uint32(136).int64(m.messageDate);
            if (m.msg != null && Object.hasOwnProperty.call(m, "msg"))
                $root.Proto.BoardChat.encode(m.msg, w.uint32(146).fork()).ldelim();
            if (m.profileUserList != null && m.profileUserList.length) {
                for (var i = 0; i < m.profileUserList.length; ++i)
                    $root.Proto.User.encode(m.profileUserList[i], w.uint32(154).fork()).ldelim();
            }
            if (m.fcm != null && Object.hasOwnProperty.call(m, "fcm"))
                w.uint32(160).bool(m.fcm);
            if (m.fixed != null && Object.hasOwnProperty.call(m, "fixed"))
                w.uint32(168).bool(m.fixed);
            if (m.icon != null && Object.hasOwnProperty.call(m, "icon"))
                $root.Proto.UploadFile.encode(m.icon, w.uint32(178).fork()).ldelim();
            if (m.insertGroupList != null && m.insertGroupList.length) {
                for (var i = 0; i < m.insertGroupList.length; ++i)
                    w.uint32(186).string(m.insertGroupList[i]);
            }
            return w;
        };

        /**
         * Decodes a CommunityBoardGroup message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardGroup} CommunityBoardGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardGroup.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardGroup();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.boardUID = r.string();
                    break;
                case 3:
                    m.userUID = r.string();
                    break;
                case 4:
                    m.name = r.string();
                    break;
                case 5:
                    m.policy = r.int32();
                    break;
                case 6:
                    m.password = r.string();
                    break;
                case 7:
                    m.userCount = r.string();
                    break;
                case 8:
                    m.isOwner = r.bool();
                    break;
                case 9:
                    m.isCompanyOwner = r.bool();
                    break;
                case 10:
                    m.joined = r.bool();
                    break;
                case 11:
                    m.registerDate = r.int64();
                    break;
                case 12:
                    m.modifyDate = r.int64();
                    break;
                case 13:
                    m.secured = r.bool();
                    break;
                case 14:
                    m.type = r.int32();
                    break;
                case 15:
                    if (!(m.insertUserList && m.insertUserList.length))
                        m.insertUserList = [];
                    m.insertUserList.push(r.string());
                    break;
                case 16:
                    m.autoName = r.bool();
                    break;
                case 17:
                    m.messageDate = r.int64();
                    break;
                case 18:
                    m.msg = $root.Proto.BoardChat.decode(r, r.uint32());
                    break;
                case 19:
                    if (!(m.profileUserList && m.profileUserList.length))
                        m.profileUserList = [];
                    m.profileUserList.push($root.Proto.User.decode(r, r.uint32()));
                    break;
                case 20:
                    m.fcm = r.bool();
                    break;
                case 21:
                    m.fixed = r.bool();
                    break;
                case 22:
                    m.icon = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 23:
                    if (!(m.insertGroupList && m.insertGroupList.length))
                        m.insertGroupList = [];
                    m.insertGroupList.push(r.string());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardGroup message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardGroup
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardGroup} CommunityBoardGroup
         */
        CommunityBoardGroup.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardGroup)
                return d;
            var m = new $root.Proto.CommunityBoardGroup();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.boardUID != null) {
                m.boardUID = String(d.boardUID);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.policy != null) {
                m.policy = d.policy | 0;
            }
            if (d.password != null) {
                m.password = String(d.password);
            }
            if (d.userCount != null) {
                m.userCount = String(d.userCount);
            }
            if (d.isOwner != null) {
                m.isOwner = Boolean(d.isOwner);
            }
            if (d.isCompanyOwner != null) {
                m.isCompanyOwner = Boolean(d.isCompanyOwner);
            }
            if (d.joined != null) {
                m.joined = Boolean(d.joined);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.secured != null) {
                m.secured = Boolean(d.secured);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.insertUserList) {
                if (!Array.isArray(d.insertUserList))
                    throw TypeError(".Proto.CommunityBoardGroup.insertUserList: array expected");
                m.insertUserList = [];
                for (var i = 0; i < d.insertUserList.length; ++i) {
                    m.insertUserList[i] = String(d.insertUserList[i]);
                }
            }
            if (d.autoName != null) {
                m.autoName = Boolean(d.autoName);
            }
            if (d.messageDate != null) {
                if ($util.Long)
                    (m.messageDate = $util.Long.fromValue(d.messageDate)).unsigned = false;
                else if (typeof d.messageDate === "string")
                    m.messageDate = parseInt(d.messageDate, 10);
                else if (typeof d.messageDate === "number")
                    m.messageDate = d.messageDate;
                else if (typeof d.messageDate === "object")
                    m.messageDate = new $util.LongBits(d.messageDate.low >>> 0, d.messageDate.high >>> 0).toNumber();
            }
            if (d.msg != null) {
                if (typeof d.msg !== "object")
                    throw TypeError(".Proto.CommunityBoardGroup.msg: object expected");
                m.msg = $root.Proto.BoardChat.fromObject(d.msg);
            }
            if (d.profileUserList) {
                if (!Array.isArray(d.profileUserList))
                    throw TypeError(".Proto.CommunityBoardGroup.profileUserList: array expected");
                m.profileUserList = [];
                for (var i = 0; i < d.profileUserList.length; ++i) {
                    if (typeof d.profileUserList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardGroup.profileUserList: object expected");
                    m.profileUserList[i] = $root.Proto.User.fromObject(d.profileUserList[i]);
                }
            }
            if (d.fcm != null) {
                m.fcm = Boolean(d.fcm);
            }
            if (d.fixed != null) {
                m.fixed = Boolean(d.fixed);
            }
            if (d.icon != null) {
                if (typeof d.icon !== "object")
                    throw TypeError(".Proto.CommunityBoardGroup.icon: object expected");
                m.icon = $root.Proto.UploadFile.fromObject(d.icon);
            }
            if (d.insertGroupList) {
                if (!Array.isArray(d.insertGroupList))
                    throw TypeError(".Proto.CommunityBoardGroup.insertGroupList: array expected");
                m.insertGroupList = [];
                for (var i = 0; i < d.insertGroupList.length; ++i) {
                    m.insertGroupList[i] = String(d.insertGroupList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardGroup message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardGroup
         * @static
         * @param {Proto.CommunityBoardGroup} m CommunityBoardGroup
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardGroup.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.insertUserList = [];
                d.profileUserList = [];
                d.insertGroupList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.boardUID = "";
                d.userUID = "";
                d.name = "";
                d.policy = 0;
                d.password = "";
                d.userCount = "";
                d.isOwner = false;
                d.isCompanyOwner = false;
                d.joined = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.secured = false;
                d.type = 0;
                d.autoName = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.messageDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.messageDate = o.longs === String ? "0" : 0;
                d.msg = null;
                d.fcm = false;
                d.fixed = false;
                d.icon = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.boardUID != null && m.hasOwnProperty("boardUID")) {
                d.boardUID = m.boardUID;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.policy != null && m.hasOwnProperty("policy")) {
                d.policy = m.policy;
            }
            if (m.password != null && m.hasOwnProperty("password")) {
                d.password = m.password;
            }
            if (m.userCount != null && m.hasOwnProperty("userCount")) {
                d.userCount = m.userCount;
            }
            if (m.isOwner != null && m.hasOwnProperty("isOwner")) {
                d.isOwner = m.isOwner;
            }
            if (m.isCompanyOwner != null && m.hasOwnProperty("isCompanyOwner")) {
                d.isCompanyOwner = m.isCompanyOwner;
            }
            if (m.joined != null && m.hasOwnProperty("joined")) {
                d.joined = m.joined;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.secured != null && m.hasOwnProperty("secured")) {
                d.secured = m.secured;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.insertUserList && m.insertUserList.length) {
                d.insertUserList = [];
                for (var j = 0; j < m.insertUserList.length; ++j) {
                    d.insertUserList[j] = m.insertUserList[j];
                }
            }
            if (m.autoName != null && m.hasOwnProperty("autoName")) {
                d.autoName = m.autoName;
            }
            if (m.messageDate != null && m.hasOwnProperty("messageDate")) {
                if (typeof m.messageDate === "number")
                    d.messageDate = o.longs === String ? String(m.messageDate) : m.messageDate;
                else
                    d.messageDate = o.longs === String ? $util.Long.prototype.toString.call(m.messageDate) : o.longs === Number ? new $util.LongBits(m.messageDate.low >>> 0, m.messageDate.high >>> 0).toNumber() : m.messageDate;
            }
            if (m.msg != null && m.hasOwnProperty("msg")) {
                d.msg = $root.Proto.BoardChat.toObject(m.msg, o);
            }
            if (m.profileUserList && m.profileUserList.length) {
                d.profileUserList = [];
                for (var j = 0; j < m.profileUserList.length; ++j) {
                    d.profileUserList[j] = $root.Proto.User.toObject(m.profileUserList[j], o);
                }
            }
            if (m.fcm != null && m.hasOwnProperty("fcm")) {
                d.fcm = m.fcm;
            }
            if (m.fixed != null && m.hasOwnProperty("fixed")) {
                d.fixed = m.fixed;
            }
            if (m.icon != null && m.hasOwnProperty("icon")) {
                d.icon = $root.Proto.UploadFile.toObject(m.icon, o);
            }
            if (m.insertGroupList && m.insertGroupList.length) {
                d.insertGroupList = [];
                for (var j = 0; j < m.insertGroupList.length; ++j) {
                    d.insertGroupList[j] = m.insertGroupList[j];
                }
            }
            return d;
        };

        /**
         * Converts this CommunityBoardGroup to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardGroup
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardGroup.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardGroup;
    })();

    Proto.CommunityBoardGroupList = (function() {

        /**
         * Properties of a CommunityBoardGroupList.
         * @memberof Proto
         * @interface ICommunityBoardGroupList
         * @property {Array.<Proto.ICommunityBoardGroup>|null} [value] CommunityBoardGroupList value
         */

        /**
         * Constructs a new CommunityBoardGroupList.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardGroupList.
         * @implements ICommunityBoardGroupList
         * @constructor
         * @param {Proto.ICommunityBoardGroupList=} [p] Properties to set
         */
        function CommunityBoardGroupList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardGroupList value.
         * @member {Array.<Proto.ICommunityBoardGroup>} value
         * @memberof Proto.CommunityBoardGroupList
         * @instance
         */
        CommunityBoardGroupList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CommunityBoardGroupList instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardGroupList
         * @static
         * @param {Proto.ICommunityBoardGroupList=} [properties] Properties to set
         * @returns {Proto.CommunityBoardGroupList} CommunityBoardGroupList instance
         */
        CommunityBoardGroupList.create = function create(properties) {
            return new CommunityBoardGroupList(properties);
        };

        /**
         * Encodes the specified CommunityBoardGroupList message. Does not implicitly {@link Proto.CommunityBoardGroupList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardGroupList
         * @static
         * @param {Proto.ICommunityBoardGroupList} m CommunityBoardGroupList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardGroupList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CommunityBoardGroup.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CommunityBoardGroupList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardGroupList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardGroupList} CommunityBoardGroupList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardGroupList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardGroupList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CommunityBoardGroup.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardGroupList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardGroupList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardGroupList} CommunityBoardGroupList
         */
        CommunityBoardGroupList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardGroupList)
                return d;
            var m = new $root.Proto.CommunityBoardGroupList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CommunityBoardGroupList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardGroupList.value: object expected");
                    m.value[i] = $root.Proto.CommunityBoardGroup.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardGroupList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardGroupList
         * @static
         * @param {Proto.CommunityBoardGroupList} m CommunityBoardGroupList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardGroupList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CommunityBoardGroup.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CommunityBoardGroupList to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardGroupList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardGroupList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardGroupList;
    })();

    Proto.BoardChat = (function() {

        /**
         * Properties of a BoardChat.
         * @memberof Proto
         * @interface IBoardChat
         * @property {string|null} [id] BoardChat id
         * @property {string|null} [groupUID] BoardChat groupUID
         * @property {number|Long|null} [ts] BoardChat ts
         * @property {string|null} [body] BoardChat body
         * @property {Proto.IUser|null} [user] BoardChat user
         * @property {boolean|null} [owner] BoardChat owner
         * @property {boolean|null} [sticky] BoardChat sticky
         * @property {boolean|null} [sender] BoardChat sender
         * @property {number|null} [status] BoardChat status
         * @property {number|Long|null} [readDate] BoardChat readDate
         * @property {string|null} [readCount] BoardChat readCount
         * @property {Array.<Proto.IUploadFile>|null} [fileList] BoardChat fileList
         * @property {number|null} [type] BoardChat type
         * @property {Array.<Proto.IUser>|null} [readUserList] BoardChat readUserList
         * @property {string|null} [deviceName] BoardChat deviceName
         * @property {number|null} [fileType] BoardChat fileType
         * @property {Object.<string,number|Long>|null} [emoticonCount] BoardChat emoticonCount
         * @property {string|null} [emoticonAllCount] BoardChat emoticonAllCount
         * @property {number|Long|null} [modifyDate] BoardChat modifyDate
         * @property {string|null} [replyTo] BoardChat replyTo
         * @property {Proto.IBoardChat|null} [reply] BoardChat reply
         * @property {Array.<string>|null} [targetUserUIDList] BoardChat targetUserUIDList
         * @property {boolean|null} [target] BoardChat target
         * @property {Array.<Proto.IUser>|null} [targetUser] BoardChat targetUser
         * @property {Proto.IStamp|null} [stamp] BoardChat stamp
         * @property {string|null} [srcId] BoardChat srcId
         * @property {number|Long|null} [srcDate] BoardChat srcDate
         * @property {Proto.IUser|null} [srcUser] BoardChat srcUser
         * @property {string|null} [linkId] BoardChat linkId
         * @property {Array.<string>|null} [timetableUID] BoardChat timetableUID
         * @property {number|Long|null} [timetableStartDate] BoardChat timetableStartDate
         * @property {number|Long|null} [timetableEndDate] BoardChat timetableEndDate
         * @property {string|null} [timetableOtherStaffName] BoardChat timetableOtherStaffName
         * @property {Array.<string>|null} [timetableRequestUID] BoardChat timetableRequestUID
         * @property {string|null} [workStaffUID] BoardChat workStaffUID
         * @property {number|Long|null} [changeStartDate] BoardChat changeStartDate
         * @property {number|Long|null} [changeEndDate] BoardChat changeEndDate
         */

        /**
         * Constructs a new BoardChat.
         * @memberof Proto
         * @classdesc Represents a BoardChat.
         * @implements IBoardChat
         * @constructor
         * @param {Proto.IBoardChat=} [p] Properties to set
         */
        function BoardChat(p) {
            this.fileList = [];
            this.readUserList = [];
            this.emoticonCount = {};
            this.targetUserUIDList = [];
            this.targetUser = [];
            this.timetableUID = [];
            this.timetableRequestUID = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * BoardChat id.
         * @member {string} id
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.id = "";

        /**
         * BoardChat groupUID.
         * @member {string} groupUID
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.groupUID = "";

        /**
         * BoardChat ts.
         * @member {number|Long} ts
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.ts = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BoardChat body.
         * @member {string} body
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.body = "";

        /**
         * BoardChat user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.user = null;

        /**
         * BoardChat owner.
         * @member {boolean} owner
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.owner = false;

        /**
         * BoardChat sticky.
         * @member {boolean} sticky
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.sticky = false;

        /**
         * BoardChat sender.
         * @member {boolean} sender
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.sender = false;

        /**
         * BoardChat status.
         * @member {number} status
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.status = 0;

        /**
         * BoardChat readDate.
         * @member {number|Long} readDate
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.readDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BoardChat readCount.
         * @member {string} readCount
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.readCount = "";

        /**
         * BoardChat fileList.
         * @member {Array.<Proto.IUploadFile>} fileList
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.fileList = $util.emptyArray;

        /**
         * BoardChat type.
         * @member {number} type
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.type = 0;

        /**
         * BoardChat readUserList.
         * @member {Array.<Proto.IUser>} readUserList
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.readUserList = $util.emptyArray;

        /**
         * BoardChat deviceName.
         * @member {string} deviceName
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.deviceName = "";

        /**
         * BoardChat fileType.
         * @member {number} fileType
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.fileType = 0;

        /**
         * BoardChat emoticonCount.
         * @member {Object.<string,number|Long>} emoticonCount
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.emoticonCount = $util.emptyObject;

        /**
         * BoardChat emoticonAllCount.
         * @member {string} emoticonAllCount
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.emoticonAllCount = "";

        /**
         * BoardChat modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BoardChat replyTo.
         * @member {string} replyTo
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.replyTo = "";

        /**
         * BoardChat reply.
         * @member {Proto.IBoardChat|null|undefined} reply
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.reply = null;

        /**
         * BoardChat targetUserUIDList.
         * @member {Array.<string>} targetUserUIDList
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.targetUserUIDList = $util.emptyArray;

        /**
         * BoardChat target.
         * @member {boolean} target
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.target = false;

        /**
         * BoardChat targetUser.
         * @member {Array.<Proto.IUser>} targetUser
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.targetUser = $util.emptyArray;

        /**
         * BoardChat stamp.
         * @member {Proto.IStamp|null|undefined} stamp
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.stamp = null;

        /**
         * BoardChat srcId.
         * @member {string} srcId
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.srcId = "";

        /**
         * BoardChat srcDate.
         * @member {number|Long} srcDate
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.srcDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BoardChat srcUser.
         * @member {Proto.IUser|null|undefined} srcUser
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.srcUser = null;

        /**
         * BoardChat linkId.
         * @member {string} linkId
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.linkId = "";

        /**
         * BoardChat timetableUID.
         * @member {Array.<string>} timetableUID
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.timetableUID = $util.emptyArray;

        /**
         * BoardChat timetableStartDate.
         * @member {number|Long} timetableStartDate
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.timetableStartDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BoardChat timetableEndDate.
         * @member {number|Long} timetableEndDate
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.timetableEndDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BoardChat timetableOtherStaffName.
         * @member {string} timetableOtherStaffName
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.timetableOtherStaffName = "";

        /**
         * BoardChat timetableRequestUID.
         * @member {Array.<string>} timetableRequestUID
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.timetableRequestUID = $util.emptyArray;

        /**
         * BoardChat workStaffUID.
         * @member {string} workStaffUID
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.workStaffUID = "";

        /**
         * BoardChat changeStartDate.
         * @member {number|Long} changeStartDate
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.changeStartDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BoardChat changeEndDate.
         * @member {number|Long} changeEndDate
         * @memberof Proto.BoardChat
         * @instance
         */
        BoardChat.prototype.changeEndDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new BoardChat instance using the specified properties.
         * @function create
         * @memberof Proto.BoardChat
         * @static
         * @param {Proto.IBoardChat=} [properties] Properties to set
         * @returns {Proto.BoardChat} BoardChat instance
         */
        BoardChat.create = function create(properties) {
            return new BoardChat(properties);
        };

        /**
         * Encodes the specified BoardChat message. Does not implicitly {@link Proto.BoardChat.verify|verify} messages.
         * @function encode
         * @memberof Proto.BoardChat
         * @static
         * @param {Proto.IBoardChat} m BoardChat message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BoardChat.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.groupUID != null && Object.hasOwnProperty.call(m, "groupUID"))
                w.uint32(18).string(m.groupUID);
            if (m.ts != null && Object.hasOwnProperty.call(m, "ts"))
                w.uint32(24).int64(m.ts);
            if (m.body != null && Object.hasOwnProperty.call(m, "body"))
                w.uint32(34).string(m.body);
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(42).fork()).ldelim();
            if (m.owner != null && Object.hasOwnProperty.call(m, "owner"))
                w.uint32(48).bool(m.owner);
            if (m.sticky != null && Object.hasOwnProperty.call(m, "sticky"))
                w.uint32(56).bool(m.sticky);
            if (m.sender != null && Object.hasOwnProperty.call(m, "sender"))
                w.uint32(64).bool(m.sender);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(72).int32(m.status);
            if (m.readDate != null && Object.hasOwnProperty.call(m, "readDate"))
                w.uint32(80).int64(m.readDate);
            if (m.readCount != null && Object.hasOwnProperty.call(m, "readCount"))
                w.uint32(90).string(m.readCount);
            if (m.fileList != null && m.fileList.length) {
                for (var i = 0; i < m.fileList.length; ++i)
                    $root.Proto.UploadFile.encode(m.fileList[i], w.uint32(98).fork()).ldelim();
            }
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(104).int32(m.type);
            if (m.readUserList != null && m.readUserList.length) {
                for (var i = 0; i < m.readUserList.length; ++i)
                    $root.Proto.User.encode(m.readUserList[i], w.uint32(114).fork()).ldelim();
            }
            if (m.deviceName != null && Object.hasOwnProperty.call(m, "deviceName"))
                w.uint32(122).string(m.deviceName);
            if (m.fileType != null && Object.hasOwnProperty.call(m, "fileType"))
                w.uint32(128).int32(m.fileType);
            if (m.emoticonCount != null && Object.hasOwnProperty.call(m, "emoticonCount")) {
                for (var ks = Object.keys(m.emoticonCount), i = 0; i < ks.length; ++i) {
                    w.uint32(138).fork().uint32(10).string(ks[i]).uint32(16).int64(m.emoticonCount[ks[i]]).ldelim();
                }
            }
            if (m.emoticonAllCount != null && Object.hasOwnProperty.call(m, "emoticonAllCount"))
                w.uint32(146).string(m.emoticonAllCount);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(152).int64(m.modifyDate);
            if (m.replyTo != null && Object.hasOwnProperty.call(m, "replyTo"))
                w.uint32(162).string(m.replyTo);
            if (m.reply != null && Object.hasOwnProperty.call(m, "reply"))
                $root.Proto.BoardChat.encode(m.reply, w.uint32(170).fork()).ldelim();
            if (m.targetUserUIDList != null && m.targetUserUIDList.length) {
                for (var i = 0; i < m.targetUserUIDList.length; ++i)
                    w.uint32(178).string(m.targetUserUIDList[i]);
            }
            if (m.target != null && Object.hasOwnProperty.call(m, "target"))
                w.uint32(184).bool(m.target);
            if (m.targetUser != null && m.targetUser.length) {
                for (var i = 0; i < m.targetUser.length; ++i)
                    $root.Proto.User.encode(m.targetUser[i], w.uint32(194).fork()).ldelim();
            }
            if (m.stamp != null && Object.hasOwnProperty.call(m, "stamp"))
                $root.Proto.Stamp.encode(m.stamp, w.uint32(202).fork()).ldelim();
            if (m.srcId != null && Object.hasOwnProperty.call(m, "srcId"))
                w.uint32(210).string(m.srcId);
            if (m.srcDate != null && Object.hasOwnProperty.call(m, "srcDate"))
                w.uint32(216).int64(m.srcDate);
            if (m.srcUser != null && Object.hasOwnProperty.call(m, "srcUser"))
                $root.Proto.User.encode(m.srcUser, w.uint32(226).fork()).ldelim();
            if (m.linkId != null && Object.hasOwnProperty.call(m, "linkId"))
                w.uint32(234).string(m.linkId);
            if (m.timetableUID != null && m.timetableUID.length) {
                for (var i = 0; i < m.timetableUID.length; ++i)
                    w.uint32(242).string(m.timetableUID[i]);
            }
            if (m.timetableStartDate != null && Object.hasOwnProperty.call(m, "timetableStartDate"))
                w.uint32(248).int64(m.timetableStartDate);
            if (m.timetableEndDate != null && Object.hasOwnProperty.call(m, "timetableEndDate"))
                w.uint32(256).int64(m.timetableEndDate);
            if (m.timetableOtherStaffName != null && Object.hasOwnProperty.call(m, "timetableOtherStaffName"))
                w.uint32(266).string(m.timetableOtherStaffName);
            if (m.timetableRequestUID != null && m.timetableRequestUID.length) {
                for (var i = 0; i < m.timetableRequestUID.length; ++i)
                    w.uint32(274).string(m.timetableRequestUID[i]);
            }
            if (m.workStaffUID != null && Object.hasOwnProperty.call(m, "workStaffUID"))
                w.uint32(282).string(m.workStaffUID);
            if (m.changeStartDate != null && Object.hasOwnProperty.call(m, "changeStartDate"))
                w.uint32(288).int64(m.changeStartDate);
            if (m.changeEndDate != null && Object.hasOwnProperty.call(m, "changeEndDate"))
                w.uint32(296).int64(m.changeEndDate);
            return w;
        };

        /**
         * Decodes a BoardChat message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.BoardChat
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.BoardChat} BoardChat
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BoardChat.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.BoardChat(), k, value;
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.id = r.string();
                    break;
                case 2:
                    m.groupUID = r.string();
                    break;
                case 3:
                    m.ts = r.int64();
                    break;
                case 4:
                    m.body = r.string();
                    break;
                case 5:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 6:
                    m.owner = r.bool();
                    break;
                case 7:
                    m.sticky = r.bool();
                    break;
                case 8:
                    m.sender = r.bool();
                    break;
                case 9:
                    m.status = r.int32();
                    break;
                case 10:
                    m.readDate = r.int64();
                    break;
                case 11:
                    m.readCount = r.string();
                    break;
                case 12:
                    if (!(m.fileList && m.fileList.length))
                        m.fileList = [];
                    m.fileList.push($root.Proto.UploadFile.decode(r, r.uint32()));
                    break;
                case 13:
                    m.type = r.int32();
                    break;
                case 14:
                    if (!(m.readUserList && m.readUserList.length))
                        m.readUserList = [];
                    m.readUserList.push($root.Proto.User.decode(r, r.uint32()));
                    break;
                case 15:
                    m.deviceName = r.string();
                    break;
                case 16:
                    m.fileType = r.int32();
                    break;
                case 17:
                    if (m.emoticonCount === $util.emptyObject)
                        m.emoticonCount = {};
                    var c2 = r.uint32() + r.pos;
                    k = "";
                    value = 0;
                    while (r.pos < c2) {
                        var tag2 = r.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            k = r.string();
                            break;
                        case 2:
                            value = r.int64();
                            break;
                        default:
                            r.skipType(tag2 & 7);
                            break;
                        }
                    }
                    m.emoticonCount[k] = value;
                    break;
                case 18:
                    m.emoticonAllCount = r.string();
                    break;
                case 19:
                    m.modifyDate = r.int64();
                    break;
                case 20:
                    m.replyTo = r.string();
                    break;
                case 21:
                    m.reply = $root.Proto.BoardChat.decode(r, r.uint32());
                    break;
                case 22:
                    if (!(m.targetUserUIDList && m.targetUserUIDList.length))
                        m.targetUserUIDList = [];
                    m.targetUserUIDList.push(r.string());
                    break;
                case 23:
                    m.target = r.bool();
                    break;
                case 24:
                    if (!(m.targetUser && m.targetUser.length))
                        m.targetUser = [];
                    m.targetUser.push($root.Proto.User.decode(r, r.uint32()));
                    break;
                case 25:
                    m.stamp = $root.Proto.Stamp.decode(r, r.uint32());
                    break;
                case 26:
                    m.srcId = r.string();
                    break;
                case 27:
                    m.srcDate = r.int64();
                    break;
                case 28:
                    m.srcUser = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 29:
                    m.linkId = r.string();
                    break;
                case 30:
                    if (!(m.timetableUID && m.timetableUID.length))
                        m.timetableUID = [];
                    m.timetableUID.push(r.string());
                    break;
                case 31:
                    m.timetableStartDate = r.int64();
                    break;
                case 32:
                    m.timetableEndDate = r.int64();
                    break;
                case 33:
                    m.timetableOtherStaffName = r.string();
                    break;
                case 34:
                    if (!(m.timetableRequestUID && m.timetableRequestUID.length))
                        m.timetableRequestUID = [];
                    m.timetableRequestUID.push(r.string());
                    break;
                case 35:
                    m.workStaffUID = r.string();
                    break;
                case 36:
                    m.changeStartDate = r.int64();
                    break;
                case 37:
                    m.changeEndDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a BoardChat message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.BoardChat
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.BoardChat} BoardChat
         */
        BoardChat.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.BoardChat)
                return d;
            var m = new $root.Proto.BoardChat();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.groupUID != null) {
                m.groupUID = String(d.groupUID);
            }
            if (d.ts != null) {
                if ($util.Long)
                    (m.ts = $util.Long.fromValue(d.ts)).unsigned = false;
                else if (typeof d.ts === "string")
                    m.ts = parseInt(d.ts, 10);
                else if (typeof d.ts === "number")
                    m.ts = d.ts;
                else if (typeof d.ts === "object")
                    m.ts = new $util.LongBits(d.ts.low >>> 0, d.ts.high >>> 0).toNumber();
            }
            if (d.body != null) {
                m.body = String(d.body);
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.BoardChat.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            if (d.owner != null) {
                m.owner = Boolean(d.owner);
            }
            if (d.sticky != null) {
                m.sticky = Boolean(d.sticky);
            }
            if (d.sender != null) {
                m.sender = Boolean(d.sender);
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.readDate != null) {
                if ($util.Long)
                    (m.readDate = $util.Long.fromValue(d.readDate)).unsigned = false;
                else if (typeof d.readDate === "string")
                    m.readDate = parseInt(d.readDate, 10);
                else if (typeof d.readDate === "number")
                    m.readDate = d.readDate;
                else if (typeof d.readDate === "object")
                    m.readDate = new $util.LongBits(d.readDate.low >>> 0, d.readDate.high >>> 0).toNumber();
            }
            if (d.readCount != null) {
                m.readCount = String(d.readCount);
            }
            if (d.fileList) {
                if (!Array.isArray(d.fileList))
                    throw TypeError(".Proto.BoardChat.fileList: array expected");
                m.fileList = [];
                for (var i = 0; i < d.fileList.length; ++i) {
                    if (typeof d.fileList[i] !== "object")
                        throw TypeError(".Proto.BoardChat.fileList: object expected");
                    m.fileList[i] = $root.Proto.UploadFile.fromObject(d.fileList[i]);
                }
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.readUserList) {
                if (!Array.isArray(d.readUserList))
                    throw TypeError(".Proto.BoardChat.readUserList: array expected");
                m.readUserList = [];
                for (var i = 0; i < d.readUserList.length; ++i) {
                    if (typeof d.readUserList[i] !== "object")
                        throw TypeError(".Proto.BoardChat.readUserList: object expected");
                    m.readUserList[i] = $root.Proto.User.fromObject(d.readUserList[i]);
                }
            }
            if (d.deviceName != null) {
                m.deviceName = String(d.deviceName);
            }
            if (d.fileType != null) {
                m.fileType = d.fileType | 0;
            }
            if (d.emoticonCount) {
                if (typeof d.emoticonCount !== "object")
                    throw TypeError(".Proto.BoardChat.emoticonCount: object expected");
                m.emoticonCount = {};
                for (var ks = Object.keys(d.emoticonCount), i = 0; i < ks.length; ++i) {
                    if ($util.Long)
                        (m.emoticonCount[ks[i]] = $util.Long.fromValue(d.emoticonCount[ks[i]])).unsigned = false;
                    else if (typeof d.emoticonCount[ks[i]] === "string")
                        m.emoticonCount[ks[i]] = parseInt(d.emoticonCount[ks[i]], 10);
                    else if (typeof d.emoticonCount[ks[i]] === "number")
                        m.emoticonCount[ks[i]] = d.emoticonCount[ks[i]];
                    else if (typeof d.emoticonCount[ks[i]] === "object")
                        m.emoticonCount[ks[i]] = new $util.LongBits(d.emoticonCount[ks[i]].low >>> 0, d.emoticonCount[ks[i]].high >>> 0).toNumber();
                }
            }
            if (d.emoticonAllCount != null) {
                m.emoticonAllCount = String(d.emoticonAllCount);
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.replyTo != null) {
                m.replyTo = String(d.replyTo);
            }
            if (d.reply != null) {
                if (typeof d.reply !== "object")
                    throw TypeError(".Proto.BoardChat.reply: object expected");
                m.reply = $root.Proto.BoardChat.fromObject(d.reply);
            }
            if (d.targetUserUIDList) {
                if (!Array.isArray(d.targetUserUIDList))
                    throw TypeError(".Proto.BoardChat.targetUserUIDList: array expected");
                m.targetUserUIDList = [];
                for (var i = 0; i < d.targetUserUIDList.length; ++i) {
                    m.targetUserUIDList[i] = String(d.targetUserUIDList[i]);
                }
            }
            if (d.target != null) {
                m.target = Boolean(d.target);
            }
            if (d.targetUser) {
                if (!Array.isArray(d.targetUser))
                    throw TypeError(".Proto.BoardChat.targetUser: array expected");
                m.targetUser = [];
                for (var i = 0; i < d.targetUser.length; ++i) {
                    if (typeof d.targetUser[i] !== "object")
                        throw TypeError(".Proto.BoardChat.targetUser: object expected");
                    m.targetUser[i] = $root.Proto.User.fromObject(d.targetUser[i]);
                }
            }
            if (d.stamp != null) {
                if (typeof d.stamp !== "object")
                    throw TypeError(".Proto.BoardChat.stamp: object expected");
                m.stamp = $root.Proto.Stamp.fromObject(d.stamp);
            }
            if (d.srcId != null) {
                m.srcId = String(d.srcId);
            }
            if (d.srcDate != null) {
                if ($util.Long)
                    (m.srcDate = $util.Long.fromValue(d.srcDate)).unsigned = false;
                else if (typeof d.srcDate === "string")
                    m.srcDate = parseInt(d.srcDate, 10);
                else if (typeof d.srcDate === "number")
                    m.srcDate = d.srcDate;
                else if (typeof d.srcDate === "object")
                    m.srcDate = new $util.LongBits(d.srcDate.low >>> 0, d.srcDate.high >>> 0).toNumber();
            }
            if (d.srcUser != null) {
                if (typeof d.srcUser !== "object")
                    throw TypeError(".Proto.BoardChat.srcUser: object expected");
                m.srcUser = $root.Proto.User.fromObject(d.srcUser);
            }
            if (d.linkId != null) {
                m.linkId = String(d.linkId);
            }
            if (d.timetableUID) {
                if (!Array.isArray(d.timetableUID))
                    throw TypeError(".Proto.BoardChat.timetableUID: array expected");
                m.timetableUID = [];
                for (var i = 0; i < d.timetableUID.length; ++i) {
                    m.timetableUID[i] = String(d.timetableUID[i]);
                }
            }
            if (d.timetableStartDate != null) {
                if ($util.Long)
                    (m.timetableStartDate = $util.Long.fromValue(d.timetableStartDate)).unsigned = false;
                else if (typeof d.timetableStartDate === "string")
                    m.timetableStartDate = parseInt(d.timetableStartDate, 10);
                else if (typeof d.timetableStartDate === "number")
                    m.timetableStartDate = d.timetableStartDate;
                else if (typeof d.timetableStartDate === "object")
                    m.timetableStartDate = new $util.LongBits(d.timetableStartDate.low >>> 0, d.timetableStartDate.high >>> 0).toNumber();
            }
            if (d.timetableEndDate != null) {
                if ($util.Long)
                    (m.timetableEndDate = $util.Long.fromValue(d.timetableEndDate)).unsigned = false;
                else if (typeof d.timetableEndDate === "string")
                    m.timetableEndDate = parseInt(d.timetableEndDate, 10);
                else if (typeof d.timetableEndDate === "number")
                    m.timetableEndDate = d.timetableEndDate;
                else if (typeof d.timetableEndDate === "object")
                    m.timetableEndDate = new $util.LongBits(d.timetableEndDate.low >>> 0, d.timetableEndDate.high >>> 0).toNumber();
            }
            if (d.timetableOtherStaffName != null) {
                m.timetableOtherStaffName = String(d.timetableOtherStaffName);
            }
            if (d.timetableRequestUID) {
                if (!Array.isArray(d.timetableRequestUID))
                    throw TypeError(".Proto.BoardChat.timetableRequestUID: array expected");
                m.timetableRequestUID = [];
                for (var i = 0; i < d.timetableRequestUID.length; ++i) {
                    m.timetableRequestUID[i] = String(d.timetableRequestUID[i]);
                }
            }
            if (d.workStaffUID != null) {
                m.workStaffUID = String(d.workStaffUID);
            }
            if (d.changeStartDate != null) {
                if ($util.Long)
                    (m.changeStartDate = $util.Long.fromValue(d.changeStartDate)).unsigned = false;
                else if (typeof d.changeStartDate === "string")
                    m.changeStartDate = parseInt(d.changeStartDate, 10);
                else if (typeof d.changeStartDate === "number")
                    m.changeStartDate = d.changeStartDate;
                else if (typeof d.changeStartDate === "object")
                    m.changeStartDate = new $util.LongBits(d.changeStartDate.low >>> 0, d.changeStartDate.high >>> 0).toNumber();
            }
            if (d.changeEndDate != null) {
                if ($util.Long)
                    (m.changeEndDate = $util.Long.fromValue(d.changeEndDate)).unsigned = false;
                else if (typeof d.changeEndDate === "string")
                    m.changeEndDate = parseInt(d.changeEndDate, 10);
                else if (typeof d.changeEndDate === "number")
                    m.changeEndDate = d.changeEndDate;
                else if (typeof d.changeEndDate === "object")
                    m.changeEndDate = new $util.LongBits(d.changeEndDate.low >>> 0, d.changeEndDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a BoardChat message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.BoardChat
         * @static
         * @param {Proto.BoardChat} m BoardChat
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BoardChat.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.fileList = [];
                d.readUserList = [];
                d.targetUserUIDList = [];
                d.targetUser = [];
                d.timetableUID = [];
                d.timetableRequestUID = [];
            }
            if (o.objects || o.defaults) {
                d.emoticonCount = {};
            }
            if (o.defaults) {
                d.id = "";
                d.groupUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.ts = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.ts = o.longs === String ? "0" : 0;
                d.body = "";
                d.user = null;
                d.owner = false;
                d.sticky = false;
                d.sender = false;
                d.status = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.readDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.readDate = o.longs === String ? "0" : 0;
                d.readCount = "";
                d.type = 0;
                d.deviceName = "";
                d.fileType = 0;
                d.emoticonAllCount = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.replyTo = "";
                d.reply = null;
                d.target = false;
                d.stamp = null;
                d.srcId = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.srcDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.srcDate = o.longs === String ? "0" : 0;
                d.srcUser = null;
                d.linkId = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.timetableStartDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timetableStartDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.timetableEndDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timetableEndDate = o.longs === String ? "0" : 0;
                d.timetableOtherStaffName = "";
                d.workStaffUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.changeStartDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.changeStartDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.changeEndDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.changeEndDate = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.groupUID != null && m.hasOwnProperty("groupUID")) {
                d.groupUID = m.groupUID;
            }
            if (m.ts != null && m.hasOwnProperty("ts")) {
                if (typeof m.ts === "number")
                    d.ts = o.longs === String ? String(m.ts) : m.ts;
                else
                    d.ts = o.longs === String ? $util.Long.prototype.toString.call(m.ts) : o.longs === Number ? new $util.LongBits(m.ts.low >>> 0, m.ts.high >>> 0).toNumber() : m.ts;
            }
            if (m.body != null && m.hasOwnProperty("body")) {
                d.body = m.body;
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            if (m.owner != null && m.hasOwnProperty("owner")) {
                d.owner = m.owner;
            }
            if (m.sticky != null && m.hasOwnProperty("sticky")) {
                d.sticky = m.sticky;
            }
            if (m.sender != null && m.hasOwnProperty("sender")) {
                d.sender = m.sender;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.readDate != null && m.hasOwnProperty("readDate")) {
                if (typeof m.readDate === "number")
                    d.readDate = o.longs === String ? String(m.readDate) : m.readDate;
                else
                    d.readDate = o.longs === String ? $util.Long.prototype.toString.call(m.readDate) : o.longs === Number ? new $util.LongBits(m.readDate.low >>> 0, m.readDate.high >>> 0).toNumber() : m.readDate;
            }
            if (m.readCount != null && m.hasOwnProperty("readCount")) {
                d.readCount = m.readCount;
            }
            if (m.fileList && m.fileList.length) {
                d.fileList = [];
                for (var j = 0; j < m.fileList.length; ++j) {
                    d.fileList[j] = $root.Proto.UploadFile.toObject(m.fileList[j], o);
                }
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.readUserList && m.readUserList.length) {
                d.readUserList = [];
                for (var j = 0; j < m.readUserList.length; ++j) {
                    d.readUserList[j] = $root.Proto.User.toObject(m.readUserList[j], o);
                }
            }
            if (m.deviceName != null && m.hasOwnProperty("deviceName")) {
                d.deviceName = m.deviceName;
            }
            if (m.fileType != null && m.hasOwnProperty("fileType")) {
                d.fileType = m.fileType;
            }
            var ks2;
            if (m.emoticonCount && (ks2 = Object.keys(m.emoticonCount)).length) {
                d.emoticonCount = {};
                for (var j = 0; j < ks2.length; ++j) {
                    if (typeof m.emoticonCount[ks2[j]] === "number")
                        d.emoticonCount[ks2[j]] = o.longs === String ? String(m.emoticonCount[ks2[j]]) : m.emoticonCount[ks2[j]];
                    else
                        d.emoticonCount[ks2[j]] = o.longs === String ? $util.Long.prototype.toString.call(m.emoticonCount[ks2[j]]) : o.longs === Number ? new $util.LongBits(m.emoticonCount[ks2[j]].low >>> 0, m.emoticonCount[ks2[j]].high >>> 0).toNumber() : m.emoticonCount[ks2[j]];
                }
            }
            if (m.emoticonAllCount != null && m.hasOwnProperty("emoticonAllCount")) {
                d.emoticonAllCount = m.emoticonAllCount;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.replyTo != null && m.hasOwnProperty("replyTo")) {
                d.replyTo = m.replyTo;
            }
            if (m.reply != null && m.hasOwnProperty("reply")) {
                d.reply = $root.Proto.BoardChat.toObject(m.reply, o);
            }
            if (m.targetUserUIDList && m.targetUserUIDList.length) {
                d.targetUserUIDList = [];
                for (var j = 0; j < m.targetUserUIDList.length; ++j) {
                    d.targetUserUIDList[j] = m.targetUserUIDList[j];
                }
            }
            if (m.target != null && m.hasOwnProperty("target")) {
                d.target = m.target;
            }
            if (m.targetUser && m.targetUser.length) {
                d.targetUser = [];
                for (var j = 0; j < m.targetUser.length; ++j) {
                    d.targetUser[j] = $root.Proto.User.toObject(m.targetUser[j], o);
                }
            }
            if (m.stamp != null && m.hasOwnProperty("stamp")) {
                d.stamp = $root.Proto.Stamp.toObject(m.stamp, o);
            }
            if (m.srcId != null && m.hasOwnProperty("srcId")) {
                d.srcId = m.srcId;
            }
            if (m.srcDate != null && m.hasOwnProperty("srcDate")) {
                if (typeof m.srcDate === "number")
                    d.srcDate = o.longs === String ? String(m.srcDate) : m.srcDate;
                else
                    d.srcDate = o.longs === String ? $util.Long.prototype.toString.call(m.srcDate) : o.longs === Number ? new $util.LongBits(m.srcDate.low >>> 0, m.srcDate.high >>> 0).toNumber() : m.srcDate;
            }
            if (m.srcUser != null && m.hasOwnProperty("srcUser")) {
                d.srcUser = $root.Proto.User.toObject(m.srcUser, o);
            }
            if (m.linkId != null && m.hasOwnProperty("linkId")) {
                d.linkId = m.linkId;
            }
            if (m.timetableUID && m.timetableUID.length) {
                d.timetableUID = [];
                for (var j = 0; j < m.timetableUID.length; ++j) {
                    d.timetableUID[j] = m.timetableUID[j];
                }
            }
            if (m.timetableStartDate != null && m.hasOwnProperty("timetableStartDate")) {
                if (typeof m.timetableStartDate === "number")
                    d.timetableStartDate = o.longs === String ? String(m.timetableStartDate) : m.timetableStartDate;
                else
                    d.timetableStartDate = o.longs === String ? $util.Long.prototype.toString.call(m.timetableStartDate) : o.longs === Number ? new $util.LongBits(m.timetableStartDate.low >>> 0, m.timetableStartDate.high >>> 0).toNumber() : m.timetableStartDate;
            }
            if (m.timetableEndDate != null && m.hasOwnProperty("timetableEndDate")) {
                if (typeof m.timetableEndDate === "number")
                    d.timetableEndDate = o.longs === String ? String(m.timetableEndDate) : m.timetableEndDate;
                else
                    d.timetableEndDate = o.longs === String ? $util.Long.prototype.toString.call(m.timetableEndDate) : o.longs === Number ? new $util.LongBits(m.timetableEndDate.low >>> 0, m.timetableEndDate.high >>> 0).toNumber() : m.timetableEndDate;
            }
            if (m.timetableOtherStaffName != null && m.hasOwnProperty("timetableOtherStaffName")) {
                d.timetableOtherStaffName = m.timetableOtherStaffName;
            }
            if (m.timetableRequestUID && m.timetableRequestUID.length) {
                d.timetableRequestUID = [];
                for (var j = 0; j < m.timetableRequestUID.length; ++j) {
                    d.timetableRequestUID[j] = m.timetableRequestUID[j];
                }
            }
            if (m.workStaffUID != null && m.hasOwnProperty("workStaffUID")) {
                d.workStaffUID = m.workStaffUID;
            }
            if (m.changeStartDate != null && m.hasOwnProperty("changeStartDate")) {
                if (typeof m.changeStartDate === "number")
                    d.changeStartDate = o.longs === String ? String(m.changeStartDate) : m.changeStartDate;
                else
                    d.changeStartDate = o.longs === String ? $util.Long.prototype.toString.call(m.changeStartDate) : o.longs === Number ? new $util.LongBits(m.changeStartDate.low >>> 0, m.changeStartDate.high >>> 0).toNumber() : m.changeStartDate;
            }
            if (m.changeEndDate != null && m.hasOwnProperty("changeEndDate")) {
                if (typeof m.changeEndDate === "number")
                    d.changeEndDate = o.longs === String ? String(m.changeEndDate) : m.changeEndDate;
                else
                    d.changeEndDate = o.longs === String ? $util.Long.prototype.toString.call(m.changeEndDate) : o.longs === Number ? new $util.LongBits(m.changeEndDate.low >>> 0, m.changeEndDate.high >>> 0).toNumber() : m.changeEndDate;
            }
            return d;
        };

        /**
         * Converts this BoardChat to JSON.
         * @function toJSON
         * @memberof Proto.BoardChat
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BoardChat.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BoardChat;
    })();

    Proto.BoardChatList = (function() {

        /**
         * Properties of a BoardChatList.
         * @memberof Proto
         * @interface IBoardChatList
         * @property {Array.<Proto.IBoardChat>|null} [value] BoardChatList value
         * @property {string|null} [nextPageToken] BoardChatList nextPageToken
         * @property {string|null} [prevPageToken] BoardChatList prevPageToken
         */

        /**
         * Constructs a new BoardChatList.
         * @memberof Proto
         * @classdesc Represents a BoardChatList.
         * @implements IBoardChatList
         * @constructor
         * @param {Proto.IBoardChatList=} [p] Properties to set
         */
        function BoardChatList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * BoardChatList value.
         * @member {Array.<Proto.IBoardChat>} value
         * @memberof Proto.BoardChatList
         * @instance
         */
        BoardChatList.prototype.value = $util.emptyArray;

        /**
         * BoardChatList nextPageToken.
         * @member {string} nextPageToken
         * @memberof Proto.BoardChatList
         * @instance
         */
        BoardChatList.prototype.nextPageToken = "";

        /**
         * BoardChatList prevPageToken.
         * @member {string} prevPageToken
         * @memberof Proto.BoardChatList
         * @instance
         */
        BoardChatList.prototype.prevPageToken = "";

        /**
         * Creates a new BoardChatList instance using the specified properties.
         * @function create
         * @memberof Proto.BoardChatList
         * @static
         * @param {Proto.IBoardChatList=} [properties] Properties to set
         * @returns {Proto.BoardChatList} BoardChatList instance
         */
        BoardChatList.create = function create(properties) {
            return new BoardChatList(properties);
        };

        /**
         * Encodes the specified BoardChatList message. Does not implicitly {@link Proto.BoardChatList.verify|verify} messages.
         * @function encode
         * @memberof Proto.BoardChatList
         * @static
         * @param {Proto.IBoardChatList} m BoardChatList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BoardChatList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.BoardChat.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                w.uint32(18).string(m.nextPageToken);
            if (m.prevPageToken != null && Object.hasOwnProperty.call(m, "prevPageToken"))
                w.uint32(26).string(m.prevPageToken);
            return w;
        };

        /**
         * Decodes a BoardChatList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.BoardChatList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.BoardChatList} BoardChatList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BoardChatList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.BoardChatList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.BoardChat.decode(r, r.uint32()));
                    break;
                case 2:
                    m.nextPageToken = r.string();
                    break;
                case 3:
                    m.prevPageToken = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a BoardChatList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.BoardChatList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.BoardChatList} BoardChatList
         */
        BoardChatList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.BoardChatList)
                return d;
            var m = new $root.Proto.BoardChatList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.BoardChatList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.BoardChatList.value: object expected");
                    m.value[i] = $root.Proto.BoardChat.fromObject(d.value[i]);
                }
            }
            if (d.nextPageToken != null) {
                m.nextPageToken = String(d.nextPageToken);
            }
            if (d.prevPageToken != null) {
                m.prevPageToken = String(d.prevPageToken);
            }
            return m;
        };

        /**
         * Creates a plain object from a BoardChatList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.BoardChatList
         * @static
         * @param {Proto.BoardChatList} m BoardChatList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BoardChatList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (o.defaults) {
                d.nextPageToken = "";
                d.prevPageToken = "";
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.BoardChat.toObject(m.value[j], o);
                }
            }
            if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                d.nextPageToken = m.nextPageToken;
            }
            if (m.prevPageToken != null && m.hasOwnProperty("prevPageToken")) {
                d.prevPageToken = m.prevPageToken;
            }
            return d;
        };

        /**
         * Converts this BoardChatList to JSON.
         * @function toJSON
         * @memberof Proto.BoardChatList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BoardChatList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BoardChatList;
    })();

    Proto.ChatUserProfile = (function() {

        /**
         * Properties of a ChatUserProfile.
         * @memberof Proto
         * @interface IChatUserProfile
         * @property {string|null} [name] ChatUserProfile name
         * @property {Proto.IUploadFile|null} [icon] ChatUserProfile icon
         * @property {number|Long|null} [registerDate] ChatUserProfile registerDate
         * @property {Array.<Proto.ChatUserProfile.IPlan>|null} [planList] ChatUserProfile planList
         * @property {Array.<Proto.ChatUserProfile.IGroup>|null} [groupList] ChatUserProfile groupList
         */

        /**
         * Constructs a new ChatUserProfile.
         * @memberof Proto
         * @classdesc Represents a ChatUserProfile.
         * @implements IChatUserProfile
         * @constructor
         * @param {Proto.IChatUserProfile=} [p] Properties to set
         */
        function ChatUserProfile(p) {
            this.planList = [];
            this.groupList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ChatUserProfile name.
         * @member {string} name
         * @memberof Proto.ChatUserProfile
         * @instance
         */
        ChatUserProfile.prototype.name = "";

        /**
         * ChatUserProfile icon.
         * @member {Proto.IUploadFile|null|undefined} icon
         * @memberof Proto.ChatUserProfile
         * @instance
         */
        ChatUserProfile.prototype.icon = null;

        /**
         * ChatUserProfile registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ChatUserProfile
         * @instance
         */
        ChatUserProfile.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ChatUserProfile planList.
         * @member {Array.<Proto.ChatUserProfile.IPlan>} planList
         * @memberof Proto.ChatUserProfile
         * @instance
         */
        ChatUserProfile.prototype.planList = $util.emptyArray;

        /**
         * ChatUserProfile groupList.
         * @member {Array.<Proto.ChatUserProfile.IGroup>} groupList
         * @memberof Proto.ChatUserProfile
         * @instance
         */
        ChatUserProfile.prototype.groupList = $util.emptyArray;

        /**
         * Creates a new ChatUserProfile instance using the specified properties.
         * @function create
         * @memberof Proto.ChatUserProfile
         * @static
         * @param {Proto.IChatUserProfile=} [properties] Properties to set
         * @returns {Proto.ChatUserProfile} ChatUserProfile instance
         */
        ChatUserProfile.create = function create(properties) {
            return new ChatUserProfile(properties);
        };

        /**
         * Encodes the specified ChatUserProfile message. Does not implicitly {@link Proto.ChatUserProfile.verify|verify} messages.
         * @function encode
         * @memberof Proto.ChatUserProfile
         * @static
         * @param {Proto.IChatUserProfile} m ChatUserProfile message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatUserProfile.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            if (m.icon != null && Object.hasOwnProperty.call(m, "icon"))
                $root.Proto.UploadFile.encode(m.icon, w.uint32(18).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.planList != null && m.planList.length) {
                for (var i = 0; i < m.planList.length; ++i)
                    $root.Proto.ChatUserProfile.Plan.encode(m.planList[i], w.uint32(34).fork()).ldelim();
            }
            if (m.groupList != null && m.groupList.length) {
                for (var i = 0; i < m.groupList.length; ++i)
                    $root.Proto.ChatUserProfile.Group.encode(m.groupList[i], w.uint32(42).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ChatUserProfile message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ChatUserProfile
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ChatUserProfile} ChatUserProfile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatUserProfile.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ChatUserProfile();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.name = r.string();
                    break;
                case 2:
                    m.icon = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    if (!(m.planList && m.planList.length))
                        m.planList = [];
                    m.planList.push($root.Proto.ChatUserProfile.Plan.decode(r, r.uint32()));
                    break;
                case 5:
                    if (!(m.groupList && m.groupList.length))
                        m.groupList = [];
                    m.groupList.push($root.Proto.ChatUserProfile.Group.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ChatUserProfile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ChatUserProfile
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ChatUserProfile} ChatUserProfile
         */
        ChatUserProfile.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ChatUserProfile)
                return d;
            var m = new $root.Proto.ChatUserProfile();
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.icon != null) {
                if (typeof d.icon !== "object")
                    throw TypeError(".Proto.ChatUserProfile.icon: object expected");
                m.icon = $root.Proto.UploadFile.fromObject(d.icon);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.planList) {
                if (!Array.isArray(d.planList))
                    throw TypeError(".Proto.ChatUserProfile.planList: array expected");
                m.planList = [];
                for (var i = 0; i < d.planList.length; ++i) {
                    if (typeof d.planList[i] !== "object")
                        throw TypeError(".Proto.ChatUserProfile.planList: object expected");
                    m.planList[i] = $root.Proto.ChatUserProfile.Plan.fromObject(d.planList[i]);
                }
            }
            if (d.groupList) {
                if (!Array.isArray(d.groupList))
                    throw TypeError(".Proto.ChatUserProfile.groupList: array expected");
                m.groupList = [];
                for (var i = 0; i < d.groupList.length; ++i) {
                    if (typeof d.groupList[i] !== "object")
                        throw TypeError(".Proto.ChatUserProfile.groupList: object expected");
                    m.groupList[i] = $root.Proto.ChatUserProfile.Group.fromObject(d.groupList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ChatUserProfile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ChatUserProfile
         * @static
         * @param {Proto.ChatUserProfile} m ChatUserProfile
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChatUserProfile.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.planList = [];
                d.groupList = [];
            }
            if (o.defaults) {
                d.name = "";
                d.icon = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.icon != null && m.hasOwnProperty("icon")) {
                d.icon = $root.Proto.UploadFile.toObject(m.icon, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.planList && m.planList.length) {
                d.planList = [];
                for (var j = 0; j < m.planList.length; ++j) {
                    d.planList[j] = $root.Proto.ChatUserProfile.Plan.toObject(m.planList[j], o);
                }
            }
            if (m.groupList && m.groupList.length) {
                d.groupList = [];
                for (var j = 0; j < m.groupList.length; ++j) {
                    d.groupList[j] = $root.Proto.ChatUserProfile.Group.toObject(m.groupList[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ChatUserProfile to JSON.
         * @function toJSON
         * @memberof Proto.ChatUserProfile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChatUserProfile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ChatUserProfile.Plan = (function() {

            /**
             * Properties of a Plan.
             * @memberof Proto.ChatUserProfile
             * @interface IPlan
             * @property {string|null} [uid] Plan uid
             * @property {string|null} [name] Plan name
             */

            /**
             * Constructs a new Plan.
             * @memberof Proto.ChatUserProfile
             * @classdesc Represents a Plan.
             * @implements IPlan
             * @constructor
             * @param {Proto.ChatUserProfile.IPlan=} [p] Properties to set
             */
            function Plan(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Plan uid.
             * @member {string} uid
             * @memberof Proto.ChatUserProfile.Plan
             * @instance
             */
            Plan.prototype.uid = "";

            /**
             * Plan name.
             * @member {string} name
             * @memberof Proto.ChatUserProfile.Plan
             * @instance
             */
            Plan.prototype.name = "";

            /**
             * Creates a new Plan instance using the specified properties.
             * @function create
             * @memberof Proto.ChatUserProfile.Plan
             * @static
             * @param {Proto.ChatUserProfile.IPlan=} [properties] Properties to set
             * @returns {Proto.ChatUserProfile.Plan} Plan instance
             */
            Plan.create = function create(properties) {
                return new Plan(properties);
            };

            /**
             * Encodes the specified Plan message. Does not implicitly {@link Proto.ChatUserProfile.Plan.verify|verify} messages.
             * @function encode
             * @memberof Proto.ChatUserProfile.Plan
             * @static
             * @param {Proto.ChatUserProfile.IPlan} m Plan message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Plan.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                    w.uint32(10).string(m.uid);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(18).string(m.name);
                return w;
            };

            /**
             * Decodes a Plan message from the specified reader or buffer.
             * @function decode
             * @memberof Proto.ChatUserProfile.Plan
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {Proto.ChatUserProfile.Plan} Plan
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Plan.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ChatUserProfile.Plan();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.uid = r.string();
                        break;
                    case 2:
                        m.name = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Plan message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Proto.ChatUserProfile.Plan
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {Proto.ChatUserProfile.Plan} Plan
             */
            Plan.fromObject = function fromObject(d) {
                if (d instanceof $root.Proto.ChatUserProfile.Plan)
                    return d;
                var m = new $root.Proto.ChatUserProfile.Plan();
                if (d.uid != null) {
                    m.uid = String(d.uid);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                return m;
            };

            /**
             * Creates a plain object from a Plan message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Proto.ChatUserProfile.Plan
             * @static
             * @param {Proto.ChatUserProfile.Plan} m Plan
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Plan.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.uid = "";
                    d.name = "";
                }
                if (m.uid != null && m.hasOwnProperty("uid")) {
                    d.uid = m.uid;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                return d;
            };

            /**
             * Converts this Plan to JSON.
             * @function toJSON
             * @memberof Proto.ChatUserProfile.Plan
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Plan.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Plan;
        })();

        ChatUserProfile.Group = (function() {

            /**
             * Properties of a Group.
             * @memberof Proto.ChatUserProfile
             * @interface IGroup
             * @property {string|null} [uid] Group uid
             * @property {string|null} [name] Group name
             */

            /**
             * Constructs a new Group.
             * @memberof Proto.ChatUserProfile
             * @classdesc Represents a Group.
             * @implements IGroup
             * @constructor
             * @param {Proto.ChatUserProfile.IGroup=} [p] Properties to set
             */
            function Group(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Group uid.
             * @member {string} uid
             * @memberof Proto.ChatUserProfile.Group
             * @instance
             */
            Group.prototype.uid = "";

            /**
             * Group name.
             * @member {string} name
             * @memberof Proto.ChatUserProfile.Group
             * @instance
             */
            Group.prototype.name = "";

            /**
             * Creates a new Group instance using the specified properties.
             * @function create
             * @memberof Proto.ChatUserProfile.Group
             * @static
             * @param {Proto.ChatUserProfile.IGroup=} [properties] Properties to set
             * @returns {Proto.ChatUserProfile.Group} Group instance
             */
            Group.create = function create(properties) {
                return new Group(properties);
            };

            /**
             * Encodes the specified Group message. Does not implicitly {@link Proto.ChatUserProfile.Group.verify|verify} messages.
             * @function encode
             * @memberof Proto.ChatUserProfile.Group
             * @static
             * @param {Proto.ChatUserProfile.IGroup} m Group message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Group.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                    w.uint32(10).string(m.uid);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(18).string(m.name);
                return w;
            };

            /**
             * Decodes a Group message from the specified reader or buffer.
             * @function decode
             * @memberof Proto.ChatUserProfile.Group
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {Proto.ChatUserProfile.Group} Group
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Group.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ChatUserProfile.Group();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.uid = r.string();
                        break;
                    case 2:
                        m.name = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Group message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Proto.ChatUserProfile.Group
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {Proto.ChatUserProfile.Group} Group
             */
            Group.fromObject = function fromObject(d) {
                if (d instanceof $root.Proto.ChatUserProfile.Group)
                    return d;
                var m = new $root.Proto.ChatUserProfile.Group();
                if (d.uid != null) {
                    m.uid = String(d.uid);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                return m;
            };

            /**
             * Creates a plain object from a Group message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Proto.ChatUserProfile.Group
             * @static
             * @param {Proto.ChatUserProfile.Group} m Group
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Group.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.uid = "";
                    d.name = "";
                }
                if (m.uid != null && m.hasOwnProperty("uid")) {
                    d.uid = m.uid;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                return d;
            };

            /**
             * Converts this Group to JSON.
             * @function toJSON
             * @memberof Proto.ChatUserProfile.Group
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Group.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Group;
        })();

        return ChatUserProfile;
    })();

    Proto.AppNeedLogin = (function() {

        /**
         * Properties of an AppNeedLogin.
         * @memberof Proto
         * @interface IAppNeedLogin
         * @property {boolean|null} [registerAvail] AppNeedLogin registerAvail
         * @property {boolean|null} [needLogin] AppNeedLogin needLogin
         * @property {boolean|null} [directHome] AppNeedLogin directHome
         * @property {number|null} [registerAlpha] AppNeedLogin registerAlpha
         */

        /**
         * Constructs a new AppNeedLogin.
         * @memberof Proto
         * @classdesc Represents an AppNeedLogin.
         * @implements IAppNeedLogin
         * @constructor
         * @param {Proto.IAppNeedLogin=} [p] Properties to set
         */
        function AppNeedLogin(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AppNeedLogin registerAvail.
         * @member {boolean} registerAvail
         * @memberof Proto.AppNeedLogin
         * @instance
         */
        AppNeedLogin.prototype.registerAvail = false;

        /**
         * AppNeedLogin needLogin.
         * @member {boolean} needLogin
         * @memberof Proto.AppNeedLogin
         * @instance
         */
        AppNeedLogin.prototype.needLogin = false;

        /**
         * AppNeedLogin directHome.
         * @member {boolean} directHome
         * @memberof Proto.AppNeedLogin
         * @instance
         */
        AppNeedLogin.prototype.directHome = false;

        /**
         * AppNeedLogin registerAlpha.
         * @member {number} registerAlpha
         * @memberof Proto.AppNeedLogin
         * @instance
         */
        AppNeedLogin.prototype.registerAlpha = 0;

        /**
         * Creates a new AppNeedLogin instance using the specified properties.
         * @function create
         * @memberof Proto.AppNeedLogin
         * @static
         * @param {Proto.IAppNeedLogin=} [properties] Properties to set
         * @returns {Proto.AppNeedLogin} AppNeedLogin instance
         */
        AppNeedLogin.create = function create(properties) {
            return new AppNeedLogin(properties);
        };

        /**
         * Encodes the specified AppNeedLogin message. Does not implicitly {@link Proto.AppNeedLogin.verify|verify} messages.
         * @function encode
         * @memberof Proto.AppNeedLogin
         * @static
         * @param {Proto.IAppNeedLogin} m AppNeedLogin message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppNeedLogin.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.registerAvail != null && Object.hasOwnProperty.call(m, "registerAvail"))
                w.uint32(8).bool(m.registerAvail);
            if (m.needLogin != null && Object.hasOwnProperty.call(m, "needLogin"))
                w.uint32(16).bool(m.needLogin);
            if (m.directHome != null && Object.hasOwnProperty.call(m, "directHome"))
                w.uint32(24).bool(m.directHome);
            if (m.registerAlpha != null && Object.hasOwnProperty.call(m, "registerAlpha"))
                w.uint32(32).int32(m.registerAlpha);
            return w;
        };

        /**
         * Decodes an AppNeedLogin message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.AppNeedLogin
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.AppNeedLogin} AppNeedLogin
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppNeedLogin.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.AppNeedLogin();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.registerAvail = r.bool();
                    break;
                case 2:
                    m.needLogin = r.bool();
                    break;
                case 3:
                    m.directHome = r.bool();
                    break;
                case 4:
                    m.registerAlpha = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AppNeedLogin message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.AppNeedLogin
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.AppNeedLogin} AppNeedLogin
         */
        AppNeedLogin.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.AppNeedLogin)
                return d;
            var m = new $root.Proto.AppNeedLogin();
            if (d.registerAvail != null) {
                m.registerAvail = Boolean(d.registerAvail);
            }
            if (d.needLogin != null) {
                m.needLogin = Boolean(d.needLogin);
            }
            if (d.directHome != null) {
                m.directHome = Boolean(d.directHome);
            }
            if (d.registerAlpha != null) {
                m.registerAlpha = d.registerAlpha | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from an AppNeedLogin message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.AppNeedLogin
         * @static
         * @param {Proto.AppNeedLogin} m AppNeedLogin
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppNeedLogin.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.registerAvail = false;
                d.needLogin = false;
                d.directHome = false;
                d.registerAlpha = 0;
            }
            if (m.registerAvail != null && m.hasOwnProperty("registerAvail")) {
                d.registerAvail = m.registerAvail;
            }
            if (m.needLogin != null && m.hasOwnProperty("needLogin")) {
                d.needLogin = m.needLogin;
            }
            if (m.directHome != null && m.hasOwnProperty("directHome")) {
                d.directHome = m.directHome;
            }
            if (m.registerAlpha != null && m.hasOwnProperty("registerAlpha")) {
                d.registerAlpha = m.registerAlpha;
            }
            return d;
        };

        /**
         * Converts this AppNeedLogin to JSON.
         * @function toJSON
         * @memberof Proto.AppNeedLogin
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppNeedLogin.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppNeedLogin;
    })();

    Proto.TermsVersionResponse = (function() {

        /**
         * Properties of a TermsVersionResponse.
         * @memberof Proto
         * @interface ITermsVersionResponse
         * @property {number|null} [required] TermsVersionResponse required
         * @property {number|null} [current] TermsVersionResponse current
         * @property {number|Long|null} [requiredDate] TermsVersionResponse requiredDate
         */

        /**
         * Constructs a new TermsVersionResponse.
         * @memberof Proto
         * @classdesc Represents a TermsVersionResponse.
         * @implements ITermsVersionResponse
         * @constructor
         * @param {Proto.ITermsVersionResponse=} [p] Properties to set
         */
        function TermsVersionResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TermsVersionResponse required.
         * @member {number} required
         * @memberof Proto.TermsVersionResponse
         * @instance
         */
        TermsVersionResponse.prototype.required = 0;

        /**
         * TermsVersionResponse current.
         * @member {number} current
         * @memberof Proto.TermsVersionResponse
         * @instance
         */
        TermsVersionResponse.prototype.current = 0;

        /**
         * TermsVersionResponse requiredDate.
         * @member {number|Long} requiredDate
         * @memberof Proto.TermsVersionResponse
         * @instance
         */
        TermsVersionResponse.prototype.requiredDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new TermsVersionResponse instance using the specified properties.
         * @function create
         * @memberof Proto.TermsVersionResponse
         * @static
         * @param {Proto.ITermsVersionResponse=} [properties] Properties to set
         * @returns {Proto.TermsVersionResponse} TermsVersionResponse instance
         */
        TermsVersionResponse.create = function create(properties) {
            return new TermsVersionResponse(properties);
        };

        /**
         * Encodes the specified TermsVersionResponse message. Does not implicitly {@link Proto.TermsVersionResponse.verify|verify} messages.
         * @function encode
         * @memberof Proto.TermsVersionResponse
         * @static
         * @param {Proto.ITermsVersionResponse} m TermsVersionResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TermsVersionResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.required != null && Object.hasOwnProperty.call(m, "required"))
                w.uint32(8).int32(m.required);
            if (m.current != null && Object.hasOwnProperty.call(m, "current"))
                w.uint32(16).int32(m.current);
            if (m.requiredDate != null && Object.hasOwnProperty.call(m, "requiredDate"))
                w.uint32(24).int64(m.requiredDate);
            return w;
        };

        /**
         * Decodes a TermsVersionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TermsVersionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TermsVersionResponse} TermsVersionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TermsVersionResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TermsVersionResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.required = r.int32();
                    break;
                case 2:
                    m.current = r.int32();
                    break;
                case 3:
                    m.requiredDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TermsVersionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TermsVersionResponse
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TermsVersionResponse} TermsVersionResponse
         */
        TermsVersionResponse.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TermsVersionResponse)
                return d;
            var m = new $root.Proto.TermsVersionResponse();
            if (d.required != null) {
                m.required = d.required | 0;
            }
            if (d.current != null) {
                m.current = d.current | 0;
            }
            if (d.requiredDate != null) {
                if ($util.Long)
                    (m.requiredDate = $util.Long.fromValue(d.requiredDate)).unsigned = false;
                else if (typeof d.requiredDate === "string")
                    m.requiredDate = parseInt(d.requiredDate, 10);
                else if (typeof d.requiredDate === "number")
                    m.requiredDate = d.requiredDate;
                else if (typeof d.requiredDate === "object")
                    m.requiredDate = new $util.LongBits(d.requiredDate.low >>> 0, d.requiredDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a TermsVersionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TermsVersionResponse
         * @static
         * @param {Proto.TermsVersionResponse} m TermsVersionResponse
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TermsVersionResponse.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.required = 0;
                d.current = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.requiredDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.requiredDate = o.longs === String ? "0" : 0;
            }
            if (m.required != null && m.hasOwnProperty("required")) {
                d.required = m.required;
            }
            if (m.current != null && m.hasOwnProperty("current")) {
                d.current = m.current;
            }
            if (m.requiredDate != null && m.hasOwnProperty("requiredDate")) {
                if (typeof m.requiredDate === "number")
                    d.requiredDate = o.longs === String ? String(m.requiredDate) : m.requiredDate;
                else
                    d.requiredDate = o.longs === String ? $util.Long.prototype.toString.call(m.requiredDate) : o.longs === Number ? new $util.LongBits(m.requiredDate.low >>> 0, m.requiredDate.high >>> 0).toNumber() : m.requiredDate;
            }
            return d;
        };

        /**
         * Converts this TermsVersionResponse to JSON.
         * @function toJSON
         * @memberof Proto.TermsVersionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TermsVersionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TermsVersionResponse;
    })();

    Proto.Device = (function() {

        /**
         * Properties of a Device.
         * @memberof Proto
         * @interface IDevice
         * @property {string|null} [uid] Device uid
         * @property {number|null} [deviceType] Device deviceType
         * @property {number|null} [osType] Device osType
         * @property {number|null} [viewerType] Device viewerType
         * @property {string|null} [osVersion] Device osVersion
         * @property {string|null} [deviceVersion] Device deviceVersion
         * @property {number|Long|null} [registerDate] Device registerDate
         * @property {number|Long|null} [modifyDate] Device modifyDate
         */

        /**
         * Constructs a new Device.
         * @memberof Proto
         * @classdesc Represents a Device.
         * @implements IDevice
         * @constructor
         * @param {Proto.IDevice=} [p] Properties to set
         */
        function Device(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Device uid.
         * @member {string} uid
         * @memberof Proto.Device
         * @instance
         */
        Device.prototype.uid = "";

        /**
         * Device deviceType.
         * @member {number} deviceType
         * @memberof Proto.Device
         * @instance
         */
        Device.prototype.deviceType = 0;

        /**
         * Device osType.
         * @member {number} osType
         * @memberof Proto.Device
         * @instance
         */
        Device.prototype.osType = 0;

        /**
         * Device viewerType.
         * @member {number} viewerType
         * @memberof Proto.Device
         * @instance
         */
        Device.prototype.viewerType = 0;

        /**
         * Device osVersion.
         * @member {string} osVersion
         * @memberof Proto.Device
         * @instance
         */
        Device.prototype.osVersion = "";

        /**
         * Device deviceVersion.
         * @member {string} deviceVersion
         * @memberof Proto.Device
         * @instance
         */
        Device.prototype.deviceVersion = "";

        /**
         * Device registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.Device
         * @instance
         */
        Device.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Device modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.Device
         * @instance
         */
        Device.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Device instance using the specified properties.
         * @function create
         * @memberof Proto.Device
         * @static
         * @param {Proto.IDevice=} [properties] Properties to set
         * @returns {Proto.Device} Device instance
         */
        Device.create = function create(properties) {
            return new Device(properties);
        };

        /**
         * Encodes the specified Device message. Does not implicitly {@link Proto.Device.verify|verify} messages.
         * @function encode
         * @memberof Proto.Device
         * @static
         * @param {Proto.IDevice} m Device message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Device.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.deviceType != null && Object.hasOwnProperty.call(m, "deviceType"))
                w.uint32(16).int32(m.deviceType);
            if (m.osType != null && Object.hasOwnProperty.call(m, "osType"))
                w.uint32(24).int32(m.osType);
            if (m.viewerType != null && Object.hasOwnProperty.call(m, "viewerType"))
                w.uint32(32).int32(m.viewerType);
            if (m.osVersion != null && Object.hasOwnProperty.call(m, "osVersion"))
                w.uint32(42).string(m.osVersion);
            if (m.deviceVersion != null && Object.hasOwnProperty.call(m, "deviceVersion"))
                w.uint32(50).string(m.deviceVersion);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(56).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(64).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a Device message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.Device
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.Device} Device
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Device.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.Device();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.deviceType = r.int32();
                    break;
                case 3:
                    m.osType = r.int32();
                    break;
                case 4:
                    m.viewerType = r.int32();
                    break;
                case 5:
                    m.osVersion = r.string();
                    break;
                case 6:
                    m.deviceVersion = r.string();
                    break;
                case 7:
                    m.registerDate = r.int64();
                    break;
                case 8:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Device message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.Device
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.Device} Device
         */
        Device.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.Device)
                return d;
            var m = new $root.Proto.Device();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.deviceType != null) {
                m.deviceType = d.deviceType | 0;
            }
            if (d.osType != null) {
                m.osType = d.osType | 0;
            }
            if (d.viewerType != null) {
                m.viewerType = d.viewerType | 0;
            }
            if (d.osVersion != null) {
                m.osVersion = String(d.osVersion);
            }
            if (d.deviceVersion != null) {
                m.deviceVersion = String(d.deviceVersion);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a Device message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.Device
         * @static
         * @param {Proto.Device} m Device
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Device.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.deviceType = 0;
                d.osType = 0;
                d.viewerType = 0;
                d.osVersion = "";
                d.deviceVersion = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.deviceType != null && m.hasOwnProperty("deviceType")) {
                d.deviceType = m.deviceType;
            }
            if (m.osType != null && m.hasOwnProperty("osType")) {
                d.osType = m.osType;
            }
            if (m.viewerType != null && m.hasOwnProperty("viewerType")) {
                d.viewerType = m.viewerType;
            }
            if (m.osVersion != null && m.hasOwnProperty("osVersion")) {
                d.osVersion = m.osVersion;
            }
            if (m.deviceVersion != null && m.hasOwnProperty("deviceVersion")) {
                d.deviceVersion = m.deviceVersion;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this Device to JSON.
         * @function toJSON
         * @memberof Proto.Device
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Device.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Device;
    })();

    Proto.ChatUserDetail = (function() {

        /**
         * Properties of a ChatUserDetail.
         * @memberof Proto
         * @interface IChatUserDetail
         * @property {string|null} [chatId] ChatUserDetail chatId
         * @property {string|null} [iconUrl] ChatUserDetail iconUrl
         * @property {string|null} [username] ChatUserDetail username
         * @property {string|null} [planName] ChatUserDetail planName
         * @property {number|null} [planCount] ChatUserDetail planCount
         * @property {number|Long|null} [planRegisterDate] ChatUserDetail planRegisterDate
         * @property {number|Long|null} [registerDate] ChatUserDetail registerDate
         * @property {number|Long|null} [modifyDate] ChatUserDetail modifyDate
         * @property {string|null} [profile] ChatUserDetail profile
         * @property {boolean|null} [me] ChatUserDetail me
         * @property {string|null} [userUID] ChatUserDetail userUID
         */

        /**
         * Constructs a new ChatUserDetail.
         * @memberof Proto
         * @classdesc Represents a ChatUserDetail.
         * @implements IChatUserDetail
         * @constructor
         * @param {Proto.IChatUserDetail=} [p] Properties to set
         */
        function ChatUserDetail(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ChatUserDetail chatId.
         * @member {string} chatId
         * @memberof Proto.ChatUserDetail
         * @instance
         */
        ChatUserDetail.prototype.chatId = "";

        /**
         * ChatUserDetail iconUrl.
         * @member {string} iconUrl
         * @memberof Proto.ChatUserDetail
         * @instance
         */
        ChatUserDetail.prototype.iconUrl = "";

        /**
         * ChatUserDetail username.
         * @member {string} username
         * @memberof Proto.ChatUserDetail
         * @instance
         */
        ChatUserDetail.prototype.username = "";

        /**
         * ChatUserDetail planName.
         * @member {string} planName
         * @memberof Proto.ChatUserDetail
         * @instance
         */
        ChatUserDetail.prototype.planName = "";

        /**
         * ChatUserDetail planCount.
         * @member {number} planCount
         * @memberof Proto.ChatUserDetail
         * @instance
         */
        ChatUserDetail.prototype.planCount = 0;

        /**
         * ChatUserDetail planRegisterDate.
         * @member {number|Long} planRegisterDate
         * @memberof Proto.ChatUserDetail
         * @instance
         */
        ChatUserDetail.prototype.planRegisterDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ChatUserDetail registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ChatUserDetail
         * @instance
         */
        ChatUserDetail.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ChatUserDetail modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ChatUserDetail
         * @instance
         */
        ChatUserDetail.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ChatUserDetail profile.
         * @member {string} profile
         * @memberof Proto.ChatUserDetail
         * @instance
         */
        ChatUserDetail.prototype.profile = "";

        /**
         * ChatUserDetail me.
         * @member {boolean} me
         * @memberof Proto.ChatUserDetail
         * @instance
         */
        ChatUserDetail.prototype.me = false;

        /**
         * ChatUserDetail userUID.
         * @member {string} userUID
         * @memberof Proto.ChatUserDetail
         * @instance
         */
        ChatUserDetail.prototype.userUID = "";

        /**
         * Creates a new ChatUserDetail instance using the specified properties.
         * @function create
         * @memberof Proto.ChatUserDetail
         * @static
         * @param {Proto.IChatUserDetail=} [properties] Properties to set
         * @returns {Proto.ChatUserDetail} ChatUserDetail instance
         */
        ChatUserDetail.create = function create(properties) {
            return new ChatUserDetail(properties);
        };

        /**
         * Encodes the specified ChatUserDetail message. Does not implicitly {@link Proto.ChatUserDetail.verify|verify} messages.
         * @function encode
         * @memberof Proto.ChatUserDetail
         * @static
         * @param {Proto.IChatUserDetail} m ChatUserDetail message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatUserDetail.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.chatId != null && Object.hasOwnProperty.call(m, "chatId"))
                w.uint32(10).string(m.chatId);
            if (m.iconUrl != null && Object.hasOwnProperty.call(m, "iconUrl"))
                w.uint32(18).string(m.iconUrl);
            if (m.username != null && Object.hasOwnProperty.call(m, "username"))
                w.uint32(26).string(m.username);
            if (m.planName != null && Object.hasOwnProperty.call(m, "planName"))
                w.uint32(34).string(m.planName);
            if (m.planCount != null && Object.hasOwnProperty.call(m, "planCount"))
                w.uint32(40).int32(m.planCount);
            if (m.planRegisterDate != null && Object.hasOwnProperty.call(m, "planRegisterDate"))
                w.uint32(48).int64(m.planRegisterDate);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(56).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(64).int64(m.modifyDate);
            if (m.profile != null && Object.hasOwnProperty.call(m, "profile"))
                w.uint32(74).string(m.profile);
            if (m.me != null && Object.hasOwnProperty.call(m, "me"))
                w.uint32(80).bool(m.me);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(90).string(m.userUID);
            return w;
        };

        /**
         * Decodes a ChatUserDetail message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ChatUserDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ChatUserDetail} ChatUserDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatUserDetail.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ChatUserDetail();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.chatId = r.string();
                    break;
                case 2:
                    m.iconUrl = r.string();
                    break;
                case 3:
                    m.username = r.string();
                    break;
                case 4:
                    m.planName = r.string();
                    break;
                case 5:
                    m.planCount = r.int32();
                    break;
                case 6:
                    m.planRegisterDate = r.int64();
                    break;
                case 7:
                    m.registerDate = r.int64();
                    break;
                case 8:
                    m.modifyDate = r.int64();
                    break;
                case 9:
                    m.profile = r.string();
                    break;
                case 10:
                    m.me = r.bool();
                    break;
                case 11:
                    m.userUID = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ChatUserDetail message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ChatUserDetail
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ChatUserDetail} ChatUserDetail
         */
        ChatUserDetail.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ChatUserDetail)
                return d;
            var m = new $root.Proto.ChatUserDetail();
            if (d.chatId != null) {
                m.chatId = String(d.chatId);
            }
            if (d.iconUrl != null) {
                m.iconUrl = String(d.iconUrl);
            }
            if (d.username != null) {
                m.username = String(d.username);
            }
            if (d.planName != null) {
                m.planName = String(d.planName);
            }
            if (d.planCount != null) {
                m.planCount = d.planCount | 0;
            }
            if (d.planRegisterDate != null) {
                if ($util.Long)
                    (m.planRegisterDate = $util.Long.fromValue(d.planRegisterDate)).unsigned = false;
                else if (typeof d.planRegisterDate === "string")
                    m.planRegisterDate = parseInt(d.planRegisterDate, 10);
                else if (typeof d.planRegisterDate === "number")
                    m.planRegisterDate = d.planRegisterDate;
                else if (typeof d.planRegisterDate === "object")
                    m.planRegisterDate = new $util.LongBits(d.planRegisterDate.low >>> 0, d.planRegisterDate.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.profile != null) {
                m.profile = String(d.profile);
            }
            if (d.me != null) {
                m.me = Boolean(d.me);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            return m;
        };

        /**
         * Creates a plain object from a ChatUserDetail message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ChatUserDetail
         * @static
         * @param {Proto.ChatUserDetail} m ChatUserDetail
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChatUserDetail.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.chatId = "";
                d.iconUrl = "";
                d.username = "";
                d.planName = "";
                d.planCount = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.planRegisterDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.planRegisterDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.profile = "";
                d.me = false;
                d.userUID = "";
            }
            if (m.chatId != null && m.hasOwnProperty("chatId")) {
                d.chatId = m.chatId;
            }
            if (m.iconUrl != null && m.hasOwnProperty("iconUrl")) {
                d.iconUrl = m.iconUrl;
            }
            if (m.username != null && m.hasOwnProperty("username")) {
                d.username = m.username;
            }
            if (m.planName != null && m.hasOwnProperty("planName")) {
                d.planName = m.planName;
            }
            if (m.planCount != null && m.hasOwnProperty("planCount")) {
                d.planCount = m.planCount;
            }
            if (m.planRegisterDate != null && m.hasOwnProperty("planRegisterDate")) {
                if (typeof m.planRegisterDate === "number")
                    d.planRegisterDate = o.longs === String ? String(m.planRegisterDate) : m.planRegisterDate;
                else
                    d.planRegisterDate = o.longs === String ? $util.Long.prototype.toString.call(m.planRegisterDate) : o.longs === Number ? new $util.LongBits(m.planRegisterDate.low >>> 0, m.planRegisterDate.high >>> 0).toNumber() : m.planRegisterDate;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.profile != null && m.hasOwnProperty("profile")) {
                d.profile = m.profile;
            }
            if (m.me != null && m.hasOwnProperty("me")) {
                d.me = m.me;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            return d;
        };

        /**
         * Converts this ChatUserDetail to JSON.
         * @function toJSON
         * @memberof Proto.ChatUserDetail
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChatUserDetail.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChatUserDetail;
    })();

    Proto.ChatroomInfo = (function() {

        /**
         * Properties of a ChatroomInfo.
         * @memberof Proto
         * @interface IChatroomInfo
         * @property {string|null} [like] ChatroomInfo like
         * @property {string|null} [user] ChatroomInfo user
         * @property {string|null} [chat] ChatroomInfo chat
         */

        /**
         * Constructs a new ChatroomInfo.
         * @memberof Proto
         * @classdesc Represents a ChatroomInfo.
         * @implements IChatroomInfo
         * @constructor
         * @param {Proto.IChatroomInfo=} [p] Properties to set
         */
        function ChatroomInfo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ChatroomInfo like.
         * @member {string} like
         * @memberof Proto.ChatroomInfo
         * @instance
         */
        ChatroomInfo.prototype.like = "";

        /**
         * ChatroomInfo user.
         * @member {string} user
         * @memberof Proto.ChatroomInfo
         * @instance
         */
        ChatroomInfo.prototype.user = "";

        /**
         * ChatroomInfo chat.
         * @member {string} chat
         * @memberof Proto.ChatroomInfo
         * @instance
         */
        ChatroomInfo.prototype.chat = "";

        /**
         * Creates a new ChatroomInfo instance using the specified properties.
         * @function create
         * @memberof Proto.ChatroomInfo
         * @static
         * @param {Proto.IChatroomInfo=} [properties] Properties to set
         * @returns {Proto.ChatroomInfo} ChatroomInfo instance
         */
        ChatroomInfo.create = function create(properties) {
            return new ChatroomInfo(properties);
        };

        /**
         * Encodes the specified ChatroomInfo message. Does not implicitly {@link Proto.ChatroomInfo.verify|verify} messages.
         * @function encode
         * @memberof Proto.ChatroomInfo
         * @static
         * @param {Proto.IChatroomInfo} m ChatroomInfo message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatroomInfo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.like != null && Object.hasOwnProperty.call(m, "like"))
                w.uint32(10).string(m.like);
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                w.uint32(18).string(m.user);
            if (m.chat != null && Object.hasOwnProperty.call(m, "chat"))
                w.uint32(26).string(m.chat);
            return w;
        };

        /**
         * Decodes a ChatroomInfo message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ChatroomInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ChatroomInfo} ChatroomInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatroomInfo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ChatroomInfo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.like = r.string();
                    break;
                case 2:
                    m.user = r.string();
                    break;
                case 3:
                    m.chat = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ChatroomInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ChatroomInfo
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ChatroomInfo} ChatroomInfo
         */
        ChatroomInfo.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ChatroomInfo)
                return d;
            var m = new $root.Proto.ChatroomInfo();
            if (d.like != null) {
                m.like = String(d.like);
            }
            if (d.user != null) {
                m.user = String(d.user);
            }
            if (d.chat != null) {
                m.chat = String(d.chat);
            }
            return m;
        };

        /**
         * Creates a plain object from a ChatroomInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ChatroomInfo
         * @static
         * @param {Proto.ChatroomInfo} m ChatroomInfo
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChatroomInfo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.like = "";
                d.user = "";
                d.chat = "";
            }
            if (m.like != null && m.hasOwnProperty("like")) {
                d.like = m.like;
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = m.user;
            }
            if (m.chat != null && m.hasOwnProperty("chat")) {
                d.chat = m.chat;
            }
            return d;
        };

        /**
         * Converts this ChatroomInfo to JSON.
         * @function toJSON
         * @memberof Proto.ChatroomInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChatroomInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChatroomInfo;
    })();

    Proto.LiveUser = (function() {

        /**
         * Properties of a LiveUser.
         * @memberof Proto
         * @interface ILiveUser
         * @property {Proto.IUser|null} [user] LiveUser user
         * @property {string|null} [like] LiveUser like
         * @property {string|null} [chat] LiveUser chat
         */

        /**
         * Constructs a new LiveUser.
         * @memberof Proto
         * @classdesc Represents a LiveUser.
         * @implements ILiveUser
         * @constructor
         * @param {Proto.ILiveUser=} [p] Properties to set
         */
        function LiveUser(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * LiveUser user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.LiveUser
         * @instance
         */
        LiveUser.prototype.user = null;

        /**
         * LiveUser like.
         * @member {string} like
         * @memberof Proto.LiveUser
         * @instance
         */
        LiveUser.prototype.like = "";

        /**
         * LiveUser chat.
         * @member {string} chat
         * @memberof Proto.LiveUser
         * @instance
         */
        LiveUser.prototype.chat = "";

        /**
         * Creates a new LiveUser instance using the specified properties.
         * @function create
         * @memberof Proto.LiveUser
         * @static
         * @param {Proto.ILiveUser=} [properties] Properties to set
         * @returns {Proto.LiveUser} LiveUser instance
         */
        LiveUser.create = function create(properties) {
            return new LiveUser(properties);
        };

        /**
         * Encodes the specified LiveUser message. Does not implicitly {@link Proto.LiveUser.verify|verify} messages.
         * @function encode
         * @memberof Proto.LiveUser
         * @static
         * @param {Proto.ILiveUser} m LiveUser message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LiveUser.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(10).fork()).ldelim();
            if (m.like != null && Object.hasOwnProperty.call(m, "like"))
                w.uint32(18).string(m.like);
            if (m.chat != null && Object.hasOwnProperty.call(m, "chat"))
                w.uint32(26).string(m.chat);
            return w;
        };

        /**
         * Decodes a LiveUser message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.LiveUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.LiveUser} LiveUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LiveUser.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.LiveUser();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 2:
                    m.like = r.string();
                    break;
                case 3:
                    m.chat = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a LiveUser message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.LiveUser
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.LiveUser} LiveUser
         */
        LiveUser.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.LiveUser)
                return d;
            var m = new $root.Proto.LiveUser();
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.LiveUser.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            if (d.like != null) {
                m.like = String(d.like);
            }
            if (d.chat != null) {
                m.chat = String(d.chat);
            }
            return m;
        };

        /**
         * Creates a plain object from a LiveUser message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.LiveUser
         * @static
         * @param {Proto.LiveUser} m LiveUser
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LiveUser.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.user = null;
                d.like = "";
                d.chat = "";
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            if (m.like != null && m.hasOwnProperty("like")) {
                d.like = m.like;
            }
            if (m.chat != null && m.hasOwnProperty("chat")) {
                d.chat = m.chat;
            }
            return d;
        };

        /**
         * Converts this LiveUser to JSON.
         * @function toJSON
         * @memberof Proto.LiveUser
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LiveUser.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LiveUser;
    })();

    Proto.LiveUserList = (function() {

        /**
         * Properties of a LiveUserList.
         * @memberof Proto
         * @interface ILiveUserList
         * @property {Array.<Proto.ILiveUser>|null} [value] LiveUserList value
         */

        /**
         * Constructs a new LiveUserList.
         * @memberof Proto
         * @classdesc Represents a LiveUserList.
         * @implements ILiveUserList
         * @constructor
         * @param {Proto.ILiveUserList=} [p] Properties to set
         */
        function LiveUserList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * LiveUserList value.
         * @member {Array.<Proto.ILiveUser>} value
         * @memberof Proto.LiveUserList
         * @instance
         */
        LiveUserList.prototype.value = $util.emptyArray;

        /**
         * Creates a new LiveUserList instance using the specified properties.
         * @function create
         * @memberof Proto.LiveUserList
         * @static
         * @param {Proto.ILiveUserList=} [properties] Properties to set
         * @returns {Proto.LiveUserList} LiveUserList instance
         */
        LiveUserList.create = function create(properties) {
            return new LiveUserList(properties);
        };

        /**
         * Encodes the specified LiveUserList message. Does not implicitly {@link Proto.LiveUserList.verify|verify} messages.
         * @function encode
         * @memberof Proto.LiveUserList
         * @static
         * @param {Proto.ILiveUserList} m LiveUserList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LiveUserList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.LiveUser.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a LiveUserList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.LiveUserList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.LiveUserList} LiveUserList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LiveUserList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.LiveUserList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.LiveUser.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a LiveUserList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.LiveUserList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.LiveUserList} LiveUserList
         */
        LiveUserList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.LiveUserList)
                return d;
            var m = new $root.Proto.LiveUserList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.LiveUserList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.LiveUserList.value: object expected");
                    m.value[i] = $root.Proto.LiveUser.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a LiveUserList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.LiveUserList
         * @static
         * @param {Proto.LiveUserList} m LiveUserList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LiveUserList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.LiveUser.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this LiveUserList to JSON.
         * @function toJSON
         * @memberof Proto.LiveUserList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LiveUserList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LiveUserList;
    })();

    Proto.SubscriptionPlanResponse = (function() {

        /**
         * Properties of a SubscriptionPlanResponse.
         * @memberof Proto
         * @interface ISubscriptionPlanResponse
         * @property {string|null} [planUID] SubscriptionPlanResponse planUID
         * @property {string|null} [name] SubscriptionPlanResponse name
         */

        /**
         * Constructs a new SubscriptionPlanResponse.
         * @memberof Proto
         * @classdesc Represents a SubscriptionPlanResponse.
         * @implements ISubscriptionPlanResponse
         * @constructor
         * @param {Proto.ISubscriptionPlanResponse=} [p] Properties to set
         */
        function SubscriptionPlanResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * SubscriptionPlanResponse planUID.
         * @member {string} planUID
         * @memberof Proto.SubscriptionPlanResponse
         * @instance
         */
        SubscriptionPlanResponse.prototype.planUID = "";

        /**
         * SubscriptionPlanResponse name.
         * @member {string} name
         * @memberof Proto.SubscriptionPlanResponse
         * @instance
         */
        SubscriptionPlanResponse.prototype.name = "";

        /**
         * Creates a new SubscriptionPlanResponse instance using the specified properties.
         * @function create
         * @memberof Proto.SubscriptionPlanResponse
         * @static
         * @param {Proto.ISubscriptionPlanResponse=} [properties] Properties to set
         * @returns {Proto.SubscriptionPlanResponse} SubscriptionPlanResponse instance
         */
        SubscriptionPlanResponse.create = function create(properties) {
            return new SubscriptionPlanResponse(properties);
        };

        /**
         * Encodes the specified SubscriptionPlanResponse message. Does not implicitly {@link Proto.SubscriptionPlanResponse.verify|verify} messages.
         * @function encode
         * @memberof Proto.SubscriptionPlanResponse
         * @static
         * @param {Proto.ISubscriptionPlanResponse} m SubscriptionPlanResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubscriptionPlanResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.planUID != null && Object.hasOwnProperty.call(m, "planUID"))
                w.uint32(10).string(m.planUID);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            return w;
        };

        /**
         * Decodes a SubscriptionPlanResponse message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.SubscriptionPlanResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.SubscriptionPlanResponse} SubscriptionPlanResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubscriptionPlanResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.SubscriptionPlanResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.planUID = r.string();
                    break;
                case 2:
                    m.name = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a SubscriptionPlanResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.SubscriptionPlanResponse
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.SubscriptionPlanResponse} SubscriptionPlanResponse
         */
        SubscriptionPlanResponse.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.SubscriptionPlanResponse)
                return d;
            var m = new $root.Proto.SubscriptionPlanResponse();
            if (d.planUID != null) {
                m.planUID = String(d.planUID);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            return m;
        };

        /**
         * Creates a plain object from a SubscriptionPlanResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.SubscriptionPlanResponse
         * @static
         * @param {Proto.SubscriptionPlanResponse} m SubscriptionPlanResponse
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SubscriptionPlanResponse.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.planUID = "";
                d.name = "";
            }
            if (m.planUID != null && m.hasOwnProperty("planUID")) {
                d.planUID = m.planUID;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            return d;
        };

        /**
         * Converts this SubscriptionPlanResponse to JSON.
         * @function toJSON
         * @memberof Proto.SubscriptionPlanResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SubscriptionPlanResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SubscriptionPlanResponse;
    })();

    Proto.SubscriptionPlanResponseList = (function() {

        /**
         * Properties of a SubscriptionPlanResponseList.
         * @memberof Proto
         * @interface ISubscriptionPlanResponseList
         * @property {Array.<Proto.ISubscriptionPlanResponse>|null} [value] SubscriptionPlanResponseList value
         */

        /**
         * Constructs a new SubscriptionPlanResponseList.
         * @memberof Proto
         * @classdesc Represents a SubscriptionPlanResponseList.
         * @implements ISubscriptionPlanResponseList
         * @constructor
         * @param {Proto.ISubscriptionPlanResponseList=} [p] Properties to set
         */
        function SubscriptionPlanResponseList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * SubscriptionPlanResponseList value.
         * @member {Array.<Proto.ISubscriptionPlanResponse>} value
         * @memberof Proto.SubscriptionPlanResponseList
         * @instance
         */
        SubscriptionPlanResponseList.prototype.value = $util.emptyArray;

        /**
         * Creates a new SubscriptionPlanResponseList instance using the specified properties.
         * @function create
         * @memberof Proto.SubscriptionPlanResponseList
         * @static
         * @param {Proto.ISubscriptionPlanResponseList=} [properties] Properties to set
         * @returns {Proto.SubscriptionPlanResponseList} SubscriptionPlanResponseList instance
         */
        SubscriptionPlanResponseList.create = function create(properties) {
            return new SubscriptionPlanResponseList(properties);
        };

        /**
         * Encodes the specified SubscriptionPlanResponseList message. Does not implicitly {@link Proto.SubscriptionPlanResponseList.verify|verify} messages.
         * @function encode
         * @memberof Proto.SubscriptionPlanResponseList
         * @static
         * @param {Proto.ISubscriptionPlanResponseList} m SubscriptionPlanResponseList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubscriptionPlanResponseList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.SubscriptionPlanResponse.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a SubscriptionPlanResponseList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.SubscriptionPlanResponseList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.SubscriptionPlanResponseList} SubscriptionPlanResponseList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubscriptionPlanResponseList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.SubscriptionPlanResponseList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.SubscriptionPlanResponse.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a SubscriptionPlanResponseList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.SubscriptionPlanResponseList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.SubscriptionPlanResponseList} SubscriptionPlanResponseList
         */
        SubscriptionPlanResponseList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.SubscriptionPlanResponseList)
                return d;
            var m = new $root.Proto.SubscriptionPlanResponseList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.SubscriptionPlanResponseList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.SubscriptionPlanResponseList.value: object expected");
                    m.value[i] = $root.Proto.SubscriptionPlanResponse.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a SubscriptionPlanResponseList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.SubscriptionPlanResponseList
         * @static
         * @param {Proto.SubscriptionPlanResponseList} m SubscriptionPlanResponseList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SubscriptionPlanResponseList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.SubscriptionPlanResponse.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this SubscriptionPlanResponseList to JSON.
         * @function toJSON
         * @memberof Proto.SubscriptionPlanResponseList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SubscriptionPlanResponseList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SubscriptionPlanResponseList;
    })();

    Proto.UserGroupResponse = (function() {

        /**
         * Properties of a UserGroupResponse.
         * @memberof Proto
         * @interface IUserGroupResponse
         * @property {string|null} [groupUID] UserGroupResponse groupUID
         * @property {string|null} [name] UserGroupResponse name
         */

        /**
         * Constructs a new UserGroupResponse.
         * @memberof Proto
         * @classdesc Represents a UserGroupResponse.
         * @implements IUserGroupResponse
         * @constructor
         * @param {Proto.IUserGroupResponse=} [p] Properties to set
         */
        function UserGroupResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserGroupResponse groupUID.
         * @member {string} groupUID
         * @memberof Proto.UserGroupResponse
         * @instance
         */
        UserGroupResponse.prototype.groupUID = "";

        /**
         * UserGroupResponse name.
         * @member {string} name
         * @memberof Proto.UserGroupResponse
         * @instance
         */
        UserGroupResponse.prototype.name = "";

        /**
         * Creates a new UserGroupResponse instance using the specified properties.
         * @function create
         * @memberof Proto.UserGroupResponse
         * @static
         * @param {Proto.IUserGroupResponse=} [properties] Properties to set
         * @returns {Proto.UserGroupResponse} UserGroupResponse instance
         */
        UserGroupResponse.create = function create(properties) {
            return new UserGroupResponse(properties);
        };

        /**
         * Encodes the specified UserGroupResponse message. Does not implicitly {@link Proto.UserGroupResponse.verify|verify} messages.
         * @function encode
         * @memberof Proto.UserGroupResponse
         * @static
         * @param {Proto.IUserGroupResponse} m UserGroupResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserGroupResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.groupUID != null && Object.hasOwnProperty.call(m, "groupUID"))
                w.uint32(10).string(m.groupUID);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            return w;
        };

        /**
         * Decodes a UserGroupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.UserGroupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.UserGroupResponse} UserGroupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserGroupResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.UserGroupResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.groupUID = r.string();
                    break;
                case 2:
                    m.name = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserGroupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.UserGroupResponse
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.UserGroupResponse} UserGroupResponse
         */
        UserGroupResponse.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.UserGroupResponse)
                return d;
            var m = new $root.Proto.UserGroupResponse();
            if (d.groupUID != null) {
                m.groupUID = String(d.groupUID);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            return m;
        };

        /**
         * Creates a plain object from a UserGroupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.UserGroupResponse
         * @static
         * @param {Proto.UserGroupResponse} m UserGroupResponse
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserGroupResponse.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.groupUID = "";
                d.name = "";
            }
            if (m.groupUID != null && m.hasOwnProperty("groupUID")) {
                d.groupUID = m.groupUID;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            return d;
        };

        /**
         * Converts this UserGroupResponse to JSON.
         * @function toJSON
         * @memberof Proto.UserGroupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserGroupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserGroupResponse;
    })();

    Proto.UserGroupResponseList = (function() {

        /**
         * Properties of a UserGroupResponseList.
         * @memberof Proto
         * @interface IUserGroupResponseList
         * @property {Array.<Proto.IUserGroupResponse>|null} [value] UserGroupResponseList value
         */

        /**
         * Constructs a new UserGroupResponseList.
         * @memberof Proto
         * @classdesc Represents a UserGroupResponseList.
         * @implements IUserGroupResponseList
         * @constructor
         * @param {Proto.IUserGroupResponseList=} [p] Properties to set
         */
        function UserGroupResponseList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * UserGroupResponseList value.
         * @member {Array.<Proto.IUserGroupResponse>} value
         * @memberof Proto.UserGroupResponseList
         * @instance
         */
        UserGroupResponseList.prototype.value = $util.emptyArray;

        /**
         * Creates a new UserGroupResponseList instance using the specified properties.
         * @function create
         * @memberof Proto.UserGroupResponseList
         * @static
         * @param {Proto.IUserGroupResponseList=} [properties] Properties to set
         * @returns {Proto.UserGroupResponseList} UserGroupResponseList instance
         */
        UserGroupResponseList.create = function create(properties) {
            return new UserGroupResponseList(properties);
        };

        /**
         * Encodes the specified UserGroupResponseList message. Does not implicitly {@link Proto.UserGroupResponseList.verify|verify} messages.
         * @function encode
         * @memberof Proto.UserGroupResponseList
         * @static
         * @param {Proto.IUserGroupResponseList} m UserGroupResponseList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserGroupResponseList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.UserGroupResponse.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a UserGroupResponseList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.UserGroupResponseList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.UserGroupResponseList} UserGroupResponseList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserGroupResponseList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.UserGroupResponseList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.UserGroupResponse.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a UserGroupResponseList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.UserGroupResponseList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.UserGroupResponseList} UserGroupResponseList
         */
        UserGroupResponseList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.UserGroupResponseList)
                return d;
            var m = new $root.Proto.UserGroupResponseList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.UserGroupResponseList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.UserGroupResponseList.value: object expected");
                    m.value[i] = $root.Proto.UserGroupResponse.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a UserGroupResponseList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.UserGroupResponseList
         * @static
         * @param {Proto.UserGroupResponseList} m UserGroupResponseList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserGroupResponseList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.UserGroupResponse.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this UserGroupResponseList to JSON.
         * @function toJSON
         * @memberof Proto.UserGroupResponseList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserGroupResponseList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserGroupResponseList;
    })();

    Proto.OGPResponse = (function() {

        /**
         * Properties of a OGPResponse.
         * @memberof Proto
         * @interface IOGPResponse
         * @property {string|null} [url] OGPResponse url
         * @property {string|null} [type] OGPResponse type
         * @property {string|null} [imageUrl] OGPResponse imageUrl
         * @property {string|null} [siteName] OGPResponse siteName
         * @property {string|null} [title] OGPResponse title
         * @property {string|null} [description] OGPResponse description
         */

        /**
         * Constructs a new OGPResponse.
         * @memberof Proto
         * @classdesc Represents a OGPResponse.
         * @implements IOGPResponse
         * @constructor
         * @param {Proto.IOGPResponse=} [p] Properties to set
         */
        function OGPResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * OGPResponse url.
         * @member {string} url
         * @memberof Proto.OGPResponse
         * @instance
         */
        OGPResponse.prototype.url = "";

        /**
         * OGPResponse type.
         * @member {string} type
         * @memberof Proto.OGPResponse
         * @instance
         */
        OGPResponse.prototype.type = "";

        /**
         * OGPResponse imageUrl.
         * @member {string} imageUrl
         * @memberof Proto.OGPResponse
         * @instance
         */
        OGPResponse.prototype.imageUrl = "";

        /**
         * OGPResponse siteName.
         * @member {string} siteName
         * @memberof Proto.OGPResponse
         * @instance
         */
        OGPResponse.prototype.siteName = "";

        /**
         * OGPResponse title.
         * @member {string} title
         * @memberof Proto.OGPResponse
         * @instance
         */
        OGPResponse.prototype.title = "";

        /**
         * OGPResponse description.
         * @member {string} description
         * @memberof Proto.OGPResponse
         * @instance
         */
        OGPResponse.prototype.description = "";

        /**
         * Creates a new OGPResponse instance using the specified properties.
         * @function create
         * @memberof Proto.OGPResponse
         * @static
         * @param {Proto.IOGPResponse=} [properties] Properties to set
         * @returns {Proto.OGPResponse} OGPResponse instance
         */
        OGPResponse.create = function create(properties) {
            return new OGPResponse(properties);
        };

        /**
         * Encodes the specified OGPResponse message. Does not implicitly {@link Proto.OGPResponse.verify|verify} messages.
         * @function encode
         * @memberof Proto.OGPResponse
         * @static
         * @param {Proto.IOGPResponse} m OGPResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OGPResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                w.uint32(10).string(m.url);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(18).string(m.type);
            if (m.imageUrl != null && Object.hasOwnProperty.call(m, "imageUrl"))
                w.uint32(26).string(m.imageUrl);
            if (m.siteName != null && Object.hasOwnProperty.call(m, "siteName"))
                w.uint32(34).string(m.siteName);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(42).string(m.title);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(50).string(m.description);
            return w;
        };

        /**
         * Decodes a OGPResponse message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.OGPResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.OGPResponse} OGPResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OGPResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.OGPResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.url = r.string();
                    break;
                case 2:
                    m.type = r.string();
                    break;
                case 3:
                    m.imageUrl = r.string();
                    break;
                case 4:
                    m.siteName = r.string();
                    break;
                case 5:
                    m.title = r.string();
                    break;
                case 6:
                    m.description = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a OGPResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.OGPResponse
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.OGPResponse} OGPResponse
         */
        OGPResponse.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.OGPResponse)
                return d;
            var m = new $root.Proto.OGPResponse();
            if (d.url != null) {
                m.url = String(d.url);
            }
            if (d.type != null) {
                m.type = String(d.type);
            }
            if (d.imageUrl != null) {
                m.imageUrl = String(d.imageUrl);
            }
            if (d.siteName != null) {
                m.siteName = String(d.siteName);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            return m;
        };

        /**
         * Creates a plain object from a OGPResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.OGPResponse
         * @static
         * @param {Proto.OGPResponse} m OGPResponse
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OGPResponse.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.url = "";
                d.type = "";
                d.imageUrl = "";
                d.siteName = "";
                d.title = "";
                d.description = "";
            }
            if (m.url != null && m.hasOwnProperty("url")) {
                d.url = m.url;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.imageUrl != null && m.hasOwnProperty("imageUrl")) {
                d.imageUrl = m.imageUrl;
            }
            if (m.siteName != null && m.hasOwnProperty("siteName")) {
                d.siteName = m.siteName;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            return d;
        };

        /**
         * Converts this OGPResponse to JSON.
         * @function toJSON
         * @memberof Proto.OGPResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OGPResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OGPResponse;
    })();

    Proto.ContentConvertRequest = (function() {

        /**
         * Properties of a ContentConvertRequest.
         * @memberof Proto
         * @interface IContentConvertRequest
         * @property {Array.<string>|null} [uid] ContentConvertRequest uid
         * @property {Array.<string>|null} [thumbnailUID] ContentConvertRequest thumbnailUID
         * @property {Array.<string>|null} [additionalUID] ContentConvertRequest additionalUID
         * @property {boolean|null} [disableConvert] ContentConvertRequest disableConvert
         */

        /**
         * Constructs a new ContentConvertRequest.
         * @memberof Proto
         * @classdesc Represents a ContentConvertRequest.
         * @implements IContentConvertRequest
         * @constructor
         * @param {Proto.IContentConvertRequest=} [p] Properties to set
         */
        function ContentConvertRequest(p) {
            this.uid = [];
            this.thumbnailUID = [];
            this.additionalUID = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentConvertRequest uid.
         * @member {Array.<string>} uid
         * @memberof Proto.ContentConvertRequest
         * @instance
         */
        ContentConvertRequest.prototype.uid = $util.emptyArray;

        /**
         * ContentConvertRequest thumbnailUID.
         * @member {Array.<string>} thumbnailUID
         * @memberof Proto.ContentConvertRequest
         * @instance
         */
        ContentConvertRequest.prototype.thumbnailUID = $util.emptyArray;

        /**
         * ContentConvertRequest additionalUID.
         * @member {Array.<string>} additionalUID
         * @memberof Proto.ContentConvertRequest
         * @instance
         */
        ContentConvertRequest.prototype.additionalUID = $util.emptyArray;

        /**
         * ContentConvertRequest disableConvert.
         * @member {boolean} disableConvert
         * @memberof Proto.ContentConvertRequest
         * @instance
         */
        ContentConvertRequest.prototype.disableConvert = false;

        /**
         * Creates a new ContentConvertRequest instance using the specified properties.
         * @function create
         * @memberof Proto.ContentConvertRequest
         * @static
         * @param {Proto.IContentConvertRequest=} [properties] Properties to set
         * @returns {Proto.ContentConvertRequest} ContentConvertRequest instance
         */
        ContentConvertRequest.create = function create(properties) {
            return new ContentConvertRequest(properties);
        };

        /**
         * Encodes the specified ContentConvertRequest message. Does not implicitly {@link Proto.ContentConvertRequest.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentConvertRequest
         * @static
         * @param {Proto.IContentConvertRequest} m ContentConvertRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentConvertRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && m.uid.length) {
                for (var i = 0; i < m.uid.length; ++i)
                    w.uint32(10).string(m.uid[i]);
            }
            if (m.thumbnailUID != null && m.thumbnailUID.length) {
                for (var i = 0; i < m.thumbnailUID.length; ++i)
                    w.uint32(18).string(m.thumbnailUID[i]);
            }
            if (m.additionalUID != null && m.additionalUID.length) {
                for (var i = 0; i < m.additionalUID.length; ++i)
                    w.uint32(26).string(m.additionalUID[i]);
            }
            if (m.disableConvert != null && Object.hasOwnProperty.call(m, "disableConvert"))
                w.uint32(32).bool(m.disableConvert);
            return w;
        };

        /**
         * Decodes a ContentConvertRequest message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentConvertRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentConvertRequest} ContentConvertRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentConvertRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentConvertRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.uid && m.uid.length))
                        m.uid = [];
                    m.uid.push(r.string());
                    break;
                case 2:
                    if (!(m.thumbnailUID && m.thumbnailUID.length))
                        m.thumbnailUID = [];
                    m.thumbnailUID.push(r.string());
                    break;
                case 3:
                    if (!(m.additionalUID && m.additionalUID.length))
                        m.additionalUID = [];
                    m.additionalUID.push(r.string());
                    break;
                case 4:
                    m.disableConvert = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentConvertRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentConvertRequest
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentConvertRequest} ContentConvertRequest
         */
        ContentConvertRequest.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentConvertRequest)
                return d;
            var m = new $root.Proto.ContentConvertRequest();
            if (d.uid) {
                if (!Array.isArray(d.uid))
                    throw TypeError(".Proto.ContentConvertRequest.uid: array expected");
                m.uid = [];
                for (var i = 0; i < d.uid.length; ++i) {
                    m.uid[i] = String(d.uid[i]);
                }
            }
            if (d.thumbnailUID) {
                if (!Array.isArray(d.thumbnailUID))
                    throw TypeError(".Proto.ContentConvertRequest.thumbnailUID: array expected");
                m.thumbnailUID = [];
                for (var i = 0; i < d.thumbnailUID.length; ++i) {
                    m.thumbnailUID[i] = String(d.thumbnailUID[i]);
                }
            }
            if (d.additionalUID) {
                if (!Array.isArray(d.additionalUID))
                    throw TypeError(".Proto.ContentConvertRequest.additionalUID: array expected");
                m.additionalUID = [];
                for (var i = 0; i < d.additionalUID.length; ++i) {
                    m.additionalUID[i] = String(d.additionalUID[i]);
                }
            }
            if (d.disableConvert != null) {
                m.disableConvert = Boolean(d.disableConvert);
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentConvertRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentConvertRequest
         * @static
         * @param {Proto.ContentConvertRequest} m ContentConvertRequest
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentConvertRequest.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.uid = [];
                d.thumbnailUID = [];
                d.additionalUID = [];
            }
            if (o.defaults) {
                d.disableConvert = false;
            }
            if (m.uid && m.uid.length) {
                d.uid = [];
                for (var j = 0; j < m.uid.length; ++j) {
                    d.uid[j] = m.uid[j];
                }
            }
            if (m.thumbnailUID && m.thumbnailUID.length) {
                d.thumbnailUID = [];
                for (var j = 0; j < m.thumbnailUID.length; ++j) {
                    d.thumbnailUID[j] = m.thumbnailUID[j];
                }
            }
            if (m.additionalUID && m.additionalUID.length) {
                d.additionalUID = [];
                for (var j = 0; j < m.additionalUID.length; ++j) {
                    d.additionalUID[j] = m.additionalUID[j];
                }
            }
            if (m.disableConvert != null && m.hasOwnProperty("disableConvert")) {
                d.disableConvert = m.disableConvert;
            }
            return d;
        };

        /**
         * Converts this ContentConvertRequest to JSON.
         * @function toJSON
         * @memberof Proto.ContentConvertRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentConvertRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentConvertRequest;
    })();

    Proto.CloudOptionInfoResponse = (function() {

        /**
         * Properties of a CloudOptionInfoResponse.
         * @memberof Proto
         * @interface ICloudOptionInfoResponse
         * @property {string|null} [planUID] CloudOptionInfoResponse planUID
         * @property {boolean|null} [hasCloud] CloudOptionInfoResponse hasCloud
         * @property {boolean|null} [needCloud] CloudOptionInfoResponse needCloud
         * @property {number|null} [storageLimit] CloudOptionInfoResponse storageLimit
         * @property {string|null} [storageRemain] CloudOptionInfoResponse storageRemain
         */

        /**
         * Constructs a new CloudOptionInfoResponse.
         * @memberof Proto
         * @classdesc Represents a CloudOptionInfoResponse.
         * @implements ICloudOptionInfoResponse
         * @constructor
         * @param {Proto.ICloudOptionInfoResponse=} [p] Properties to set
         */
        function CloudOptionInfoResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CloudOptionInfoResponse planUID.
         * @member {string} planUID
         * @memberof Proto.CloudOptionInfoResponse
         * @instance
         */
        CloudOptionInfoResponse.prototype.planUID = "";

        /**
         * CloudOptionInfoResponse hasCloud.
         * @member {boolean} hasCloud
         * @memberof Proto.CloudOptionInfoResponse
         * @instance
         */
        CloudOptionInfoResponse.prototype.hasCloud = false;

        /**
         * CloudOptionInfoResponse needCloud.
         * @member {boolean} needCloud
         * @memberof Proto.CloudOptionInfoResponse
         * @instance
         */
        CloudOptionInfoResponse.prototype.needCloud = false;

        /**
         * CloudOptionInfoResponse storageLimit.
         * @member {number} storageLimit
         * @memberof Proto.CloudOptionInfoResponse
         * @instance
         */
        CloudOptionInfoResponse.prototype.storageLimit = 0;

        /**
         * CloudOptionInfoResponse storageRemain.
         * @member {string} storageRemain
         * @memberof Proto.CloudOptionInfoResponse
         * @instance
         */
        CloudOptionInfoResponse.prototype.storageRemain = "";

        /**
         * Creates a new CloudOptionInfoResponse instance using the specified properties.
         * @function create
         * @memberof Proto.CloudOptionInfoResponse
         * @static
         * @param {Proto.ICloudOptionInfoResponse=} [properties] Properties to set
         * @returns {Proto.CloudOptionInfoResponse} CloudOptionInfoResponse instance
         */
        CloudOptionInfoResponse.create = function create(properties) {
            return new CloudOptionInfoResponse(properties);
        };

        /**
         * Encodes the specified CloudOptionInfoResponse message. Does not implicitly {@link Proto.CloudOptionInfoResponse.verify|verify} messages.
         * @function encode
         * @memberof Proto.CloudOptionInfoResponse
         * @static
         * @param {Proto.ICloudOptionInfoResponse} m CloudOptionInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CloudOptionInfoResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.planUID != null && Object.hasOwnProperty.call(m, "planUID"))
                w.uint32(10).string(m.planUID);
            if (m.hasCloud != null && Object.hasOwnProperty.call(m, "hasCloud"))
                w.uint32(16).bool(m.hasCloud);
            if (m.needCloud != null && Object.hasOwnProperty.call(m, "needCloud"))
                w.uint32(24).bool(m.needCloud);
            if (m.storageLimit != null && Object.hasOwnProperty.call(m, "storageLimit"))
                w.uint32(32).int32(m.storageLimit);
            if (m.storageRemain != null && Object.hasOwnProperty.call(m, "storageRemain"))
                w.uint32(42).string(m.storageRemain);
            return w;
        };

        /**
         * Decodes a CloudOptionInfoResponse message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CloudOptionInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CloudOptionInfoResponse} CloudOptionInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CloudOptionInfoResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CloudOptionInfoResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.planUID = r.string();
                    break;
                case 2:
                    m.hasCloud = r.bool();
                    break;
                case 3:
                    m.needCloud = r.bool();
                    break;
                case 4:
                    m.storageLimit = r.int32();
                    break;
                case 5:
                    m.storageRemain = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CloudOptionInfoResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CloudOptionInfoResponse
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CloudOptionInfoResponse} CloudOptionInfoResponse
         */
        CloudOptionInfoResponse.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CloudOptionInfoResponse)
                return d;
            var m = new $root.Proto.CloudOptionInfoResponse();
            if (d.planUID != null) {
                m.planUID = String(d.planUID);
            }
            if (d.hasCloud != null) {
                m.hasCloud = Boolean(d.hasCloud);
            }
            if (d.needCloud != null) {
                m.needCloud = Boolean(d.needCloud);
            }
            if (d.storageLimit != null) {
                m.storageLimit = d.storageLimit | 0;
            }
            if (d.storageRemain != null) {
                m.storageRemain = String(d.storageRemain);
            }
            return m;
        };

        /**
         * Creates a plain object from a CloudOptionInfoResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CloudOptionInfoResponse
         * @static
         * @param {Proto.CloudOptionInfoResponse} m CloudOptionInfoResponse
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CloudOptionInfoResponse.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.planUID = "";
                d.hasCloud = false;
                d.needCloud = false;
                d.storageLimit = 0;
                d.storageRemain = "";
            }
            if (m.planUID != null && m.hasOwnProperty("planUID")) {
                d.planUID = m.planUID;
            }
            if (m.hasCloud != null && m.hasOwnProperty("hasCloud")) {
                d.hasCloud = m.hasCloud;
            }
            if (m.needCloud != null && m.hasOwnProperty("needCloud")) {
                d.needCloud = m.needCloud;
            }
            if (m.storageLimit != null && m.hasOwnProperty("storageLimit")) {
                d.storageLimit = m.storageLimit;
            }
            if (m.storageRemain != null && m.hasOwnProperty("storageRemain")) {
                d.storageRemain = m.storageRemain;
            }
            return d;
        };

        /**
         * Converts this CloudOptionInfoResponse to JSON.
         * @function toJSON
         * @memberof Proto.CloudOptionInfoResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CloudOptionInfoResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CloudOptionInfoResponse;
    })();

    Proto.OAuthContent = (function() {

        /**
         * Properties of a OAuthContent.
         * @memberof Proto
         * @interface IOAuthContent
         * @property {number|null} [type] OAuthContent type
         * @property {string|null} [display] OAuthContent display
         * @property {number|Long|null} [registerDate] OAuthContent registerDate
         * @property {Array.<Proto.IContent>|null} [content] OAuthContent content
         * @property {string|null} [oauthUID] OAuthContent oauthUID
         * @property {string|null} [serviceType] OAuthContent serviceType
         * @property {string|null} [accountId] OAuthContent accountId
         */

        /**
         * Constructs a new OAuthContent.
         * @memberof Proto
         * @classdesc Represents a OAuthContent.
         * @implements IOAuthContent
         * @constructor
         * @param {Proto.IOAuthContent=} [p] Properties to set
         */
        function OAuthContent(p) {
            this.content = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * OAuthContent type.
         * @member {number} type
         * @memberof Proto.OAuthContent
         * @instance
         */
        OAuthContent.prototype.type = 0;

        /**
         * OAuthContent display.
         * @member {string} display
         * @memberof Proto.OAuthContent
         * @instance
         */
        OAuthContent.prototype.display = "";

        /**
         * OAuthContent registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.OAuthContent
         * @instance
         */
        OAuthContent.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * OAuthContent content.
         * @member {Array.<Proto.IContent>} content
         * @memberof Proto.OAuthContent
         * @instance
         */
        OAuthContent.prototype.content = $util.emptyArray;

        /**
         * OAuthContent oauthUID.
         * @member {string} oauthUID
         * @memberof Proto.OAuthContent
         * @instance
         */
        OAuthContent.prototype.oauthUID = "";

        /**
         * OAuthContent serviceType.
         * @member {string} serviceType
         * @memberof Proto.OAuthContent
         * @instance
         */
        OAuthContent.prototype.serviceType = "";

        /**
         * OAuthContent accountId.
         * @member {string} accountId
         * @memberof Proto.OAuthContent
         * @instance
         */
        OAuthContent.prototype.accountId = "";

        /**
         * Creates a new OAuthContent instance using the specified properties.
         * @function create
         * @memberof Proto.OAuthContent
         * @static
         * @param {Proto.IOAuthContent=} [properties] Properties to set
         * @returns {Proto.OAuthContent} OAuthContent instance
         */
        OAuthContent.create = function create(properties) {
            return new OAuthContent(properties);
        };

        /**
         * Encodes the specified OAuthContent message. Does not implicitly {@link Proto.OAuthContent.verify|verify} messages.
         * @function encode
         * @memberof Proto.OAuthContent
         * @static
         * @param {Proto.IOAuthContent} m OAuthContent message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OAuthContent.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(8).int32(m.type);
            if (m.display != null && Object.hasOwnProperty.call(m, "display"))
                w.uint32(18).string(m.display);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.content != null && m.content.length) {
                for (var i = 0; i < m.content.length; ++i)
                    $root.Proto.Content.encode(m.content[i], w.uint32(34).fork()).ldelim();
            }
            if (m.oauthUID != null && Object.hasOwnProperty.call(m, "oauthUID"))
                w.uint32(42).string(m.oauthUID);
            if (m.serviceType != null && Object.hasOwnProperty.call(m, "serviceType"))
                w.uint32(50).string(m.serviceType);
            if (m.accountId != null && Object.hasOwnProperty.call(m, "accountId"))
                w.uint32(58).string(m.accountId);
            return w;
        };

        /**
         * Decodes a OAuthContent message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.OAuthContent
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.OAuthContent} OAuthContent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OAuthContent.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.OAuthContent();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.type = r.int32();
                    break;
                case 2:
                    m.display = r.string();
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    if (!(m.content && m.content.length))
                        m.content = [];
                    m.content.push($root.Proto.Content.decode(r, r.uint32()));
                    break;
                case 5:
                    m.oauthUID = r.string();
                    break;
                case 6:
                    m.serviceType = r.string();
                    break;
                case 7:
                    m.accountId = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a OAuthContent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.OAuthContent
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.OAuthContent} OAuthContent
         */
        OAuthContent.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.OAuthContent)
                return d;
            var m = new $root.Proto.OAuthContent();
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.display != null) {
                m.display = String(d.display);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.content) {
                if (!Array.isArray(d.content))
                    throw TypeError(".Proto.OAuthContent.content: array expected");
                m.content = [];
                for (var i = 0; i < d.content.length; ++i) {
                    if (typeof d.content[i] !== "object")
                        throw TypeError(".Proto.OAuthContent.content: object expected");
                    m.content[i] = $root.Proto.Content.fromObject(d.content[i]);
                }
            }
            if (d.oauthUID != null) {
                m.oauthUID = String(d.oauthUID);
            }
            if (d.serviceType != null) {
                m.serviceType = String(d.serviceType);
            }
            if (d.accountId != null) {
                m.accountId = String(d.accountId);
            }
            return m;
        };

        /**
         * Creates a plain object from a OAuthContent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.OAuthContent
         * @static
         * @param {Proto.OAuthContent} m OAuthContent
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OAuthContent.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.content = [];
            }
            if (o.defaults) {
                d.type = 0;
                d.display = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                d.oauthUID = "";
                d.serviceType = "";
                d.accountId = "";
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.display != null && m.hasOwnProperty("display")) {
                d.display = m.display;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.content && m.content.length) {
                d.content = [];
                for (var j = 0; j < m.content.length; ++j) {
                    d.content[j] = $root.Proto.Content.toObject(m.content[j], o);
                }
            }
            if (m.oauthUID != null && m.hasOwnProperty("oauthUID")) {
                d.oauthUID = m.oauthUID;
            }
            if (m.serviceType != null && m.hasOwnProperty("serviceType")) {
                d.serviceType = m.serviceType;
            }
            if (m.accountId != null && m.hasOwnProperty("accountId")) {
                d.accountId = m.accountId;
            }
            return d;
        };

        /**
         * Converts this OAuthContent to JSON.
         * @function toJSON
         * @memberof Proto.OAuthContent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OAuthContent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OAuthContent;
    })();

    Proto.OAuthContentList = (function() {

        /**
         * Properties of a OAuthContentList.
         * @memberof Proto
         * @interface IOAuthContentList
         * @property {Array.<Proto.IOAuthContent>|null} [value] OAuthContentList value
         */

        /**
         * Constructs a new OAuthContentList.
         * @memberof Proto
         * @classdesc Represents a OAuthContentList.
         * @implements IOAuthContentList
         * @constructor
         * @param {Proto.IOAuthContentList=} [p] Properties to set
         */
        function OAuthContentList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * OAuthContentList value.
         * @member {Array.<Proto.IOAuthContent>} value
         * @memberof Proto.OAuthContentList
         * @instance
         */
        OAuthContentList.prototype.value = $util.emptyArray;

        /**
         * Creates a new OAuthContentList instance using the specified properties.
         * @function create
         * @memberof Proto.OAuthContentList
         * @static
         * @param {Proto.IOAuthContentList=} [properties] Properties to set
         * @returns {Proto.OAuthContentList} OAuthContentList instance
         */
        OAuthContentList.create = function create(properties) {
            return new OAuthContentList(properties);
        };

        /**
         * Encodes the specified OAuthContentList message. Does not implicitly {@link Proto.OAuthContentList.verify|verify} messages.
         * @function encode
         * @memberof Proto.OAuthContentList
         * @static
         * @param {Proto.IOAuthContentList} m OAuthContentList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OAuthContentList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.OAuthContent.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a OAuthContentList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.OAuthContentList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.OAuthContentList} OAuthContentList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OAuthContentList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.OAuthContentList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.OAuthContent.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a OAuthContentList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.OAuthContentList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.OAuthContentList} OAuthContentList
         */
        OAuthContentList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.OAuthContentList)
                return d;
            var m = new $root.Proto.OAuthContentList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.OAuthContentList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.OAuthContentList.value: object expected");
                    m.value[i] = $root.Proto.OAuthContent.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a OAuthContentList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.OAuthContentList
         * @static
         * @param {Proto.OAuthContentList} m OAuthContentList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OAuthContentList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.OAuthContent.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this OAuthContentList to JSON.
         * @function toJSON
         * @memberof Proto.OAuthContentList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OAuthContentList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OAuthContentList;
    })();

    Proto.VimeoVideo = (function() {

        /**
         * Properties of a VimeoVideo.
         * @memberof Proto
         * @interface IVimeoVideo
         * @property {string|null} [id] VimeoVideo id
         * @property {string|null} [description] VimeoVideo description
         * @property {number|null} [duration] VimeoVideo duration
         * @property {number|null} [height] VimeoVideo height
         * @property {boolean|null} [playable] VimeoVideo playable
         * @property {string|null} [link] VimeoVideo link
         * @property {string|null} [name] VimeoVideo name
         * @property {Proto.VimeoVideo.IEmbed|null} [embed] VimeoVideo embed
         * @property {Proto.VimeoVideo.IPicture|null} [pictures] VimeoVideo pictures
         * @property {Proto.VimeoVideo.IPrivacy|null} [privacy] VimeoVideo privacy
         * @property {string|null} [status] VimeoVideo status
         * @property {string|null} [type] VimeoVideo type
         * @property {string|null} [uri] VimeoVideo uri
         * @property {number|null} [width] VimeoVideo width
         * @property {number|Long|null} [registerDate] VimeoVideo registerDate
         * @property {number|Long|null} [modifyDate] VimeoVideo modifyDate
         * @property {string|null} [password] VimeoVideo password
         */

        /**
         * Constructs a new VimeoVideo.
         * @memberof Proto
         * @classdesc Represents a VimeoVideo.
         * @implements IVimeoVideo
         * @constructor
         * @param {Proto.IVimeoVideo=} [p] Properties to set
         */
        function VimeoVideo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * VimeoVideo id.
         * @member {string} id
         * @memberof Proto.VimeoVideo
         * @instance
         */
        VimeoVideo.prototype.id = "";

        /**
         * VimeoVideo description.
         * @member {string} description
         * @memberof Proto.VimeoVideo
         * @instance
         */
        VimeoVideo.prototype.description = "";

        /**
         * VimeoVideo duration.
         * @member {number} duration
         * @memberof Proto.VimeoVideo
         * @instance
         */
        VimeoVideo.prototype.duration = 0;

        /**
         * VimeoVideo height.
         * @member {number} height
         * @memberof Proto.VimeoVideo
         * @instance
         */
        VimeoVideo.prototype.height = 0;

        /**
         * VimeoVideo playable.
         * @member {boolean} playable
         * @memberof Proto.VimeoVideo
         * @instance
         */
        VimeoVideo.prototype.playable = false;

        /**
         * VimeoVideo link.
         * @member {string} link
         * @memberof Proto.VimeoVideo
         * @instance
         */
        VimeoVideo.prototype.link = "";

        /**
         * VimeoVideo name.
         * @member {string} name
         * @memberof Proto.VimeoVideo
         * @instance
         */
        VimeoVideo.prototype.name = "";

        /**
         * VimeoVideo embed.
         * @member {Proto.VimeoVideo.IEmbed|null|undefined} embed
         * @memberof Proto.VimeoVideo
         * @instance
         */
        VimeoVideo.prototype.embed = null;

        /**
         * VimeoVideo pictures.
         * @member {Proto.VimeoVideo.IPicture|null|undefined} pictures
         * @memberof Proto.VimeoVideo
         * @instance
         */
        VimeoVideo.prototype.pictures = null;

        /**
         * VimeoVideo privacy.
         * @member {Proto.VimeoVideo.IPrivacy|null|undefined} privacy
         * @memberof Proto.VimeoVideo
         * @instance
         */
        VimeoVideo.prototype.privacy = null;

        /**
         * VimeoVideo status.
         * @member {string} status
         * @memberof Proto.VimeoVideo
         * @instance
         */
        VimeoVideo.prototype.status = "";

        /**
         * VimeoVideo type.
         * @member {string} type
         * @memberof Proto.VimeoVideo
         * @instance
         */
        VimeoVideo.prototype.type = "";

        /**
         * VimeoVideo uri.
         * @member {string} uri
         * @memberof Proto.VimeoVideo
         * @instance
         */
        VimeoVideo.prototype.uri = "";

        /**
         * VimeoVideo width.
         * @member {number} width
         * @memberof Proto.VimeoVideo
         * @instance
         */
        VimeoVideo.prototype.width = 0;

        /**
         * VimeoVideo registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.VimeoVideo
         * @instance
         */
        VimeoVideo.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * VimeoVideo modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.VimeoVideo
         * @instance
         */
        VimeoVideo.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * VimeoVideo password.
         * @member {string} password
         * @memberof Proto.VimeoVideo
         * @instance
         */
        VimeoVideo.prototype.password = "";

        /**
         * Creates a new VimeoVideo instance using the specified properties.
         * @function create
         * @memberof Proto.VimeoVideo
         * @static
         * @param {Proto.IVimeoVideo=} [properties] Properties to set
         * @returns {Proto.VimeoVideo} VimeoVideo instance
         */
        VimeoVideo.create = function create(properties) {
            return new VimeoVideo(properties);
        };

        /**
         * Encodes the specified VimeoVideo message. Does not implicitly {@link Proto.VimeoVideo.verify|verify} messages.
         * @function encode
         * @memberof Proto.VimeoVideo
         * @static
         * @param {Proto.IVimeoVideo} m VimeoVideo message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VimeoVideo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(18).string(m.description);
            if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                w.uint32(25).double(m.duration);
            if (m.height != null && Object.hasOwnProperty.call(m, "height"))
                w.uint32(33).double(m.height);
            if (m.playable != null && Object.hasOwnProperty.call(m, "playable"))
                w.uint32(40).bool(m.playable);
            if (m.link != null && Object.hasOwnProperty.call(m, "link"))
                w.uint32(50).string(m.link);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(58).string(m.name);
            if (m.embed != null && Object.hasOwnProperty.call(m, "embed"))
                $root.Proto.VimeoVideo.Embed.encode(m.embed, w.uint32(74).fork()).ldelim();
            if (m.pictures != null && Object.hasOwnProperty.call(m, "pictures"))
                $root.Proto.VimeoVideo.Picture.encode(m.pictures, w.uint32(82).fork()).ldelim();
            if (m.privacy != null && Object.hasOwnProperty.call(m, "privacy"))
                $root.Proto.VimeoVideo.Privacy.encode(m.privacy, w.uint32(90).fork()).ldelim();
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(98).string(m.status);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(106).string(m.type);
            if (m.uri != null && Object.hasOwnProperty.call(m, "uri"))
                w.uint32(114).string(m.uri);
            if (m.width != null && Object.hasOwnProperty.call(m, "width"))
                w.uint32(121).double(m.width);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(128).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(136).int64(m.modifyDate);
            if (m.password != null && Object.hasOwnProperty.call(m, "password"))
                w.uint32(146).string(m.password);
            return w;
        };

        /**
         * Decodes a VimeoVideo message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.VimeoVideo
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.VimeoVideo} VimeoVideo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VimeoVideo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.VimeoVideo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.id = r.string();
                    break;
                case 2:
                    m.description = r.string();
                    break;
                case 3:
                    m.duration = r.double();
                    break;
                case 4:
                    m.height = r.double();
                    break;
                case 5:
                    m.playable = r.bool();
                    break;
                case 6:
                    m.link = r.string();
                    break;
                case 7:
                    m.name = r.string();
                    break;
                case 9:
                    m.embed = $root.Proto.VimeoVideo.Embed.decode(r, r.uint32());
                    break;
                case 10:
                    m.pictures = $root.Proto.VimeoVideo.Picture.decode(r, r.uint32());
                    break;
                case 11:
                    m.privacy = $root.Proto.VimeoVideo.Privacy.decode(r, r.uint32());
                    break;
                case 12:
                    m.status = r.string();
                    break;
                case 13:
                    m.type = r.string();
                    break;
                case 14:
                    m.uri = r.string();
                    break;
                case 15:
                    m.width = r.double();
                    break;
                case 16:
                    m.registerDate = r.int64();
                    break;
                case 17:
                    m.modifyDate = r.int64();
                    break;
                case 18:
                    m.password = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a VimeoVideo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.VimeoVideo
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.VimeoVideo} VimeoVideo
         */
        VimeoVideo.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.VimeoVideo)
                return d;
            var m = new $root.Proto.VimeoVideo();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.duration != null) {
                m.duration = Number(d.duration);
            }
            if (d.height != null) {
                m.height = Number(d.height);
            }
            if (d.playable != null) {
                m.playable = Boolean(d.playable);
            }
            if (d.link != null) {
                m.link = String(d.link);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.embed != null) {
                if (typeof d.embed !== "object")
                    throw TypeError(".Proto.VimeoVideo.embed: object expected");
                m.embed = $root.Proto.VimeoVideo.Embed.fromObject(d.embed);
            }
            if (d.pictures != null) {
                if (typeof d.pictures !== "object")
                    throw TypeError(".Proto.VimeoVideo.pictures: object expected");
                m.pictures = $root.Proto.VimeoVideo.Picture.fromObject(d.pictures);
            }
            if (d.privacy != null) {
                if (typeof d.privacy !== "object")
                    throw TypeError(".Proto.VimeoVideo.privacy: object expected");
                m.privacy = $root.Proto.VimeoVideo.Privacy.fromObject(d.privacy);
            }
            if (d.status != null) {
                m.status = String(d.status);
            }
            if (d.type != null) {
                m.type = String(d.type);
            }
            if (d.uri != null) {
                m.uri = String(d.uri);
            }
            if (d.width != null) {
                m.width = Number(d.width);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.password != null) {
                m.password = String(d.password);
            }
            return m;
        };

        /**
         * Creates a plain object from a VimeoVideo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.VimeoVideo
         * @static
         * @param {Proto.VimeoVideo} m VimeoVideo
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VimeoVideo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.description = "";
                d.duration = 0;
                d.height = 0;
                d.playable = false;
                d.link = "";
                d.name = "";
                d.embed = null;
                d.pictures = null;
                d.privacy = null;
                d.status = "";
                d.type = "";
                d.uri = "";
                d.width = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.password = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.duration != null && m.hasOwnProperty("duration")) {
                d.duration = o.json && !isFinite(m.duration) ? String(m.duration) : m.duration;
            }
            if (m.height != null && m.hasOwnProperty("height")) {
                d.height = o.json && !isFinite(m.height) ? String(m.height) : m.height;
            }
            if (m.playable != null && m.hasOwnProperty("playable")) {
                d.playable = m.playable;
            }
            if (m.link != null && m.hasOwnProperty("link")) {
                d.link = m.link;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.embed != null && m.hasOwnProperty("embed")) {
                d.embed = $root.Proto.VimeoVideo.Embed.toObject(m.embed, o);
            }
            if (m.pictures != null && m.hasOwnProperty("pictures")) {
                d.pictures = $root.Proto.VimeoVideo.Picture.toObject(m.pictures, o);
            }
            if (m.privacy != null && m.hasOwnProperty("privacy")) {
                d.privacy = $root.Proto.VimeoVideo.Privacy.toObject(m.privacy, o);
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.uri != null && m.hasOwnProperty("uri")) {
                d.uri = m.uri;
            }
            if (m.width != null && m.hasOwnProperty("width")) {
                d.width = o.json && !isFinite(m.width) ? String(m.width) : m.width;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.password != null && m.hasOwnProperty("password")) {
                d.password = m.password;
            }
            return d;
        };

        /**
         * Converts this VimeoVideo to JSON.
         * @function toJSON
         * @memberof Proto.VimeoVideo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VimeoVideo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        VimeoVideo.Embed = (function() {

            /**
             * Properties of an Embed.
             * @memberof Proto.VimeoVideo
             * @interface IEmbed
             * @property {string|null} [uri] Embed uri
             */

            /**
             * Constructs a new Embed.
             * @memberof Proto.VimeoVideo
             * @classdesc Represents an Embed.
             * @implements IEmbed
             * @constructor
             * @param {Proto.VimeoVideo.IEmbed=} [p] Properties to set
             */
            function Embed(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Embed uri.
             * @member {string} uri
             * @memberof Proto.VimeoVideo.Embed
             * @instance
             */
            Embed.prototype.uri = "";

            /**
             * Creates a new Embed instance using the specified properties.
             * @function create
             * @memberof Proto.VimeoVideo.Embed
             * @static
             * @param {Proto.VimeoVideo.IEmbed=} [properties] Properties to set
             * @returns {Proto.VimeoVideo.Embed} Embed instance
             */
            Embed.create = function create(properties) {
                return new Embed(properties);
            };

            /**
             * Encodes the specified Embed message. Does not implicitly {@link Proto.VimeoVideo.Embed.verify|verify} messages.
             * @function encode
             * @memberof Proto.VimeoVideo.Embed
             * @static
             * @param {Proto.VimeoVideo.IEmbed} m Embed message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Embed.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.uri != null && Object.hasOwnProperty.call(m, "uri"))
                    w.uint32(10).string(m.uri);
                return w;
            };

            /**
             * Decodes an Embed message from the specified reader or buffer.
             * @function decode
             * @memberof Proto.VimeoVideo.Embed
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {Proto.VimeoVideo.Embed} Embed
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Embed.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.VimeoVideo.Embed();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.uri = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates an Embed message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Proto.VimeoVideo.Embed
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {Proto.VimeoVideo.Embed} Embed
             */
            Embed.fromObject = function fromObject(d) {
                if (d instanceof $root.Proto.VimeoVideo.Embed)
                    return d;
                var m = new $root.Proto.VimeoVideo.Embed();
                if (d.uri != null) {
                    m.uri = String(d.uri);
                }
                return m;
            };

            /**
             * Creates a plain object from an Embed message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Proto.VimeoVideo.Embed
             * @static
             * @param {Proto.VimeoVideo.Embed} m Embed
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Embed.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.uri = "";
                }
                if (m.uri != null && m.hasOwnProperty("uri")) {
                    d.uri = m.uri;
                }
                return d;
            };

            /**
             * Converts this Embed to JSON.
             * @function toJSON
             * @memberof Proto.VimeoVideo.Embed
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Embed.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Embed;
        })();

        VimeoVideo.Picture = (function() {

            /**
             * Properties of a Picture.
             * @memberof Proto.VimeoVideo
             * @interface IPicture
             * @property {boolean|null} [active] Picture active
             * @property {boolean|null} [defaultPicture] Picture defaultPicture
             * @property {string|null} [uri] Picture uri
             * @property {Array.<Proto.VimeoVideo.Picture.ISize>|null} [sizes] Picture sizes
             */

            /**
             * Constructs a new Picture.
             * @memberof Proto.VimeoVideo
             * @classdesc Represents a Picture.
             * @implements IPicture
             * @constructor
             * @param {Proto.VimeoVideo.IPicture=} [p] Properties to set
             */
            function Picture(p) {
                this.sizes = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Picture active.
             * @member {boolean} active
             * @memberof Proto.VimeoVideo.Picture
             * @instance
             */
            Picture.prototype.active = false;

            /**
             * Picture defaultPicture.
             * @member {boolean} defaultPicture
             * @memberof Proto.VimeoVideo.Picture
             * @instance
             */
            Picture.prototype.defaultPicture = false;

            /**
             * Picture uri.
             * @member {string} uri
             * @memberof Proto.VimeoVideo.Picture
             * @instance
             */
            Picture.prototype.uri = "";

            /**
             * Picture sizes.
             * @member {Array.<Proto.VimeoVideo.Picture.ISize>} sizes
             * @memberof Proto.VimeoVideo.Picture
             * @instance
             */
            Picture.prototype.sizes = $util.emptyArray;

            /**
             * Creates a new Picture instance using the specified properties.
             * @function create
             * @memberof Proto.VimeoVideo.Picture
             * @static
             * @param {Proto.VimeoVideo.IPicture=} [properties] Properties to set
             * @returns {Proto.VimeoVideo.Picture} Picture instance
             */
            Picture.create = function create(properties) {
                return new Picture(properties);
            };

            /**
             * Encodes the specified Picture message. Does not implicitly {@link Proto.VimeoVideo.Picture.verify|verify} messages.
             * @function encode
             * @memberof Proto.VimeoVideo.Picture
             * @static
             * @param {Proto.VimeoVideo.IPicture} m Picture message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Picture.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                    w.uint32(8).bool(m.active);
                if (m.defaultPicture != null && Object.hasOwnProperty.call(m, "defaultPicture"))
                    w.uint32(16).bool(m.defaultPicture);
                if (m.uri != null && Object.hasOwnProperty.call(m, "uri"))
                    w.uint32(26).string(m.uri);
                if (m.sizes != null && m.sizes.length) {
                    for (var i = 0; i < m.sizes.length; ++i)
                        $root.Proto.VimeoVideo.Picture.Size.encode(m.sizes[i], w.uint32(34).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a Picture message from the specified reader or buffer.
             * @function decode
             * @memberof Proto.VimeoVideo.Picture
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {Proto.VimeoVideo.Picture} Picture
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Picture.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.VimeoVideo.Picture();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.active = r.bool();
                        break;
                    case 2:
                        m.defaultPicture = r.bool();
                        break;
                    case 3:
                        m.uri = r.string();
                        break;
                    case 4:
                        if (!(m.sizes && m.sizes.length))
                            m.sizes = [];
                        m.sizes.push($root.Proto.VimeoVideo.Picture.Size.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Picture message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Proto.VimeoVideo.Picture
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {Proto.VimeoVideo.Picture} Picture
             */
            Picture.fromObject = function fromObject(d) {
                if (d instanceof $root.Proto.VimeoVideo.Picture)
                    return d;
                var m = new $root.Proto.VimeoVideo.Picture();
                if (d.active != null) {
                    m.active = Boolean(d.active);
                }
                if (d.defaultPicture != null) {
                    m.defaultPicture = Boolean(d.defaultPicture);
                }
                if (d.uri != null) {
                    m.uri = String(d.uri);
                }
                if (d.sizes) {
                    if (!Array.isArray(d.sizes))
                        throw TypeError(".Proto.VimeoVideo.Picture.sizes: array expected");
                    m.sizes = [];
                    for (var i = 0; i < d.sizes.length; ++i) {
                        if (typeof d.sizes[i] !== "object")
                            throw TypeError(".Proto.VimeoVideo.Picture.sizes: object expected");
                        m.sizes[i] = $root.Proto.VimeoVideo.Picture.Size.fromObject(d.sizes[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a Picture message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Proto.VimeoVideo.Picture
             * @static
             * @param {Proto.VimeoVideo.Picture} m Picture
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Picture.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.sizes = [];
                }
                if (o.defaults) {
                    d.active = false;
                    d.defaultPicture = false;
                    d.uri = "";
                }
                if (m.active != null && m.hasOwnProperty("active")) {
                    d.active = m.active;
                }
                if (m.defaultPicture != null && m.hasOwnProperty("defaultPicture")) {
                    d.defaultPicture = m.defaultPicture;
                }
                if (m.uri != null && m.hasOwnProperty("uri")) {
                    d.uri = m.uri;
                }
                if (m.sizes && m.sizes.length) {
                    d.sizes = [];
                    for (var j = 0; j < m.sizes.length; ++j) {
                        d.sizes[j] = $root.Proto.VimeoVideo.Picture.Size.toObject(m.sizes[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this Picture to JSON.
             * @function toJSON
             * @memberof Proto.VimeoVideo.Picture
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Picture.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            Picture.Size = (function() {

                /**
                 * Properties of a Size.
                 * @memberof Proto.VimeoVideo.Picture
                 * @interface ISize
                 * @property {number|null} [width] Size width
                 * @property {number|null} [height] Size height
                 * @property {string|null} [link] Size link
                 */

                /**
                 * Constructs a new Size.
                 * @memberof Proto.VimeoVideo.Picture
                 * @classdesc Represents a Size.
                 * @implements ISize
                 * @constructor
                 * @param {Proto.VimeoVideo.Picture.ISize=} [p] Properties to set
                 */
                function Size(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                /**
                 * Size width.
                 * @member {number} width
                 * @memberof Proto.VimeoVideo.Picture.Size
                 * @instance
                 */
                Size.prototype.width = 0;

                /**
                 * Size height.
                 * @member {number} height
                 * @memberof Proto.VimeoVideo.Picture.Size
                 * @instance
                 */
                Size.prototype.height = 0;

                /**
                 * Size link.
                 * @member {string} link
                 * @memberof Proto.VimeoVideo.Picture.Size
                 * @instance
                 */
                Size.prototype.link = "";

                /**
                 * Creates a new Size instance using the specified properties.
                 * @function create
                 * @memberof Proto.VimeoVideo.Picture.Size
                 * @static
                 * @param {Proto.VimeoVideo.Picture.ISize=} [properties] Properties to set
                 * @returns {Proto.VimeoVideo.Picture.Size} Size instance
                 */
                Size.create = function create(properties) {
                    return new Size(properties);
                };

                /**
                 * Encodes the specified Size message. Does not implicitly {@link Proto.VimeoVideo.Picture.Size.verify|verify} messages.
                 * @function encode
                 * @memberof Proto.VimeoVideo.Picture.Size
                 * @static
                 * @param {Proto.VimeoVideo.Picture.ISize} m Size message or plain object to encode
                 * @param {$protobuf.Writer} [w] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Size.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.width != null && Object.hasOwnProperty.call(m, "width"))
                        w.uint32(9).double(m.width);
                    if (m.height != null && Object.hasOwnProperty.call(m, "height"))
                        w.uint32(17).double(m.height);
                    if (m.link != null && Object.hasOwnProperty.call(m, "link"))
                        w.uint32(26).string(m.link);
                    return w;
                };

                /**
                 * Decodes a Size message from the specified reader or buffer.
                 * @function decode
                 * @memberof Proto.VimeoVideo.Picture.Size
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
                 * @param {number} [l] Message length if known beforehand
                 * @returns {Proto.VimeoVideo.Picture.Size} Size
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Size.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.VimeoVideo.Picture.Size();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1:
                            m.width = r.double();
                            break;
                        case 2:
                            m.height = r.double();
                            break;
                        case 3:
                            m.link = r.string();
                            break;
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };

                /**
                 * Creates a Size message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Proto.VimeoVideo.Picture.Size
                 * @static
                 * @param {Object.<string,*>} d Plain object
                 * @returns {Proto.VimeoVideo.Picture.Size} Size
                 */
                Size.fromObject = function fromObject(d) {
                    if (d instanceof $root.Proto.VimeoVideo.Picture.Size)
                        return d;
                    var m = new $root.Proto.VimeoVideo.Picture.Size();
                    if (d.width != null) {
                        m.width = Number(d.width);
                    }
                    if (d.height != null) {
                        m.height = Number(d.height);
                    }
                    if (d.link != null) {
                        m.link = String(d.link);
                    }
                    return m;
                };

                /**
                 * Creates a plain object from a Size message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Proto.VimeoVideo.Picture.Size
                 * @static
                 * @param {Proto.VimeoVideo.Picture.Size} m Size
                 * @param {$protobuf.IConversionOptions} [o] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Size.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.width = 0;
                        d.height = 0;
                        d.link = "";
                    }
                    if (m.width != null && m.hasOwnProperty("width")) {
                        d.width = o.json && !isFinite(m.width) ? String(m.width) : m.width;
                    }
                    if (m.height != null && m.hasOwnProperty("height")) {
                        d.height = o.json && !isFinite(m.height) ? String(m.height) : m.height;
                    }
                    if (m.link != null && m.hasOwnProperty("link")) {
                        d.link = m.link;
                    }
                    return d;
                };

                /**
                 * Converts this Size to JSON.
                 * @function toJSON
                 * @memberof Proto.VimeoVideo.Picture.Size
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Size.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Size;
            })();

            return Picture;
        })();

        VimeoVideo.Privacy = (function() {

            /**
             * Properties of a Privacy.
             * @memberof Proto.VimeoVideo
             * @interface IPrivacy
             * @property {boolean|null} [add] Privacy add
             * @property {string|null} [comments] Privacy comments
             * @property {string|null} [embed] Privacy embed
             * @property {string|null} [view] Privacy view
             */

            /**
             * Constructs a new Privacy.
             * @memberof Proto.VimeoVideo
             * @classdesc Represents a Privacy.
             * @implements IPrivacy
             * @constructor
             * @param {Proto.VimeoVideo.IPrivacy=} [p] Properties to set
             */
            function Privacy(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Privacy add.
             * @member {boolean} add
             * @memberof Proto.VimeoVideo.Privacy
             * @instance
             */
            Privacy.prototype.add = false;

            /**
             * Privacy comments.
             * @member {string} comments
             * @memberof Proto.VimeoVideo.Privacy
             * @instance
             */
            Privacy.prototype.comments = "";

            /**
             * Privacy embed.
             * @member {string} embed
             * @memberof Proto.VimeoVideo.Privacy
             * @instance
             */
            Privacy.prototype.embed = "";

            /**
             * Privacy view.
             * @member {string} view
             * @memberof Proto.VimeoVideo.Privacy
             * @instance
             */
            Privacy.prototype.view = "";

            /**
             * Creates a new Privacy instance using the specified properties.
             * @function create
             * @memberof Proto.VimeoVideo.Privacy
             * @static
             * @param {Proto.VimeoVideo.IPrivacy=} [properties] Properties to set
             * @returns {Proto.VimeoVideo.Privacy} Privacy instance
             */
            Privacy.create = function create(properties) {
                return new Privacy(properties);
            };

            /**
             * Encodes the specified Privacy message. Does not implicitly {@link Proto.VimeoVideo.Privacy.verify|verify} messages.
             * @function encode
             * @memberof Proto.VimeoVideo.Privacy
             * @static
             * @param {Proto.VimeoVideo.IPrivacy} m Privacy message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Privacy.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.add != null && Object.hasOwnProperty.call(m, "add"))
                    w.uint32(8).bool(m.add);
                if (m.comments != null && Object.hasOwnProperty.call(m, "comments"))
                    w.uint32(18).string(m.comments);
                if (m.embed != null && Object.hasOwnProperty.call(m, "embed"))
                    w.uint32(26).string(m.embed);
                if (m.view != null && Object.hasOwnProperty.call(m, "view"))
                    w.uint32(34).string(m.view);
                return w;
            };

            /**
             * Decodes a Privacy message from the specified reader or buffer.
             * @function decode
             * @memberof Proto.VimeoVideo.Privacy
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {Proto.VimeoVideo.Privacy} Privacy
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Privacy.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.VimeoVideo.Privacy();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.add = r.bool();
                        break;
                    case 2:
                        m.comments = r.string();
                        break;
                    case 3:
                        m.embed = r.string();
                        break;
                    case 4:
                        m.view = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Privacy message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Proto.VimeoVideo.Privacy
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {Proto.VimeoVideo.Privacy} Privacy
             */
            Privacy.fromObject = function fromObject(d) {
                if (d instanceof $root.Proto.VimeoVideo.Privacy)
                    return d;
                var m = new $root.Proto.VimeoVideo.Privacy();
                if (d.add != null) {
                    m.add = Boolean(d.add);
                }
                if (d.comments != null) {
                    m.comments = String(d.comments);
                }
                if (d.embed != null) {
                    m.embed = String(d.embed);
                }
                if (d.view != null) {
                    m.view = String(d.view);
                }
                return m;
            };

            /**
             * Creates a plain object from a Privacy message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Proto.VimeoVideo.Privacy
             * @static
             * @param {Proto.VimeoVideo.Privacy} m Privacy
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Privacy.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.add = false;
                    d.comments = "";
                    d.embed = "";
                    d.view = "";
                }
                if (m.add != null && m.hasOwnProperty("add")) {
                    d.add = m.add;
                }
                if (m.comments != null && m.hasOwnProperty("comments")) {
                    d.comments = m.comments;
                }
                if (m.embed != null && m.hasOwnProperty("embed")) {
                    d.embed = m.embed;
                }
                if (m.view != null && m.hasOwnProperty("view")) {
                    d.view = m.view;
                }
                return d;
            };

            /**
             * Converts this Privacy to JSON.
             * @function toJSON
             * @memberof Proto.VimeoVideo.Privacy
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Privacy.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Privacy;
        })();

        return VimeoVideo;
    })();

    Proto.VimeoVideoList = (function() {

        /**
         * Properties of a VimeoVideoList.
         * @memberof Proto
         * @interface IVimeoVideoList
         * @property {Array.<Proto.IVimeoVideo>|null} [value] VimeoVideoList value
         */

        /**
         * Constructs a new VimeoVideoList.
         * @memberof Proto
         * @classdesc Represents a VimeoVideoList.
         * @implements IVimeoVideoList
         * @constructor
         * @param {Proto.IVimeoVideoList=} [p] Properties to set
         */
        function VimeoVideoList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * VimeoVideoList value.
         * @member {Array.<Proto.IVimeoVideo>} value
         * @memberof Proto.VimeoVideoList
         * @instance
         */
        VimeoVideoList.prototype.value = $util.emptyArray;

        /**
         * Creates a new VimeoVideoList instance using the specified properties.
         * @function create
         * @memberof Proto.VimeoVideoList
         * @static
         * @param {Proto.IVimeoVideoList=} [properties] Properties to set
         * @returns {Proto.VimeoVideoList} VimeoVideoList instance
         */
        VimeoVideoList.create = function create(properties) {
            return new VimeoVideoList(properties);
        };

        /**
         * Encodes the specified VimeoVideoList message. Does not implicitly {@link Proto.VimeoVideoList.verify|verify} messages.
         * @function encode
         * @memberof Proto.VimeoVideoList
         * @static
         * @param {Proto.IVimeoVideoList} m VimeoVideoList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VimeoVideoList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.VimeoVideo.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a VimeoVideoList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.VimeoVideoList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.VimeoVideoList} VimeoVideoList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VimeoVideoList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.VimeoVideoList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.VimeoVideo.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a VimeoVideoList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.VimeoVideoList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.VimeoVideoList} VimeoVideoList
         */
        VimeoVideoList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.VimeoVideoList)
                return d;
            var m = new $root.Proto.VimeoVideoList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.VimeoVideoList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.VimeoVideoList.value: object expected");
                    m.value[i] = $root.Proto.VimeoVideo.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a VimeoVideoList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.VimeoVideoList
         * @static
         * @param {Proto.VimeoVideoList} m VimeoVideoList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VimeoVideoList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.VimeoVideo.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this VimeoVideoList to JSON.
         * @function toJSON
         * @memberof Proto.VimeoVideoList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VimeoVideoList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VimeoVideoList;
    })();

    Proto.VimeoVideoPageList = (function() {

        /**
         * Properties of a VimeoVideoPageList.
         * @memberof Proto
         * @interface IVimeoVideoPageList
         * @property {string|null} [total] VimeoVideoPageList total
         * @property {string|null} [filteredTotal] VimeoVideoPageList filteredTotal
         * @property {number|null} [page] VimeoVideoPageList page
         * @property {number|null} [pageRow] VimeoVideoPageList pageRow
         * @property {Array.<Proto.IVimeoVideo>|null} [data] VimeoVideoPageList data
         */

        /**
         * Constructs a new VimeoVideoPageList.
         * @memberof Proto
         * @classdesc Represents a VimeoVideoPageList.
         * @implements IVimeoVideoPageList
         * @constructor
         * @param {Proto.IVimeoVideoPageList=} [p] Properties to set
         */
        function VimeoVideoPageList(p) {
            this.data = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * VimeoVideoPageList total.
         * @member {string} total
         * @memberof Proto.VimeoVideoPageList
         * @instance
         */
        VimeoVideoPageList.prototype.total = "";

        /**
         * VimeoVideoPageList filteredTotal.
         * @member {string} filteredTotal
         * @memberof Proto.VimeoVideoPageList
         * @instance
         */
        VimeoVideoPageList.prototype.filteredTotal = "";

        /**
         * VimeoVideoPageList page.
         * @member {number} page
         * @memberof Proto.VimeoVideoPageList
         * @instance
         */
        VimeoVideoPageList.prototype.page = 0;

        /**
         * VimeoVideoPageList pageRow.
         * @member {number} pageRow
         * @memberof Proto.VimeoVideoPageList
         * @instance
         */
        VimeoVideoPageList.prototype.pageRow = 0;

        /**
         * VimeoVideoPageList data.
         * @member {Array.<Proto.IVimeoVideo>} data
         * @memberof Proto.VimeoVideoPageList
         * @instance
         */
        VimeoVideoPageList.prototype.data = $util.emptyArray;

        /**
         * Creates a new VimeoVideoPageList instance using the specified properties.
         * @function create
         * @memberof Proto.VimeoVideoPageList
         * @static
         * @param {Proto.IVimeoVideoPageList=} [properties] Properties to set
         * @returns {Proto.VimeoVideoPageList} VimeoVideoPageList instance
         */
        VimeoVideoPageList.create = function create(properties) {
            return new VimeoVideoPageList(properties);
        };

        /**
         * Encodes the specified VimeoVideoPageList message. Does not implicitly {@link Proto.VimeoVideoPageList.verify|verify} messages.
         * @function encode
         * @memberof Proto.VimeoVideoPageList
         * @static
         * @param {Proto.IVimeoVideoPageList} m VimeoVideoPageList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VimeoVideoPageList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.total != null && Object.hasOwnProperty.call(m, "total"))
                w.uint32(10).string(m.total);
            if (m.filteredTotal != null && Object.hasOwnProperty.call(m, "filteredTotal"))
                w.uint32(18).string(m.filteredTotal);
            if (m.page != null && Object.hasOwnProperty.call(m, "page"))
                w.uint32(24).int32(m.page);
            if (m.pageRow != null && Object.hasOwnProperty.call(m, "pageRow"))
                w.uint32(32).int32(m.pageRow);
            if (m.data != null && m.data.length) {
                for (var i = 0; i < m.data.length; ++i)
                    $root.Proto.VimeoVideo.encode(m.data[i], w.uint32(42).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a VimeoVideoPageList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.VimeoVideoPageList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.VimeoVideoPageList} VimeoVideoPageList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VimeoVideoPageList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.VimeoVideoPageList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.total = r.string();
                    break;
                case 2:
                    m.filteredTotal = r.string();
                    break;
                case 3:
                    m.page = r.int32();
                    break;
                case 4:
                    m.pageRow = r.int32();
                    break;
                case 5:
                    if (!(m.data && m.data.length))
                        m.data = [];
                    m.data.push($root.Proto.VimeoVideo.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a VimeoVideoPageList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.VimeoVideoPageList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.VimeoVideoPageList} VimeoVideoPageList
         */
        VimeoVideoPageList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.VimeoVideoPageList)
                return d;
            var m = new $root.Proto.VimeoVideoPageList();
            if (d.total != null) {
                m.total = String(d.total);
            }
            if (d.filteredTotal != null) {
                m.filteredTotal = String(d.filteredTotal);
            }
            if (d.page != null) {
                m.page = d.page | 0;
            }
            if (d.pageRow != null) {
                m.pageRow = d.pageRow | 0;
            }
            if (d.data) {
                if (!Array.isArray(d.data))
                    throw TypeError(".Proto.VimeoVideoPageList.data: array expected");
                m.data = [];
                for (var i = 0; i < d.data.length; ++i) {
                    if (typeof d.data[i] !== "object")
                        throw TypeError(".Proto.VimeoVideoPageList.data: object expected");
                    m.data[i] = $root.Proto.VimeoVideo.fromObject(d.data[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a VimeoVideoPageList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.VimeoVideoPageList
         * @static
         * @param {Proto.VimeoVideoPageList} m VimeoVideoPageList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VimeoVideoPageList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.data = [];
            }
            if (o.defaults) {
                d.total = "";
                d.filteredTotal = "";
                d.page = 0;
                d.pageRow = 0;
            }
            if (m.total != null && m.hasOwnProperty("total")) {
                d.total = m.total;
            }
            if (m.filteredTotal != null && m.hasOwnProperty("filteredTotal")) {
                d.filteredTotal = m.filteredTotal;
            }
            if (m.page != null && m.hasOwnProperty("page")) {
                d.page = m.page;
            }
            if (m.pageRow != null && m.hasOwnProperty("pageRow")) {
                d.pageRow = m.pageRow;
            }
            if (m.data && m.data.length) {
                d.data = [];
                for (var j = 0; j < m.data.length; ++j) {
                    d.data[j] = $root.Proto.VimeoVideo.toObject(m.data[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this VimeoVideoPageList to JSON.
         * @function toJSON
         * @memberof Proto.VimeoVideoPageList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VimeoVideoPageList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VimeoVideoPageList;
    })();

    Proto.YoutubeItem = (function() {

        /**
         * Properties of a YoutubeItem.
         * @memberof Proto
         * @interface IYoutubeItem
         * @property {string|null} [id] YoutubeItem id
         * @property {string|null} [kind] YoutubeItem kind
         * @property {Proto.YoutubeItem.ISnippet|null} [snippet] YoutubeItem snippet
         * @property {Proto.YoutubeItem.IContentDetails|null} [contentDetails] YoutubeItem contentDetails
         * @property {Proto.YoutubeItem.IFileDetails|null} [fileDetails] YoutubeItem fileDetails
         * @property {Proto.YoutubeItem.IStatus|null} [status] YoutubeItem status
         */

        /**
         * Constructs a new YoutubeItem.
         * @memberof Proto
         * @classdesc Represents a YoutubeItem.
         * @implements IYoutubeItem
         * @constructor
         * @param {Proto.IYoutubeItem=} [p] Properties to set
         */
        function YoutubeItem(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * YoutubeItem id.
         * @member {string} id
         * @memberof Proto.YoutubeItem
         * @instance
         */
        YoutubeItem.prototype.id = "";

        /**
         * YoutubeItem kind.
         * @member {string} kind
         * @memberof Proto.YoutubeItem
         * @instance
         */
        YoutubeItem.prototype.kind = "";

        /**
         * YoutubeItem snippet.
         * @member {Proto.YoutubeItem.ISnippet|null|undefined} snippet
         * @memberof Proto.YoutubeItem
         * @instance
         */
        YoutubeItem.prototype.snippet = null;

        /**
         * YoutubeItem contentDetails.
         * @member {Proto.YoutubeItem.IContentDetails|null|undefined} contentDetails
         * @memberof Proto.YoutubeItem
         * @instance
         */
        YoutubeItem.prototype.contentDetails = null;

        /**
         * YoutubeItem fileDetails.
         * @member {Proto.YoutubeItem.IFileDetails|null|undefined} fileDetails
         * @memberof Proto.YoutubeItem
         * @instance
         */
        YoutubeItem.prototype.fileDetails = null;

        /**
         * YoutubeItem status.
         * @member {Proto.YoutubeItem.IStatus|null|undefined} status
         * @memberof Proto.YoutubeItem
         * @instance
         */
        YoutubeItem.prototype.status = null;

        /**
         * Creates a new YoutubeItem instance using the specified properties.
         * @function create
         * @memberof Proto.YoutubeItem
         * @static
         * @param {Proto.IYoutubeItem=} [properties] Properties to set
         * @returns {Proto.YoutubeItem} YoutubeItem instance
         */
        YoutubeItem.create = function create(properties) {
            return new YoutubeItem(properties);
        };

        /**
         * Encodes the specified YoutubeItem message. Does not implicitly {@link Proto.YoutubeItem.verify|verify} messages.
         * @function encode
         * @memberof Proto.YoutubeItem
         * @static
         * @param {Proto.IYoutubeItem} m YoutubeItem message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        YoutubeItem.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                w.uint32(10).string(m.id);
            if (m.kind != null && Object.hasOwnProperty.call(m, "kind"))
                w.uint32(18).string(m.kind);
            if (m.snippet != null && Object.hasOwnProperty.call(m, "snippet"))
                $root.Proto.YoutubeItem.Snippet.encode(m.snippet, w.uint32(26).fork()).ldelim();
            if (m.contentDetails != null && Object.hasOwnProperty.call(m, "contentDetails"))
                $root.Proto.YoutubeItem.ContentDetails.encode(m.contentDetails, w.uint32(34).fork()).ldelim();
            if (m.fileDetails != null && Object.hasOwnProperty.call(m, "fileDetails"))
                $root.Proto.YoutubeItem.FileDetails.encode(m.fileDetails, w.uint32(42).fork()).ldelim();
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                $root.Proto.YoutubeItem.Status.encode(m.status, w.uint32(50).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a YoutubeItem message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.YoutubeItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.YoutubeItem} YoutubeItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        YoutubeItem.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.YoutubeItem();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.id = r.string();
                    break;
                case 2:
                    m.kind = r.string();
                    break;
                case 3:
                    m.snippet = $root.Proto.YoutubeItem.Snippet.decode(r, r.uint32());
                    break;
                case 4:
                    m.contentDetails = $root.Proto.YoutubeItem.ContentDetails.decode(r, r.uint32());
                    break;
                case 5:
                    m.fileDetails = $root.Proto.YoutubeItem.FileDetails.decode(r, r.uint32());
                    break;
                case 6:
                    m.status = $root.Proto.YoutubeItem.Status.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a YoutubeItem message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.YoutubeItem
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.YoutubeItem} YoutubeItem
         */
        YoutubeItem.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.YoutubeItem)
                return d;
            var m = new $root.Proto.YoutubeItem();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.kind != null) {
                m.kind = String(d.kind);
            }
            if (d.snippet != null) {
                if (typeof d.snippet !== "object")
                    throw TypeError(".Proto.YoutubeItem.snippet: object expected");
                m.snippet = $root.Proto.YoutubeItem.Snippet.fromObject(d.snippet);
            }
            if (d.contentDetails != null) {
                if (typeof d.contentDetails !== "object")
                    throw TypeError(".Proto.YoutubeItem.contentDetails: object expected");
                m.contentDetails = $root.Proto.YoutubeItem.ContentDetails.fromObject(d.contentDetails);
            }
            if (d.fileDetails != null) {
                if (typeof d.fileDetails !== "object")
                    throw TypeError(".Proto.YoutubeItem.fileDetails: object expected");
                m.fileDetails = $root.Proto.YoutubeItem.FileDetails.fromObject(d.fileDetails);
            }
            if (d.status != null) {
                if (typeof d.status !== "object")
                    throw TypeError(".Proto.YoutubeItem.status: object expected");
                m.status = $root.Proto.YoutubeItem.Status.fromObject(d.status);
            }
            return m;
        };

        /**
         * Creates a plain object from a YoutubeItem message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.YoutubeItem
         * @static
         * @param {Proto.YoutubeItem} m YoutubeItem
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        YoutubeItem.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.kind = "";
                d.snippet = null;
                d.contentDetails = null;
                d.fileDetails = null;
                d.status = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.kind != null && m.hasOwnProperty("kind")) {
                d.kind = m.kind;
            }
            if (m.snippet != null && m.hasOwnProperty("snippet")) {
                d.snippet = $root.Proto.YoutubeItem.Snippet.toObject(m.snippet, o);
            }
            if (m.contentDetails != null && m.hasOwnProperty("contentDetails")) {
                d.contentDetails = $root.Proto.YoutubeItem.ContentDetails.toObject(m.contentDetails, o);
            }
            if (m.fileDetails != null && m.hasOwnProperty("fileDetails")) {
                d.fileDetails = $root.Proto.YoutubeItem.FileDetails.toObject(m.fileDetails, o);
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = $root.Proto.YoutubeItem.Status.toObject(m.status, o);
            }
            return d;
        };

        /**
         * Converts this YoutubeItem to JSON.
         * @function toJSON
         * @memberof Proto.YoutubeItem
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        YoutubeItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        YoutubeItem.Thumbnail = (function() {

            /**
             * Properties of a Thumbnail.
             * @memberof Proto.YoutubeItem
             * @interface IThumbnail
             * @property {string|null} [url] Thumbnail url
             * @property {number|null} [width] Thumbnail width
             * @property {number|null} [height] Thumbnail height
             */

            /**
             * Constructs a new Thumbnail.
             * @memberof Proto.YoutubeItem
             * @classdesc Represents a Thumbnail.
             * @implements IThumbnail
             * @constructor
             * @param {Proto.YoutubeItem.IThumbnail=} [p] Properties to set
             */
            function Thumbnail(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Thumbnail url.
             * @member {string} url
             * @memberof Proto.YoutubeItem.Thumbnail
             * @instance
             */
            Thumbnail.prototype.url = "";

            /**
             * Thumbnail width.
             * @member {number} width
             * @memberof Proto.YoutubeItem.Thumbnail
             * @instance
             */
            Thumbnail.prototype.width = 0;

            /**
             * Thumbnail height.
             * @member {number} height
             * @memberof Proto.YoutubeItem.Thumbnail
             * @instance
             */
            Thumbnail.prototype.height = 0;

            /**
             * Creates a new Thumbnail instance using the specified properties.
             * @function create
             * @memberof Proto.YoutubeItem.Thumbnail
             * @static
             * @param {Proto.YoutubeItem.IThumbnail=} [properties] Properties to set
             * @returns {Proto.YoutubeItem.Thumbnail} Thumbnail instance
             */
            Thumbnail.create = function create(properties) {
                return new Thumbnail(properties);
            };

            /**
             * Encodes the specified Thumbnail message. Does not implicitly {@link Proto.YoutubeItem.Thumbnail.verify|verify} messages.
             * @function encode
             * @memberof Proto.YoutubeItem.Thumbnail
             * @static
             * @param {Proto.YoutubeItem.IThumbnail} m Thumbnail message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Thumbnail.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.url != null && Object.hasOwnProperty.call(m, "url"))
                    w.uint32(10).string(m.url);
                if (m.width != null && Object.hasOwnProperty.call(m, "width"))
                    w.uint32(16).int32(m.width);
                if (m.height != null && Object.hasOwnProperty.call(m, "height"))
                    w.uint32(24).int32(m.height);
                return w;
            };

            /**
             * Decodes a Thumbnail message from the specified reader or buffer.
             * @function decode
             * @memberof Proto.YoutubeItem.Thumbnail
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {Proto.YoutubeItem.Thumbnail} Thumbnail
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Thumbnail.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.YoutubeItem.Thumbnail();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.url = r.string();
                        break;
                    case 2:
                        m.width = r.int32();
                        break;
                    case 3:
                        m.height = r.int32();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Thumbnail message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Proto.YoutubeItem.Thumbnail
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {Proto.YoutubeItem.Thumbnail} Thumbnail
             */
            Thumbnail.fromObject = function fromObject(d) {
                if (d instanceof $root.Proto.YoutubeItem.Thumbnail)
                    return d;
                var m = new $root.Proto.YoutubeItem.Thumbnail();
                if (d.url != null) {
                    m.url = String(d.url);
                }
                if (d.width != null) {
                    m.width = d.width | 0;
                }
                if (d.height != null) {
                    m.height = d.height | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a Thumbnail message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Proto.YoutubeItem.Thumbnail
             * @static
             * @param {Proto.YoutubeItem.Thumbnail} m Thumbnail
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Thumbnail.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.url = "";
                    d.width = 0;
                    d.height = 0;
                }
                if (m.url != null && m.hasOwnProperty("url")) {
                    d.url = m.url;
                }
                if (m.width != null && m.hasOwnProperty("width")) {
                    d.width = m.width;
                }
                if (m.height != null && m.hasOwnProperty("height")) {
                    d.height = m.height;
                }
                return d;
            };

            /**
             * Converts this Thumbnail to JSON.
             * @function toJSON
             * @memberof Proto.YoutubeItem.Thumbnail
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Thumbnail.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Thumbnail;
        })();

        YoutubeItem.ThumbnailList = (function() {

            /**
             * Properties of a ThumbnailList.
             * @memberof Proto.YoutubeItem
             * @interface IThumbnailList
             * @property {Proto.YoutubeItem.IThumbnail|null} [basic] ThumbnailList basic
             * @property {Proto.YoutubeItem.IThumbnail|null} [medium] ThumbnailList medium
             * @property {Proto.YoutubeItem.IThumbnail|null} [high] ThumbnailList high
             */

            /**
             * Constructs a new ThumbnailList.
             * @memberof Proto.YoutubeItem
             * @classdesc Represents a ThumbnailList.
             * @implements IThumbnailList
             * @constructor
             * @param {Proto.YoutubeItem.IThumbnailList=} [p] Properties to set
             */
            function ThumbnailList(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ThumbnailList basic.
             * @member {Proto.YoutubeItem.IThumbnail|null|undefined} basic
             * @memberof Proto.YoutubeItem.ThumbnailList
             * @instance
             */
            ThumbnailList.prototype.basic = null;

            /**
             * ThumbnailList medium.
             * @member {Proto.YoutubeItem.IThumbnail|null|undefined} medium
             * @memberof Proto.YoutubeItem.ThumbnailList
             * @instance
             */
            ThumbnailList.prototype.medium = null;

            /**
             * ThumbnailList high.
             * @member {Proto.YoutubeItem.IThumbnail|null|undefined} high
             * @memberof Proto.YoutubeItem.ThumbnailList
             * @instance
             */
            ThumbnailList.prototype.high = null;

            /**
             * Creates a new ThumbnailList instance using the specified properties.
             * @function create
             * @memberof Proto.YoutubeItem.ThumbnailList
             * @static
             * @param {Proto.YoutubeItem.IThumbnailList=} [properties] Properties to set
             * @returns {Proto.YoutubeItem.ThumbnailList} ThumbnailList instance
             */
            ThumbnailList.create = function create(properties) {
                return new ThumbnailList(properties);
            };

            /**
             * Encodes the specified ThumbnailList message. Does not implicitly {@link Proto.YoutubeItem.ThumbnailList.verify|verify} messages.
             * @function encode
             * @memberof Proto.YoutubeItem.ThumbnailList
             * @static
             * @param {Proto.YoutubeItem.IThumbnailList} m ThumbnailList message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ThumbnailList.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.basic != null && Object.hasOwnProperty.call(m, "basic"))
                    $root.Proto.YoutubeItem.Thumbnail.encode(m.basic, w.uint32(10).fork()).ldelim();
                if (m.medium != null && Object.hasOwnProperty.call(m, "medium"))
                    $root.Proto.YoutubeItem.Thumbnail.encode(m.medium, w.uint32(18).fork()).ldelim();
                if (m.high != null && Object.hasOwnProperty.call(m, "high"))
                    $root.Proto.YoutubeItem.Thumbnail.encode(m.high, w.uint32(26).fork()).ldelim();
                return w;
            };

            /**
             * Decodes a ThumbnailList message from the specified reader or buffer.
             * @function decode
             * @memberof Proto.YoutubeItem.ThumbnailList
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {Proto.YoutubeItem.ThumbnailList} ThumbnailList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ThumbnailList.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.YoutubeItem.ThumbnailList();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.basic = $root.Proto.YoutubeItem.Thumbnail.decode(r, r.uint32());
                        break;
                    case 2:
                        m.medium = $root.Proto.YoutubeItem.Thumbnail.decode(r, r.uint32());
                        break;
                    case 3:
                        m.high = $root.Proto.YoutubeItem.Thumbnail.decode(r, r.uint32());
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ThumbnailList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Proto.YoutubeItem.ThumbnailList
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {Proto.YoutubeItem.ThumbnailList} ThumbnailList
             */
            ThumbnailList.fromObject = function fromObject(d) {
                if (d instanceof $root.Proto.YoutubeItem.ThumbnailList)
                    return d;
                var m = new $root.Proto.YoutubeItem.ThumbnailList();
                if (d.basic != null) {
                    if (typeof d.basic !== "object")
                        throw TypeError(".Proto.YoutubeItem.ThumbnailList.basic: object expected");
                    m.basic = $root.Proto.YoutubeItem.Thumbnail.fromObject(d.basic);
                }
                if (d.medium != null) {
                    if (typeof d.medium !== "object")
                        throw TypeError(".Proto.YoutubeItem.ThumbnailList.medium: object expected");
                    m.medium = $root.Proto.YoutubeItem.Thumbnail.fromObject(d.medium);
                }
                if (d.high != null) {
                    if (typeof d.high !== "object")
                        throw TypeError(".Proto.YoutubeItem.ThumbnailList.high: object expected");
                    m.high = $root.Proto.YoutubeItem.Thumbnail.fromObject(d.high);
                }
                return m;
            };

            /**
             * Creates a plain object from a ThumbnailList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Proto.YoutubeItem.ThumbnailList
             * @static
             * @param {Proto.YoutubeItem.ThumbnailList} m ThumbnailList
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ThumbnailList.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.basic = null;
                    d.medium = null;
                    d.high = null;
                }
                if (m.basic != null && m.hasOwnProperty("basic")) {
                    d.basic = $root.Proto.YoutubeItem.Thumbnail.toObject(m.basic, o);
                }
                if (m.medium != null && m.hasOwnProperty("medium")) {
                    d.medium = $root.Proto.YoutubeItem.Thumbnail.toObject(m.medium, o);
                }
                if (m.high != null && m.hasOwnProperty("high")) {
                    d.high = $root.Proto.YoutubeItem.Thumbnail.toObject(m.high, o);
                }
                return d;
            };

            /**
             * Converts this ThumbnailList to JSON.
             * @function toJSON
             * @memberof Proto.YoutubeItem.ThumbnailList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ThumbnailList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ThumbnailList;
        })();

        YoutubeItem.Snippet = (function() {

            /**
             * Properties of a Snippet.
             * @memberof Proto.YoutubeItem
             * @interface ISnippet
             * @property {string|null} [title] Snippet title
             * @property {string|null} [description] Snippet description
             * @property {number|Long|null} [publishedAt] Snippet publishedAt
             * @property {Proto.YoutubeItem.IThumbnailList|null} [thumbnails] Snippet thumbnails
             * @property {number|Long|null} [scheduledStartTime] Snippet scheduledStartTime
             * @property {number|Long|null} [scheduledEndTime] Snippet scheduledEndTime
             * @property {number|Long|null} [actualStartTime] Snippet actualStartTime
             * @property {number|Long|null} [actualEndTime] Snippet actualEndTime
             */

            /**
             * Constructs a new Snippet.
             * @memberof Proto.YoutubeItem
             * @classdesc Represents a Snippet.
             * @implements ISnippet
             * @constructor
             * @param {Proto.YoutubeItem.ISnippet=} [p] Properties to set
             */
            function Snippet(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Snippet title.
             * @member {string} title
             * @memberof Proto.YoutubeItem.Snippet
             * @instance
             */
            Snippet.prototype.title = "";

            /**
             * Snippet description.
             * @member {string} description
             * @memberof Proto.YoutubeItem.Snippet
             * @instance
             */
            Snippet.prototype.description = "";

            /**
             * Snippet publishedAt.
             * @member {number|Long} publishedAt
             * @memberof Proto.YoutubeItem.Snippet
             * @instance
             */
            Snippet.prototype.publishedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Snippet thumbnails.
             * @member {Proto.YoutubeItem.IThumbnailList|null|undefined} thumbnails
             * @memberof Proto.YoutubeItem.Snippet
             * @instance
             */
            Snippet.prototype.thumbnails = null;

            /**
             * Snippet scheduledStartTime.
             * @member {number|Long} scheduledStartTime
             * @memberof Proto.YoutubeItem.Snippet
             * @instance
             */
            Snippet.prototype.scheduledStartTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Snippet scheduledEndTime.
             * @member {number|Long} scheduledEndTime
             * @memberof Proto.YoutubeItem.Snippet
             * @instance
             */
            Snippet.prototype.scheduledEndTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Snippet actualStartTime.
             * @member {number|Long} actualStartTime
             * @memberof Proto.YoutubeItem.Snippet
             * @instance
             */
            Snippet.prototype.actualStartTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Snippet actualEndTime.
             * @member {number|Long} actualEndTime
             * @memberof Proto.YoutubeItem.Snippet
             * @instance
             */
            Snippet.prototype.actualEndTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new Snippet instance using the specified properties.
             * @function create
             * @memberof Proto.YoutubeItem.Snippet
             * @static
             * @param {Proto.YoutubeItem.ISnippet=} [properties] Properties to set
             * @returns {Proto.YoutubeItem.Snippet} Snippet instance
             */
            Snippet.create = function create(properties) {
                return new Snippet(properties);
            };

            /**
             * Encodes the specified Snippet message. Does not implicitly {@link Proto.YoutubeItem.Snippet.verify|verify} messages.
             * @function encode
             * @memberof Proto.YoutubeItem.Snippet
             * @static
             * @param {Proto.YoutubeItem.ISnippet} m Snippet message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Snippet.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                    w.uint32(10).string(m.title);
                if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                    w.uint32(18).string(m.description);
                if (m.publishedAt != null && Object.hasOwnProperty.call(m, "publishedAt"))
                    w.uint32(24).int64(m.publishedAt);
                if (m.thumbnails != null && Object.hasOwnProperty.call(m, "thumbnails"))
                    $root.Proto.YoutubeItem.ThumbnailList.encode(m.thumbnails, w.uint32(34).fork()).ldelim();
                if (m.scheduledStartTime != null && Object.hasOwnProperty.call(m, "scheduledStartTime"))
                    w.uint32(40).int64(m.scheduledStartTime);
                if (m.scheduledEndTime != null && Object.hasOwnProperty.call(m, "scheduledEndTime"))
                    w.uint32(48).int64(m.scheduledEndTime);
                if (m.actualStartTime != null && Object.hasOwnProperty.call(m, "actualStartTime"))
                    w.uint32(56).int64(m.actualStartTime);
                if (m.actualEndTime != null && Object.hasOwnProperty.call(m, "actualEndTime"))
                    w.uint32(64).int64(m.actualEndTime);
                return w;
            };

            /**
             * Decodes a Snippet message from the specified reader or buffer.
             * @function decode
             * @memberof Proto.YoutubeItem.Snippet
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {Proto.YoutubeItem.Snippet} Snippet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Snippet.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.YoutubeItem.Snippet();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.title = r.string();
                        break;
                    case 2:
                        m.description = r.string();
                        break;
                    case 3:
                        m.publishedAt = r.int64();
                        break;
                    case 4:
                        m.thumbnails = $root.Proto.YoutubeItem.ThumbnailList.decode(r, r.uint32());
                        break;
                    case 5:
                        m.scheduledStartTime = r.int64();
                        break;
                    case 6:
                        m.scheduledEndTime = r.int64();
                        break;
                    case 7:
                        m.actualStartTime = r.int64();
                        break;
                    case 8:
                        m.actualEndTime = r.int64();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Snippet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Proto.YoutubeItem.Snippet
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {Proto.YoutubeItem.Snippet} Snippet
             */
            Snippet.fromObject = function fromObject(d) {
                if (d instanceof $root.Proto.YoutubeItem.Snippet)
                    return d;
                var m = new $root.Proto.YoutubeItem.Snippet();
                if (d.title != null) {
                    m.title = String(d.title);
                }
                if (d.description != null) {
                    m.description = String(d.description);
                }
                if (d.publishedAt != null) {
                    if ($util.Long)
                        (m.publishedAt = $util.Long.fromValue(d.publishedAt)).unsigned = false;
                    else if (typeof d.publishedAt === "string")
                        m.publishedAt = parseInt(d.publishedAt, 10);
                    else if (typeof d.publishedAt === "number")
                        m.publishedAt = d.publishedAt;
                    else if (typeof d.publishedAt === "object")
                        m.publishedAt = new $util.LongBits(d.publishedAt.low >>> 0, d.publishedAt.high >>> 0).toNumber();
                }
                if (d.thumbnails != null) {
                    if (typeof d.thumbnails !== "object")
                        throw TypeError(".Proto.YoutubeItem.Snippet.thumbnails: object expected");
                    m.thumbnails = $root.Proto.YoutubeItem.ThumbnailList.fromObject(d.thumbnails);
                }
                if (d.scheduledStartTime != null) {
                    if ($util.Long)
                        (m.scheduledStartTime = $util.Long.fromValue(d.scheduledStartTime)).unsigned = false;
                    else if (typeof d.scheduledStartTime === "string")
                        m.scheduledStartTime = parseInt(d.scheduledStartTime, 10);
                    else if (typeof d.scheduledStartTime === "number")
                        m.scheduledStartTime = d.scheduledStartTime;
                    else if (typeof d.scheduledStartTime === "object")
                        m.scheduledStartTime = new $util.LongBits(d.scheduledStartTime.low >>> 0, d.scheduledStartTime.high >>> 0).toNumber();
                }
                if (d.scheduledEndTime != null) {
                    if ($util.Long)
                        (m.scheduledEndTime = $util.Long.fromValue(d.scheduledEndTime)).unsigned = false;
                    else if (typeof d.scheduledEndTime === "string")
                        m.scheduledEndTime = parseInt(d.scheduledEndTime, 10);
                    else if (typeof d.scheduledEndTime === "number")
                        m.scheduledEndTime = d.scheduledEndTime;
                    else if (typeof d.scheduledEndTime === "object")
                        m.scheduledEndTime = new $util.LongBits(d.scheduledEndTime.low >>> 0, d.scheduledEndTime.high >>> 0).toNumber();
                }
                if (d.actualStartTime != null) {
                    if ($util.Long)
                        (m.actualStartTime = $util.Long.fromValue(d.actualStartTime)).unsigned = false;
                    else if (typeof d.actualStartTime === "string")
                        m.actualStartTime = parseInt(d.actualStartTime, 10);
                    else if (typeof d.actualStartTime === "number")
                        m.actualStartTime = d.actualStartTime;
                    else if (typeof d.actualStartTime === "object")
                        m.actualStartTime = new $util.LongBits(d.actualStartTime.low >>> 0, d.actualStartTime.high >>> 0).toNumber();
                }
                if (d.actualEndTime != null) {
                    if ($util.Long)
                        (m.actualEndTime = $util.Long.fromValue(d.actualEndTime)).unsigned = false;
                    else if (typeof d.actualEndTime === "string")
                        m.actualEndTime = parseInt(d.actualEndTime, 10);
                    else if (typeof d.actualEndTime === "number")
                        m.actualEndTime = d.actualEndTime;
                    else if (typeof d.actualEndTime === "object")
                        m.actualEndTime = new $util.LongBits(d.actualEndTime.low >>> 0, d.actualEndTime.high >>> 0).toNumber();
                }
                return m;
            };

            /**
             * Creates a plain object from a Snippet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Proto.YoutubeItem.Snippet
             * @static
             * @param {Proto.YoutubeItem.Snippet} m Snippet
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Snippet.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.title = "";
                    d.description = "";
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.publishedAt = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.publishedAt = o.longs === String ? "0" : 0;
                    d.thumbnails = null;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.scheduledStartTime = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.scheduledStartTime = o.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.scheduledEndTime = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.scheduledEndTime = o.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.actualStartTime = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.actualStartTime = o.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.actualEndTime = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.actualEndTime = o.longs === String ? "0" : 0;
                }
                if (m.title != null && m.hasOwnProperty("title")) {
                    d.title = m.title;
                }
                if (m.description != null && m.hasOwnProperty("description")) {
                    d.description = m.description;
                }
                if (m.publishedAt != null && m.hasOwnProperty("publishedAt")) {
                    if (typeof m.publishedAt === "number")
                        d.publishedAt = o.longs === String ? String(m.publishedAt) : m.publishedAt;
                    else
                        d.publishedAt = o.longs === String ? $util.Long.prototype.toString.call(m.publishedAt) : o.longs === Number ? new $util.LongBits(m.publishedAt.low >>> 0, m.publishedAt.high >>> 0).toNumber() : m.publishedAt;
                }
                if (m.thumbnails != null && m.hasOwnProperty("thumbnails")) {
                    d.thumbnails = $root.Proto.YoutubeItem.ThumbnailList.toObject(m.thumbnails, o);
                }
                if (m.scheduledStartTime != null && m.hasOwnProperty("scheduledStartTime")) {
                    if (typeof m.scheduledStartTime === "number")
                        d.scheduledStartTime = o.longs === String ? String(m.scheduledStartTime) : m.scheduledStartTime;
                    else
                        d.scheduledStartTime = o.longs === String ? $util.Long.prototype.toString.call(m.scheduledStartTime) : o.longs === Number ? new $util.LongBits(m.scheduledStartTime.low >>> 0, m.scheduledStartTime.high >>> 0).toNumber() : m.scheduledStartTime;
                }
                if (m.scheduledEndTime != null && m.hasOwnProperty("scheduledEndTime")) {
                    if (typeof m.scheduledEndTime === "number")
                        d.scheduledEndTime = o.longs === String ? String(m.scheduledEndTime) : m.scheduledEndTime;
                    else
                        d.scheduledEndTime = o.longs === String ? $util.Long.prototype.toString.call(m.scheduledEndTime) : o.longs === Number ? new $util.LongBits(m.scheduledEndTime.low >>> 0, m.scheduledEndTime.high >>> 0).toNumber() : m.scheduledEndTime;
                }
                if (m.actualStartTime != null && m.hasOwnProperty("actualStartTime")) {
                    if (typeof m.actualStartTime === "number")
                        d.actualStartTime = o.longs === String ? String(m.actualStartTime) : m.actualStartTime;
                    else
                        d.actualStartTime = o.longs === String ? $util.Long.prototype.toString.call(m.actualStartTime) : o.longs === Number ? new $util.LongBits(m.actualStartTime.low >>> 0, m.actualStartTime.high >>> 0).toNumber() : m.actualStartTime;
                }
                if (m.actualEndTime != null && m.hasOwnProperty("actualEndTime")) {
                    if (typeof m.actualEndTime === "number")
                        d.actualEndTime = o.longs === String ? String(m.actualEndTime) : m.actualEndTime;
                    else
                        d.actualEndTime = o.longs === String ? $util.Long.prototype.toString.call(m.actualEndTime) : o.longs === Number ? new $util.LongBits(m.actualEndTime.low >>> 0, m.actualEndTime.high >>> 0).toNumber() : m.actualEndTime;
                }
                return d;
            };

            /**
             * Converts this Snippet to JSON.
             * @function toJSON
             * @memberof Proto.YoutubeItem.Snippet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Snippet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Snippet;
        })();

        YoutubeItem.ContentDetails = (function() {

            /**
             * Properties of a ContentDetails.
             * @memberof Proto.YoutubeItem
             * @interface IContentDetails
             * @property {string|null} [duration] ContentDetails duration
             * @property {string|null} [dimension] ContentDetails dimension
             * @property {string|null} [definition] ContentDetails definition
             * @property {boolean|null} [enableEmbed] ContentDetails enableEmbed
             * @property {boolean|null} [enableAutoStart] ContentDetails enableAutoStart
             * @property {number|Long|null} [durationInSec] ContentDetails durationInSec
             */

            /**
             * Constructs a new ContentDetails.
             * @memberof Proto.YoutubeItem
             * @classdesc Represents a ContentDetails.
             * @implements IContentDetails
             * @constructor
             * @param {Proto.YoutubeItem.IContentDetails=} [p] Properties to set
             */
            function ContentDetails(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * ContentDetails duration.
             * @member {string} duration
             * @memberof Proto.YoutubeItem.ContentDetails
             * @instance
             */
            ContentDetails.prototype.duration = "";

            /**
             * ContentDetails dimension.
             * @member {string} dimension
             * @memberof Proto.YoutubeItem.ContentDetails
             * @instance
             */
            ContentDetails.prototype.dimension = "";

            /**
             * ContentDetails definition.
             * @member {string} definition
             * @memberof Proto.YoutubeItem.ContentDetails
             * @instance
             */
            ContentDetails.prototype.definition = "";

            /**
             * ContentDetails enableEmbed.
             * @member {boolean} enableEmbed
             * @memberof Proto.YoutubeItem.ContentDetails
             * @instance
             */
            ContentDetails.prototype.enableEmbed = false;

            /**
             * ContentDetails enableAutoStart.
             * @member {boolean} enableAutoStart
             * @memberof Proto.YoutubeItem.ContentDetails
             * @instance
             */
            ContentDetails.prototype.enableAutoStart = false;

            /**
             * ContentDetails durationInSec.
             * @member {number|Long} durationInSec
             * @memberof Proto.YoutubeItem.ContentDetails
             * @instance
             */
            ContentDetails.prototype.durationInSec = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new ContentDetails instance using the specified properties.
             * @function create
             * @memberof Proto.YoutubeItem.ContentDetails
             * @static
             * @param {Proto.YoutubeItem.IContentDetails=} [properties] Properties to set
             * @returns {Proto.YoutubeItem.ContentDetails} ContentDetails instance
             */
            ContentDetails.create = function create(properties) {
                return new ContentDetails(properties);
            };

            /**
             * Encodes the specified ContentDetails message. Does not implicitly {@link Proto.YoutubeItem.ContentDetails.verify|verify} messages.
             * @function encode
             * @memberof Proto.YoutubeItem.ContentDetails
             * @static
             * @param {Proto.YoutubeItem.IContentDetails} m ContentDetails message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ContentDetails.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                    w.uint32(10).string(m.duration);
                if (m.dimension != null && Object.hasOwnProperty.call(m, "dimension"))
                    w.uint32(18).string(m.dimension);
                if (m.definition != null && Object.hasOwnProperty.call(m, "definition"))
                    w.uint32(26).string(m.definition);
                if (m.enableEmbed != null && Object.hasOwnProperty.call(m, "enableEmbed"))
                    w.uint32(32).bool(m.enableEmbed);
                if (m.enableAutoStart != null && Object.hasOwnProperty.call(m, "enableAutoStart"))
                    w.uint32(40).bool(m.enableAutoStart);
                if (m.durationInSec != null && Object.hasOwnProperty.call(m, "durationInSec"))
                    w.uint32(48).int64(m.durationInSec);
                return w;
            };

            /**
             * Decodes a ContentDetails message from the specified reader or buffer.
             * @function decode
             * @memberof Proto.YoutubeItem.ContentDetails
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {Proto.YoutubeItem.ContentDetails} ContentDetails
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ContentDetails.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.YoutubeItem.ContentDetails();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.duration = r.string();
                        break;
                    case 2:
                        m.dimension = r.string();
                        break;
                    case 3:
                        m.definition = r.string();
                        break;
                    case 4:
                        m.enableEmbed = r.bool();
                        break;
                    case 5:
                        m.enableAutoStart = r.bool();
                        break;
                    case 6:
                        m.durationInSec = r.int64();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a ContentDetails message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Proto.YoutubeItem.ContentDetails
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {Proto.YoutubeItem.ContentDetails} ContentDetails
             */
            ContentDetails.fromObject = function fromObject(d) {
                if (d instanceof $root.Proto.YoutubeItem.ContentDetails)
                    return d;
                var m = new $root.Proto.YoutubeItem.ContentDetails();
                if (d.duration != null) {
                    m.duration = String(d.duration);
                }
                if (d.dimension != null) {
                    m.dimension = String(d.dimension);
                }
                if (d.definition != null) {
                    m.definition = String(d.definition);
                }
                if (d.enableEmbed != null) {
                    m.enableEmbed = Boolean(d.enableEmbed);
                }
                if (d.enableAutoStart != null) {
                    m.enableAutoStart = Boolean(d.enableAutoStart);
                }
                if (d.durationInSec != null) {
                    if ($util.Long)
                        (m.durationInSec = $util.Long.fromValue(d.durationInSec)).unsigned = false;
                    else if (typeof d.durationInSec === "string")
                        m.durationInSec = parseInt(d.durationInSec, 10);
                    else if (typeof d.durationInSec === "number")
                        m.durationInSec = d.durationInSec;
                    else if (typeof d.durationInSec === "object")
                        m.durationInSec = new $util.LongBits(d.durationInSec.low >>> 0, d.durationInSec.high >>> 0).toNumber();
                }
                return m;
            };

            /**
             * Creates a plain object from a ContentDetails message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Proto.YoutubeItem.ContentDetails
             * @static
             * @param {Proto.YoutubeItem.ContentDetails} m ContentDetails
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ContentDetails.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.duration = "";
                    d.dimension = "";
                    d.definition = "";
                    d.enableEmbed = false;
                    d.enableAutoStart = false;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.durationInSec = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.durationInSec = o.longs === String ? "0" : 0;
                }
                if (m.duration != null && m.hasOwnProperty("duration")) {
                    d.duration = m.duration;
                }
                if (m.dimension != null && m.hasOwnProperty("dimension")) {
                    d.dimension = m.dimension;
                }
                if (m.definition != null && m.hasOwnProperty("definition")) {
                    d.definition = m.definition;
                }
                if (m.enableEmbed != null && m.hasOwnProperty("enableEmbed")) {
                    d.enableEmbed = m.enableEmbed;
                }
                if (m.enableAutoStart != null && m.hasOwnProperty("enableAutoStart")) {
                    d.enableAutoStart = m.enableAutoStart;
                }
                if (m.durationInSec != null && m.hasOwnProperty("durationInSec")) {
                    if (typeof m.durationInSec === "number")
                        d.durationInSec = o.longs === String ? String(m.durationInSec) : m.durationInSec;
                    else
                        d.durationInSec = o.longs === String ? $util.Long.prototype.toString.call(m.durationInSec) : o.longs === Number ? new $util.LongBits(m.durationInSec.low >>> 0, m.durationInSec.high >>> 0).toNumber() : m.durationInSec;
                }
                return d;
            };

            /**
             * Converts this ContentDetails to JSON.
             * @function toJSON
             * @memberof Proto.YoutubeItem.ContentDetails
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ContentDetails.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ContentDetails;
        })();

        YoutubeItem.FileVideoStream = (function() {

            /**
             * Properties of a FileVideoStream.
             * @memberof Proto.YoutubeItem
             * @interface IFileVideoStream
             * @property {number|null} [widthPixels] FileVideoStream widthPixels
             * @property {number|null} [heightPixels] FileVideoStream heightPixels
             * @property {number|Long|null} [bitrateBps] FileVideoStream bitrateBps
             * @property {number|null} [frameRateFps] FileVideoStream frameRateFps
             */

            /**
             * Constructs a new FileVideoStream.
             * @memberof Proto.YoutubeItem
             * @classdesc Represents a FileVideoStream.
             * @implements IFileVideoStream
             * @constructor
             * @param {Proto.YoutubeItem.IFileVideoStream=} [p] Properties to set
             */
            function FileVideoStream(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FileVideoStream widthPixels.
             * @member {number} widthPixels
             * @memberof Proto.YoutubeItem.FileVideoStream
             * @instance
             */
            FileVideoStream.prototype.widthPixels = 0;

            /**
             * FileVideoStream heightPixels.
             * @member {number} heightPixels
             * @memberof Proto.YoutubeItem.FileVideoStream
             * @instance
             */
            FileVideoStream.prototype.heightPixels = 0;

            /**
             * FileVideoStream bitrateBps.
             * @member {number|Long} bitrateBps
             * @memberof Proto.YoutubeItem.FileVideoStream
             * @instance
             */
            FileVideoStream.prototype.bitrateBps = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * FileVideoStream frameRateFps.
             * @member {number} frameRateFps
             * @memberof Proto.YoutubeItem.FileVideoStream
             * @instance
             */
            FileVideoStream.prototype.frameRateFps = 0;

            /**
             * Creates a new FileVideoStream instance using the specified properties.
             * @function create
             * @memberof Proto.YoutubeItem.FileVideoStream
             * @static
             * @param {Proto.YoutubeItem.IFileVideoStream=} [properties] Properties to set
             * @returns {Proto.YoutubeItem.FileVideoStream} FileVideoStream instance
             */
            FileVideoStream.create = function create(properties) {
                return new FileVideoStream(properties);
            };

            /**
             * Encodes the specified FileVideoStream message. Does not implicitly {@link Proto.YoutubeItem.FileVideoStream.verify|verify} messages.
             * @function encode
             * @memberof Proto.YoutubeItem.FileVideoStream
             * @static
             * @param {Proto.YoutubeItem.IFileVideoStream} m FileVideoStream message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileVideoStream.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.widthPixels != null && Object.hasOwnProperty.call(m, "widthPixels"))
                    w.uint32(8).int32(m.widthPixels);
                if (m.heightPixels != null && Object.hasOwnProperty.call(m, "heightPixels"))
                    w.uint32(16).int32(m.heightPixels);
                if (m.bitrateBps != null && Object.hasOwnProperty.call(m, "bitrateBps"))
                    w.uint32(24).int64(m.bitrateBps);
                if (m.frameRateFps != null && Object.hasOwnProperty.call(m, "frameRateFps"))
                    w.uint32(33).double(m.frameRateFps);
                return w;
            };

            /**
             * Decodes a FileVideoStream message from the specified reader or buffer.
             * @function decode
             * @memberof Proto.YoutubeItem.FileVideoStream
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {Proto.YoutubeItem.FileVideoStream} FileVideoStream
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileVideoStream.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.YoutubeItem.FileVideoStream();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.widthPixels = r.int32();
                        break;
                    case 2:
                        m.heightPixels = r.int32();
                        break;
                    case 3:
                        m.bitrateBps = r.int64();
                        break;
                    case 4:
                        m.frameRateFps = r.double();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FileVideoStream message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Proto.YoutubeItem.FileVideoStream
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {Proto.YoutubeItem.FileVideoStream} FileVideoStream
             */
            FileVideoStream.fromObject = function fromObject(d) {
                if (d instanceof $root.Proto.YoutubeItem.FileVideoStream)
                    return d;
                var m = new $root.Proto.YoutubeItem.FileVideoStream();
                if (d.widthPixels != null) {
                    m.widthPixels = d.widthPixels | 0;
                }
                if (d.heightPixels != null) {
                    m.heightPixels = d.heightPixels | 0;
                }
                if (d.bitrateBps != null) {
                    if ($util.Long)
                        (m.bitrateBps = $util.Long.fromValue(d.bitrateBps)).unsigned = false;
                    else if (typeof d.bitrateBps === "string")
                        m.bitrateBps = parseInt(d.bitrateBps, 10);
                    else if (typeof d.bitrateBps === "number")
                        m.bitrateBps = d.bitrateBps;
                    else if (typeof d.bitrateBps === "object")
                        m.bitrateBps = new $util.LongBits(d.bitrateBps.low >>> 0, d.bitrateBps.high >>> 0).toNumber();
                }
                if (d.frameRateFps != null) {
                    m.frameRateFps = Number(d.frameRateFps);
                }
                return m;
            };

            /**
             * Creates a plain object from a FileVideoStream message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Proto.YoutubeItem.FileVideoStream
             * @static
             * @param {Proto.YoutubeItem.FileVideoStream} m FileVideoStream
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileVideoStream.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.widthPixels = 0;
                    d.heightPixels = 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.bitrateBps = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.bitrateBps = o.longs === String ? "0" : 0;
                    d.frameRateFps = 0;
                }
                if (m.widthPixels != null && m.hasOwnProperty("widthPixels")) {
                    d.widthPixels = m.widthPixels;
                }
                if (m.heightPixels != null && m.hasOwnProperty("heightPixels")) {
                    d.heightPixels = m.heightPixels;
                }
                if (m.bitrateBps != null && m.hasOwnProperty("bitrateBps")) {
                    if (typeof m.bitrateBps === "number")
                        d.bitrateBps = o.longs === String ? String(m.bitrateBps) : m.bitrateBps;
                    else
                        d.bitrateBps = o.longs === String ? $util.Long.prototype.toString.call(m.bitrateBps) : o.longs === Number ? new $util.LongBits(m.bitrateBps.low >>> 0, m.bitrateBps.high >>> 0).toNumber() : m.bitrateBps;
                }
                if (m.frameRateFps != null && m.hasOwnProperty("frameRateFps")) {
                    d.frameRateFps = o.json && !isFinite(m.frameRateFps) ? String(m.frameRateFps) : m.frameRateFps;
                }
                return d;
            };

            /**
             * Converts this FileVideoStream to JSON.
             * @function toJSON
             * @memberof Proto.YoutubeItem.FileVideoStream
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileVideoStream.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FileVideoStream;
        })();

        YoutubeItem.FileDetails = (function() {

            /**
             * Properties of a FileDetails.
             * @memberof Proto.YoutubeItem
             * @interface IFileDetails
             * @property {Array.<Proto.YoutubeItem.IFileVideoStream>|null} [videoStreams] FileDetails videoStreams
             */

            /**
             * Constructs a new FileDetails.
             * @memberof Proto.YoutubeItem
             * @classdesc Represents a FileDetails.
             * @implements IFileDetails
             * @constructor
             * @param {Proto.YoutubeItem.IFileDetails=} [p] Properties to set
             */
            function FileDetails(p) {
                this.videoStreams = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * FileDetails videoStreams.
             * @member {Array.<Proto.YoutubeItem.IFileVideoStream>} videoStreams
             * @memberof Proto.YoutubeItem.FileDetails
             * @instance
             */
            FileDetails.prototype.videoStreams = $util.emptyArray;

            /**
             * Creates a new FileDetails instance using the specified properties.
             * @function create
             * @memberof Proto.YoutubeItem.FileDetails
             * @static
             * @param {Proto.YoutubeItem.IFileDetails=} [properties] Properties to set
             * @returns {Proto.YoutubeItem.FileDetails} FileDetails instance
             */
            FileDetails.create = function create(properties) {
                return new FileDetails(properties);
            };

            /**
             * Encodes the specified FileDetails message. Does not implicitly {@link Proto.YoutubeItem.FileDetails.verify|verify} messages.
             * @function encode
             * @memberof Proto.YoutubeItem.FileDetails
             * @static
             * @param {Proto.YoutubeItem.IFileDetails} m FileDetails message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDetails.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.videoStreams != null && m.videoStreams.length) {
                    for (var i = 0; i < m.videoStreams.length; ++i)
                        $root.Proto.YoutubeItem.FileVideoStream.encode(m.videoStreams[i], w.uint32(10).fork()).ldelim();
                }
                return w;
            };

            /**
             * Decodes a FileDetails message from the specified reader or buffer.
             * @function decode
             * @memberof Proto.YoutubeItem.FileDetails
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {Proto.YoutubeItem.FileDetails} FileDetails
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDetails.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.YoutubeItem.FileDetails();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        if (!(m.videoStreams && m.videoStreams.length))
                            m.videoStreams = [];
                        m.videoStreams.push($root.Proto.YoutubeItem.FileVideoStream.decode(r, r.uint32()));
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a FileDetails message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Proto.YoutubeItem.FileDetails
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {Proto.YoutubeItem.FileDetails} FileDetails
             */
            FileDetails.fromObject = function fromObject(d) {
                if (d instanceof $root.Proto.YoutubeItem.FileDetails)
                    return d;
                var m = new $root.Proto.YoutubeItem.FileDetails();
                if (d.videoStreams) {
                    if (!Array.isArray(d.videoStreams))
                        throw TypeError(".Proto.YoutubeItem.FileDetails.videoStreams: array expected");
                    m.videoStreams = [];
                    for (var i = 0; i < d.videoStreams.length; ++i) {
                        if (typeof d.videoStreams[i] !== "object")
                            throw TypeError(".Proto.YoutubeItem.FileDetails.videoStreams: object expected");
                        m.videoStreams[i] = $root.Proto.YoutubeItem.FileVideoStream.fromObject(d.videoStreams[i]);
                    }
                }
                return m;
            };

            /**
             * Creates a plain object from a FileDetails message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Proto.YoutubeItem.FileDetails
             * @static
             * @param {Proto.YoutubeItem.FileDetails} m FileDetails
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDetails.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.videoStreams = [];
                }
                if (m.videoStreams && m.videoStreams.length) {
                    d.videoStreams = [];
                    for (var j = 0; j < m.videoStreams.length; ++j) {
                        d.videoStreams[j] = $root.Proto.YoutubeItem.FileVideoStream.toObject(m.videoStreams[j], o);
                    }
                }
                return d;
            };

            /**
             * Converts this FileDetails to JSON.
             * @function toJSON
             * @memberof Proto.YoutubeItem.FileDetails
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDetails.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FileDetails;
        })();

        YoutubeItem.Status = (function() {

            /**
             * Properties of a Status.
             * @memberof Proto.YoutubeItem
             * @interface IStatus
             * @property {string|null} [privacyStatus] Status privacyStatus
             * @property {string|null} [uploadStatus] Status uploadStatus
             * @property {string|null} [lifeCycleStatus] Status lifeCycleStatus
             * @property {string|null} [recordingStatus] Status recordingStatus
             * @property {boolean|null} [embeddable] Status embeddable
             */

            /**
             * Constructs a new Status.
             * @memberof Proto.YoutubeItem
             * @classdesc Represents a Status.
             * @implements IStatus
             * @constructor
             * @param {Proto.YoutubeItem.IStatus=} [p] Properties to set
             */
            function Status(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Status privacyStatus.
             * @member {string} privacyStatus
             * @memberof Proto.YoutubeItem.Status
             * @instance
             */
            Status.prototype.privacyStatus = "";

            /**
             * Status uploadStatus.
             * @member {string} uploadStatus
             * @memberof Proto.YoutubeItem.Status
             * @instance
             */
            Status.prototype.uploadStatus = "";

            /**
             * Status lifeCycleStatus.
             * @member {string} lifeCycleStatus
             * @memberof Proto.YoutubeItem.Status
             * @instance
             */
            Status.prototype.lifeCycleStatus = "";

            /**
             * Status recordingStatus.
             * @member {string} recordingStatus
             * @memberof Proto.YoutubeItem.Status
             * @instance
             */
            Status.prototype.recordingStatus = "";

            /**
             * Status embeddable.
             * @member {boolean} embeddable
             * @memberof Proto.YoutubeItem.Status
             * @instance
             */
            Status.prototype.embeddable = false;

            /**
             * Creates a new Status instance using the specified properties.
             * @function create
             * @memberof Proto.YoutubeItem.Status
             * @static
             * @param {Proto.YoutubeItem.IStatus=} [properties] Properties to set
             * @returns {Proto.YoutubeItem.Status} Status instance
             */
            Status.create = function create(properties) {
                return new Status(properties);
            };

            /**
             * Encodes the specified Status message. Does not implicitly {@link Proto.YoutubeItem.Status.verify|verify} messages.
             * @function encode
             * @memberof Proto.YoutubeItem.Status
             * @static
             * @param {Proto.YoutubeItem.IStatus} m Status message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Status.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.privacyStatus != null && Object.hasOwnProperty.call(m, "privacyStatus"))
                    w.uint32(10).string(m.privacyStatus);
                if (m.uploadStatus != null && Object.hasOwnProperty.call(m, "uploadStatus"))
                    w.uint32(18).string(m.uploadStatus);
                if (m.lifeCycleStatus != null && Object.hasOwnProperty.call(m, "lifeCycleStatus"))
                    w.uint32(26).string(m.lifeCycleStatus);
                if (m.recordingStatus != null && Object.hasOwnProperty.call(m, "recordingStatus"))
                    w.uint32(34).string(m.recordingStatus);
                if (m.embeddable != null && Object.hasOwnProperty.call(m, "embeddable"))
                    w.uint32(40).bool(m.embeddable);
                return w;
            };

            /**
             * Decodes a Status message from the specified reader or buffer.
             * @function decode
             * @memberof Proto.YoutubeItem.Status
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {Proto.YoutubeItem.Status} Status
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Status.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.YoutubeItem.Status();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.privacyStatus = r.string();
                        break;
                    case 2:
                        m.uploadStatus = r.string();
                        break;
                    case 3:
                        m.lifeCycleStatus = r.string();
                        break;
                    case 4:
                        m.recordingStatus = r.string();
                        break;
                    case 5:
                        m.embeddable = r.bool();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a Status message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Proto.YoutubeItem.Status
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {Proto.YoutubeItem.Status} Status
             */
            Status.fromObject = function fromObject(d) {
                if (d instanceof $root.Proto.YoutubeItem.Status)
                    return d;
                var m = new $root.Proto.YoutubeItem.Status();
                if (d.privacyStatus != null) {
                    m.privacyStatus = String(d.privacyStatus);
                }
                if (d.uploadStatus != null) {
                    m.uploadStatus = String(d.uploadStatus);
                }
                if (d.lifeCycleStatus != null) {
                    m.lifeCycleStatus = String(d.lifeCycleStatus);
                }
                if (d.recordingStatus != null) {
                    m.recordingStatus = String(d.recordingStatus);
                }
                if (d.embeddable != null) {
                    m.embeddable = Boolean(d.embeddable);
                }
                return m;
            };

            /**
             * Creates a plain object from a Status message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Proto.YoutubeItem.Status
             * @static
             * @param {Proto.YoutubeItem.Status} m Status
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Status.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.privacyStatus = "";
                    d.uploadStatus = "";
                    d.lifeCycleStatus = "";
                    d.recordingStatus = "";
                    d.embeddable = false;
                }
                if (m.privacyStatus != null && m.hasOwnProperty("privacyStatus")) {
                    d.privacyStatus = m.privacyStatus;
                }
                if (m.uploadStatus != null && m.hasOwnProperty("uploadStatus")) {
                    d.uploadStatus = m.uploadStatus;
                }
                if (m.lifeCycleStatus != null && m.hasOwnProperty("lifeCycleStatus")) {
                    d.lifeCycleStatus = m.lifeCycleStatus;
                }
                if (m.recordingStatus != null && m.hasOwnProperty("recordingStatus")) {
                    d.recordingStatus = m.recordingStatus;
                }
                if (m.embeddable != null && m.hasOwnProperty("embeddable")) {
                    d.embeddable = m.embeddable;
                }
                return d;
            };

            /**
             * Converts this Status to JSON.
             * @function toJSON
             * @memberof Proto.YoutubeItem.Status
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Status.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Status;
        })();

        return YoutubeItem;
    })();

    Proto.YoutubeItemList = (function() {

        /**
         * Properties of a YoutubeItemList.
         * @memberof Proto
         * @interface IYoutubeItemList
         * @property {string|null} [kind] YoutubeItemList kind
         * @property {string|null} [etag] YoutubeItemList etag
         * @property {string|null} [prevPageToken] YoutubeItemList prevPageToken
         * @property {string|null} [nextPageToken] YoutubeItemList nextPageToken
         * @property {Proto.YoutubeItemList.IPageInfo|null} [pageInfo] YoutubeItemList pageInfo
         * @property {Array.<Proto.IYoutubeItem>|null} [items] YoutubeItemList items
         */

        /**
         * Constructs a new YoutubeItemList.
         * @memberof Proto
         * @classdesc Represents a YoutubeItemList.
         * @implements IYoutubeItemList
         * @constructor
         * @param {Proto.IYoutubeItemList=} [p] Properties to set
         */
        function YoutubeItemList(p) {
            this.items = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * YoutubeItemList kind.
         * @member {string} kind
         * @memberof Proto.YoutubeItemList
         * @instance
         */
        YoutubeItemList.prototype.kind = "";

        /**
         * YoutubeItemList etag.
         * @member {string} etag
         * @memberof Proto.YoutubeItemList
         * @instance
         */
        YoutubeItemList.prototype.etag = "";

        /**
         * YoutubeItemList prevPageToken.
         * @member {string} prevPageToken
         * @memberof Proto.YoutubeItemList
         * @instance
         */
        YoutubeItemList.prototype.prevPageToken = "";

        /**
         * YoutubeItemList nextPageToken.
         * @member {string} nextPageToken
         * @memberof Proto.YoutubeItemList
         * @instance
         */
        YoutubeItemList.prototype.nextPageToken = "";

        /**
         * YoutubeItemList pageInfo.
         * @member {Proto.YoutubeItemList.IPageInfo|null|undefined} pageInfo
         * @memberof Proto.YoutubeItemList
         * @instance
         */
        YoutubeItemList.prototype.pageInfo = null;

        /**
         * YoutubeItemList items.
         * @member {Array.<Proto.IYoutubeItem>} items
         * @memberof Proto.YoutubeItemList
         * @instance
         */
        YoutubeItemList.prototype.items = $util.emptyArray;

        /**
         * Creates a new YoutubeItemList instance using the specified properties.
         * @function create
         * @memberof Proto.YoutubeItemList
         * @static
         * @param {Proto.IYoutubeItemList=} [properties] Properties to set
         * @returns {Proto.YoutubeItemList} YoutubeItemList instance
         */
        YoutubeItemList.create = function create(properties) {
            return new YoutubeItemList(properties);
        };

        /**
         * Encodes the specified YoutubeItemList message. Does not implicitly {@link Proto.YoutubeItemList.verify|verify} messages.
         * @function encode
         * @memberof Proto.YoutubeItemList
         * @static
         * @param {Proto.IYoutubeItemList} m YoutubeItemList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        YoutubeItemList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.kind != null && Object.hasOwnProperty.call(m, "kind"))
                w.uint32(10).string(m.kind);
            if (m.etag != null && Object.hasOwnProperty.call(m, "etag"))
                w.uint32(18).string(m.etag);
            if (m.prevPageToken != null && Object.hasOwnProperty.call(m, "prevPageToken"))
                w.uint32(26).string(m.prevPageToken);
            if (m.nextPageToken != null && Object.hasOwnProperty.call(m, "nextPageToken"))
                w.uint32(34).string(m.nextPageToken);
            if (m.pageInfo != null && Object.hasOwnProperty.call(m, "pageInfo"))
                $root.Proto.YoutubeItemList.PageInfo.encode(m.pageInfo, w.uint32(42).fork()).ldelim();
            if (m.items != null && m.items.length) {
                for (var i = 0; i < m.items.length; ++i)
                    $root.Proto.YoutubeItem.encode(m.items[i], w.uint32(50).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a YoutubeItemList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.YoutubeItemList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.YoutubeItemList} YoutubeItemList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        YoutubeItemList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.YoutubeItemList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.kind = r.string();
                    break;
                case 2:
                    m.etag = r.string();
                    break;
                case 3:
                    m.prevPageToken = r.string();
                    break;
                case 4:
                    m.nextPageToken = r.string();
                    break;
                case 5:
                    m.pageInfo = $root.Proto.YoutubeItemList.PageInfo.decode(r, r.uint32());
                    break;
                case 6:
                    if (!(m.items && m.items.length))
                        m.items = [];
                    m.items.push($root.Proto.YoutubeItem.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a YoutubeItemList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.YoutubeItemList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.YoutubeItemList} YoutubeItemList
         */
        YoutubeItemList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.YoutubeItemList)
                return d;
            var m = new $root.Proto.YoutubeItemList();
            if (d.kind != null) {
                m.kind = String(d.kind);
            }
            if (d.etag != null) {
                m.etag = String(d.etag);
            }
            if (d.prevPageToken != null) {
                m.prevPageToken = String(d.prevPageToken);
            }
            if (d.nextPageToken != null) {
                m.nextPageToken = String(d.nextPageToken);
            }
            if (d.pageInfo != null) {
                if (typeof d.pageInfo !== "object")
                    throw TypeError(".Proto.YoutubeItemList.pageInfo: object expected");
                m.pageInfo = $root.Proto.YoutubeItemList.PageInfo.fromObject(d.pageInfo);
            }
            if (d.items) {
                if (!Array.isArray(d.items))
                    throw TypeError(".Proto.YoutubeItemList.items: array expected");
                m.items = [];
                for (var i = 0; i < d.items.length; ++i) {
                    if (typeof d.items[i] !== "object")
                        throw TypeError(".Proto.YoutubeItemList.items: object expected");
                    m.items[i] = $root.Proto.YoutubeItem.fromObject(d.items[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a YoutubeItemList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.YoutubeItemList
         * @static
         * @param {Proto.YoutubeItemList} m YoutubeItemList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        YoutubeItemList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.items = [];
            }
            if (o.defaults) {
                d.kind = "";
                d.etag = "";
                d.prevPageToken = "";
                d.nextPageToken = "";
                d.pageInfo = null;
            }
            if (m.kind != null && m.hasOwnProperty("kind")) {
                d.kind = m.kind;
            }
            if (m.etag != null && m.hasOwnProperty("etag")) {
                d.etag = m.etag;
            }
            if (m.prevPageToken != null && m.hasOwnProperty("prevPageToken")) {
                d.prevPageToken = m.prevPageToken;
            }
            if (m.nextPageToken != null && m.hasOwnProperty("nextPageToken")) {
                d.nextPageToken = m.nextPageToken;
            }
            if (m.pageInfo != null && m.hasOwnProperty("pageInfo")) {
                d.pageInfo = $root.Proto.YoutubeItemList.PageInfo.toObject(m.pageInfo, o);
            }
            if (m.items && m.items.length) {
                d.items = [];
                for (var j = 0; j < m.items.length; ++j) {
                    d.items[j] = $root.Proto.YoutubeItem.toObject(m.items[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this YoutubeItemList to JSON.
         * @function toJSON
         * @memberof Proto.YoutubeItemList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        YoutubeItemList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        YoutubeItemList.PageInfo = (function() {

            /**
             * Properties of a PageInfo.
             * @memberof Proto.YoutubeItemList
             * @interface IPageInfo
             * @property {number|null} [totalResults] PageInfo totalResults
             * @property {number|null} [resultsPerPage] PageInfo resultsPerPage
             */

            /**
             * Constructs a new PageInfo.
             * @memberof Proto.YoutubeItemList
             * @classdesc Represents a PageInfo.
             * @implements IPageInfo
             * @constructor
             * @param {Proto.YoutubeItemList.IPageInfo=} [p] Properties to set
             */
            function PageInfo(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * PageInfo totalResults.
             * @member {number} totalResults
             * @memberof Proto.YoutubeItemList.PageInfo
             * @instance
             */
            PageInfo.prototype.totalResults = 0;

            /**
             * PageInfo resultsPerPage.
             * @member {number} resultsPerPage
             * @memberof Proto.YoutubeItemList.PageInfo
             * @instance
             */
            PageInfo.prototype.resultsPerPage = 0;

            /**
             * Creates a new PageInfo instance using the specified properties.
             * @function create
             * @memberof Proto.YoutubeItemList.PageInfo
             * @static
             * @param {Proto.YoutubeItemList.IPageInfo=} [properties] Properties to set
             * @returns {Proto.YoutubeItemList.PageInfo} PageInfo instance
             */
            PageInfo.create = function create(properties) {
                return new PageInfo(properties);
            };

            /**
             * Encodes the specified PageInfo message. Does not implicitly {@link Proto.YoutubeItemList.PageInfo.verify|verify} messages.
             * @function encode
             * @memberof Proto.YoutubeItemList.PageInfo
             * @static
             * @param {Proto.YoutubeItemList.IPageInfo} m PageInfo message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PageInfo.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.totalResults != null && Object.hasOwnProperty.call(m, "totalResults"))
                    w.uint32(8).int32(m.totalResults);
                if (m.resultsPerPage != null && Object.hasOwnProperty.call(m, "resultsPerPage"))
                    w.uint32(16).int32(m.resultsPerPage);
                return w;
            };

            /**
             * Decodes a PageInfo message from the specified reader or buffer.
             * @function decode
             * @memberof Proto.YoutubeItemList.PageInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {Proto.YoutubeItemList.PageInfo} PageInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PageInfo.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.YoutubeItemList.PageInfo();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.totalResults = r.int32();
                        break;
                    case 2:
                        m.resultsPerPage = r.int32();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Creates a PageInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Proto.YoutubeItemList.PageInfo
             * @static
             * @param {Object.<string,*>} d Plain object
             * @returns {Proto.YoutubeItemList.PageInfo} PageInfo
             */
            PageInfo.fromObject = function fromObject(d) {
                if (d instanceof $root.Proto.YoutubeItemList.PageInfo)
                    return d;
                var m = new $root.Proto.YoutubeItemList.PageInfo();
                if (d.totalResults != null) {
                    m.totalResults = d.totalResults | 0;
                }
                if (d.resultsPerPage != null) {
                    m.resultsPerPage = d.resultsPerPage | 0;
                }
                return m;
            };

            /**
             * Creates a plain object from a PageInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Proto.YoutubeItemList.PageInfo
             * @static
             * @param {Proto.YoutubeItemList.PageInfo} m PageInfo
             * @param {$protobuf.IConversionOptions} [o] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PageInfo.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.totalResults = 0;
                    d.resultsPerPage = 0;
                }
                if (m.totalResults != null && m.hasOwnProperty("totalResults")) {
                    d.totalResults = m.totalResults;
                }
                if (m.resultsPerPage != null && m.hasOwnProperty("resultsPerPage")) {
                    d.resultsPerPage = m.resultsPerPage;
                }
                return d;
            };

            /**
             * Converts this PageInfo to JSON.
             * @function toJSON
             * @memberof Proto.YoutubeItemList.PageInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PageInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return PageInfo;
        })();

        return YoutubeItemList;
    })();

    Proto.GetConvertProgress = (function() {

        /**
         * Properties of a GetConvertProgress.
         * @memberof Proto
         * @interface IGetConvertProgress
         * @property {string|null} [uid] GetConvertProgress uid
         * @property {number|null} [progress] GetConvertProgress progress
         */

        /**
         * Constructs a new GetConvertProgress.
         * @memberof Proto
         * @classdesc Represents a GetConvertProgress.
         * @implements IGetConvertProgress
         * @constructor
         * @param {Proto.IGetConvertProgress=} [p] Properties to set
         */
        function GetConvertProgress(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * GetConvertProgress uid.
         * @member {string} uid
         * @memberof Proto.GetConvertProgress
         * @instance
         */
        GetConvertProgress.prototype.uid = "";

        /**
         * GetConvertProgress progress.
         * @member {number} progress
         * @memberof Proto.GetConvertProgress
         * @instance
         */
        GetConvertProgress.prototype.progress = 0;

        /**
         * Creates a new GetConvertProgress instance using the specified properties.
         * @function create
         * @memberof Proto.GetConvertProgress
         * @static
         * @param {Proto.IGetConvertProgress=} [properties] Properties to set
         * @returns {Proto.GetConvertProgress} GetConvertProgress instance
         */
        GetConvertProgress.create = function create(properties) {
            return new GetConvertProgress(properties);
        };

        /**
         * Encodes the specified GetConvertProgress message. Does not implicitly {@link Proto.GetConvertProgress.verify|verify} messages.
         * @function encode
         * @memberof Proto.GetConvertProgress
         * @static
         * @param {Proto.IGetConvertProgress} m GetConvertProgress message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetConvertProgress.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.progress != null && Object.hasOwnProperty.call(m, "progress"))
                w.uint32(16).int32(m.progress);
            return w;
        };

        /**
         * Decodes a GetConvertProgress message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.GetConvertProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.GetConvertProgress} GetConvertProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetConvertProgress.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.GetConvertProgress();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.progress = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a GetConvertProgress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.GetConvertProgress
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.GetConvertProgress} GetConvertProgress
         */
        GetConvertProgress.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.GetConvertProgress)
                return d;
            var m = new $root.Proto.GetConvertProgress();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.progress != null) {
                m.progress = d.progress | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a GetConvertProgress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.GetConvertProgress
         * @static
         * @param {Proto.GetConvertProgress} m GetConvertProgress
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetConvertProgress.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.progress = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.progress != null && m.hasOwnProperty("progress")) {
                d.progress = m.progress;
            }
            return d;
        };

        /**
         * Converts this GetConvertProgress to JSON.
         * @function toJSON
         * @memberof Proto.GetConvertProgress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetConvertProgress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetConvertProgress;
    })();

    Proto.GetConvertProgressList = (function() {

        /**
         * Properties of a GetConvertProgressList.
         * @memberof Proto
         * @interface IGetConvertProgressList
         * @property {Array.<Proto.IGetConvertProgress>|null} [value] GetConvertProgressList value
         */

        /**
         * Constructs a new GetConvertProgressList.
         * @memberof Proto
         * @classdesc Represents a GetConvertProgressList.
         * @implements IGetConvertProgressList
         * @constructor
         * @param {Proto.IGetConvertProgressList=} [p] Properties to set
         */
        function GetConvertProgressList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * GetConvertProgressList value.
         * @member {Array.<Proto.IGetConvertProgress>} value
         * @memberof Proto.GetConvertProgressList
         * @instance
         */
        GetConvertProgressList.prototype.value = $util.emptyArray;

        /**
         * Creates a new GetConvertProgressList instance using the specified properties.
         * @function create
         * @memberof Proto.GetConvertProgressList
         * @static
         * @param {Proto.IGetConvertProgressList=} [properties] Properties to set
         * @returns {Proto.GetConvertProgressList} GetConvertProgressList instance
         */
        GetConvertProgressList.create = function create(properties) {
            return new GetConvertProgressList(properties);
        };

        /**
         * Encodes the specified GetConvertProgressList message. Does not implicitly {@link Proto.GetConvertProgressList.verify|verify} messages.
         * @function encode
         * @memberof Proto.GetConvertProgressList
         * @static
         * @param {Proto.IGetConvertProgressList} m GetConvertProgressList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetConvertProgressList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.GetConvertProgress.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a GetConvertProgressList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.GetConvertProgressList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.GetConvertProgressList} GetConvertProgressList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetConvertProgressList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.GetConvertProgressList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.GetConvertProgress.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a GetConvertProgressList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.GetConvertProgressList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.GetConvertProgressList} GetConvertProgressList
         */
        GetConvertProgressList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.GetConvertProgressList)
                return d;
            var m = new $root.Proto.GetConvertProgressList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.GetConvertProgressList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.GetConvertProgressList.value: object expected");
                    m.value[i] = $root.Proto.GetConvertProgress.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a GetConvertProgressList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.GetConvertProgressList
         * @static
         * @param {Proto.GetConvertProgressList} m GetConvertProgressList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetConvertProgressList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.GetConvertProgress.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this GetConvertProgressList to JSON.
         * @function toJSON
         * @memberof Proto.GetConvertProgressList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetConvertProgressList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetConvertProgressList;
    })();

    Proto.Funding = (function() {

        /**
         * Properties of a Funding.
         * @memberof Proto
         * @interface IFunding
         * @property {string|null} [uid] Funding uid
         * @property {string|null} [title] Funding title
         * @property {number|null} [type] Funding type
         * @property {string|null} [description] Funding description
         * @property {number|null} [price] Funding price
         * @property {string|null} [currency] Funding currency
         * @property {string|null} [fundingCount] Funding fundingCount
         * @property {number|null} [fundingPrice] Funding fundingPrice
         * @property {number|Long|null} [startDate] Funding startDate
         * @property {number|Long|null} [endDate] Funding endDate
         * @property {number|Long|null} [returnDate] Funding returnDate
         * @property {number|null} [distribution] Funding distribution
         * @property {Proto.IUploadFile|null} [image] Funding image
         * @property {Proto.IUploadFile|null} [listImage] Funding listImage
         * @property {string|null} [listImageOrder] Funding listImageOrder
         * @property {string|null} [variationOrder] Funding variationOrder
         * @property {number|Long|null} [registerDate] Funding registerDate
         * @property {number|Long|null} [modifyDate] Funding modifyDate
         * @property {number|null} [status] Funding status
         * @property {Array.<Proto.IFundingVariation>|null} [variationList] Funding variationList
         * @property {Array.<Proto.IContentFundingMap>|null} [contentMapList] Funding contentMapList
         * @property {Array.<Proto.IHashTag>|null} [adminTagList] Funding adminTagList
         * @property {number|null} [fundingStatus] Funding fundingStatus
         * @property {boolean|null} [main] Funding main
         * @property {boolean|null} [fixed] Funding fixed
         * @property {string|null} [targetContentUID] Funding targetContentUID
         * @property {number|null} [kgiType] Funding kgiType
         * @property {string|null} [kgiCount] Funding kgiCount
         * @property {number|Long|null} [allQuantity] Funding allQuantity
         */

        /**
         * Constructs a new Funding.
         * @memberof Proto
         * @classdesc Represents a Funding.
         * @implements IFunding
         * @constructor
         * @param {Proto.IFunding=} [p] Properties to set
         */
        function Funding(p) {
            this.variationList = [];
            this.contentMapList = [];
            this.adminTagList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Funding uid.
         * @member {string} uid
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.uid = "";

        /**
         * Funding title.
         * @member {string} title
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.title = "";

        /**
         * Funding type.
         * @member {number} type
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.type = 0;

        /**
         * Funding description.
         * @member {string} description
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.description = "";

        /**
         * Funding price.
         * @member {number} price
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.price = 0;

        /**
         * Funding currency.
         * @member {string} currency
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.currency = "";

        /**
         * Funding fundingCount.
         * @member {string} fundingCount
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.fundingCount = "";

        /**
         * Funding fundingPrice.
         * @member {number} fundingPrice
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.fundingPrice = 0;

        /**
         * Funding startDate.
         * @member {number|Long} startDate
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Funding endDate.
         * @member {number|Long} endDate
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Funding returnDate.
         * @member {number|Long} returnDate
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.returnDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Funding distribution.
         * @member {number} distribution
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.distribution = 0;

        /**
         * Funding image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.image = null;

        /**
         * Funding listImage.
         * @member {Proto.IUploadFile|null|undefined} listImage
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.listImage = null;

        /**
         * Funding listImageOrder.
         * @member {string} listImageOrder
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.listImageOrder = "";

        /**
         * Funding variationOrder.
         * @member {string} variationOrder
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.variationOrder = "";

        /**
         * Funding registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Funding modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Funding status.
         * @member {number} status
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.status = 0;

        /**
         * Funding variationList.
         * @member {Array.<Proto.IFundingVariation>} variationList
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.variationList = $util.emptyArray;

        /**
         * Funding contentMapList.
         * @member {Array.<Proto.IContentFundingMap>} contentMapList
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.contentMapList = $util.emptyArray;

        /**
         * Funding adminTagList.
         * @member {Array.<Proto.IHashTag>} adminTagList
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.adminTagList = $util.emptyArray;

        /**
         * Funding fundingStatus.
         * @member {number} fundingStatus
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.fundingStatus = 0;

        /**
         * Funding main.
         * @member {boolean} main
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.main = false;

        /**
         * Funding fixed.
         * @member {boolean} fixed
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.fixed = false;

        /**
         * Funding targetContentUID.
         * @member {string} targetContentUID
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.targetContentUID = "";

        /**
         * Funding kgiType.
         * @member {number} kgiType
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.kgiType = 0;

        /**
         * Funding kgiCount.
         * @member {string} kgiCount
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.kgiCount = "";

        /**
         * Funding allQuantity.
         * @member {number|Long} allQuantity
         * @memberof Proto.Funding
         * @instance
         */
        Funding.prototype.allQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Funding instance using the specified properties.
         * @function create
         * @memberof Proto.Funding
         * @static
         * @param {Proto.IFunding=} [properties] Properties to set
         * @returns {Proto.Funding} Funding instance
         */
        Funding.create = function create(properties) {
            return new Funding(properties);
        };

        /**
         * Encodes the specified Funding message. Does not implicitly {@link Proto.Funding.verify|verify} messages.
         * @function encode
         * @memberof Proto.Funding
         * @static
         * @param {Proto.IFunding} m Funding message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Funding.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(18).string(m.title);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(24).int32(m.type);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(34).string(m.description);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(41).double(m.price);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(50).string(m.currency);
            if (m.fundingCount != null && Object.hasOwnProperty.call(m, "fundingCount"))
                w.uint32(58).string(m.fundingCount);
            if (m.fundingPrice != null && Object.hasOwnProperty.call(m, "fundingPrice"))
                w.uint32(65).double(m.fundingPrice);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(72).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(80).int64(m.endDate);
            if (m.returnDate != null && Object.hasOwnProperty.call(m, "returnDate"))
                w.uint32(88).int64(m.returnDate);
            if (m.distribution != null && Object.hasOwnProperty.call(m, "distribution"))
                w.uint32(96).int32(m.distribution);
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(106).fork()).ldelim();
            if (m.listImage != null && Object.hasOwnProperty.call(m, "listImage"))
                $root.Proto.UploadFile.encode(m.listImage, w.uint32(114).fork()).ldelim();
            if (m.listImageOrder != null && Object.hasOwnProperty.call(m, "listImageOrder"))
                w.uint32(122).string(m.listImageOrder);
            if (m.variationOrder != null && Object.hasOwnProperty.call(m, "variationOrder"))
                w.uint32(130).string(m.variationOrder);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(136).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(144).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(152).int32(m.status);
            if (m.variationList != null && m.variationList.length) {
                for (var i = 0; i < m.variationList.length; ++i)
                    $root.Proto.FundingVariation.encode(m.variationList[i], w.uint32(162).fork()).ldelim();
            }
            if (m.contentMapList != null && m.contentMapList.length) {
                for (var i = 0; i < m.contentMapList.length; ++i)
                    $root.Proto.ContentFundingMap.encode(m.contentMapList[i], w.uint32(170).fork()).ldelim();
            }
            if (m.adminTagList != null && m.adminTagList.length) {
                for (var i = 0; i < m.adminTagList.length; ++i)
                    $root.Proto.HashTag.encode(m.adminTagList[i], w.uint32(178).fork()).ldelim();
            }
            if (m.fundingStatus != null && Object.hasOwnProperty.call(m, "fundingStatus"))
                w.uint32(184).int32(m.fundingStatus);
            if (m.main != null && Object.hasOwnProperty.call(m, "main"))
                w.uint32(192).bool(m.main);
            if (m.fixed != null && Object.hasOwnProperty.call(m, "fixed"))
                w.uint32(200).bool(m.fixed);
            if (m.targetContentUID != null && Object.hasOwnProperty.call(m, "targetContentUID"))
                w.uint32(210).string(m.targetContentUID);
            if (m.kgiType != null && Object.hasOwnProperty.call(m, "kgiType"))
                w.uint32(216).int32(m.kgiType);
            if (m.kgiCount != null && Object.hasOwnProperty.call(m, "kgiCount"))
                w.uint32(226).string(m.kgiCount);
            if (m.allQuantity != null && Object.hasOwnProperty.call(m, "allQuantity"))
                w.uint32(232).int64(m.allQuantity);
            return w;
        };

        /**
         * Decodes a Funding message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.Funding
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.Funding} Funding
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Funding.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.Funding();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.title = r.string();
                    break;
                case 3:
                    m.type = r.int32();
                    break;
                case 4:
                    m.description = r.string();
                    break;
                case 5:
                    m.price = r.double();
                    break;
                case 6:
                    m.currency = r.string();
                    break;
                case 7:
                    m.fundingCount = r.string();
                    break;
                case 8:
                    m.fundingPrice = r.double();
                    break;
                case 9:
                    m.startDate = r.int64();
                    break;
                case 10:
                    m.endDate = r.int64();
                    break;
                case 11:
                    m.returnDate = r.int64();
                    break;
                case 12:
                    m.distribution = r.int32();
                    break;
                case 13:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 14:
                    m.listImage = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 15:
                    m.listImageOrder = r.string();
                    break;
                case 16:
                    m.variationOrder = r.string();
                    break;
                case 17:
                    m.registerDate = r.int64();
                    break;
                case 18:
                    m.modifyDate = r.int64();
                    break;
                case 19:
                    m.status = r.int32();
                    break;
                case 20:
                    if (!(m.variationList && m.variationList.length))
                        m.variationList = [];
                    m.variationList.push($root.Proto.FundingVariation.decode(r, r.uint32()));
                    break;
                case 21:
                    if (!(m.contentMapList && m.contentMapList.length))
                        m.contentMapList = [];
                    m.contentMapList.push($root.Proto.ContentFundingMap.decode(r, r.uint32()));
                    break;
                case 22:
                    if (!(m.adminTagList && m.adminTagList.length))
                        m.adminTagList = [];
                    m.adminTagList.push($root.Proto.HashTag.decode(r, r.uint32()));
                    break;
                case 23:
                    m.fundingStatus = r.int32();
                    break;
                case 24:
                    m.main = r.bool();
                    break;
                case 25:
                    m.fixed = r.bool();
                    break;
                case 26:
                    m.targetContentUID = r.string();
                    break;
                case 27:
                    m.kgiType = r.int32();
                    break;
                case 28:
                    m.kgiCount = r.string();
                    break;
                case 29:
                    m.allQuantity = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Funding message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.Funding
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.Funding} Funding
         */
        Funding.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.Funding)
                return d;
            var m = new $root.Proto.Funding();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.fundingCount != null) {
                m.fundingCount = String(d.fundingCount);
            }
            if (d.fundingPrice != null) {
                m.fundingPrice = Number(d.fundingPrice);
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.returnDate != null) {
                if ($util.Long)
                    (m.returnDate = $util.Long.fromValue(d.returnDate)).unsigned = false;
                else if (typeof d.returnDate === "string")
                    m.returnDate = parseInt(d.returnDate, 10);
                else if (typeof d.returnDate === "number")
                    m.returnDate = d.returnDate;
                else if (typeof d.returnDate === "object")
                    m.returnDate = new $util.LongBits(d.returnDate.low >>> 0, d.returnDate.high >>> 0).toNumber();
            }
            if (d.distribution != null) {
                m.distribution = d.distribution | 0;
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.Funding.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.listImage != null) {
                if (typeof d.listImage !== "object")
                    throw TypeError(".Proto.Funding.listImage: object expected");
                m.listImage = $root.Proto.UploadFile.fromObject(d.listImage);
            }
            if (d.listImageOrder != null) {
                m.listImageOrder = String(d.listImageOrder);
            }
            if (d.variationOrder != null) {
                m.variationOrder = String(d.variationOrder);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.variationList) {
                if (!Array.isArray(d.variationList))
                    throw TypeError(".Proto.Funding.variationList: array expected");
                m.variationList = [];
                for (var i = 0; i < d.variationList.length; ++i) {
                    if (typeof d.variationList[i] !== "object")
                        throw TypeError(".Proto.Funding.variationList: object expected");
                    m.variationList[i] = $root.Proto.FundingVariation.fromObject(d.variationList[i]);
                }
            }
            if (d.contentMapList) {
                if (!Array.isArray(d.contentMapList))
                    throw TypeError(".Proto.Funding.contentMapList: array expected");
                m.contentMapList = [];
                for (var i = 0; i < d.contentMapList.length; ++i) {
                    if (typeof d.contentMapList[i] !== "object")
                        throw TypeError(".Proto.Funding.contentMapList: object expected");
                    m.contentMapList[i] = $root.Proto.ContentFundingMap.fromObject(d.contentMapList[i]);
                }
            }
            if (d.adminTagList) {
                if (!Array.isArray(d.adminTagList))
                    throw TypeError(".Proto.Funding.adminTagList: array expected");
                m.adminTagList = [];
                for (var i = 0; i < d.adminTagList.length; ++i) {
                    if (typeof d.adminTagList[i] !== "object")
                        throw TypeError(".Proto.Funding.adminTagList: object expected");
                    m.adminTagList[i] = $root.Proto.HashTag.fromObject(d.adminTagList[i]);
                }
            }
            if (d.fundingStatus != null) {
                m.fundingStatus = d.fundingStatus | 0;
            }
            if (d.main != null) {
                m.main = Boolean(d.main);
            }
            if (d.fixed != null) {
                m.fixed = Boolean(d.fixed);
            }
            if (d.targetContentUID != null) {
                m.targetContentUID = String(d.targetContentUID);
            }
            if (d.kgiType != null) {
                m.kgiType = d.kgiType | 0;
            }
            if (d.kgiCount != null) {
                m.kgiCount = String(d.kgiCount);
            }
            if (d.allQuantity != null) {
                if ($util.Long)
                    (m.allQuantity = $util.Long.fromValue(d.allQuantity)).unsigned = false;
                else if (typeof d.allQuantity === "string")
                    m.allQuantity = parseInt(d.allQuantity, 10);
                else if (typeof d.allQuantity === "number")
                    m.allQuantity = d.allQuantity;
                else if (typeof d.allQuantity === "object")
                    m.allQuantity = new $util.LongBits(d.allQuantity.low >>> 0, d.allQuantity.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a Funding message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.Funding
         * @static
         * @param {Proto.Funding} m Funding
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Funding.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.variationList = [];
                d.contentMapList = [];
                d.adminTagList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.title = "";
                d.type = 0;
                d.description = "";
                d.price = 0;
                d.currency = "";
                d.fundingCount = "";
                d.fundingPrice = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.returnDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.returnDate = o.longs === String ? "0" : 0;
                d.distribution = 0;
                d.image = null;
                d.listImage = null;
                d.listImageOrder = "";
                d.variationOrder = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.fundingStatus = 0;
                d.main = false;
                d.fixed = false;
                d.targetContentUID = "";
                d.kgiType = 0;
                d.kgiCount = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.allQuantity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.allQuantity = o.longs === String ? "0" : 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.fundingCount != null && m.hasOwnProperty("fundingCount")) {
                d.fundingCount = m.fundingCount;
            }
            if (m.fundingPrice != null && m.hasOwnProperty("fundingPrice")) {
                d.fundingPrice = o.json && !isFinite(m.fundingPrice) ? String(m.fundingPrice) : m.fundingPrice;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.returnDate != null && m.hasOwnProperty("returnDate")) {
                if (typeof m.returnDate === "number")
                    d.returnDate = o.longs === String ? String(m.returnDate) : m.returnDate;
                else
                    d.returnDate = o.longs === String ? $util.Long.prototype.toString.call(m.returnDate) : o.longs === Number ? new $util.LongBits(m.returnDate.low >>> 0, m.returnDate.high >>> 0).toNumber() : m.returnDate;
            }
            if (m.distribution != null && m.hasOwnProperty("distribution")) {
                d.distribution = m.distribution;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.listImage != null && m.hasOwnProperty("listImage")) {
                d.listImage = $root.Proto.UploadFile.toObject(m.listImage, o);
            }
            if (m.listImageOrder != null && m.hasOwnProperty("listImageOrder")) {
                d.listImageOrder = m.listImageOrder;
            }
            if (m.variationOrder != null && m.hasOwnProperty("variationOrder")) {
                d.variationOrder = m.variationOrder;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.variationList && m.variationList.length) {
                d.variationList = [];
                for (var j = 0; j < m.variationList.length; ++j) {
                    d.variationList[j] = $root.Proto.FundingVariation.toObject(m.variationList[j], o);
                }
            }
            if (m.contentMapList && m.contentMapList.length) {
                d.contentMapList = [];
                for (var j = 0; j < m.contentMapList.length; ++j) {
                    d.contentMapList[j] = $root.Proto.ContentFundingMap.toObject(m.contentMapList[j], o);
                }
            }
            if (m.adminTagList && m.adminTagList.length) {
                d.adminTagList = [];
                for (var j = 0; j < m.adminTagList.length; ++j) {
                    d.adminTagList[j] = $root.Proto.HashTag.toObject(m.adminTagList[j], o);
                }
            }
            if (m.fundingStatus != null && m.hasOwnProperty("fundingStatus")) {
                d.fundingStatus = m.fundingStatus;
            }
            if (m.main != null && m.hasOwnProperty("main")) {
                d.main = m.main;
            }
            if (m.fixed != null && m.hasOwnProperty("fixed")) {
                d.fixed = m.fixed;
            }
            if (m.targetContentUID != null && m.hasOwnProperty("targetContentUID")) {
                d.targetContentUID = m.targetContentUID;
            }
            if (m.kgiType != null && m.hasOwnProperty("kgiType")) {
                d.kgiType = m.kgiType;
            }
            if (m.kgiCount != null && m.hasOwnProperty("kgiCount")) {
                d.kgiCount = m.kgiCount;
            }
            if (m.allQuantity != null && m.hasOwnProperty("allQuantity")) {
                if (typeof m.allQuantity === "number")
                    d.allQuantity = o.longs === String ? String(m.allQuantity) : m.allQuantity;
                else
                    d.allQuantity = o.longs === String ? $util.Long.prototype.toString.call(m.allQuantity) : o.longs === Number ? new $util.LongBits(m.allQuantity.low >>> 0, m.allQuantity.high >>> 0).toNumber() : m.allQuantity;
            }
            return d;
        };

        /**
         * Converts this Funding to JSON.
         * @function toJSON
         * @memberof Proto.Funding
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Funding.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Funding;
    })();

    Proto.FundingList = (function() {

        /**
         * Properties of a FundingList.
         * @memberof Proto
         * @interface IFundingList
         * @property {Array.<Proto.IFunding>|null} [value] FundingList value
         */

        /**
         * Constructs a new FundingList.
         * @memberof Proto
         * @classdesc Represents a FundingList.
         * @implements IFundingList
         * @constructor
         * @param {Proto.IFundingList=} [p] Properties to set
         */
        function FundingList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * FundingList value.
         * @member {Array.<Proto.IFunding>} value
         * @memberof Proto.FundingList
         * @instance
         */
        FundingList.prototype.value = $util.emptyArray;

        /**
         * Creates a new FundingList instance using the specified properties.
         * @function create
         * @memberof Proto.FundingList
         * @static
         * @param {Proto.IFundingList=} [properties] Properties to set
         * @returns {Proto.FundingList} FundingList instance
         */
        FundingList.create = function create(properties) {
            return new FundingList(properties);
        };

        /**
         * Encodes the specified FundingList message. Does not implicitly {@link Proto.FundingList.verify|verify} messages.
         * @function encode
         * @memberof Proto.FundingList
         * @static
         * @param {Proto.IFundingList} m FundingList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundingList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.Funding.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a FundingList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.FundingList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.FundingList} FundingList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundingList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.FundingList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.Funding.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a FundingList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.FundingList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.FundingList} FundingList
         */
        FundingList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.FundingList)
                return d;
            var m = new $root.Proto.FundingList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.FundingList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.FundingList.value: object expected");
                    m.value[i] = $root.Proto.Funding.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a FundingList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.FundingList
         * @static
         * @param {Proto.FundingList} m FundingList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FundingList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.Funding.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this FundingList to JSON.
         * @function toJSON
         * @memberof Proto.FundingList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FundingList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FundingList;
    })();

    Proto.FundingVariation = (function() {

        /**
         * Properties of a FundingVariation.
         * @memberof Proto
         * @interface IFundingVariation
         * @property {string|null} [uid] FundingVariation uid
         * @property {string|null} [fundingUID] FundingVariation fundingUID
         * @property {string|null} [name] FundingVariation name
         * @property {string|null} [description] FundingVariation description
         * @property {number|null} [price] FundingVariation price
         * @property {string|null} [currency] FundingVariation currency
         * @property {number|null} [quantityFirst] FundingVariation quantityFirst
         * @property {number|null} [quantity] FundingVariation quantity
         * @property {number|null} [quantityLimit] FundingVariation quantityLimit
         * @property {string|null} [fundingCount] FundingVariation fundingCount
         * @property {number|null} [fundingPrice] FundingVariation fundingPrice
         * @property {number|null} [status] FundingVariation status
         * @property {Proto.IUploadFile|null} [image] FundingVariation image
         * @property {Proto.ICommerceOrderCount|null} [orderCount] FundingVariation orderCount
         * @property {number|Long|null} [registerDate] FundingVariation registerDate
         * @property {number|Long|null} [modifyDate] FundingVariation modifyDate
         * @property {Array.<Proto.IPlaylist>|null} [playlistList] FundingVariation playlistList
         * @property {number|null} [returnType] FundingVariation returnType
         * @property {boolean|null} [returnAuto] FundingVariation returnAuto
         */

        /**
         * Constructs a new FundingVariation.
         * @memberof Proto
         * @classdesc Represents a FundingVariation.
         * @implements IFundingVariation
         * @constructor
         * @param {Proto.IFundingVariation=} [p] Properties to set
         */
        function FundingVariation(p) {
            this.playlistList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * FundingVariation uid.
         * @member {string} uid
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.uid = "";

        /**
         * FundingVariation fundingUID.
         * @member {string} fundingUID
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.fundingUID = "";

        /**
         * FundingVariation name.
         * @member {string} name
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.name = "";

        /**
         * FundingVariation description.
         * @member {string} description
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.description = "";

        /**
         * FundingVariation price.
         * @member {number} price
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.price = 0;

        /**
         * FundingVariation currency.
         * @member {string} currency
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.currency = "";

        /**
         * FundingVariation quantityFirst.
         * @member {number} quantityFirst
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.quantityFirst = 0;

        /**
         * FundingVariation quantity.
         * @member {number} quantity
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.quantity = 0;

        /**
         * FundingVariation quantityLimit.
         * @member {number} quantityLimit
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.quantityLimit = 0;

        /**
         * FundingVariation fundingCount.
         * @member {string} fundingCount
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.fundingCount = "";

        /**
         * FundingVariation fundingPrice.
         * @member {number} fundingPrice
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.fundingPrice = 0;

        /**
         * FundingVariation status.
         * @member {number} status
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.status = 0;

        /**
         * FundingVariation image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.image = null;

        /**
         * FundingVariation orderCount.
         * @member {Proto.ICommerceOrderCount|null|undefined} orderCount
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.orderCount = null;

        /**
         * FundingVariation registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * FundingVariation modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * FundingVariation playlistList.
         * @member {Array.<Proto.IPlaylist>} playlistList
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.playlistList = $util.emptyArray;

        /**
         * FundingVariation returnType.
         * @member {number} returnType
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.returnType = 0;

        /**
         * FundingVariation returnAuto.
         * @member {boolean} returnAuto
         * @memberof Proto.FundingVariation
         * @instance
         */
        FundingVariation.prototype.returnAuto = false;

        /**
         * Creates a new FundingVariation instance using the specified properties.
         * @function create
         * @memberof Proto.FundingVariation
         * @static
         * @param {Proto.IFundingVariation=} [properties] Properties to set
         * @returns {Proto.FundingVariation} FundingVariation instance
         */
        FundingVariation.create = function create(properties) {
            return new FundingVariation(properties);
        };

        /**
         * Encodes the specified FundingVariation message. Does not implicitly {@link Proto.FundingVariation.verify|verify} messages.
         * @function encode
         * @memberof Proto.FundingVariation
         * @static
         * @param {Proto.IFundingVariation} m FundingVariation message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundingVariation.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.fundingUID != null && Object.hasOwnProperty.call(m, "fundingUID"))
                w.uint32(18).string(m.fundingUID);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(34).string(m.description);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(41).double(m.price);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(50).string(m.currency);
            if (m.quantityFirst != null && Object.hasOwnProperty.call(m, "quantityFirst"))
                w.uint32(56).int32(m.quantityFirst);
            if (m.quantity != null && Object.hasOwnProperty.call(m, "quantity"))
                w.uint32(64).int32(m.quantity);
            if (m.quantityLimit != null && Object.hasOwnProperty.call(m, "quantityLimit"))
                w.uint32(72).int32(m.quantityLimit);
            if (m.fundingCount != null && Object.hasOwnProperty.call(m, "fundingCount"))
                w.uint32(82).string(m.fundingCount);
            if (m.fundingPrice != null && Object.hasOwnProperty.call(m, "fundingPrice"))
                w.uint32(89).double(m.fundingPrice);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(96).int32(m.status);
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(106).fork()).ldelim();
            if (m.orderCount != null && Object.hasOwnProperty.call(m, "orderCount"))
                $root.Proto.CommerceOrderCount.encode(m.orderCount, w.uint32(114).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(120).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(128).int64(m.modifyDate);
            if (m.playlistList != null && m.playlistList.length) {
                for (var i = 0; i < m.playlistList.length; ++i)
                    $root.Proto.Playlist.encode(m.playlistList[i], w.uint32(146).fork()).ldelim();
            }
            if (m.returnType != null && Object.hasOwnProperty.call(m, "returnType"))
                w.uint32(152).int32(m.returnType);
            if (m.returnAuto != null && Object.hasOwnProperty.call(m, "returnAuto"))
                w.uint32(160).bool(m.returnAuto);
            return w;
        };

        /**
         * Decodes a FundingVariation message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.FundingVariation
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.FundingVariation} FundingVariation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundingVariation.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.FundingVariation();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.fundingUID = r.string();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.description = r.string();
                    break;
                case 5:
                    m.price = r.double();
                    break;
                case 6:
                    m.currency = r.string();
                    break;
                case 7:
                    m.quantityFirst = r.int32();
                    break;
                case 8:
                    m.quantity = r.int32();
                    break;
                case 9:
                    m.quantityLimit = r.int32();
                    break;
                case 10:
                    m.fundingCount = r.string();
                    break;
                case 11:
                    m.fundingPrice = r.double();
                    break;
                case 12:
                    m.status = r.int32();
                    break;
                case 13:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 14:
                    m.orderCount = $root.Proto.CommerceOrderCount.decode(r, r.uint32());
                    break;
                case 15:
                    m.registerDate = r.int64();
                    break;
                case 16:
                    m.modifyDate = r.int64();
                    break;
                case 18:
                    if (!(m.playlistList && m.playlistList.length))
                        m.playlistList = [];
                    m.playlistList.push($root.Proto.Playlist.decode(r, r.uint32()));
                    break;
                case 19:
                    m.returnType = r.int32();
                    break;
                case 20:
                    m.returnAuto = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a FundingVariation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.FundingVariation
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.FundingVariation} FundingVariation
         */
        FundingVariation.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.FundingVariation)
                return d;
            var m = new $root.Proto.FundingVariation();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.fundingUID != null) {
                m.fundingUID = String(d.fundingUID);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.quantityFirst != null) {
                m.quantityFirst = d.quantityFirst | 0;
            }
            if (d.quantity != null) {
                m.quantity = d.quantity | 0;
            }
            if (d.quantityLimit != null) {
                m.quantityLimit = d.quantityLimit | 0;
            }
            if (d.fundingCount != null) {
                m.fundingCount = String(d.fundingCount);
            }
            if (d.fundingPrice != null) {
                m.fundingPrice = Number(d.fundingPrice);
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.FundingVariation.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.orderCount != null) {
                if (typeof d.orderCount !== "object")
                    throw TypeError(".Proto.FundingVariation.orderCount: object expected");
                m.orderCount = $root.Proto.CommerceOrderCount.fromObject(d.orderCount);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.playlistList) {
                if (!Array.isArray(d.playlistList))
                    throw TypeError(".Proto.FundingVariation.playlistList: array expected");
                m.playlistList = [];
                for (var i = 0; i < d.playlistList.length; ++i) {
                    if (typeof d.playlistList[i] !== "object")
                        throw TypeError(".Proto.FundingVariation.playlistList: object expected");
                    m.playlistList[i] = $root.Proto.Playlist.fromObject(d.playlistList[i]);
                }
            }
            if (d.returnType != null) {
                m.returnType = d.returnType | 0;
            }
            if (d.returnAuto != null) {
                m.returnAuto = Boolean(d.returnAuto);
            }
            return m;
        };

        /**
         * Creates a plain object from a FundingVariation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.FundingVariation
         * @static
         * @param {Proto.FundingVariation} m FundingVariation
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FundingVariation.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.playlistList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.fundingUID = "";
                d.name = "";
                d.description = "";
                d.price = 0;
                d.currency = "";
                d.quantityFirst = 0;
                d.quantity = 0;
                d.quantityLimit = 0;
                d.fundingCount = "";
                d.fundingPrice = 0;
                d.status = 0;
                d.image = null;
                d.orderCount = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.returnType = 0;
                d.returnAuto = false;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.fundingUID != null && m.hasOwnProperty("fundingUID")) {
                d.fundingUID = m.fundingUID;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.quantityFirst != null && m.hasOwnProperty("quantityFirst")) {
                d.quantityFirst = m.quantityFirst;
            }
            if (m.quantity != null && m.hasOwnProperty("quantity")) {
                d.quantity = m.quantity;
            }
            if (m.quantityLimit != null && m.hasOwnProperty("quantityLimit")) {
                d.quantityLimit = m.quantityLimit;
            }
            if (m.fundingCount != null && m.hasOwnProperty("fundingCount")) {
                d.fundingCount = m.fundingCount;
            }
            if (m.fundingPrice != null && m.hasOwnProperty("fundingPrice")) {
                d.fundingPrice = o.json && !isFinite(m.fundingPrice) ? String(m.fundingPrice) : m.fundingPrice;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.orderCount != null && m.hasOwnProperty("orderCount")) {
                d.orderCount = $root.Proto.CommerceOrderCount.toObject(m.orderCount, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.playlistList && m.playlistList.length) {
                d.playlistList = [];
                for (var j = 0; j < m.playlistList.length; ++j) {
                    d.playlistList[j] = $root.Proto.Playlist.toObject(m.playlistList[j], o);
                }
            }
            if (m.returnType != null && m.hasOwnProperty("returnType")) {
                d.returnType = m.returnType;
            }
            if (m.returnAuto != null && m.hasOwnProperty("returnAuto")) {
                d.returnAuto = m.returnAuto;
            }
            return d;
        };

        /**
         * Converts this FundingVariation to JSON.
         * @function toJSON
         * @memberof Proto.FundingVariation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FundingVariation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FundingVariation;
    })();

    Proto.ContentFundingMap = (function() {

        /**
         * Properties of a ContentFundingMap.
         * @memberof Proto
         * @interface IContentFundingMap
         * @property {string|null} [contentUID] ContentFundingMap contentUID
         * @property {string|null} [fundingUID] ContentFundingMap fundingUID
         * @property {number|Long|null} [registerDate] ContentFundingMap registerDate
         * @property {number|Long|null} [modifyDate] ContentFundingMap modifyDate
         * @property {number|null} [status] ContentFundingMap status
         * @property {string|null} [contentTitle] ContentFundingMap contentTitle
         * @property {number|null} [order] ContentFundingMap order
         * @property {Proto.IFunding|null} [funding] ContentFundingMap funding
         * @property {boolean|null} [main] ContentFundingMap main
         * @property {number|null} [distribution] ContentFundingMap distribution
         * @property {Proto.IUploadFile|null} [contentFile] ContentFundingMap contentFile
         * @property {number|null} [contentDistribution] ContentFundingMap contentDistribution
         * @property {number|Long|null} [contentStartDate] ContentFundingMap contentStartDate
         * @property {number|Long|null} [contentEndDate] ContentFundingMap contentEndDate
         * @property {number|null} [contentFundingPolicy] ContentFundingMap contentFundingPolicy
         * @property {number|null} [contentPublicPolicy] ContentFundingMap contentPublicPolicy
         */

        /**
         * Constructs a new ContentFundingMap.
         * @memberof Proto
         * @classdesc Represents a ContentFundingMap.
         * @implements IContentFundingMap
         * @constructor
         * @param {Proto.IContentFundingMap=} [p] Properties to set
         */
        function ContentFundingMap(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentFundingMap contentUID.
         * @member {string} contentUID
         * @memberof Proto.ContentFundingMap
         * @instance
         */
        ContentFundingMap.prototype.contentUID = "";

        /**
         * ContentFundingMap fundingUID.
         * @member {string} fundingUID
         * @memberof Proto.ContentFundingMap
         * @instance
         */
        ContentFundingMap.prototype.fundingUID = "";

        /**
         * ContentFundingMap registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentFundingMap
         * @instance
         */
        ContentFundingMap.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentFundingMap modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentFundingMap
         * @instance
         */
        ContentFundingMap.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentFundingMap status.
         * @member {number} status
         * @memberof Proto.ContentFundingMap
         * @instance
         */
        ContentFundingMap.prototype.status = 0;

        /**
         * ContentFundingMap contentTitle.
         * @member {string} contentTitle
         * @memberof Proto.ContentFundingMap
         * @instance
         */
        ContentFundingMap.prototype.contentTitle = "";

        /**
         * ContentFundingMap order.
         * @member {number} order
         * @memberof Proto.ContentFundingMap
         * @instance
         */
        ContentFundingMap.prototype.order = 0;

        /**
         * ContentFundingMap funding.
         * @member {Proto.IFunding|null|undefined} funding
         * @memberof Proto.ContentFundingMap
         * @instance
         */
        ContentFundingMap.prototype.funding = null;

        /**
         * ContentFundingMap main.
         * @member {boolean} main
         * @memberof Proto.ContentFundingMap
         * @instance
         */
        ContentFundingMap.prototype.main = false;

        /**
         * ContentFundingMap distribution.
         * @member {number} distribution
         * @memberof Proto.ContentFundingMap
         * @instance
         */
        ContentFundingMap.prototype.distribution = 0;

        /**
         * ContentFundingMap contentFile.
         * @member {Proto.IUploadFile|null|undefined} contentFile
         * @memberof Proto.ContentFundingMap
         * @instance
         */
        ContentFundingMap.prototype.contentFile = null;

        /**
         * ContentFundingMap contentDistribution.
         * @member {number} contentDistribution
         * @memberof Proto.ContentFundingMap
         * @instance
         */
        ContentFundingMap.prototype.contentDistribution = 0;

        /**
         * ContentFundingMap contentStartDate.
         * @member {number|Long} contentStartDate
         * @memberof Proto.ContentFundingMap
         * @instance
         */
        ContentFundingMap.prototype.contentStartDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentFundingMap contentEndDate.
         * @member {number|Long} contentEndDate
         * @memberof Proto.ContentFundingMap
         * @instance
         */
        ContentFundingMap.prototype.contentEndDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentFundingMap contentFundingPolicy.
         * @member {number} contentFundingPolicy
         * @memberof Proto.ContentFundingMap
         * @instance
         */
        ContentFundingMap.prototype.contentFundingPolicy = 0;

        /**
         * ContentFundingMap contentPublicPolicy.
         * @member {number} contentPublicPolicy
         * @memberof Proto.ContentFundingMap
         * @instance
         */
        ContentFundingMap.prototype.contentPublicPolicy = 0;

        /**
         * Creates a new ContentFundingMap instance using the specified properties.
         * @function create
         * @memberof Proto.ContentFundingMap
         * @static
         * @param {Proto.IContentFundingMap=} [properties] Properties to set
         * @returns {Proto.ContentFundingMap} ContentFundingMap instance
         */
        ContentFundingMap.create = function create(properties) {
            return new ContentFundingMap(properties);
        };

        /**
         * Encodes the specified ContentFundingMap message. Does not implicitly {@link Proto.ContentFundingMap.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentFundingMap
         * @static
         * @param {Proto.IContentFundingMap} m ContentFundingMap message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentFundingMap.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(10).string(m.contentUID);
            if (m.fundingUID != null && Object.hasOwnProperty.call(m, "fundingUID"))
                w.uint32(18).string(m.fundingUID);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(32).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(40).int32(m.status);
            if (m.contentTitle != null && Object.hasOwnProperty.call(m, "contentTitle"))
                w.uint32(50).string(m.contentTitle);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(56).int32(m.order);
            if (m.funding != null && Object.hasOwnProperty.call(m, "funding"))
                $root.Proto.Funding.encode(m.funding, w.uint32(66).fork()).ldelim();
            if (m.main != null && Object.hasOwnProperty.call(m, "main"))
                w.uint32(72).bool(m.main);
            if (m.distribution != null && Object.hasOwnProperty.call(m, "distribution"))
                w.uint32(80).int32(m.distribution);
            if (m.contentFile != null && Object.hasOwnProperty.call(m, "contentFile"))
                $root.Proto.UploadFile.encode(m.contentFile, w.uint32(90).fork()).ldelim();
            if (m.contentDistribution != null && Object.hasOwnProperty.call(m, "contentDistribution"))
                w.uint32(96).int32(m.contentDistribution);
            if (m.contentStartDate != null && Object.hasOwnProperty.call(m, "contentStartDate"))
                w.uint32(104).int64(m.contentStartDate);
            if (m.contentEndDate != null && Object.hasOwnProperty.call(m, "contentEndDate"))
                w.uint32(120).int64(m.contentEndDate);
            if (m.contentFundingPolicy != null && Object.hasOwnProperty.call(m, "contentFundingPolicy"))
                w.uint32(128).int32(m.contentFundingPolicy);
            if (m.contentPublicPolicy != null && Object.hasOwnProperty.call(m, "contentPublicPolicy"))
                w.uint32(136).int32(m.contentPublicPolicy);
            return w;
        };

        /**
         * Decodes a ContentFundingMap message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentFundingMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentFundingMap} ContentFundingMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentFundingMap.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentFundingMap();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.contentUID = r.string();
                    break;
                case 2:
                    m.fundingUID = r.string();
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    m.modifyDate = r.int64();
                    break;
                case 5:
                    m.status = r.int32();
                    break;
                case 6:
                    m.contentTitle = r.string();
                    break;
                case 7:
                    m.order = r.int32();
                    break;
                case 8:
                    m.funding = $root.Proto.Funding.decode(r, r.uint32());
                    break;
                case 9:
                    m.main = r.bool();
                    break;
                case 10:
                    m.distribution = r.int32();
                    break;
                case 11:
                    m.contentFile = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 12:
                    m.contentDistribution = r.int32();
                    break;
                case 13:
                    m.contentStartDate = r.int64();
                    break;
                case 15:
                    m.contentEndDate = r.int64();
                    break;
                case 16:
                    m.contentFundingPolicy = r.int32();
                    break;
                case 17:
                    m.contentPublicPolicy = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentFundingMap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentFundingMap
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentFundingMap} ContentFundingMap
         */
        ContentFundingMap.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentFundingMap)
                return d;
            var m = new $root.Proto.ContentFundingMap();
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.fundingUID != null) {
                m.fundingUID = String(d.fundingUID);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.contentTitle != null) {
                m.contentTitle = String(d.contentTitle);
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.funding != null) {
                if (typeof d.funding !== "object")
                    throw TypeError(".Proto.ContentFundingMap.funding: object expected");
                m.funding = $root.Proto.Funding.fromObject(d.funding);
            }
            if (d.main != null) {
                m.main = Boolean(d.main);
            }
            if (d.distribution != null) {
                m.distribution = d.distribution | 0;
            }
            if (d.contentFile != null) {
                if (typeof d.contentFile !== "object")
                    throw TypeError(".Proto.ContentFundingMap.contentFile: object expected");
                m.contentFile = $root.Proto.UploadFile.fromObject(d.contentFile);
            }
            if (d.contentDistribution != null) {
                m.contentDistribution = d.contentDistribution | 0;
            }
            if (d.contentStartDate != null) {
                if ($util.Long)
                    (m.contentStartDate = $util.Long.fromValue(d.contentStartDate)).unsigned = false;
                else if (typeof d.contentStartDate === "string")
                    m.contentStartDate = parseInt(d.contentStartDate, 10);
                else if (typeof d.contentStartDate === "number")
                    m.contentStartDate = d.contentStartDate;
                else if (typeof d.contentStartDate === "object")
                    m.contentStartDate = new $util.LongBits(d.contentStartDate.low >>> 0, d.contentStartDate.high >>> 0).toNumber();
            }
            if (d.contentEndDate != null) {
                if ($util.Long)
                    (m.contentEndDate = $util.Long.fromValue(d.contentEndDate)).unsigned = false;
                else if (typeof d.contentEndDate === "string")
                    m.contentEndDate = parseInt(d.contentEndDate, 10);
                else if (typeof d.contentEndDate === "number")
                    m.contentEndDate = d.contentEndDate;
                else if (typeof d.contentEndDate === "object")
                    m.contentEndDate = new $util.LongBits(d.contentEndDate.low >>> 0, d.contentEndDate.high >>> 0).toNumber();
            }
            if (d.contentFundingPolicy != null) {
                m.contentFundingPolicy = d.contentFundingPolicy | 0;
            }
            if (d.contentPublicPolicy != null) {
                m.contentPublicPolicy = d.contentPublicPolicy | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentFundingMap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentFundingMap
         * @static
         * @param {Proto.ContentFundingMap} m ContentFundingMap
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentFundingMap.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.contentUID = "";
                d.fundingUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.contentTitle = "";
                d.order = 0;
                d.funding = null;
                d.main = false;
                d.distribution = 0;
                d.contentFile = null;
                d.contentDistribution = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.contentStartDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.contentStartDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.contentEndDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.contentEndDate = o.longs === String ? "0" : 0;
                d.contentFundingPolicy = 0;
                d.contentPublicPolicy = 0;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.fundingUID != null && m.hasOwnProperty("fundingUID")) {
                d.fundingUID = m.fundingUID;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.contentTitle != null && m.hasOwnProperty("contentTitle")) {
                d.contentTitle = m.contentTitle;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.funding != null && m.hasOwnProperty("funding")) {
                d.funding = $root.Proto.Funding.toObject(m.funding, o);
            }
            if (m.main != null && m.hasOwnProperty("main")) {
                d.main = m.main;
            }
            if (m.distribution != null && m.hasOwnProperty("distribution")) {
                d.distribution = m.distribution;
            }
            if (m.contentFile != null && m.hasOwnProperty("contentFile")) {
                d.contentFile = $root.Proto.UploadFile.toObject(m.contentFile, o);
            }
            if (m.contentDistribution != null && m.hasOwnProperty("contentDistribution")) {
                d.contentDistribution = m.contentDistribution;
            }
            if (m.contentStartDate != null && m.hasOwnProperty("contentStartDate")) {
                if (typeof m.contentStartDate === "number")
                    d.contentStartDate = o.longs === String ? String(m.contentStartDate) : m.contentStartDate;
                else
                    d.contentStartDate = o.longs === String ? $util.Long.prototype.toString.call(m.contentStartDate) : o.longs === Number ? new $util.LongBits(m.contentStartDate.low >>> 0, m.contentStartDate.high >>> 0).toNumber() : m.contentStartDate;
            }
            if (m.contentEndDate != null && m.hasOwnProperty("contentEndDate")) {
                if (typeof m.contentEndDate === "number")
                    d.contentEndDate = o.longs === String ? String(m.contentEndDate) : m.contentEndDate;
                else
                    d.contentEndDate = o.longs === String ? $util.Long.prototype.toString.call(m.contentEndDate) : o.longs === Number ? new $util.LongBits(m.contentEndDate.low >>> 0, m.contentEndDate.high >>> 0).toNumber() : m.contentEndDate;
            }
            if (m.contentFundingPolicy != null && m.hasOwnProperty("contentFundingPolicy")) {
                d.contentFundingPolicy = m.contentFundingPolicy;
            }
            if (m.contentPublicPolicy != null && m.hasOwnProperty("contentPublicPolicy")) {
                d.contentPublicPolicy = m.contentPublicPolicy;
            }
            return d;
        };

        /**
         * Converts this ContentFundingMap to JSON.
         * @function toJSON
         * @memberof Proto.ContentFundingMap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentFundingMap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentFundingMap;
    })();

    Proto.ContentFundingMapList = (function() {

        /**
         * Properties of a ContentFundingMapList.
         * @memberof Proto
         * @interface IContentFundingMapList
         * @property {Array.<Proto.IContentFundingMap>|null} [value] ContentFundingMapList value
         */

        /**
         * Constructs a new ContentFundingMapList.
         * @memberof Proto
         * @classdesc Represents a ContentFundingMapList.
         * @implements IContentFundingMapList
         * @constructor
         * @param {Proto.IContentFundingMapList=} [p] Properties to set
         */
        function ContentFundingMapList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentFundingMapList value.
         * @member {Array.<Proto.IContentFundingMap>} value
         * @memberof Proto.ContentFundingMapList
         * @instance
         */
        ContentFundingMapList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentFundingMapList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentFundingMapList
         * @static
         * @param {Proto.IContentFundingMapList=} [properties] Properties to set
         * @returns {Proto.ContentFundingMapList} ContentFundingMapList instance
         */
        ContentFundingMapList.create = function create(properties) {
            return new ContentFundingMapList(properties);
        };

        /**
         * Encodes the specified ContentFundingMapList message. Does not implicitly {@link Proto.ContentFundingMapList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentFundingMapList
         * @static
         * @param {Proto.IContentFundingMapList} m ContentFundingMapList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentFundingMapList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentFundingMap.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentFundingMapList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentFundingMapList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentFundingMapList} ContentFundingMapList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentFundingMapList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentFundingMapList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentFundingMap.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentFundingMapList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentFundingMapList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentFundingMapList} ContentFundingMapList
         */
        ContentFundingMapList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentFundingMapList)
                return d;
            var m = new $root.Proto.ContentFundingMapList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentFundingMapList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentFundingMapList.value: object expected");
                    m.value[i] = $root.Proto.ContentFundingMap.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentFundingMapList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentFundingMapList
         * @static
         * @param {Proto.ContentFundingMapList} m ContentFundingMapList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentFundingMapList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentFundingMap.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentFundingMapList to JSON.
         * @function toJSON
         * @memberof Proto.ContentFundingMapList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentFundingMapList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentFundingMapList;
    })();

    Proto.FundingVariationOrder = (function() {

        /**
         * Properties of a FundingVariationOrder.
         * @memberof Proto
         * @interface IFundingVariationOrder
         * @property {string|null} [uid] FundingVariationOrder uid
         * @property {string|null} [variationUID] FundingVariationOrder variationUID
         * @property {string|null} [userUID] FundingVariationOrder userUID
         * @property {string|null} [thirdCheckoutId] FundingVariationOrder thirdCheckoutId
         * @property {string|null} [thirdPaymentId] FundingVariationOrder thirdPaymentId
         * @property {number|null} [quantity] FundingVariationOrder quantity
         * @property {number|null} [price] FundingVariationOrder price
         * @property {string|null} [currency] FundingVariationOrder currency
         * @property {string|null} [trackingNumber] FundingVariationOrder trackingNumber
         * @property {number|null} [trackingStatus] FundingVariationOrder trackingStatus
         * @property {number|Long|null} [registerDate] FundingVariationOrder registerDate
         * @property {number|Long|null} [modifyDate] FundingVariationOrder modifyDate
         * @property {number|null} [status] FundingVariationOrder status
         * @property {Proto.IUser|null} [user] FundingVariationOrder user
         * @property {Proto.IFunding|null} [funding] FundingVariationOrder funding
         * @property {string|null} [phone] FundingVariationOrder phone
         * @property {string|null} [postalCode] FundingVariationOrder postalCode
         * @property {string|null} [country] FundingVariationOrder country
         * @property {string|null} [state] FundingVariationOrder state
         * @property {string|null} [city] FundingVariationOrder city
         * @property {string|null} [address] FundingVariationOrder address
         * @property {number|null} [fee] FundingVariationOrder fee
         * @property {number|null} [total] FundingVariationOrder total
         * @property {number|null} [trackingCompany] FundingVariationOrder trackingCompany
         * @property {number|null} [payFee] FundingVariationOrder payFee
         * @property {boolean|null} [userCancel] FundingVariationOrder userCancel
         * @property {number|Long|null} [refundDate] FundingVariationOrder refundDate
         * @property {string|null} [receiptName] FundingVariationOrder receiptName
         * @property {string|null} [addressDetail] FundingVariationOrder addressDetail
         * @property {boolean|null} [invoiceFixed] FundingVariationOrder invoiceFixed
         * @property {number|null} [paymentType] FundingVariationOrder paymentType
         * @property {number|null} [thirdType] FundingVariationOrder thirdType
         * @property {number|null} [cashFee] FundingVariationOrder cashFee
         * @property {string|null} [variationName] FundingVariationOrder variationName
         * @property {boolean|null} [captured] FundingVariationOrder captured
         */

        /**
         * Constructs a new FundingVariationOrder.
         * @memberof Proto
         * @classdesc Represents a FundingVariationOrder.
         * @implements IFundingVariationOrder
         * @constructor
         * @param {Proto.IFundingVariationOrder=} [p] Properties to set
         */
        function FundingVariationOrder(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * FundingVariationOrder uid.
         * @member {string} uid
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.uid = "";

        /**
         * FundingVariationOrder variationUID.
         * @member {string} variationUID
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.variationUID = "";

        /**
         * FundingVariationOrder userUID.
         * @member {string} userUID
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.userUID = "";

        /**
         * FundingVariationOrder thirdCheckoutId.
         * @member {string} thirdCheckoutId
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.thirdCheckoutId = "";

        /**
         * FundingVariationOrder thirdPaymentId.
         * @member {string} thirdPaymentId
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.thirdPaymentId = "";

        /**
         * FundingVariationOrder quantity.
         * @member {number} quantity
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.quantity = 0;

        /**
         * FundingVariationOrder price.
         * @member {number} price
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.price = 0;

        /**
         * FundingVariationOrder currency.
         * @member {string} currency
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.currency = "";

        /**
         * FundingVariationOrder trackingNumber.
         * @member {string} trackingNumber
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.trackingNumber = "";

        /**
         * FundingVariationOrder trackingStatus.
         * @member {number} trackingStatus
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.trackingStatus = 0;

        /**
         * FundingVariationOrder registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * FundingVariationOrder modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * FundingVariationOrder status.
         * @member {number} status
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.status = 0;

        /**
         * FundingVariationOrder user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.user = null;

        /**
         * FundingVariationOrder funding.
         * @member {Proto.IFunding|null|undefined} funding
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.funding = null;

        /**
         * FundingVariationOrder phone.
         * @member {string} phone
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.phone = "";

        /**
         * FundingVariationOrder postalCode.
         * @member {string} postalCode
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.postalCode = "";

        /**
         * FundingVariationOrder country.
         * @member {string} country
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.country = "";

        /**
         * FundingVariationOrder state.
         * @member {string} state
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.state = "";

        /**
         * FundingVariationOrder city.
         * @member {string} city
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.city = "";

        /**
         * FundingVariationOrder address.
         * @member {string} address
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.address = "";

        /**
         * FundingVariationOrder fee.
         * @member {number} fee
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.fee = 0;

        /**
         * FundingVariationOrder total.
         * @member {number} total
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.total = 0;

        /**
         * FundingVariationOrder trackingCompany.
         * @member {number} trackingCompany
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.trackingCompany = 0;

        /**
         * FundingVariationOrder payFee.
         * @member {number} payFee
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.payFee = 0;

        /**
         * FundingVariationOrder userCancel.
         * @member {boolean} userCancel
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.userCancel = false;

        /**
         * FundingVariationOrder refundDate.
         * @member {number|Long} refundDate
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.refundDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * FundingVariationOrder receiptName.
         * @member {string} receiptName
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.receiptName = "";

        /**
         * FundingVariationOrder addressDetail.
         * @member {string} addressDetail
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.addressDetail = "";

        /**
         * FundingVariationOrder invoiceFixed.
         * @member {boolean} invoiceFixed
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.invoiceFixed = false;

        /**
         * FundingVariationOrder paymentType.
         * @member {number} paymentType
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.paymentType = 0;

        /**
         * FundingVariationOrder thirdType.
         * @member {number} thirdType
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.thirdType = 0;

        /**
         * FundingVariationOrder cashFee.
         * @member {number} cashFee
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.cashFee = 0;

        /**
         * FundingVariationOrder variationName.
         * @member {string} variationName
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.variationName = "";

        /**
         * FundingVariationOrder captured.
         * @member {boolean} captured
         * @memberof Proto.FundingVariationOrder
         * @instance
         */
        FundingVariationOrder.prototype.captured = false;

        /**
         * Creates a new FundingVariationOrder instance using the specified properties.
         * @function create
         * @memberof Proto.FundingVariationOrder
         * @static
         * @param {Proto.IFundingVariationOrder=} [properties] Properties to set
         * @returns {Proto.FundingVariationOrder} FundingVariationOrder instance
         */
        FundingVariationOrder.create = function create(properties) {
            return new FundingVariationOrder(properties);
        };

        /**
         * Encodes the specified FundingVariationOrder message. Does not implicitly {@link Proto.FundingVariationOrder.verify|verify} messages.
         * @function encode
         * @memberof Proto.FundingVariationOrder
         * @static
         * @param {Proto.IFundingVariationOrder} m FundingVariationOrder message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundingVariationOrder.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.variationUID != null && Object.hasOwnProperty.call(m, "variationUID"))
                w.uint32(18).string(m.variationUID);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(26).string(m.userUID);
            if (m.thirdCheckoutId != null && Object.hasOwnProperty.call(m, "thirdCheckoutId"))
                w.uint32(34).string(m.thirdCheckoutId);
            if (m.thirdPaymentId != null && Object.hasOwnProperty.call(m, "thirdPaymentId"))
                w.uint32(42).string(m.thirdPaymentId);
            if (m.quantity != null && Object.hasOwnProperty.call(m, "quantity"))
                w.uint32(48).int32(m.quantity);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(57).double(m.price);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(66).string(m.currency);
            if (m.trackingNumber != null && Object.hasOwnProperty.call(m, "trackingNumber"))
                w.uint32(74).string(m.trackingNumber);
            if (m.trackingStatus != null && Object.hasOwnProperty.call(m, "trackingStatus"))
                w.uint32(80).int32(m.trackingStatus);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(88).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(96).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(104).int32(m.status);
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(114).fork()).ldelim();
            if (m.funding != null && Object.hasOwnProperty.call(m, "funding"))
                $root.Proto.Funding.encode(m.funding, w.uint32(122).fork()).ldelim();
            if (m.phone != null && Object.hasOwnProperty.call(m, "phone"))
                w.uint32(130).string(m.phone);
            if (m.postalCode != null && Object.hasOwnProperty.call(m, "postalCode"))
                w.uint32(138).string(m.postalCode);
            if (m.country != null && Object.hasOwnProperty.call(m, "country"))
                w.uint32(146).string(m.country);
            if (m.state != null && Object.hasOwnProperty.call(m, "state"))
                w.uint32(154).string(m.state);
            if (m.city != null && Object.hasOwnProperty.call(m, "city"))
                w.uint32(162).string(m.city);
            if (m.address != null && Object.hasOwnProperty.call(m, "address"))
                w.uint32(170).string(m.address);
            if (m.fee != null && Object.hasOwnProperty.call(m, "fee"))
                w.uint32(177).double(m.fee);
            if (m.total != null && Object.hasOwnProperty.call(m, "total"))
                w.uint32(185).double(m.total);
            if (m.trackingCompany != null && Object.hasOwnProperty.call(m, "trackingCompany"))
                w.uint32(192).int32(m.trackingCompany);
            if (m.payFee != null && Object.hasOwnProperty.call(m, "payFee"))
                w.uint32(201).double(m.payFee);
            if (m.userCancel != null && Object.hasOwnProperty.call(m, "userCancel"))
                w.uint32(208).bool(m.userCancel);
            if (m.refundDate != null && Object.hasOwnProperty.call(m, "refundDate"))
                w.uint32(216).int64(m.refundDate);
            if (m.receiptName != null && Object.hasOwnProperty.call(m, "receiptName"))
                w.uint32(226).string(m.receiptName);
            if (m.addressDetail != null && Object.hasOwnProperty.call(m, "addressDetail"))
                w.uint32(234).string(m.addressDetail);
            if (m.invoiceFixed != null && Object.hasOwnProperty.call(m, "invoiceFixed"))
                w.uint32(240).bool(m.invoiceFixed);
            if (m.paymentType != null && Object.hasOwnProperty.call(m, "paymentType"))
                w.uint32(248).int32(m.paymentType);
            if (m.thirdType != null && Object.hasOwnProperty.call(m, "thirdType"))
                w.uint32(256).int32(m.thirdType);
            if (m.cashFee != null && Object.hasOwnProperty.call(m, "cashFee"))
                w.uint32(265).double(m.cashFee);
            if (m.variationName != null && Object.hasOwnProperty.call(m, "variationName"))
                w.uint32(274).string(m.variationName);
            if (m.captured != null && Object.hasOwnProperty.call(m, "captured"))
                w.uint32(280).bool(m.captured);
            return w;
        };

        /**
         * Decodes a FundingVariationOrder message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.FundingVariationOrder
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.FundingVariationOrder} FundingVariationOrder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundingVariationOrder.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.FundingVariationOrder();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.variationUID = r.string();
                    break;
                case 3:
                    m.userUID = r.string();
                    break;
                case 4:
                    m.thirdCheckoutId = r.string();
                    break;
                case 5:
                    m.thirdPaymentId = r.string();
                    break;
                case 6:
                    m.quantity = r.int32();
                    break;
                case 7:
                    m.price = r.double();
                    break;
                case 8:
                    m.currency = r.string();
                    break;
                case 9:
                    m.trackingNumber = r.string();
                    break;
                case 10:
                    m.trackingStatus = r.int32();
                    break;
                case 11:
                    m.registerDate = r.int64();
                    break;
                case 12:
                    m.modifyDate = r.int64();
                    break;
                case 13:
                    m.status = r.int32();
                    break;
                case 14:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 15:
                    m.funding = $root.Proto.Funding.decode(r, r.uint32());
                    break;
                case 16:
                    m.phone = r.string();
                    break;
                case 17:
                    m.postalCode = r.string();
                    break;
                case 18:
                    m.country = r.string();
                    break;
                case 19:
                    m.state = r.string();
                    break;
                case 20:
                    m.city = r.string();
                    break;
                case 21:
                    m.address = r.string();
                    break;
                case 22:
                    m.fee = r.double();
                    break;
                case 23:
                    m.total = r.double();
                    break;
                case 24:
                    m.trackingCompany = r.int32();
                    break;
                case 25:
                    m.payFee = r.double();
                    break;
                case 26:
                    m.userCancel = r.bool();
                    break;
                case 27:
                    m.refundDate = r.int64();
                    break;
                case 28:
                    m.receiptName = r.string();
                    break;
                case 29:
                    m.addressDetail = r.string();
                    break;
                case 30:
                    m.invoiceFixed = r.bool();
                    break;
                case 31:
                    m.paymentType = r.int32();
                    break;
                case 32:
                    m.thirdType = r.int32();
                    break;
                case 33:
                    m.cashFee = r.double();
                    break;
                case 34:
                    m.variationName = r.string();
                    break;
                case 35:
                    m.captured = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a FundingVariationOrder message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.FundingVariationOrder
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.FundingVariationOrder} FundingVariationOrder
         */
        FundingVariationOrder.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.FundingVariationOrder)
                return d;
            var m = new $root.Proto.FundingVariationOrder();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.variationUID != null) {
                m.variationUID = String(d.variationUID);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.thirdCheckoutId != null) {
                m.thirdCheckoutId = String(d.thirdCheckoutId);
            }
            if (d.thirdPaymentId != null) {
                m.thirdPaymentId = String(d.thirdPaymentId);
            }
            if (d.quantity != null) {
                m.quantity = d.quantity | 0;
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.trackingNumber != null) {
                m.trackingNumber = String(d.trackingNumber);
            }
            if (d.trackingStatus != null) {
                m.trackingStatus = d.trackingStatus | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.FundingVariationOrder.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            if (d.funding != null) {
                if (typeof d.funding !== "object")
                    throw TypeError(".Proto.FundingVariationOrder.funding: object expected");
                m.funding = $root.Proto.Funding.fromObject(d.funding);
            }
            if (d.phone != null) {
                m.phone = String(d.phone);
            }
            if (d.postalCode != null) {
                m.postalCode = String(d.postalCode);
            }
            if (d.country != null) {
                m.country = String(d.country);
            }
            if (d.state != null) {
                m.state = String(d.state);
            }
            if (d.city != null) {
                m.city = String(d.city);
            }
            if (d.address != null) {
                m.address = String(d.address);
            }
            if (d.fee != null) {
                m.fee = Number(d.fee);
            }
            if (d.total != null) {
                m.total = Number(d.total);
            }
            if (d.trackingCompany != null) {
                m.trackingCompany = d.trackingCompany | 0;
            }
            if (d.payFee != null) {
                m.payFee = Number(d.payFee);
            }
            if (d.userCancel != null) {
                m.userCancel = Boolean(d.userCancel);
            }
            if (d.refundDate != null) {
                if ($util.Long)
                    (m.refundDate = $util.Long.fromValue(d.refundDate)).unsigned = false;
                else if (typeof d.refundDate === "string")
                    m.refundDate = parseInt(d.refundDate, 10);
                else if (typeof d.refundDate === "number")
                    m.refundDate = d.refundDate;
                else if (typeof d.refundDate === "object")
                    m.refundDate = new $util.LongBits(d.refundDate.low >>> 0, d.refundDate.high >>> 0).toNumber();
            }
            if (d.receiptName != null) {
                m.receiptName = String(d.receiptName);
            }
            if (d.addressDetail != null) {
                m.addressDetail = String(d.addressDetail);
            }
            if (d.invoiceFixed != null) {
                m.invoiceFixed = Boolean(d.invoiceFixed);
            }
            if (d.paymentType != null) {
                m.paymentType = d.paymentType | 0;
            }
            if (d.thirdType != null) {
                m.thirdType = d.thirdType | 0;
            }
            if (d.cashFee != null) {
                m.cashFee = Number(d.cashFee);
            }
            if (d.variationName != null) {
                m.variationName = String(d.variationName);
            }
            if (d.captured != null) {
                m.captured = Boolean(d.captured);
            }
            return m;
        };

        /**
         * Creates a plain object from a FundingVariationOrder message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.FundingVariationOrder
         * @static
         * @param {Proto.FundingVariationOrder} m FundingVariationOrder
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FundingVariationOrder.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.variationUID = "";
                d.userUID = "";
                d.thirdCheckoutId = "";
                d.thirdPaymentId = "";
                d.quantity = 0;
                d.price = 0;
                d.currency = "";
                d.trackingNumber = "";
                d.trackingStatus = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.user = null;
                d.funding = null;
                d.phone = "";
                d.postalCode = "";
                d.country = "";
                d.state = "";
                d.city = "";
                d.address = "";
                d.fee = 0;
                d.total = 0;
                d.trackingCompany = 0;
                d.payFee = 0;
                d.userCancel = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.refundDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.refundDate = o.longs === String ? "0" : 0;
                d.receiptName = "";
                d.addressDetail = "";
                d.invoiceFixed = false;
                d.paymentType = 0;
                d.thirdType = 0;
                d.cashFee = 0;
                d.variationName = "";
                d.captured = false;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.variationUID != null && m.hasOwnProperty("variationUID")) {
                d.variationUID = m.variationUID;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.thirdCheckoutId != null && m.hasOwnProperty("thirdCheckoutId")) {
                d.thirdCheckoutId = m.thirdCheckoutId;
            }
            if (m.thirdPaymentId != null && m.hasOwnProperty("thirdPaymentId")) {
                d.thirdPaymentId = m.thirdPaymentId;
            }
            if (m.quantity != null && m.hasOwnProperty("quantity")) {
                d.quantity = m.quantity;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.trackingNumber != null && m.hasOwnProperty("trackingNumber")) {
                d.trackingNumber = m.trackingNumber;
            }
            if (m.trackingStatus != null && m.hasOwnProperty("trackingStatus")) {
                d.trackingStatus = m.trackingStatus;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            if (m.funding != null && m.hasOwnProperty("funding")) {
                d.funding = $root.Proto.Funding.toObject(m.funding, o);
            }
            if (m.phone != null && m.hasOwnProperty("phone")) {
                d.phone = m.phone;
            }
            if (m.postalCode != null && m.hasOwnProperty("postalCode")) {
                d.postalCode = m.postalCode;
            }
            if (m.country != null && m.hasOwnProperty("country")) {
                d.country = m.country;
            }
            if (m.state != null && m.hasOwnProperty("state")) {
                d.state = m.state;
            }
            if (m.city != null && m.hasOwnProperty("city")) {
                d.city = m.city;
            }
            if (m.address != null && m.hasOwnProperty("address")) {
                d.address = m.address;
            }
            if (m.fee != null && m.hasOwnProperty("fee")) {
                d.fee = o.json && !isFinite(m.fee) ? String(m.fee) : m.fee;
            }
            if (m.total != null && m.hasOwnProperty("total")) {
                d.total = o.json && !isFinite(m.total) ? String(m.total) : m.total;
            }
            if (m.trackingCompany != null && m.hasOwnProperty("trackingCompany")) {
                d.trackingCompany = m.trackingCompany;
            }
            if (m.payFee != null && m.hasOwnProperty("payFee")) {
                d.payFee = o.json && !isFinite(m.payFee) ? String(m.payFee) : m.payFee;
            }
            if (m.userCancel != null && m.hasOwnProperty("userCancel")) {
                d.userCancel = m.userCancel;
            }
            if (m.refundDate != null && m.hasOwnProperty("refundDate")) {
                if (typeof m.refundDate === "number")
                    d.refundDate = o.longs === String ? String(m.refundDate) : m.refundDate;
                else
                    d.refundDate = o.longs === String ? $util.Long.prototype.toString.call(m.refundDate) : o.longs === Number ? new $util.LongBits(m.refundDate.low >>> 0, m.refundDate.high >>> 0).toNumber() : m.refundDate;
            }
            if (m.receiptName != null && m.hasOwnProperty("receiptName")) {
                d.receiptName = m.receiptName;
            }
            if (m.addressDetail != null && m.hasOwnProperty("addressDetail")) {
                d.addressDetail = m.addressDetail;
            }
            if (m.invoiceFixed != null && m.hasOwnProperty("invoiceFixed")) {
                d.invoiceFixed = m.invoiceFixed;
            }
            if (m.paymentType != null && m.hasOwnProperty("paymentType")) {
                d.paymentType = m.paymentType;
            }
            if (m.thirdType != null && m.hasOwnProperty("thirdType")) {
                d.thirdType = m.thirdType;
            }
            if (m.cashFee != null && m.hasOwnProperty("cashFee")) {
                d.cashFee = o.json && !isFinite(m.cashFee) ? String(m.cashFee) : m.cashFee;
            }
            if (m.variationName != null && m.hasOwnProperty("variationName")) {
                d.variationName = m.variationName;
            }
            if (m.captured != null && m.hasOwnProperty("captured")) {
                d.captured = m.captured;
            }
            return d;
        };

        /**
         * Converts this FundingVariationOrder to JSON.
         * @function toJSON
         * @memberof Proto.FundingVariationOrder
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FundingVariationOrder.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FundingVariationOrder;
    })();

    Proto.FundingVariationOrderList = (function() {

        /**
         * Properties of a FundingVariationOrderList.
         * @memberof Proto
         * @interface IFundingVariationOrderList
         * @property {Array.<Proto.IFundingVariationOrder>|null} [value] FundingVariationOrderList value
         */

        /**
         * Constructs a new FundingVariationOrderList.
         * @memberof Proto
         * @classdesc Represents a FundingVariationOrderList.
         * @implements IFundingVariationOrderList
         * @constructor
         * @param {Proto.IFundingVariationOrderList=} [p] Properties to set
         */
        function FundingVariationOrderList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * FundingVariationOrderList value.
         * @member {Array.<Proto.IFundingVariationOrder>} value
         * @memberof Proto.FundingVariationOrderList
         * @instance
         */
        FundingVariationOrderList.prototype.value = $util.emptyArray;

        /**
         * Creates a new FundingVariationOrderList instance using the specified properties.
         * @function create
         * @memberof Proto.FundingVariationOrderList
         * @static
         * @param {Proto.IFundingVariationOrderList=} [properties] Properties to set
         * @returns {Proto.FundingVariationOrderList} FundingVariationOrderList instance
         */
        FundingVariationOrderList.create = function create(properties) {
            return new FundingVariationOrderList(properties);
        };

        /**
         * Encodes the specified FundingVariationOrderList message. Does not implicitly {@link Proto.FundingVariationOrderList.verify|verify} messages.
         * @function encode
         * @memberof Proto.FundingVariationOrderList
         * @static
         * @param {Proto.IFundingVariationOrderList} m FundingVariationOrderList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundingVariationOrderList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.FundingVariationOrder.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a FundingVariationOrderList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.FundingVariationOrderList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.FundingVariationOrderList} FundingVariationOrderList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundingVariationOrderList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.FundingVariationOrderList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.FundingVariationOrder.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a FundingVariationOrderList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.FundingVariationOrderList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.FundingVariationOrderList} FundingVariationOrderList
         */
        FundingVariationOrderList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.FundingVariationOrderList)
                return d;
            var m = new $root.Proto.FundingVariationOrderList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.FundingVariationOrderList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.FundingVariationOrderList.value: object expected");
                    m.value[i] = $root.Proto.FundingVariationOrder.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a FundingVariationOrderList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.FundingVariationOrderList
         * @static
         * @param {Proto.FundingVariationOrderList} m FundingVariationOrderList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FundingVariationOrderList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.FundingVariationOrder.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this FundingVariationOrderList to JSON.
         * @function toJSON
         * @memberof Proto.FundingVariationOrderList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FundingVariationOrderList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FundingVariationOrderList;
    })();

    Proto.CompanyFeaturesListResponse = (function() {

        /**
         * Properties of a CompanyFeaturesListResponse.
         * @memberof Proto
         * @interface ICompanyFeaturesListResponse
         * @property {Array.<number>|null} [typeList] CompanyFeaturesListResponse typeList
         * @property {string|null} [groupChatUserLimit] CompanyFeaturesListResponse groupChatUserLimit
         */

        /**
         * Constructs a new CompanyFeaturesListResponse.
         * @memberof Proto
         * @classdesc Represents a CompanyFeaturesListResponse.
         * @implements ICompanyFeaturesListResponse
         * @constructor
         * @param {Proto.ICompanyFeaturesListResponse=} [p] Properties to set
         */
        function CompanyFeaturesListResponse(p) {
            this.typeList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyFeaturesListResponse typeList.
         * @member {Array.<number>} typeList
         * @memberof Proto.CompanyFeaturesListResponse
         * @instance
         */
        CompanyFeaturesListResponse.prototype.typeList = $util.emptyArray;

        /**
         * CompanyFeaturesListResponse groupChatUserLimit.
         * @member {string} groupChatUserLimit
         * @memberof Proto.CompanyFeaturesListResponse
         * @instance
         */
        CompanyFeaturesListResponse.prototype.groupChatUserLimit = "";

        /**
         * Creates a new CompanyFeaturesListResponse instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyFeaturesListResponse
         * @static
         * @param {Proto.ICompanyFeaturesListResponse=} [properties] Properties to set
         * @returns {Proto.CompanyFeaturesListResponse} CompanyFeaturesListResponse instance
         */
        CompanyFeaturesListResponse.create = function create(properties) {
            return new CompanyFeaturesListResponse(properties);
        };

        /**
         * Encodes the specified CompanyFeaturesListResponse message. Does not implicitly {@link Proto.CompanyFeaturesListResponse.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyFeaturesListResponse
         * @static
         * @param {Proto.ICompanyFeaturesListResponse} m CompanyFeaturesListResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyFeaturesListResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.typeList != null && m.typeList.length) {
                w.uint32(10).fork();
                for (var i = 0; i < m.typeList.length; ++i)
                    w.int32(m.typeList[i]);
                w.ldelim();
            }
            if (m.groupChatUserLimit != null && Object.hasOwnProperty.call(m, "groupChatUserLimit"))
                w.uint32(18).string(m.groupChatUserLimit);
            return w;
        };

        /**
         * Decodes a CompanyFeaturesListResponse message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyFeaturesListResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyFeaturesListResponse} CompanyFeaturesListResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyFeaturesListResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyFeaturesListResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.typeList && m.typeList.length))
                        m.typeList = [];
                    if ((t & 7) === 2) {
                        var c2 = r.uint32() + r.pos;
                        while (r.pos < c2)
                            m.typeList.push(r.int32());
                    } else
                        m.typeList.push(r.int32());
                    break;
                case 2:
                    m.groupChatUserLimit = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyFeaturesListResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyFeaturesListResponse
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyFeaturesListResponse} CompanyFeaturesListResponse
         */
        CompanyFeaturesListResponse.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyFeaturesListResponse)
                return d;
            var m = new $root.Proto.CompanyFeaturesListResponse();
            if (d.typeList) {
                if (!Array.isArray(d.typeList))
                    throw TypeError(".Proto.CompanyFeaturesListResponse.typeList: array expected");
                m.typeList = [];
                for (var i = 0; i < d.typeList.length; ++i) {
                    m.typeList[i] = d.typeList[i] | 0;
                }
            }
            if (d.groupChatUserLimit != null) {
                m.groupChatUserLimit = String(d.groupChatUserLimit);
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyFeaturesListResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyFeaturesListResponse
         * @static
         * @param {Proto.CompanyFeaturesListResponse} m CompanyFeaturesListResponse
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyFeaturesListResponse.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.typeList = [];
            }
            if (o.defaults) {
                d.groupChatUserLimit = "";
            }
            if (m.typeList && m.typeList.length) {
                d.typeList = [];
                for (var j = 0; j < m.typeList.length; ++j) {
                    d.typeList[j] = m.typeList[j];
                }
            }
            if (m.groupChatUserLimit != null && m.hasOwnProperty("groupChatUserLimit")) {
                d.groupChatUserLimit = m.groupChatUserLimit;
            }
            return d;
        };

        /**
         * Converts this CompanyFeaturesListResponse to JSON.
         * @function toJSON
         * @memberof Proto.CompanyFeaturesListResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyFeaturesListResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyFeaturesListResponse;
    })();

    Proto.AppSettingInfoResponse = (function() {

        /**
         * Properties of an AppSettingInfoResponse.
         * @memberof Proto
         * @interface IAppSettingInfoResponse
         * @property {number|Long|null} [resignAvailableCount] AppSettingInfoResponse resignAvailableCount
         * @property {string|null} [cpHistoryCount] AppSettingInfoResponse cpHistoryCount
         * @property {number|Long|null} [registerDate] AppSettingInfoResponse registerDate
         * @property {number|Long|null} [modifyDate] AppSettingInfoResponse modifyDate
         * @property {string|null} [commerceCount] AppSettingInfoResponse commerceCount
         * @property {string|null} [fundingCount] AppSettingInfoResponse fundingCount
         * @property {string|null} [scheduleHistoryCount] AppSettingInfoResponse scheduleHistoryCount
         * @property {string|null} [ticketCount] AppSettingInfoResponse ticketCount
         * @property {boolean|null} [nft] AppSettingInfoResponse nft
         * @property {boolean|null} [mailFilter] AppSettingInfoResponse mailFilter
         * @property {string|null} [mailFilterText1] AppSettingInfoResponse mailFilterText1
         * @property {string|null} [workflowCount] AppSettingInfoResponse workflowCount
         */

        /**
         * Constructs a new AppSettingInfoResponse.
         * @memberof Proto
         * @classdesc Represents an AppSettingInfoResponse.
         * @implements IAppSettingInfoResponse
         * @constructor
         * @param {Proto.IAppSettingInfoResponse=} [p] Properties to set
         */
        function AppSettingInfoResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * AppSettingInfoResponse resignAvailableCount.
         * @member {number|Long} resignAvailableCount
         * @memberof Proto.AppSettingInfoResponse
         * @instance
         */
        AppSettingInfoResponse.prototype.resignAvailableCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * AppSettingInfoResponse cpHistoryCount.
         * @member {string} cpHistoryCount
         * @memberof Proto.AppSettingInfoResponse
         * @instance
         */
        AppSettingInfoResponse.prototype.cpHistoryCount = "";

        /**
         * AppSettingInfoResponse registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.AppSettingInfoResponse
         * @instance
         */
        AppSettingInfoResponse.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * AppSettingInfoResponse modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.AppSettingInfoResponse
         * @instance
         */
        AppSettingInfoResponse.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * AppSettingInfoResponse commerceCount.
         * @member {string} commerceCount
         * @memberof Proto.AppSettingInfoResponse
         * @instance
         */
        AppSettingInfoResponse.prototype.commerceCount = "";

        /**
         * AppSettingInfoResponse fundingCount.
         * @member {string} fundingCount
         * @memberof Proto.AppSettingInfoResponse
         * @instance
         */
        AppSettingInfoResponse.prototype.fundingCount = "";

        /**
         * AppSettingInfoResponse scheduleHistoryCount.
         * @member {string} scheduleHistoryCount
         * @memberof Proto.AppSettingInfoResponse
         * @instance
         */
        AppSettingInfoResponse.prototype.scheduleHistoryCount = "";

        /**
         * AppSettingInfoResponse ticketCount.
         * @member {string} ticketCount
         * @memberof Proto.AppSettingInfoResponse
         * @instance
         */
        AppSettingInfoResponse.prototype.ticketCount = "";

        /**
         * AppSettingInfoResponse nft.
         * @member {boolean} nft
         * @memberof Proto.AppSettingInfoResponse
         * @instance
         */
        AppSettingInfoResponse.prototype.nft = false;

        /**
         * AppSettingInfoResponse mailFilter.
         * @member {boolean} mailFilter
         * @memberof Proto.AppSettingInfoResponse
         * @instance
         */
        AppSettingInfoResponse.prototype.mailFilter = false;

        /**
         * AppSettingInfoResponse mailFilterText1.
         * @member {string} mailFilterText1
         * @memberof Proto.AppSettingInfoResponse
         * @instance
         */
        AppSettingInfoResponse.prototype.mailFilterText1 = "";

        /**
         * AppSettingInfoResponse workflowCount.
         * @member {string} workflowCount
         * @memberof Proto.AppSettingInfoResponse
         * @instance
         */
        AppSettingInfoResponse.prototype.workflowCount = "";

        /**
         * Creates a new AppSettingInfoResponse instance using the specified properties.
         * @function create
         * @memberof Proto.AppSettingInfoResponse
         * @static
         * @param {Proto.IAppSettingInfoResponse=} [properties] Properties to set
         * @returns {Proto.AppSettingInfoResponse} AppSettingInfoResponse instance
         */
        AppSettingInfoResponse.create = function create(properties) {
            return new AppSettingInfoResponse(properties);
        };

        /**
         * Encodes the specified AppSettingInfoResponse message. Does not implicitly {@link Proto.AppSettingInfoResponse.verify|verify} messages.
         * @function encode
         * @memberof Proto.AppSettingInfoResponse
         * @static
         * @param {Proto.IAppSettingInfoResponse} m AppSettingInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppSettingInfoResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.resignAvailableCount != null && Object.hasOwnProperty.call(m, "resignAvailableCount"))
                w.uint32(8).int64(m.resignAvailableCount);
            if (m.cpHistoryCount != null && Object.hasOwnProperty.call(m, "cpHistoryCount"))
                w.uint32(18).string(m.cpHistoryCount);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(32).int64(m.modifyDate);
            if (m.commerceCount != null && Object.hasOwnProperty.call(m, "commerceCount"))
                w.uint32(42).string(m.commerceCount);
            if (m.fundingCount != null && Object.hasOwnProperty.call(m, "fundingCount"))
                w.uint32(50).string(m.fundingCount);
            if (m.scheduleHistoryCount != null && Object.hasOwnProperty.call(m, "scheduleHistoryCount"))
                w.uint32(58).string(m.scheduleHistoryCount);
            if (m.ticketCount != null && Object.hasOwnProperty.call(m, "ticketCount"))
                w.uint32(66).string(m.ticketCount);
            if (m.nft != null && Object.hasOwnProperty.call(m, "nft"))
                w.uint32(72).bool(m.nft);
            if (m.mailFilter != null && Object.hasOwnProperty.call(m, "mailFilter"))
                w.uint32(80).bool(m.mailFilter);
            if (m.mailFilterText1 != null && Object.hasOwnProperty.call(m, "mailFilterText1"))
                w.uint32(90).string(m.mailFilterText1);
            if (m.workflowCount != null && Object.hasOwnProperty.call(m, "workflowCount"))
                w.uint32(98).string(m.workflowCount);
            return w;
        };

        /**
         * Decodes an AppSettingInfoResponse message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.AppSettingInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.AppSettingInfoResponse} AppSettingInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppSettingInfoResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.AppSettingInfoResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.resignAvailableCount = r.int64();
                    break;
                case 2:
                    m.cpHistoryCount = r.string();
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    m.modifyDate = r.int64();
                    break;
                case 5:
                    m.commerceCount = r.string();
                    break;
                case 6:
                    m.fundingCount = r.string();
                    break;
                case 7:
                    m.scheduleHistoryCount = r.string();
                    break;
                case 8:
                    m.ticketCount = r.string();
                    break;
                case 9:
                    m.nft = r.bool();
                    break;
                case 10:
                    m.mailFilter = r.bool();
                    break;
                case 11:
                    m.mailFilterText1 = r.string();
                    break;
                case 12:
                    m.workflowCount = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an AppSettingInfoResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.AppSettingInfoResponse
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.AppSettingInfoResponse} AppSettingInfoResponse
         */
        AppSettingInfoResponse.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.AppSettingInfoResponse)
                return d;
            var m = new $root.Proto.AppSettingInfoResponse();
            if (d.resignAvailableCount != null) {
                if ($util.Long)
                    (m.resignAvailableCount = $util.Long.fromValue(d.resignAvailableCount)).unsigned = false;
                else if (typeof d.resignAvailableCount === "string")
                    m.resignAvailableCount = parseInt(d.resignAvailableCount, 10);
                else if (typeof d.resignAvailableCount === "number")
                    m.resignAvailableCount = d.resignAvailableCount;
                else if (typeof d.resignAvailableCount === "object")
                    m.resignAvailableCount = new $util.LongBits(d.resignAvailableCount.low >>> 0, d.resignAvailableCount.high >>> 0).toNumber();
            }
            if (d.cpHistoryCount != null) {
                m.cpHistoryCount = String(d.cpHistoryCount);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.commerceCount != null) {
                m.commerceCount = String(d.commerceCount);
            }
            if (d.fundingCount != null) {
                m.fundingCount = String(d.fundingCount);
            }
            if (d.scheduleHistoryCount != null) {
                m.scheduleHistoryCount = String(d.scheduleHistoryCount);
            }
            if (d.ticketCount != null) {
                m.ticketCount = String(d.ticketCount);
            }
            if (d.nft != null) {
                m.nft = Boolean(d.nft);
            }
            if (d.mailFilter != null) {
                m.mailFilter = Boolean(d.mailFilter);
            }
            if (d.mailFilterText1 != null) {
                m.mailFilterText1 = String(d.mailFilterText1);
            }
            if (d.workflowCount != null) {
                m.workflowCount = String(d.workflowCount);
            }
            return m;
        };

        /**
         * Creates a plain object from an AppSettingInfoResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.AppSettingInfoResponse
         * @static
         * @param {Proto.AppSettingInfoResponse} m AppSettingInfoResponse
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppSettingInfoResponse.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.resignAvailableCount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.resignAvailableCount = o.longs === String ? "0" : 0;
                d.cpHistoryCount = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.commerceCount = "";
                d.fundingCount = "";
                d.scheduleHistoryCount = "";
                d.ticketCount = "";
                d.nft = false;
                d.mailFilter = false;
                d.mailFilterText1 = "";
                d.workflowCount = "";
            }
            if (m.resignAvailableCount != null && m.hasOwnProperty("resignAvailableCount")) {
                if (typeof m.resignAvailableCount === "number")
                    d.resignAvailableCount = o.longs === String ? String(m.resignAvailableCount) : m.resignAvailableCount;
                else
                    d.resignAvailableCount = o.longs === String ? $util.Long.prototype.toString.call(m.resignAvailableCount) : o.longs === Number ? new $util.LongBits(m.resignAvailableCount.low >>> 0, m.resignAvailableCount.high >>> 0).toNumber() : m.resignAvailableCount;
            }
            if (m.cpHistoryCount != null && m.hasOwnProperty("cpHistoryCount")) {
                d.cpHistoryCount = m.cpHistoryCount;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.commerceCount != null && m.hasOwnProperty("commerceCount")) {
                d.commerceCount = m.commerceCount;
            }
            if (m.fundingCount != null && m.hasOwnProperty("fundingCount")) {
                d.fundingCount = m.fundingCount;
            }
            if (m.scheduleHistoryCount != null && m.hasOwnProperty("scheduleHistoryCount")) {
                d.scheduleHistoryCount = m.scheduleHistoryCount;
            }
            if (m.ticketCount != null && m.hasOwnProperty("ticketCount")) {
                d.ticketCount = m.ticketCount;
            }
            if (m.nft != null && m.hasOwnProperty("nft")) {
                d.nft = m.nft;
            }
            if (m.mailFilter != null && m.hasOwnProperty("mailFilter")) {
                d.mailFilter = m.mailFilter;
            }
            if (m.mailFilterText1 != null && m.hasOwnProperty("mailFilterText1")) {
                d.mailFilterText1 = m.mailFilterText1;
            }
            if (m.workflowCount != null && m.hasOwnProperty("workflowCount")) {
                d.workflowCount = m.workflowCount;
            }
            return d;
        };

        /**
         * Converts this AppSettingInfoResponse to JSON.
         * @function toJSON
         * @memberof Proto.AppSettingInfoResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppSettingInfoResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppSettingInfoResponse;
    })();

    Proto.ContentCommentViolate = (function() {

        /**
         * Properties of a ContentCommentViolate.
         * @memberof Proto
         * @interface IContentCommentViolate
         * @property {string|null} [commentUID] ContentCommentViolate commentUID
         * @property {number|null} [type] ContentCommentViolate type
         * @property {string|null} [text] ContentCommentViolate text
         * @property {Proto.IContent|null} [content] ContentCommentViolate content
         * @property {Proto.IContentComment|null} [comment] ContentCommentViolate comment
         * @property {string|null} [companyUID] ContentCommentViolate companyUID
         * @property {string|null} [companyName] ContentCommentViolate companyName
         * @property {number|Long|null} [registerDate] ContentCommentViolate registerDate
         * @property {number|Long|null} [modifyDate] ContentCommentViolate modifyDate
         */

        /**
         * Constructs a new ContentCommentViolate.
         * @memberof Proto
         * @classdesc Represents a ContentCommentViolate.
         * @implements IContentCommentViolate
         * @constructor
         * @param {Proto.IContentCommentViolate=} [p] Properties to set
         */
        function ContentCommentViolate(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentCommentViolate commentUID.
         * @member {string} commentUID
         * @memberof Proto.ContentCommentViolate
         * @instance
         */
        ContentCommentViolate.prototype.commentUID = "";

        /**
         * ContentCommentViolate type.
         * @member {number} type
         * @memberof Proto.ContentCommentViolate
         * @instance
         */
        ContentCommentViolate.prototype.type = 0;

        /**
         * ContentCommentViolate text.
         * @member {string} text
         * @memberof Proto.ContentCommentViolate
         * @instance
         */
        ContentCommentViolate.prototype.text = "";

        /**
         * ContentCommentViolate content.
         * @member {Proto.IContent|null|undefined} content
         * @memberof Proto.ContentCommentViolate
         * @instance
         */
        ContentCommentViolate.prototype.content = null;

        /**
         * ContentCommentViolate comment.
         * @member {Proto.IContentComment|null|undefined} comment
         * @memberof Proto.ContentCommentViolate
         * @instance
         */
        ContentCommentViolate.prototype.comment = null;

        /**
         * ContentCommentViolate companyUID.
         * @member {string} companyUID
         * @memberof Proto.ContentCommentViolate
         * @instance
         */
        ContentCommentViolate.prototype.companyUID = "";

        /**
         * ContentCommentViolate companyName.
         * @member {string} companyName
         * @memberof Proto.ContentCommentViolate
         * @instance
         */
        ContentCommentViolate.prototype.companyName = "";

        /**
         * ContentCommentViolate registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentCommentViolate
         * @instance
         */
        ContentCommentViolate.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommentViolate modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentCommentViolate
         * @instance
         */
        ContentCommentViolate.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new ContentCommentViolate instance using the specified properties.
         * @function create
         * @memberof Proto.ContentCommentViolate
         * @static
         * @param {Proto.IContentCommentViolate=} [properties] Properties to set
         * @returns {Proto.ContentCommentViolate} ContentCommentViolate instance
         */
        ContentCommentViolate.create = function create(properties) {
            return new ContentCommentViolate(properties);
        };

        /**
         * Encodes the specified ContentCommentViolate message. Does not implicitly {@link Proto.ContentCommentViolate.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentCommentViolate
         * @static
         * @param {Proto.IContentCommentViolate} m ContentCommentViolate message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentCommentViolate.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.commentUID != null && Object.hasOwnProperty.call(m, "commentUID"))
                w.uint32(10).string(m.commentUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.content != null && Object.hasOwnProperty.call(m, "content"))
                $root.Proto.Content.encode(m.content, w.uint32(34).fork()).ldelim();
            if (m.comment != null && Object.hasOwnProperty.call(m, "comment"))
                $root.Proto.ContentComment.encode(m.comment, w.uint32(42).fork()).ldelim();
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(50).string(m.companyUID);
            if (m.companyName != null && Object.hasOwnProperty.call(m, "companyName"))
                w.uint32(58).string(m.companyName);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a ContentCommentViolate message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentCommentViolate
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentCommentViolate} ContentCommentViolate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentCommentViolate.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentCommentViolate();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.commentUID = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.content = $root.Proto.Content.decode(r, r.uint32());
                    break;
                case 5:
                    m.comment = $root.Proto.ContentComment.decode(r, r.uint32());
                    break;
                case 6:
                    m.companyUID = r.string();
                    break;
                case 7:
                    m.companyName = r.string();
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentCommentViolate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentCommentViolate
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentCommentViolate} ContentCommentViolate
         */
        ContentCommentViolate.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentCommentViolate)
                return d;
            var m = new $root.Proto.ContentCommentViolate();
            if (d.commentUID != null) {
                m.commentUID = String(d.commentUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.content != null) {
                if (typeof d.content !== "object")
                    throw TypeError(".Proto.ContentCommentViolate.content: object expected");
                m.content = $root.Proto.Content.fromObject(d.content);
            }
            if (d.comment != null) {
                if (typeof d.comment !== "object")
                    throw TypeError(".Proto.ContentCommentViolate.comment: object expected");
                m.comment = $root.Proto.ContentComment.fromObject(d.comment);
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.companyName != null) {
                m.companyName = String(d.companyName);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentCommentViolate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentCommentViolate
         * @static
         * @param {Proto.ContentCommentViolate} m ContentCommentViolate
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentCommentViolate.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.commentUID = "";
                d.type = 0;
                d.text = "";
                d.content = null;
                d.comment = null;
                d.companyUID = "";
                d.companyName = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.commentUID != null && m.hasOwnProperty("commentUID")) {
                d.commentUID = m.commentUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.content != null && m.hasOwnProperty("content")) {
                d.content = $root.Proto.Content.toObject(m.content, o);
            }
            if (m.comment != null && m.hasOwnProperty("comment")) {
                d.comment = $root.Proto.ContentComment.toObject(m.comment, o);
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.companyName != null && m.hasOwnProperty("companyName")) {
                d.companyName = m.companyName;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this ContentCommentViolate to JSON.
         * @function toJSON
         * @memberof Proto.ContentCommentViolate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentCommentViolate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentCommentViolate;
    })();

    Proto.ContentCommentViolateList = (function() {

        /**
         * Properties of a ContentCommentViolateList.
         * @memberof Proto
         * @interface IContentCommentViolateList
         * @property {Array.<Proto.IContentCommentViolate>|null} [value] ContentCommentViolateList value
         */

        /**
         * Constructs a new ContentCommentViolateList.
         * @memberof Proto
         * @classdesc Represents a ContentCommentViolateList.
         * @implements IContentCommentViolateList
         * @constructor
         * @param {Proto.IContentCommentViolateList=} [p] Properties to set
         */
        function ContentCommentViolateList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentCommentViolateList value.
         * @member {Array.<Proto.IContentCommentViolate>} value
         * @memberof Proto.ContentCommentViolateList
         * @instance
         */
        ContentCommentViolateList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentCommentViolateList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentCommentViolateList
         * @static
         * @param {Proto.IContentCommentViolateList=} [properties] Properties to set
         * @returns {Proto.ContentCommentViolateList} ContentCommentViolateList instance
         */
        ContentCommentViolateList.create = function create(properties) {
            return new ContentCommentViolateList(properties);
        };

        /**
         * Encodes the specified ContentCommentViolateList message. Does not implicitly {@link Proto.ContentCommentViolateList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentCommentViolateList
         * @static
         * @param {Proto.IContentCommentViolateList} m ContentCommentViolateList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentCommentViolateList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentCommentViolate.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentCommentViolateList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentCommentViolateList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentCommentViolateList} ContentCommentViolateList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentCommentViolateList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentCommentViolateList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentCommentViolate.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentCommentViolateList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentCommentViolateList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentCommentViolateList} ContentCommentViolateList
         */
        ContentCommentViolateList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentCommentViolateList)
                return d;
            var m = new $root.Proto.ContentCommentViolateList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentCommentViolateList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentCommentViolateList.value: object expected");
                    m.value[i] = $root.Proto.ContentCommentViolate.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentCommentViolateList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentCommentViolateList
         * @static
         * @param {Proto.ContentCommentViolateList} m ContentCommentViolateList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentCommentViolateList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentCommentViolate.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentCommentViolateList to JSON.
         * @function toJSON
         * @memberof Proto.ContentCommentViolateList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentCommentViolateList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentCommentViolateList;
    })();

    Proto.CommunityBoardPoll = (function() {

        /**
         * Properties of a CommunityBoardPoll.
         * @memberof Proto
         * @interface ICommunityBoardPoll
         * @property {string|null} [uid] CommunityBoardPoll uid
         * @property {string|null} [boardUID] CommunityBoardPoll boardUID
         * @property {string|null} [topicUID] CommunityBoardPoll topicUID
         * @property {string|null} [commentUID] CommunityBoardPoll commentUID
         * @property {number|null} [type] CommunityBoardPoll type
         * @property {string|null} [title] CommunityBoardPoll title
         * @property {number|Long|null} [endDate] CommunityBoardPoll endDate
         * @property {boolean|null} [multiple] CommunityBoardPoll multiple
         * @property {boolean|null} [anonymous] CommunityBoardPoll anonymous
         * @property {boolean|null} [addable] CommunityBoardPoll addable
         * @property {boolean|null} [fixed] CommunityBoardPoll fixed
         * @property {number|Long|null} [registerDate] CommunityBoardPoll registerDate
         * @property {number|Long|null} [modifyDate] CommunityBoardPoll modifyDate
         * @property {Array.<Proto.ICommunityBoardPollItem>|null} [itemList] CommunityBoardPoll itemList
         * @property {number|null} [status] CommunityBoardPoll status
         * @property {boolean|null} [userPolled] CommunityBoardPoll userPolled
         * @property {string|null} [allCount] CommunityBoardPoll allCount
         */

        /**
         * Constructs a new CommunityBoardPoll.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardPoll.
         * @implements ICommunityBoardPoll
         * @constructor
         * @param {Proto.ICommunityBoardPoll=} [p] Properties to set
         */
        function CommunityBoardPoll(p) {
            this.itemList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardPoll uid.
         * @member {string} uid
         * @memberof Proto.CommunityBoardPoll
         * @instance
         */
        CommunityBoardPoll.prototype.uid = "";

        /**
         * CommunityBoardPoll boardUID.
         * @member {string} boardUID
         * @memberof Proto.CommunityBoardPoll
         * @instance
         */
        CommunityBoardPoll.prototype.boardUID = "";

        /**
         * CommunityBoardPoll topicUID.
         * @member {string} topicUID
         * @memberof Proto.CommunityBoardPoll
         * @instance
         */
        CommunityBoardPoll.prototype.topicUID = "";

        /**
         * CommunityBoardPoll commentUID.
         * @member {string} commentUID
         * @memberof Proto.CommunityBoardPoll
         * @instance
         */
        CommunityBoardPoll.prototype.commentUID = "";

        /**
         * CommunityBoardPoll type.
         * @member {number} type
         * @memberof Proto.CommunityBoardPoll
         * @instance
         */
        CommunityBoardPoll.prototype.type = 0;

        /**
         * CommunityBoardPoll title.
         * @member {string} title
         * @memberof Proto.CommunityBoardPoll
         * @instance
         */
        CommunityBoardPoll.prototype.title = "";

        /**
         * CommunityBoardPoll endDate.
         * @member {number|Long} endDate
         * @memberof Proto.CommunityBoardPoll
         * @instance
         */
        CommunityBoardPoll.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardPoll multiple.
         * @member {boolean} multiple
         * @memberof Proto.CommunityBoardPoll
         * @instance
         */
        CommunityBoardPoll.prototype.multiple = false;

        /**
         * CommunityBoardPoll anonymous.
         * @member {boolean} anonymous
         * @memberof Proto.CommunityBoardPoll
         * @instance
         */
        CommunityBoardPoll.prototype.anonymous = false;

        /**
         * CommunityBoardPoll addable.
         * @member {boolean} addable
         * @memberof Proto.CommunityBoardPoll
         * @instance
         */
        CommunityBoardPoll.prototype.addable = false;

        /**
         * CommunityBoardPoll fixed.
         * @member {boolean} fixed
         * @memberof Proto.CommunityBoardPoll
         * @instance
         */
        CommunityBoardPoll.prototype.fixed = false;

        /**
         * CommunityBoardPoll registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommunityBoardPoll
         * @instance
         */
        CommunityBoardPoll.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardPoll modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommunityBoardPoll
         * @instance
         */
        CommunityBoardPoll.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardPoll itemList.
         * @member {Array.<Proto.ICommunityBoardPollItem>} itemList
         * @memberof Proto.CommunityBoardPoll
         * @instance
         */
        CommunityBoardPoll.prototype.itemList = $util.emptyArray;

        /**
         * CommunityBoardPoll status.
         * @member {number} status
         * @memberof Proto.CommunityBoardPoll
         * @instance
         */
        CommunityBoardPoll.prototype.status = 0;

        /**
         * CommunityBoardPoll userPolled.
         * @member {boolean} userPolled
         * @memberof Proto.CommunityBoardPoll
         * @instance
         */
        CommunityBoardPoll.prototype.userPolled = false;

        /**
         * CommunityBoardPoll allCount.
         * @member {string} allCount
         * @memberof Proto.CommunityBoardPoll
         * @instance
         */
        CommunityBoardPoll.prototype.allCount = "";

        /**
         * Creates a new CommunityBoardPoll instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardPoll
         * @static
         * @param {Proto.ICommunityBoardPoll=} [properties] Properties to set
         * @returns {Proto.CommunityBoardPoll} CommunityBoardPoll instance
         */
        CommunityBoardPoll.create = function create(properties) {
            return new CommunityBoardPoll(properties);
        };

        /**
         * Encodes the specified CommunityBoardPoll message. Does not implicitly {@link Proto.CommunityBoardPoll.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardPoll
         * @static
         * @param {Proto.ICommunityBoardPoll} m CommunityBoardPoll message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardPoll.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.boardUID != null && Object.hasOwnProperty.call(m, "boardUID"))
                w.uint32(18).string(m.boardUID);
            if (m.topicUID != null && Object.hasOwnProperty.call(m, "topicUID"))
                w.uint32(26).string(m.topicUID);
            if (m.commentUID != null && Object.hasOwnProperty.call(m, "commentUID"))
                w.uint32(34).string(m.commentUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(40).int32(m.type);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(50).string(m.title);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(56).int64(m.endDate);
            if (m.multiple != null && Object.hasOwnProperty.call(m, "multiple"))
                w.uint32(64).bool(m.multiple);
            if (m.anonymous != null && Object.hasOwnProperty.call(m, "anonymous"))
                w.uint32(72).bool(m.anonymous);
            if (m.addable != null && Object.hasOwnProperty.call(m, "addable"))
                w.uint32(80).bool(m.addable);
            if (m.fixed != null && Object.hasOwnProperty.call(m, "fixed"))
                w.uint32(88).bool(m.fixed);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(96).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(104).int64(m.modifyDate);
            if (m.itemList != null && m.itemList.length) {
                for (var i = 0; i < m.itemList.length; ++i)
                    $root.Proto.CommunityBoardPollItem.encode(m.itemList[i], w.uint32(114).fork()).ldelim();
            }
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(120).int32(m.status);
            if (m.userPolled != null && Object.hasOwnProperty.call(m, "userPolled"))
                w.uint32(128).bool(m.userPolled);
            if (m.allCount != null && Object.hasOwnProperty.call(m, "allCount"))
                w.uint32(138).string(m.allCount);
            return w;
        };

        /**
         * Decodes a CommunityBoardPoll message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardPoll
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardPoll} CommunityBoardPoll
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardPoll.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardPoll();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.boardUID = r.string();
                    break;
                case 3:
                    m.topicUID = r.string();
                    break;
                case 4:
                    m.commentUID = r.string();
                    break;
                case 5:
                    m.type = r.int32();
                    break;
                case 6:
                    m.title = r.string();
                    break;
                case 7:
                    m.endDate = r.int64();
                    break;
                case 8:
                    m.multiple = r.bool();
                    break;
                case 9:
                    m.anonymous = r.bool();
                    break;
                case 10:
                    m.addable = r.bool();
                    break;
                case 11:
                    m.fixed = r.bool();
                    break;
                case 12:
                    m.registerDate = r.int64();
                    break;
                case 13:
                    m.modifyDate = r.int64();
                    break;
                case 14:
                    if (!(m.itemList && m.itemList.length))
                        m.itemList = [];
                    m.itemList.push($root.Proto.CommunityBoardPollItem.decode(r, r.uint32()));
                    break;
                case 15:
                    m.status = r.int32();
                    break;
                case 16:
                    m.userPolled = r.bool();
                    break;
                case 17:
                    m.allCount = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardPoll message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardPoll
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardPoll} CommunityBoardPoll
         */
        CommunityBoardPoll.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardPoll)
                return d;
            var m = new $root.Proto.CommunityBoardPoll();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.boardUID != null) {
                m.boardUID = String(d.boardUID);
            }
            if (d.topicUID != null) {
                m.topicUID = String(d.topicUID);
            }
            if (d.commentUID != null) {
                m.commentUID = String(d.commentUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.multiple != null) {
                m.multiple = Boolean(d.multiple);
            }
            if (d.anonymous != null) {
                m.anonymous = Boolean(d.anonymous);
            }
            if (d.addable != null) {
                m.addable = Boolean(d.addable);
            }
            if (d.fixed != null) {
                m.fixed = Boolean(d.fixed);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.itemList) {
                if (!Array.isArray(d.itemList))
                    throw TypeError(".Proto.CommunityBoardPoll.itemList: array expected");
                m.itemList = [];
                for (var i = 0; i < d.itemList.length; ++i) {
                    if (typeof d.itemList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardPoll.itemList: object expected");
                    m.itemList[i] = $root.Proto.CommunityBoardPollItem.fromObject(d.itemList[i]);
                }
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.userPolled != null) {
                m.userPolled = Boolean(d.userPolled);
            }
            if (d.allCount != null) {
                m.allCount = String(d.allCount);
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardPoll message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardPoll
         * @static
         * @param {Proto.CommunityBoardPoll} m CommunityBoardPoll
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardPoll.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.itemList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.boardUID = "";
                d.topicUID = "";
                d.commentUID = "";
                d.type = 0;
                d.title = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                d.multiple = false;
                d.anonymous = false;
                d.addable = false;
                d.fixed = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.userPolled = false;
                d.allCount = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.boardUID != null && m.hasOwnProperty("boardUID")) {
                d.boardUID = m.boardUID;
            }
            if (m.topicUID != null && m.hasOwnProperty("topicUID")) {
                d.topicUID = m.topicUID;
            }
            if (m.commentUID != null && m.hasOwnProperty("commentUID")) {
                d.commentUID = m.commentUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.multiple != null && m.hasOwnProperty("multiple")) {
                d.multiple = m.multiple;
            }
            if (m.anonymous != null && m.hasOwnProperty("anonymous")) {
                d.anonymous = m.anonymous;
            }
            if (m.addable != null && m.hasOwnProperty("addable")) {
                d.addable = m.addable;
            }
            if (m.fixed != null && m.hasOwnProperty("fixed")) {
                d.fixed = m.fixed;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.itemList && m.itemList.length) {
                d.itemList = [];
                for (var j = 0; j < m.itemList.length; ++j) {
                    d.itemList[j] = $root.Proto.CommunityBoardPollItem.toObject(m.itemList[j], o);
                }
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.userPolled != null && m.hasOwnProperty("userPolled")) {
                d.userPolled = m.userPolled;
            }
            if (m.allCount != null && m.hasOwnProperty("allCount")) {
                d.allCount = m.allCount;
            }
            return d;
        };

        /**
         * Converts this CommunityBoardPoll to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardPoll
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardPoll.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardPoll;
    })();

    Proto.CommunityBoardPollList = (function() {

        /**
         * Properties of a CommunityBoardPollList.
         * @memberof Proto
         * @interface ICommunityBoardPollList
         * @property {Array.<Proto.ICommunityBoardPoll>|null} [value] CommunityBoardPollList value
         */

        /**
         * Constructs a new CommunityBoardPollList.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardPollList.
         * @implements ICommunityBoardPollList
         * @constructor
         * @param {Proto.ICommunityBoardPollList=} [p] Properties to set
         */
        function CommunityBoardPollList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardPollList value.
         * @member {Array.<Proto.ICommunityBoardPoll>} value
         * @memberof Proto.CommunityBoardPollList
         * @instance
         */
        CommunityBoardPollList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CommunityBoardPollList instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardPollList
         * @static
         * @param {Proto.ICommunityBoardPollList=} [properties] Properties to set
         * @returns {Proto.CommunityBoardPollList} CommunityBoardPollList instance
         */
        CommunityBoardPollList.create = function create(properties) {
            return new CommunityBoardPollList(properties);
        };

        /**
         * Encodes the specified CommunityBoardPollList message. Does not implicitly {@link Proto.CommunityBoardPollList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardPollList
         * @static
         * @param {Proto.ICommunityBoardPollList} m CommunityBoardPollList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardPollList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CommunityBoardPoll.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CommunityBoardPollList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardPollList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardPollList} CommunityBoardPollList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardPollList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardPollList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CommunityBoardPoll.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardPollList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardPollList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardPollList} CommunityBoardPollList
         */
        CommunityBoardPollList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardPollList)
                return d;
            var m = new $root.Proto.CommunityBoardPollList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CommunityBoardPollList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardPollList.value: object expected");
                    m.value[i] = $root.Proto.CommunityBoardPoll.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardPollList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardPollList
         * @static
         * @param {Proto.CommunityBoardPollList} m CommunityBoardPollList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardPollList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CommunityBoardPoll.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CommunityBoardPollList to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardPollList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardPollList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardPollList;
    })();

    Proto.CommunityBoardPollItem = (function() {

        /**
         * Properties of a CommunityBoardPollItem.
         * @memberof Proto
         * @interface ICommunityBoardPollItem
         * @property {string|null} [uid] CommunityBoardPollItem uid
         * @property {string|null} [pollUID] CommunityBoardPollItem pollUID
         * @property {string|null} [text] CommunityBoardPollItem text
         * @property {number|null} [order] CommunityBoardPollItem order
         * @property {string|null} [count] CommunityBoardPollItem count
         * @property {number|Long|null} [registerDate] CommunityBoardPollItem registerDate
         * @property {number|Long|null} [modifyDate] CommunityBoardPollItem modifyDate
         * @property {number|null} [status] CommunityBoardPollItem status
         * @property {boolean|null} [userChecked] CommunityBoardPollItem userChecked
         */

        /**
         * Constructs a new CommunityBoardPollItem.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardPollItem.
         * @implements ICommunityBoardPollItem
         * @constructor
         * @param {Proto.ICommunityBoardPollItem=} [p] Properties to set
         */
        function CommunityBoardPollItem(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardPollItem uid.
         * @member {string} uid
         * @memberof Proto.CommunityBoardPollItem
         * @instance
         */
        CommunityBoardPollItem.prototype.uid = "";

        /**
         * CommunityBoardPollItem pollUID.
         * @member {string} pollUID
         * @memberof Proto.CommunityBoardPollItem
         * @instance
         */
        CommunityBoardPollItem.prototype.pollUID = "";

        /**
         * CommunityBoardPollItem text.
         * @member {string} text
         * @memberof Proto.CommunityBoardPollItem
         * @instance
         */
        CommunityBoardPollItem.prototype.text = "";

        /**
         * CommunityBoardPollItem order.
         * @member {number} order
         * @memberof Proto.CommunityBoardPollItem
         * @instance
         */
        CommunityBoardPollItem.prototype.order = 0;

        /**
         * CommunityBoardPollItem count.
         * @member {string} count
         * @memberof Proto.CommunityBoardPollItem
         * @instance
         */
        CommunityBoardPollItem.prototype.count = "";

        /**
         * CommunityBoardPollItem registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommunityBoardPollItem
         * @instance
         */
        CommunityBoardPollItem.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardPollItem modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommunityBoardPollItem
         * @instance
         */
        CommunityBoardPollItem.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardPollItem status.
         * @member {number} status
         * @memberof Proto.CommunityBoardPollItem
         * @instance
         */
        CommunityBoardPollItem.prototype.status = 0;

        /**
         * CommunityBoardPollItem userChecked.
         * @member {boolean} userChecked
         * @memberof Proto.CommunityBoardPollItem
         * @instance
         */
        CommunityBoardPollItem.prototype.userChecked = false;

        /**
         * Creates a new CommunityBoardPollItem instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardPollItem
         * @static
         * @param {Proto.ICommunityBoardPollItem=} [properties] Properties to set
         * @returns {Proto.CommunityBoardPollItem} CommunityBoardPollItem instance
         */
        CommunityBoardPollItem.create = function create(properties) {
            return new CommunityBoardPollItem(properties);
        };

        /**
         * Encodes the specified CommunityBoardPollItem message. Does not implicitly {@link Proto.CommunityBoardPollItem.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardPollItem
         * @static
         * @param {Proto.ICommunityBoardPollItem} m CommunityBoardPollItem message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardPollItem.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.pollUID != null && Object.hasOwnProperty.call(m, "pollUID"))
                w.uint32(18).string(m.pollUID);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(32).int32(m.order);
            if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                w.uint32(42).string(m.count);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(64).int32(m.status);
            if (m.userChecked != null && Object.hasOwnProperty.call(m, "userChecked"))
                w.uint32(72).bool(m.userChecked);
            return w;
        };

        /**
         * Decodes a CommunityBoardPollItem message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardPollItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardPollItem} CommunityBoardPollItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardPollItem.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardPollItem();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.pollUID = r.string();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.order = r.int32();
                    break;
                case 5:
                    m.count = r.string();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.status = r.int32();
                    break;
                case 9:
                    m.userChecked = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardPollItem message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardPollItem
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardPollItem} CommunityBoardPollItem
         */
        CommunityBoardPollItem.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardPollItem)
                return d;
            var m = new $root.Proto.CommunityBoardPollItem();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.pollUID != null) {
                m.pollUID = String(d.pollUID);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.count != null) {
                m.count = String(d.count);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.userChecked != null) {
                m.userChecked = Boolean(d.userChecked);
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardPollItem message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardPollItem
         * @static
         * @param {Proto.CommunityBoardPollItem} m CommunityBoardPollItem
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardPollItem.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.pollUID = "";
                d.text = "";
                d.order = 0;
                d.count = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.userChecked = false;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.pollUID != null && m.hasOwnProperty("pollUID")) {
                d.pollUID = m.pollUID;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                d.count = m.count;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.userChecked != null && m.hasOwnProperty("userChecked")) {
                d.userChecked = m.userChecked;
            }
            return d;
        };

        /**
         * Converts this CommunityBoardPollItem to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardPollItem
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardPollItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardPollItem;
    })();

    Proto.CommunityBoardPollItemList = (function() {

        /**
         * Properties of a CommunityBoardPollItemList.
         * @memberof Proto
         * @interface ICommunityBoardPollItemList
         * @property {Array.<Proto.ICommunityBoardPollItem>|null} [value] CommunityBoardPollItemList value
         */

        /**
         * Constructs a new CommunityBoardPollItemList.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardPollItemList.
         * @implements ICommunityBoardPollItemList
         * @constructor
         * @param {Proto.ICommunityBoardPollItemList=} [p] Properties to set
         */
        function CommunityBoardPollItemList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardPollItemList value.
         * @member {Array.<Proto.ICommunityBoardPollItem>} value
         * @memberof Proto.CommunityBoardPollItemList
         * @instance
         */
        CommunityBoardPollItemList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CommunityBoardPollItemList instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardPollItemList
         * @static
         * @param {Proto.ICommunityBoardPollItemList=} [properties] Properties to set
         * @returns {Proto.CommunityBoardPollItemList} CommunityBoardPollItemList instance
         */
        CommunityBoardPollItemList.create = function create(properties) {
            return new CommunityBoardPollItemList(properties);
        };

        /**
         * Encodes the specified CommunityBoardPollItemList message. Does not implicitly {@link Proto.CommunityBoardPollItemList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardPollItemList
         * @static
         * @param {Proto.ICommunityBoardPollItemList} m CommunityBoardPollItemList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardPollItemList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CommunityBoardPollItem.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CommunityBoardPollItemList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardPollItemList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardPollItemList} CommunityBoardPollItemList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardPollItemList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardPollItemList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CommunityBoardPollItem.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardPollItemList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardPollItemList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardPollItemList} CommunityBoardPollItemList
         */
        CommunityBoardPollItemList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardPollItemList)
                return d;
            var m = new $root.Proto.CommunityBoardPollItemList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CommunityBoardPollItemList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardPollItemList.value: object expected");
                    m.value[i] = $root.Proto.CommunityBoardPollItem.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardPollItemList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardPollItemList
         * @static
         * @param {Proto.CommunityBoardPollItemList} m CommunityBoardPollItemList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardPollItemList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CommunityBoardPollItem.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CommunityBoardPollItemList to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardPollItemList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardPollItemList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardPollItemList;
    })();

    Proto.CommunityBoardMapGroup = (function() {

        /**
         * Properties of a CommunityBoardMapGroup.
         * @memberof Proto
         * @interface ICommunityBoardMapGroup
         * @property {string|null} [uid] CommunityBoardMapGroup uid
         * @property {string|null} [boardUID] CommunityBoardMapGroup boardUID
         * @property {string|null} [topicUID] CommunityBoardMapGroup topicUID
         * @property {string|null} [commentUID] CommunityBoardMapGroup commentUID
         * @property {string|null} [title] CommunityBoardMapGroup title
         * @property {string|null} [locationCount] CommunityBoardMapGroup locationCount
         * @property {number|Long|null} [registerDate] CommunityBoardMapGroup registerDate
         * @property {number|Long|null} [modifyDate] CommunityBoardMapGroup modifyDate
         * @property {Array.<Proto.ICommunityBoardMap>|null} [mapList] CommunityBoardMapGroup mapList
         * @property {number|null} [status] CommunityBoardMapGroup status
         */

        /**
         * Constructs a new CommunityBoardMapGroup.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardMapGroup.
         * @implements ICommunityBoardMapGroup
         * @constructor
         * @param {Proto.ICommunityBoardMapGroup=} [p] Properties to set
         */
        function CommunityBoardMapGroup(p) {
            this.mapList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardMapGroup uid.
         * @member {string} uid
         * @memberof Proto.CommunityBoardMapGroup
         * @instance
         */
        CommunityBoardMapGroup.prototype.uid = "";

        /**
         * CommunityBoardMapGroup boardUID.
         * @member {string} boardUID
         * @memberof Proto.CommunityBoardMapGroup
         * @instance
         */
        CommunityBoardMapGroup.prototype.boardUID = "";

        /**
         * CommunityBoardMapGroup topicUID.
         * @member {string} topicUID
         * @memberof Proto.CommunityBoardMapGroup
         * @instance
         */
        CommunityBoardMapGroup.prototype.topicUID = "";

        /**
         * CommunityBoardMapGroup commentUID.
         * @member {string} commentUID
         * @memberof Proto.CommunityBoardMapGroup
         * @instance
         */
        CommunityBoardMapGroup.prototype.commentUID = "";

        /**
         * CommunityBoardMapGroup title.
         * @member {string} title
         * @memberof Proto.CommunityBoardMapGroup
         * @instance
         */
        CommunityBoardMapGroup.prototype.title = "";

        /**
         * CommunityBoardMapGroup locationCount.
         * @member {string} locationCount
         * @memberof Proto.CommunityBoardMapGroup
         * @instance
         */
        CommunityBoardMapGroup.prototype.locationCount = "";

        /**
         * CommunityBoardMapGroup registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommunityBoardMapGroup
         * @instance
         */
        CommunityBoardMapGroup.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardMapGroup modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommunityBoardMapGroup
         * @instance
         */
        CommunityBoardMapGroup.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardMapGroup mapList.
         * @member {Array.<Proto.ICommunityBoardMap>} mapList
         * @memberof Proto.CommunityBoardMapGroup
         * @instance
         */
        CommunityBoardMapGroup.prototype.mapList = $util.emptyArray;

        /**
         * CommunityBoardMapGroup status.
         * @member {number} status
         * @memberof Proto.CommunityBoardMapGroup
         * @instance
         */
        CommunityBoardMapGroup.prototype.status = 0;

        /**
         * Creates a new CommunityBoardMapGroup instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardMapGroup
         * @static
         * @param {Proto.ICommunityBoardMapGroup=} [properties] Properties to set
         * @returns {Proto.CommunityBoardMapGroup} CommunityBoardMapGroup instance
         */
        CommunityBoardMapGroup.create = function create(properties) {
            return new CommunityBoardMapGroup(properties);
        };

        /**
         * Encodes the specified CommunityBoardMapGroup message. Does not implicitly {@link Proto.CommunityBoardMapGroup.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardMapGroup
         * @static
         * @param {Proto.ICommunityBoardMapGroup} m CommunityBoardMapGroup message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardMapGroup.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.boardUID != null && Object.hasOwnProperty.call(m, "boardUID"))
                w.uint32(18).string(m.boardUID);
            if (m.topicUID != null && Object.hasOwnProperty.call(m, "topicUID"))
                w.uint32(26).string(m.topicUID);
            if (m.commentUID != null && Object.hasOwnProperty.call(m, "commentUID"))
                w.uint32(34).string(m.commentUID);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(42).string(m.title);
            if (m.locationCount != null && Object.hasOwnProperty.call(m, "locationCount"))
                w.uint32(50).string(m.locationCount);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(56).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(64).int64(m.modifyDate);
            if (m.mapList != null && m.mapList.length) {
                for (var i = 0; i < m.mapList.length; ++i)
                    $root.Proto.CommunityBoardMap.encode(m.mapList[i], w.uint32(74).fork()).ldelim();
            }
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(80).int32(m.status);
            return w;
        };

        /**
         * Decodes a CommunityBoardMapGroup message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardMapGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardMapGroup} CommunityBoardMapGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardMapGroup.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardMapGroup();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.boardUID = r.string();
                    break;
                case 3:
                    m.topicUID = r.string();
                    break;
                case 4:
                    m.commentUID = r.string();
                    break;
                case 5:
                    m.title = r.string();
                    break;
                case 6:
                    m.locationCount = r.string();
                    break;
                case 7:
                    m.registerDate = r.int64();
                    break;
                case 8:
                    m.modifyDate = r.int64();
                    break;
                case 9:
                    if (!(m.mapList && m.mapList.length))
                        m.mapList = [];
                    m.mapList.push($root.Proto.CommunityBoardMap.decode(r, r.uint32()));
                    break;
                case 10:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardMapGroup message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardMapGroup
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardMapGroup} CommunityBoardMapGroup
         */
        CommunityBoardMapGroup.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardMapGroup)
                return d;
            var m = new $root.Proto.CommunityBoardMapGroup();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.boardUID != null) {
                m.boardUID = String(d.boardUID);
            }
            if (d.topicUID != null) {
                m.topicUID = String(d.topicUID);
            }
            if (d.commentUID != null) {
                m.commentUID = String(d.commentUID);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.locationCount != null) {
                m.locationCount = String(d.locationCount);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.mapList) {
                if (!Array.isArray(d.mapList))
                    throw TypeError(".Proto.CommunityBoardMapGroup.mapList: array expected");
                m.mapList = [];
                for (var i = 0; i < d.mapList.length; ++i) {
                    if (typeof d.mapList[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardMapGroup.mapList: object expected");
                    m.mapList[i] = $root.Proto.CommunityBoardMap.fromObject(d.mapList[i]);
                }
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardMapGroup message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardMapGroup
         * @static
         * @param {Proto.CommunityBoardMapGroup} m CommunityBoardMapGroup
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardMapGroup.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.mapList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.boardUID = "";
                d.topicUID = "";
                d.commentUID = "";
                d.title = "";
                d.locationCount = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.boardUID != null && m.hasOwnProperty("boardUID")) {
                d.boardUID = m.boardUID;
            }
            if (m.topicUID != null && m.hasOwnProperty("topicUID")) {
                d.topicUID = m.topicUID;
            }
            if (m.commentUID != null && m.hasOwnProperty("commentUID")) {
                d.commentUID = m.commentUID;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.locationCount != null && m.hasOwnProperty("locationCount")) {
                d.locationCount = m.locationCount;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.mapList && m.mapList.length) {
                d.mapList = [];
                for (var j = 0; j < m.mapList.length; ++j) {
                    d.mapList[j] = $root.Proto.CommunityBoardMap.toObject(m.mapList[j], o);
                }
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this CommunityBoardMapGroup to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardMapGroup
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardMapGroup.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardMapGroup;
    })();

    Proto.CommunityBoardMapGroupList = (function() {

        /**
         * Properties of a CommunityBoardMapGroupList.
         * @memberof Proto
         * @interface ICommunityBoardMapGroupList
         * @property {Array.<Proto.ICommunityBoardMapGroup>|null} [value] CommunityBoardMapGroupList value
         */

        /**
         * Constructs a new CommunityBoardMapGroupList.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardMapGroupList.
         * @implements ICommunityBoardMapGroupList
         * @constructor
         * @param {Proto.ICommunityBoardMapGroupList=} [p] Properties to set
         */
        function CommunityBoardMapGroupList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardMapGroupList value.
         * @member {Array.<Proto.ICommunityBoardMapGroup>} value
         * @memberof Proto.CommunityBoardMapGroupList
         * @instance
         */
        CommunityBoardMapGroupList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CommunityBoardMapGroupList instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardMapGroupList
         * @static
         * @param {Proto.ICommunityBoardMapGroupList=} [properties] Properties to set
         * @returns {Proto.CommunityBoardMapGroupList} CommunityBoardMapGroupList instance
         */
        CommunityBoardMapGroupList.create = function create(properties) {
            return new CommunityBoardMapGroupList(properties);
        };

        /**
         * Encodes the specified CommunityBoardMapGroupList message. Does not implicitly {@link Proto.CommunityBoardMapGroupList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardMapGroupList
         * @static
         * @param {Proto.ICommunityBoardMapGroupList} m CommunityBoardMapGroupList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardMapGroupList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CommunityBoardMapGroup.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CommunityBoardMapGroupList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardMapGroupList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardMapGroupList} CommunityBoardMapGroupList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardMapGroupList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardMapGroupList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CommunityBoardMapGroup.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardMapGroupList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardMapGroupList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardMapGroupList} CommunityBoardMapGroupList
         */
        CommunityBoardMapGroupList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardMapGroupList)
                return d;
            var m = new $root.Proto.CommunityBoardMapGroupList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CommunityBoardMapGroupList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardMapGroupList.value: object expected");
                    m.value[i] = $root.Proto.CommunityBoardMapGroup.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardMapGroupList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardMapGroupList
         * @static
         * @param {Proto.CommunityBoardMapGroupList} m CommunityBoardMapGroupList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardMapGroupList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CommunityBoardMapGroup.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CommunityBoardMapGroupList to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardMapGroupList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardMapGroupList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardMapGroupList;
    })();

    Proto.CommunityBoardMap = (function() {

        /**
         * Properties of a CommunityBoardMap.
         * @memberof Proto
         * @interface ICommunityBoardMap
         * @property {string|null} [uid] CommunityBoardMap uid
         * @property {string|null} [groupUID] CommunityBoardMap groupUID
         * @property {string|null} [name] CommunityBoardMap name
         * @property {string|null} [address] CommunityBoardMap address
         * @property {number|null} [lat] CommunityBoardMap lat
         * @property {number|null} [lon] CommunityBoardMap lon
         * @property {string|null} [placeId] CommunityBoardMap placeId
         * @property {number|Long|null} [registerDate] CommunityBoardMap registerDate
         * @property {number|Long|null} [modifyDate] CommunityBoardMap modifyDate
         * @property {number|null} [status] CommunityBoardMap status
         * @property {number|null} [idType] CommunityBoardMap idType
         */

        /**
         * Constructs a new CommunityBoardMap.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardMap.
         * @implements ICommunityBoardMap
         * @constructor
         * @param {Proto.ICommunityBoardMap=} [p] Properties to set
         */
        function CommunityBoardMap(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardMap uid.
         * @member {string} uid
         * @memberof Proto.CommunityBoardMap
         * @instance
         */
        CommunityBoardMap.prototype.uid = "";

        /**
         * CommunityBoardMap groupUID.
         * @member {string} groupUID
         * @memberof Proto.CommunityBoardMap
         * @instance
         */
        CommunityBoardMap.prototype.groupUID = "";

        /**
         * CommunityBoardMap name.
         * @member {string} name
         * @memberof Proto.CommunityBoardMap
         * @instance
         */
        CommunityBoardMap.prototype.name = "";

        /**
         * CommunityBoardMap address.
         * @member {string} address
         * @memberof Proto.CommunityBoardMap
         * @instance
         */
        CommunityBoardMap.prototype.address = "";

        /**
         * CommunityBoardMap lat.
         * @member {number} lat
         * @memberof Proto.CommunityBoardMap
         * @instance
         */
        CommunityBoardMap.prototype.lat = 0;

        /**
         * CommunityBoardMap lon.
         * @member {number} lon
         * @memberof Proto.CommunityBoardMap
         * @instance
         */
        CommunityBoardMap.prototype.lon = 0;

        /**
         * CommunityBoardMap placeId.
         * @member {string} placeId
         * @memberof Proto.CommunityBoardMap
         * @instance
         */
        CommunityBoardMap.prototype.placeId = "";

        /**
         * CommunityBoardMap registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommunityBoardMap
         * @instance
         */
        CommunityBoardMap.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardMap modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommunityBoardMap
         * @instance
         */
        CommunityBoardMap.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardMap status.
         * @member {number} status
         * @memberof Proto.CommunityBoardMap
         * @instance
         */
        CommunityBoardMap.prototype.status = 0;

        /**
         * CommunityBoardMap idType.
         * @member {number} idType
         * @memberof Proto.CommunityBoardMap
         * @instance
         */
        CommunityBoardMap.prototype.idType = 0;

        /**
         * Creates a new CommunityBoardMap instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardMap
         * @static
         * @param {Proto.ICommunityBoardMap=} [properties] Properties to set
         * @returns {Proto.CommunityBoardMap} CommunityBoardMap instance
         */
        CommunityBoardMap.create = function create(properties) {
            return new CommunityBoardMap(properties);
        };

        /**
         * Encodes the specified CommunityBoardMap message. Does not implicitly {@link Proto.CommunityBoardMap.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardMap
         * @static
         * @param {Proto.ICommunityBoardMap} m CommunityBoardMap message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardMap.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.groupUID != null && Object.hasOwnProperty.call(m, "groupUID"))
                w.uint32(18).string(m.groupUID);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.address != null && Object.hasOwnProperty.call(m, "address"))
                w.uint32(34).string(m.address);
            if (m.lat != null && Object.hasOwnProperty.call(m, "lat"))
                w.uint32(41).double(m.lat);
            if (m.lon != null && Object.hasOwnProperty.call(m, "lon"))
                w.uint32(49).double(m.lon);
            if (m.placeId != null && Object.hasOwnProperty.call(m, "placeId"))
                w.uint32(58).string(m.placeId);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(80).int32(m.status);
            if (m.idType != null && Object.hasOwnProperty.call(m, "idType"))
                w.uint32(88).int32(m.idType);
            return w;
        };

        /**
         * Decodes a CommunityBoardMap message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardMap} CommunityBoardMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardMap.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardMap();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.groupUID = r.string();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.address = r.string();
                    break;
                case 5:
                    m.lat = r.double();
                    break;
                case 6:
                    m.lon = r.double();
                    break;
                case 7:
                    m.placeId = r.string();
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                case 10:
                    m.status = r.int32();
                    break;
                case 11:
                    m.idType = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardMap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardMap
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardMap} CommunityBoardMap
         */
        CommunityBoardMap.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardMap)
                return d;
            var m = new $root.Proto.CommunityBoardMap();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.groupUID != null) {
                m.groupUID = String(d.groupUID);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.address != null) {
                m.address = String(d.address);
            }
            if (d.lat != null) {
                m.lat = Number(d.lat);
            }
            if (d.lon != null) {
                m.lon = Number(d.lon);
            }
            if (d.placeId != null) {
                m.placeId = String(d.placeId);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.idType != null) {
                m.idType = d.idType | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardMap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardMap
         * @static
         * @param {Proto.CommunityBoardMap} m CommunityBoardMap
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardMap.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.groupUID = "";
                d.name = "";
                d.address = "";
                d.lat = 0;
                d.lon = 0;
                d.placeId = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.idType = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.groupUID != null && m.hasOwnProperty("groupUID")) {
                d.groupUID = m.groupUID;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.address != null && m.hasOwnProperty("address")) {
                d.address = m.address;
            }
            if (m.lat != null && m.hasOwnProperty("lat")) {
                d.lat = o.json && !isFinite(m.lat) ? String(m.lat) : m.lat;
            }
            if (m.lon != null && m.hasOwnProperty("lon")) {
                d.lon = o.json && !isFinite(m.lon) ? String(m.lon) : m.lon;
            }
            if (m.placeId != null && m.hasOwnProperty("placeId")) {
                d.placeId = m.placeId;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.idType != null && m.hasOwnProperty("idType")) {
                d.idType = m.idType;
            }
            return d;
        };

        /**
         * Converts this CommunityBoardMap to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardMap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardMap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardMap;
    })();

    Proto.CommunityBoardMapList = (function() {

        /**
         * Properties of a CommunityBoardMapList.
         * @memberof Proto
         * @interface ICommunityBoardMapList
         * @property {Array.<Proto.ICommunityBoardMap>|null} [value] CommunityBoardMapList value
         */

        /**
         * Constructs a new CommunityBoardMapList.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardMapList.
         * @implements ICommunityBoardMapList
         * @constructor
         * @param {Proto.ICommunityBoardMapList=} [p] Properties to set
         */
        function CommunityBoardMapList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardMapList value.
         * @member {Array.<Proto.ICommunityBoardMap>} value
         * @memberof Proto.CommunityBoardMapList
         * @instance
         */
        CommunityBoardMapList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CommunityBoardMapList instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardMapList
         * @static
         * @param {Proto.ICommunityBoardMapList=} [properties] Properties to set
         * @returns {Proto.CommunityBoardMapList} CommunityBoardMapList instance
         */
        CommunityBoardMapList.create = function create(properties) {
            return new CommunityBoardMapList(properties);
        };

        /**
         * Encodes the specified CommunityBoardMapList message. Does not implicitly {@link Proto.CommunityBoardMapList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardMapList
         * @static
         * @param {Proto.ICommunityBoardMapList} m CommunityBoardMapList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardMapList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CommunityBoardMap.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CommunityBoardMapList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardMapList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardMapList} CommunityBoardMapList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardMapList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardMapList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CommunityBoardMap.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardMapList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardMapList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardMapList} CommunityBoardMapList
         */
        CommunityBoardMapList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardMapList)
                return d;
            var m = new $root.Proto.CommunityBoardMapList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CommunityBoardMapList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardMapList.value: object expected");
                    m.value[i] = $root.Proto.CommunityBoardMap.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardMapList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardMapList
         * @static
         * @param {Proto.CommunityBoardMapList} m CommunityBoardMapList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardMapList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CommunityBoardMap.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CommunityBoardMapList to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardMapList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardMapList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardMapList;
    })();

    Proto.CompanyApp = (function() {

        /**
         * Properties of a CompanyApp.
         * @memberof Proto
         * @interface ICompanyApp
         * @property {string|null} [companyUID] CompanyApp companyUID
         * @property {number|null} [appTheme] CompanyApp appTheme
         * @property {number|null} [appHome] CompanyApp appHome
         * @property {string|null} [homeContent] CompanyApp homeContent
         * @property {string|null} [tabContent] CompanyApp tabContent
         * @property {number|Long|null} [registerDate] CompanyApp registerDate
         * @property {number|Long|null} [modifyDate] CompanyApp modifyDate
         * @property {boolean|null} [allowSignupPlan] CompanyApp allowSignupPlan
         * @property {number|null} [talkType] CompanyApp talkType
         * @property {number|null} [talkPolicy] CompanyApp talkPolicy
         * @property {string|null} [talkOAuth] CompanyApp talkOAuth
         * @property {string|null} [directLinkIos] CompanyApp directLinkIos
         * @property {string|null} [directLinkAndroid] CompanyApp directLinkAndroid
         * @property {boolean|null} [customColor] CompanyApp customColor
         * @property {string|null} [colorContent] CompanyApp colorContent
         * @property {boolean|null} [headerForum] CompanyApp headerForum
         * @property {boolean|null} [headerChat] CompanyApp headerChat
         */

        /**
         * Constructs a new CompanyApp.
         * @memberof Proto
         * @classdesc Represents a CompanyApp.
         * @implements ICompanyApp
         * @constructor
         * @param {Proto.ICompanyApp=} [p] Properties to set
         */
        function CompanyApp(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyApp companyUID.
         * @member {string} companyUID
         * @memberof Proto.CompanyApp
         * @instance
         */
        CompanyApp.prototype.companyUID = "";

        /**
         * CompanyApp appTheme.
         * @member {number} appTheme
         * @memberof Proto.CompanyApp
         * @instance
         */
        CompanyApp.prototype.appTheme = 0;

        /**
         * CompanyApp appHome.
         * @member {number} appHome
         * @memberof Proto.CompanyApp
         * @instance
         */
        CompanyApp.prototype.appHome = 0;

        /**
         * CompanyApp homeContent.
         * @member {string} homeContent
         * @memberof Proto.CompanyApp
         * @instance
         */
        CompanyApp.prototype.homeContent = "";

        /**
         * CompanyApp tabContent.
         * @member {string} tabContent
         * @memberof Proto.CompanyApp
         * @instance
         */
        CompanyApp.prototype.tabContent = "";

        /**
         * CompanyApp registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CompanyApp
         * @instance
         */
        CompanyApp.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyApp modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CompanyApp
         * @instance
         */
        CompanyApp.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyApp allowSignupPlan.
         * @member {boolean} allowSignupPlan
         * @memberof Proto.CompanyApp
         * @instance
         */
        CompanyApp.prototype.allowSignupPlan = false;

        /**
         * CompanyApp talkType.
         * @member {number} talkType
         * @memberof Proto.CompanyApp
         * @instance
         */
        CompanyApp.prototype.talkType = 0;

        /**
         * CompanyApp talkPolicy.
         * @member {number} talkPolicy
         * @memberof Proto.CompanyApp
         * @instance
         */
        CompanyApp.prototype.talkPolicy = 0;

        /**
         * CompanyApp talkOAuth.
         * @member {string} talkOAuth
         * @memberof Proto.CompanyApp
         * @instance
         */
        CompanyApp.prototype.talkOAuth = "";

        /**
         * CompanyApp directLinkIos.
         * @member {string} directLinkIos
         * @memberof Proto.CompanyApp
         * @instance
         */
        CompanyApp.prototype.directLinkIos = "";

        /**
         * CompanyApp directLinkAndroid.
         * @member {string} directLinkAndroid
         * @memberof Proto.CompanyApp
         * @instance
         */
        CompanyApp.prototype.directLinkAndroid = "";

        /**
         * CompanyApp customColor.
         * @member {boolean} customColor
         * @memberof Proto.CompanyApp
         * @instance
         */
        CompanyApp.prototype.customColor = false;

        /**
         * CompanyApp colorContent.
         * @member {string} colorContent
         * @memberof Proto.CompanyApp
         * @instance
         */
        CompanyApp.prototype.colorContent = "";

        /**
         * CompanyApp headerForum.
         * @member {boolean} headerForum
         * @memberof Proto.CompanyApp
         * @instance
         */
        CompanyApp.prototype.headerForum = false;

        /**
         * CompanyApp headerChat.
         * @member {boolean} headerChat
         * @memberof Proto.CompanyApp
         * @instance
         */
        CompanyApp.prototype.headerChat = false;

        /**
         * Creates a new CompanyApp instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyApp
         * @static
         * @param {Proto.ICompanyApp=} [properties] Properties to set
         * @returns {Proto.CompanyApp} CompanyApp instance
         */
        CompanyApp.create = function create(properties) {
            return new CompanyApp(properties);
        };

        /**
         * Encodes the specified CompanyApp message. Does not implicitly {@link Proto.CompanyApp.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyApp
         * @static
         * @param {Proto.ICompanyApp} m CompanyApp message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyApp.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(10).string(m.companyUID);
            if (m.appTheme != null && Object.hasOwnProperty.call(m, "appTheme"))
                w.uint32(16).int32(m.appTheme);
            if (m.appHome != null && Object.hasOwnProperty.call(m, "appHome"))
                w.uint32(24).int32(m.appHome);
            if (m.homeContent != null && Object.hasOwnProperty.call(m, "homeContent"))
                w.uint32(34).string(m.homeContent);
            if (m.tabContent != null && Object.hasOwnProperty.call(m, "tabContent"))
                w.uint32(42).string(m.tabContent);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.allowSignupPlan != null && Object.hasOwnProperty.call(m, "allowSignupPlan"))
                w.uint32(64).bool(m.allowSignupPlan);
            if (m.talkType != null && Object.hasOwnProperty.call(m, "talkType"))
                w.uint32(72).int32(m.talkType);
            if (m.talkPolicy != null && Object.hasOwnProperty.call(m, "talkPolicy"))
                w.uint32(80).int32(m.talkPolicy);
            if (m.talkOAuth != null && Object.hasOwnProperty.call(m, "talkOAuth"))
                w.uint32(90).string(m.talkOAuth);
            if (m.directLinkIos != null && Object.hasOwnProperty.call(m, "directLinkIos"))
                w.uint32(98).string(m.directLinkIos);
            if (m.directLinkAndroid != null && Object.hasOwnProperty.call(m, "directLinkAndroid"))
                w.uint32(106).string(m.directLinkAndroid);
            if (m.customColor != null && Object.hasOwnProperty.call(m, "customColor"))
                w.uint32(112).bool(m.customColor);
            if (m.colorContent != null && Object.hasOwnProperty.call(m, "colorContent"))
                w.uint32(122).string(m.colorContent);
            if (m.headerForum != null && Object.hasOwnProperty.call(m, "headerForum"))
                w.uint32(128).bool(m.headerForum);
            if (m.headerChat != null && Object.hasOwnProperty.call(m, "headerChat"))
                w.uint32(136).bool(m.headerChat);
            return w;
        };

        /**
         * Decodes a CompanyApp message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyApp
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyApp} CompanyApp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyApp.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyApp();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.companyUID = r.string();
                    break;
                case 2:
                    m.appTheme = r.int32();
                    break;
                case 3:
                    m.appHome = r.int32();
                    break;
                case 4:
                    m.homeContent = r.string();
                    break;
                case 5:
                    m.tabContent = r.string();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.allowSignupPlan = r.bool();
                    break;
                case 9:
                    m.talkType = r.int32();
                    break;
                case 10:
                    m.talkPolicy = r.int32();
                    break;
                case 11:
                    m.talkOAuth = r.string();
                    break;
                case 12:
                    m.directLinkIos = r.string();
                    break;
                case 13:
                    m.directLinkAndroid = r.string();
                    break;
                case 14:
                    m.customColor = r.bool();
                    break;
                case 15:
                    m.colorContent = r.string();
                    break;
                case 16:
                    m.headerForum = r.bool();
                    break;
                case 17:
                    m.headerChat = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyApp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyApp
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyApp} CompanyApp
         */
        CompanyApp.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyApp)
                return d;
            var m = new $root.Proto.CompanyApp();
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.appTheme != null) {
                m.appTheme = d.appTheme | 0;
            }
            if (d.appHome != null) {
                m.appHome = d.appHome | 0;
            }
            if (d.homeContent != null) {
                m.homeContent = String(d.homeContent);
            }
            if (d.tabContent != null) {
                m.tabContent = String(d.tabContent);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.allowSignupPlan != null) {
                m.allowSignupPlan = Boolean(d.allowSignupPlan);
            }
            if (d.talkType != null) {
                m.talkType = d.talkType | 0;
            }
            if (d.talkPolicy != null) {
                m.talkPolicy = d.talkPolicy | 0;
            }
            if (d.talkOAuth != null) {
                m.talkOAuth = String(d.talkOAuth);
            }
            if (d.directLinkIos != null) {
                m.directLinkIos = String(d.directLinkIos);
            }
            if (d.directLinkAndroid != null) {
                m.directLinkAndroid = String(d.directLinkAndroid);
            }
            if (d.customColor != null) {
                m.customColor = Boolean(d.customColor);
            }
            if (d.colorContent != null) {
                m.colorContent = String(d.colorContent);
            }
            if (d.headerForum != null) {
                m.headerForum = Boolean(d.headerForum);
            }
            if (d.headerChat != null) {
                m.headerChat = Boolean(d.headerChat);
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyApp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyApp
         * @static
         * @param {Proto.CompanyApp} m CompanyApp
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyApp.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.companyUID = "";
                d.appTheme = 0;
                d.appHome = 0;
                d.homeContent = "";
                d.tabContent = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.allowSignupPlan = false;
                d.talkType = 0;
                d.talkPolicy = 0;
                d.talkOAuth = "";
                d.directLinkIos = "";
                d.directLinkAndroid = "";
                d.customColor = false;
                d.colorContent = "";
                d.headerForum = false;
                d.headerChat = false;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.appTheme != null && m.hasOwnProperty("appTheme")) {
                d.appTheme = m.appTheme;
            }
            if (m.appHome != null && m.hasOwnProperty("appHome")) {
                d.appHome = m.appHome;
            }
            if (m.homeContent != null && m.hasOwnProperty("homeContent")) {
                d.homeContent = m.homeContent;
            }
            if (m.tabContent != null && m.hasOwnProperty("tabContent")) {
                d.tabContent = m.tabContent;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.allowSignupPlan != null && m.hasOwnProperty("allowSignupPlan")) {
                d.allowSignupPlan = m.allowSignupPlan;
            }
            if (m.talkType != null && m.hasOwnProperty("talkType")) {
                d.talkType = m.talkType;
            }
            if (m.talkPolicy != null && m.hasOwnProperty("talkPolicy")) {
                d.talkPolicy = m.talkPolicy;
            }
            if (m.talkOAuth != null && m.hasOwnProperty("talkOAuth")) {
                d.talkOAuth = m.talkOAuth;
            }
            if (m.directLinkIos != null && m.hasOwnProperty("directLinkIos")) {
                d.directLinkIos = m.directLinkIos;
            }
            if (m.directLinkAndroid != null && m.hasOwnProperty("directLinkAndroid")) {
                d.directLinkAndroid = m.directLinkAndroid;
            }
            if (m.customColor != null && m.hasOwnProperty("customColor")) {
                d.customColor = m.customColor;
            }
            if (m.colorContent != null && m.hasOwnProperty("colorContent")) {
                d.colorContent = m.colorContent;
            }
            if (m.headerForum != null && m.hasOwnProperty("headerForum")) {
                d.headerForum = m.headerForum;
            }
            if (m.headerChat != null && m.hasOwnProperty("headerChat")) {
                d.headerChat = m.headerChat;
            }
            return d;
        };

        /**
         * Converts this CompanyApp to JSON.
         * @function toJSON
         * @memberof Proto.CompanyApp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyApp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyApp;
    })();

    Proto.ListRequestFromApp = (function() {

        /**
         * Properties of a ListRequestFromApp.
         * @memberof Proto
         * @interface IListRequestFromApp
         * @property {Array.<string>|null} [uidList] ListRequestFromApp uidList
         * @property {string|null} [pageNum] ListRequestFromApp pageNum
         * @property {string|null} [pageRow] ListRequestFromApp pageRow
         * @property {string|null} [title] ListRequestFromApp title
         * @property {number|null} [sortType] ListRequestFromApp sortType
         * @property {boolean|null} [all] ListRequestFromApp all
         * @property {boolean|null} [includeSoldOut] ListRequestFromApp includeSoldOut
         * @property {boolean|null} [anonymous] ListRequestFromApp anonymous
         * @property {Array.<number>|null} [contentTypes] ListRequestFromApp contentTypes
         * @property {Array.<number>|null} [types] ListRequestFromApp types
         * @property {boolean|null} [parentOnly] ListRequestFromApp parentOnly
         * @property {string|null} [parentUID] ListRequestFromApp parentUID
         * @property {string|null} [targetUserUID] ListRequestFromApp targetUserUID
         * @property {string|null} [forumUID] ListRequestFromApp forumUID
         * @property {Array.<string>|null} [playlistUIDList] ListRequestFromApp playlistUIDList
         */

        /**
         * Constructs a new ListRequestFromApp.
         * @memberof Proto
         * @classdesc Represents a ListRequestFromApp.
         * @implements IListRequestFromApp
         * @constructor
         * @param {Proto.IListRequestFromApp=} [p] Properties to set
         */
        function ListRequestFromApp(p) {
            this.uidList = [];
            this.contentTypes = [];
            this.types = [];
            this.playlistUIDList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ListRequestFromApp uidList.
         * @member {Array.<string>} uidList
         * @memberof Proto.ListRequestFromApp
         * @instance
         */
        ListRequestFromApp.prototype.uidList = $util.emptyArray;

        /**
         * ListRequestFromApp pageNum.
         * @member {string} pageNum
         * @memberof Proto.ListRequestFromApp
         * @instance
         */
        ListRequestFromApp.prototype.pageNum = "";

        /**
         * ListRequestFromApp pageRow.
         * @member {string} pageRow
         * @memberof Proto.ListRequestFromApp
         * @instance
         */
        ListRequestFromApp.prototype.pageRow = "";

        /**
         * ListRequestFromApp title.
         * @member {string} title
         * @memberof Proto.ListRequestFromApp
         * @instance
         */
        ListRequestFromApp.prototype.title = "";

        /**
         * ListRequestFromApp sortType.
         * @member {number} sortType
         * @memberof Proto.ListRequestFromApp
         * @instance
         */
        ListRequestFromApp.prototype.sortType = 0;

        /**
         * ListRequestFromApp all.
         * @member {boolean} all
         * @memberof Proto.ListRequestFromApp
         * @instance
         */
        ListRequestFromApp.prototype.all = false;

        /**
         * ListRequestFromApp includeSoldOut.
         * @member {boolean} includeSoldOut
         * @memberof Proto.ListRequestFromApp
         * @instance
         */
        ListRequestFromApp.prototype.includeSoldOut = false;

        /**
         * ListRequestFromApp anonymous.
         * @member {boolean} anonymous
         * @memberof Proto.ListRequestFromApp
         * @instance
         */
        ListRequestFromApp.prototype.anonymous = false;

        /**
         * ListRequestFromApp contentTypes.
         * @member {Array.<number>} contentTypes
         * @memberof Proto.ListRequestFromApp
         * @instance
         */
        ListRequestFromApp.prototype.contentTypes = $util.emptyArray;

        /**
         * ListRequestFromApp types.
         * @member {Array.<number>} types
         * @memberof Proto.ListRequestFromApp
         * @instance
         */
        ListRequestFromApp.prototype.types = $util.emptyArray;

        /**
         * ListRequestFromApp parentOnly.
         * @member {boolean} parentOnly
         * @memberof Proto.ListRequestFromApp
         * @instance
         */
        ListRequestFromApp.prototype.parentOnly = false;

        /**
         * ListRequestFromApp parentUID.
         * @member {string} parentUID
         * @memberof Proto.ListRequestFromApp
         * @instance
         */
        ListRequestFromApp.prototype.parentUID = "";

        /**
         * ListRequestFromApp targetUserUID.
         * @member {string} targetUserUID
         * @memberof Proto.ListRequestFromApp
         * @instance
         */
        ListRequestFromApp.prototype.targetUserUID = "";

        /**
         * ListRequestFromApp forumUID.
         * @member {string} forumUID
         * @memberof Proto.ListRequestFromApp
         * @instance
         */
        ListRequestFromApp.prototype.forumUID = "";

        /**
         * ListRequestFromApp playlistUIDList.
         * @member {Array.<string>} playlistUIDList
         * @memberof Proto.ListRequestFromApp
         * @instance
         */
        ListRequestFromApp.prototype.playlistUIDList = $util.emptyArray;

        /**
         * Creates a new ListRequestFromApp instance using the specified properties.
         * @function create
         * @memberof Proto.ListRequestFromApp
         * @static
         * @param {Proto.IListRequestFromApp=} [properties] Properties to set
         * @returns {Proto.ListRequestFromApp} ListRequestFromApp instance
         */
        ListRequestFromApp.create = function create(properties) {
            return new ListRequestFromApp(properties);
        };

        /**
         * Encodes the specified ListRequestFromApp message. Does not implicitly {@link Proto.ListRequestFromApp.verify|verify} messages.
         * @function encode
         * @memberof Proto.ListRequestFromApp
         * @static
         * @param {Proto.IListRequestFromApp} m ListRequestFromApp message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListRequestFromApp.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uidList != null && m.uidList.length) {
                for (var i = 0; i < m.uidList.length; ++i)
                    w.uint32(10).string(m.uidList[i]);
            }
            if (m.pageNum != null && Object.hasOwnProperty.call(m, "pageNum"))
                w.uint32(18).string(m.pageNum);
            if (m.pageRow != null && Object.hasOwnProperty.call(m, "pageRow"))
                w.uint32(26).string(m.pageRow);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(34).string(m.title);
            if (m.sortType != null && Object.hasOwnProperty.call(m, "sortType"))
                w.uint32(40).int32(m.sortType);
            if (m.all != null && Object.hasOwnProperty.call(m, "all"))
                w.uint32(48).bool(m.all);
            if (m.includeSoldOut != null && Object.hasOwnProperty.call(m, "includeSoldOut"))
                w.uint32(56).bool(m.includeSoldOut);
            if (m.anonymous != null && Object.hasOwnProperty.call(m, "anonymous"))
                w.uint32(64).bool(m.anonymous);
            if (m.contentTypes != null && m.contentTypes.length) {
                w.uint32(74).fork();
                for (var i = 0; i < m.contentTypes.length; ++i)
                    w.int32(m.contentTypes[i]);
                w.ldelim();
            }
            if (m.types != null && m.types.length) {
                w.uint32(82).fork();
                for (var i = 0; i < m.types.length; ++i)
                    w.int32(m.types[i]);
                w.ldelim();
            }
            if (m.parentOnly != null && Object.hasOwnProperty.call(m, "parentOnly"))
                w.uint32(88).bool(m.parentOnly);
            if (m.parentUID != null && Object.hasOwnProperty.call(m, "parentUID"))
                w.uint32(98).string(m.parentUID);
            if (m.targetUserUID != null && Object.hasOwnProperty.call(m, "targetUserUID"))
                w.uint32(106).string(m.targetUserUID);
            if (m.forumUID != null && Object.hasOwnProperty.call(m, "forumUID"))
                w.uint32(114).string(m.forumUID);
            if (m.playlistUIDList != null && m.playlistUIDList.length) {
                for (var i = 0; i < m.playlistUIDList.length; ++i)
                    w.uint32(122).string(m.playlistUIDList[i]);
            }
            return w;
        };

        /**
         * Decodes a ListRequestFromApp message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ListRequestFromApp
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ListRequestFromApp} ListRequestFromApp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListRequestFromApp.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ListRequestFromApp();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.uidList && m.uidList.length))
                        m.uidList = [];
                    m.uidList.push(r.string());
                    break;
                case 2:
                    m.pageNum = r.string();
                    break;
                case 3:
                    m.pageRow = r.string();
                    break;
                case 4:
                    m.title = r.string();
                    break;
                case 5:
                    m.sortType = r.int32();
                    break;
                case 6:
                    m.all = r.bool();
                    break;
                case 7:
                    m.includeSoldOut = r.bool();
                    break;
                case 8:
                    m.anonymous = r.bool();
                    break;
                case 9:
                    if (!(m.contentTypes && m.contentTypes.length))
                        m.contentTypes = [];
                    if ((t & 7) === 2) {
                        var c2 = r.uint32() + r.pos;
                        while (r.pos < c2)
                            m.contentTypes.push(r.int32());
                    } else
                        m.contentTypes.push(r.int32());
                    break;
                case 10:
                    if (!(m.types && m.types.length))
                        m.types = [];
                    if ((t & 7) === 2) {
                        var c2 = r.uint32() + r.pos;
                        while (r.pos < c2)
                            m.types.push(r.int32());
                    } else
                        m.types.push(r.int32());
                    break;
                case 11:
                    m.parentOnly = r.bool();
                    break;
                case 12:
                    m.parentUID = r.string();
                    break;
                case 13:
                    m.targetUserUID = r.string();
                    break;
                case 14:
                    m.forumUID = r.string();
                    break;
                case 15:
                    if (!(m.playlistUIDList && m.playlistUIDList.length))
                        m.playlistUIDList = [];
                    m.playlistUIDList.push(r.string());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ListRequestFromApp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ListRequestFromApp
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ListRequestFromApp} ListRequestFromApp
         */
        ListRequestFromApp.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ListRequestFromApp)
                return d;
            var m = new $root.Proto.ListRequestFromApp();
            if (d.uidList) {
                if (!Array.isArray(d.uidList))
                    throw TypeError(".Proto.ListRequestFromApp.uidList: array expected");
                m.uidList = [];
                for (var i = 0; i < d.uidList.length; ++i) {
                    m.uidList[i] = String(d.uidList[i]);
                }
            }
            if (d.pageNum != null) {
                m.pageNum = String(d.pageNum);
            }
            if (d.pageRow != null) {
                m.pageRow = String(d.pageRow);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.sortType != null) {
                m.sortType = d.sortType | 0;
            }
            if (d.all != null) {
                m.all = Boolean(d.all);
            }
            if (d.includeSoldOut != null) {
                m.includeSoldOut = Boolean(d.includeSoldOut);
            }
            if (d.anonymous != null) {
                m.anonymous = Boolean(d.anonymous);
            }
            if (d.contentTypes) {
                if (!Array.isArray(d.contentTypes))
                    throw TypeError(".Proto.ListRequestFromApp.contentTypes: array expected");
                m.contentTypes = [];
                for (var i = 0; i < d.contentTypes.length; ++i) {
                    m.contentTypes[i] = d.contentTypes[i] | 0;
                }
            }
            if (d.types) {
                if (!Array.isArray(d.types))
                    throw TypeError(".Proto.ListRequestFromApp.types: array expected");
                m.types = [];
                for (var i = 0; i < d.types.length; ++i) {
                    m.types[i] = d.types[i] | 0;
                }
            }
            if (d.parentOnly != null) {
                m.parentOnly = Boolean(d.parentOnly);
            }
            if (d.parentUID != null) {
                m.parentUID = String(d.parentUID);
            }
            if (d.targetUserUID != null) {
                m.targetUserUID = String(d.targetUserUID);
            }
            if (d.forumUID != null) {
                m.forumUID = String(d.forumUID);
            }
            if (d.playlistUIDList) {
                if (!Array.isArray(d.playlistUIDList))
                    throw TypeError(".Proto.ListRequestFromApp.playlistUIDList: array expected");
                m.playlistUIDList = [];
                for (var i = 0; i < d.playlistUIDList.length; ++i) {
                    m.playlistUIDList[i] = String(d.playlistUIDList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ListRequestFromApp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ListRequestFromApp
         * @static
         * @param {Proto.ListRequestFromApp} m ListRequestFromApp
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListRequestFromApp.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.uidList = [];
                d.contentTypes = [];
                d.types = [];
                d.playlistUIDList = [];
            }
            if (o.defaults) {
                d.pageNum = "";
                d.pageRow = "";
                d.title = "";
                d.sortType = 0;
                d.all = false;
                d.includeSoldOut = false;
                d.anonymous = false;
                d.parentOnly = false;
                d.parentUID = "";
                d.targetUserUID = "";
                d.forumUID = "";
            }
            if (m.uidList && m.uidList.length) {
                d.uidList = [];
                for (var j = 0; j < m.uidList.length; ++j) {
                    d.uidList[j] = m.uidList[j];
                }
            }
            if (m.pageNum != null && m.hasOwnProperty("pageNum")) {
                d.pageNum = m.pageNum;
            }
            if (m.pageRow != null && m.hasOwnProperty("pageRow")) {
                d.pageRow = m.pageRow;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.sortType != null && m.hasOwnProperty("sortType")) {
                d.sortType = m.sortType;
            }
            if (m.all != null && m.hasOwnProperty("all")) {
                d.all = m.all;
            }
            if (m.includeSoldOut != null && m.hasOwnProperty("includeSoldOut")) {
                d.includeSoldOut = m.includeSoldOut;
            }
            if (m.anonymous != null && m.hasOwnProperty("anonymous")) {
                d.anonymous = m.anonymous;
            }
            if (m.contentTypes && m.contentTypes.length) {
                d.contentTypes = [];
                for (var j = 0; j < m.contentTypes.length; ++j) {
                    d.contentTypes[j] = m.contentTypes[j];
                }
            }
            if (m.types && m.types.length) {
                d.types = [];
                for (var j = 0; j < m.types.length; ++j) {
                    d.types[j] = m.types[j];
                }
            }
            if (m.parentOnly != null && m.hasOwnProperty("parentOnly")) {
                d.parentOnly = m.parentOnly;
            }
            if (m.parentUID != null && m.hasOwnProperty("parentUID")) {
                d.parentUID = m.parentUID;
            }
            if (m.targetUserUID != null && m.hasOwnProperty("targetUserUID")) {
                d.targetUserUID = m.targetUserUID;
            }
            if (m.forumUID != null && m.hasOwnProperty("forumUID")) {
                d.forumUID = m.forumUID;
            }
            if (m.playlistUIDList && m.playlistUIDList.length) {
                d.playlistUIDList = [];
                for (var j = 0; j < m.playlistUIDList.length; ++j) {
                    d.playlistUIDList[j] = m.playlistUIDList[j];
                }
            }
            return d;
        };

        /**
         * Converts this ListRequestFromApp to JSON.
         * @function toJSON
         * @memberof Proto.ListRequestFromApp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListRequestFromApp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListRequestFromApp;
    })();

    Proto.ChannelIOKey = (function() {

        /**
         * Properties of a ChannelIOKey.
         * @memberof Proto
         * @interface IChannelIOKey
         * @property {string|null} [key] ChannelIOKey key
         * @property {boolean|null} [member] ChannelIOKey member
         */

        /**
         * Constructs a new ChannelIOKey.
         * @memberof Proto
         * @classdesc Represents a ChannelIOKey.
         * @implements IChannelIOKey
         * @constructor
         * @param {Proto.IChannelIOKey=} [p] Properties to set
         */
        function ChannelIOKey(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ChannelIOKey key.
         * @member {string} key
         * @memberof Proto.ChannelIOKey
         * @instance
         */
        ChannelIOKey.prototype.key = "";

        /**
         * ChannelIOKey member.
         * @member {boolean} member
         * @memberof Proto.ChannelIOKey
         * @instance
         */
        ChannelIOKey.prototype.member = false;

        /**
         * Creates a new ChannelIOKey instance using the specified properties.
         * @function create
         * @memberof Proto.ChannelIOKey
         * @static
         * @param {Proto.IChannelIOKey=} [properties] Properties to set
         * @returns {Proto.ChannelIOKey} ChannelIOKey instance
         */
        ChannelIOKey.create = function create(properties) {
            return new ChannelIOKey(properties);
        };

        /**
         * Encodes the specified ChannelIOKey message. Does not implicitly {@link Proto.ChannelIOKey.verify|verify} messages.
         * @function encode
         * @memberof Proto.ChannelIOKey
         * @static
         * @param {Proto.IChannelIOKey} m ChannelIOKey message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelIOKey.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.key != null && Object.hasOwnProperty.call(m, "key"))
                w.uint32(10).string(m.key);
            if (m.member != null && Object.hasOwnProperty.call(m, "member"))
                w.uint32(16).bool(m.member);
            return w;
        };

        /**
         * Decodes a ChannelIOKey message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ChannelIOKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ChannelIOKey} ChannelIOKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelIOKey.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ChannelIOKey();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.key = r.string();
                    break;
                case 2:
                    m.member = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ChannelIOKey message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ChannelIOKey
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ChannelIOKey} ChannelIOKey
         */
        ChannelIOKey.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ChannelIOKey)
                return d;
            var m = new $root.Proto.ChannelIOKey();
            if (d.key != null) {
                m.key = String(d.key);
            }
            if (d.member != null) {
                m.member = Boolean(d.member);
            }
            return m;
        };

        /**
         * Creates a plain object from a ChannelIOKey message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ChannelIOKey
         * @static
         * @param {Proto.ChannelIOKey} m ChannelIOKey
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelIOKey.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.key = "";
                d.member = false;
            }
            if (m.key != null && m.hasOwnProperty("key")) {
                d.key = m.key;
            }
            if (m.member != null && m.hasOwnProperty("member")) {
                d.member = m.member;
            }
            return d;
        };

        /**
         * Converts this ChannelIOKey to JSON.
         * @function toJSON
         * @memberof Proto.ChannelIOKey
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelIOKey.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelIOKey;
    })();

    Proto.ThirdOAuthResponse = (function() {

        /**
         * Properties of a ThirdOAuthResponse.
         * @memberof Proto
         * @interface IThirdOAuthResponse
         * @property {number|null} [errorCode] ThirdOAuthResponse errorCode
         * @property {string|null} [message] ThirdOAuthResponse message
         * @property {string|null} [tmpUID] ThirdOAuthResponse tmpUID
         * @property {string|null} [oauthToken] ThirdOAuthResponse oauthToken
         * @property {string|null} [oauthEmail] ThirdOAuthResponse oauthEmail
         * @property {string|null} [name] ThirdOAuthResponse name
         */

        /**
         * Constructs a new ThirdOAuthResponse.
         * @memberof Proto
         * @classdesc Represents a ThirdOAuthResponse.
         * @implements IThirdOAuthResponse
         * @constructor
         * @param {Proto.IThirdOAuthResponse=} [p] Properties to set
         */
        function ThirdOAuthResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ThirdOAuthResponse errorCode.
         * @member {number} errorCode
         * @memberof Proto.ThirdOAuthResponse
         * @instance
         */
        ThirdOAuthResponse.prototype.errorCode = 0;

        /**
         * ThirdOAuthResponse message.
         * @member {string} message
         * @memberof Proto.ThirdOAuthResponse
         * @instance
         */
        ThirdOAuthResponse.prototype.message = "";

        /**
         * ThirdOAuthResponse tmpUID.
         * @member {string} tmpUID
         * @memberof Proto.ThirdOAuthResponse
         * @instance
         */
        ThirdOAuthResponse.prototype.tmpUID = "";

        /**
         * ThirdOAuthResponse oauthToken.
         * @member {string} oauthToken
         * @memberof Proto.ThirdOAuthResponse
         * @instance
         */
        ThirdOAuthResponse.prototype.oauthToken = "";

        /**
         * ThirdOAuthResponse oauthEmail.
         * @member {string} oauthEmail
         * @memberof Proto.ThirdOAuthResponse
         * @instance
         */
        ThirdOAuthResponse.prototype.oauthEmail = "";

        /**
         * ThirdOAuthResponse name.
         * @member {string} name
         * @memberof Proto.ThirdOAuthResponse
         * @instance
         */
        ThirdOAuthResponse.prototype.name = "";

        /**
         * Creates a new ThirdOAuthResponse instance using the specified properties.
         * @function create
         * @memberof Proto.ThirdOAuthResponse
         * @static
         * @param {Proto.IThirdOAuthResponse=} [properties] Properties to set
         * @returns {Proto.ThirdOAuthResponse} ThirdOAuthResponse instance
         */
        ThirdOAuthResponse.create = function create(properties) {
            return new ThirdOAuthResponse(properties);
        };

        /**
         * Encodes the specified ThirdOAuthResponse message. Does not implicitly {@link Proto.ThirdOAuthResponse.verify|verify} messages.
         * @function encode
         * @memberof Proto.ThirdOAuthResponse
         * @static
         * @param {Proto.IThirdOAuthResponse} m ThirdOAuthResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ThirdOAuthResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.errorCode != null && Object.hasOwnProperty.call(m, "errorCode"))
                w.uint32(8).int32(m.errorCode);
            if (m.message != null && Object.hasOwnProperty.call(m, "message"))
                w.uint32(18).string(m.message);
            if (m.tmpUID != null && Object.hasOwnProperty.call(m, "tmpUID"))
                w.uint32(26).string(m.tmpUID);
            if (m.oauthToken != null && Object.hasOwnProperty.call(m, "oauthToken"))
                w.uint32(34).string(m.oauthToken);
            if (m.oauthEmail != null && Object.hasOwnProperty.call(m, "oauthEmail"))
                w.uint32(42).string(m.oauthEmail);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(50).string(m.name);
            return w;
        };

        /**
         * Decodes a ThirdOAuthResponse message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ThirdOAuthResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ThirdOAuthResponse} ThirdOAuthResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ThirdOAuthResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ThirdOAuthResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.errorCode = r.int32();
                    break;
                case 2:
                    m.message = r.string();
                    break;
                case 3:
                    m.tmpUID = r.string();
                    break;
                case 4:
                    m.oauthToken = r.string();
                    break;
                case 5:
                    m.oauthEmail = r.string();
                    break;
                case 6:
                    m.name = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ThirdOAuthResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ThirdOAuthResponse
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ThirdOAuthResponse} ThirdOAuthResponse
         */
        ThirdOAuthResponse.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ThirdOAuthResponse)
                return d;
            var m = new $root.Proto.ThirdOAuthResponse();
            if (d.errorCode != null) {
                m.errorCode = d.errorCode | 0;
            }
            if (d.message != null) {
                m.message = String(d.message);
            }
            if (d.tmpUID != null) {
                m.tmpUID = String(d.tmpUID);
            }
            if (d.oauthToken != null) {
                m.oauthToken = String(d.oauthToken);
            }
            if (d.oauthEmail != null) {
                m.oauthEmail = String(d.oauthEmail);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            return m;
        };

        /**
         * Creates a plain object from a ThirdOAuthResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ThirdOAuthResponse
         * @static
         * @param {Proto.ThirdOAuthResponse} m ThirdOAuthResponse
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ThirdOAuthResponse.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.errorCode = 0;
                d.message = "";
                d.tmpUID = "";
                d.oauthToken = "";
                d.oauthEmail = "";
                d.name = "";
            }
            if (m.errorCode != null && m.hasOwnProperty("errorCode")) {
                d.errorCode = m.errorCode;
            }
            if (m.message != null && m.hasOwnProperty("message")) {
                d.message = m.message;
            }
            if (m.tmpUID != null && m.hasOwnProperty("tmpUID")) {
                d.tmpUID = m.tmpUID;
            }
            if (m.oauthToken != null && m.hasOwnProperty("oauthToken")) {
                d.oauthToken = m.oauthToken;
            }
            if (m.oauthEmail != null && m.hasOwnProperty("oauthEmail")) {
                d.oauthEmail = m.oauthEmail;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            return d;
        };

        /**
         * Converts this ThirdOAuthResponse to JSON.
         * @function toJSON
         * @memberof Proto.ThirdOAuthResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ThirdOAuthResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ThirdOAuthResponse;
    })();

    Proto.ContentMapGroup = (function() {

        /**
         * Properties of a ContentMapGroup.
         * @memberof Proto
         * @interface IContentMapGroup
         * @property {string|null} [uid] ContentMapGroup uid
         * @property {string|null} [contentUID] ContentMapGroup contentUID
         * @property {string|null} [commentUID] ContentMapGroup commentUID
         * @property {string|null} [title] ContentMapGroup title
         * @property {string|null} [locationCount] ContentMapGroup locationCount
         * @property {number|Long|null} [registerDate] ContentMapGroup registerDate
         * @property {number|Long|null} [modifyDate] ContentMapGroup modifyDate
         * @property {Array.<Proto.IContentMap>|null} [mapList] ContentMapGroup mapList
         * @property {number|null} [status] ContentMapGroup status
         */

        /**
         * Constructs a new ContentMapGroup.
         * @memberof Proto
         * @classdesc Represents a ContentMapGroup.
         * @implements IContentMapGroup
         * @constructor
         * @param {Proto.IContentMapGroup=} [p] Properties to set
         */
        function ContentMapGroup(p) {
            this.mapList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentMapGroup uid.
         * @member {string} uid
         * @memberof Proto.ContentMapGroup
         * @instance
         */
        ContentMapGroup.prototype.uid = "";

        /**
         * ContentMapGroup contentUID.
         * @member {string} contentUID
         * @memberof Proto.ContentMapGroup
         * @instance
         */
        ContentMapGroup.prototype.contentUID = "";

        /**
         * ContentMapGroup commentUID.
         * @member {string} commentUID
         * @memberof Proto.ContentMapGroup
         * @instance
         */
        ContentMapGroup.prototype.commentUID = "";

        /**
         * ContentMapGroup title.
         * @member {string} title
         * @memberof Proto.ContentMapGroup
         * @instance
         */
        ContentMapGroup.prototype.title = "";

        /**
         * ContentMapGroup locationCount.
         * @member {string} locationCount
         * @memberof Proto.ContentMapGroup
         * @instance
         */
        ContentMapGroup.prototype.locationCount = "";

        /**
         * ContentMapGroup registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentMapGroup
         * @instance
         */
        ContentMapGroup.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentMapGroup modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentMapGroup
         * @instance
         */
        ContentMapGroup.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentMapGroup mapList.
         * @member {Array.<Proto.IContentMap>} mapList
         * @memberof Proto.ContentMapGroup
         * @instance
         */
        ContentMapGroup.prototype.mapList = $util.emptyArray;

        /**
         * ContentMapGroup status.
         * @member {number} status
         * @memberof Proto.ContentMapGroup
         * @instance
         */
        ContentMapGroup.prototype.status = 0;

        /**
         * Creates a new ContentMapGroup instance using the specified properties.
         * @function create
         * @memberof Proto.ContentMapGroup
         * @static
         * @param {Proto.IContentMapGroup=} [properties] Properties to set
         * @returns {Proto.ContentMapGroup} ContentMapGroup instance
         */
        ContentMapGroup.create = function create(properties) {
            return new ContentMapGroup(properties);
        };

        /**
         * Encodes the specified ContentMapGroup message. Does not implicitly {@link Proto.ContentMapGroup.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentMapGroup
         * @static
         * @param {Proto.IContentMapGroup} m ContentMapGroup message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentMapGroup.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(18).string(m.contentUID);
            if (m.commentUID != null && Object.hasOwnProperty.call(m, "commentUID"))
                w.uint32(26).string(m.commentUID);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(34).string(m.title);
            if (m.locationCount != null && Object.hasOwnProperty.call(m, "locationCount"))
                w.uint32(42).string(m.locationCount);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.mapList != null && m.mapList.length) {
                for (var i = 0; i < m.mapList.length; ++i)
                    $root.Proto.ContentMap.encode(m.mapList[i], w.uint32(66).fork()).ldelim();
            }
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(72).int32(m.status);
            return w;
        };

        /**
         * Decodes a ContentMapGroup message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentMapGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentMapGroup} ContentMapGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentMapGroup.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentMapGroup();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.contentUID = r.string();
                    break;
                case 3:
                    m.commentUID = r.string();
                    break;
                case 4:
                    m.title = r.string();
                    break;
                case 5:
                    m.locationCount = r.string();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    if (!(m.mapList && m.mapList.length))
                        m.mapList = [];
                    m.mapList.push($root.Proto.ContentMap.decode(r, r.uint32()));
                    break;
                case 9:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentMapGroup message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentMapGroup
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentMapGroup} ContentMapGroup
         */
        ContentMapGroup.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentMapGroup)
                return d;
            var m = new $root.Proto.ContentMapGroup();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.commentUID != null) {
                m.commentUID = String(d.commentUID);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.locationCount != null) {
                m.locationCount = String(d.locationCount);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.mapList) {
                if (!Array.isArray(d.mapList))
                    throw TypeError(".Proto.ContentMapGroup.mapList: array expected");
                m.mapList = [];
                for (var i = 0; i < d.mapList.length; ++i) {
                    if (typeof d.mapList[i] !== "object")
                        throw TypeError(".Proto.ContentMapGroup.mapList: object expected");
                    m.mapList[i] = $root.Proto.ContentMap.fromObject(d.mapList[i]);
                }
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentMapGroup message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentMapGroup
         * @static
         * @param {Proto.ContentMapGroup} m ContentMapGroup
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentMapGroup.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.mapList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.contentUID = "";
                d.commentUID = "";
                d.title = "";
                d.locationCount = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.commentUID != null && m.hasOwnProperty("commentUID")) {
                d.commentUID = m.commentUID;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.locationCount != null && m.hasOwnProperty("locationCount")) {
                d.locationCount = m.locationCount;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.mapList && m.mapList.length) {
                d.mapList = [];
                for (var j = 0; j < m.mapList.length; ++j) {
                    d.mapList[j] = $root.Proto.ContentMap.toObject(m.mapList[j], o);
                }
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this ContentMapGroup to JSON.
         * @function toJSON
         * @memberof Proto.ContentMapGroup
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentMapGroup.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentMapGroup;
    })();

    Proto.ContentMapGroupList = (function() {

        /**
         * Properties of a ContentMapGroupList.
         * @memberof Proto
         * @interface IContentMapGroupList
         * @property {Array.<Proto.IContentMapGroup>|null} [value] ContentMapGroupList value
         */

        /**
         * Constructs a new ContentMapGroupList.
         * @memberof Proto
         * @classdesc Represents a ContentMapGroupList.
         * @implements IContentMapGroupList
         * @constructor
         * @param {Proto.IContentMapGroupList=} [p] Properties to set
         */
        function ContentMapGroupList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentMapGroupList value.
         * @member {Array.<Proto.IContentMapGroup>} value
         * @memberof Proto.ContentMapGroupList
         * @instance
         */
        ContentMapGroupList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentMapGroupList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentMapGroupList
         * @static
         * @param {Proto.IContentMapGroupList=} [properties] Properties to set
         * @returns {Proto.ContentMapGroupList} ContentMapGroupList instance
         */
        ContentMapGroupList.create = function create(properties) {
            return new ContentMapGroupList(properties);
        };

        /**
         * Encodes the specified ContentMapGroupList message. Does not implicitly {@link Proto.ContentMapGroupList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentMapGroupList
         * @static
         * @param {Proto.IContentMapGroupList} m ContentMapGroupList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentMapGroupList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentMapGroup.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentMapGroupList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentMapGroupList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentMapGroupList} ContentMapGroupList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentMapGroupList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentMapGroupList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentMapGroup.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentMapGroupList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentMapGroupList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentMapGroupList} ContentMapGroupList
         */
        ContentMapGroupList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentMapGroupList)
                return d;
            var m = new $root.Proto.ContentMapGroupList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentMapGroupList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentMapGroupList.value: object expected");
                    m.value[i] = $root.Proto.ContentMapGroup.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentMapGroupList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentMapGroupList
         * @static
         * @param {Proto.ContentMapGroupList} m ContentMapGroupList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentMapGroupList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentMapGroup.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentMapGroupList to JSON.
         * @function toJSON
         * @memberof Proto.ContentMapGroupList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentMapGroupList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentMapGroupList;
    })();

    Proto.ContentMap = (function() {

        /**
         * Properties of a ContentMap.
         * @memberof Proto
         * @interface IContentMap
         * @property {string|null} [uid] ContentMap uid
         * @property {string|null} [groupUID] ContentMap groupUID
         * @property {string|null} [name] ContentMap name
         * @property {string|null} [address] ContentMap address
         * @property {number|null} [lat] ContentMap lat
         * @property {number|null} [lon] ContentMap lon
         * @property {string|null} [placeId] ContentMap placeId
         * @property {number|Long|null} [registerDate] ContentMap registerDate
         * @property {number|Long|null} [modifyDate] ContentMap modifyDate
         * @property {number|null} [status] ContentMap status
         * @property {number|null} [idType] ContentMap idType
         */

        /**
         * Constructs a new ContentMap.
         * @memberof Proto
         * @classdesc Represents a ContentMap.
         * @implements IContentMap
         * @constructor
         * @param {Proto.IContentMap=} [p] Properties to set
         */
        function ContentMap(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentMap uid.
         * @member {string} uid
         * @memberof Proto.ContentMap
         * @instance
         */
        ContentMap.prototype.uid = "";

        /**
         * ContentMap groupUID.
         * @member {string} groupUID
         * @memberof Proto.ContentMap
         * @instance
         */
        ContentMap.prototype.groupUID = "";

        /**
         * ContentMap name.
         * @member {string} name
         * @memberof Proto.ContentMap
         * @instance
         */
        ContentMap.prototype.name = "";

        /**
         * ContentMap address.
         * @member {string} address
         * @memberof Proto.ContentMap
         * @instance
         */
        ContentMap.prototype.address = "";

        /**
         * ContentMap lat.
         * @member {number} lat
         * @memberof Proto.ContentMap
         * @instance
         */
        ContentMap.prototype.lat = 0;

        /**
         * ContentMap lon.
         * @member {number} lon
         * @memberof Proto.ContentMap
         * @instance
         */
        ContentMap.prototype.lon = 0;

        /**
         * ContentMap placeId.
         * @member {string} placeId
         * @memberof Proto.ContentMap
         * @instance
         */
        ContentMap.prototype.placeId = "";

        /**
         * ContentMap registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentMap
         * @instance
         */
        ContentMap.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentMap modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentMap
         * @instance
         */
        ContentMap.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentMap status.
         * @member {number} status
         * @memberof Proto.ContentMap
         * @instance
         */
        ContentMap.prototype.status = 0;

        /**
         * ContentMap idType.
         * @member {number} idType
         * @memberof Proto.ContentMap
         * @instance
         */
        ContentMap.prototype.idType = 0;

        /**
         * Creates a new ContentMap instance using the specified properties.
         * @function create
         * @memberof Proto.ContentMap
         * @static
         * @param {Proto.IContentMap=} [properties] Properties to set
         * @returns {Proto.ContentMap} ContentMap instance
         */
        ContentMap.create = function create(properties) {
            return new ContentMap(properties);
        };

        /**
         * Encodes the specified ContentMap message. Does not implicitly {@link Proto.ContentMap.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentMap
         * @static
         * @param {Proto.IContentMap} m ContentMap message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentMap.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.groupUID != null && Object.hasOwnProperty.call(m, "groupUID"))
                w.uint32(18).string(m.groupUID);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.address != null && Object.hasOwnProperty.call(m, "address"))
                w.uint32(34).string(m.address);
            if (m.lat != null && Object.hasOwnProperty.call(m, "lat"))
                w.uint32(41).double(m.lat);
            if (m.lon != null && Object.hasOwnProperty.call(m, "lon"))
                w.uint32(49).double(m.lon);
            if (m.placeId != null && Object.hasOwnProperty.call(m, "placeId"))
                w.uint32(58).string(m.placeId);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(80).int32(m.status);
            if (m.idType != null && Object.hasOwnProperty.call(m, "idType"))
                w.uint32(88).int32(m.idType);
            return w;
        };

        /**
         * Decodes a ContentMap message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentMap} ContentMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentMap.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentMap();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.groupUID = r.string();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.address = r.string();
                    break;
                case 5:
                    m.lat = r.double();
                    break;
                case 6:
                    m.lon = r.double();
                    break;
                case 7:
                    m.placeId = r.string();
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                case 10:
                    m.status = r.int32();
                    break;
                case 11:
                    m.idType = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentMap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentMap
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentMap} ContentMap
         */
        ContentMap.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentMap)
                return d;
            var m = new $root.Proto.ContentMap();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.groupUID != null) {
                m.groupUID = String(d.groupUID);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.address != null) {
                m.address = String(d.address);
            }
            if (d.lat != null) {
                m.lat = Number(d.lat);
            }
            if (d.lon != null) {
                m.lon = Number(d.lon);
            }
            if (d.placeId != null) {
                m.placeId = String(d.placeId);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.idType != null) {
                m.idType = d.idType | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentMap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentMap
         * @static
         * @param {Proto.ContentMap} m ContentMap
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentMap.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.groupUID = "";
                d.name = "";
                d.address = "";
                d.lat = 0;
                d.lon = 0;
                d.placeId = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.idType = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.groupUID != null && m.hasOwnProperty("groupUID")) {
                d.groupUID = m.groupUID;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.address != null && m.hasOwnProperty("address")) {
                d.address = m.address;
            }
            if (m.lat != null && m.hasOwnProperty("lat")) {
                d.lat = o.json && !isFinite(m.lat) ? String(m.lat) : m.lat;
            }
            if (m.lon != null && m.hasOwnProperty("lon")) {
                d.lon = o.json && !isFinite(m.lon) ? String(m.lon) : m.lon;
            }
            if (m.placeId != null && m.hasOwnProperty("placeId")) {
                d.placeId = m.placeId;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.idType != null && m.hasOwnProperty("idType")) {
                d.idType = m.idType;
            }
            return d;
        };

        /**
         * Converts this ContentMap to JSON.
         * @function toJSON
         * @memberof Proto.ContentMap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentMap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentMap;
    })();

    Proto.ContentMapList = (function() {

        /**
         * Properties of a ContentMapList.
         * @memberof Proto
         * @interface IContentMapList
         * @property {Array.<Proto.IContentMap>|null} [value] ContentMapList value
         */

        /**
         * Constructs a new ContentMapList.
         * @memberof Proto
         * @classdesc Represents a ContentMapList.
         * @implements IContentMapList
         * @constructor
         * @param {Proto.IContentMapList=} [p] Properties to set
         */
        function ContentMapList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentMapList value.
         * @member {Array.<Proto.IContentMap>} value
         * @memberof Proto.ContentMapList
         * @instance
         */
        ContentMapList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentMapList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentMapList
         * @static
         * @param {Proto.IContentMapList=} [properties] Properties to set
         * @returns {Proto.ContentMapList} ContentMapList instance
         */
        ContentMapList.create = function create(properties) {
            return new ContentMapList(properties);
        };

        /**
         * Encodes the specified ContentMapList message. Does not implicitly {@link Proto.ContentMapList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentMapList
         * @static
         * @param {Proto.IContentMapList} m ContentMapList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentMapList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentMap.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentMapList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentMapList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentMapList} ContentMapList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentMapList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentMapList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentMap.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentMapList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentMapList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentMapList} ContentMapList
         */
        ContentMapList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentMapList)
                return d;
            var m = new $root.Proto.ContentMapList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentMapList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentMapList.value: object expected");
                    m.value[i] = $root.Proto.ContentMap.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentMapList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentMapList
         * @static
         * @param {Proto.ContentMapList} m ContentMapList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentMapList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentMap.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentMapList to JSON.
         * @function toJSON
         * @memberof Proto.ContentMapList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentMapList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentMapList;
    })();

    Proto.EmailRequest = (function() {

        /**
         * Properties of an EmailRequest.
         * @memberof Proto
         * @interface IEmailRequest
         * @property {string|null} [title] EmailRequest title
         * @property {string|null} [body] EmailRequest body
         * @property {string|null} [contentUID] EmailRequest contentUID
         * @property {string|null} [email] EmailRequest email
         * @property {string|null} [name] EmailRequest name
         * @property {string|null} [phone] EmailRequest phone
         * @property {string|null} [companyUID] EmailRequest companyUID
         */

        /**
         * Constructs a new EmailRequest.
         * @memberof Proto
         * @classdesc Represents an EmailRequest.
         * @implements IEmailRequest
         * @constructor
         * @param {Proto.IEmailRequest=} [p] Properties to set
         */
        function EmailRequest(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * EmailRequest title.
         * @member {string} title
         * @memberof Proto.EmailRequest
         * @instance
         */
        EmailRequest.prototype.title = "";

        /**
         * EmailRequest body.
         * @member {string} body
         * @memberof Proto.EmailRequest
         * @instance
         */
        EmailRequest.prototype.body = "";

        /**
         * EmailRequest contentUID.
         * @member {string} contentUID
         * @memberof Proto.EmailRequest
         * @instance
         */
        EmailRequest.prototype.contentUID = "";

        /**
         * EmailRequest email.
         * @member {string} email
         * @memberof Proto.EmailRequest
         * @instance
         */
        EmailRequest.prototype.email = "";

        /**
         * EmailRequest name.
         * @member {string} name
         * @memberof Proto.EmailRequest
         * @instance
         */
        EmailRequest.prototype.name = "";

        /**
         * EmailRequest phone.
         * @member {string} phone
         * @memberof Proto.EmailRequest
         * @instance
         */
        EmailRequest.prototype.phone = "";

        /**
         * EmailRequest companyUID.
         * @member {string} companyUID
         * @memberof Proto.EmailRequest
         * @instance
         */
        EmailRequest.prototype.companyUID = "";

        /**
         * Creates a new EmailRequest instance using the specified properties.
         * @function create
         * @memberof Proto.EmailRequest
         * @static
         * @param {Proto.IEmailRequest=} [properties] Properties to set
         * @returns {Proto.EmailRequest} EmailRequest instance
         */
        EmailRequest.create = function create(properties) {
            return new EmailRequest(properties);
        };

        /**
         * Encodes the specified EmailRequest message. Does not implicitly {@link Proto.EmailRequest.verify|verify} messages.
         * @function encode
         * @memberof Proto.EmailRequest
         * @static
         * @param {Proto.IEmailRequest} m EmailRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(10).string(m.title);
            if (m.body != null && Object.hasOwnProperty.call(m, "body"))
                w.uint32(18).string(m.body);
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(26).string(m.contentUID);
            if (m.email != null && Object.hasOwnProperty.call(m, "email"))
                w.uint32(34).string(m.email);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(42).string(m.name);
            if (m.phone != null && Object.hasOwnProperty.call(m, "phone"))
                w.uint32(50).string(m.phone);
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(58).string(m.companyUID);
            return w;
        };

        /**
         * Decodes an EmailRequest message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.EmailRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.EmailRequest} EmailRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.EmailRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.title = r.string();
                    break;
                case 2:
                    m.body = r.string();
                    break;
                case 3:
                    m.contentUID = r.string();
                    break;
                case 4:
                    m.email = r.string();
                    break;
                case 5:
                    m.name = r.string();
                    break;
                case 6:
                    m.phone = r.string();
                    break;
                case 7:
                    m.companyUID = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an EmailRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.EmailRequest
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.EmailRequest} EmailRequest
         */
        EmailRequest.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.EmailRequest)
                return d;
            var m = new $root.Proto.EmailRequest();
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.body != null) {
                m.body = String(d.body);
            }
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.email != null) {
                m.email = String(d.email);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.phone != null) {
                m.phone = String(d.phone);
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            return m;
        };

        /**
         * Creates a plain object from an EmailRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.EmailRequest
         * @static
         * @param {Proto.EmailRequest} m EmailRequest
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmailRequest.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.title = "";
                d.body = "";
                d.contentUID = "";
                d.email = "";
                d.name = "";
                d.phone = "";
                d.companyUID = "";
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.body != null && m.hasOwnProperty("body")) {
                d.body = m.body;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.email != null && m.hasOwnProperty("email")) {
                d.email = m.email;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.phone != null && m.hasOwnProperty("phone")) {
                d.phone = m.phone;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            return d;
        };

        /**
         * Converts this EmailRequest to JSON.
         * @function toJSON
         * @memberof Proto.EmailRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmailRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EmailRequest;
    })();

    Proto.ContentScheduleUserInfo = (function() {

        /**
         * Properties of a ContentScheduleUserInfo.
         * @memberof Proto
         * @interface IContentScheduleUserInfo
         * @property {string|null} [uid] ContentScheduleUserInfo uid
         * @property {string|null} [scheduleUID] ContentScheduleUserInfo scheduleUID
         * @property {number|null} [type] ContentScheduleUserInfo type
         * @property {string|null} [name] ContentScheduleUserInfo name
         * @property {boolean|null} [optional] ContentScheduleUserInfo optional
         * @property {string|null} [setting] ContentScheduleUserInfo setting
         * @property {number|null} [order] ContentScheduleUserInfo order
         * @property {number|Long|null} [registerDate] ContentScheduleUserInfo registerDate
         * @property {number|Long|null} [modifyDate] ContentScheduleUserInfo modifyDate
         * @property {number|null} [status] ContentScheduleUserInfo status
         */

        /**
         * Constructs a new ContentScheduleUserInfo.
         * @memberof Proto
         * @classdesc Represents a ContentScheduleUserInfo.
         * @implements IContentScheduleUserInfo
         * @constructor
         * @param {Proto.IContentScheduleUserInfo=} [p] Properties to set
         */
        function ContentScheduleUserInfo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentScheduleUserInfo uid.
         * @member {string} uid
         * @memberof Proto.ContentScheduleUserInfo
         * @instance
         */
        ContentScheduleUserInfo.prototype.uid = "";

        /**
         * ContentScheduleUserInfo scheduleUID.
         * @member {string} scheduleUID
         * @memberof Proto.ContentScheduleUserInfo
         * @instance
         */
        ContentScheduleUserInfo.prototype.scheduleUID = "";

        /**
         * ContentScheduleUserInfo type.
         * @member {number} type
         * @memberof Proto.ContentScheduleUserInfo
         * @instance
         */
        ContentScheduleUserInfo.prototype.type = 0;

        /**
         * ContentScheduleUserInfo name.
         * @member {string} name
         * @memberof Proto.ContentScheduleUserInfo
         * @instance
         */
        ContentScheduleUserInfo.prototype.name = "";

        /**
         * ContentScheduleUserInfo optional.
         * @member {boolean} optional
         * @memberof Proto.ContentScheduleUserInfo
         * @instance
         */
        ContentScheduleUserInfo.prototype.optional = false;

        /**
         * ContentScheduleUserInfo setting.
         * @member {string} setting
         * @memberof Proto.ContentScheduleUserInfo
         * @instance
         */
        ContentScheduleUserInfo.prototype.setting = "";

        /**
         * ContentScheduleUserInfo order.
         * @member {number} order
         * @memberof Proto.ContentScheduleUserInfo
         * @instance
         */
        ContentScheduleUserInfo.prototype.order = 0;

        /**
         * ContentScheduleUserInfo registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentScheduleUserInfo
         * @instance
         */
        ContentScheduleUserInfo.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleUserInfo modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentScheduleUserInfo
         * @instance
         */
        ContentScheduleUserInfo.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleUserInfo status.
         * @member {number} status
         * @memberof Proto.ContentScheduleUserInfo
         * @instance
         */
        ContentScheduleUserInfo.prototype.status = 0;

        /**
         * Creates a new ContentScheduleUserInfo instance using the specified properties.
         * @function create
         * @memberof Proto.ContentScheduleUserInfo
         * @static
         * @param {Proto.IContentScheduleUserInfo=} [properties] Properties to set
         * @returns {Proto.ContentScheduleUserInfo} ContentScheduleUserInfo instance
         */
        ContentScheduleUserInfo.create = function create(properties) {
            return new ContentScheduleUserInfo(properties);
        };

        /**
         * Encodes the specified ContentScheduleUserInfo message. Does not implicitly {@link Proto.ContentScheduleUserInfo.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentScheduleUserInfo
         * @static
         * @param {Proto.IContentScheduleUserInfo} m ContentScheduleUserInfo message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentScheduleUserInfo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.scheduleUID != null && Object.hasOwnProperty.call(m, "scheduleUID"))
                w.uint32(18).string(m.scheduleUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(24).int32(m.type);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(34).string(m.name);
            if (m.optional != null && Object.hasOwnProperty.call(m, "optional"))
                w.uint32(40).bool(m.optional);
            if (m.setting != null && Object.hasOwnProperty.call(m, "setting"))
                w.uint32(50).string(m.setting);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(56).int32(m.order);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(80).int32(m.status);
            return w;
        };

        /**
         * Decodes a ContentScheduleUserInfo message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentScheduleUserInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentScheduleUserInfo} ContentScheduleUserInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentScheduleUserInfo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentScheduleUserInfo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.scheduleUID = r.string();
                    break;
                case 3:
                    m.type = r.int32();
                    break;
                case 4:
                    m.name = r.string();
                    break;
                case 5:
                    m.optional = r.bool();
                    break;
                case 6:
                    m.setting = r.string();
                    break;
                case 7:
                    m.order = r.int32();
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                case 10:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentScheduleUserInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentScheduleUserInfo
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentScheduleUserInfo} ContentScheduleUserInfo
         */
        ContentScheduleUserInfo.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentScheduleUserInfo)
                return d;
            var m = new $root.Proto.ContentScheduleUserInfo();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.scheduleUID != null) {
                m.scheduleUID = String(d.scheduleUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.optional != null) {
                m.optional = Boolean(d.optional);
            }
            if (d.setting != null) {
                m.setting = String(d.setting);
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentScheduleUserInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentScheduleUserInfo
         * @static
         * @param {Proto.ContentScheduleUserInfo} m ContentScheduleUserInfo
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentScheduleUserInfo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.scheduleUID = "";
                d.type = 0;
                d.name = "";
                d.optional = false;
                d.setting = "";
                d.order = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.scheduleUID != null && m.hasOwnProperty("scheduleUID")) {
                d.scheduleUID = m.scheduleUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.optional != null && m.hasOwnProperty("optional")) {
                d.optional = m.optional;
            }
            if (m.setting != null && m.hasOwnProperty("setting")) {
                d.setting = m.setting;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this ContentScheduleUserInfo to JSON.
         * @function toJSON
         * @memberof Proto.ContentScheduleUserInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentScheduleUserInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentScheduleUserInfo;
    })();

    Proto.ContentScheduleUserInfoList = (function() {

        /**
         * Properties of a ContentScheduleUserInfoList.
         * @memberof Proto
         * @interface IContentScheduleUserInfoList
         * @property {Array.<Proto.IContentScheduleUserInfo>|null} [value] ContentScheduleUserInfoList value
         */

        /**
         * Constructs a new ContentScheduleUserInfoList.
         * @memberof Proto
         * @classdesc Represents a ContentScheduleUserInfoList.
         * @implements IContentScheduleUserInfoList
         * @constructor
         * @param {Proto.IContentScheduleUserInfoList=} [p] Properties to set
         */
        function ContentScheduleUserInfoList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentScheduleUserInfoList value.
         * @member {Array.<Proto.IContentScheduleUserInfo>} value
         * @memberof Proto.ContentScheduleUserInfoList
         * @instance
         */
        ContentScheduleUserInfoList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentScheduleUserInfoList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentScheduleUserInfoList
         * @static
         * @param {Proto.IContentScheduleUserInfoList=} [properties] Properties to set
         * @returns {Proto.ContentScheduleUserInfoList} ContentScheduleUserInfoList instance
         */
        ContentScheduleUserInfoList.create = function create(properties) {
            return new ContentScheduleUserInfoList(properties);
        };

        /**
         * Encodes the specified ContentScheduleUserInfoList message. Does not implicitly {@link Proto.ContentScheduleUserInfoList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentScheduleUserInfoList
         * @static
         * @param {Proto.IContentScheduleUserInfoList} m ContentScheduleUserInfoList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentScheduleUserInfoList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentScheduleUserInfo.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentScheduleUserInfoList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentScheduleUserInfoList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentScheduleUserInfoList} ContentScheduleUserInfoList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentScheduleUserInfoList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentScheduleUserInfoList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentScheduleUserInfo.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentScheduleUserInfoList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentScheduleUserInfoList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentScheduleUserInfoList} ContentScheduleUserInfoList
         */
        ContentScheduleUserInfoList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentScheduleUserInfoList)
                return d;
            var m = new $root.Proto.ContentScheduleUserInfoList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentScheduleUserInfoList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentScheduleUserInfoList.value: object expected");
                    m.value[i] = $root.Proto.ContentScheduleUserInfo.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentScheduleUserInfoList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentScheduleUserInfoList
         * @static
         * @param {Proto.ContentScheduleUserInfoList} m ContentScheduleUserInfoList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentScheduleUserInfoList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentScheduleUserInfo.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentScheduleUserInfoList to JSON.
         * @function toJSON
         * @memberof Proto.ContentScheduleUserInfoList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentScheduleUserInfoList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentScheduleUserInfoList;
    })();

    Proto.ContentScheduleDateUserInfo = (function() {

        /**
         * Properties of a ContentScheduleDateUserInfo.
         * @memberof Proto
         * @interface IContentScheduleDateUserInfo
         * @property {string|null} [userUID] ContentScheduleDateUserInfo userUID
         * @property {string|null} [infoUID] ContentScheduleDateUserInfo infoUID
         * @property {string|null} [text] ContentScheduleDateUserInfo text
         * @property {number|null} [number] ContentScheduleDateUserInfo number
         * @property {number|Long|null} [date] ContentScheduleDateUserInfo date
         * @property {number|Long|null} [registerDate] ContentScheduleDateUserInfo registerDate
         * @property {number|Long|null} [modifyDate] ContentScheduleDateUserInfo modifyDate
         * @property {Proto.IUploadFile|null} [file] ContentScheduleDateUserInfo file
         * @property {string|null} [additional] ContentScheduleDateUserInfo additional
         * @property {boolean|null} [readonly] ContentScheduleDateUserInfo readonly
         */

        /**
         * Constructs a new ContentScheduleDateUserInfo.
         * @memberof Proto
         * @classdesc Represents a ContentScheduleDateUserInfo.
         * @implements IContentScheduleDateUserInfo
         * @constructor
         * @param {Proto.IContentScheduleDateUserInfo=} [p] Properties to set
         */
        function ContentScheduleDateUserInfo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentScheduleDateUserInfo userUID.
         * @member {string} userUID
         * @memberof Proto.ContentScheduleDateUserInfo
         * @instance
         */
        ContentScheduleDateUserInfo.prototype.userUID = "";

        /**
         * ContentScheduleDateUserInfo infoUID.
         * @member {string} infoUID
         * @memberof Proto.ContentScheduleDateUserInfo
         * @instance
         */
        ContentScheduleDateUserInfo.prototype.infoUID = "";

        /**
         * ContentScheduleDateUserInfo text.
         * @member {string} text
         * @memberof Proto.ContentScheduleDateUserInfo
         * @instance
         */
        ContentScheduleDateUserInfo.prototype.text = "";

        /**
         * ContentScheduleDateUserInfo number.
         * @member {number} number
         * @memberof Proto.ContentScheduleDateUserInfo
         * @instance
         */
        ContentScheduleDateUserInfo.prototype.number = 0;

        /**
         * ContentScheduleDateUserInfo date.
         * @member {number|Long} date
         * @memberof Proto.ContentScheduleDateUserInfo
         * @instance
         */
        ContentScheduleDateUserInfo.prototype.date = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleDateUserInfo registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentScheduleDateUserInfo
         * @instance
         */
        ContentScheduleDateUserInfo.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleDateUserInfo modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentScheduleDateUserInfo
         * @instance
         */
        ContentScheduleDateUserInfo.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentScheduleDateUserInfo file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.ContentScheduleDateUserInfo
         * @instance
         */
        ContentScheduleDateUserInfo.prototype.file = null;

        /**
         * ContentScheduleDateUserInfo additional.
         * @member {string} additional
         * @memberof Proto.ContentScheduleDateUserInfo
         * @instance
         */
        ContentScheduleDateUserInfo.prototype.additional = "";

        /**
         * ContentScheduleDateUserInfo readonly.
         * @member {boolean} readonly
         * @memberof Proto.ContentScheduleDateUserInfo
         * @instance
         */
        ContentScheduleDateUserInfo.prototype.readonly = false;

        /**
         * Creates a new ContentScheduleDateUserInfo instance using the specified properties.
         * @function create
         * @memberof Proto.ContentScheduleDateUserInfo
         * @static
         * @param {Proto.IContentScheduleDateUserInfo=} [properties] Properties to set
         * @returns {Proto.ContentScheduleDateUserInfo} ContentScheduleDateUserInfo instance
         */
        ContentScheduleDateUserInfo.create = function create(properties) {
            return new ContentScheduleDateUserInfo(properties);
        };

        /**
         * Encodes the specified ContentScheduleDateUserInfo message. Does not implicitly {@link Proto.ContentScheduleDateUserInfo.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentScheduleDateUserInfo
         * @static
         * @param {Proto.IContentScheduleDateUserInfo} m ContentScheduleDateUserInfo message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentScheduleDateUserInfo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(10).string(m.userUID);
            if (m.infoUID != null && Object.hasOwnProperty.call(m, "infoUID"))
                w.uint32(18).string(m.infoUID);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.number != null && Object.hasOwnProperty.call(m, "number"))
                w.uint32(32).int32(m.number);
            if (m.date != null && Object.hasOwnProperty.call(m, "date"))
                w.uint32(40).int64(m.date);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(66).fork()).ldelim();
            if (m.additional != null && Object.hasOwnProperty.call(m, "additional"))
                w.uint32(74).string(m.additional);
            if (m.readonly != null && Object.hasOwnProperty.call(m, "readonly"))
                w.uint32(80).bool(m.readonly);
            return w;
        };

        /**
         * Decodes a ContentScheduleDateUserInfo message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentScheduleDateUserInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentScheduleDateUserInfo} ContentScheduleDateUserInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentScheduleDateUserInfo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentScheduleDateUserInfo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.userUID = r.string();
                    break;
                case 2:
                    m.infoUID = r.string();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.number = r.int32();
                    break;
                case 5:
                    m.date = r.int64();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 9:
                    m.additional = r.string();
                    break;
                case 10:
                    m.readonly = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentScheduleDateUserInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentScheduleDateUserInfo
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentScheduleDateUserInfo} ContentScheduleDateUserInfo
         */
        ContentScheduleDateUserInfo.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentScheduleDateUserInfo)
                return d;
            var m = new $root.Proto.ContentScheduleDateUserInfo();
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.infoUID != null) {
                m.infoUID = String(d.infoUID);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.number != null) {
                m.number = d.number | 0;
            }
            if (d.date != null) {
                if ($util.Long)
                    (m.date = $util.Long.fromValue(d.date)).unsigned = false;
                else if (typeof d.date === "string")
                    m.date = parseInt(d.date, 10);
                else if (typeof d.date === "number")
                    m.date = d.date;
                else if (typeof d.date === "object")
                    m.date = new $util.LongBits(d.date.low >>> 0, d.date.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.ContentScheduleDateUserInfo.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            if (d.additional != null) {
                m.additional = String(d.additional);
            }
            if (d.readonly != null) {
                m.readonly = Boolean(d.readonly);
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentScheduleDateUserInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentScheduleDateUserInfo
         * @static
         * @param {Proto.ContentScheduleDateUserInfo} m ContentScheduleDateUserInfo
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentScheduleDateUserInfo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.userUID = "";
                d.infoUID = "";
                d.text = "";
                d.number = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.date = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.date = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.file = null;
                d.additional = "";
                d.readonly = false;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.infoUID != null && m.hasOwnProperty("infoUID")) {
                d.infoUID = m.infoUID;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.number != null && m.hasOwnProperty("number")) {
                d.number = m.number;
            }
            if (m.date != null && m.hasOwnProperty("date")) {
                if (typeof m.date === "number")
                    d.date = o.longs === String ? String(m.date) : m.date;
                else
                    d.date = o.longs === String ? $util.Long.prototype.toString.call(m.date) : o.longs === Number ? new $util.LongBits(m.date.low >>> 0, m.date.high >>> 0).toNumber() : m.date;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            if (m.additional != null && m.hasOwnProperty("additional")) {
                d.additional = m.additional;
            }
            if (m.readonly != null && m.hasOwnProperty("readonly")) {
                d.readonly = m.readonly;
            }
            return d;
        };

        /**
         * Converts this ContentScheduleDateUserInfo to JSON.
         * @function toJSON
         * @memberof Proto.ContentScheduleDateUserInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentScheduleDateUserInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentScheduleDateUserInfo;
    })();

    Proto.ContentScheduleDateUserInfoList = (function() {

        /**
         * Properties of a ContentScheduleDateUserInfoList.
         * @memberof Proto
         * @interface IContentScheduleDateUserInfoList
         * @property {Array.<Proto.IContentScheduleDateUserInfo>|null} [value] ContentScheduleDateUserInfoList value
         */

        /**
         * Constructs a new ContentScheduleDateUserInfoList.
         * @memberof Proto
         * @classdesc Represents a ContentScheduleDateUserInfoList.
         * @implements IContentScheduleDateUserInfoList
         * @constructor
         * @param {Proto.IContentScheduleDateUserInfoList=} [p] Properties to set
         */
        function ContentScheduleDateUserInfoList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentScheduleDateUserInfoList value.
         * @member {Array.<Proto.IContentScheduleDateUserInfo>} value
         * @memberof Proto.ContentScheduleDateUserInfoList
         * @instance
         */
        ContentScheduleDateUserInfoList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentScheduleDateUserInfoList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentScheduleDateUserInfoList
         * @static
         * @param {Proto.IContentScheduleDateUserInfoList=} [properties] Properties to set
         * @returns {Proto.ContentScheduleDateUserInfoList} ContentScheduleDateUserInfoList instance
         */
        ContentScheduleDateUserInfoList.create = function create(properties) {
            return new ContentScheduleDateUserInfoList(properties);
        };

        /**
         * Encodes the specified ContentScheduleDateUserInfoList message. Does not implicitly {@link Proto.ContentScheduleDateUserInfoList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentScheduleDateUserInfoList
         * @static
         * @param {Proto.IContentScheduleDateUserInfoList} m ContentScheduleDateUserInfoList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentScheduleDateUserInfoList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentScheduleDateUserInfo.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentScheduleDateUserInfoList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentScheduleDateUserInfoList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentScheduleDateUserInfoList} ContentScheduleDateUserInfoList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentScheduleDateUserInfoList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentScheduleDateUserInfoList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentScheduleDateUserInfo.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentScheduleDateUserInfoList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentScheduleDateUserInfoList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentScheduleDateUserInfoList} ContentScheduleDateUserInfoList
         */
        ContentScheduleDateUserInfoList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentScheduleDateUserInfoList)
                return d;
            var m = new $root.Proto.ContentScheduleDateUserInfoList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentScheduleDateUserInfoList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentScheduleDateUserInfoList.value: object expected");
                    m.value[i] = $root.Proto.ContentScheduleDateUserInfo.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentScheduleDateUserInfoList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentScheduleDateUserInfoList
         * @static
         * @param {Proto.ContentScheduleDateUserInfoList} m ContentScheduleDateUserInfoList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentScheduleDateUserInfoList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentScheduleDateUserInfo.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentScheduleDateUserInfoList to JSON.
         * @function toJSON
         * @memberof Proto.ContentScheduleDateUserInfoList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentScheduleDateUserInfoList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentScheduleDateUserInfoList;
    })();

    Proto.Ticket = (function() {

        /**
         * Properties of a Ticket.
         * @memberof Proto
         * @interface ITicket
         * @property {string|null} [uid] Ticket uid
         * @property {number|null} [type] Ticket type
         * @property {string|null} [title] Ticket title
         * @property {string|null} [description] Ticket description
         * @property {number|null} [price] Ticket price
         * @property {string|null} [currency] Ticket currency
         * @property {number|Long|null} [startDate] Ticket startDate
         * @property {number|Long|null} [endDate] Ticket endDate
         * @property {number|Long|null} [returnDate] Ticket returnDate
         * @property {number|null} [distribution] Ticket distribution
         * @property {Proto.IUploadFile|null} [image] Ticket image
         * @property {Proto.IUploadFile|null} [listImage] Ticket listImage
         * @property {string|null} [listImageOrder] Ticket listImageOrder
         * @property {string|null} [variationOrder] Ticket variationOrder
         * @property {number|Long|null} [registerDate] Ticket registerDate
         * @property {number|Long|null} [modifyDate] Ticket modifyDate
         * @property {number|null} [status] Ticket status
         * @property {number|null} [approveType] Ticket approveType
         * @property {Array.<Proto.ITicketVariation>|null} [variationList] Ticket variationList
         * @property {Array.<Proto.IContentTicketMap>|null} [contentMapList] Ticket contentMapList
         * @property {Array.<Proto.IHashTag>|null} [adminTagList] Ticket adminTagList
         * @property {boolean|null} [main] Ticket main
         * @property {string|null} [targetContentUID] Ticket targetContentUID
         * @property {number|Long|null} [allQuantity] Ticket allQuantity
         * @property {boolean|null} [soldout] Ticket soldout
         * @property {boolean|null} [allowMultiple] Ticket allowMultiple
         * @property {number|null} [serviceType] Ticket serviceType
         * @property {string|null} [name] Ticket name
         * @property {number|null} [priceType] Ticket priceType
         * @property {string|null} [priceDescription] Ticket priceDescription
         * @property {number|null} [multipleType] Ticket multipleType
         * @property {Array.<Proto.ITicketStaff>|null} [staffList] Ticket staffList
         * @property {string|null} [staffName] Ticket staffName
         * @property {number|null} [minPrice] Ticket minPrice
         * @property {number|null} [maxPrice] Ticket maxPrice
         */

        /**
         * Constructs a new Ticket.
         * @memberof Proto
         * @classdesc Represents a Ticket.
         * @implements ITicket
         * @constructor
         * @param {Proto.ITicket=} [p] Properties to set
         */
        function Ticket(p) {
            this.variationList = [];
            this.contentMapList = [];
            this.adminTagList = [];
            this.staffList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Ticket uid.
         * @member {string} uid
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.uid = "";

        /**
         * Ticket type.
         * @member {number} type
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.type = 0;

        /**
         * Ticket title.
         * @member {string} title
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.title = "";

        /**
         * Ticket description.
         * @member {string} description
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.description = "";

        /**
         * Ticket price.
         * @member {number} price
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.price = 0;

        /**
         * Ticket currency.
         * @member {string} currency
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.currency = "";

        /**
         * Ticket startDate.
         * @member {number|Long} startDate
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Ticket endDate.
         * @member {number|Long} endDate
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Ticket returnDate.
         * @member {number|Long} returnDate
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.returnDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Ticket distribution.
         * @member {number} distribution
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.distribution = 0;

        /**
         * Ticket image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.image = null;

        /**
         * Ticket listImage.
         * @member {Proto.IUploadFile|null|undefined} listImage
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.listImage = null;

        /**
         * Ticket listImageOrder.
         * @member {string} listImageOrder
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.listImageOrder = "";

        /**
         * Ticket variationOrder.
         * @member {string} variationOrder
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.variationOrder = "";

        /**
         * Ticket registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Ticket modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Ticket status.
         * @member {number} status
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.status = 0;

        /**
         * Ticket approveType.
         * @member {number} approveType
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.approveType = 0;

        /**
         * Ticket variationList.
         * @member {Array.<Proto.ITicketVariation>} variationList
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.variationList = $util.emptyArray;

        /**
         * Ticket contentMapList.
         * @member {Array.<Proto.IContentTicketMap>} contentMapList
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.contentMapList = $util.emptyArray;

        /**
         * Ticket adminTagList.
         * @member {Array.<Proto.IHashTag>} adminTagList
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.adminTagList = $util.emptyArray;

        /**
         * Ticket main.
         * @member {boolean} main
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.main = false;

        /**
         * Ticket targetContentUID.
         * @member {string} targetContentUID
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.targetContentUID = "";

        /**
         * Ticket allQuantity.
         * @member {number|Long} allQuantity
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.allQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Ticket soldout.
         * @member {boolean} soldout
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.soldout = false;

        /**
         * Ticket allowMultiple.
         * @member {boolean} allowMultiple
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.allowMultiple = false;

        /**
         * Ticket serviceType.
         * @member {number} serviceType
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.serviceType = 0;

        /**
         * Ticket name.
         * @member {string} name
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.name = "";

        /**
         * Ticket priceType.
         * @member {number} priceType
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.priceType = 0;

        /**
         * Ticket priceDescription.
         * @member {string} priceDescription
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.priceDescription = "";

        /**
         * Ticket multipleType.
         * @member {number} multipleType
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.multipleType = 0;

        /**
         * Ticket staffList.
         * @member {Array.<Proto.ITicketStaff>} staffList
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.staffList = $util.emptyArray;

        /**
         * Ticket staffName.
         * @member {string} staffName
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.staffName = "";

        /**
         * Ticket minPrice.
         * @member {number} minPrice
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.minPrice = 0;

        /**
         * Ticket maxPrice.
         * @member {number} maxPrice
         * @memberof Proto.Ticket
         * @instance
         */
        Ticket.prototype.maxPrice = 0;

        /**
         * Creates a new Ticket instance using the specified properties.
         * @function create
         * @memberof Proto.Ticket
         * @static
         * @param {Proto.ITicket=} [properties] Properties to set
         * @returns {Proto.Ticket} Ticket instance
         */
        Ticket.create = function create(properties) {
            return new Ticket(properties);
        };

        /**
         * Encodes the specified Ticket message. Does not implicitly {@link Proto.Ticket.verify|verify} messages.
         * @function encode
         * @memberof Proto.Ticket
         * @static
         * @param {Proto.ITicket} m Ticket message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ticket.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(26).string(m.title);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(34).string(m.description);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(41).double(m.price);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(50).string(m.currency);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(56).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(64).int64(m.endDate);
            if (m.returnDate != null && Object.hasOwnProperty.call(m, "returnDate"))
                w.uint32(72).int64(m.returnDate);
            if (m.distribution != null && Object.hasOwnProperty.call(m, "distribution"))
                w.uint32(80).int32(m.distribution);
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(90).fork()).ldelim();
            if (m.listImage != null && Object.hasOwnProperty.call(m, "listImage"))
                $root.Proto.UploadFile.encode(m.listImage, w.uint32(98).fork()).ldelim();
            if (m.listImageOrder != null && Object.hasOwnProperty.call(m, "listImageOrder"))
                w.uint32(106).string(m.listImageOrder);
            if (m.variationOrder != null && Object.hasOwnProperty.call(m, "variationOrder"))
                w.uint32(114).string(m.variationOrder);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(120).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(128).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(136).int32(m.status);
            if (m.approveType != null && Object.hasOwnProperty.call(m, "approveType"))
                w.uint32(144).int32(m.approveType);
            if (m.variationList != null && m.variationList.length) {
                for (var i = 0; i < m.variationList.length; ++i)
                    $root.Proto.TicketVariation.encode(m.variationList[i], w.uint32(154).fork()).ldelim();
            }
            if (m.contentMapList != null && m.contentMapList.length) {
                for (var i = 0; i < m.contentMapList.length; ++i)
                    $root.Proto.ContentTicketMap.encode(m.contentMapList[i], w.uint32(162).fork()).ldelim();
            }
            if (m.adminTagList != null && m.adminTagList.length) {
                for (var i = 0; i < m.adminTagList.length; ++i)
                    $root.Proto.HashTag.encode(m.adminTagList[i], w.uint32(170).fork()).ldelim();
            }
            if (m.main != null && Object.hasOwnProperty.call(m, "main"))
                w.uint32(176).bool(m.main);
            if (m.targetContentUID != null && Object.hasOwnProperty.call(m, "targetContentUID"))
                w.uint32(186).string(m.targetContentUID);
            if (m.allQuantity != null && Object.hasOwnProperty.call(m, "allQuantity"))
                w.uint32(192).int64(m.allQuantity);
            if (m.soldout != null && Object.hasOwnProperty.call(m, "soldout"))
                w.uint32(200).bool(m.soldout);
            if (m.allowMultiple != null && Object.hasOwnProperty.call(m, "allowMultiple"))
                w.uint32(208).bool(m.allowMultiple);
            if (m.serviceType != null && Object.hasOwnProperty.call(m, "serviceType"))
                w.uint32(216).int32(m.serviceType);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(226).string(m.name);
            if (m.priceType != null && Object.hasOwnProperty.call(m, "priceType"))
                w.uint32(232).int32(m.priceType);
            if (m.priceDescription != null && Object.hasOwnProperty.call(m, "priceDescription"))
                w.uint32(242).string(m.priceDescription);
            if (m.multipleType != null && Object.hasOwnProperty.call(m, "multipleType"))
                w.uint32(248).int32(m.multipleType);
            if (m.staffList != null && m.staffList.length) {
                for (var i = 0; i < m.staffList.length; ++i)
                    $root.Proto.TicketStaff.encode(m.staffList[i], w.uint32(258).fork()).ldelim();
            }
            if (m.staffName != null && Object.hasOwnProperty.call(m, "staffName"))
                w.uint32(266).string(m.staffName);
            if (m.minPrice != null && Object.hasOwnProperty.call(m, "minPrice"))
                w.uint32(273).double(m.minPrice);
            if (m.maxPrice != null && Object.hasOwnProperty.call(m, "maxPrice"))
                w.uint32(281).double(m.maxPrice);
            return w;
        };

        /**
         * Decodes a Ticket message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.Ticket
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.Ticket} Ticket
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ticket.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.Ticket();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.title = r.string();
                    break;
                case 4:
                    m.description = r.string();
                    break;
                case 5:
                    m.price = r.double();
                    break;
                case 6:
                    m.currency = r.string();
                    break;
                case 7:
                    m.startDate = r.int64();
                    break;
                case 8:
                    m.endDate = r.int64();
                    break;
                case 9:
                    m.returnDate = r.int64();
                    break;
                case 10:
                    m.distribution = r.int32();
                    break;
                case 11:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 12:
                    m.listImage = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 13:
                    m.listImageOrder = r.string();
                    break;
                case 14:
                    m.variationOrder = r.string();
                    break;
                case 15:
                    m.registerDate = r.int64();
                    break;
                case 16:
                    m.modifyDate = r.int64();
                    break;
                case 17:
                    m.status = r.int32();
                    break;
                case 18:
                    m.approveType = r.int32();
                    break;
                case 19:
                    if (!(m.variationList && m.variationList.length))
                        m.variationList = [];
                    m.variationList.push($root.Proto.TicketVariation.decode(r, r.uint32()));
                    break;
                case 20:
                    if (!(m.contentMapList && m.contentMapList.length))
                        m.contentMapList = [];
                    m.contentMapList.push($root.Proto.ContentTicketMap.decode(r, r.uint32()));
                    break;
                case 21:
                    if (!(m.adminTagList && m.adminTagList.length))
                        m.adminTagList = [];
                    m.adminTagList.push($root.Proto.HashTag.decode(r, r.uint32()));
                    break;
                case 22:
                    m.main = r.bool();
                    break;
                case 23:
                    m.targetContentUID = r.string();
                    break;
                case 24:
                    m.allQuantity = r.int64();
                    break;
                case 25:
                    m.soldout = r.bool();
                    break;
                case 26:
                    m.allowMultiple = r.bool();
                    break;
                case 27:
                    m.serviceType = r.int32();
                    break;
                case 28:
                    m.name = r.string();
                    break;
                case 29:
                    m.priceType = r.int32();
                    break;
                case 30:
                    m.priceDescription = r.string();
                    break;
                case 31:
                    m.multipleType = r.int32();
                    break;
                case 32:
                    if (!(m.staffList && m.staffList.length))
                        m.staffList = [];
                    m.staffList.push($root.Proto.TicketStaff.decode(r, r.uint32()));
                    break;
                case 33:
                    m.staffName = r.string();
                    break;
                case 34:
                    m.minPrice = r.double();
                    break;
                case 35:
                    m.maxPrice = r.double();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Ticket message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.Ticket
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.Ticket} Ticket
         */
        Ticket.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.Ticket)
                return d;
            var m = new $root.Proto.Ticket();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.returnDate != null) {
                if ($util.Long)
                    (m.returnDate = $util.Long.fromValue(d.returnDate)).unsigned = false;
                else if (typeof d.returnDate === "string")
                    m.returnDate = parseInt(d.returnDate, 10);
                else if (typeof d.returnDate === "number")
                    m.returnDate = d.returnDate;
                else if (typeof d.returnDate === "object")
                    m.returnDate = new $util.LongBits(d.returnDate.low >>> 0, d.returnDate.high >>> 0).toNumber();
            }
            if (d.distribution != null) {
                m.distribution = d.distribution | 0;
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.Ticket.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.listImage != null) {
                if (typeof d.listImage !== "object")
                    throw TypeError(".Proto.Ticket.listImage: object expected");
                m.listImage = $root.Proto.UploadFile.fromObject(d.listImage);
            }
            if (d.listImageOrder != null) {
                m.listImageOrder = String(d.listImageOrder);
            }
            if (d.variationOrder != null) {
                m.variationOrder = String(d.variationOrder);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.approveType != null) {
                m.approveType = d.approveType | 0;
            }
            if (d.variationList) {
                if (!Array.isArray(d.variationList))
                    throw TypeError(".Proto.Ticket.variationList: array expected");
                m.variationList = [];
                for (var i = 0; i < d.variationList.length; ++i) {
                    if (typeof d.variationList[i] !== "object")
                        throw TypeError(".Proto.Ticket.variationList: object expected");
                    m.variationList[i] = $root.Proto.TicketVariation.fromObject(d.variationList[i]);
                }
            }
            if (d.contentMapList) {
                if (!Array.isArray(d.contentMapList))
                    throw TypeError(".Proto.Ticket.contentMapList: array expected");
                m.contentMapList = [];
                for (var i = 0; i < d.contentMapList.length; ++i) {
                    if (typeof d.contentMapList[i] !== "object")
                        throw TypeError(".Proto.Ticket.contentMapList: object expected");
                    m.contentMapList[i] = $root.Proto.ContentTicketMap.fromObject(d.contentMapList[i]);
                }
            }
            if (d.adminTagList) {
                if (!Array.isArray(d.adminTagList))
                    throw TypeError(".Proto.Ticket.adminTagList: array expected");
                m.adminTagList = [];
                for (var i = 0; i < d.adminTagList.length; ++i) {
                    if (typeof d.adminTagList[i] !== "object")
                        throw TypeError(".Proto.Ticket.adminTagList: object expected");
                    m.adminTagList[i] = $root.Proto.HashTag.fromObject(d.adminTagList[i]);
                }
            }
            if (d.main != null) {
                m.main = Boolean(d.main);
            }
            if (d.targetContentUID != null) {
                m.targetContentUID = String(d.targetContentUID);
            }
            if (d.allQuantity != null) {
                if ($util.Long)
                    (m.allQuantity = $util.Long.fromValue(d.allQuantity)).unsigned = false;
                else if (typeof d.allQuantity === "string")
                    m.allQuantity = parseInt(d.allQuantity, 10);
                else if (typeof d.allQuantity === "number")
                    m.allQuantity = d.allQuantity;
                else if (typeof d.allQuantity === "object")
                    m.allQuantity = new $util.LongBits(d.allQuantity.low >>> 0, d.allQuantity.high >>> 0).toNumber();
            }
            if (d.soldout != null) {
                m.soldout = Boolean(d.soldout);
            }
            if (d.allowMultiple != null) {
                m.allowMultiple = Boolean(d.allowMultiple);
            }
            if (d.serviceType != null) {
                m.serviceType = d.serviceType | 0;
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.priceType != null) {
                m.priceType = d.priceType | 0;
            }
            if (d.priceDescription != null) {
                m.priceDescription = String(d.priceDescription);
            }
            if (d.multipleType != null) {
                m.multipleType = d.multipleType | 0;
            }
            if (d.staffList) {
                if (!Array.isArray(d.staffList))
                    throw TypeError(".Proto.Ticket.staffList: array expected");
                m.staffList = [];
                for (var i = 0; i < d.staffList.length; ++i) {
                    if (typeof d.staffList[i] !== "object")
                        throw TypeError(".Proto.Ticket.staffList: object expected");
                    m.staffList[i] = $root.Proto.TicketStaff.fromObject(d.staffList[i]);
                }
            }
            if (d.staffName != null) {
                m.staffName = String(d.staffName);
            }
            if (d.minPrice != null) {
                m.minPrice = Number(d.minPrice);
            }
            if (d.maxPrice != null) {
                m.maxPrice = Number(d.maxPrice);
            }
            return m;
        };

        /**
         * Creates a plain object from a Ticket message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.Ticket
         * @static
         * @param {Proto.Ticket} m Ticket
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Ticket.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.variationList = [];
                d.contentMapList = [];
                d.adminTagList = [];
                d.staffList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.title = "";
                d.description = "";
                d.price = 0;
                d.currency = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.returnDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.returnDate = o.longs === String ? "0" : 0;
                d.distribution = 0;
                d.image = null;
                d.listImage = null;
                d.listImageOrder = "";
                d.variationOrder = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.approveType = 0;
                d.main = false;
                d.targetContentUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.allQuantity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.allQuantity = o.longs === String ? "0" : 0;
                d.soldout = false;
                d.allowMultiple = false;
                d.serviceType = 0;
                d.name = "";
                d.priceType = 0;
                d.priceDescription = "";
                d.multipleType = 0;
                d.staffName = "";
                d.minPrice = 0;
                d.maxPrice = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.returnDate != null && m.hasOwnProperty("returnDate")) {
                if (typeof m.returnDate === "number")
                    d.returnDate = o.longs === String ? String(m.returnDate) : m.returnDate;
                else
                    d.returnDate = o.longs === String ? $util.Long.prototype.toString.call(m.returnDate) : o.longs === Number ? new $util.LongBits(m.returnDate.low >>> 0, m.returnDate.high >>> 0).toNumber() : m.returnDate;
            }
            if (m.distribution != null && m.hasOwnProperty("distribution")) {
                d.distribution = m.distribution;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.listImage != null && m.hasOwnProperty("listImage")) {
                d.listImage = $root.Proto.UploadFile.toObject(m.listImage, o);
            }
            if (m.listImageOrder != null && m.hasOwnProperty("listImageOrder")) {
                d.listImageOrder = m.listImageOrder;
            }
            if (m.variationOrder != null && m.hasOwnProperty("variationOrder")) {
                d.variationOrder = m.variationOrder;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.approveType != null && m.hasOwnProperty("approveType")) {
                d.approveType = m.approveType;
            }
            if (m.variationList && m.variationList.length) {
                d.variationList = [];
                for (var j = 0; j < m.variationList.length; ++j) {
                    d.variationList[j] = $root.Proto.TicketVariation.toObject(m.variationList[j], o);
                }
            }
            if (m.contentMapList && m.contentMapList.length) {
                d.contentMapList = [];
                for (var j = 0; j < m.contentMapList.length; ++j) {
                    d.contentMapList[j] = $root.Proto.ContentTicketMap.toObject(m.contentMapList[j], o);
                }
            }
            if (m.adminTagList && m.adminTagList.length) {
                d.adminTagList = [];
                for (var j = 0; j < m.adminTagList.length; ++j) {
                    d.adminTagList[j] = $root.Proto.HashTag.toObject(m.adminTagList[j], o);
                }
            }
            if (m.main != null && m.hasOwnProperty("main")) {
                d.main = m.main;
            }
            if (m.targetContentUID != null && m.hasOwnProperty("targetContentUID")) {
                d.targetContentUID = m.targetContentUID;
            }
            if (m.allQuantity != null && m.hasOwnProperty("allQuantity")) {
                if (typeof m.allQuantity === "number")
                    d.allQuantity = o.longs === String ? String(m.allQuantity) : m.allQuantity;
                else
                    d.allQuantity = o.longs === String ? $util.Long.prototype.toString.call(m.allQuantity) : o.longs === Number ? new $util.LongBits(m.allQuantity.low >>> 0, m.allQuantity.high >>> 0).toNumber() : m.allQuantity;
            }
            if (m.soldout != null && m.hasOwnProperty("soldout")) {
                d.soldout = m.soldout;
            }
            if (m.allowMultiple != null && m.hasOwnProperty("allowMultiple")) {
                d.allowMultiple = m.allowMultiple;
            }
            if (m.serviceType != null && m.hasOwnProperty("serviceType")) {
                d.serviceType = m.serviceType;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.priceType != null && m.hasOwnProperty("priceType")) {
                d.priceType = m.priceType;
            }
            if (m.priceDescription != null && m.hasOwnProperty("priceDescription")) {
                d.priceDescription = m.priceDescription;
            }
            if (m.multipleType != null && m.hasOwnProperty("multipleType")) {
                d.multipleType = m.multipleType;
            }
            if (m.staffList && m.staffList.length) {
                d.staffList = [];
                for (var j = 0; j < m.staffList.length; ++j) {
                    d.staffList[j] = $root.Proto.TicketStaff.toObject(m.staffList[j], o);
                }
            }
            if (m.staffName != null && m.hasOwnProperty("staffName")) {
                d.staffName = m.staffName;
            }
            if (m.minPrice != null && m.hasOwnProperty("minPrice")) {
                d.minPrice = o.json && !isFinite(m.minPrice) ? String(m.minPrice) : m.minPrice;
            }
            if (m.maxPrice != null && m.hasOwnProperty("maxPrice")) {
                d.maxPrice = o.json && !isFinite(m.maxPrice) ? String(m.maxPrice) : m.maxPrice;
            }
            return d;
        };

        /**
         * Converts this Ticket to JSON.
         * @function toJSON
         * @memberof Proto.Ticket
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Ticket.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Ticket;
    })();

    Proto.TicketList = (function() {

        /**
         * Properties of a TicketList.
         * @memberof Proto
         * @interface ITicketList
         * @property {Array.<Proto.ITicket>|null} [value] TicketList value
         */

        /**
         * Constructs a new TicketList.
         * @memberof Proto
         * @classdesc Represents a TicketList.
         * @implements ITicketList
         * @constructor
         * @param {Proto.ITicketList=} [p] Properties to set
         */
        function TicketList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketList value.
         * @member {Array.<Proto.ITicket>} value
         * @memberof Proto.TicketList
         * @instance
         */
        TicketList.prototype.value = $util.emptyArray;

        /**
         * Creates a new TicketList instance using the specified properties.
         * @function create
         * @memberof Proto.TicketList
         * @static
         * @param {Proto.ITicketList=} [properties] Properties to set
         * @returns {Proto.TicketList} TicketList instance
         */
        TicketList.create = function create(properties) {
            return new TicketList(properties);
        };

        /**
         * Encodes the specified TicketList message. Does not implicitly {@link Proto.TicketList.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketList
         * @static
         * @param {Proto.ITicketList} m TicketList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.Ticket.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a TicketList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketList} TicketList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.Ticket.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketList} TicketList
         */
        TicketList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketList)
                return d;
            var m = new $root.Proto.TicketList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.TicketList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.TicketList.value: object expected");
                    m.value[i] = $root.Proto.Ticket.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketList
         * @static
         * @param {Proto.TicketList} m TicketList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.Ticket.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this TicketList to JSON.
         * @function toJSON
         * @memberof Proto.TicketList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketList;
    })();

    Proto.TicketVariation = (function() {

        /**
         * Properties of a TicketVariation.
         * @memberof Proto
         * @interface ITicketVariation
         * @property {string|null} [uid] TicketVariation uid
         * @property {string|null} [ticketUID] TicketVariation ticketUID
         * @property {string|null} [name] TicketVariation name
         * @property {string|null} [description] TicketVariation description
         * @property {number|null} [price] TicketVariation price
         * @property {string|null} [currency] TicketVariation currency
         * @property {number|null} [quantityFirst] TicketVariation quantityFirst
         * @property {number|null} [quantity] TicketVariation quantity
         * @property {number|null} [quantityLimit] TicketVariation quantityLimit
         * @property {number|null} [status] TicketVariation status
         * @property {Proto.IUploadFile|null} [image] TicketVariation image
         * @property {number|Long|null} [registerDate] TicketVariation registerDate
         * @property {number|Long|null} [modifyDate] TicketVariation modifyDate
         * @property {boolean|null} [soldout] TicketVariation soldout
         * @property {boolean|null} [scheduleEmpty] TicketVariation scheduleEmpty
         * @property {string|null} [onlineLink] TicketVariation onlineLink
         * @property {string|null} [scheduleCount] TicketVariation scheduleCount
         * @property {Proto.ITicketVariationSchedule|null} [representativeSchedule] TicketVariation representativeSchedule
         * @property {number|null} [duration] TicketVariation duration
         * @property {number|null} [distribution] TicketVariation distribution
         * @property {number|null} [deadlinePolicy] TicketVariation deadlinePolicy
         * @property {number|null} [deadlineBaseType] TicketVariation deadlineBaseType
         * @property {number|null} [deadlineBaseNum] TicketVariation deadlineBaseNum
         * @property {string|null} [deadlineBaseTime] TicketVariation deadlineBaseTime
         * @property {number|null} [cancelPolicy] TicketVariation cancelPolicy
         * @property {number|null} [cancelBaseType] TicketVariation cancelBaseType
         * @property {number|null} [cancelBaseNum] TicketVariation cancelBaseNum
         * @property {string|null} [cancelBaseTime] TicketVariation cancelBaseTime
         * @property {number|null} [priceType] TicketVariation priceType
         * @property {string|null} [priceDescription] TicketVariation priceDescription
         * @property {Array.<Proto.ITicketVariationSchedule>|null} [scheduleList] TicketVariation scheduleList
         * @property {Array.<Proto.ITicketVariationScheduleUserInfo>|null} [userInfoList] TicketVariation userInfoList
         * @property {string|null} [excludeHoliday] TicketVariation excludeHoliday
         * @property {Array.<Proto.ITicketVariationScheduleException>|null} [scheduleExceptionList] TicketVariation scheduleExceptionList
         * @property {Array.<Proto.ITicketStaff>|null} [staffList] TicketVariation staffList
         * @property {boolean|null} [splitDate] TicketVariation splitDate
         */

        /**
         * Constructs a new TicketVariation.
         * @memberof Proto
         * @classdesc Represents a TicketVariation.
         * @implements ITicketVariation
         * @constructor
         * @param {Proto.ITicketVariation=} [p] Properties to set
         */
        function TicketVariation(p) {
            this.scheduleList = [];
            this.userInfoList = [];
            this.scheduleExceptionList = [];
            this.staffList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketVariation uid.
         * @member {string} uid
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.uid = "";

        /**
         * TicketVariation ticketUID.
         * @member {string} ticketUID
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.ticketUID = "";

        /**
         * TicketVariation name.
         * @member {string} name
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.name = "";

        /**
         * TicketVariation description.
         * @member {string} description
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.description = "";

        /**
         * TicketVariation price.
         * @member {number} price
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.price = 0;

        /**
         * TicketVariation currency.
         * @member {string} currency
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.currency = "";

        /**
         * TicketVariation quantityFirst.
         * @member {number} quantityFirst
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.quantityFirst = 0;

        /**
         * TicketVariation quantity.
         * @member {number} quantity
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.quantity = 0;

        /**
         * TicketVariation quantityLimit.
         * @member {number} quantityLimit
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.quantityLimit = 0;

        /**
         * TicketVariation status.
         * @member {number} status
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.status = 0;

        /**
         * TicketVariation image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.image = null;

        /**
         * TicketVariation registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariation modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariation soldout.
         * @member {boolean} soldout
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.soldout = false;

        /**
         * TicketVariation scheduleEmpty.
         * @member {boolean} scheduleEmpty
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.scheduleEmpty = false;

        /**
         * TicketVariation onlineLink.
         * @member {string} onlineLink
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.onlineLink = "";

        /**
         * TicketVariation scheduleCount.
         * @member {string} scheduleCount
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.scheduleCount = "";

        /**
         * TicketVariation representativeSchedule.
         * @member {Proto.ITicketVariationSchedule|null|undefined} representativeSchedule
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.representativeSchedule = null;

        /**
         * TicketVariation duration.
         * @member {number} duration
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.duration = 0;

        /**
         * TicketVariation distribution.
         * @member {number} distribution
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.distribution = 0;

        /**
         * TicketVariation deadlinePolicy.
         * @member {number} deadlinePolicy
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.deadlinePolicy = 0;

        /**
         * TicketVariation deadlineBaseType.
         * @member {number} deadlineBaseType
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.deadlineBaseType = 0;

        /**
         * TicketVariation deadlineBaseNum.
         * @member {number} deadlineBaseNum
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.deadlineBaseNum = 0;

        /**
         * TicketVariation deadlineBaseTime.
         * @member {string} deadlineBaseTime
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.deadlineBaseTime = "";

        /**
         * TicketVariation cancelPolicy.
         * @member {number} cancelPolicy
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.cancelPolicy = 0;

        /**
         * TicketVariation cancelBaseType.
         * @member {number} cancelBaseType
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.cancelBaseType = 0;

        /**
         * TicketVariation cancelBaseNum.
         * @member {number} cancelBaseNum
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.cancelBaseNum = 0;

        /**
         * TicketVariation cancelBaseTime.
         * @member {string} cancelBaseTime
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.cancelBaseTime = "";

        /**
         * TicketVariation priceType.
         * @member {number} priceType
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.priceType = 0;

        /**
         * TicketVariation priceDescription.
         * @member {string} priceDescription
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.priceDescription = "";

        /**
         * TicketVariation scheduleList.
         * @member {Array.<Proto.ITicketVariationSchedule>} scheduleList
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.scheduleList = $util.emptyArray;

        /**
         * TicketVariation userInfoList.
         * @member {Array.<Proto.ITicketVariationScheduleUserInfo>} userInfoList
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.userInfoList = $util.emptyArray;

        /**
         * TicketVariation excludeHoliday.
         * @member {string} excludeHoliday
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.excludeHoliday = "";

        /**
         * TicketVariation scheduleExceptionList.
         * @member {Array.<Proto.ITicketVariationScheduleException>} scheduleExceptionList
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.scheduleExceptionList = $util.emptyArray;

        /**
         * TicketVariation staffList.
         * @member {Array.<Proto.ITicketStaff>} staffList
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.staffList = $util.emptyArray;

        /**
         * TicketVariation splitDate.
         * @member {boolean} splitDate
         * @memberof Proto.TicketVariation
         * @instance
         */
        TicketVariation.prototype.splitDate = false;

        /**
         * Creates a new TicketVariation instance using the specified properties.
         * @function create
         * @memberof Proto.TicketVariation
         * @static
         * @param {Proto.ITicketVariation=} [properties] Properties to set
         * @returns {Proto.TicketVariation} TicketVariation instance
         */
        TicketVariation.create = function create(properties) {
            return new TicketVariation(properties);
        };

        /**
         * Encodes the specified TicketVariation message. Does not implicitly {@link Proto.TicketVariation.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketVariation
         * @static
         * @param {Proto.ITicketVariation} m TicketVariation message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketVariation.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.ticketUID != null && Object.hasOwnProperty.call(m, "ticketUID"))
                w.uint32(18).string(m.ticketUID);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(34).string(m.description);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(41).double(m.price);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(50).string(m.currency);
            if (m.quantityFirst != null && Object.hasOwnProperty.call(m, "quantityFirst"))
                w.uint32(56).int32(m.quantityFirst);
            if (m.quantity != null && Object.hasOwnProperty.call(m, "quantity"))
                w.uint32(64).int32(m.quantity);
            if (m.quantityLimit != null && Object.hasOwnProperty.call(m, "quantityLimit"))
                w.uint32(72).int32(m.quantityLimit);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(80).int32(m.status);
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(90).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(96).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(104).int64(m.modifyDate);
            if (m.soldout != null && Object.hasOwnProperty.call(m, "soldout"))
                w.uint32(112).bool(m.soldout);
            if (m.scheduleEmpty != null && Object.hasOwnProperty.call(m, "scheduleEmpty"))
                w.uint32(120).bool(m.scheduleEmpty);
            if (m.onlineLink != null && Object.hasOwnProperty.call(m, "onlineLink"))
                w.uint32(130).string(m.onlineLink);
            if (m.scheduleCount != null && Object.hasOwnProperty.call(m, "scheduleCount"))
                w.uint32(138).string(m.scheduleCount);
            if (m.representativeSchedule != null && Object.hasOwnProperty.call(m, "representativeSchedule"))
                $root.Proto.TicketVariationSchedule.encode(m.representativeSchedule, w.uint32(146).fork()).ldelim();
            if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                w.uint32(152).int32(m.duration);
            if (m.distribution != null && Object.hasOwnProperty.call(m, "distribution"))
                w.uint32(160).int32(m.distribution);
            if (m.deadlinePolicy != null && Object.hasOwnProperty.call(m, "deadlinePolicy"))
                w.uint32(168).int32(m.deadlinePolicy);
            if (m.deadlineBaseType != null && Object.hasOwnProperty.call(m, "deadlineBaseType"))
                w.uint32(176).int32(m.deadlineBaseType);
            if (m.deadlineBaseNum != null && Object.hasOwnProperty.call(m, "deadlineBaseNum"))
                w.uint32(184).int32(m.deadlineBaseNum);
            if (m.deadlineBaseTime != null && Object.hasOwnProperty.call(m, "deadlineBaseTime"))
                w.uint32(194).string(m.deadlineBaseTime);
            if (m.cancelPolicy != null && Object.hasOwnProperty.call(m, "cancelPolicy"))
                w.uint32(200).int32(m.cancelPolicy);
            if (m.cancelBaseType != null && Object.hasOwnProperty.call(m, "cancelBaseType"))
                w.uint32(208).int32(m.cancelBaseType);
            if (m.cancelBaseNum != null && Object.hasOwnProperty.call(m, "cancelBaseNum"))
                w.uint32(216).int32(m.cancelBaseNum);
            if (m.cancelBaseTime != null && Object.hasOwnProperty.call(m, "cancelBaseTime"))
                w.uint32(226).string(m.cancelBaseTime);
            if (m.priceType != null && Object.hasOwnProperty.call(m, "priceType"))
                w.uint32(232).int32(m.priceType);
            if (m.priceDescription != null && Object.hasOwnProperty.call(m, "priceDescription"))
                w.uint32(242).string(m.priceDescription);
            if (m.scheduleList != null && m.scheduleList.length) {
                for (var i = 0; i < m.scheduleList.length; ++i)
                    $root.Proto.TicketVariationSchedule.encode(m.scheduleList[i], w.uint32(250).fork()).ldelim();
            }
            if (m.userInfoList != null && m.userInfoList.length) {
                for (var i = 0; i < m.userInfoList.length; ++i)
                    $root.Proto.TicketVariationScheduleUserInfo.encode(m.userInfoList[i], w.uint32(258).fork()).ldelim();
            }
            if (m.excludeHoliday != null && Object.hasOwnProperty.call(m, "excludeHoliday"))
                w.uint32(266).string(m.excludeHoliday);
            if (m.scheduleExceptionList != null && m.scheduleExceptionList.length) {
                for (var i = 0; i < m.scheduleExceptionList.length; ++i)
                    $root.Proto.TicketVariationScheduleException.encode(m.scheduleExceptionList[i], w.uint32(274).fork()).ldelim();
            }
            if (m.staffList != null && m.staffList.length) {
                for (var i = 0; i < m.staffList.length; ++i)
                    $root.Proto.TicketStaff.encode(m.staffList[i], w.uint32(282).fork()).ldelim();
            }
            if (m.splitDate != null && Object.hasOwnProperty.call(m, "splitDate"))
                w.uint32(288).bool(m.splitDate);
            return w;
        };

        /**
         * Decodes a TicketVariation message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketVariation
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketVariation} TicketVariation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketVariation.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketVariation();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.ticketUID = r.string();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.description = r.string();
                    break;
                case 5:
                    m.price = r.double();
                    break;
                case 6:
                    m.currency = r.string();
                    break;
                case 7:
                    m.quantityFirst = r.int32();
                    break;
                case 8:
                    m.quantity = r.int32();
                    break;
                case 9:
                    m.quantityLimit = r.int32();
                    break;
                case 10:
                    m.status = r.int32();
                    break;
                case 11:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 12:
                    m.registerDate = r.int64();
                    break;
                case 13:
                    m.modifyDate = r.int64();
                    break;
                case 14:
                    m.soldout = r.bool();
                    break;
                case 15:
                    m.scheduleEmpty = r.bool();
                    break;
                case 16:
                    m.onlineLink = r.string();
                    break;
                case 17:
                    m.scheduleCount = r.string();
                    break;
                case 18:
                    m.representativeSchedule = $root.Proto.TicketVariationSchedule.decode(r, r.uint32());
                    break;
                case 19:
                    m.duration = r.int32();
                    break;
                case 20:
                    m.distribution = r.int32();
                    break;
                case 21:
                    m.deadlinePolicy = r.int32();
                    break;
                case 22:
                    m.deadlineBaseType = r.int32();
                    break;
                case 23:
                    m.deadlineBaseNum = r.int32();
                    break;
                case 24:
                    m.deadlineBaseTime = r.string();
                    break;
                case 25:
                    m.cancelPolicy = r.int32();
                    break;
                case 26:
                    m.cancelBaseType = r.int32();
                    break;
                case 27:
                    m.cancelBaseNum = r.int32();
                    break;
                case 28:
                    m.cancelBaseTime = r.string();
                    break;
                case 29:
                    m.priceType = r.int32();
                    break;
                case 30:
                    m.priceDescription = r.string();
                    break;
                case 31:
                    if (!(m.scheduleList && m.scheduleList.length))
                        m.scheduleList = [];
                    m.scheduleList.push($root.Proto.TicketVariationSchedule.decode(r, r.uint32()));
                    break;
                case 32:
                    if (!(m.userInfoList && m.userInfoList.length))
                        m.userInfoList = [];
                    m.userInfoList.push($root.Proto.TicketVariationScheduleUserInfo.decode(r, r.uint32()));
                    break;
                case 33:
                    m.excludeHoliday = r.string();
                    break;
                case 34:
                    if (!(m.scheduleExceptionList && m.scheduleExceptionList.length))
                        m.scheduleExceptionList = [];
                    m.scheduleExceptionList.push($root.Proto.TicketVariationScheduleException.decode(r, r.uint32()));
                    break;
                case 35:
                    if (!(m.staffList && m.staffList.length))
                        m.staffList = [];
                    m.staffList.push($root.Proto.TicketStaff.decode(r, r.uint32()));
                    break;
                case 36:
                    m.splitDate = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketVariation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketVariation
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketVariation} TicketVariation
         */
        TicketVariation.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketVariation)
                return d;
            var m = new $root.Proto.TicketVariation();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.ticketUID != null) {
                m.ticketUID = String(d.ticketUID);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.quantityFirst != null) {
                m.quantityFirst = d.quantityFirst | 0;
            }
            if (d.quantity != null) {
                m.quantity = d.quantity | 0;
            }
            if (d.quantityLimit != null) {
                m.quantityLimit = d.quantityLimit | 0;
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.TicketVariation.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.soldout != null) {
                m.soldout = Boolean(d.soldout);
            }
            if (d.scheduleEmpty != null) {
                m.scheduleEmpty = Boolean(d.scheduleEmpty);
            }
            if (d.onlineLink != null) {
                m.onlineLink = String(d.onlineLink);
            }
            if (d.scheduleCount != null) {
                m.scheduleCount = String(d.scheduleCount);
            }
            if (d.representativeSchedule != null) {
                if (typeof d.representativeSchedule !== "object")
                    throw TypeError(".Proto.TicketVariation.representativeSchedule: object expected");
                m.representativeSchedule = $root.Proto.TicketVariationSchedule.fromObject(d.representativeSchedule);
            }
            if (d.duration != null) {
                m.duration = d.duration | 0;
            }
            if (d.distribution != null) {
                m.distribution = d.distribution | 0;
            }
            if (d.deadlinePolicy != null) {
                m.deadlinePolicy = d.deadlinePolicy | 0;
            }
            if (d.deadlineBaseType != null) {
                m.deadlineBaseType = d.deadlineBaseType | 0;
            }
            if (d.deadlineBaseNum != null) {
                m.deadlineBaseNum = d.deadlineBaseNum | 0;
            }
            if (d.deadlineBaseTime != null) {
                m.deadlineBaseTime = String(d.deadlineBaseTime);
            }
            if (d.cancelPolicy != null) {
                m.cancelPolicy = d.cancelPolicy | 0;
            }
            if (d.cancelBaseType != null) {
                m.cancelBaseType = d.cancelBaseType | 0;
            }
            if (d.cancelBaseNum != null) {
                m.cancelBaseNum = d.cancelBaseNum | 0;
            }
            if (d.cancelBaseTime != null) {
                m.cancelBaseTime = String(d.cancelBaseTime);
            }
            if (d.priceType != null) {
                m.priceType = d.priceType | 0;
            }
            if (d.priceDescription != null) {
                m.priceDescription = String(d.priceDescription);
            }
            if (d.scheduleList) {
                if (!Array.isArray(d.scheduleList))
                    throw TypeError(".Proto.TicketVariation.scheduleList: array expected");
                m.scheduleList = [];
                for (var i = 0; i < d.scheduleList.length; ++i) {
                    if (typeof d.scheduleList[i] !== "object")
                        throw TypeError(".Proto.TicketVariation.scheduleList: object expected");
                    m.scheduleList[i] = $root.Proto.TicketVariationSchedule.fromObject(d.scheduleList[i]);
                }
            }
            if (d.userInfoList) {
                if (!Array.isArray(d.userInfoList))
                    throw TypeError(".Proto.TicketVariation.userInfoList: array expected");
                m.userInfoList = [];
                for (var i = 0; i < d.userInfoList.length; ++i) {
                    if (typeof d.userInfoList[i] !== "object")
                        throw TypeError(".Proto.TicketVariation.userInfoList: object expected");
                    m.userInfoList[i] = $root.Proto.TicketVariationScheduleUserInfo.fromObject(d.userInfoList[i]);
                }
            }
            if (d.excludeHoliday != null) {
                m.excludeHoliday = String(d.excludeHoliday);
            }
            if (d.scheduleExceptionList) {
                if (!Array.isArray(d.scheduleExceptionList))
                    throw TypeError(".Proto.TicketVariation.scheduleExceptionList: array expected");
                m.scheduleExceptionList = [];
                for (var i = 0; i < d.scheduleExceptionList.length; ++i) {
                    if (typeof d.scheduleExceptionList[i] !== "object")
                        throw TypeError(".Proto.TicketVariation.scheduleExceptionList: object expected");
                    m.scheduleExceptionList[i] = $root.Proto.TicketVariationScheduleException.fromObject(d.scheduleExceptionList[i]);
                }
            }
            if (d.staffList) {
                if (!Array.isArray(d.staffList))
                    throw TypeError(".Proto.TicketVariation.staffList: array expected");
                m.staffList = [];
                for (var i = 0; i < d.staffList.length; ++i) {
                    if (typeof d.staffList[i] !== "object")
                        throw TypeError(".Proto.TicketVariation.staffList: object expected");
                    m.staffList[i] = $root.Proto.TicketStaff.fromObject(d.staffList[i]);
                }
            }
            if (d.splitDate != null) {
                m.splitDate = Boolean(d.splitDate);
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketVariation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketVariation
         * @static
         * @param {Proto.TicketVariation} m TicketVariation
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketVariation.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.scheduleList = [];
                d.userInfoList = [];
                d.scheduleExceptionList = [];
                d.staffList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.ticketUID = "";
                d.name = "";
                d.description = "";
                d.price = 0;
                d.currency = "";
                d.quantityFirst = 0;
                d.quantity = 0;
                d.quantityLimit = 0;
                d.status = 0;
                d.image = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.soldout = false;
                d.scheduleEmpty = false;
                d.onlineLink = "";
                d.scheduleCount = "";
                d.representativeSchedule = null;
                d.duration = 0;
                d.distribution = 0;
                d.deadlinePolicy = 0;
                d.deadlineBaseType = 0;
                d.deadlineBaseNum = 0;
                d.deadlineBaseTime = "";
                d.cancelPolicy = 0;
                d.cancelBaseType = 0;
                d.cancelBaseNum = 0;
                d.cancelBaseTime = "";
                d.priceType = 0;
                d.priceDescription = "";
                d.excludeHoliday = "";
                d.splitDate = false;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.ticketUID != null && m.hasOwnProperty("ticketUID")) {
                d.ticketUID = m.ticketUID;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.quantityFirst != null && m.hasOwnProperty("quantityFirst")) {
                d.quantityFirst = m.quantityFirst;
            }
            if (m.quantity != null && m.hasOwnProperty("quantity")) {
                d.quantity = m.quantity;
            }
            if (m.quantityLimit != null && m.hasOwnProperty("quantityLimit")) {
                d.quantityLimit = m.quantityLimit;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.soldout != null && m.hasOwnProperty("soldout")) {
                d.soldout = m.soldout;
            }
            if (m.scheduleEmpty != null && m.hasOwnProperty("scheduleEmpty")) {
                d.scheduleEmpty = m.scheduleEmpty;
            }
            if (m.onlineLink != null && m.hasOwnProperty("onlineLink")) {
                d.onlineLink = m.onlineLink;
            }
            if (m.scheduleCount != null && m.hasOwnProperty("scheduleCount")) {
                d.scheduleCount = m.scheduleCount;
            }
            if (m.representativeSchedule != null && m.hasOwnProperty("representativeSchedule")) {
                d.representativeSchedule = $root.Proto.TicketVariationSchedule.toObject(m.representativeSchedule, o);
            }
            if (m.duration != null && m.hasOwnProperty("duration")) {
                d.duration = m.duration;
            }
            if (m.distribution != null && m.hasOwnProperty("distribution")) {
                d.distribution = m.distribution;
            }
            if (m.deadlinePolicy != null && m.hasOwnProperty("deadlinePolicy")) {
                d.deadlinePolicy = m.deadlinePolicy;
            }
            if (m.deadlineBaseType != null && m.hasOwnProperty("deadlineBaseType")) {
                d.deadlineBaseType = m.deadlineBaseType;
            }
            if (m.deadlineBaseNum != null && m.hasOwnProperty("deadlineBaseNum")) {
                d.deadlineBaseNum = m.deadlineBaseNum;
            }
            if (m.deadlineBaseTime != null && m.hasOwnProperty("deadlineBaseTime")) {
                d.deadlineBaseTime = m.deadlineBaseTime;
            }
            if (m.cancelPolicy != null && m.hasOwnProperty("cancelPolicy")) {
                d.cancelPolicy = m.cancelPolicy;
            }
            if (m.cancelBaseType != null && m.hasOwnProperty("cancelBaseType")) {
                d.cancelBaseType = m.cancelBaseType;
            }
            if (m.cancelBaseNum != null && m.hasOwnProperty("cancelBaseNum")) {
                d.cancelBaseNum = m.cancelBaseNum;
            }
            if (m.cancelBaseTime != null && m.hasOwnProperty("cancelBaseTime")) {
                d.cancelBaseTime = m.cancelBaseTime;
            }
            if (m.priceType != null && m.hasOwnProperty("priceType")) {
                d.priceType = m.priceType;
            }
            if (m.priceDescription != null && m.hasOwnProperty("priceDescription")) {
                d.priceDescription = m.priceDescription;
            }
            if (m.scheduleList && m.scheduleList.length) {
                d.scheduleList = [];
                for (var j = 0; j < m.scheduleList.length; ++j) {
                    d.scheduleList[j] = $root.Proto.TicketVariationSchedule.toObject(m.scheduleList[j], o);
                }
            }
            if (m.userInfoList && m.userInfoList.length) {
                d.userInfoList = [];
                for (var j = 0; j < m.userInfoList.length; ++j) {
                    d.userInfoList[j] = $root.Proto.TicketVariationScheduleUserInfo.toObject(m.userInfoList[j], o);
                }
            }
            if (m.excludeHoliday != null && m.hasOwnProperty("excludeHoliday")) {
                d.excludeHoliday = m.excludeHoliday;
            }
            if (m.scheduleExceptionList && m.scheduleExceptionList.length) {
                d.scheduleExceptionList = [];
                for (var j = 0; j < m.scheduleExceptionList.length; ++j) {
                    d.scheduleExceptionList[j] = $root.Proto.TicketVariationScheduleException.toObject(m.scheduleExceptionList[j], o);
                }
            }
            if (m.staffList && m.staffList.length) {
                d.staffList = [];
                for (var j = 0; j < m.staffList.length; ++j) {
                    d.staffList[j] = $root.Proto.TicketStaff.toObject(m.staffList[j], o);
                }
            }
            if (m.splitDate != null && m.hasOwnProperty("splitDate")) {
                d.splitDate = m.splitDate;
            }
            return d;
        };

        /**
         * Converts this TicketVariation to JSON.
         * @function toJSON
         * @memberof Proto.TicketVariation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketVariation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketVariation;
    })();

    Proto.ContentTicketMap = (function() {

        /**
         * Properties of a ContentTicketMap.
         * @memberof Proto
         * @interface IContentTicketMap
         * @property {string|null} [contentUID] ContentTicketMap contentUID
         * @property {string|null} [ticketUID] ContentTicketMap ticketUID
         * @property {number|Long|null} [registerDate] ContentTicketMap registerDate
         * @property {number|Long|null} [modifyDate] ContentTicketMap modifyDate
         * @property {number|null} [status] ContentTicketMap status
         * @property {string|null} [contentTitle] ContentTicketMap contentTitle
         * @property {number|null} [order] ContentTicketMap order
         * @property {Proto.ITicket|null} [ticket] ContentTicketMap ticket
         * @property {boolean|null} [main] ContentTicketMap main
         * @property {number|null} [distribution] ContentTicketMap distribution
         * @property {Proto.IUploadFile|null} [contentFile] ContentTicketMap contentFile
         * @property {number|null} [contentDistribution] ContentTicketMap contentDistribution
         * @property {number|Long|null} [contentStartDate] ContentTicketMap contentStartDate
         * @property {number|Long|null} [contentEndDate] ContentTicketMap contentEndDate
         * @property {number|null} [contentTicketPolicy] ContentTicketMap contentTicketPolicy
         * @property {number|null} [contentPublicPolicy] ContentTicketMap contentPublicPolicy
         */

        /**
         * Constructs a new ContentTicketMap.
         * @memberof Proto
         * @classdesc Represents a ContentTicketMap.
         * @implements IContentTicketMap
         * @constructor
         * @param {Proto.IContentTicketMap=} [p] Properties to set
         */
        function ContentTicketMap(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentTicketMap contentUID.
         * @member {string} contentUID
         * @memberof Proto.ContentTicketMap
         * @instance
         */
        ContentTicketMap.prototype.contentUID = "";

        /**
         * ContentTicketMap ticketUID.
         * @member {string} ticketUID
         * @memberof Proto.ContentTicketMap
         * @instance
         */
        ContentTicketMap.prototype.ticketUID = "";

        /**
         * ContentTicketMap registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentTicketMap
         * @instance
         */
        ContentTicketMap.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentTicketMap modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentTicketMap
         * @instance
         */
        ContentTicketMap.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentTicketMap status.
         * @member {number} status
         * @memberof Proto.ContentTicketMap
         * @instance
         */
        ContentTicketMap.prototype.status = 0;

        /**
         * ContentTicketMap contentTitle.
         * @member {string} contentTitle
         * @memberof Proto.ContentTicketMap
         * @instance
         */
        ContentTicketMap.prototype.contentTitle = "";

        /**
         * ContentTicketMap order.
         * @member {number} order
         * @memberof Proto.ContentTicketMap
         * @instance
         */
        ContentTicketMap.prototype.order = 0;

        /**
         * ContentTicketMap ticket.
         * @member {Proto.ITicket|null|undefined} ticket
         * @memberof Proto.ContentTicketMap
         * @instance
         */
        ContentTicketMap.prototype.ticket = null;

        /**
         * ContentTicketMap main.
         * @member {boolean} main
         * @memberof Proto.ContentTicketMap
         * @instance
         */
        ContentTicketMap.prototype.main = false;

        /**
         * ContentTicketMap distribution.
         * @member {number} distribution
         * @memberof Proto.ContentTicketMap
         * @instance
         */
        ContentTicketMap.prototype.distribution = 0;

        /**
         * ContentTicketMap contentFile.
         * @member {Proto.IUploadFile|null|undefined} contentFile
         * @memberof Proto.ContentTicketMap
         * @instance
         */
        ContentTicketMap.prototype.contentFile = null;

        /**
         * ContentTicketMap contentDistribution.
         * @member {number} contentDistribution
         * @memberof Proto.ContentTicketMap
         * @instance
         */
        ContentTicketMap.prototype.contentDistribution = 0;

        /**
         * ContentTicketMap contentStartDate.
         * @member {number|Long} contentStartDate
         * @memberof Proto.ContentTicketMap
         * @instance
         */
        ContentTicketMap.prototype.contentStartDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentTicketMap contentEndDate.
         * @member {number|Long} contentEndDate
         * @memberof Proto.ContentTicketMap
         * @instance
         */
        ContentTicketMap.prototype.contentEndDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentTicketMap contentTicketPolicy.
         * @member {number} contentTicketPolicy
         * @memberof Proto.ContentTicketMap
         * @instance
         */
        ContentTicketMap.prototype.contentTicketPolicy = 0;

        /**
         * ContentTicketMap contentPublicPolicy.
         * @member {number} contentPublicPolicy
         * @memberof Proto.ContentTicketMap
         * @instance
         */
        ContentTicketMap.prototype.contentPublicPolicy = 0;

        /**
         * Creates a new ContentTicketMap instance using the specified properties.
         * @function create
         * @memberof Proto.ContentTicketMap
         * @static
         * @param {Proto.IContentTicketMap=} [properties] Properties to set
         * @returns {Proto.ContentTicketMap} ContentTicketMap instance
         */
        ContentTicketMap.create = function create(properties) {
            return new ContentTicketMap(properties);
        };

        /**
         * Encodes the specified ContentTicketMap message. Does not implicitly {@link Proto.ContentTicketMap.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentTicketMap
         * @static
         * @param {Proto.IContentTicketMap} m ContentTicketMap message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentTicketMap.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(10).string(m.contentUID);
            if (m.ticketUID != null && Object.hasOwnProperty.call(m, "ticketUID"))
                w.uint32(18).string(m.ticketUID);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(32).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(40).int32(m.status);
            if (m.contentTitle != null && Object.hasOwnProperty.call(m, "contentTitle"))
                w.uint32(50).string(m.contentTitle);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(56).int32(m.order);
            if (m.ticket != null && Object.hasOwnProperty.call(m, "ticket"))
                $root.Proto.Ticket.encode(m.ticket, w.uint32(66).fork()).ldelim();
            if (m.main != null && Object.hasOwnProperty.call(m, "main"))
                w.uint32(72).bool(m.main);
            if (m.distribution != null && Object.hasOwnProperty.call(m, "distribution"))
                w.uint32(80).int32(m.distribution);
            if (m.contentFile != null && Object.hasOwnProperty.call(m, "contentFile"))
                $root.Proto.UploadFile.encode(m.contentFile, w.uint32(90).fork()).ldelim();
            if (m.contentDistribution != null && Object.hasOwnProperty.call(m, "contentDistribution"))
                w.uint32(96).int32(m.contentDistribution);
            if (m.contentStartDate != null && Object.hasOwnProperty.call(m, "contentStartDate"))
                w.uint32(104).int64(m.contentStartDate);
            if (m.contentEndDate != null && Object.hasOwnProperty.call(m, "contentEndDate"))
                w.uint32(120).int64(m.contentEndDate);
            if (m.contentTicketPolicy != null && Object.hasOwnProperty.call(m, "contentTicketPolicy"))
                w.uint32(128).int32(m.contentTicketPolicy);
            if (m.contentPublicPolicy != null && Object.hasOwnProperty.call(m, "contentPublicPolicy"))
                w.uint32(136).int32(m.contentPublicPolicy);
            return w;
        };

        /**
         * Decodes a ContentTicketMap message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentTicketMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentTicketMap} ContentTicketMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentTicketMap.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentTicketMap();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.contentUID = r.string();
                    break;
                case 2:
                    m.ticketUID = r.string();
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    m.modifyDate = r.int64();
                    break;
                case 5:
                    m.status = r.int32();
                    break;
                case 6:
                    m.contentTitle = r.string();
                    break;
                case 7:
                    m.order = r.int32();
                    break;
                case 8:
                    m.ticket = $root.Proto.Ticket.decode(r, r.uint32());
                    break;
                case 9:
                    m.main = r.bool();
                    break;
                case 10:
                    m.distribution = r.int32();
                    break;
                case 11:
                    m.contentFile = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 12:
                    m.contentDistribution = r.int32();
                    break;
                case 13:
                    m.contentStartDate = r.int64();
                    break;
                case 15:
                    m.contentEndDate = r.int64();
                    break;
                case 16:
                    m.contentTicketPolicy = r.int32();
                    break;
                case 17:
                    m.contentPublicPolicy = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentTicketMap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentTicketMap
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentTicketMap} ContentTicketMap
         */
        ContentTicketMap.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentTicketMap)
                return d;
            var m = new $root.Proto.ContentTicketMap();
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.ticketUID != null) {
                m.ticketUID = String(d.ticketUID);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.contentTitle != null) {
                m.contentTitle = String(d.contentTitle);
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.ticket != null) {
                if (typeof d.ticket !== "object")
                    throw TypeError(".Proto.ContentTicketMap.ticket: object expected");
                m.ticket = $root.Proto.Ticket.fromObject(d.ticket);
            }
            if (d.main != null) {
                m.main = Boolean(d.main);
            }
            if (d.distribution != null) {
                m.distribution = d.distribution | 0;
            }
            if (d.contentFile != null) {
                if (typeof d.contentFile !== "object")
                    throw TypeError(".Proto.ContentTicketMap.contentFile: object expected");
                m.contentFile = $root.Proto.UploadFile.fromObject(d.contentFile);
            }
            if (d.contentDistribution != null) {
                m.contentDistribution = d.contentDistribution | 0;
            }
            if (d.contentStartDate != null) {
                if ($util.Long)
                    (m.contentStartDate = $util.Long.fromValue(d.contentStartDate)).unsigned = false;
                else if (typeof d.contentStartDate === "string")
                    m.contentStartDate = parseInt(d.contentStartDate, 10);
                else if (typeof d.contentStartDate === "number")
                    m.contentStartDate = d.contentStartDate;
                else if (typeof d.contentStartDate === "object")
                    m.contentStartDate = new $util.LongBits(d.contentStartDate.low >>> 0, d.contentStartDate.high >>> 0).toNumber();
            }
            if (d.contentEndDate != null) {
                if ($util.Long)
                    (m.contentEndDate = $util.Long.fromValue(d.contentEndDate)).unsigned = false;
                else if (typeof d.contentEndDate === "string")
                    m.contentEndDate = parseInt(d.contentEndDate, 10);
                else if (typeof d.contentEndDate === "number")
                    m.contentEndDate = d.contentEndDate;
                else if (typeof d.contentEndDate === "object")
                    m.contentEndDate = new $util.LongBits(d.contentEndDate.low >>> 0, d.contentEndDate.high >>> 0).toNumber();
            }
            if (d.contentTicketPolicy != null) {
                m.contentTicketPolicy = d.contentTicketPolicy | 0;
            }
            if (d.contentPublicPolicy != null) {
                m.contentPublicPolicy = d.contentPublicPolicy | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentTicketMap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentTicketMap
         * @static
         * @param {Proto.ContentTicketMap} m ContentTicketMap
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentTicketMap.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.contentUID = "";
                d.ticketUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.contentTitle = "";
                d.order = 0;
                d.ticket = null;
                d.main = false;
                d.distribution = 0;
                d.contentFile = null;
                d.contentDistribution = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.contentStartDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.contentStartDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.contentEndDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.contentEndDate = o.longs === String ? "0" : 0;
                d.contentTicketPolicy = 0;
                d.contentPublicPolicy = 0;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.ticketUID != null && m.hasOwnProperty("ticketUID")) {
                d.ticketUID = m.ticketUID;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.contentTitle != null && m.hasOwnProperty("contentTitle")) {
                d.contentTitle = m.contentTitle;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.ticket != null && m.hasOwnProperty("ticket")) {
                d.ticket = $root.Proto.Ticket.toObject(m.ticket, o);
            }
            if (m.main != null && m.hasOwnProperty("main")) {
                d.main = m.main;
            }
            if (m.distribution != null && m.hasOwnProperty("distribution")) {
                d.distribution = m.distribution;
            }
            if (m.contentFile != null && m.hasOwnProperty("contentFile")) {
                d.contentFile = $root.Proto.UploadFile.toObject(m.contentFile, o);
            }
            if (m.contentDistribution != null && m.hasOwnProperty("contentDistribution")) {
                d.contentDistribution = m.contentDistribution;
            }
            if (m.contentStartDate != null && m.hasOwnProperty("contentStartDate")) {
                if (typeof m.contentStartDate === "number")
                    d.contentStartDate = o.longs === String ? String(m.contentStartDate) : m.contentStartDate;
                else
                    d.contentStartDate = o.longs === String ? $util.Long.prototype.toString.call(m.contentStartDate) : o.longs === Number ? new $util.LongBits(m.contentStartDate.low >>> 0, m.contentStartDate.high >>> 0).toNumber() : m.contentStartDate;
            }
            if (m.contentEndDate != null && m.hasOwnProperty("contentEndDate")) {
                if (typeof m.contentEndDate === "number")
                    d.contentEndDate = o.longs === String ? String(m.contentEndDate) : m.contentEndDate;
                else
                    d.contentEndDate = o.longs === String ? $util.Long.prototype.toString.call(m.contentEndDate) : o.longs === Number ? new $util.LongBits(m.contentEndDate.low >>> 0, m.contentEndDate.high >>> 0).toNumber() : m.contentEndDate;
            }
            if (m.contentTicketPolicy != null && m.hasOwnProperty("contentTicketPolicy")) {
                d.contentTicketPolicy = m.contentTicketPolicy;
            }
            if (m.contentPublicPolicy != null && m.hasOwnProperty("contentPublicPolicy")) {
                d.contentPublicPolicy = m.contentPublicPolicy;
            }
            return d;
        };

        /**
         * Converts this ContentTicketMap to JSON.
         * @function toJSON
         * @memberof Proto.ContentTicketMap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentTicketMap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentTicketMap;
    })();

    Proto.ContentTicketMapList = (function() {

        /**
         * Properties of a ContentTicketMapList.
         * @memberof Proto
         * @interface IContentTicketMapList
         * @property {Array.<Proto.IContentTicketMap>|null} [value] ContentTicketMapList value
         */

        /**
         * Constructs a new ContentTicketMapList.
         * @memberof Proto
         * @classdesc Represents a ContentTicketMapList.
         * @implements IContentTicketMapList
         * @constructor
         * @param {Proto.IContentTicketMapList=} [p] Properties to set
         */
        function ContentTicketMapList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentTicketMapList value.
         * @member {Array.<Proto.IContentTicketMap>} value
         * @memberof Proto.ContentTicketMapList
         * @instance
         */
        ContentTicketMapList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentTicketMapList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentTicketMapList
         * @static
         * @param {Proto.IContentTicketMapList=} [properties] Properties to set
         * @returns {Proto.ContentTicketMapList} ContentTicketMapList instance
         */
        ContentTicketMapList.create = function create(properties) {
            return new ContentTicketMapList(properties);
        };

        /**
         * Encodes the specified ContentTicketMapList message. Does not implicitly {@link Proto.ContentTicketMapList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentTicketMapList
         * @static
         * @param {Proto.IContentTicketMapList} m ContentTicketMapList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentTicketMapList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentTicketMap.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentTicketMapList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentTicketMapList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentTicketMapList} ContentTicketMapList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentTicketMapList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentTicketMapList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentTicketMap.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentTicketMapList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentTicketMapList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentTicketMapList} ContentTicketMapList
         */
        ContentTicketMapList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentTicketMapList)
                return d;
            var m = new $root.Proto.ContentTicketMapList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentTicketMapList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentTicketMapList.value: object expected");
                    m.value[i] = $root.Proto.ContentTicketMap.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentTicketMapList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentTicketMapList
         * @static
         * @param {Proto.ContentTicketMapList} m ContentTicketMapList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentTicketMapList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentTicketMap.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentTicketMapList to JSON.
         * @function toJSON
         * @memberof Proto.ContentTicketMapList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentTicketMapList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentTicketMapList;
    })();

    Proto.TicketVariationSchedule = (function() {

        /**
         * Properties of a TicketVariationSchedule.
         * @memberof Proto
         * @interface ITicketVariationSchedule
         * @property {string|null} [uid] TicketVariationSchedule uid
         * @property {string|null} [variationUID] TicketVariationSchedule variationUID
         * @property {number|null} [dateInterval] TicketVariationSchedule dateInterval
         * @property {number|Long|null} [startDate] TicketVariationSchedule startDate
         * @property {number|Long|null} [endDate] TicketVariationSchedule endDate
         * @property {number|Long|null} [registerDate] TicketVariationSchedule registerDate
         * @property {number|Long|null} [modifyDate] TicketVariationSchedule modifyDate
         * @property {number|null} [status] TicketVariationSchedule status
         * @property {Array.<Proto.ITicketVariationScheduleDate>|null} [dateList] TicketVariationSchedule dateList
         * @property {Array.<Proto.ITicketVariationScheduleUserInfo>|null} [userInfoList] TicketVariationSchedule userInfoList
         * @property {Array.<Proto.ITicketVariationScheduleDateUser>|null} [userList] TicketVariationSchedule userList
         * @property {string|null} [timezoneId] TicketVariationSchedule timezoneId
         * @property {number|null} [timezoneOffset] TicketVariationSchedule timezoneOffset
         * @property {string|null} [recurStartDate] TicketVariationSchedule recurStartDate
         * @property {string|null} [recurStartTime] TicketVariationSchedule recurStartTime
         * @property {string|null} [recurEndDate] TicketVariationSchedule recurEndDate
         * @property {number|null} [recurType] TicketVariationSchedule recurType
         * @property {number|null} [duration] TicketVariationSchedule duration
         * @property {string|null} [recurDay] TicketVariationSchedule recurDay
         * @property {string|null} [recurWeekday] TicketVariationSchedule recurWeekday
         * @property {string|null} [recurWeek] TicketVariationSchedule recurWeek
         * @property {number|null} [recurInterval] TicketVariationSchedule recurInterval
         */

        /**
         * Constructs a new TicketVariationSchedule.
         * @memberof Proto
         * @classdesc Represents a TicketVariationSchedule.
         * @implements ITicketVariationSchedule
         * @constructor
         * @param {Proto.ITicketVariationSchedule=} [p] Properties to set
         */
        function TicketVariationSchedule(p) {
            this.dateList = [];
            this.userInfoList = [];
            this.userList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketVariationSchedule uid.
         * @member {string} uid
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.uid = "";

        /**
         * TicketVariationSchedule variationUID.
         * @member {string} variationUID
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.variationUID = "";

        /**
         * TicketVariationSchedule dateInterval.
         * @member {number} dateInterval
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.dateInterval = 0;

        /**
         * TicketVariationSchedule startDate.
         * @member {number|Long} startDate
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationSchedule endDate.
         * @member {number|Long} endDate
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationSchedule registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationSchedule modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationSchedule status.
         * @member {number} status
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.status = 0;

        /**
         * TicketVariationSchedule dateList.
         * @member {Array.<Proto.ITicketVariationScheduleDate>} dateList
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.dateList = $util.emptyArray;

        /**
         * TicketVariationSchedule userInfoList.
         * @member {Array.<Proto.ITicketVariationScheduleUserInfo>} userInfoList
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.userInfoList = $util.emptyArray;

        /**
         * TicketVariationSchedule userList.
         * @member {Array.<Proto.ITicketVariationScheduleDateUser>} userList
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.userList = $util.emptyArray;

        /**
         * TicketVariationSchedule timezoneId.
         * @member {string} timezoneId
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.timezoneId = "";

        /**
         * TicketVariationSchedule timezoneOffset.
         * @member {number} timezoneOffset
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.timezoneOffset = 0;

        /**
         * TicketVariationSchedule recurStartDate.
         * @member {string} recurStartDate
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.recurStartDate = "";

        /**
         * TicketVariationSchedule recurStartTime.
         * @member {string} recurStartTime
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.recurStartTime = "";

        /**
         * TicketVariationSchedule recurEndDate.
         * @member {string} recurEndDate
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.recurEndDate = "";

        /**
         * TicketVariationSchedule recurType.
         * @member {number} recurType
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.recurType = 0;

        /**
         * TicketVariationSchedule duration.
         * @member {number} duration
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.duration = 0;

        /**
         * TicketVariationSchedule recurDay.
         * @member {string} recurDay
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.recurDay = "";

        /**
         * TicketVariationSchedule recurWeekday.
         * @member {string} recurWeekday
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.recurWeekday = "";

        /**
         * TicketVariationSchedule recurWeek.
         * @member {string} recurWeek
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.recurWeek = "";

        /**
         * TicketVariationSchedule recurInterval.
         * @member {number} recurInterval
         * @memberof Proto.TicketVariationSchedule
         * @instance
         */
        TicketVariationSchedule.prototype.recurInterval = 0;

        /**
         * Creates a new TicketVariationSchedule instance using the specified properties.
         * @function create
         * @memberof Proto.TicketVariationSchedule
         * @static
         * @param {Proto.ITicketVariationSchedule=} [properties] Properties to set
         * @returns {Proto.TicketVariationSchedule} TicketVariationSchedule instance
         */
        TicketVariationSchedule.create = function create(properties) {
            return new TicketVariationSchedule(properties);
        };

        /**
         * Encodes the specified TicketVariationSchedule message. Does not implicitly {@link Proto.TicketVariationSchedule.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketVariationSchedule
         * @static
         * @param {Proto.ITicketVariationSchedule} m TicketVariationSchedule message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketVariationSchedule.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.variationUID != null && Object.hasOwnProperty.call(m, "variationUID"))
                w.uint32(18).string(m.variationUID);
            if (m.dateInterval != null && Object.hasOwnProperty.call(m, "dateInterval"))
                w.uint32(24).int32(m.dateInterval);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(32).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(40).int64(m.endDate);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(64).int32(m.status);
            if (m.dateList != null && m.dateList.length) {
                for (var i = 0; i < m.dateList.length; ++i)
                    $root.Proto.TicketVariationScheduleDate.encode(m.dateList[i], w.uint32(82).fork()).ldelim();
            }
            if (m.userInfoList != null && m.userInfoList.length) {
                for (var i = 0; i < m.userInfoList.length; ++i)
                    $root.Proto.TicketVariationScheduleUserInfo.encode(m.userInfoList[i], w.uint32(90).fork()).ldelim();
            }
            if (m.userList != null && m.userList.length) {
                for (var i = 0; i < m.userList.length; ++i)
                    $root.Proto.TicketVariationScheduleDateUser.encode(m.userList[i], w.uint32(98).fork()).ldelim();
            }
            if (m.timezoneId != null && Object.hasOwnProperty.call(m, "timezoneId"))
                w.uint32(106).string(m.timezoneId);
            if (m.timezoneOffset != null && Object.hasOwnProperty.call(m, "timezoneOffset"))
                w.uint32(112).int32(m.timezoneOffset);
            if (m.recurStartDate != null && Object.hasOwnProperty.call(m, "recurStartDate"))
                w.uint32(122).string(m.recurStartDate);
            if (m.recurStartTime != null && Object.hasOwnProperty.call(m, "recurStartTime"))
                w.uint32(130).string(m.recurStartTime);
            if (m.recurEndDate != null && Object.hasOwnProperty.call(m, "recurEndDate"))
                w.uint32(138).string(m.recurEndDate);
            if (m.recurType != null && Object.hasOwnProperty.call(m, "recurType"))
                w.uint32(144).int32(m.recurType);
            if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                w.uint32(152).int32(m.duration);
            if (m.recurDay != null && Object.hasOwnProperty.call(m, "recurDay"))
                w.uint32(162).string(m.recurDay);
            if (m.recurWeekday != null && Object.hasOwnProperty.call(m, "recurWeekday"))
                w.uint32(170).string(m.recurWeekday);
            if (m.recurWeek != null && Object.hasOwnProperty.call(m, "recurWeek"))
                w.uint32(178).string(m.recurWeek);
            if (m.recurInterval != null && Object.hasOwnProperty.call(m, "recurInterval"))
                w.uint32(184).int32(m.recurInterval);
            return w;
        };

        /**
         * Decodes a TicketVariationSchedule message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketVariationSchedule
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketVariationSchedule} TicketVariationSchedule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketVariationSchedule.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketVariationSchedule();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.variationUID = r.string();
                    break;
                case 3:
                    m.dateInterval = r.int32();
                    break;
                case 4:
                    m.startDate = r.int64();
                    break;
                case 5:
                    m.endDate = r.int64();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.status = r.int32();
                    break;
                case 10:
                    if (!(m.dateList && m.dateList.length))
                        m.dateList = [];
                    m.dateList.push($root.Proto.TicketVariationScheduleDate.decode(r, r.uint32()));
                    break;
                case 11:
                    if (!(m.userInfoList && m.userInfoList.length))
                        m.userInfoList = [];
                    m.userInfoList.push($root.Proto.TicketVariationScheduleUserInfo.decode(r, r.uint32()));
                    break;
                case 12:
                    if (!(m.userList && m.userList.length))
                        m.userList = [];
                    m.userList.push($root.Proto.TicketVariationScheduleDateUser.decode(r, r.uint32()));
                    break;
                case 13:
                    m.timezoneId = r.string();
                    break;
                case 14:
                    m.timezoneOffset = r.int32();
                    break;
                case 15:
                    m.recurStartDate = r.string();
                    break;
                case 16:
                    m.recurStartTime = r.string();
                    break;
                case 17:
                    m.recurEndDate = r.string();
                    break;
                case 18:
                    m.recurType = r.int32();
                    break;
                case 19:
                    m.duration = r.int32();
                    break;
                case 20:
                    m.recurDay = r.string();
                    break;
                case 21:
                    m.recurWeekday = r.string();
                    break;
                case 22:
                    m.recurWeek = r.string();
                    break;
                case 23:
                    m.recurInterval = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketVariationSchedule message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketVariationSchedule
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketVariationSchedule} TicketVariationSchedule
         */
        TicketVariationSchedule.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketVariationSchedule)
                return d;
            var m = new $root.Proto.TicketVariationSchedule();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.variationUID != null) {
                m.variationUID = String(d.variationUID);
            }
            if (d.dateInterval != null) {
                m.dateInterval = d.dateInterval | 0;
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.dateList) {
                if (!Array.isArray(d.dateList))
                    throw TypeError(".Proto.TicketVariationSchedule.dateList: array expected");
                m.dateList = [];
                for (var i = 0; i < d.dateList.length; ++i) {
                    if (typeof d.dateList[i] !== "object")
                        throw TypeError(".Proto.TicketVariationSchedule.dateList: object expected");
                    m.dateList[i] = $root.Proto.TicketVariationScheduleDate.fromObject(d.dateList[i]);
                }
            }
            if (d.userInfoList) {
                if (!Array.isArray(d.userInfoList))
                    throw TypeError(".Proto.TicketVariationSchedule.userInfoList: array expected");
                m.userInfoList = [];
                for (var i = 0; i < d.userInfoList.length; ++i) {
                    if (typeof d.userInfoList[i] !== "object")
                        throw TypeError(".Proto.TicketVariationSchedule.userInfoList: object expected");
                    m.userInfoList[i] = $root.Proto.TicketVariationScheduleUserInfo.fromObject(d.userInfoList[i]);
                }
            }
            if (d.userList) {
                if (!Array.isArray(d.userList))
                    throw TypeError(".Proto.TicketVariationSchedule.userList: array expected");
                m.userList = [];
                for (var i = 0; i < d.userList.length; ++i) {
                    if (typeof d.userList[i] !== "object")
                        throw TypeError(".Proto.TicketVariationSchedule.userList: object expected");
                    m.userList[i] = $root.Proto.TicketVariationScheduleDateUser.fromObject(d.userList[i]);
                }
            }
            if (d.timezoneId != null) {
                m.timezoneId = String(d.timezoneId);
            }
            if (d.timezoneOffset != null) {
                m.timezoneOffset = d.timezoneOffset | 0;
            }
            if (d.recurStartDate != null) {
                m.recurStartDate = String(d.recurStartDate);
            }
            if (d.recurStartTime != null) {
                m.recurStartTime = String(d.recurStartTime);
            }
            if (d.recurEndDate != null) {
                m.recurEndDate = String(d.recurEndDate);
            }
            if (d.recurType != null) {
                m.recurType = d.recurType | 0;
            }
            if (d.duration != null) {
                m.duration = d.duration | 0;
            }
            if (d.recurDay != null) {
                m.recurDay = String(d.recurDay);
            }
            if (d.recurWeekday != null) {
                m.recurWeekday = String(d.recurWeekday);
            }
            if (d.recurWeek != null) {
                m.recurWeek = String(d.recurWeek);
            }
            if (d.recurInterval != null) {
                m.recurInterval = d.recurInterval | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketVariationSchedule message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketVariationSchedule
         * @static
         * @param {Proto.TicketVariationSchedule} m TicketVariationSchedule
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketVariationSchedule.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.dateList = [];
                d.userInfoList = [];
                d.userList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.variationUID = "";
                d.dateInterval = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.timezoneId = "";
                d.timezoneOffset = 0;
                d.recurStartDate = "";
                d.recurStartTime = "";
                d.recurEndDate = "";
                d.recurType = 0;
                d.duration = 0;
                d.recurDay = "";
                d.recurWeekday = "";
                d.recurWeek = "";
                d.recurInterval = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.variationUID != null && m.hasOwnProperty("variationUID")) {
                d.variationUID = m.variationUID;
            }
            if (m.dateInterval != null && m.hasOwnProperty("dateInterval")) {
                d.dateInterval = m.dateInterval;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.dateList && m.dateList.length) {
                d.dateList = [];
                for (var j = 0; j < m.dateList.length; ++j) {
                    d.dateList[j] = $root.Proto.TicketVariationScheduleDate.toObject(m.dateList[j], o);
                }
            }
            if (m.userInfoList && m.userInfoList.length) {
                d.userInfoList = [];
                for (var j = 0; j < m.userInfoList.length; ++j) {
                    d.userInfoList[j] = $root.Proto.TicketVariationScheduleUserInfo.toObject(m.userInfoList[j], o);
                }
            }
            if (m.userList && m.userList.length) {
                d.userList = [];
                for (var j = 0; j < m.userList.length; ++j) {
                    d.userList[j] = $root.Proto.TicketVariationScheduleDateUser.toObject(m.userList[j], o);
                }
            }
            if (m.timezoneId != null && m.hasOwnProperty("timezoneId")) {
                d.timezoneId = m.timezoneId;
            }
            if (m.timezoneOffset != null && m.hasOwnProperty("timezoneOffset")) {
                d.timezoneOffset = m.timezoneOffset;
            }
            if (m.recurStartDate != null && m.hasOwnProperty("recurStartDate")) {
                d.recurStartDate = m.recurStartDate;
            }
            if (m.recurStartTime != null && m.hasOwnProperty("recurStartTime")) {
                d.recurStartTime = m.recurStartTime;
            }
            if (m.recurEndDate != null && m.hasOwnProperty("recurEndDate")) {
                d.recurEndDate = m.recurEndDate;
            }
            if (m.recurType != null && m.hasOwnProperty("recurType")) {
                d.recurType = m.recurType;
            }
            if (m.duration != null && m.hasOwnProperty("duration")) {
                d.duration = m.duration;
            }
            if (m.recurDay != null && m.hasOwnProperty("recurDay")) {
                d.recurDay = m.recurDay;
            }
            if (m.recurWeekday != null && m.hasOwnProperty("recurWeekday")) {
                d.recurWeekday = m.recurWeekday;
            }
            if (m.recurWeek != null && m.hasOwnProperty("recurWeek")) {
                d.recurWeek = m.recurWeek;
            }
            if (m.recurInterval != null && m.hasOwnProperty("recurInterval")) {
                d.recurInterval = m.recurInterval;
            }
            return d;
        };

        /**
         * Converts this TicketVariationSchedule to JSON.
         * @function toJSON
         * @memberof Proto.TicketVariationSchedule
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketVariationSchedule.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketVariationSchedule;
    })();

    Proto.TicketVariationScheduleList = (function() {

        /**
         * Properties of a TicketVariationScheduleList.
         * @memberof Proto
         * @interface ITicketVariationScheduleList
         * @property {Array.<Proto.ITicketVariationSchedule>|null} [value] TicketVariationScheduleList value
         */

        /**
         * Constructs a new TicketVariationScheduleList.
         * @memberof Proto
         * @classdesc Represents a TicketVariationScheduleList.
         * @implements ITicketVariationScheduleList
         * @constructor
         * @param {Proto.ITicketVariationScheduleList=} [p] Properties to set
         */
        function TicketVariationScheduleList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketVariationScheduleList value.
         * @member {Array.<Proto.ITicketVariationSchedule>} value
         * @memberof Proto.TicketVariationScheduleList
         * @instance
         */
        TicketVariationScheduleList.prototype.value = $util.emptyArray;

        /**
         * Creates a new TicketVariationScheduleList instance using the specified properties.
         * @function create
         * @memberof Proto.TicketVariationScheduleList
         * @static
         * @param {Proto.ITicketVariationScheduleList=} [properties] Properties to set
         * @returns {Proto.TicketVariationScheduleList} TicketVariationScheduleList instance
         */
        TicketVariationScheduleList.create = function create(properties) {
            return new TicketVariationScheduleList(properties);
        };

        /**
         * Encodes the specified TicketVariationScheduleList message. Does not implicitly {@link Proto.TicketVariationScheduleList.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketVariationScheduleList
         * @static
         * @param {Proto.ITicketVariationScheduleList} m TicketVariationScheduleList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketVariationScheduleList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.TicketVariationSchedule.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a TicketVariationScheduleList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketVariationScheduleList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketVariationScheduleList} TicketVariationScheduleList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketVariationScheduleList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketVariationScheduleList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.TicketVariationSchedule.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketVariationScheduleList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketVariationScheduleList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketVariationScheduleList} TicketVariationScheduleList
         */
        TicketVariationScheduleList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketVariationScheduleList)
                return d;
            var m = new $root.Proto.TicketVariationScheduleList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.TicketVariationScheduleList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.TicketVariationScheduleList.value: object expected");
                    m.value[i] = $root.Proto.TicketVariationSchedule.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketVariationScheduleList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketVariationScheduleList
         * @static
         * @param {Proto.TicketVariationScheduleList} m TicketVariationScheduleList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketVariationScheduleList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.TicketVariationSchedule.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this TicketVariationScheduleList to JSON.
         * @function toJSON
         * @memberof Proto.TicketVariationScheduleList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketVariationScheduleList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketVariationScheduleList;
    })();

    Proto.TicketVariationScheduleException = (function() {

        /**
         * Properties of a TicketVariationScheduleException.
         * @memberof Proto
         * @interface ITicketVariationScheduleException
         * @property {string|null} [uid] TicketVariationScheduleException uid
         * @property {string|null} [variationUID] TicketVariationScheduleException variationUID
         * @property {number|Long|null} [startDate] TicketVariationScheduleException startDate
         * @property {number|Long|null} [endDate] TicketVariationScheduleException endDate
         * @property {string|null} [timezoneId] TicketVariationScheduleException timezoneId
         * @property {number|null} [timezoneOffset] TicketVariationScheduleException timezoneOffset
         * @property {string|null} [recurStartDate] TicketVariationScheduleException recurStartDate
         * @property {string|null} [recurStartTime] TicketVariationScheduleException recurStartTime
         * @property {string|null} [recurEndDate] TicketVariationScheduleException recurEndDate
         * @property {number|null} [recurType] TicketVariationScheduleException recurType
         * @property {number|null} [duration] TicketVariationScheduleException duration
         * @property {string|null} [recurDay] TicketVariationScheduleException recurDay
         * @property {string|null} [recurWeekday] TicketVariationScheduleException recurWeekday
         * @property {string|null} [recurWeek] TicketVariationScheduleException recurWeek
         * @property {number|null} [recurInterval] TicketVariationScheduleException recurInterval
         * @property {number|Long|null} [registerDate] TicketVariationScheduleException registerDate
         * @property {number|Long|null} [modifyDate] TicketVariationScheduleException modifyDate
         * @property {number|null} [status] TicketVariationScheduleException status
         */

        /**
         * Constructs a new TicketVariationScheduleException.
         * @memberof Proto
         * @classdesc Represents a TicketVariationScheduleException.
         * @implements ITicketVariationScheduleException
         * @constructor
         * @param {Proto.ITicketVariationScheduleException=} [p] Properties to set
         */
        function TicketVariationScheduleException(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketVariationScheduleException uid.
         * @member {string} uid
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.uid = "";

        /**
         * TicketVariationScheduleException variationUID.
         * @member {string} variationUID
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.variationUID = "";

        /**
         * TicketVariationScheduleException startDate.
         * @member {number|Long} startDate
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleException endDate.
         * @member {number|Long} endDate
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleException timezoneId.
         * @member {string} timezoneId
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.timezoneId = "";

        /**
         * TicketVariationScheduleException timezoneOffset.
         * @member {number} timezoneOffset
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.timezoneOffset = 0;

        /**
         * TicketVariationScheduleException recurStartDate.
         * @member {string} recurStartDate
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.recurStartDate = "";

        /**
         * TicketVariationScheduleException recurStartTime.
         * @member {string} recurStartTime
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.recurStartTime = "";

        /**
         * TicketVariationScheduleException recurEndDate.
         * @member {string} recurEndDate
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.recurEndDate = "";

        /**
         * TicketVariationScheduleException recurType.
         * @member {number} recurType
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.recurType = 0;

        /**
         * TicketVariationScheduleException duration.
         * @member {number} duration
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.duration = 0;

        /**
         * TicketVariationScheduleException recurDay.
         * @member {string} recurDay
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.recurDay = "";

        /**
         * TicketVariationScheduleException recurWeekday.
         * @member {string} recurWeekday
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.recurWeekday = "";

        /**
         * TicketVariationScheduleException recurWeek.
         * @member {string} recurWeek
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.recurWeek = "";

        /**
         * TicketVariationScheduleException recurInterval.
         * @member {number} recurInterval
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.recurInterval = 0;

        /**
         * TicketVariationScheduleException registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleException modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleException status.
         * @member {number} status
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         */
        TicketVariationScheduleException.prototype.status = 0;

        /**
         * Creates a new TicketVariationScheduleException instance using the specified properties.
         * @function create
         * @memberof Proto.TicketVariationScheduleException
         * @static
         * @param {Proto.ITicketVariationScheduleException=} [properties] Properties to set
         * @returns {Proto.TicketVariationScheduleException} TicketVariationScheduleException instance
         */
        TicketVariationScheduleException.create = function create(properties) {
            return new TicketVariationScheduleException(properties);
        };

        /**
         * Encodes the specified TicketVariationScheduleException message. Does not implicitly {@link Proto.TicketVariationScheduleException.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketVariationScheduleException
         * @static
         * @param {Proto.ITicketVariationScheduleException} m TicketVariationScheduleException message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketVariationScheduleException.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.variationUID != null && Object.hasOwnProperty.call(m, "variationUID"))
                w.uint32(18).string(m.variationUID);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(24).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(32).int64(m.endDate);
            if (m.timezoneId != null && Object.hasOwnProperty.call(m, "timezoneId"))
                w.uint32(42).string(m.timezoneId);
            if (m.timezoneOffset != null && Object.hasOwnProperty.call(m, "timezoneOffset"))
                w.uint32(48).int32(m.timezoneOffset);
            if (m.recurStartDate != null && Object.hasOwnProperty.call(m, "recurStartDate"))
                w.uint32(58).string(m.recurStartDate);
            if (m.recurStartTime != null && Object.hasOwnProperty.call(m, "recurStartTime"))
                w.uint32(66).string(m.recurStartTime);
            if (m.recurEndDate != null && Object.hasOwnProperty.call(m, "recurEndDate"))
                w.uint32(74).string(m.recurEndDate);
            if (m.recurType != null && Object.hasOwnProperty.call(m, "recurType"))
                w.uint32(80).int32(m.recurType);
            if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                w.uint32(88).int32(m.duration);
            if (m.recurDay != null && Object.hasOwnProperty.call(m, "recurDay"))
                w.uint32(98).string(m.recurDay);
            if (m.recurWeekday != null && Object.hasOwnProperty.call(m, "recurWeekday"))
                w.uint32(106).string(m.recurWeekday);
            if (m.recurWeek != null && Object.hasOwnProperty.call(m, "recurWeek"))
                w.uint32(114).string(m.recurWeek);
            if (m.recurInterval != null && Object.hasOwnProperty.call(m, "recurInterval"))
                w.uint32(120).int32(m.recurInterval);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(128).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(136).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(144).int32(m.status);
            return w;
        };

        /**
         * Decodes a TicketVariationScheduleException message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketVariationScheduleException
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketVariationScheduleException} TicketVariationScheduleException
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketVariationScheduleException.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketVariationScheduleException();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.variationUID = r.string();
                    break;
                case 3:
                    m.startDate = r.int64();
                    break;
                case 4:
                    m.endDate = r.int64();
                    break;
                case 5:
                    m.timezoneId = r.string();
                    break;
                case 6:
                    m.timezoneOffset = r.int32();
                    break;
                case 7:
                    m.recurStartDate = r.string();
                    break;
                case 8:
                    m.recurStartTime = r.string();
                    break;
                case 9:
                    m.recurEndDate = r.string();
                    break;
                case 10:
                    m.recurType = r.int32();
                    break;
                case 11:
                    m.duration = r.int32();
                    break;
                case 12:
                    m.recurDay = r.string();
                    break;
                case 13:
                    m.recurWeekday = r.string();
                    break;
                case 14:
                    m.recurWeek = r.string();
                    break;
                case 15:
                    m.recurInterval = r.int32();
                    break;
                case 16:
                    m.registerDate = r.int64();
                    break;
                case 17:
                    m.modifyDate = r.int64();
                    break;
                case 18:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketVariationScheduleException message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketVariationScheduleException
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketVariationScheduleException} TicketVariationScheduleException
         */
        TicketVariationScheduleException.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketVariationScheduleException)
                return d;
            var m = new $root.Proto.TicketVariationScheduleException();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.variationUID != null) {
                m.variationUID = String(d.variationUID);
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.timezoneId != null) {
                m.timezoneId = String(d.timezoneId);
            }
            if (d.timezoneOffset != null) {
                m.timezoneOffset = d.timezoneOffset | 0;
            }
            if (d.recurStartDate != null) {
                m.recurStartDate = String(d.recurStartDate);
            }
            if (d.recurStartTime != null) {
                m.recurStartTime = String(d.recurStartTime);
            }
            if (d.recurEndDate != null) {
                m.recurEndDate = String(d.recurEndDate);
            }
            if (d.recurType != null) {
                m.recurType = d.recurType | 0;
            }
            if (d.duration != null) {
                m.duration = d.duration | 0;
            }
            if (d.recurDay != null) {
                m.recurDay = String(d.recurDay);
            }
            if (d.recurWeekday != null) {
                m.recurWeekday = String(d.recurWeekday);
            }
            if (d.recurWeek != null) {
                m.recurWeek = String(d.recurWeek);
            }
            if (d.recurInterval != null) {
                m.recurInterval = d.recurInterval | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketVariationScheduleException message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketVariationScheduleException
         * @static
         * @param {Proto.TicketVariationScheduleException} m TicketVariationScheduleException
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketVariationScheduleException.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.variationUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                d.timezoneId = "";
                d.timezoneOffset = 0;
                d.recurStartDate = "";
                d.recurStartTime = "";
                d.recurEndDate = "";
                d.recurType = 0;
                d.duration = 0;
                d.recurDay = "";
                d.recurWeekday = "";
                d.recurWeek = "";
                d.recurInterval = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.variationUID != null && m.hasOwnProperty("variationUID")) {
                d.variationUID = m.variationUID;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.timezoneId != null && m.hasOwnProperty("timezoneId")) {
                d.timezoneId = m.timezoneId;
            }
            if (m.timezoneOffset != null && m.hasOwnProperty("timezoneOffset")) {
                d.timezoneOffset = m.timezoneOffset;
            }
            if (m.recurStartDate != null && m.hasOwnProperty("recurStartDate")) {
                d.recurStartDate = m.recurStartDate;
            }
            if (m.recurStartTime != null && m.hasOwnProperty("recurStartTime")) {
                d.recurStartTime = m.recurStartTime;
            }
            if (m.recurEndDate != null && m.hasOwnProperty("recurEndDate")) {
                d.recurEndDate = m.recurEndDate;
            }
            if (m.recurType != null && m.hasOwnProperty("recurType")) {
                d.recurType = m.recurType;
            }
            if (m.duration != null && m.hasOwnProperty("duration")) {
                d.duration = m.duration;
            }
            if (m.recurDay != null && m.hasOwnProperty("recurDay")) {
                d.recurDay = m.recurDay;
            }
            if (m.recurWeekday != null && m.hasOwnProperty("recurWeekday")) {
                d.recurWeekday = m.recurWeekday;
            }
            if (m.recurWeek != null && m.hasOwnProperty("recurWeek")) {
                d.recurWeek = m.recurWeek;
            }
            if (m.recurInterval != null && m.hasOwnProperty("recurInterval")) {
                d.recurInterval = m.recurInterval;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this TicketVariationScheduleException to JSON.
         * @function toJSON
         * @memberof Proto.TicketVariationScheduleException
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketVariationScheduleException.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketVariationScheduleException;
    })();

    Proto.TicketVariationScheduleExceptionList = (function() {

        /**
         * Properties of a TicketVariationScheduleExceptionList.
         * @memberof Proto
         * @interface ITicketVariationScheduleExceptionList
         * @property {Array.<Proto.ITicketVariationScheduleException>|null} [value] TicketVariationScheduleExceptionList value
         */

        /**
         * Constructs a new TicketVariationScheduleExceptionList.
         * @memberof Proto
         * @classdesc Represents a TicketVariationScheduleExceptionList.
         * @implements ITicketVariationScheduleExceptionList
         * @constructor
         * @param {Proto.ITicketVariationScheduleExceptionList=} [p] Properties to set
         */
        function TicketVariationScheduleExceptionList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketVariationScheduleExceptionList value.
         * @member {Array.<Proto.ITicketVariationScheduleException>} value
         * @memberof Proto.TicketVariationScheduleExceptionList
         * @instance
         */
        TicketVariationScheduleExceptionList.prototype.value = $util.emptyArray;

        /**
         * Creates a new TicketVariationScheduleExceptionList instance using the specified properties.
         * @function create
         * @memberof Proto.TicketVariationScheduleExceptionList
         * @static
         * @param {Proto.ITicketVariationScheduleExceptionList=} [properties] Properties to set
         * @returns {Proto.TicketVariationScheduleExceptionList} TicketVariationScheduleExceptionList instance
         */
        TicketVariationScheduleExceptionList.create = function create(properties) {
            return new TicketVariationScheduleExceptionList(properties);
        };

        /**
         * Encodes the specified TicketVariationScheduleExceptionList message. Does not implicitly {@link Proto.TicketVariationScheduleExceptionList.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketVariationScheduleExceptionList
         * @static
         * @param {Proto.ITicketVariationScheduleExceptionList} m TicketVariationScheduleExceptionList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketVariationScheduleExceptionList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.TicketVariationScheduleException.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a TicketVariationScheduleExceptionList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketVariationScheduleExceptionList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketVariationScheduleExceptionList} TicketVariationScheduleExceptionList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketVariationScheduleExceptionList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketVariationScheduleExceptionList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.TicketVariationScheduleException.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketVariationScheduleExceptionList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketVariationScheduleExceptionList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketVariationScheduleExceptionList} TicketVariationScheduleExceptionList
         */
        TicketVariationScheduleExceptionList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketVariationScheduleExceptionList)
                return d;
            var m = new $root.Proto.TicketVariationScheduleExceptionList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.TicketVariationScheduleExceptionList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.TicketVariationScheduleExceptionList.value: object expected");
                    m.value[i] = $root.Proto.TicketVariationScheduleException.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketVariationScheduleExceptionList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketVariationScheduleExceptionList
         * @static
         * @param {Proto.TicketVariationScheduleExceptionList} m TicketVariationScheduleExceptionList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketVariationScheduleExceptionList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.TicketVariationScheduleException.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this TicketVariationScheduleExceptionList to JSON.
         * @function toJSON
         * @memberof Proto.TicketVariationScheduleExceptionList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketVariationScheduleExceptionList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketVariationScheduleExceptionList;
    })();

    Proto.TicketVariationScheduleDate = (function() {

        /**
         * Properties of a TicketVariationScheduleDate.
         * @memberof Proto
         * @interface ITicketVariationScheduleDate
         * @property {string|null} [uid] TicketVariationScheduleDate uid
         * @property {string|null} [scheduleUID] TicketVariationScheduleDate scheduleUID
         * @property {number|Long|null} [startDate] TicketVariationScheduleDate startDate
         * @property {number|Long|null} [endDate] TicketVariationScheduleDate endDate
         * @property {number|null} [quantity] TicketVariationScheduleDate quantity
         * @property {number|Long|null} [registerDate] TicketVariationScheduleDate registerDate
         * @property {number|Long|null} [modifyDate] TicketVariationScheduleDate modifyDate
         * @property {number|null} [status] TicketVariationScheduleDate status
         * @property {Proto.ICommerceOrderCount|null} [orderCount] TicketVariationScheduleDate orderCount
         */

        /**
         * Constructs a new TicketVariationScheduleDate.
         * @memberof Proto
         * @classdesc Represents a TicketVariationScheduleDate.
         * @implements ITicketVariationScheduleDate
         * @constructor
         * @param {Proto.ITicketVariationScheduleDate=} [p] Properties to set
         */
        function TicketVariationScheduleDate(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketVariationScheduleDate uid.
         * @member {string} uid
         * @memberof Proto.TicketVariationScheduleDate
         * @instance
         */
        TicketVariationScheduleDate.prototype.uid = "";

        /**
         * TicketVariationScheduleDate scheduleUID.
         * @member {string} scheduleUID
         * @memberof Proto.TicketVariationScheduleDate
         * @instance
         */
        TicketVariationScheduleDate.prototype.scheduleUID = "";

        /**
         * TicketVariationScheduleDate startDate.
         * @member {number|Long} startDate
         * @memberof Proto.TicketVariationScheduleDate
         * @instance
         */
        TicketVariationScheduleDate.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleDate endDate.
         * @member {number|Long} endDate
         * @memberof Proto.TicketVariationScheduleDate
         * @instance
         */
        TicketVariationScheduleDate.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleDate quantity.
         * @member {number} quantity
         * @memberof Proto.TicketVariationScheduleDate
         * @instance
         */
        TicketVariationScheduleDate.prototype.quantity = 0;

        /**
         * TicketVariationScheduleDate registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.TicketVariationScheduleDate
         * @instance
         */
        TicketVariationScheduleDate.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleDate modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.TicketVariationScheduleDate
         * @instance
         */
        TicketVariationScheduleDate.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleDate status.
         * @member {number} status
         * @memberof Proto.TicketVariationScheduleDate
         * @instance
         */
        TicketVariationScheduleDate.prototype.status = 0;

        /**
         * TicketVariationScheduleDate orderCount.
         * @member {Proto.ICommerceOrderCount|null|undefined} orderCount
         * @memberof Proto.TicketVariationScheduleDate
         * @instance
         */
        TicketVariationScheduleDate.prototype.orderCount = null;

        /**
         * Creates a new TicketVariationScheduleDate instance using the specified properties.
         * @function create
         * @memberof Proto.TicketVariationScheduleDate
         * @static
         * @param {Proto.ITicketVariationScheduleDate=} [properties] Properties to set
         * @returns {Proto.TicketVariationScheduleDate} TicketVariationScheduleDate instance
         */
        TicketVariationScheduleDate.create = function create(properties) {
            return new TicketVariationScheduleDate(properties);
        };

        /**
         * Encodes the specified TicketVariationScheduleDate message. Does not implicitly {@link Proto.TicketVariationScheduleDate.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketVariationScheduleDate
         * @static
         * @param {Proto.ITicketVariationScheduleDate} m TicketVariationScheduleDate message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketVariationScheduleDate.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.scheduleUID != null && Object.hasOwnProperty.call(m, "scheduleUID"))
                w.uint32(18).string(m.scheduleUID);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(24).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(32).int64(m.endDate);
            if (m.quantity != null && Object.hasOwnProperty.call(m, "quantity"))
                w.uint32(40).int32(m.quantity);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(64).int32(m.status);
            if (m.orderCount != null && Object.hasOwnProperty.call(m, "orderCount"))
                $root.Proto.CommerceOrderCount.encode(m.orderCount, w.uint32(74).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a TicketVariationScheduleDate message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketVariationScheduleDate
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketVariationScheduleDate} TicketVariationScheduleDate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketVariationScheduleDate.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketVariationScheduleDate();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.scheduleUID = r.string();
                    break;
                case 3:
                    m.startDate = r.int64();
                    break;
                case 4:
                    m.endDate = r.int64();
                    break;
                case 5:
                    m.quantity = r.int32();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.status = r.int32();
                    break;
                case 9:
                    m.orderCount = $root.Proto.CommerceOrderCount.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketVariationScheduleDate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketVariationScheduleDate
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketVariationScheduleDate} TicketVariationScheduleDate
         */
        TicketVariationScheduleDate.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketVariationScheduleDate)
                return d;
            var m = new $root.Proto.TicketVariationScheduleDate();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.scheduleUID != null) {
                m.scheduleUID = String(d.scheduleUID);
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.quantity != null) {
                m.quantity = d.quantity | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.orderCount != null) {
                if (typeof d.orderCount !== "object")
                    throw TypeError(".Proto.TicketVariationScheduleDate.orderCount: object expected");
                m.orderCount = $root.Proto.CommerceOrderCount.fromObject(d.orderCount);
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketVariationScheduleDate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketVariationScheduleDate
         * @static
         * @param {Proto.TicketVariationScheduleDate} m TicketVariationScheduleDate
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketVariationScheduleDate.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.scheduleUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                d.quantity = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.orderCount = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.scheduleUID != null && m.hasOwnProperty("scheduleUID")) {
                d.scheduleUID = m.scheduleUID;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.quantity != null && m.hasOwnProperty("quantity")) {
                d.quantity = m.quantity;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.orderCount != null && m.hasOwnProperty("orderCount")) {
                d.orderCount = $root.Proto.CommerceOrderCount.toObject(m.orderCount, o);
            }
            return d;
        };

        /**
         * Converts this TicketVariationScheduleDate to JSON.
         * @function toJSON
         * @memberof Proto.TicketVariationScheduleDate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketVariationScheduleDate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketVariationScheduleDate;
    })();

    Proto.TicketVariationScheduleUserInfo = (function() {

        /**
         * Properties of a TicketVariationScheduleUserInfo.
         * @memberof Proto
         * @interface ITicketVariationScheduleUserInfo
         * @property {string|null} [uid] TicketVariationScheduleUserInfo uid
         * @property {string|null} [scheduleUID] TicketVariationScheduleUserInfo scheduleUID
         * @property {number|null} [type] TicketVariationScheduleUserInfo type
         * @property {string|null} [name] TicketVariationScheduleUserInfo name
         * @property {boolean|null} [optional] TicketVariationScheduleUserInfo optional
         * @property {string|null} [setting] TicketVariationScheduleUserInfo setting
         * @property {number|null} [order] TicketVariationScheduleUserInfo order
         * @property {number|Long|null} [registerDate] TicketVariationScheduleUserInfo registerDate
         * @property {number|Long|null} [modifyDate] TicketVariationScheduleUserInfo modifyDate
         * @property {number|null} [status] TicketVariationScheduleUserInfo status
         * @property {string|null} [variationUID] TicketVariationScheduleUserInfo variationUID
         */

        /**
         * Constructs a new TicketVariationScheduleUserInfo.
         * @memberof Proto
         * @classdesc Represents a TicketVariationScheduleUserInfo.
         * @implements ITicketVariationScheduleUserInfo
         * @constructor
         * @param {Proto.ITicketVariationScheduleUserInfo=} [p] Properties to set
         */
        function TicketVariationScheduleUserInfo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketVariationScheduleUserInfo uid.
         * @member {string} uid
         * @memberof Proto.TicketVariationScheduleUserInfo
         * @instance
         */
        TicketVariationScheduleUserInfo.prototype.uid = "";

        /**
         * TicketVariationScheduleUserInfo scheduleUID.
         * @member {string} scheduleUID
         * @memberof Proto.TicketVariationScheduleUserInfo
         * @instance
         */
        TicketVariationScheduleUserInfo.prototype.scheduleUID = "";

        /**
         * TicketVariationScheduleUserInfo type.
         * @member {number} type
         * @memberof Proto.TicketVariationScheduleUserInfo
         * @instance
         */
        TicketVariationScheduleUserInfo.prototype.type = 0;

        /**
         * TicketVariationScheduleUserInfo name.
         * @member {string} name
         * @memberof Proto.TicketVariationScheduleUserInfo
         * @instance
         */
        TicketVariationScheduleUserInfo.prototype.name = "";

        /**
         * TicketVariationScheduleUserInfo optional.
         * @member {boolean} optional
         * @memberof Proto.TicketVariationScheduleUserInfo
         * @instance
         */
        TicketVariationScheduleUserInfo.prototype.optional = false;

        /**
         * TicketVariationScheduleUserInfo setting.
         * @member {string} setting
         * @memberof Proto.TicketVariationScheduleUserInfo
         * @instance
         */
        TicketVariationScheduleUserInfo.prototype.setting = "";

        /**
         * TicketVariationScheduleUserInfo order.
         * @member {number} order
         * @memberof Proto.TicketVariationScheduleUserInfo
         * @instance
         */
        TicketVariationScheduleUserInfo.prototype.order = 0;

        /**
         * TicketVariationScheduleUserInfo registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.TicketVariationScheduleUserInfo
         * @instance
         */
        TicketVariationScheduleUserInfo.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleUserInfo modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.TicketVariationScheduleUserInfo
         * @instance
         */
        TicketVariationScheduleUserInfo.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleUserInfo status.
         * @member {number} status
         * @memberof Proto.TicketVariationScheduleUserInfo
         * @instance
         */
        TicketVariationScheduleUserInfo.prototype.status = 0;

        /**
         * TicketVariationScheduleUserInfo variationUID.
         * @member {string} variationUID
         * @memberof Proto.TicketVariationScheduleUserInfo
         * @instance
         */
        TicketVariationScheduleUserInfo.prototype.variationUID = "";

        /**
         * Creates a new TicketVariationScheduleUserInfo instance using the specified properties.
         * @function create
         * @memberof Proto.TicketVariationScheduleUserInfo
         * @static
         * @param {Proto.ITicketVariationScheduleUserInfo=} [properties] Properties to set
         * @returns {Proto.TicketVariationScheduleUserInfo} TicketVariationScheduleUserInfo instance
         */
        TicketVariationScheduleUserInfo.create = function create(properties) {
            return new TicketVariationScheduleUserInfo(properties);
        };

        /**
         * Encodes the specified TicketVariationScheduleUserInfo message. Does not implicitly {@link Proto.TicketVariationScheduleUserInfo.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketVariationScheduleUserInfo
         * @static
         * @param {Proto.ITicketVariationScheduleUserInfo} m TicketVariationScheduleUserInfo message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketVariationScheduleUserInfo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.scheduleUID != null && Object.hasOwnProperty.call(m, "scheduleUID"))
                w.uint32(18).string(m.scheduleUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(24).int32(m.type);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(34).string(m.name);
            if (m.optional != null && Object.hasOwnProperty.call(m, "optional"))
                w.uint32(40).bool(m.optional);
            if (m.setting != null && Object.hasOwnProperty.call(m, "setting"))
                w.uint32(50).string(m.setting);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(56).int32(m.order);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(80).int32(m.status);
            if (m.variationUID != null && Object.hasOwnProperty.call(m, "variationUID"))
                w.uint32(90).string(m.variationUID);
            return w;
        };

        /**
         * Decodes a TicketVariationScheduleUserInfo message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketVariationScheduleUserInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketVariationScheduleUserInfo} TicketVariationScheduleUserInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketVariationScheduleUserInfo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketVariationScheduleUserInfo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.scheduleUID = r.string();
                    break;
                case 3:
                    m.type = r.int32();
                    break;
                case 4:
                    m.name = r.string();
                    break;
                case 5:
                    m.optional = r.bool();
                    break;
                case 6:
                    m.setting = r.string();
                    break;
                case 7:
                    m.order = r.int32();
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                case 10:
                    m.status = r.int32();
                    break;
                case 11:
                    m.variationUID = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketVariationScheduleUserInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketVariationScheduleUserInfo
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketVariationScheduleUserInfo} TicketVariationScheduleUserInfo
         */
        TicketVariationScheduleUserInfo.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketVariationScheduleUserInfo)
                return d;
            var m = new $root.Proto.TicketVariationScheduleUserInfo();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.scheduleUID != null) {
                m.scheduleUID = String(d.scheduleUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.optional != null) {
                m.optional = Boolean(d.optional);
            }
            if (d.setting != null) {
                m.setting = String(d.setting);
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.variationUID != null) {
                m.variationUID = String(d.variationUID);
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketVariationScheduleUserInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketVariationScheduleUserInfo
         * @static
         * @param {Proto.TicketVariationScheduleUserInfo} m TicketVariationScheduleUserInfo
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketVariationScheduleUserInfo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.scheduleUID = "";
                d.type = 0;
                d.name = "";
                d.optional = false;
                d.setting = "";
                d.order = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.variationUID = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.scheduleUID != null && m.hasOwnProperty("scheduleUID")) {
                d.scheduleUID = m.scheduleUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.optional != null && m.hasOwnProperty("optional")) {
                d.optional = m.optional;
            }
            if (m.setting != null && m.hasOwnProperty("setting")) {
                d.setting = m.setting;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.variationUID != null && m.hasOwnProperty("variationUID")) {
                d.variationUID = m.variationUID;
            }
            return d;
        };

        /**
         * Converts this TicketVariationScheduleUserInfo to JSON.
         * @function toJSON
         * @memberof Proto.TicketVariationScheduleUserInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketVariationScheduleUserInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketVariationScheduleUserInfo;
    })();

    Proto.TicketVariationScheduleUserInfoList = (function() {

        /**
         * Properties of a TicketVariationScheduleUserInfoList.
         * @memberof Proto
         * @interface ITicketVariationScheduleUserInfoList
         * @property {Array.<Proto.ITicketVariationScheduleUserInfo>|null} [value] TicketVariationScheduleUserInfoList value
         */

        /**
         * Constructs a new TicketVariationScheduleUserInfoList.
         * @memberof Proto
         * @classdesc Represents a TicketVariationScheduleUserInfoList.
         * @implements ITicketVariationScheduleUserInfoList
         * @constructor
         * @param {Proto.ITicketVariationScheduleUserInfoList=} [p] Properties to set
         */
        function TicketVariationScheduleUserInfoList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketVariationScheduleUserInfoList value.
         * @member {Array.<Proto.ITicketVariationScheduleUserInfo>} value
         * @memberof Proto.TicketVariationScheduleUserInfoList
         * @instance
         */
        TicketVariationScheduleUserInfoList.prototype.value = $util.emptyArray;

        /**
         * Creates a new TicketVariationScheduleUserInfoList instance using the specified properties.
         * @function create
         * @memberof Proto.TicketVariationScheduleUserInfoList
         * @static
         * @param {Proto.ITicketVariationScheduleUserInfoList=} [properties] Properties to set
         * @returns {Proto.TicketVariationScheduleUserInfoList} TicketVariationScheduleUserInfoList instance
         */
        TicketVariationScheduleUserInfoList.create = function create(properties) {
            return new TicketVariationScheduleUserInfoList(properties);
        };

        /**
         * Encodes the specified TicketVariationScheduleUserInfoList message. Does not implicitly {@link Proto.TicketVariationScheduleUserInfoList.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketVariationScheduleUserInfoList
         * @static
         * @param {Proto.ITicketVariationScheduleUserInfoList} m TicketVariationScheduleUserInfoList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketVariationScheduleUserInfoList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.TicketVariationScheduleUserInfo.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a TicketVariationScheduleUserInfoList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketVariationScheduleUserInfoList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketVariationScheduleUserInfoList} TicketVariationScheduleUserInfoList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketVariationScheduleUserInfoList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketVariationScheduleUserInfoList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.TicketVariationScheduleUserInfo.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketVariationScheduleUserInfoList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketVariationScheduleUserInfoList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketVariationScheduleUserInfoList} TicketVariationScheduleUserInfoList
         */
        TicketVariationScheduleUserInfoList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketVariationScheduleUserInfoList)
                return d;
            var m = new $root.Proto.TicketVariationScheduleUserInfoList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.TicketVariationScheduleUserInfoList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.TicketVariationScheduleUserInfoList.value: object expected");
                    m.value[i] = $root.Proto.TicketVariationScheduleUserInfo.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketVariationScheduleUserInfoList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketVariationScheduleUserInfoList
         * @static
         * @param {Proto.TicketVariationScheduleUserInfoList} m TicketVariationScheduleUserInfoList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketVariationScheduleUserInfoList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.TicketVariationScheduleUserInfo.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this TicketVariationScheduleUserInfoList to JSON.
         * @function toJSON
         * @memberof Proto.TicketVariationScheduleUserInfoList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketVariationScheduleUserInfoList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketVariationScheduleUserInfoList;
    })();

    Proto.TicketVariationScheduleDateUserInfo = (function() {

        /**
         * Properties of a TicketVariationScheduleDateUserInfo.
         * @memberof Proto
         * @interface ITicketVariationScheduleDateUserInfo
         * @property {string|null} [userUID] TicketVariationScheduleDateUserInfo userUID
         * @property {string|null} [infoUID] TicketVariationScheduleDateUserInfo infoUID
         * @property {string|null} [text] TicketVariationScheduleDateUserInfo text
         * @property {number|null} [number] TicketVariationScheduleDateUserInfo number
         * @property {number|Long|null} [date] TicketVariationScheduleDateUserInfo date
         * @property {number|Long|null} [registerDate] TicketVariationScheduleDateUserInfo registerDate
         * @property {number|Long|null} [modifyDate] TicketVariationScheduleDateUserInfo modifyDate
         * @property {Proto.IUploadFile|null} [file] TicketVariationScheduleDateUserInfo file
         * @property {string|null} [additional] TicketVariationScheduleDateUserInfo additional
         * @property {boolean|null} [readonly] TicketVariationScheduleDateUserInfo readonly
         * @property {string|null} [json] TicketVariationScheduleDateUserInfo json
         */

        /**
         * Constructs a new TicketVariationScheduleDateUserInfo.
         * @memberof Proto
         * @classdesc Represents a TicketVariationScheduleDateUserInfo.
         * @implements ITicketVariationScheduleDateUserInfo
         * @constructor
         * @param {Proto.ITicketVariationScheduleDateUserInfo=} [p] Properties to set
         */
        function TicketVariationScheduleDateUserInfo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketVariationScheduleDateUserInfo userUID.
         * @member {string} userUID
         * @memberof Proto.TicketVariationScheduleDateUserInfo
         * @instance
         */
        TicketVariationScheduleDateUserInfo.prototype.userUID = "";

        /**
         * TicketVariationScheduleDateUserInfo infoUID.
         * @member {string} infoUID
         * @memberof Proto.TicketVariationScheduleDateUserInfo
         * @instance
         */
        TicketVariationScheduleDateUserInfo.prototype.infoUID = "";

        /**
         * TicketVariationScheduleDateUserInfo text.
         * @member {string} text
         * @memberof Proto.TicketVariationScheduleDateUserInfo
         * @instance
         */
        TicketVariationScheduleDateUserInfo.prototype.text = "";

        /**
         * TicketVariationScheduleDateUserInfo number.
         * @member {number} number
         * @memberof Proto.TicketVariationScheduleDateUserInfo
         * @instance
         */
        TicketVariationScheduleDateUserInfo.prototype.number = 0;

        /**
         * TicketVariationScheduleDateUserInfo date.
         * @member {number|Long} date
         * @memberof Proto.TicketVariationScheduleDateUserInfo
         * @instance
         */
        TicketVariationScheduleDateUserInfo.prototype.date = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleDateUserInfo registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.TicketVariationScheduleDateUserInfo
         * @instance
         */
        TicketVariationScheduleDateUserInfo.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleDateUserInfo modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.TicketVariationScheduleDateUserInfo
         * @instance
         */
        TicketVariationScheduleDateUserInfo.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleDateUserInfo file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.TicketVariationScheduleDateUserInfo
         * @instance
         */
        TicketVariationScheduleDateUserInfo.prototype.file = null;

        /**
         * TicketVariationScheduleDateUserInfo additional.
         * @member {string} additional
         * @memberof Proto.TicketVariationScheduleDateUserInfo
         * @instance
         */
        TicketVariationScheduleDateUserInfo.prototype.additional = "";

        /**
         * TicketVariationScheduleDateUserInfo readonly.
         * @member {boolean} readonly
         * @memberof Proto.TicketVariationScheduleDateUserInfo
         * @instance
         */
        TicketVariationScheduleDateUserInfo.prototype.readonly = false;

        /**
         * TicketVariationScheduleDateUserInfo json.
         * @member {string} json
         * @memberof Proto.TicketVariationScheduleDateUserInfo
         * @instance
         */
        TicketVariationScheduleDateUserInfo.prototype.json = "";

        /**
         * Creates a new TicketVariationScheduleDateUserInfo instance using the specified properties.
         * @function create
         * @memberof Proto.TicketVariationScheduleDateUserInfo
         * @static
         * @param {Proto.ITicketVariationScheduleDateUserInfo=} [properties] Properties to set
         * @returns {Proto.TicketVariationScheduleDateUserInfo} TicketVariationScheduleDateUserInfo instance
         */
        TicketVariationScheduleDateUserInfo.create = function create(properties) {
            return new TicketVariationScheduleDateUserInfo(properties);
        };

        /**
         * Encodes the specified TicketVariationScheduleDateUserInfo message. Does not implicitly {@link Proto.TicketVariationScheduleDateUserInfo.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketVariationScheduleDateUserInfo
         * @static
         * @param {Proto.ITicketVariationScheduleDateUserInfo} m TicketVariationScheduleDateUserInfo message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketVariationScheduleDateUserInfo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(10).string(m.userUID);
            if (m.infoUID != null && Object.hasOwnProperty.call(m, "infoUID"))
                w.uint32(18).string(m.infoUID);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.number != null && Object.hasOwnProperty.call(m, "number"))
                w.uint32(32).int32(m.number);
            if (m.date != null && Object.hasOwnProperty.call(m, "date"))
                w.uint32(40).int64(m.date);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(66).fork()).ldelim();
            if (m.additional != null && Object.hasOwnProperty.call(m, "additional"))
                w.uint32(74).string(m.additional);
            if (m.readonly != null && Object.hasOwnProperty.call(m, "readonly"))
                w.uint32(80).bool(m.readonly);
            if (m.json != null && Object.hasOwnProperty.call(m, "json"))
                w.uint32(90).string(m.json);
            return w;
        };

        /**
         * Decodes a TicketVariationScheduleDateUserInfo message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketVariationScheduleDateUserInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketVariationScheduleDateUserInfo} TicketVariationScheduleDateUserInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketVariationScheduleDateUserInfo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketVariationScheduleDateUserInfo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.userUID = r.string();
                    break;
                case 2:
                    m.infoUID = r.string();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.number = r.int32();
                    break;
                case 5:
                    m.date = r.int64();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 9:
                    m.additional = r.string();
                    break;
                case 10:
                    m.readonly = r.bool();
                    break;
                case 11:
                    m.json = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketVariationScheduleDateUserInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketVariationScheduleDateUserInfo
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketVariationScheduleDateUserInfo} TicketVariationScheduleDateUserInfo
         */
        TicketVariationScheduleDateUserInfo.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketVariationScheduleDateUserInfo)
                return d;
            var m = new $root.Proto.TicketVariationScheduleDateUserInfo();
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.infoUID != null) {
                m.infoUID = String(d.infoUID);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.number != null) {
                m.number = d.number | 0;
            }
            if (d.date != null) {
                if ($util.Long)
                    (m.date = $util.Long.fromValue(d.date)).unsigned = false;
                else if (typeof d.date === "string")
                    m.date = parseInt(d.date, 10);
                else if (typeof d.date === "number")
                    m.date = d.date;
                else if (typeof d.date === "object")
                    m.date = new $util.LongBits(d.date.low >>> 0, d.date.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.TicketVariationScheduleDateUserInfo.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            if (d.additional != null) {
                m.additional = String(d.additional);
            }
            if (d.readonly != null) {
                m.readonly = Boolean(d.readonly);
            }
            if (d.json != null) {
                m.json = String(d.json);
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketVariationScheduleDateUserInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketVariationScheduleDateUserInfo
         * @static
         * @param {Proto.TicketVariationScheduleDateUserInfo} m TicketVariationScheduleDateUserInfo
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketVariationScheduleDateUserInfo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.userUID = "";
                d.infoUID = "";
                d.text = "";
                d.number = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.date = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.date = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.file = null;
                d.additional = "";
                d.readonly = false;
                d.json = "";
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.infoUID != null && m.hasOwnProperty("infoUID")) {
                d.infoUID = m.infoUID;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.number != null && m.hasOwnProperty("number")) {
                d.number = m.number;
            }
            if (m.date != null && m.hasOwnProperty("date")) {
                if (typeof m.date === "number")
                    d.date = o.longs === String ? String(m.date) : m.date;
                else
                    d.date = o.longs === String ? $util.Long.prototype.toString.call(m.date) : o.longs === Number ? new $util.LongBits(m.date.low >>> 0, m.date.high >>> 0).toNumber() : m.date;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            if (m.additional != null && m.hasOwnProperty("additional")) {
                d.additional = m.additional;
            }
            if (m.readonly != null && m.hasOwnProperty("readonly")) {
                d.readonly = m.readonly;
            }
            if (m.json != null && m.hasOwnProperty("json")) {
                d.json = m.json;
            }
            return d;
        };

        /**
         * Converts this TicketVariationScheduleDateUserInfo to JSON.
         * @function toJSON
         * @memberof Proto.TicketVariationScheduleDateUserInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketVariationScheduleDateUserInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketVariationScheduleDateUserInfo;
    })();

    Proto.TicketVariationScheduleDateUserInfoList = (function() {

        /**
         * Properties of a TicketVariationScheduleDateUserInfoList.
         * @memberof Proto
         * @interface ITicketVariationScheduleDateUserInfoList
         * @property {Array.<Proto.ITicketVariationScheduleDateUserInfo>|null} [value] TicketVariationScheduleDateUserInfoList value
         */

        /**
         * Constructs a new TicketVariationScheduleDateUserInfoList.
         * @memberof Proto
         * @classdesc Represents a TicketVariationScheduleDateUserInfoList.
         * @implements ITicketVariationScheduleDateUserInfoList
         * @constructor
         * @param {Proto.ITicketVariationScheduleDateUserInfoList=} [p] Properties to set
         */
        function TicketVariationScheduleDateUserInfoList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketVariationScheduleDateUserInfoList value.
         * @member {Array.<Proto.ITicketVariationScheduleDateUserInfo>} value
         * @memberof Proto.TicketVariationScheduleDateUserInfoList
         * @instance
         */
        TicketVariationScheduleDateUserInfoList.prototype.value = $util.emptyArray;

        /**
         * Creates a new TicketVariationScheduleDateUserInfoList instance using the specified properties.
         * @function create
         * @memberof Proto.TicketVariationScheduleDateUserInfoList
         * @static
         * @param {Proto.ITicketVariationScheduleDateUserInfoList=} [properties] Properties to set
         * @returns {Proto.TicketVariationScheduleDateUserInfoList} TicketVariationScheduleDateUserInfoList instance
         */
        TicketVariationScheduleDateUserInfoList.create = function create(properties) {
            return new TicketVariationScheduleDateUserInfoList(properties);
        };

        /**
         * Encodes the specified TicketVariationScheduleDateUserInfoList message. Does not implicitly {@link Proto.TicketVariationScheduleDateUserInfoList.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketVariationScheduleDateUserInfoList
         * @static
         * @param {Proto.ITicketVariationScheduleDateUserInfoList} m TicketVariationScheduleDateUserInfoList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketVariationScheduleDateUserInfoList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.TicketVariationScheduleDateUserInfo.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a TicketVariationScheduleDateUserInfoList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketVariationScheduleDateUserInfoList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketVariationScheduleDateUserInfoList} TicketVariationScheduleDateUserInfoList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketVariationScheduleDateUserInfoList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketVariationScheduleDateUserInfoList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.TicketVariationScheduleDateUserInfo.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketVariationScheduleDateUserInfoList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketVariationScheduleDateUserInfoList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketVariationScheduleDateUserInfoList} TicketVariationScheduleDateUserInfoList
         */
        TicketVariationScheduleDateUserInfoList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketVariationScheduleDateUserInfoList)
                return d;
            var m = new $root.Proto.TicketVariationScheduleDateUserInfoList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.TicketVariationScheduleDateUserInfoList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.TicketVariationScheduleDateUserInfoList.value: object expected");
                    m.value[i] = $root.Proto.TicketVariationScheduleDateUserInfo.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketVariationScheduleDateUserInfoList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketVariationScheduleDateUserInfoList
         * @static
         * @param {Proto.TicketVariationScheduleDateUserInfoList} m TicketVariationScheduleDateUserInfoList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketVariationScheduleDateUserInfoList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.TicketVariationScheduleDateUserInfo.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this TicketVariationScheduleDateUserInfoList to JSON.
         * @function toJSON
         * @memberof Proto.TicketVariationScheduleDateUserInfoList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketVariationScheduleDateUserInfoList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketVariationScheduleDateUserInfoList;
    })();

    Proto.TicketVariationScheduleDateUser = (function() {

        /**
         * Properties of a TicketVariationScheduleDateUser.
         * @memberof Proto
         * @interface ITicketVariationScheduleDateUser
         * @property {string|null} [uid] TicketVariationScheduleDateUser uid
         * @property {string|null} [dateUID] TicketVariationScheduleDateUser dateUID
         * @property {string|null} [userUID] TicketVariationScheduleDateUser userUID
         * @property {boolean|null} [participate] TicketVariationScheduleDateUser participate
         * @property {Proto.IUser|null} [user] TicketVariationScheduleDateUser user
         * @property {number|Long|null} [registerDate] TicketVariationScheduleDateUser registerDate
         * @property {number|Long|null} [modifyDate] TicketVariationScheduleDateUser modifyDate
         * @property {number|null} [status] TicketVariationScheduleDateUser status
         * @property {Array.<Proto.IUserInfo>|null} [userInfoList] TicketVariationScheduleDateUser userInfoList
         * @property {Array.<Proto.ITicketVariationScheduleDateUserInfo>|null} [questionInfoList] TicketVariationScheduleDateUser questionInfoList
         * @property {Proto.ITicketVariationScheduleDate|null} [date] TicketVariationScheduleDateUser date
         * @property {string|null} [orderUID] TicketVariationScheduleDateUser orderUID
         * @property {number|null} [quantity] TicketVariationScheduleDateUser quantity
         * @property {string|null} [staffUID] TicketVariationScheduleDateUser staffUID
         * @property {Proto.ITicketStaff|null} [staff] TicketVariationScheduleDateUser staff
         */

        /**
         * Constructs a new TicketVariationScheduleDateUser.
         * @memberof Proto
         * @classdesc Represents a TicketVariationScheduleDateUser.
         * @implements ITicketVariationScheduleDateUser
         * @constructor
         * @param {Proto.ITicketVariationScheduleDateUser=} [p] Properties to set
         */
        function TicketVariationScheduleDateUser(p) {
            this.userInfoList = [];
            this.questionInfoList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketVariationScheduleDateUser uid.
         * @member {string} uid
         * @memberof Proto.TicketVariationScheduleDateUser
         * @instance
         */
        TicketVariationScheduleDateUser.prototype.uid = "";

        /**
         * TicketVariationScheduleDateUser dateUID.
         * @member {string} dateUID
         * @memberof Proto.TicketVariationScheduleDateUser
         * @instance
         */
        TicketVariationScheduleDateUser.prototype.dateUID = "";

        /**
         * TicketVariationScheduleDateUser userUID.
         * @member {string} userUID
         * @memberof Proto.TicketVariationScheduleDateUser
         * @instance
         */
        TicketVariationScheduleDateUser.prototype.userUID = "";

        /**
         * TicketVariationScheduleDateUser participate.
         * @member {boolean} participate
         * @memberof Proto.TicketVariationScheduleDateUser
         * @instance
         */
        TicketVariationScheduleDateUser.prototype.participate = false;

        /**
         * TicketVariationScheduleDateUser user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.TicketVariationScheduleDateUser
         * @instance
         */
        TicketVariationScheduleDateUser.prototype.user = null;

        /**
         * TicketVariationScheduleDateUser registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.TicketVariationScheduleDateUser
         * @instance
         */
        TicketVariationScheduleDateUser.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleDateUser modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.TicketVariationScheduleDateUser
         * @instance
         */
        TicketVariationScheduleDateUser.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleDateUser status.
         * @member {number} status
         * @memberof Proto.TicketVariationScheduleDateUser
         * @instance
         */
        TicketVariationScheduleDateUser.prototype.status = 0;

        /**
         * TicketVariationScheduleDateUser userInfoList.
         * @member {Array.<Proto.IUserInfo>} userInfoList
         * @memberof Proto.TicketVariationScheduleDateUser
         * @instance
         */
        TicketVariationScheduleDateUser.prototype.userInfoList = $util.emptyArray;

        /**
         * TicketVariationScheduleDateUser questionInfoList.
         * @member {Array.<Proto.ITicketVariationScheduleDateUserInfo>} questionInfoList
         * @memberof Proto.TicketVariationScheduleDateUser
         * @instance
         */
        TicketVariationScheduleDateUser.prototype.questionInfoList = $util.emptyArray;

        /**
         * TicketVariationScheduleDateUser date.
         * @member {Proto.ITicketVariationScheduleDate|null|undefined} date
         * @memberof Proto.TicketVariationScheduleDateUser
         * @instance
         */
        TicketVariationScheduleDateUser.prototype.date = null;

        /**
         * TicketVariationScheduleDateUser orderUID.
         * @member {string} orderUID
         * @memberof Proto.TicketVariationScheduleDateUser
         * @instance
         */
        TicketVariationScheduleDateUser.prototype.orderUID = "";

        /**
         * TicketVariationScheduleDateUser quantity.
         * @member {number} quantity
         * @memberof Proto.TicketVariationScheduleDateUser
         * @instance
         */
        TicketVariationScheduleDateUser.prototype.quantity = 0;

        /**
         * TicketVariationScheduleDateUser staffUID.
         * @member {string} staffUID
         * @memberof Proto.TicketVariationScheduleDateUser
         * @instance
         */
        TicketVariationScheduleDateUser.prototype.staffUID = "";

        /**
         * TicketVariationScheduleDateUser staff.
         * @member {Proto.ITicketStaff|null|undefined} staff
         * @memberof Proto.TicketVariationScheduleDateUser
         * @instance
         */
        TicketVariationScheduleDateUser.prototype.staff = null;

        /**
         * Creates a new TicketVariationScheduleDateUser instance using the specified properties.
         * @function create
         * @memberof Proto.TicketVariationScheduleDateUser
         * @static
         * @param {Proto.ITicketVariationScheduleDateUser=} [properties] Properties to set
         * @returns {Proto.TicketVariationScheduleDateUser} TicketVariationScheduleDateUser instance
         */
        TicketVariationScheduleDateUser.create = function create(properties) {
            return new TicketVariationScheduleDateUser(properties);
        };

        /**
         * Encodes the specified TicketVariationScheduleDateUser message. Does not implicitly {@link Proto.TicketVariationScheduleDateUser.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketVariationScheduleDateUser
         * @static
         * @param {Proto.ITicketVariationScheduleDateUser} m TicketVariationScheduleDateUser message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketVariationScheduleDateUser.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.dateUID != null && Object.hasOwnProperty.call(m, "dateUID"))
                w.uint32(18).string(m.dateUID);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(26).string(m.userUID);
            if (m.participate != null && Object.hasOwnProperty.call(m, "participate"))
                w.uint32(32).bool(m.participate);
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(42).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(64).int32(m.status);
            if (m.userInfoList != null && m.userInfoList.length) {
                for (var i = 0; i < m.userInfoList.length; ++i)
                    $root.Proto.UserInfo.encode(m.userInfoList[i], w.uint32(74).fork()).ldelim();
            }
            if (m.questionInfoList != null && m.questionInfoList.length) {
                for (var i = 0; i < m.questionInfoList.length; ++i)
                    $root.Proto.TicketVariationScheduleDateUserInfo.encode(m.questionInfoList[i], w.uint32(82).fork()).ldelim();
            }
            if (m.date != null && Object.hasOwnProperty.call(m, "date"))
                $root.Proto.TicketVariationScheduleDate.encode(m.date, w.uint32(90).fork()).ldelim();
            if (m.orderUID != null && Object.hasOwnProperty.call(m, "orderUID"))
                w.uint32(98).string(m.orderUID);
            if (m.quantity != null && Object.hasOwnProperty.call(m, "quantity"))
                w.uint32(104).int32(m.quantity);
            if (m.staffUID != null && Object.hasOwnProperty.call(m, "staffUID"))
                w.uint32(114).string(m.staffUID);
            if (m.staff != null && Object.hasOwnProperty.call(m, "staff"))
                $root.Proto.TicketStaff.encode(m.staff, w.uint32(122).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a TicketVariationScheduleDateUser message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketVariationScheduleDateUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketVariationScheduleDateUser} TicketVariationScheduleDateUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketVariationScheduleDateUser.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketVariationScheduleDateUser();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.dateUID = r.string();
                    break;
                case 3:
                    m.userUID = r.string();
                    break;
                case 4:
                    m.participate = r.bool();
                    break;
                case 5:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.status = r.int32();
                    break;
                case 9:
                    if (!(m.userInfoList && m.userInfoList.length))
                        m.userInfoList = [];
                    m.userInfoList.push($root.Proto.UserInfo.decode(r, r.uint32()));
                    break;
                case 10:
                    if (!(m.questionInfoList && m.questionInfoList.length))
                        m.questionInfoList = [];
                    m.questionInfoList.push($root.Proto.TicketVariationScheduleDateUserInfo.decode(r, r.uint32()));
                    break;
                case 11:
                    m.date = $root.Proto.TicketVariationScheduleDate.decode(r, r.uint32());
                    break;
                case 12:
                    m.orderUID = r.string();
                    break;
                case 13:
                    m.quantity = r.int32();
                    break;
                case 14:
                    m.staffUID = r.string();
                    break;
                case 15:
                    m.staff = $root.Proto.TicketStaff.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketVariationScheduleDateUser message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketVariationScheduleDateUser
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketVariationScheduleDateUser} TicketVariationScheduleDateUser
         */
        TicketVariationScheduleDateUser.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketVariationScheduleDateUser)
                return d;
            var m = new $root.Proto.TicketVariationScheduleDateUser();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.dateUID != null) {
                m.dateUID = String(d.dateUID);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.participate != null) {
                m.participate = Boolean(d.participate);
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.TicketVariationScheduleDateUser.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.userInfoList) {
                if (!Array.isArray(d.userInfoList))
                    throw TypeError(".Proto.TicketVariationScheduleDateUser.userInfoList: array expected");
                m.userInfoList = [];
                for (var i = 0; i < d.userInfoList.length; ++i) {
                    if (typeof d.userInfoList[i] !== "object")
                        throw TypeError(".Proto.TicketVariationScheduleDateUser.userInfoList: object expected");
                    m.userInfoList[i] = $root.Proto.UserInfo.fromObject(d.userInfoList[i]);
                }
            }
            if (d.questionInfoList) {
                if (!Array.isArray(d.questionInfoList))
                    throw TypeError(".Proto.TicketVariationScheduleDateUser.questionInfoList: array expected");
                m.questionInfoList = [];
                for (var i = 0; i < d.questionInfoList.length; ++i) {
                    if (typeof d.questionInfoList[i] !== "object")
                        throw TypeError(".Proto.TicketVariationScheduleDateUser.questionInfoList: object expected");
                    m.questionInfoList[i] = $root.Proto.TicketVariationScheduleDateUserInfo.fromObject(d.questionInfoList[i]);
                }
            }
            if (d.date != null) {
                if (typeof d.date !== "object")
                    throw TypeError(".Proto.TicketVariationScheduleDateUser.date: object expected");
                m.date = $root.Proto.TicketVariationScheduleDate.fromObject(d.date);
            }
            if (d.orderUID != null) {
                m.orderUID = String(d.orderUID);
            }
            if (d.quantity != null) {
                m.quantity = d.quantity | 0;
            }
            if (d.staffUID != null) {
                m.staffUID = String(d.staffUID);
            }
            if (d.staff != null) {
                if (typeof d.staff !== "object")
                    throw TypeError(".Proto.TicketVariationScheduleDateUser.staff: object expected");
                m.staff = $root.Proto.TicketStaff.fromObject(d.staff);
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketVariationScheduleDateUser message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketVariationScheduleDateUser
         * @static
         * @param {Proto.TicketVariationScheduleDateUser} m TicketVariationScheduleDateUser
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketVariationScheduleDateUser.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.userInfoList = [];
                d.questionInfoList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.dateUID = "";
                d.userUID = "";
                d.participate = false;
                d.user = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.date = null;
                d.orderUID = "";
                d.quantity = 0;
                d.staffUID = "";
                d.staff = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.dateUID != null && m.hasOwnProperty("dateUID")) {
                d.dateUID = m.dateUID;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.participate != null && m.hasOwnProperty("participate")) {
                d.participate = m.participate;
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.userInfoList && m.userInfoList.length) {
                d.userInfoList = [];
                for (var j = 0; j < m.userInfoList.length; ++j) {
                    d.userInfoList[j] = $root.Proto.UserInfo.toObject(m.userInfoList[j], o);
                }
            }
            if (m.questionInfoList && m.questionInfoList.length) {
                d.questionInfoList = [];
                for (var j = 0; j < m.questionInfoList.length; ++j) {
                    d.questionInfoList[j] = $root.Proto.TicketVariationScheduleDateUserInfo.toObject(m.questionInfoList[j], o);
                }
            }
            if (m.date != null && m.hasOwnProperty("date")) {
                d.date = $root.Proto.TicketVariationScheduleDate.toObject(m.date, o);
            }
            if (m.orderUID != null && m.hasOwnProperty("orderUID")) {
                d.orderUID = m.orderUID;
            }
            if (m.quantity != null && m.hasOwnProperty("quantity")) {
                d.quantity = m.quantity;
            }
            if (m.staffUID != null && m.hasOwnProperty("staffUID")) {
                d.staffUID = m.staffUID;
            }
            if (m.staff != null && m.hasOwnProperty("staff")) {
                d.staff = $root.Proto.TicketStaff.toObject(m.staff, o);
            }
            return d;
        };

        /**
         * Converts this TicketVariationScheduleDateUser to JSON.
         * @function toJSON
         * @memberof Proto.TicketVariationScheduleDateUser
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketVariationScheduleDateUser.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketVariationScheduleDateUser;
    })();

    Proto.TicketVariationScheduleDateUserList = (function() {

        /**
         * Properties of a TicketVariationScheduleDateUserList.
         * @memberof Proto
         * @interface ITicketVariationScheduleDateUserList
         * @property {Array.<Proto.ITicketVariationScheduleDateUser>|null} [value] TicketVariationScheduleDateUserList value
         */

        /**
         * Constructs a new TicketVariationScheduleDateUserList.
         * @memberof Proto
         * @classdesc Represents a TicketVariationScheduleDateUserList.
         * @implements ITicketVariationScheduleDateUserList
         * @constructor
         * @param {Proto.ITicketVariationScheduleDateUserList=} [p] Properties to set
         */
        function TicketVariationScheduleDateUserList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketVariationScheduleDateUserList value.
         * @member {Array.<Proto.ITicketVariationScheduleDateUser>} value
         * @memberof Proto.TicketVariationScheduleDateUserList
         * @instance
         */
        TicketVariationScheduleDateUserList.prototype.value = $util.emptyArray;

        /**
         * Creates a new TicketVariationScheduleDateUserList instance using the specified properties.
         * @function create
         * @memberof Proto.TicketVariationScheduleDateUserList
         * @static
         * @param {Proto.ITicketVariationScheduleDateUserList=} [properties] Properties to set
         * @returns {Proto.TicketVariationScheduleDateUserList} TicketVariationScheduleDateUserList instance
         */
        TicketVariationScheduleDateUserList.create = function create(properties) {
            return new TicketVariationScheduleDateUserList(properties);
        };

        /**
         * Encodes the specified TicketVariationScheduleDateUserList message. Does not implicitly {@link Proto.TicketVariationScheduleDateUserList.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketVariationScheduleDateUserList
         * @static
         * @param {Proto.ITicketVariationScheduleDateUserList} m TicketVariationScheduleDateUserList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketVariationScheduleDateUserList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.TicketVariationScheduleDateUser.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a TicketVariationScheduleDateUserList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketVariationScheduleDateUserList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketVariationScheduleDateUserList} TicketVariationScheduleDateUserList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketVariationScheduleDateUserList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketVariationScheduleDateUserList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.TicketVariationScheduleDateUser.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketVariationScheduleDateUserList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketVariationScheduleDateUserList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketVariationScheduleDateUserList} TicketVariationScheduleDateUserList
         */
        TicketVariationScheduleDateUserList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketVariationScheduleDateUserList)
                return d;
            var m = new $root.Proto.TicketVariationScheduleDateUserList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.TicketVariationScheduleDateUserList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.TicketVariationScheduleDateUserList.value: object expected");
                    m.value[i] = $root.Proto.TicketVariationScheduleDateUser.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketVariationScheduleDateUserList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketVariationScheduleDateUserList
         * @static
         * @param {Proto.TicketVariationScheduleDateUserList} m TicketVariationScheduleDateUserList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketVariationScheduleDateUserList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.TicketVariationScheduleDateUser.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this TicketVariationScheduleDateUserList to JSON.
         * @function toJSON
         * @memberof Proto.TicketVariationScheduleDateUserList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketVariationScheduleDateUserList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketVariationScheduleDateUserList;
    })();

    Proto.TicketVariationScheduleOrder = (function() {

        /**
         * Properties of a TicketVariationScheduleOrder.
         * @memberof Proto
         * @interface ITicketVariationScheduleOrder
         * @property {string|null} [uid] TicketVariationScheduleOrder uid
         * @property {string|null} [variationUID] TicketVariationScheduleOrder variationUID
         * @property {string|null} [dateUserUID] TicketVariationScheduleOrder dateUserUID
         * @property {string|null} [userUID] TicketVariationScheduleOrder userUID
         * @property {string|null} [thirdCheckoutId] TicketVariationScheduleOrder thirdCheckoutId
         * @property {string|null} [thirdPaymentId] TicketVariationScheduleOrder thirdPaymentId
         * @property {number|null} [quantity] TicketVariationScheduleOrder quantity
         * @property {number|null} [price] TicketVariationScheduleOrder price
         * @property {string|null} [currency] TicketVariationScheduleOrder currency
         * @property {string|null} [trackingNumber] TicketVariationScheduleOrder trackingNumber
         * @property {number|null} [trackingStatus] TicketVariationScheduleOrder trackingStatus
         * @property {number|Long|null} [registerDate] TicketVariationScheduleOrder registerDate
         * @property {number|Long|null} [modifyDate] TicketVariationScheduleOrder modifyDate
         * @property {number|null} [status] TicketVariationScheduleOrder status
         * @property {Proto.IUser|null} [user] TicketVariationScheduleOrder user
         * @property {Proto.ITicket|null} [ticket] TicketVariationScheduleOrder ticket
         * @property {string|null} [phone] TicketVariationScheduleOrder phone
         * @property {string|null} [postalCode] TicketVariationScheduleOrder postalCode
         * @property {string|null} [country] TicketVariationScheduleOrder country
         * @property {string|null} [state] TicketVariationScheduleOrder state
         * @property {string|null} [city] TicketVariationScheduleOrder city
         * @property {string|null} [address] TicketVariationScheduleOrder address
         * @property {number|null} [fee] TicketVariationScheduleOrder fee
         * @property {number|null} [total] TicketVariationScheduleOrder total
         * @property {number|null} [trackingCompany] TicketVariationScheduleOrder trackingCompany
         * @property {number|null} [payFee] TicketVariationScheduleOrder payFee
         * @property {boolean|null} [userCancel] TicketVariationScheduleOrder userCancel
         * @property {number|Long|null} [refundDate] TicketVariationScheduleOrder refundDate
         * @property {string|null} [receiptName] TicketVariationScheduleOrder receiptName
         * @property {string|null} [addressDetail] TicketVariationScheduleOrder addressDetail
         * @property {boolean|null} [invoiceFixed] TicketVariationScheduleOrder invoiceFixed
         * @property {number|null} [paymentType] TicketVariationScheduleOrder paymentType
         * @property {number|null} [thirdType] TicketVariationScheduleOrder thirdType
         * @property {number|null} [cashFee] TicketVariationScheduleOrder cashFee
         * @property {string|null} [variationName] TicketVariationScheduleOrder variationName
         * @property {boolean|null} [captured] TicketVariationScheduleOrder captured
         * @property {Proto.ITicketVariationScheduleDate|null} [date] TicketVariationScheduleOrder date
         * @property {Array.<Proto.ITicketVariationScheduleUserInfo>|null} [questionInfoList] TicketVariationScheduleOrder questionInfoList
         * @property {Array.<Proto.ITicketVariationScheduleDateUserInfo>|null} [answerInfoList] TicketVariationScheduleOrder answerInfoList
         * @property {string|null} [variationOnlineLink] TicketVariationScheduleOrder variationOnlineLink
         * @property {Proto.ITicketStaff|null} [staff] TicketVariationScheduleOrder staff
         */

        /**
         * Constructs a new TicketVariationScheduleOrder.
         * @memberof Proto
         * @classdesc Represents a TicketVariationScheduleOrder.
         * @implements ITicketVariationScheduleOrder
         * @constructor
         * @param {Proto.ITicketVariationScheduleOrder=} [p] Properties to set
         */
        function TicketVariationScheduleOrder(p) {
            this.questionInfoList = [];
            this.answerInfoList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketVariationScheduleOrder uid.
         * @member {string} uid
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.uid = "";

        /**
         * TicketVariationScheduleOrder variationUID.
         * @member {string} variationUID
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.variationUID = "";

        /**
         * TicketVariationScheduleOrder dateUserUID.
         * @member {string} dateUserUID
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.dateUserUID = "";

        /**
         * TicketVariationScheduleOrder userUID.
         * @member {string} userUID
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.userUID = "";

        /**
         * TicketVariationScheduleOrder thirdCheckoutId.
         * @member {string} thirdCheckoutId
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.thirdCheckoutId = "";

        /**
         * TicketVariationScheduleOrder thirdPaymentId.
         * @member {string} thirdPaymentId
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.thirdPaymentId = "";

        /**
         * TicketVariationScheduleOrder quantity.
         * @member {number} quantity
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.quantity = 0;

        /**
         * TicketVariationScheduleOrder price.
         * @member {number} price
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.price = 0;

        /**
         * TicketVariationScheduleOrder currency.
         * @member {string} currency
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.currency = "";

        /**
         * TicketVariationScheduleOrder trackingNumber.
         * @member {string} trackingNumber
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.trackingNumber = "";

        /**
         * TicketVariationScheduleOrder trackingStatus.
         * @member {number} trackingStatus
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.trackingStatus = 0;

        /**
         * TicketVariationScheduleOrder registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleOrder modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleOrder status.
         * @member {number} status
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.status = 0;

        /**
         * TicketVariationScheduleOrder user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.user = null;

        /**
         * TicketVariationScheduleOrder ticket.
         * @member {Proto.ITicket|null|undefined} ticket
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.ticket = null;

        /**
         * TicketVariationScheduleOrder phone.
         * @member {string} phone
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.phone = "";

        /**
         * TicketVariationScheduleOrder postalCode.
         * @member {string} postalCode
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.postalCode = "";

        /**
         * TicketVariationScheduleOrder country.
         * @member {string} country
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.country = "";

        /**
         * TicketVariationScheduleOrder state.
         * @member {string} state
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.state = "";

        /**
         * TicketVariationScheduleOrder city.
         * @member {string} city
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.city = "";

        /**
         * TicketVariationScheduleOrder address.
         * @member {string} address
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.address = "";

        /**
         * TicketVariationScheduleOrder fee.
         * @member {number} fee
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.fee = 0;

        /**
         * TicketVariationScheduleOrder total.
         * @member {number} total
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.total = 0;

        /**
         * TicketVariationScheduleOrder trackingCompany.
         * @member {number} trackingCompany
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.trackingCompany = 0;

        /**
         * TicketVariationScheduleOrder payFee.
         * @member {number} payFee
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.payFee = 0;

        /**
         * TicketVariationScheduleOrder userCancel.
         * @member {boolean} userCancel
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.userCancel = false;

        /**
         * TicketVariationScheduleOrder refundDate.
         * @member {number|Long} refundDate
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.refundDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketVariationScheduleOrder receiptName.
         * @member {string} receiptName
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.receiptName = "";

        /**
         * TicketVariationScheduleOrder addressDetail.
         * @member {string} addressDetail
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.addressDetail = "";

        /**
         * TicketVariationScheduleOrder invoiceFixed.
         * @member {boolean} invoiceFixed
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.invoiceFixed = false;

        /**
         * TicketVariationScheduleOrder paymentType.
         * @member {number} paymentType
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.paymentType = 0;

        /**
         * TicketVariationScheduleOrder thirdType.
         * @member {number} thirdType
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.thirdType = 0;

        /**
         * TicketVariationScheduleOrder cashFee.
         * @member {number} cashFee
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.cashFee = 0;

        /**
         * TicketVariationScheduleOrder variationName.
         * @member {string} variationName
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.variationName = "";

        /**
         * TicketVariationScheduleOrder captured.
         * @member {boolean} captured
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.captured = false;

        /**
         * TicketVariationScheduleOrder date.
         * @member {Proto.ITicketVariationScheduleDate|null|undefined} date
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.date = null;

        /**
         * TicketVariationScheduleOrder questionInfoList.
         * @member {Array.<Proto.ITicketVariationScheduleUserInfo>} questionInfoList
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.questionInfoList = $util.emptyArray;

        /**
         * TicketVariationScheduleOrder answerInfoList.
         * @member {Array.<Proto.ITicketVariationScheduleDateUserInfo>} answerInfoList
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.answerInfoList = $util.emptyArray;

        /**
         * TicketVariationScheduleOrder variationOnlineLink.
         * @member {string} variationOnlineLink
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.variationOnlineLink = "";

        /**
         * TicketVariationScheduleOrder staff.
         * @member {Proto.ITicketStaff|null|undefined} staff
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         */
        TicketVariationScheduleOrder.prototype.staff = null;

        /**
         * Creates a new TicketVariationScheduleOrder instance using the specified properties.
         * @function create
         * @memberof Proto.TicketVariationScheduleOrder
         * @static
         * @param {Proto.ITicketVariationScheduleOrder=} [properties] Properties to set
         * @returns {Proto.TicketVariationScheduleOrder} TicketVariationScheduleOrder instance
         */
        TicketVariationScheduleOrder.create = function create(properties) {
            return new TicketVariationScheduleOrder(properties);
        };

        /**
         * Encodes the specified TicketVariationScheduleOrder message. Does not implicitly {@link Proto.TicketVariationScheduleOrder.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketVariationScheduleOrder
         * @static
         * @param {Proto.ITicketVariationScheduleOrder} m TicketVariationScheduleOrder message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketVariationScheduleOrder.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.variationUID != null && Object.hasOwnProperty.call(m, "variationUID"))
                w.uint32(18).string(m.variationUID);
            if (m.dateUserUID != null && Object.hasOwnProperty.call(m, "dateUserUID"))
                w.uint32(26).string(m.dateUserUID);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(34).string(m.userUID);
            if (m.thirdCheckoutId != null && Object.hasOwnProperty.call(m, "thirdCheckoutId"))
                w.uint32(42).string(m.thirdCheckoutId);
            if (m.thirdPaymentId != null && Object.hasOwnProperty.call(m, "thirdPaymentId"))
                w.uint32(50).string(m.thirdPaymentId);
            if (m.quantity != null && Object.hasOwnProperty.call(m, "quantity"))
                w.uint32(56).int32(m.quantity);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(65).double(m.price);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(74).string(m.currency);
            if (m.trackingNumber != null && Object.hasOwnProperty.call(m, "trackingNumber"))
                w.uint32(82).string(m.trackingNumber);
            if (m.trackingStatus != null && Object.hasOwnProperty.call(m, "trackingStatus"))
                w.uint32(88).int32(m.trackingStatus);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(96).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(104).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(112).int32(m.status);
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(122).fork()).ldelim();
            if (m.ticket != null && Object.hasOwnProperty.call(m, "ticket"))
                $root.Proto.Ticket.encode(m.ticket, w.uint32(130).fork()).ldelim();
            if (m.phone != null && Object.hasOwnProperty.call(m, "phone"))
                w.uint32(138).string(m.phone);
            if (m.postalCode != null && Object.hasOwnProperty.call(m, "postalCode"))
                w.uint32(146).string(m.postalCode);
            if (m.country != null && Object.hasOwnProperty.call(m, "country"))
                w.uint32(154).string(m.country);
            if (m.state != null && Object.hasOwnProperty.call(m, "state"))
                w.uint32(162).string(m.state);
            if (m.city != null && Object.hasOwnProperty.call(m, "city"))
                w.uint32(170).string(m.city);
            if (m.address != null && Object.hasOwnProperty.call(m, "address"))
                w.uint32(178).string(m.address);
            if (m.fee != null && Object.hasOwnProperty.call(m, "fee"))
                w.uint32(185).double(m.fee);
            if (m.total != null && Object.hasOwnProperty.call(m, "total"))
                w.uint32(193).double(m.total);
            if (m.trackingCompany != null && Object.hasOwnProperty.call(m, "trackingCompany"))
                w.uint32(200).int32(m.trackingCompany);
            if (m.payFee != null && Object.hasOwnProperty.call(m, "payFee"))
                w.uint32(209).double(m.payFee);
            if (m.userCancel != null && Object.hasOwnProperty.call(m, "userCancel"))
                w.uint32(216).bool(m.userCancel);
            if (m.refundDate != null && Object.hasOwnProperty.call(m, "refundDate"))
                w.uint32(224).int64(m.refundDate);
            if (m.receiptName != null && Object.hasOwnProperty.call(m, "receiptName"))
                w.uint32(234).string(m.receiptName);
            if (m.addressDetail != null && Object.hasOwnProperty.call(m, "addressDetail"))
                w.uint32(242).string(m.addressDetail);
            if (m.invoiceFixed != null && Object.hasOwnProperty.call(m, "invoiceFixed"))
                w.uint32(248).bool(m.invoiceFixed);
            if (m.paymentType != null && Object.hasOwnProperty.call(m, "paymentType"))
                w.uint32(256).int32(m.paymentType);
            if (m.thirdType != null && Object.hasOwnProperty.call(m, "thirdType"))
                w.uint32(264).int32(m.thirdType);
            if (m.cashFee != null && Object.hasOwnProperty.call(m, "cashFee"))
                w.uint32(273).double(m.cashFee);
            if (m.variationName != null && Object.hasOwnProperty.call(m, "variationName"))
                w.uint32(282).string(m.variationName);
            if (m.captured != null && Object.hasOwnProperty.call(m, "captured"))
                w.uint32(288).bool(m.captured);
            if (m.date != null && Object.hasOwnProperty.call(m, "date"))
                $root.Proto.TicketVariationScheduleDate.encode(m.date, w.uint32(298).fork()).ldelim();
            if (m.questionInfoList != null && m.questionInfoList.length) {
                for (var i = 0; i < m.questionInfoList.length; ++i)
                    $root.Proto.TicketVariationScheduleUserInfo.encode(m.questionInfoList[i], w.uint32(306).fork()).ldelim();
            }
            if (m.answerInfoList != null && m.answerInfoList.length) {
                for (var i = 0; i < m.answerInfoList.length; ++i)
                    $root.Proto.TicketVariationScheduleDateUserInfo.encode(m.answerInfoList[i], w.uint32(314).fork()).ldelim();
            }
            if (m.variationOnlineLink != null && Object.hasOwnProperty.call(m, "variationOnlineLink"))
                w.uint32(322).string(m.variationOnlineLink);
            if (m.staff != null && Object.hasOwnProperty.call(m, "staff"))
                $root.Proto.TicketStaff.encode(m.staff, w.uint32(330).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a TicketVariationScheduleOrder message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketVariationScheduleOrder
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketVariationScheduleOrder} TicketVariationScheduleOrder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketVariationScheduleOrder.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketVariationScheduleOrder();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.variationUID = r.string();
                    break;
                case 3:
                    m.dateUserUID = r.string();
                    break;
                case 4:
                    m.userUID = r.string();
                    break;
                case 5:
                    m.thirdCheckoutId = r.string();
                    break;
                case 6:
                    m.thirdPaymentId = r.string();
                    break;
                case 7:
                    m.quantity = r.int32();
                    break;
                case 8:
                    m.price = r.double();
                    break;
                case 9:
                    m.currency = r.string();
                    break;
                case 10:
                    m.trackingNumber = r.string();
                    break;
                case 11:
                    m.trackingStatus = r.int32();
                    break;
                case 12:
                    m.registerDate = r.int64();
                    break;
                case 13:
                    m.modifyDate = r.int64();
                    break;
                case 14:
                    m.status = r.int32();
                    break;
                case 15:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 16:
                    m.ticket = $root.Proto.Ticket.decode(r, r.uint32());
                    break;
                case 17:
                    m.phone = r.string();
                    break;
                case 18:
                    m.postalCode = r.string();
                    break;
                case 19:
                    m.country = r.string();
                    break;
                case 20:
                    m.state = r.string();
                    break;
                case 21:
                    m.city = r.string();
                    break;
                case 22:
                    m.address = r.string();
                    break;
                case 23:
                    m.fee = r.double();
                    break;
                case 24:
                    m.total = r.double();
                    break;
                case 25:
                    m.trackingCompany = r.int32();
                    break;
                case 26:
                    m.payFee = r.double();
                    break;
                case 27:
                    m.userCancel = r.bool();
                    break;
                case 28:
                    m.refundDate = r.int64();
                    break;
                case 29:
                    m.receiptName = r.string();
                    break;
                case 30:
                    m.addressDetail = r.string();
                    break;
                case 31:
                    m.invoiceFixed = r.bool();
                    break;
                case 32:
                    m.paymentType = r.int32();
                    break;
                case 33:
                    m.thirdType = r.int32();
                    break;
                case 34:
                    m.cashFee = r.double();
                    break;
                case 35:
                    m.variationName = r.string();
                    break;
                case 36:
                    m.captured = r.bool();
                    break;
                case 37:
                    m.date = $root.Proto.TicketVariationScheduleDate.decode(r, r.uint32());
                    break;
                case 38:
                    if (!(m.questionInfoList && m.questionInfoList.length))
                        m.questionInfoList = [];
                    m.questionInfoList.push($root.Proto.TicketVariationScheduleUserInfo.decode(r, r.uint32()));
                    break;
                case 39:
                    if (!(m.answerInfoList && m.answerInfoList.length))
                        m.answerInfoList = [];
                    m.answerInfoList.push($root.Proto.TicketVariationScheduleDateUserInfo.decode(r, r.uint32()));
                    break;
                case 40:
                    m.variationOnlineLink = r.string();
                    break;
                case 41:
                    m.staff = $root.Proto.TicketStaff.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketVariationScheduleOrder message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketVariationScheduleOrder
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketVariationScheduleOrder} TicketVariationScheduleOrder
         */
        TicketVariationScheduleOrder.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketVariationScheduleOrder)
                return d;
            var m = new $root.Proto.TicketVariationScheduleOrder();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.variationUID != null) {
                m.variationUID = String(d.variationUID);
            }
            if (d.dateUserUID != null) {
                m.dateUserUID = String(d.dateUserUID);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.thirdCheckoutId != null) {
                m.thirdCheckoutId = String(d.thirdCheckoutId);
            }
            if (d.thirdPaymentId != null) {
                m.thirdPaymentId = String(d.thirdPaymentId);
            }
            if (d.quantity != null) {
                m.quantity = d.quantity | 0;
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.trackingNumber != null) {
                m.trackingNumber = String(d.trackingNumber);
            }
            if (d.trackingStatus != null) {
                m.trackingStatus = d.trackingStatus | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.TicketVariationScheduleOrder.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            if (d.ticket != null) {
                if (typeof d.ticket !== "object")
                    throw TypeError(".Proto.TicketVariationScheduleOrder.ticket: object expected");
                m.ticket = $root.Proto.Ticket.fromObject(d.ticket);
            }
            if (d.phone != null) {
                m.phone = String(d.phone);
            }
            if (d.postalCode != null) {
                m.postalCode = String(d.postalCode);
            }
            if (d.country != null) {
                m.country = String(d.country);
            }
            if (d.state != null) {
                m.state = String(d.state);
            }
            if (d.city != null) {
                m.city = String(d.city);
            }
            if (d.address != null) {
                m.address = String(d.address);
            }
            if (d.fee != null) {
                m.fee = Number(d.fee);
            }
            if (d.total != null) {
                m.total = Number(d.total);
            }
            if (d.trackingCompany != null) {
                m.trackingCompany = d.trackingCompany | 0;
            }
            if (d.payFee != null) {
                m.payFee = Number(d.payFee);
            }
            if (d.userCancel != null) {
                m.userCancel = Boolean(d.userCancel);
            }
            if (d.refundDate != null) {
                if ($util.Long)
                    (m.refundDate = $util.Long.fromValue(d.refundDate)).unsigned = false;
                else if (typeof d.refundDate === "string")
                    m.refundDate = parseInt(d.refundDate, 10);
                else if (typeof d.refundDate === "number")
                    m.refundDate = d.refundDate;
                else if (typeof d.refundDate === "object")
                    m.refundDate = new $util.LongBits(d.refundDate.low >>> 0, d.refundDate.high >>> 0).toNumber();
            }
            if (d.receiptName != null) {
                m.receiptName = String(d.receiptName);
            }
            if (d.addressDetail != null) {
                m.addressDetail = String(d.addressDetail);
            }
            if (d.invoiceFixed != null) {
                m.invoiceFixed = Boolean(d.invoiceFixed);
            }
            if (d.paymentType != null) {
                m.paymentType = d.paymentType | 0;
            }
            if (d.thirdType != null) {
                m.thirdType = d.thirdType | 0;
            }
            if (d.cashFee != null) {
                m.cashFee = Number(d.cashFee);
            }
            if (d.variationName != null) {
                m.variationName = String(d.variationName);
            }
            if (d.captured != null) {
                m.captured = Boolean(d.captured);
            }
            if (d.date != null) {
                if (typeof d.date !== "object")
                    throw TypeError(".Proto.TicketVariationScheduleOrder.date: object expected");
                m.date = $root.Proto.TicketVariationScheduleDate.fromObject(d.date);
            }
            if (d.questionInfoList) {
                if (!Array.isArray(d.questionInfoList))
                    throw TypeError(".Proto.TicketVariationScheduleOrder.questionInfoList: array expected");
                m.questionInfoList = [];
                for (var i = 0; i < d.questionInfoList.length; ++i) {
                    if (typeof d.questionInfoList[i] !== "object")
                        throw TypeError(".Proto.TicketVariationScheduleOrder.questionInfoList: object expected");
                    m.questionInfoList[i] = $root.Proto.TicketVariationScheduleUserInfo.fromObject(d.questionInfoList[i]);
                }
            }
            if (d.answerInfoList) {
                if (!Array.isArray(d.answerInfoList))
                    throw TypeError(".Proto.TicketVariationScheduleOrder.answerInfoList: array expected");
                m.answerInfoList = [];
                for (var i = 0; i < d.answerInfoList.length; ++i) {
                    if (typeof d.answerInfoList[i] !== "object")
                        throw TypeError(".Proto.TicketVariationScheduleOrder.answerInfoList: object expected");
                    m.answerInfoList[i] = $root.Proto.TicketVariationScheduleDateUserInfo.fromObject(d.answerInfoList[i]);
                }
            }
            if (d.variationOnlineLink != null) {
                m.variationOnlineLink = String(d.variationOnlineLink);
            }
            if (d.staff != null) {
                if (typeof d.staff !== "object")
                    throw TypeError(".Proto.TicketVariationScheduleOrder.staff: object expected");
                m.staff = $root.Proto.TicketStaff.fromObject(d.staff);
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketVariationScheduleOrder message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketVariationScheduleOrder
         * @static
         * @param {Proto.TicketVariationScheduleOrder} m TicketVariationScheduleOrder
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketVariationScheduleOrder.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.questionInfoList = [];
                d.answerInfoList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.variationUID = "";
                d.dateUserUID = "";
                d.userUID = "";
                d.thirdCheckoutId = "";
                d.thirdPaymentId = "";
                d.quantity = 0;
                d.price = 0;
                d.currency = "";
                d.trackingNumber = "";
                d.trackingStatus = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.user = null;
                d.ticket = null;
                d.phone = "";
                d.postalCode = "";
                d.country = "";
                d.state = "";
                d.city = "";
                d.address = "";
                d.fee = 0;
                d.total = 0;
                d.trackingCompany = 0;
                d.payFee = 0;
                d.userCancel = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.refundDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.refundDate = o.longs === String ? "0" : 0;
                d.receiptName = "";
                d.addressDetail = "";
                d.invoiceFixed = false;
                d.paymentType = 0;
                d.thirdType = 0;
                d.cashFee = 0;
                d.variationName = "";
                d.captured = false;
                d.date = null;
                d.variationOnlineLink = "";
                d.staff = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.variationUID != null && m.hasOwnProperty("variationUID")) {
                d.variationUID = m.variationUID;
            }
            if (m.dateUserUID != null && m.hasOwnProperty("dateUserUID")) {
                d.dateUserUID = m.dateUserUID;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.thirdCheckoutId != null && m.hasOwnProperty("thirdCheckoutId")) {
                d.thirdCheckoutId = m.thirdCheckoutId;
            }
            if (m.thirdPaymentId != null && m.hasOwnProperty("thirdPaymentId")) {
                d.thirdPaymentId = m.thirdPaymentId;
            }
            if (m.quantity != null && m.hasOwnProperty("quantity")) {
                d.quantity = m.quantity;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.trackingNumber != null && m.hasOwnProperty("trackingNumber")) {
                d.trackingNumber = m.trackingNumber;
            }
            if (m.trackingStatus != null && m.hasOwnProperty("trackingStatus")) {
                d.trackingStatus = m.trackingStatus;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            if (m.ticket != null && m.hasOwnProperty("ticket")) {
                d.ticket = $root.Proto.Ticket.toObject(m.ticket, o);
            }
            if (m.phone != null && m.hasOwnProperty("phone")) {
                d.phone = m.phone;
            }
            if (m.postalCode != null && m.hasOwnProperty("postalCode")) {
                d.postalCode = m.postalCode;
            }
            if (m.country != null && m.hasOwnProperty("country")) {
                d.country = m.country;
            }
            if (m.state != null && m.hasOwnProperty("state")) {
                d.state = m.state;
            }
            if (m.city != null && m.hasOwnProperty("city")) {
                d.city = m.city;
            }
            if (m.address != null && m.hasOwnProperty("address")) {
                d.address = m.address;
            }
            if (m.fee != null && m.hasOwnProperty("fee")) {
                d.fee = o.json && !isFinite(m.fee) ? String(m.fee) : m.fee;
            }
            if (m.total != null && m.hasOwnProperty("total")) {
                d.total = o.json && !isFinite(m.total) ? String(m.total) : m.total;
            }
            if (m.trackingCompany != null && m.hasOwnProperty("trackingCompany")) {
                d.trackingCompany = m.trackingCompany;
            }
            if (m.payFee != null && m.hasOwnProperty("payFee")) {
                d.payFee = o.json && !isFinite(m.payFee) ? String(m.payFee) : m.payFee;
            }
            if (m.userCancel != null && m.hasOwnProperty("userCancel")) {
                d.userCancel = m.userCancel;
            }
            if (m.refundDate != null && m.hasOwnProperty("refundDate")) {
                if (typeof m.refundDate === "number")
                    d.refundDate = o.longs === String ? String(m.refundDate) : m.refundDate;
                else
                    d.refundDate = o.longs === String ? $util.Long.prototype.toString.call(m.refundDate) : o.longs === Number ? new $util.LongBits(m.refundDate.low >>> 0, m.refundDate.high >>> 0).toNumber() : m.refundDate;
            }
            if (m.receiptName != null && m.hasOwnProperty("receiptName")) {
                d.receiptName = m.receiptName;
            }
            if (m.addressDetail != null && m.hasOwnProperty("addressDetail")) {
                d.addressDetail = m.addressDetail;
            }
            if (m.invoiceFixed != null && m.hasOwnProperty("invoiceFixed")) {
                d.invoiceFixed = m.invoiceFixed;
            }
            if (m.paymentType != null && m.hasOwnProperty("paymentType")) {
                d.paymentType = m.paymentType;
            }
            if (m.thirdType != null && m.hasOwnProperty("thirdType")) {
                d.thirdType = m.thirdType;
            }
            if (m.cashFee != null && m.hasOwnProperty("cashFee")) {
                d.cashFee = o.json && !isFinite(m.cashFee) ? String(m.cashFee) : m.cashFee;
            }
            if (m.variationName != null && m.hasOwnProperty("variationName")) {
                d.variationName = m.variationName;
            }
            if (m.captured != null && m.hasOwnProperty("captured")) {
                d.captured = m.captured;
            }
            if (m.date != null && m.hasOwnProperty("date")) {
                d.date = $root.Proto.TicketVariationScheduleDate.toObject(m.date, o);
            }
            if (m.questionInfoList && m.questionInfoList.length) {
                d.questionInfoList = [];
                for (var j = 0; j < m.questionInfoList.length; ++j) {
                    d.questionInfoList[j] = $root.Proto.TicketVariationScheduleUserInfo.toObject(m.questionInfoList[j], o);
                }
            }
            if (m.answerInfoList && m.answerInfoList.length) {
                d.answerInfoList = [];
                for (var j = 0; j < m.answerInfoList.length; ++j) {
                    d.answerInfoList[j] = $root.Proto.TicketVariationScheduleDateUserInfo.toObject(m.answerInfoList[j], o);
                }
            }
            if (m.variationOnlineLink != null && m.hasOwnProperty("variationOnlineLink")) {
                d.variationOnlineLink = m.variationOnlineLink;
            }
            if (m.staff != null && m.hasOwnProperty("staff")) {
                d.staff = $root.Proto.TicketStaff.toObject(m.staff, o);
            }
            return d;
        };

        /**
         * Converts this TicketVariationScheduleOrder to JSON.
         * @function toJSON
         * @memberof Proto.TicketVariationScheduleOrder
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketVariationScheduleOrder.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketVariationScheduleOrder;
    })();

    Proto.TicketVariationScheduleOrderList = (function() {

        /**
         * Properties of a TicketVariationScheduleOrderList.
         * @memberof Proto
         * @interface ITicketVariationScheduleOrderList
         * @property {Array.<Proto.ITicketVariationScheduleOrder>|null} [value] TicketVariationScheduleOrderList value
         */

        /**
         * Constructs a new TicketVariationScheduleOrderList.
         * @memberof Proto
         * @classdesc Represents a TicketVariationScheduleOrderList.
         * @implements ITicketVariationScheduleOrderList
         * @constructor
         * @param {Proto.ITicketVariationScheduleOrderList=} [p] Properties to set
         */
        function TicketVariationScheduleOrderList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketVariationScheduleOrderList value.
         * @member {Array.<Proto.ITicketVariationScheduleOrder>} value
         * @memberof Proto.TicketVariationScheduleOrderList
         * @instance
         */
        TicketVariationScheduleOrderList.prototype.value = $util.emptyArray;

        /**
         * Creates a new TicketVariationScheduleOrderList instance using the specified properties.
         * @function create
         * @memberof Proto.TicketVariationScheduleOrderList
         * @static
         * @param {Proto.ITicketVariationScheduleOrderList=} [properties] Properties to set
         * @returns {Proto.TicketVariationScheduleOrderList} TicketVariationScheduleOrderList instance
         */
        TicketVariationScheduleOrderList.create = function create(properties) {
            return new TicketVariationScheduleOrderList(properties);
        };

        /**
         * Encodes the specified TicketVariationScheduleOrderList message. Does not implicitly {@link Proto.TicketVariationScheduleOrderList.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketVariationScheduleOrderList
         * @static
         * @param {Proto.ITicketVariationScheduleOrderList} m TicketVariationScheduleOrderList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketVariationScheduleOrderList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.TicketVariationScheduleOrder.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a TicketVariationScheduleOrderList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketVariationScheduleOrderList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketVariationScheduleOrderList} TicketVariationScheduleOrderList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketVariationScheduleOrderList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketVariationScheduleOrderList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.TicketVariationScheduleOrder.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketVariationScheduleOrderList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketVariationScheduleOrderList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketVariationScheduleOrderList} TicketVariationScheduleOrderList
         */
        TicketVariationScheduleOrderList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketVariationScheduleOrderList)
                return d;
            var m = new $root.Proto.TicketVariationScheduleOrderList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.TicketVariationScheduleOrderList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.TicketVariationScheduleOrderList.value: object expected");
                    m.value[i] = $root.Proto.TicketVariationScheduleOrder.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketVariationScheduleOrderList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketVariationScheduleOrderList
         * @static
         * @param {Proto.TicketVariationScheduleOrderList} m TicketVariationScheduleOrderList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketVariationScheduleOrderList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.TicketVariationScheduleOrder.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this TicketVariationScheduleOrderList to JSON.
         * @function toJSON
         * @memberof Proto.TicketVariationScheduleOrderList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketVariationScheduleOrderList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketVariationScheduleOrderList;
    })();

    Proto.Reaction = (function() {

        /**
         * Properties of a Reaction.
         * @memberof Proto
         * @interface IReaction
         * @property {string|null} [uid] Reaction uid
         * @property {number|null} [type] Reaction type
         * @property {string|null} [text] Reaction text
         * @property {Proto.IUploadFile|null} [file] Reaction file
         * @property {number|Long|null} [registerDate] Reaction registerDate
         * @property {number|Long|null} [modifyDate] Reaction modifyDate
         */

        /**
         * Constructs a new Reaction.
         * @memberof Proto
         * @classdesc Represents a Reaction.
         * @implements IReaction
         * @constructor
         * @param {Proto.IReaction=} [p] Properties to set
         */
        function Reaction(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Reaction uid.
         * @member {string} uid
         * @memberof Proto.Reaction
         * @instance
         */
        Reaction.prototype.uid = "";

        /**
         * Reaction type.
         * @member {number} type
         * @memberof Proto.Reaction
         * @instance
         */
        Reaction.prototype.type = 0;

        /**
         * Reaction text.
         * @member {string} text
         * @memberof Proto.Reaction
         * @instance
         */
        Reaction.prototype.text = "";

        /**
         * Reaction file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.Reaction
         * @instance
         */
        Reaction.prototype.file = null;

        /**
         * Reaction registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.Reaction
         * @instance
         */
        Reaction.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Reaction modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.Reaction
         * @instance
         */
        Reaction.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Reaction instance using the specified properties.
         * @function create
         * @memberof Proto.Reaction
         * @static
         * @param {Proto.IReaction=} [properties] Properties to set
         * @returns {Proto.Reaction} Reaction instance
         */
        Reaction.create = function create(properties) {
            return new Reaction(properties);
        };

        /**
         * Encodes the specified Reaction message. Does not implicitly {@link Proto.Reaction.verify|verify} messages.
         * @function encode
         * @memberof Proto.Reaction
         * @static
         * @param {Proto.IReaction} m Reaction message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Reaction.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(34).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(40).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(48).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a Reaction message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.Reaction
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.Reaction} Reaction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Reaction.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.Reaction();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 5:
                    m.registerDate = r.int64();
                    break;
                case 6:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Reaction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.Reaction
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.Reaction} Reaction
         */
        Reaction.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.Reaction)
                return d;
            var m = new $root.Proto.Reaction();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.Reaction.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a Reaction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.Reaction
         * @static
         * @param {Proto.Reaction} m Reaction
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Reaction.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.text = "";
                d.file = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this Reaction to JSON.
         * @function toJSON
         * @memberof Proto.Reaction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Reaction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Reaction;
    })();

    Proto.ReactionList = (function() {

        /**
         * Properties of a ReactionList.
         * @memberof Proto
         * @interface IReactionList
         * @property {Array.<Proto.IReaction>|null} [value] ReactionList value
         */

        /**
         * Constructs a new ReactionList.
         * @memberof Proto
         * @classdesc Represents a ReactionList.
         * @implements IReactionList
         * @constructor
         * @param {Proto.IReactionList=} [p] Properties to set
         */
        function ReactionList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ReactionList value.
         * @member {Array.<Proto.IReaction>} value
         * @memberof Proto.ReactionList
         * @instance
         */
        ReactionList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ReactionList instance using the specified properties.
         * @function create
         * @memberof Proto.ReactionList
         * @static
         * @param {Proto.IReactionList=} [properties] Properties to set
         * @returns {Proto.ReactionList} ReactionList instance
         */
        ReactionList.create = function create(properties) {
            return new ReactionList(properties);
        };

        /**
         * Encodes the specified ReactionList message. Does not implicitly {@link Proto.ReactionList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ReactionList
         * @static
         * @param {Proto.IReactionList} m ReactionList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReactionList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.Reaction.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ReactionList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ReactionList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ReactionList} ReactionList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReactionList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ReactionList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.Reaction.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ReactionList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ReactionList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ReactionList} ReactionList
         */
        ReactionList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ReactionList)
                return d;
            var m = new $root.Proto.ReactionList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ReactionList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ReactionList.value: object expected");
                    m.value[i] = $root.Proto.Reaction.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ReactionList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ReactionList
         * @static
         * @param {Proto.ReactionList} m ReactionList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReactionList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.Reaction.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ReactionList to JSON.
         * @function toJSON
         * @memberof Proto.ReactionList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReactionList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ReactionList;
    })();

    Proto.ContentReaction = (function() {

        /**
         * Properties of a ContentReaction.
         * @memberof Proto
         * @interface IContentReaction
         * @property {string|null} [reactionUID] ContentReaction reactionUID
         * @property {string|null} [reactionCount] ContentReaction reactionCount
         * @property {number|Long|null} [registerDate] ContentReaction registerDate
         * @property {number|Long|null} [modifyDate] ContentReaction modifyDate
         * @property {string|null} [contentUID] ContentReaction contentUID
         */

        /**
         * Constructs a new ContentReaction.
         * @memberof Proto
         * @classdesc Represents a ContentReaction.
         * @implements IContentReaction
         * @constructor
         * @param {Proto.IContentReaction=} [p] Properties to set
         */
        function ContentReaction(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentReaction reactionUID.
         * @member {string} reactionUID
         * @memberof Proto.ContentReaction
         * @instance
         */
        ContentReaction.prototype.reactionUID = "";

        /**
         * ContentReaction reactionCount.
         * @member {string} reactionCount
         * @memberof Proto.ContentReaction
         * @instance
         */
        ContentReaction.prototype.reactionCount = "";

        /**
         * ContentReaction registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentReaction
         * @instance
         */
        ContentReaction.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentReaction modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentReaction
         * @instance
         */
        ContentReaction.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentReaction contentUID.
         * @member {string} contentUID
         * @memberof Proto.ContentReaction
         * @instance
         */
        ContentReaction.prototype.contentUID = "";

        /**
         * Creates a new ContentReaction instance using the specified properties.
         * @function create
         * @memberof Proto.ContentReaction
         * @static
         * @param {Proto.IContentReaction=} [properties] Properties to set
         * @returns {Proto.ContentReaction} ContentReaction instance
         */
        ContentReaction.create = function create(properties) {
            return new ContentReaction(properties);
        };

        /**
         * Encodes the specified ContentReaction message. Does not implicitly {@link Proto.ContentReaction.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentReaction
         * @static
         * @param {Proto.IContentReaction} m ContentReaction message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentReaction.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.reactionUID != null && Object.hasOwnProperty.call(m, "reactionUID"))
                w.uint32(10).string(m.reactionUID);
            if (m.reactionCount != null && Object.hasOwnProperty.call(m, "reactionCount"))
                w.uint32(18).string(m.reactionCount);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(32).int64(m.modifyDate);
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(42).string(m.contentUID);
            return w;
        };

        /**
         * Decodes a ContentReaction message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentReaction
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentReaction} ContentReaction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentReaction.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentReaction();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.reactionUID = r.string();
                    break;
                case 2:
                    m.reactionCount = r.string();
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    m.modifyDate = r.int64();
                    break;
                case 5:
                    m.contentUID = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentReaction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentReaction
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentReaction} ContentReaction
         */
        ContentReaction.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentReaction)
                return d;
            var m = new $root.Proto.ContentReaction();
            if (d.reactionUID != null) {
                m.reactionUID = String(d.reactionUID);
            }
            if (d.reactionCount != null) {
                m.reactionCount = String(d.reactionCount);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentReaction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentReaction
         * @static
         * @param {Proto.ContentReaction} m ContentReaction
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentReaction.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.reactionUID = "";
                d.reactionCount = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.contentUID = "";
            }
            if (m.reactionUID != null && m.hasOwnProperty("reactionUID")) {
                d.reactionUID = m.reactionUID;
            }
            if (m.reactionCount != null && m.hasOwnProperty("reactionCount")) {
                d.reactionCount = m.reactionCount;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            return d;
        };

        /**
         * Converts this ContentReaction to JSON.
         * @function toJSON
         * @memberof Proto.ContentReaction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentReaction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentReaction;
    })();

    Proto.ContentReactionList = (function() {

        /**
         * Properties of a ContentReactionList.
         * @memberof Proto
         * @interface IContentReactionList
         * @property {Array.<Proto.IContentReaction>|null} [value] ContentReactionList value
         */

        /**
         * Constructs a new ContentReactionList.
         * @memberof Proto
         * @classdesc Represents a ContentReactionList.
         * @implements IContentReactionList
         * @constructor
         * @param {Proto.IContentReactionList=} [p] Properties to set
         */
        function ContentReactionList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentReactionList value.
         * @member {Array.<Proto.IContentReaction>} value
         * @memberof Proto.ContentReactionList
         * @instance
         */
        ContentReactionList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentReactionList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentReactionList
         * @static
         * @param {Proto.IContentReactionList=} [properties] Properties to set
         * @returns {Proto.ContentReactionList} ContentReactionList instance
         */
        ContentReactionList.create = function create(properties) {
            return new ContentReactionList(properties);
        };

        /**
         * Encodes the specified ContentReactionList message. Does not implicitly {@link Proto.ContentReactionList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentReactionList
         * @static
         * @param {Proto.IContentReactionList} m ContentReactionList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentReactionList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentReaction.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentReactionList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentReactionList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentReactionList} ContentReactionList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentReactionList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentReactionList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentReaction.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentReactionList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentReactionList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentReactionList} ContentReactionList
         */
        ContentReactionList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentReactionList)
                return d;
            var m = new $root.Proto.ContentReactionList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentReactionList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentReactionList.value: object expected");
                    m.value[i] = $root.Proto.ContentReaction.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentReactionList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentReactionList
         * @static
         * @param {Proto.ContentReactionList} m ContentReactionList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentReactionList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentReaction.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentReactionList to JSON.
         * @function toJSON
         * @memberof Proto.ContentReactionList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentReactionList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentReactionList;
    })();

    Proto.ContentCommentReaction = (function() {

        /**
         * Properties of a ContentCommentReaction.
         * @memberof Proto
         * @interface IContentCommentReaction
         * @property {string|null} [reactionUID] ContentCommentReaction reactionUID
         * @property {string|null} [reactionCount] ContentCommentReaction reactionCount
         * @property {number|Long|null} [registerDate] ContentCommentReaction registerDate
         * @property {number|Long|null} [modifyDate] ContentCommentReaction modifyDate
         * @property {string|null} [commentUID] ContentCommentReaction commentUID
         */

        /**
         * Constructs a new ContentCommentReaction.
         * @memberof Proto
         * @classdesc Represents a ContentCommentReaction.
         * @implements IContentCommentReaction
         * @constructor
         * @param {Proto.IContentCommentReaction=} [p] Properties to set
         */
        function ContentCommentReaction(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentCommentReaction reactionUID.
         * @member {string} reactionUID
         * @memberof Proto.ContentCommentReaction
         * @instance
         */
        ContentCommentReaction.prototype.reactionUID = "";

        /**
         * ContentCommentReaction reactionCount.
         * @member {string} reactionCount
         * @memberof Proto.ContentCommentReaction
         * @instance
         */
        ContentCommentReaction.prototype.reactionCount = "";

        /**
         * ContentCommentReaction registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentCommentReaction
         * @instance
         */
        ContentCommentReaction.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommentReaction modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentCommentReaction
         * @instance
         */
        ContentCommentReaction.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentCommentReaction commentUID.
         * @member {string} commentUID
         * @memberof Proto.ContentCommentReaction
         * @instance
         */
        ContentCommentReaction.prototype.commentUID = "";

        /**
         * Creates a new ContentCommentReaction instance using the specified properties.
         * @function create
         * @memberof Proto.ContentCommentReaction
         * @static
         * @param {Proto.IContentCommentReaction=} [properties] Properties to set
         * @returns {Proto.ContentCommentReaction} ContentCommentReaction instance
         */
        ContentCommentReaction.create = function create(properties) {
            return new ContentCommentReaction(properties);
        };

        /**
         * Encodes the specified ContentCommentReaction message. Does not implicitly {@link Proto.ContentCommentReaction.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentCommentReaction
         * @static
         * @param {Proto.IContentCommentReaction} m ContentCommentReaction message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentCommentReaction.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.reactionUID != null && Object.hasOwnProperty.call(m, "reactionUID"))
                w.uint32(10).string(m.reactionUID);
            if (m.reactionCount != null && Object.hasOwnProperty.call(m, "reactionCount"))
                w.uint32(18).string(m.reactionCount);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(32).int64(m.modifyDate);
            if (m.commentUID != null && Object.hasOwnProperty.call(m, "commentUID"))
                w.uint32(42).string(m.commentUID);
            return w;
        };

        /**
         * Decodes a ContentCommentReaction message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentCommentReaction
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentCommentReaction} ContentCommentReaction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentCommentReaction.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentCommentReaction();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.reactionUID = r.string();
                    break;
                case 2:
                    m.reactionCount = r.string();
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    m.modifyDate = r.int64();
                    break;
                case 5:
                    m.commentUID = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentCommentReaction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentCommentReaction
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentCommentReaction} ContentCommentReaction
         */
        ContentCommentReaction.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentCommentReaction)
                return d;
            var m = new $root.Proto.ContentCommentReaction();
            if (d.reactionUID != null) {
                m.reactionUID = String(d.reactionUID);
            }
            if (d.reactionCount != null) {
                m.reactionCount = String(d.reactionCount);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.commentUID != null) {
                m.commentUID = String(d.commentUID);
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentCommentReaction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentCommentReaction
         * @static
         * @param {Proto.ContentCommentReaction} m ContentCommentReaction
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentCommentReaction.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.reactionUID = "";
                d.reactionCount = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.commentUID = "";
            }
            if (m.reactionUID != null && m.hasOwnProperty("reactionUID")) {
                d.reactionUID = m.reactionUID;
            }
            if (m.reactionCount != null && m.hasOwnProperty("reactionCount")) {
                d.reactionCount = m.reactionCount;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.commentUID != null && m.hasOwnProperty("commentUID")) {
                d.commentUID = m.commentUID;
            }
            return d;
        };

        /**
         * Converts this ContentCommentReaction to JSON.
         * @function toJSON
         * @memberof Proto.ContentCommentReaction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentCommentReaction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentCommentReaction;
    })();

    Proto.ContentCommentReactionList = (function() {

        /**
         * Properties of a ContentCommentReactionList.
         * @memberof Proto
         * @interface IContentCommentReactionList
         * @property {Array.<Proto.IContentCommentReaction>|null} [value] ContentCommentReactionList value
         */

        /**
         * Constructs a new ContentCommentReactionList.
         * @memberof Proto
         * @classdesc Represents a ContentCommentReactionList.
         * @implements IContentCommentReactionList
         * @constructor
         * @param {Proto.IContentCommentReactionList=} [p] Properties to set
         */
        function ContentCommentReactionList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentCommentReactionList value.
         * @member {Array.<Proto.IContentCommentReaction>} value
         * @memberof Proto.ContentCommentReactionList
         * @instance
         */
        ContentCommentReactionList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentCommentReactionList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentCommentReactionList
         * @static
         * @param {Proto.IContentCommentReactionList=} [properties] Properties to set
         * @returns {Proto.ContentCommentReactionList} ContentCommentReactionList instance
         */
        ContentCommentReactionList.create = function create(properties) {
            return new ContentCommentReactionList(properties);
        };

        /**
         * Encodes the specified ContentCommentReactionList message. Does not implicitly {@link Proto.ContentCommentReactionList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentCommentReactionList
         * @static
         * @param {Proto.IContentCommentReactionList} m ContentCommentReactionList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentCommentReactionList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentCommentReaction.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentCommentReactionList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentCommentReactionList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentCommentReactionList} ContentCommentReactionList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentCommentReactionList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentCommentReactionList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentCommentReaction.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentCommentReactionList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentCommentReactionList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentCommentReactionList} ContentCommentReactionList
         */
        ContentCommentReactionList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentCommentReactionList)
                return d;
            var m = new $root.Proto.ContentCommentReactionList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentCommentReactionList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentCommentReactionList.value: object expected");
                    m.value[i] = $root.Proto.ContentCommentReaction.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentCommentReactionList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentCommentReactionList
         * @static
         * @param {Proto.ContentCommentReactionList} m ContentCommentReactionList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentCommentReactionList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentCommentReaction.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentCommentReactionList to JSON.
         * @function toJSON
         * @memberof Proto.ContentCommentReactionList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentCommentReactionList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentCommentReactionList;
    })();

    Proto.BoardChatRoom = (function() {

        /**
         * Properties of a BoardChatRoom.
         * @memberof Proto
         * @interface IBoardChatRoom
         * @property {string|null} [companyUID] BoardChatRoom companyUID
         * @property {Proto.ICommunityBoardGroup|null} [group] BoardChatRoom group
         * @property {Proto.IBoardChat|null} [chat] BoardChatRoom chat
         * @property {Proto.IUploadFile|null} [companyIcon] BoardChatRoom companyIcon
         * @property {Proto.ICompanyLogo|null} [companyLogo] BoardChatRoom companyLogo
         * @property {Proto.ICompanyLP|null} [companyLp] BoardChatRoom companyLp
         * @property {Proto.IUser|null} [targetUser] BoardChatRoom targetUser
         * @property {string|null} [companyNickname] BoardChatRoom companyNickname
         */

        /**
         * Constructs a new BoardChatRoom.
         * @memberof Proto
         * @classdesc Represents a BoardChatRoom.
         * @implements IBoardChatRoom
         * @constructor
         * @param {Proto.IBoardChatRoom=} [p] Properties to set
         */
        function BoardChatRoom(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * BoardChatRoom companyUID.
         * @member {string} companyUID
         * @memberof Proto.BoardChatRoom
         * @instance
         */
        BoardChatRoom.prototype.companyUID = "";

        /**
         * BoardChatRoom group.
         * @member {Proto.ICommunityBoardGroup|null|undefined} group
         * @memberof Proto.BoardChatRoom
         * @instance
         */
        BoardChatRoom.prototype.group = null;

        /**
         * BoardChatRoom chat.
         * @member {Proto.IBoardChat|null|undefined} chat
         * @memberof Proto.BoardChatRoom
         * @instance
         */
        BoardChatRoom.prototype.chat = null;

        /**
         * BoardChatRoom companyIcon.
         * @member {Proto.IUploadFile|null|undefined} companyIcon
         * @memberof Proto.BoardChatRoom
         * @instance
         */
        BoardChatRoom.prototype.companyIcon = null;

        /**
         * BoardChatRoom companyLogo.
         * @member {Proto.ICompanyLogo|null|undefined} companyLogo
         * @memberof Proto.BoardChatRoom
         * @instance
         */
        BoardChatRoom.prototype.companyLogo = null;

        /**
         * BoardChatRoom companyLp.
         * @member {Proto.ICompanyLP|null|undefined} companyLp
         * @memberof Proto.BoardChatRoom
         * @instance
         */
        BoardChatRoom.prototype.companyLp = null;

        /**
         * BoardChatRoom targetUser.
         * @member {Proto.IUser|null|undefined} targetUser
         * @memberof Proto.BoardChatRoom
         * @instance
         */
        BoardChatRoom.prototype.targetUser = null;

        /**
         * BoardChatRoom companyNickname.
         * @member {string} companyNickname
         * @memberof Proto.BoardChatRoom
         * @instance
         */
        BoardChatRoom.prototype.companyNickname = "";

        /**
         * Creates a new BoardChatRoom instance using the specified properties.
         * @function create
         * @memberof Proto.BoardChatRoom
         * @static
         * @param {Proto.IBoardChatRoom=} [properties] Properties to set
         * @returns {Proto.BoardChatRoom} BoardChatRoom instance
         */
        BoardChatRoom.create = function create(properties) {
            return new BoardChatRoom(properties);
        };

        /**
         * Encodes the specified BoardChatRoom message. Does not implicitly {@link Proto.BoardChatRoom.verify|verify} messages.
         * @function encode
         * @memberof Proto.BoardChatRoom
         * @static
         * @param {Proto.IBoardChatRoom} m BoardChatRoom message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BoardChatRoom.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(10).string(m.companyUID);
            if (m.group != null && Object.hasOwnProperty.call(m, "group"))
                $root.Proto.CommunityBoardGroup.encode(m.group, w.uint32(18).fork()).ldelim();
            if (m.chat != null && Object.hasOwnProperty.call(m, "chat"))
                $root.Proto.BoardChat.encode(m.chat, w.uint32(26).fork()).ldelim();
            if (m.companyIcon != null && Object.hasOwnProperty.call(m, "companyIcon"))
                $root.Proto.UploadFile.encode(m.companyIcon, w.uint32(34).fork()).ldelim();
            if (m.companyLogo != null && Object.hasOwnProperty.call(m, "companyLogo"))
                $root.Proto.CompanyLogo.encode(m.companyLogo, w.uint32(42).fork()).ldelim();
            if (m.companyLp != null && Object.hasOwnProperty.call(m, "companyLp"))
                $root.Proto.CompanyLP.encode(m.companyLp, w.uint32(50).fork()).ldelim();
            if (m.targetUser != null && Object.hasOwnProperty.call(m, "targetUser"))
                $root.Proto.User.encode(m.targetUser, w.uint32(58).fork()).ldelim();
            if (m.companyNickname != null && Object.hasOwnProperty.call(m, "companyNickname"))
                w.uint32(66).string(m.companyNickname);
            return w;
        };

        /**
         * Decodes a BoardChatRoom message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.BoardChatRoom
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.BoardChatRoom} BoardChatRoom
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BoardChatRoom.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.BoardChatRoom();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.companyUID = r.string();
                    break;
                case 2:
                    m.group = $root.Proto.CommunityBoardGroup.decode(r, r.uint32());
                    break;
                case 3:
                    m.chat = $root.Proto.BoardChat.decode(r, r.uint32());
                    break;
                case 4:
                    m.companyIcon = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 5:
                    m.companyLogo = $root.Proto.CompanyLogo.decode(r, r.uint32());
                    break;
                case 6:
                    m.companyLp = $root.Proto.CompanyLP.decode(r, r.uint32());
                    break;
                case 7:
                    m.targetUser = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 8:
                    m.companyNickname = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a BoardChatRoom message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.BoardChatRoom
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.BoardChatRoom} BoardChatRoom
         */
        BoardChatRoom.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.BoardChatRoom)
                return d;
            var m = new $root.Proto.BoardChatRoom();
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.group != null) {
                if (typeof d.group !== "object")
                    throw TypeError(".Proto.BoardChatRoom.group: object expected");
                m.group = $root.Proto.CommunityBoardGroup.fromObject(d.group);
            }
            if (d.chat != null) {
                if (typeof d.chat !== "object")
                    throw TypeError(".Proto.BoardChatRoom.chat: object expected");
                m.chat = $root.Proto.BoardChat.fromObject(d.chat);
            }
            if (d.companyIcon != null) {
                if (typeof d.companyIcon !== "object")
                    throw TypeError(".Proto.BoardChatRoom.companyIcon: object expected");
                m.companyIcon = $root.Proto.UploadFile.fromObject(d.companyIcon);
            }
            if (d.companyLogo != null) {
                if (typeof d.companyLogo !== "object")
                    throw TypeError(".Proto.BoardChatRoom.companyLogo: object expected");
                m.companyLogo = $root.Proto.CompanyLogo.fromObject(d.companyLogo);
            }
            if (d.companyLp != null) {
                if (typeof d.companyLp !== "object")
                    throw TypeError(".Proto.BoardChatRoom.companyLp: object expected");
                m.companyLp = $root.Proto.CompanyLP.fromObject(d.companyLp);
            }
            if (d.targetUser != null) {
                if (typeof d.targetUser !== "object")
                    throw TypeError(".Proto.BoardChatRoom.targetUser: object expected");
                m.targetUser = $root.Proto.User.fromObject(d.targetUser);
            }
            if (d.companyNickname != null) {
                m.companyNickname = String(d.companyNickname);
            }
            return m;
        };

        /**
         * Creates a plain object from a BoardChatRoom message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.BoardChatRoom
         * @static
         * @param {Proto.BoardChatRoom} m BoardChatRoom
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BoardChatRoom.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.companyUID = "";
                d.group = null;
                d.chat = null;
                d.companyIcon = null;
                d.companyLogo = null;
                d.companyLp = null;
                d.targetUser = null;
                d.companyNickname = "";
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.group != null && m.hasOwnProperty("group")) {
                d.group = $root.Proto.CommunityBoardGroup.toObject(m.group, o);
            }
            if (m.chat != null && m.hasOwnProperty("chat")) {
                d.chat = $root.Proto.BoardChat.toObject(m.chat, o);
            }
            if (m.companyIcon != null && m.hasOwnProperty("companyIcon")) {
                d.companyIcon = $root.Proto.UploadFile.toObject(m.companyIcon, o);
            }
            if (m.companyLogo != null && m.hasOwnProperty("companyLogo")) {
                d.companyLogo = $root.Proto.CompanyLogo.toObject(m.companyLogo, o);
            }
            if (m.companyLp != null && m.hasOwnProperty("companyLp")) {
                d.companyLp = $root.Proto.CompanyLP.toObject(m.companyLp, o);
            }
            if (m.targetUser != null && m.hasOwnProperty("targetUser")) {
                d.targetUser = $root.Proto.User.toObject(m.targetUser, o);
            }
            if (m.companyNickname != null && m.hasOwnProperty("companyNickname")) {
                d.companyNickname = m.companyNickname;
            }
            return d;
        };

        /**
         * Converts this BoardChatRoom to JSON.
         * @function toJSON
         * @memberof Proto.BoardChatRoom
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BoardChatRoom.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BoardChatRoom;
    })();

    Proto.BoardChatRoomList = (function() {

        /**
         * Properties of a BoardChatRoomList.
         * @memberof Proto
         * @interface IBoardChatRoomList
         * @property {Array.<Proto.IBoardChatRoom>|null} [value] BoardChatRoomList value
         */

        /**
         * Constructs a new BoardChatRoomList.
         * @memberof Proto
         * @classdesc Represents a BoardChatRoomList.
         * @implements IBoardChatRoomList
         * @constructor
         * @param {Proto.IBoardChatRoomList=} [p] Properties to set
         */
        function BoardChatRoomList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * BoardChatRoomList value.
         * @member {Array.<Proto.IBoardChatRoom>} value
         * @memberof Proto.BoardChatRoomList
         * @instance
         */
        BoardChatRoomList.prototype.value = $util.emptyArray;

        /**
         * Creates a new BoardChatRoomList instance using the specified properties.
         * @function create
         * @memberof Proto.BoardChatRoomList
         * @static
         * @param {Proto.IBoardChatRoomList=} [properties] Properties to set
         * @returns {Proto.BoardChatRoomList} BoardChatRoomList instance
         */
        BoardChatRoomList.create = function create(properties) {
            return new BoardChatRoomList(properties);
        };

        /**
         * Encodes the specified BoardChatRoomList message. Does not implicitly {@link Proto.BoardChatRoomList.verify|verify} messages.
         * @function encode
         * @memberof Proto.BoardChatRoomList
         * @static
         * @param {Proto.IBoardChatRoomList} m BoardChatRoomList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BoardChatRoomList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.BoardChatRoom.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a BoardChatRoomList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.BoardChatRoomList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.BoardChatRoomList} BoardChatRoomList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BoardChatRoomList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.BoardChatRoomList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.BoardChatRoom.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a BoardChatRoomList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.BoardChatRoomList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.BoardChatRoomList} BoardChatRoomList
         */
        BoardChatRoomList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.BoardChatRoomList)
                return d;
            var m = new $root.Proto.BoardChatRoomList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.BoardChatRoomList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.BoardChatRoomList.value: object expected");
                    m.value[i] = $root.Proto.BoardChatRoom.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a BoardChatRoomList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.BoardChatRoomList
         * @static
         * @param {Proto.BoardChatRoomList} m BoardChatRoomList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BoardChatRoomList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.BoardChatRoom.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this BoardChatRoomList to JSON.
         * @function toJSON
         * @memberof Proto.BoardChatRoomList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BoardChatRoomList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BoardChatRoomList;
    })();

    Proto.CompanyAppPrototype = (function() {

        /**
         * Properties of a CompanyAppPrototype.
         * @memberof Proto
         * @interface ICompanyAppPrototype
         * @property {string|null} [uid] CompanyAppPrototype uid
         * @property {string|null} [companyUID] CompanyAppPrototype companyUID
         * @property {string|null} [name] CompanyAppPrototype name
         * @property {string|null} [content] CompanyAppPrototype content
         * @property {number|Long|null} [registerDate] CompanyAppPrototype registerDate
         * @property {number|Long|null} [modifyDate] CompanyAppPrototype modifyDate
         * @property {number|null} [status] CompanyAppPrototype status
         */

        /**
         * Constructs a new CompanyAppPrototype.
         * @memberof Proto
         * @classdesc Represents a CompanyAppPrototype.
         * @implements ICompanyAppPrototype
         * @constructor
         * @param {Proto.ICompanyAppPrototype=} [p] Properties to set
         */
        function CompanyAppPrototype(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyAppPrototype uid.
         * @member {string} uid
         * @memberof Proto.CompanyAppPrototype
         * @instance
         */
        CompanyAppPrototype.prototype.uid = "";

        /**
         * CompanyAppPrototype companyUID.
         * @member {string} companyUID
         * @memberof Proto.CompanyAppPrototype
         * @instance
         */
        CompanyAppPrototype.prototype.companyUID = "";

        /**
         * CompanyAppPrototype name.
         * @member {string} name
         * @memberof Proto.CompanyAppPrototype
         * @instance
         */
        CompanyAppPrototype.prototype.name = "";

        /**
         * CompanyAppPrototype content.
         * @member {string} content
         * @memberof Proto.CompanyAppPrototype
         * @instance
         */
        CompanyAppPrototype.prototype.content = "";

        /**
         * CompanyAppPrototype registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CompanyAppPrototype
         * @instance
         */
        CompanyAppPrototype.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyAppPrototype modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CompanyAppPrototype
         * @instance
         */
        CompanyAppPrototype.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyAppPrototype status.
         * @member {number} status
         * @memberof Proto.CompanyAppPrototype
         * @instance
         */
        CompanyAppPrototype.prototype.status = 0;

        /**
         * Creates a new CompanyAppPrototype instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyAppPrototype
         * @static
         * @param {Proto.ICompanyAppPrototype=} [properties] Properties to set
         * @returns {Proto.CompanyAppPrototype} CompanyAppPrototype instance
         */
        CompanyAppPrototype.create = function create(properties) {
            return new CompanyAppPrototype(properties);
        };

        /**
         * Encodes the specified CompanyAppPrototype message. Does not implicitly {@link Proto.CompanyAppPrototype.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyAppPrototype
         * @static
         * @param {Proto.ICompanyAppPrototype} m CompanyAppPrototype message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyAppPrototype.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(18).string(m.companyUID);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.content != null && Object.hasOwnProperty.call(m, "content"))
                w.uint32(34).string(m.content);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(40).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(48).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(56).int32(m.status);
            return w;
        };

        /**
         * Decodes a CompanyAppPrototype message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyAppPrototype
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyAppPrototype} CompanyAppPrototype
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyAppPrototype.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyAppPrototype();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.companyUID = r.string();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.content = r.string();
                    break;
                case 5:
                    m.registerDate = r.int64();
                    break;
                case 6:
                    m.modifyDate = r.int64();
                    break;
                case 7:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyAppPrototype message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyAppPrototype
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyAppPrototype} CompanyAppPrototype
         */
        CompanyAppPrototype.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyAppPrototype)
                return d;
            var m = new $root.Proto.CompanyAppPrototype();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.content != null) {
                m.content = String(d.content);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyAppPrototype message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyAppPrototype
         * @static
         * @param {Proto.CompanyAppPrototype} m CompanyAppPrototype
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyAppPrototype.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.companyUID = "";
                d.name = "";
                d.content = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.content != null && m.hasOwnProperty("content")) {
                d.content = m.content;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this CompanyAppPrototype to JSON.
         * @function toJSON
         * @memberof Proto.CompanyAppPrototype
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyAppPrototype.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyAppPrototype;
    })();

    Proto.CompanyAppPrototypeList = (function() {

        /**
         * Properties of a CompanyAppPrototypeList.
         * @memberof Proto
         * @interface ICompanyAppPrototypeList
         * @property {Array.<Proto.ICompanyAppPrototype>|null} [value] CompanyAppPrototypeList value
         */

        /**
         * Constructs a new CompanyAppPrototypeList.
         * @memberof Proto
         * @classdesc Represents a CompanyAppPrototypeList.
         * @implements ICompanyAppPrototypeList
         * @constructor
         * @param {Proto.ICompanyAppPrototypeList=} [p] Properties to set
         */
        function CompanyAppPrototypeList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyAppPrototypeList value.
         * @member {Array.<Proto.ICompanyAppPrototype>} value
         * @memberof Proto.CompanyAppPrototypeList
         * @instance
         */
        CompanyAppPrototypeList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CompanyAppPrototypeList instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyAppPrototypeList
         * @static
         * @param {Proto.ICompanyAppPrototypeList=} [properties] Properties to set
         * @returns {Proto.CompanyAppPrototypeList} CompanyAppPrototypeList instance
         */
        CompanyAppPrototypeList.create = function create(properties) {
            return new CompanyAppPrototypeList(properties);
        };

        /**
         * Encodes the specified CompanyAppPrototypeList message. Does not implicitly {@link Proto.CompanyAppPrototypeList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyAppPrototypeList
         * @static
         * @param {Proto.ICompanyAppPrototypeList} m CompanyAppPrototypeList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyAppPrototypeList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CompanyAppPrototype.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CompanyAppPrototypeList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyAppPrototypeList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyAppPrototypeList} CompanyAppPrototypeList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyAppPrototypeList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyAppPrototypeList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CompanyAppPrototype.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyAppPrototypeList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyAppPrototypeList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyAppPrototypeList} CompanyAppPrototypeList
         */
        CompanyAppPrototypeList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyAppPrototypeList)
                return d;
            var m = new $root.Proto.CompanyAppPrototypeList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CompanyAppPrototypeList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CompanyAppPrototypeList.value: object expected");
                    m.value[i] = $root.Proto.CompanyAppPrototype.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyAppPrototypeList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyAppPrototypeList
         * @static
         * @param {Proto.CompanyAppPrototypeList} m CompanyAppPrototypeList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyAppPrototypeList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CompanyAppPrototype.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CompanyAppPrototypeList to JSON.
         * @function toJSON
         * @memberof Proto.CompanyAppPrototypeList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyAppPrototypeList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyAppPrototypeList;
    })();

    Proto.ByteLiveDetail = (function() {

        /**
         * Properties of a ByteLiveDetail.
         * @memberof Proto
         * @interface IByteLiveDetail
         * @property {string|null} [streamId] ByteLiveDetail streamId
         * @property {string|null} [pushUrl] ByteLiveDetail pushUrl
         * @property {string|null} [pushKey] ByteLiveDetail pushKey
         * @property {string|null} [rtmUrl] ByteLiveDetail rtmUrl
         * @property {string|null} [playUrl] ByteLiveDetail playUrl
         * @property {number|Long|null} [startDate] ByteLiveDetail startDate
         * @property {string|null} [playSDUrl] ByteLiveDetail playSDUrl
         * @property {string|null} [playHDUrl] ByteLiveDetail playHDUrl
         * @property {number|Long|null} [pushUrlExpireDate] ByteLiveDetail pushUrlExpireDate
         */

        /**
         * Constructs a new ByteLiveDetail.
         * @memberof Proto
         * @classdesc Represents a ByteLiveDetail.
         * @implements IByteLiveDetail
         * @constructor
         * @param {Proto.IByteLiveDetail=} [p] Properties to set
         */
        function ByteLiveDetail(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ByteLiveDetail streamId.
         * @member {string} streamId
         * @memberof Proto.ByteLiveDetail
         * @instance
         */
        ByteLiveDetail.prototype.streamId = "";

        /**
         * ByteLiveDetail pushUrl.
         * @member {string} pushUrl
         * @memberof Proto.ByteLiveDetail
         * @instance
         */
        ByteLiveDetail.prototype.pushUrl = "";

        /**
         * ByteLiveDetail pushKey.
         * @member {string} pushKey
         * @memberof Proto.ByteLiveDetail
         * @instance
         */
        ByteLiveDetail.prototype.pushKey = "";

        /**
         * ByteLiveDetail rtmUrl.
         * @member {string} rtmUrl
         * @memberof Proto.ByteLiveDetail
         * @instance
         */
        ByteLiveDetail.prototype.rtmUrl = "";

        /**
         * ByteLiveDetail playUrl.
         * @member {string} playUrl
         * @memberof Proto.ByteLiveDetail
         * @instance
         */
        ByteLiveDetail.prototype.playUrl = "";

        /**
         * ByteLiveDetail startDate.
         * @member {number|Long} startDate
         * @memberof Proto.ByteLiveDetail
         * @instance
         */
        ByteLiveDetail.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ByteLiveDetail playSDUrl.
         * @member {string} playSDUrl
         * @memberof Proto.ByteLiveDetail
         * @instance
         */
        ByteLiveDetail.prototype.playSDUrl = "";

        /**
         * ByteLiveDetail playHDUrl.
         * @member {string} playHDUrl
         * @memberof Proto.ByteLiveDetail
         * @instance
         */
        ByteLiveDetail.prototype.playHDUrl = "";

        /**
         * ByteLiveDetail pushUrlExpireDate.
         * @member {number|Long} pushUrlExpireDate
         * @memberof Proto.ByteLiveDetail
         * @instance
         */
        ByteLiveDetail.prototype.pushUrlExpireDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new ByteLiveDetail instance using the specified properties.
         * @function create
         * @memberof Proto.ByteLiveDetail
         * @static
         * @param {Proto.IByteLiveDetail=} [properties] Properties to set
         * @returns {Proto.ByteLiveDetail} ByteLiveDetail instance
         */
        ByteLiveDetail.create = function create(properties) {
            return new ByteLiveDetail(properties);
        };

        /**
         * Encodes the specified ByteLiveDetail message. Does not implicitly {@link Proto.ByteLiveDetail.verify|verify} messages.
         * @function encode
         * @memberof Proto.ByteLiveDetail
         * @static
         * @param {Proto.IByteLiveDetail} m ByteLiveDetail message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ByteLiveDetail.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.streamId != null && Object.hasOwnProperty.call(m, "streamId"))
                w.uint32(10).string(m.streamId);
            if (m.pushUrl != null && Object.hasOwnProperty.call(m, "pushUrl"))
                w.uint32(18).string(m.pushUrl);
            if (m.pushKey != null && Object.hasOwnProperty.call(m, "pushKey"))
                w.uint32(26).string(m.pushKey);
            if (m.rtmUrl != null && Object.hasOwnProperty.call(m, "rtmUrl"))
                w.uint32(34).string(m.rtmUrl);
            if (m.playUrl != null && Object.hasOwnProperty.call(m, "playUrl"))
                w.uint32(42).string(m.playUrl);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(48).int64(m.startDate);
            if (m.playSDUrl != null && Object.hasOwnProperty.call(m, "playSDUrl"))
                w.uint32(58).string(m.playSDUrl);
            if (m.playHDUrl != null && Object.hasOwnProperty.call(m, "playHDUrl"))
                w.uint32(66).string(m.playHDUrl);
            if (m.pushUrlExpireDate != null && Object.hasOwnProperty.call(m, "pushUrlExpireDate"))
                w.uint32(72).int64(m.pushUrlExpireDate);
            return w;
        };

        /**
         * Decodes a ByteLiveDetail message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ByteLiveDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ByteLiveDetail} ByteLiveDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ByteLiveDetail.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ByteLiveDetail();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.streamId = r.string();
                    break;
                case 2:
                    m.pushUrl = r.string();
                    break;
                case 3:
                    m.pushKey = r.string();
                    break;
                case 4:
                    m.rtmUrl = r.string();
                    break;
                case 5:
                    m.playUrl = r.string();
                    break;
                case 6:
                    m.startDate = r.int64();
                    break;
                case 7:
                    m.playSDUrl = r.string();
                    break;
                case 8:
                    m.playHDUrl = r.string();
                    break;
                case 9:
                    m.pushUrlExpireDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ByteLiveDetail message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ByteLiveDetail
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ByteLiveDetail} ByteLiveDetail
         */
        ByteLiveDetail.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ByteLiveDetail)
                return d;
            var m = new $root.Proto.ByteLiveDetail();
            if (d.streamId != null) {
                m.streamId = String(d.streamId);
            }
            if (d.pushUrl != null) {
                m.pushUrl = String(d.pushUrl);
            }
            if (d.pushKey != null) {
                m.pushKey = String(d.pushKey);
            }
            if (d.rtmUrl != null) {
                m.rtmUrl = String(d.rtmUrl);
            }
            if (d.playUrl != null) {
                m.playUrl = String(d.playUrl);
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.playSDUrl != null) {
                m.playSDUrl = String(d.playSDUrl);
            }
            if (d.playHDUrl != null) {
                m.playHDUrl = String(d.playHDUrl);
            }
            if (d.pushUrlExpireDate != null) {
                if ($util.Long)
                    (m.pushUrlExpireDate = $util.Long.fromValue(d.pushUrlExpireDate)).unsigned = false;
                else if (typeof d.pushUrlExpireDate === "string")
                    m.pushUrlExpireDate = parseInt(d.pushUrlExpireDate, 10);
                else if (typeof d.pushUrlExpireDate === "number")
                    m.pushUrlExpireDate = d.pushUrlExpireDate;
                else if (typeof d.pushUrlExpireDate === "object")
                    m.pushUrlExpireDate = new $util.LongBits(d.pushUrlExpireDate.low >>> 0, d.pushUrlExpireDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a ByteLiveDetail message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ByteLiveDetail
         * @static
         * @param {Proto.ByteLiveDetail} m ByteLiveDetail
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ByteLiveDetail.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.streamId = "";
                d.pushUrl = "";
                d.pushKey = "";
                d.rtmUrl = "";
                d.playUrl = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                d.playSDUrl = "";
                d.playHDUrl = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.pushUrlExpireDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.pushUrlExpireDate = o.longs === String ? "0" : 0;
            }
            if (m.streamId != null && m.hasOwnProperty("streamId")) {
                d.streamId = m.streamId;
            }
            if (m.pushUrl != null && m.hasOwnProperty("pushUrl")) {
                d.pushUrl = m.pushUrl;
            }
            if (m.pushKey != null && m.hasOwnProperty("pushKey")) {
                d.pushKey = m.pushKey;
            }
            if (m.rtmUrl != null && m.hasOwnProperty("rtmUrl")) {
                d.rtmUrl = m.rtmUrl;
            }
            if (m.playUrl != null && m.hasOwnProperty("playUrl")) {
                d.playUrl = m.playUrl;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.playSDUrl != null && m.hasOwnProperty("playSDUrl")) {
                d.playSDUrl = m.playSDUrl;
            }
            if (m.playHDUrl != null && m.hasOwnProperty("playHDUrl")) {
                d.playHDUrl = m.playHDUrl;
            }
            if (m.pushUrlExpireDate != null && m.hasOwnProperty("pushUrlExpireDate")) {
                if (typeof m.pushUrlExpireDate === "number")
                    d.pushUrlExpireDate = o.longs === String ? String(m.pushUrlExpireDate) : m.pushUrlExpireDate;
                else
                    d.pushUrlExpireDate = o.longs === String ? $util.Long.prototype.toString.call(m.pushUrlExpireDate) : o.longs === Number ? new $util.LongBits(m.pushUrlExpireDate.low >>> 0, m.pushUrlExpireDate.high >>> 0).toNumber() : m.pushUrlExpireDate;
            }
            return d;
        };

        /**
         * Converts this ByteLiveDetail to JSON.
         * @function toJSON
         * @memberof Proto.ByteLiveDetail
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ByteLiveDetail.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ByteLiveDetail;
    })();

    Proto.CompanyUserGroup = (function() {

        /**
         * Properties of a CompanyUserGroup.
         * @memberof Proto
         * @interface ICompanyUserGroup
         * @property {string|null} [uid] CompanyUserGroup uid
         * @property {number|null} [type] CompanyUserGroup type
         * @property {string|null} [name] CompanyUserGroup name
         * @property {number|Long|null} [registerDate] CompanyUserGroup registerDate
         * @property {number|Long|null} [modifyDate] CompanyUserGroup modifyDate
         * @property {Array.<Proto.ICompanyUserGroupPolicy>|null} [policyList] CompanyUserGroup policyList
         * @property {Proto.IUploadFile|null} [image] CompanyUserGroup image
         * @property {Proto.IUploadFile|null} [topImage] CompanyUserGroup topImage
         * @property {string|null} [userCount] CompanyUserGroup userCount
         * @property {number|null} [status] CompanyUserGroup status
         * @property {string|null} [parentUID] CompanyUserGroup parentUID
         * @property {string|null} [planUID] CompanyUserGroup planUID
         * @property {string|null} [groupUID] CompanyUserGroup groupUID
         * @property {number|null} [level] CompanyUserGroup level
         * @property {boolean|null} [canChat] CompanyUserGroup canChat
         * @property {string|null} [allChildCount] CompanyUserGroup allChildCount
         * @property {string|null} [companyUID] CompanyUserGroup companyUID
         * @property {Array.<Proto.IUser>|null} [profileUserList] CompanyUserGroup profileUserList
         * @property {Array.<Proto.ICompanyUserGroup>|null} [sequence] CompanyUserGroup sequence
         * @property {boolean|null} [chatUploadPolicy] CompanyUserGroup chatUploadPolicy
         * @property {number|null} [chatUploadEnv] CompanyUserGroup chatUploadEnv
         * @property {Array.<Proto.ICommunityBoardForum>|null} [exclusiveForumList] CompanyUserGroup exclusiveForumList
         * @property {Proto.ICommunityBoardGroup|null} [exclusiveGroup] CompanyUserGroup exclusiveGroup
         * @property {boolean|null} [exclusiveForumDirect] CompanyUserGroup exclusiveForumDirect
         */

        /**
         * Constructs a new CompanyUserGroup.
         * @memberof Proto
         * @classdesc Represents a CompanyUserGroup.
         * @implements ICompanyUserGroup
         * @constructor
         * @param {Proto.ICompanyUserGroup=} [p] Properties to set
         */
        function CompanyUserGroup(p) {
            this.policyList = [];
            this.profileUserList = [];
            this.sequence = [];
            this.exclusiveForumList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyUserGroup uid.
         * @member {string} uid
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.uid = "";

        /**
         * CompanyUserGroup type.
         * @member {number} type
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.type = 0;

        /**
         * CompanyUserGroup name.
         * @member {string} name
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.name = "";

        /**
         * CompanyUserGroup registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyUserGroup modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyUserGroup policyList.
         * @member {Array.<Proto.ICompanyUserGroupPolicy>} policyList
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.policyList = $util.emptyArray;

        /**
         * CompanyUserGroup image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.image = null;

        /**
         * CompanyUserGroup topImage.
         * @member {Proto.IUploadFile|null|undefined} topImage
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.topImage = null;

        /**
         * CompanyUserGroup userCount.
         * @member {string} userCount
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.userCount = "";

        /**
         * CompanyUserGroup status.
         * @member {number} status
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.status = 0;

        /**
         * CompanyUserGroup parentUID.
         * @member {string} parentUID
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.parentUID = "";

        /**
         * CompanyUserGroup planUID.
         * @member {string} planUID
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.planUID = "";

        /**
         * CompanyUserGroup groupUID.
         * @member {string} groupUID
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.groupUID = "";

        /**
         * CompanyUserGroup level.
         * @member {number} level
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.level = 0;

        /**
         * CompanyUserGroup canChat.
         * @member {boolean} canChat
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.canChat = false;

        /**
         * CompanyUserGroup allChildCount.
         * @member {string} allChildCount
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.allChildCount = "";

        /**
         * CompanyUserGroup companyUID.
         * @member {string} companyUID
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.companyUID = "";

        /**
         * CompanyUserGroup profileUserList.
         * @member {Array.<Proto.IUser>} profileUserList
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.profileUserList = $util.emptyArray;

        /**
         * CompanyUserGroup sequence.
         * @member {Array.<Proto.ICompanyUserGroup>} sequence
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.sequence = $util.emptyArray;

        /**
         * CompanyUserGroup chatUploadPolicy.
         * @member {boolean} chatUploadPolicy
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.chatUploadPolicy = false;

        /**
         * CompanyUserGroup chatUploadEnv.
         * @member {number} chatUploadEnv
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.chatUploadEnv = 0;

        /**
         * CompanyUserGroup exclusiveForumList.
         * @member {Array.<Proto.ICommunityBoardForum>} exclusiveForumList
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.exclusiveForumList = $util.emptyArray;

        /**
         * CompanyUserGroup exclusiveGroup.
         * @member {Proto.ICommunityBoardGroup|null|undefined} exclusiveGroup
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.exclusiveGroup = null;

        /**
         * CompanyUserGroup exclusiveForumDirect.
         * @member {boolean} exclusiveForumDirect
         * @memberof Proto.CompanyUserGroup
         * @instance
         */
        CompanyUserGroup.prototype.exclusiveForumDirect = false;

        /**
         * Creates a new CompanyUserGroup instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyUserGroup
         * @static
         * @param {Proto.ICompanyUserGroup=} [properties] Properties to set
         * @returns {Proto.CompanyUserGroup} CompanyUserGroup instance
         */
        CompanyUserGroup.create = function create(properties) {
            return new CompanyUserGroup(properties);
        };

        /**
         * Encodes the specified CompanyUserGroup message. Does not implicitly {@link Proto.CompanyUserGroup.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyUserGroup
         * @static
         * @param {Proto.ICompanyUserGroup} m CompanyUserGroup message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyUserGroup.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            if (m.policyList != null && m.policyList.length) {
                for (var i = 0; i < m.policyList.length; ++i)
                    $root.Proto.CompanyUserGroupPolicy.encode(m.policyList[i], w.uint32(50).fork()).ldelim();
            }
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(58).fork()).ldelim();
            if (m.topImage != null && Object.hasOwnProperty.call(m, "topImage"))
                $root.Proto.UploadFile.encode(m.topImage, w.uint32(66).fork()).ldelim();
            if (m.userCount != null && Object.hasOwnProperty.call(m, "userCount"))
                w.uint32(74).string(m.userCount);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(80).int32(m.status);
            if (m.parentUID != null && Object.hasOwnProperty.call(m, "parentUID"))
                w.uint32(90).string(m.parentUID);
            if (m.planUID != null && Object.hasOwnProperty.call(m, "planUID"))
                w.uint32(98).string(m.planUID);
            if (m.groupUID != null && Object.hasOwnProperty.call(m, "groupUID"))
                w.uint32(106).string(m.groupUID);
            if (m.level != null && Object.hasOwnProperty.call(m, "level"))
                w.uint32(112).int32(m.level);
            if (m.canChat != null && Object.hasOwnProperty.call(m, "canChat"))
                w.uint32(120).bool(m.canChat);
            if (m.allChildCount != null && Object.hasOwnProperty.call(m, "allChildCount"))
                w.uint32(130).string(m.allChildCount);
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(138).string(m.companyUID);
            if (m.profileUserList != null && m.profileUserList.length) {
                for (var i = 0; i < m.profileUserList.length; ++i)
                    $root.Proto.User.encode(m.profileUserList[i], w.uint32(146).fork()).ldelim();
            }
            if (m.sequence != null && m.sequence.length) {
                for (var i = 0; i < m.sequence.length; ++i)
                    $root.Proto.CompanyUserGroup.encode(m.sequence[i], w.uint32(154).fork()).ldelim();
            }
            if (m.chatUploadPolicy != null && Object.hasOwnProperty.call(m, "chatUploadPolicy"))
                w.uint32(160).bool(m.chatUploadPolicy);
            if (m.chatUploadEnv != null && Object.hasOwnProperty.call(m, "chatUploadEnv"))
                w.uint32(168).int32(m.chatUploadEnv);
            if (m.exclusiveForumList != null && m.exclusiveForumList.length) {
                for (var i = 0; i < m.exclusiveForumList.length; ++i)
                    $root.Proto.CommunityBoardForum.encode(m.exclusiveForumList[i], w.uint32(178).fork()).ldelim();
            }
            if (m.exclusiveGroup != null && Object.hasOwnProperty.call(m, "exclusiveGroup"))
                $root.Proto.CommunityBoardGroup.encode(m.exclusiveGroup, w.uint32(186).fork()).ldelim();
            if (m.exclusiveForumDirect != null && Object.hasOwnProperty.call(m, "exclusiveForumDirect"))
                w.uint32(192).bool(m.exclusiveForumDirect);
            return w;
        };

        /**
         * Decodes a CompanyUserGroup message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyUserGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyUserGroup} CompanyUserGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyUserGroup.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyUserGroup();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                case 6:
                    if (!(m.policyList && m.policyList.length))
                        m.policyList = [];
                    m.policyList.push($root.Proto.CompanyUserGroupPolicy.decode(r, r.uint32()));
                    break;
                case 7:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 8:
                    m.topImage = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 9:
                    m.userCount = r.string();
                    break;
                case 10:
                    m.status = r.int32();
                    break;
                case 11:
                    m.parentUID = r.string();
                    break;
                case 12:
                    m.planUID = r.string();
                    break;
                case 13:
                    m.groupUID = r.string();
                    break;
                case 14:
                    m.level = r.int32();
                    break;
                case 15:
                    m.canChat = r.bool();
                    break;
                case 16:
                    m.allChildCount = r.string();
                    break;
                case 17:
                    m.companyUID = r.string();
                    break;
                case 18:
                    if (!(m.profileUserList && m.profileUserList.length))
                        m.profileUserList = [];
                    m.profileUserList.push($root.Proto.User.decode(r, r.uint32()));
                    break;
                case 19:
                    if (!(m.sequence && m.sequence.length))
                        m.sequence = [];
                    m.sequence.push($root.Proto.CompanyUserGroup.decode(r, r.uint32()));
                    break;
                case 20:
                    m.chatUploadPolicy = r.bool();
                    break;
                case 21:
                    m.chatUploadEnv = r.int32();
                    break;
                case 22:
                    if (!(m.exclusiveForumList && m.exclusiveForumList.length))
                        m.exclusiveForumList = [];
                    m.exclusiveForumList.push($root.Proto.CommunityBoardForum.decode(r, r.uint32()));
                    break;
                case 23:
                    m.exclusiveGroup = $root.Proto.CommunityBoardGroup.decode(r, r.uint32());
                    break;
                case 24:
                    m.exclusiveForumDirect = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyUserGroup message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyUserGroup
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyUserGroup} CompanyUserGroup
         */
        CompanyUserGroup.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyUserGroup)
                return d;
            var m = new $root.Proto.CompanyUserGroup();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.policyList) {
                if (!Array.isArray(d.policyList))
                    throw TypeError(".Proto.CompanyUserGroup.policyList: array expected");
                m.policyList = [];
                for (var i = 0; i < d.policyList.length; ++i) {
                    if (typeof d.policyList[i] !== "object")
                        throw TypeError(".Proto.CompanyUserGroup.policyList: object expected");
                    m.policyList[i] = $root.Proto.CompanyUserGroupPolicy.fromObject(d.policyList[i]);
                }
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.CompanyUserGroup.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.topImage != null) {
                if (typeof d.topImage !== "object")
                    throw TypeError(".Proto.CompanyUserGroup.topImage: object expected");
                m.topImage = $root.Proto.UploadFile.fromObject(d.topImage);
            }
            if (d.userCount != null) {
                m.userCount = String(d.userCount);
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.parentUID != null) {
                m.parentUID = String(d.parentUID);
            }
            if (d.planUID != null) {
                m.planUID = String(d.planUID);
            }
            if (d.groupUID != null) {
                m.groupUID = String(d.groupUID);
            }
            if (d.level != null) {
                m.level = d.level | 0;
            }
            if (d.canChat != null) {
                m.canChat = Boolean(d.canChat);
            }
            if (d.allChildCount != null) {
                m.allChildCount = String(d.allChildCount);
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.profileUserList) {
                if (!Array.isArray(d.profileUserList))
                    throw TypeError(".Proto.CompanyUserGroup.profileUserList: array expected");
                m.profileUserList = [];
                for (var i = 0; i < d.profileUserList.length; ++i) {
                    if (typeof d.profileUserList[i] !== "object")
                        throw TypeError(".Proto.CompanyUserGroup.profileUserList: object expected");
                    m.profileUserList[i] = $root.Proto.User.fromObject(d.profileUserList[i]);
                }
            }
            if (d.sequence) {
                if (!Array.isArray(d.sequence))
                    throw TypeError(".Proto.CompanyUserGroup.sequence: array expected");
                m.sequence = [];
                for (var i = 0; i < d.sequence.length; ++i) {
                    if (typeof d.sequence[i] !== "object")
                        throw TypeError(".Proto.CompanyUserGroup.sequence: object expected");
                    m.sequence[i] = $root.Proto.CompanyUserGroup.fromObject(d.sequence[i]);
                }
            }
            if (d.chatUploadPolicy != null) {
                m.chatUploadPolicy = Boolean(d.chatUploadPolicy);
            }
            if (d.chatUploadEnv != null) {
                m.chatUploadEnv = d.chatUploadEnv | 0;
            }
            if (d.exclusiveForumList) {
                if (!Array.isArray(d.exclusiveForumList))
                    throw TypeError(".Proto.CompanyUserGroup.exclusiveForumList: array expected");
                m.exclusiveForumList = [];
                for (var i = 0; i < d.exclusiveForumList.length; ++i) {
                    if (typeof d.exclusiveForumList[i] !== "object")
                        throw TypeError(".Proto.CompanyUserGroup.exclusiveForumList: object expected");
                    m.exclusiveForumList[i] = $root.Proto.CommunityBoardForum.fromObject(d.exclusiveForumList[i]);
                }
            }
            if (d.exclusiveGroup != null) {
                if (typeof d.exclusiveGroup !== "object")
                    throw TypeError(".Proto.CompanyUserGroup.exclusiveGroup: object expected");
                m.exclusiveGroup = $root.Proto.CommunityBoardGroup.fromObject(d.exclusiveGroup);
            }
            if (d.exclusiveForumDirect != null) {
                m.exclusiveForumDirect = Boolean(d.exclusiveForumDirect);
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyUserGroup message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyUserGroup
         * @static
         * @param {Proto.CompanyUserGroup} m CompanyUserGroup
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyUserGroup.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.policyList = [];
                d.profileUserList = [];
                d.sequence = [];
                d.exclusiveForumList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.name = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.image = null;
                d.topImage = null;
                d.userCount = "";
                d.status = 0;
                d.parentUID = "";
                d.planUID = "";
                d.groupUID = "";
                d.level = 0;
                d.canChat = false;
                d.allChildCount = "";
                d.companyUID = "";
                d.chatUploadPolicy = false;
                d.chatUploadEnv = 0;
                d.exclusiveGroup = null;
                d.exclusiveForumDirect = false;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.policyList && m.policyList.length) {
                d.policyList = [];
                for (var j = 0; j < m.policyList.length; ++j) {
                    d.policyList[j] = $root.Proto.CompanyUserGroupPolicy.toObject(m.policyList[j], o);
                }
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.topImage != null && m.hasOwnProperty("topImage")) {
                d.topImage = $root.Proto.UploadFile.toObject(m.topImage, o);
            }
            if (m.userCount != null && m.hasOwnProperty("userCount")) {
                d.userCount = m.userCount;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.parentUID != null && m.hasOwnProperty("parentUID")) {
                d.parentUID = m.parentUID;
            }
            if (m.planUID != null && m.hasOwnProperty("planUID")) {
                d.planUID = m.planUID;
            }
            if (m.groupUID != null && m.hasOwnProperty("groupUID")) {
                d.groupUID = m.groupUID;
            }
            if (m.level != null && m.hasOwnProperty("level")) {
                d.level = m.level;
            }
            if (m.canChat != null && m.hasOwnProperty("canChat")) {
                d.canChat = m.canChat;
            }
            if (m.allChildCount != null && m.hasOwnProperty("allChildCount")) {
                d.allChildCount = m.allChildCount;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.profileUserList && m.profileUserList.length) {
                d.profileUserList = [];
                for (var j = 0; j < m.profileUserList.length; ++j) {
                    d.profileUserList[j] = $root.Proto.User.toObject(m.profileUserList[j], o);
                }
            }
            if (m.sequence && m.sequence.length) {
                d.sequence = [];
                for (var j = 0; j < m.sequence.length; ++j) {
                    d.sequence[j] = $root.Proto.CompanyUserGroup.toObject(m.sequence[j], o);
                }
            }
            if (m.chatUploadPolicy != null && m.hasOwnProperty("chatUploadPolicy")) {
                d.chatUploadPolicy = m.chatUploadPolicy;
            }
            if (m.chatUploadEnv != null && m.hasOwnProperty("chatUploadEnv")) {
                d.chatUploadEnv = m.chatUploadEnv;
            }
            if (m.exclusiveForumList && m.exclusiveForumList.length) {
                d.exclusiveForumList = [];
                for (var j = 0; j < m.exclusiveForumList.length; ++j) {
                    d.exclusiveForumList[j] = $root.Proto.CommunityBoardForum.toObject(m.exclusiveForumList[j], o);
                }
            }
            if (m.exclusiveGroup != null && m.hasOwnProperty("exclusiveGroup")) {
                d.exclusiveGroup = $root.Proto.CommunityBoardGroup.toObject(m.exclusiveGroup, o);
            }
            if (m.exclusiveForumDirect != null && m.hasOwnProperty("exclusiveForumDirect")) {
                d.exclusiveForumDirect = m.exclusiveForumDirect;
            }
            return d;
        };

        /**
         * Converts this CompanyUserGroup to JSON.
         * @function toJSON
         * @memberof Proto.CompanyUserGroup
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyUserGroup.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyUserGroup;
    })();

    Proto.CompanyUserGroupList = (function() {

        /**
         * Properties of a CompanyUserGroupList.
         * @memberof Proto
         * @interface ICompanyUserGroupList
         * @property {Array.<Proto.ICompanyUserGroup>|null} [value] CompanyUserGroupList value
         */

        /**
         * Constructs a new CompanyUserGroupList.
         * @memberof Proto
         * @classdesc Represents a CompanyUserGroupList.
         * @implements ICompanyUserGroupList
         * @constructor
         * @param {Proto.ICompanyUserGroupList=} [p] Properties to set
         */
        function CompanyUserGroupList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyUserGroupList value.
         * @member {Array.<Proto.ICompanyUserGroup>} value
         * @memberof Proto.CompanyUserGroupList
         * @instance
         */
        CompanyUserGroupList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CompanyUserGroupList instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyUserGroupList
         * @static
         * @param {Proto.ICompanyUserGroupList=} [properties] Properties to set
         * @returns {Proto.CompanyUserGroupList} CompanyUserGroupList instance
         */
        CompanyUserGroupList.create = function create(properties) {
            return new CompanyUserGroupList(properties);
        };

        /**
         * Encodes the specified CompanyUserGroupList message. Does not implicitly {@link Proto.CompanyUserGroupList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyUserGroupList
         * @static
         * @param {Proto.ICompanyUserGroupList} m CompanyUserGroupList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyUserGroupList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CompanyUserGroup.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CompanyUserGroupList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyUserGroupList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyUserGroupList} CompanyUserGroupList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyUserGroupList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyUserGroupList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CompanyUserGroup.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyUserGroupList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyUserGroupList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyUserGroupList} CompanyUserGroupList
         */
        CompanyUserGroupList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyUserGroupList)
                return d;
            var m = new $root.Proto.CompanyUserGroupList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CompanyUserGroupList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CompanyUserGroupList.value: object expected");
                    m.value[i] = $root.Proto.CompanyUserGroup.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyUserGroupList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyUserGroupList
         * @static
         * @param {Proto.CompanyUserGroupList} m CompanyUserGroupList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyUserGroupList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CompanyUserGroup.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CompanyUserGroupList to JSON.
         * @function toJSON
         * @memberof Proto.CompanyUserGroupList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyUserGroupList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyUserGroupList;
    })();

    Proto.CompanyUserGroupPolicy = (function() {

        /**
         * Properties of a CompanyUserGroupPolicy.
         * @memberof Proto
         * @interface ICompanyUserGroupPolicy
         * @property {string|null} [userGroupUID] CompanyUserGroupPolicy userGroupUID
         * @property {string|null} [targetUserGroupUID] CompanyUserGroupPolicy targetUserGroupUID
         * @property {boolean|null} [search] CompanyUserGroupPolicy search
         * @property {boolean|null} [list] CompanyUserGroupPolicy list
         * @property {boolean|null} [userChat] CompanyUserGroupPolicy userChat
         * @property {number|null} [playEnv] CompanyUserGroupPolicy playEnv
         * @property {boolean|null} [active] CompanyUserGroupPolicy active
         * @property {number|Long|null} [registerDate] CompanyUserGroupPolicy registerDate
         * @property {number|Long|null} [modifyDate] CompanyUserGroupPolicy modifyDate
         * @property {boolean|null} [profile] CompanyUserGroupPolicy profile
         */

        /**
         * Constructs a new CompanyUserGroupPolicy.
         * @memberof Proto
         * @classdesc Represents a CompanyUserGroupPolicy.
         * @implements ICompanyUserGroupPolicy
         * @constructor
         * @param {Proto.ICompanyUserGroupPolicy=} [p] Properties to set
         */
        function CompanyUserGroupPolicy(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyUserGroupPolicy userGroupUID.
         * @member {string} userGroupUID
         * @memberof Proto.CompanyUserGroupPolicy
         * @instance
         */
        CompanyUserGroupPolicy.prototype.userGroupUID = "";

        /**
         * CompanyUserGroupPolicy targetUserGroupUID.
         * @member {string} targetUserGroupUID
         * @memberof Proto.CompanyUserGroupPolicy
         * @instance
         */
        CompanyUserGroupPolicy.prototype.targetUserGroupUID = "";

        /**
         * CompanyUserGroupPolicy search.
         * @member {boolean} search
         * @memberof Proto.CompanyUserGroupPolicy
         * @instance
         */
        CompanyUserGroupPolicy.prototype.search = false;

        /**
         * CompanyUserGroupPolicy list.
         * @member {boolean} list
         * @memberof Proto.CompanyUserGroupPolicy
         * @instance
         */
        CompanyUserGroupPolicy.prototype.list = false;

        /**
         * CompanyUserGroupPolicy userChat.
         * @member {boolean} userChat
         * @memberof Proto.CompanyUserGroupPolicy
         * @instance
         */
        CompanyUserGroupPolicy.prototype.userChat = false;

        /**
         * CompanyUserGroupPolicy playEnv.
         * @member {number} playEnv
         * @memberof Proto.CompanyUserGroupPolicy
         * @instance
         */
        CompanyUserGroupPolicy.prototype.playEnv = 0;

        /**
         * CompanyUserGroupPolicy active.
         * @member {boolean} active
         * @memberof Proto.CompanyUserGroupPolicy
         * @instance
         */
        CompanyUserGroupPolicy.prototype.active = false;

        /**
         * CompanyUserGroupPolicy registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CompanyUserGroupPolicy
         * @instance
         */
        CompanyUserGroupPolicy.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyUserGroupPolicy modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CompanyUserGroupPolicy
         * @instance
         */
        CompanyUserGroupPolicy.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyUserGroupPolicy profile.
         * @member {boolean} profile
         * @memberof Proto.CompanyUserGroupPolicy
         * @instance
         */
        CompanyUserGroupPolicy.prototype.profile = false;

        /**
         * Creates a new CompanyUserGroupPolicy instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyUserGroupPolicy
         * @static
         * @param {Proto.ICompanyUserGroupPolicy=} [properties] Properties to set
         * @returns {Proto.CompanyUserGroupPolicy} CompanyUserGroupPolicy instance
         */
        CompanyUserGroupPolicy.create = function create(properties) {
            return new CompanyUserGroupPolicy(properties);
        };

        /**
         * Encodes the specified CompanyUserGroupPolicy message. Does not implicitly {@link Proto.CompanyUserGroupPolicy.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyUserGroupPolicy
         * @static
         * @param {Proto.ICompanyUserGroupPolicy} m CompanyUserGroupPolicy message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyUserGroupPolicy.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userGroupUID != null && Object.hasOwnProperty.call(m, "userGroupUID"))
                w.uint32(10).string(m.userGroupUID);
            if (m.targetUserGroupUID != null && Object.hasOwnProperty.call(m, "targetUserGroupUID"))
                w.uint32(18).string(m.targetUserGroupUID);
            if (m.search != null && Object.hasOwnProperty.call(m, "search"))
                w.uint32(24).bool(m.search);
            if (m.list != null && Object.hasOwnProperty.call(m, "list"))
                w.uint32(32).bool(m.list);
            if (m.userChat != null && Object.hasOwnProperty.call(m, "userChat"))
                w.uint32(40).bool(m.userChat);
            if (m.playEnv != null && Object.hasOwnProperty.call(m, "playEnv"))
                w.uint32(48).int32(m.playEnv);
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(56).bool(m.active);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            if (m.profile != null && Object.hasOwnProperty.call(m, "profile"))
                w.uint32(80).bool(m.profile);
            return w;
        };

        /**
         * Decodes a CompanyUserGroupPolicy message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyUserGroupPolicy
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyUserGroupPolicy} CompanyUserGroupPolicy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyUserGroupPolicy.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyUserGroupPolicy();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.userGroupUID = r.string();
                    break;
                case 2:
                    m.targetUserGroupUID = r.string();
                    break;
                case 3:
                    m.search = r.bool();
                    break;
                case 4:
                    m.list = r.bool();
                    break;
                case 5:
                    m.userChat = r.bool();
                    break;
                case 6:
                    m.playEnv = r.int32();
                    break;
                case 7:
                    m.active = r.bool();
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                case 10:
                    m.profile = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyUserGroupPolicy message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyUserGroupPolicy
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyUserGroupPolicy} CompanyUserGroupPolicy
         */
        CompanyUserGroupPolicy.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyUserGroupPolicy)
                return d;
            var m = new $root.Proto.CompanyUserGroupPolicy();
            if (d.userGroupUID != null) {
                m.userGroupUID = String(d.userGroupUID);
            }
            if (d.targetUserGroupUID != null) {
                m.targetUserGroupUID = String(d.targetUserGroupUID);
            }
            if (d.search != null) {
                m.search = Boolean(d.search);
            }
            if (d.list != null) {
                m.list = Boolean(d.list);
            }
            if (d.userChat != null) {
                m.userChat = Boolean(d.userChat);
            }
            if (d.playEnv != null) {
                m.playEnv = d.playEnv | 0;
            }
            if (d.active != null) {
                m.active = Boolean(d.active);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.profile != null) {
                m.profile = Boolean(d.profile);
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyUserGroupPolicy message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyUserGroupPolicy
         * @static
         * @param {Proto.CompanyUserGroupPolicy} m CompanyUserGroupPolicy
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyUserGroupPolicy.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.userGroupUID = "";
                d.targetUserGroupUID = "";
                d.search = false;
                d.list = false;
                d.userChat = false;
                d.playEnv = 0;
                d.active = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.profile = false;
            }
            if (m.userGroupUID != null && m.hasOwnProperty("userGroupUID")) {
                d.userGroupUID = m.userGroupUID;
            }
            if (m.targetUserGroupUID != null && m.hasOwnProperty("targetUserGroupUID")) {
                d.targetUserGroupUID = m.targetUserGroupUID;
            }
            if (m.search != null && m.hasOwnProperty("search")) {
                d.search = m.search;
            }
            if (m.list != null && m.hasOwnProperty("list")) {
                d.list = m.list;
            }
            if (m.userChat != null && m.hasOwnProperty("userChat")) {
                d.userChat = m.userChat;
            }
            if (m.playEnv != null && m.hasOwnProperty("playEnv")) {
                d.playEnv = m.playEnv;
            }
            if (m.active != null && m.hasOwnProperty("active")) {
                d.active = m.active;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.profile != null && m.hasOwnProperty("profile")) {
                d.profile = m.profile;
            }
            return d;
        };

        /**
         * Converts this CompanyUserGroupPolicy to JSON.
         * @function toJSON
         * @memberof Proto.CompanyUserGroupPolicy
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyUserGroupPolicy.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyUserGroupPolicy;
    })();

    Proto.CompanyUserGroupPolicyList = (function() {

        /**
         * Properties of a CompanyUserGroupPolicyList.
         * @memberof Proto
         * @interface ICompanyUserGroupPolicyList
         * @property {Array.<Proto.ICompanyUserGroupPolicy>|null} [value] CompanyUserGroupPolicyList value
         */

        /**
         * Constructs a new CompanyUserGroupPolicyList.
         * @memberof Proto
         * @classdesc Represents a CompanyUserGroupPolicyList.
         * @implements ICompanyUserGroupPolicyList
         * @constructor
         * @param {Proto.ICompanyUserGroupPolicyList=} [p] Properties to set
         */
        function CompanyUserGroupPolicyList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyUserGroupPolicyList value.
         * @member {Array.<Proto.ICompanyUserGroupPolicy>} value
         * @memberof Proto.CompanyUserGroupPolicyList
         * @instance
         */
        CompanyUserGroupPolicyList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CompanyUserGroupPolicyList instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyUserGroupPolicyList
         * @static
         * @param {Proto.ICompanyUserGroupPolicyList=} [properties] Properties to set
         * @returns {Proto.CompanyUserGroupPolicyList} CompanyUserGroupPolicyList instance
         */
        CompanyUserGroupPolicyList.create = function create(properties) {
            return new CompanyUserGroupPolicyList(properties);
        };

        /**
         * Encodes the specified CompanyUserGroupPolicyList message. Does not implicitly {@link Proto.CompanyUserGroupPolicyList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyUserGroupPolicyList
         * @static
         * @param {Proto.ICompanyUserGroupPolicyList} m CompanyUserGroupPolicyList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyUserGroupPolicyList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CompanyUserGroupPolicy.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CompanyUserGroupPolicyList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyUserGroupPolicyList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyUserGroupPolicyList} CompanyUserGroupPolicyList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyUserGroupPolicyList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyUserGroupPolicyList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CompanyUserGroupPolicy.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyUserGroupPolicyList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyUserGroupPolicyList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyUserGroupPolicyList} CompanyUserGroupPolicyList
         */
        CompanyUserGroupPolicyList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyUserGroupPolicyList)
                return d;
            var m = new $root.Proto.CompanyUserGroupPolicyList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CompanyUserGroupPolicyList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CompanyUserGroupPolicyList.value: object expected");
                    m.value[i] = $root.Proto.CompanyUserGroupPolicy.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyUserGroupPolicyList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyUserGroupPolicyList
         * @static
         * @param {Proto.CompanyUserGroupPolicyList} m CompanyUserGroupPolicyList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyUserGroupPolicyList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CompanyUserGroupPolicy.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CompanyUserGroupPolicyList to JSON.
         * @function toJSON
         * @memberof Proto.CompanyUserGroupPolicyList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyUserGroupPolicyList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyUserGroupPolicyList;
    })();

    Proto.CompanyAppLaunchScreen = (function() {

        /**
         * Properties of a CompanyAppLaunchScreen.
         * @memberof Proto
         * @interface ICompanyAppLaunchScreen
         * @property {string|null} [uid] CompanyAppLaunchScreen uid
         * @property {string|null} [title] CompanyAppLaunchScreen title
         * @property {string|null} [text] CompanyAppLaunchScreen text
         * @property {number|null} [ctaType] CompanyAppLaunchScreen ctaType
         * @property {string|null} [ctaText] CompanyAppLaunchScreen ctaText
         * @property {string|null} [ctaBg] CompanyAppLaunchScreen ctaBg
         * @property {string|null} [ctaColor] CompanyAppLaunchScreen ctaColor
         * @property {number|null} [fileType] CompanyAppLaunchScreen fileType
         * @property {number|null} [dimAlpha] CompanyAppLaunchScreen dimAlpha
         * @property {number|null} [order] CompanyAppLaunchScreen order
         * @property {Proto.IUploadFile|null} [file] CompanyAppLaunchScreen file
         * @property {number|Long|null} [registerDate] CompanyAppLaunchScreen registerDate
         * @property {number|Long|null} [modifyDate] CompanyAppLaunchScreen modifyDate
         * @property {number|null} [status] CompanyAppLaunchScreen status
         */

        /**
         * Constructs a new CompanyAppLaunchScreen.
         * @memberof Proto
         * @classdesc Represents a CompanyAppLaunchScreen.
         * @implements ICompanyAppLaunchScreen
         * @constructor
         * @param {Proto.ICompanyAppLaunchScreen=} [p] Properties to set
         */
        function CompanyAppLaunchScreen(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyAppLaunchScreen uid.
         * @member {string} uid
         * @memberof Proto.CompanyAppLaunchScreen
         * @instance
         */
        CompanyAppLaunchScreen.prototype.uid = "";

        /**
         * CompanyAppLaunchScreen title.
         * @member {string} title
         * @memberof Proto.CompanyAppLaunchScreen
         * @instance
         */
        CompanyAppLaunchScreen.prototype.title = "";

        /**
         * CompanyAppLaunchScreen text.
         * @member {string} text
         * @memberof Proto.CompanyAppLaunchScreen
         * @instance
         */
        CompanyAppLaunchScreen.prototype.text = "";

        /**
         * CompanyAppLaunchScreen ctaType.
         * @member {number} ctaType
         * @memberof Proto.CompanyAppLaunchScreen
         * @instance
         */
        CompanyAppLaunchScreen.prototype.ctaType = 0;

        /**
         * CompanyAppLaunchScreen ctaText.
         * @member {string} ctaText
         * @memberof Proto.CompanyAppLaunchScreen
         * @instance
         */
        CompanyAppLaunchScreen.prototype.ctaText = "";

        /**
         * CompanyAppLaunchScreen ctaBg.
         * @member {string} ctaBg
         * @memberof Proto.CompanyAppLaunchScreen
         * @instance
         */
        CompanyAppLaunchScreen.prototype.ctaBg = "";

        /**
         * CompanyAppLaunchScreen ctaColor.
         * @member {string} ctaColor
         * @memberof Proto.CompanyAppLaunchScreen
         * @instance
         */
        CompanyAppLaunchScreen.prototype.ctaColor = "";

        /**
         * CompanyAppLaunchScreen fileType.
         * @member {number} fileType
         * @memberof Proto.CompanyAppLaunchScreen
         * @instance
         */
        CompanyAppLaunchScreen.prototype.fileType = 0;

        /**
         * CompanyAppLaunchScreen dimAlpha.
         * @member {number} dimAlpha
         * @memberof Proto.CompanyAppLaunchScreen
         * @instance
         */
        CompanyAppLaunchScreen.prototype.dimAlpha = 0;

        /**
         * CompanyAppLaunchScreen order.
         * @member {number} order
         * @memberof Proto.CompanyAppLaunchScreen
         * @instance
         */
        CompanyAppLaunchScreen.prototype.order = 0;

        /**
         * CompanyAppLaunchScreen file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.CompanyAppLaunchScreen
         * @instance
         */
        CompanyAppLaunchScreen.prototype.file = null;

        /**
         * CompanyAppLaunchScreen registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CompanyAppLaunchScreen
         * @instance
         */
        CompanyAppLaunchScreen.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyAppLaunchScreen modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CompanyAppLaunchScreen
         * @instance
         */
        CompanyAppLaunchScreen.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyAppLaunchScreen status.
         * @member {number} status
         * @memberof Proto.CompanyAppLaunchScreen
         * @instance
         */
        CompanyAppLaunchScreen.prototype.status = 0;

        /**
         * Creates a new CompanyAppLaunchScreen instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyAppLaunchScreen
         * @static
         * @param {Proto.ICompanyAppLaunchScreen=} [properties] Properties to set
         * @returns {Proto.CompanyAppLaunchScreen} CompanyAppLaunchScreen instance
         */
        CompanyAppLaunchScreen.create = function create(properties) {
            return new CompanyAppLaunchScreen(properties);
        };

        /**
         * Encodes the specified CompanyAppLaunchScreen message. Does not implicitly {@link Proto.CompanyAppLaunchScreen.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyAppLaunchScreen
         * @static
         * @param {Proto.ICompanyAppLaunchScreen} m CompanyAppLaunchScreen message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyAppLaunchScreen.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(18).string(m.title);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.ctaType != null && Object.hasOwnProperty.call(m, "ctaType"))
                w.uint32(32).int32(m.ctaType);
            if (m.ctaText != null && Object.hasOwnProperty.call(m, "ctaText"))
                w.uint32(42).string(m.ctaText);
            if (m.ctaBg != null && Object.hasOwnProperty.call(m, "ctaBg"))
                w.uint32(50).string(m.ctaBg);
            if (m.ctaColor != null && Object.hasOwnProperty.call(m, "ctaColor"))
                w.uint32(58).string(m.ctaColor);
            if (m.fileType != null && Object.hasOwnProperty.call(m, "fileType"))
                w.uint32(64).int32(m.fileType);
            if (m.dimAlpha != null && Object.hasOwnProperty.call(m, "dimAlpha"))
                w.uint32(72).int32(m.dimAlpha);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(80).int32(m.order);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(90).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(96).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(104).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(112).int32(m.status);
            return w;
        };

        /**
         * Decodes a CompanyAppLaunchScreen message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyAppLaunchScreen
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyAppLaunchScreen} CompanyAppLaunchScreen
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyAppLaunchScreen.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyAppLaunchScreen();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.title = r.string();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.ctaType = r.int32();
                    break;
                case 5:
                    m.ctaText = r.string();
                    break;
                case 6:
                    m.ctaBg = r.string();
                    break;
                case 7:
                    m.ctaColor = r.string();
                    break;
                case 8:
                    m.fileType = r.int32();
                    break;
                case 9:
                    m.dimAlpha = r.int32();
                    break;
                case 10:
                    m.order = r.int32();
                    break;
                case 11:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 12:
                    m.registerDate = r.int64();
                    break;
                case 13:
                    m.modifyDate = r.int64();
                    break;
                case 14:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyAppLaunchScreen message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyAppLaunchScreen
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyAppLaunchScreen} CompanyAppLaunchScreen
         */
        CompanyAppLaunchScreen.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyAppLaunchScreen)
                return d;
            var m = new $root.Proto.CompanyAppLaunchScreen();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.ctaType != null) {
                m.ctaType = d.ctaType | 0;
            }
            if (d.ctaText != null) {
                m.ctaText = String(d.ctaText);
            }
            if (d.ctaBg != null) {
                m.ctaBg = String(d.ctaBg);
            }
            if (d.ctaColor != null) {
                m.ctaColor = String(d.ctaColor);
            }
            if (d.fileType != null) {
                m.fileType = d.fileType | 0;
            }
            if (d.dimAlpha != null) {
                m.dimAlpha = d.dimAlpha | 0;
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.CompanyAppLaunchScreen.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyAppLaunchScreen message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyAppLaunchScreen
         * @static
         * @param {Proto.CompanyAppLaunchScreen} m CompanyAppLaunchScreen
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyAppLaunchScreen.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.title = "";
                d.text = "";
                d.ctaType = 0;
                d.ctaText = "";
                d.ctaBg = "";
                d.ctaColor = "";
                d.fileType = 0;
                d.dimAlpha = 0;
                d.order = 0;
                d.file = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.ctaType != null && m.hasOwnProperty("ctaType")) {
                d.ctaType = m.ctaType;
            }
            if (m.ctaText != null && m.hasOwnProperty("ctaText")) {
                d.ctaText = m.ctaText;
            }
            if (m.ctaBg != null && m.hasOwnProperty("ctaBg")) {
                d.ctaBg = m.ctaBg;
            }
            if (m.ctaColor != null && m.hasOwnProperty("ctaColor")) {
                d.ctaColor = m.ctaColor;
            }
            if (m.fileType != null && m.hasOwnProperty("fileType")) {
                d.fileType = m.fileType;
            }
            if (m.dimAlpha != null && m.hasOwnProperty("dimAlpha")) {
                d.dimAlpha = m.dimAlpha;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this CompanyAppLaunchScreen to JSON.
         * @function toJSON
         * @memberof Proto.CompanyAppLaunchScreen
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyAppLaunchScreen.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyAppLaunchScreen;
    })();

    Proto.CompanyAppLaunchScreenList = (function() {

        /**
         * Properties of a CompanyAppLaunchScreenList.
         * @memberof Proto
         * @interface ICompanyAppLaunchScreenList
         * @property {Array.<Proto.ICompanyAppLaunchScreen>|null} [value] CompanyAppLaunchScreenList value
         */

        /**
         * Constructs a new CompanyAppLaunchScreenList.
         * @memberof Proto
         * @classdesc Represents a CompanyAppLaunchScreenList.
         * @implements ICompanyAppLaunchScreenList
         * @constructor
         * @param {Proto.ICompanyAppLaunchScreenList=} [p] Properties to set
         */
        function CompanyAppLaunchScreenList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyAppLaunchScreenList value.
         * @member {Array.<Proto.ICompanyAppLaunchScreen>} value
         * @memberof Proto.CompanyAppLaunchScreenList
         * @instance
         */
        CompanyAppLaunchScreenList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CompanyAppLaunchScreenList instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyAppLaunchScreenList
         * @static
         * @param {Proto.ICompanyAppLaunchScreenList=} [properties] Properties to set
         * @returns {Proto.CompanyAppLaunchScreenList} CompanyAppLaunchScreenList instance
         */
        CompanyAppLaunchScreenList.create = function create(properties) {
            return new CompanyAppLaunchScreenList(properties);
        };

        /**
         * Encodes the specified CompanyAppLaunchScreenList message. Does not implicitly {@link Proto.CompanyAppLaunchScreenList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyAppLaunchScreenList
         * @static
         * @param {Proto.ICompanyAppLaunchScreenList} m CompanyAppLaunchScreenList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyAppLaunchScreenList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CompanyAppLaunchScreen.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CompanyAppLaunchScreenList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyAppLaunchScreenList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyAppLaunchScreenList} CompanyAppLaunchScreenList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyAppLaunchScreenList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyAppLaunchScreenList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CompanyAppLaunchScreen.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyAppLaunchScreenList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyAppLaunchScreenList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyAppLaunchScreenList} CompanyAppLaunchScreenList
         */
        CompanyAppLaunchScreenList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyAppLaunchScreenList)
                return d;
            var m = new $root.Proto.CompanyAppLaunchScreenList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CompanyAppLaunchScreenList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CompanyAppLaunchScreenList.value: object expected");
                    m.value[i] = $root.Proto.CompanyAppLaunchScreen.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyAppLaunchScreenList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyAppLaunchScreenList
         * @static
         * @param {Proto.CompanyAppLaunchScreenList} m CompanyAppLaunchScreenList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyAppLaunchScreenList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CompanyAppLaunchScreen.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CompanyAppLaunchScreenList to JSON.
         * @function toJSON
         * @memberof Proto.CompanyAppLaunchScreenList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyAppLaunchScreenList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyAppLaunchScreenList;
    })();

    Proto.CompanyUserProfile = (function() {

        /**
         * Properties of a CompanyUserProfile.
         * @memberof Proto
         * @interface ICompanyUserProfile
         * @property {string|null} [uid] CompanyUserProfile uid
         * @property {number|null} [type] CompanyUserProfile type
         * @property {string|null} [infoUID] CompanyUserProfile infoUID
         * @property {string|null} [option] CompanyUserProfile option
         * @property {string|null} [order] CompanyUserProfile order
         * @property {Proto.IViewerUserForm|null} [formInfo] CompanyUserProfile formInfo
         * @property {Proto.IViewerUserInfo|null} [userInfo] CompanyUserProfile userInfo
         * @property {number|Long|null} [registerDate] CompanyUserProfile registerDate
         * @property {number|Long|null} [modifyDate] CompanyUserProfile modifyDate
         * @property {number|null} [status] CompanyUserProfile status
         */

        /**
         * Constructs a new CompanyUserProfile.
         * @memberof Proto
         * @classdesc Represents a CompanyUserProfile.
         * @implements ICompanyUserProfile
         * @constructor
         * @param {Proto.ICompanyUserProfile=} [p] Properties to set
         */
        function CompanyUserProfile(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyUserProfile uid.
         * @member {string} uid
         * @memberof Proto.CompanyUserProfile
         * @instance
         */
        CompanyUserProfile.prototype.uid = "";

        /**
         * CompanyUserProfile type.
         * @member {number} type
         * @memberof Proto.CompanyUserProfile
         * @instance
         */
        CompanyUserProfile.prototype.type = 0;

        /**
         * CompanyUserProfile infoUID.
         * @member {string} infoUID
         * @memberof Proto.CompanyUserProfile
         * @instance
         */
        CompanyUserProfile.prototype.infoUID = "";

        /**
         * CompanyUserProfile option.
         * @member {string} option
         * @memberof Proto.CompanyUserProfile
         * @instance
         */
        CompanyUserProfile.prototype.option = "";

        /**
         * CompanyUserProfile order.
         * @member {string} order
         * @memberof Proto.CompanyUserProfile
         * @instance
         */
        CompanyUserProfile.prototype.order = "";

        /**
         * CompanyUserProfile formInfo.
         * @member {Proto.IViewerUserForm|null|undefined} formInfo
         * @memberof Proto.CompanyUserProfile
         * @instance
         */
        CompanyUserProfile.prototype.formInfo = null;

        /**
         * CompanyUserProfile userInfo.
         * @member {Proto.IViewerUserInfo|null|undefined} userInfo
         * @memberof Proto.CompanyUserProfile
         * @instance
         */
        CompanyUserProfile.prototype.userInfo = null;

        /**
         * CompanyUserProfile registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CompanyUserProfile
         * @instance
         */
        CompanyUserProfile.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyUserProfile modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CompanyUserProfile
         * @instance
         */
        CompanyUserProfile.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyUserProfile status.
         * @member {number} status
         * @memberof Proto.CompanyUserProfile
         * @instance
         */
        CompanyUserProfile.prototype.status = 0;

        /**
         * Creates a new CompanyUserProfile instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyUserProfile
         * @static
         * @param {Proto.ICompanyUserProfile=} [properties] Properties to set
         * @returns {Proto.CompanyUserProfile} CompanyUserProfile instance
         */
        CompanyUserProfile.create = function create(properties) {
            return new CompanyUserProfile(properties);
        };

        /**
         * Encodes the specified CompanyUserProfile message. Does not implicitly {@link Proto.CompanyUserProfile.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyUserProfile
         * @static
         * @param {Proto.ICompanyUserProfile} m CompanyUserProfile message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyUserProfile.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.infoUID != null && Object.hasOwnProperty.call(m, "infoUID"))
                w.uint32(26).string(m.infoUID);
            if (m.option != null && Object.hasOwnProperty.call(m, "option"))
                w.uint32(34).string(m.option);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(42).string(m.order);
            if (m.formInfo != null && Object.hasOwnProperty.call(m, "formInfo"))
                $root.Proto.ViewerUserForm.encode(m.formInfo, w.uint32(50).fork()).ldelim();
            if (m.userInfo != null && Object.hasOwnProperty.call(m, "userInfo"))
                $root.Proto.ViewerUserInfo.encode(m.userInfo, w.uint32(58).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(80).int32(m.status);
            return w;
        };

        /**
         * Decodes a CompanyUserProfile message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyUserProfile
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyUserProfile} CompanyUserProfile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyUserProfile.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyUserProfile();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.infoUID = r.string();
                    break;
                case 4:
                    m.option = r.string();
                    break;
                case 5:
                    m.order = r.string();
                    break;
                case 6:
                    m.formInfo = $root.Proto.ViewerUserForm.decode(r, r.uint32());
                    break;
                case 7:
                    m.userInfo = $root.Proto.ViewerUserInfo.decode(r, r.uint32());
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                case 10:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyUserProfile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyUserProfile
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyUserProfile} CompanyUserProfile
         */
        CompanyUserProfile.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyUserProfile)
                return d;
            var m = new $root.Proto.CompanyUserProfile();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.infoUID != null) {
                m.infoUID = String(d.infoUID);
            }
            if (d.option != null) {
                m.option = String(d.option);
            }
            if (d.order != null) {
                m.order = String(d.order);
            }
            if (d.formInfo != null) {
                if (typeof d.formInfo !== "object")
                    throw TypeError(".Proto.CompanyUserProfile.formInfo: object expected");
                m.formInfo = $root.Proto.ViewerUserForm.fromObject(d.formInfo);
            }
            if (d.userInfo != null) {
                if (typeof d.userInfo !== "object")
                    throw TypeError(".Proto.CompanyUserProfile.userInfo: object expected");
                m.userInfo = $root.Proto.ViewerUserInfo.fromObject(d.userInfo);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyUserProfile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyUserProfile
         * @static
         * @param {Proto.CompanyUserProfile} m CompanyUserProfile
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyUserProfile.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.infoUID = "";
                d.option = "";
                d.order = "";
                d.formInfo = null;
                d.userInfo = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.infoUID != null && m.hasOwnProperty("infoUID")) {
                d.infoUID = m.infoUID;
            }
            if (m.option != null && m.hasOwnProperty("option")) {
                d.option = m.option;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.formInfo != null && m.hasOwnProperty("formInfo")) {
                d.formInfo = $root.Proto.ViewerUserForm.toObject(m.formInfo, o);
            }
            if (m.userInfo != null && m.hasOwnProperty("userInfo")) {
                d.userInfo = $root.Proto.ViewerUserInfo.toObject(m.userInfo, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this CompanyUserProfile to JSON.
         * @function toJSON
         * @memberof Proto.CompanyUserProfile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyUserProfile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyUserProfile;
    })();

    Proto.CompanyUserProfileList = (function() {

        /**
         * Properties of a CompanyUserProfileList.
         * @memberof Proto
         * @interface ICompanyUserProfileList
         * @property {Array.<Proto.ICompanyUserProfile>|null} [value] CompanyUserProfileList value
         */

        /**
         * Constructs a new CompanyUserProfileList.
         * @memberof Proto
         * @classdesc Represents a CompanyUserProfileList.
         * @implements ICompanyUserProfileList
         * @constructor
         * @param {Proto.ICompanyUserProfileList=} [p] Properties to set
         */
        function CompanyUserProfileList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyUserProfileList value.
         * @member {Array.<Proto.ICompanyUserProfile>} value
         * @memberof Proto.CompanyUserProfileList
         * @instance
         */
        CompanyUserProfileList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CompanyUserProfileList instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyUserProfileList
         * @static
         * @param {Proto.ICompanyUserProfileList=} [properties] Properties to set
         * @returns {Proto.CompanyUserProfileList} CompanyUserProfileList instance
         */
        CompanyUserProfileList.create = function create(properties) {
            return new CompanyUserProfileList(properties);
        };

        /**
         * Encodes the specified CompanyUserProfileList message. Does not implicitly {@link Proto.CompanyUserProfileList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyUserProfileList
         * @static
         * @param {Proto.ICompanyUserProfileList} m CompanyUserProfileList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyUserProfileList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CompanyUserProfile.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CompanyUserProfileList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyUserProfileList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyUserProfileList} CompanyUserProfileList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyUserProfileList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyUserProfileList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CompanyUserProfile.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyUserProfileList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyUserProfileList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyUserProfileList} CompanyUserProfileList
         */
        CompanyUserProfileList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyUserProfileList)
                return d;
            var m = new $root.Proto.CompanyUserProfileList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CompanyUserProfileList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CompanyUserProfileList.value: object expected");
                    m.value[i] = $root.Proto.CompanyUserProfile.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyUserProfileList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyUserProfileList
         * @static
         * @param {Proto.CompanyUserProfileList} m CompanyUserProfileList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyUserProfileList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CompanyUserProfile.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CompanyUserProfileList to JSON.
         * @function toJSON
         * @memberof Proto.CompanyUserProfileList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyUserProfileList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyUserProfileList;
    })();

    Proto.Emoticon = (function() {

        /**
         * Properties of an Emoticon.
         * @memberof Proto
         * @interface IEmoticon
         * @property {string|null} [uid] Emoticon uid
         * @property {number|null} [type] Emoticon type
         * @property {string|null} [text] Emoticon text
         * @property {Proto.IUploadFile|null} [file] Emoticon file
         * @property {number|Long|null} [registerDate] Emoticon registerDate
         * @property {number|Long|null} [modifyDate] Emoticon modifyDate
         */

        /**
         * Constructs a new Emoticon.
         * @memberof Proto
         * @classdesc Represents an Emoticon.
         * @implements IEmoticon
         * @constructor
         * @param {Proto.IEmoticon=} [p] Properties to set
         */
        function Emoticon(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Emoticon uid.
         * @member {string} uid
         * @memberof Proto.Emoticon
         * @instance
         */
        Emoticon.prototype.uid = "";

        /**
         * Emoticon type.
         * @member {number} type
         * @memberof Proto.Emoticon
         * @instance
         */
        Emoticon.prototype.type = 0;

        /**
         * Emoticon text.
         * @member {string} text
         * @memberof Proto.Emoticon
         * @instance
         */
        Emoticon.prototype.text = "";

        /**
         * Emoticon file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.Emoticon
         * @instance
         */
        Emoticon.prototype.file = null;

        /**
         * Emoticon registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.Emoticon
         * @instance
         */
        Emoticon.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Emoticon modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.Emoticon
         * @instance
         */
        Emoticon.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Emoticon instance using the specified properties.
         * @function create
         * @memberof Proto.Emoticon
         * @static
         * @param {Proto.IEmoticon=} [properties] Properties to set
         * @returns {Proto.Emoticon} Emoticon instance
         */
        Emoticon.create = function create(properties) {
            return new Emoticon(properties);
        };

        /**
         * Encodes the specified Emoticon message. Does not implicitly {@link Proto.Emoticon.verify|verify} messages.
         * @function encode
         * @memberof Proto.Emoticon
         * @static
         * @param {Proto.IEmoticon} m Emoticon message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Emoticon.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(34).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(40).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(48).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes an Emoticon message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.Emoticon
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.Emoticon} Emoticon
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Emoticon.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.Emoticon();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 5:
                    m.registerDate = r.int64();
                    break;
                case 6:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an Emoticon message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.Emoticon
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.Emoticon} Emoticon
         */
        Emoticon.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.Emoticon)
                return d;
            var m = new $root.Proto.Emoticon();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.Emoticon.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from an Emoticon message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.Emoticon
         * @static
         * @param {Proto.Emoticon} m Emoticon
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Emoticon.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.text = "";
                d.file = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this Emoticon to JSON.
         * @function toJSON
         * @memberof Proto.Emoticon
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Emoticon.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Emoticon;
    })();

    Proto.EmoticonList = (function() {

        /**
         * Properties of an EmoticonList.
         * @memberof Proto
         * @interface IEmoticonList
         * @property {Array.<Proto.IEmoticon>|null} [value] EmoticonList value
         */

        /**
         * Constructs a new EmoticonList.
         * @memberof Proto
         * @classdesc Represents an EmoticonList.
         * @implements IEmoticonList
         * @constructor
         * @param {Proto.IEmoticonList=} [p] Properties to set
         */
        function EmoticonList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * EmoticonList value.
         * @member {Array.<Proto.IEmoticon>} value
         * @memberof Proto.EmoticonList
         * @instance
         */
        EmoticonList.prototype.value = $util.emptyArray;

        /**
         * Creates a new EmoticonList instance using the specified properties.
         * @function create
         * @memberof Proto.EmoticonList
         * @static
         * @param {Proto.IEmoticonList=} [properties] Properties to set
         * @returns {Proto.EmoticonList} EmoticonList instance
         */
        EmoticonList.create = function create(properties) {
            return new EmoticonList(properties);
        };

        /**
         * Encodes the specified EmoticonList message. Does not implicitly {@link Proto.EmoticonList.verify|verify} messages.
         * @function encode
         * @memberof Proto.EmoticonList
         * @static
         * @param {Proto.IEmoticonList} m EmoticonList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmoticonList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.Emoticon.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes an EmoticonList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.EmoticonList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.EmoticonList} EmoticonList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmoticonList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.EmoticonList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.Emoticon.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an EmoticonList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.EmoticonList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.EmoticonList} EmoticonList
         */
        EmoticonList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.EmoticonList)
                return d;
            var m = new $root.Proto.EmoticonList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.EmoticonList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.EmoticonList.value: object expected");
                    m.value[i] = $root.Proto.Emoticon.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from an EmoticonList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.EmoticonList
         * @static
         * @param {Proto.EmoticonList} m EmoticonList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmoticonList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.Emoticon.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this EmoticonList to JSON.
         * @function toJSON
         * @memberof Proto.EmoticonList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmoticonList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EmoticonList;
    })();

    Proto.Stamp = (function() {

        /**
         * Properties of a Stamp.
         * @memberof Proto
         * @interface IStamp
         * @property {string|null} [uid] Stamp uid
         * @property {number|null} [type] Stamp type
         * @property {string|null} [text] Stamp text
         * @property {Proto.IUploadFile|null} [file] Stamp file
         * @property {number|Long|null} [registerDate] Stamp registerDate
         * @property {number|Long|null} [modifyDate] Stamp modifyDate
         */

        /**
         * Constructs a new Stamp.
         * @memberof Proto
         * @classdesc Represents a Stamp.
         * @implements IStamp
         * @constructor
         * @param {Proto.IStamp=} [p] Properties to set
         */
        function Stamp(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Stamp uid.
         * @member {string} uid
         * @memberof Proto.Stamp
         * @instance
         */
        Stamp.prototype.uid = "";

        /**
         * Stamp type.
         * @member {number} type
         * @memberof Proto.Stamp
         * @instance
         */
        Stamp.prototype.type = 0;

        /**
         * Stamp text.
         * @member {string} text
         * @memberof Proto.Stamp
         * @instance
         */
        Stamp.prototype.text = "";

        /**
         * Stamp file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.Stamp
         * @instance
         */
        Stamp.prototype.file = null;

        /**
         * Stamp registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.Stamp
         * @instance
         */
        Stamp.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Stamp modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.Stamp
         * @instance
         */
        Stamp.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Stamp instance using the specified properties.
         * @function create
         * @memberof Proto.Stamp
         * @static
         * @param {Proto.IStamp=} [properties] Properties to set
         * @returns {Proto.Stamp} Stamp instance
         */
        Stamp.create = function create(properties) {
            return new Stamp(properties);
        };

        /**
         * Encodes the specified Stamp message. Does not implicitly {@link Proto.Stamp.verify|verify} messages.
         * @function encode
         * @memberof Proto.Stamp
         * @static
         * @param {Proto.IStamp} m Stamp message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Stamp.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(34).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(40).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(48).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a Stamp message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.Stamp
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.Stamp} Stamp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Stamp.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.Stamp();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 5:
                    m.registerDate = r.int64();
                    break;
                case 6:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Stamp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.Stamp
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.Stamp} Stamp
         */
        Stamp.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.Stamp)
                return d;
            var m = new $root.Proto.Stamp();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.Stamp.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a Stamp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.Stamp
         * @static
         * @param {Proto.Stamp} m Stamp
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Stamp.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.text = "";
                d.file = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this Stamp to JSON.
         * @function toJSON
         * @memberof Proto.Stamp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Stamp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Stamp;
    })();

    Proto.StampList = (function() {

        /**
         * Properties of a StampList.
         * @memberof Proto
         * @interface IStampList
         * @property {Array.<Proto.IStamp>|null} [value] StampList value
         */

        /**
         * Constructs a new StampList.
         * @memberof Proto
         * @classdesc Represents a StampList.
         * @implements IStampList
         * @constructor
         * @param {Proto.IStampList=} [p] Properties to set
         */
        function StampList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * StampList value.
         * @member {Array.<Proto.IStamp>} value
         * @memberof Proto.StampList
         * @instance
         */
        StampList.prototype.value = $util.emptyArray;

        /**
         * Creates a new StampList instance using the specified properties.
         * @function create
         * @memberof Proto.StampList
         * @static
         * @param {Proto.IStampList=} [properties] Properties to set
         * @returns {Proto.StampList} StampList instance
         */
        StampList.create = function create(properties) {
            return new StampList(properties);
        };

        /**
         * Encodes the specified StampList message. Does not implicitly {@link Proto.StampList.verify|verify} messages.
         * @function encode
         * @memberof Proto.StampList
         * @static
         * @param {Proto.IStampList} m StampList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StampList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.Stamp.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a StampList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.StampList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.StampList} StampList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StampList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.StampList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.Stamp.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a StampList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.StampList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.StampList} StampList
         */
        StampList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.StampList)
                return d;
            var m = new $root.Proto.StampList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.StampList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.StampList.value: object expected");
                    m.value[i] = $root.Proto.Stamp.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a StampList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.StampList
         * @static
         * @param {Proto.StampList} m StampList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StampList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.Stamp.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this StampList to JSON.
         * @function toJSON
         * @memberof Proto.StampList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StampList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StampList;
    })();

    Proto.StampGroup = (function() {

        /**
         * Properties of a StampGroup.
         * @memberof Proto
         * @interface IStampGroup
         * @property {string|null} [uid] StampGroup uid
         * @property {Proto.IUploadFile|null} [icon] StampGroup icon
         * @property {number|Long|null} [registerDate] StampGroup registerDate
         * @property {number|Long|null} [modifyDate] StampGroup modifyDate
         */

        /**
         * Constructs a new StampGroup.
         * @memberof Proto
         * @classdesc Represents a StampGroup.
         * @implements IStampGroup
         * @constructor
         * @param {Proto.IStampGroup=} [p] Properties to set
         */
        function StampGroup(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * StampGroup uid.
         * @member {string} uid
         * @memberof Proto.StampGroup
         * @instance
         */
        StampGroup.prototype.uid = "";

        /**
         * StampGroup icon.
         * @member {Proto.IUploadFile|null|undefined} icon
         * @memberof Proto.StampGroup
         * @instance
         */
        StampGroup.prototype.icon = null;

        /**
         * StampGroup registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.StampGroup
         * @instance
         */
        StampGroup.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * StampGroup modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.StampGroup
         * @instance
         */
        StampGroup.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new StampGroup instance using the specified properties.
         * @function create
         * @memberof Proto.StampGroup
         * @static
         * @param {Proto.IStampGroup=} [properties] Properties to set
         * @returns {Proto.StampGroup} StampGroup instance
         */
        StampGroup.create = function create(properties) {
            return new StampGroup(properties);
        };

        /**
         * Encodes the specified StampGroup message. Does not implicitly {@link Proto.StampGroup.verify|verify} messages.
         * @function encode
         * @memberof Proto.StampGroup
         * @static
         * @param {Proto.IStampGroup} m StampGroup message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StampGroup.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.icon != null && Object.hasOwnProperty.call(m, "icon"))
                $root.Proto.UploadFile.encode(m.icon, w.uint32(18).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(32).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a StampGroup message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.StampGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.StampGroup} StampGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StampGroup.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.StampGroup();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.icon = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a StampGroup message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.StampGroup
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.StampGroup} StampGroup
         */
        StampGroup.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.StampGroup)
                return d;
            var m = new $root.Proto.StampGroup();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.icon != null) {
                if (typeof d.icon !== "object")
                    throw TypeError(".Proto.StampGroup.icon: object expected");
                m.icon = $root.Proto.UploadFile.fromObject(d.icon);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a StampGroup message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.StampGroup
         * @static
         * @param {Proto.StampGroup} m StampGroup
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StampGroup.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.icon = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.icon != null && m.hasOwnProperty("icon")) {
                d.icon = $root.Proto.UploadFile.toObject(m.icon, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this StampGroup to JSON.
         * @function toJSON
         * @memberof Proto.StampGroup
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StampGroup.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StampGroup;
    })();

    Proto.StampGroupList = (function() {

        /**
         * Properties of a StampGroupList.
         * @memberof Proto
         * @interface IStampGroupList
         * @property {Array.<Proto.IStampGroup>|null} [value] StampGroupList value
         */

        /**
         * Constructs a new StampGroupList.
         * @memberof Proto
         * @classdesc Represents a StampGroupList.
         * @implements IStampGroupList
         * @constructor
         * @param {Proto.IStampGroupList=} [p] Properties to set
         */
        function StampGroupList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * StampGroupList value.
         * @member {Array.<Proto.IStampGroup>} value
         * @memberof Proto.StampGroupList
         * @instance
         */
        StampGroupList.prototype.value = $util.emptyArray;

        /**
         * Creates a new StampGroupList instance using the specified properties.
         * @function create
         * @memberof Proto.StampGroupList
         * @static
         * @param {Proto.IStampGroupList=} [properties] Properties to set
         * @returns {Proto.StampGroupList} StampGroupList instance
         */
        StampGroupList.create = function create(properties) {
            return new StampGroupList(properties);
        };

        /**
         * Encodes the specified StampGroupList message. Does not implicitly {@link Proto.StampGroupList.verify|verify} messages.
         * @function encode
         * @memberof Proto.StampGroupList
         * @static
         * @param {Proto.IStampGroupList} m StampGroupList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StampGroupList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.StampGroup.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a StampGroupList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.StampGroupList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.StampGroupList} StampGroupList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StampGroupList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.StampGroupList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.StampGroup.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a StampGroupList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.StampGroupList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.StampGroupList} StampGroupList
         */
        StampGroupList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.StampGroupList)
                return d;
            var m = new $root.Proto.StampGroupList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.StampGroupList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.StampGroupList.value: object expected");
                    m.value[i] = $root.Proto.StampGroup.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a StampGroupList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.StampGroupList
         * @static
         * @param {Proto.StampGroupList} m StampGroupList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StampGroupList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.StampGroup.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this StampGroupList to JSON.
         * @function toJSON
         * @memberof Proto.StampGroupList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StampGroupList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StampGroupList;
    })();

    Proto.IconTemplate = (function() {

        /**
         * Properties of an IconTemplate.
         * @memberof Proto
         * @interface IIconTemplate
         * @property {string|null} [uid] IconTemplate uid
         * @property {number|null} [type] IconTemplate type
         * @property {Proto.IUploadFile|null} [file] IconTemplate file
         * @property {number|Long|null} [registerDate] IconTemplate registerDate
         * @property {number|Long|null} [modifyDate] IconTemplate modifyDate
         */

        /**
         * Constructs a new IconTemplate.
         * @memberof Proto
         * @classdesc Represents an IconTemplate.
         * @implements IIconTemplate
         * @constructor
         * @param {Proto.IIconTemplate=} [p] Properties to set
         */
        function IconTemplate(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * IconTemplate uid.
         * @member {string} uid
         * @memberof Proto.IconTemplate
         * @instance
         */
        IconTemplate.prototype.uid = "";

        /**
         * IconTemplate type.
         * @member {number} type
         * @memberof Proto.IconTemplate
         * @instance
         */
        IconTemplate.prototype.type = 0;

        /**
         * IconTemplate file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.IconTemplate
         * @instance
         */
        IconTemplate.prototype.file = null;

        /**
         * IconTemplate registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.IconTemplate
         * @instance
         */
        IconTemplate.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * IconTemplate modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.IconTemplate
         * @instance
         */
        IconTemplate.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new IconTemplate instance using the specified properties.
         * @function create
         * @memberof Proto.IconTemplate
         * @static
         * @param {Proto.IIconTemplate=} [properties] Properties to set
         * @returns {Proto.IconTemplate} IconTemplate instance
         */
        IconTemplate.create = function create(properties) {
            return new IconTemplate(properties);
        };

        /**
         * Encodes the specified IconTemplate message. Does not implicitly {@link Proto.IconTemplate.verify|verify} messages.
         * @function encode
         * @memberof Proto.IconTemplate
         * @static
         * @param {Proto.IIconTemplate} m IconTemplate message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IconTemplate.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(26).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes an IconTemplate message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.IconTemplate
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.IconTemplate} IconTemplate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IconTemplate.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.IconTemplate();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an IconTemplate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.IconTemplate
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.IconTemplate} IconTemplate
         */
        IconTemplate.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.IconTemplate)
                return d;
            var m = new $root.Proto.IconTemplate();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.IconTemplate.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from an IconTemplate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.IconTemplate
         * @static
         * @param {Proto.IconTemplate} m IconTemplate
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IconTemplate.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.file = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this IconTemplate to JSON.
         * @function toJSON
         * @memberof Proto.IconTemplate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IconTemplate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return IconTemplate;
    })();

    Proto.IconTemplateList = (function() {

        /**
         * Properties of an IconTemplateList.
         * @memberof Proto
         * @interface IIconTemplateList
         * @property {Array.<Proto.IIconTemplate>|null} [value] IconTemplateList value
         */

        /**
         * Constructs a new IconTemplateList.
         * @memberof Proto
         * @classdesc Represents an IconTemplateList.
         * @implements IIconTemplateList
         * @constructor
         * @param {Proto.IIconTemplateList=} [p] Properties to set
         */
        function IconTemplateList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * IconTemplateList value.
         * @member {Array.<Proto.IIconTemplate>} value
         * @memberof Proto.IconTemplateList
         * @instance
         */
        IconTemplateList.prototype.value = $util.emptyArray;

        /**
         * Creates a new IconTemplateList instance using the specified properties.
         * @function create
         * @memberof Proto.IconTemplateList
         * @static
         * @param {Proto.IIconTemplateList=} [properties] Properties to set
         * @returns {Proto.IconTemplateList} IconTemplateList instance
         */
        IconTemplateList.create = function create(properties) {
            return new IconTemplateList(properties);
        };

        /**
         * Encodes the specified IconTemplateList message. Does not implicitly {@link Proto.IconTemplateList.verify|verify} messages.
         * @function encode
         * @memberof Proto.IconTemplateList
         * @static
         * @param {Proto.IIconTemplateList} m IconTemplateList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IconTemplateList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.IconTemplate.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes an IconTemplateList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.IconTemplateList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.IconTemplateList} IconTemplateList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IconTemplateList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.IconTemplateList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.IconTemplate.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates an IconTemplateList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.IconTemplateList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.IconTemplateList} IconTemplateList
         */
        IconTemplateList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.IconTemplateList)
                return d;
            var m = new $root.Proto.IconTemplateList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.IconTemplateList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.IconTemplateList.value: object expected");
                    m.value[i] = $root.Proto.IconTemplate.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from an IconTemplateList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.IconTemplateList
         * @static
         * @param {Proto.IconTemplateList} m IconTemplateList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IconTemplateList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.IconTemplate.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this IconTemplateList to JSON.
         * @function toJSON
         * @memberof Proto.IconTemplateList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IconTemplateList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return IconTemplateList;
    })();

    Proto.CompanyLoginLaunchScreen = (function() {

        /**
         * Properties of a CompanyLoginLaunchScreen.
         * @memberof Proto
         * @interface ICompanyLoginLaunchScreen
         * @property {string|null} [uid] CompanyLoginLaunchScreen uid
         * @property {string|null} [title] CompanyLoginLaunchScreen title
         * @property {string|null} [text] CompanyLoginLaunchScreen text
         * @property {number|null} [fileType] CompanyLoginLaunchScreen fileType
         * @property {number|null} [order] CompanyLoginLaunchScreen order
         * @property {Proto.IUploadFile|null} [file] CompanyLoginLaunchScreen file
         * @property {number|Long|null} [registerDate] CompanyLoginLaunchScreen registerDate
         * @property {number|Long|null} [modifyDate] CompanyLoginLaunchScreen modifyDate
         * @property {number|null} [status] CompanyLoginLaunchScreen status
         * @property {number|null} [dimAlpha] CompanyLoginLaunchScreen dimAlpha
         */

        /**
         * Constructs a new CompanyLoginLaunchScreen.
         * @memberof Proto
         * @classdesc Represents a CompanyLoginLaunchScreen.
         * @implements ICompanyLoginLaunchScreen
         * @constructor
         * @param {Proto.ICompanyLoginLaunchScreen=} [p] Properties to set
         */
        function CompanyLoginLaunchScreen(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyLoginLaunchScreen uid.
         * @member {string} uid
         * @memberof Proto.CompanyLoginLaunchScreen
         * @instance
         */
        CompanyLoginLaunchScreen.prototype.uid = "";

        /**
         * CompanyLoginLaunchScreen title.
         * @member {string} title
         * @memberof Proto.CompanyLoginLaunchScreen
         * @instance
         */
        CompanyLoginLaunchScreen.prototype.title = "";

        /**
         * CompanyLoginLaunchScreen text.
         * @member {string} text
         * @memberof Proto.CompanyLoginLaunchScreen
         * @instance
         */
        CompanyLoginLaunchScreen.prototype.text = "";

        /**
         * CompanyLoginLaunchScreen fileType.
         * @member {number} fileType
         * @memberof Proto.CompanyLoginLaunchScreen
         * @instance
         */
        CompanyLoginLaunchScreen.prototype.fileType = 0;

        /**
         * CompanyLoginLaunchScreen order.
         * @member {number} order
         * @memberof Proto.CompanyLoginLaunchScreen
         * @instance
         */
        CompanyLoginLaunchScreen.prototype.order = 0;

        /**
         * CompanyLoginLaunchScreen file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.CompanyLoginLaunchScreen
         * @instance
         */
        CompanyLoginLaunchScreen.prototype.file = null;

        /**
         * CompanyLoginLaunchScreen registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CompanyLoginLaunchScreen
         * @instance
         */
        CompanyLoginLaunchScreen.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyLoginLaunchScreen modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CompanyLoginLaunchScreen
         * @instance
         */
        CompanyLoginLaunchScreen.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyLoginLaunchScreen status.
         * @member {number} status
         * @memberof Proto.CompanyLoginLaunchScreen
         * @instance
         */
        CompanyLoginLaunchScreen.prototype.status = 0;

        /**
         * CompanyLoginLaunchScreen dimAlpha.
         * @member {number} dimAlpha
         * @memberof Proto.CompanyLoginLaunchScreen
         * @instance
         */
        CompanyLoginLaunchScreen.prototype.dimAlpha = 0;

        /**
         * Creates a new CompanyLoginLaunchScreen instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyLoginLaunchScreen
         * @static
         * @param {Proto.ICompanyLoginLaunchScreen=} [properties] Properties to set
         * @returns {Proto.CompanyLoginLaunchScreen} CompanyLoginLaunchScreen instance
         */
        CompanyLoginLaunchScreen.create = function create(properties) {
            return new CompanyLoginLaunchScreen(properties);
        };

        /**
         * Encodes the specified CompanyLoginLaunchScreen message. Does not implicitly {@link Proto.CompanyLoginLaunchScreen.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyLoginLaunchScreen
         * @static
         * @param {Proto.ICompanyLoginLaunchScreen} m CompanyLoginLaunchScreen message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyLoginLaunchScreen.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(18).string(m.title);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.fileType != null && Object.hasOwnProperty.call(m, "fileType"))
                w.uint32(32).int32(m.fileType);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(40).int32(m.order);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(50).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(56).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(64).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(72).int32(m.status);
            if (m.dimAlpha != null && Object.hasOwnProperty.call(m, "dimAlpha"))
                w.uint32(80).int32(m.dimAlpha);
            return w;
        };

        /**
         * Decodes a CompanyLoginLaunchScreen message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyLoginLaunchScreen
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyLoginLaunchScreen} CompanyLoginLaunchScreen
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyLoginLaunchScreen.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyLoginLaunchScreen();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.title = r.string();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.fileType = r.int32();
                    break;
                case 5:
                    m.order = r.int32();
                    break;
                case 6:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 7:
                    m.registerDate = r.int64();
                    break;
                case 8:
                    m.modifyDate = r.int64();
                    break;
                case 9:
                    m.status = r.int32();
                    break;
                case 10:
                    m.dimAlpha = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyLoginLaunchScreen message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyLoginLaunchScreen
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyLoginLaunchScreen} CompanyLoginLaunchScreen
         */
        CompanyLoginLaunchScreen.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyLoginLaunchScreen)
                return d;
            var m = new $root.Proto.CompanyLoginLaunchScreen();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.fileType != null) {
                m.fileType = d.fileType | 0;
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.CompanyLoginLaunchScreen.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.dimAlpha != null) {
                m.dimAlpha = d.dimAlpha | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyLoginLaunchScreen message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyLoginLaunchScreen
         * @static
         * @param {Proto.CompanyLoginLaunchScreen} m CompanyLoginLaunchScreen
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyLoginLaunchScreen.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.title = "";
                d.text = "";
                d.fileType = 0;
                d.order = 0;
                d.file = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.dimAlpha = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.fileType != null && m.hasOwnProperty("fileType")) {
                d.fileType = m.fileType;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.dimAlpha != null && m.hasOwnProperty("dimAlpha")) {
                d.dimAlpha = m.dimAlpha;
            }
            return d;
        };

        /**
         * Converts this CompanyLoginLaunchScreen to JSON.
         * @function toJSON
         * @memberof Proto.CompanyLoginLaunchScreen
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyLoginLaunchScreen.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyLoginLaunchScreen;
    })();

    Proto.CompanyLoginLaunchScreenList = (function() {

        /**
         * Properties of a CompanyLoginLaunchScreenList.
         * @memberof Proto
         * @interface ICompanyLoginLaunchScreenList
         * @property {Array.<Proto.ICompanyLoginLaunchScreen>|null} [value] CompanyLoginLaunchScreenList value
         */

        /**
         * Constructs a new CompanyLoginLaunchScreenList.
         * @memberof Proto
         * @classdesc Represents a CompanyLoginLaunchScreenList.
         * @implements ICompanyLoginLaunchScreenList
         * @constructor
         * @param {Proto.ICompanyLoginLaunchScreenList=} [p] Properties to set
         */
        function CompanyLoginLaunchScreenList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyLoginLaunchScreenList value.
         * @member {Array.<Proto.ICompanyLoginLaunchScreen>} value
         * @memberof Proto.CompanyLoginLaunchScreenList
         * @instance
         */
        CompanyLoginLaunchScreenList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CompanyLoginLaunchScreenList instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyLoginLaunchScreenList
         * @static
         * @param {Proto.ICompanyLoginLaunchScreenList=} [properties] Properties to set
         * @returns {Proto.CompanyLoginLaunchScreenList} CompanyLoginLaunchScreenList instance
         */
        CompanyLoginLaunchScreenList.create = function create(properties) {
            return new CompanyLoginLaunchScreenList(properties);
        };

        /**
         * Encodes the specified CompanyLoginLaunchScreenList message. Does not implicitly {@link Proto.CompanyLoginLaunchScreenList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyLoginLaunchScreenList
         * @static
         * @param {Proto.ICompanyLoginLaunchScreenList} m CompanyLoginLaunchScreenList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyLoginLaunchScreenList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CompanyLoginLaunchScreen.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CompanyLoginLaunchScreenList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyLoginLaunchScreenList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyLoginLaunchScreenList} CompanyLoginLaunchScreenList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyLoginLaunchScreenList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyLoginLaunchScreenList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CompanyLoginLaunchScreen.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyLoginLaunchScreenList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyLoginLaunchScreenList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyLoginLaunchScreenList} CompanyLoginLaunchScreenList
         */
        CompanyLoginLaunchScreenList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyLoginLaunchScreenList)
                return d;
            var m = new $root.Proto.CompanyLoginLaunchScreenList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CompanyLoginLaunchScreenList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CompanyLoginLaunchScreenList.value: object expected");
                    m.value[i] = $root.Proto.CompanyLoginLaunchScreen.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyLoginLaunchScreenList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyLoginLaunchScreenList
         * @static
         * @param {Proto.CompanyLoginLaunchScreenList} m CompanyLoginLaunchScreenList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyLoginLaunchScreenList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CompanyLoginLaunchScreen.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CompanyLoginLaunchScreenList to JSON.
         * @function toJSON
         * @memberof Proto.CompanyLoginLaunchScreenList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyLoginLaunchScreenList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyLoginLaunchScreenList;
    })();

    Proto.ForumPolicyGroupCount = (function() {

        /**
         * Properties of a ForumPolicyGroupCount.
         * @memberof Proto
         * @interface IForumPolicyGroupCount
         * @property {string|null} [forumUID] ForumPolicyGroupCount forumUID
         * @property {string|null} [adminCount] ForumPolicyGroupCount adminCount
         * @property {Array.<Proto.ICompanyUserGroup>|null} [adminGroupList] ForumPolicyGroupCount adminGroupList
         * @property {string|null} [scopeCount] ForumPolicyGroupCount scopeCount
         * @property {Array.<Proto.ICompanyUserGroup>|null} [scopeGroupList] ForumPolicyGroupCount scopeGroupList
         * @property {string|null} [topicCount] ForumPolicyGroupCount topicCount
         * @property {Array.<Proto.ICompanyUserGroup>|null} [topicGroupList] ForumPolicyGroupCount topicGroupList
         * @property {string|null} [commentCount] ForumPolicyGroupCount commentCount
         * @property {Array.<Proto.ICompanyUserGroup>|null} [commentGroupList] ForumPolicyGroupCount commentGroupList
         */

        /**
         * Constructs a new ForumPolicyGroupCount.
         * @memberof Proto
         * @classdesc Represents a ForumPolicyGroupCount.
         * @implements IForumPolicyGroupCount
         * @constructor
         * @param {Proto.IForumPolicyGroupCount=} [p] Properties to set
         */
        function ForumPolicyGroupCount(p) {
            this.adminGroupList = [];
            this.scopeGroupList = [];
            this.topicGroupList = [];
            this.commentGroupList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ForumPolicyGroupCount forumUID.
         * @member {string} forumUID
         * @memberof Proto.ForumPolicyGroupCount
         * @instance
         */
        ForumPolicyGroupCount.prototype.forumUID = "";

        /**
         * ForumPolicyGroupCount adminCount.
         * @member {string} adminCount
         * @memberof Proto.ForumPolicyGroupCount
         * @instance
         */
        ForumPolicyGroupCount.prototype.adminCount = "";

        /**
         * ForumPolicyGroupCount adminGroupList.
         * @member {Array.<Proto.ICompanyUserGroup>} adminGroupList
         * @memberof Proto.ForumPolicyGroupCount
         * @instance
         */
        ForumPolicyGroupCount.prototype.adminGroupList = $util.emptyArray;

        /**
         * ForumPolicyGroupCount scopeCount.
         * @member {string} scopeCount
         * @memberof Proto.ForumPolicyGroupCount
         * @instance
         */
        ForumPolicyGroupCount.prototype.scopeCount = "";

        /**
         * ForumPolicyGroupCount scopeGroupList.
         * @member {Array.<Proto.ICompanyUserGroup>} scopeGroupList
         * @memberof Proto.ForumPolicyGroupCount
         * @instance
         */
        ForumPolicyGroupCount.prototype.scopeGroupList = $util.emptyArray;

        /**
         * ForumPolicyGroupCount topicCount.
         * @member {string} topicCount
         * @memberof Proto.ForumPolicyGroupCount
         * @instance
         */
        ForumPolicyGroupCount.prototype.topicCount = "";

        /**
         * ForumPolicyGroupCount topicGroupList.
         * @member {Array.<Proto.ICompanyUserGroup>} topicGroupList
         * @memberof Proto.ForumPolicyGroupCount
         * @instance
         */
        ForumPolicyGroupCount.prototype.topicGroupList = $util.emptyArray;

        /**
         * ForumPolicyGroupCount commentCount.
         * @member {string} commentCount
         * @memberof Proto.ForumPolicyGroupCount
         * @instance
         */
        ForumPolicyGroupCount.prototype.commentCount = "";

        /**
         * ForumPolicyGroupCount commentGroupList.
         * @member {Array.<Proto.ICompanyUserGroup>} commentGroupList
         * @memberof Proto.ForumPolicyGroupCount
         * @instance
         */
        ForumPolicyGroupCount.prototype.commentGroupList = $util.emptyArray;

        /**
         * Creates a new ForumPolicyGroupCount instance using the specified properties.
         * @function create
         * @memberof Proto.ForumPolicyGroupCount
         * @static
         * @param {Proto.IForumPolicyGroupCount=} [properties] Properties to set
         * @returns {Proto.ForumPolicyGroupCount} ForumPolicyGroupCount instance
         */
        ForumPolicyGroupCount.create = function create(properties) {
            return new ForumPolicyGroupCount(properties);
        };

        /**
         * Encodes the specified ForumPolicyGroupCount message. Does not implicitly {@link Proto.ForumPolicyGroupCount.verify|verify} messages.
         * @function encode
         * @memberof Proto.ForumPolicyGroupCount
         * @static
         * @param {Proto.IForumPolicyGroupCount} m ForumPolicyGroupCount message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForumPolicyGroupCount.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.forumUID != null && Object.hasOwnProperty.call(m, "forumUID"))
                w.uint32(10).string(m.forumUID);
            if (m.adminCount != null && Object.hasOwnProperty.call(m, "adminCount"))
                w.uint32(18).string(m.adminCount);
            if (m.adminGroupList != null && m.adminGroupList.length) {
                for (var i = 0; i < m.adminGroupList.length; ++i)
                    $root.Proto.CompanyUserGroup.encode(m.adminGroupList[i], w.uint32(26).fork()).ldelim();
            }
            if (m.scopeCount != null && Object.hasOwnProperty.call(m, "scopeCount"))
                w.uint32(34).string(m.scopeCount);
            if (m.scopeGroupList != null && m.scopeGroupList.length) {
                for (var i = 0; i < m.scopeGroupList.length; ++i)
                    $root.Proto.CompanyUserGroup.encode(m.scopeGroupList[i], w.uint32(42).fork()).ldelim();
            }
            if (m.topicCount != null && Object.hasOwnProperty.call(m, "topicCount"))
                w.uint32(50).string(m.topicCount);
            if (m.topicGroupList != null && m.topicGroupList.length) {
                for (var i = 0; i < m.topicGroupList.length; ++i)
                    $root.Proto.CompanyUserGroup.encode(m.topicGroupList[i], w.uint32(58).fork()).ldelim();
            }
            if (m.commentCount != null && Object.hasOwnProperty.call(m, "commentCount"))
                w.uint32(66).string(m.commentCount);
            if (m.commentGroupList != null && m.commentGroupList.length) {
                for (var i = 0; i < m.commentGroupList.length; ++i)
                    $root.Proto.CompanyUserGroup.encode(m.commentGroupList[i], w.uint32(74).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ForumPolicyGroupCount message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ForumPolicyGroupCount
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ForumPolicyGroupCount} ForumPolicyGroupCount
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForumPolicyGroupCount.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ForumPolicyGroupCount();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.forumUID = r.string();
                    break;
                case 2:
                    m.adminCount = r.string();
                    break;
                case 3:
                    if (!(m.adminGroupList && m.adminGroupList.length))
                        m.adminGroupList = [];
                    m.adminGroupList.push($root.Proto.CompanyUserGroup.decode(r, r.uint32()));
                    break;
                case 4:
                    m.scopeCount = r.string();
                    break;
                case 5:
                    if (!(m.scopeGroupList && m.scopeGroupList.length))
                        m.scopeGroupList = [];
                    m.scopeGroupList.push($root.Proto.CompanyUserGroup.decode(r, r.uint32()));
                    break;
                case 6:
                    m.topicCount = r.string();
                    break;
                case 7:
                    if (!(m.topicGroupList && m.topicGroupList.length))
                        m.topicGroupList = [];
                    m.topicGroupList.push($root.Proto.CompanyUserGroup.decode(r, r.uint32()));
                    break;
                case 8:
                    m.commentCount = r.string();
                    break;
                case 9:
                    if (!(m.commentGroupList && m.commentGroupList.length))
                        m.commentGroupList = [];
                    m.commentGroupList.push($root.Proto.CompanyUserGroup.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ForumPolicyGroupCount message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ForumPolicyGroupCount
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ForumPolicyGroupCount} ForumPolicyGroupCount
         */
        ForumPolicyGroupCount.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ForumPolicyGroupCount)
                return d;
            var m = new $root.Proto.ForumPolicyGroupCount();
            if (d.forumUID != null) {
                m.forumUID = String(d.forumUID);
            }
            if (d.adminCount != null) {
                m.adminCount = String(d.adminCount);
            }
            if (d.adminGroupList) {
                if (!Array.isArray(d.adminGroupList))
                    throw TypeError(".Proto.ForumPolicyGroupCount.adminGroupList: array expected");
                m.adminGroupList = [];
                for (var i = 0; i < d.adminGroupList.length; ++i) {
                    if (typeof d.adminGroupList[i] !== "object")
                        throw TypeError(".Proto.ForumPolicyGroupCount.adminGroupList: object expected");
                    m.adminGroupList[i] = $root.Proto.CompanyUserGroup.fromObject(d.adminGroupList[i]);
                }
            }
            if (d.scopeCount != null) {
                m.scopeCount = String(d.scopeCount);
            }
            if (d.scopeGroupList) {
                if (!Array.isArray(d.scopeGroupList))
                    throw TypeError(".Proto.ForumPolicyGroupCount.scopeGroupList: array expected");
                m.scopeGroupList = [];
                for (var i = 0; i < d.scopeGroupList.length; ++i) {
                    if (typeof d.scopeGroupList[i] !== "object")
                        throw TypeError(".Proto.ForumPolicyGroupCount.scopeGroupList: object expected");
                    m.scopeGroupList[i] = $root.Proto.CompanyUserGroup.fromObject(d.scopeGroupList[i]);
                }
            }
            if (d.topicCount != null) {
                m.topicCount = String(d.topicCount);
            }
            if (d.topicGroupList) {
                if (!Array.isArray(d.topicGroupList))
                    throw TypeError(".Proto.ForumPolicyGroupCount.topicGroupList: array expected");
                m.topicGroupList = [];
                for (var i = 0; i < d.topicGroupList.length; ++i) {
                    if (typeof d.topicGroupList[i] !== "object")
                        throw TypeError(".Proto.ForumPolicyGroupCount.topicGroupList: object expected");
                    m.topicGroupList[i] = $root.Proto.CompanyUserGroup.fromObject(d.topicGroupList[i]);
                }
            }
            if (d.commentCount != null) {
                m.commentCount = String(d.commentCount);
            }
            if (d.commentGroupList) {
                if (!Array.isArray(d.commentGroupList))
                    throw TypeError(".Proto.ForumPolicyGroupCount.commentGroupList: array expected");
                m.commentGroupList = [];
                for (var i = 0; i < d.commentGroupList.length; ++i) {
                    if (typeof d.commentGroupList[i] !== "object")
                        throw TypeError(".Proto.ForumPolicyGroupCount.commentGroupList: object expected");
                    m.commentGroupList[i] = $root.Proto.CompanyUserGroup.fromObject(d.commentGroupList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ForumPolicyGroupCount message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ForumPolicyGroupCount
         * @static
         * @param {Proto.ForumPolicyGroupCount} m ForumPolicyGroupCount
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ForumPolicyGroupCount.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.adminGroupList = [];
                d.scopeGroupList = [];
                d.topicGroupList = [];
                d.commentGroupList = [];
            }
            if (o.defaults) {
                d.forumUID = "";
                d.adminCount = "";
                d.scopeCount = "";
                d.topicCount = "";
                d.commentCount = "";
            }
            if (m.forumUID != null && m.hasOwnProperty("forumUID")) {
                d.forumUID = m.forumUID;
            }
            if (m.adminCount != null && m.hasOwnProperty("adminCount")) {
                d.adminCount = m.adminCount;
            }
            if (m.adminGroupList && m.adminGroupList.length) {
                d.adminGroupList = [];
                for (var j = 0; j < m.adminGroupList.length; ++j) {
                    d.adminGroupList[j] = $root.Proto.CompanyUserGroup.toObject(m.adminGroupList[j], o);
                }
            }
            if (m.scopeCount != null && m.hasOwnProperty("scopeCount")) {
                d.scopeCount = m.scopeCount;
            }
            if (m.scopeGroupList && m.scopeGroupList.length) {
                d.scopeGroupList = [];
                for (var j = 0; j < m.scopeGroupList.length; ++j) {
                    d.scopeGroupList[j] = $root.Proto.CompanyUserGroup.toObject(m.scopeGroupList[j], o);
                }
            }
            if (m.topicCount != null && m.hasOwnProperty("topicCount")) {
                d.topicCount = m.topicCount;
            }
            if (m.topicGroupList && m.topicGroupList.length) {
                d.topicGroupList = [];
                for (var j = 0; j < m.topicGroupList.length; ++j) {
                    d.topicGroupList[j] = $root.Proto.CompanyUserGroup.toObject(m.topicGroupList[j], o);
                }
            }
            if (m.commentCount != null && m.hasOwnProperty("commentCount")) {
                d.commentCount = m.commentCount;
            }
            if (m.commentGroupList && m.commentGroupList.length) {
                d.commentGroupList = [];
                for (var j = 0; j < m.commentGroupList.length; ++j) {
                    d.commentGroupList[j] = $root.Proto.CompanyUserGroup.toObject(m.commentGroupList[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ForumPolicyGroupCount to JSON.
         * @function toJSON
         * @memberof Proto.ForumPolicyGroupCount
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ForumPolicyGroupCount.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ForumPolicyGroupCount;
    })();

    Proto.CommunityBoardForumItem = (function() {

        /**
         * Properties of a CommunityBoardForumItem.
         * @memberof Proto
         * @interface ICommunityBoardForumItem
         * @property {string|null} [uid] CommunityBoardForumItem uid
         * @property {string|null} [forumUID] CommunityBoardForumItem forumUID
         * @property {number|null} [type] CommunityBoardForumItem type
         * @property {string|null} [name] CommunityBoardForumItem name
         * @property {boolean|null} [optional] CommunityBoardForumItem optional
         * @property {string|null} [setting] CommunityBoardForumItem setting
         * @property {number|null} [order] CommunityBoardForumItem order
         * @property {number|Long|null} [registerDate] CommunityBoardForumItem registerDate
         * @property {number|Long|null} [modifyDate] CommunityBoardForumItem modifyDate
         * @property {number|null} [status] CommunityBoardForumItem status
         */

        /**
         * Constructs a new CommunityBoardForumItem.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardForumItem.
         * @implements ICommunityBoardForumItem
         * @constructor
         * @param {Proto.ICommunityBoardForumItem=} [p] Properties to set
         */
        function CommunityBoardForumItem(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardForumItem uid.
         * @member {string} uid
         * @memberof Proto.CommunityBoardForumItem
         * @instance
         */
        CommunityBoardForumItem.prototype.uid = "";

        /**
         * CommunityBoardForumItem forumUID.
         * @member {string} forumUID
         * @memberof Proto.CommunityBoardForumItem
         * @instance
         */
        CommunityBoardForumItem.prototype.forumUID = "";

        /**
         * CommunityBoardForumItem type.
         * @member {number} type
         * @memberof Proto.CommunityBoardForumItem
         * @instance
         */
        CommunityBoardForumItem.prototype.type = 0;

        /**
         * CommunityBoardForumItem name.
         * @member {string} name
         * @memberof Proto.CommunityBoardForumItem
         * @instance
         */
        CommunityBoardForumItem.prototype.name = "";

        /**
         * CommunityBoardForumItem optional.
         * @member {boolean} optional
         * @memberof Proto.CommunityBoardForumItem
         * @instance
         */
        CommunityBoardForumItem.prototype.optional = false;

        /**
         * CommunityBoardForumItem setting.
         * @member {string} setting
         * @memberof Proto.CommunityBoardForumItem
         * @instance
         */
        CommunityBoardForumItem.prototype.setting = "";

        /**
         * CommunityBoardForumItem order.
         * @member {number} order
         * @memberof Proto.CommunityBoardForumItem
         * @instance
         */
        CommunityBoardForumItem.prototype.order = 0;

        /**
         * CommunityBoardForumItem registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommunityBoardForumItem
         * @instance
         */
        CommunityBoardForumItem.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardForumItem modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommunityBoardForumItem
         * @instance
         */
        CommunityBoardForumItem.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardForumItem status.
         * @member {number} status
         * @memberof Proto.CommunityBoardForumItem
         * @instance
         */
        CommunityBoardForumItem.prototype.status = 0;

        /**
         * Creates a new CommunityBoardForumItem instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardForumItem
         * @static
         * @param {Proto.ICommunityBoardForumItem=} [properties] Properties to set
         * @returns {Proto.CommunityBoardForumItem} CommunityBoardForumItem instance
         */
        CommunityBoardForumItem.create = function create(properties) {
            return new CommunityBoardForumItem(properties);
        };

        /**
         * Encodes the specified CommunityBoardForumItem message. Does not implicitly {@link Proto.CommunityBoardForumItem.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardForumItem
         * @static
         * @param {Proto.ICommunityBoardForumItem} m CommunityBoardForumItem message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardForumItem.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.forumUID != null && Object.hasOwnProperty.call(m, "forumUID"))
                w.uint32(18).string(m.forumUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(24).int32(m.type);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(34).string(m.name);
            if (m.optional != null && Object.hasOwnProperty.call(m, "optional"))
                w.uint32(40).bool(m.optional);
            if (m.setting != null && Object.hasOwnProperty.call(m, "setting"))
                w.uint32(50).string(m.setting);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(56).int32(m.order);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(80).int32(m.status);
            return w;
        };

        /**
         * Decodes a CommunityBoardForumItem message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardForumItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardForumItem} CommunityBoardForumItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardForumItem.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardForumItem();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.forumUID = r.string();
                    break;
                case 3:
                    m.type = r.int32();
                    break;
                case 4:
                    m.name = r.string();
                    break;
                case 5:
                    m.optional = r.bool();
                    break;
                case 6:
                    m.setting = r.string();
                    break;
                case 7:
                    m.order = r.int32();
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                case 10:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardForumItem message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardForumItem
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardForumItem} CommunityBoardForumItem
         */
        CommunityBoardForumItem.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardForumItem)
                return d;
            var m = new $root.Proto.CommunityBoardForumItem();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.forumUID != null) {
                m.forumUID = String(d.forumUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.optional != null) {
                m.optional = Boolean(d.optional);
            }
            if (d.setting != null) {
                m.setting = String(d.setting);
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardForumItem message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardForumItem
         * @static
         * @param {Proto.CommunityBoardForumItem} m CommunityBoardForumItem
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardForumItem.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.forumUID = "";
                d.type = 0;
                d.name = "";
                d.optional = false;
                d.setting = "";
                d.order = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.forumUID != null && m.hasOwnProperty("forumUID")) {
                d.forumUID = m.forumUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.optional != null && m.hasOwnProperty("optional")) {
                d.optional = m.optional;
            }
            if (m.setting != null && m.hasOwnProperty("setting")) {
                d.setting = m.setting;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this CommunityBoardForumItem to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardForumItem
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardForumItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardForumItem;
    })();

    Proto.CommunityBoardForumItemList = (function() {

        /**
         * Properties of a CommunityBoardForumItemList.
         * @memberof Proto
         * @interface ICommunityBoardForumItemList
         * @property {Array.<Proto.ICommunityBoardForumItem>|null} [value] CommunityBoardForumItemList value
         */

        /**
         * Constructs a new CommunityBoardForumItemList.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardForumItemList.
         * @implements ICommunityBoardForumItemList
         * @constructor
         * @param {Proto.ICommunityBoardForumItemList=} [p] Properties to set
         */
        function CommunityBoardForumItemList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardForumItemList value.
         * @member {Array.<Proto.ICommunityBoardForumItem>} value
         * @memberof Proto.CommunityBoardForumItemList
         * @instance
         */
        CommunityBoardForumItemList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CommunityBoardForumItemList instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardForumItemList
         * @static
         * @param {Proto.ICommunityBoardForumItemList=} [properties] Properties to set
         * @returns {Proto.CommunityBoardForumItemList} CommunityBoardForumItemList instance
         */
        CommunityBoardForumItemList.create = function create(properties) {
            return new CommunityBoardForumItemList(properties);
        };

        /**
         * Encodes the specified CommunityBoardForumItemList message. Does not implicitly {@link Proto.CommunityBoardForumItemList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardForumItemList
         * @static
         * @param {Proto.ICommunityBoardForumItemList} m CommunityBoardForumItemList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardForumItemList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CommunityBoardForumItem.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CommunityBoardForumItemList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardForumItemList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardForumItemList} CommunityBoardForumItemList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardForumItemList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardForumItemList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CommunityBoardForumItem.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardForumItemList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardForumItemList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardForumItemList} CommunityBoardForumItemList
         */
        CommunityBoardForumItemList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardForumItemList)
                return d;
            var m = new $root.Proto.CommunityBoardForumItemList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CommunityBoardForumItemList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardForumItemList.value: object expected");
                    m.value[i] = $root.Proto.CommunityBoardForumItem.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardForumItemList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardForumItemList
         * @static
         * @param {Proto.CommunityBoardForumItemList} m CommunityBoardForumItemList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardForumItemList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CommunityBoardForumItem.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CommunityBoardForumItemList to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardForumItemList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardForumItemList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardForumItemList;
    })();

    Proto.CommunityBoardTopicItem = (function() {

        /**
         * Properties of a CommunityBoardTopicItem.
         * @memberof Proto
         * @interface ICommunityBoardTopicItem
         * @property {string|null} [topicUID] CommunityBoardTopicItem topicUID
         * @property {string|null} [infoUID] CommunityBoardTopicItem infoUID
         * @property {string|null} [text] CommunityBoardTopicItem text
         * @property {number|null} [number] CommunityBoardTopicItem number
         * @property {number|Long|null} [date] CommunityBoardTopicItem date
         * @property {number|Long|null} [registerDate] CommunityBoardTopicItem registerDate
         * @property {number|Long|null} [modifyDate] CommunityBoardTopicItem modifyDate
         * @property {Proto.IUploadFile|null} [file] CommunityBoardTopicItem file
         * @property {string|null} [additional] CommunityBoardTopicItem additional
         * @property {boolean|null} [readonly] CommunityBoardTopicItem readonly
         * @property {number|null} [infoType] CommunityBoardTopicItem infoType
         * @property {number|null} [textVersion] CommunityBoardTopicItem textVersion
         */

        /**
         * Constructs a new CommunityBoardTopicItem.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardTopicItem.
         * @implements ICommunityBoardTopicItem
         * @constructor
         * @param {Proto.ICommunityBoardTopicItem=} [p] Properties to set
         */
        function CommunityBoardTopicItem(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardTopicItem topicUID.
         * @member {string} topicUID
         * @memberof Proto.CommunityBoardTopicItem
         * @instance
         */
        CommunityBoardTopicItem.prototype.topicUID = "";

        /**
         * CommunityBoardTopicItem infoUID.
         * @member {string} infoUID
         * @memberof Proto.CommunityBoardTopicItem
         * @instance
         */
        CommunityBoardTopicItem.prototype.infoUID = "";

        /**
         * CommunityBoardTopicItem text.
         * @member {string} text
         * @memberof Proto.CommunityBoardTopicItem
         * @instance
         */
        CommunityBoardTopicItem.prototype.text = "";

        /**
         * CommunityBoardTopicItem number.
         * @member {number} number
         * @memberof Proto.CommunityBoardTopicItem
         * @instance
         */
        CommunityBoardTopicItem.prototype.number = 0;

        /**
         * CommunityBoardTopicItem date.
         * @member {number|Long} date
         * @memberof Proto.CommunityBoardTopicItem
         * @instance
         */
        CommunityBoardTopicItem.prototype.date = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardTopicItem registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommunityBoardTopicItem
         * @instance
         */
        CommunityBoardTopicItem.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardTopicItem modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommunityBoardTopicItem
         * @instance
         */
        CommunityBoardTopicItem.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommunityBoardTopicItem file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.CommunityBoardTopicItem
         * @instance
         */
        CommunityBoardTopicItem.prototype.file = null;

        /**
         * CommunityBoardTopicItem additional.
         * @member {string} additional
         * @memberof Proto.CommunityBoardTopicItem
         * @instance
         */
        CommunityBoardTopicItem.prototype.additional = "";

        /**
         * CommunityBoardTopicItem readonly.
         * @member {boolean} readonly
         * @memberof Proto.CommunityBoardTopicItem
         * @instance
         */
        CommunityBoardTopicItem.prototype.readonly = false;

        /**
         * CommunityBoardTopicItem infoType.
         * @member {number} infoType
         * @memberof Proto.CommunityBoardTopicItem
         * @instance
         */
        CommunityBoardTopicItem.prototype.infoType = 0;

        /**
         * CommunityBoardTopicItem textVersion.
         * @member {number} textVersion
         * @memberof Proto.CommunityBoardTopicItem
         * @instance
         */
        CommunityBoardTopicItem.prototype.textVersion = 0;

        /**
         * Creates a new CommunityBoardTopicItem instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardTopicItem
         * @static
         * @param {Proto.ICommunityBoardTopicItem=} [properties] Properties to set
         * @returns {Proto.CommunityBoardTopicItem} CommunityBoardTopicItem instance
         */
        CommunityBoardTopicItem.create = function create(properties) {
            return new CommunityBoardTopicItem(properties);
        };

        /**
         * Encodes the specified CommunityBoardTopicItem message. Does not implicitly {@link Proto.CommunityBoardTopicItem.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardTopicItem
         * @static
         * @param {Proto.ICommunityBoardTopicItem} m CommunityBoardTopicItem message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardTopicItem.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.topicUID != null && Object.hasOwnProperty.call(m, "topicUID"))
                w.uint32(10).string(m.topicUID);
            if (m.infoUID != null && Object.hasOwnProperty.call(m, "infoUID"))
                w.uint32(18).string(m.infoUID);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.number != null && Object.hasOwnProperty.call(m, "number"))
                w.uint32(32).int32(m.number);
            if (m.date != null && Object.hasOwnProperty.call(m, "date"))
                w.uint32(40).int64(m.date);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(66).fork()).ldelim();
            if (m.additional != null && Object.hasOwnProperty.call(m, "additional"))
                w.uint32(74).string(m.additional);
            if (m.readonly != null && Object.hasOwnProperty.call(m, "readonly"))
                w.uint32(80).bool(m.readonly);
            if (m.infoType != null && Object.hasOwnProperty.call(m, "infoType"))
                w.uint32(88).int32(m.infoType);
            if (m.textVersion != null && Object.hasOwnProperty.call(m, "textVersion"))
                w.uint32(96).int32(m.textVersion);
            return w;
        };

        /**
         * Decodes a CommunityBoardTopicItem message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardTopicItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardTopicItem} CommunityBoardTopicItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardTopicItem.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardTopicItem();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.topicUID = r.string();
                    break;
                case 2:
                    m.infoUID = r.string();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.number = r.int32();
                    break;
                case 5:
                    m.date = r.int64();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 9:
                    m.additional = r.string();
                    break;
                case 10:
                    m.readonly = r.bool();
                    break;
                case 11:
                    m.infoType = r.int32();
                    break;
                case 12:
                    m.textVersion = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardTopicItem message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardTopicItem
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardTopicItem} CommunityBoardTopicItem
         */
        CommunityBoardTopicItem.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardTopicItem)
                return d;
            var m = new $root.Proto.CommunityBoardTopicItem();
            if (d.topicUID != null) {
                m.topicUID = String(d.topicUID);
            }
            if (d.infoUID != null) {
                m.infoUID = String(d.infoUID);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.number != null) {
                m.number = d.number | 0;
            }
            if (d.date != null) {
                if ($util.Long)
                    (m.date = $util.Long.fromValue(d.date)).unsigned = false;
                else if (typeof d.date === "string")
                    m.date = parseInt(d.date, 10);
                else if (typeof d.date === "number")
                    m.date = d.date;
                else if (typeof d.date === "object")
                    m.date = new $util.LongBits(d.date.low >>> 0, d.date.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.CommunityBoardTopicItem.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            if (d.additional != null) {
                m.additional = String(d.additional);
            }
            if (d.readonly != null) {
                m.readonly = Boolean(d.readonly);
            }
            if (d.infoType != null) {
                m.infoType = d.infoType | 0;
            }
            if (d.textVersion != null) {
                m.textVersion = d.textVersion | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardTopicItem message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardTopicItem
         * @static
         * @param {Proto.CommunityBoardTopicItem} m CommunityBoardTopicItem
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardTopicItem.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.topicUID = "";
                d.infoUID = "";
                d.text = "";
                d.number = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.date = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.date = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.file = null;
                d.additional = "";
                d.readonly = false;
                d.infoType = 0;
                d.textVersion = 0;
            }
            if (m.topicUID != null && m.hasOwnProperty("topicUID")) {
                d.topicUID = m.topicUID;
            }
            if (m.infoUID != null && m.hasOwnProperty("infoUID")) {
                d.infoUID = m.infoUID;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.number != null && m.hasOwnProperty("number")) {
                d.number = m.number;
            }
            if (m.date != null && m.hasOwnProperty("date")) {
                if (typeof m.date === "number")
                    d.date = o.longs === String ? String(m.date) : m.date;
                else
                    d.date = o.longs === String ? $util.Long.prototype.toString.call(m.date) : o.longs === Number ? new $util.LongBits(m.date.low >>> 0, m.date.high >>> 0).toNumber() : m.date;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            if (m.additional != null && m.hasOwnProperty("additional")) {
                d.additional = m.additional;
            }
            if (m.readonly != null && m.hasOwnProperty("readonly")) {
                d.readonly = m.readonly;
            }
            if (m.infoType != null && m.hasOwnProperty("infoType")) {
                d.infoType = m.infoType;
            }
            if (m.textVersion != null && m.hasOwnProperty("textVersion")) {
                d.textVersion = m.textVersion;
            }
            return d;
        };

        /**
         * Converts this CommunityBoardTopicItem to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardTopicItem
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardTopicItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardTopicItem;
    })();

    Proto.CommunityBoardTopicItemList = (function() {

        /**
         * Properties of a CommunityBoardTopicItemList.
         * @memberof Proto
         * @interface ICommunityBoardTopicItemList
         * @property {Array.<Proto.ICommunityBoardTopicItem>|null} [value] CommunityBoardTopicItemList value
         */

        /**
         * Constructs a new CommunityBoardTopicItemList.
         * @memberof Proto
         * @classdesc Represents a CommunityBoardTopicItemList.
         * @implements ICommunityBoardTopicItemList
         * @constructor
         * @param {Proto.ICommunityBoardTopicItemList=} [p] Properties to set
         */
        function CommunityBoardTopicItemList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommunityBoardTopicItemList value.
         * @member {Array.<Proto.ICommunityBoardTopicItem>} value
         * @memberof Proto.CommunityBoardTopicItemList
         * @instance
         */
        CommunityBoardTopicItemList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CommunityBoardTopicItemList instance using the specified properties.
         * @function create
         * @memberof Proto.CommunityBoardTopicItemList
         * @static
         * @param {Proto.ICommunityBoardTopicItemList=} [properties] Properties to set
         * @returns {Proto.CommunityBoardTopicItemList} CommunityBoardTopicItemList instance
         */
        CommunityBoardTopicItemList.create = function create(properties) {
            return new CommunityBoardTopicItemList(properties);
        };

        /**
         * Encodes the specified CommunityBoardTopicItemList message. Does not implicitly {@link Proto.CommunityBoardTopicItemList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommunityBoardTopicItemList
         * @static
         * @param {Proto.ICommunityBoardTopicItemList} m CommunityBoardTopicItemList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunityBoardTopicItemList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CommunityBoardTopicItem.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CommunityBoardTopicItemList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommunityBoardTopicItemList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommunityBoardTopicItemList} CommunityBoardTopicItemList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunityBoardTopicItemList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommunityBoardTopicItemList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CommunityBoardTopicItem.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommunityBoardTopicItemList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommunityBoardTopicItemList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommunityBoardTopicItemList} CommunityBoardTopicItemList
         */
        CommunityBoardTopicItemList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommunityBoardTopicItemList)
                return d;
            var m = new $root.Proto.CommunityBoardTopicItemList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CommunityBoardTopicItemList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CommunityBoardTopicItemList.value: object expected");
                    m.value[i] = $root.Proto.CommunityBoardTopicItem.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CommunityBoardTopicItemList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommunityBoardTopicItemList
         * @static
         * @param {Proto.CommunityBoardTopicItemList} m CommunityBoardTopicItemList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommunityBoardTopicItemList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CommunityBoardTopicItem.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CommunityBoardTopicItemList to JSON.
         * @function toJSON
         * @memberof Proto.CommunityBoardTopicItemList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommunityBoardTopicItemList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommunityBoardTopicItemList;
    })();

    Proto.QuickWatchIssue = (function() {

        /**
         * Properties of a QuickWatchIssue.
         * @memberof Proto
         * @interface IQuickWatchIssue
         * @property {string|null} [uid] QuickWatchIssue uid
         * @property {string|null} [name] QuickWatchIssue name
         * @property {number|null} [dataType] QuickWatchIssue dataType
         * @property {string|null} [code] QuickWatchIssue code
         * @property {string|null} [dataJson] QuickWatchIssue dataJson
         * @property {number|null} [holdingCount] QuickWatchIssue holdingCount
         * @property {number|null} [averagePurchasePrice] QuickWatchIssue averagePurchasePrice
         * @property {number|Long|null} [registerDate] QuickWatchIssue registerDate
         * @property {number|Long|null} [modifyDate] QuickWatchIssue modifyDate
         */

        /**
         * Constructs a new QuickWatchIssue.
         * @memberof Proto
         * @classdesc Represents a QuickWatchIssue.
         * @implements IQuickWatchIssue
         * @constructor
         * @param {Proto.IQuickWatchIssue=} [p] Properties to set
         */
        function QuickWatchIssue(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * QuickWatchIssue uid.
         * @member {string} uid
         * @memberof Proto.QuickWatchIssue
         * @instance
         */
        QuickWatchIssue.prototype.uid = "";

        /**
         * QuickWatchIssue name.
         * @member {string} name
         * @memberof Proto.QuickWatchIssue
         * @instance
         */
        QuickWatchIssue.prototype.name = "";

        /**
         * QuickWatchIssue dataType.
         * @member {number} dataType
         * @memberof Proto.QuickWatchIssue
         * @instance
         */
        QuickWatchIssue.prototype.dataType = 0;

        /**
         * QuickWatchIssue code.
         * @member {string} code
         * @memberof Proto.QuickWatchIssue
         * @instance
         */
        QuickWatchIssue.prototype.code = "";

        /**
         * QuickWatchIssue dataJson.
         * @member {string} dataJson
         * @memberof Proto.QuickWatchIssue
         * @instance
         */
        QuickWatchIssue.prototype.dataJson = "";

        /**
         * QuickWatchIssue holdingCount.
         * @member {number} holdingCount
         * @memberof Proto.QuickWatchIssue
         * @instance
         */
        QuickWatchIssue.prototype.holdingCount = 0;

        /**
         * QuickWatchIssue averagePurchasePrice.
         * @member {number} averagePurchasePrice
         * @memberof Proto.QuickWatchIssue
         * @instance
         */
        QuickWatchIssue.prototype.averagePurchasePrice = 0;

        /**
         * QuickWatchIssue registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.QuickWatchIssue
         * @instance
         */
        QuickWatchIssue.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * QuickWatchIssue modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.QuickWatchIssue
         * @instance
         */
        QuickWatchIssue.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new QuickWatchIssue instance using the specified properties.
         * @function create
         * @memberof Proto.QuickWatchIssue
         * @static
         * @param {Proto.IQuickWatchIssue=} [properties] Properties to set
         * @returns {Proto.QuickWatchIssue} QuickWatchIssue instance
         */
        QuickWatchIssue.create = function create(properties) {
            return new QuickWatchIssue(properties);
        };

        /**
         * Encodes the specified QuickWatchIssue message. Does not implicitly {@link Proto.QuickWatchIssue.verify|verify} messages.
         * @function encode
         * @memberof Proto.QuickWatchIssue
         * @static
         * @param {Proto.IQuickWatchIssue} m QuickWatchIssue message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuickWatchIssue.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.dataType != null && Object.hasOwnProperty.call(m, "dataType"))
                w.uint32(24).int32(m.dataType);
            if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                w.uint32(34).string(m.code);
            if (m.dataJson != null && Object.hasOwnProperty.call(m, "dataJson"))
                w.uint32(42).string(m.dataJson);
            if (m.holdingCount != null && Object.hasOwnProperty.call(m, "holdingCount"))
                w.uint32(49).double(m.holdingCount);
            if (m.averagePurchasePrice != null && Object.hasOwnProperty.call(m, "averagePurchasePrice"))
                w.uint32(57).double(m.averagePurchasePrice);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a QuickWatchIssue message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.QuickWatchIssue
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.QuickWatchIssue} QuickWatchIssue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuickWatchIssue.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.QuickWatchIssue();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.name = r.string();
                    break;
                case 3:
                    m.dataType = r.int32();
                    break;
                case 4:
                    m.code = r.string();
                    break;
                case 5:
                    m.dataJson = r.string();
                    break;
                case 6:
                    m.holdingCount = r.double();
                    break;
                case 7:
                    m.averagePurchasePrice = r.double();
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a QuickWatchIssue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.QuickWatchIssue
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.QuickWatchIssue} QuickWatchIssue
         */
        QuickWatchIssue.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.QuickWatchIssue)
                return d;
            var m = new $root.Proto.QuickWatchIssue();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.dataType != null) {
                m.dataType = d.dataType | 0;
            }
            if (d.code != null) {
                m.code = String(d.code);
            }
            if (d.dataJson != null) {
                m.dataJson = String(d.dataJson);
            }
            if (d.holdingCount != null) {
                m.holdingCount = Number(d.holdingCount);
            }
            if (d.averagePurchasePrice != null) {
                m.averagePurchasePrice = Number(d.averagePurchasePrice);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a QuickWatchIssue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.QuickWatchIssue
         * @static
         * @param {Proto.QuickWatchIssue} m QuickWatchIssue
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QuickWatchIssue.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.name = "";
                d.dataType = 0;
                d.code = "";
                d.dataJson = "";
                d.holdingCount = 0;
                d.averagePurchasePrice = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.dataType != null && m.hasOwnProperty("dataType")) {
                d.dataType = m.dataType;
            }
            if (m.code != null && m.hasOwnProperty("code")) {
                d.code = m.code;
            }
            if (m.dataJson != null && m.hasOwnProperty("dataJson")) {
                d.dataJson = m.dataJson;
            }
            if (m.holdingCount != null && m.hasOwnProperty("holdingCount")) {
                d.holdingCount = o.json && !isFinite(m.holdingCount) ? String(m.holdingCount) : m.holdingCount;
            }
            if (m.averagePurchasePrice != null && m.hasOwnProperty("averagePurchasePrice")) {
                d.averagePurchasePrice = o.json && !isFinite(m.averagePurchasePrice) ? String(m.averagePurchasePrice) : m.averagePurchasePrice;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this QuickWatchIssue to JSON.
         * @function toJSON
         * @memberof Proto.QuickWatchIssue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QuickWatchIssue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return QuickWatchIssue;
    })();

    Proto.QuickWatchIssueList = (function() {

        /**
         * Properties of a QuickWatchIssueList.
         * @memberof Proto
         * @interface IQuickWatchIssueList
         * @property {Array.<Proto.IQuickWatchIssue>|null} [value] QuickWatchIssueList value
         */

        /**
         * Constructs a new QuickWatchIssueList.
         * @memberof Proto
         * @classdesc Represents a QuickWatchIssueList.
         * @implements IQuickWatchIssueList
         * @constructor
         * @param {Proto.IQuickWatchIssueList=} [p] Properties to set
         */
        function QuickWatchIssueList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * QuickWatchIssueList value.
         * @member {Array.<Proto.IQuickWatchIssue>} value
         * @memberof Proto.QuickWatchIssueList
         * @instance
         */
        QuickWatchIssueList.prototype.value = $util.emptyArray;

        /**
         * Creates a new QuickWatchIssueList instance using the specified properties.
         * @function create
         * @memberof Proto.QuickWatchIssueList
         * @static
         * @param {Proto.IQuickWatchIssueList=} [properties] Properties to set
         * @returns {Proto.QuickWatchIssueList} QuickWatchIssueList instance
         */
        QuickWatchIssueList.create = function create(properties) {
            return new QuickWatchIssueList(properties);
        };

        /**
         * Encodes the specified QuickWatchIssueList message. Does not implicitly {@link Proto.QuickWatchIssueList.verify|verify} messages.
         * @function encode
         * @memberof Proto.QuickWatchIssueList
         * @static
         * @param {Proto.IQuickWatchIssueList} m QuickWatchIssueList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuickWatchIssueList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.QuickWatchIssue.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a QuickWatchIssueList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.QuickWatchIssueList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.QuickWatchIssueList} QuickWatchIssueList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuickWatchIssueList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.QuickWatchIssueList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.QuickWatchIssue.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a QuickWatchIssueList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.QuickWatchIssueList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.QuickWatchIssueList} QuickWatchIssueList
         */
        QuickWatchIssueList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.QuickWatchIssueList)
                return d;
            var m = new $root.Proto.QuickWatchIssueList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.QuickWatchIssueList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.QuickWatchIssueList.value: object expected");
                    m.value[i] = $root.Proto.QuickWatchIssue.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a QuickWatchIssueList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.QuickWatchIssueList
         * @static
         * @param {Proto.QuickWatchIssueList} m QuickWatchIssueList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QuickWatchIssueList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.QuickWatchIssue.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this QuickWatchIssueList to JSON.
         * @function toJSON
         * @memberof Proto.QuickWatchIssueList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QuickWatchIssueList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return QuickWatchIssueList;
    })();

    Proto.CryptoWallet = (function() {

        /**
         * Properties of a CryptoWallet.
         * @memberof Proto
         * @interface ICryptoWallet
         * @property {string|null} [uid] CryptoWallet uid
         * @property {number|null} [walletType] CryptoWallet walletType
         * @property {string|null} [address] CryptoWallet address
         * @property {number|Long|null} [registerDate] CryptoWallet registerDate
         * @property {number|Long|null} [modifyDate] CryptoWallet modifyDate
         * @property {string|null} [signMessage] CryptoWallet signMessage
         * @property {string|null} [signHash] CryptoWallet signHash
         */

        /**
         * Constructs a new CryptoWallet.
         * @memberof Proto
         * @classdesc Represents a CryptoWallet.
         * @implements ICryptoWallet
         * @constructor
         * @param {Proto.ICryptoWallet=} [p] Properties to set
         */
        function CryptoWallet(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CryptoWallet uid.
         * @member {string} uid
         * @memberof Proto.CryptoWallet
         * @instance
         */
        CryptoWallet.prototype.uid = "";

        /**
         * CryptoWallet walletType.
         * @member {number} walletType
         * @memberof Proto.CryptoWallet
         * @instance
         */
        CryptoWallet.prototype.walletType = 0;

        /**
         * CryptoWallet address.
         * @member {string} address
         * @memberof Proto.CryptoWallet
         * @instance
         */
        CryptoWallet.prototype.address = "";

        /**
         * CryptoWallet registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CryptoWallet
         * @instance
         */
        CryptoWallet.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CryptoWallet modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CryptoWallet
         * @instance
         */
        CryptoWallet.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CryptoWallet signMessage.
         * @member {string} signMessage
         * @memberof Proto.CryptoWallet
         * @instance
         */
        CryptoWallet.prototype.signMessage = "";

        /**
         * CryptoWallet signHash.
         * @member {string} signHash
         * @memberof Proto.CryptoWallet
         * @instance
         */
        CryptoWallet.prototype.signHash = "";

        /**
         * Creates a new CryptoWallet instance using the specified properties.
         * @function create
         * @memberof Proto.CryptoWallet
         * @static
         * @param {Proto.ICryptoWallet=} [properties] Properties to set
         * @returns {Proto.CryptoWallet} CryptoWallet instance
         */
        CryptoWallet.create = function create(properties) {
            return new CryptoWallet(properties);
        };

        /**
         * Encodes the specified CryptoWallet message. Does not implicitly {@link Proto.CryptoWallet.verify|verify} messages.
         * @function encode
         * @memberof Proto.CryptoWallet
         * @static
         * @param {Proto.ICryptoWallet} m CryptoWallet message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CryptoWallet.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.walletType != null && Object.hasOwnProperty.call(m, "walletType"))
                w.uint32(16).int32(m.walletType);
            if (m.address != null && Object.hasOwnProperty.call(m, "address"))
                w.uint32(26).string(m.address);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            if (m.signMessage != null && Object.hasOwnProperty.call(m, "signMessage"))
                w.uint32(50).string(m.signMessage);
            if (m.signHash != null && Object.hasOwnProperty.call(m, "signHash"))
                w.uint32(58).string(m.signHash);
            return w;
        };

        /**
         * Decodes a CryptoWallet message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CryptoWallet
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CryptoWallet} CryptoWallet
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CryptoWallet.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CryptoWallet();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.walletType = r.int32();
                    break;
                case 3:
                    m.address = r.string();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                case 6:
                    m.signMessage = r.string();
                    break;
                case 7:
                    m.signHash = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CryptoWallet message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CryptoWallet
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CryptoWallet} CryptoWallet
         */
        CryptoWallet.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CryptoWallet)
                return d;
            var m = new $root.Proto.CryptoWallet();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.walletType != null) {
                m.walletType = d.walletType | 0;
            }
            if (d.address != null) {
                m.address = String(d.address);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.signMessage != null) {
                m.signMessage = String(d.signMessage);
            }
            if (d.signHash != null) {
                m.signHash = String(d.signHash);
            }
            return m;
        };

        /**
         * Creates a plain object from a CryptoWallet message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CryptoWallet
         * @static
         * @param {Proto.CryptoWallet} m CryptoWallet
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CryptoWallet.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.walletType = 0;
                d.address = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.signMessage = "";
                d.signHash = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.walletType != null && m.hasOwnProperty("walletType")) {
                d.walletType = m.walletType;
            }
            if (m.address != null && m.hasOwnProperty("address")) {
                d.address = m.address;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.signMessage != null && m.hasOwnProperty("signMessage")) {
                d.signMessage = m.signMessage;
            }
            if (m.signHash != null && m.hasOwnProperty("signHash")) {
                d.signHash = m.signHash;
            }
            return d;
        };

        /**
         * Converts this CryptoWallet to JSON.
         * @function toJSON
         * @memberof Proto.CryptoWallet
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CryptoWallet.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CryptoWallet;
    })();

    Proto.CryptoWalletList = (function() {

        /**
         * Properties of a CryptoWalletList.
         * @memberof Proto
         * @interface ICryptoWalletList
         * @property {Array.<Proto.ICryptoWallet>|null} [value] CryptoWalletList value
         */

        /**
         * Constructs a new CryptoWalletList.
         * @memberof Proto
         * @classdesc Represents a CryptoWalletList.
         * @implements ICryptoWalletList
         * @constructor
         * @param {Proto.ICryptoWalletList=} [p] Properties to set
         */
        function CryptoWalletList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CryptoWalletList value.
         * @member {Array.<Proto.ICryptoWallet>} value
         * @memberof Proto.CryptoWalletList
         * @instance
         */
        CryptoWalletList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CryptoWalletList instance using the specified properties.
         * @function create
         * @memberof Proto.CryptoWalletList
         * @static
         * @param {Proto.ICryptoWalletList=} [properties] Properties to set
         * @returns {Proto.CryptoWalletList} CryptoWalletList instance
         */
        CryptoWalletList.create = function create(properties) {
            return new CryptoWalletList(properties);
        };

        /**
         * Encodes the specified CryptoWalletList message. Does not implicitly {@link Proto.CryptoWalletList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CryptoWalletList
         * @static
         * @param {Proto.ICryptoWalletList} m CryptoWalletList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CryptoWalletList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CryptoWallet.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CryptoWalletList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CryptoWalletList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CryptoWalletList} CryptoWalletList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CryptoWalletList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CryptoWalletList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CryptoWallet.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CryptoWalletList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CryptoWalletList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CryptoWalletList} CryptoWalletList
         */
        CryptoWalletList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CryptoWalletList)
                return d;
            var m = new $root.Proto.CryptoWalletList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CryptoWalletList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CryptoWalletList.value: object expected");
                    m.value[i] = $root.Proto.CryptoWallet.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CryptoWalletList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CryptoWalletList
         * @static
         * @param {Proto.CryptoWalletList} m CryptoWalletList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CryptoWalletList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CryptoWallet.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CryptoWalletList to JSON.
         * @function toJSON
         * @memberof Proto.CryptoWalletList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CryptoWalletList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CryptoWalletList;
    })();

    Proto.CryptoContract = (function() {

        /**
         * Properties of a CryptoContract.
         * @memberof Proto
         * @interface ICryptoContract
         * @property {string|null} [uid] CryptoContract uid
         * @property {string|null} [address] CryptoContract address
         * @property {number|null} [networkType] CryptoContract networkType
         * @property {number|null} [type] CryptoContract type
         * @property {number|null} [version] CryptoContract version
         * @property {string|null} [walletUID] CryptoContract walletUID
         * @property {number|Long|null} [registerDate] CryptoContract registerDate
         * @property {number|Long|null} [modifyDate] CryptoContract modifyDate
         * @property {string|null} [proxyAddress] CryptoContract proxyAddress
         * @property {string|null} [walletAddress] CryptoContract walletAddress
         */

        /**
         * Constructs a new CryptoContract.
         * @memberof Proto
         * @classdesc Represents a CryptoContract.
         * @implements ICryptoContract
         * @constructor
         * @param {Proto.ICryptoContract=} [p] Properties to set
         */
        function CryptoContract(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CryptoContract uid.
         * @member {string} uid
         * @memberof Proto.CryptoContract
         * @instance
         */
        CryptoContract.prototype.uid = "";

        /**
         * CryptoContract address.
         * @member {string} address
         * @memberof Proto.CryptoContract
         * @instance
         */
        CryptoContract.prototype.address = "";

        /**
         * CryptoContract networkType.
         * @member {number} networkType
         * @memberof Proto.CryptoContract
         * @instance
         */
        CryptoContract.prototype.networkType = 0;

        /**
         * CryptoContract type.
         * @member {number} type
         * @memberof Proto.CryptoContract
         * @instance
         */
        CryptoContract.prototype.type = 0;

        /**
         * CryptoContract version.
         * @member {number} version
         * @memberof Proto.CryptoContract
         * @instance
         */
        CryptoContract.prototype.version = 0;

        /**
         * CryptoContract walletUID.
         * @member {string} walletUID
         * @memberof Proto.CryptoContract
         * @instance
         */
        CryptoContract.prototype.walletUID = "";

        /**
         * CryptoContract registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CryptoContract
         * @instance
         */
        CryptoContract.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CryptoContract modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CryptoContract
         * @instance
         */
        CryptoContract.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CryptoContract proxyAddress.
         * @member {string} proxyAddress
         * @memberof Proto.CryptoContract
         * @instance
         */
        CryptoContract.prototype.proxyAddress = "";

        /**
         * CryptoContract walletAddress.
         * @member {string} walletAddress
         * @memberof Proto.CryptoContract
         * @instance
         */
        CryptoContract.prototype.walletAddress = "";

        /**
         * Creates a new CryptoContract instance using the specified properties.
         * @function create
         * @memberof Proto.CryptoContract
         * @static
         * @param {Proto.ICryptoContract=} [properties] Properties to set
         * @returns {Proto.CryptoContract} CryptoContract instance
         */
        CryptoContract.create = function create(properties) {
            return new CryptoContract(properties);
        };

        /**
         * Encodes the specified CryptoContract message. Does not implicitly {@link Proto.CryptoContract.verify|verify} messages.
         * @function encode
         * @memberof Proto.CryptoContract
         * @static
         * @param {Proto.ICryptoContract} m CryptoContract message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CryptoContract.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.address != null && Object.hasOwnProperty.call(m, "address"))
                w.uint32(18).string(m.address);
            if (m.networkType != null && Object.hasOwnProperty.call(m, "networkType"))
                w.uint32(24).int32(m.networkType);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(32).int32(m.type);
            if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                w.uint32(40).int32(m.version);
            if (m.walletUID != null && Object.hasOwnProperty.call(m, "walletUID"))
                w.uint32(50).string(m.walletUID);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(56).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(64).int64(m.modifyDate);
            if (m.proxyAddress != null && Object.hasOwnProperty.call(m, "proxyAddress"))
                w.uint32(74).string(m.proxyAddress);
            if (m.walletAddress != null && Object.hasOwnProperty.call(m, "walletAddress"))
                w.uint32(90).string(m.walletAddress);
            return w;
        };

        /**
         * Decodes a CryptoContract message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CryptoContract
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CryptoContract} CryptoContract
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CryptoContract.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CryptoContract();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.address = r.string();
                    break;
                case 3:
                    m.networkType = r.int32();
                    break;
                case 4:
                    m.type = r.int32();
                    break;
                case 5:
                    m.version = r.int32();
                    break;
                case 6:
                    m.walletUID = r.string();
                    break;
                case 7:
                    m.registerDate = r.int64();
                    break;
                case 8:
                    m.modifyDate = r.int64();
                    break;
                case 9:
                    m.proxyAddress = r.string();
                    break;
                case 11:
                    m.walletAddress = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CryptoContract message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CryptoContract
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CryptoContract} CryptoContract
         */
        CryptoContract.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CryptoContract)
                return d;
            var m = new $root.Proto.CryptoContract();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.address != null) {
                m.address = String(d.address);
            }
            if (d.networkType != null) {
                m.networkType = d.networkType | 0;
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.version != null) {
                m.version = d.version | 0;
            }
            if (d.walletUID != null) {
                m.walletUID = String(d.walletUID);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.proxyAddress != null) {
                m.proxyAddress = String(d.proxyAddress);
            }
            if (d.walletAddress != null) {
                m.walletAddress = String(d.walletAddress);
            }
            return m;
        };

        /**
         * Creates a plain object from a CryptoContract message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CryptoContract
         * @static
         * @param {Proto.CryptoContract} m CryptoContract
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CryptoContract.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.address = "";
                d.networkType = 0;
                d.type = 0;
                d.version = 0;
                d.walletUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.proxyAddress = "";
                d.walletAddress = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.address != null && m.hasOwnProperty("address")) {
                d.address = m.address;
            }
            if (m.networkType != null && m.hasOwnProperty("networkType")) {
                d.networkType = m.networkType;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.version != null && m.hasOwnProperty("version")) {
                d.version = m.version;
            }
            if (m.walletUID != null && m.hasOwnProperty("walletUID")) {
                d.walletUID = m.walletUID;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.proxyAddress != null && m.hasOwnProperty("proxyAddress")) {
                d.proxyAddress = m.proxyAddress;
            }
            if (m.walletAddress != null && m.hasOwnProperty("walletAddress")) {
                d.walletAddress = m.walletAddress;
            }
            return d;
        };

        /**
         * Converts this CryptoContract to JSON.
         * @function toJSON
         * @memberof Proto.CryptoContract
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CryptoContract.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CryptoContract;
    })();

    Proto.CryptoNFT = (function() {

        /**
         * Properties of a CryptoNFT.
         * @memberof Proto
         * @interface ICryptoNFT
         * @property {string|null} [uid] CryptoNFT uid
         * @property {string|null} [address] CryptoNFT address
         * @property {number|null} [networkType] CryptoNFT networkType
         * @property {number|null} [type] CryptoNFT type
         * @property {string|null} [tokenId] CryptoNFT tokenId
         * @property {string|null} [walletAddress] CryptoNFT walletAddress
         * @property {string|null} [walletUID] CryptoNFT walletUID
         * @property {number|null} [bindType] CryptoNFT bindType
         * @property {string|null} [metadataUri] CryptoNFT metadataUri
         * @property {string|null} [contractUID] CryptoNFT contractUID
         * @property {number|Long|null} [registerDate] CryptoNFT registerDate
         * @property {number|Long|null} [modifyDate] CryptoNFT modifyDate
         * @property {string|null} [productUID] CryptoNFT productUID
         * @property {Proto.ICryptoContract|null} [contract] CryptoNFT contract
         * @property {Proto.IUser|null} [toUser] CryptoNFT toUser
         * @property {string|null} [variationName] CryptoNFT variationName
         * @property {string|null} [productName] CryptoNFT productName
         * @property {Proto.IContentCommerceVariationOrder|null} [transferringOrder] CryptoNFT transferringOrder
         */

        /**
         * Constructs a new CryptoNFT.
         * @memberof Proto
         * @classdesc Represents a CryptoNFT.
         * @implements ICryptoNFT
         * @constructor
         * @param {Proto.ICryptoNFT=} [p] Properties to set
         */
        function CryptoNFT(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CryptoNFT uid.
         * @member {string} uid
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.uid = "";

        /**
         * CryptoNFT address.
         * @member {string} address
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.address = "";

        /**
         * CryptoNFT networkType.
         * @member {number} networkType
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.networkType = 0;

        /**
         * CryptoNFT type.
         * @member {number} type
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.type = 0;

        /**
         * CryptoNFT tokenId.
         * @member {string} tokenId
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.tokenId = "";

        /**
         * CryptoNFT walletAddress.
         * @member {string} walletAddress
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.walletAddress = "";

        /**
         * CryptoNFT walletUID.
         * @member {string} walletUID
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.walletUID = "";

        /**
         * CryptoNFT bindType.
         * @member {number} bindType
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.bindType = 0;

        /**
         * CryptoNFT metadataUri.
         * @member {string} metadataUri
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.metadataUri = "";

        /**
         * CryptoNFT contractUID.
         * @member {string} contractUID
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.contractUID = "";

        /**
         * CryptoNFT registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CryptoNFT modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CryptoNFT productUID.
         * @member {string} productUID
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.productUID = "";

        /**
         * CryptoNFT contract.
         * @member {Proto.ICryptoContract|null|undefined} contract
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.contract = null;

        /**
         * CryptoNFT toUser.
         * @member {Proto.IUser|null|undefined} toUser
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.toUser = null;

        /**
         * CryptoNFT variationName.
         * @member {string} variationName
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.variationName = "";

        /**
         * CryptoNFT productName.
         * @member {string} productName
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.productName = "";

        /**
         * CryptoNFT transferringOrder.
         * @member {Proto.IContentCommerceVariationOrder|null|undefined} transferringOrder
         * @memberof Proto.CryptoNFT
         * @instance
         */
        CryptoNFT.prototype.transferringOrder = null;

        /**
         * Creates a new CryptoNFT instance using the specified properties.
         * @function create
         * @memberof Proto.CryptoNFT
         * @static
         * @param {Proto.ICryptoNFT=} [properties] Properties to set
         * @returns {Proto.CryptoNFT} CryptoNFT instance
         */
        CryptoNFT.create = function create(properties) {
            return new CryptoNFT(properties);
        };

        /**
         * Encodes the specified CryptoNFT message. Does not implicitly {@link Proto.CryptoNFT.verify|verify} messages.
         * @function encode
         * @memberof Proto.CryptoNFT
         * @static
         * @param {Proto.ICryptoNFT} m CryptoNFT message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CryptoNFT.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.address != null && Object.hasOwnProperty.call(m, "address"))
                w.uint32(18).string(m.address);
            if (m.networkType != null && Object.hasOwnProperty.call(m, "networkType"))
                w.uint32(24).int32(m.networkType);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(32).int32(m.type);
            if (m.tokenId != null && Object.hasOwnProperty.call(m, "tokenId"))
                w.uint32(42).string(m.tokenId);
            if (m.walletAddress != null && Object.hasOwnProperty.call(m, "walletAddress"))
                w.uint32(50).string(m.walletAddress);
            if (m.walletUID != null && Object.hasOwnProperty.call(m, "walletUID"))
                w.uint32(58).string(m.walletUID);
            if (m.bindType != null && Object.hasOwnProperty.call(m, "bindType"))
                w.uint32(64).int32(m.bindType);
            if (m.metadataUri != null && Object.hasOwnProperty.call(m, "metadataUri"))
                w.uint32(74).string(m.metadataUri);
            if (m.contractUID != null && Object.hasOwnProperty.call(m, "contractUID"))
                w.uint32(82).string(m.contractUID);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(88).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(96).int64(m.modifyDate);
            if (m.productUID != null && Object.hasOwnProperty.call(m, "productUID"))
                w.uint32(106).string(m.productUID);
            if (m.contract != null && Object.hasOwnProperty.call(m, "contract"))
                $root.Proto.CryptoContract.encode(m.contract, w.uint32(114).fork()).ldelim();
            if (m.toUser != null && Object.hasOwnProperty.call(m, "toUser"))
                $root.Proto.User.encode(m.toUser, w.uint32(122).fork()).ldelim();
            if (m.variationName != null && Object.hasOwnProperty.call(m, "variationName"))
                w.uint32(130).string(m.variationName);
            if (m.productName != null && Object.hasOwnProperty.call(m, "productName"))
                w.uint32(138).string(m.productName);
            if (m.transferringOrder != null && Object.hasOwnProperty.call(m, "transferringOrder"))
                $root.Proto.ContentCommerceVariationOrder.encode(m.transferringOrder, w.uint32(146).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a CryptoNFT message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CryptoNFT
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CryptoNFT} CryptoNFT
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CryptoNFT.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CryptoNFT();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.address = r.string();
                    break;
                case 3:
                    m.networkType = r.int32();
                    break;
                case 4:
                    m.type = r.int32();
                    break;
                case 5:
                    m.tokenId = r.string();
                    break;
                case 6:
                    m.walletAddress = r.string();
                    break;
                case 7:
                    m.walletUID = r.string();
                    break;
                case 8:
                    m.bindType = r.int32();
                    break;
                case 9:
                    m.metadataUri = r.string();
                    break;
                case 10:
                    m.contractUID = r.string();
                    break;
                case 11:
                    m.registerDate = r.int64();
                    break;
                case 12:
                    m.modifyDate = r.int64();
                    break;
                case 13:
                    m.productUID = r.string();
                    break;
                case 14:
                    m.contract = $root.Proto.CryptoContract.decode(r, r.uint32());
                    break;
                case 15:
                    m.toUser = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 16:
                    m.variationName = r.string();
                    break;
                case 17:
                    m.productName = r.string();
                    break;
                case 18:
                    m.transferringOrder = $root.Proto.ContentCommerceVariationOrder.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CryptoNFT message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CryptoNFT
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CryptoNFT} CryptoNFT
         */
        CryptoNFT.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CryptoNFT)
                return d;
            var m = new $root.Proto.CryptoNFT();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.address != null) {
                m.address = String(d.address);
            }
            if (d.networkType != null) {
                m.networkType = d.networkType | 0;
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.tokenId != null) {
                m.tokenId = String(d.tokenId);
            }
            if (d.walletAddress != null) {
                m.walletAddress = String(d.walletAddress);
            }
            if (d.walletUID != null) {
                m.walletUID = String(d.walletUID);
            }
            if (d.bindType != null) {
                m.bindType = d.bindType | 0;
            }
            if (d.metadataUri != null) {
                m.metadataUri = String(d.metadataUri);
            }
            if (d.contractUID != null) {
                m.contractUID = String(d.contractUID);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.productUID != null) {
                m.productUID = String(d.productUID);
            }
            if (d.contract != null) {
                if (typeof d.contract !== "object")
                    throw TypeError(".Proto.CryptoNFT.contract: object expected");
                m.contract = $root.Proto.CryptoContract.fromObject(d.contract);
            }
            if (d.toUser != null) {
                if (typeof d.toUser !== "object")
                    throw TypeError(".Proto.CryptoNFT.toUser: object expected");
                m.toUser = $root.Proto.User.fromObject(d.toUser);
            }
            if (d.variationName != null) {
                m.variationName = String(d.variationName);
            }
            if (d.productName != null) {
                m.productName = String(d.productName);
            }
            if (d.transferringOrder != null) {
                if (typeof d.transferringOrder !== "object")
                    throw TypeError(".Proto.CryptoNFT.transferringOrder: object expected");
                m.transferringOrder = $root.Proto.ContentCommerceVariationOrder.fromObject(d.transferringOrder);
            }
            return m;
        };

        /**
         * Creates a plain object from a CryptoNFT message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CryptoNFT
         * @static
         * @param {Proto.CryptoNFT} m CryptoNFT
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CryptoNFT.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.address = "";
                d.networkType = 0;
                d.type = 0;
                d.tokenId = "";
                d.walletAddress = "";
                d.walletUID = "";
                d.bindType = 0;
                d.metadataUri = "";
                d.contractUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.productUID = "";
                d.contract = null;
                d.toUser = null;
                d.variationName = "";
                d.productName = "";
                d.transferringOrder = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.address != null && m.hasOwnProperty("address")) {
                d.address = m.address;
            }
            if (m.networkType != null && m.hasOwnProperty("networkType")) {
                d.networkType = m.networkType;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.tokenId != null && m.hasOwnProperty("tokenId")) {
                d.tokenId = m.tokenId;
            }
            if (m.walletAddress != null && m.hasOwnProperty("walletAddress")) {
                d.walletAddress = m.walletAddress;
            }
            if (m.walletUID != null && m.hasOwnProperty("walletUID")) {
                d.walletUID = m.walletUID;
            }
            if (m.bindType != null && m.hasOwnProperty("bindType")) {
                d.bindType = m.bindType;
            }
            if (m.metadataUri != null && m.hasOwnProperty("metadataUri")) {
                d.metadataUri = m.metadataUri;
            }
            if (m.contractUID != null && m.hasOwnProperty("contractUID")) {
                d.contractUID = m.contractUID;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.productUID != null && m.hasOwnProperty("productUID")) {
                d.productUID = m.productUID;
            }
            if (m.contract != null && m.hasOwnProperty("contract")) {
                d.contract = $root.Proto.CryptoContract.toObject(m.contract, o);
            }
            if (m.toUser != null && m.hasOwnProperty("toUser")) {
                d.toUser = $root.Proto.User.toObject(m.toUser, o);
            }
            if (m.variationName != null && m.hasOwnProperty("variationName")) {
                d.variationName = m.variationName;
            }
            if (m.productName != null && m.hasOwnProperty("productName")) {
                d.productName = m.productName;
            }
            if (m.transferringOrder != null && m.hasOwnProperty("transferringOrder")) {
                d.transferringOrder = $root.Proto.ContentCommerceVariationOrder.toObject(m.transferringOrder, o);
            }
            return d;
        };

        /**
         * Converts this CryptoNFT to JSON.
         * @function toJSON
         * @memberof Proto.CryptoNFT
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CryptoNFT.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CryptoNFT;
    })();

    Proto.CryptoNFTList = (function() {

        /**
         * Properties of a CryptoNFTList.
         * @memberof Proto
         * @interface ICryptoNFTList
         * @property {Array.<Proto.ICryptoNFT>|null} [value] CryptoNFTList value
         */

        /**
         * Constructs a new CryptoNFTList.
         * @memberof Proto
         * @classdesc Represents a CryptoNFTList.
         * @implements ICryptoNFTList
         * @constructor
         * @param {Proto.ICryptoNFTList=} [p] Properties to set
         */
        function CryptoNFTList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CryptoNFTList value.
         * @member {Array.<Proto.ICryptoNFT>} value
         * @memberof Proto.CryptoNFTList
         * @instance
         */
        CryptoNFTList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CryptoNFTList instance using the specified properties.
         * @function create
         * @memberof Proto.CryptoNFTList
         * @static
         * @param {Proto.ICryptoNFTList=} [properties] Properties to set
         * @returns {Proto.CryptoNFTList} CryptoNFTList instance
         */
        CryptoNFTList.create = function create(properties) {
            return new CryptoNFTList(properties);
        };

        /**
         * Encodes the specified CryptoNFTList message. Does not implicitly {@link Proto.CryptoNFTList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CryptoNFTList
         * @static
         * @param {Proto.ICryptoNFTList} m CryptoNFTList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CryptoNFTList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CryptoNFT.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CryptoNFTList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CryptoNFTList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CryptoNFTList} CryptoNFTList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CryptoNFTList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CryptoNFTList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CryptoNFT.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CryptoNFTList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CryptoNFTList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CryptoNFTList} CryptoNFTList
         */
        CryptoNFTList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CryptoNFTList)
                return d;
            var m = new $root.Proto.CryptoNFTList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CryptoNFTList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CryptoNFTList.value: object expected");
                    m.value[i] = $root.Proto.CryptoNFT.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CryptoNFTList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CryptoNFTList
         * @static
         * @param {Proto.CryptoNFTList} m CryptoNFTList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CryptoNFTList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CryptoNFT.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CryptoNFTList to JSON.
         * @function toJSON
         * @memberof Proto.CryptoNFTList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CryptoNFTList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CryptoNFTList;
    })();

    Proto.CryptoContractHistory = (function() {

        /**
         * Properties of a CryptoContractHistory.
         * @memberof Proto
         * @interface ICryptoContractHistory
         * @property {string|null} [uid] CryptoContractHistory uid
         * @property {string|null} [contractUID] CryptoContractHistory contractUID
         * @property {number|null} [type] CryptoContractHistory type
         * @property {string|null} [nftUID] CryptoContractHistory nftUID
         * @property {string|null} [tokenId] CryptoContractHistory tokenId
         * @property {string|null} [fromWallet] CryptoContractHistory fromWallet
         * @property {string|null} [toWallet] CryptoContractHistory toWallet
         * @property {Proto.IUser|null} [fromUser] CryptoContractHistory fromUser
         * @property {Proto.IUser|null} [toUser] CryptoContractHistory toUser
         * @property {Proto.ICryptoWallet|null} [from] CryptoContractHistory from
         * @property {Proto.ICryptoWallet|null} [to] CryptoContractHistory to
         * @property {Proto.ICryptoContract|null} [contract] CryptoContractHistory contract
         * @property {number|Long|null} [registerDate] CryptoContractHistory registerDate
         * @property {number|Long|null} [modifyDate] CryptoContractHistory modifyDate
         * @property {string|null} [hash] CryptoContractHistory hash
         * @property {number|null} [status] CryptoContractHistory status
         */

        /**
         * Constructs a new CryptoContractHistory.
         * @memberof Proto
         * @classdesc Represents a CryptoContractHistory.
         * @implements ICryptoContractHistory
         * @constructor
         * @param {Proto.ICryptoContractHistory=} [p] Properties to set
         */
        function CryptoContractHistory(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CryptoContractHistory uid.
         * @member {string} uid
         * @memberof Proto.CryptoContractHistory
         * @instance
         */
        CryptoContractHistory.prototype.uid = "";

        /**
         * CryptoContractHistory contractUID.
         * @member {string} contractUID
         * @memberof Proto.CryptoContractHistory
         * @instance
         */
        CryptoContractHistory.prototype.contractUID = "";

        /**
         * CryptoContractHistory type.
         * @member {number} type
         * @memberof Proto.CryptoContractHistory
         * @instance
         */
        CryptoContractHistory.prototype.type = 0;

        /**
         * CryptoContractHistory nftUID.
         * @member {string} nftUID
         * @memberof Proto.CryptoContractHistory
         * @instance
         */
        CryptoContractHistory.prototype.nftUID = "";

        /**
         * CryptoContractHistory tokenId.
         * @member {string} tokenId
         * @memberof Proto.CryptoContractHistory
         * @instance
         */
        CryptoContractHistory.prototype.tokenId = "";

        /**
         * CryptoContractHistory fromWallet.
         * @member {string} fromWallet
         * @memberof Proto.CryptoContractHistory
         * @instance
         */
        CryptoContractHistory.prototype.fromWallet = "";

        /**
         * CryptoContractHistory toWallet.
         * @member {string} toWallet
         * @memberof Proto.CryptoContractHistory
         * @instance
         */
        CryptoContractHistory.prototype.toWallet = "";

        /**
         * CryptoContractHistory fromUser.
         * @member {Proto.IUser|null|undefined} fromUser
         * @memberof Proto.CryptoContractHistory
         * @instance
         */
        CryptoContractHistory.prototype.fromUser = null;

        /**
         * CryptoContractHistory toUser.
         * @member {Proto.IUser|null|undefined} toUser
         * @memberof Proto.CryptoContractHistory
         * @instance
         */
        CryptoContractHistory.prototype.toUser = null;

        /**
         * CryptoContractHistory from.
         * @member {Proto.ICryptoWallet|null|undefined} from
         * @memberof Proto.CryptoContractHistory
         * @instance
         */
        CryptoContractHistory.prototype.from = null;

        /**
         * CryptoContractHistory to.
         * @member {Proto.ICryptoWallet|null|undefined} to
         * @memberof Proto.CryptoContractHistory
         * @instance
         */
        CryptoContractHistory.prototype.to = null;

        /**
         * CryptoContractHistory contract.
         * @member {Proto.ICryptoContract|null|undefined} contract
         * @memberof Proto.CryptoContractHistory
         * @instance
         */
        CryptoContractHistory.prototype.contract = null;

        /**
         * CryptoContractHistory registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CryptoContractHistory
         * @instance
         */
        CryptoContractHistory.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CryptoContractHistory modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CryptoContractHistory
         * @instance
         */
        CryptoContractHistory.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CryptoContractHistory hash.
         * @member {string} hash
         * @memberof Proto.CryptoContractHistory
         * @instance
         */
        CryptoContractHistory.prototype.hash = "";

        /**
         * CryptoContractHistory status.
         * @member {number} status
         * @memberof Proto.CryptoContractHistory
         * @instance
         */
        CryptoContractHistory.prototype.status = 0;

        /**
         * Creates a new CryptoContractHistory instance using the specified properties.
         * @function create
         * @memberof Proto.CryptoContractHistory
         * @static
         * @param {Proto.ICryptoContractHistory=} [properties] Properties to set
         * @returns {Proto.CryptoContractHistory} CryptoContractHistory instance
         */
        CryptoContractHistory.create = function create(properties) {
            return new CryptoContractHistory(properties);
        };

        /**
         * Encodes the specified CryptoContractHistory message. Does not implicitly {@link Proto.CryptoContractHistory.verify|verify} messages.
         * @function encode
         * @memberof Proto.CryptoContractHistory
         * @static
         * @param {Proto.ICryptoContractHistory} m CryptoContractHistory message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CryptoContractHistory.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.contractUID != null && Object.hasOwnProperty.call(m, "contractUID"))
                w.uint32(18).string(m.contractUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(24).int32(m.type);
            if (m.nftUID != null && Object.hasOwnProperty.call(m, "nftUID"))
                w.uint32(34).string(m.nftUID);
            if (m.tokenId != null && Object.hasOwnProperty.call(m, "tokenId"))
                w.uint32(42).string(m.tokenId);
            if (m.fromWallet != null && Object.hasOwnProperty.call(m, "fromWallet"))
                w.uint32(50).string(m.fromWallet);
            if (m.toWallet != null && Object.hasOwnProperty.call(m, "toWallet"))
                w.uint32(58).string(m.toWallet);
            if (m.fromUser != null && Object.hasOwnProperty.call(m, "fromUser"))
                $root.Proto.User.encode(m.fromUser, w.uint32(66).fork()).ldelim();
            if (m.toUser != null && Object.hasOwnProperty.call(m, "toUser"))
                $root.Proto.User.encode(m.toUser, w.uint32(74).fork()).ldelim();
            if (m.from != null && Object.hasOwnProperty.call(m, "from"))
                $root.Proto.CryptoWallet.encode(m.from, w.uint32(82).fork()).ldelim();
            if (m.to != null && Object.hasOwnProperty.call(m, "to"))
                $root.Proto.CryptoWallet.encode(m.to, w.uint32(90).fork()).ldelim();
            if (m.contract != null && Object.hasOwnProperty.call(m, "contract"))
                $root.Proto.CryptoContract.encode(m.contract, w.uint32(98).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(104).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(112).int64(m.modifyDate);
            if (m.hash != null && Object.hasOwnProperty.call(m, "hash"))
                w.uint32(122).string(m.hash);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(128).int32(m.status);
            return w;
        };

        /**
         * Decodes a CryptoContractHistory message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CryptoContractHistory
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CryptoContractHistory} CryptoContractHistory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CryptoContractHistory.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CryptoContractHistory();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.contractUID = r.string();
                    break;
                case 3:
                    m.type = r.int32();
                    break;
                case 4:
                    m.nftUID = r.string();
                    break;
                case 5:
                    m.tokenId = r.string();
                    break;
                case 6:
                    m.fromWallet = r.string();
                    break;
                case 7:
                    m.toWallet = r.string();
                    break;
                case 8:
                    m.fromUser = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 9:
                    m.toUser = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 10:
                    m.from = $root.Proto.CryptoWallet.decode(r, r.uint32());
                    break;
                case 11:
                    m.to = $root.Proto.CryptoWallet.decode(r, r.uint32());
                    break;
                case 12:
                    m.contract = $root.Proto.CryptoContract.decode(r, r.uint32());
                    break;
                case 13:
                    m.registerDate = r.int64();
                    break;
                case 14:
                    m.modifyDate = r.int64();
                    break;
                case 15:
                    m.hash = r.string();
                    break;
                case 16:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CryptoContractHistory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CryptoContractHistory
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CryptoContractHistory} CryptoContractHistory
         */
        CryptoContractHistory.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CryptoContractHistory)
                return d;
            var m = new $root.Proto.CryptoContractHistory();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.contractUID != null) {
                m.contractUID = String(d.contractUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.nftUID != null) {
                m.nftUID = String(d.nftUID);
            }
            if (d.tokenId != null) {
                m.tokenId = String(d.tokenId);
            }
            if (d.fromWallet != null) {
                m.fromWallet = String(d.fromWallet);
            }
            if (d.toWallet != null) {
                m.toWallet = String(d.toWallet);
            }
            if (d.fromUser != null) {
                if (typeof d.fromUser !== "object")
                    throw TypeError(".Proto.CryptoContractHistory.fromUser: object expected");
                m.fromUser = $root.Proto.User.fromObject(d.fromUser);
            }
            if (d.toUser != null) {
                if (typeof d.toUser !== "object")
                    throw TypeError(".Proto.CryptoContractHistory.toUser: object expected");
                m.toUser = $root.Proto.User.fromObject(d.toUser);
            }
            if (d.from != null) {
                if (typeof d.from !== "object")
                    throw TypeError(".Proto.CryptoContractHistory.from: object expected");
                m.from = $root.Proto.CryptoWallet.fromObject(d.from);
            }
            if (d.to != null) {
                if (typeof d.to !== "object")
                    throw TypeError(".Proto.CryptoContractHistory.to: object expected");
                m.to = $root.Proto.CryptoWallet.fromObject(d.to);
            }
            if (d.contract != null) {
                if (typeof d.contract !== "object")
                    throw TypeError(".Proto.CryptoContractHistory.contract: object expected");
                m.contract = $root.Proto.CryptoContract.fromObject(d.contract);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.hash != null) {
                m.hash = String(d.hash);
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a CryptoContractHistory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CryptoContractHistory
         * @static
         * @param {Proto.CryptoContractHistory} m CryptoContractHistory
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CryptoContractHistory.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.contractUID = "";
                d.type = 0;
                d.nftUID = "";
                d.tokenId = "";
                d.fromWallet = "";
                d.toWallet = "";
                d.fromUser = null;
                d.toUser = null;
                d.from = null;
                d.to = null;
                d.contract = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.hash = "";
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.contractUID != null && m.hasOwnProperty("contractUID")) {
                d.contractUID = m.contractUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.nftUID != null && m.hasOwnProperty("nftUID")) {
                d.nftUID = m.nftUID;
            }
            if (m.tokenId != null && m.hasOwnProperty("tokenId")) {
                d.tokenId = m.tokenId;
            }
            if (m.fromWallet != null && m.hasOwnProperty("fromWallet")) {
                d.fromWallet = m.fromWallet;
            }
            if (m.toWallet != null && m.hasOwnProperty("toWallet")) {
                d.toWallet = m.toWallet;
            }
            if (m.fromUser != null && m.hasOwnProperty("fromUser")) {
                d.fromUser = $root.Proto.User.toObject(m.fromUser, o);
            }
            if (m.toUser != null && m.hasOwnProperty("toUser")) {
                d.toUser = $root.Proto.User.toObject(m.toUser, o);
            }
            if (m.from != null && m.hasOwnProperty("from")) {
                d.from = $root.Proto.CryptoWallet.toObject(m.from, o);
            }
            if (m.to != null && m.hasOwnProperty("to")) {
                d.to = $root.Proto.CryptoWallet.toObject(m.to, o);
            }
            if (m.contract != null && m.hasOwnProperty("contract")) {
                d.contract = $root.Proto.CryptoContract.toObject(m.contract, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.hash != null && m.hasOwnProperty("hash")) {
                d.hash = m.hash;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this CryptoContractHistory to JSON.
         * @function toJSON
         * @memberof Proto.CryptoContractHistory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CryptoContractHistory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CryptoContractHistory;
    })();

    Proto.CryptoContractHistoryList = (function() {

        /**
         * Properties of a CryptoContractHistoryList.
         * @memberof Proto
         * @interface ICryptoContractHistoryList
         * @property {Array.<Proto.ICryptoContractHistory>|null} [value] CryptoContractHistoryList value
         */

        /**
         * Constructs a new CryptoContractHistoryList.
         * @memberof Proto
         * @classdesc Represents a CryptoContractHistoryList.
         * @implements ICryptoContractHistoryList
         * @constructor
         * @param {Proto.ICryptoContractHistoryList=} [p] Properties to set
         */
        function CryptoContractHistoryList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CryptoContractHistoryList value.
         * @member {Array.<Proto.ICryptoContractHistory>} value
         * @memberof Proto.CryptoContractHistoryList
         * @instance
         */
        CryptoContractHistoryList.prototype.value = $util.emptyArray;

        /**
         * Creates a new CryptoContractHistoryList instance using the specified properties.
         * @function create
         * @memberof Proto.CryptoContractHistoryList
         * @static
         * @param {Proto.ICryptoContractHistoryList=} [properties] Properties to set
         * @returns {Proto.CryptoContractHistoryList} CryptoContractHistoryList instance
         */
        CryptoContractHistoryList.create = function create(properties) {
            return new CryptoContractHistoryList(properties);
        };

        /**
         * Encodes the specified CryptoContractHistoryList message. Does not implicitly {@link Proto.CryptoContractHistoryList.verify|verify} messages.
         * @function encode
         * @memberof Proto.CryptoContractHistoryList
         * @static
         * @param {Proto.ICryptoContractHistoryList} m CryptoContractHistoryList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CryptoContractHistoryList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.CryptoContractHistory.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a CryptoContractHistoryList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CryptoContractHistoryList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CryptoContractHistoryList} CryptoContractHistoryList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CryptoContractHistoryList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CryptoContractHistoryList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.CryptoContractHistory.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CryptoContractHistoryList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CryptoContractHistoryList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CryptoContractHistoryList} CryptoContractHistoryList
         */
        CryptoContractHistoryList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CryptoContractHistoryList)
                return d;
            var m = new $root.Proto.CryptoContractHistoryList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.CryptoContractHistoryList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.CryptoContractHistoryList.value: object expected");
                    m.value[i] = $root.Proto.CryptoContractHistory.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a CryptoContractHistoryList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CryptoContractHistoryList
         * @static
         * @param {Proto.CryptoContractHistoryList} m CryptoContractHistoryList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CryptoContractHistoryList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.CryptoContractHistory.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this CryptoContractHistoryList to JSON.
         * @function toJSON
         * @memberof Proto.CryptoContractHistoryList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CryptoContractHistoryList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CryptoContractHistoryList;
    })();

    Proto.Web3NetworkInfo = (function() {

        /**
         * Properties of a Web3NetworkInfo.
         * @memberof Proto
         * @interface IWeb3NetworkInfo
         * @property {number|null} [networkType] Web3NetworkInfo networkType
         * @property {string|null} [scanUrl] Web3NetworkInfo scanUrl
         * @property {string|null} [name] Web3NetworkInfo name
         * @property {string|null} [symbol] Web3NetworkInfo symbol
         * @property {number|null} [fee] Web3NetworkInfo fee
         */

        /**
         * Constructs a new Web3NetworkInfo.
         * @memberof Proto
         * @classdesc Represents a Web3NetworkInfo.
         * @implements IWeb3NetworkInfo
         * @constructor
         * @param {Proto.IWeb3NetworkInfo=} [p] Properties to set
         */
        function Web3NetworkInfo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Web3NetworkInfo networkType.
         * @member {number} networkType
         * @memberof Proto.Web3NetworkInfo
         * @instance
         */
        Web3NetworkInfo.prototype.networkType = 0;

        /**
         * Web3NetworkInfo scanUrl.
         * @member {string} scanUrl
         * @memberof Proto.Web3NetworkInfo
         * @instance
         */
        Web3NetworkInfo.prototype.scanUrl = "";

        /**
         * Web3NetworkInfo name.
         * @member {string} name
         * @memberof Proto.Web3NetworkInfo
         * @instance
         */
        Web3NetworkInfo.prototype.name = "";

        /**
         * Web3NetworkInfo symbol.
         * @member {string} symbol
         * @memberof Proto.Web3NetworkInfo
         * @instance
         */
        Web3NetworkInfo.prototype.symbol = "";

        /**
         * Web3NetworkInfo fee.
         * @member {number} fee
         * @memberof Proto.Web3NetworkInfo
         * @instance
         */
        Web3NetworkInfo.prototype.fee = 0;

        /**
         * Creates a new Web3NetworkInfo instance using the specified properties.
         * @function create
         * @memberof Proto.Web3NetworkInfo
         * @static
         * @param {Proto.IWeb3NetworkInfo=} [properties] Properties to set
         * @returns {Proto.Web3NetworkInfo} Web3NetworkInfo instance
         */
        Web3NetworkInfo.create = function create(properties) {
            return new Web3NetworkInfo(properties);
        };

        /**
         * Encodes the specified Web3NetworkInfo message. Does not implicitly {@link Proto.Web3NetworkInfo.verify|verify} messages.
         * @function encode
         * @memberof Proto.Web3NetworkInfo
         * @static
         * @param {Proto.IWeb3NetworkInfo} m Web3NetworkInfo message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Web3NetworkInfo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.networkType != null && Object.hasOwnProperty.call(m, "networkType"))
                w.uint32(8).int32(m.networkType);
            if (m.scanUrl != null && Object.hasOwnProperty.call(m, "scanUrl"))
                w.uint32(18).string(m.scanUrl);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.symbol != null && Object.hasOwnProperty.call(m, "symbol"))
                w.uint32(34).string(m.symbol);
            if (m.fee != null && Object.hasOwnProperty.call(m, "fee"))
                w.uint32(41).double(m.fee);
            return w;
        };

        /**
         * Decodes a Web3NetworkInfo message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.Web3NetworkInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.Web3NetworkInfo} Web3NetworkInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Web3NetworkInfo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.Web3NetworkInfo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.networkType = r.int32();
                    break;
                case 2:
                    m.scanUrl = r.string();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.symbol = r.string();
                    break;
                case 5:
                    m.fee = r.double();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a Web3NetworkInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.Web3NetworkInfo
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.Web3NetworkInfo} Web3NetworkInfo
         */
        Web3NetworkInfo.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.Web3NetworkInfo)
                return d;
            var m = new $root.Proto.Web3NetworkInfo();
            if (d.networkType != null) {
                m.networkType = d.networkType | 0;
            }
            if (d.scanUrl != null) {
                m.scanUrl = String(d.scanUrl);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.symbol != null) {
                m.symbol = String(d.symbol);
            }
            if (d.fee != null) {
                m.fee = Number(d.fee);
            }
            return m;
        };

        /**
         * Creates a plain object from a Web3NetworkInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.Web3NetworkInfo
         * @static
         * @param {Proto.Web3NetworkInfo} m Web3NetworkInfo
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Web3NetworkInfo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.networkType = 0;
                d.scanUrl = "";
                d.name = "";
                d.symbol = "";
                d.fee = 0;
            }
            if (m.networkType != null && m.hasOwnProperty("networkType")) {
                d.networkType = m.networkType;
            }
            if (m.scanUrl != null && m.hasOwnProperty("scanUrl")) {
                d.scanUrl = m.scanUrl;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.symbol != null && m.hasOwnProperty("symbol")) {
                d.symbol = m.symbol;
            }
            if (m.fee != null && m.hasOwnProperty("fee")) {
                d.fee = o.json && !isFinite(m.fee) ? String(m.fee) : m.fee;
            }
            return d;
        };

        /**
         * Converts this Web3NetworkInfo to JSON.
         * @function toJSON
         * @memberof Proto.Web3NetworkInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Web3NetworkInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Web3NetworkInfo;
    })();

    Proto.WalletOrder = (function() {

        /**
         * Properties of a WalletOrder.
         * @memberof Proto
         * @interface IWalletOrder
         * @property {string|null} [title] WalletOrder title
         * @property {Proto.ICryptoContract|null} [contract] WalletOrder contract
         * @property {Array.<Proto.ICryptoNFT>|null} [nftList] WalletOrder nftList
         * @property {Proto.IContentCommerce|null} [commerce] WalletOrder commerce
         */

        /**
         * Constructs a new WalletOrder.
         * @memberof Proto
         * @classdesc Represents a WalletOrder.
         * @implements IWalletOrder
         * @constructor
         * @param {Proto.IWalletOrder=} [p] Properties to set
         */
        function WalletOrder(p) {
            this.nftList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WalletOrder title.
         * @member {string} title
         * @memberof Proto.WalletOrder
         * @instance
         */
        WalletOrder.prototype.title = "";

        /**
         * WalletOrder contract.
         * @member {Proto.ICryptoContract|null|undefined} contract
         * @memberof Proto.WalletOrder
         * @instance
         */
        WalletOrder.prototype.contract = null;

        /**
         * WalletOrder nftList.
         * @member {Array.<Proto.ICryptoNFT>} nftList
         * @memberof Proto.WalletOrder
         * @instance
         */
        WalletOrder.prototype.nftList = $util.emptyArray;

        /**
         * WalletOrder commerce.
         * @member {Proto.IContentCommerce|null|undefined} commerce
         * @memberof Proto.WalletOrder
         * @instance
         */
        WalletOrder.prototype.commerce = null;

        /**
         * Creates a new WalletOrder instance using the specified properties.
         * @function create
         * @memberof Proto.WalletOrder
         * @static
         * @param {Proto.IWalletOrder=} [properties] Properties to set
         * @returns {Proto.WalletOrder} WalletOrder instance
         */
        WalletOrder.create = function create(properties) {
            return new WalletOrder(properties);
        };

        /**
         * Encodes the specified WalletOrder message. Does not implicitly {@link Proto.WalletOrder.verify|verify} messages.
         * @function encode
         * @memberof Proto.WalletOrder
         * @static
         * @param {Proto.IWalletOrder} m WalletOrder message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WalletOrder.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(10).string(m.title);
            if (m.contract != null && Object.hasOwnProperty.call(m, "contract"))
                $root.Proto.CryptoContract.encode(m.contract, w.uint32(18).fork()).ldelim();
            if (m.nftList != null && m.nftList.length) {
                for (var i = 0; i < m.nftList.length; ++i)
                    $root.Proto.CryptoNFT.encode(m.nftList[i], w.uint32(26).fork()).ldelim();
            }
            if (m.commerce != null && Object.hasOwnProperty.call(m, "commerce"))
                $root.Proto.ContentCommerce.encode(m.commerce, w.uint32(42).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a WalletOrder message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WalletOrder
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WalletOrder} WalletOrder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WalletOrder.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WalletOrder();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.title = r.string();
                    break;
                case 2:
                    m.contract = $root.Proto.CryptoContract.decode(r, r.uint32());
                    break;
                case 3:
                    if (!(m.nftList && m.nftList.length))
                        m.nftList = [];
                    m.nftList.push($root.Proto.CryptoNFT.decode(r, r.uint32()));
                    break;
                case 5:
                    m.commerce = $root.Proto.ContentCommerce.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WalletOrder message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WalletOrder
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WalletOrder} WalletOrder
         */
        WalletOrder.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WalletOrder)
                return d;
            var m = new $root.Proto.WalletOrder();
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.contract != null) {
                if (typeof d.contract !== "object")
                    throw TypeError(".Proto.WalletOrder.contract: object expected");
                m.contract = $root.Proto.CryptoContract.fromObject(d.contract);
            }
            if (d.nftList) {
                if (!Array.isArray(d.nftList))
                    throw TypeError(".Proto.WalletOrder.nftList: array expected");
                m.nftList = [];
                for (var i = 0; i < d.nftList.length; ++i) {
                    if (typeof d.nftList[i] !== "object")
                        throw TypeError(".Proto.WalletOrder.nftList: object expected");
                    m.nftList[i] = $root.Proto.CryptoNFT.fromObject(d.nftList[i]);
                }
            }
            if (d.commerce != null) {
                if (typeof d.commerce !== "object")
                    throw TypeError(".Proto.WalletOrder.commerce: object expected");
                m.commerce = $root.Proto.ContentCommerce.fromObject(d.commerce);
            }
            return m;
        };

        /**
         * Creates a plain object from a WalletOrder message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WalletOrder
         * @static
         * @param {Proto.WalletOrder} m WalletOrder
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WalletOrder.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.nftList = [];
            }
            if (o.defaults) {
                d.title = "";
                d.contract = null;
                d.commerce = null;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.contract != null && m.hasOwnProperty("contract")) {
                d.contract = $root.Proto.CryptoContract.toObject(m.contract, o);
            }
            if (m.nftList && m.nftList.length) {
                d.nftList = [];
                for (var j = 0; j < m.nftList.length; ++j) {
                    d.nftList[j] = $root.Proto.CryptoNFT.toObject(m.nftList[j], o);
                }
            }
            if (m.commerce != null && m.hasOwnProperty("commerce")) {
                d.commerce = $root.Proto.ContentCommerce.toObject(m.commerce, o);
            }
            return d;
        };

        /**
         * Converts this WalletOrder to JSON.
         * @function toJSON
         * @memberof Proto.WalletOrder
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WalletOrder.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WalletOrder;
    })();

    Proto.WalletOrderList = (function() {

        /**
         * Properties of a WalletOrderList.
         * @memberof Proto
         * @interface IWalletOrderList
         * @property {Array.<Proto.IWalletOrder>|null} [value] WalletOrderList value
         */

        /**
         * Constructs a new WalletOrderList.
         * @memberof Proto
         * @classdesc Represents a WalletOrderList.
         * @implements IWalletOrderList
         * @constructor
         * @param {Proto.IWalletOrderList=} [p] Properties to set
         */
        function WalletOrderList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WalletOrderList value.
         * @member {Array.<Proto.IWalletOrder>} value
         * @memberof Proto.WalletOrderList
         * @instance
         */
        WalletOrderList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WalletOrderList instance using the specified properties.
         * @function create
         * @memberof Proto.WalletOrderList
         * @static
         * @param {Proto.IWalletOrderList=} [properties] Properties to set
         * @returns {Proto.WalletOrderList} WalletOrderList instance
         */
        WalletOrderList.create = function create(properties) {
            return new WalletOrderList(properties);
        };

        /**
         * Encodes the specified WalletOrderList message. Does not implicitly {@link Proto.WalletOrderList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WalletOrderList
         * @static
         * @param {Proto.IWalletOrderList} m WalletOrderList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WalletOrderList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WalletOrder.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WalletOrderList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WalletOrderList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WalletOrderList} WalletOrderList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WalletOrderList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WalletOrderList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WalletOrder.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WalletOrderList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WalletOrderList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WalletOrderList} WalletOrderList
         */
        WalletOrderList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WalletOrderList)
                return d;
            var m = new $root.Proto.WalletOrderList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WalletOrderList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WalletOrderList.value: object expected");
                    m.value[i] = $root.Proto.WalletOrder.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WalletOrderList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WalletOrderList
         * @static
         * @param {Proto.WalletOrderList} m WalletOrderList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WalletOrderList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WalletOrder.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WalletOrderList to JSON.
         * @function toJSON
         * @memberof Proto.WalletOrderList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WalletOrderList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WalletOrderList;
    })();

    Proto.NFTTransfer = (function() {

        /**
         * Properties of a NFTTransfer.
         * @memberof Proto
         * @interface INFTTransfer
         * @property {string|null} [userId] NFTTransfer userId
         * @property {number|null} [price] NFTTransfer price
         * @property {string|null} [currency] NFTTransfer currency
         */

        /**
         * Constructs a new NFTTransfer.
         * @memberof Proto
         * @classdesc Represents a NFTTransfer.
         * @implements INFTTransfer
         * @constructor
         * @param {Proto.INFTTransfer=} [p] Properties to set
         */
        function NFTTransfer(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * NFTTransfer userId.
         * @member {string} userId
         * @memberof Proto.NFTTransfer
         * @instance
         */
        NFTTransfer.prototype.userId = "";

        /**
         * NFTTransfer price.
         * @member {number} price
         * @memberof Proto.NFTTransfer
         * @instance
         */
        NFTTransfer.prototype.price = 0;

        /**
         * NFTTransfer currency.
         * @member {string} currency
         * @memberof Proto.NFTTransfer
         * @instance
         */
        NFTTransfer.prototype.currency = "";

        /**
         * Creates a new NFTTransfer instance using the specified properties.
         * @function create
         * @memberof Proto.NFTTransfer
         * @static
         * @param {Proto.INFTTransfer=} [properties] Properties to set
         * @returns {Proto.NFTTransfer} NFTTransfer instance
         */
        NFTTransfer.create = function create(properties) {
            return new NFTTransfer(properties);
        };

        /**
         * Encodes the specified NFTTransfer message. Does not implicitly {@link Proto.NFTTransfer.verify|verify} messages.
         * @function encode
         * @memberof Proto.NFTTransfer
         * @static
         * @param {Proto.INFTTransfer} m NFTTransfer message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NFTTransfer.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userId != null && Object.hasOwnProperty.call(m, "userId"))
                w.uint32(10).string(m.userId);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(17).double(m.price);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(26).string(m.currency);
            return w;
        };

        /**
         * Decodes a NFTTransfer message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.NFTTransfer
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.NFTTransfer} NFTTransfer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NFTTransfer.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.NFTTransfer();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.userId = r.string();
                    break;
                case 2:
                    m.price = r.double();
                    break;
                case 3:
                    m.currency = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a NFTTransfer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.NFTTransfer
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.NFTTransfer} NFTTransfer
         */
        NFTTransfer.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.NFTTransfer)
                return d;
            var m = new $root.Proto.NFTTransfer();
            if (d.userId != null) {
                m.userId = String(d.userId);
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            return m;
        };

        /**
         * Creates a plain object from a NFTTransfer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.NFTTransfer
         * @static
         * @param {Proto.NFTTransfer} m NFTTransfer
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NFTTransfer.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.userId = "";
                d.price = 0;
                d.currency = "";
            }
            if (m.userId != null && m.hasOwnProperty("userId")) {
                d.userId = m.userId;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            return d;
        };

        /**
         * Converts this NFTTransfer to JSON.
         * @function toJSON
         * @memberof Proto.NFTTransfer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NFTTransfer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NFTTransfer;
    })();

    Proto.ChatUnreadCount = (function() {

        /**
         * Properties of a ChatUnreadCount.
         * @memberof Proto
         * @interface IChatUnreadCount
         * @property {string|null} [oldChatId] ChatUnreadCount oldChatId
         * @property {string|null} [count] ChatUnreadCount count
         */

        /**
         * Constructs a new ChatUnreadCount.
         * @memberof Proto
         * @classdesc Represents a ChatUnreadCount.
         * @implements IChatUnreadCount
         * @constructor
         * @param {Proto.IChatUnreadCount=} [p] Properties to set
         */
        function ChatUnreadCount(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ChatUnreadCount oldChatId.
         * @member {string} oldChatId
         * @memberof Proto.ChatUnreadCount
         * @instance
         */
        ChatUnreadCount.prototype.oldChatId = "";

        /**
         * ChatUnreadCount count.
         * @member {string} count
         * @memberof Proto.ChatUnreadCount
         * @instance
         */
        ChatUnreadCount.prototype.count = "";

        /**
         * Creates a new ChatUnreadCount instance using the specified properties.
         * @function create
         * @memberof Proto.ChatUnreadCount
         * @static
         * @param {Proto.IChatUnreadCount=} [properties] Properties to set
         * @returns {Proto.ChatUnreadCount} ChatUnreadCount instance
         */
        ChatUnreadCount.create = function create(properties) {
            return new ChatUnreadCount(properties);
        };

        /**
         * Encodes the specified ChatUnreadCount message. Does not implicitly {@link Proto.ChatUnreadCount.verify|verify} messages.
         * @function encode
         * @memberof Proto.ChatUnreadCount
         * @static
         * @param {Proto.IChatUnreadCount} m ChatUnreadCount message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatUnreadCount.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.oldChatId != null && Object.hasOwnProperty.call(m, "oldChatId"))
                w.uint32(10).string(m.oldChatId);
            if (m.count != null && Object.hasOwnProperty.call(m, "count"))
                w.uint32(18).string(m.count);
            return w;
        };

        /**
         * Decodes a ChatUnreadCount message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ChatUnreadCount
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ChatUnreadCount} ChatUnreadCount
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatUnreadCount.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ChatUnreadCount();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.oldChatId = r.string();
                    break;
                case 2:
                    m.count = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ChatUnreadCount message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ChatUnreadCount
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ChatUnreadCount} ChatUnreadCount
         */
        ChatUnreadCount.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ChatUnreadCount)
                return d;
            var m = new $root.Proto.ChatUnreadCount();
            if (d.oldChatId != null) {
                m.oldChatId = String(d.oldChatId);
            }
            if (d.count != null) {
                m.count = String(d.count);
            }
            return m;
        };

        /**
         * Creates a plain object from a ChatUnreadCount message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ChatUnreadCount
         * @static
         * @param {Proto.ChatUnreadCount} m ChatUnreadCount
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChatUnreadCount.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.oldChatId = "";
                d.count = "";
            }
            if (m.oldChatId != null && m.hasOwnProperty("oldChatId")) {
                d.oldChatId = m.oldChatId;
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                d.count = m.count;
            }
            return d;
        };

        /**
         * Converts this ChatUnreadCount to JSON.
         * @function toJSON
         * @memberof Proto.ChatUnreadCount
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChatUnreadCount.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChatUnreadCount;
    })();

    Proto.WorkflowBuilder = (function() {

        /**
         * Properties of a WorkflowBuilder.
         * @memberof Proto
         * @interface IWorkflowBuilder
         * @property {string|null} [uid] WorkflowBuilder uid
         * @property {string|null} [companyUID] WorkflowBuilder companyUID
         * @property {string|null} [title] WorkflowBuilder title
         * @property {string|null} [subtitle] WorkflowBuilder subtitle
         * @property {string|null} [description] WorkflowBuilder description
         * @property {number|Long|null} [startDate] WorkflowBuilder startDate
         * @property {number|Long|null} [endDate] WorkflowBuilder endDate
         * @property {number|null} [distribution] WorkflowBuilder distribution
         * @property {number|Long|null} [registerDate] WorkflowBuilder registerDate
         * @property {number|Long|null} [modifyDate] WorkflowBuilder modifyDate
         * @property {number|null} [status] WorkflowBuilder status
         * @property {string|null} [formInfo] WorkflowBuilder formInfo
         * @property {boolean|null} [enableMultipleInput] WorkflowBuilder enableMultipleInput
         * @property {Proto.IUploadFile|null} [image] WorkflowBuilder image
         * @property {Proto.IUploadFile|null} [listImage] WorkflowBuilder listImage
         * @property {string|null} [listImageOrder] WorkflowBuilder listImageOrder
         * @property {string|null} [plan] WorkflowBuilder plan
         * @property {Array.<Proto.IWorkflowBuilderFormInfo>|null} [formList] WorkflowBuilder formList
         * @property {string|null} [answerCount] WorkflowBuilder answerCount
         * @property {Array.<Proto.IHashTag>|null} [adminTagList] WorkflowBuilder adminTagList
         * @property {number|null} [inputType] WorkflowBuilder inputType
         * @property {Array.<Proto.ICompanyUserGroup>|null} [groupList] WorkflowBuilder groupList
         * @property {string|null} [existCount] WorkflowBuilder existCount
         * @property {boolean|null} [main] WorkflowBuilder main
         * @property {Array.<Proto.IContentWorkflowMap>|null} [contentMapList] WorkflowBuilder contentMapList
         * @property {string|null} [targetContentUID] WorkflowBuilder targetContentUID
         * @property {string|null} [name] WorkflowBuilder name
         * @property {boolean|null} [enableStatus] WorkflowBuilder enableStatus
         * @property {Array.<Proto.IWorkflowBuilderStatus>|null} [statusList] WorkflowBuilder statusList
         * @property {number|null} [remindType] WorkflowBuilder remindType
         * @property {boolean|null} [remindByEnd] WorkflowBuilder remindByEnd
         * @property {number|null} [remindWeekday] WorkflowBuilder remindWeekday
         * @property {number|null} [remindDay] WorkflowBuilder remindDay
         * @property {string|null} [remindTime] WorkflowBuilder remindTime
         * @property {number|null} [remindTimezone] WorkflowBuilder remindTimezone
         * @property {string|null} [remindText] WorkflowBuilder remindText
         * @property {number|null} [statusType] WorkflowBuilder statusType
         * @property {string|null} [remindWeekdays] WorkflowBuilder remindWeekdays
         * @property {boolean|null} [useFileTemplate] WorkflowBuilder useFileTemplate
         * @property {string|null} [fileTemplate] WorkflowBuilder fileTemplate
         * @property {Array.<Proto.IUser>|null} [planUserList] WorkflowBuilder planUserList
         * @property {Proto.IWorkflowBuilderTVOD|null} [tvod] WorkflowBuilder tvod
         * @property {number|null} [needToPay] WorkflowBuilder needToPay
         * @property {Array.<number>|null} [filePayTypes] WorkflowBuilder filePayTypes
         */

        /**
         * Constructs a new WorkflowBuilder.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilder.
         * @implements IWorkflowBuilder
         * @constructor
         * @param {Proto.IWorkflowBuilder=} [p] Properties to set
         */
        function WorkflowBuilder(p) {
            this.formList = [];
            this.adminTagList = [];
            this.groupList = [];
            this.contentMapList = [];
            this.statusList = [];
            this.planUserList = [];
            this.filePayTypes = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilder uid.
         * @member {string} uid
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.uid = "";

        /**
         * WorkflowBuilder companyUID.
         * @member {string} companyUID
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.companyUID = "";

        /**
         * WorkflowBuilder title.
         * @member {string} title
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.title = "";

        /**
         * WorkflowBuilder subtitle.
         * @member {string} subtitle
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.subtitle = "";

        /**
         * WorkflowBuilder description.
         * @member {string} description
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.description = "";

        /**
         * WorkflowBuilder startDate.
         * @member {number|Long} startDate
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilder endDate.
         * @member {number|Long} endDate
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilder distribution.
         * @member {number} distribution
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.distribution = 0;

        /**
         * WorkflowBuilder registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilder modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilder status.
         * @member {number} status
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.status = 0;

        /**
         * WorkflowBuilder formInfo.
         * @member {string} formInfo
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.formInfo = "";

        /**
         * WorkflowBuilder enableMultipleInput.
         * @member {boolean} enableMultipleInput
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.enableMultipleInput = false;

        /**
         * WorkflowBuilder image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.image = null;

        /**
         * WorkflowBuilder listImage.
         * @member {Proto.IUploadFile|null|undefined} listImage
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.listImage = null;

        /**
         * WorkflowBuilder listImageOrder.
         * @member {string} listImageOrder
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.listImageOrder = "";

        /**
         * WorkflowBuilder plan.
         * @member {string} plan
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.plan = "";

        /**
         * WorkflowBuilder formList.
         * @member {Array.<Proto.IWorkflowBuilderFormInfo>} formList
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.formList = $util.emptyArray;

        /**
         * WorkflowBuilder answerCount.
         * @member {string} answerCount
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.answerCount = "";

        /**
         * WorkflowBuilder adminTagList.
         * @member {Array.<Proto.IHashTag>} adminTagList
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.adminTagList = $util.emptyArray;

        /**
         * WorkflowBuilder inputType.
         * @member {number} inputType
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.inputType = 0;

        /**
         * WorkflowBuilder groupList.
         * @member {Array.<Proto.ICompanyUserGroup>} groupList
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.groupList = $util.emptyArray;

        /**
         * WorkflowBuilder existCount.
         * @member {string} existCount
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.existCount = "";

        /**
         * WorkflowBuilder main.
         * @member {boolean} main
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.main = false;

        /**
         * WorkflowBuilder contentMapList.
         * @member {Array.<Proto.IContentWorkflowMap>} contentMapList
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.contentMapList = $util.emptyArray;

        /**
         * WorkflowBuilder targetContentUID.
         * @member {string} targetContentUID
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.targetContentUID = "";

        /**
         * WorkflowBuilder name.
         * @member {string} name
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.name = "";

        /**
         * WorkflowBuilder enableStatus.
         * @member {boolean} enableStatus
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.enableStatus = false;

        /**
         * WorkflowBuilder statusList.
         * @member {Array.<Proto.IWorkflowBuilderStatus>} statusList
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.statusList = $util.emptyArray;

        /**
         * WorkflowBuilder remindType.
         * @member {number} remindType
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.remindType = 0;

        /**
         * WorkflowBuilder remindByEnd.
         * @member {boolean} remindByEnd
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.remindByEnd = false;

        /**
         * WorkflowBuilder remindWeekday.
         * @member {number} remindWeekday
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.remindWeekday = 0;

        /**
         * WorkflowBuilder remindDay.
         * @member {number} remindDay
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.remindDay = 0;

        /**
         * WorkflowBuilder remindTime.
         * @member {string} remindTime
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.remindTime = "";

        /**
         * WorkflowBuilder remindTimezone.
         * @member {number} remindTimezone
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.remindTimezone = 0;

        /**
         * WorkflowBuilder remindText.
         * @member {string} remindText
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.remindText = "";

        /**
         * WorkflowBuilder statusType.
         * @member {number} statusType
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.statusType = 0;

        /**
         * WorkflowBuilder remindWeekdays.
         * @member {string} remindWeekdays
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.remindWeekdays = "";

        /**
         * WorkflowBuilder useFileTemplate.
         * @member {boolean} useFileTemplate
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.useFileTemplate = false;

        /**
         * WorkflowBuilder fileTemplate.
         * @member {string} fileTemplate
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.fileTemplate = "";

        /**
         * WorkflowBuilder planUserList.
         * @member {Array.<Proto.IUser>} planUserList
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.planUserList = $util.emptyArray;

        /**
         * WorkflowBuilder tvod.
         * @member {Proto.IWorkflowBuilderTVOD|null|undefined} tvod
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.tvod = null;

        /**
         * WorkflowBuilder needToPay.
         * @member {number} needToPay
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.needToPay = 0;

        /**
         * WorkflowBuilder filePayTypes.
         * @member {Array.<number>} filePayTypes
         * @memberof Proto.WorkflowBuilder
         * @instance
         */
        WorkflowBuilder.prototype.filePayTypes = $util.emptyArray;

        /**
         * Creates a new WorkflowBuilder instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilder
         * @static
         * @param {Proto.IWorkflowBuilder=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilder} WorkflowBuilder instance
         */
        WorkflowBuilder.create = function create(properties) {
            return new WorkflowBuilder(properties);
        };

        /**
         * Encodes the specified WorkflowBuilder message. Does not implicitly {@link Proto.WorkflowBuilder.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilder
         * @static
         * @param {Proto.IWorkflowBuilder} m WorkflowBuilder message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilder.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.companyUID != null && Object.hasOwnProperty.call(m, "companyUID"))
                w.uint32(18).string(m.companyUID);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(26).string(m.title);
            if (m.subtitle != null && Object.hasOwnProperty.call(m, "subtitle"))
                w.uint32(34).string(m.subtitle);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(42).string(m.description);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(48).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(56).int64(m.endDate);
            if (m.distribution != null && Object.hasOwnProperty.call(m, "distribution"))
                w.uint32(64).int32(m.distribution);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(72).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(80).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(88).int32(m.status);
            if (m.formInfo != null && Object.hasOwnProperty.call(m, "formInfo"))
                w.uint32(98).string(m.formInfo);
            if (m.enableMultipleInput != null && Object.hasOwnProperty.call(m, "enableMultipleInput"))
                w.uint32(104).bool(m.enableMultipleInput);
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(114).fork()).ldelim();
            if (m.listImage != null && Object.hasOwnProperty.call(m, "listImage"))
                $root.Proto.UploadFile.encode(m.listImage, w.uint32(122).fork()).ldelim();
            if (m.listImageOrder != null && Object.hasOwnProperty.call(m, "listImageOrder"))
                w.uint32(130).string(m.listImageOrder);
            if (m.plan != null && Object.hasOwnProperty.call(m, "plan"))
                w.uint32(138).string(m.plan);
            if (m.formList != null && m.formList.length) {
                for (var i = 0; i < m.formList.length; ++i)
                    $root.Proto.WorkflowBuilderFormInfo.encode(m.formList[i], w.uint32(146).fork()).ldelim();
            }
            if (m.answerCount != null && Object.hasOwnProperty.call(m, "answerCount"))
                w.uint32(154).string(m.answerCount);
            if (m.adminTagList != null && m.adminTagList.length) {
                for (var i = 0; i < m.adminTagList.length; ++i)
                    $root.Proto.HashTag.encode(m.adminTagList[i], w.uint32(162).fork()).ldelim();
            }
            if (m.inputType != null && Object.hasOwnProperty.call(m, "inputType"))
                w.uint32(168).int32(m.inputType);
            if (m.groupList != null && m.groupList.length) {
                for (var i = 0; i < m.groupList.length; ++i)
                    $root.Proto.CompanyUserGroup.encode(m.groupList[i], w.uint32(186).fork()).ldelim();
            }
            if (m.existCount != null && Object.hasOwnProperty.call(m, "existCount"))
                w.uint32(202).string(m.existCount);
            if (m.main != null && Object.hasOwnProperty.call(m, "main"))
                w.uint32(208).bool(m.main);
            if (m.contentMapList != null && m.contentMapList.length) {
                for (var i = 0; i < m.contentMapList.length; ++i)
                    $root.Proto.ContentWorkflowMap.encode(m.contentMapList[i], w.uint32(218).fork()).ldelim();
            }
            if (m.targetContentUID != null && Object.hasOwnProperty.call(m, "targetContentUID"))
                w.uint32(226).string(m.targetContentUID);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(234).string(m.name);
            if (m.enableStatus != null && Object.hasOwnProperty.call(m, "enableStatus"))
                w.uint32(240).bool(m.enableStatus);
            if (m.statusList != null && m.statusList.length) {
                for (var i = 0; i < m.statusList.length; ++i)
                    $root.Proto.WorkflowBuilderStatus.encode(m.statusList[i], w.uint32(250).fork()).ldelim();
            }
            if (m.remindType != null && Object.hasOwnProperty.call(m, "remindType"))
                w.uint32(256).int32(m.remindType);
            if (m.remindByEnd != null && Object.hasOwnProperty.call(m, "remindByEnd"))
                w.uint32(264).bool(m.remindByEnd);
            if (m.remindWeekday != null && Object.hasOwnProperty.call(m, "remindWeekday"))
                w.uint32(272).int32(m.remindWeekday);
            if (m.remindDay != null && Object.hasOwnProperty.call(m, "remindDay"))
                w.uint32(280).int32(m.remindDay);
            if (m.remindTime != null && Object.hasOwnProperty.call(m, "remindTime"))
                w.uint32(290).string(m.remindTime);
            if (m.remindTimezone != null && Object.hasOwnProperty.call(m, "remindTimezone"))
                w.uint32(296).int32(m.remindTimezone);
            if (m.remindText != null && Object.hasOwnProperty.call(m, "remindText"))
                w.uint32(306).string(m.remindText);
            if (m.statusType != null && Object.hasOwnProperty.call(m, "statusType"))
                w.uint32(312).int32(m.statusType);
            if (m.remindWeekdays != null && Object.hasOwnProperty.call(m, "remindWeekdays"))
                w.uint32(322).string(m.remindWeekdays);
            if (m.useFileTemplate != null && Object.hasOwnProperty.call(m, "useFileTemplate"))
                w.uint32(328).bool(m.useFileTemplate);
            if (m.fileTemplate != null && Object.hasOwnProperty.call(m, "fileTemplate"))
                w.uint32(338).string(m.fileTemplate);
            if (m.planUserList != null && m.planUserList.length) {
                for (var i = 0; i < m.planUserList.length; ++i)
                    $root.Proto.User.encode(m.planUserList[i], w.uint32(346).fork()).ldelim();
            }
            if (m.tvod != null && Object.hasOwnProperty.call(m, "tvod"))
                $root.Proto.WorkflowBuilderTVOD.encode(m.tvod, w.uint32(354).fork()).ldelim();
            if (m.needToPay != null && Object.hasOwnProperty.call(m, "needToPay"))
                w.uint32(360).int32(m.needToPay);
            if (m.filePayTypes != null && m.filePayTypes.length) {
                w.uint32(370).fork();
                for (var i = 0; i < m.filePayTypes.length; ++i)
                    w.int32(m.filePayTypes[i]);
                w.ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowBuilder message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilder
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilder} WorkflowBuilder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilder.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilder();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.companyUID = r.string();
                    break;
                case 3:
                    m.title = r.string();
                    break;
                case 4:
                    m.subtitle = r.string();
                    break;
                case 5:
                    m.description = r.string();
                    break;
                case 6:
                    m.startDate = r.int64();
                    break;
                case 7:
                    m.endDate = r.int64();
                    break;
                case 8:
                    m.distribution = r.int32();
                    break;
                case 9:
                    m.registerDate = r.int64();
                    break;
                case 10:
                    m.modifyDate = r.int64();
                    break;
                case 11:
                    m.status = r.int32();
                    break;
                case 12:
                    m.formInfo = r.string();
                    break;
                case 13:
                    m.enableMultipleInput = r.bool();
                    break;
                case 14:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 15:
                    m.listImage = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 16:
                    m.listImageOrder = r.string();
                    break;
                case 17:
                    m.plan = r.string();
                    break;
                case 18:
                    if (!(m.formList && m.formList.length))
                        m.formList = [];
                    m.formList.push($root.Proto.WorkflowBuilderFormInfo.decode(r, r.uint32()));
                    break;
                case 19:
                    m.answerCount = r.string();
                    break;
                case 20:
                    if (!(m.adminTagList && m.adminTagList.length))
                        m.adminTagList = [];
                    m.adminTagList.push($root.Proto.HashTag.decode(r, r.uint32()));
                    break;
                case 21:
                    m.inputType = r.int32();
                    break;
                case 23:
                    if (!(m.groupList && m.groupList.length))
                        m.groupList = [];
                    m.groupList.push($root.Proto.CompanyUserGroup.decode(r, r.uint32()));
                    break;
                case 25:
                    m.existCount = r.string();
                    break;
                case 26:
                    m.main = r.bool();
                    break;
                case 27:
                    if (!(m.contentMapList && m.contentMapList.length))
                        m.contentMapList = [];
                    m.contentMapList.push($root.Proto.ContentWorkflowMap.decode(r, r.uint32()));
                    break;
                case 28:
                    m.targetContentUID = r.string();
                    break;
                case 29:
                    m.name = r.string();
                    break;
                case 30:
                    m.enableStatus = r.bool();
                    break;
                case 31:
                    if (!(m.statusList && m.statusList.length))
                        m.statusList = [];
                    m.statusList.push($root.Proto.WorkflowBuilderStatus.decode(r, r.uint32()));
                    break;
                case 32:
                    m.remindType = r.int32();
                    break;
                case 33:
                    m.remindByEnd = r.bool();
                    break;
                case 34:
                    m.remindWeekday = r.int32();
                    break;
                case 35:
                    m.remindDay = r.int32();
                    break;
                case 36:
                    m.remindTime = r.string();
                    break;
                case 37:
                    m.remindTimezone = r.int32();
                    break;
                case 38:
                    m.remindText = r.string();
                    break;
                case 39:
                    m.statusType = r.int32();
                    break;
                case 40:
                    m.remindWeekdays = r.string();
                    break;
                case 41:
                    m.useFileTemplate = r.bool();
                    break;
                case 42:
                    m.fileTemplate = r.string();
                    break;
                case 43:
                    if (!(m.planUserList && m.planUserList.length))
                        m.planUserList = [];
                    m.planUserList.push($root.Proto.User.decode(r, r.uint32()));
                    break;
                case 44:
                    m.tvod = $root.Proto.WorkflowBuilderTVOD.decode(r, r.uint32());
                    break;
                case 45:
                    m.needToPay = r.int32();
                    break;
                case 46:
                    if (!(m.filePayTypes && m.filePayTypes.length))
                        m.filePayTypes = [];
                    if ((t & 7) === 2) {
                        var c2 = r.uint32() + r.pos;
                        while (r.pos < c2)
                            m.filePayTypes.push(r.int32());
                    } else
                        m.filePayTypes.push(r.int32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilder message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilder
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilder} WorkflowBuilder
         */
        WorkflowBuilder.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilder)
                return d;
            var m = new $root.Proto.WorkflowBuilder();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.companyUID != null) {
                m.companyUID = String(d.companyUID);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.subtitle != null) {
                m.subtitle = String(d.subtitle);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.distribution != null) {
                m.distribution = d.distribution | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.formInfo != null) {
                m.formInfo = String(d.formInfo);
            }
            if (d.enableMultipleInput != null) {
                m.enableMultipleInput = Boolean(d.enableMultipleInput);
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.WorkflowBuilder.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.listImage != null) {
                if (typeof d.listImage !== "object")
                    throw TypeError(".Proto.WorkflowBuilder.listImage: object expected");
                m.listImage = $root.Proto.UploadFile.fromObject(d.listImage);
            }
            if (d.listImageOrder != null) {
                m.listImageOrder = String(d.listImageOrder);
            }
            if (d.plan != null) {
                m.plan = String(d.plan);
            }
            if (d.formList) {
                if (!Array.isArray(d.formList))
                    throw TypeError(".Proto.WorkflowBuilder.formList: array expected");
                m.formList = [];
                for (var i = 0; i < d.formList.length; ++i) {
                    if (typeof d.formList[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilder.formList: object expected");
                    m.formList[i] = $root.Proto.WorkflowBuilderFormInfo.fromObject(d.formList[i]);
                }
            }
            if (d.answerCount != null) {
                m.answerCount = String(d.answerCount);
            }
            if (d.adminTagList) {
                if (!Array.isArray(d.adminTagList))
                    throw TypeError(".Proto.WorkflowBuilder.adminTagList: array expected");
                m.adminTagList = [];
                for (var i = 0; i < d.adminTagList.length; ++i) {
                    if (typeof d.adminTagList[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilder.adminTagList: object expected");
                    m.adminTagList[i] = $root.Proto.HashTag.fromObject(d.adminTagList[i]);
                }
            }
            if (d.inputType != null) {
                m.inputType = d.inputType | 0;
            }
            if (d.groupList) {
                if (!Array.isArray(d.groupList))
                    throw TypeError(".Proto.WorkflowBuilder.groupList: array expected");
                m.groupList = [];
                for (var i = 0; i < d.groupList.length; ++i) {
                    if (typeof d.groupList[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilder.groupList: object expected");
                    m.groupList[i] = $root.Proto.CompanyUserGroup.fromObject(d.groupList[i]);
                }
            }
            if (d.existCount != null) {
                m.existCount = String(d.existCount);
            }
            if (d.main != null) {
                m.main = Boolean(d.main);
            }
            if (d.contentMapList) {
                if (!Array.isArray(d.contentMapList))
                    throw TypeError(".Proto.WorkflowBuilder.contentMapList: array expected");
                m.contentMapList = [];
                for (var i = 0; i < d.contentMapList.length; ++i) {
                    if (typeof d.contentMapList[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilder.contentMapList: object expected");
                    m.contentMapList[i] = $root.Proto.ContentWorkflowMap.fromObject(d.contentMapList[i]);
                }
            }
            if (d.targetContentUID != null) {
                m.targetContentUID = String(d.targetContentUID);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.enableStatus != null) {
                m.enableStatus = Boolean(d.enableStatus);
            }
            if (d.statusList) {
                if (!Array.isArray(d.statusList))
                    throw TypeError(".Proto.WorkflowBuilder.statusList: array expected");
                m.statusList = [];
                for (var i = 0; i < d.statusList.length; ++i) {
                    if (typeof d.statusList[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilder.statusList: object expected");
                    m.statusList[i] = $root.Proto.WorkflowBuilderStatus.fromObject(d.statusList[i]);
                }
            }
            if (d.remindType != null) {
                m.remindType = d.remindType | 0;
            }
            if (d.remindByEnd != null) {
                m.remindByEnd = Boolean(d.remindByEnd);
            }
            if (d.remindWeekday != null) {
                m.remindWeekday = d.remindWeekday | 0;
            }
            if (d.remindDay != null) {
                m.remindDay = d.remindDay | 0;
            }
            if (d.remindTime != null) {
                m.remindTime = String(d.remindTime);
            }
            if (d.remindTimezone != null) {
                m.remindTimezone = d.remindTimezone | 0;
            }
            if (d.remindText != null) {
                m.remindText = String(d.remindText);
            }
            if (d.statusType != null) {
                m.statusType = d.statusType | 0;
            }
            if (d.remindWeekdays != null) {
                m.remindWeekdays = String(d.remindWeekdays);
            }
            if (d.useFileTemplate != null) {
                m.useFileTemplate = Boolean(d.useFileTemplate);
            }
            if (d.fileTemplate != null) {
                m.fileTemplate = String(d.fileTemplate);
            }
            if (d.planUserList) {
                if (!Array.isArray(d.planUserList))
                    throw TypeError(".Proto.WorkflowBuilder.planUserList: array expected");
                m.planUserList = [];
                for (var i = 0; i < d.planUserList.length; ++i) {
                    if (typeof d.planUserList[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilder.planUserList: object expected");
                    m.planUserList[i] = $root.Proto.User.fromObject(d.planUserList[i]);
                }
            }
            if (d.tvod != null) {
                if (typeof d.tvod !== "object")
                    throw TypeError(".Proto.WorkflowBuilder.tvod: object expected");
                m.tvod = $root.Proto.WorkflowBuilderTVOD.fromObject(d.tvod);
            }
            if (d.needToPay != null) {
                m.needToPay = d.needToPay | 0;
            }
            if (d.filePayTypes) {
                if (!Array.isArray(d.filePayTypes))
                    throw TypeError(".Proto.WorkflowBuilder.filePayTypes: array expected");
                m.filePayTypes = [];
                for (var i = 0; i < d.filePayTypes.length; ++i) {
                    m.filePayTypes[i] = d.filePayTypes[i] | 0;
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilder message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilder
         * @static
         * @param {Proto.WorkflowBuilder} m WorkflowBuilder
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilder.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.formList = [];
                d.adminTagList = [];
                d.groupList = [];
                d.contentMapList = [];
                d.statusList = [];
                d.planUserList = [];
                d.filePayTypes = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.companyUID = "";
                d.title = "";
                d.subtitle = "";
                d.description = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                d.distribution = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.formInfo = "";
                d.enableMultipleInput = false;
                d.image = null;
                d.listImage = null;
                d.listImageOrder = "";
                d.plan = "";
                d.answerCount = "";
                d.inputType = 0;
                d.existCount = "";
                d.main = false;
                d.targetContentUID = "";
                d.name = "";
                d.enableStatus = false;
                d.remindType = 0;
                d.remindByEnd = false;
                d.remindWeekday = 0;
                d.remindDay = 0;
                d.remindTime = "";
                d.remindTimezone = 0;
                d.remindText = "";
                d.statusType = 0;
                d.remindWeekdays = "";
                d.useFileTemplate = false;
                d.fileTemplate = "";
                d.tvod = null;
                d.needToPay = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.companyUID != null && m.hasOwnProperty("companyUID")) {
                d.companyUID = m.companyUID;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.subtitle != null && m.hasOwnProperty("subtitle")) {
                d.subtitle = m.subtitle;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.distribution != null && m.hasOwnProperty("distribution")) {
                d.distribution = m.distribution;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.formInfo != null && m.hasOwnProperty("formInfo")) {
                d.formInfo = m.formInfo;
            }
            if (m.enableMultipleInput != null && m.hasOwnProperty("enableMultipleInput")) {
                d.enableMultipleInput = m.enableMultipleInput;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.listImage != null && m.hasOwnProperty("listImage")) {
                d.listImage = $root.Proto.UploadFile.toObject(m.listImage, o);
            }
            if (m.listImageOrder != null && m.hasOwnProperty("listImageOrder")) {
                d.listImageOrder = m.listImageOrder;
            }
            if (m.plan != null && m.hasOwnProperty("plan")) {
                d.plan = m.plan;
            }
            if (m.formList && m.formList.length) {
                d.formList = [];
                for (var j = 0; j < m.formList.length; ++j) {
                    d.formList[j] = $root.Proto.WorkflowBuilderFormInfo.toObject(m.formList[j], o);
                }
            }
            if (m.answerCount != null && m.hasOwnProperty("answerCount")) {
                d.answerCount = m.answerCount;
            }
            if (m.adminTagList && m.adminTagList.length) {
                d.adminTagList = [];
                for (var j = 0; j < m.adminTagList.length; ++j) {
                    d.adminTagList[j] = $root.Proto.HashTag.toObject(m.adminTagList[j], o);
                }
            }
            if (m.inputType != null && m.hasOwnProperty("inputType")) {
                d.inputType = m.inputType;
            }
            if (m.groupList && m.groupList.length) {
                d.groupList = [];
                for (var j = 0; j < m.groupList.length; ++j) {
                    d.groupList[j] = $root.Proto.CompanyUserGroup.toObject(m.groupList[j], o);
                }
            }
            if (m.existCount != null && m.hasOwnProperty("existCount")) {
                d.existCount = m.existCount;
            }
            if (m.main != null && m.hasOwnProperty("main")) {
                d.main = m.main;
            }
            if (m.contentMapList && m.contentMapList.length) {
                d.contentMapList = [];
                for (var j = 0; j < m.contentMapList.length; ++j) {
                    d.contentMapList[j] = $root.Proto.ContentWorkflowMap.toObject(m.contentMapList[j], o);
                }
            }
            if (m.targetContentUID != null && m.hasOwnProperty("targetContentUID")) {
                d.targetContentUID = m.targetContentUID;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.enableStatus != null && m.hasOwnProperty("enableStatus")) {
                d.enableStatus = m.enableStatus;
            }
            if (m.statusList && m.statusList.length) {
                d.statusList = [];
                for (var j = 0; j < m.statusList.length; ++j) {
                    d.statusList[j] = $root.Proto.WorkflowBuilderStatus.toObject(m.statusList[j], o);
                }
            }
            if (m.remindType != null && m.hasOwnProperty("remindType")) {
                d.remindType = m.remindType;
            }
            if (m.remindByEnd != null && m.hasOwnProperty("remindByEnd")) {
                d.remindByEnd = m.remindByEnd;
            }
            if (m.remindWeekday != null && m.hasOwnProperty("remindWeekday")) {
                d.remindWeekday = m.remindWeekday;
            }
            if (m.remindDay != null && m.hasOwnProperty("remindDay")) {
                d.remindDay = m.remindDay;
            }
            if (m.remindTime != null && m.hasOwnProperty("remindTime")) {
                d.remindTime = m.remindTime;
            }
            if (m.remindTimezone != null && m.hasOwnProperty("remindTimezone")) {
                d.remindTimezone = m.remindTimezone;
            }
            if (m.remindText != null && m.hasOwnProperty("remindText")) {
                d.remindText = m.remindText;
            }
            if (m.statusType != null && m.hasOwnProperty("statusType")) {
                d.statusType = m.statusType;
            }
            if (m.remindWeekdays != null && m.hasOwnProperty("remindWeekdays")) {
                d.remindWeekdays = m.remindWeekdays;
            }
            if (m.useFileTemplate != null && m.hasOwnProperty("useFileTemplate")) {
                d.useFileTemplate = m.useFileTemplate;
            }
            if (m.fileTemplate != null && m.hasOwnProperty("fileTemplate")) {
                d.fileTemplate = m.fileTemplate;
            }
            if (m.planUserList && m.planUserList.length) {
                d.planUserList = [];
                for (var j = 0; j < m.planUserList.length; ++j) {
                    d.planUserList[j] = $root.Proto.User.toObject(m.planUserList[j], o);
                }
            }
            if (m.tvod != null && m.hasOwnProperty("tvod")) {
                d.tvod = $root.Proto.WorkflowBuilderTVOD.toObject(m.tvod, o);
            }
            if (m.needToPay != null && m.hasOwnProperty("needToPay")) {
                d.needToPay = m.needToPay;
            }
            if (m.filePayTypes && m.filePayTypes.length) {
                d.filePayTypes = [];
                for (var j = 0; j < m.filePayTypes.length; ++j) {
                    d.filePayTypes[j] = m.filePayTypes[j];
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilder to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilder
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilder.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilder;
    })();

    Proto.WorkflowBuilderList = (function() {

        /**
         * Properties of a WorkflowBuilderList.
         * @memberof Proto
         * @interface IWorkflowBuilderList
         * @property {Array.<Proto.IWorkflowBuilder>|null} [value] WorkflowBuilderList value
         */

        /**
         * Constructs a new WorkflowBuilderList.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilderList.
         * @implements IWorkflowBuilderList
         * @constructor
         * @param {Proto.IWorkflowBuilderList=} [p] Properties to set
         */
        function WorkflowBuilderList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilderList value.
         * @member {Array.<Proto.IWorkflowBuilder>} value
         * @memberof Proto.WorkflowBuilderList
         * @instance
         */
        WorkflowBuilderList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkflowBuilderList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilderList
         * @static
         * @param {Proto.IWorkflowBuilderList=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilderList} WorkflowBuilderList instance
         */
        WorkflowBuilderList.create = function create(properties) {
            return new WorkflowBuilderList(properties);
        };

        /**
         * Encodes the specified WorkflowBuilderList message. Does not implicitly {@link Proto.WorkflowBuilderList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilderList
         * @static
         * @param {Proto.IWorkflowBuilderList} m WorkflowBuilderList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilderList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkflowBuilder.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowBuilderList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilderList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilderList} WorkflowBuilderList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilderList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilderList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkflowBuilder.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilderList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilderList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilderList} WorkflowBuilderList
         */
        WorkflowBuilderList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilderList)
                return d;
            var m = new $root.Proto.WorkflowBuilderList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkflowBuilderList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilderList.value: object expected");
                    m.value[i] = $root.Proto.WorkflowBuilder.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilderList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilderList
         * @static
         * @param {Proto.WorkflowBuilderList} m WorkflowBuilderList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilderList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkflowBuilder.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilderList to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilderList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilderList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilderList;
    })();

    Proto.WorkflowBuilderFormInfo = (function() {

        /**
         * Properties of a WorkflowBuilderFormInfo.
         * @memberof Proto
         * @interface IWorkflowBuilderFormInfo
         * @property {string|null} [uid] WorkflowBuilderFormInfo uid
         * @property {number|null} [type] WorkflowBuilderFormInfo type
         * @property {string|null} [name] WorkflowBuilderFormInfo name
         * @property {boolean|null} [optional] WorkflowBuilderFormInfo optional
         * @property {string|null} [setting] WorkflowBuilderFormInfo setting
         * @property {number|null} [order] WorkflowBuilderFormInfo order
         * @property {number|Long|null} [registerDate] WorkflowBuilderFormInfo registerDate
         * @property {number|Long|null} [modifyDate] WorkflowBuilderFormInfo modifyDate
         * @property {number|null} [status] WorkflowBuilderFormInfo status
         * @property {boolean|null} [preview] WorkflowBuilderFormInfo preview
         * @property {boolean|null} [editable] WorkflowBuilderFormInfo editable
         * @property {Proto.IWorkflowParams|null} [params] WorkflowBuilderFormInfo params
         */

        /**
         * Constructs a new WorkflowBuilderFormInfo.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilderFormInfo.
         * @implements IWorkflowBuilderFormInfo
         * @constructor
         * @param {Proto.IWorkflowBuilderFormInfo=} [p] Properties to set
         */
        function WorkflowBuilderFormInfo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilderFormInfo uid.
         * @member {string} uid
         * @memberof Proto.WorkflowBuilderFormInfo
         * @instance
         */
        WorkflowBuilderFormInfo.prototype.uid = "";

        /**
         * WorkflowBuilderFormInfo type.
         * @member {number} type
         * @memberof Proto.WorkflowBuilderFormInfo
         * @instance
         */
        WorkflowBuilderFormInfo.prototype.type = 0;

        /**
         * WorkflowBuilderFormInfo name.
         * @member {string} name
         * @memberof Proto.WorkflowBuilderFormInfo
         * @instance
         */
        WorkflowBuilderFormInfo.prototype.name = "";

        /**
         * WorkflowBuilderFormInfo optional.
         * @member {boolean} optional
         * @memberof Proto.WorkflowBuilderFormInfo
         * @instance
         */
        WorkflowBuilderFormInfo.prototype.optional = false;

        /**
         * WorkflowBuilderFormInfo setting.
         * @member {string} setting
         * @memberof Proto.WorkflowBuilderFormInfo
         * @instance
         */
        WorkflowBuilderFormInfo.prototype.setting = "";

        /**
         * WorkflowBuilderFormInfo order.
         * @member {number} order
         * @memberof Proto.WorkflowBuilderFormInfo
         * @instance
         */
        WorkflowBuilderFormInfo.prototype.order = 0;

        /**
         * WorkflowBuilderFormInfo registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkflowBuilderFormInfo
         * @instance
         */
        WorkflowBuilderFormInfo.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderFormInfo modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkflowBuilderFormInfo
         * @instance
         */
        WorkflowBuilderFormInfo.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderFormInfo status.
         * @member {number} status
         * @memberof Proto.WorkflowBuilderFormInfo
         * @instance
         */
        WorkflowBuilderFormInfo.prototype.status = 0;

        /**
         * WorkflowBuilderFormInfo preview.
         * @member {boolean} preview
         * @memberof Proto.WorkflowBuilderFormInfo
         * @instance
         */
        WorkflowBuilderFormInfo.prototype.preview = false;

        /**
         * WorkflowBuilderFormInfo editable.
         * @member {boolean} editable
         * @memberof Proto.WorkflowBuilderFormInfo
         * @instance
         */
        WorkflowBuilderFormInfo.prototype.editable = false;

        /**
         * WorkflowBuilderFormInfo params.
         * @member {Proto.IWorkflowParams|null|undefined} params
         * @memberof Proto.WorkflowBuilderFormInfo
         * @instance
         */
        WorkflowBuilderFormInfo.prototype.params = null;

        /**
         * Creates a new WorkflowBuilderFormInfo instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilderFormInfo
         * @static
         * @param {Proto.IWorkflowBuilderFormInfo=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilderFormInfo} WorkflowBuilderFormInfo instance
         */
        WorkflowBuilderFormInfo.create = function create(properties) {
            return new WorkflowBuilderFormInfo(properties);
        };

        /**
         * Encodes the specified WorkflowBuilderFormInfo message. Does not implicitly {@link Proto.WorkflowBuilderFormInfo.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilderFormInfo
         * @static
         * @param {Proto.IWorkflowBuilderFormInfo} m WorkflowBuilderFormInfo message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilderFormInfo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.optional != null && Object.hasOwnProperty.call(m, "optional"))
                w.uint32(32).bool(m.optional);
            if (m.setting != null && Object.hasOwnProperty.call(m, "setting"))
                w.uint32(42).string(m.setting);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(48).int32(m.order);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(56).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(64).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(72).int32(m.status);
            if (m.preview != null && Object.hasOwnProperty.call(m, "preview"))
                w.uint32(80).bool(m.preview);
            if (m.editable != null && Object.hasOwnProperty.call(m, "editable"))
                w.uint32(88).bool(m.editable);
            if (m.params != null && Object.hasOwnProperty.call(m, "params"))
                $root.Proto.WorkflowParams.encode(m.params, w.uint32(98).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a WorkflowBuilderFormInfo message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilderFormInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilderFormInfo} WorkflowBuilderFormInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilderFormInfo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilderFormInfo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.optional = r.bool();
                    break;
                case 5:
                    m.setting = r.string();
                    break;
                case 6:
                    m.order = r.int32();
                    break;
                case 7:
                    m.registerDate = r.int64();
                    break;
                case 8:
                    m.modifyDate = r.int64();
                    break;
                case 9:
                    m.status = r.int32();
                    break;
                case 10:
                    m.preview = r.bool();
                    break;
                case 11:
                    m.editable = r.bool();
                    break;
                case 12:
                    m.params = $root.Proto.WorkflowParams.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilderFormInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilderFormInfo
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilderFormInfo} WorkflowBuilderFormInfo
         */
        WorkflowBuilderFormInfo.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilderFormInfo)
                return d;
            var m = new $root.Proto.WorkflowBuilderFormInfo();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.optional != null) {
                m.optional = Boolean(d.optional);
            }
            if (d.setting != null) {
                m.setting = String(d.setting);
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.preview != null) {
                m.preview = Boolean(d.preview);
            }
            if (d.editable != null) {
                m.editable = Boolean(d.editable);
            }
            if (d.params != null) {
                if (typeof d.params !== "object")
                    throw TypeError(".Proto.WorkflowBuilderFormInfo.params: object expected");
                m.params = $root.Proto.WorkflowParams.fromObject(d.params);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilderFormInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilderFormInfo
         * @static
         * @param {Proto.WorkflowBuilderFormInfo} m WorkflowBuilderFormInfo
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilderFormInfo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.name = "";
                d.optional = false;
                d.setting = "";
                d.order = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.preview = false;
                d.editable = false;
                d.params = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.optional != null && m.hasOwnProperty("optional")) {
                d.optional = m.optional;
            }
            if (m.setting != null && m.hasOwnProperty("setting")) {
                d.setting = m.setting;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.preview != null && m.hasOwnProperty("preview")) {
                d.preview = m.preview;
            }
            if (m.editable != null && m.hasOwnProperty("editable")) {
                d.editable = m.editable;
            }
            if (m.params != null && m.hasOwnProperty("params")) {
                d.params = $root.Proto.WorkflowParams.toObject(m.params, o);
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilderFormInfo to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilderFormInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilderFormInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilderFormInfo;
    })();

    Proto.WorkflowBuilderFormInfoList = (function() {

        /**
         * Properties of a WorkflowBuilderFormInfoList.
         * @memberof Proto
         * @interface IWorkflowBuilderFormInfoList
         * @property {Array.<Proto.IWorkflowBuilderFormInfo>|null} [value] WorkflowBuilderFormInfoList value
         */

        /**
         * Constructs a new WorkflowBuilderFormInfoList.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilderFormInfoList.
         * @implements IWorkflowBuilderFormInfoList
         * @constructor
         * @param {Proto.IWorkflowBuilderFormInfoList=} [p] Properties to set
         */
        function WorkflowBuilderFormInfoList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilderFormInfoList value.
         * @member {Array.<Proto.IWorkflowBuilderFormInfo>} value
         * @memberof Proto.WorkflowBuilderFormInfoList
         * @instance
         */
        WorkflowBuilderFormInfoList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkflowBuilderFormInfoList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilderFormInfoList
         * @static
         * @param {Proto.IWorkflowBuilderFormInfoList=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilderFormInfoList} WorkflowBuilderFormInfoList instance
         */
        WorkflowBuilderFormInfoList.create = function create(properties) {
            return new WorkflowBuilderFormInfoList(properties);
        };

        /**
         * Encodes the specified WorkflowBuilderFormInfoList message. Does not implicitly {@link Proto.WorkflowBuilderFormInfoList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilderFormInfoList
         * @static
         * @param {Proto.IWorkflowBuilderFormInfoList} m WorkflowBuilderFormInfoList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilderFormInfoList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkflowBuilderFormInfo.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowBuilderFormInfoList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilderFormInfoList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilderFormInfoList} WorkflowBuilderFormInfoList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilderFormInfoList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilderFormInfoList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkflowBuilderFormInfo.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilderFormInfoList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilderFormInfoList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilderFormInfoList} WorkflowBuilderFormInfoList
         */
        WorkflowBuilderFormInfoList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilderFormInfoList)
                return d;
            var m = new $root.Proto.WorkflowBuilderFormInfoList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkflowBuilderFormInfoList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilderFormInfoList.value: object expected");
                    m.value[i] = $root.Proto.WorkflowBuilderFormInfo.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilderFormInfoList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilderFormInfoList
         * @static
         * @param {Proto.WorkflowBuilderFormInfoList} m WorkflowBuilderFormInfoList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilderFormInfoList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkflowBuilderFormInfo.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilderFormInfoList to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilderFormInfoList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilderFormInfoList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilderFormInfoList;
    })();

    Proto.WorkflowBuilderStatus = (function() {

        /**
         * Properties of a WorkflowBuilderStatus.
         * @memberof Proto
         * @interface IWorkflowBuilderStatus
         * @property {string|null} [uid] WorkflowBuilderStatus uid
         * @property {string|null} [name] WorkflowBuilderStatus name
         * @property {number|null} [allowType] WorkflowBuilderStatus allowType
         * @property {string|null} [userUidList] WorkflowBuilderStatus userUidList
         * @property {number|null} [order] WorkflowBuilderStatus order
         * @property {number|Long|null} [registerDate] WorkflowBuilderStatus registerDate
         * @property {number|Long|null} [modifyDate] WorkflowBuilderStatus modifyDate
         * @property {number|null} [status] WorkflowBuilderStatus status
         * @property {boolean|null} [allow] WorkflowBuilderStatus allow
         * @property {number|null} [sequence] WorkflowBuilderStatus sequence
         * @property {number|null} [allowType2] WorkflowBuilderStatus allowType2
         * @property {string|null} [userUidList2] WorkflowBuilderStatus userUidList2
         * @property {string|null} [description] WorkflowBuilderStatus description
         * @property {number|null} [sequenceType] WorkflowBuilderStatus sequenceType
         * @property {number|null} [notificationType] WorkflowBuilderStatus notificationType
         * @property {boolean|null} [editable] WorkflowBuilderStatus editable
         * @property {string|null} [planUidList] WorkflowBuilderStatus planUidList
         * @property {string|null} [planUidList2] WorkflowBuilderStatus planUidList2
         * @property {boolean|null} [fileEditable] WorkflowBuilderStatus fileEditable
         */

        /**
         * Constructs a new WorkflowBuilderStatus.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilderStatus.
         * @implements IWorkflowBuilderStatus
         * @constructor
         * @param {Proto.IWorkflowBuilderStatus=} [p] Properties to set
         */
        function WorkflowBuilderStatus(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilderStatus uid.
         * @member {string} uid
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.uid = "";

        /**
         * WorkflowBuilderStatus name.
         * @member {string} name
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.name = "";

        /**
         * WorkflowBuilderStatus allowType.
         * @member {number} allowType
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.allowType = 0;

        /**
         * WorkflowBuilderStatus userUidList.
         * @member {string} userUidList
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.userUidList = "";

        /**
         * WorkflowBuilderStatus order.
         * @member {number} order
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.order = 0;

        /**
         * WorkflowBuilderStatus registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderStatus modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderStatus status.
         * @member {number} status
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.status = 0;

        /**
         * WorkflowBuilderStatus allow.
         * @member {boolean} allow
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.allow = false;

        /**
         * WorkflowBuilderStatus sequence.
         * @member {number} sequence
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.sequence = 0;

        /**
         * WorkflowBuilderStatus allowType2.
         * @member {number} allowType2
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.allowType2 = 0;

        /**
         * WorkflowBuilderStatus userUidList2.
         * @member {string} userUidList2
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.userUidList2 = "";

        /**
         * WorkflowBuilderStatus description.
         * @member {string} description
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.description = "";

        /**
         * WorkflowBuilderStatus sequenceType.
         * @member {number} sequenceType
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.sequenceType = 0;

        /**
         * WorkflowBuilderStatus notificationType.
         * @member {number} notificationType
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.notificationType = 0;

        /**
         * WorkflowBuilderStatus editable.
         * @member {boolean} editable
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.editable = false;

        /**
         * WorkflowBuilderStatus planUidList.
         * @member {string} planUidList
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.planUidList = "";

        /**
         * WorkflowBuilderStatus planUidList2.
         * @member {string} planUidList2
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.planUidList2 = "";

        /**
         * WorkflowBuilderStatus fileEditable.
         * @member {boolean} fileEditable
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         */
        WorkflowBuilderStatus.prototype.fileEditable = false;

        /**
         * Creates a new WorkflowBuilderStatus instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilderStatus
         * @static
         * @param {Proto.IWorkflowBuilderStatus=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilderStatus} WorkflowBuilderStatus instance
         */
        WorkflowBuilderStatus.create = function create(properties) {
            return new WorkflowBuilderStatus(properties);
        };

        /**
         * Encodes the specified WorkflowBuilderStatus message. Does not implicitly {@link Proto.WorkflowBuilderStatus.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilderStatus
         * @static
         * @param {Proto.IWorkflowBuilderStatus} m WorkflowBuilderStatus message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilderStatus.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.allowType != null && Object.hasOwnProperty.call(m, "allowType"))
                w.uint32(24).int32(m.allowType);
            if (m.userUidList != null && Object.hasOwnProperty.call(m, "userUidList"))
                w.uint32(34).string(m.userUidList);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(40).int32(m.order);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(64).int32(m.status);
            if (m.allow != null && Object.hasOwnProperty.call(m, "allow"))
                w.uint32(72).bool(m.allow);
            if (m.sequence != null && Object.hasOwnProperty.call(m, "sequence"))
                w.uint32(80).int32(m.sequence);
            if (m.allowType2 != null && Object.hasOwnProperty.call(m, "allowType2"))
                w.uint32(88).int32(m.allowType2);
            if (m.userUidList2 != null && Object.hasOwnProperty.call(m, "userUidList2"))
                w.uint32(98).string(m.userUidList2);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(106).string(m.description);
            if (m.sequenceType != null && Object.hasOwnProperty.call(m, "sequenceType"))
                w.uint32(112).int32(m.sequenceType);
            if (m.notificationType != null && Object.hasOwnProperty.call(m, "notificationType"))
                w.uint32(120).int32(m.notificationType);
            if (m.editable != null && Object.hasOwnProperty.call(m, "editable"))
                w.uint32(128).bool(m.editable);
            if (m.planUidList != null && Object.hasOwnProperty.call(m, "planUidList"))
                w.uint32(138).string(m.planUidList);
            if (m.planUidList2 != null && Object.hasOwnProperty.call(m, "planUidList2"))
                w.uint32(146).string(m.planUidList2);
            if (m.fileEditable != null && Object.hasOwnProperty.call(m, "fileEditable"))
                w.uint32(152).bool(m.fileEditable);
            return w;
        };

        /**
         * Decodes a WorkflowBuilderStatus message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilderStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilderStatus} WorkflowBuilderStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilderStatus.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilderStatus();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.name = r.string();
                    break;
                case 3:
                    m.allowType = r.int32();
                    break;
                case 4:
                    m.userUidList = r.string();
                    break;
                case 5:
                    m.order = r.int32();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.status = r.int32();
                    break;
                case 9:
                    m.allow = r.bool();
                    break;
                case 10:
                    m.sequence = r.int32();
                    break;
                case 11:
                    m.allowType2 = r.int32();
                    break;
                case 12:
                    m.userUidList2 = r.string();
                    break;
                case 13:
                    m.description = r.string();
                    break;
                case 14:
                    m.sequenceType = r.int32();
                    break;
                case 15:
                    m.notificationType = r.int32();
                    break;
                case 16:
                    m.editable = r.bool();
                    break;
                case 17:
                    m.planUidList = r.string();
                    break;
                case 18:
                    m.planUidList2 = r.string();
                    break;
                case 19:
                    m.fileEditable = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilderStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilderStatus
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilderStatus} WorkflowBuilderStatus
         */
        WorkflowBuilderStatus.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilderStatus)
                return d;
            var m = new $root.Proto.WorkflowBuilderStatus();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.allowType != null) {
                m.allowType = d.allowType | 0;
            }
            if (d.userUidList != null) {
                m.userUidList = String(d.userUidList);
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.allow != null) {
                m.allow = Boolean(d.allow);
            }
            if (d.sequence != null) {
                m.sequence = d.sequence | 0;
            }
            if (d.allowType2 != null) {
                m.allowType2 = d.allowType2 | 0;
            }
            if (d.userUidList2 != null) {
                m.userUidList2 = String(d.userUidList2);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.sequenceType != null) {
                m.sequenceType = d.sequenceType | 0;
            }
            if (d.notificationType != null) {
                m.notificationType = d.notificationType | 0;
            }
            if (d.editable != null) {
                m.editable = Boolean(d.editable);
            }
            if (d.planUidList != null) {
                m.planUidList = String(d.planUidList);
            }
            if (d.planUidList2 != null) {
                m.planUidList2 = String(d.planUidList2);
            }
            if (d.fileEditable != null) {
                m.fileEditable = Boolean(d.fileEditable);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilderStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilderStatus
         * @static
         * @param {Proto.WorkflowBuilderStatus} m WorkflowBuilderStatus
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilderStatus.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.name = "";
                d.allowType = 0;
                d.userUidList = "";
                d.order = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.allow = false;
                d.sequence = 0;
                d.allowType2 = 0;
                d.userUidList2 = "";
                d.description = "";
                d.sequenceType = 0;
                d.notificationType = 0;
                d.editable = false;
                d.planUidList = "";
                d.planUidList2 = "";
                d.fileEditable = false;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.allowType != null && m.hasOwnProperty("allowType")) {
                d.allowType = m.allowType;
            }
            if (m.userUidList != null && m.hasOwnProperty("userUidList")) {
                d.userUidList = m.userUidList;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.allow != null && m.hasOwnProperty("allow")) {
                d.allow = m.allow;
            }
            if (m.sequence != null && m.hasOwnProperty("sequence")) {
                d.sequence = m.sequence;
            }
            if (m.allowType2 != null && m.hasOwnProperty("allowType2")) {
                d.allowType2 = m.allowType2;
            }
            if (m.userUidList2 != null && m.hasOwnProperty("userUidList2")) {
                d.userUidList2 = m.userUidList2;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.sequenceType != null && m.hasOwnProperty("sequenceType")) {
                d.sequenceType = m.sequenceType;
            }
            if (m.notificationType != null && m.hasOwnProperty("notificationType")) {
                d.notificationType = m.notificationType;
            }
            if (m.editable != null && m.hasOwnProperty("editable")) {
                d.editable = m.editable;
            }
            if (m.planUidList != null && m.hasOwnProperty("planUidList")) {
                d.planUidList = m.planUidList;
            }
            if (m.planUidList2 != null && m.hasOwnProperty("planUidList2")) {
                d.planUidList2 = m.planUidList2;
            }
            if (m.fileEditable != null && m.hasOwnProperty("fileEditable")) {
                d.fileEditable = m.fileEditable;
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilderStatus to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilderStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilderStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilderStatus;
    })();

    Proto.WorkflowBuilderStatusList = (function() {

        /**
         * Properties of a WorkflowBuilderStatusList.
         * @memberof Proto
         * @interface IWorkflowBuilderStatusList
         * @property {Array.<Proto.IWorkflowBuilderStatus>|null} [value] WorkflowBuilderStatusList value
         */

        /**
         * Constructs a new WorkflowBuilderStatusList.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilderStatusList.
         * @implements IWorkflowBuilderStatusList
         * @constructor
         * @param {Proto.IWorkflowBuilderStatusList=} [p] Properties to set
         */
        function WorkflowBuilderStatusList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilderStatusList value.
         * @member {Array.<Proto.IWorkflowBuilderStatus>} value
         * @memberof Proto.WorkflowBuilderStatusList
         * @instance
         */
        WorkflowBuilderStatusList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkflowBuilderStatusList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilderStatusList
         * @static
         * @param {Proto.IWorkflowBuilderStatusList=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilderStatusList} WorkflowBuilderStatusList instance
         */
        WorkflowBuilderStatusList.create = function create(properties) {
            return new WorkflowBuilderStatusList(properties);
        };

        /**
         * Encodes the specified WorkflowBuilderStatusList message. Does not implicitly {@link Proto.WorkflowBuilderStatusList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilderStatusList
         * @static
         * @param {Proto.IWorkflowBuilderStatusList} m WorkflowBuilderStatusList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilderStatusList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkflowBuilderStatus.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowBuilderStatusList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilderStatusList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilderStatusList} WorkflowBuilderStatusList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilderStatusList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilderStatusList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkflowBuilderStatus.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilderStatusList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilderStatusList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilderStatusList} WorkflowBuilderStatusList
         */
        WorkflowBuilderStatusList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilderStatusList)
                return d;
            var m = new $root.Proto.WorkflowBuilderStatusList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkflowBuilderStatusList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilderStatusList.value: object expected");
                    m.value[i] = $root.Proto.WorkflowBuilderStatus.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilderStatusList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilderStatusList
         * @static
         * @param {Proto.WorkflowBuilderStatusList} m WorkflowBuilderStatusList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilderStatusList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkflowBuilderStatus.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilderStatusList to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilderStatusList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilderStatusList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilderStatusList;
    })();

    Proto.WorkflowBuilderAnswerUserInfo = (function() {

        /**
         * Properties of a WorkflowBuilderAnswerUserInfo.
         * @memberof Proto
         * @interface IWorkflowBuilderAnswerUserInfo
         * @property {string|null} [userUID] WorkflowBuilderAnswerUserInfo userUID
         * @property {string|null} [infoUID] WorkflowBuilderAnswerUserInfo infoUID
         * @property {string|null} [text] WorkflowBuilderAnswerUserInfo text
         * @property {number|null} [number] WorkflowBuilderAnswerUserInfo number
         * @property {number|Long|null} [date] WorkflowBuilderAnswerUserInfo date
         * @property {number|Long|null} [registerDate] WorkflowBuilderAnswerUserInfo registerDate
         * @property {number|Long|null} [modifyDate] WorkflowBuilderAnswerUserInfo modifyDate
         * @property {Proto.IUploadFile|null} [file] WorkflowBuilderAnswerUserInfo file
         * @property {string|null} [additional] WorkflowBuilderAnswerUserInfo additional
         * @property {boolean|null} [readonly] WorkflowBuilderAnswerUserInfo readonly
         * @property {string|null} [json] WorkflowBuilderAnswerUserInfo json
         * @property {string|null} [guid] WorkflowBuilderAnswerUserInfo guid
         * @property {Proto.IWorkflowParams|null} [params] WorkflowBuilderAnswerUserInfo params
         * @property {Proto.IWorkflowParamsAnswer|null} [paramsAnswer] WorkflowBuilderAnswerUserInfo paramsAnswer
         */

        /**
         * Constructs a new WorkflowBuilderAnswerUserInfo.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilderAnswerUserInfo.
         * @implements IWorkflowBuilderAnswerUserInfo
         * @constructor
         * @param {Proto.IWorkflowBuilderAnswerUserInfo=} [p] Properties to set
         */
        function WorkflowBuilderAnswerUserInfo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilderAnswerUserInfo userUID.
         * @member {string} userUID
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @instance
         */
        WorkflowBuilderAnswerUserInfo.prototype.userUID = "";

        /**
         * WorkflowBuilderAnswerUserInfo infoUID.
         * @member {string} infoUID
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @instance
         */
        WorkflowBuilderAnswerUserInfo.prototype.infoUID = "";

        /**
         * WorkflowBuilderAnswerUserInfo text.
         * @member {string} text
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @instance
         */
        WorkflowBuilderAnswerUserInfo.prototype.text = "";

        /**
         * WorkflowBuilderAnswerUserInfo number.
         * @member {number} number
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @instance
         */
        WorkflowBuilderAnswerUserInfo.prototype.number = 0;

        /**
         * WorkflowBuilderAnswerUserInfo date.
         * @member {number|Long} date
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @instance
         */
        WorkflowBuilderAnswerUserInfo.prototype.date = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderAnswerUserInfo registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @instance
         */
        WorkflowBuilderAnswerUserInfo.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderAnswerUserInfo modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @instance
         */
        WorkflowBuilderAnswerUserInfo.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderAnswerUserInfo file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @instance
         */
        WorkflowBuilderAnswerUserInfo.prototype.file = null;

        /**
         * WorkflowBuilderAnswerUserInfo additional.
         * @member {string} additional
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @instance
         */
        WorkflowBuilderAnswerUserInfo.prototype.additional = "";

        /**
         * WorkflowBuilderAnswerUserInfo readonly.
         * @member {boolean} readonly
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @instance
         */
        WorkflowBuilderAnswerUserInfo.prototype.readonly = false;

        /**
         * WorkflowBuilderAnswerUserInfo json.
         * @member {string} json
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @instance
         */
        WorkflowBuilderAnswerUserInfo.prototype.json = "";

        /**
         * WorkflowBuilderAnswerUserInfo guid.
         * @member {string} guid
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @instance
         */
        WorkflowBuilderAnswerUserInfo.prototype.guid = "";

        /**
         * WorkflowBuilderAnswerUserInfo params.
         * @member {Proto.IWorkflowParams|null|undefined} params
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @instance
         */
        WorkflowBuilderAnswerUserInfo.prototype.params = null;

        /**
         * WorkflowBuilderAnswerUserInfo paramsAnswer.
         * @member {Proto.IWorkflowParamsAnswer|null|undefined} paramsAnswer
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @instance
         */
        WorkflowBuilderAnswerUserInfo.prototype.paramsAnswer = null;

        /**
         * Creates a new WorkflowBuilderAnswerUserInfo instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @static
         * @param {Proto.IWorkflowBuilderAnswerUserInfo=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilderAnswerUserInfo} WorkflowBuilderAnswerUserInfo instance
         */
        WorkflowBuilderAnswerUserInfo.create = function create(properties) {
            return new WorkflowBuilderAnswerUserInfo(properties);
        };

        /**
         * Encodes the specified WorkflowBuilderAnswerUserInfo message. Does not implicitly {@link Proto.WorkflowBuilderAnswerUserInfo.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @static
         * @param {Proto.IWorkflowBuilderAnswerUserInfo} m WorkflowBuilderAnswerUserInfo message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilderAnswerUserInfo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(10).string(m.userUID);
            if (m.infoUID != null && Object.hasOwnProperty.call(m, "infoUID"))
                w.uint32(18).string(m.infoUID);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.number != null && Object.hasOwnProperty.call(m, "number"))
                w.uint32(32).int32(m.number);
            if (m.date != null && Object.hasOwnProperty.call(m, "date"))
                w.uint32(40).int64(m.date);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(66).fork()).ldelim();
            if (m.additional != null && Object.hasOwnProperty.call(m, "additional"))
                w.uint32(74).string(m.additional);
            if (m.readonly != null && Object.hasOwnProperty.call(m, "readonly"))
                w.uint32(80).bool(m.readonly);
            if (m.json != null && Object.hasOwnProperty.call(m, "json"))
                w.uint32(90).string(m.json);
            if (m.guid != null && Object.hasOwnProperty.call(m, "guid"))
                w.uint32(98).string(m.guid);
            if (m.params != null && Object.hasOwnProperty.call(m, "params"))
                $root.Proto.WorkflowParams.encode(m.params, w.uint32(106).fork()).ldelim();
            if (m.paramsAnswer != null && Object.hasOwnProperty.call(m, "paramsAnswer"))
                $root.Proto.WorkflowParamsAnswer.encode(m.paramsAnswer, w.uint32(114).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a WorkflowBuilderAnswerUserInfo message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilderAnswerUserInfo} WorkflowBuilderAnswerUserInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilderAnswerUserInfo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilderAnswerUserInfo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.userUID = r.string();
                    break;
                case 2:
                    m.infoUID = r.string();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.number = r.int32();
                    break;
                case 5:
                    m.date = r.int64();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 9:
                    m.additional = r.string();
                    break;
                case 10:
                    m.readonly = r.bool();
                    break;
                case 11:
                    m.json = r.string();
                    break;
                case 12:
                    m.guid = r.string();
                    break;
                case 13:
                    m.params = $root.Proto.WorkflowParams.decode(r, r.uint32());
                    break;
                case 14:
                    m.paramsAnswer = $root.Proto.WorkflowParamsAnswer.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilderAnswerUserInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilderAnswerUserInfo} WorkflowBuilderAnswerUserInfo
         */
        WorkflowBuilderAnswerUserInfo.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilderAnswerUserInfo)
                return d;
            var m = new $root.Proto.WorkflowBuilderAnswerUserInfo();
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.infoUID != null) {
                m.infoUID = String(d.infoUID);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.number != null) {
                m.number = d.number | 0;
            }
            if (d.date != null) {
                if ($util.Long)
                    (m.date = $util.Long.fromValue(d.date)).unsigned = false;
                else if (typeof d.date === "string")
                    m.date = parseInt(d.date, 10);
                else if (typeof d.date === "number")
                    m.date = d.date;
                else if (typeof d.date === "object")
                    m.date = new $util.LongBits(d.date.low >>> 0, d.date.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.WorkflowBuilderAnswerUserInfo.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            if (d.additional != null) {
                m.additional = String(d.additional);
            }
            if (d.readonly != null) {
                m.readonly = Boolean(d.readonly);
            }
            if (d.json != null) {
                m.json = String(d.json);
            }
            if (d.guid != null) {
                m.guid = String(d.guid);
            }
            if (d.params != null) {
                if (typeof d.params !== "object")
                    throw TypeError(".Proto.WorkflowBuilderAnswerUserInfo.params: object expected");
                m.params = $root.Proto.WorkflowParams.fromObject(d.params);
            }
            if (d.paramsAnswer != null) {
                if (typeof d.paramsAnswer !== "object")
                    throw TypeError(".Proto.WorkflowBuilderAnswerUserInfo.paramsAnswer: object expected");
                m.paramsAnswer = $root.Proto.WorkflowParamsAnswer.fromObject(d.paramsAnswer);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilderAnswerUserInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @static
         * @param {Proto.WorkflowBuilderAnswerUserInfo} m WorkflowBuilderAnswerUserInfo
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilderAnswerUserInfo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.userUID = "";
                d.infoUID = "";
                d.text = "";
                d.number = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.date = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.date = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.file = null;
                d.additional = "";
                d.readonly = false;
                d.json = "";
                d.guid = "";
                d.params = null;
                d.paramsAnswer = null;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.infoUID != null && m.hasOwnProperty("infoUID")) {
                d.infoUID = m.infoUID;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.number != null && m.hasOwnProperty("number")) {
                d.number = m.number;
            }
            if (m.date != null && m.hasOwnProperty("date")) {
                if (typeof m.date === "number")
                    d.date = o.longs === String ? String(m.date) : m.date;
                else
                    d.date = o.longs === String ? $util.Long.prototype.toString.call(m.date) : o.longs === Number ? new $util.LongBits(m.date.low >>> 0, m.date.high >>> 0).toNumber() : m.date;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            if (m.additional != null && m.hasOwnProperty("additional")) {
                d.additional = m.additional;
            }
            if (m.readonly != null && m.hasOwnProperty("readonly")) {
                d.readonly = m.readonly;
            }
            if (m.json != null && m.hasOwnProperty("json")) {
                d.json = m.json;
            }
            if (m.guid != null && m.hasOwnProperty("guid")) {
                d.guid = m.guid;
            }
            if (m.params != null && m.hasOwnProperty("params")) {
                d.params = $root.Proto.WorkflowParams.toObject(m.params, o);
            }
            if (m.paramsAnswer != null && m.hasOwnProperty("paramsAnswer")) {
                d.paramsAnswer = $root.Proto.WorkflowParamsAnswer.toObject(m.paramsAnswer, o);
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilderAnswerUserInfo to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilderAnswerUserInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilderAnswerUserInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilderAnswerUserInfo;
    })();

    Proto.WorkflowBuilderAnswerUserInfoList = (function() {

        /**
         * Properties of a WorkflowBuilderAnswerUserInfoList.
         * @memberof Proto
         * @interface IWorkflowBuilderAnswerUserInfoList
         * @property {Array.<Proto.IWorkflowBuilderAnswerUserInfo>|null} [value] WorkflowBuilderAnswerUserInfoList value
         */

        /**
         * Constructs a new WorkflowBuilderAnswerUserInfoList.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilderAnswerUserInfoList.
         * @implements IWorkflowBuilderAnswerUserInfoList
         * @constructor
         * @param {Proto.IWorkflowBuilderAnswerUserInfoList=} [p] Properties to set
         */
        function WorkflowBuilderAnswerUserInfoList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilderAnswerUserInfoList value.
         * @member {Array.<Proto.IWorkflowBuilderAnswerUserInfo>} value
         * @memberof Proto.WorkflowBuilderAnswerUserInfoList
         * @instance
         */
        WorkflowBuilderAnswerUserInfoList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkflowBuilderAnswerUserInfoList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilderAnswerUserInfoList
         * @static
         * @param {Proto.IWorkflowBuilderAnswerUserInfoList=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilderAnswerUserInfoList} WorkflowBuilderAnswerUserInfoList instance
         */
        WorkflowBuilderAnswerUserInfoList.create = function create(properties) {
            return new WorkflowBuilderAnswerUserInfoList(properties);
        };

        /**
         * Encodes the specified WorkflowBuilderAnswerUserInfoList message. Does not implicitly {@link Proto.WorkflowBuilderAnswerUserInfoList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilderAnswerUserInfoList
         * @static
         * @param {Proto.IWorkflowBuilderAnswerUserInfoList} m WorkflowBuilderAnswerUserInfoList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilderAnswerUserInfoList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkflowBuilderAnswerUserInfo.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowBuilderAnswerUserInfoList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilderAnswerUserInfoList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilderAnswerUserInfoList} WorkflowBuilderAnswerUserInfoList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilderAnswerUserInfoList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilderAnswerUserInfoList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkflowBuilderAnswerUserInfo.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilderAnswerUserInfoList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilderAnswerUserInfoList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilderAnswerUserInfoList} WorkflowBuilderAnswerUserInfoList
         */
        WorkflowBuilderAnswerUserInfoList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilderAnswerUserInfoList)
                return d;
            var m = new $root.Proto.WorkflowBuilderAnswerUserInfoList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkflowBuilderAnswerUserInfoList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilderAnswerUserInfoList.value: object expected");
                    m.value[i] = $root.Proto.WorkflowBuilderAnswerUserInfo.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilderAnswerUserInfoList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilderAnswerUserInfoList
         * @static
         * @param {Proto.WorkflowBuilderAnswerUserInfoList} m WorkflowBuilderAnswerUserInfoList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilderAnswerUserInfoList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkflowBuilderAnswerUserInfo.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilderAnswerUserInfoList to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilderAnswerUserInfoList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilderAnswerUserInfoList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilderAnswerUserInfoList;
    })();

    Proto.WorkflowBuilderAnswer = (function() {

        /**
         * Properties of a WorkflowBuilderAnswer.
         * @memberof Proto
         * @interface IWorkflowBuilderAnswer
         * @property {string|null} [uid] WorkflowBuilderAnswer uid
         * @property {Proto.IWorkflowBuilder|null} [builder] WorkflowBuilderAnswer builder
         * @property {Proto.IUser|null} [user] WorkflowBuilderAnswer user
         * @property {Array.<Proto.IHashTag>|null} [adminTagList] WorkflowBuilderAnswer adminTagList
         * @property {number|Long|null} [registerDate] WorkflowBuilderAnswer registerDate
         * @property {number|Long|null} [modifyDate] WorkflowBuilderAnswer modifyDate
         * @property {Array.<Proto.IWorkflowBuilderAnswerUserInfo>|null} [infoList] WorkflowBuilderAnswer infoList
         * @property {number|Long|null} [statusDate] WorkflowBuilderAnswer statusDate
         * @property {Proto.IWorkflowBuilderStatus|null} [statusDTO] WorkflowBuilderAnswer statusDTO
         * @property {Array.<Proto.IWorkflowBuilderAnswerFile>|null} [fileList] WorkflowBuilderAnswer fileList
         * @property {boolean|null} [needToCreateFile] WorkflowBuilderAnswer needToCreateFile
         * @property {Array.<Proto.IWorkflowBuilderFormInfo>|null} [previewFormList] WorkflowBuilderAnswer previewFormList
         * @property {boolean|null} [editable] WorkflowBuilderAnswer editable
         * @property {boolean|null} [revertible] WorkflowBuilderAnswer revertible
         * @property {Proto.IWorkflowBuilderStatus|null} [revertStatusDTO] WorkflowBuilderAnswer revertStatusDTO
         * @property {boolean|null} [highlight] WorkflowBuilderAnswer highlight
         * @property {boolean|null} [fileEditable] WorkflowBuilderAnswer fileEditable
         * @property {boolean|null} [duplicatable] WorkflowBuilderAnswer duplicatable
         */

        /**
         * Constructs a new WorkflowBuilderAnswer.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilderAnswer.
         * @implements IWorkflowBuilderAnswer
         * @constructor
         * @param {Proto.IWorkflowBuilderAnswer=} [p] Properties to set
         */
        function WorkflowBuilderAnswer(p) {
            this.adminTagList = [];
            this.infoList = [];
            this.fileList = [];
            this.previewFormList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilderAnswer uid.
         * @member {string} uid
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.uid = "";

        /**
         * WorkflowBuilderAnswer builder.
         * @member {Proto.IWorkflowBuilder|null|undefined} builder
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.builder = null;

        /**
         * WorkflowBuilderAnswer user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.user = null;

        /**
         * WorkflowBuilderAnswer adminTagList.
         * @member {Array.<Proto.IHashTag>} adminTagList
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.adminTagList = $util.emptyArray;

        /**
         * WorkflowBuilderAnswer registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderAnswer modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderAnswer infoList.
         * @member {Array.<Proto.IWorkflowBuilderAnswerUserInfo>} infoList
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.infoList = $util.emptyArray;

        /**
         * WorkflowBuilderAnswer statusDate.
         * @member {number|Long} statusDate
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.statusDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderAnswer statusDTO.
         * @member {Proto.IWorkflowBuilderStatus|null|undefined} statusDTO
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.statusDTO = null;

        /**
         * WorkflowBuilderAnswer fileList.
         * @member {Array.<Proto.IWorkflowBuilderAnswerFile>} fileList
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.fileList = $util.emptyArray;

        /**
         * WorkflowBuilderAnswer needToCreateFile.
         * @member {boolean} needToCreateFile
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.needToCreateFile = false;

        /**
         * WorkflowBuilderAnswer previewFormList.
         * @member {Array.<Proto.IWorkflowBuilderFormInfo>} previewFormList
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.previewFormList = $util.emptyArray;

        /**
         * WorkflowBuilderAnswer editable.
         * @member {boolean} editable
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.editable = false;

        /**
         * WorkflowBuilderAnswer revertible.
         * @member {boolean} revertible
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.revertible = false;

        /**
         * WorkflowBuilderAnswer revertStatusDTO.
         * @member {Proto.IWorkflowBuilderStatus|null|undefined} revertStatusDTO
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.revertStatusDTO = null;

        /**
         * WorkflowBuilderAnswer highlight.
         * @member {boolean} highlight
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.highlight = false;

        /**
         * WorkflowBuilderAnswer fileEditable.
         * @member {boolean} fileEditable
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.fileEditable = false;

        /**
         * WorkflowBuilderAnswer duplicatable.
         * @member {boolean} duplicatable
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         */
        WorkflowBuilderAnswer.prototype.duplicatable = false;

        /**
         * Creates a new WorkflowBuilderAnswer instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilderAnswer
         * @static
         * @param {Proto.IWorkflowBuilderAnswer=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilderAnswer} WorkflowBuilderAnswer instance
         */
        WorkflowBuilderAnswer.create = function create(properties) {
            return new WorkflowBuilderAnswer(properties);
        };

        /**
         * Encodes the specified WorkflowBuilderAnswer message. Does not implicitly {@link Proto.WorkflowBuilderAnswer.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilderAnswer
         * @static
         * @param {Proto.IWorkflowBuilderAnswer} m WorkflowBuilderAnswer message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilderAnswer.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.builder != null && Object.hasOwnProperty.call(m, "builder"))
                $root.Proto.WorkflowBuilder.encode(m.builder, w.uint32(18).fork()).ldelim();
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(26).fork()).ldelim();
            if (m.adminTagList != null && m.adminTagList.length) {
                for (var i = 0; i < m.adminTagList.length; ++i)
                    $root.Proto.HashTag.encode(m.adminTagList[i], w.uint32(34).fork()).ldelim();
            }
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(40).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(48).int64(m.modifyDate);
            if (m.infoList != null && m.infoList.length) {
                for (var i = 0; i < m.infoList.length; ++i)
                    $root.Proto.WorkflowBuilderAnswerUserInfo.encode(m.infoList[i], w.uint32(58).fork()).ldelim();
            }
            if (m.statusDate != null && Object.hasOwnProperty.call(m, "statusDate"))
                w.uint32(64).int64(m.statusDate);
            if (m.statusDTO != null && Object.hasOwnProperty.call(m, "statusDTO"))
                $root.Proto.WorkflowBuilderStatus.encode(m.statusDTO, w.uint32(74).fork()).ldelim();
            if (m.fileList != null && m.fileList.length) {
                for (var i = 0; i < m.fileList.length; ++i)
                    $root.Proto.WorkflowBuilderAnswerFile.encode(m.fileList[i], w.uint32(82).fork()).ldelim();
            }
            if (m.needToCreateFile != null && Object.hasOwnProperty.call(m, "needToCreateFile"))
                w.uint32(88).bool(m.needToCreateFile);
            if (m.previewFormList != null && m.previewFormList.length) {
                for (var i = 0; i < m.previewFormList.length; ++i)
                    $root.Proto.WorkflowBuilderFormInfo.encode(m.previewFormList[i], w.uint32(98).fork()).ldelim();
            }
            if (m.editable != null && Object.hasOwnProperty.call(m, "editable"))
                w.uint32(104).bool(m.editable);
            if (m.revertible != null && Object.hasOwnProperty.call(m, "revertible"))
                w.uint32(112).bool(m.revertible);
            if (m.revertStatusDTO != null && Object.hasOwnProperty.call(m, "revertStatusDTO"))
                $root.Proto.WorkflowBuilderStatus.encode(m.revertStatusDTO, w.uint32(122).fork()).ldelim();
            if (m.highlight != null && Object.hasOwnProperty.call(m, "highlight"))
                w.uint32(128).bool(m.highlight);
            if (m.fileEditable != null && Object.hasOwnProperty.call(m, "fileEditable"))
                w.uint32(136).bool(m.fileEditable);
            if (m.duplicatable != null && Object.hasOwnProperty.call(m, "duplicatable"))
                w.uint32(144).bool(m.duplicatable);
            return w;
        };

        /**
         * Decodes a WorkflowBuilderAnswer message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilderAnswer
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilderAnswer} WorkflowBuilderAnswer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilderAnswer.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilderAnswer();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.builder = $root.Proto.WorkflowBuilder.decode(r, r.uint32());
                    break;
                case 3:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 4:
                    if (!(m.adminTagList && m.adminTagList.length))
                        m.adminTagList = [];
                    m.adminTagList.push($root.Proto.HashTag.decode(r, r.uint32()));
                    break;
                case 5:
                    m.registerDate = r.int64();
                    break;
                case 6:
                    m.modifyDate = r.int64();
                    break;
                case 7:
                    if (!(m.infoList && m.infoList.length))
                        m.infoList = [];
                    m.infoList.push($root.Proto.WorkflowBuilderAnswerUserInfo.decode(r, r.uint32()));
                    break;
                case 8:
                    m.statusDate = r.int64();
                    break;
                case 9:
                    m.statusDTO = $root.Proto.WorkflowBuilderStatus.decode(r, r.uint32());
                    break;
                case 10:
                    if (!(m.fileList && m.fileList.length))
                        m.fileList = [];
                    m.fileList.push($root.Proto.WorkflowBuilderAnswerFile.decode(r, r.uint32()));
                    break;
                case 11:
                    m.needToCreateFile = r.bool();
                    break;
                case 12:
                    if (!(m.previewFormList && m.previewFormList.length))
                        m.previewFormList = [];
                    m.previewFormList.push($root.Proto.WorkflowBuilderFormInfo.decode(r, r.uint32()));
                    break;
                case 13:
                    m.editable = r.bool();
                    break;
                case 14:
                    m.revertible = r.bool();
                    break;
                case 15:
                    m.revertStatusDTO = $root.Proto.WorkflowBuilderStatus.decode(r, r.uint32());
                    break;
                case 16:
                    m.highlight = r.bool();
                    break;
                case 17:
                    m.fileEditable = r.bool();
                    break;
                case 18:
                    m.duplicatable = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilderAnswer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilderAnswer
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilderAnswer} WorkflowBuilderAnswer
         */
        WorkflowBuilderAnswer.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilderAnswer)
                return d;
            var m = new $root.Proto.WorkflowBuilderAnswer();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.builder != null) {
                if (typeof d.builder !== "object")
                    throw TypeError(".Proto.WorkflowBuilderAnswer.builder: object expected");
                m.builder = $root.Proto.WorkflowBuilder.fromObject(d.builder);
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.WorkflowBuilderAnswer.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            if (d.adminTagList) {
                if (!Array.isArray(d.adminTagList))
                    throw TypeError(".Proto.WorkflowBuilderAnswer.adminTagList: array expected");
                m.adminTagList = [];
                for (var i = 0; i < d.adminTagList.length; ++i) {
                    if (typeof d.adminTagList[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilderAnswer.adminTagList: object expected");
                    m.adminTagList[i] = $root.Proto.HashTag.fromObject(d.adminTagList[i]);
                }
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.infoList) {
                if (!Array.isArray(d.infoList))
                    throw TypeError(".Proto.WorkflowBuilderAnswer.infoList: array expected");
                m.infoList = [];
                for (var i = 0; i < d.infoList.length; ++i) {
                    if (typeof d.infoList[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilderAnswer.infoList: object expected");
                    m.infoList[i] = $root.Proto.WorkflowBuilderAnswerUserInfo.fromObject(d.infoList[i]);
                }
            }
            if (d.statusDate != null) {
                if ($util.Long)
                    (m.statusDate = $util.Long.fromValue(d.statusDate)).unsigned = false;
                else if (typeof d.statusDate === "string")
                    m.statusDate = parseInt(d.statusDate, 10);
                else if (typeof d.statusDate === "number")
                    m.statusDate = d.statusDate;
                else if (typeof d.statusDate === "object")
                    m.statusDate = new $util.LongBits(d.statusDate.low >>> 0, d.statusDate.high >>> 0).toNumber();
            }
            if (d.statusDTO != null) {
                if (typeof d.statusDTO !== "object")
                    throw TypeError(".Proto.WorkflowBuilderAnswer.statusDTO: object expected");
                m.statusDTO = $root.Proto.WorkflowBuilderStatus.fromObject(d.statusDTO);
            }
            if (d.fileList) {
                if (!Array.isArray(d.fileList))
                    throw TypeError(".Proto.WorkflowBuilderAnswer.fileList: array expected");
                m.fileList = [];
                for (var i = 0; i < d.fileList.length; ++i) {
                    if (typeof d.fileList[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilderAnswer.fileList: object expected");
                    m.fileList[i] = $root.Proto.WorkflowBuilderAnswerFile.fromObject(d.fileList[i]);
                }
            }
            if (d.needToCreateFile != null) {
                m.needToCreateFile = Boolean(d.needToCreateFile);
            }
            if (d.previewFormList) {
                if (!Array.isArray(d.previewFormList))
                    throw TypeError(".Proto.WorkflowBuilderAnswer.previewFormList: array expected");
                m.previewFormList = [];
                for (var i = 0; i < d.previewFormList.length; ++i) {
                    if (typeof d.previewFormList[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilderAnswer.previewFormList: object expected");
                    m.previewFormList[i] = $root.Proto.WorkflowBuilderFormInfo.fromObject(d.previewFormList[i]);
                }
            }
            if (d.editable != null) {
                m.editable = Boolean(d.editable);
            }
            if (d.revertible != null) {
                m.revertible = Boolean(d.revertible);
            }
            if (d.revertStatusDTO != null) {
                if (typeof d.revertStatusDTO !== "object")
                    throw TypeError(".Proto.WorkflowBuilderAnswer.revertStatusDTO: object expected");
                m.revertStatusDTO = $root.Proto.WorkflowBuilderStatus.fromObject(d.revertStatusDTO);
            }
            if (d.highlight != null) {
                m.highlight = Boolean(d.highlight);
            }
            if (d.fileEditable != null) {
                m.fileEditable = Boolean(d.fileEditable);
            }
            if (d.duplicatable != null) {
                m.duplicatable = Boolean(d.duplicatable);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilderAnswer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilderAnswer
         * @static
         * @param {Proto.WorkflowBuilderAnswer} m WorkflowBuilderAnswer
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilderAnswer.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.adminTagList = [];
                d.infoList = [];
                d.fileList = [];
                d.previewFormList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.builder = null;
                d.user = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.statusDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.statusDate = o.longs === String ? "0" : 0;
                d.statusDTO = null;
                d.needToCreateFile = false;
                d.editable = false;
                d.revertible = false;
                d.revertStatusDTO = null;
                d.highlight = false;
                d.fileEditable = false;
                d.duplicatable = false;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.builder != null && m.hasOwnProperty("builder")) {
                d.builder = $root.Proto.WorkflowBuilder.toObject(m.builder, o);
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            if (m.adminTagList && m.adminTagList.length) {
                d.adminTagList = [];
                for (var j = 0; j < m.adminTagList.length; ++j) {
                    d.adminTagList[j] = $root.Proto.HashTag.toObject(m.adminTagList[j], o);
                }
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.infoList && m.infoList.length) {
                d.infoList = [];
                for (var j = 0; j < m.infoList.length; ++j) {
                    d.infoList[j] = $root.Proto.WorkflowBuilderAnswerUserInfo.toObject(m.infoList[j], o);
                }
            }
            if (m.statusDate != null && m.hasOwnProperty("statusDate")) {
                if (typeof m.statusDate === "number")
                    d.statusDate = o.longs === String ? String(m.statusDate) : m.statusDate;
                else
                    d.statusDate = o.longs === String ? $util.Long.prototype.toString.call(m.statusDate) : o.longs === Number ? new $util.LongBits(m.statusDate.low >>> 0, m.statusDate.high >>> 0).toNumber() : m.statusDate;
            }
            if (m.statusDTO != null && m.hasOwnProperty("statusDTO")) {
                d.statusDTO = $root.Proto.WorkflowBuilderStatus.toObject(m.statusDTO, o);
            }
            if (m.fileList && m.fileList.length) {
                d.fileList = [];
                for (var j = 0; j < m.fileList.length; ++j) {
                    d.fileList[j] = $root.Proto.WorkflowBuilderAnswerFile.toObject(m.fileList[j], o);
                }
            }
            if (m.needToCreateFile != null && m.hasOwnProperty("needToCreateFile")) {
                d.needToCreateFile = m.needToCreateFile;
            }
            if (m.previewFormList && m.previewFormList.length) {
                d.previewFormList = [];
                for (var j = 0; j < m.previewFormList.length; ++j) {
                    d.previewFormList[j] = $root.Proto.WorkflowBuilderFormInfo.toObject(m.previewFormList[j], o);
                }
            }
            if (m.editable != null && m.hasOwnProperty("editable")) {
                d.editable = m.editable;
            }
            if (m.revertible != null && m.hasOwnProperty("revertible")) {
                d.revertible = m.revertible;
            }
            if (m.revertStatusDTO != null && m.hasOwnProperty("revertStatusDTO")) {
                d.revertStatusDTO = $root.Proto.WorkflowBuilderStatus.toObject(m.revertStatusDTO, o);
            }
            if (m.highlight != null && m.hasOwnProperty("highlight")) {
                d.highlight = m.highlight;
            }
            if (m.fileEditable != null && m.hasOwnProperty("fileEditable")) {
                d.fileEditable = m.fileEditable;
            }
            if (m.duplicatable != null && m.hasOwnProperty("duplicatable")) {
                d.duplicatable = m.duplicatable;
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilderAnswer to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilderAnswer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilderAnswer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilderAnswer;
    })();

    Proto.WorkflowBuilderAnswerList = (function() {

        /**
         * Properties of a WorkflowBuilderAnswerList.
         * @memberof Proto
         * @interface IWorkflowBuilderAnswerList
         * @property {Array.<Proto.IWorkflowBuilderAnswer>|null} [value] WorkflowBuilderAnswerList value
         */

        /**
         * Constructs a new WorkflowBuilderAnswerList.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilderAnswerList.
         * @implements IWorkflowBuilderAnswerList
         * @constructor
         * @param {Proto.IWorkflowBuilderAnswerList=} [p] Properties to set
         */
        function WorkflowBuilderAnswerList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilderAnswerList value.
         * @member {Array.<Proto.IWorkflowBuilderAnswer>} value
         * @memberof Proto.WorkflowBuilderAnswerList
         * @instance
         */
        WorkflowBuilderAnswerList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkflowBuilderAnswerList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilderAnswerList
         * @static
         * @param {Proto.IWorkflowBuilderAnswerList=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilderAnswerList} WorkflowBuilderAnswerList instance
         */
        WorkflowBuilderAnswerList.create = function create(properties) {
            return new WorkflowBuilderAnswerList(properties);
        };

        /**
         * Encodes the specified WorkflowBuilderAnswerList message. Does not implicitly {@link Proto.WorkflowBuilderAnswerList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilderAnswerList
         * @static
         * @param {Proto.IWorkflowBuilderAnswerList} m WorkflowBuilderAnswerList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilderAnswerList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkflowBuilderAnswer.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowBuilderAnswerList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilderAnswerList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilderAnswerList} WorkflowBuilderAnswerList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilderAnswerList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilderAnswerList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkflowBuilderAnswer.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilderAnswerList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilderAnswerList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilderAnswerList} WorkflowBuilderAnswerList
         */
        WorkflowBuilderAnswerList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilderAnswerList)
                return d;
            var m = new $root.Proto.WorkflowBuilderAnswerList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkflowBuilderAnswerList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilderAnswerList.value: object expected");
                    m.value[i] = $root.Proto.WorkflowBuilderAnswer.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilderAnswerList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilderAnswerList
         * @static
         * @param {Proto.WorkflowBuilderAnswerList} m WorkflowBuilderAnswerList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilderAnswerList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkflowBuilderAnswer.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilderAnswerList to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilderAnswerList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilderAnswerList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilderAnswerList;
    })();

    Proto.WorkflowBuilderAnswerFile = (function() {

        /**
         * Properties of a WorkflowBuilderAnswerFile.
         * @memberof Proto
         * @interface IWorkflowBuilderAnswerFile
         * @property {string|null} [uid] WorkflowBuilderAnswerFile uid
         * @property {string|null} [templateUID] WorkflowBuilderAnswerFile templateUID
         * @property {string|null} [template] WorkflowBuilderAnswerFile template
         * @property {Proto.IUploadFile|null} [file] WorkflowBuilderAnswerFile file
         * @property {Proto.IUser|null} [user] WorkflowBuilderAnswerFile user
         * @property {number|Long|null} [registerDate] WorkflowBuilderAnswerFile registerDate
         * @property {number|Long|null} [modifyDate] WorkflowBuilderAnswerFile modifyDate
         */

        /**
         * Constructs a new WorkflowBuilderAnswerFile.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilderAnswerFile.
         * @implements IWorkflowBuilderAnswerFile
         * @constructor
         * @param {Proto.IWorkflowBuilderAnswerFile=} [p] Properties to set
         */
        function WorkflowBuilderAnswerFile(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilderAnswerFile uid.
         * @member {string} uid
         * @memberof Proto.WorkflowBuilderAnswerFile
         * @instance
         */
        WorkflowBuilderAnswerFile.prototype.uid = "";

        /**
         * WorkflowBuilderAnswerFile templateUID.
         * @member {string} templateUID
         * @memberof Proto.WorkflowBuilderAnswerFile
         * @instance
         */
        WorkflowBuilderAnswerFile.prototype.templateUID = "";

        /**
         * WorkflowBuilderAnswerFile template.
         * @member {string} template
         * @memberof Proto.WorkflowBuilderAnswerFile
         * @instance
         */
        WorkflowBuilderAnswerFile.prototype.template = "";

        /**
         * WorkflowBuilderAnswerFile file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.WorkflowBuilderAnswerFile
         * @instance
         */
        WorkflowBuilderAnswerFile.prototype.file = null;

        /**
         * WorkflowBuilderAnswerFile user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.WorkflowBuilderAnswerFile
         * @instance
         */
        WorkflowBuilderAnswerFile.prototype.user = null;

        /**
         * WorkflowBuilderAnswerFile registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkflowBuilderAnswerFile
         * @instance
         */
        WorkflowBuilderAnswerFile.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderAnswerFile modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkflowBuilderAnswerFile
         * @instance
         */
        WorkflowBuilderAnswerFile.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new WorkflowBuilderAnswerFile instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilderAnswerFile
         * @static
         * @param {Proto.IWorkflowBuilderAnswerFile=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilderAnswerFile} WorkflowBuilderAnswerFile instance
         */
        WorkflowBuilderAnswerFile.create = function create(properties) {
            return new WorkflowBuilderAnswerFile(properties);
        };

        /**
         * Encodes the specified WorkflowBuilderAnswerFile message. Does not implicitly {@link Proto.WorkflowBuilderAnswerFile.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilderAnswerFile
         * @static
         * @param {Proto.IWorkflowBuilderAnswerFile} m WorkflowBuilderAnswerFile message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilderAnswerFile.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.templateUID != null && Object.hasOwnProperty.call(m, "templateUID"))
                w.uint32(18).string(m.templateUID);
            if (m.template != null && Object.hasOwnProperty.call(m, "template"))
                w.uint32(26).string(m.template);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(34).fork()).ldelim();
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(42).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a WorkflowBuilderAnswerFile message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilderAnswerFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilderAnswerFile} WorkflowBuilderAnswerFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilderAnswerFile.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilderAnswerFile();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.templateUID = r.string();
                    break;
                case 3:
                    m.template = r.string();
                    break;
                case 4:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 5:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilderAnswerFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilderAnswerFile
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilderAnswerFile} WorkflowBuilderAnswerFile
         */
        WorkflowBuilderAnswerFile.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilderAnswerFile)
                return d;
            var m = new $root.Proto.WorkflowBuilderAnswerFile();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.templateUID != null) {
                m.templateUID = String(d.templateUID);
            }
            if (d.template != null) {
                m.template = String(d.template);
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.WorkflowBuilderAnswerFile.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.WorkflowBuilderAnswerFile.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilderAnswerFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilderAnswerFile
         * @static
         * @param {Proto.WorkflowBuilderAnswerFile} m WorkflowBuilderAnswerFile
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilderAnswerFile.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.templateUID = "";
                d.template = "";
                d.file = null;
                d.user = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.templateUID != null && m.hasOwnProperty("templateUID")) {
                d.templateUID = m.templateUID;
            }
            if (m.template != null && m.hasOwnProperty("template")) {
                d.template = m.template;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilderAnswerFile to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilderAnswerFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilderAnswerFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilderAnswerFile;
    })();

    Proto.WorkflowBuilderAnswerFileList = (function() {

        /**
         * Properties of a WorkflowBuilderAnswerFileList.
         * @memberof Proto
         * @interface IWorkflowBuilderAnswerFileList
         * @property {Array.<Proto.IWorkflowBuilderAnswerFile>|null} [value] WorkflowBuilderAnswerFileList value
         */

        /**
         * Constructs a new WorkflowBuilderAnswerFileList.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilderAnswerFileList.
         * @implements IWorkflowBuilderAnswerFileList
         * @constructor
         * @param {Proto.IWorkflowBuilderAnswerFileList=} [p] Properties to set
         */
        function WorkflowBuilderAnswerFileList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilderAnswerFileList value.
         * @member {Array.<Proto.IWorkflowBuilderAnswerFile>} value
         * @memberof Proto.WorkflowBuilderAnswerFileList
         * @instance
         */
        WorkflowBuilderAnswerFileList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkflowBuilderAnswerFileList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilderAnswerFileList
         * @static
         * @param {Proto.IWorkflowBuilderAnswerFileList=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilderAnswerFileList} WorkflowBuilderAnswerFileList instance
         */
        WorkflowBuilderAnswerFileList.create = function create(properties) {
            return new WorkflowBuilderAnswerFileList(properties);
        };

        /**
         * Encodes the specified WorkflowBuilderAnswerFileList message. Does not implicitly {@link Proto.WorkflowBuilderAnswerFileList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilderAnswerFileList
         * @static
         * @param {Proto.IWorkflowBuilderAnswerFileList} m WorkflowBuilderAnswerFileList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilderAnswerFileList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkflowBuilderAnswerFile.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowBuilderAnswerFileList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilderAnswerFileList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilderAnswerFileList} WorkflowBuilderAnswerFileList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilderAnswerFileList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilderAnswerFileList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkflowBuilderAnswerFile.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilderAnswerFileList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilderAnswerFileList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilderAnswerFileList} WorkflowBuilderAnswerFileList
         */
        WorkflowBuilderAnswerFileList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilderAnswerFileList)
                return d;
            var m = new $root.Proto.WorkflowBuilderAnswerFileList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkflowBuilderAnswerFileList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilderAnswerFileList.value: object expected");
                    m.value[i] = $root.Proto.WorkflowBuilderAnswerFile.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilderAnswerFileList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilderAnswerFileList
         * @static
         * @param {Proto.WorkflowBuilderAnswerFileList} m WorkflowBuilderAnswerFileList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilderAnswerFileList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkflowBuilderAnswerFile.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilderAnswerFileList to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilderAnswerFileList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilderAnswerFileList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilderAnswerFileList;
    })();

    Proto.ContentWorkflowMap = (function() {

        /**
         * Properties of a ContentWorkflowMap.
         * @memberof Proto
         * @interface IContentWorkflowMap
         * @property {string|null} [contentUID] ContentWorkflowMap contentUID
         * @property {string|null} [builderUID] ContentWorkflowMap builderUID
         * @property {number|Long|null} [registerDate] ContentWorkflowMap registerDate
         * @property {number|Long|null} [modifyDate] ContentWorkflowMap modifyDate
         * @property {number|null} [status] ContentWorkflowMap status
         * @property {string|null} [contentTitle] ContentWorkflowMap contentTitle
         * @property {number|null} [order] ContentWorkflowMap order
         * @property {Proto.IWorkflowBuilder|null} [builder] ContentWorkflowMap builder
         * @property {boolean|null} [main] ContentWorkflowMap main
         * @property {number|null} [distribution] ContentWorkflowMap distribution
         * @property {Proto.IUploadFile|null} [contentFile] ContentWorkflowMap contentFile
         * @property {number|null} [contentDistribution] ContentWorkflowMap contentDistribution
         * @property {number|Long|null} [contentStartDate] ContentWorkflowMap contentStartDate
         * @property {number|Long|null} [contentEndDate] ContentWorkflowMap contentEndDate
         * @property {number|null} [contentWorkflowPolicy] ContentWorkflowMap contentWorkflowPolicy
         * @property {number|null} [contentPublicPolicy] ContentWorkflowMap contentPublicPolicy
         */

        /**
         * Constructs a new ContentWorkflowMap.
         * @memberof Proto
         * @classdesc Represents a ContentWorkflowMap.
         * @implements IContentWorkflowMap
         * @constructor
         * @param {Proto.IContentWorkflowMap=} [p] Properties to set
         */
        function ContentWorkflowMap(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentWorkflowMap contentUID.
         * @member {string} contentUID
         * @memberof Proto.ContentWorkflowMap
         * @instance
         */
        ContentWorkflowMap.prototype.contentUID = "";

        /**
         * ContentWorkflowMap builderUID.
         * @member {string} builderUID
         * @memberof Proto.ContentWorkflowMap
         * @instance
         */
        ContentWorkflowMap.prototype.builderUID = "";

        /**
         * ContentWorkflowMap registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.ContentWorkflowMap
         * @instance
         */
        ContentWorkflowMap.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentWorkflowMap modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.ContentWorkflowMap
         * @instance
         */
        ContentWorkflowMap.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentWorkflowMap status.
         * @member {number} status
         * @memberof Proto.ContentWorkflowMap
         * @instance
         */
        ContentWorkflowMap.prototype.status = 0;

        /**
         * ContentWorkflowMap contentTitle.
         * @member {string} contentTitle
         * @memberof Proto.ContentWorkflowMap
         * @instance
         */
        ContentWorkflowMap.prototype.contentTitle = "";

        /**
         * ContentWorkflowMap order.
         * @member {number} order
         * @memberof Proto.ContentWorkflowMap
         * @instance
         */
        ContentWorkflowMap.prototype.order = 0;

        /**
         * ContentWorkflowMap builder.
         * @member {Proto.IWorkflowBuilder|null|undefined} builder
         * @memberof Proto.ContentWorkflowMap
         * @instance
         */
        ContentWorkflowMap.prototype.builder = null;

        /**
         * ContentWorkflowMap main.
         * @member {boolean} main
         * @memberof Proto.ContentWorkflowMap
         * @instance
         */
        ContentWorkflowMap.prototype.main = false;

        /**
         * ContentWorkflowMap distribution.
         * @member {number} distribution
         * @memberof Proto.ContentWorkflowMap
         * @instance
         */
        ContentWorkflowMap.prototype.distribution = 0;

        /**
         * ContentWorkflowMap contentFile.
         * @member {Proto.IUploadFile|null|undefined} contentFile
         * @memberof Proto.ContentWorkflowMap
         * @instance
         */
        ContentWorkflowMap.prototype.contentFile = null;

        /**
         * ContentWorkflowMap contentDistribution.
         * @member {number} contentDistribution
         * @memberof Proto.ContentWorkflowMap
         * @instance
         */
        ContentWorkflowMap.prototype.contentDistribution = 0;

        /**
         * ContentWorkflowMap contentStartDate.
         * @member {number|Long} contentStartDate
         * @memberof Proto.ContentWorkflowMap
         * @instance
         */
        ContentWorkflowMap.prototype.contentStartDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentWorkflowMap contentEndDate.
         * @member {number|Long} contentEndDate
         * @memberof Proto.ContentWorkflowMap
         * @instance
         */
        ContentWorkflowMap.prototype.contentEndDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ContentWorkflowMap contentWorkflowPolicy.
         * @member {number} contentWorkflowPolicy
         * @memberof Proto.ContentWorkflowMap
         * @instance
         */
        ContentWorkflowMap.prototype.contentWorkflowPolicy = 0;

        /**
         * ContentWorkflowMap contentPublicPolicy.
         * @member {number} contentPublicPolicy
         * @memberof Proto.ContentWorkflowMap
         * @instance
         */
        ContentWorkflowMap.prototype.contentPublicPolicy = 0;

        /**
         * Creates a new ContentWorkflowMap instance using the specified properties.
         * @function create
         * @memberof Proto.ContentWorkflowMap
         * @static
         * @param {Proto.IContentWorkflowMap=} [properties] Properties to set
         * @returns {Proto.ContentWorkflowMap} ContentWorkflowMap instance
         */
        ContentWorkflowMap.create = function create(properties) {
            return new ContentWorkflowMap(properties);
        };

        /**
         * Encodes the specified ContentWorkflowMap message. Does not implicitly {@link Proto.ContentWorkflowMap.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentWorkflowMap
         * @static
         * @param {Proto.IContentWorkflowMap} m ContentWorkflowMap message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentWorkflowMap.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.contentUID != null && Object.hasOwnProperty.call(m, "contentUID"))
                w.uint32(10).string(m.contentUID);
            if (m.builderUID != null && Object.hasOwnProperty.call(m, "builderUID"))
                w.uint32(18).string(m.builderUID);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(32).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(40).int32(m.status);
            if (m.contentTitle != null && Object.hasOwnProperty.call(m, "contentTitle"))
                w.uint32(50).string(m.contentTitle);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(56).int32(m.order);
            if (m.builder != null && Object.hasOwnProperty.call(m, "builder"))
                $root.Proto.WorkflowBuilder.encode(m.builder, w.uint32(66).fork()).ldelim();
            if (m.main != null && Object.hasOwnProperty.call(m, "main"))
                w.uint32(72).bool(m.main);
            if (m.distribution != null && Object.hasOwnProperty.call(m, "distribution"))
                w.uint32(80).int32(m.distribution);
            if (m.contentFile != null && Object.hasOwnProperty.call(m, "contentFile"))
                $root.Proto.UploadFile.encode(m.contentFile, w.uint32(90).fork()).ldelim();
            if (m.contentDistribution != null && Object.hasOwnProperty.call(m, "contentDistribution"))
                w.uint32(96).int32(m.contentDistribution);
            if (m.contentStartDate != null && Object.hasOwnProperty.call(m, "contentStartDate"))
                w.uint32(104).int64(m.contentStartDate);
            if (m.contentEndDate != null && Object.hasOwnProperty.call(m, "contentEndDate"))
                w.uint32(120).int64(m.contentEndDate);
            if (m.contentWorkflowPolicy != null && Object.hasOwnProperty.call(m, "contentWorkflowPolicy"))
                w.uint32(128).int32(m.contentWorkflowPolicy);
            if (m.contentPublicPolicy != null && Object.hasOwnProperty.call(m, "contentPublicPolicy"))
                w.uint32(136).int32(m.contentPublicPolicy);
            return w;
        };

        /**
         * Decodes a ContentWorkflowMap message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentWorkflowMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentWorkflowMap} ContentWorkflowMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentWorkflowMap.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentWorkflowMap();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.contentUID = r.string();
                    break;
                case 2:
                    m.builderUID = r.string();
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    m.modifyDate = r.int64();
                    break;
                case 5:
                    m.status = r.int32();
                    break;
                case 6:
                    m.contentTitle = r.string();
                    break;
                case 7:
                    m.order = r.int32();
                    break;
                case 8:
                    m.builder = $root.Proto.WorkflowBuilder.decode(r, r.uint32());
                    break;
                case 9:
                    m.main = r.bool();
                    break;
                case 10:
                    m.distribution = r.int32();
                    break;
                case 11:
                    m.contentFile = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 12:
                    m.contentDistribution = r.int32();
                    break;
                case 13:
                    m.contentStartDate = r.int64();
                    break;
                case 15:
                    m.contentEndDate = r.int64();
                    break;
                case 16:
                    m.contentWorkflowPolicy = r.int32();
                    break;
                case 17:
                    m.contentPublicPolicy = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentWorkflowMap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentWorkflowMap
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentWorkflowMap} ContentWorkflowMap
         */
        ContentWorkflowMap.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentWorkflowMap)
                return d;
            var m = new $root.Proto.ContentWorkflowMap();
            if (d.contentUID != null) {
                m.contentUID = String(d.contentUID);
            }
            if (d.builderUID != null) {
                m.builderUID = String(d.builderUID);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.contentTitle != null) {
                m.contentTitle = String(d.contentTitle);
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.builder != null) {
                if (typeof d.builder !== "object")
                    throw TypeError(".Proto.ContentWorkflowMap.builder: object expected");
                m.builder = $root.Proto.WorkflowBuilder.fromObject(d.builder);
            }
            if (d.main != null) {
                m.main = Boolean(d.main);
            }
            if (d.distribution != null) {
                m.distribution = d.distribution | 0;
            }
            if (d.contentFile != null) {
                if (typeof d.contentFile !== "object")
                    throw TypeError(".Proto.ContentWorkflowMap.contentFile: object expected");
                m.contentFile = $root.Proto.UploadFile.fromObject(d.contentFile);
            }
            if (d.contentDistribution != null) {
                m.contentDistribution = d.contentDistribution | 0;
            }
            if (d.contentStartDate != null) {
                if ($util.Long)
                    (m.contentStartDate = $util.Long.fromValue(d.contentStartDate)).unsigned = false;
                else if (typeof d.contentStartDate === "string")
                    m.contentStartDate = parseInt(d.contentStartDate, 10);
                else if (typeof d.contentStartDate === "number")
                    m.contentStartDate = d.contentStartDate;
                else if (typeof d.contentStartDate === "object")
                    m.contentStartDate = new $util.LongBits(d.contentStartDate.low >>> 0, d.contentStartDate.high >>> 0).toNumber();
            }
            if (d.contentEndDate != null) {
                if ($util.Long)
                    (m.contentEndDate = $util.Long.fromValue(d.contentEndDate)).unsigned = false;
                else if (typeof d.contentEndDate === "string")
                    m.contentEndDate = parseInt(d.contentEndDate, 10);
                else if (typeof d.contentEndDate === "number")
                    m.contentEndDate = d.contentEndDate;
                else if (typeof d.contentEndDate === "object")
                    m.contentEndDate = new $util.LongBits(d.contentEndDate.low >>> 0, d.contentEndDate.high >>> 0).toNumber();
            }
            if (d.contentWorkflowPolicy != null) {
                m.contentWorkflowPolicy = d.contentWorkflowPolicy | 0;
            }
            if (d.contentPublicPolicy != null) {
                m.contentPublicPolicy = d.contentPublicPolicy | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentWorkflowMap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentWorkflowMap
         * @static
         * @param {Proto.ContentWorkflowMap} m ContentWorkflowMap
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentWorkflowMap.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.contentUID = "";
                d.builderUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.contentTitle = "";
                d.order = 0;
                d.builder = null;
                d.main = false;
                d.distribution = 0;
                d.contentFile = null;
                d.contentDistribution = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.contentStartDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.contentStartDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.contentEndDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.contentEndDate = o.longs === String ? "0" : 0;
                d.contentWorkflowPolicy = 0;
                d.contentPublicPolicy = 0;
            }
            if (m.contentUID != null && m.hasOwnProperty("contentUID")) {
                d.contentUID = m.contentUID;
            }
            if (m.builderUID != null && m.hasOwnProperty("builderUID")) {
                d.builderUID = m.builderUID;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.contentTitle != null && m.hasOwnProperty("contentTitle")) {
                d.contentTitle = m.contentTitle;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.builder != null && m.hasOwnProperty("builder")) {
                d.builder = $root.Proto.WorkflowBuilder.toObject(m.builder, o);
            }
            if (m.main != null && m.hasOwnProperty("main")) {
                d.main = m.main;
            }
            if (m.distribution != null && m.hasOwnProperty("distribution")) {
                d.distribution = m.distribution;
            }
            if (m.contentFile != null && m.hasOwnProperty("contentFile")) {
                d.contentFile = $root.Proto.UploadFile.toObject(m.contentFile, o);
            }
            if (m.contentDistribution != null && m.hasOwnProperty("contentDistribution")) {
                d.contentDistribution = m.contentDistribution;
            }
            if (m.contentStartDate != null && m.hasOwnProperty("contentStartDate")) {
                if (typeof m.contentStartDate === "number")
                    d.contentStartDate = o.longs === String ? String(m.contentStartDate) : m.contentStartDate;
                else
                    d.contentStartDate = o.longs === String ? $util.Long.prototype.toString.call(m.contentStartDate) : o.longs === Number ? new $util.LongBits(m.contentStartDate.low >>> 0, m.contentStartDate.high >>> 0).toNumber() : m.contentStartDate;
            }
            if (m.contentEndDate != null && m.hasOwnProperty("contentEndDate")) {
                if (typeof m.contentEndDate === "number")
                    d.contentEndDate = o.longs === String ? String(m.contentEndDate) : m.contentEndDate;
                else
                    d.contentEndDate = o.longs === String ? $util.Long.prototype.toString.call(m.contentEndDate) : o.longs === Number ? new $util.LongBits(m.contentEndDate.low >>> 0, m.contentEndDate.high >>> 0).toNumber() : m.contentEndDate;
            }
            if (m.contentWorkflowPolicy != null && m.hasOwnProperty("contentWorkflowPolicy")) {
                d.contentWorkflowPolicy = m.contentWorkflowPolicy;
            }
            if (m.contentPublicPolicy != null && m.hasOwnProperty("contentPublicPolicy")) {
                d.contentPublicPolicy = m.contentPublicPolicy;
            }
            return d;
        };

        /**
         * Converts this ContentWorkflowMap to JSON.
         * @function toJSON
         * @memberof Proto.ContentWorkflowMap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentWorkflowMap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentWorkflowMap;
    })();

    Proto.ContentWorkflowMapList = (function() {

        /**
         * Properties of a ContentWorkflowMapList.
         * @memberof Proto
         * @interface IContentWorkflowMapList
         * @property {Array.<Proto.IContentWorkflowMap>|null} [value] ContentWorkflowMapList value
         */

        /**
         * Constructs a new ContentWorkflowMapList.
         * @memberof Proto
         * @classdesc Represents a ContentWorkflowMapList.
         * @implements IContentWorkflowMapList
         * @constructor
         * @param {Proto.IContentWorkflowMapList=} [p] Properties to set
         */
        function ContentWorkflowMapList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ContentWorkflowMapList value.
         * @member {Array.<Proto.IContentWorkflowMap>} value
         * @memberof Proto.ContentWorkflowMapList
         * @instance
         */
        ContentWorkflowMapList.prototype.value = $util.emptyArray;

        /**
         * Creates a new ContentWorkflowMapList instance using the specified properties.
         * @function create
         * @memberof Proto.ContentWorkflowMapList
         * @static
         * @param {Proto.IContentWorkflowMapList=} [properties] Properties to set
         * @returns {Proto.ContentWorkflowMapList} ContentWorkflowMapList instance
         */
        ContentWorkflowMapList.create = function create(properties) {
            return new ContentWorkflowMapList(properties);
        };

        /**
         * Encodes the specified ContentWorkflowMapList message. Does not implicitly {@link Proto.ContentWorkflowMapList.verify|verify} messages.
         * @function encode
         * @memberof Proto.ContentWorkflowMapList
         * @static
         * @param {Proto.IContentWorkflowMapList} m ContentWorkflowMapList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContentWorkflowMapList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.ContentWorkflowMap.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a ContentWorkflowMapList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.ContentWorkflowMapList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.ContentWorkflowMapList} ContentWorkflowMapList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContentWorkflowMapList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.ContentWorkflowMapList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.ContentWorkflowMap.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a ContentWorkflowMapList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.ContentWorkflowMapList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.ContentWorkflowMapList} ContentWorkflowMapList
         */
        ContentWorkflowMapList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.ContentWorkflowMapList)
                return d;
            var m = new $root.Proto.ContentWorkflowMapList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.ContentWorkflowMapList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.ContentWorkflowMapList.value: object expected");
                    m.value[i] = $root.Proto.ContentWorkflowMap.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a ContentWorkflowMapList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.ContentWorkflowMapList
         * @static
         * @param {Proto.ContentWorkflowMapList} m ContentWorkflowMapList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContentWorkflowMapList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.ContentWorkflowMap.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this ContentWorkflowMapList to JSON.
         * @function toJSON
         * @memberof Proto.ContentWorkflowMapList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContentWorkflowMapList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContentWorkflowMapList;
    })();

    Proto.WorkflowFileTemplate = (function() {

        /**
         * Properties of a WorkflowFileTemplate.
         * @memberof Proto
         * @interface IWorkflowFileTemplate
         * @property {string|null} [uid] WorkflowFileTemplate uid
         * @property {number|null} [type] WorkflowFileTemplate type
         * @property {string|null} [title] WorkflowFileTemplate title
         * @property {string|null} [subtitle] WorkflowFileTemplate subtitle
         * @property {string|null} [description] WorkflowFileTemplate description
         * @property {string|null} [design] WorkflowFileTemplate design
         * @property {string|null} [template] WorkflowFileTemplate template
         * @property {number|null} [version] WorkflowFileTemplate version
         * @property {Proto.IUploadFile|null} [image] WorkflowFileTemplate image
         * @property {Proto.IUploadFile|null} [listImage] WorkflowFileTemplate listImage
         * @property {string|null} [listImageOrder] WorkflowFileTemplate listImageOrder
         * @property {number|Long|null} [registerDate] WorkflowFileTemplate registerDate
         * @property {number|Long|null} [modifyDate] WorkflowFileTemplate modifyDate
         * @property {number|null} [status] WorkflowFileTemplate status
         * @property {Array.<Proto.IHashTag>|null} [adminTagList] WorkflowFileTemplate adminTagList
         * @property {Proto.IUser|null} [user] WorkflowFileTemplate user
         * @property {Array.<Proto.IWorkflowBuilder>|null} [builderList] WorkflowFileTemplate builderList
         */

        /**
         * Constructs a new WorkflowFileTemplate.
         * @memberof Proto
         * @classdesc Represents a WorkflowFileTemplate.
         * @implements IWorkflowFileTemplate
         * @constructor
         * @param {Proto.IWorkflowFileTemplate=} [p] Properties to set
         */
        function WorkflowFileTemplate(p) {
            this.adminTagList = [];
            this.builderList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowFileTemplate uid.
         * @member {string} uid
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         */
        WorkflowFileTemplate.prototype.uid = "";

        /**
         * WorkflowFileTemplate type.
         * @member {number} type
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         */
        WorkflowFileTemplate.prototype.type = 0;

        /**
         * WorkflowFileTemplate title.
         * @member {string} title
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         */
        WorkflowFileTemplate.prototype.title = "";

        /**
         * WorkflowFileTemplate subtitle.
         * @member {string} subtitle
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         */
        WorkflowFileTemplate.prototype.subtitle = "";

        /**
         * WorkflowFileTemplate description.
         * @member {string} description
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         */
        WorkflowFileTemplate.prototype.description = "";

        /**
         * WorkflowFileTemplate design.
         * @member {string} design
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         */
        WorkflowFileTemplate.prototype.design = "";

        /**
         * WorkflowFileTemplate template.
         * @member {string} template
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         */
        WorkflowFileTemplate.prototype.template = "";

        /**
         * WorkflowFileTemplate version.
         * @member {number} version
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         */
        WorkflowFileTemplate.prototype.version = 0;

        /**
         * WorkflowFileTemplate image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         */
        WorkflowFileTemplate.prototype.image = null;

        /**
         * WorkflowFileTemplate listImage.
         * @member {Proto.IUploadFile|null|undefined} listImage
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         */
        WorkflowFileTemplate.prototype.listImage = null;

        /**
         * WorkflowFileTemplate listImageOrder.
         * @member {string} listImageOrder
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         */
        WorkflowFileTemplate.prototype.listImageOrder = "";

        /**
         * WorkflowFileTemplate registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         */
        WorkflowFileTemplate.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowFileTemplate modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         */
        WorkflowFileTemplate.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowFileTemplate status.
         * @member {number} status
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         */
        WorkflowFileTemplate.prototype.status = 0;

        /**
         * WorkflowFileTemplate adminTagList.
         * @member {Array.<Proto.IHashTag>} adminTagList
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         */
        WorkflowFileTemplate.prototype.adminTagList = $util.emptyArray;

        /**
         * WorkflowFileTemplate user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         */
        WorkflowFileTemplate.prototype.user = null;

        /**
         * WorkflowFileTemplate builderList.
         * @member {Array.<Proto.IWorkflowBuilder>} builderList
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         */
        WorkflowFileTemplate.prototype.builderList = $util.emptyArray;

        /**
         * Creates a new WorkflowFileTemplate instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowFileTemplate
         * @static
         * @param {Proto.IWorkflowFileTemplate=} [properties] Properties to set
         * @returns {Proto.WorkflowFileTemplate} WorkflowFileTemplate instance
         */
        WorkflowFileTemplate.create = function create(properties) {
            return new WorkflowFileTemplate(properties);
        };

        /**
         * Encodes the specified WorkflowFileTemplate message. Does not implicitly {@link Proto.WorkflowFileTemplate.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowFileTemplate
         * @static
         * @param {Proto.IWorkflowFileTemplate} m WorkflowFileTemplate message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowFileTemplate.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(26).string(m.title);
            if (m.subtitle != null && Object.hasOwnProperty.call(m, "subtitle"))
                w.uint32(34).string(m.subtitle);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(42).string(m.description);
            if (m.design != null && Object.hasOwnProperty.call(m, "design"))
                w.uint32(50).string(m.design);
            if (m.template != null && Object.hasOwnProperty.call(m, "template"))
                w.uint32(58).string(m.template);
            if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                w.uint32(64).int32(m.version);
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(74).fork()).ldelim();
            if (m.listImage != null && Object.hasOwnProperty.call(m, "listImage"))
                $root.Proto.UploadFile.encode(m.listImage, w.uint32(82).fork()).ldelim();
            if (m.listImageOrder != null && Object.hasOwnProperty.call(m, "listImageOrder"))
                w.uint32(90).string(m.listImageOrder);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(96).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(104).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(112).int32(m.status);
            if (m.adminTagList != null && m.adminTagList.length) {
                for (var i = 0; i < m.adminTagList.length; ++i)
                    $root.Proto.HashTag.encode(m.adminTagList[i], w.uint32(122).fork()).ldelim();
            }
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(130).fork()).ldelim();
            if (m.builderList != null && m.builderList.length) {
                for (var i = 0; i < m.builderList.length; ++i)
                    $root.Proto.WorkflowBuilder.encode(m.builderList[i], w.uint32(138).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowFileTemplate message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowFileTemplate
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowFileTemplate} WorkflowFileTemplate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowFileTemplate.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowFileTemplate();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.title = r.string();
                    break;
                case 4:
                    m.subtitle = r.string();
                    break;
                case 5:
                    m.description = r.string();
                    break;
                case 6:
                    m.design = r.string();
                    break;
                case 7:
                    m.template = r.string();
                    break;
                case 8:
                    m.version = r.int32();
                    break;
                case 9:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 10:
                    m.listImage = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 11:
                    m.listImageOrder = r.string();
                    break;
                case 12:
                    m.registerDate = r.int64();
                    break;
                case 13:
                    m.modifyDate = r.int64();
                    break;
                case 14:
                    m.status = r.int32();
                    break;
                case 15:
                    if (!(m.adminTagList && m.adminTagList.length))
                        m.adminTagList = [];
                    m.adminTagList.push($root.Proto.HashTag.decode(r, r.uint32()));
                    break;
                case 16:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 17:
                    if (!(m.builderList && m.builderList.length))
                        m.builderList = [];
                    m.builderList.push($root.Proto.WorkflowBuilder.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowFileTemplate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowFileTemplate
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowFileTemplate} WorkflowFileTemplate
         */
        WorkflowFileTemplate.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowFileTemplate)
                return d;
            var m = new $root.Proto.WorkflowFileTemplate();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.subtitle != null) {
                m.subtitle = String(d.subtitle);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.design != null) {
                m.design = String(d.design);
            }
            if (d.template != null) {
                m.template = String(d.template);
            }
            if (d.version != null) {
                m.version = d.version | 0;
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.WorkflowFileTemplate.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.listImage != null) {
                if (typeof d.listImage !== "object")
                    throw TypeError(".Proto.WorkflowFileTemplate.listImage: object expected");
                m.listImage = $root.Proto.UploadFile.fromObject(d.listImage);
            }
            if (d.listImageOrder != null) {
                m.listImageOrder = String(d.listImageOrder);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.adminTagList) {
                if (!Array.isArray(d.adminTagList))
                    throw TypeError(".Proto.WorkflowFileTemplate.adminTagList: array expected");
                m.adminTagList = [];
                for (var i = 0; i < d.adminTagList.length; ++i) {
                    if (typeof d.adminTagList[i] !== "object")
                        throw TypeError(".Proto.WorkflowFileTemplate.adminTagList: object expected");
                    m.adminTagList[i] = $root.Proto.HashTag.fromObject(d.adminTagList[i]);
                }
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.WorkflowFileTemplate.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            if (d.builderList) {
                if (!Array.isArray(d.builderList))
                    throw TypeError(".Proto.WorkflowFileTemplate.builderList: array expected");
                m.builderList = [];
                for (var i = 0; i < d.builderList.length; ++i) {
                    if (typeof d.builderList[i] !== "object")
                        throw TypeError(".Proto.WorkflowFileTemplate.builderList: object expected");
                    m.builderList[i] = $root.Proto.WorkflowBuilder.fromObject(d.builderList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowFileTemplate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowFileTemplate
         * @static
         * @param {Proto.WorkflowFileTemplate} m WorkflowFileTemplate
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowFileTemplate.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.adminTagList = [];
                d.builderList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.title = "";
                d.subtitle = "";
                d.description = "";
                d.design = "";
                d.template = "";
                d.version = 0;
                d.image = null;
                d.listImage = null;
                d.listImageOrder = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.user = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.subtitle != null && m.hasOwnProperty("subtitle")) {
                d.subtitle = m.subtitle;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.design != null && m.hasOwnProperty("design")) {
                d.design = m.design;
            }
            if (m.template != null && m.hasOwnProperty("template")) {
                d.template = m.template;
            }
            if (m.version != null && m.hasOwnProperty("version")) {
                d.version = m.version;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.listImage != null && m.hasOwnProperty("listImage")) {
                d.listImage = $root.Proto.UploadFile.toObject(m.listImage, o);
            }
            if (m.listImageOrder != null && m.hasOwnProperty("listImageOrder")) {
                d.listImageOrder = m.listImageOrder;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.adminTagList && m.adminTagList.length) {
                d.adminTagList = [];
                for (var j = 0; j < m.adminTagList.length; ++j) {
                    d.adminTagList[j] = $root.Proto.HashTag.toObject(m.adminTagList[j], o);
                }
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            if (m.builderList && m.builderList.length) {
                d.builderList = [];
                for (var j = 0; j < m.builderList.length; ++j) {
                    d.builderList[j] = $root.Proto.WorkflowBuilder.toObject(m.builderList[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowFileTemplate to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowFileTemplate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowFileTemplate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowFileTemplate;
    })();

    Proto.WorkflowFileTemplateList = (function() {

        /**
         * Properties of a WorkflowFileTemplateList.
         * @memberof Proto
         * @interface IWorkflowFileTemplateList
         * @property {Array.<Proto.IWorkflowFileTemplate>|null} [value] WorkflowFileTemplateList value
         */

        /**
         * Constructs a new WorkflowFileTemplateList.
         * @memberof Proto
         * @classdesc Represents a WorkflowFileTemplateList.
         * @implements IWorkflowFileTemplateList
         * @constructor
         * @param {Proto.IWorkflowFileTemplateList=} [p] Properties to set
         */
        function WorkflowFileTemplateList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowFileTemplateList value.
         * @member {Array.<Proto.IWorkflowFileTemplate>} value
         * @memberof Proto.WorkflowFileTemplateList
         * @instance
         */
        WorkflowFileTemplateList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkflowFileTemplateList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowFileTemplateList
         * @static
         * @param {Proto.IWorkflowFileTemplateList=} [properties] Properties to set
         * @returns {Proto.WorkflowFileTemplateList} WorkflowFileTemplateList instance
         */
        WorkflowFileTemplateList.create = function create(properties) {
            return new WorkflowFileTemplateList(properties);
        };

        /**
         * Encodes the specified WorkflowFileTemplateList message. Does not implicitly {@link Proto.WorkflowFileTemplateList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowFileTemplateList
         * @static
         * @param {Proto.IWorkflowFileTemplateList} m WorkflowFileTemplateList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowFileTemplateList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkflowFileTemplate.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowFileTemplateList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowFileTemplateList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowFileTemplateList} WorkflowFileTemplateList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowFileTemplateList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowFileTemplateList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkflowFileTemplate.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowFileTemplateList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowFileTemplateList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowFileTemplateList} WorkflowFileTemplateList
         */
        WorkflowFileTemplateList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowFileTemplateList)
                return d;
            var m = new $root.Proto.WorkflowFileTemplateList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkflowFileTemplateList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkflowFileTemplateList.value: object expected");
                    m.value[i] = $root.Proto.WorkflowFileTemplate.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowFileTemplateList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowFileTemplateList
         * @static
         * @param {Proto.WorkflowFileTemplateList} m WorkflowFileTemplateList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowFileTemplateList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkflowFileTemplate.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowFileTemplateList to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowFileTemplateList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowFileTemplateList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowFileTemplateList;
    })();

    Proto.TicketStaff = (function() {

        /**
         * Properties of a TicketStaff.
         * @memberof Proto
         * @interface ITicketStaff
         * @property {string|null} [uid] TicketStaff uid
         * @property {string|null} [name] TicketStaff name
         * @property {string|null} [description] TicketStaff description
         * @property {number|null} [price] TicketStaff price
         * @property {string|null} [currency] TicketStaff currency
         * @property {string|null} [excludeHoliday] TicketStaff excludeHoliday
         * @property {number|Long|null} [registerDate] TicketStaff registerDate
         * @property {number|Long|null} [modifyDate] TicketStaff modifyDate
         * @property {number|null} [status] TicketStaff status
         * @property {Proto.IUploadFile|null} [image] TicketStaff image
         * @property {boolean|null} [soldout] TicketStaff soldout
         * @property {boolean|null} [scheduleEmpty] TicketStaff scheduleEmpty
         * @property {Array.<Proto.ITicketStaffSchedule>|null} [scheduleList] TicketStaff scheduleList
         * @property {Array.<Proto.ITicketStaffScheduleException>|null} [scheduleExceptionList] TicketStaff scheduleExceptionList
         * @property {boolean|null} [added] TicketStaff added
         * @property {string|null} [availableTimeString] TicketStaff availableTimeString
         * @property {Array.<string>|null} [availableTimeList] TicketStaff availableTimeList
         * @property {boolean|null} [fixed] TicketStaff fixed
         */

        /**
         * Constructs a new TicketStaff.
         * @memberof Proto
         * @classdesc Represents a TicketStaff.
         * @implements ITicketStaff
         * @constructor
         * @param {Proto.ITicketStaff=} [p] Properties to set
         */
        function TicketStaff(p) {
            this.scheduleList = [];
            this.scheduleExceptionList = [];
            this.availableTimeList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketStaff uid.
         * @member {string} uid
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.uid = "";

        /**
         * TicketStaff name.
         * @member {string} name
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.name = "";

        /**
         * TicketStaff description.
         * @member {string} description
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.description = "";

        /**
         * TicketStaff price.
         * @member {number} price
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.price = 0;

        /**
         * TicketStaff currency.
         * @member {string} currency
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.currency = "";

        /**
         * TicketStaff excludeHoliday.
         * @member {string} excludeHoliday
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.excludeHoliday = "";

        /**
         * TicketStaff registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketStaff modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketStaff status.
         * @member {number} status
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.status = 0;

        /**
         * TicketStaff image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.image = null;

        /**
         * TicketStaff soldout.
         * @member {boolean} soldout
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.soldout = false;

        /**
         * TicketStaff scheduleEmpty.
         * @member {boolean} scheduleEmpty
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.scheduleEmpty = false;

        /**
         * TicketStaff scheduleList.
         * @member {Array.<Proto.ITicketStaffSchedule>} scheduleList
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.scheduleList = $util.emptyArray;

        /**
         * TicketStaff scheduleExceptionList.
         * @member {Array.<Proto.ITicketStaffScheduleException>} scheduleExceptionList
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.scheduleExceptionList = $util.emptyArray;

        /**
         * TicketStaff added.
         * @member {boolean} added
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.added = false;

        /**
         * TicketStaff availableTimeString.
         * @member {string} availableTimeString
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.availableTimeString = "";

        /**
         * TicketStaff availableTimeList.
         * @member {Array.<string>} availableTimeList
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.availableTimeList = $util.emptyArray;

        /**
         * TicketStaff fixed.
         * @member {boolean} fixed
         * @memberof Proto.TicketStaff
         * @instance
         */
        TicketStaff.prototype.fixed = false;

        /**
         * Creates a new TicketStaff instance using the specified properties.
         * @function create
         * @memberof Proto.TicketStaff
         * @static
         * @param {Proto.ITicketStaff=} [properties] Properties to set
         * @returns {Proto.TicketStaff} TicketStaff instance
         */
        TicketStaff.create = function create(properties) {
            return new TicketStaff(properties);
        };

        /**
         * Encodes the specified TicketStaff message. Does not implicitly {@link Proto.TicketStaff.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketStaff
         * @static
         * @param {Proto.ITicketStaff} m TicketStaff message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketStaff.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.description != null && Object.hasOwnProperty.call(m, "description"))
                w.uint32(26).string(m.description);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(33).double(m.price);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(42).string(m.currency);
            if (m.excludeHoliday != null && Object.hasOwnProperty.call(m, "excludeHoliday"))
                w.uint32(50).string(m.excludeHoliday);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(56).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(64).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(72).int32(m.status);
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(82).fork()).ldelim();
            if (m.soldout != null && Object.hasOwnProperty.call(m, "soldout"))
                w.uint32(88).bool(m.soldout);
            if (m.scheduleEmpty != null && Object.hasOwnProperty.call(m, "scheduleEmpty"))
                w.uint32(96).bool(m.scheduleEmpty);
            if (m.scheduleList != null && m.scheduleList.length) {
                for (var i = 0; i < m.scheduleList.length; ++i)
                    $root.Proto.TicketStaffSchedule.encode(m.scheduleList[i], w.uint32(106).fork()).ldelim();
            }
            if (m.scheduleExceptionList != null && m.scheduleExceptionList.length) {
                for (var i = 0; i < m.scheduleExceptionList.length; ++i)
                    $root.Proto.TicketStaffScheduleException.encode(m.scheduleExceptionList[i], w.uint32(114).fork()).ldelim();
            }
            if (m.added != null && Object.hasOwnProperty.call(m, "added"))
                w.uint32(120).bool(m.added);
            if (m.availableTimeString != null && Object.hasOwnProperty.call(m, "availableTimeString"))
                w.uint32(130).string(m.availableTimeString);
            if (m.availableTimeList != null && m.availableTimeList.length) {
                for (var i = 0; i < m.availableTimeList.length; ++i)
                    w.uint32(138).string(m.availableTimeList[i]);
            }
            if (m.fixed != null && Object.hasOwnProperty.call(m, "fixed"))
                w.uint32(144).bool(m.fixed);
            return w;
        };

        /**
         * Decodes a TicketStaff message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketStaff
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketStaff} TicketStaff
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketStaff.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketStaff();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.name = r.string();
                    break;
                case 3:
                    m.description = r.string();
                    break;
                case 4:
                    m.price = r.double();
                    break;
                case 5:
                    m.currency = r.string();
                    break;
                case 6:
                    m.excludeHoliday = r.string();
                    break;
                case 7:
                    m.registerDate = r.int64();
                    break;
                case 8:
                    m.modifyDate = r.int64();
                    break;
                case 9:
                    m.status = r.int32();
                    break;
                case 10:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 11:
                    m.soldout = r.bool();
                    break;
                case 12:
                    m.scheduleEmpty = r.bool();
                    break;
                case 13:
                    if (!(m.scheduleList && m.scheduleList.length))
                        m.scheduleList = [];
                    m.scheduleList.push($root.Proto.TicketStaffSchedule.decode(r, r.uint32()));
                    break;
                case 14:
                    if (!(m.scheduleExceptionList && m.scheduleExceptionList.length))
                        m.scheduleExceptionList = [];
                    m.scheduleExceptionList.push($root.Proto.TicketStaffScheduleException.decode(r, r.uint32()));
                    break;
                case 15:
                    m.added = r.bool();
                    break;
                case 16:
                    m.availableTimeString = r.string();
                    break;
                case 17:
                    if (!(m.availableTimeList && m.availableTimeList.length))
                        m.availableTimeList = [];
                    m.availableTimeList.push(r.string());
                    break;
                case 18:
                    m.fixed = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketStaff message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketStaff
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketStaff} TicketStaff
         */
        TicketStaff.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketStaff)
                return d;
            var m = new $root.Proto.TicketStaff();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.description != null) {
                m.description = String(d.description);
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.excludeHoliday != null) {
                m.excludeHoliday = String(d.excludeHoliday);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.TicketStaff.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.soldout != null) {
                m.soldout = Boolean(d.soldout);
            }
            if (d.scheduleEmpty != null) {
                m.scheduleEmpty = Boolean(d.scheduleEmpty);
            }
            if (d.scheduleList) {
                if (!Array.isArray(d.scheduleList))
                    throw TypeError(".Proto.TicketStaff.scheduleList: array expected");
                m.scheduleList = [];
                for (var i = 0; i < d.scheduleList.length; ++i) {
                    if (typeof d.scheduleList[i] !== "object")
                        throw TypeError(".Proto.TicketStaff.scheduleList: object expected");
                    m.scheduleList[i] = $root.Proto.TicketStaffSchedule.fromObject(d.scheduleList[i]);
                }
            }
            if (d.scheduleExceptionList) {
                if (!Array.isArray(d.scheduleExceptionList))
                    throw TypeError(".Proto.TicketStaff.scheduleExceptionList: array expected");
                m.scheduleExceptionList = [];
                for (var i = 0; i < d.scheduleExceptionList.length; ++i) {
                    if (typeof d.scheduleExceptionList[i] !== "object")
                        throw TypeError(".Proto.TicketStaff.scheduleExceptionList: object expected");
                    m.scheduleExceptionList[i] = $root.Proto.TicketStaffScheduleException.fromObject(d.scheduleExceptionList[i]);
                }
            }
            if (d.added != null) {
                m.added = Boolean(d.added);
            }
            if (d.availableTimeString != null) {
                m.availableTimeString = String(d.availableTimeString);
            }
            if (d.availableTimeList) {
                if (!Array.isArray(d.availableTimeList))
                    throw TypeError(".Proto.TicketStaff.availableTimeList: array expected");
                m.availableTimeList = [];
                for (var i = 0; i < d.availableTimeList.length; ++i) {
                    m.availableTimeList[i] = String(d.availableTimeList[i]);
                }
            }
            if (d.fixed != null) {
                m.fixed = Boolean(d.fixed);
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketStaff message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketStaff
         * @static
         * @param {Proto.TicketStaff} m TicketStaff
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketStaff.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.scheduleList = [];
                d.scheduleExceptionList = [];
                d.availableTimeList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.name = "";
                d.description = "";
                d.price = 0;
                d.currency = "";
                d.excludeHoliday = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.image = null;
                d.soldout = false;
                d.scheduleEmpty = false;
                d.added = false;
                d.availableTimeString = "";
                d.fixed = false;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.description != null && m.hasOwnProperty("description")) {
                d.description = m.description;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.excludeHoliday != null && m.hasOwnProperty("excludeHoliday")) {
                d.excludeHoliday = m.excludeHoliday;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.soldout != null && m.hasOwnProperty("soldout")) {
                d.soldout = m.soldout;
            }
            if (m.scheduleEmpty != null && m.hasOwnProperty("scheduleEmpty")) {
                d.scheduleEmpty = m.scheduleEmpty;
            }
            if (m.scheduleList && m.scheduleList.length) {
                d.scheduleList = [];
                for (var j = 0; j < m.scheduleList.length; ++j) {
                    d.scheduleList[j] = $root.Proto.TicketStaffSchedule.toObject(m.scheduleList[j], o);
                }
            }
            if (m.scheduleExceptionList && m.scheduleExceptionList.length) {
                d.scheduleExceptionList = [];
                for (var j = 0; j < m.scheduleExceptionList.length; ++j) {
                    d.scheduleExceptionList[j] = $root.Proto.TicketStaffScheduleException.toObject(m.scheduleExceptionList[j], o);
                }
            }
            if (m.added != null && m.hasOwnProperty("added")) {
                d.added = m.added;
            }
            if (m.availableTimeString != null && m.hasOwnProperty("availableTimeString")) {
                d.availableTimeString = m.availableTimeString;
            }
            if (m.availableTimeList && m.availableTimeList.length) {
                d.availableTimeList = [];
                for (var j = 0; j < m.availableTimeList.length; ++j) {
                    d.availableTimeList[j] = m.availableTimeList[j];
                }
            }
            if (m.fixed != null && m.hasOwnProperty("fixed")) {
                d.fixed = m.fixed;
            }
            return d;
        };

        /**
         * Converts this TicketStaff to JSON.
         * @function toJSON
         * @memberof Proto.TicketStaff
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketStaff.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketStaff;
    })();

    Proto.TicketStaffList = (function() {

        /**
         * Properties of a TicketStaffList.
         * @memberof Proto
         * @interface ITicketStaffList
         * @property {Array.<Proto.ITicketStaff>|null} [value] TicketStaffList value
         */

        /**
         * Constructs a new TicketStaffList.
         * @memberof Proto
         * @classdesc Represents a TicketStaffList.
         * @implements ITicketStaffList
         * @constructor
         * @param {Proto.ITicketStaffList=} [p] Properties to set
         */
        function TicketStaffList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketStaffList value.
         * @member {Array.<Proto.ITicketStaff>} value
         * @memberof Proto.TicketStaffList
         * @instance
         */
        TicketStaffList.prototype.value = $util.emptyArray;

        /**
         * Creates a new TicketStaffList instance using the specified properties.
         * @function create
         * @memberof Proto.TicketStaffList
         * @static
         * @param {Proto.ITicketStaffList=} [properties] Properties to set
         * @returns {Proto.TicketStaffList} TicketStaffList instance
         */
        TicketStaffList.create = function create(properties) {
            return new TicketStaffList(properties);
        };

        /**
         * Encodes the specified TicketStaffList message. Does not implicitly {@link Proto.TicketStaffList.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketStaffList
         * @static
         * @param {Proto.ITicketStaffList} m TicketStaffList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketStaffList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.TicketStaff.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a TicketStaffList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketStaffList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketStaffList} TicketStaffList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketStaffList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketStaffList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.TicketStaff.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketStaffList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketStaffList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketStaffList} TicketStaffList
         */
        TicketStaffList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketStaffList)
                return d;
            var m = new $root.Proto.TicketStaffList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.TicketStaffList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.TicketStaffList.value: object expected");
                    m.value[i] = $root.Proto.TicketStaff.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketStaffList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketStaffList
         * @static
         * @param {Proto.TicketStaffList} m TicketStaffList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketStaffList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.TicketStaff.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this TicketStaffList to JSON.
         * @function toJSON
         * @memberof Proto.TicketStaffList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketStaffList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketStaffList;
    })();

    Proto.TicketStaffSchedule = (function() {

        /**
         * Properties of a TicketStaffSchedule.
         * @memberof Proto
         * @interface ITicketStaffSchedule
         * @property {string|null} [uid] TicketStaffSchedule uid
         * @property {string|null} [staffUID] TicketStaffSchedule staffUID
         * @property {number|null} [dateInterval] TicketStaffSchedule dateInterval
         * @property {number|Long|null} [startDate] TicketStaffSchedule startDate
         * @property {number|Long|null} [endDate] TicketStaffSchedule endDate
         * @property {number|Long|null} [registerDate] TicketStaffSchedule registerDate
         * @property {number|Long|null} [modifyDate] TicketStaffSchedule modifyDate
         * @property {number|null} [status] TicketStaffSchedule status
         * @property {Array.<Proto.ITicketVariationScheduleDate>|null} [dateList] TicketStaffSchedule dateList
         * @property {Array.<Proto.ITicketVariationScheduleUserInfo>|null} [userInfoList] TicketStaffSchedule userInfoList
         * @property {Array.<Proto.ITicketVariationScheduleDateUser>|null} [userList] TicketStaffSchedule userList
         * @property {string|null} [timezoneId] TicketStaffSchedule timezoneId
         * @property {number|null} [timezoneOffset] TicketStaffSchedule timezoneOffset
         * @property {string|null} [recurStartDate] TicketStaffSchedule recurStartDate
         * @property {string|null} [recurStartTime] TicketStaffSchedule recurStartTime
         * @property {string|null} [recurEndDate] TicketStaffSchedule recurEndDate
         * @property {number|null} [recurType] TicketStaffSchedule recurType
         * @property {number|null} [duration] TicketStaffSchedule duration
         * @property {string|null} [recurDay] TicketStaffSchedule recurDay
         * @property {string|null} [recurWeekday] TicketStaffSchedule recurWeekday
         * @property {string|null} [recurWeek] TicketStaffSchedule recurWeek
         * @property {number|null} [recurInterval] TicketStaffSchedule recurInterval
         */

        /**
         * Constructs a new TicketStaffSchedule.
         * @memberof Proto
         * @classdesc Represents a TicketStaffSchedule.
         * @implements ITicketStaffSchedule
         * @constructor
         * @param {Proto.ITicketStaffSchedule=} [p] Properties to set
         */
        function TicketStaffSchedule(p) {
            this.dateList = [];
            this.userInfoList = [];
            this.userList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketStaffSchedule uid.
         * @member {string} uid
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.uid = "";

        /**
         * TicketStaffSchedule staffUID.
         * @member {string} staffUID
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.staffUID = "";

        /**
         * TicketStaffSchedule dateInterval.
         * @member {number} dateInterval
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.dateInterval = 0;

        /**
         * TicketStaffSchedule startDate.
         * @member {number|Long} startDate
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketStaffSchedule endDate.
         * @member {number|Long} endDate
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketStaffSchedule registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketStaffSchedule modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketStaffSchedule status.
         * @member {number} status
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.status = 0;

        /**
         * TicketStaffSchedule dateList.
         * @member {Array.<Proto.ITicketVariationScheduleDate>} dateList
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.dateList = $util.emptyArray;

        /**
         * TicketStaffSchedule userInfoList.
         * @member {Array.<Proto.ITicketVariationScheduleUserInfo>} userInfoList
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.userInfoList = $util.emptyArray;

        /**
         * TicketStaffSchedule userList.
         * @member {Array.<Proto.ITicketVariationScheduleDateUser>} userList
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.userList = $util.emptyArray;

        /**
         * TicketStaffSchedule timezoneId.
         * @member {string} timezoneId
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.timezoneId = "";

        /**
         * TicketStaffSchedule timezoneOffset.
         * @member {number} timezoneOffset
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.timezoneOffset = 0;

        /**
         * TicketStaffSchedule recurStartDate.
         * @member {string} recurStartDate
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.recurStartDate = "";

        /**
         * TicketStaffSchedule recurStartTime.
         * @member {string} recurStartTime
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.recurStartTime = "";

        /**
         * TicketStaffSchedule recurEndDate.
         * @member {string} recurEndDate
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.recurEndDate = "";

        /**
         * TicketStaffSchedule recurType.
         * @member {number} recurType
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.recurType = 0;

        /**
         * TicketStaffSchedule duration.
         * @member {number} duration
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.duration = 0;

        /**
         * TicketStaffSchedule recurDay.
         * @member {string} recurDay
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.recurDay = "";

        /**
         * TicketStaffSchedule recurWeekday.
         * @member {string} recurWeekday
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.recurWeekday = "";

        /**
         * TicketStaffSchedule recurWeek.
         * @member {string} recurWeek
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.recurWeek = "";

        /**
         * TicketStaffSchedule recurInterval.
         * @member {number} recurInterval
         * @memberof Proto.TicketStaffSchedule
         * @instance
         */
        TicketStaffSchedule.prototype.recurInterval = 0;

        /**
         * Creates a new TicketStaffSchedule instance using the specified properties.
         * @function create
         * @memberof Proto.TicketStaffSchedule
         * @static
         * @param {Proto.ITicketStaffSchedule=} [properties] Properties to set
         * @returns {Proto.TicketStaffSchedule} TicketStaffSchedule instance
         */
        TicketStaffSchedule.create = function create(properties) {
            return new TicketStaffSchedule(properties);
        };

        /**
         * Encodes the specified TicketStaffSchedule message. Does not implicitly {@link Proto.TicketStaffSchedule.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketStaffSchedule
         * @static
         * @param {Proto.ITicketStaffSchedule} m TicketStaffSchedule message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketStaffSchedule.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.staffUID != null && Object.hasOwnProperty.call(m, "staffUID"))
                w.uint32(18).string(m.staffUID);
            if (m.dateInterval != null && Object.hasOwnProperty.call(m, "dateInterval"))
                w.uint32(24).int32(m.dateInterval);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(32).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(40).int64(m.endDate);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(64).int32(m.status);
            if (m.dateList != null && m.dateList.length) {
                for (var i = 0; i < m.dateList.length; ++i)
                    $root.Proto.TicketVariationScheduleDate.encode(m.dateList[i], w.uint32(82).fork()).ldelim();
            }
            if (m.userInfoList != null && m.userInfoList.length) {
                for (var i = 0; i < m.userInfoList.length; ++i)
                    $root.Proto.TicketVariationScheduleUserInfo.encode(m.userInfoList[i], w.uint32(90).fork()).ldelim();
            }
            if (m.userList != null && m.userList.length) {
                for (var i = 0; i < m.userList.length; ++i)
                    $root.Proto.TicketVariationScheduleDateUser.encode(m.userList[i], w.uint32(98).fork()).ldelim();
            }
            if (m.timezoneId != null && Object.hasOwnProperty.call(m, "timezoneId"))
                w.uint32(106).string(m.timezoneId);
            if (m.timezoneOffset != null && Object.hasOwnProperty.call(m, "timezoneOffset"))
                w.uint32(112).int32(m.timezoneOffset);
            if (m.recurStartDate != null && Object.hasOwnProperty.call(m, "recurStartDate"))
                w.uint32(122).string(m.recurStartDate);
            if (m.recurStartTime != null && Object.hasOwnProperty.call(m, "recurStartTime"))
                w.uint32(130).string(m.recurStartTime);
            if (m.recurEndDate != null && Object.hasOwnProperty.call(m, "recurEndDate"))
                w.uint32(138).string(m.recurEndDate);
            if (m.recurType != null && Object.hasOwnProperty.call(m, "recurType"))
                w.uint32(144).int32(m.recurType);
            if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                w.uint32(152).int32(m.duration);
            if (m.recurDay != null && Object.hasOwnProperty.call(m, "recurDay"))
                w.uint32(162).string(m.recurDay);
            if (m.recurWeekday != null && Object.hasOwnProperty.call(m, "recurWeekday"))
                w.uint32(170).string(m.recurWeekday);
            if (m.recurWeek != null && Object.hasOwnProperty.call(m, "recurWeek"))
                w.uint32(178).string(m.recurWeek);
            if (m.recurInterval != null && Object.hasOwnProperty.call(m, "recurInterval"))
                w.uint32(184).int32(m.recurInterval);
            return w;
        };

        /**
         * Decodes a TicketStaffSchedule message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketStaffSchedule
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketStaffSchedule} TicketStaffSchedule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketStaffSchedule.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketStaffSchedule();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.staffUID = r.string();
                    break;
                case 3:
                    m.dateInterval = r.int32();
                    break;
                case 4:
                    m.startDate = r.int64();
                    break;
                case 5:
                    m.endDate = r.int64();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.status = r.int32();
                    break;
                case 10:
                    if (!(m.dateList && m.dateList.length))
                        m.dateList = [];
                    m.dateList.push($root.Proto.TicketVariationScheduleDate.decode(r, r.uint32()));
                    break;
                case 11:
                    if (!(m.userInfoList && m.userInfoList.length))
                        m.userInfoList = [];
                    m.userInfoList.push($root.Proto.TicketVariationScheduleUserInfo.decode(r, r.uint32()));
                    break;
                case 12:
                    if (!(m.userList && m.userList.length))
                        m.userList = [];
                    m.userList.push($root.Proto.TicketVariationScheduleDateUser.decode(r, r.uint32()));
                    break;
                case 13:
                    m.timezoneId = r.string();
                    break;
                case 14:
                    m.timezoneOffset = r.int32();
                    break;
                case 15:
                    m.recurStartDate = r.string();
                    break;
                case 16:
                    m.recurStartTime = r.string();
                    break;
                case 17:
                    m.recurEndDate = r.string();
                    break;
                case 18:
                    m.recurType = r.int32();
                    break;
                case 19:
                    m.duration = r.int32();
                    break;
                case 20:
                    m.recurDay = r.string();
                    break;
                case 21:
                    m.recurWeekday = r.string();
                    break;
                case 22:
                    m.recurWeek = r.string();
                    break;
                case 23:
                    m.recurInterval = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketStaffSchedule message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketStaffSchedule
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketStaffSchedule} TicketStaffSchedule
         */
        TicketStaffSchedule.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketStaffSchedule)
                return d;
            var m = new $root.Proto.TicketStaffSchedule();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.staffUID != null) {
                m.staffUID = String(d.staffUID);
            }
            if (d.dateInterval != null) {
                m.dateInterval = d.dateInterval | 0;
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.dateList) {
                if (!Array.isArray(d.dateList))
                    throw TypeError(".Proto.TicketStaffSchedule.dateList: array expected");
                m.dateList = [];
                for (var i = 0; i < d.dateList.length; ++i) {
                    if (typeof d.dateList[i] !== "object")
                        throw TypeError(".Proto.TicketStaffSchedule.dateList: object expected");
                    m.dateList[i] = $root.Proto.TicketVariationScheduleDate.fromObject(d.dateList[i]);
                }
            }
            if (d.userInfoList) {
                if (!Array.isArray(d.userInfoList))
                    throw TypeError(".Proto.TicketStaffSchedule.userInfoList: array expected");
                m.userInfoList = [];
                for (var i = 0; i < d.userInfoList.length; ++i) {
                    if (typeof d.userInfoList[i] !== "object")
                        throw TypeError(".Proto.TicketStaffSchedule.userInfoList: object expected");
                    m.userInfoList[i] = $root.Proto.TicketVariationScheduleUserInfo.fromObject(d.userInfoList[i]);
                }
            }
            if (d.userList) {
                if (!Array.isArray(d.userList))
                    throw TypeError(".Proto.TicketStaffSchedule.userList: array expected");
                m.userList = [];
                for (var i = 0; i < d.userList.length; ++i) {
                    if (typeof d.userList[i] !== "object")
                        throw TypeError(".Proto.TicketStaffSchedule.userList: object expected");
                    m.userList[i] = $root.Proto.TicketVariationScheduleDateUser.fromObject(d.userList[i]);
                }
            }
            if (d.timezoneId != null) {
                m.timezoneId = String(d.timezoneId);
            }
            if (d.timezoneOffset != null) {
                m.timezoneOffset = d.timezoneOffset | 0;
            }
            if (d.recurStartDate != null) {
                m.recurStartDate = String(d.recurStartDate);
            }
            if (d.recurStartTime != null) {
                m.recurStartTime = String(d.recurStartTime);
            }
            if (d.recurEndDate != null) {
                m.recurEndDate = String(d.recurEndDate);
            }
            if (d.recurType != null) {
                m.recurType = d.recurType | 0;
            }
            if (d.duration != null) {
                m.duration = d.duration | 0;
            }
            if (d.recurDay != null) {
                m.recurDay = String(d.recurDay);
            }
            if (d.recurWeekday != null) {
                m.recurWeekday = String(d.recurWeekday);
            }
            if (d.recurWeek != null) {
                m.recurWeek = String(d.recurWeek);
            }
            if (d.recurInterval != null) {
                m.recurInterval = d.recurInterval | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketStaffSchedule message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketStaffSchedule
         * @static
         * @param {Proto.TicketStaffSchedule} m TicketStaffSchedule
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketStaffSchedule.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.dateList = [];
                d.userInfoList = [];
                d.userList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.staffUID = "";
                d.dateInterval = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.timezoneId = "";
                d.timezoneOffset = 0;
                d.recurStartDate = "";
                d.recurStartTime = "";
                d.recurEndDate = "";
                d.recurType = 0;
                d.duration = 0;
                d.recurDay = "";
                d.recurWeekday = "";
                d.recurWeek = "";
                d.recurInterval = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.staffUID != null && m.hasOwnProperty("staffUID")) {
                d.staffUID = m.staffUID;
            }
            if (m.dateInterval != null && m.hasOwnProperty("dateInterval")) {
                d.dateInterval = m.dateInterval;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.dateList && m.dateList.length) {
                d.dateList = [];
                for (var j = 0; j < m.dateList.length; ++j) {
                    d.dateList[j] = $root.Proto.TicketVariationScheduleDate.toObject(m.dateList[j], o);
                }
            }
            if (m.userInfoList && m.userInfoList.length) {
                d.userInfoList = [];
                for (var j = 0; j < m.userInfoList.length; ++j) {
                    d.userInfoList[j] = $root.Proto.TicketVariationScheduleUserInfo.toObject(m.userInfoList[j], o);
                }
            }
            if (m.userList && m.userList.length) {
                d.userList = [];
                for (var j = 0; j < m.userList.length; ++j) {
                    d.userList[j] = $root.Proto.TicketVariationScheduleDateUser.toObject(m.userList[j], o);
                }
            }
            if (m.timezoneId != null && m.hasOwnProperty("timezoneId")) {
                d.timezoneId = m.timezoneId;
            }
            if (m.timezoneOffset != null && m.hasOwnProperty("timezoneOffset")) {
                d.timezoneOffset = m.timezoneOffset;
            }
            if (m.recurStartDate != null && m.hasOwnProperty("recurStartDate")) {
                d.recurStartDate = m.recurStartDate;
            }
            if (m.recurStartTime != null && m.hasOwnProperty("recurStartTime")) {
                d.recurStartTime = m.recurStartTime;
            }
            if (m.recurEndDate != null && m.hasOwnProperty("recurEndDate")) {
                d.recurEndDate = m.recurEndDate;
            }
            if (m.recurType != null && m.hasOwnProperty("recurType")) {
                d.recurType = m.recurType;
            }
            if (m.duration != null && m.hasOwnProperty("duration")) {
                d.duration = m.duration;
            }
            if (m.recurDay != null && m.hasOwnProperty("recurDay")) {
                d.recurDay = m.recurDay;
            }
            if (m.recurWeekday != null && m.hasOwnProperty("recurWeekday")) {
                d.recurWeekday = m.recurWeekday;
            }
            if (m.recurWeek != null && m.hasOwnProperty("recurWeek")) {
                d.recurWeek = m.recurWeek;
            }
            if (m.recurInterval != null && m.hasOwnProperty("recurInterval")) {
                d.recurInterval = m.recurInterval;
            }
            return d;
        };

        /**
         * Converts this TicketStaffSchedule to JSON.
         * @function toJSON
         * @memberof Proto.TicketStaffSchedule
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketStaffSchedule.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketStaffSchedule;
    })();

    Proto.TicketStaffScheduleList = (function() {

        /**
         * Properties of a TicketStaffScheduleList.
         * @memberof Proto
         * @interface ITicketStaffScheduleList
         * @property {Array.<Proto.ITicketStaffSchedule>|null} [value] TicketStaffScheduleList value
         */

        /**
         * Constructs a new TicketStaffScheduleList.
         * @memberof Proto
         * @classdesc Represents a TicketStaffScheduleList.
         * @implements ITicketStaffScheduleList
         * @constructor
         * @param {Proto.ITicketStaffScheduleList=} [p] Properties to set
         */
        function TicketStaffScheduleList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketStaffScheduleList value.
         * @member {Array.<Proto.ITicketStaffSchedule>} value
         * @memberof Proto.TicketStaffScheduleList
         * @instance
         */
        TicketStaffScheduleList.prototype.value = $util.emptyArray;

        /**
         * Creates a new TicketStaffScheduleList instance using the specified properties.
         * @function create
         * @memberof Proto.TicketStaffScheduleList
         * @static
         * @param {Proto.ITicketStaffScheduleList=} [properties] Properties to set
         * @returns {Proto.TicketStaffScheduleList} TicketStaffScheduleList instance
         */
        TicketStaffScheduleList.create = function create(properties) {
            return new TicketStaffScheduleList(properties);
        };

        /**
         * Encodes the specified TicketStaffScheduleList message. Does not implicitly {@link Proto.TicketStaffScheduleList.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketStaffScheduleList
         * @static
         * @param {Proto.ITicketStaffScheduleList} m TicketStaffScheduleList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketStaffScheduleList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.TicketStaffSchedule.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a TicketStaffScheduleList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketStaffScheduleList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketStaffScheduleList} TicketStaffScheduleList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketStaffScheduleList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketStaffScheduleList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.TicketStaffSchedule.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketStaffScheduleList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketStaffScheduleList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketStaffScheduleList} TicketStaffScheduleList
         */
        TicketStaffScheduleList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketStaffScheduleList)
                return d;
            var m = new $root.Proto.TicketStaffScheduleList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.TicketStaffScheduleList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.TicketStaffScheduleList.value: object expected");
                    m.value[i] = $root.Proto.TicketStaffSchedule.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketStaffScheduleList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketStaffScheduleList
         * @static
         * @param {Proto.TicketStaffScheduleList} m TicketStaffScheduleList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketStaffScheduleList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.TicketStaffSchedule.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this TicketStaffScheduleList to JSON.
         * @function toJSON
         * @memberof Proto.TicketStaffScheduleList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketStaffScheduleList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketStaffScheduleList;
    })();

    Proto.TicketStaffScheduleException = (function() {

        /**
         * Properties of a TicketStaffScheduleException.
         * @memberof Proto
         * @interface ITicketStaffScheduleException
         * @property {string|null} [uid] TicketStaffScheduleException uid
         * @property {string|null} [staffUID] TicketStaffScheduleException staffUID
         * @property {number|Long|null} [startDate] TicketStaffScheduleException startDate
         * @property {number|Long|null} [endDate] TicketStaffScheduleException endDate
         * @property {string|null} [timezoneId] TicketStaffScheduleException timezoneId
         * @property {number|null} [timezoneOffset] TicketStaffScheduleException timezoneOffset
         * @property {string|null} [recurStartDate] TicketStaffScheduleException recurStartDate
         * @property {string|null} [recurStartTime] TicketStaffScheduleException recurStartTime
         * @property {string|null} [recurEndDate] TicketStaffScheduleException recurEndDate
         * @property {number|null} [recurType] TicketStaffScheduleException recurType
         * @property {number|null} [duration] TicketStaffScheduleException duration
         * @property {string|null} [recurDay] TicketStaffScheduleException recurDay
         * @property {string|null} [recurWeekday] TicketStaffScheduleException recurWeekday
         * @property {string|null} [recurWeek] TicketStaffScheduleException recurWeek
         * @property {number|null} [recurInterval] TicketStaffScheduleException recurInterval
         * @property {number|Long|null} [registerDate] TicketStaffScheduleException registerDate
         * @property {number|Long|null} [modifyDate] TicketStaffScheduleException modifyDate
         * @property {number|null} [status] TicketStaffScheduleException status
         */

        /**
         * Constructs a new TicketStaffScheduleException.
         * @memberof Proto
         * @classdesc Represents a TicketStaffScheduleException.
         * @implements ITicketStaffScheduleException
         * @constructor
         * @param {Proto.ITicketStaffScheduleException=} [p] Properties to set
         */
        function TicketStaffScheduleException(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketStaffScheduleException uid.
         * @member {string} uid
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.uid = "";

        /**
         * TicketStaffScheduleException staffUID.
         * @member {string} staffUID
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.staffUID = "";

        /**
         * TicketStaffScheduleException startDate.
         * @member {number|Long} startDate
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketStaffScheduleException endDate.
         * @member {number|Long} endDate
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketStaffScheduleException timezoneId.
         * @member {string} timezoneId
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.timezoneId = "";

        /**
         * TicketStaffScheduleException timezoneOffset.
         * @member {number} timezoneOffset
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.timezoneOffset = 0;

        /**
         * TicketStaffScheduleException recurStartDate.
         * @member {string} recurStartDate
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.recurStartDate = "";

        /**
         * TicketStaffScheduleException recurStartTime.
         * @member {string} recurStartTime
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.recurStartTime = "";

        /**
         * TicketStaffScheduleException recurEndDate.
         * @member {string} recurEndDate
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.recurEndDate = "";

        /**
         * TicketStaffScheduleException recurType.
         * @member {number} recurType
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.recurType = 0;

        /**
         * TicketStaffScheduleException duration.
         * @member {number} duration
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.duration = 0;

        /**
         * TicketStaffScheduleException recurDay.
         * @member {string} recurDay
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.recurDay = "";

        /**
         * TicketStaffScheduleException recurWeekday.
         * @member {string} recurWeekday
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.recurWeekday = "";

        /**
         * TicketStaffScheduleException recurWeek.
         * @member {string} recurWeek
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.recurWeek = "";

        /**
         * TicketStaffScheduleException recurInterval.
         * @member {number} recurInterval
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.recurInterval = 0;

        /**
         * TicketStaffScheduleException registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketStaffScheduleException modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TicketStaffScheduleException status.
         * @member {number} status
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         */
        TicketStaffScheduleException.prototype.status = 0;

        /**
         * Creates a new TicketStaffScheduleException instance using the specified properties.
         * @function create
         * @memberof Proto.TicketStaffScheduleException
         * @static
         * @param {Proto.ITicketStaffScheduleException=} [properties] Properties to set
         * @returns {Proto.TicketStaffScheduleException} TicketStaffScheduleException instance
         */
        TicketStaffScheduleException.create = function create(properties) {
            return new TicketStaffScheduleException(properties);
        };

        /**
         * Encodes the specified TicketStaffScheduleException message. Does not implicitly {@link Proto.TicketStaffScheduleException.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketStaffScheduleException
         * @static
         * @param {Proto.ITicketStaffScheduleException} m TicketStaffScheduleException message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketStaffScheduleException.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.staffUID != null && Object.hasOwnProperty.call(m, "staffUID"))
                w.uint32(18).string(m.staffUID);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(24).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(32).int64(m.endDate);
            if (m.timezoneId != null && Object.hasOwnProperty.call(m, "timezoneId"))
                w.uint32(42).string(m.timezoneId);
            if (m.timezoneOffset != null && Object.hasOwnProperty.call(m, "timezoneOffset"))
                w.uint32(48).int32(m.timezoneOffset);
            if (m.recurStartDate != null && Object.hasOwnProperty.call(m, "recurStartDate"))
                w.uint32(58).string(m.recurStartDate);
            if (m.recurStartTime != null && Object.hasOwnProperty.call(m, "recurStartTime"))
                w.uint32(66).string(m.recurStartTime);
            if (m.recurEndDate != null && Object.hasOwnProperty.call(m, "recurEndDate"))
                w.uint32(74).string(m.recurEndDate);
            if (m.recurType != null && Object.hasOwnProperty.call(m, "recurType"))
                w.uint32(80).int32(m.recurType);
            if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                w.uint32(88).int32(m.duration);
            if (m.recurDay != null && Object.hasOwnProperty.call(m, "recurDay"))
                w.uint32(98).string(m.recurDay);
            if (m.recurWeekday != null && Object.hasOwnProperty.call(m, "recurWeekday"))
                w.uint32(106).string(m.recurWeekday);
            if (m.recurWeek != null && Object.hasOwnProperty.call(m, "recurWeek"))
                w.uint32(114).string(m.recurWeek);
            if (m.recurInterval != null && Object.hasOwnProperty.call(m, "recurInterval"))
                w.uint32(120).int32(m.recurInterval);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(128).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(136).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(144).int32(m.status);
            return w;
        };

        /**
         * Decodes a TicketStaffScheduleException message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketStaffScheduleException
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketStaffScheduleException} TicketStaffScheduleException
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketStaffScheduleException.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketStaffScheduleException();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.staffUID = r.string();
                    break;
                case 3:
                    m.startDate = r.int64();
                    break;
                case 4:
                    m.endDate = r.int64();
                    break;
                case 5:
                    m.timezoneId = r.string();
                    break;
                case 6:
                    m.timezoneOffset = r.int32();
                    break;
                case 7:
                    m.recurStartDate = r.string();
                    break;
                case 8:
                    m.recurStartTime = r.string();
                    break;
                case 9:
                    m.recurEndDate = r.string();
                    break;
                case 10:
                    m.recurType = r.int32();
                    break;
                case 11:
                    m.duration = r.int32();
                    break;
                case 12:
                    m.recurDay = r.string();
                    break;
                case 13:
                    m.recurWeekday = r.string();
                    break;
                case 14:
                    m.recurWeek = r.string();
                    break;
                case 15:
                    m.recurInterval = r.int32();
                    break;
                case 16:
                    m.registerDate = r.int64();
                    break;
                case 17:
                    m.modifyDate = r.int64();
                    break;
                case 18:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketStaffScheduleException message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketStaffScheduleException
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketStaffScheduleException} TicketStaffScheduleException
         */
        TicketStaffScheduleException.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketStaffScheduleException)
                return d;
            var m = new $root.Proto.TicketStaffScheduleException();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.staffUID != null) {
                m.staffUID = String(d.staffUID);
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.timezoneId != null) {
                m.timezoneId = String(d.timezoneId);
            }
            if (d.timezoneOffset != null) {
                m.timezoneOffset = d.timezoneOffset | 0;
            }
            if (d.recurStartDate != null) {
                m.recurStartDate = String(d.recurStartDate);
            }
            if (d.recurStartTime != null) {
                m.recurStartTime = String(d.recurStartTime);
            }
            if (d.recurEndDate != null) {
                m.recurEndDate = String(d.recurEndDate);
            }
            if (d.recurType != null) {
                m.recurType = d.recurType | 0;
            }
            if (d.duration != null) {
                m.duration = d.duration | 0;
            }
            if (d.recurDay != null) {
                m.recurDay = String(d.recurDay);
            }
            if (d.recurWeekday != null) {
                m.recurWeekday = String(d.recurWeekday);
            }
            if (d.recurWeek != null) {
                m.recurWeek = String(d.recurWeek);
            }
            if (d.recurInterval != null) {
                m.recurInterval = d.recurInterval | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketStaffScheduleException message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketStaffScheduleException
         * @static
         * @param {Proto.TicketStaffScheduleException} m TicketStaffScheduleException
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketStaffScheduleException.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.staffUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                d.timezoneId = "";
                d.timezoneOffset = 0;
                d.recurStartDate = "";
                d.recurStartTime = "";
                d.recurEndDate = "";
                d.recurType = 0;
                d.duration = 0;
                d.recurDay = "";
                d.recurWeekday = "";
                d.recurWeek = "";
                d.recurInterval = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.staffUID != null && m.hasOwnProperty("staffUID")) {
                d.staffUID = m.staffUID;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.timezoneId != null && m.hasOwnProperty("timezoneId")) {
                d.timezoneId = m.timezoneId;
            }
            if (m.timezoneOffset != null && m.hasOwnProperty("timezoneOffset")) {
                d.timezoneOffset = m.timezoneOffset;
            }
            if (m.recurStartDate != null && m.hasOwnProperty("recurStartDate")) {
                d.recurStartDate = m.recurStartDate;
            }
            if (m.recurStartTime != null && m.hasOwnProperty("recurStartTime")) {
                d.recurStartTime = m.recurStartTime;
            }
            if (m.recurEndDate != null && m.hasOwnProperty("recurEndDate")) {
                d.recurEndDate = m.recurEndDate;
            }
            if (m.recurType != null && m.hasOwnProperty("recurType")) {
                d.recurType = m.recurType;
            }
            if (m.duration != null && m.hasOwnProperty("duration")) {
                d.duration = m.duration;
            }
            if (m.recurDay != null && m.hasOwnProperty("recurDay")) {
                d.recurDay = m.recurDay;
            }
            if (m.recurWeekday != null && m.hasOwnProperty("recurWeekday")) {
                d.recurWeekday = m.recurWeekday;
            }
            if (m.recurWeek != null && m.hasOwnProperty("recurWeek")) {
                d.recurWeek = m.recurWeek;
            }
            if (m.recurInterval != null && m.hasOwnProperty("recurInterval")) {
                d.recurInterval = m.recurInterval;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this TicketStaffScheduleException to JSON.
         * @function toJSON
         * @memberof Proto.TicketStaffScheduleException
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketStaffScheduleException.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketStaffScheduleException;
    })();

    Proto.TicketStaffScheduleExceptionList = (function() {

        /**
         * Properties of a TicketStaffScheduleExceptionList.
         * @memberof Proto
         * @interface ITicketStaffScheduleExceptionList
         * @property {Array.<Proto.ITicketStaffScheduleException>|null} [value] TicketStaffScheduleExceptionList value
         */

        /**
         * Constructs a new TicketStaffScheduleExceptionList.
         * @memberof Proto
         * @classdesc Represents a TicketStaffScheduleExceptionList.
         * @implements ITicketStaffScheduleExceptionList
         * @constructor
         * @param {Proto.ITicketStaffScheduleExceptionList=} [p] Properties to set
         */
        function TicketStaffScheduleExceptionList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * TicketStaffScheduleExceptionList value.
         * @member {Array.<Proto.ITicketStaffScheduleException>} value
         * @memberof Proto.TicketStaffScheduleExceptionList
         * @instance
         */
        TicketStaffScheduleExceptionList.prototype.value = $util.emptyArray;

        /**
         * Creates a new TicketStaffScheduleExceptionList instance using the specified properties.
         * @function create
         * @memberof Proto.TicketStaffScheduleExceptionList
         * @static
         * @param {Proto.ITicketStaffScheduleExceptionList=} [properties] Properties to set
         * @returns {Proto.TicketStaffScheduleExceptionList} TicketStaffScheduleExceptionList instance
         */
        TicketStaffScheduleExceptionList.create = function create(properties) {
            return new TicketStaffScheduleExceptionList(properties);
        };

        /**
         * Encodes the specified TicketStaffScheduleExceptionList message. Does not implicitly {@link Proto.TicketStaffScheduleExceptionList.verify|verify} messages.
         * @function encode
         * @memberof Proto.TicketStaffScheduleExceptionList
         * @static
         * @param {Proto.ITicketStaffScheduleExceptionList} m TicketStaffScheduleExceptionList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TicketStaffScheduleExceptionList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.TicketStaffScheduleException.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a TicketStaffScheduleExceptionList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.TicketStaffScheduleExceptionList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.TicketStaffScheduleExceptionList} TicketStaffScheduleExceptionList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TicketStaffScheduleExceptionList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.TicketStaffScheduleExceptionList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.TicketStaffScheduleException.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a TicketStaffScheduleExceptionList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.TicketStaffScheduleExceptionList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.TicketStaffScheduleExceptionList} TicketStaffScheduleExceptionList
         */
        TicketStaffScheduleExceptionList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.TicketStaffScheduleExceptionList)
                return d;
            var m = new $root.Proto.TicketStaffScheduleExceptionList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.TicketStaffScheduleExceptionList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.TicketStaffScheduleExceptionList.value: object expected");
                    m.value[i] = $root.Proto.TicketStaffScheduleException.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a TicketStaffScheduleExceptionList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.TicketStaffScheduleExceptionList
         * @static
         * @param {Proto.TicketStaffScheduleExceptionList} m TicketStaffScheduleExceptionList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TicketStaffScheduleExceptionList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.TicketStaffScheduleException.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this TicketStaffScheduleExceptionList to JSON.
         * @function toJSON
         * @memberof Proto.TicketStaffScheduleExceptionList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TicketStaffScheduleExceptionList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TicketStaffScheduleExceptionList;
    })();

    Proto.WorkflowBuilderAnswerHistory = (function() {

        /**
         * Properties of a WorkflowBuilderAnswerHistory.
         * @memberof Proto
         * @interface IWorkflowBuilderAnswerHistory
         * @property {string|null} [uid] WorkflowBuilderAnswerHistory uid
         * @property {number|null} [type] WorkflowBuilderAnswerHistory type
         * @property {string|null} [history] WorkflowBuilderAnswerHistory history
         * @property {number|Long|null} [registerDate] WorkflowBuilderAnswerHistory registerDate
         * @property {number|Long|null} [modifyDate] WorkflowBuilderAnswerHistory modifyDate
         * @property {Proto.IWorkflowBuilder|null} [builder] WorkflowBuilderAnswerHistory builder
         * @property {Proto.IUser|null} [user] WorkflowBuilderAnswerHistory user
         */

        /**
         * Constructs a new WorkflowBuilderAnswerHistory.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilderAnswerHistory.
         * @implements IWorkflowBuilderAnswerHistory
         * @constructor
         * @param {Proto.IWorkflowBuilderAnswerHistory=} [p] Properties to set
         */
        function WorkflowBuilderAnswerHistory(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilderAnswerHistory uid.
         * @member {string} uid
         * @memberof Proto.WorkflowBuilderAnswerHistory
         * @instance
         */
        WorkflowBuilderAnswerHistory.prototype.uid = "";

        /**
         * WorkflowBuilderAnswerHistory type.
         * @member {number} type
         * @memberof Proto.WorkflowBuilderAnswerHistory
         * @instance
         */
        WorkflowBuilderAnswerHistory.prototype.type = 0;

        /**
         * WorkflowBuilderAnswerHistory history.
         * @member {string} history
         * @memberof Proto.WorkflowBuilderAnswerHistory
         * @instance
         */
        WorkflowBuilderAnswerHistory.prototype.history = "";

        /**
         * WorkflowBuilderAnswerHistory registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkflowBuilderAnswerHistory
         * @instance
         */
        WorkflowBuilderAnswerHistory.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderAnswerHistory modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkflowBuilderAnswerHistory
         * @instance
         */
        WorkflowBuilderAnswerHistory.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderAnswerHistory builder.
         * @member {Proto.IWorkflowBuilder|null|undefined} builder
         * @memberof Proto.WorkflowBuilderAnswerHistory
         * @instance
         */
        WorkflowBuilderAnswerHistory.prototype.builder = null;

        /**
         * WorkflowBuilderAnswerHistory user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.WorkflowBuilderAnswerHistory
         * @instance
         */
        WorkflowBuilderAnswerHistory.prototype.user = null;

        /**
         * Creates a new WorkflowBuilderAnswerHistory instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilderAnswerHistory
         * @static
         * @param {Proto.IWorkflowBuilderAnswerHistory=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilderAnswerHistory} WorkflowBuilderAnswerHistory instance
         */
        WorkflowBuilderAnswerHistory.create = function create(properties) {
            return new WorkflowBuilderAnswerHistory(properties);
        };

        /**
         * Encodes the specified WorkflowBuilderAnswerHistory message. Does not implicitly {@link Proto.WorkflowBuilderAnswerHistory.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilderAnswerHistory
         * @static
         * @param {Proto.IWorkflowBuilderAnswerHistory} m WorkflowBuilderAnswerHistory message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilderAnswerHistory.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.history != null && Object.hasOwnProperty.call(m, "history"))
                w.uint32(26).string(m.history);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            if (m.builder != null && Object.hasOwnProperty.call(m, "builder"))
                $root.Proto.WorkflowBuilder.encode(m.builder, w.uint32(50).fork()).ldelim();
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(58).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a WorkflowBuilderAnswerHistory message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilderAnswerHistory
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilderAnswerHistory} WorkflowBuilderAnswerHistory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilderAnswerHistory.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilderAnswerHistory();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.history = r.string();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                case 6:
                    m.builder = $root.Proto.WorkflowBuilder.decode(r, r.uint32());
                    break;
                case 7:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilderAnswerHistory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilderAnswerHistory
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilderAnswerHistory} WorkflowBuilderAnswerHistory
         */
        WorkflowBuilderAnswerHistory.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilderAnswerHistory)
                return d;
            var m = new $root.Proto.WorkflowBuilderAnswerHistory();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.history != null) {
                m.history = String(d.history);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.builder != null) {
                if (typeof d.builder !== "object")
                    throw TypeError(".Proto.WorkflowBuilderAnswerHistory.builder: object expected");
                m.builder = $root.Proto.WorkflowBuilder.fromObject(d.builder);
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.WorkflowBuilderAnswerHistory.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilderAnswerHistory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilderAnswerHistory
         * @static
         * @param {Proto.WorkflowBuilderAnswerHistory} m WorkflowBuilderAnswerHistory
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilderAnswerHistory.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.history = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.builder = null;
                d.user = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.history != null && m.hasOwnProperty("history")) {
                d.history = m.history;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.builder != null && m.hasOwnProperty("builder")) {
                d.builder = $root.Proto.WorkflowBuilder.toObject(m.builder, o);
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilderAnswerHistory to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilderAnswerHistory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilderAnswerHistory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilderAnswerHistory;
    })();

    Proto.WorkflowBuilderAnswerHistoryList = (function() {

        /**
         * Properties of a WorkflowBuilderAnswerHistoryList.
         * @memberof Proto
         * @interface IWorkflowBuilderAnswerHistoryList
         * @property {Array.<Proto.IWorkflowBuilderAnswerHistory>|null} [value] WorkflowBuilderAnswerHistoryList value
         */

        /**
         * Constructs a new WorkflowBuilderAnswerHistoryList.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilderAnswerHistoryList.
         * @implements IWorkflowBuilderAnswerHistoryList
         * @constructor
         * @param {Proto.IWorkflowBuilderAnswerHistoryList=} [p] Properties to set
         */
        function WorkflowBuilderAnswerHistoryList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilderAnswerHistoryList value.
         * @member {Array.<Proto.IWorkflowBuilderAnswerHistory>} value
         * @memberof Proto.WorkflowBuilderAnswerHistoryList
         * @instance
         */
        WorkflowBuilderAnswerHistoryList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkflowBuilderAnswerHistoryList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilderAnswerHistoryList
         * @static
         * @param {Proto.IWorkflowBuilderAnswerHistoryList=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilderAnswerHistoryList} WorkflowBuilderAnswerHistoryList instance
         */
        WorkflowBuilderAnswerHistoryList.create = function create(properties) {
            return new WorkflowBuilderAnswerHistoryList(properties);
        };

        /**
         * Encodes the specified WorkflowBuilderAnswerHistoryList message. Does not implicitly {@link Proto.WorkflowBuilderAnswerHistoryList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilderAnswerHistoryList
         * @static
         * @param {Proto.IWorkflowBuilderAnswerHistoryList} m WorkflowBuilderAnswerHistoryList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilderAnswerHistoryList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkflowBuilderAnswerHistory.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowBuilderAnswerHistoryList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilderAnswerHistoryList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilderAnswerHistoryList} WorkflowBuilderAnswerHistoryList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilderAnswerHistoryList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilderAnswerHistoryList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkflowBuilderAnswerHistory.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilderAnswerHistoryList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilderAnswerHistoryList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilderAnswerHistoryList} WorkflowBuilderAnswerHistoryList
         */
        WorkflowBuilderAnswerHistoryList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilderAnswerHistoryList)
                return d;
            var m = new $root.Proto.WorkflowBuilderAnswerHistoryList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkflowBuilderAnswerHistoryList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilderAnswerHistoryList.value: object expected");
                    m.value[i] = $root.Proto.WorkflowBuilderAnswerHistory.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilderAnswerHistoryList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilderAnswerHistoryList
         * @static
         * @param {Proto.WorkflowBuilderAnswerHistoryList} m WorkflowBuilderAnswerHistoryList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilderAnswerHistoryList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkflowBuilderAnswerHistory.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilderAnswerHistoryList to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilderAnswerHistoryList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilderAnswerHistoryList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilderAnswerHistoryList;
    })();

    Proto.WorkStore = (function() {

        /**
         * Properties of a WorkStore.
         * @memberof Proto
         * @interface IWorkStore
         * @property {string|null} [uid] WorkStore uid
         * @property {number|null} [type] WorkStore type
         * @property {string|null} [storeCode] WorkStore storeCode
         * @property {string|null} [name] WorkStore name
         * @property {string|null} [phone] WorkStore phone
         * @property {string|null} [postalCode] WorkStore postalCode
         * @property {number|null} [state] WorkStore state
         * @property {string|null} [city] WorkStore city
         * @property {string|null} [address] WorkStore address
         * @property {string|null} [addressDetail] WorkStore addressDetail
         * @property {Proto.INullDouble|null} [latitude] WorkStore latitude
         * @property {Proto.INullDouble|null} [longitude] WorkStore longitude
         * @property {string|null} [mainUser] WorkStore mainUser
         * @property {string|null} [subUser] WorkStore subUser
         * @property {number|Long|null} [registerDate] WorkStore registerDate
         * @property {number|Long|null} [modifyDate] WorkStore modifyDate
         * @property {number|null} [status] WorkStore status
         * @property {Array.<Proto.IWorkStaff>|null} [mainStaffList] WorkStore mainStaffList
         * @property {Array.<Proto.IWorkStaff>|null} [subStaffList] WorkStore subStaffList
         * @property {boolean|null} [locationLock] WorkStore locationLock
         * @property {Array.<Proto.IWorkStoreLocation>|null} [locationList] WorkStore locationList
         */

        /**
         * Constructs a new WorkStore.
         * @memberof Proto
         * @classdesc Represents a WorkStore.
         * @implements IWorkStore
         * @constructor
         * @param {Proto.IWorkStore=} [p] Properties to set
         */
        function WorkStore(p) {
            this.mainStaffList = [];
            this.subStaffList = [];
            this.locationList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStore uid.
         * @member {string} uid
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.uid = "";

        /**
         * WorkStore type.
         * @member {number} type
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.type = 0;

        /**
         * WorkStore storeCode.
         * @member {string} storeCode
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.storeCode = "";

        /**
         * WorkStore name.
         * @member {string} name
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.name = "";

        /**
         * WorkStore phone.
         * @member {string} phone
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.phone = "";

        /**
         * WorkStore postalCode.
         * @member {string} postalCode
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.postalCode = "";

        /**
         * WorkStore state.
         * @member {number} state
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.state = 0;

        /**
         * WorkStore city.
         * @member {string} city
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.city = "";

        /**
         * WorkStore address.
         * @member {string} address
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.address = "";

        /**
         * WorkStore addressDetail.
         * @member {string} addressDetail
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.addressDetail = "";

        /**
         * WorkStore latitude.
         * @member {Proto.INullDouble|null|undefined} latitude
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.latitude = null;

        /**
         * WorkStore longitude.
         * @member {Proto.INullDouble|null|undefined} longitude
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.longitude = null;

        /**
         * WorkStore mainUser.
         * @member {string} mainUser
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.mainUser = "";

        /**
         * WorkStore subUser.
         * @member {string} subUser
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.subUser = "";

        /**
         * WorkStore registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStore modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStore status.
         * @member {number} status
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.status = 0;

        /**
         * WorkStore mainStaffList.
         * @member {Array.<Proto.IWorkStaff>} mainStaffList
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.mainStaffList = $util.emptyArray;

        /**
         * WorkStore subStaffList.
         * @member {Array.<Proto.IWorkStaff>} subStaffList
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.subStaffList = $util.emptyArray;

        /**
         * WorkStore locationLock.
         * @member {boolean} locationLock
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.locationLock = false;

        /**
         * WorkStore locationList.
         * @member {Array.<Proto.IWorkStoreLocation>} locationList
         * @memberof Proto.WorkStore
         * @instance
         */
        WorkStore.prototype.locationList = $util.emptyArray;

        /**
         * Creates a new WorkStore instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStore
         * @static
         * @param {Proto.IWorkStore=} [properties] Properties to set
         * @returns {Proto.WorkStore} WorkStore instance
         */
        WorkStore.create = function create(properties) {
            return new WorkStore(properties);
        };

        /**
         * Encodes the specified WorkStore message. Does not implicitly {@link Proto.WorkStore.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStore
         * @static
         * @param {Proto.IWorkStore} m WorkStore message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStore.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.storeCode != null && Object.hasOwnProperty.call(m, "storeCode"))
                w.uint32(26).string(m.storeCode);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(34).string(m.name);
            if (m.phone != null && Object.hasOwnProperty.call(m, "phone"))
                w.uint32(42).string(m.phone);
            if (m.postalCode != null && Object.hasOwnProperty.call(m, "postalCode"))
                w.uint32(50).string(m.postalCode);
            if (m.state != null && Object.hasOwnProperty.call(m, "state"))
                w.uint32(56).int32(m.state);
            if (m.city != null && Object.hasOwnProperty.call(m, "city"))
                w.uint32(66).string(m.city);
            if (m.address != null && Object.hasOwnProperty.call(m, "address"))
                w.uint32(74).string(m.address);
            if (m.addressDetail != null && Object.hasOwnProperty.call(m, "addressDetail"))
                w.uint32(82).string(m.addressDetail);
            if (m.latitude != null && Object.hasOwnProperty.call(m, "latitude"))
                $root.Proto.NullDouble.encode(m.latitude, w.uint32(90).fork()).ldelim();
            if (m.longitude != null && Object.hasOwnProperty.call(m, "longitude"))
                $root.Proto.NullDouble.encode(m.longitude, w.uint32(98).fork()).ldelim();
            if (m.mainUser != null && Object.hasOwnProperty.call(m, "mainUser"))
                w.uint32(106).string(m.mainUser);
            if (m.subUser != null && Object.hasOwnProperty.call(m, "subUser"))
                w.uint32(114).string(m.subUser);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(120).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(128).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(136).int32(m.status);
            if (m.mainStaffList != null && m.mainStaffList.length) {
                for (var i = 0; i < m.mainStaffList.length; ++i)
                    $root.Proto.WorkStaff.encode(m.mainStaffList[i], w.uint32(146).fork()).ldelim();
            }
            if (m.subStaffList != null && m.subStaffList.length) {
                for (var i = 0; i < m.subStaffList.length; ++i)
                    $root.Proto.WorkStaff.encode(m.subStaffList[i], w.uint32(154).fork()).ldelim();
            }
            if (m.locationLock != null && Object.hasOwnProperty.call(m, "locationLock"))
                w.uint32(160).bool(m.locationLock);
            if (m.locationList != null && m.locationList.length) {
                for (var i = 0; i < m.locationList.length; ++i)
                    $root.Proto.WorkStoreLocation.encode(m.locationList[i], w.uint32(170).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkStore message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStore
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStore} WorkStore
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStore.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStore();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.storeCode = r.string();
                    break;
                case 4:
                    m.name = r.string();
                    break;
                case 5:
                    m.phone = r.string();
                    break;
                case 6:
                    m.postalCode = r.string();
                    break;
                case 7:
                    m.state = r.int32();
                    break;
                case 8:
                    m.city = r.string();
                    break;
                case 9:
                    m.address = r.string();
                    break;
                case 10:
                    m.addressDetail = r.string();
                    break;
                case 11:
                    m.latitude = $root.Proto.NullDouble.decode(r, r.uint32());
                    break;
                case 12:
                    m.longitude = $root.Proto.NullDouble.decode(r, r.uint32());
                    break;
                case 13:
                    m.mainUser = r.string();
                    break;
                case 14:
                    m.subUser = r.string();
                    break;
                case 15:
                    m.registerDate = r.int64();
                    break;
                case 16:
                    m.modifyDate = r.int64();
                    break;
                case 17:
                    m.status = r.int32();
                    break;
                case 18:
                    if (!(m.mainStaffList && m.mainStaffList.length))
                        m.mainStaffList = [];
                    m.mainStaffList.push($root.Proto.WorkStaff.decode(r, r.uint32()));
                    break;
                case 19:
                    if (!(m.subStaffList && m.subStaffList.length))
                        m.subStaffList = [];
                    m.subStaffList.push($root.Proto.WorkStaff.decode(r, r.uint32()));
                    break;
                case 20:
                    m.locationLock = r.bool();
                    break;
                case 21:
                    if (!(m.locationList && m.locationList.length))
                        m.locationList = [];
                    m.locationList.push($root.Proto.WorkStoreLocation.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStore message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStore
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStore} WorkStore
         */
        WorkStore.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStore)
                return d;
            var m = new $root.Proto.WorkStore();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.storeCode != null) {
                m.storeCode = String(d.storeCode);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.phone != null) {
                m.phone = String(d.phone);
            }
            if (d.postalCode != null) {
                m.postalCode = String(d.postalCode);
            }
            if (d.state != null) {
                m.state = d.state | 0;
            }
            if (d.city != null) {
                m.city = String(d.city);
            }
            if (d.address != null) {
                m.address = String(d.address);
            }
            if (d.addressDetail != null) {
                m.addressDetail = String(d.addressDetail);
            }
            if (d.latitude != null) {
                if (typeof d.latitude !== "object")
                    throw TypeError(".Proto.WorkStore.latitude: object expected");
                m.latitude = $root.Proto.NullDouble.fromObject(d.latitude);
            }
            if (d.longitude != null) {
                if (typeof d.longitude !== "object")
                    throw TypeError(".Proto.WorkStore.longitude: object expected");
                m.longitude = $root.Proto.NullDouble.fromObject(d.longitude);
            }
            if (d.mainUser != null) {
                m.mainUser = String(d.mainUser);
            }
            if (d.subUser != null) {
                m.subUser = String(d.subUser);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.mainStaffList) {
                if (!Array.isArray(d.mainStaffList))
                    throw TypeError(".Proto.WorkStore.mainStaffList: array expected");
                m.mainStaffList = [];
                for (var i = 0; i < d.mainStaffList.length; ++i) {
                    if (typeof d.mainStaffList[i] !== "object")
                        throw TypeError(".Proto.WorkStore.mainStaffList: object expected");
                    m.mainStaffList[i] = $root.Proto.WorkStaff.fromObject(d.mainStaffList[i]);
                }
            }
            if (d.subStaffList) {
                if (!Array.isArray(d.subStaffList))
                    throw TypeError(".Proto.WorkStore.subStaffList: array expected");
                m.subStaffList = [];
                for (var i = 0; i < d.subStaffList.length; ++i) {
                    if (typeof d.subStaffList[i] !== "object")
                        throw TypeError(".Proto.WorkStore.subStaffList: object expected");
                    m.subStaffList[i] = $root.Proto.WorkStaff.fromObject(d.subStaffList[i]);
                }
            }
            if (d.locationLock != null) {
                m.locationLock = Boolean(d.locationLock);
            }
            if (d.locationList) {
                if (!Array.isArray(d.locationList))
                    throw TypeError(".Proto.WorkStore.locationList: array expected");
                m.locationList = [];
                for (var i = 0; i < d.locationList.length; ++i) {
                    if (typeof d.locationList[i] !== "object")
                        throw TypeError(".Proto.WorkStore.locationList: object expected");
                    m.locationList[i] = $root.Proto.WorkStoreLocation.fromObject(d.locationList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStore message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStore
         * @static
         * @param {Proto.WorkStore} m WorkStore
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStore.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.mainStaffList = [];
                d.subStaffList = [];
                d.locationList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.storeCode = "";
                d.name = "";
                d.phone = "";
                d.postalCode = "";
                d.state = 0;
                d.city = "";
                d.address = "";
                d.addressDetail = "";
                d.latitude = null;
                d.longitude = null;
                d.mainUser = "";
                d.subUser = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.locationLock = false;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.storeCode != null && m.hasOwnProperty("storeCode")) {
                d.storeCode = m.storeCode;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.phone != null && m.hasOwnProperty("phone")) {
                d.phone = m.phone;
            }
            if (m.postalCode != null && m.hasOwnProperty("postalCode")) {
                d.postalCode = m.postalCode;
            }
            if (m.state != null && m.hasOwnProperty("state")) {
                d.state = m.state;
            }
            if (m.city != null && m.hasOwnProperty("city")) {
                d.city = m.city;
            }
            if (m.address != null && m.hasOwnProperty("address")) {
                d.address = m.address;
            }
            if (m.addressDetail != null && m.hasOwnProperty("addressDetail")) {
                d.addressDetail = m.addressDetail;
            }
            if (m.latitude != null && m.hasOwnProperty("latitude")) {
                d.latitude = $root.Proto.NullDouble.toObject(m.latitude, o);
            }
            if (m.longitude != null && m.hasOwnProperty("longitude")) {
                d.longitude = $root.Proto.NullDouble.toObject(m.longitude, o);
            }
            if (m.mainUser != null && m.hasOwnProperty("mainUser")) {
                d.mainUser = m.mainUser;
            }
            if (m.subUser != null && m.hasOwnProperty("subUser")) {
                d.subUser = m.subUser;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.mainStaffList && m.mainStaffList.length) {
                d.mainStaffList = [];
                for (var j = 0; j < m.mainStaffList.length; ++j) {
                    d.mainStaffList[j] = $root.Proto.WorkStaff.toObject(m.mainStaffList[j], o);
                }
            }
            if (m.subStaffList && m.subStaffList.length) {
                d.subStaffList = [];
                for (var j = 0; j < m.subStaffList.length; ++j) {
                    d.subStaffList[j] = $root.Proto.WorkStaff.toObject(m.subStaffList[j], o);
                }
            }
            if (m.locationLock != null && m.hasOwnProperty("locationLock")) {
                d.locationLock = m.locationLock;
            }
            if (m.locationList && m.locationList.length) {
                d.locationList = [];
                for (var j = 0; j < m.locationList.length; ++j) {
                    d.locationList[j] = $root.Proto.WorkStoreLocation.toObject(m.locationList[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkStore to JSON.
         * @function toJSON
         * @memberof Proto.WorkStore
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStore.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStore;
    })();

    Proto.WorkStoreList = (function() {

        /**
         * Properties of a WorkStoreList.
         * @memberof Proto
         * @interface IWorkStoreList
         * @property {Array.<Proto.IWorkStore>|null} [value] WorkStoreList value
         */

        /**
         * Constructs a new WorkStoreList.
         * @memberof Proto
         * @classdesc Represents a WorkStoreList.
         * @implements IWorkStoreList
         * @constructor
         * @param {Proto.IWorkStoreList=} [p] Properties to set
         */
        function WorkStoreList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreList value.
         * @member {Array.<Proto.IWorkStore>} value
         * @memberof Proto.WorkStoreList
         * @instance
         */
        WorkStoreList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkStoreList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreList
         * @static
         * @param {Proto.IWorkStoreList=} [properties] Properties to set
         * @returns {Proto.WorkStoreList} WorkStoreList instance
         */
        WorkStoreList.create = function create(properties) {
            return new WorkStoreList(properties);
        };

        /**
         * Encodes the specified WorkStoreList message. Does not implicitly {@link Proto.WorkStoreList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreList
         * @static
         * @param {Proto.IWorkStoreList} m WorkStoreList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkStore.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkStoreList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreList} WorkStoreList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkStore.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreList} WorkStoreList
         */
        WorkStoreList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreList)
                return d;
            var m = new $root.Proto.WorkStoreList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkStoreList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkStoreList.value: object expected");
                    m.value[i] = $root.Proto.WorkStore.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreList
         * @static
         * @param {Proto.WorkStoreList} m WorkStoreList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkStore.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkStoreList to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreList;
    })();

    Proto.WorkStoreLocation = (function() {

        /**
         * Properties of a WorkStoreLocation.
         * @memberof Proto
         * @interface IWorkStoreLocation
         * @property {string|null} [uid] WorkStoreLocation uid
         * @property {string|null} [name] WorkStoreLocation name
         * @property {number|null} [latitude] WorkStoreLocation latitude
         * @property {number|null} [longitude] WorkStoreLocation longitude
         * @property {string|null} [postalCode] WorkStoreLocation postalCode
         * @property {number|null} [state] WorkStoreLocation state
         * @property {string|null} [city] WorkStoreLocation city
         * @property {string|null} [address] WorkStoreLocation address
         * @property {string|null} [addressDetail] WorkStoreLocation addressDetail
         * @property {number|null} [range] WorkStoreLocation range
         * @property {number|Long|null} [registerDate] WorkStoreLocation registerDate
         * @property {number|Long|null} [modifyDate] WorkStoreLocation modifyDate
         * @property {number|null} [status] WorkStoreLocation status
         */

        /**
         * Constructs a new WorkStoreLocation.
         * @memberof Proto
         * @classdesc Represents a WorkStoreLocation.
         * @implements IWorkStoreLocation
         * @constructor
         * @param {Proto.IWorkStoreLocation=} [p] Properties to set
         */
        function WorkStoreLocation(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreLocation uid.
         * @member {string} uid
         * @memberof Proto.WorkStoreLocation
         * @instance
         */
        WorkStoreLocation.prototype.uid = "";

        /**
         * WorkStoreLocation name.
         * @member {string} name
         * @memberof Proto.WorkStoreLocation
         * @instance
         */
        WorkStoreLocation.prototype.name = "";

        /**
         * WorkStoreLocation latitude.
         * @member {number} latitude
         * @memberof Proto.WorkStoreLocation
         * @instance
         */
        WorkStoreLocation.prototype.latitude = 0;

        /**
         * WorkStoreLocation longitude.
         * @member {number} longitude
         * @memberof Proto.WorkStoreLocation
         * @instance
         */
        WorkStoreLocation.prototype.longitude = 0;

        /**
         * WorkStoreLocation postalCode.
         * @member {string} postalCode
         * @memberof Proto.WorkStoreLocation
         * @instance
         */
        WorkStoreLocation.prototype.postalCode = "";

        /**
         * WorkStoreLocation state.
         * @member {number} state
         * @memberof Proto.WorkStoreLocation
         * @instance
         */
        WorkStoreLocation.prototype.state = 0;

        /**
         * WorkStoreLocation city.
         * @member {string} city
         * @memberof Proto.WorkStoreLocation
         * @instance
         */
        WorkStoreLocation.prototype.city = "";

        /**
         * WorkStoreLocation address.
         * @member {string} address
         * @memberof Proto.WorkStoreLocation
         * @instance
         */
        WorkStoreLocation.prototype.address = "";

        /**
         * WorkStoreLocation addressDetail.
         * @member {string} addressDetail
         * @memberof Proto.WorkStoreLocation
         * @instance
         */
        WorkStoreLocation.prototype.addressDetail = "";

        /**
         * WorkStoreLocation range.
         * @member {number} range
         * @memberof Proto.WorkStoreLocation
         * @instance
         */
        WorkStoreLocation.prototype.range = 0;

        /**
         * WorkStoreLocation registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStoreLocation
         * @instance
         */
        WorkStoreLocation.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreLocation modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStoreLocation
         * @instance
         */
        WorkStoreLocation.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreLocation status.
         * @member {number} status
         * @memberof Proto.WorkStoreLocation
         * @instance
         */
        WorkStoreLocation.prototype.status = 0;

        /**
         * Creates a new WorkStoreLocation instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreLocation
         * @static
         * @param {Proto.IWorkStoreLocation=} [properties] Properties to set
         * @returns {Proto.WorkStoreLocation} WorkStoreLocation instance
         */
        WorkStoreLocation.create = function create(properties) {
            return new WorkStoreLocation(properties);
        };

        /**
         * Encodes the specified WorkStoreLocation message. Does not implicitly {@link Proto.WorkStoreLocation.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreLocation
         * @static
         * @param {Proto.IWorkStoreLocation} m WorkStoreLocation message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreLocation.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.latitude != null && Object.hasOwnProperty.call(m, "latitude"))
                w.uint32(25).double(m.latitude);
            if (m.longitude != null && Object.hasOwnProperty.call(m, "longitude"))
                w.uint32(33).double(m.longitude);
            if (m.postalCode != null && Object.hasOwnProperty.call(m, "postalCode"))
                w.uint32(42).string(m.postalCode);
            if (m.state != null && Object.hasOwnProperty.call(m, "state"))
                w.uint32(48).int32(m.state);
            if (m.city != null && Object.hasOwnProperty.call(m, "city"))
                w.uint32(58).string(m.city);
            if (m.address != null && Object.hasOwnProperty.call(m, "address"))
                w.uint32(66).string(m.address);
            if (m.addressDetail != null && Object.hasOwnProperty.call(m, "addressDetail"))
                w.uint32(74).string(m.addressDetail);
            if (m.range != null && Object.hasOwnProperty.call(m, "range"))
                w.uint32(80).int32(m.range);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(88).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(96).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(104).int32(m.status);
            return w;
        };

        /**
         * Decodes a WorkStoreLocation message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreLocation
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreLocation} WorkStoreLocation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreLocation.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreLocation();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.name = r.string();
                    break;
                case 3:
                    m.latitude = r.double();
                    break;
                case 4:
                    m.longitude = r.double();
                    break;
                case 5:
                    m.postalCode = r.string();
                    break;
                case 6:
                    m.state = r.int32();
                    break;
                case 7:
                    m.city = r.string();
                    break;
                case 8:
                    m.address = r.string();
                    break;
                case 9:
                    m.addressDetail = r.string();
                    break;
                case 10:
                    m.range = r.int32();
                    break;
                case 11:
                    m.registerDate = r.int64();
                    break;
                case 12:
                    m.modifyDate = r.int64();
                    break;
                case 13:
                    m.status = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreLocation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreLocation
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreLocation} WorkStoreLocation
         */
        WorkStoreLocation.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreLocation)
                return d;
            var m = new $root.Proto.WorkStoreLocation();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.latitude != null) {
                m.latitude = Number(d.latitude);
            }
            if (d.longitude != null) {
                m.longitude = Number(d.longitude);
            }
            if (d.postalCode != null) {
                m.postalCode = String(d.postalCode);
            }
            if (d.state != null) {
                m.state = d.state | 0;
            }
            if (d.city != null) {
                m.city = String(d.city);
            }
            if (d.address != null) {
                m.address = String(d.address);
            }
            if (d.addressDetail != null) {
                m.addressDetail = String(d.addressDetail);
            }
            if (d.range != null) {
                m.range = d.range | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreLocation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreLocation
         * @static
         * @param {Proto.WorkStoreLocation} m WorkStoreLocation
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreLocation.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.name = "";
                d.latitude = 0;
                d.longitude = 0;
                d.postalCode = "";
                d.state = 0;
                d.city = "";
                d.address = "";
                d.addressDetail = "";
                d.range = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.latitude != null && m.hasOwnProperty("latitude")) {
                d.latitude = o.json && !isFinite(m.latitude) ? String(m.latitude) : m.latitude;
            }
            if (m.longitude != null && m.hasOwnProperty("longitude")) {
                d.longitude = o.json && !isFinite(m.longitude) ? String(m.longitude) : m.longitude;
            }
            if (m.postalCode != null && m.hasOwnProperty("postalCode")) {
                d.postalCode = m.postalCode;
            }
            if (m.state != null && m.hasOwnProperty("state")) {
                d.state = m.state;
            }
            if (m.city != null && m.hasOwnProperty("city")) {
                d.city = m.city;
            }
            if (m.address != null && m.hasOwnProperty("address")) {
                d.address = m.address;
            }
            if (m.addressDetail != null && m.hasOwnProperty("addressDetail")) {
                d.addressDetail = m.addressDetail;
            }
            if (m.range != null && m.hasOwnProperty("range")) {
                d.range = m.range;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        /**
         * Converts this WorkStoreLocation to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreLocation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreLocation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreLocation;
    })();

    Proto.WorkStaff = (function() {

        /**
         * Properties of a WorkStaff.
         * @memberof Proto
         * @interface IWorkStaff
         * @property {string|null} [uid] WorkStaff uid
         * @property {number|null} [type] WorkStaff type
         * @property {string|null} [lastname] WorkStaff lastname
         * @property {string|null} [firstname] WorkStaff firstname
         * @property {string|null} [lastnameFuri] WorkStaff lastnameFuri
         * @property {string|null} [firstnameFuri] WorkStaff firstnameFuri
         * @property {number|null} [gender] WorkStaff gender
         * @property {string|null} [phone] WorkStaff phone
         * @property {string|null} [postalCode] WorkStaff postalCode
         * @property {number|null} [state] WorkStaff state
         * @property {string|null} [city] WorkStaff city
         * @property {string|null} [address] WorkStaff address
         * @property {string|null} [addressDetail] WorkStaff addressDetail
         * @property {number|null} [payHour] WorkStaff payHour
         * @property {string|null} [currency] WorkStaff currency
         * @property {number|null} [transportationFee] WorkStaff transportationFee
         * @property {string|null} [staffCode] WorkStaff staffCode
         * @property {string|null} [fixedTimetable] WorkStaff fixedTimetable
         * @property {string|null} [timetableComment] WorkStaff timetableComment
         * @property {string|null} [memo] WorkStaff memo
         * @property {string|null} [joinedDate] WorkStaff joinedDate
         * @property {string|null} [exitDate] WorkStaff exitDate
         * @property {number|Long|null} [registerDate] WorkStaff registerDate
         * @property {number|Long|null} [modifyDate] WorkStaff modifyDate
         * @property {number|null} [status] WorkStaff status
         * @property {Proto.IUser|null} [user] WorkStaff user
         * @property {Array.<Proto.IWorkStoreStaffMap>|null} [storeMapList] WorkStaff storeMapList
         * @property {Proto.IWorkHire|null} [hire] WorkStaff hire
         * @property {number|null} [hireType] WorkStaff hireType
         * @property {Array.<string>|null} [skillUIDList] WorkStaff skillUIDList
         * @property {Array.<string>|null} [attrUIDList] WorkStaff attrUIDList
         * @property {Proto.IWorkStaffExpectTime|null} [workHour] WorkStaff workHour
         * @property {Proto.IWorkStaffExpectTime|null} [overtimeWorkHour] WorkStaff overtimeWorkHour
         * @property {Proto.IWorkStaffExpectTime|null} [workDay] WorkStaff workDay
         * @property {boolean|null} [calculateOvertime] WorkStaff calculateOvertime
         * @property {number|null} [midnightWorkType] WorkStaff midnightWorkType
         * @property {Proto.IWorkStaffVacationSetting|null} [vacationSetting] WorkStaff vacationSetting
         * @property {Proto.IWorkStaffVacationSetting|null} [compVacationSetting] WorkStaff compVacationSetting
         * @property {Proto.IWorkStaffVacationSetting|null} [additionalVacationSetting] WorkStaff additionalVacationSetting
         * @property {boolean|null} [absence] WorkStaff absence
         * @property {string|null} [absenceStartDate] WorkStaff absenceStartDate
         * @property {string|null} [absenceEndDate] WorkStaff absenceEndDate
         * @property {Array.<Proto.IWorkSkill>|null} [skillList] WorkStaff skillList
         * @property {Array.<Proto.IWorkAttribute>|null} [attrList] WorkStaff attrList
         * @property {number|null} [currentStatus] WorkStaff currentStatus
         * @property {number|Long|null} [currentStatusDate] WorkStaff currentStatusDate
         * @property {number|null} [vacationDay] WorkStaff vacationDay
         * @property {number|null} [compVacationDay] WorkStaff compVacationDay
         * @property {number|null} [additionalVacationDay] WorkStaff additionalVacationDay
         * @property {number|null} [transferredVacationDay] WorkStaff transferredVacationDay
         * @property {number|null} [allVacationDay] WorkStaff allVacationDay
         * @property {number|null} [allCompVacationDay] WorkStaff allCompVacationDay
         * @property {Proto.IWorkStoreStaffSalaryMonth|null} [salaryTotal] WorkStaff salaryTotal
         * @property {string|null} [allSaturdayDays] WorkStaff allSaturdayDays
         * @property {string|null} [allSundayDays] WorkStaff allSundayDays
         * @property {number|null} [allAdditionalVacationDay] WorkStaff allAdditionalVacationDay
         * @property {number|null} [allTransferredVacationDay] WorkStaff allTransferredVacationDay
         */

        /**
         * Constructs a new WorkStaff.
         * @memberof Proto
         * @classdesc Represents a WorkStaff.
         * @implements IWorkStaff
         * @constructor
         * @param {Proto.IWorkStaff=} [p] Properties to set
         */
        function WorkStaff(p) {
            this.storeMapList = [];
            this.skillUIDList = [];
            this.attrUIDList = [];
            this.skillList = [];
            this.attrList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStaff uid.
         * @member {string} uid
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.uid = "";

        /**
         * WorkStaff type.
         * @member {number} type
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.type = 0;

        /**
         * WorkStaff lastname.
         * @member {string} lastname
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.lastname = "";

        /**
         * WorkStaff firstname.
         * @member {string} firstname
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.firstname = "";

        /**
         * WorkStaff lastnameFuri.
         * @member {string} lastnameFuri
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.lastnameFuri = "";

        /**
         * WorkStaff firstnameFuri.
         * @member {string} firstnameFuri
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.firstnameFuri = "";

        /**
         * WorkStaff gender.
         * @member {number} gender
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.gender = 0;

        /**
         * WorkStaff phone.
         * @member {string} phone
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.phone = "";

        /**
         * WorkStaff postalCode.
         * @member {string} postalCode
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.postalCode = "";

        /**
         * WorkStaff state.
         * @member {number} state
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.state = 0;

        /**
         * WorkStaff city.
         * @member {string} city
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.city = "";

        /**
         * WorkStaff address.
         * @member {string} address
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.address = "";

        /**
         * WorkStaff addressDetail.
         * @member {string} addressDetail
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.addressDetail = "";

        /**
         * WorkStaff payHour.
         * @member {number} payHour
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.payHour = 0;

        /**
         * WorkStaff currency.
         * @member {string} currency
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.currency = "";

        /**
         * WorkStaff transportationFee.
         * @member {number} transportationFee
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.transportationFee = 0;

        /**
         * WorkStaff staffCode.
         * @member {string} staffCode
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.staffCode = "";

        /**
         * WorkStaff fixedTimetable.
         * @member {string} fixedTimetable
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.fixedTimetable = "";

        /**
         * WorkStaff timetableComment.
         * @member {string} timetableComment
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.timetableComment = "";

        /**
         * WorkStaff memo.
         * @member {string} memo
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.memo = "";

        /**
         * WorkStaff joinedDate.
         * @member {string} joinedDate
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.joinedDate = "";

        /**
         * WorkStaff exitDate.
         * @member {string} exitDate
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.exitDate = "";

        /**
         * WorkStaff registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStaff modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStaff status.
         * @member {number} status
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.status = 0;

        /**
         * WorkStaff user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.user = null;

        /**
         * WorkStaff storeMapList.
         * @member {Array.<Proto.IWorkStoreStaffMap>} storeMapList
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.storeMapList = $util.emptyArray;

        /**
         * WorkStaff hire.
         * @member {Proto.IWorkHire|null|undefined} hire
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.hire = null;

        /**
         * WorkStaff hireType.
         * @member {number} hireType
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.hireType = 0;

        /**
         * WorkStaff skillUIDList.
         * @member {Array.<string>} skillUIDList
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.skillUIDList = $util.emptyArray;

        /**
         * WorkStaff attrUIDList.
         * @member {Array.<string>} attrUIDList
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.attrUIDList = $util.emptyArray;

        /**
         * WorkStaff workHour.
         * @member {Proto.IWorkStaffExpectTime|null|undefined} workHour
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.workHour = null;

        /**
         * WorkStaff overtimeWorkHour.
         * @member {Proto.IWorkStaffExpectTime|null|undefined} overtimeWorkHour
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.overtimeWorkHour = null;

        /**
         * WorkStaff workDay.
         * @member {Proto.IWorkStaffExpectTime|null|undefined} workDay
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.workDay = null;

        /**
         * WorkStaff calculateOvertime.
         * @member {boolean} calculateOvertime
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.calculateOvertime = false;

        /**
         * WorkStaff midnightWorkType.
         * @member {number} midnightWorkType
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.midnightWorkType = 0;

        /**
         * WorkStaff vacationSetting.
         * @member {Proto.IWorkStaffVacationSetting|null|undefined} vacationSetting
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.vacationSetting = null;

        /**
         * WorkStaff compVacationSetting.
         * @member {Proto.IWorkStaffVacationSetting|null|undefined} compVacationSetting
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.compVacationSetting = null;

        /**
         * WorkStaff additionalVacationSetting.
         * @member {Proto.IWorkStaffVacationSetting|null|undefined} additionalVacationSetting
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.additionalVacationSetting = null;

        /**
         * WorkStaff absence.
         * @member {boolean} absence
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.absence = false;

        /**
         * WorkStaff absenceStartDate.
         * @member {string} absenceStartDate
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.absenceStartDate = "";

        /**
         * WorkStaff absenceEndDate.
         * @member {string} absenceEndDate
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.absenceEndDate = "";

        /**
         * WorkStaff skillList.
         * @member {Array.<Proto.IWorkSkill>} skillList
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.skillList = $util.emptyArray;

        /**
         * WorkStaff attrList.
         * @member {Array.<Proto.IWorkAttribute>} attrList
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.attrList = $util.emptyArray;

        /**
         * WorkStaff currentStatus.
         * @member {number} currentStatus
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.currentStatus = 0;

        /**
         * WorkStaff currentStatusDate.
         * @member {number|Long} currentStatusDate
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.currentStatusDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStaff vacationDay.
         * @member {number} vacationDay
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.vacationDay = 0;

        /**
         * WorkStaff compVacationDay.
         * @member {number} compVacationDay
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.compVacationDay = 0;

        /**
         * WorkStaff additionalVacationDay.
         * @member {number} additionalVacationDay
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.additionalVacationDay = 0;

        /**
         * WorkStaff transferredVacationDay.
         * @member {number} transferredVacationDay
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.transferredVacationDay = 0;

        /**
         * WorkStaff allVacationDay.
         * @member {number} allVacationDay
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.allVacationDay = 0;

        /**
         * WorkStaff allCompVacationDay.
         * @member {number} allCompVacationDay
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.allCompVacationDay = 0;

        /**
         * WorkStaff salaryTotal.
         * @member {Proto.IWorkStoreStaffSalaryMonth|null|undefined} salaryTotal
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.salaryTotal = null;

        /**
         * WorkStaff allSaturdayDays.
         * @member {string} allSaturdayDays
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.allSaturdayDays = "";

        /**
         * WorkStaff allSundayDays.
         * @member {string} allSundayDays
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.allSundayDays = "";

        /**
         * WorkStaff allAdditionalVacationDay.
         * @member {number} allAdditionalVacationDay
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.allAdditionalVacationDay = 0;

        /**
         * WorkStaff allTransferredVacationDay.
         * @member {number} allTransferredVacationDay
         * @memberof Proto.WorkStaff
         * @instance
         */
        WorkStaff.prototype.allTransferredVacationDay = 0;

        /**
         * Creates a new WorkStaff instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStaff
         * @static
         * @param {Proto.IWorkStaff=} [properties] Properties to set
         * @returns {Proto.WorkStaff} WorkStaff instance
         */
        WorkStaff.create = function create(properties) {
            return new WorkStaff(properties);
        };

        /**
         * Encodes the specified WorkStaff message. Does not implicitly {@link Proto.WorkStaff.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStaff
         * @static
         * @param {Proto.IWorkStaff} m WorkStaff message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStaff.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.lastname != null && Object.hasOwnProperty.call(m, "lastname"))
                w.uint32(26).string(m.lastname);
            if (m.firstname != null && Object.hasOwnProperty.call(m, "firstname"))
                w.uint32(34).string(m.firstname);
            if (m.lastnameFuri != null && Object.hasOwnProperty.call(m, "lastnameFuri"))
                w.uint32(42).string(m.lastnameFuri);
            if (m.firstnameFuri != null && Object.hasOwnProperty.call(m, "firstnameFuri"))
                w.uint32(50).string(m.firstnameFuri);
            if (m.gender != null && Object.hasOwnProperty.call(m, "gender"))
                w.uint32(56).int32(m.gender);
            if (m.phone != null && Object.hasOwnProperty.call(m, "phone"))
                w.uint32(66).string(m.phone);
            if (m.postalCode != null && Object.hasOwnProperty.call(m, "postalCode"))
                w.uint32(74).string(m.postalCode);
            if (m.state != null && Object.hasOwnProperty.call(m, "state"))
                w.uint32(80).int32(m.state);
            if (m.city != null && Object.hasOwnProperty.call(m, "city"))
                w.uint32(90).string(m.city);
            if (m.address != null && Object.hasOwnProperty.call(m, "address"))
                w.uint32(98).string(m.address);
            if (m.addressDetail != null && Object.hasOwnProperty.call(m, "addressDetail"))
                w.uint32(106).string(m.addressDetail);
            if (m.payHour != null && Object.hasOwnProperty.call(m, "payHour"))
                w.uint32(113).double(m.payHour);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(122).string(m.currency);
            if (m.transportationFee != null && Object.hasOwnProperty.call(m, "transportationFee"))
                w.uint32(129).double(m.transportationFee);
            if (m.staffCode != null && Object.hasOwnProperty.call(m, "staffCode"))
                w.uint32(138).string(m.staffCode);
            if (m.fixedTimetable != null && Object.hasOwnProperty.call(m, "fixedTimetable"))
                w.uint32(146).string(m.fixedTimetable);
            if (m.timetableComment != null && Object.hasOwnProperty.call(m, "timetableComment"))
                w.uint32(154).string(m.timetableComment);
            if (m.memo != null && Object.hasOwnProperty.call(m, "memo"))
                w.uint32(162).string(m.memo);
            if (m.joinedDate != null && Object.hasOwnProperty.call(m, "joinedDate"))
                w.uint32(170).string(m.joinedDate);
            if (m.exitDate != null && Object.hasOwnProperty.call(m, "exitDate"))
                w.uint32(178).string(m.exitDate);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(184).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(192).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(200).int32(m.status);
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(210).fork()).ldelim();
            if (m.storeMapList != null && m.storeMapList.length) {
                for (var i = 0; i < m.storeMapList.length; ++i)
                    $root.Proto.WorkStoreStaffMap.encode(m.storeMapList[i], w.uint32(218).fork()).ldelim();
            }
            if (m.hire != null && Object.hasOwnProperty.call(m, "hire"))
                $root.Proto.WorkHire.encode(m.hire, w.uint32(226).fork()).ldelim();
            if (m.hireType != null && Object.hasOwnProperty.call(m, "hireType"))
                w.uint32(232).int32(m.hireType);
            if (m.skillUIDList != null && m.skillUIDList.length) {
                for (var i = 0; i < m.skillUIDList.length; ++i)
                    w.uint32(242).string(m.skillUIDList[i]);
            }
            if (m.attrUIDList != null && m.attrUIDList.length) {
                for (var i = 0; i < m.attrUIDList.length; ++i)
                    w.uint32(250).string(m.attrUIDList[i]);
            }
            if (m.workHour != null && Object.hasOwnProperty.call(m, "workHour"))
                $root.Proto.WorkStaffExpectTime.encode(m.workHour, w.uint32(258).fork()).ldelim();
            if (m.overtimeWorkHour != null && Object.hasOwnProperty.call(m, "overtimeWorkHour"))
                $root.Proto.WorkStaffExpectTime.encode(m.overtimeWorkHour, w.uint32(266).fork()).ldelim();
            if (m.workDay != null && Object.hasOwnProperty.call(m, "workDay"))
                $root.Proto.WorkStaffExpectTime.encode(m.workDay, w.uint32(274).fork()).ldelim();
            if (m.calculateOvertime != null && Object.hasOwnProperty.call(m, "calculateOvertime"))
                w.uint32(280).bool(m.calculateOvertime);
            if (m.midnightWorkType != null && Object.hasOwnProperty.call(m, "midnightWorkType"))
                w.uint32(288).int32(m.midnightWorkType);
            if (m.vacationSetting != null && Object.hasOwnProperty.call(m, "vacationSetting"))
                $root.Proto.WorkStaffVacationSetting.encode(m.vacationSetting, w.uint32(298).fork()).ldelim();
            if (m.compVacationSetting != null && Object.hasOwnProperty.call(m, "compVacationSetting"))
                $root.Proto.WorkStaffVacationSetting.encode(m.compVacationSetting, w.uint32(306).fork()).ldelim();
            if (m.additionalVacationSetting != null && Object.hasOwnProperty.call(m, "additionalVacationSetting"))
                $root.Proto.WorkStaffVacationSetting.encode(m.additionalVacationSetting, w.uint32(314).fork()).ldelim();
            if (m.absence != null && Object.hasOwnProperty.call(m, "absence"))
                w.uint32(320).bool(m.absence);
            if (m.absenceStartDate != null && Object.hasOwnProperty.call(m, "absenceStartDate"))
                w.uint32(330).string(m.absenceStartDate);
            if (m.absenceEndDate != null && Object.hasOwnProperty.call(m, "absenceEndDate"))
                w.uint32(338).string(m.absenceEndDate);
            if (m.skillList != null && m.skillList.length) {
                for (var i = 0; i < m.skillList.length; ++i)
                    $root.Proto.WorkSkill.encode(m.skillList[i], w.uint32(346).fork()).ldelim();
            }
            if (m.attrList != null && m.attrList.length) {
                for (var i = 0; i < m.attrList.length; ++i)
                    $root.Proto.WorkAttribute.encode(m.attrList[i], w.uint32(354).fork()).ldelim();
            }
            if (m.currentStatus != null && Object.hasOwnProperty.call(m, "currentStatus"))
                w.uint32(360).int32(m.currentStatus);
            if (m.currentStatusDate != null && Object.hasOwnProperty.call(m, "currentStatusDate"))
                w.uint32(368).int64(m.currentStatusDate);
            if (m.vacationDay != null && Object.hasOwnProperty.call(m, "vacationDay"))
                w.uint32(376).int32(m.vacationDay);
            if (m.compVacationDay != null && Object.hasOwnProperty.call(m, "compVacationDay"))
                w.uint32(384).int32(m.compVacationDay);
            if (m.additionalVacationDay != null && Object.hasOwnProperty.call(m, "additionalVacationDay"))
                w.uint32(392).int32(m.additionalVacationDay);
            if (m.transferredVacationDay != null && Object.hasOwnProperty.call(m, "transferredVacationDay"))
                w.uint32(400).int32(m.transferredVacationDay);
            if (m.allVacationDay != null && Object.hasOwnProperty.call(m, "allVacationDay"))
                w.uint32(408).int32(m.allVacationDay);
            if (m.allCompVacationDay != null && Object.hasOwnProperty.call(m, "allCompVacationDay"))
                w.uint32(416).int32(m.allCompVacationDay);
            if (m.salaryTotal != null && Object.hasOwnProperty.call(m, "salaryTotal"))
                $root.Proto.WorkStoreStaffSalaryMonth.encode(m.salaryTotal, w.uint32(426).fork()).ldelim();
            if (m.allSaturdayDays != null && Object.hasOwnProperty.call(m, "allSaturdayDays"))
                w.uint32(434).string(m.allSaturdayDays);
            if (m.allSundayDays != null && Object.hasOwnProperty.call(m, "allSundayDays"))
                w.uint32(442).string(m.allSundayDays);
            if (m.allAdditionalVacationDay != null && Object.hasOwnProperty.call(m, "allAdditionalVacationDay"))
                w.uint32(448).int32(m.allAdditionalVacationDay);
            if (m.allTransferredVacationDay != null && Object.hasOwnProperty.call(m, "allTransferredVacationDay"))
                w.uint32(456).int32(m.allTransferredVacationDay);
            return w;
        };

        /**
         * Decodes a WorkStaff message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStaff
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStaff} WorkStaff
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStaff.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStaff();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.lastname = r.string();
                    break;
                case 4:
                    m.firstname = r.string();
                    break;
                case 5:
                    m.lastnameFuri = r.string();
                    break;
                case 6:
                    m.firstnameFuri = r.string();
                    break;
                case 7:
                    m.gender = r.int32();
                    break;
                case 8:
                    m.phone = r.string();
                    break;
                case 9:
                    m.postalCode = r.string();
                    break;
                case 10:
                    m.state = r.int32();
                    break;
                case 11:
                    m.city = r.string();
                    break;
                case 12:
                    m.address = r.string();
                    break;
                case 13:
                    m.addressDetail = r.string();
                    break;
                case 14:
                    m.payHour = r.double();
                    break;
                case 15:
                    m.currency = r.string();
                    break;
                case 16:
                    m.transportationFee = r.double();
                    break;
                case 17:
                    m.staffCode = r.string();
                    break;
                case 18:
                    m.fixedTimetable = r.string();
                    break;
                case 19:
                    m.timetableComment = r.string();
                    break;
                case 20:
                    m.memo = r.string();
                    break;
                case 21:
                    m.joinedDate = r.string();
                    break;
                case 22:
                    m.exitDate = r.string();
                    break;
                case 23:
                    m.registerDate = r.int64();
                    break;
                case 24:
                    m.modifyDate = r.int64();
                    break;
                case 25:
                    m.status = r.int32();
                    break;
                case 26:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 27:
                    if (!(m.storeMapList && m.storeMapList.length))
                        m.storeMapList = [];
                    m.storeMapList.push($root.Proto.WorkStoreStaffMap.decode(r, r.uint32()));
                    break;
                case 28:
                    m.hire = $root.Proto.WorkHire.decode(r, r.uint32());
                    break;
                case 29:
                    m.hireType = r.int32();
                    break;
                case 30:
                    if (!(m.skillUIDList && m.skillUIDList.length))
                        m.skillUIDList = [];
                    m.skillUIDList.push(r.string());
                    break;
                case 31:
                    if (!(m.attrUIDList && m.attrUIDList.length))
                        m.attrUIDList = [];
                    m.attrUIDList.push(r.string());
                    break;
                case 32:
                    m.workHour = $root.Proto.WorkStaffExpectTime.decode(r, r.uint32());
                    break;
                case 33:
                    m.overtimeWorkHour = $root.Proto.WorkStaffExpectTime.decode(r, r.uint32());
                    break;
                case 34:
                    m.workDay = $root.Proto.WorkStaffExpectTime.decode(r, r.uint32());
                    break;
                case 35:
                    m.calculateOvertime = r.bool();
                    break;
                case 36:
                    m.midnightWorkType = r.int32();
                    break;
                case 37:
                    m.vacationSetting = $root.Proto.WorkStaffVacationSetting.decode(r, r.uint32());
                    break;
                case 38:
                    m.compVacationSetting = $root.Proto.WorkStaffVacationSetting.decode(r, r.uint32());
                    break;
                case 39:
                    m.additionalVacationSetting = $root.Proto.WorkStaffVacationSetting.decode(r, r.uint32());
                    break;
                case 40:
                    m.absence = r.bool();
                    break;
                case 41:
                    m.absenceStartDate = r.string();
                    break;
                case 42:
                    m.absenceEndDate = r.string();
                    break;
                case 43:
                    if (!(m.skillList && m.skillList.length))
                        m.skillList = [];
                    m.skillList.push($root.Proto.WorkSkill.decode(r, r.uint32()));
                    break;
                case 44:
                    if (!(m.attrList && m.attrList.length))
                        m.attrList = [];
                    m.attrList.push($root.Proto.WorkAttribute.decode(r, r.uint32()));
                    break;
                case 45:
                    m.currentStatus = r.int32();
                    break;
                case 46:
                    m.currentStatusDate = r.int64();
                    break;
                case 47:
                    m.vacationDay = r.int32();
                    break;
                case 48:
                    m.compVacationDay = r.int32();
                    break;
                case 49:
                    m.additionalVacationDay = r.int32();
                    break;
                case 50:
                    m.transferredVacationDay = r.int32();
                    break;
                case 51:
                    m.allVacationDay = r.int32();
                    break;
                case 52:
                    m.allCompVacationDay = r.int32();
                    break;
                case 53:
                    m.salaryTotal = $root.Proto.WorkStoreStaffSalaryMonth.decode(r, r.uint32());
                    break;
                case 54:
                    m.allSaturdayDays = r.string();
                    break;
                case 55:
                    m.allSundayDays = r.string();
                    break;
                case 56:
                    m.allAdditionalVacationDay = r.int32();
                    break;
                case 57:
                    m.allTransferredVacationDay = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStaff message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStaff
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStaff} WorkStaff
         */
        WorkStaff.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStaff)
                return d;
            var m = new $root.Proto.WorkStaff();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.lastname != null) {
                m.lastname = String(d.lastname);
            }
            if (d.firstname != null) {
                m.firstname = String(d.firstname);
            }
            if (d.lastnameFuri != null) {
                m.lastnameFuri = String(d.lastnameFuri);
            }
            if (d.firstnameFuri != null) {
                m.firstnameFuri = String(d.firstnameFuri);
            }
            if (d.gender != null) {
                m.gender = d.gender | 0;
            }
            if (d.phone != null) {
                m.phone = String(d.phone);
            }
            if (d.postalCode != null) {
                m.postalCode = String(d.postalCode);
            }
            if (d.state != null) {
                m.state = d.state | 0;
            }
            if (d.city != null) {
                m.city = String(d.city);
            }
            if (d.address != null) {
                m.address = String(d.address);
            }
            if (d.addressDetail != null) {
                m.addressDetail = String(d.addressDetail);
            }
            if (d.payHour != null) {
                m.payHour = Number(d.payHour);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.transportationFee != null) {
                m.transportationFee = Number(d.transportationFee);
            }
            if (d.staffCode != null) {
                m.staffCode = String(d.staffCode);
            }
            if (d.fixedTimetable != null) {
                m.fixedTimetable = String(d.fixedTimetable);
            }
            if (d.timetableComment != null) {
                m.timetableComment = String(d.timetableComment);
            }
            if (d.memo != null) {
                m.memo = String(d.memo);
            }
            if (d.joinedDate != null) {
                m.joinedDate = String(d.joinedDate);
            }
            if (d.exitDate != null) {
                m.exitDate = String(d.exitDate);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.WorkStaff.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            if (d.storeMapList) {
                if (!Array.isArray(d.storeMapList))
                    throw TypeError(".Proto.WorkStaff.storeMapList: array expected");
                m.storeMapList = [];
                for (var i = 0; i < d.storeMapList.length; ++i) {
                    if (typeof d.storeMapList[i] !== "object")
                        throw TypeError(".Proto.WorkStaff.storeMapList: object expected");
                    m.storeMapList[i] = $root.Proto.WorkStoreStaffMap.fromObject(d.storeMapList[i]);
                }
            }
            if (d.hire != null) {
                if (typeof d.hire !== "object")
                    throw TypeError(".Proto.WorkStaff.hire: object expected");
                m.hire = $root.Proto.WorkHire.fromObject(d.hire);
            }
            if (d.hireType != null) {
                m.hireType = d.hireType | 0;
            }
            if (d.skillUIDList) {
                if (!Array.isArray(d.skillUIDList))
                    throw TypeError(".Proto.WorkStaff.skillUIDList: array expected");
                m.skillUIDList = [];
                for (var i = 0; i < d.skillUIDList.length; ++i) {
                    m.skillUIDList[i] = String(d.skillUIDList[i]);
                }
            }
            if (d.attrUIDList) {
                if (!Array.isArray(d.attrUIDList))
                    throw TypeError(".Proto.WorkStaff.attrUIDList: array expected");
                m.attrUIDList = [];
                for (var i = 0; i < d.attrUIDList.length; ++i) {
                    m.attrUIDList[i] = String(d.attrUIDList[i]);
                }
            }
            if (d.workHour != null) {
                if (typeof d.workHour !== "object")
                    throw TypeError(".Proto.WorkStaff.workHour: object expected");
                m.workHour = $root.Proto.WorkStaffExpectTime.fromObject(d.workHour);
            }
            if (d.overtimeWorkHour != null) {
                if (typeof d.overtimeWorkHour !== "object")
                    throw TypeError(".Proto.WorkStaff.overtimeWorkHour: object expected");
                m.overtimeWorkHour = $root.Proto.WorkStaffExpectTime.fromObject(d.overtimeWorkHour);
            }
            if (d.workDay != null) {
                if (typeof d.workDay !== "object")
                    throw TypeError(".Proto.WorkStaff.workDay: object expected");
                m.workDay = $root.Proto.WorkStaffExpectTime.fromObject(d.workDay);
            }
            if (d.calculateOvertime != null) {
                m.calculateOvertime = Boolean(d.calculateOvertime);
            }
            if (d.midnightWorkType != null) {
                m.midnightWorkType = d.midnightWorkType | 0;
            }
            if (d.vacationSetting != null) {
                if (typeof d.vacationSetting !== "object")
                    throw TypeError(".Proto.WorkStaff.vacationSetting: object expected");
                m.vacationSetting = $root.Proto.WorkStaffVacationSetting.fromObject(d.vacationSetting);
            }
            if (d.compVacationSetting != null) {
                if (typeof d.compVacationSetting !== "object")
                    throw TypeError(".Proto.WorkStaff.compVacationSetting: object expected");
                m.compVacationSetting = $root.Proto.WorkStaffVacationSetting.fromObject(d.compVacationSetting);
            }
            if (d.additionalVacationSetting != null) {
                if (typeof d.additionalVacationSetting !== "object")
                    throw TypeError(".Proto.WorkStaff.additionalVacationSetting: object expected");
                m.additionalVacationSetting = $root.Proto.WorkStaffVacationSetting.fromObject(d.additionalVacationSetting);
            }
            if (d.absence != null) {
                m.absence = Boolean(d.absence);
            }
            if (d.absenceStartDate != null) {
                m.absenceStartDate = String(d.absenceStartDate);
            }
            if (d.absenceEndDate != null) {
                m.absenceEndDate = String(d.absenceEndDate);
            }
            if (d.skillList) {
                if (!Array.isArray(d.skillList))
                    throw TypeError(".Proto.WorkStaff.skillList: array expected");
                m.skillList = [];
                for (var i = 0; i < d.skillList.length; ++i) {
                    if (typeof d.skillList[i] !== "object")
                        throw TypeError(".Proto.WorkStaff.skillList: object expected");
                    m.skillList[i] = $root.Proto.WorkSkill.fromObject(d.skillList[i]);
                }
            }
            if (d.attrList) {
                if (!Array.isArray(d.attrList))
                    throw TypeError(".Proto.WorkStaff.attrList: array expected");
                m.attrList = [];
                for (var i = 0; i < d.attrList.length; ++i) {
                    if (typeof d.attrList[i] !== "object")
                        throw TypeError(".Proto.WorkStaff.attrList: object expected");
                    m.attrList[i] = $root.Proto.WorkAttribute.fromObject(d.attrList[i]);
                }
            }
            if (d.currentStatus != null) {
                m.currentStatus = d.currentStatus | 0;
            }
            if (d.currentStatusDate != null) {
                if ($util.Long)
                    (m.currentStatusDate = $util.Long.fromValue(d.currentStatusDate)).unsigned = false;
                else if (typeof d.currentStatusDate === "string")
                    m.currentStatusDate = parseInt(d.currentStatusDate, 10);
                else if (typeof d.currentStatusDate === "number")
                    m.currentStatusDate = d.currentStatusDate;
                else if (typeof d.currentStatusDate === "object")
                    m.currentStatusDate = new $util.LongBits(d.currentStatusDate.low >>> 0, d.currentStatusDate.high >>> 0).toNumber();
            }
            if (d.vacationDay != null) {
                m.vacationDay = d.vacationDay | 0;
            }
            if (d.compVacationDay != null) {
                m.compVacationDay = d.compVacationDay | 0;
            }
            if (d.additionalVacationDay != null) {
                m.additionalVacationDay = d.additionalVacationDay | 0;
            }
            if (d.transferredVacationDay != null) {
                m.transferredVacationDay = d.transferredVacationDay | 0;
            }
            if (d.allVacationDay != null) {
                m.allVacationDay = d.allVacationDay | 0;
            }
            if (d.allCompVacationDay != null) {
                m.allCompVacationDay = d.allCompVacationDay | 0;
            }
            if (d.salaryTotal != null) {
                if (typeof d.salaryTotal !== "object")
                    throw TypeError(".Proto.WorkStaff.salaryTotal: object expected");
                m.salaryTotal = $root.Proto.WorkStoreStaffSalaryMonth.fromObject(d.salaryTotal);
            }
            if (d.allSaturdayDays != null) {
                m.allSaturdayDays = String(d.allSaturdayDays);
            }
            if (d.allSundayDays != null) {
                m.allSundayDays = String(d.allSundayDays);
            }
            if (d.allAdditionalVacationDay != null) {
                m.allAdditionalVacationDay = d.allAdditionalVacationDay | 0;
            }
            if (d.allTransferredVacationDay != null) {
                m.allTransferredVacationDay = d.allTransferredVacationDay | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStaff message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStaff
         * @static
         * @param {Proto.WorkStaff} m WorkStaff
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStaff.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.storeMapList = [];
                d.skillUIDList = [];
                d.attrUIDList = [];
                d.skillList = [];
                d.attrList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.lastname = "";
                d.firstname = "";
                d.lastnameFuri = "";
                d.firstnameFuri = "";
                d.gender = 0;
                d.phone = "";
                d.postalCode = "";
                d.state = 0;
                d.city = "";
                d.address = "";
                d.addressDetail = "";
                d.payHour = 0;
                d.currency = "";
                d.transportationFee = 0;
                d.staffCode = "";
                d.fixedTimetable = "";
                d.timetableComment = "";
                d.memo = "";
                d.joinedDate = "";
                d.exitDate = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.user = null;
                d.hire = null;
                d.hireType = 0;
                d.workHour = null;
                d.overtimeWorkHour = null;
                d.workDay = null;
                d.calculateOvertime = false;
                d.midnightWorkType = 0;
                d.vacationSetting = null;
                d.compVacationSetting = null;
                d.additionalVacationSetting = null;
                d.absence = false;
                d.absenceStartDate = "";
                d.absenceEndDate = "";
                d.currentStatus = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.currentStatusDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.currentStatusDate = o.longs === String ? "0" : 0;
                d.vacationDay = 0;
                d.compVacationDay = 0;
                d.additionalVacationDay = 0;
                d.transferredVacationDay = 0;
                d.allVacationDay = 0;
                d.allCompVacationDay = 0;
                d.salaryTotal = null;
                d.allSaturdayDays = "";
                d.allSundayDays = "";
                d.allAdditionalVacationDay = 0;
                d.allTransferredVacationDay = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.lastname != null && m.hasOwnProperty("lastname")) {
                d.lastname = m.lastname;
            }
            if (m.firstname != null && m.hasOwnProperty("firstname")) {
                d.firstname = m.firstname;
            }
            if (m.lastnameFuri != null && m.hasOwnProperty("lastnameFuri")) {
                d.lastnameFuri = m.lastnameFuri;
            }
            if (m.firstnameFuri != null && m.hasOwnProperty("firstnameFuri")) {
                d.firstnameFuri = m.firstnameFuri;
            }
            if (m.gender != null && m.hasOwnProperty("gender")) {
                d.gender = m.gender;
            }
            if (m.phone != null && m.hasOwnProperty("phone")) {
                d.phone = m.phone;
            }
            if (m.postalCode != null && m.hasOwnProperty("postalCode")) {
                d.postalCode = m.postalCode;
            }
            if (m.state != null && m.hasOwnProperty("state")) {
                d.state = m.state;
            }
            if (m.city != null && m.hasOwnProperty("city")) {
                d.city = m.city;
            }
            if (m.address != null && m.hasOwnProperty("address")) {
                d.address = m.address;
            }
            if (m.addressDetail != null && m.hasOwnProperty("addressDetail")) {
                d.addressDetail = m.addressDetail;
            }
            if (m.payHour != null && m.hasOwnProperty("payHour")) {
                d.payHour = o.json && !isFinite(m.payHour) ? String(m.payHour) : m.payHour;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.transportationFee != null && m.hasOwnProperty("transportationFee")) {
                d.transportationFee = o.json && !isFinite(m.transportationFee) ? String(m.transportationFee) : m.transportationFee;
            }
            if (m.staffCode != null && m.hasOwnProperty("staffCode")) {
                d.staffCode = m.staffCode;
            }
            if (m.fixedTimetable != null && m.hasOwnProperty("fixedTimetable")) {
                d.fixedTimetable = m.fixedTimetable;
            }
            if (m.timetableComment != null && m.hasOwnProperty("timetableComment")) {
                d.timetableComment = m.timetableComment;
            }
            if (m.memo != null && m.hasOwnProperty("memo")) {
                d.memo = m.memo;
            }
            if (m.joinedDate != null && m.hasOwnProperty("joinedDate")) {
                d.joinedDate = m.joinedDate;
            }
            if (m.exitDate != null && m.hasOwnProperty("exitDate")) {
                d.exitDate = m.exitDate;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            if (m.storeMapList && m.storeMapList.length) {
                d.storeMapList = [];
                for (var j = 0; j < m.storeMapList.length; ++j) {
                    d.storeMapList[j] = $root.Proto.WorkStoreStaffMap.toObject(m.storeMapList[j], o);
                }
            }
            if (m.hire != null && m.hasOwnProperty("hire")) {
                d.hire = $root.Proto.WorkHire.toObject(m.hire, o);
            }
            if (m.hireType != null && m.hasOwnProperty("hireType")) {
                d.hireType = m.hireType;
            }
            if (m.skillUIDList && m.skillUIDList.length) {
                d.skillUIDList = [];
                for (var j = 0; j < m.skillUIDList.length; ++j) {
                    d.skillUIDList[j] = m.skillUIDList[j];
                }
            }
            if (m.attrUIDList && m.attrUIDList.length) {
                d.attrUIDList = [];
                for (var j = 0; j < m.attrUIDList.length; ++j) {
                    d.attrUIDList[j] = m.attrUIDList[j];
                }
            }
            if (m.workHour != null && m.hasOwnProperty("workHour")) {
                d.workHour = $root.Proto.WorkStaffExpectTime.toObject(m.workHour, o);
            }
            if (m.overtimeWorkHour != null && m.hasOwnProperty("overtimeWorkHour")) {
                d.overtimeWorkHour = $root.Proto.WorkStaffExpectTime.toObject(m.overtimeWorkHour, o);
            }
            if (m.workDay != null && m.hasOwnProperty("workDay")) {
                d.workDay = $root.Proto.WorkStaffExpectTime.toObject(m.workDay, o);
            }
            if (m.calculateOvertime != null && m.hasOwnProperty("calculateOvertime")) {
                d.calculateOvertime = m.calculateOvertime;
            }
            if (m.midnightWorkType != null && m.hasOwnProperty("midnightWorkType")) {
                d.midnightWorkType = m.midnightWorkType;
            }
            if (m.vacationSetting != null && m.hasOwnProperty("vacationSetting")) {
                d.vacationSetting = $root.Proto.WorkStaffVacationSetting.toObject(m.vacationSetting, o);
            }
            if (m.compVacationSetting != null && m.hasOwnProperty("compVacationSetting")) {
                d.compVacationSetting = $root.Proto.WorkStaffVacationSetting.toObject(m.compVacationSetting, o);
            }
            if (m.additionalVacationSetting != null && m.hasOwnProperty("additionalVacationSetting")) {
                d.additionalVacationSetting = $root.Proto.WorkStaffVacationSetting.toObject(m.additionalVacationSetting, o);
            }
            if (m.absence != null && m.hasOwnProperty("absence")) {
                d.absence = m.absence;
            }
            if (m.absenceStartDate != null && m.hasOwnProperty("absenceStartDate")) {
                d.absenceStartDate = m.absenceStartDate;
            }
            if (m.absenceEndDate != null && m.hasOwnProperty("absenceEndDate")) {
                d.absenceEndDate = m.absenceEndDate;
            }
            if (m.skillList && m.skillList.length) {
                d.skillList = [];
                for (var j = 0; j < m.skillList.length; ++j) {
                    d.skillList[j] = $root.Proto.WorkSkill.toObject(m.skillList[j], o);
                }
            }
            if (m.attrList && m.attrList.length) {
                d.attrList = [];
                for (var j = 0; j < m.attrList.length; ++j) {
                    d.attrList[j] = $root.Proto.WorkAttribute.toObject(m.attrList[j], o);
                }
            }
            if (m.currentStatus != null && m.hasOwnProperty("currentStatus")) {
                d.currentStatus = m.currentStatus;
            }
            if (m.currentStatusDate != null && m.hasOwnProperty("currentStatusDate")) {
                if (typeof m.currentStatusDate === "number")
                    d.currentStatusDate = o.longs === String ? String(m.currentStatusDate) : m.currentStatusDate;
                else
                    d.currentStatusDate = o.longs === String ? $util.Long.prototype.toString.call(m.currentStatusDate) : o.longs === Number ? new $util.LongBits(m.currentStatusDate.low >>> 0, m.currentStatusDate.high >>> 0).toNumber() : m.currentStatusDate;
            }
            if (m.vacationDay != null && m.hasOwnProperty("vacationDay")) {
                d.vacationDay = m.vacationDay;
            }
            if (m.compVacationDay != null && m.hasOwnProperty("compVacationDay")) {
                d.compVacationDay = m.compVacationDay;
            }
            if (m.additionalVacationDay != null && m.hasOwnProperty("additionalVacationDay")) {
                d.additionalVacationDay = m.additionalVacationDay;
            }
            if (m.transferredVacationDay != null && m.hasOwnProperty("transferredVacationDay")) {
                d.transferredVacationDay = m.transferredVacationDay;
            }
            if (m.allVacationDay != null && m.hasOwnProperty("allVacationDay")) {
                d.allVacationDay = m.allVacationDay;
            }
            if (m.allCompVacationDay != null && m.hasOwnProperty("allCompVacationDay")) {
                d.allCompVacationDay = m.allCompVacationDay;
            }
            if (m.salaryTotal != null && m.hasOwnProperty("salaryTotal")) {
                d.salaryTotal = $root.Proto.WorkStoreStaffSalaryMonth.toObject(m.salaryTotal, o);
            }
            if (m.allSaturdayDays != null && m.hasOwnProperty("allSaturdayDays")) {
                d.allSaturdayDays = m.allSaturdayDays;
            }
            if (m.allSundayDays != null && m.hasOwnProperty("allSundayDays")) {
                d.allSundayDays = m.allSundayDays;
            }
            if (m.allAdditionalVacationDay != null && m.hasOwnProperty("allAdditionalVacationDay")) {
                d.allAdditionalVacationDay = m.allAdditionalVacationDay;
            }
            if (m.allTransferredVacationDay != null && m.hasOwnProperty("allTransferredVacationDay")) {
                d.allTransferredVacationDay = m.allTransferredVacationDay;
            }
            return d;
        };

        /**
         * Converts this WorkStaff to JSON.
         * @function toJSON
         * @memberof Proto.WorkStaff
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStaff.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStaff;
    })();

    Proto.WorkStaffList = (function() {

        /**
         * Properties of a WorkStaffList.
         * @memberof Proto
         * @interface IWorkStaffList
         * @property {Array.<Proto.IWorkStaff>|null} [value] WorkStaffList value
         */

        /**
         * Constructs a new WorkStaffList.
         * @memberof Proto
         * @classdesc Represents a WorkStaffList.
         * @implements IWorkStaffList
         * @constructor
         * @param {Proto.IWorkStaffList=} [p] Properties to set
         */
        function WorkStaffList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStaffList value.
         * @member {Array.<Proto.IWorkStaff>} value
         * @memberof Proto.WorkStaffList
         * @instance
         */
        WorkStaffList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkStaffList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStaffList
         * @static
         * @param {Proto.IWorkStaffList=} [properties] Properties to set
         * @returns {Proto.WorkStaffList} WorkStaffList instance
         */
        WorkStaffList.create = function create(properties) {
            return new WorkStaffList(properties);
        };

        /**
         * Encodes the specified WorkStaffList message. Does not implicitly {@link Proto.WorkStaffList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStaffList
         * @static
         * @param {Proto.IWorkStaffList} m WorkStaffList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStaffList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkStaff.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkStaffList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStaffList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStaffList} WorkStaffList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStaffList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStaffList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkStaff.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStaffList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStaffList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStaffList} WorkStaffList
         */
        WorkStaffList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStaffList)
                return d;
            var m = new $root.Proto.WorkStaffList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkStaffList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkStaffList.value: object expected");
                    m.value[i] = $root.Proto.WorkStaff.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStaffList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStaffList
         * @static
         * @param {Proto.WorkStaffList} m WorkStaffList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStaffList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkStaff.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkStaffList to JSON.
         * @function toJSON
         * @memberof Proto.WorkStaffList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStaffList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStaffList;
    })();

    Proto.WorkStoreStaffMap = (function() {

        /**
         * Properties of a WorkStoreStaffMap.
         * @memberof Proto
         * @interface IWorkStoreStaffMap
         * @property {string|null} [storeUID] WorkStoreStaffMap storeUID
         * @property {string|null} [staffUID] WorkStoreStaffMap staffUID
         * @property {string|null} [joinedDate] WorkStoreStaffMap joinedDate
         * @property {string|null} [exitDate] WorkStoreStaffMap exitDate
         * @property {number|Long|null} [registerDate] WorkStoreStaffMap registerDate
         * @property {number|Long|null} [modifyDate] WorkStoreStaffMap modifyDate
         * @property {number|null} [status] WorkStoreStaffMap status
         * @property {string|null} [storeName] WorkStoreStaffMap storeName
         */

        /**
         * Constructs a new WorkStoreStaffMap.
         * @memberof Proto
         * @classdesc Represents a WorkStoreStaffMap.
         * @implements IWorkStoreStaffMap
         * @constructor
         * @param {Proto.IWorkStoreStaffMap=} [p] Properties to set
         */
        function WorkStoreStaffMap(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreStaffMap storeUID.
         * @member {string} storeUID
         * @memberof Proto.WorkStoreStaffMap
         * @instance
         */
        WorkStoreStaffMap.prototype.storeUID = "";

        /**
         * WorkStoreStaffMap staffUID.
         * @member {string} staffUID
         * @memberof Proto.WorkStoreStaffMap
         * @instance
         */
        WorkStoreStaffMap.prototype.staffUID = "";

        /**
         * WorkStoreStaffMap joinedDate.
         * @member {string} joinedDate
         * @memberof Proto.WorkStoreStaffMap
         * @instance
         */
        WorkStoreStaffMap.prototype.joinedDate = "";

        /**
         * WorkStoreStaffMap exitDate.
         * @member {string} exitDate
         * @memberof Proto.WorkStoreStaffMap
         * @instance
         */
        WorkStoreStaffMap.prototype.exitDate = "";

        /**
         * WorkStoreStaffMap registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStoreStaffMap
         * @instance
         */
        WorkStoreStaffMap.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreStaffMap modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStoreStaffMap
         * @instance
         */
        WorkStoreStaffMap.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreStaffMap status.
         * @member {number} status
         * @memberof Proto.WorkStoreStaffMap
         * @instance
         */
        WorkStoreStaffMap.prototype.status = 0;

        /**
         * WorkStoreStaffMap storeName.
         * @member {string} storeName
         * @memberof Proto.WorkStoreStaffMap
         * @instance
         */
        WorkStoreStaffMap.prototype.storeName = "";

        /**
         * Creates a new WorkStoreStaffMap instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreStaffMap
         * @static
         * @param {Proto.IWorkStoreStaffMap=} [properties] Properties to set
         * @returns {Proto.WorkStoreStaffMap} WorkStoreStaffMap instance
         */
        WorkStoreStaffMap.create = function create(properties) {
            return new WorkStoreStaffMap(properties);
        };

        /**
         * Encodes the specified WorkStoreStaffMap message. Does not implicitly {@link Proto.WorkStoreStaffMap.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreStaffMap
         * @static
         * @param {Proto.IWorkStoreStaffMap} m WorkStoreStaffMap message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreStaffMap.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.storeUID != null && Object.hasOwnProperty.call(m, "storeUID"))
                w.uint32(10).string(m.storeUID);
            if (m.staffUID != null && Object.hasOwnProperty.call(m, "staffUID"))
                w.uint32(18).string(m.staffUID);
            if (m.joinedDate != null && Object.hasOwnProperty.call(m, "joinedDate"))
                w.uint32(26).string(m.joinedDate);
            if (m.exitDate != null && Object.hasOwnProperty.call(m, "exitDate"))
                w.uint32(34).string(m.exitDate);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(40).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(64).int32(m.status);
            if (m.storeName != null && Object.hasOwnProperty.call(m, "storeName"))
                w.uint32(74).string(m.storeName);
            return w;
        };

        /**
         * Decodes a WorkStoreStaffMap message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreStaffMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreStaffMap} WorkStoreStaffMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreStaffMap.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreStaffMap();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.storeUID = r.string();
                    break;
                case 2:
                    m.staffUID = r.string();
                    break;
                case 3:
                    m.joinedDate = r.string();
                    break;
                case 4:
                    m.exitDate = r.string();
                    break;
                case 5:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.status = r.int32();
                    break;
                case 9:
                    m.storeName = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreStaffMap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreStaffMap
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreStaffMap} WorkStoreStaffMap
         */
        WorkStoreStaffMap.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreStaffMap)
                return d;
            var m = new $root.Proto.WorkStoreStaffMap();
            if (d.storeUID != null) {
                m.storeUID = String(d.storeUID);
            }
            if (d.staffUID != null) {
                m.staffUID = String(d.staffUID);
            }
            if (d.joinedDate != null) {
                m.joinedDate = String(d.joinedDate);
            }
            if (d.exitDate != null) {
                m.exitDate = String(d.exitDate);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.storeName != null) {
                m.storeName = String(d.storeName);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreStaffMap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreStaffMap
         * @static
         * @param {Proto.WorkStoreStaffMap} m WorkStoreStaffMap
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreStaffMap.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.storeUID = "";
                d.staffUID = "";
                d.joinedDate = "";
                d.exitDate = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.storeName = "";
            }
            if (m.storeUID != null && m.hasOwnProperty("storeUID")) {
                d.storeUID = m.storeUID;
            }
            if (m.staffUID != null && m.hasOwnProperty("staffUID")) {
                d.staffUID = m.staffUID;
            }
            if (m.joinedDate != null && m.hasOwnProperty("joinedDate")) {
                d.joinedDate = m.joinedDate;
            }
            if (m.exitDate != null && m.hasOwnProperty("exitDate")) {
                d.exitDate = m.exitDate;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.storeName != null && m.hasOwnProperty("storeName")) {
                d.storeName = m.storeName;
            }
            return d;
        };

        /**
         * Converts this WorkStoreStaffMap to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreStaffMap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreStaffMap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreStaffMap;
    })();

    Proto.WorkStoreStaffMapList = (function() {

        /**
         * Properties of a WorkStoreStaffMapList.
         * @memberof Proto
         * @interface IWorkStoreStaffMapList
         * @property {Array.<Proto.IWorkStoreStaffMap>|null} [value] WorkStoreStaffMapList value
         */

        /**
         * Constructs a new WorkStoreStaffMapList.
         * @memberof Proto
         * @classdesc Represents a WorkStoreStaffMapList.
         * @implements IWorkStoreStaffMapList
         * @constructor
         * @param {Proto.IWorkStoreStaffMapList=} [p] Properties to set
         */
        function WorkStoreStaffMapList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreStaffMapList value.
         * @member {Array.<Proto.IWorkStoreStaffMap>} value
         * @memberof Proto.WorkStoreStaffMapList
         * @instance
         */
        WorkStoreStaffMapList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkStoreStaffMapList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreStaffMapList
         * @static
         * @param {Proto.IWorkStoreStaffMapList=} [properties] Properties to set
         * @returns {Proto.WorkStoreStaffMapList} WorkStoreStaffMapList instance
         */
        WorkStoreStaffMapList.create = function create(properties) {
            return new WorkStoreStaffMapList(properties);
        };

        /**
         * Encodes the specified WorkStoreStaffMapList message. Does not implicitly {@link Proto.WorkStoreStaffMapList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreStaffMapList
         * @static
         * @param {Proto.IWorkStoreStaffMapList} m WorkStoreStaffMapList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreStaffMapList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkStoreStaffMap.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkStoreStaffMapList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreStaffMapList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreStaffMapList} WorkStoreStaffMapList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreStaffMapList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreStaffMapList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkStoreStaffMap.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreStaffMapList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreStaffMapList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreStaffMapList} WorkStoreStaffMapList
         */
        WorkStoreStaffMapList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreStaffMapList)
                return d;
            var m = new $root.Proto.WorkStoreStaffMapList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkStoreStaffMapList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkStoreStaffMapList.value: object expected");
                    m.value[i] = $root.Proto.WorkStoreStaffMap.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreStaffMapList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreStaffMapList
         * @static
         * @param {Proto.WorkStoreStaffMapList} m WorkStoreStaffMapList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreStaffMapList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkStoreStaffMap.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkStoreStaffMapList to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreStaffMapList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreStaffMapList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreStaffMapList;
    })();

    Proto.WorkHire = (function() {

        /**
         * Properties of a WorkHire.
         * @memberof Proto
         * @interface IWorkHire
         * @property {string|null} [uid] WorkHire uid
         * @property {string|null} [name] WorkHire name
         * @property {string|null} [timezoneId] WorkHire timezoneId
         * @property {number|null} [timezoneOffset] WorkHire timezoneOffset
         * @property {number|Long|null} [startTime] WorkHire startTime
         * @property {number|Long|null} [endTime] WorkHire endTime
         * @property {number|Long|null} [lateStartTime] WorkHire lateStartTime
         * @property {number|Long|null} [lateEndTime] WorkHire lateEndTime
         * @property {number|Long|null} [registerDate] WorkHire registerDate
         * @property {number|Long|null} [modifyDate] WorkHire modifyDate
         * @property {number|null} [payHour] WorkHire payHour
         * @property {string|null} [currency] WorkHire currency
         * @property {number|null} [transportationFee] WorkHire transportationFee
         * @property {number|null} [workHourWeeklyHour] WorkHire workHourWeeklyHour
         * @property {number|null} [workHourMonthlyHour] WorkHire workHourMonthlyHour
         * @property {number|null} [workHourYearlyHour] WorkHire workHourYearlyHour
         * @property {number|null} [overtimeWeeklyHour] WorkHire overtimeWeeklyHour
         * @property {number|null} [overtimeMonthlyHour] WorkHire overtimeMonthlyHour
         * @property {number|null} [overtimeYearlyHour] WorkHire overtimeYearlyHour
         * @property {number|null} [workDayWeeklyHour] WorkHire workDayWeeklyHour
         * @property {number|null} [workDayMonthlyHour] WorkHire workDayMonthlyHour
         * @property {number|null} [workDayYearlyHour] WorkHire workDayYearlyHour
         * @property {number|null} [vacationFirstYearDay] WorkHire vacationFirstYearDay
         * @property {number|null} [vacationDurationNum] WorkHire vacationDurationNum
         * @property {number|null} [vacationYearlyDay] WorkHire vacationYearlyDay
         */

        /**
         * Constructs a new WorkHire.
         * @memberof Proto
         * @classdesc Represents a WorkHire.
         * @implements IWorkHire
         * @constructor
         * @param {Proto.IWorkHire=} [p] Properties to set
         */
        function WorkHire(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkHire uid.
         * @member {string} uid
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.uid = "";

        /**
         * WorkHire name.
         * @member {string} name
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.name = "";

        /**
         * WorkHire timezoneId.
         * @member {string} timezoneId
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.timezoneId = "";

        /**
         * WorkHire timezoneOffset.
         * @member {number} timezoneOffset
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.timezoneOffset = 0;

        /**
         * WorkHire startTime.
         * @member {number|Long} startTime
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.startTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkHire endTime.
         * @member {number|Long} endTime
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.endTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkHire lateStartTime.
         * @member {number|Long} lateStartTime
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.lateStartTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkHire lateEndTime.
         * @member {number|Long} lateEndTime
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.lateEndTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkHire registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkHire modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkHire payHour.
         * @member {number} payHour
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.payHour = 0;

        /**
         * WorkHire currency.
         * @member {string} currency
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.currency = "";

        /**
         * WorkHire transportationFee.
         * @member {number} transportationFee
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.transportationFee = 0;

        /**
         * WorkHire workHourWeeklyHour.
         * @member {number} workHourWeeklyHour
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.workHourWeeklyHour = 0;

        /**
         * WorkHire workHourMonthlyHour.
         * @member {number} workHourMonthlyHour
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.workHourMonthlyHour = 0;

        /**
         * WorkHire workHourYearlyHour.
         * @member {number} workHourYearlyHour
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.workHourYearlyHour = 0;

        /**
         * WorkHire overtimeWeeklyHour.
         * @member {number} overtimeWeeklyHour
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.overtimeWeeklyHour = 0;

        /**
         * WorkHire overtimeMonthlyHour.
         * @member {number} overtimeMonthlyHour
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.overtimeMonthlyHour = 0;

        /**
         * WorkHire overtimeYearlyHour.
         * @member {number} overtimeYearlyHour
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.overtimeYearlyHour = 0;

        /**
         * WorkHire workDayWeeklyHour.
         * @member {number} workDayWeeklyHour
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.workDayWeeklyHour = 0;

        /**
         * WorkHire workDayMonthlyHour.
         * @member {number} workDayMonthlyHour
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.workDayMonthlyHour = 0;

        /**
         * WorkHire workDayYearlyHour.
         * @member {number} workDayYearlyHour
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.workDayYearlyHour = 0;

        /**
         * WorkHire vacationFirstYearDay.
         * @member {number} vacationFirstYearDay
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.vacationFirstYearDay = 0;

        /**
         * WorkHire vacationDurationNum.
         * @member {number} vacationDurationNum
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.vacationDurationNum = 0;

        /**
         * WorkHire vacationYearlyDay.
         * @member {number} vacationYearlyDay
         * @memberof Proto.WorkHire
         * @instance
         */
        WorkHire.prototype.vacationYearlyDay = 0;

        /**
         * Creates a new WorkHire instance using the specified properties.
         * @function create
         * @memberof Proto.WorkHire
         * @static
         * @param {Proto.IWorkHire=} [properties] Properties to set
         * @returns {Proto.WorkHire} WorkHire instance
         */
        WorkHire.create = function create(properties) {
            return new WorkHire(properties);
        };

        /**
         * Encodes the specified WorkHire message. Does not implicitly {@link Proto.WorkHire.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkHire
         * @static
         * @param {Proto.IWorkHire} m WorkHire message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkHire.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.timezoneId != null && Object.hasOwnProperty.call(m, "timezoneId"))
                w.uint32(26).string(m.timezoneId);
            if (m.timezoneOffset != null && Object.hasOwnProperty.call(m, "timezoneOffset"))
                w.uint32(32).int32(m.timezoneOffset);
            if (m.startTime != null && Object.hasOwnProperty.call(m, "startTime"))
                w.uint32(40).int64(m.startTime);
            if (m.endTime != null && Object.hasOwnProperty.call(m, "endTime"))
                w.uint32(48).int64(m.endTime);
            if (m.lateStartTime != null && Object.hasOwnProperty.call(m, "lateStartTime"))
                w.uint32(56).int64(m.lateStartTime);
            if (m.lateEndTime != null && Object.hasOwnProperty.call(m, "lateEndTime"))
                w.uint32(64).int64(m.lateEndTime);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(72).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(80).int64(m.modifyDate);
            if (m.payHour != null && Object.hasOwnProperty.call(m, "payHour"))
                w.uint32(89).double(m.payHour);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(98).string(m.currency);
            if (m.transportationFee != null && Object.hasOwnProperty.call(m, "transportationFee"))
                w.uint32(105).double(m.transportationFee);
            if (m.workHourWeeklyHour != null && Object.hasOwnProperty.call(m, "workHourWeeklyHour"))
                w.uint32(112).int32(m.workHourWeeklyHour);
            if (m.workHourMonthlyHour != null && Object.hasOwnProperty.call(m, "workHourMonthlyHour"))
                w.uint32(120).int32(m.workHourMonthlyHour);
            if (m.workHourYearlyHour != null && Object.hasOwnProperty.call(m, "workHourYearlyHour"))
                w.uint32(128).int32(m.workHourYearlyHour);
            if (m.overtimeWeeklyHour != null && Object.hasOwnProperty.call(m, "overtimeWeeklyHour"))
                w.uint32(136).int32(m.overtimeWeeklyHour);
            if (m.overtimeMonthlyHour != null && Object.hasOwnProperty.call(m, "overtimeMonthlyHour"))
                w.uint32(144).int32(m.overtimeMonthlyHour);
            if (m.overtimeYearlyHour != null && Object.hasOwnProperty.call(m, "overtimeYearlyHour"))
                w.uint32(152).int32(m.overtimeYearlyHour);
            if (m.workDayWeeklyHour != null && Object.hasOwnProperty.call(m, "workDayWeeklyHour"))
                w.uint32(160).int32(m.workDayWeeklyHour);
            if (m.workDayMonthlyHour != null && Object.hasOwnProperty.call(m, "workDayMonthlyHour"))
                w.uint32(168).int32(m.workDayMonthlyHour);
            if (m.workDayYearlyHour != null && Object.hasOwnProperty.call(m, "workDayYearlyHour"))
                w.uint32(176).int32(m.workDayYearlyHour);
            if (m.vacationFirstYearDay != null && Object.hasOwnProperty.call(m, "vacationFirstYearDay"))
                w.uint32(184).int32(m.vacationFirstYearDay);
            if (m.vacationDurationNum != null && Object.hasOwnProperty.call(m, "vacationDurationNum"))
                w.uint32(192).int32(m.vacationDurationNum);
            if (m.vacationYearlyDay != null && Object.hasOwnProperty.call(m, "vacationYearlyDay"))
                w.uint32(200).int32(m.vacationYearlyDay);
            return w;
        };

        /**
         * Decodes a WorkHire message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkHire
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkHire} WorkHire
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkHire.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkHire();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.name = r.string();
                    break;
                case 3:
                    m.timezoneId = r.string();
                    break;
                case 4:
                    m.timezoneOffset = r.int32();
                    break;
                case 5:
                    m.startTime = r.int64();
                    break;
                case 6:
                    m.endTime = r.int64();
                    break;
                case 7:
                    m.lateStartTime = r.int64();
                    break;
                case 8:
                    m.lateEndTime = r.int64();
                    break;
                case 9:
                    m.registerDate = r.int64();
                    break;
                case 10:
                    m.modifyDate = r.int64();
                    break;
                case 11:
                    m.payHour = r.double();
                    break;
                case 12:
                    m.currency = r.string();
                    break;
                case 13:
                    m.transportationFee = r.double();
                    break;
                case 14:
                    m.workHourWeeklyHour = r.int32();
                    break;
                case 15:
                    m.workHourMonthlyHour = r.int32();
                    break;
                case 16:
                    m.workHourYearlyHour = r.int32();
                    break;
                case 17:
                    m.overtimeWeeklyHour = r.int32();
                    break;
                case 18:
                    m.overtimeMonthlyHour = r.int32();
                    break;
                case 19:
                    m.overtimeYearlyHour = r.int32();
                    break;
                case 20:
                    m.workDayWeeklyHour = r.int32();
                    break;
                case 21:
                    m.workDayMonthlyHour = r.int32();
                    break;
                case 22:
                    m.workDayYearlyHour = r.int32();
                    break;
                case 23:
                    m.vacationFirstYearDay = r.int32();
                    break;
                case 24:
                    m.vacationDurationNum = r.int32();
                    break;
                case 25:
                    m.vacationYearlyDay = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkHire message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkHire
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkHire} WorkHire
         */
        WorkHire.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkHire)
                return d;
            var m = new $root.Proto.WorkHire();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.timezoneId != null) {
                m.timezoneId = String(d.timezoneId);
            }
            if (d.timezoneOffset != null) {
                m.timezoneOffset = d.timezoneOffset | 0;
            }
            if (d.startTime != null) {
                if ($util.Long)
                    (m.startTime = $util.Long.fromValue(d.startTime)).unsigned = false;
                else if (typeof d.startTime === "string")
                    m.startTime = parseInt(d.startTime, 10);
                else if (typeof d.startTime === "number")
                    m.startTime = d.startTime;
                else if (typeof d.startTime === "object")
                    m.startTime = new $util.LongBits(d.startTime.low >>> 0, d.startTime.high >>> 0).toNumber();
            }
            if (d.endTime != null) {
                if ($util.Long)
                    (m.endTime = $util.Long.fromValue(d.endTime)).unsigned = false;
                else if (typeof d.endTime === "string")
                    m.endTime = parseInt(d.endTime, 10);
                else if (typeof d.endTime === "number")
                    m.endTime = d.endTime;
                else if (typeof d.endTime === "object")
                    m.endTime = new $util.LongBits(d.endTime.low >>> 0, d.endTime.high >>> 0).toNumber();
            }
            if (d.lateStartTime != null) {
                if ($util.Long)
                    (m.lateStartTime = $util.Long.fromValue(d.lateStartTime)).unsigned = false;
                else if (typeof d.lateStartTime === "string")
                    m.lateStartTime = parseInt(d.lateStartTime, 10);
                else if (typeof d.lateStartTime === "number")
                    m.lateStartTime = d.lateStartTime;
                else if (typeof d.lateStartTime === "object")
                    m.lateStartTime = new $util.LongBits(d.lateStartTime.low >>> 0, d.lateStartTime.high >>> 0).toNumber();
            }
            if (d.lateEndTime != null) {
                if ($util.Long)
                    (m.lateEndTime = $util.Long.fromValue(d.lateEndTime)).unsigned = false;
                else if (typeof d.lateEndTime === "string")
                    m.lateEndTime = parseInt(d.lateEndTime, 10);
                else if (typeof d.lateEndTime === "number")
                    m.lateEndTime = d.lateEndTime;
                else if (typeof d.lateEndTime === "object")
                    m.lateEndTime = new $util.LongBits(d.lateEndTime.low >>> 0, d.lateEndTime.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.payHour != null) {
                m.payHour = Number(d.payHour);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.transportationFee != null) {
                m.transportationFee = Number(d.transportationFee);
            }
            if (d.workHourWeeklyHour != null) {
                m.workHourWeeklyHour = d.workHourWeeklyHour | 0;
            }
            if (d.workHourMonthlyHour != null) {
                m.workHourMonthlyHour = d.workHourMonthlyHour | 0;
            }
            if (d.workHourYearlyHour != null) {
                m.workHourYearlyHour = d.workHourYearlyHour | 0;
            }
            if (d.overtimeWeeklyHour != null) {
                m.overtimeWeeklyHour = d.overtimeWeeklyHour | 0;
            }
            if (d.overtimeMonthlyHour != null) {
                m.overtimeMonthlyHour = d.overtimeMonthlyHour | 0;
            }
            if (d.overtimeYearlyHour != null) {
                m.overtimeYearlyHour = d.overtimeYearlyHour | 0;
            }
            if (d.workDayWeeklyHour != null) {
                m.workDayWeeklyHour = d.workDayWeeklyHour | 0;
            }
            if (d.workDayMonthlyHour != null) {
                m.workDayMonthlyHour = d.workDayMonthlyHour | 0;
            }
            if (d.workDayYearlyHour != null) {
                m.workDayYearlyHour = d.workDayYearlyHour | 0;
            }
            if (d.vacationFirstYearDay != null) {
                m.vacationFirstYearDay = d.vacationFirstYearDay | 0;
            }
            if (d.vacationDurationNum != null) {
                m.vacationDurationNum = d.vacationDurationNum | 0;
            }
            if (d.vacationYearlyDay != null) {
                m.vacationYearlyDay = d.vacationYearlyDay | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkHire message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkHire
         * @static
         * @param {Proto.WorkHire} m WorkHire
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkHire.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.name = "";
                d.timezoneId = "";
                d.timezoneOffset = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startTime = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startTime = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endTime = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endTime = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.lateStartTime = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lateStartTime = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.lateEndTime = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lateEndTime = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.payHour = 0;
                d.currency = "";
                d.transportationFee = 0;
                d.workHourWeeklyHour = 0;
                d.workHourMonthlyHour = 0;
                d.workHourYearlyHour = 0;
                d.overtimeWeeklyHour = 0;
                d.overtimeMonthlyHour = 0;
                d.overtimeYearlyHour = 0;
                d.workDayWeeklyHour = 0;
                d.workDayMonthlyHour = 0;
                d.workDayYearlyHour = 0;
                d.vacationFirstYearDay = 0;
                d.vacationDurationNum = 0;
                d.vacationYearlyDay = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.timezoneId != null && m.hasOwnProperty("timezoneId")) {
                d.timezoneId = m.timezoneId;
            }
            if (m.timezoneOffset != null && m.hasOwnProperty("timezoneOffset")) {
                d.timezoneOffset = m.timezoneOffset;
            }
            if (m.startTime != null && m.hasOwnProperty("startTime")) {
                if (typeof m.startTime === "number")
                    d.startTime = o.longs === String ? String(m.startTime) : m.startTime;
                else
                    d.startTime = o.longs === String ? $util.Long.prototype.toString.call(m.startTime) : o.longs === Number ? new $util.LongBits(m.startTime.low >>> 0, m.startTime.high >>> 0).toNumber() : m.startTime;
            }
            if (m.endTime != null && m.hasOwnProperty("endTime")) {
                if (typeof m.endTime === "number")
                    d.endTime = o.longs === String ? String(m.endTime) : m.endTime;
                else
                    d.endTime = o.longs === String ? $util.Long.prototype.toString.call(m.endTime) : o.longs === Number ? new $util.LongBits(m.endTime.low >>> 0, m.endTime.high >>> 0).toNumber() : m.endTime;
            }
            if (m.lateStartTime != null && m.hasOwnProperty("lateStartTime")) {
                if (typeof m.lateStartTime === "number")
                    d.lateStartTime = o.longs === String ? String(m.lateStartTime) : m.lateStartTime;
                else
                    d.lateStartTime = o.longs === String ? $util.Long.prototype.toString.call(m.lateStartTime) : o.longs === Number ? new $util.LongBits(m.lateStartTime.low >>> 0, m.lateStartTime.high >>> 0).toNumber() : m.lateStartTime;
            }
            if (m.lateEndTime != null && m.hasOwnProperty("lateEndTime")) {
                if (typeof m.lateEndTime === "number")
                    d.lateEndTime = o.longs === String ? String(m.lateEndTime) : m.lateEndTime;
                else
                    d.lateEndTime = o.longs === String ? $util.Long.prototype.toString.call(m.lateEndTime) : o.longs === Number ? new $util.LongBits(m.lateEndTime.low >>> 0, m.lateEndTime.high >>> 0).toNumber() : m.lateEndTime;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.payHour != null && m.hasOwnProperty("payHour")) {
                d.payHour = o.json && !isFinite(m.payHour) ? String(m.payHour) : m.payHour;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.transportationFee != null && m.hasOwnProperty("transportationFee")) {
                d.transportationFee = o.json && !isFinite(m.transportationFee) ? String(m.transportationFee) : m.transportationFee;
            }
            if (m.workHourWeeklyHour != null && m.hasOwnProperty("workHourWeeklyHour")) {
                d.workHourWeeklyHour = m.workHourWeeklyHour;
            }
            if (m.workHourMonthlyHour != null && m.hasOwnProperty("workHourMonthlyHour")) {
                d.workHourMonthlyHour = m.workHourMonthlyHour;
            }
            if (m.workHourYearlyHour != null && m.hasOwnProperty("workHourYearlyHour")) {
                d.workHourYearlyHour = m.workHourYearlyHour;
            }
            if (m.overtimeWeeklyHour != null && m.hasOwnProperty("overtimeWeeklyHour")) {
                d.overtimeWeeklyHour = m.overtimeWeeklyHour;
            }
            if (m.overtimeMonthlyHour != null && m.hasOwnProperty("overtimeMonthlyHour")) {
                d.overtimeMonthlyHour = m.overtimeMonthlyHour;
            }
            if (m.overtimeYearlyHour != null && m.hasOwnProperty("overtimeYearlyHour")) {
                d.overtimeYearlyHour = m.overtimeYearlyHour;
            }
            if (m.workDayWeeklyHour != null && m.hasOwnProperty("workDayWeeklyHour")) {
                d.workDayWeeklyHour = m.workDayWeeklyHour;
            }
            if (m.workDayMonthlyHour != null && m.hasOwnProperty("workDayMonthlyHour")) {
                d.workDayMonthlyHour = m.workDayMonthlyHour;
            }
            if (m.workDayYearlyHour != null && m.hasOwnProperty("workDayYearlyHour")) {
                d.workDayYearlyHour = m.workDayYearlyHour;
            }
            if (m.vacationFirstYearDay != null && m.hasOwnProperty("vacationFirstYearDay")) {
                d.vacationFirstYearDay = m.vacationFirstYearDay;
            }
            if (m.vacationDurationNum != null && m.hasOwnProperty("vacationDurationNum")) {
                d.vacationDurationNum = m.vacationDurationNum;
            }
            if (m.vacationYearlyDay != null && m.hasOwnProperty("vacationYearlyDay")) {
                d.vacationYearlyDay = m.vacationYearlyDay;
            }
            return d;
        };

        /**
         * Converts this WorkHire to JSON.
         * @function toJSON
         * @memberof Proto.WorkHire
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkHire.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkHire;
    })();

    Proto.WorkHireList = (function() {

        /**
         * Properties of a WorkHireList.
         * @memberof Proto
         * @interface IWorkHireList
         * @property {Array.<Proto.IWorkHire>|null} [value] WorkHireList value
         */

        /**
         * Constructs a new WorkHireList.
         * @memberof Proto
         * @classdesc Represents a WorkHireList.
         * @implements IWorkHireList
         * @constructor
         * @param {Proto.IWorkHireList=} [p] Properties to set
         */
        function WorkHireList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkHireList value.
         * @member {Array.<Proto.IWorkHire>} value
         * @memberof Proto.WorkHireList
         * @instance
         */
        WorkHireList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkHireList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkHireList
         * @static
         * @param {Proto.IWorkHireList=} [properties] Properties to set
         * @returns {Proto.WorkHireList} WorkHireList instance
         */
        WorkHireList.create = function create(properties) {
            return new WorkHireList(properties);
        };

        /**
         * Encodes the specified WorkHireList message. Does not implicitly {@link Proto.WorkHireList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkHireList
         * @static
         * @param {Proto.IWorkHireList} m WorkHireList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkHireList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkHire.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkHireList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkHireList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkHireList} WorkHireList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkHireList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkHireList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkHire.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkHireList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkHireList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkHireList} WorkHireList
         */
        WorkHireList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkHireList)
                return d;
            var m = new $root.Proto.WorkHireList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkHireList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkHireList.value: object expected");
                    m.value[i] = $root.Proto.WorkHire.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkHireList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkHireList
         * @static
         * @param {Proto.WorkHireList} m WorkHireList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkHireList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkHire.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkHireList to JSON.
         * @function toJSON
         * @memberof Proto.WorkHireList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkHireList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkHireList;
    })();

    Proto.WorkSkillCategory = (function() {

        /**
         * Properties of a WorkSkillCategory.
         * @memberof Proto
         * @interface IWorkSkillCategory
         * @property {string|null} [uid] WorkSkillCategory uid
         * @property {string|null} [name] WorkSkillCategory name
         * @property {number|Long|null} [registerDate] WorkSkillCategory registerDate
         * @property {number|Long|null} [modifyDate] WorkSkillCategory modifyDate
         * @property {number|null} [status] WorkSkillCategory status
         * @property {Array.<Proto.IWorkSkill>|null} [skillList] WorkSkillCategory skillList
         * @property {string|null} [color] WorkSkillCategory color
         */

        /**
         * Constructs a new WorkSkillCategory.
         * @memberof Proto
         * @classdesc Represents a WorkSkillCategory.
         * @implements IWorkSkillCategory
         * @constructor
         * @param {Proto.IWorkSkillCategory=} [p] Properties to set
         */
        function WorkSkillCategory(p) {
            this.skillList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkSkillCategory uid.
         * @member {string} uid
         * @memberof Proto.WorkSkillCategory
         * @instance
         */
        WorkSkillCategory.prototype.uid = "";

        /**
         * WorkSkillCategory name.
         * @member {string} name
         * @memberof Proto.WorkSkillCategory
         * @instance
         */
        WorkSkillCategory.prototype.name = "";

        /**
         * WorkSkillCategory registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkSkillCategory
         * @instance
         */
        WorkSkillCategory.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkSkillCategory modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkSkillCategory
         * @instance
         */
        WorkSkillCategory.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkSkillCategory status.
         * @member {number} status
         * @memberof Proto.WorkSkillCategory
         * @instance
         */
        WorkSkillCategory.prototype.status = 0;

        /**
         * WorkSkillCategory skillList.
         * @member {Array.<Proto.IWorkSkill>} skillList
         * @memberof Proto.WorkSkillCategory
         * @instance
         */
        WorkSkillCategory.prototype.skillList = $util.emptyArray;

        /**
         * WorkSkillCategory color.
         * @member {string} color
         * @memberof Proto.WorkSkillCategory
         * @instance
         */
        WorkSkillCategory.prototype.color = "";

        /**
         * Creates a new WorkSkillCategory instance using the specified properties.
         * @function create
         * @memberof Proto.WorkSkillCategory
         * @static
         * @param {Proto.IWorkSkillCategory=} [properties] Properties to set
         * @returns {Proto.WorkSkillCategory} WorkSkillCategory instance
         */
        WorkSkillCategory.create = function create(properties) {
            return new WorkSkillCategory(properties);
        };

        /**
         * Encodes the specified WorkSkillCategory message. Does not implicitly {@link Proto.WorkSkillCategory.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkSkillCategory
         * @static
         * @param {Proto.IWorkSkillCategory} m WorkSkillCategory message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkSkillCategory.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(32).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(40).int32(m.status);
            if (m.skillList != null && m.skillList.length) {
                for (var i = 0; i < m.skillList.length; ++i)
                    $root.Proto.WorkSkill.encode(m.skillList[i], w.uint32(50).fork()).ldelim();
            }
            if (m.color != null && Object.hasOwnProperty.call(m, "color"))
                w.uint32(58).string(m.color);
            return w;
        };

        /**
         * Decodes a WorkSkillCategory message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkSkillCategory
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkSkillCategory} WorkSkillCategory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkSkillCategory.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkSkillCategory();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.name = r.string();
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    m.modifyDate = r.int64();
                    break;
                case 5:
                    m.status = r.int32();
                    break;
                case 6:
                    if (!(m.skillList && m.skillList.length))
                        m.skillList = [];
                    m.skillList.push($root.Proto.WorkSkill.decode(r, r.uint32()));
                    break;
                case 7:
                    m.color = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkSkillCategory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkSkillCategory
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkSkillCategory} WorkSkillCategory
         */
        WorkSkillCategory.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkSkillCategory)
                return d;
            var m = new $root.Proto.WorkSkillCategory();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.skillList) {
                if (!Array.isArray(d.skillList))
                    throw TypeError(".Proto.WorkSkillCategory.skillList: array expected");
                m.skillList = [];
                for (var i = 0; i < d.skillList.length; ++i) {
                    if (typeof d.skillList[i] !== "object")
                        throw TypeError(".Proto.WorkSkillCategory.skillList: object expected");
                    m.skillList[i] = $root.Proto.WorkSkill.fromObject(d.skillList[i]);
                }
            }
            if (d.color != null) {
                m.color = String(d.color);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkSkillCategory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkSkillCategory
         * @static
         * @param {Proto.WorkSkillCategory} m WorkSkillCategory
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkSkillCategory.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.skillList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.name = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.color = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.skillList && m.skillList.length) {
                d.skillList = [];
                for (var j = 0; j < m.skillList.length; ++j) {
                    d.skillList[j] = $root.Proto.WorkSkill.toObject(m.skillList[j], o);
                }
            }
            if (m.color != null && m.hasOwnProperty("color")) {
                d.color = m.color;
            }
            return d;
        };

        /**
         * Converts this WorkSkillCategory to JSON.
         * @function toJSON
         * @memberof Proto.WorkSkillCategory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkSkillCategory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkSkillCategory;
    })();

    Proto.WorkSkillCategoryList = (function() {

        /**
         * Properties of a WorkSkillCategoryList.
         * @memberof Proto
         * @interface IWorkSkillCategoryList
         * @property {Array.<Proto.IWorkSkillCategory>|null} [value] WorkSkillCategoryList value
         */

        /**
         * Constructs a new WorkSkillCategoryList.
         * @memberof Proto
         * @classdesc Represents a WorkSkillCategoryList.
         * @implements IWorkSkillCategoryList
         * @constructor
         * @param {Proto.IWorkSkillCategoryList=} [p] Properties to set
         */
        function WorkSkillCategoryList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkSkillCategoryList value.
         * @member {Array.<Proto.IWorkSkillCategory>} value
         * @memberof Proto.WorkSkillCategoryList
         * @instance
         */
        WorkSkillCategoryList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkSkillCategoryList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkSkillCategoryList
         * @static
         * @param {Proto.IWorkSkillCategoryList=} [properties] Properties to set
         * @returns {Proto.WorkSkillCategoryList} WorkSkillCategoryList instance
         */
        WorkSkillCategoryList.create = function create(properties) {
            return new WorkSkillCategoryList(properties);
        };

        /**
         * Encodes the specified WorkSkillCategoryList message. Does not implicitly {@link Proto.WorkSkillCategoryList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkSkillCategoryList
         * @static
         * @param {Proto.IWorkSkillCategoryList} m WorkSkillCategoryList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkSkillCategoryList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkSkillCategory.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkSkillCategoryList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkSkillCategoryList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkSkillCategoryList} WorkSkillCategoryList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkSkillCategoryList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkSkillCategoryList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkSkillCategory.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkSkillCategoryList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkSkillCategoryList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkSkillCategoryList} WorkSkillCategoryList
         */
        WorkSkillCategoryList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkSkillCategoryList)
                return d;
            var m = new $root.Proto.WorkSkillCategoryList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkSkillCategoryList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkSkillCategoryList.value: object expected");
                    m.value[i] = $root.Proto.WorkSkillCategory.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkSkillCategoryList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkSkillCategoryList
         * @static
         * @param {Proto.WorkSkillCategoryList} m WorkSkillCategoryList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkSkillCategoryList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkSkillCategory.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkSkillCategoryList to JSON.
         * @function toJSON
         * @memberof Proto.WorkSkillCategoryList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkSkillCategoryList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkSkillCategoryList;
    })();

    Proto.WorkSkill = (function() {

        /**
         * Properties of a WorkSkill.
         * @memberof Proto
         * @interface IWorkSkill
         * @property {string|null} [uid] WorkSkill uid
         * @property {string|null} [categoryUID] WorkSkill categoryUID
         * @property {string|null} [name] WorkSkill name
         * @property {number|Long|null} [registerDate] WorkSkill registerDate
         * @property {number|Long|null} [modifyDate] WorkSkill modifyDate
         * @property {number|null} [status] WorkSkill status
         * @property {string|null} [color] WorkSkill color
         */

        /**
         * Constructs a new WorkSkill.
         * @memberof Proto
         * @classdesc Represents a WorkSkill.
         * @implements IWorkSkill
         * @constructor
         * @param {Proto.IWorkSkill=} [p] Properties to set
         */
        function WorkSkill(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkSkill uid.
         * @member {string} uid
         * @memberof Proto.WorkSkill
         * @instance
         */
        WorkSkill.prototype.uid = "";

        /**
         * WorkSkill categoryUID.
         * @member {string} categoryUID
         * @memberof Proto.WorkSkill
         * @instance
         */
        WorkSkill.prototype.categoryUID = "";

        /**
         * WorkSkill name.
         * @member {string} name
         * @memberof Proto.WorkSkill
         * @instance
         */
        WorkSkill.prototype.name = "";

        /**
         * WorkSkill registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkSkill
         * @instance
         */
        WorkSkill.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkSkill modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkSkill
         * @instance
         */
        WorkSkill.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkSkill status.
         * @member {number} status
         * @memberof Proto.WorkSkill
         * @instance
         */
        WorkSkill.prototype.status = 0;

        /**
         * WorkSkill color.
         * @member {string} color
         * @memberof Proto.WorkSkill
         * @instance
         */
        WorkSkill.prototype.color = "";

        /**
         * Creates a new WorkSkill instance using the specified properties.
         * @function create
         * @memberof Proto.WorkSkill
         * @static
         * @param {Proto.IWorkSkill=} [properties] Properties to set
         * @returns {Proto.WorkSkill} WorkSkill instance
         */
        WorkSkill.create = function create(properties) {
            return new WorkSkill(properties);
        };

        /**
         * Encodes the specified WorkSkill message. Does not implicitly {@link Proto.WorkSkill.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkSkill
         * @static
         * @param {Proto.IWorkSkill} m WorkSkill message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkSkill.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.categoryUID != null && Object.hasOwnProperty.call(m, "categoryUID"))
                w.uint32(18).string(m.categoryUID);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(48).int32(m.status);
            if (m.color != null && Object.hasOwnProperty.call(m, "color"))
                w.uint32(58).string(m.color);
            return w;
        };

        /**
         * Decodes a WorkSkill message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkSkill
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkSkill} WorkSkill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkSkill.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkSkill();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.categoryUID = r.string();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                case 6:
                    m.status = r.int32();
                    break;
                case 7:
                    m.color = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkSkill message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkSkill
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkSkill} WorkSkill
         */
        WorkSkill.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkSkill)
                return d;
            var m = new $root.Proto.WorkSkill();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.categoryUID != null) {
                m.categoryUID = String(d.categoryUID);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.color != null) {
                m.color = String(d.color);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkSkill message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkSkill
         * @static
         * @param {Proto.WorkSkill} m WorkSkill
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkSkill.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.categoryUID = "";
                d.name = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.color = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.categoryUID != null && m.hasOwnProperty("categoryUID")) {
                d.categoryUID = m.categoryUID;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.color != null && m.hasOwnProperty("color")) {
                d.color = m.color;
            }
            return d;
        };

        /**
         * Converts this WorkSkill to JSON.
         * @function toJSON
         * @memberof Proto.WorkSkill
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkSkill.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkSkill;
    })();

    Proto.WorkSkillList = (function() {

        /**
         * Properties of a WorkSkillList.
         * @memberof Proto
         * @interface IWorkSkillList
         * @property {Array.<Proto.IWorkSkill>|null} [value] WorkSkillList value
         */

        /**
         * Constructs a new WorkSkillList.
         * @memberof Proto
         * @classdesc Represents a WorkSkillList.
         * @implements IWorkSkillList
         * @constructor
         * @param {Proto.IWorkSkillList=} [p] Properties to set
         */
        function WorkSkillList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkSkillList value.
         * @member {Array.<Proto.IWorkSkill>} value
         * @memberof Proto.WorkSkillList
         * @instance
         */
        WorkSkillList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkSkillList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkSkillList
         * @static
         * @param {Proto.IWorkSkillList=} [properties] Properties to set
         * @returns {Proto.WorkSkillList} WorkSkillList instance
         */
        WorkSkillList.create = function create(properties) {
            return new WorkSkillList(properties);
        };

        /**
         * Encodes the specified WorkSkillList message. Does not implicitly {@link Proto.WorkSkillList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkSkillList
         * @static
         * @param {Proto.IWorkSkillList} m WorkSkillList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkSkillList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkSkill.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkSkillList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkSkillList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkSkillList} WorkSkillList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkSkillList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkSkillList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkSkill.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkSkillList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkSkillList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkSkillList} WorkSkillList
         */
        WorkSkillList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkSkillList)
                return d;
            var m = new $root.Proto.WorkSkillList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkSkillList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkSkillList.value: object expected");
                    m.value[i] = $root.Proto.WorkSkill.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkSkillList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkSkillList
         * @static
         * @param {Proto.WorkSkillList} m WorkSkillList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkSkillList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkSkill.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkSkillList to JSON.
         * @function toJSON
         * @memberof Proto.WorkSkillList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkSkillList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkSkillList;
    })();

    Proto.WorkAttributeCategory = (function() {

        /**
         * Properties of a WorkAttributeCategory.
         * @memberof Proto
         * @interface IWorkAttributeCategory
         * @property {string|null} [uid] WorkAttributeCategory uid
         * @property {string|null} [name] WorkAttributeCategory name
         * @property {number|Long|null} [registerDate] WorkAttributeCategory registerDate
         * @property {number|Long|null} [modifyDate] WorkAttributeCategory modifyDate
         * @property {number|null} [status] WorkAttributeCategory status
         * @property {Array.<Proto.IWorkAttribute>|null} [attrList] WorkAttributeCategory attrList
         * @property {string|null} [color] WorkAttributeCategory color
         */

        /**
         * Constructs a new WorkAttributeCategory.
         * @memberof Proto
         * @classdesc Represents a WorkAttributeCategory.
         * @implements IWorkAttributeCategory
         * @constructor
         * @param {Proto.IWorkAttributeCategory=} [p] Properties to set
         */
        function WorkAttributeCategory(p) {
            this.attrList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkAttributeCategory uid.
         * @member {string} uid
         * @memberof Proto.WorkAttributeCategory
         * @instance
         */
        WorkAttributeCategory.prototype.uid = "";

        /**
         * WorkAttributeCategory name.
         * @member {string} name
         * @memberof Proto.WorkAttributeCategory
         * @instance
         */
        WorkAttributeCategory.prototype.name = "";

        /**
         * WorkAttributeCategory registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkAttributeCategory
         * @instance
         */
        WorkAttributeCategory.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkAttributeCategory modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkAttributeCategory
         * @instance
         */
        WorkAttributeCategory.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkAttributeCategory status.
         * @member {number} status
         * @memberof Proto.WorkAttributeCategory
         * @instance
         */
        WorkAttributeCategory.prototype.status = 0;

        /**
         * WorkAttributeCategory attrList.
         * @member {Array.<Proto.IWorkAttribute>} attrList
         * @memberof Proto.WorkAttributeCategory
         * @instance
         */
        WorkAttributeCategory.prototype.attrList = $util.emptyArray;

        /**
         * WorkAttributeCategory color.
         * @member {string} color
         * @memberof Proto.WorkAttributeCategory
         * @instance
         */
        WorkAttributeCategory.prototype.color = "";

        /**
         * Creates a new WorkAttributeCategory instance using the specified properties.
         * @function create
         * @memberof Proto.WorkAttributeCategory
         * @static
         * @param {Proto.IWorkAttributeCategory=} [properties] Properties to set
         * @returns {Proto.WorkAttributeCategory} WorkAttributeCategory instance
         */
        WorkAttributeCategory.create = function create(properties) {
            return new WorkAttributeCategory(properties);
        };

        /**
         * Encodes the specified WorkAttributeCategory message. Does not implicitly {@link Proto.WorkAttributeCategory.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkAttributeCategory
         * @static
         * @param {Proto.IWorkAttributeCategory} m WorkAttributeCategory message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkAttributeCategory.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(18).string(m.name);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(32).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(40).int32(m.status);
            if (m.attrList != null && m.attrList.length) {
                for (var i = 0; i < m.attrList.length; ++i)
                    $root.Proto.WorkAttribute.encode(m.attrList[i], w.uint32(50).fork()).ldelim();
            }
            if (m.color != null && Object.hasOwnProperty.call(m, "color"))
                w.uint32(58).string(m.color);
            return w;
        };

        /**
         * Decodes a WorkAttributeCategory message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkAttributeCategory
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkAttributeCategory} WorkAttributeCategory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkAttributeCategory.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkAttributeCategory();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.name = r.string();
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    m.modifyDate = r.int64();
                    break;
                case 5:
                    m.status = r.int32();
                    break;
                case 6:
                    if (!(m.attrList && m.attrList.length))
                        m.attrList = [];
                    m.attrList.push($root.Proto.WorkAttribute.decode(r, r.uint32()));
                    break;
                case 7:
                    m.color = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkAttributeCategory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkAttributeCategory
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkAttributeCategory} WorkAttributeCategory
         */
        WorkAttributeCategory.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkAttributeCategory)
                return d;
            var m = new $root.Proto.WorkAttributeCategory();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.attrList) {
                if (!Array.isArray(d.attrList))
                    throw TypeError(".Proto.WorkAttributeCategory.attrList: array expected");
                m.attrList = [];
                for (var i = 0; i < d.attrList.length; ++i) {
                    if (typeof d.attrList[i] !== "object")
                        throw TypeError(".Proto.WorkAttributeCategory.attrList: object expected");
                    m.attrList[i] = $root.Proto.WorkAttribute.fromObject(d.attrList[i]);
                }
            }
            if (d.color != null) {
                m.color = String(d.color);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkAttributeCategory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkAttributeCategory
         * @static
         * @param {Proto.WorkAttributeCategory} m WorkAttributeCategory
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkAttributeCategory.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.attrList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.name = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.color = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.attrList && m.attrList.length) {
                d.attrList = [];
                for (var j = 0; j < m.attrList.length; ++j) {
                    d.attrList[j] = $root.Proto.WorkAttribute.toObject(m.attrList[j], o);
                }
            }
            if (m.color != null && m.hasOwnProperty("color")) {
                d.color = m.color;
            }
            return d;
        };

        /**
         * Converts this WorkAttributeCategory to JSON.
         * @function toJSON
         * @memberof Proto.WorkAttributeCategory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkAttributeCategory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkAttributeCategory;
    })();

    Proto.WorkAttributeCategoryList = (function() {

        /**
         * Properties of a WorkAttributeCategoryList.
         * @memberof Proto
         * @interface IWorkAttributeCategoryList
         * @property {Array.<Proto.IWorkAttributeCategory>|null} [value] WorkAttributeCategoryList value
         */

        /**
         * Constructs a new WorkAttributeCategoryList.
         * @memberof Proto
         * @classdesc Represents a WorkAttributeCategoryList.
         * @implements IWorkAttributeCategoryList
         * @constructor
         * @param {Proto.IWorkAttributeCategoryList=} [p] Properties to set
         */
        function WorkAttributeCategoryList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkAttributeCategoryList value.
         * @member {Array.<Proto.IWorkAttributeCategory>} value
         * @memberof Proto.WorkAttributeCategoryList
         * @instance
         */
        WorkAttributeCategoryList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkAttributeCategoryList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkAttributeCategoryList
         * @static
         * @param {Proto.IWorkAttributeCategoryList=} [properties] Properties to set
         * @returns {Proto.WorkAttributeCategoryList} WorkAttributeCategoryList instance
         */
        WorkAttributeCategoryList.create = function create(properties) {
            return new WorkAttributeCategoryList(properties);
        };

        /**
         * Encodes the specified WorkAttributeCategoryList message. Does not implicitly {@link Proto.WorkAttributeCategoryList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkAttributeCategoryList
         * @static
         * @param {Proto.IWorkAttributeCategoryList} m WorkAttributeCategoryList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkAttributeCategoryList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkAttributeCategory.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkAttributeCategoryList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkAttributeCategoryList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkAttributeCategoryList} WorkAttributeCategoryList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkAttributeCategoryList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkAttributeCategoryList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkAttributeCategory.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkAttributeCategoryList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkAttributeCategoryList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkAttributeCategoryList} WorkAttributeCategoryList
         */
        WorkAttributeCategoryList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkAttributeCategoryList)
                return d;
            var m = new $root.Proto.WorkAttributeCategoryList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkAttributeCategoryList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkAttributeCategoryList.value: object expected");
                    m.value[i] = $root.Proto.WorkAttributeCategory.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkAttributeCategoryList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkAttributeCategoryList
         * @static
         * @param {Proto.WorkAttributeCategoryList} m WorkAttributeCategoryList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkAttributeCategoryList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkAttributeCategory.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkAttributeCategoryList to JSON.
         * @function toJSON
         * @memberof Proto.WorkAttributeCategoryList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkAttributeCategoryList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkAttributeCategoryList;
    })();

    Proto.WorkAttribute = (function() {

        /**
         * Properties of a WorkAttribute.
         * @memberof Proto
         * @interface IWorkAttribute
         * @property {string|null} [uid] WorkAttribute uid
         * @property {string|null} [categoryUID] WorkAttribute categoryUID
         * @property {string|null} [name] WorkAttribute name
         * @property {number|Long|null} [registerDate] WorkAttribute registerDate
         * @property {number|Long|null} [modifyDate] WorkAttribute modifyDate
         * @property {number|null} [status] WorkAttribute status
         * @property {string|null} [color] WorkAttribute color
         */

        /**
         * Constructs a new WorkAttribute.
         * @memberof Proto
         * @classdesc Represents a WorkAttribute.
         * @implements IWorkAttribute
         * @constructor
         * @param {Proto.IWorkAttribute=} [p] Properties to set
         */
        function WorkAttribute(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkAttribute uid.
         * @member {string} uid
         * @memberof Proto.WorkAttribute
         * @instance
         */
        WorkAttribute.prototype.uid = "";

        /**
         * WorkAttribute categoryUID.
         * @member {string} categoryUID
         * @memberof Proto.WorkAttribute
         * @instance
         */
        WorkAttribute.prototype.categoryUID = "";

        /**
         * WorkAttribute name.
         * @member {string} name
         * @memberof Proto.WorkAttribute
         * @instance
         */
        WorkAttribute.prototype.name = "";

        /**
         * WorkAttribute registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkAttribute
         * @instance
         */
        WorkAttribute.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkAttribute modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkAttribute
         * @instance
         */
        WorkAttribute.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkAttribute status.
         * @member {number} status
         * @memberof Proto.WorkAttribute
         * @instance
         */
        WorkAttribute.prototype.status = 0;

        /**
         * WorkAttribute color.
         * @member {string} color
         * @memberof Proto.WorkAttribute
         * @instance
         */
        WorkAttribute.prototype.color = "";

        /**
         * Creates a new WorkAttribute instance using the specified properties.
         * @function create
         * @memberof Proto.WorkAttribute
         * @static
         * @param {Proto.IWorkAttribute=} [properties] Properties to set
         * @returns {Proto.WorkAttribute} WorkAttribute instance
         */
        WorkAttribute.create = function create(properties) {
            return new WorkAttribute(properties);
        };

        /**
         * Encodes the specified WorkAttribute message. Does not implicitly {@link Proto.WorkAttribute.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkAttribute
         * @static
         * @param {Proto.IWorkAttribute} m WorkAttribute message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkAttribute.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.categoryUID != null && Object.hasOwnProperty.call(m, "categoryUID"))
                w.uint32(18).string(m.categoryUID);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(48).int32(m.status);
            if (m.color != null && Object.hasOwnProperty.call(m, "color"))
                w.uint32(58).string(m.color);
            return w;
        };

        /**
         * Decodes a WorkAttribute message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkAttribute
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkAttribute} WorkAttribute
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkAttribute.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkAttribute();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.categoryUID = r.string();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                case 6:
                    m.status = r.int32();
                    break;
                case 7:
                    m.color = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkAttribute message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkAttribute
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkAttribute} WorkAttribute
         */
        WorkAttribute.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkAttribute)
                return d;
            var m = new $root.Proto.WorkAttribute();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.categoryUID != null) {
                m.categoryUID = String(d.categoryUID);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.color != null) {
                m.color = String(d.color);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkAttribute message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkAttribute
         * @static
         * @param {Proto.WorkAttribute} m WorkAttribute
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkAttribute.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.categoryUID = "";
                d.name = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.color = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.categoryUID != null && m.hasOwnProperty("categoryUID")) {
                d.categoryUID = m.categoryUID;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.color != null && m.hasOwnProperty("color")) {
                d.color = m.color;
            }
            return d;
        };

        /**
         * Converts this WorkAttribute to JSON.
         * @function toJSON
         * @memberof Proto.WorkAttribute
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkAttribute.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkAttribute;
    })();

    Proto.WorkAttributeList = (function() {

        /**
         * Properties of a WorkAttributeList.
         * @memberof Proto
         * @interface IWorkAttributeList
         * @property {Array.<Proto.IWorkAttribute>|null} [value] WorkAttributeList value
         */

        /**
         * Constructs a new WorkAttributeList.
         * @memberof Proto
         * @classdesc Represents a WorkAttributeList.
         * @implements IWorkAttributeList
         * @constructor
         * @param {Proto.IWorkAttributeList=} [p] Properties to set
         */
        function WorkAttributeList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkAttributeList value.
         * @member {Array.<Proto.IWorkAttribute>} value
         * @memberof Proto.WorkAttributeList
         * @instance
         */
        WorkAttributeList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkAttributeList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkAttributeList
         * @static
         * @param {Proto.IWorkAttributeList=} [properties] Properties to set
         * @returns {Proto.WorkAttributeList} WorkAttributeList instance
         */
        WorkAttributeList.create = function create(properties) {
            return new WorkAttributeList(properties);
        };

        /**
         * Encodes the specified WorkAttributeList message. Does not implicitly {@link Proto.WorkAttributeList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkAttributeList
         * @static
         * @param {Proto.IWorkAttributeList} m WorkAttributeList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkAttributeList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkAttribute.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkAttributeList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkAttributeList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkAttributeList} WorkAttributeList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkAttributeList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkAttributeList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkAttribute.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkAttributeList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkAttributeList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkAttributeList} WorkAttributeList
         */
        WorkAttributeList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkAttributeList)
                return d;
            var m = new $root.Proto.WorkAttributeList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkAttributeList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkAttributeList.value: object expected");
                    m.value[i] = $root.Proto.WorkAttribute.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkAttributeList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkAttributeList
         * @static
         * @param {Proto.WorkAttributeList} m WorkAttributeList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkAttributeList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkAttribute.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkAttributeList to JSON.
         * @function toJSON
         * @memberof Proto.WorkAttributeList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkAttributeList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkAttributeList;
    })();

    Proto.WorkStaffExpectTime = (function() {

        /**
         * Properties of a WorkStaffExpectTime.
         * @memberof Proto
         * @interface IWorkStaffExpectTime
         * @property {string|null} [staffUID] WorkStaffExpectTime staffUID
         * @property {number|null} [type] WorkStaffExpectTime type
         * @property {string|null} [startDate] WorkStaffExpectTime startDate
         * @property {number|null} [weeklyHour] WorkStaffExpectTime weeklyHour
         * @property {number|null} [monthlyHour] WorkStaffExpectTime monthlyHour
         * @property {number|null} [yearlyHour] WorkStaffExpectTime yearlyHour
         * @property {number|Long|null} [registerDate] WorkStaffExpectTime registerDate
         * @property {number|Long|null} [modifyDate] WorkStaffExpectTime modifyDate
         */

        /**
         * Constructs a new WorkStaffExpectTime.
         * @memberof Proto
         * @classdesc Represents a WorkStaffExpectTime.
         * @implements IWorkStaffExpectTime
         * @constructor
         * @param {Proto.IWorkStaffExpectTime=} [p] Properties to set
         */
        function WorkStaffExpectTime(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStaffExpectTime staffUID.
         * @member {string} staffUID
         * @memberof Proto.WorkStaffExpectTime
         * @instance
         */
        WorkStaffExpectTime.prototype.staffUID = "";

        /**
         * WorkStaffExpectTime type.
         * @member {number} type
         * @memberof Proto.WorkStaffExpectTime
         * @instance
         */
        WorkStaffExpectTime.prototype.type = 0;

        /**
         * WorkStaffExpectTime startDate.
         * @member {string} startDate
         * @memberof Proto.WorkStaffExpectTime
         * @instance
         */
        WorkStaffExpectTime.prototype.startDate = "";

        /**
         * WorkStaffExpectTime weeklyHour.
         * @member {number} weeklyHour
         * @memberof Proto.WorkStaffExpectTime
         * @instance
         */
        WorkStaffExpectTime.prototype.weeklyHour = 0;

        /**
         * WorkStaffExpectTime monthlyHour.
         * @member {number} monthlyHour
         * @memberof Proto.WorkStaffExpectTime
         * @instance
         */
        WorkStaffExpectTime.prototype.monthlyHour = 0;

        /**
         * WorkStaffExpectTime yearlyHour.
         * @member {number} yearlyHour
         * @memberof Proto.WorkStaffExpectTime
         * @instance
         */
        WorkStaffExpectTime.prototype.yearlyHour = 0;

        /**
         * WorkStaffExpectTime registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStaffExpectTime
         * @instance
         */
        WorkStaffExpectTime.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStaffExpectTime modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStaffExpectTime
         * @instance
         */
        WorkStaffExpectTime.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new WorkStaffExpectTime instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStaffExpectTime
         * @static
         * @param {Proto.IWorkStaffExpectTime=} [properties] Properties to set
         * @returns {Proto.WorkStaffExpectTime} WorkStaffExpectTime instance
         */
        WorkStaffExpectTime.create = function create(properties) {
            return new WorkStaffExpectTime(properties);
        };

        /**
         * Encodes the specified WorkStaffExpectTime message. Does not implicitly {@link Proto.WorkStaffExpectTime.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStaffExpectTime
         * @static
         * @param {Proto.IWorkStaffExpectTime} m WorkStaffExpectTime message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStaffExpectTime.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.staffUID != null && Object.hasOwnProperty.call(m, "staffUID"))
                w.uint32(10).string(m.staffUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(26).string(m.startDate);
            if (m.weeklyHour != null && Object.hasOwnProperty.call(m, "weeklyHour"))
                w.uint32(32).int32(m.weeklyHour);
            if (m.monthlyHour != null && Object.hasOwnProperty.call(m, "monthlyHour"))
                w.uint32(40).int32(m.monthlyHour);
            if (m.yearlyHour != null && Object.hasOwnProperty.call(m, "yearlyHour"))
                w.uint32(48).int32(m.yearlyHour);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(56).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(64).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a WorkStaffExpectTime message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStaffExpectTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStaffExpectTime} WorkStaffExpectTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStaffExpectTime.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStaffExpectTime();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.staffUID = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.startDate = r.string();
                    break;
                case 4:
                    m.weeklyHour = r.int32();
                    break;
                case 5:
                    m.monthlyHour = r.int32();
                    break;
                case 6:
                    m.yearlyHour = r.int32();
                    break;
                case 7:
                    m.registerDate = r.int64();
                    break;
                case 8:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStaffExpectTime message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStaffExpectTime
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStaffExpectTime} WorkStaffExpectTime
         */
        WorkStaffExpectTime.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStaffExpectTime)
                return d;
            var m = new $root.Proto.WorkStaffExpectTime();
            if (d.staffUID != null) {
                m.staffUID = String(d.staffUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.startDate != null) {
                m.startDate = String(d.startDate);
            }
            if (d.weeklyHour != null) {
                m.weeklyHour = d.weeklyHour | 0;
            }
            if (d.monthlyHour != null) {
                m.monthlyHour = d.monthlyHour | 0;
            }
            if (d.yearlyHour != null) {
                m.yearlyHour = d.yearlyHour | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStaffExpectTime message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStaffExpectTime
         * @static
         * @param {Proto.WorkStaffExpectTime} m WorkStaffExpectTime
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStaffExpectTime.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.staffUID = "";
                d.type = 0;
                d.startDate = "";
                d.weeklyHour = 0;
                d.monthlyHour = 0;
                d.yearlyHour = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.staffUID != null && m.hasOwnProperty("staffUID")) {
                d.staffUID = m.staffUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                d.startDate = m.startDate;
            }
            if (m.weeklyHour != null && m.hasOwnProperty("weeklyHour")) {
                d.weeklyHour = m.weeklyHour;
            }
            if (m.monthlyHour != null && m.hasOwnProperty("monthlyHour")) {
                d.monthlyHour = m.monthlyHour;
            }
            if (m.yearlyHour != null && m.hasOwnProperty("yearlyHour")) {
                d.yearlyHour = m.yearlyHour;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this WorkStaffExpectTime to JSON.
         * @function toJSON
         * @memberof Proto.WorkStaffExpectTime
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStaffExpectTime.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStaffExpectTime;
    })();

    Proto.WorkStaffVacationSetting = (function() {

        /**
         * Properties of a WorkStaffVacationSetting.
         * @memberof Proto
         * @interface IWorkStaffVacationSetting
         * @property {string|null} [staffUID] WorkStaffVacationSetting staffUID
         * @property {number|null} [type] WorkStaffVacationSetting type
         * @property {string|null} [baseStartDate] WorkStaffVacationSetting baseStartDate
         * @property {string|null} [useStartDate] WorkStaffVacationSetting useStartDate
         * @property {number|null} [firstYearDay] WorkStaffVacationSetting firstYearDay
         * @property {number|null} [durationType] WorkStaffVacationSetting durationType
         * @property {number|null} [durationNum] WorkStaffVacationSetting durationNum
         * @property {string|null} [baseAddDate] WorkStaffVacationSetting baseAddDate
         * @property {number|null} [yearlyDay] WorkStaffVacationSetting yearlyDay
         * @property {number|Long|null} [registerDate] WorkStaffVacationSetting registerDate
         * @property {number|Long|null} [modifyDate] WorkStaffVacationSetting modifyDate
         */

        /**
         * Constructs a new WorkStaffVacationSetting.
         * @memberof Proto
         * @classdesc Represents a WorkStaffVacationSetting.
         * @implements IWorkStaffVacationSetting
         * @constructor
         * @param {Proto.IWorkStaffVacationSetting=} [p] Properties to set
         */
        function WorkStaffVacationSetting(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStaffVacationSetting staffUID.
         * @member {string} staffUID
         * @memberof Proto.WorkStaffVacationSetting
         * @instance
         */
        WorkStaffVacationSetting.prototype.staffUID = "";

        /**
         * WorkStaffVacationSetting type.
         * @member {number} type
         * @memberof Proto.WorkStaffVacationSetting
         * @instance
         */
        WorkStaffVacationSetting.prototype.type = 0;

        /**
         * WorkStaffVacationSetting baseStartDate.
         * @member {string} baseStartDate
         * @memberof Proto.WorkStaffVacationSetting
         * @instance
         */
        WorkStaffVacationSetting.prototype.baseStartDate = "";

        /**
         * WorkStaffVacationSetting useStartDate.
         * @member {string} useStartDate
         * @memberof Proto.WorkStaffVacationSetting
         * @instance
         */
        WorkStaffVacationSetting.prototype.useStartDate = "";

        /**
         * WorkStaffVacationSetting firstYearDay.
         * @member {number} firstYearDay
         * @memberof Proto.WorkStaffVacationSetting
         * @instance
         */
        WorkStaffVacationSetting.prototype.firstYearDay = 0;

        /**
         * WorkStaffVacationSetting durationType.
         * @member {number} durationType
         * @memberof Proto.WorkStaffVacationSetting
         * @instance
         */
        WorkStaffVacationSetting.prototype.durationType = 0;

        /**
         * WorkStaffVacationSetting durationNum.
         * @member {number} durationNum
         * @memberof Proto.WorkStaffVacationSetting
         * @instance
         */
        WorkStaffVacationSetting.prototype.durationNum = 0;

        /**
         * WorkStaffVacationSetting baseAddDate.
         * @member {string} baseAddDate
         * @memberof Proto.WorkStaffVacationSetting
         * @instance
         */
        WorkStaffVacationSetting.prototype.baseAddDate = "";

        /**
         * WorkStaffVacationSetting yearlyDay.
         * @member {number} yearlyDay
         * @memberof Proto.WorkStaffVacationSetting
         * @instance
         */
        WorkStaffVacationSetting.prototype.yearlyDay = 0;

        /**
         * WorkStaffVacationSetting registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStaffVacationSetting
         * @instance
         */
        WorkStaffVacationSetting.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStaffVacationSetting modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStaffVacationSetting
         * @instance
         */
        WorkStaffVacationSetting.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new WorkStaffVacationSetting instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStaffVacationSetting
         * @static
         * @param {Proto.IWorkStaffVacationSetting=} [properties] Properties to set
         * @returns {Proto.WorkStaffVacationSetting} WorkStaffVacationSetting instance
         */
        WorkStaffVacationSetting.create = function create(properties) {
            return new WorkStaffVacationSetting(properties);
        };

        /**
         * Encodes the specified WorkStaffVacationSetting message. Does not implicitly {@link Proto.WorkStaffVacationSetting.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStaffVacationSetting
         * @static
         * @param {Proto.IWorkStaffVacationSetting} m WorkStaffVacationSetting message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStaffVacationSetting.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.staffUID != null && Object.hasOwnProperty.call(m, "staffUID"))
                w.uint32(10).string(m.staffUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.baseStartDate != null && Object.hasOwnProperty.call(m, "baseStartDate"))
                w.uint32(26).string(m.baseStartDate);
            if (m.useStartDate != null && Object.hasOwnProperty.call(m, "useStartDate"))
                w.uint32(34).string(m.useStartDate);
            if (m.firstYearDay != null && Object.hasOwnProperty.call(m, "firstYearDay"))
                w.uint32(40).int32(m.firstYearDay);
            if (m.durationType != null && Object.hasOwnProperty.call(m, "durationType"))
                w.uint32(48).int32(m.durationType);
            if (m.durationNum != null && Object.hasOwnProperty.call(m, "durationNum"))
                w.uint32(56).int32(m.durationNum);
            if (m.baseAddDate != null && Object.hasOwnProperty.call(m, "baseAddDate"))
                w.uint32(66).string(m.baseAddDate);
            if (m.yearlyDay != null && Object.hasOwnProperty.call(m, "yearlyDay"))
                w.uint32(72).int32(m.yearlyDay);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(80).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(88).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a WorkStaffVacationSetting message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStaffVacationSetting
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStaffVacationSetting} WorkStaffVacationSetting
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStaffVacationSetting.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStaffVacationSetting();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.staffUID = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.baseStartDate = r.string();
                    break;
                case 4:
                    m.useStartDate = r.string();
                    break;
                case 5:
                    m.firstYearDay = r.int32();
                    break;
                case 6:
                    m.durationType = r.int32();
                    break;
                case 7:
                    m.durationNum = r.int32();
                    break;
                case 8:
                    m.baseAddDate = r.string();
                    break;
                case 9:
                    m.yearlyDay = r.int32();
                    break;
                case 10:
                    m.registerDate = r.int64();
                    break;
                case 11:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStaffVacationSetting message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStaffVacationSetting
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStaffVacationSetting} WorkStaffVacationSetting
         */
        WorkStaffVacationSetting.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStaffVacationSetting)
                return d;
            var m = new $root.Proto.WorkStaffVacationSetting();
            if (d.staffUID != null) {
                m.staffUID = String(d.staffUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.baseStartDate != null) {
                m.baseStartDate = String(d.baseStartDate);
            }
            if (d.useStartDate != null) {
                m.useStartDate = String(d.useStartDate);
            }
            if (d.firstYearDay != null) {
                m.firstYearDay = d.firstYearDay | 0;
            }
            if (d.durationType != null) {
                m.durationType = d.durationType | 0;
            }
            if (d.durationNum != null) {
                m.durationNum = d.durationNum | 0;
            }
            if (d.baseAddDate != null) {
                m.baseAddDate = String(d.baseAddDate);
            }
            if (d.yearlyDay != null) {
                m.yearlyDay = d.yearlyDay | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStaffVacationSetting message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStaffVacationSetting
         * @static
         * @param {Proto.WorkStaffVacationSetting} m WorkStaffVacationSetting
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStaffVacationSetting.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.staffUID = "";
                d.type = 0;
                d.baseStartDate = "";
                d.useStartDate = "";
                d.firstYearDay = 0;
                d.durationType = 0;
                d.durationNum = 0;
                d.baseAddDate = "";
                d.yearlyDay = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.staffUID != null && m.hasOwnProperty("staffUID")) {
                d.staffUID = m.staffUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.baseStartDate != null && m.hasOwnProperty("baseStartDate")) {
                d.baseStartDate = m.baseStartDate;
            }
            if (m.useStartDate != null && m.hasOwnProperty("useStartDate")) {
                d.useStartDate = m.useStartDate;
            }
            if (m.firstYearDay != null && m.hasOwnProperty("firstYearDay")) {
                d.firstYearDay = m.firstYearDay;
            }
            if (m.durationType != null && m.hasOwnProperty("durationType")) {
                d.durationType = m.durationType;
            }
            if (m.durationNum != null && m.hasOwnProperty("durationNum")) {
                d.durationNum = m.durationNum;
            }
            if (m.baseAddDate != null && m.hasOwnProperty("baseAddDate")) {
                d.baseAddDate = m.baseAddDate;
            }
            if (m.yearlyDay != null && m.hasOwnProperty("yearlyDay")) {
                d.yearlyDay = m.yearlyDay;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this WorkStaffVacationSetting to JSON.
         * @function toJSON
         * @memberof Proto.WorkStaffVacationSetting
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStaffVacationSetting.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStaffVacationSetting;
    })();

    Proto.WorkStoreTimetableRequest = (function() {

        /**
         * Properties of a WorkStoreTimetableRequest.
         * @memberof Proto
         * @interface IWorkStoreTimetableRequest
         * @property {string|null} [uid] WorkStoreTimetableRequest uid
         * @property {string|null} [staffUID] WorkStoreTimetableRequest staffUID
         * @property {number|null} [type] WorkStoreTimetableRequest type
         * @property {number|Long|null} [startDate] WorkStoreTimetableRequest startDate
         * @property {number|Long|null} [endDate] WorkStoreTimetableRequest endDate
         * @property {string|null} [otherStaffUID] WorkStoreTimetableRequest otherStaffUID
         * @property {boolean|null} [fixed] WorkStoreTimetableRequest fixed
         * @property {string|null} [memo] WorkStoreTimetableRequest memo
         * @property {number|Long|null} [registerDate] WorkStoreTimetableRequest registerDate
         * @property {number|Long|null} [modifyDate] WorkStoreTimetableRequest modifyDate
         * @property {number|null} [status] WorkStoreTimetableRequest status
         * @property {Proto.IWorkStore|null} [store] WorkStoreTimetableRequest store
         * @property {number|Long|null} [changeStartDate] WorkStoreTimetableRequest changeStartDate
         * @property {number|Long|null} [changeEndDate] WorkStoreTimetableRequest changeEndDate
         * @property {number|Long|null} [restStartDate] WorkStoreTimetableRequest restStartDate
         * @property {number|Long|null} [restEndDate] WorkStoreTimetableRequest restEndDate
         * @property {number|null} [restDuration] WorkStoreTimetableRequest restDuration
         * @property {number|null} [vacationDuration] WorkStoreTimetableRequest vacationDuration
         * @property {Proto.IWorkStaff|null} [otherStaff] WorkStoreTimetableRequest otherStaff
         */

        /**
         * Constructs a new WorkStoreTimetableRequest.
         * @memberof Proto
         * @classdesc Represents a WorkStoreTimetableRequest.
         * @implements IWorkStoreTimetableRequest
         * @constructor
         * @param {Proto.IWorkStoreTimetableRequest=} [p] Properties to set
         */
        function WorkStoreTimetableRequest(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreTimetableRequest uid.
         * @member {string} uid
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.uid = "";

        /**
         * WorkStoreTimetableRequest staffUID.
         * @member {string} staffUID
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.staffUID = "";

        /**
         * WorkStoreTimetableRequest type.
         * @member {number} type
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.type = 0;

        /**
         * WorkStoreTimetableRequest startDate.
         * @member {number|Long} startDate
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableRequest endDate.
         * @member {number|Long} endDate
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableRequest otherStaffUID.
         * @member {string} otherStaffUID
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.otherStaffUID = "";

        /**
         * WorkStoreTimetableRequest fixed.
         * @member {boolean} fixed
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.fixed = false;

        /**
         * WorkStoreTimetableRequest memo.
         * @member {string} memo
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.memo = "";

        /**
         * WorkStoreTimetableRequest registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableRequest modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableRequest status.
         * @member {number} status
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.status = 0;

        /**
         * WorkStoreTimetableRequest store.
         * @member {Proto.IWorkStore|null|undefined} store
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.store = null;

        /**
         * WorkStoreTimetableRequest changeStartDate.
         * @member {number|Long} changeStartDate
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.changeStartDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableRequest changeEndDate.
         * @member {number|Long} changeEndDate
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.changeEndDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableRequest restStartDate.
         * @member {number|Long} restStartDate
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.restStartDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableRequest restEndDate.
         * @member {number|Long} restEndDate
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.restEndDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableRequest restDuration.
         * @member {number} restDuration
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.restDuration = 0;

        /**
         * WorkStoreTimetableRequest vacationDuration.
         * @member {number} vacationDuration
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.vacationDuration = 0;

        /**
         * WorkStoreTimetableRequest otherStaff.
         * @member {Proto.IWorkStaff|null|undefined} otherStaff
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         */
        WorkStoreTimetableRequest.prototype.otherStaff = null;

        /**
         * Creates a new WorkStoreTimetableRequest instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreTimetableRequest
         * @static
         * @param {Proto.IWorkStoreTimetableRequest=} [properties] Properties to set
         * @returns {Proto.WorkStoreTimetableRequest} WorkStoreTimetableRequest instance
         */
        WorkStoreTimetableRequest.create = function create(properties) {
            return new WorkStoreTimetableRequest(properties);
        };

        /**
         * Encodes the specified WorkStoreTimetableRequest message. Does not implicitly {@link Proto.WorkStoreTimetableRequest.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreTimetableRequest
         * @static
         * @param {Proto.IWorkStoreTimetableRequest} m WorkStoreTimetableRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreTimetableRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.staffUID != null && Object.hasOwnProperty.call(m, "staffUID"))
                w.uint32(18).string(m.staffUID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(24).int32(m.type);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(32).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(40).int64(m.endDate);
            if (m.otherStaffUID != null && Object.hasOwnProperty.call(m, "otherStaffUID"))
                w.uint32(50).string(m.otherStaffUID);
            if (m.fixed != null && Object.hasOwnProperty.call(m, "fixed"))
                w.uint32(56).bool(m.fixed);
            if (m.memo != null && Object.hasOwnProperty.call(m, "memo"))
                w.uint32(66).string(m.memo);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(72).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(80).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(88).int32(m.status);
            if (m.store != null && Object.hasOwnProperty.call(m, "store"))
                $root.Proto.WorkStore.encode(m.store, w.uint32(98).fork()).ldelim();
            if (m.changeStartDate != null && Object.hasOwnProperty.call(m, "changeStartDate"))
                w.uint32(104).int64(m.changeStartDate);
            if (m.changeEndDate != null && Object.hasOwnProperty.call(m, "changeEndDate"))
                w.uint32(112).int64(m.changeEndDate);
            if (m.restStartDate != null && Object.hasOwnProperty.call(m, "restStartDate"))
                w.uint32(120).int64(m.restStartDate);
            if (m.restEndDate != null && Object.hasOwnProperty.call(m, "restEndDate"))
                w.uint32(128).int64(m.restEndDate);
            if (m.restDuration != null && Object.hasOwnProperty.call(m, "restDuration"))
                w.uint32(136).int32(m.restDuration);
            if (m.vacationDuration != null && Object.hasOwnProperty.call(m, "vacationDuration"))
                w.uint32(144).int32(m.vacationDuration);
            if (m.otherStaff != null && Object.hasOwnProperty.call(m, "otherStaff"))
                $root.Proto.WorkStaff.encode(m.otherStaff, w.uint32(154).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a WorkStoreTimetableRequest message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreTimetableRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreTimetableRequest} WorkStoreTimetableRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreTimetableRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreTimetableRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.staffUID = r.string();
                    break;
                case 3:
                    m.type = r.int32();
                    break;
                case 4:
                    m.startDate = r.int64();
                    break;
                case 5:
                    m.endDate = r.int64();
                    break;
                case 6:
                    m.otherStaffUID = r.string();
                    break;
                case 7:
                    m.fixed = r.bool();
                    break;
                case 8:
                    m.memo = r.string();
                    break;
                case 9:
                    m.registerDate = r.int64();
                    break;
                case 10:
                    m.modifyDate = r.int64();
                    break;
                case 11:
                    m.status = r.int32();
                    break;
                case 12:
                    m.store = $root.Proto.WorkStore.decode(r, r.uint32());
                    break;
                case 13:
                    m.changeStartDate = r.int64();
                    break;
                case 14:
                    m.changeEndDate = r.int64();
                    break;
                case 15:
                    m.restStartDate = r.int64();
                    break;
                case 16:
                    m.restEndDate = r.int64();
                    break;
                case 17:
                    m.restDuration = r.int32();
                    break;
                case 18:
                    m.vacationDuration = r.int32();
                    break;
                case 19:
                    m.otherStaff = $root.Proto.WorkStaff.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreTimetableRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreTimetableRequest
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreTimetableRequest} WorkStoreTimetableRequest
         */
        WorkStoreTimetableRequest.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreTimetableRequest)
                return d;
            var m = new $root.Proto.WorkStoreTimetableRequest();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.staffUID != null) {
                m.staffUID = String(d.staffUID);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.otherStaffUID != null) {
                m.otherStaffUID = String(d.otherStaffUID);
            }
            if (d.fixed != null) {
                m.fixed = Boolean(d.fixed);
            }
            if (d.memo != null) {
                m.memo = String(d.memo);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.store != null) {
                if (typeof d.store !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableRequest.store: object expected");
                m.store = $root.Proto.WorkStore.fromObject(d.store);
            }
            if (d.changeStartDate != null) {
                if ($util.Long)
                    (m.changeStartDate = $util.Long.fromValue(d.changeStartDate)).unsigned = false;
                else if (typeof d.changeStartDate === "string")
                    m.changeStartDate = parseInt(d.changeStartDate, 10);
                else if (typeof d.changeStartDate === "number")
                    m.changeStartDate = d.changeStartDate;
                else if (typeof d.changeStartDate === "object")
                    m.changeStartDate = new $util.LongBits(d.changeStartDate.low >>> 0, d.changeStartDate.high >>> 0).toNumber();
            }
            if (d.changeEndDate != null) {
                if ($util.Long)
                    (m.changeEndDate = $util.Long.fromValue(d.changeEndDate)).unsigned = false;
                else if (typeof d.changeEndDate === "string")
                    m.changeEndDate = parseInt(d.changeEndDate, 10);
                else if (typeof d.changeEndDate === "number")
                    m.changeEndDate = d.changeEndDate;
                else if (typeof d.changeEndDate === "object")
                    m.changeEndDate = new $util.LongBits(d.changeEndDate.low >>> 0, d.changeEndDate.high >>> 0).toNumber();
            }
            if (d.restStartDate != null) {
                if ($util.Long)
                    (m.restStartDate = $util.Long.fromValue(d.restStartDate)).unsigned = false;
                else if (typeof d.restStartDate === "string")
                    m.restStartDate = parseInt(d.restStartDate, 10);
                else if (typeof d.restStartDate === "number")
                    m.restStartDate = d.restStartDate;
                else if (typeof d.restStartDate === "object")
                    m.restStartDate = new $util.LongBits(d.restStartDate.low >>> 0, d.restStartDate.high >>> 0).toNumber();
            }
            if (d.restEndDate != null) {
                if ($util.Long)
                    (m.restEndDate = $util.Long.fromValue(d.restEndDate)).unsigned = false;
                else if (typeof d.restEndDate === "string")
                    m.restEndDate = parseInt(d.restEndDate, 10);
                else if (typeof d.restEndDate === "number")
                    m.restEndDate = d.restEndDate;
                else if (typeof d.restEndDate === "object")
                    m.restEndDate = new $util.LongBits(d.restEndDate.low >>> 0, d.restEndDate.high >>> 0).toNumber();
            }
            if (d.restDuration != null) {
                m.restDuration = d.restDuration | 0;
            }
            if (d.vacationDuration != null) {
                m.vacationDuration = d.vacationDuration | 0;
            }
            if (d.otherStaff != null) {
                if (typeof d.otherStaff !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableRequest.otherStaff: object expected");
                m.otherStaff = $root.Proto.WorkStaff.fromObject(d.otherStaff);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreTimetableRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreTimetableRequest
         * @static
         * @param {Proto.WorkStoreTimetableRequest} m WorkStoreTimetableRequest
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreTimetableRequest.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.staffUID = "";
                d.type = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                d.otherStaffUID = "";
                d.fixed = false;
                d.memo = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.store = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.changeStartDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.changeStartDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.changeEndDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.changeEndDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.restStartDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.restStartDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.restEndDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.restEndDate = o.longs === String ? "0" : 0;
                d.restDuration = 0;
                d.vacationDuration = 0;
                d.otherStaff = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.staffUID != null && m.hasOwnProperty("staffUID")) {
                d.staffUID = m.staffUID;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.otherStaffUID != null && m.hasOwnProperty("otherStaffUID")) {
                d.otherStaffUID = m.otherStaffUID;
            }
            if (m.fixed != null && m.hasOwnProperty("fixed")) {
                d.fixed = m.fixed;
            }
            if (m.memo != null && m.hasOwnProperty("memo")) {
                d.memo = m.memo;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.store != null && m.hasOwnProperty("store")) {
                d.store = $root.Proto.WorkStore.toObject(m.store, o);
            }
            if (m.changeStartDate != null && m.hasOwnProperty("changeStartDate")) {
                if (typeof m.changeStartDate === "number")
                    d.changeStartDate = o.longs === String ? String(m.changeStartDate) : m.changeStartDate;
                else
                    d.changeStartDate = o.longs === String ? $util.Long.prototype.toString.call(m.changeStartDate) : o.longs === Number ? new $util.LongBits(m.changeStartDate.low >>> 0, m.changeStartDate.high >>> 0).toNumber() : m.changeStartDate;
            }
            if (m.changeEndDate != null && m.hasOwnProperty("changeEndDate")) {
                if (typeof m.changeEndDate === "number")
                    d.changeEndDate = o.longs === String ? String(m.changeEndDate) : m.changeEndDate;
                else
                    d.changeEndDate = o.longs === String ? $util.Long.prototype.toString.call(m.changeEndDate) : o.longs === Number ? new $util.LongBits(m.changeEndDate.low >>> 0, m.changeEndDate.high >>> 0).toNumber() : m.changeEndDate;
            }
            if (m.restStartDate != null && m.hasOwnProperty("restStartDate")) {
                if (typeof m.restStartDate === "number")
                    d.restStartDate = o.longs === String ? String(m.restStartDate) : m.restStartDate;
                else
                    d.restStartDate = o.longs === String ? $util.Long.prototype.toString.call(m.restStartDate) : o.longs === Number ? new $util.LongBits(m.restStartDate.low >>> 0, m.restStartDate.high >>> 0).toNumber() : m.restStartDate;
            }
            if (m.restEndDate != null && m.hasOwnProperty("restEndDate")) {
                if (typeof m.restEndDate === "number")
                    d.restEndDate = o.longs === String ? String(m.restEndDate) : m.restEndDate;
                else
                    d.restEndDate = o.longs === String ? $util.Long.prototype.toString.call(m.restEndDate) : o.longs === Number ? new $util.LongBits(m.restEndDate.low >>> 0, m.restEndDate.high >>> 0).toNumber() : m.restEndDate;
            }
            if (m.restDuration != null && m.hasOwnProperty("restDuration")) {
                d.restDuration = m.restDuration;
            }
            if (m.vacationDuration != null && m.hasOwnProperty("vacationDuration")) {
                d.vacationDuration = m.vacationDuration;
            }
            if (m.otherStaff != null && m.hasOwnProperty("otherStaff")) {
                d.otherStaff = $root.Proto.WorkStaff.toObject(m.otherStaff, o);
            }
            return d;
        };

        /**
         * Converts this WorkStoreTimetableRequest to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreTimetableRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreTimetableRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreTimetableRequest;
    })();

    Proto.WorkStoreTimetableRequestList = (function() {

        /**
         * Properties of a WorkStoreTimetableRequestList.
         * @memberof Proto
         * @interface IWorkStoreTimetableRequestList
         * @property {Array.<Proto.IWorkStoreTimetableRequest>|null} [value] WorkStoreTimetableRequestList value
         */

        /**
         * Constructs a new WorkStoreTimetableRequestList.
         * @memberof Proto
         * @classdesc Represents a WorkStoreTimetableRequestList.
         * @implements IWorkStoreTimetableRequestList
         * @constructor
         * @param {Proto.IWorkStoreTimetableRequestList=} [p] Properties to set
         */
        function WorkStoreTimetableRequestList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreTimetableRequestList value.
         * @member {Array.<Proto.IWorkStoreTimetableRequest>} value
         * @memberof Proto.WorkStoreTimetableRequestList
         * @instance
         */
        WorkStoreTimetableRequestList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkStoreTimetableRequestList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreTimetableRequestList
         * @static
         * @param {Proto.IWorkStoreTimetableRequestList=} [properties] Properties to set
         * @returns {Proto.WorkStoreTimetableRequestList} WorkStoreTimetableRequestList instance
         */
        WorkStoreTimetableRequestList.create = function create(properties) {
            return new WorkStoreTimetableRequestList(properties);
        };

        /**
         * Encodes the specified WorkStoreTimetableRequestList message. Does not implicitly {@link Proto.WorkStoreTimetableRequestList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreTimetableRequestList
         * @static
         * @param {Proto.IWorkStoreTimetableRequestList} m WorkStoreTimetableRequestList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreTimetableRequestList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkStoreTimetableRequest.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkStoreTimetableRequestList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreTimetableRequestList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreTimetableRequestList} WorkStoreTimetableRequestList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreTimetableRequestList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreTimetableRequestList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkStoreTimetableRequest.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreTimetableRequestList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreTimetableRequestList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreTimetableRequestList} WorkStoreTimetableRequestList
         */
        WorkStoreTimetableRequestList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreTimetableRequestList)
                return d;
            var m = new $root.Proto.WorkStoreTimetableRequestList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkStoreTimetableRequestList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkStoreTimetableRequestList.value: object expected");
                    m.value[i] = $root.Proto.WorkStoreTimetableRequest.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreTimetableRequestList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreTimetableRequestList
         * @static
         * @param {Proto.WorkStoreTimetableRequestList} m WorkStoreTimetableRequestList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreTimetableRequestList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkStoreTimetableRequest.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkStoreTimetableRequestList to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreTimetableRequestList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreTimetableRequestList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreTimetableRequestList;
    })();

    Proto.WorkStoreTimetable = (function() {

        /**
         * Properties of a WorkStoreTimetable.
         * @memberof Proto
         * @interface IWorkStoreTimetable
         * @property {string|null} [uid] WorkStoreTimetable uid
         * @property {string|null} [staffUID] WorkStoreTimetable staffUID
         * @property {number|Long|null} [startDate] WorkStoreTimetable startDate
         * @property {number|Long|null} [endDate] WorkStoreTimetable endDate
         * @property {number|Long|null} [workStartDate] WorkStoreTimetable workStartDate
         * @property {number|Long|null} [workEndDate] WorkStoreTimetable workEndDate
         * @property {Proto.INullDouble|null} [workStartLatitude] WorkStoreTimetable workStartLatitude
         * @property {Proto.INullDouble|null} [workStartLongitude] WorkStoreTimetable workStartLongitude
         * @property {Proto.INullDouble|null} [workEndLatitude] WorkStoreTimetable workEndLatitude
         * @property {Proto.INullDouble|null} [workEndLongitude] WorkStoreTimetable workEndLongitude
         * @property {number|null} [restDuration] WorkStoreTimetable restDuration
         * @property {Proto.INullDouble|null} [payHour] WorkStoreTimetable payHour
         * @property {string|null} [currency] WorkStoreTimetable currency
         * @property {string|null} [memo] WorkStoreTimetable memo
         * @property {boolean|null} [fixed] WorkStoreTimetable fixed
         * @property {number|Long|null} [registerDate] WorkStoreTimetable registerDate
         * @property {number|Long|null} [modifyDate] WorkStoreTimetable modifyDate
         * @property {Proto.IWorkStore|null} [store] WorkStoreTimetable store
         * @property {Proto.IWorkStoreTimetableRequest|null} [request] WorkStoreTimetable request
         * @property {number|null} [payTotal] WorkStoreTimetable payTotal
         * @property {number|null} [type] WorkStoreTimetable type
         * @property {number|Long|null} [restStartDate] WorkStoreTimetable restStartDate
         * @property {number|Long|null} [restEndDate] WorkStoreTimetable restEndDate
         * @property {number|null} [vacationDuration] WorkStoreTimetable vacationDuration
         */

        /**
         * Constructs a new WorkStoreTimetable.
         * @memberof Proto
         * @classdesc Represents a WorkStoreTimetable.
         * @implements IWorkStoreTimetable
         * @constructor
         * @param {Proto.IWorkStoreTimetable=} [p] Properties to set
         */
        function WorkStoreTimetable(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreTimetable uid.
         * @member {string} uid
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.uid = "";

        /**
         * WorkStoreTimetable staffUID.
         * @member {string} staffUID
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.staffUID = "";

        /**
         * WorkStoreTimetable startDate.
         * @member {number|Long} startDate
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetable endDate.
         * @member {number|Long} endDate
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetable workStartDate.
         * @member {number|Long} workStartDate
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.workStartDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetable workEndDate.
         * @member {number|Long} workEndDate
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.workEndDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetable workStartLatitude.
         * @member {Proto.INullDouble|null|undefined} workStartLatitude
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.workStartLatitude = null;

        /**
         * WorkStoreTimetable workStartLongitude.
         * @member {Proto.INullDouble|null|undefined} workStartLongitude
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.workStartLongitude = null;

        /**
         * WorkStoreTimetable workEndLatitude.
         * @member {Proto.INullDouble|null|undefined} workEndLatitude
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.workEndLatitude = null;

        /**
         * WorkStoreTimetable workEndLongitude.
         * @member {Proto.INullDouble|null|undefined} workEndLongitude
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.workEndLongitude = null;

        /**
         * WorkStoreTimetable restDuration.
         * @member {number} restDuration
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.restDuration = 0;

        /**
         * WorkStoreTimetable payHour.
         * @member {Proto.INullDouble|null|undefined} payHour
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.payHour = null;

        /**
         * WorkStoreTimetable currency.
         * @member {string} currency
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.currency = "";

        /**
         * WorkStoreTimetable memo.
         * @member {string} memo
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.memo = "";

        /**
         * WorkStoreTimetable fixed.
         * @member {boolean} fixed
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.fixed = false;

        /**
         * WorkStoreTimetable registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetable modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetable store.
         * @member {Proto.IWorkStore|null|undefined} store
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.store = null;

        /**
         * WorkStoreTimetable request.
         * @member {Proto.IWorkStoreTimetableRequest|null|undefined} request
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.request = null;

        /**
         * WorkStoreTimetable payTotal.
         * @member {number} payTotal
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.payTotal = 0;

        /**
         * WorkStoreTimetable type.
         * @member {number} type
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.type = 0;

        /**
         * WorkStoreTimetable restStartDate.
         * @member {number|Long} restStartDate
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.restStartDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetable restEndDate.
         * @member {number|Long} restEndDate
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.restEndDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetable vacationDuration.
         * @member {number} vacationDuration
         * @memberof Proto.WorkStoreTimetable
         * @instance
         */
        WorkStoreTimetable.prototype.vacationDuration = 0;

        /**
         * Creates a new WorkStoreTimetable instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreTimetable
         * @static
         * @param {Proto.IWorkStoreTimetable=} [properties] Properties to set
         * @returns {Proto.WorkStoreTimetable} WorkStoreTimetable instance
         */
        WorkStoreTimetable.create = function create(properties) {
            return new WorkStoreTimetable(properties);
        };

        /**
         * Encodes the specified WorkStoreTimetable message. Does not implicitly {@link Proto.WorkStoreTimetable.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreTimetable
         * @static
         * @param {Proto.IWorkStoreTimetable} m WorkStoreTimetable message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreTimetable.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.staffUID != null && Object.hasOwnProperty.call(m, "staffUID"))
                w.uint32(18).string(m.staffUID);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(24).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(32).int64(m.endDate);
            if (m.workStartDate != null && Object.hasOwnProperty.call(m, "workStartDate"))
                w.uint32(40).int64(m.workStartDate);
            if (m.workEndDate != null && Object.hasOwnProperty.call(m, "workEndDate"))
                w.uint32(48).int64(m.workEndDate);
            if (m.workStartLatitude != null && Object.hasOwnProperty.call(m, "workStartLatitude"))
                $root.Proto.NullDouble.encode(m.workStartLatitude, w.uint32(58).fork()).ldelim();
            if (m.workStartLongitude != null && Object.hasOwnProperty.call(m, "workStartLongitude"))
                $root.Proto.NullDouble.encode(m.workStartLongitude, w.uint32(66).fork()).ldelim();
            if (m.workEndLatitude != null && Object.hasOwnProperty.call(m, "workEndLatitude"))
                $root.Proto.NullDouble.encode(m.workEndLatitude, w.uint32(74).fork()).ldelim();
            if (m.workEndLongitude != null && Object.hasOwnProperty.call(m, "workEndLongitude"))
                $root.Proto.NullDouble.encode(m.workEndLongitude, w.uint32(82).fork()).ldelim();
            if (m.restDuration != null && Object.hasOwnProperty.call(m, "restDuration"))
                w.uint32(88).int32(m.restDuration);
            if (m.payHour != null && Object.hasOwnProperty.call(m, "payHour"))
                $root.Proto.NullDouble.encode(m.payHour, w.uint32(98).fork()).ldelim();
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(106).string(m.currency);
            if (m.memo != null && Object.hasOwnProperty.call(m, "memo"))
                w.uint32(114).string(m.memo);
            if (m.fixed != null && Object.hasOwnProperty.call(m, "fixed"))
                w.uint32(120).bool(m.fixed);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(128).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(136).int64(m.modifyDate);
            if (m.store != null && Object.hasOwnProperty.call(m, "store"))
                $root.Proto.WorkStore.encode(m.store, w.uint32(146).fork()).ldelim();
            if (m.request != null && Object.hasOwnProperty.call(m, "request"))
                $root.Proto.WorkStoreTimetableRequest.encode(m.request, w.uint32(154).fork()).ldelim();
            if (m.payTotal != null && Object.hasOwnProperty.call(m, "payTotal"))
                w.uint32(161).double(m.payTotal);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(168).int32(m.type);
            if (m.restStartDate != null && Object.hasOwnProperty.call(m, "restStartDate"))
                w.uint32(176).int64(m.restStartDate);
            if (m.restEndDate != null && Object.hasOwnProperty.call(m, "restEndDate"))
                w.uint32(184).int64(m.restEndDate);
            if (m.vacationDuration != null && Object.hasOwnProperty.call(m, "vacationDuration"))
                w.uint32(192).int32(m.vacationDuration);
            return w;
        };

        /**
         * Decodes a WorkStoreTimetable message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreTimetable
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreTimetable} WorkStoreTimetable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreTimetable.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreTimetable();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.staffUID = r.string();
                    break;
                case 3:
                    m.startDate = r.int64();
                    break;
                case 4:
                    m.endDate = r.int64();
                    break;
                case 5:
                    m.workStartDate = r.int64();
                    break;
                case 6:
                    m.workEndDate = r.int64();
                    break;
                case 7:
                    m.workStartLatitude = $root.Proto.NullDouble.decode(r, r.uint32());
                    break;
                case 8:
                    m.workStartLongitude = $root.Proto.NullDouble.decode(r, r.uint32());
                    break;
                case 9:
                    m.workEndLatitude = $root.Proto.NullDouble.decode(r, r.uint32());
                    break;
                case 10:
                    m.workEndLongitude = $root.Proto.NullDouble.decode(r, r.uint32());
                    break;
                case 11:
                    m.restDuration = r.int32();
                    break;
                case 12:
                    m.payHour = $root.Proto.NullDouble.decode(r, r.uint32());
                    break;
                case 13:
                    m.currency = r.string();
                    break;
                case 14:
                    m.memo = r.string();
                    break;
                case 15:
                    m.fixed = r.bool();
                    break;
                case 16:
                    m.registerDate = r.int64();
                    break;
                case 17:
                    m.modifyDate = r.int64();
                    break;
                case 18:
                    m.store = $root.Proto.WorkStore.decode(r, r.uint32());
                    break;
                case 19:
                    m.request = $root.Proto.WorkStoreTimetableRequest.decode(r, r.uint32());
                    break;
                case 20:
                    m.payTotal = r.double();
                    break;
                case 21:
                    m.type = r.int32();
                    break;
                case 22:
                    m.restStartDate = r.int64();
                    break;
                case 23:
                    m.restEndDate = r.int64();
                    break;
                case 24:
                    m.vacationDuration = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreTimetable message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreTimetable
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreTimetable} WorkStoreTimetable
         */
        WorkStoreTimetable.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreTimetable)
                return d;
            var m = new $root.Proto.WorkStoreTimetable();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.staffUID != null) {
                m.staffUID = String(d.staffUID);
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.workStartDate != null) {
                if ($util.Long)
                    (m.workStartDate = $util.Long.fromValue(d.workStartDate)).unsigned = false;
                else if (typeof d.workStartDate === "string")
                    m.workStartDate = parseInt(d.workStartDate, 10);
                else if (typeof d.workStartDate === "number")
                    m.workStartDate = d.workStartDate;
                else if (typeof d.workStartDate === "object")
                    m.workStartDate = new $util.LongBits(d.workStartDate.low >>> 0, d.workStartDate.high >>> 0).toNumber();
            }
            if (d.workEndDate != null) {
                if ($util.Long)
                    (m.workEndDate = $util.Long.fromValue(d.workEndDate)).unsigned = false;
                else if (typeof d.workEndDate === "string")
                    m.workEndDate = parseInt(d.workEndDate, 10);
                else if (typeof d.workEndDate === "number")
                    m.workEndDate = d.workEndDate;
                else if (typeof d.workEndDate === "object")
                    m.workEndDate = new $util.LongBits(d.workEndDate.low >>> 0, d.workEndDate.high >>> 0).toNumber();
            }
            if (d.workStartLatitude != null) {
                if (typeof d.workStartLatitude !== "object")
                    throw TypeError(".Proto.WorkStoreTimetable.workStartLatitude: object expected");
                m.workStartLatitude = $root.Proto.NullDouble.fromObject(d.workStartLatitude);
            }
            if (d.workStartLongitude != null) {
                if (typeof d.workStartLongitude !== "object")
                    throw TypeError(".Proto.WorkStoreTimetable.workStartLongitude: object expected");
                m.workStartLongitude = $root.Proto.NullDouble.fromObject(d.workStartLongitude);
            }
            if (d.workEndLatitude != null) {
                if (typeof d.workEndLatitude !== "object")
                    throw TypeError(".Proto.WorkStoreTimetable.workEndLatitude: object expected");
                m.workEndLatitude = $root.Proto.NullDouble.fromObject(d.workEndLatitude);
            }
            if (d.workEndLongitude != null) {
                if (typeof d.workEndLongitude !== "object")
                    throw TypeError(".Proto.WorkStoreTimetable.workEndLongitude: object expected");
                m.workEndLongitude = $root.Proto.NullDouble.fromObject(d.workEndLongitude);
            }
            if (d.restDuration != null) {
                m.restDuration = d.restDuration | 0;
            }
            if (d.payHour != null) {
                if (typeof d.payHour !== "object")
                    throw TypeError(".Proto.WorkStoreTimetable.payHour: object expected");
                m.payHour = $root.Proto.NullDouble.fromObject(d.payHour);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.memo != null) {
                m.memo = String(d.memo);
            }
            if (d.fixed != null) {
                m.fixed = Boolean(d.fixed);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.store != null) {
                if (typeof d.store !== "object")
                    throw TypeError(".Proto.WorkStoreTimetable.store: object expected");
                m.store = $root.Proto.WorkStore.fromObject(d.store);
            }
            if (d.request != null) {
                if (typeof d.request !== "object")
                    throw TypeError(".Proto.WorkStoreTimetable.request: object expected");
                m.request = $root.Proto.WorkStoreTimetableRequest.fromObject(d.request);
            }
            if (d.payTotal != null) {
                m.payTotal = Number(d.payTotal);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.restStartDate != null) {
                if ($util.Long)
                    (m.restStartDate = $util.Long.fromValue(d.restStartDate)).unsigned = false;
                else if (typeof d.restStartDate === "string")
                    m.restStartDate = parseInt(d.restStartDate, 10);
                else if (typeof d.restStartDate === "number")
                    m.restStartDate = d.restStartDate;
                else if (typeof d.restStartDate === "object")
                    m.restStartDate = new $util.LongBits(d.restStartDate.low >>> 0, d.restStartDate.high >>> 0).toNumber();
            }
            if (d.restEndDate != null) {
                if ($util.Long)
                    (m.restEndDate = $util.Long.fromValue(d.restEndDate)).unsigned = false;
                else if (typeof d.restEndDate === "string")
                    m.restEndDate = parseInt(d.restEndDate, 10);
                else if (typeof d.restEndDate === "number")
                    m.restEndDate = d.restEndDate;
                else if (typeof d.restEndDate === "object")
                    m.restEndDate = new $util.LongBits(d.restEndDate.low >>> 0, d.restEndDate.high >>> 0).toNumber();
            }
            if (d.vacationDuration != null) {
                m.vacationDuration = d.vacationDuration | 0;
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreTimetable message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreTimetable
         * @static
         * @param {Proto.WorkStoreTimetable} m WorkStoreTimetable
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreTimetable.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.staffUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.workStartDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.workStartDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.workEndDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.workEndDate = o.longs === String ? "0" : 0;
                d.workStartLatitude = null;
                d.workStartLongitude = null;
                d.workEndLatitude = null;
                d.workEndLongitude = null;
                d.restDuration = 0;
                d.payHour = null;
                d.currency = "";
                d.memo = "";
                d.fixed = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.store = null;
                d.request = null;
                d.payTotal = 0;
                d.type = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.restStartDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.restStartDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.restEndDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.restEndDate = o.longs === String ? "0" : 0;
                d.vacationDuration = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.staffUID != null && m.hasOwnProperty("staffUID")) {
                d.staffUID = m.staffUID;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.workStartDate != null && m.hasOwnProperty("workStartDate")) {
                if (typeof m.workStartDate === "number")
                    d.workStartDate = o.longs === String ? String(m.workStartDate) : m.workStartDate;
                else
                    d.workStartDate = o.longs === String ? $util.Long.prototype.toString.call(m.workStartDate) : o.longs === Number ? new $util.LongBits(m.workStartDate.low >>> 0, m.workStartDate.high >>> 0).toNumber() : m.workStartDate;
            }
            if (m.workEndDate != null && m.hasOwnProperty("workEndDate")) {
                if (typeof m.workEndDate === "number")
                    d.workEndDate = o.longs === String ? String(m.workEndDate) : m.workEndDate;
                else
                    d.workEndDate = o.longs === String ? $util.Long.prototype.toString.call(m.workEndDate) : o.longs === Number ? new $util.LongBits(m.workEndDate.low >>> 0, m.workEndDate.high >>> 0).toNumber() : m.workEndDate;
            }
            if (m.workStartLatitude != null && m.hasOwnProperty("workStartLatitude")) {
                d.workStartLatitude = $root.Proto.NullDouble.toObject(m.workStartLatitude, o);
            }
            if (m.workStartLongitude != null && m.hasOwnProperty("workStartLongitude")) {
                d.workStartLongitude = $root.Proto.NullDouble.toObject(m.workStartLongitude, o);
            }
            if (m.workEndLatitude != null && m.hasOwnProperty("workEndLatitude")) {
                d.workEndLatitude = $root.Proto.NullDouble.toObject(m.workEndLatitude, o);
            }
            if (m.workEndLongitude != null && m.hasOwnProperty("workEndLongitude")) {
                d.workEndLongitude = $root.Proto.NullDouble.toObject(m.workEndLongitude, o);
            }
            if (m.restDuration != null && m.hasOwnProperty("restDuration")) {
                d.restDuration = m.restDuration;
            }
            if (m.payHour != null && m.hasOwnProperty("payHour")) {
                d.payHour = $root.Proto.NullDouble.toObject(m.payHour, o);
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.memo != null && m.hasOwnProperty("memo")) {
                d.memo = m.memo;
            }
            if (m.fixed != null && m.hasOwnProperty("fixed")) {
                d.fixed = m.fixed;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.store != null && m.hasOwnProperty("store")) {
                d.store = $root.Proto.WorkStore.toObject(m.store, o);
            }
            if (m.request != null && m.hasOwnProperty("request")) {
                d.request = $root.Proto.WorkStoreTimetableRequest.toObject(m.request, o);
            }
            if (m.payTotal != null && m.hasOwnProperty("payTotal")) {
                d.payTotal = o.json && !isFinite(m.payTotal) ? String(m.payTotal) : m.payTotal;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.restStartDate != null && m.hasOwnProperty("restStartDate")) {
                if (typeof m.restStartDate === "number")
                    d.restStartDate = o.longs === String ? String(m.restStartDate) : m.restStartDate;
                else
                    d.restStartDate = o.longs === String ? $util.Long.prototype.toString.call(m.restStartDate) : o.longs === Number ? new $util.LongBits(m.restStartDate.low >>> 0, m.restStartDate.high >>> 0).toNumber() : m.restStartDate;
            }
            if (m.restEndDate != null && m.hasOwnProperty("restEndDate")) {
                if (typeof m.restEndDate === "number")
                    d.restEndDate = o.longs === String ? String(m.restEndDate) : m.restEndDate;
                else
                    d.restEndDate = o.longs === String ? $util.Long.prototype.toString.call(m.restEndDate) : o.longs === Number ? new $util.LongBits(m.restEndDate.low >>> 0, m.restEndDate.high >>> 0).toNumber() : m.restEndDate;
            }
            if (m.vacationDuration != null && m.hasOwnProperty("vacationDuration")) {
                d.vacationDuration = m.vacationDuration;
            }
            return d;
        };

        /**
         * Converts this WorkStoreTimetable to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreTimetable
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreTimetable.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreTimetable;
    })();

    Proto.WorkStoreTimetableList = (function() {

        /**
         * Properties of a WorkStoreTimetableList.
         * @memberof Proto
         * @interface IWorkStoreTimetableList
         * @property {Array.<Proto.IWorkStoreTimetable>|null} [value] WorkStoreTimetableList value
         */

        /**
         * Constructs a new WorkStoreTimetableList.
         * @memberof Proto
         * @classdesc Represents a WorkStoreTimetableList.
         * @implements IWorkStoreTimetableList
         * @constructor
         * @param {Proto.IWorkStoreTimetableList=} [p] Properties to set
         */
        function WorkStoreTimetableList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreTimetableList value.
         * @member {Array.<Proto.IWorkStoreTimetable>} value
         * @memberof Proto.WorkStoreTimetableList
         * @instance
         */
        WorkStoreTimetableList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkStoreTimetableList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreTimetableList
         * @static
         * @param {Proto.IWorkStoreTimetableList=} [properties] Properties to set
         * @returns {Proto.WorkStoreTimetableList} WorkStoreTimetableList instance
         */
        WorkStoreTimetableList.create = function create(properties) {
            return new WorkStoreTimetableList(properties);
        };

        /**
         * Encodes the specified WorkStoreTimetableList message. Does not implicitly {@link Proto.WorkStoreTimetableList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreTimetableList
         * @static
         * @param {Proto.IWorkStoreTimetableList} m WorkStoreTimetableList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreTimetableList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkStoreTimetable.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkStoreTimetableList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreTimetableList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreTimetableList} WorkStoreTimetableList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreTimetableList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreTimetableList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkStoreTimetable.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreTimetableList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreTimetableList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreTimetableList} WorkStoreTimetableList
         */
        WorkStoreTimetableList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreTimetableList)
                return d;
            var m = new $root.Proto.WorkStoreTimetableList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkStoreTimetableList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkStoreTimetableList.value: object expected");
                    m.value[i] = $root.Proto.WorkStoreTimetable.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreTimetableList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreTimetableList
         * @static
         * @param {Proto.WorkStoreTimetableList} m WorkStoreTimetableList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreTimetableList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkStoreTimetable.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkStoreTimetableList to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreTimetableList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreTimetableList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreTimetableList;
    })();

    Proto.WorkStoreTimetableSetting = (function() {

        /**
         * Properties of a WorkStoreTimetableSetting.
         * @memberof Proto
         * @interface IWorkStoreTimetableSetting
         * @property {string|null} [uid] WorkStoreTimetableSetting uid
         * @property {string|null} [storeUID] WorkStoreTimetableSetting storeUID
         * @property {number|null} [dateInterval] WorkStoreTimetableSetting dateInterval
         * @property {number|Long|null} [startDate] WorkStoreTimetableSetting startDate
         * @property {number|Long|null} [endDate] WorkStoreTimetableSetting endDate
         * @property {string|null} [timezoneId] WorkStoreTimetableSetting timezoneId
         * @property {number|null} [timezoneOffset] WorkStoreTimetableSetting timezoneOffset
         * @property {string|null} [recurStartDate] WorkStoreTimetableSetting recurStartDate
         * @property {string|null} [recurStartTime] WorkStoreTimetableSetting recurStartTime
         * @property {string|null} [recurEndDate] WorkStoreTimetableSetting recurEndDate
         * @property {number|null} [recurType] WorkStoreTimetableSetting recurType
         * @property {number|null} [duration] WorkStoreTimetableSetting duration
         * @property {number|null} [recurInterval] WorkStoreTimetableSetting recurInterval
         * @property {string|null} [recurDay] WorkStoreTimetableSetting recurDay
         * @property {string|null} [recurWeekday] WorkStoreTimetableSetting recurWeekday
         * @property {string|null} [recurWeek] WorkStoreTimetableSetting recurWeek
         * @property {boolean|null} [attrNeed] WorkStoreTimetableSetting attrNeed
         * @property {number|null} [priority] WorkStoreTimetableSetting priority
         * @property {number|Long|null} [registerDate] WorkStoreTimetableSetting registerDate
         * @property {number|Long|null} [modifyDate] WorkStoreTimetableSetting modifyDate
         * @property {number|null} [status] WorkStoreTimetableSetting status
         * @property {Array.<Proto.IWorkSkill>|null} [skillList] WorkStoreTimetableSetting skillList
         * @property {Array.<Proto.IWorkAttribute>|null} [attributeList] WorkStoreTimetableSetting attributeList
         */

        /**
         * Constructs a new WorkStoreTimetableSetting.
         * @memberof Proto
         * @classdesc Represents a WorkStoreTimetableSetting.
         * @implements IWorkStoreTimetableSetting
         * @constructor
         * @param {Proto.IWorkStoreTimetableSetting=} [p] Properties to set
         */
        function WorkStoreTimetableSetting(p) {
            this.skillList = [];
            this.attributeList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreTimetableSetting uid.
         * @member {string} uid
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.uid = "";

        /**
         * WorkStoreTimetableSetting storeUID.
         * @member {string} storeUID
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.storeUID = "";

        /**
         * WorkStoreTimetableSetting dateInterval.
         * @member {number} dateInterval
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.dateInterval = 0;

        /**
         * WorkStoreTimetableSetting startDate.
         * @member {number|Long} startDate
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableSetting endDate.
         * @member {number|Long} endDate
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableSetting timezoneId.
         * @member {string} timezoneId
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.timezoneId = "";

        /**
         * WorkStoreTimetableSetting timezoneOffset.
         * @member {number} timezoneOffset
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.timezoneOffset = 0;

        /**
         * WorkStoreTimetableSetting recurStartDate.
         * @member {string} recurStartDate
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.recurStartDate = "";

        /**
         * WorkStoreTimetableSetting recurStartTime.
         * @member {string} recurStartTime
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.recurStartTime = "";

        /**
         * WorkStoreTimetableSetting recurEndDate.
         * @member {string} recurEndDate
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.recurEndDate = "";

        /**
         * WorkStoreTimetableSetting recurType.
         * @member {number} recurType
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.recurType = 0;

        /**
         * WorkStoreTimetableSetting duration.
         * @member {number} duration
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.duration = 0;

        /**
         * WorkStoreTimetableSetting recurInterval.
         * @member {number} recurInterval
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.recurInterval = 0;

        /**
         * WorkStoreTimetableSetting recurDay.
         * @member {string} recurDay
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.recurDay = "";

        /**
         * WorkStoreTimetableSetting recurWeekday.
         * @member {string} recurWeekday
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.recurWeekday = "";

        /**
         * WorkStoreTimetableSetting recurWeek.
         * @member {string} recurWeek
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.recurWeek = "";

        /**
         * WorkStoreTimetableSetting attrNeed.
         * @member {boolean} attrNeed
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.attrNeed = false;

        /**
         * WorkStoreTimetableSetting priority.
         * @member {number} priority
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.priority = 0;

        /**
         * WorkStoreTimetableSetting registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableSetting modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableSetting status.
         * @member {number} status
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.status = 0;

        /**
         * WorkStoreTimetableSetting skillList.
         * @member {Array.<Proto.IWorkSkill>} skillList
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.skillList = $util.emptyArray;

        /**
         * WorkStoreTimetableSetting attributeList.
         * @member {Array.<Proto.IWorkAttribute>} attributeList
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         */
        WorkStoreTimetableSetting.prototype.attributeList = $util.emptyArray;

        /**
         * Creates a new WorkStoreTimetableSetting instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreTimetableSetting
         * @static
         * @param {Proto.IWorkStoreTimetableSetting=} [properties] Properties to set
         * @returns {Proto.WorkStoreTimetableSetting} WorkStoreTimetableSetting instance
         */
        WorkStoreTimetableSetting.create = function create(properties) {
            return new WorkStoreTimetableSetting(properties);
        };

        /**
         * Encodes the specified WorkStoreTimetableSetting message. Does not implicitly {@link Proto.WorkStoreTimetableSetting.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreTimetableSetting
         * @static
         * @param {Proto.IWorkStoreTimetableSetting} m WorkStoreTimetableSetting message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreTimetableSetting.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.storeUID != null && Object.hasOwnProperty.call(m, "storeUID"))
                w.uint32(18).string(m.storeUID);
            if (m.dateInterval != null && Object.hasOwnProperty.call(m, "dateInterval"))
                w.uint32(24).int32(m.dateInterval);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(32).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(40).int64(m.endDate);
            if (m.timezoneId != null && Object.hasOwnProperty.call(m, "timezoneId"))
                w.uint32(50).string(m.timezoneId);
            if (m.timezoneOffset != null && Object.hasOwnProperty.call(m, "timezoneOffset"))
                w.uint32(56).int32(m.timezoneOffset);
            if (m.recurStartDate != null && Object.hasOwnProperty.call(m, "recurStartDate"))
                w.uint32(66).string(m.recurStartDate);
            if (m.recurStartTime != null && Object.hasOwnProperty.call(m, "recurStartTime"))
                w.uint32(74).string(m.recurStartTime);
            if (m.recurEndDate != null && Object.hasOwnProperty.call(m, "recurEndDate"))
                w.uint32(82).string(m.recurEndDate);
            if (m.recurType != null && Object.hasOwnProperty.call(m, "recurType"))
                w.uint32(88).int32(m.recurType);
            if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                w.uint32(96).int32(m.duration);
            if (m.recurInterval != null && Object.hasOwnProperty.call(m, "recurInterval"))
                w.uint32(104).int32(m.recurInterval);
            if (m.recurDay != null && Object.hasOwnProperty.call(m, "recurDay"))
                w.uint32(114).string(m.recurDay);
            if (m.recurWeekday != null && Object.hasOwnProperty.call(m, "recurWeekday"))
                w.uint32(122).string(m.recurWeekday);
            if (m.recurWeek != null && Object.hasOwnProperty.call(m, "recurWeek"))
                w.uint32(130).string(m.recurWeek);
            if (m.attrNeed != null && Object.hasOwnProperty.call(m, "attrNeed"))
                w.uint32(136).bool(m.attrNeed);
            if (m.priority != null && Object.hasOwnProperty.call(m, "priority"))
                w.uint32(144).int32(m.priority);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(152).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(160).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(168).int32(m.status);
            if (m.skillList != null && m.skillList.length) {
                for (var i = 0; i < m.skillList.length; ++i)
                    $root.Proto.WorkSkill.encode(m.skillList[i], w.uint32(178).fork()).ldelim();
            }
            if (m.attributeList != null && m.attributeList.length) {
                for (var i = 0; i < m.attributeList.length; ++i)
                    $root.Proto.WorkAttribute.encode(m.attributeList[i], w.uint32(186).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkStoreTimetableSetting message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreTimetableSetting
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreTimetableSetting} WorkStoreTimetableSetting
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreTimetableSetting.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreTimetableSetting();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.storeUID = r.string();
                    break;
                case 3:
                    m.dateInterval = r.int32();
                    break;
                case 4:
                    m.startDate = r.int64();
                    break;
                case 5:
                    m.endDate = r.int64();
                    break;
                case 6:
                    m.timezoneId = r.string();
                    break;
                case 7:
                    m.timezoneOffset = r.int32();
                    break;
                case 8:
                    m.recurStartDate = r.string();
                    break;
                case 9:
                    m.recurStartTime = r.string();
                    break;
                case 10:
                    m.recurEndDate = r.string();
                    break;
                case 11:
                    m.recurType = r.int32();
                    break;
                case 12:
                    m.duration = r.int32();
                    break;
                case 13:
                    m.recurInterval = r.int32();
                    break;
                case 14:
                    m.recurDay = r.string();
                    break;
                case 15:
                    m.recurWeekday = r.string();
                    break;
                case 16:
                    m.recurWeek = r.string();
                    break;
                case 17:
                    m.attrNeed = r.bool();
                    break;
                case 18:
                    m.priority = r.int32();
                    break;
                case 19:
                    m.registerDate = r.int64();
                    break;
                case 20:
                    m.modifyDate = r.int64();
                    break;
                case 21:
                    m.status = r.int32();
                    break;
                case 22:
                    if (!(m.skillList && m.skillList.length))
                        m.skillList = [];
                    m.skillList.push($root.Proto.WorkSkill.decode(r, r.uint32()));
                    break;
                case 23:
                    if (!(m.attributeList && m.attributeList.length))
                        m.attributeList = [];
                    m.attributeList.push($root.Proto.WorkAttribute.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreTimetableSetting message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreTimetableSetting
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreTimetableSetting} WorkStoreTimetableSetting
         */
        WorkStoreTimetableSetting.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreTimetableSetting)
                return d;
            var m = new $root.Proto.WorkStoreTimetableSetting();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.storeUID != null) {
                m.storeUID = String(d.storeUID);
            }
            if (d.dateInterval != null) {
                m.dateInterval = d.dateInterval | 0;
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.timezoneId != null) {
                m.timezoneId = String(d.timezoneId);
            }
            if (d.timezoneOffset != null) {
                m.timezoneOffset = d.timezoneOffset | 0;
            }
            if (d.recurStartDate != null) {
                m.recurStartDate = String(d.recurStartDate);
            }
            if (d.recurStartTime != null) {
                m.recurStartTime = String(d.recurStartTime);
            }
            if (d.recurEndDate != null) {
                m.recurEndDate = String(d.recurEndDate);
            }
            if (d.recurType != null) {
                m.recurType = d.recurType | 0;
            }
            if (d.duration != null) {
                m.duration = d.duration | 0;
            }
            if (d.recurInterval != null) {
                m.recurInterval = d.recurInterval | 0;
            }
            if (d.recurDay != null) {
                m.recurDay = String(d.recurDay);
            }
            if (d.recurWeekday != null) {
                m.recurWeekday = String(d.recurWeekday);
            }
            if (d.recurWeek != null) {
                m.recurWeek = String(d.recurWeek);
            }
            if (d.attrNeed != null) {
                m.attrNeed = Boolean(d.attrNeed);
            }
            if (d.priority != null) {
                m.priority = d.priority | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.skillList) {
                if (!Array.isArray(d.skillList))
                    throw TypeError(".Proto.WorkStoreTimetableSetting.skillList: array expected");
                m.skillList = [];
                for (var i = 0; i < d.skillList.length; ++i) {
                    if (typeof d.skillList[i] !== "object")
                        throw TypeError(".Proto.WorkStoreTimetableSetting.skillList: object expected");
                    m.skillList[i] = $root.Proto.WorkSkill.fromObject(d.skillList[i]);
                }
            }
            if (d.attributeList) {
                if (!Array.isArray(d.attributeList))
                    throw TypeError(".Proto.WorkStoreTimetableSetting.attributeList: array expected");
                m.attributeList = [];
                for (var i = 0; i < d.attributeList.length; ++i) {
                    if (typeof d.attributeList[i] !== "object")
                        throw TypeError(".Proto.WorkStoreTimetableSetting.attributeList: object expected");
                    m.attributeList[i] = $root.Proto.WorkAttribute.fromObject(d.attributeList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreTimetableSetting message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreTimetableSetting
         * @static
         * @param {Proto.WorkStoreTimetableSetting} m WorkStoreTimetableSetting
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreTimetableSetting.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.skillList = [];
                d.attributeList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.storeUID = "";
                d.dateInterval = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                d.timezoneId = "";
                d.timezoneOffset = 0;
                d.recurStartDate = "";
                d.recurStartTime = "";
                d.recurEndDate = "";
                d.recurType = 0;
                d.duration = 0;
                d.recurInterval = 0;
                d.recurDay = "";
                d.recurWeekday = "";
                d.recurWeek = "";
                d.attrNeed = false;
                d.priority = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.storeUID != null && m.hasOwnProperty("storeUID")) {
                d.storeUID = m.storeUID;
            }
            if (m.dateInterval != null && m.hasOwnProperty("dateInterval")) {
                d.dateInterval = m.dateInterval;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.timezoneId != null && m.hasOwnProperty("timezoneId")) {
                d.timezoneId = m.timezoneId;
            }
            if (m.timezoneOffset != null && m.hasOwnProperty("timezoneOffset")) {
                d.timezoneOffset = m.timezoneOffset;
            }
            if (m.recurStartDate != null && m.hasOwnProperty("recurStartDate")) {
                d.recurStartDate = m.recurStartDate;
            }
            if (m.recurStartTime != null && m.hasOwnProperty("recurStartTime")) {
                d.recurStartTime = m.recurStartTime;
            }
            if (m.recurEndDate != null && m.hasOwnProperty("recurEndDate")) {
                d.recurEndDate = m.recurEndDate;
            }
            if (m.recurType != null && m.hasOwnProperty("recurType")) {
                d.recurType = m.recurType;
            }
            if (m.duration != null && m.hasOwnProperty("duration")) {
                d.duration = m.duration;
            }
            if (m.recurInterval != null && m.hasOwnProperty("recurInterval")) {
                d.recurInterval = m.recurInterval;
            }
            if (m.recurDay != null && m.hasOwnProperty("recurDay")) {
                d.recurDay = m.recurDay;
            }
            if (m.recurWeekday != null && m.hasOwnProperty("recurWeekday")) {
                d.recurWeekday = m.recurWeekday;
            }
            if (m.recurWeek != null && m.hasOwnProperty("recurWeek")) {
                d.recurWeek = m.recurWeek;
            }
            if (m.attrNeed != null && m.hasOwnProperty("attrNeed")) {
                d.attrNeed = m.attrNeed;
            }
            if (m.priority != null && m.hasOwnProperty("priority")) {
                d.priority = m.priority;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.skillList && m.skillList.length) {
                d.skillList = [];
                for (var j = 0; j < m.skillList.length; ++j) {
                    d.skillList[j] = $root.Proto.WorkSkill.toObject(m.skillList[j], o);
                }
            }
            if (m.attributeList && m.attributeList.length) {
                d.attributeList = [];
                for (var j = 0; j < m.attributeList.length; ++j) {
                    d.attributeList[j] = $root.Proto.WorkAttribute.toObject(m.attributeList[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkStoreTimetableSetting to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreTimetableSetting
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreTimetableSetting.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreTimetableSetting;
    })();

    Proto.WorkStoreTimetableSettingList = (function() {

        /**
         * Properties of a WorkStoreTimetableSettingList.
         * @memberof Proto
         * @interface IWorkStoreTimetableSettingList
         * @property {Array.<Proto.IWorkStoreTimetableSetting>|null} [value] WorkStoreTimetableSettingList value
         */

        /**
         * Constructs a new WorkStoreTimetableSettingList.
         * @memberof Proto
         * @classdesc Represents a WorkStoreTimetableSettingList.
         * @implements IWorkStoreTimetableSettingList
         * @constructor
         * @param {Proto.IWorkStoreTimetableSettingList=} [p] Properties to set
         */
        function WorkStoreTimetableSettingList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreTimetableSettingList value.
         * @member {Array.<Proto.IWorkStoreTimetableSetting>} value
         * @memberof Proto.WorkStoreTimetableSettingList
         * @instance
         */
        WorkStoreTimetableSettingList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkStoreTimetableSettingList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreTimetableSettingList
         * @static
         * @param {Proto.IWorkStoreTimetableSettingList=} [properties] Properties to set
         * @returns {Proto.WorkStoreTimetableSettingList} WorkStoreTimetableSettingList instance
         */
        WorkStoreTimetableSettingList.create = function create(properties) {
            return new WorkStoreTimetableSettingList(properties);
        };

        /**
         * Encodes the specified WorkStoreTimetableSettingList message. Does not implicitly {@link Proto.WorkStoreTimetableSettingList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreTimetableSettingList
         * @static
         * @param {Proto.IWorkStoreTimetableSettingList} m WorkStoreTimetableSettingList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreTimetableSettingList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkStoreTimetableSetting.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkStoreTimetableSettingList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreTimetableSettingList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreTimetableSettingList} WorkStoreTimetableSettingList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreTimetableSettingList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreTimetableSettingList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkStoreTimetableSetting.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreTimetableSettingList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreTimetableSettingList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreTimetableSettingList} WorkStoreTimetableSettingList
         */
        WorkStoreTimetableSettingList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreTimetableSettingList)
                return d;
            var m = new $root.Proto.WorkStoreTimetableSettingList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkStoreTimetableSettingList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkStoreTimetableSettingList.value: object expected");
                    m.value[i] = $root.Proto.WorkStoreTimetableSetting.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreTimetableSettingList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreTimetableSettingList
         * @static
         * @param {Proto.WorkStoreTimetableSettingList} m WorkStoreTimetableSettingList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreTimetableSettingList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkStoreTimetableSetting.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkStoreTimetableSettingList to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreTimetableSettingList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreTimetableSettingList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreTimetableSettingList;
    })();

    Proto.WorkStoreShiftSetting = (function() {

        /**
         * Properties of a WorkStoreShiftSetting.
         * @memberof Proto
         * @interface IWorkStoreShiftSetting
         * @property {number|null} [intervalType] WorkStoreShiftSetting intervalType
         * @property {number|null} [startDay] WorkStoreShiftSetting startDay
         * @property {string|null} [startTime] WorkStoreShiftSetting startTime
         * @property {number|null} [duration] WorkStoreShiftSetting duration
         * @property {number|null} [shiftInterval] WorkStoreShiftSetting shiftInterval
         * @property {number|null} [startWeekday] WorkStoreShiftSetting startWeekday
         * @property {number|null} [submitDeadlineType] WorkStoreShiftSetting submitDeadlineType
         * @property {number|null} [submitDeadlineDay] WorkStoreShiftSetting submitDeadlineDay
         * @property {number|null} [submitDeadlineAlert] WorkStoreShiftSetting submitDeadlineAlert
         * @property {number|null} [submitDeadlineRemind] WorkStoreShiftSetting submitDeadlineRemind
         * @property {boolean|null} [workhourDpointEnable] WorkStoreShiftSetting workhourDpointEnable
         * @property {number|null} [workhourDpointType] WorkStoreShiftSetting workhourDpointType
         * @property {number|null} [workhourDpointTime] WorkStoreShiftSetting workhourDpointTime
         * @property {number|null} [closehourDpointType] WorkStoreShiftSetting closehourDpointType
         * @property {number|null} [closehourDpointTime] WorkStoreShiftSetting closehourDpointTime
         * @property {boolean|null} [breakEnterDpointEnable] WorkStoreShiftSetting breakEnterDpointEnable
         * @property {number|null} [breakEnterDpointType] WorkStoreShiftSetting breakEnterDpointType
         * @property {number|null} [breakEnterDpointInt] WorkStoreShiftSetting breakEnterDpointInt
         * @property {number|null} [breakCloseDpointType] WorkStoreShiftSetting breakCloseDpointType
         * @property {number|null} [breakCloseDpointInt] WorkStoreShiftSetting breakCloseDpointInt
         * @property {number|null} [overtimeWorkhour] WorkStoreShiftSetting overtimeWorkhour
         * @property {number|null} [overtimeWorkhourWeekday] WorkStoreShiftSetting overtimeWorkhourWeekday
         * @property {number|null} [overtimeAdditionalPercent] WorkStoreShiftSetting overtimeAdditionalPercent
         * @property {number|null} [overtimeLatePercent] WorkStoreShiftSetting overtimeLatePercent
         * @property {boolean|null} [notificationEmail] WorkStoreShiftSetting notificationEmail
         * @property {number|Long|null} [registerDate] WorkStoreShiftSetting registerDate
         * @property {number|Long|null} [modifyDate] WorkStoreShiftSetting modifyDate
         */

        /**
         * Constructs a new WorkStoreShiftSetting.
         * @memberof Proto
         * @classdesc Represents a WorkStoreShiftSetting.
         * @implements IWorkStoreShiftSetting
         * @constructor
         * @param {Proto.IWorkStoreShiftSetting=} [p] Properties to set
         */
        function WorkStoreShiftSetting(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreShiftSetting intervalType.
         * @member {number} intervalType
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.intervalType = 0;

        /**
         * WorkStoreShiftSetting startDay.
         * @member {number} startDay
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.startDay = 0;

        /**
         * WorkStoreShiftSetting startTime.
         * @member {string} startTime
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.startTime = "";

        /**
         * WorkStoreShiftSetting duration.
         * @member {number} duration
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.duration = 0;

        /**
         * WorkStoreShiftSetting shiftInterval.
         * @member {number} shiftInterval
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.shiftInterval = 0;

        /**
         * WorkStoreShiftSetting startWeekday.
         * @member {number} startWeekday
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.startWeekday = 0;

        /**
         * WorkStoreShiftSetting submitDeadlineType.
         * @member {number} submitDeadlineType
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.submitDeadlineType = 0;

        /**
         * WorkStoreShiftSetting submitDeadlineDay.
         * @member {number} submitDeadlineDay
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.submitDeadlineDay = 0;

        /**
         * WorkStoreShiftSetting submitDeadlineAlert.
         * @member {number} submitDeadlineAlert
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.submitDeadlineAlert = 0;

        /**
         * WorkStoreShiftSetting submitDeadlineRemind.
         * @member {number} submitDeadlineRemind
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.submitDeadlineRemind = 0;

        /**
         * WorkStoreShiftSetting workhourDpointEnable.
         * @member {boolean} workhourDpointEnable
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.workhourDpointEnable = false;

        /**
         * WorkStoreShiftSetting workhourDpointType.
         * @member {number} workhourDpointType
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.workhourDpointType = 0;

        /**
         * WorkStoreShiftSetting workhourDpointTime.
         * @member {number} workhourDpointTime
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.workhourDpointTime = 0;

        /**
         * WorkStoreShiftSetting closehourDpointType.
         * @member {number} closehourDpointType
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.closehourDpointType = 0;

        /**
         * WorkStoreShiftSetting closehourDpointTime.
         * @member {number} closehourDpointTime
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.closehourDpointTime = 0;

        /**
         * WorkStoreShiftSetting breakEnterDpointEnable.
         * @member {boolean} breakEnterDpointEnable
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.breakEnterDpointEnable = false;

        /**
         * WorkStoreShiftSetting breakEnterDpointType.
         * @member {number} breakEnterDpointType
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.breakEnterDpointType = 0;

        /**
         * WorkStoreShiftSetting breakEnterDpointInt.
         * @member {number} breakEnterDpointInt
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.breakEnterDpointInt = 0;

        /**
         * WorkStoreShiftSetting breakCloseDpointType.
         * @member {number} breakCloseDpointType
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.breakCloseDpointType = 0;

        /**
         * WorkStoreShiftSetting breakCloseDpointInt.
         * @member {number} breakCloseDpointInt
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.breakCloseDpointInt = 0;

        /**
         * WorkStoreShiftSetting overtimeWorkhour.
         * @member {number} overtimeWorkhour
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.overtimeWorkhour = 0;

        /**
         * WorkStoreShiftSetting overtimeWorkhourWeekday.
         * @member {number} overtimeWorkhourWeekday
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.overtimeWorkhourWeekday = 0;

        /**
         * WorkStoreShiftSetting overtimeAdditionalPercent.
         * @member {number} overtimeAdditionalPercent
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.overtimeAdditionalPercent = 0;

        /**
         * WorkStoreShiftSetting overtimeLatePercent.
         * @member {number} overtimeLatePercent
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.overtimeLatePercent = 0;

        /**
         * WorkStoreShiftSetting notificationEmail.
         * @member {boolean} notificationEmail
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.notificationEmail = false;

        /**
         * WorkStoreShiftSetting registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreShiftSetting modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         */
        WorkStoreShiftSetting.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new WorkStoreShiftSetting instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreShiftSetting
         * @static
         * @param {Proto.IWorkStoreShiftSetting=} [properties] Properties to set
         * @returns {Proto.WorkStoreShiftSetting} WorkStoreShiftSetting instance
         */
        WorkStoreShiftSetting.create = function create(properties) {
            return new WorkStoreShiftSetting(properties);
        };

        /**
         * Encodes the specified WorkStoreShiftSetting message. Does not implicitly {@link Proto.WorkStoreShiftSetting.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreShiftSetting
         * @static
         * @param {Proto.IWorkStoreShiftSetting} m WorkStoreShiftSetting message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreShiftSetting.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.intervalType != null && Object.hasOwnProperty.call(m, "intervalType"))
                w.uint32(8).int32(m.intervalType);
            if (m.startDay != null && Object.hasOwnProperty.call(m, "startDay"))
                w.uint32(16).int32(m.startDay);
            if (m.startTime != null && Object.hasOwnProperty.call(m, "startTime"))
                w.uint32(26).string(m.startTime);
            if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                w.uint32(32).int32(m.duration);
            if (m.shiftInterval != null && Object.hasOwnProperty.call(m, "shiftInterval"))
                w.uint32(40).int32(m.shiftInterval);
            if (m.startWeekday != null && Object.hasOwnProperty.call(m, "startWeekday"))
                w.uint32(48).int32(m.startWeekday);
            if (m.submitDeadlineType != null && Object.hasOwnProperty.call(m, "submitDeadlineType"))
                w.uint32(56).int32(m.submitDeadlineType);
            if (m.submitDeadlineDay != null && Object.hasOwnProperty.call(m, "submitDeadlineDay"))
                w.uint32(64).int32(m.submitDeadlineDay);
            if (m.submitDeadlineAlert != null && Object.hasOwnProperty.call(m, "submitDeadlineAlert"))
                w.uint32(72).int32(m.submitDeadlineAlert);
            if (m.submitDeadlineRemind != null && Object.hasOwnProperty.call(m, "submitDeadlineRemind"))
                w.uint32(80).int32(m.submitDeadlineRemind);
            if (m.workhourDpointEnable != null && Object.hasOwnProperty.call(m, "workhourDpointEnable"))
                w.uint32(88).bool(m.workhourDpointEnable);
            if (m.workhourDpointType != null && Object.hasOwnProperty.call(m, "workhourDpointType"))
                w.uint32(96).int32(m.workhourDpointType);
            if (m.workhourDpointTime != null && Object.hasOwnProperty.call(m, "workhourDpointTime"))
                w.uint32(104).int32(m.workhourDpointTime);
            if (m.closehourDpointType != null && Object.hasOwnProperty.call(m, "closehourDpointType"))
                w.uint32(112).int32(m.closehourDpointType);
            if (m.closehourDpointTime != null && Object.hasOwnProperty.call(m, "closehourDpointTime"))
                w.uint32(120).int32(m.closehourDpointTime);
            if (m.breakEnterDpointEnable != null && Object.hasOwnProperty.call(m, "breakEnterDpointEnable"))
                w.uint32(128).bool(m.breakEnterDpointEnable);
            if (m.breakEnterDpointType != null && Object.hasOwnProperty.call(m, "breakEnterDpointType"))
                w.uint32(136).int32(m.breakEnterDpointType);
            if (m.breakEnterDpointInt != null && Object.hasOwnProperty.call(m, "breakEnterDpointInt"))
                w.uint32(144).int32(m.breakEnterDpointInt);
            if (m.breakCloseDpointType != null && Object.hasOwnProperty.call(m, "breakCloseDpointType"))
                w.uint32(152).int32(m.breakCloseDpointType);
            if (m.breakCloseDpointInt != null && Object.hasOwnProperty.call(m, "breakCloseDpointInt"))
                w.uint32(160).int32(m.breakCloseDpointInt);
            if (m.overtimeWorkhour != null && Object.hasOwnProperty.call(m, "overtimeWorkhour"))
                w.uint32(168).int32(m.overtimeWorkhour);
            if (m.overtimeWorkhourWeekday != null && Object.hasOwnProperty.call(m, "overtimeWorkhourWeekday"))
                w.uint32(176).int32(m.overtimeWorkhourWeekday);
            if (m.overtimeAdditionalPercent != null && Object.hasOwnProperty.call(m, "overtimeAdditionalPercent"))
                w.uint32(184).int32(m.overtimeAdditionalPercent);
            if (m.overtimeLatePercent != null && Object.hasOwnProperty.call(m, "overtimeLatePercent"))
                w.uint32(192).int32(m.overtimeLatePercent);
            if (m.notificationEmail != null && Object.hasOwnProperty.call(m, "notificationEmail"))
                w.uint32(200).bool(m.notificationEmail);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(208).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(216).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a WorkStoreShiftSetting message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreShiftSetting
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreShiftSetting} WorkStoreShiftSetting
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreShiftSetting.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreShiftSetting();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.intervalType = r.int32();
                    break;
                case 2:
                    m.startDay = r.int32();
                    break;
                case 3:
                    m.startTime = r.string();
                    break;
                case 4:
                    m.duration = r.int32();
                    break;
                case 5:
                    m.shiftInterval = r.int32();
                    break;
                case 6:
                    m.startWeekday = r.int32();
                    break;
                case 7:
                    m.submitDeadlineType = r.int32();
                    break;
                case 8:
                    m.submitDeadlineDay = r.int32();
                    break;
                case 9:
                    m.submitDeadlineAlert = r.int32();
                    break;
                case 10:
                    m.submitDeadlineRemind = r.int32();
                    break;
                case 11:
                    m.workhourDpointEnable = r.bool();
                    break;
                case 12:
                    m.workhourDpointType = r.int32();
                    break;
                case 13:
                    m.workhourDpointTime = r.int32();
                    break;
                case 14:
                    m.closehourDpointType = r.int32();
                    break;
                case 15:
                    m.closehourDpointTime = r.int32();
                    break;
                case 16:
                    m.breakEnterDpointEnable = r.bool();
                    break;
                case 17:
                    m.breakEnterDpointType = r.int32();
                    break;
                case 18:
                    m.breakEnterDpointInt = r.int32();
                    break;
                case 19:
                    m.breakCloseDpointType = r.int32();
                    break;
                case 20:
                    m.breakCloseDpointInt = r.int32();
                    break;
                case 21:
                    m.overtimeWorkhour = r.int32();
                    break;
                case 22:
                    m.overtimeWorkhourWeekday = r.int32();
                    break;
                case 23:
                    m.overtimeAdditionalPercent = r.int32();
                    break;
                case 24:
                    m.overtimeLatePercent = r.int32();
                    break;
                case 25:
                    m.notificationEmail = r.bool();
                    break;
                case 26:
                    m.registerDate = r.int64();
                    break;
                case 27:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreShiftSetting message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreShiftSetting
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreShiftSetting} WorkStoreShiftSetting
         */
        WorkStoreShiftSetting.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreShiftSetting)
                return d;
            var m = new $root.Proto.WorkStoreShiftSetting();
            if (d.intervalType != null) {
                m.intervalType = d.intervalType | 0;
            }
            if (d.startDay != null) {
                m.startDay = d.startDay | 0;
            }
            if (d.startTime != null) {
                m.startTime = String(d.startTime);
            }
            if (d.duration != null) {
                m.duration = d.duration | 0;
            }
            if (d.shiftInterval != null) {
                m.shiftInterval = d.shiftInterval | 0;
            }
            if (d.startWeekday != null) {
                m.startWeekday = d.startWeekday | 0;
            }
            if (d.submitDeadlineType != null) {
                m.submitDeadlineType = d.submitDeadlineType | 0;
            }
            if (d.submitDeadlineDay != null) {
                m.submitDeadlineDay = d.submitDeadlineDay | 0;
            }
            if (d.submitDeadlineAlert != null) {
                m.submitDeadlineAlert = d.submitDeadlineAlert | 0;
            }
            if (d.submitDeadlineRemind != null) {
                m.submitDeadlineRemind = d.submitDeadlineRemind | 0;
            }
            if (d.workhourDpointEnable != null) {
                m.workhourDpointEnable = Boolean(d.workhourDpointEnable);
            }
            if (d.workhourDpointType != null) {
                m.workhourDpointType = d.workhourDpointType | 0;
            }
            if (d.workhourDpointTime != null) {
                m.workhourDpointTime = d.workhourDpointTime | 0;
            }
            if (d.closehourDpointType != null) {
                m.closehourDpointType = d.closehourDpointType | 0;
            }
            if (d.closehourDpointTime != null) {
                m.closehourDpointTime = d.closehourDpointTime | 0;
            }
            if (d.breakEnterDpointEnable != null) {
                m.breakEnterDpointEnable = Boolean(d.breakEnterDpointEnable);
            }
            if (d.breakEnterDpointType != null) {
                m.breakEnterDpointType = d.breakEnterDpointType | 0;
            }
            if (d.breakEnterDpointInt != null) {
                m.breakEnterDpointInt = d.breakEnterDpointInt | 0;
            }
            if (d.breakCloseDpointType != null) {
                m.breakCloseDpointType = d.breakCloseDpointType | 0;
            }
            if (d.breakCloseDpointInt != null) {
                m.breakCloseDpointInt = d.breakCloseDpointInt | 0;
            }
            if (d.overtimeWorkhour != null) {
                m.overtimeWorkhour = d.overtimeWorkhour | 0;
            }
            if (d.overtimeWorkhourWeekday != null) {
                m.overtimeWorkhourWeekday = d.overtimeWorkhourWeekday | 0;
            }
            if (d.overtimeAdditionalPercent != null) {
                m.overtimeAdditionalPercent = d.overtimeAdditionalPercent | 0;
            }
            if (d.overtimeLatePercent != null) {
                m.overtimeLatePercent = d.overtimeLatePercent | 0;
            }
            if (d.notificationEmail != null) {
                m.notificationEmail = Boolean(d.notificationEmail);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreShiftSetting message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreShiftSetting
         * @static
         * @param {Proto.WorkStoreShiftSetting} m WorkStoreShiftSetting
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreShiftSetting.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.intervalType = 0;
                d.startDay = 0;
                d.startTime = "";
                d.duration = 0;
                d.shiftInterval = 0;
                d.startWeekday = 0;
                d.submitDeadlineType = 0;
                d.submitDeadlineDay = 0;
                d.submitDeadlineAlert = 0;
                d.submitDeadlineRemind = 0;
                d.workhourDpointEnable = false;
                d.workhourDpointType = 0;
                d.workhourDpointTime = 0;
                d.closehourDpointType = 0;
                d.closehourDpointTime = 0;
                d.breakEnterDpointEnable = false;
                d.breakEnterDpointType = 0;
                d.breakEnterDpointInt = 0;
                d.breakCloseDpointType = 0;
                d.breakCloseDpointInt = 0;
                d.overtimeWorkhour = 0;
                d.overtimeWorkhourWeekday = 0;
                d.overtimeAdditionalPercent = 0;
                d.overtimeLatePercent = 0;
                d.notificationEmail = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.intervalType != null && m.hasOwnProperty("intervalType")) {
                d.intervalType = m.intervalType;
            }
            if (m.startDay != null && m.hasOwnProperty("startDay")) {
                d.startDay = m.startDay;
            }
            if (m.startTime != null && m.hasOwnProperty("startTime")) {
                d.startTime = m.startTime;
            }
            if (m.duration != null && m.hasOwnProperty("duration")) {
                d.duration = m.duration;
            }
            if (m.shiftInterval != null && m.hasOwnProperty("shiftInterval")) {
                d.shiftInterval = m.shiftInterval;
            }
            if (m.startWeekday != null && m.hasOwnProperty("startWeekday")) {
                d.startWeekday = m.startWeekday;
            }
            if (m.submitDeadlineType != null && m.hasOwnProperty("submitDeadlineType")) {
                d.submitDeadlineType = m.submitDeadlineType;
            }
            if (m.submitDeadlineDay != null && m.hasOwnProperty("submitDeadlineDay")) {
                d.submitDeadlineDay = m.submitDeadlineDay;
            }
            if (m.submitDeadlineAlert != null && m.hasOwnProperty("submitDeadlineAlert")) {
                d.submitDeadlineAlert = m.submitDeadlineAlert;
            }
            if (m.submitDeadlineRemind != null && m.hasOwnProperty("submitDeadlineRemind")) {
                d.submitDeadlineRemind = m.submitDeadlineRemind;
            }
            if (m.workhourDpointEnable != null && m.hasOwnProperty("workhourDpointEnable")) {
                d.workhourDpointEnable = m.workhourDpointEnable;
            }
            if (m.workhourDpointType != null && m.hasOwnProperty("workhourDpointType")) {
                d.workhourDpointType = m.workhourDpointType;
            }
            if (m.workhourDpointTime != null && m.hasOwnProperty("workhourDpointTime")) {
                d.workhourDpointTime = m.workhourDpointTime;
            }
            if (m.closehourDpointType != null && m.hasOwnProperty("closehourDpointType")) {
                d.closehourDpointType = m.closehourDpointType;
            }
            if (m.closehourDpointTime != null && m.hasOwnProperty("closehourDpointTime")) {
                d.closehourDpointTime = m.closehourDpointTime;
            }
            if (m.breakEnterDpointEnable != null && m.hasOwnProperty("breakEnterDpointEnable")) {
                d.breakEnterDpointEnable = m.breakEnterDpointEnable;
            }
            if (m.breakEnterDpointType != null && m.hasOwnProperty("breakEnterDpointType")) {
                d.breakEnterDpointType = m.breakEnterDpointType;
            }
            if (m.breakEnterDpointInt != null && m.hasOwnProperty("breakEnterDpointInt")) {
                d.breakEnterDpointInt = m.breakEnterDpointInt;
            }
            if (m.breakCloseDpointType != null && m.hasOwnProperty("breakCloseDpointType")) {
                d.breakCloseDpointType = m.breakCloseDpointType;
            }
            if (m.breakCloseDpointInt != null && m.hasOwnProperty("breakCloseDpointInt")) {
                d.breakCloseDpointInt = m.breakCloseDpointInt;
            }
            if (m.overtimeWorkhour != null && m.hasOwnProperty("overtimeWorkhour")) {
                d.overtimeWorkhour = m.overtimeWorkhour;
            }
            if (m.overtimeWorkhourWeekday != null && m.hasOwnProperty("overtimeWorkhourWeekday")) {
                d.overtimeWorkhourWeekday = m.overtimeWorkhourWeekday;
            }
            if (m.overtimeAdditionalPercent != null && m.hasOwnProperty("overtimeAdditionalPercent")) {
                d.overtimeAdditionalPercent = m.overtimeAdditionalPercent;
            }
            if (m.overtimeLatePercent != null && m.hasOwnProperty("overtimeLatePercent")) {
                d.overtimeLatePercent = m.overtimeLatePercent;
            }
            if (m.notificationEmail != null && m.hasOwnProperty("notificationEmail")) {
                d.notificationEmail = m.notificationEmail;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this WorkStoreShiftSetting to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreShiftSetting
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreShiftSetting.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreShiftSetting;
    })();

    Proto.WorkStoreTimetableRequestMap = (function() {

        /**
         * Properties of a WorkStoreTimetableRequestMap.
         * @memberof Proto
         * @interface IWorkStoreTimetableRequestMap
         * @property {Proto.IWorkStoreTimetable|null} [timetable] WorkStoreTimetableRequestMap timetable
         * @property {Proto.IWorkStoreTimetableRequest|null} [request] WorkStoreTimetableRequestMap request
         * @property {Proto.IUser|null} [user] WorkStoreTimetableRequestMap user
         * @property {number|Long|null} [registerDate] WorkStoreTimetableRequestMap registerDate
         * @property {number|Long|null} [modifyDate] WorkStoreTimetableRequestMap modifyDate
         * @property {string|null} [chatGroupUID] WorkStoreTimetableRequestMap chatGroupUID
         * @property {string|null} [chatId] WorkStoreTimetableRequestMap chatId
         */

        /**
         * Constructs a new WorkStoreTimetableRequestMap.
         * @memberof Proto
         * @classdesc Represents a WorkStoreTimetableRequestMap.
         * @implements IWorkStoreTimetableRequestMap
         * @constructor
         * @param {Proto.IWorkStoreTimetableRequestMap=} [p] Properties to set
         */
        function WorkStoreTimetableRequestMap(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreTimetableRequestMap timetable.
         * @member {Proto.IWorkStoreTimetable|null|undefined} timetable
         * @memberof Proto.WorkStoreTimetableRequestMap
         * @instance
         */
        WorkStoreTimetableRequestMap.prototype.timetable = null;

        /**
         * WorkStoreTimetableRequestMap request.
         * @member {Proto.IWorkStoreTimetableRequest|null|undefined} request
         * @memberof Proto.WorkStoreTimetableRequestMap
         * @instance
         */
        WorkStoreTimetableRequestMap.prototype.request = null;

        /**
         * WorkStoreTimetableRequestMap user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.WorkStoreTimetableRequestMap
         * @instance
         */
        WorkStoreTimetableRequestMap.prototype.user = null;

        /**
         * WorkStoreTimetableRequestMap registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStoreTimetableRequestMap
         * @instance
         */
        WorkStoreTimetableRequestMap.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableRequestMap modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStoreTimetableRequestMap
         * @instance
         */
        WorkStoreTimetableRequestMap.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableRequestMap chatGroupUID.
         * @member {string} chatGroupUID
         * @memberof Proto.WorkStoreTimetableRequestMap
         * @instance
         */
        WorkStoreTimetableRequestMap.prototype.chatGroupUID = "";

        /**
         * WorkStoreTimetableRequestMap chatId.
         * @member {string} chatId
         * @memberof Proto.WorkStoreTimetableRequestMap
         * @instance
         */
        WorkStoreTimetableRequestMap.prototype.chatId = "";

        /**
         * Creates a new WorkStoreTimetableRequestMap instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreTimetableRequestMap
         * @static
         * @param {Proto.IWorkStoreTimetableRequestMap=} [properties] Properties to set
         * @returns {Proto.WorkStoreTimetableRequestMap} WorkStoreTimetableRequestMap instance
         */
        WorkStoreTimetableRequestMap.create = function create(properties) {
            return new WorkStoreTimetableRequestMap(properties);
        };

        /**
         * Encodes the specified WorkStoreTimetableRequestMap message. Does not implicitly {@link Proto.WorkStoreTimetableRequestMap.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreTimetableRequestMap
         * @static
         * @param {Proto.IWorkStoreTimetableRequestMap} m WorkStoreTimetableRequestMap message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreTimetableRequestMap.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.timetable != null && Object.hasOwnProperty.call(m, "timetable"))
                $root.Proto.WorkStoreTimetable.encode(m.timetable, w.uint32(10).fork()).ldelim();
            if (m.request != null && Object.hasOwnProperty.call(m, "request"))
                $root.Proto.WorkStoreTimetableRequest.encode(m.request, w.uint32(18).fork()).ldelim();
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(26).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            if (m.chatGroupUID != null && Object.hasOwnProperty.call(m, "chatGroupUID"))
                w.uint32(50).string(m.chatGroupUID);
            if (m.chatId != null && Object.hasOwnProperty.call(m, "chatId"))
                w.uint32(58).string(m.chatId);
            return w;
        };

        /**
         * Decodes a WorkStoreTimetableRequestMap message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreTimetableRequestMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreTimetableRequestMap} WorkStoreTimetableRequestMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreTimetableRequestMap.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreTimetableRequestMap();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.timetable = $root.Proto.WorkStoreTimetable.decode(r, r.uint32());
                    break;
                case 2:
                    m.request = $root.Proto.WorkStoreTimetableRequest.decode(r, r.uint32());
                    break;
                case 3:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                case 6:
                    m.chatGroupUID = r.string();
                    break;
                case 7:
                    m.chatId = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreTimetableRequestMap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreTimetableRequestMap
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreTimetableRequestMap} WorkStoreTimetableRequestMap
         */
        WorkStoreTimetableRequestMap.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreTimetableRequestMap)
                return d;
            var m = new $root.Proto.WorkStoreTimetableRequestMap();
            if (d.timetable != null) {
                if (typeof d.timetable !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableRequestMap.timetable: object expected");
                m.timetable = $root.Proto.WorkStoreTimetable.fromObject(d.timetable);
            }
            if (d.request != null) {
                if (typeof d.request !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableRequestMap.request: object expected");
                m.request = $root.Proto.WorkStoreTimetableRequest.fromObject(d.request);
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableRequestMap.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.chatGroupUID != null) {
                m.chatGroupUID = String(d.chatGroupUID);
            }
            if (d.chatId != null) {
                m.chatId = String(d.chatId);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreTimetableRequestMap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreTimetableRequestMap
         * @static
         * @param {Proto.WorkStoreTimetableRequestMap} m WorkStoreTimetableRequestMap
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreTimetableRequestMap.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.timetable = null;
                d.request = null;
                d.user = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.chatGroupUID = "";
                d.chatId = "";
            }
            if (m.timetable != null && m.hasOwnProperty("timetable")) {
                d.timetable = $root.Proto.WorkStoreTimetable.toObject(m.timetable, o);
            }
            if (m.request != null && m.hasOwnProperty("request")) {
                d.request = $root.Proto.WorkStoreTimetableRequest.toObject(m.request, o);
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.chatGroupUID != null && m.hasOwnProperty("chatGroupUID")) {
                d.chatGroupUID = m.chatGroupUID;
            }
            if (m.chatId != null && m.hasOwnProperty("chatId")) {
                d.chatId = m.chatId;
            }
            return d;
        };

        /**
         * Converts this WorkStoreTimetableRequestMap to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreTimetableRequestMap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreTimetableRequestMap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreTimetableRequestMap;
    })();

    Proto.WorkStoreTimetableRequestMapList = (function() {

        /**
         * Properties of a WorkStoreTimetableRequestMapList.
         * @memberof Proto
         * @interface IWorkStoreTimetableRequestMapList
         * @property {Array.<Proto.IWorkStoreTimetableRequestMap>|null} [value] WorkStoreTimetableRequestMapList value
         */

        /**
         * Constructs a new WorkStoreTimetableRequestMapList.
         * @memberof Proto
         * @classdesc Represents a WorkStoreTimetableRequestMapList.
         * @implements IWorkStoreTimetableRequestMapList
         * @constructor
         * @param {Proto.IWorkStoreTimetableRequestMapList=} [p] Properties to set
         */
        function WorkStoreTimetableRequestMapList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreTimetableRequestMapList value.
         * @member {Array.<Proto.IWorkStoreTimetableRequestMap>} value
         * @memberof Proto.WorkStoreTimetableRequestMapList
         * @instance
         */
        WorkStoreTimetableRequestMapList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkStoreTimetableRequestMapList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreTimetableRequestMapList
         * @static
         * @param {Proto.IWorkStoreTimetableRequestMapList=} [properties] Properties to set
         * @returns {Proto.WorkStoreTimetableRequestMapList} WorkStoreTimetableRequestMapList instance
         */
        WorkStoreTimetableRequestMapList.create = function create(properties) {
            return new WorkStoreTimetableRequestMapList(properties);
        };

        /**
         * Encodes the specified WorkStoreTimetableRequestMapList message. Does not implicitly {@link Proto.WorkStoreTimetableRequestMapList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreTimetableRequestMapList
         * @static
         * @param {Proto.IWorkStoreTimetableRequestMapList} m WorkStoreTimetableRequestMapList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreTimetableRequestMapList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkStoreTimetableRequestMap.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkStoreTimetableRequestMapList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreTimetableRequestMapList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreTimetableRequestMapList} WorkStoreTimetableRequestMapList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreTimetableRequestMapList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreTimetableRequestMapList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkStoreTimetableRequestMap.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreTimetableRequestMapList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreTimetableRequestMapList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreTimetableRequestMapList} WorkStoreTimetableRequestMapList
         */
        WorkStoreTimetableRequestMapList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreTimetableRequestMapList)
                return d;
            var m = new $root.Proto.WorkStoreTimetableRequestMapList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkStoreTimetableRequestMapList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkStoreTimetableRequestMapList.value: object expected");
                    m.value[i] = $root.Proto.WorkStoreTimetableRequestMap.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreTimetableRequestMapList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreTimetableRequestMapList
         * @static
         * @param {Proto.WorkStoreTimetableRequestMapList} m WorkStoreTimetableRequestMapList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreTimetableRequestMapList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkStoreTimetableRequestMap.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkStoreTimetableRequestMapList to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreTimetableRequestMapList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreTimetableRequestMapList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreTimetableRequestMapList;
    })();

    Proto.WorkStoreTimetableClock = (function() {

        /**
         * Properties of a WorkStoreTimetableClock.
         * @memberof Proto
         * @interface IWorkStoreTimetableClock
         * @property {string|null} [uid] WorkStoreTimetableClock uid
         * @property {string|null} [storeUID] WorkStoreTimetableClock storeUID
         * @property {number|Long|null} [startDate] WorkStoreTimetableClock startDate
         * @property {number|Long|null} [endDate] WorkStoreTimetableClock endDate
         * @property {Proto.INullDouble|null} [startLatitude] WorkStoreTimetableClock startLatitude
         * @property {Proto.INullDouble|null} [startLongitude] WorkStoreTimetableClock startLongitude
         * @property {Proto.INullDouble|null} [endLatitude] WorkStoreTimetableClock endLatitude
         * @property {Proto.INullDouble|null} [endLongitude] WorkStoreTimetableClock endLongitude
         * @property {number|Long|null} [restStartDate] WorkStoreTimetableClock restStartDate
         * @property {number|Long|null} [restEndDate] WorkStoreTimetableClock restEndDate
         * @property {string|null} [memo] WorkStoreTimetableClock memo
         * @property {number|Long|null} [changeStartDate] WorkStoreTimetableClock changeStartDate
         * @property {number|Long|null} [changeEndDate] WorkStoreTimetableClock changeEndDate
         * @property {number|Long|null} [changeRequestDate] WorkStoreTimetableClock changeRequestDate
         * @property {boolean|null} [fixed] WorkStoreTimetableClock fixed
         * @property {number|Long|null} [registerDate] WorkStoreTimetableClock registerDate
         * @property {number|Long|null} [modifyDate] WorkStoreTimetableClock modifyDate
         * @property {number|null} [status] WorkStoreTimetableClock status
         * @property {number|null} [restDuration] WorkStoreTimetableClock restDuration
         * @property {number|null} [payHour] WorkStoreTimetableClock payHour
         * @property {string|null} [currency] WorkStoreTimetableClock currency
         * @property {number|null} [transportationFee] WorkStoreTimetableClock transportationFee
         * @property {number|null} [payTotal] WorkStoreTimetableClock payTotal
         * @property {number|null} [type] WorkStoreTimetableClock type
         * @property {Proto.IWorkStore|null} [store] WorkStoreTimetableClock store
         * @property {number|null} [lateDuration] WorkStoreTimetableClock lateDuration
         * @property {boolean|null} [changeRequested] WorkStoreTimetableClock changeRequested
         * @property {string|null} [holidayInfo] WorkStoreTimetableClock holidayInfo
         * @property {Proto.IWorkStoreTimetable|null} [timetable] WorkStoreTimetableClock timetable
         */

        /**
         * Constructs a new WorkStoreTimetableClock.
         * @memberof Proto
         * @classdesc Represents a WorkStoreTimetableClock.
         * @implements IWorkStoreTimetableClock
         * @constructor
         * @param {Proto.IWorkStoreTimetableClock=} [p] Properties to set
         */
        function WorkStoreTimetableClock(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreTimetableClock uid.
         * @member {string} uid
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.uid = "";

        /**
         * WorkStoreTimetableClock storeUID.
         * @member {string} storeUID
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.storeUID = "";

        /**
         * WorkStoreTimetableClock startDate.
         * @member {number|Long} startDate
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableClock endDate.
         * @member {number|Long} endDate
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableClock startLatitude.
         * @member {Proto.INullDouble|null|undefined} startLatitude
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.startLatitude = null;

        /**
         * WorkStoreTimetableClock startLongitude.
         * @member {Proto.INullDouble|null|undefined} startLongitude
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.startLongitude = null;

        /**
         * WorkStoreTimetableClock endLatitude.
         * @member {Proto.INullDouble|null|undefined} endLatitude
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.endLatitude = null;

        /**
         * WorkStoreTimetableClock endLongitude.
         * @member {Proto.INullDouble|null|undefined} endLongitude
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.endLongitude = null;

        /**
         * WorkStoreTimetableClock restStartDate.
         * @member {number|Long} restStartDate
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.restStartDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableClock restEndDate.
         * @member {number|Long} restEndDate
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.restEndDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableClock memo.
         * @member {string} memo
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.memo = "";

        /**
         * WorkStoreTimetableClock changeStartDate.
         * @member {number|Long} changeStartDate
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.changeStartDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableClock changeEndDate.
         * @member {number|Long} changeEndDate
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.changeEndDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableClock changeRequestDate.
         * @member {number|Long} changeRequestDate
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.changeRequestDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableClock fixed.
         * @member {boolean} fixed
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.fixed = false;

        /**
         * WorkStoreTimetableClock registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableClock modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableClock status.
         * @member {number} status
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.status = 0;

        /**
         * WorkStoreTimetableClock restDuration.
         * @member {number} restDuration
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.restDuration = 0;

        /**
         * WorkStoreTimetableClock payHour.
         * @member {number} payHour
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.payHour = 0;

        /**
         * WorkStoreTimetableClock currency.
         * @member {string} currency
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.currency = "";

        /**
         * WorkStoreTimetableClock transportationFee.
         * @member {number} transportationFee
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.transportationFee = 0;

        /**
         * WorkStoreTimetableClock payTotal.
         * @member {number} payTotal
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.payTotal = 0;

        /**
         * WorkStoreTimetableClock type.
         * @member {number} type
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.type = 0;

        /**
         * WorkStoreTimetableClock store.
         * @member {Proto.IWorkStore|null|undefined} store
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.store = null;

        /**
         * WorkStoreTimetableClock lateDuration.
         * @member {number} lateDuration
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.lateDuration = 0;

        /**
         * WorkStoreTimetableClock changeRequested.
         * @member {boolean} changeRequested
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.changeRequested = false;

        /**
         * WorkStoreTimetableClock holidayInfo.
         * @member {string} holidayInfo
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.holidayInfo = "";

        /**
         * WorkStoreTimetableClock timetable.
         * @member {Proto.IWorkStoreTimetable|null|undefined} timetable
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         */
        WorkStoreTimetableClock.prototype.timetable = null;

        /**
         * Creates a new WorkStoreTimetableClock instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreTimetableClock
         * @static
         * @param {Proto.IWorkStoreTimetableClock=} [properties] Properties to set
         * @returns {Proto.WorkStoreTimetableClock} WorkStoreTimetableClock instance
         */
        WorkStoreTimetableClock.create = function create(properties) {
            return new WorkStoreTimetableClock(properties);
        };

        /**
         * Encodes the specified WorkStoreTimetableClock message. Does not implicitly {@link Proto.WorkStoreTimetableClock.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreTimetableClock
         * @static
         * @param {Proto.IWorkStoreTimetableClock} m WorkStoreTimetableClock message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreTimetableClock.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.storeUID != null && Object.hasOwnProperty.call(m, "storeUID"))
                w.uint32(18).string(m.storeUID);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(24).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(32).int64(m.endDate);
            if (m.startLatitude != null && Object.hasOwnProperty.call(m, "startLatitude"))
                $root.Proto.NullDouble.encode(m.startLatitude, w.uint32(42).fork()).ldelim();
            if (m.startLongitude != null && Object.hasOwnProperty.call(m, "startLongitude"))
                $root.Proto.NullDouble.encode(m.startLongitude, w.uint32(50).fork()).ldelim();
            if (m.endLatitude != null && Object.hasOwnProperty.call(m, "endLatitude"))
                $root.Proto.NullDouble.encode(m.endLatitude, w.uint32(58).fork()).ldelim();
            if (m.endLongitude != null && Object.hasOwnProperty.call(m, "endLongitude"))
                $root.Proto.NullDouble.encode(m.endLongitude, w.uint32(66).fork()).ldelim();
            if (m.restStartDate != null && Object.hasOwnProperty.call(m, "restStartDate"))
                w.uint32(72).int64(m.restStartDate);
            if (m.restEndDate != null && Object.hasOwnProperty.call(m, "restEndDate"))
                w.uint32(80).int64(m.restEndDate);
            if (m.memo != null && Object.hasOwnProperty.call(m, "memo"))
                w.uint32(90).string(m.memo);
            if (m.changeStartDate != null && Object.hasOwnProperty.call(m, "changeStartDate"))
                w.uint32(96).int64(m.changeStartDate);
            if (m.changeEndDate != null && Object.hasOwnProperty.call(m, "changeEndDate"))
                w.uint32(104).int64(m.changeEndDate);
            if (m.changeRequestDate != null && Object.hasOwnProperty.call(m, "changeRequestDate"))
                w.uint32(112).int64(m.changeRequestDate);
            if (m.fixed != null && Object.hasOwnProperty.call(m, "fixed"))
                w.uint32(120).bool(m.fixed);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(128).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(136).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(144).int32(m.status);
            if (m.restDuration != null && Object.hasOwnProperty.call(m, "restDuration"))
                w.uint32(152).int32(m.restDuration);
            if (m.payHour != null && Object.hasOwnProperty.call(m, "payHour"))
                w.uint32(161).double(m.payHour);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(170).string(m.currency);
            if (m.transportationFee != null && Object.hasOwnProperty.call(m, "transportationFee"))
                w.uint32(177).double(m.transportationFee);
            if (m.payTotal != null && Object.hasOwnProperty.call(m, "payTotal"))
                w.uint32(185).double(m.payTotal);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(192).int32(m.type);
            if (m.store != null && Object.hasOwnProperty.call(m, "store"))
                $root.Proto.WorkStore.encode(m.store, w.uint32(202).fork()).ldelim();
            if (m.lateDuration != null && Object.hasOwnProperty.call(m, "lateDuration"))
                w.uint32(208).int32(m.lateDuration);
            if (m.changeRequested != null && Object.hasOwnProperty.call(m, "changeRequested"))
                w.uint32(216).bool(m.changeRequested);
            if (m.holidayInfo != null && Object.hasOwnProperty.call(m, "holidayInfo"))
                w.uint32(226).string(m.holidayInfo);
            if (m.timetable != null && Object.hasOwnProperty.call(m, "timetable"))
                $root.Proto.WorkStoreTimetable.encode(m.timetable, w.uint32(234).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a WorkStoreTimetableClock message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreTimetableClock
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreTimetableClock} WorkStoreTimetableClock
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreTimetableClock.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreTimetableClock();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.storeUID = r.string();
                    break;
                case 3:
                    m.startDate = r.int64();
                    break;
                case 4:
                    m.endDate = r.int64();
                    break;
                case 5:
                    m.startLatitude = $root.Proto.NullDouble.decode(r, r.uint32());
                    break;
                case 6:
                    m.startLongitude = $root.Proto.NullDouble.decode(r, r.uint32());
                    break;
                case 7:
                    m.endLatitude = $root.Proto.NullDouble.decode(r, r.uint32());
                    break;
                case 8:
                    m.endLongitude = $root.Proto.NullDouble.decode(r, r.uint32());
                    break;
                case 9:
                    m.restStartDate = r.int64();
                    break;
                case 10:
                    m.restEndDate = r.int64();
                    break;
                case 11:
                    m.memo = r.string();
                    break;
                case 12:
                    m.changeStartDate = r.int64();
                    break;
                case 13:
                    m.changeEndDate = r.int64();
                    break;
                case 14:
                    m.changeRequestDate = r.int64();
                    break;
                case 15:
                    m.fixed = r.bool();
                    break;
                case 16:
                    m.registerDate = r.int64();
                    break;
                case 17:
                    m.modifyDate = r.int64();
                    break;
                case 18:
                    m.status = r.int32();
                    break;
                case 19:
                    m.restDuration = r.int32();
                    break;
                case 20:
                    m.payHour = r.double();
                    break;
                case 21:
                    m.currency = r.string();
                    break;
                case 22:
                    m.transportationFee = r.double();
                    break;
                case 23:
                    m.payTotal = r.double();
                    break;
                case 24:
                    m.type = r.int32();
                    break;
                case 25:
                    m.store = $root.Proto.WorkStore.decode(r, r.uint32());
                    break;
                case 26:
                    m.lateDuration = r.int32();
                    break;
                case 27:
                    m.changeRequested = r.bool();
                    break;
                case 28:
                    m.holidayInfo = r.string();
                    break;
                case 29:
                    m.timetable = $root.Proto.WorkStoreTimetable.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreTimetableClock message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreTimetableClock
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreTimetableClock} WorkStoreTimetableClock
         */
        WorkStoreTimetableClock.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreTimetableClock)
                return d;
            var m = new $root.Proto.WorkStoreTimetableClock();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.storeUID != null) {
                m.storeUID = String(d.storeUID);
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.startLatitude != null) {
                if (typeof d.startLatitude !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableClock.startLatitude: object expected");
                m.startLatitude = $root.Proto.NullDouble.fromObject(d.startLatitude);
            }
            if (d.startLongitude != null) {
                if (typeof d.startLongitude !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableClock.startLongitude: object expected");
                m.startLongitude = $root.Proto.NullDouble.fromObject(d.startLongitude);
            }
            if (d.endLatitude != null) {
                if (typeof d.endLatitude !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableClock.endLatitude: object expected");
                m.endLatitude = $root.Proto.NullDouble.fromObject(d.endLatitude);
            }
            if (d.endLongitude != null) {
                if (typeof d.endLongitude !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableClock.endLongitude: object expected");
                m.endLongitude = $root.Proto.NullDouble.fromObject(d.endLongitude);
            }
            if (d.restStartDate != null) {
                if ($util.Long)
                    (m.restStartDate = $util.Long.fromValue(d.restStartDate)).unsigned = false;
                else if (typeof d.restStartDate === "string")
                    m.restStartDate = parseInt(d.restStartDate, 10);
                else if (typeof d.restStartDate === "number")
                    m.restStartDate = d.restStartDate;
                else if (typeof d.restStartDate === "object")
                    m.restStartDate = new $util.LongBits(d.restStartDate.low >>> 0, d.restStartDate.high >>> 0).toNumber();
            }
            if (d.restEndDate != null) {
                if ($util.Long)
                    (m.restEndDate = $util.Long.fromValue(d.restEndDate)).unsigned = false;
                else if (typeof d.restEndDate === "string")
                    m.restEndDate = parseInt(d.restEndDate, 10);
                else if (typeof d.restEndDate === "number")
                    m.restEndDate = d.restEndDate;
                else if (typeof d.restEndDate === "object")
                    m.restEndDate = new $util.LongBits(d.restEndDate.low >>> 0, d.restEndDate.high >>> 0).toNumber();
            }
            if (d.memo != null) {
                m.memo = String(d.memo);
            }
            if (d.changeStartDate != null) {
                if ($util.Long)
                    (m.changeStartDate = $util.Long.fromValue(d.changeStartDate)).unsigned = false;
                else if (typeof d.changeStartDate === "string")
                    m.changeStartDate = parseInt(d.changeStartDate, 10);
                else if (typeof d.changeStartDate === "number")
                    m.changeStartDate = d.changeStartDate;
                else if (typeof d.changeStartDate === "object")
                    m.changeStartDate = new $util.LongBits(d.changeStartDate.low >>> 0, d.changeStartDate.high >>> 0).toNumber();
            }
            if (d.changeEndDate != null) {
                if ($util.Long)
                    (m.changeEndDate = $util.Long.fromValue(d.changeEndDate)).unsigned = false;
                else if (typeof d.changeEndDate === "string")
                    m.changeEndDate = parseInt(d.changeEndDate, 10);
                else if (typeof d.changeEndDate === "number")
                    m.changeEndDate = d.changeEndDate;
                else if (typeof d.changeEndDate === "object")
                    m.changeEndDate = new $util.LongBits(d.changeEndDate.low >>> 0, d.changeEndDate.high >>> 0).toNumber();
            }
            if (d.changeRequestDate != null) {
                if ($util.Long)
                    (m.changeRequestDate = $util.Long.fromValue(d.changeRequestDate)).unsigned = false;
                else if (typeof d.changeRequestDate === "string")
                    m.changeRequestDate = parseInt(d.changeRequestDate, 10);
                else if (typeof d.changeRequestDate === "number")
                    m.changeRequestDate = d.changeRequestDate;
                else if (typeof d.changeRequestDate === "object")
                    m.changeRequestDate = new $util.LongBits(d.changeRequestDate.low >>> 0, d.changeRequestDate.high >>> 0).toNumber();
            }
            if (d.fixed != null) {
                m.fixed = Boolean(d.fixed);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.restDuration != null) {
                m.restDuration = d.restDuration | 0;
            }
            if (d.payHour != null) {
                m.payHour = Number(d.payHour);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.transportationFee != null) {
                m.transportationFee = Number(d.transportationFee);
            }
            if (d.payTotal != null) {
                m.payTotal = Number(d.payTotal);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.store != null) {
                if (typeof d.store !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableClock.store: object expected");
                m.store = $root.Proto.WorkStore.fromObject(d.store);
            }
            if (d.lateDuration != null) {
                m.lateDuration = d.lateDuration | 0;
            }
            if (d.changeRequested != null) {
                m.changeRequested = Boolean(d.changeRequested);
            }
            if (d.holidayInfo != null) {
                m.holidayInfo = String(d.holidayInfo);
            }
            if (d.timetable != null) {
                if (typeof d.timetable !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableClock.timetable: object expected");
                m.timetable = $root.Proto.WorkStoreTimetable.fromObject(d.timetable);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreTimetableClock message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreTimetableClock
         * @static
         * @param {Proto.WorkStoreTimetableClock} m WorkStoreTimetableClock
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreTimetableClock.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.storeUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                d.startLatitude = null;
                d.startLongitude = null;
                d.endLatitude = null;
                d.endLongitude = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.restStartDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.restStartDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.restEndDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.restEndDate = o.longs === String ? "0" : 0;
                d.memo = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.changeStartDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.changeStartDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.changeEndDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.changeEndDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.changeRequestDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.changeRequestDate = o.longs === String ? "0" : 0;
                d.fixed = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.restDuration = 0;
                d.payHour = 0;
                d.currency = "";
                d.transportationFee = 0;
                d.payTotal = 0;
                d.type = 0;
                d.store = null;
                d.lateDuration = 0;
                d.changeRequested = false;
                d.holidayInfo = "";
                d.timetable = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.storeUID != null && m.hasOwnProperty("storeUID")) {
                d.storeUID = m.storeUID;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.startLatitude != null && m.hasOwnProperty("startLatitude")) {
                d.startLatitude = $root.Proto.NullDouble.toObject(m.startLatitude, o);
            }
            if (m.startLongitude != null && m.hasOwnProperty("startLongitude")) {
                d.startLongitude = $root.Proto.NullDouble.toObject(m.startLongitude, o);
            }
            if (m.endLatitude != null && m.hasOwnProperty("endLatitude")) {
                d.endLatitude = $root.Proto.NullDouble.toObject(m.endLatitude, o);
            }
            if (m.endLongitude != null && m.hasOwnProperty("endLongitude")) {
                d.endLongitude = $root.Proto.NullDouble.toObject(m.endLongitude, o);
            }
            if (m.restStartDate != null && m.hasOwnProperty("restStartDate")) {
                if (typeof m.restStartDate === "number")
                    d.restStartDate = o.longs === String ? String(m.restStartDate) : m.restStartDate;
                else
                    d.restStartDate = o.longs === String ? $util.Long.prototype.toString.call(m.restStartDate) : o.longs === Number ? new $util.LongBits(m.restStartDate.low >>> 0, m.restStartDate.high >>> 0).toNumber() : m.restStartDate;
            }
            if (m.restEndDate != null && m.hasOwnProperty("restEndDate")) {
                if (typeof m.restEndDate === "number")
                    d.restEndDate = o.longs === String ? String(m.restEndDate) : m.restEndDate;
                else
                    d.restEndDate = o.longs === String ? $util.Long.prototype.toString.call(m.restEndDate) : o.longs === Number ? new $util.LongBits(m.restEndDate.low >>> 0, m.restEndDate.high >>> 0).toNumber() : m.restEndDate;
            }
            if (m.memo != null && m.hasOwnProperty("memo")) {
                d.memo = m.memo;
            }
            if (m.changeStartDate != null && m.hasOwnProperty("changeStartDate")) {
                if (typeof m.changeStartDate === "number")
                    d.changeStartDate = o.longs === String ? String(m.changeStartDate) : m.changeStartDate;
                else
                    d.changeStartDate = o.longs === String ? $util.Long.prototype.toString.call(m.changeStartDate) : o.longs === Number ? new $util.LongBits(m.changeStartDate.low >>> 0, m.changeStartDate.high >>> 0).toNumber() : m.changeStartDate;
            }
            if (m.changeEndDate != null && m.hasOwnProperty("changeEndDate")) {
                if (typeof m.changeEndDate === "number")
                    d.changeEndDate = o.longs === String ? String(m.changeEndDate) : m.changeEndDate;
                else
                    d.changeEndDate = o.longs === String ? $util.Long.prototype.toString.call(m.changeEndDate) : o.longs === Number ? new $util.LongBits(m.changeEndDate.low >>> 0, m.changeEndDate.high >>> 0).toNumber() : m.changeEndDate;
            }
            if (m.changeRequestDate != null && m.hasOwnProperty("changeRequestDate")) {
                if (typeof m.changeRequestDate === "number")
                    d.changeRequestDate = o.longs === String ? String(m.changeRequestDate) : m.changeRequestDate;
                else
                    d.changeRequestDate = o.longs === String ? $util.Long.prototype.toString.call(m.changeRequestDate) : o.longs === Number ? new $util.LongBits(m.changeRequestDate.low >>> 0, m.changeRequestDate.high >>> 0).toNumber() : m.changeRequestDate;
            }
            if (m.fixed != null && m.hasOwnProperty("fixed")) {
                d.fixed = m.fixed;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.restDuration != null && m.hasOwnProperty("restDuration")) {
                d.restDuration = m.restDuration;
            }
            if (m.payHour != null && m.hasOwnProperty("payHour")) {
                d.payHour = o.json && !isFinite(m.payHour) ? String(m.payHour) : m.payHour;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.transportationFee != null && m.hasOwnProperty("transportationFee")) {
                d.transportationFee = o.json && !isFinite(m.transportationFee) ? String(m.transportationFee) : m.transportationFee;
            }
            if (m.payTotal != null && m.hasOwnProperty("payTotal")) {
                d.payTotal = o.json && !isFinite(m.payTotal) ? String(m.payTotal) : m.payTotal;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.store != null && m.hasOwnProperty("store")) {
                d.store = $root.Proto.WorkStore.toObject(m.store, o);
            }
            if (m.lateDuration != null && m.hasOwnProperty("lateDuration")) {
                d.lateDuration = m.lateDuration;
            }
            if (m.changeRequested != null && m.hasOwnProperty("changeRequested")) {
                d.changeRequested = m.changeRequested;
            }
            if (m.holidayInfo != null && m.hasOwnProperty("holidayInfo")) {
                d.holidayInfo = m.holidayInfo;
            }
            if (m.timetable != null && m.hasOwnProperty("timetable")) {
                d.timetable = $root.Proto.WorkStoreTimetable.toObject(m.timetable, o);
            }
            return d;
        };

        /**
         * Converts this WorkStoreTimetableClock to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreTimetableClock
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreTimetableClock.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreTimetableClock;
    })();

    Proto.WorkStoreTimetableClockList = (function() {

        /**
         * Properties of a WorkStoreTimetableClockList.
         * @memberof Proto
         * @interface IWorkStoreTimetableClockList
         * @property {Array.<Proto.IWorkStoreTimetableClock>|null} [value] WorkStoreTimetableClockList value
         */

        /**
         * Constructs a new WorkStoreTimetableClockList.
         * @memberof Proto
         * @classdesc Represents a WorkStoreTimetableClockList.
         * @implements IWorkStoreTimetableClockList
         * @constructor
         * @param {Proto.IWorkStoreTimetableClockList=} [p] Properties to set
         */
        function WorkStoreTimetableClockList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreTimetableClockList value.
         * @member {Array.<Proto.IWorkStoreTimetableClock>} value
         * @memberof Proto.WorkStoreTimetableClockList
         * @instance
         */
        WorkStoreTimetableClockList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkStoreTimetableClockList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreTimetableClockList
         * @static
         * @param {Proto.IWorkStoreTimetableClockList=} [properties] Properties to set
         * @returns {Proto.WorkStoreTimetableClockList} WorkStoreTimetableClockList instance
         */
        WorkStoreTimetableClockList.create = function create(properties) {
            return new WorkStoreTimetableClockList(properties);
        };

        /**
         * Encodes the specified WorkStoreTimetableClockList message. Does not implicitly {@link Proto.WorkStoreTimetableClockList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreTimetableClockList
         * @static
         * @param {Proto.IWorkStoreTimetableClockList} m WorkStoreTimetableClockList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreTimetableClockList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkStoreTimetableClock.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkStoreTimetableClockList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreTimetableClockList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreTimetableClockList} WorkStoreTimetableClockList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreTimetableClockList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreTimetableClockList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkStoreTimetableClock.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreTimetableClockList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreTimetableClockList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreTimetableClockList} WorkStoreTimetableClockList
         */
        WorkStoreTimetableClockList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreTimetableClockList)
                return d;
            var m = new $root.Proto.WorkStoreTimetableClockList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkStoreTimetableClockList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkStoreTimetableClockList.value: object expected");
                    m.value[i] = $root.Proto.WorkStoreTimetableClock.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreTimetableClockList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreTimetableClockList
         * @static
         * @param {Proto.WorkStoreTimetableClockList} m WorkStoreTimetableClockList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreTimetableClockList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkStoreTimetableClock.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkStoreTimetableClockList to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreTimetableClockList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreTimetableClockList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreTimetableClockList;
    })();

    Proto.WorkStaffPayTarget = (function() {

        /**
         * Properties of a WorkStaffPayTarget.
         * @memberof Proto
         * @interface IWorkStaffPayTarget
         * @property {string|null} [month] WorkStaffPayTarget month
         * @property {number|null} [value] WorkStaffPayTarget value
         * @property {string|null} [currency] WorkStaffPayTarget currency
         * @property {number|Long|null} [registerDate] WorkStaffPayTarget registerDate
         * @property {number|Long|null} [modifyDate] WorkStaffPayTarget modifyDate
         */

        /**
         * Constructs a new WorkStaffPayTarget.
         * @memberof Proto
         * @classdesc Represents a WorkStaffPayTarget.
         * @implements IWorkStaffPayTarget
         * @constructor
         * @param {Proto.IWorkStaffPayTarget=} [p] Properties to set
         */
        function WorkStaffPayTarget(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStaffPayTarget month.
         * @member {string} month
         * @memberof Proto.WorkStaffPayTarget
         * @instance
         */
        WorkStaffPayTarget.prototype.month = "";

        /**
         * WorkStaffPayTarget value.
         * @member {number} value
         * @memberof Proto.WorkStaffPayTarget
         * @instance
         */
        WorkStaffPayTarget.prototype.value = 0;

        /**
         * WorkStaffPayTarget currency.
         * @member {string} currency
         * @memberof Proto.WorkStaffPayTarget
         * @instance
         */
        WorkStaffPayTarget.prototype.currency = "";

        /**
         * WorkStaffPayTarget registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStaffPayTarget
         * @instance
         */
        WorkStaffPayTarget.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStaffPayTarget modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStaffPayTarget
         * @instance
         */
        WorkStaffPayTarget.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new WorkStaffPayTarget instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStaffPayTarget
         * @static
         * @param {Proto.IWorkStaffPayTarget=} [properties] Properties to set
         * @returns {Proto.WorkStaffPayTarget} WorkStaffPayTarget instance
         */
        WorkStaffPayTarget.create = function create(properties) {
            return new WorkStaffPayTarget(properties);
        };

        /**
         * Encodes the specified WorkStaffPayTarget message. Does not implicitly {@link Proto.WorkStaffPayTarget.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStaffPayTarget
         * @static
         * @param {Proto.IWorkStaffPayTarget} m WorkStaffPayTarget message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStaffPayTarget.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.month != null && Object.hasOwnProperty.call(m, "month"))
                w.uint32(10).string(m.month);
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(17).double(m.value);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(26).string(m.currency);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a WorkStaffPayTarget message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStaffPayTarget
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStaffPayTarget} WorkStaffPayTarget
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStaffPayTarget.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStaffPayTarget();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.month = r.string();
                    break;
                case 2:
                    m.value = r.double();
                    break;
                case 3:
                    m.currency = r.string();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStaffPayTarget message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStaffPayTarget
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStaffPayTarget} WorkStaffPayTarget
         */
        WorkStaffPayTarget.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStaffPayTarget)
                return d;
            var m = new $root.Proto.WorkStaffPayTarget();
            if (d.month != null) {
                m.month = String(d.month);
            }
            if (d.value != null) {
                m.value = Number(d.value);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStaffPayTarget message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStaffPayTarget
         * @static
         * @param {Proto.WorkStaffPayTarget} m WorkStaffPayTarget
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStaffPayTarget.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.month = "";
                d.value = 0;
                d.currency = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.month != null && m.hasOwnProperty("month")) {
                d.month = m.month;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = o.json && !isFinite(m.value) ? String(m.value) : m.value;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this WorkStaffPayTarget to JSON.
         * @function toJSON
         * @memberof Proto.WorkStaffPayTarget
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStaffPayTarget.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStaffPayTarget;
    })();

    Proto.WorkStoreStaffSalaryWeek = (function() {

        /**
         * Properties of a WorkStoreStaffSalaryWeek.
         * @memberof Proto
         * @interface IWorkStoreStaffSalaryWeek
         * @property {number|null} [expectDays] WorkStoreStaffSalaryWeek expectDays
         * @property {number|null} [workDays] WorkStoreStaffSalaryWeek workDays
         * @property {number|null} [saturdayDays] WorkStoreStaffSalaryWeek saturdayDays
         * @property {number|null} [sundayDays] WorkStoreStaffSalaryWeek sundayDays
         * @property {string|null} [cancelDays] WorkStoreStaffSalaryWeek cancelDays
         * @property {number|null} [delayDays] WorkStoreStaffSalaryWeek delayDays
         * @property {number|null} [earlyDays] WorkStoreStaffSalaryWeek earlyDays
         * @property {string|null} [overallDuration] WorkStoreStaffSalaryWeek overallDuration
         * @property {string|null} [totalDuration] WorkStoreStaffSalaryWeek totalDuration
         * @property {string|null} [duration] WorkStoreStaffSalaryWeek duration
         * @property {string|null} [expectDuration] WorkStoreStaffSalaryWeek expectDuration
         * @property {string|null} [expectRealDuration] WorkStoreStaffSalaryWeek expectRealDuration
         * @property {string|null} [additionalDuration] WorkStoreStaffSalaryWeek additionalDuration
         * @property {string|null} [additionalInLawAllDuration] WorkStoreStaffSalaryWeek additionalInLawAllDuration
         * @property {string|null} [additionalAllDuration] WorkStoreStaffSalaryWeek additionalAllDuration
         * @property {string|null} [saturdayDuration] WorkStoreStaffSalaryWeek saturdayDuration
         * @property {string|null} [sundayDuration] WorkStoreStaffSalaryWeek sundayDuration
         * @property {string|null} [lateDuration] WorkStoreStaffSalaryWeek lateDuration
         * @property {string|null} [delayDuration] WorkStoreStaffSalaryWeek delayDuration
         * @property {string|null} [earlyDuration] WorkStoreStaffSalaryWeek earlyDuration
         * @property {string|null} [restDuration] WorkStoreStaffSalaryWeek restDuration
         * @property {string|null} [shortageDuration] WorkStoreStaffSalaryWeek shortageDuration
         * @property {number|null} [holidayDays] WorkStoreStaffSalaryWeek holidayDays
         * @property {number|null} [work7Hours] WorkStoreStaffSalaryWeek work7Hours
         * @property {number|null} [work8Hours] WorkStoreStaffSalaryWeek work8Hours
         * @property {number|null} [work9Hours] WorkStoreStaffSalaryWeek work9Hours
         * @property {string|null} [salary] WorkStoreStaffSalaryWeek salary
         * @property {string|null} [additional] WorkStoreStaffSalaryWeek additional
         * @property {string|null} [transportationFee] WorkStoreStaffSalaryWeek transportationFee
         * @property {string|null} [totalSalary] WorkStoreStaffSalaryWeek totalSalary
         * @property {Proto.IWorkStoreStaffHolidayInfo|null} [holidayInfo] WorkStoreStaffSalaryWeek holidayInfo
         * @property {string|null} [currency] WorkStoreStaffSalaryWeek currency
         * @property {number|Long|null} [registerDate] WorkStoreStaffSalaryWeek registerDate
         * @property {number|Long|null} [modifyDate] WorkStoreStaffSalaryWeek modifyDate
         * @property {string|null} [startDate] WorkStoreStaffSalaryWeek startDate
         * @property {string|null} [endDate] WorkStoreStaffSalaryWeek endDate
         */

        /**
         * Constructs a new WorkStoreStaffSalaryWeek.
         * @memberof Proto
         * @classdesc Represents a WorkStoreStaffSalaryWeek.
         * @implements IWorkStoreStaffSalaryWeek
         * @constructor
         * @param {Proto.IWorkStoreStaffSalaryWeek=} [p] Properties to set
         */
        function WorkStoreStaffSalaryWeek(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreStaffSalaryWeek expectDays.
         * @member {number} expectDays
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.expectDays = 0;

        /**
         * WorkStoreStaffSalaryWeek workDays.
         * @member {number} workDays
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.workDays = 0;

        /**
         * WorkStoreStaffSalaryWeek saturdayDays.
         * @member {number} saturdayDays
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.saturdayDays = 0;

        /**
         * WorkStoreStaffSalaryWeek sundayDays.
         * @member {number} sundayDays
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.sundayDays = 0;

        /**
         * WorkStoreStaffSalaryWeek cancelDays.
         * @member {string} cancelDays
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.cancelDays = "";

        /**
         * WorkStoreStaffSalaryWeek delayDays.
         * @member {number} delayDays
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.delayDays = 0;

        /**
         * WorkStoreStaffSalaryWeek earlyDays.
         * @member {number} earlyDays
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.earlyDays = 0;

        /**
         * WorkStoreStaffSalaryWeek overallDuration.
         * @member {string} overallDuration
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.overallDuration = "";

        /**
         * WorkStoreStaffSalaryWeek totalDuration.
         * @member {string} totalDuration
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.totalDuration = "";

        /**
         * WorkStoreStaffSalaryWeek duration.
         * @member {string} duration
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.duration = "";

        /**
         * WorkStoreStaffSalaryWeek expectDuration.
         * @member {string} expectDuration
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.expectDuration = "";

        /**
         * WorkStoreStaffSalaryWeek expectRealDuration.
         * @member {string} expectRealDuration
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.expectRealDuration = "";

        /**
         * WorkStoreStaffSalaryWeek additionalDuration.
         * @member {string} additionalDuration
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.additionalDuration = "";

        /**
         * WorkStoreStaffSalaryWeek additionalInLawAllDuration.
         * @member {string} additionalInLawAllDuration
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.additionalInLawAllDuration = "";

        /**
         * WorkStoreStaffSalaryWeek additionalAllDuration.
         * @member {string} additionalAllDuration
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.additionalAllDuration = "";

        /**
         * WorkStoreStaffSalaryWeek saturdayDuration.
         * @member {string} saturdayDuration
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.saturdayDuration = "";

        /**
         * WorkStoreStaffSalaryWeek sundayDuration.
         * @member {string} sundayDuration
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.sundayDuration = "";

        /**
         * WorkStoreStaffSalaryWeek lateDuration.
         * @member {string} lateDuration
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.lateDuration = "";

        /**
         * WorkStoreStaffSalaryWeek delayDuration.
         * @member {string} delayDuration
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.delayDuration = "";

        /**
         * WorkStoreStaffSalaryWeek earlyDuration.
         * @member {string} earlyDuration
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.earlyDuration = "";

        /**
         * WorkStoreStaffSalaryWeek restDuration.
         * @member {string} restDuration
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.restDuration = "";

        /**
         * WorkStoreStaffSalaryWeek shortageDuration.
         * @member {string} shortageDuration
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.shortageDuration = "";

        /**
         * WorkStoreStaffSalaryWeek holidayDays.
         * @member {number} holidayDays
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.holidayDays = 0;

        /**
         * WorkStoreStaffSalaryWeek work7Hours.
         * @member {number} work7Hours
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.work7Hours = 0;

        /**
         * WorkStoreStaffSalaryWeek work8Hours.
         * @member {number} work8Hours
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.work8Hours = 0;

        /**
         * WorkStoreStaffSalaryWeek work9Hours.
         * @member {number} work9Hours
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.work9Hours = 0;

        /**
         * WorkStoreStaffSalaryWeek salary.
         * @member {string} salary
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.salary = "";

        /**
         * WorkStoreStaffSalaryWeek additional.
         * @member {string} additional
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.additional = "";

        /**
         * WorkStoreStaffSalaryWeek transportationFee.
         * @member {string} transportationFee
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.transportationFee = "";

        /**
         * WorkStoreStaffSalaryWeek totalSalary.
         * @member {string} totalSalary
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.totalSalary = "";

        /**
         * WorkStoreStaffSalaryWeek holidayInfo.
         * @member {Proto.IWorkStoreStaffHolidayInfo|null|undefined} holidayInfo
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.holidayInfo = null;

        /**
         * WorkStoreStaffSalaryWeek currency.
         * @member {string} currency
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.currency = "";

        /**
         * WorkStoreStaffSalaryWeek registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreStaffSalaryWeek modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreStaffSalaryWeek startDate.
         * @member {string} startDate
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.startDate = "";

        /**
         * WorkStoreStaffSalaryWeek endDate.
         * @member {string} endDate
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         */
        WorkStoreStaffSalaryWeek.prototype.endDate = "";

        /**
         * Creates a new WorkStoreStaffSalaryWeek instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @static
         * @param {Proto.IWorkStoreStaffSalaryWeek=} [properties] Properties to set
         * @returns {Proto.WorkStoreStaffSalaryWeek} WorkStoreStaffSalaryWeek instance
         */
        WorkStoreStaffSalaryWeek.create = function create(properties) {
            return new WorkStoreStaffSalaryWeek(properties);
        };

        /**
         * Encodes the specified WorkStoreStaffSalaryWeek message. Does not implicitly {@link Proto.WorkStoreStaffSalaryWeek.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @static
         * @param {Proto.IWorkStoreStaffSalaryWeek} m WorkStoreStaffSalaryWeek message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreStaffSalaryWeek.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.expectDays != null && Object.hasOwnProperty.call(m, "expectDays"))
                w.uint32(8).int32(m.expectDays);
            if (m.workDays != null && Object.hasOwnProperty.call(m, "workDays"))
                w.uint32(16).int32(m.workDays);
            if (m.saturdayDays != null && Object.hasOwnProperty.call(m, "saturdayDays"))
                w.uint32(24).int32(m.saturdayDays);
            if (m.sundayDays != null && Object.hasOwnProperty.call(m, "sundayDays"))
                w.uint32(32).int32(m.sundayDays);
            if (m.cancelDays != null && Object.hasOwnProperty.call(m, "cancelDays"))
                w.uint32(42).string(m.cancelDays);
            if (m.delayDays != null && Object.hasOwnProperty.call(m, "delayDays"))
                w.uint32(48).int32(m.delayDays);
            if (m.earlyDays != null && Object.hasOwnProperty.call(m, "earlyDays"))
                w.uint32(56).int32(m.earlyDays);
            if (m.overallDuration != null && Object.hasOwnProperty.call(m, "overallDuration"))
                w.uint32(66).string(m.overallDuration);
            if (m.totalDuration != null && Object.hasOwnProperty.call(m, "totalDuration"))
                w.uint32(74).string(m.totalDuration);
            if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                w.uint32(82).string(m.duration);
            if (m.expectDuration != null && Object.hasOwnProperty.call(m, "expectDuration"))
                w.uint32(90).string(m.expectDuration);
            if (m.expectRealDuration != null && Object.hasOwnProperty.call(m, "expectRealDuration"))
                w.uint32(98).string(m.expectRealDuration);
            if (m.additionalDuration != null && Object.hasOwnProperty.call(m, "additionalDuration"))
                w.uint32(106).string(m.additionalDuration);
            if (m.additionalInLawAllDuration != null && Object.hasOwnProperty.call(m, "additionalInLawAllDuration"))
                w.uint32(114).string(m.additionalInLawAllDuration);
            if (m.additionalAllDuration != null && Object.hasOwnProperty.call(m, "additionalAllDuration"))
                w.uint32(122).string(m.additionalAllDuration);
            if (m.saturdayDuration != null && Object.hasOwnProperty.call(m, "saturdayDuration"))
                w.uint32(130).string(m.saturdayDuration);
            if (m.sundayDuration != null && Object.hasOwnProperty.call(m, "sundayDuration"))
                w.uint32(138).string(m.sundayDuration);
            if (m.lateDuration != null && Object.hasOwnProperty.call(m, "lateDuration"))
                w.uint32(146).string(m.lateDuration);
            if (m.delayDuration != null && Object.hasOwnProperty.call(m, "delayDuration"))
                w.uint32(154).string(m.delayDuration);
            if (m.earlyDuration != null && Object.hasOwnProperty.call(m, "earlyDuration"))
                w.uint32(162).string(m.earlyDuration);
            if (m.restDuration != null && Object.hasOwnProperty.call(m, "restDuration"))
                w.uint32(170).string(m.restDuration);
            if (m.shortageDuration != null && Object.hasOwnProperty.call(m, "shortageDuration"))
                w.uint32(178).string(m.shortageDuration);
            if (m.holidayDays != null && Object.hasOwnProperty.call(m, "holidayDays"))
                w.uint32(184).int32(m.holidayDays);
            if (m.work7Hours != null && Object.hasOwnProperty.call(m, "work7Hours"))
                w.uint32(192).int32(m.work7Hours);
            if (m.work8Hours != null && Object.hasOwnProperty.call(m, "work8Hours"))
                w.uint32(200).int32(m.work8Hours);
            if (m.work9Hours != null && Object.hasOwnProperty.call(m, "work9Hours"))
                w.uint32(208).int32(m.work9Hours);
            if (m.salary != null && Object.hasOwnProperty.call(m, "salary"))
                w.uint32(218).string(m.salary);
            if (m.additional != null && Object.hasOwnProperty.call(m, "additional"))
                w.uint32(226).string(m.additional);
            if (m.transportationFee != null && Object.hasOwnProperty.call(m, "transportationFee"))
                w.uint32(234).string(m.transportationFee);
            if (m.totalSalary != null && Object.hasOwnProperty.call(m, "totalSalary"))
                w.uint32(242).string(m.totalSalary);
            if (m.holidayInfo != null && Object.hasOwnProperty.call(m, "holidayInfo"))
                $root.Proto.WorkStoreStaffHolidayInfo.encode(m.holidayInfo, w.uint32(250).fork()).ldelim();
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(258).string(m.currency);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(328).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(336).int64(m.modifyDate);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(346).string(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(354).string(m.endDate);
            return w;
        };

        /**
         * Decodes a WorkStoreStaffSalaryWeek message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreStaffSalaryWeek} WorkStoreStaffSalaryWeek
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreStaffSalaryWeek.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreStaffSalaryWeek();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.expectDays = r.int32();
                    break;
                case 2:
                    m.workDays = r.int32();
                    break;
                case 3:
                    m.saturdayDays = r.int32();
                    break;
                case 4:
                    m.sundayDays = r.int32();
                    break;
                case 5:
                    m.cancelDays = r.string();
                    break;
                case 6:
                    m.delayDays = r.int32();
                    break;
                case 7:
                    m.earlyDays = r.int32();
                    break;
                case 8:
                    m.overallDuration = r.string();
                    break;
                case 9:
                    m.totalDuration = r.string();
                    break;
                case 10:
                    m.duration = r.string();
                    break;
                case 11:
                    m.expectDuration = r.string();
                    break;
                case 12:
                    m.expectRealDuration = r.string();
                    break;
                case 13:
                    m.additionalDuration = r.string();
                    break;
                case 14:
                    m.additionalInLawAllDuration = r.string();
                    break;
                case 15:
                    m.additionalAllDuration = r.string();
                    break;
                case 16:
                    m.saturdayDuration = r.string();
                    break;
                case 17:
                    m.sundayDuration = r.string();
                    break;
                case 18:
                    m.lateDuration = r.string();
                    break;
                case 19:
                    m.delayDuration = r.string();
                    break;
                case 20:
                    m.earlyDuration = r.string();
                    break;
                case 21:
                    m.restDuration = r.string();
                    break;
                case 22:
                    m.shortageDuration = r.string();
                    break;
                case 23:
                    m.holidayDays = r.int32();
                    break;
                case 24:
                    m.work7Hours = r.int32();
                    break;
                case 25:
                    m.work8Hours = r.int32();
                    break;
                case 26:
                    m.work9Hours = r.int32();
                    break;
                case 27:
                    m.salary = r.string();
                    break;
                case 28:
                    m.additional = r.string();
                    break;
                case 29:
                    m.transportationFee = r.string();
                    break;
                case 30:
                    m.totalSalary = r.string();
                    break;
                case 31:
                    m.holidayInfo = $root.Proto.WorkStoreStaffHolidayInfo.decode(r, r.uint32());
                    break;
                case 32:
                    m.currency = r.string();
                    break;
                case 41:
                    m.registerDate = r.int64();
                    break;
                case 42:
                    m.modifyDate = r.int64();
                    break;
                case 43:
                    m.startDate = r.string();
                    break;
                case 44:
                    m.endDate = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreStaffSalaryWeek message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreStaffSalaryWeek} WorkStoreStaffSalaryWeek
         */
        WorkStoreStaffSalaryWeek.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreStaffSalaryWeek)
                return d;
            var m = new $root.Proto.WorkStoreStaffSalaryWeek();
            if (d.expectDays != null) {
                m.expectDays = d.expectDays | 0;
            }
            if (d.workDays != null) {
                m.workDays = d.workDays | 0;
            }
            if (d.saturdayDays != null) {
                m.saturdayDays = d.saturdayDays | 0;
            }
            if (d.sundayDays != null) {
                m.sundayDays = d.sundayDays | 0;
            }
            if (d.cancelDays != null) {
                m.cancelDays = String(d.cancelDays);
            }
            if (d.delayDays != null) {
                m.delayDays = d.delayDays | 0;
            }
            if (d.earlyDays != null) {
                m.earlyDays = d.earlyDays | 0;
            }
            if (d.overallDuration != null) {
                m.overallDuration = String(d.overallDuration);
            }
            if (d.totalDuration != null) {
                m.totalDuration = String(d.totalDuration);
            }
            if (d.duration != null) {
                m.duration = String(d.duration);
            }
            if (d.expectDuration != null) {
                m.expectDuration = String(d.expectDuration);
            }
            if (d.expectRealDuration != null) {
                m.expectRealDuration = String(d.expectRealDuration);
            }
            if (d.additionalDuration != null) {
                m.additionalDuration = String(d.additionalDuration);
            }
            if (d.additionalInLawAllDuration != null) {
                m.additionalInLawAllDuration = String(d.additionalInLawAllDuration);
            }
            if (d.additionalAllDuration != null) {
                m.additionalAllDuration = String(d.additionalAllDuration);
            }
            if (d.saturdayDuration != null) {
                m.saturdayDuration = String(d.saturdayDuration);
            }
            if (d.sundayDuration != null) {
                m.sundayDuration = String(d.sundayDuration);
            }
            if (d.lateDuration != null) {
                m.lateDuration = String(d.lateDuration);
            }
            if (d.delayDuration != null) {
                m.delayDuration = String(d.delayDuration);
            }
            if (d.earlyDuration != null) {
                m.earlyDuration = String(d.earlyDuration);
            }
            if (d.restDuration != null) {
                m.restDuration = String(d.restDuration);
            }
            if (d.shortageDuration != null) {
                m.shortageDuration = String(d.shortageDuration);
            }
            if (d.holidayDays != null) {
                m.holidayDays = d.holidayDays | 0;
            }
            if (d.work7Hours != null) {
                m.work7Hours = d.work7Hours | 0;
            }
            if (d.work8Hours != null) {
                m.work8Hours = d.work8Hours | 0;
            }
            if (d.work9Hours != null) {
                m.work9Hours = d.work9Hours | 0;
            }
            if (d.salary != null) {
                m.salary = String(d.salary);
            }
            if (d.additional != null) {
                m.additional = String(d.additional);
            }
            if (d.transportationFee != null) {
                m.transportationFee = String(d.transportationFee);
            }
            if (d.totalSalary != null) {
                m.totalSalary = String(d.totalSalary);
            }
            if (d.holidayInfo != null) {
                if (typeof d.holidayInfo !== "object")
                    throw TypeError(".Proto.WorkStoreStaffSalaryWeek.holidayInfo: object expected");
                m.holidayInfo = $root.Proto.WorkStoreStaffHolidayInfo.fromObject(d.holidayInfo);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.startDate != null) {
                m.startDate = String(d.startDate);
            }
            if (d.endDate != null) {
                m.endDate = String(d.endDate);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreStaffSalaryWeek message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @static
         * @param {Proto.WorkStoreStaffSalaryWeek} m WorkStoreStaffSalaryWeek
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreStaffSalaryWeek.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.expectDays = 0;
                d.workDays = 0;
                d.saturdayDays = 0;
                d.sundayDays = 0;
                d.cancelDays = "";
                d.delayDays = 0;
                d.earlyDays = 0;
                d.overallDuration = "";
                d.totalDuration = "";
                d.duration = "";
                d.expectDuration = "";
                d.expectRealDuration = "";
                d.additionalDuration = "";
                d.additionalInLawAllDuration = "";
                d.additionalAllDuration = "";
                d.saturdayDuration = "";
                d.sundayDuration = "";
                d.lateDuration = "";
                d.delayDuration = "";
                d.earlyDuration = "";
                d.restDuration = "";
                d.shortageDuration = "";
                d.holidayDays = 0;
                d.work7Hours = 0;
                d.work8Hours = 0;
                d.work9Hours = 0;
                d.salary = "";
                d.additional = "";
                d.transportationFee = "";
                d.totalSalary = "";
                d.holidayInfo = null;
                d.currency = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.startDate = "";
                d.endDate = "";
            }
            if (m.expectDays != null && m.hasOwnProperty("expectDays")) {
                d.expectDays = m.expectDays;
            }
            if (m.workDays != null && m.hasOwnProperty("workDays")) {
                d.workDays = m.workDays;
            }
            if (m.saturdayDays != null && m.hasOwnProperty("saturdayDays")) {
                d.saturdayDays = m.saturdayDays;
            }
            if (m.sundayDays != null && m.hasOwnProperty("sundayDays")) {
                d.sundayDays = m.sundayDays;
            }
            if (m.cancelDays != null && m.hasOwnProperty("cancelDays")) {
                d.cancelDays = m.cancelDays;
            }
            if (m.delayDays != null && m.hasOwnProperty("delayDays")) {
                d.delayDays = m.delayDays;
            }
            if (m.earlyDays != null && m.hasOwnProperty("earlyDays")) {
                d.earlyDays = m.earlyDays;
            }
            if (m.overallDuration != null && m.hasOwnProperty("overallDuration")) {
                d.overallDuration = m.overallDuration;
            }
            if (m.totalDuration != null && m.hasOwnProperty("totalDuration")) {
                d.totalDuration = m.totalDuration;
            }
            if (m.duration != null && m.hasOwnProperty("duration")) {
                d.duration = m.duration;
            }
            if (m.expectDuration != null && m.hasOwnProperty("expectDuration")) {
                d.expectDuration = m.expectDuration;
            }
            if (m.expectRealDuration != null && m.hasOwnProperty("expectRealDuration")) {
                d.expectRealDuration = m.expectRealDuration;
            }
            if (m.additionalDuration != null && m.hasOwnProperty("additionalDuration")) {
                d.additionalDuration = m.additionalDuration;
            }
            if (m.additionalInLawAllDuration != null && m.hasOwnProperty("additionalInLawAllDuration")) {
                d.additionalInLawAllDuration = m.additionalInLawAllDuration;
            }
            if (m.additionalAllDuration != null && m.hasOwnProperty("additionalAllDuration")) {
                d.additionalAllDuration = m.additionalAllDuration;
            }
            if (m.saturdayDuration != null && m.hasOwnProperty("saturdayDuration")) {
                d.saturdayDuration = m.saturdayDuration;
            }
            if (m.sundayDuration != null && m.hasOwnProperty("sundayDuration")) {
                d.sundayDuration = m.sundayDuration;
            }
            if (m.lateDuration != null && m.hasOwnProperty("lateDuration")) {
                d.lateDuration = m.lateDuration;
            }
            if (m.delayDuration != null && m.hasOwnProperty("delayDuration")) {
                d.delayDuration = m.delayDuration;
            }
            if (m.earlyDuration != null && m.hasOwnProperty("earlyDuration")) {
                d.earlyDuration = m.earlyDuration;
            }
            if (m.restDuration != null && m.hasOwnProperty("restDuration")) {
                d.restDuration = m.restDuration;
            }
            if (m.shortageDuration != null && m.hasOwnProperty("shortageDuration")) {
                d.shortageDuration = m.shortageDuration;
            }
            if (m.holidayDays != null && m.hasOwnProperty("holidayDays")) {
                d.holidayDays = m.holidayDays;
            }
            if (m.work7Hours != null && m.hasOwnProperty("work7Hours")) {
                d.work7Hours = m.work7Hours;
            }
            if (m.work8Hours != null && m.hasOwnProperty("work8Hours")) {
                d.work8Hours = m.work8Hours;
            }
            if (m.work9Hours != null && m.hasOwnProperty("work9Hours")) {
                d.work9Hours = m.work9Hours;
            }
            if (m.salary != null && m.hasOwnProperty("salary")) {
                d.salary = m.salary;
            }
            if (m.additional != null && m.hasOwnProperty("additional")) {
                d.additional = m.additional;
            }
            if (m.transportationFee != null && m.hasOwnProperty("transportationFee")) {
                d.transportationFee = m.transportationFee;
            }
            if (m.totalSalary != null && m.hasOwnProperty("totalSalary")) {
                d.totalSalary = m.totalSalary;
            }
            if (m.holidayInfo != null && m.hasOwnProperty("holidayInfo")) {
                d.holidayInfo = $root.Proto.WorkStoreStaffHolidayInfo.toObject(m.holidayInfo, o);
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                d.startDate = m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                d.endDate = m.endDate;
            }
            return d;
        };

        /**
         * Converts this WorkStoreStaffSalaryWeek to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreStaffSalaryWeek
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreStaffSalaryWeek.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreStaffSalaryWeek;
    })();

    Proto.WorkStoreStaffSalaryWeekList = (function() {

        /**
         * Properties of a WorkStoreStaffSalaryWeekList.
         * @memberof Proto
         * @interface IWorkStoreStaffSalaryWeekList
         * @property {Array.<Proto.IWorkStoreStaffSalaryWeek>|null} [value] WorkStoreStaffSalaryWeekList value
         */

        /**
         * Constructs a new WorkStoreStaffSalaryWeekList.
         * @memberof Proto
         * @classdesc Represents a WorkStoreStaffSalaryWeekList.
         * @implements IWorkStoreStaffSalaryWeekList
         * @constructor
         * @param {Proto.IWorkStoreStaffSalaryWeekList=} [p] Properties to set
         */
        function WorkStoreStaffSalaryWeekList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreStaffSalaryWeekList value.
         * @member {Array.<Proto.IWorkStoreStaffSalaryWeek>} value
         * @memberof Proto.WorkStoreStaffSalaryWeekList
         * @instance
         */
        WorkStoreStaffSalaryWeekList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkStoreStaffSalaryWeekList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreStaffSalaryWeekList
         * @static
         * @param {Proto.IWorkStoreStaffSalaryWeekList=} [properties] Properties to set
         * @returns {Proto.WorkStoreStaffSalaryWeekList} WorkStoreStaffSalaryWeekList instance
         */
        WorkStoreStaffSalaryWeekList.create = function create(properties) {
            return new WorkStoreStaffSalaryWeekList(properties);
        };

        /**
         * Encodes the specified WorkStoreStaffSalaryWeekList message. Does not implicitly {@link Proto.WorkStoreStaffSalaryWeekList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreStaffSalaryWeekList
         * @static
         * @param {Proto.IWorkStoreStaffSalaryWeekList} m WorkStoreStaffSalaryWeekList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreStaffSalaryWeekList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkStoreStaffSalaryWeek.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkStoreStaffSalaryWeekList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreStaffSalaryWeekList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreStaffSalaryWeekList} WorkStoreStaffSalaryWeekList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreStaffSalaryWeekList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreStaffSalaryWeekList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkStoreStaffSalaryWeek.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreStaffSalaryWeekList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreStaffSalaryWeekList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreStaffSalaryWeekList} WorkStoreStaffSalaryWeekList
         */
        WorkStoreStaffSalaryWeekList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreStaffSalaryWeekList)
                return d;
            var m = new $root.Proto.WorkStoreStaffSalaryWeekList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkStoreStaffSalaryWeekList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkStoreStaffSalaryWeekList.value: object expected");
                    m.value[i] = $root.Proto.WorkStoreStaffSalaryWeek.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreStaffSalaryWeekList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreStaffSalaryWeekList
         * @static
         * @param {Proto.WorkStoreStaffSalaryWeekList} m WorkStoreStaffSalaryWeekList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreStaffSalaryWeekList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkStoreStaffSalaryWeek.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkStoreStaffSalaryWeekList to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreStaffSalaryWeekList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreStaffSalaryWeekList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreStaffSalaryWeekList;
    })();

    Proto.WorkStoreStaffSalaryMonth = (function() {

        /**
         * Properties of a WorkStoreStaffSalaryMonth.
         * @memberof Proto
         * @interface IWorkStoreStaffSalaryMonth
         * @property {number|null} [expectDays] WorkStoreStaffSalaryMonth expectDays
         * @property {number|null} [workDays] WorkStoreStaffSalaryMonth workDays
         * @property {number|null} [saturdayDays] WorkStoreStaffSalaryMonth saturdayDays
         * @property {number|null} [sundayDays] WorkStoreStaffSalaryMonth sundayDays
         * @property {string|null} [cancelDays] WorkStoreStaffSalaryMonth cancelDays
         * @property {number|null} [delayDays] WorkStoreStaffSalaryMonth delayDays
         * @property {number|null} [earlyDays] WorkStoreStaffSalaryMonth earlyDays
         * @property {string|null} [overallDuration] WorkStoreStaffSalaryMonth overallDuration
         * @property {string|null} [totalDuration] WorkStoreStaffSalaryMonth totalDuration
         * @property {string|null} [duration] WorkStoreStaffSalaryMonth duration
         * @property {string|null} [expectDuration] WorkStoreStaffSalaryMonth expectDuration
         * @property {string|null} [expectRealDuration] WorkStoreStaffSalaryMonth expectRealDuration
         * @property {string|null} [additionalDuration] WorkStoreStaffSalaryMonth additionalDuration
         * @property {string|null} [additionalInLawAllDuration] WorkStoreStaffSalaryMonth additionalInLawAllDuration
         * @property {string|null} [additionalAllDuration] WorkStoreStaffSalaryMonth additionalAllDuration
         * @property {string|null} [saturdayDuration] WorkStoreStaffSalaryMonth saturdayDuration
         * @property {string|null} [sundayDuration] WorkStoreStaffSalaryMonth sundayDuration
         * @property {string|null} [lateDuration] WorkStoreStaffSalaryMonth lateDuration
         * @property {string|null} [delayDuration] WorkStoreStaffSalaryMonth delayDuration
         * @property {string|null} [earlyDuration] WorkStoreStaffSalaryMonth earlyDuration
         * @property {string|null} [restDuration] WorkStoreStaffSalaryMonth restDuration
         * @property {string|null} [shortageDuration] WorkStoreStaffSalaryMonth shortageDuration
         * @property {number|null} [holidayDays] WorkStoreStaffSalaryMonth holidayDays
         * @property {number|null} [work7Hours] WorkStoreStaffSalaryMonth work7Hours
         * @property {number|null} [work8Hours] WorkStoreStaffSalaryMonth work8Hours
         * @property {number|null} [work9Hours] WorkStoreStaffSalaryMonth work9Hours
         * @property {string|null} [salary] WorkStoreStaffSalaryMonth salary
         * @property {string|null} [additional] WorkStoreStaffSalaryMonth additional
         * @property {string|null} [transportationFee] WorkStoreStaffSalaryMonth transportationFee
         * @property {string|null} [totalSalary] WorkStoreStaffSalaryMonth totalSalary
         * @property {Proto.IWorkStoreStaffHolidayInfo|null} [holidayInfo] WorkStoreStaffSalaryMonth holidayInfo
         * @property {string|null} [currency] WorkStoreStaffSalaryMonth currency
         * @property {number|Long|null} [registerDate] WorkStoreStaffSalaryMonth registerDate
         * @property {number|Long|null} [modifyDate] WorkStoreStaffSalaryMonth modifyDate
         * @property {string|null} [date] WorkStoreStaffSalaryMonth date
         */

        /**
         * Constructs a new WorkStoreStaffSalaryMonth.
         * @memberof Proto
         * @classdesc Represents a WorkStoreStaffSalaryMonth.
         * @implements IWorkStoreStaffSalaryMonth
         * @constructor
         * @param {Proto.IWorkStoreStaffSalaryMonth=} [p] Properties to set
         */
        function WorkStoreStaffSalaryMonth(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreStaffSalaryMonth expectDays.
         * @member {number} expectDays
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.expectDays = 0;

        /**
         * WorkStoreStaffSalaryMonth workDays.
         * @member {number} workDays
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.workDays = 0;

        /**
         * WorkStoreStaffSalaryMonth saturdayDays.
         * @member {number} saturdayDays
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.saturdayDays = 0;

        /**
         * WorkStoreStaffSalaryMonth sundayDays.
         * @member {number} sundayDays
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.sundayDays = 0;

        /**
         * WorkStoreStaffSalaryMonth cancelDays.
         * @member {string} cancelDays
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.cancelDays = "";

        /**
         * WorkStoreStaffSalaryMonth delayDays.
         * @member {number} delayDays
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.delayDays = 0;

        /**
         * WorkStoreStaffSalaryMonth earlyDays.
         * @member {number} earlyDays
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.earlyDays = 0;

        /**
         * WorkStoreStaffSalaryMonth overallDuration.
         * @member {string} overallDuration
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.overallDuration = "";

        /**
         * WorkStoreStaffSalaryMonth totalDuration.
         * @member {string} totalDuration
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.totalDuration = "";

        /**
         * WorkStoreStaffSalaryMonth duration.
         * @member {string} duration
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.duration = "";

        /**
         * WorkStoreStaffSalaryMonth expectDuration.
         * @member {string} expectDuration
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.expectDuration = "";

        /**
         * WorkStoreStaffSalaryMonth expectRealDuration.
         * @member {string} expectRealDuration
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.expectRealDuration = "";

        /**
         * WorkStoreStaffSalaryMonth additionalDuration.
         * @member {string} additionalDuration
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.additionalDuration = "";

        /**
         * WorkStoreStaffSalaryMonth additionalInLawAllDuration.
         * @member {string} additionalInLawAllDuration
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.additionalInLawAllDuration = "";

        /**
         * WorkStoreStaffSalaryMonth additionalAllDuration.
         * @member {string} additionalAllDuration
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.additionalAllDuration = "";

        /**
         * WorkStoreStaffSalaryMonth saturdayDuration.
         * @member {string} saturdayDuration
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.saturdayDuration = "";

        /**
         * WorkStoreStaffSalaryMonth sundayDuration.
         * @member {string} sundayDuration
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.sundayDuration = "";

        /**
         * WorkStoreStaffSalaryMonth lateDuration.
         * @member {string} lateDuration
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.lateDuration = "";

        /**
         * WorkStoreStaffSalaryMonth delayDuration.
         * @member {string} delayDuration
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.delayDuration = "";

        /**
         * WorkStoreStaffSalaryMonth earlyDuration.
         * @member {string} earlyDuration
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.earlyDuration = "";

        /**
         * WorkStoreStaffSalaryMonth restDuration.
         * @member {string} restDuration
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.restDuration = "";

        /**
         * WorkStoreStaffSalaryMonth shortageDuration.
         * @member {string} shortageDuration
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.shortageDuration = "";

        /**
         * WorkStoreStaffSalaryMonth holidayDays.
         * @member {number} holidayDays
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.holidayDays = 0;

        /**
         * WorkStoreStaffSalaryMonth work7Hours.
         * @member {number} work7Hours
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.work7Hours = 0;

        /**
         * WorkStoreStaffSalaryMonth work8Hours.
         * @member {number} work8Hours
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.work8Hours = 0;

        /**
         * WorkStoreStaffSalaryMonth work9Hours.
         * @member {number} work9Hours
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.work9Hours = 0;

        /**
         * WorkStoreStaffSalaryMonth salary.
         * @member {string} salary
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.salary = "";

        /**
         * WorkStoreStaffSalaryMonth additional.
         * @member {string} additional
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.additional = "";

        /**
         * WorkStoreStaffSalaryMonth transportationFee.
         * @member {string} transportationFee
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.transportationFee = "";

        /**
         * WorkStoreStaffSalaryMonth totalSalary.
         * @member {string} totalSalary
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.totalSalary = "";

        /**
         * WorkStoreStaffSalaryMonth holidayInfo.
         * @member {Proto.IWorkStoreStaffHolidayInfo|null|undefined} holidayInfo
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.holidayInfo = null;

        /**
         * WorkStoreStaffSalaryMonth currency.
         * @member {string} currency
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.currency = "";

        /**
         * WorkStoreStaffSalaryMonth registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreStaffSalaryMonth modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreStaffSalaryMonth date.
         * @member {string} date
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         */
        WorkStoreStaffSalaryMonth.prototype.date = "";

        /**
         * Creates a new WorkStoreStaffSalaryMonth instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @static
         * @param {Proto.IWorkStoreStaffSalaryMonth=} [properties] Properties to set
         * @returns {Proto.WorkStoreStaffSalaryMonth} WorkStoreStaffSalaryMonth instance
         */
        WorkStoreStaffSalaryMonth.create = function create(properties) {
            return new WorkStoreStaffSalaryMonth(properties);
        };

        /**
         * Encodes the specified WorkStoreStaffSalaryMonth message. Does not implicitly {@link Proto.WorkStoreStaffSalaryMonth.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @static
         * @param {Proto.IWorkStoreStaffSalaryMonth} m WorkStoreStaffSalaryMonth message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreStaffSalaryMonth.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.expectDays != null && Object.hasOwnProperty.call(m, "expectDays"))
                w.uint32(8).int32(m.expectDays);
            if (m.workDays != null && Object.hasOwnProperty.call(m, "workDays"))
                w.uint32(16).int32(m.workDays);
            if (m.saturdayDays != null && Object.hasOwnProperty.call(m, "saturdayDays"))
                w.uint32(24).int32(m.saturdayDays);
            if (m.sundayDays != null && Object.hasOwnProperty.call(m, "sundayDays"))
                w.uint32(32).int32(m.sundayDays);
            if (m.cancelDays != null && Object.hasOwnProperty.call(m, "cancelDays"))
                w.uint32(42).string(m.cancelDays);
            if (m.delayDays != null && Object.hasOwnProperty.call(m, "delayDays"))
                w.uint32(48).int32(m.delayDays);
            if (m.earlyDays != null && Object.hasOwnProperty.call(m, "earlyDays"))
                w.uint32(56).int32(m.earlyDays);
            if (m.overallDuration != null && Object.hasOwnProperty.call(m, "overallDuration"))
                w.uint32(66).string(m.overallDuration);
            if (m.totalDuration != null && Object.hasOwnProperty.call(m, "totalDuration"))
                w.uint32(74).string(m.totalDuration);
            if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                w.uint32(82).string(m.duration);
            if (m.expectDuration != null && Object.hasOwnProperty.call(m, "expectDuration"))
                w.uint32(90).string(m.expectDuration);
            if (m.expectRealDuration != null && Object.hasOwnProperty.call(m, "expectRealDuration"))
                w.uint32(98).string(m.expectRealDuration);
            if (m.additionalDuration != null && Object.hasOwnProperty.call(m, "additionalDuration"))
                w.uint32(106).string(m.additionalDuration);
            if (m.additionalInLawAllDuration != null && Object.hasOwnProperty.call(m, "additionalInLawAllDuration"))
                w.uint32(114).string(m.additionalInLawAllDuration);
            if (m.additionalAllDuration != null && Object.hasOwnProperty.call(m, "additionalAllDuration"))
                w.uint32(122).string(m.additionalAllDuration);
            if (m.saturdayDuration != null && Object.hasOwnProperty.call(m, "saturdayDuration"))
                w.uint32(130).string(m.saturdayDuration);
            if (m.sundayDuration != null && Object.hasOwnProperty.call(m, "sundayDuration"))
                w.uint32(138).string(m.sundayDuration);
            if (m.lateDuration != null && Object.hasOwnProperty.call(m, "lateDuration"))
                w.uint32(146).string(m.lateDuration);
            if (m.delayDuration != null && Object.hasOwnProperty.call(m, "delayDuration"))
                w.uint32(154).string(m.delayDuration);
            if (m.earlyDuration != null && Object.hasOwnProperty.call(m, "earlyDuration"))
                w.uint32(162).string(m.earlyDuration);
            if (m.restDuration != null && Object.hasOwnProperty.call(m, "restDuration"))
                w.uint32(170).string(m.restDuration);
            if (m.shortageDuration != null && Object.hasOwnProperty.call(m, "shortageDuration"))
                w.uint32(178).string(m.shortageDuration);
            if (m.holidayDays != null && Object.hasOwnProperty.call(m, "holidayDays"))
                w.uint32(184).int32(m.holidayDays);
            if (m.work7Hours != null && Object.hasOwnProperty.call(m, "work7Hours"))
                w.uint32(192).int32(m.work7Hours);
            if (m.work8Hours != null && Object.hasOwnProperty.call(m, "work8Hours"))
                w.uint32(200).int32(m.work8Hours);
            if (m.work9Hours != null && Object.hasOwnProperty.call(m, "work9Hours"))
                w.uint32(208).int32(m.work9Hours);
            if (m.salary != null && Object.hasOwnProperty.call(m, "salary"))
                w.uint32(218).string(m.salary);
            if (m.additional != null && Object.hasOwnProperty.call(m, "additional"))
                w.uint32(226).string(m.additional);
            if (m.transportationFee != null && Object.hasOwnProperty.call(m, "transportationFee"))
                w.uint32(234).string(m.transportationFee);
            if (m.totalSalary != null && Object.hasOwnProperty.call(m, "totalSalary"))
                w.uint32(242).string(m.totalSalary);
            if (m.holidayInfo != null && Object.hasOwnProperty.call(m, "holidayInfo"))
                $root.Proto.WorkStoreStaffHolidayInfo.encode(m.holidayInfo, w.uint32(250).fork()).ldelim();
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(258).string(m.currency);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(328).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(336).int64(m.modifyDate);
            if (m.date != null && Object.hasOwnProperty.call(m, "date"))
                w.uint32(346).string(m.date);
            return w;
        };

        /**
         * Decodes a WorkStoreStaffSalaryMonth message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreStaffSalaryMonth} WorkStoreStaffSalaryMonth
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreStaffSalaryMonth.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreStaffSalaryMonth();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.expectDays = r.int32();
                    break;
                case 2:
                    m.workDays = r.int32();
                    break;
                case 3:
                    m.saturdayDays = r.int32();
                    break;
                case 4:
                    m.sundayDays = r.int32();
                    break;
                case 5:
                    m.cancelDays = r.string();
                    break;
                case 6:
                    m.delayDays = r.int32();
                    break;
                case 7:
                    m.earlyDays = r.int32();
                    break;
                case 8:
                    m.overallDuration = r.string();
                    break;
                case 9:
                    m.totalDuration = r.string();
                    break;
                case 10:
                    m.duration = r.string();
                    break;
                case 11:
                    m.expectDuration = r.string();
                    break;
                case 12:
                    m.expectRealDuration = r.string();
                    break;
                case 13:
                    m.additionalDuration = r.string();
                    break;
                case 14:
                    m.additionalInLawAllDuration = r.string();
                    break;
                case 15:
                    m.additionalAllDuration = r.string();
                    break;
                case 16:
                    m.saturdayDuration = r.string();
                    break;
                case 17:
                    m.sundayDuration = r.string();
                    break;
                case 18:
                    m.lateDuration = r.string();
                    break;
                case 19:
                    m.delayDuration = r.string();
                    break;
                case 20:
                    m.earlyDuration = r.string();
                    break;
                case 21:
                    m.restDuration = r.string();
                    break;
                case 22:
                    m.shortageDuration = r.string();
                    break;
                case 23:
                    m.holidayDays = r.int32();
                    break;
                case 24:
                    m.work7Hours = r.int32();
                    break;
                case 25:
                    m.work8Hours = r.int32();
                    break;
                case 26:
                    m.work9Hours = r.int32();
                    break;
                case 27:
                    m.salary = r.string();
                    break;
                case 28:
                    m.additional = r.string();
                    break;
                case 29:
                    m.transportationFee = r.string();
                    break;
                case 30:
                    m.totalSalary = r.string();
                    break;
                case 31:
                    m.holidayInfo = $root.Proto.WorkStoreStaffHolidayInfo.decode(r, r.uint32());
                    break;
                case 32:
                    m.currency = r.string();
                    break;
                case 41:
                    m.registerDate = r.int64();
                    break;
                case 42:
                    m.modifyDate = r.int64();
                    break;
                case 43:
                    m.date = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreStaffSalaryMonth message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreStaffSalaryMonth} WorkStoreStaffSalaryMonth
         */
        WorkStoreStaffSalaryMonth.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreStaffSalaryMonth)
                return d;
            var m = new $root.Proto.WorkStoreStaffSalaryMonth();
            if (d.expectDays != null) {
                m.expectDays = d.expectDays | 0;
            }
            if (d.workDays != null) {
                m.workDays = d.workDays | 0;
            }
            if (d.saturdayDays != null) {
                m.saturdayDays = d.saturdayDays | 0;
            }
            if (d.sundayDays != null) {
                m.sundayDays = d.sundayDays | 0;
            }
            if (d.cancelDays != null) {
                m.cancelDays = String(d.cancelDays);
            }
            if (d.delayDays != null) {
                m.delayDays = d.delayDays | 0;
            }
            if (d.earlyDays != null) {
                m.earlyDays = d.earlyDays | 0;
            }
            if (d.overallDuration != null) {
                m.overallDuration = String(d.overallDuration);
            }
            if (d.totalDuration != null) {
                m.totalDuration = String(d.totalDuration);
            }
            if (d.duration != null) {
                m.duration = String(d.duration);
            }
            if (d.expectDuration != null) {
                m.expectDuration = String(d.expectDuration);
            }
            if (d.expectRealDuration != null) {
                m.expectRealDuration = String(d.expectRealDuration);
            }
            if (d.additionalDuration != null) {
                m.additionalDuration = String(d.additionalDuration);
            }
            if (d.additionalInLawAllDuration != null) {
                m.additionalInLawAllDuration = String(d.additionalInLawAllDuration);
            }
            if (d.additionalAllDuration != null) {
                m.additionalAllDuration = String(d.additionalAllDuration);
            }
            if (d.saturdayDuration != null) {
                m.saturdayDuration = String(d.saturdayDuration);
            }
            if (d.sundayDuration != null) {
                m.sundayDuration = String(d.sundayDuration);
            }
            if (d.lateDuration != null) {
                m.lateDuration = String(d.lateDuration);
            }
            if (d.delayDuration != null) {
                m.delayDuration = String(d.delayDuration);
            }
            if (d.earlyDuration != null) {
                m.earlyDuration = String(d.earlyDuration);
            }
            if (d.restDuration != null) {
                m.restDuration = String(d.restDuration);
            }
            if (d.shortageDuration != null) {
                m.shortageDuration = String(d.shortageDuration);
            }
            if (d.holidayDays != null) {
                m.holidayDays = d.holidayDays | 0;
            }
            if (d.work7Hours != null) {
                m.work7Hours = d.work7Hours | 0;
            }
            if (d.work8Hours != null) {
                m.work8Hours = d.work8Hours | 0;
            }
            if (d.work9Hours != null) {
                m.work9Hours = d.work9Hours | 0;
            }
            if (d.salary != null) {
                m.salary = String(d.salary);
            }
            if (d.additional != null) {
                m.additional = String(d.additional);
            }
            if (d.transportationFee != null) {
                m.transportationFee = String(d.transportationFee);
            }
            if (d.totalSalary != null) {
                m.totalSalary = String(d.totalSalary);
            }
            if (d.holidayInfo != null) {
                if (typeof d.holidayInfo !== "object")
                    throw TypeError(".Proto.WorkStoreStaffSalaryMonth.holidayInfo: object expected");
                m.holidayInfo = $root.Proto.WorkStoreStaffHolidayInfo.fromObject(d.holidayInfo);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.date != null) {
                m.date = String(d.date);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreStaffSalaryMonth message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @static
         * @param {Proto.WorkStoreStaffSalaryMonth} m WorkStoreStaffSalaryMonth
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreStaffSalaryMonth.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.expectDays = 0;
                d.workDays = 0;
                d.saturdayDays = 0;
                d.sundayDays = 0;
                d.cancelDays = "";
                d.delayDays = 0;
                d.earlyDays = 0;
                d.overallDuration = "";
                d.totalDuration = "";
                d.duration = "";
                d.expectDuration = "";
                d.expectRealDuration = "";
                d.additionalDuration = "";
                d.additionalInLawAllDuration = "";
                d.additionalAllDuration = "";
                d.saturdayDuration = "";
                d.sundayDuration = "";
                d.lateDuration = "";
                d.delayDuration = "";
                d.earlyDuration = "";
                d.restDuration = "";
                d.shortageDuration = "";
                d.holidayDays = 0;
                d.work7Hours = 0;
                d.work8Hours = 0;
                d.work9Hours = 0;
                d.salary = "";
                d.additional = "";
                d.transportationFee = "";
                d.totalSalary = "";
                d.holidayInfo = null;
                d.currency = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.date = "";
            }
            if (m.expectDays != null && m.hasOwnProperty("expectDays")) {
                d.expectDays = m.expectDays;
            }
            if (m.workDays != null && m.hasOwnProperty("workDays")) {
                d.workDays = m.workDays;
            }
            if (m.saturdayDays != null && m.hasOwnProperty("saturdayDays")) {
                d.saturdayDays = m.saturdayDays;
            }
            if (m.sundayDays != null && m.hasOwnProperty("sundayDays")) {
                d.sundayDays = m.sundayDays;
            }
            if (m.cancelDays != null && m.hasOwnProperty("cancelDays")) {
                d.cancelDays = m.cancelDays;
            }
            if (m.delayDays != null && m.hasOwnProperty("delayDays")) {
                d.delayDays = m.delayDays;
            }
            if (m.earlyDays != null && m.hasOwnProperty("earlyDays")) {
                d.earlyDays = m.earlyDays;
            }
            if (m.overallDuration != null && m.hasOwnProperty("overallDuration")) {
                d.overallDuration = m.overallDuration;
            }
            if (m.totalDuration != null && m.hasOwnProperty("totalDuration")) {
                d.totalDuration = m.totalDuration;
            }
            if (m.duration != null && m.hasOwnProperty("duration")) {
                d.duration = m.duration;
            }
            if (m.expectDuration != null && m.hasOwnProperty("expectDuration")) {
                d.expectDuration = m.expectDuration;
            }
            if (m.expectRealDuration != null && m.hasOwnProperty("expectRealDuration")) {
                d.expectRealDuration = m.expectRealDuration;
            }
            if (m.additionalDuration != null && m.hasOwnProperty("additionalDuration")) {
                d.additionalDuration = m.additionalDuration;
            }
            if (m.additionalInLawAllDuration != null && m.hasOwnProperty("additionalInLawAllDuration")) {
                d.additionalInLawAllDuration = m.additionalInLawAllDuration;
            }
            if (m.additionalAllDuration != null && m.hasOwnProperty("additionalAllDuration")) {
                d.additionalAllDuration = m.additionalAllDuration;
            }
            if (m.saturdayDuration != null && m.hasOwnProperty("saturdayDuration")) {
                d.saturdayDuration = m.saturdayDuration;
            }
            if (m.sundayDuration != null && m.hasOwnProperty("sundayDuration")) {
                d.sundayDuration = m.sundayDuration;
            }
            if (m.lateDuration != null && m.hasOwnProperty("lateDuration")) {
                d.lateDuration = m.lateDuration;
            }
            if (m.delayDuration != null && m.hasOwnProperty("delayDuration")) {
                d.delayDuration = m.delayDuration;
            }
            if (m.earlyDuration != null && m.hasOwnProperty("earlyDuration")) {
                d.earlyDuration = m.earlyDuration;
            }
            if (m.restDuration != null && m.hasOwnProperty("restDuration")) {
                d.restDuration = m.restDuration;
            }
            if (m.shortageDuration != null && m.hasOwnProperty("shortageDuration")) {
                d.shortageDuration = m.shortageDuration;
            }
            if (m.holidayDays != null && m.hasOwnProperty("holidayDays")) {
                d.holidayDays = m.holidayDays;
            }
            if (m.work7Hours != null && m.hasOwnProperty("work7Hours")) {
                d.work7Hours = m.work7Hours;
            }
            if (m.work8Hours != null && m.hasOwnProperty("work8Hours")) {
                d.work8Hours = m.work8Hours;
            }
            if (m.work9Hours != null && m.hasOwnProperty("work9Hours")) {
                d.work9Hours = m.work9Hours;
            }
            if (m.salary != null && m.hasOwnProperty("salary")) {
                d.salary = m.salary;
            }
            if (m.additional != null && m.hasOwnProperty("additional")) {
                d.additional = m.additional;
            }
            if (m.transportationFee != null && m.hasOwnProperty("transportationFee")) {
                d.transportationFee = m.transportationFee;
            }
            if (m.totalSalary != null && m.hasOwnProperty("totalSalary")) {
                d.totalSalary = m.totalSalary;
            }
            if (m.holidayInfo != null && m.hasOwnProperty("holidayInfo")) {
                d.holidayInfo = $root.Proto.WorkStoreStaffHolidayInfo.toObject(m.holidayInfo, o);
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.date != null && m.hasOwnProperty("date")) {
                d.date = m.date;
            }
            return d;
        };

        /**
         * Converts this WorkStoreStaffSalaryMonth to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreStaffSalaryMonth
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreStaffSalaryMonth.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreStaffSalaryMonth;
    })();

    Proto.WorkStoreStaffSalaryMonthList = (function() {

        /**
         * Properties of a WorkStoreStaffSalaryMonthList.
         * @memberof Proto
         * @interface IWorkStoreStaffSalaryMonthList
         * @property {Array.<Proto.IWorkStoreStaffSalaryMonth>|null} [value] WorkStoreStaffSalaryMonthList value
         */

        /**
         * Constructs a new WorkStoreStaffSalaryMonthList.
         * @memberof Proto
         * @classdesc Represents a WorkStoreStaffSalaryMonthList.
         * @implements IWorkStoreStaffSalaryMonthList
         * @constructor
         * @param {Proto.IWorkStoreStaffSalaryMonthList=} [p] Properties to set
         */
        function WorkStoreStaffSalaryMonthList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreStaffSalaryMonthList value.
         * @member {Array.<Proto.IWorkStoreStaffSalaryMonth>} value
         * @memberof Proto.WorkStoreStaffSalaryMonthList
         * @instance
         */
        WorkStoreStaffSalaryMonthList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkStoreStaffSalaryMonthList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreStaffSalaryMonthList
         * @static
         * @param {Proto.IWorkStoreStaffSalaryMonthList=} [properties] Properties to set
         * @returns {Proto.WorkStoreStaffSalaryMonthList} WorkStoreStaffSalaryMonthList instance
         */
        WorkStoreStaffSalaryMonthList.create = function create(properties) {
            return new WorkStoreStaffSalaryMonthList(properties);
        };

        /**
         * Encodes the specified WorkStoreStaffSalaryMonthList message. Does not implicitly {@link Proto.WorkStoreStaffSalaryMonthList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreStaffSalaryMonthList
         * @static
         * @param {Proto.IWorkStoreStaffSalaryMonthList} m WorkStoreStaffSalaryMonthList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreStaffSalaryMonthList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkStoreStaffSalaryMonth.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkStoreStaffSalaryMonthList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreStaffSalaryMonthList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreStaffSalaryMonthList} WorkStoreStaffSalaryMonthList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreStaffSalaryMonthList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreStaffSalaryMonthList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkStoreStaffSalaryMonth.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreStaffSalaryMonthList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreStaffSalaryMonthList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreStaffSalaryMonthList} WorkStoreStaffSalaryMonthList
         */
        WorkStoreStaffSalaryMonthList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreStaffSalaryMonthList)
                return d;
            var m = new $root.Proto.WorkStoreStaffSalaryMonthList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkStoreStaffSalaryMonthList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkStoreStaffSalaryMonthList.value: object expected");
                    m.value[i] = $root.Proto.WorkStoreStaffSalaryMonth.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreStaffSalaryMonthList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreStaffSalaryMonthList
         * @static
         * @param {Proto.WorkStoreStaffSalaryMonthList} m WorkStoreStaffSalaryMonthList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreStaffSalaryMonthList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkStoreStaffSalaryMonth.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkStoreStaffSalaryMonthList to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreStaffSalaryMonthList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreStaffSalaryMonthList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreStaffSalaryMonthList;
    })();

    Proto.WorkStoreStaffHolidayInfo = (function() {

        /**
         * Properties of a WorkStoreStaffHolidayInfo.
         * @memberof Proto
         * @interface IWorkStoreStaffHolidayInfo
         * @property {string|null} [nationalHolidayDays] WorkStoreStaffHolidayInfo nationalHolidayDays
         * @property {string|null} [vacationDays] WorkStoreStaffHolidayInfo vacationDays
         * @property {string|null} [remainVacationDays] WorkStoreStaffHolidayInfo remainVacationDays
         * @property {string|null} [transferredVacationDays] WorkStoreStaffHolidayInfo transferredVacationDays
         * @property {string|null} [remainTransferredVacationDays] WorkStoreStaffHolidayInfo remainTransferredVacationDays
         * @property {string|null} [compVacationDays] WorkStoreStaffHolidayInfo compVacationDays
         * @property {string|null} [remainCompVacationDays] WorkStoreStaffHolidayInfo remainCompVacationDays
         * @property {string|null} [additionalVacationDays] WorkStoreStaffHolidayInfo additionalVacationDays
         */

        /**
         * Constructs a new WorkStoreStaffHolidayInfo.
         * @memberof Proto
         * @classdesc Represents a WorkStoreStaffHolidayInfo.
         * @implements IWorkStoreStaffHolidayInfo
         * @constructor
         * @param {Proto.IWorkStoreStaffHolidayInfo=} [p] Properties to set
         */
        function WorkStoreStaffHolidayInfo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreStaffHolidayInfo nationalHolidayDays.
         * @member {string} nationalHolidayDays
         * @memberof Proto.WorkStoreStaffHolidayInfo
         * @instance
         */
        WorkStoreStaffHolidayInfo.prototype.nationalHolidayDays = "";

        /**
         * WorkStoreStaffHolidayInfo vacationDays.
         * @member {string} vacationDays
         * @memberof Proto.WorkStoreStaffHolidayInfo
         * @instance
         */
        WorkStoreStaffHolidayInfo.prototype.vacationDays = "";

        /**
         * WorkStoreStaffHolidayInfo remainVacationDays.
         * @member {string} remainVacationDays
         * @memberof Proto.WorkStoreStaffHolidayInfo
         * @instance
         */
        WorkStoreStaffHolidayInfo.prototype.remainVacationDays = "";

        /**
         * WorkStoreStaffHolidayInfo transferredVacationDays.
         * @member {string} transferredVacationDays
         * @memberof Proto.WorkStoreStaffHolidayInfo
         * @instance
         */
        WorkStoreStaffHolidayInfo.prototype.transferredVacationDays = "";

        /**
         * WorkStoreStaffHolidayInfo remainTransferredVacationDays.
         * @member {string} remainTransferredVacationDays
         * @memberof Proto.WorkStoreStaffHolidayInfo
         * @instance
         */
        WorkStoreStaffHolidayInfo.prototype.remainTransferredVacationDays = "";

        /**
         * WorkStoreStaffHolidayInfo compVacationDays.
         * @member {string} compVacationDays
         * @memberof Proto.WorkStoreStaffHolidayInfo
         * @instance
         */
        WorkStoreStaffHolidayInfo.prototype.compVacationDays = "";

        /**
         * WorkStoreStaffHolidayInfo remainCompVacationDays.
         * @member {string} remainCompVacationDays
         * @memberof Proto.WorkStoreStaffHolidayInfo
         * @instance
         */
        WorkStoreStaffHolidayInfo.prototype.remainCompVacationDays = "";

        /**
         * WorkStoreStaffHolidayInfo additionalVacationDays.
         * @member {string} additionalVacationDays
         * @memberof Proto.WorkStoreStaffHolidayInfo
         * @instance
         */
        WorkStoreStaffHolidayInfo.prototype.additionalVacationDays = "";

        /**
         * Creates a new WorkStoreStaffHolidayInfo instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreStaffHolidayInfo
         * @static
         * @param {Proto.IWorkStoreStaffHolidayInfo=} [properties] Properties to set
         * @returns {Proto.WorkStoreStaffHolidayInfo} WorkStoreStaffHolidayInfo instance
         */
        WorkStoreStaffHolidayInfo.create = function create(properties) {
            return new WorkStoreStaffHolidayInfo(properties);
        };

        /**
         * Encodes the specified WorkStoreStaffHolidayInfo message. Does not implicitly {@link Proto.WorkStoreStaffHolidayInfo.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreStaffHolidayInfo
         * @static
         * @param {Proto.IWorkStoreStaffHolidayInfo} m WorkStoreStaffHolidayInfo message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreStaffHolidayInfo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.nationalHolidayDays != null && Object.hasOwnProperty.call(m, "nationalHolidayDays"))
                w.uint32(10).string(m.nationalHolidayDays);
            if (m.vacationDays != null && Object.hasOwnProperty.call(m, "vacationDays"))
                w.uint32(18).string(m.vacationDays);
            if (m.remainVacationDays != null && Object.hasOwnProperty.call(m, "remainVacationDays"))
                w.uint32(26).string(m.remainVacationDays);
            if (m.transferredVacationDays != null && Object.hasOwnProperty.call(m, "transferredVacationDays"))
                w.uint32(34).string(m.transferredVacationDays);
            if (m.remainTransferredVacationDays != null && Object.hasOwnProperty.call(m, "remainTransferredVacationDays"))
                w.uint32(42).string(m.remainTransferredVacationDays);
            if (m.compVacationDays != null && Object.hasOwnProperty.call(m, "compVacationDays"))
                w.uint32(50).string(m.compVacationDays);
            if (m.remainCompVacationDays != null && Object.hasOwnProperty.call(m, "remainCompVacationDays"))
                w.uint32(58).string(m.remainCompVacationDays);
            if (m.additionalVacationDays != null && Object.hasOwnProperty.call(m, "additionalVacationDays"))
                w.uint32(66).string(m.additionalVacationDays);
            return w;
        };

        /**
         * Decodes a WorkStoreStaffHolidayInfo message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreStaffHolidayInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreStaffHolidayInfo} WorkStoreStaffHolidayInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreStaffHolidayInfo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreStaffHolidayInfo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.nationalHolidayDays = r.string();
                    break;
                case 2:
                    m.vacationDays = r.string();
                    break;
                case 3:
                    m.remainVacationDays = r.string();
                    break;
                case 4:
                    m.transferredVacationDays = r.string();
                    break;
                case 5:
                    m.remainTransferredVacationDays = r.string();
                    break;
                case 6:
                    m.compVacationDays = r.string();
                    break;
                case 7:
                    m.remainCompVacationDays = r.string();
                    break;
                case 8:
                    m.additionalVacationDays = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreStaffHolidayInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreStaffHolidayInfo
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreStaffHolidayInfo} WorkStoreStaffHolidayInfo
         */
        WorkStoreStaffHolidayInfo.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreStaffHolidayInfo)
                return d;
            var m = new $root.Proto.WorkStoreStaffHolidayInfo();
            if (d.nationalHolidayDays != null) {
                m.nationalHolidayDays = String(d.nationalHolidayDays);
            }
            if (d.vacationDays != null) {
                m.vacationDays = String(d.vacationDays);
            }
            if (d.remainVacationDays != null) {
                m.remainVacationDays = String(d.remainVacationDays);
            }
            if (d.transferredVacationDays != null) {
                m.transferredVacationDays = String(d.transferredVacationDays);
            }
            if (d.remainTransferredVacationDays != null) {
                m.remainTransferredVacationDays = String(d.remainTransferredVacationDays);
            }
            if (d.compVacationDays != null) {
                m.compVacationDays = String(d.compVacationDays);
            }
            if (d.remainCompVacationDays != null) {
                m.remainCompVacationDays = String(d.remainCompVacationDays);
            }
            if (d.additionalVacationDays != null) {
                m.additionalVacationDays = String(d.additionalVacationDays);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreStaffHolidayInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreStaffHolidayInfo
         * @static
         * @param {Proto.WorkStoreStaffHolidayInfo} m WorkStoreStaffHolidayInfo
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreStaffHolidayInfo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.nationalHolidayDays = "";
                d.vacationDays = "";
                d.remainVacationDays = "";
                d.transferredVacationDays = "";
                d.remainTransferredVacationDays = "";
                d.compVacationDays = "";
                d.remainCompVacationDays = "";
                d.additionalVacationDays = "";
            }
            if (m.nationalHolidayDays != null && m.hasOwnProperty("nationalHolidayDays")) {
                d.nationalHolidayDays = m.nationalHolidayDays;
            }
            if (m.vacationDays != null && m.hasOwnProperty("vacationDays")) {
                d.vacationDays = m.vacationDays;
            }
            if (m.remainVacationDays != null && m.hasOwnProperty("remainVacationDays")) {
                d.remainVacationDays = m.remainVacationDays;
            }
            if (m.transferredVacationDays != null && m.hasOwnProperty("transferredVacationDays")) {
                d.transferredVacationDays = m.transferredVacationDays;
            }
            if (m.remainTransferredVacationDays != null && m.hasOwnProperty("remainTransferredVacationDays")) {
                d.remainTransferredVacationDays = m.remainTransferredVacationDays;
            }
            if (m.compVacationDays != null && m.hasOwnProperty("compVacationDays")) {
                d.compVacationDays = m.compVacationDays;
            }
            if (m.remainCompVacationDays != null && m.hasOwnProperty("remainCompVacationDays")) {
                d.remainCompVacationDays = m.remainCompVacationDays;
            }
            if (m.additionalVacationDays != null && m.hasOwnProperty("additionalVacationDays")) {
                d.additionalVacationDays = m.additionalVacationDays;
            }
            return d;
        };

        /**
         * Converts this WorkStoreStaffHolidayInfo to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreStaffHolidayInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreStaffHolidayInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreStaffHolidayInfo;
    })();

    Proto.WorkStoreStaffSalaryTotal = (function() {

        /**
         * Properties of a WorkStoreStaffSalaryTotal.
         * @memberof Proto
         * @interface IWorkStoreStaffSalaryTotal
         * @property {Proto.IWorkStore|null} [store] WorkStoreStaffSalaryTotal store
         * @property {string|null} [duration] WorkStoreStaffSalaryTotal duration
         * @property {string|null} [expectSalary] WorkStoreStaffSalaryTotal expectSalary
         * @property {string|null} [salary] WorkStoreStaffSalaryTotal salary
         * @property {string|null} [currency] WorkStoreStaffSalaryTotal currency
         * @property {string|null} [transportationFee] WorkStoreStaffSalaryTotal transportationFee
         */

        /**
         * Constructs a new WorkStoreStaffSalaryTotal.
         * @memberof Proto
         * @classdesc Represents a WorkStoreStaffSalaryTotal.
         * @implements IWorkStoreStaffSalaryTotal
         * @constructor
         * @param {Proto.IWorkStoreStaffSalaryTotal=} [p] Properties to set
         */
        function WorkStoreStaffSalaryTotal(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreStaffSalaryTotal store.
         * @member {Proto.IWorkStore|null|undefined} store
         * @memberof Proto.WorkStoreStaffSalaryTotal
         * @instance
         */
        WorkStoreStaffSalaryTotal.prototype.store = null;

        /**
         * WorkStoreStaffSalaryTotal duration.
         * @member {string} duration
         * @memberof Proto.WorkStoreStaffSalaryTotal
         * @instance
         */
        WorkStoreStaffSalaryTotal.prototype.duration = "";

        /**
         * WorkStoreStaffSalaryTotal expectSalary.
         * @member {string} expectSalary
         * @memberof Proto.WorkStoreStaffSalaryTotal
         * @instance
         */
        WorkStoreStaffSalaryTotal.prototype.expectSalary = "";

        /**
         * WorkStoreStaffSalaryTotal salary.
         * @member {string} salary
         * @memberof Proto.WorkStoreStaffSalaryTotal
         * @instance
         */
        WorkStoreStaffSalaryTotal.prototype.salary = "";

        /**
         * WorkStoreStaffSalaryTotal currency.
         * @member {string} currency
         * @memberof Proto.WorkStoreStaffSalaryTotal
         * @instance
         */
        WorkStoreStaffSalaryTotal.prototype.currency = "";

        /**
         * WorkStoreStaffSalaryTotal transportationFee.
         * @member {string} transportationFee
         * @memberof Proto.WorkStoreStaffSalaryTotal
         * @instance
         */
        WorkStoreStaffSalaryTotal.prototype.transportationFee = "";

        /**
         * Creates a new WorkStoreStaffSalaryTotal instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreStaffSalaryTotal
         * @static
         * @param {Proto.IWorkStoreStaffSalaryTotal=} [properties] Properties to set
         * @returns {Proto.WorkStoreStaffSalaryTotal} WorkStoreStaffSalaryTotal instance
         */
        WorkStoreStaffSalaryTotal.create = function create(properties) {
            return new WorkStoreStaffSalaryTotal(properties);
        };

        /**
         * Encodes the specified WorkStoreStaffSalaryTotal message. Does not implicitly {@link Proto.WorkStoreStaffSalaryTotal.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreStaffSalaryTotal
         * @static
         * @param {Proto.IWorkStoreStaffSalaryTotal} m WorkStoreStaffSalaryTotal message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreStaffSalaryTotal.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.store != null && Object.hasOwnProperty.call(m, "store"))
                $root.Proto.WorkStore.encode(m.store, w.uint32(10).fork()).ldelim();
            if (m.duration != null && Object.hasOwnProperty.call(m, "duration"))
                w.uint32(18).string(m.duration);
            if (m.expectSalary != null && Object.hasOwnProperty.call(m, "expectSalary"))
                w.uint32(26).string(m.expectSalary);
            if (m.salary != null && Object.hasOwnProperty.call(m, "salary"))
                w.uint32(34).string(m.salary);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(42).string(m.currency);
            if (m.transportationFee != null && Object.hasOwnProperty.call(m, "transportationFee"))
                w.uint32(50).string(m.transportationFee);
            return w;
        };

        /**
         * Decodes a WorkStoreStaffSalaryTotal message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreStaffSalaryTotal
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreStaffSalaryTotal} WorkStoreStaffSalaryTotal
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreStaffSalaryTotal.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreStaffSalaryTotal();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.store = $root.Proto.WorkStore.decode(r, r.uint32());
                    break;
                case 2:
                    m.duration = r.string();
                    break;
                case 3:
                    m.expectSalary = r.string();
                    break;
                case 4:
                    m.salary = r.string();
                    break;
                case 5:
                    m.currency = r.string();
                    break;
                case 6:
                    m.transportationFee = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreStaffSalaryTotal message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreStaffSalaryTotal
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreStaffSalaryTotal} WorkStoreStaffSalaryTotal
         */
        WorkStoreStaffSalaryTotal.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreStaffSalaryTotal)
                return d;
            var m = new $root.Proto.WorkStoreStaffSalaryTotal();
            if (d.store != null) {
                if (typeof d.store !== "object")
                    throw TypeError(".Proto.WorkStoreStaffSalaryTotal.store: object expected");
                m.store = $root.Proto.WorkStore.fromObject(d.store);
            }
            if (d.duration != null) {
                m.duration = String(d.duration);
            }
            if (d.expectSalary != null) {
                m.expectSalary = String(d.expectSalary);
            }
            if (d.salary != null) {
                m.salary = String(d.salary);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.transportationFee != null) {
                m.transportationFee = String(d.transportationFee);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreStaffSalaryTotal message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreStaffSalaryTotal
         * @static
         * @param {Proto.WorkStoreStaffSalaryTotal} m WorkStoreStaffSalaryTotal
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreStaffSalaryTotal.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.store = null;
                d.duration = "";
                d.expectSalary = "";
                d.salary = "";
                d.currency = "";
                d.transportationFee = "";
            }
            if (m.store != null && m.hasOwnProperty("store")) {
                d.store = $root.Proto.WorkStore.toObject(m.store, o);
            }
            if (m.duration != null && m.hasOwnProperty("duration")) {
                d.duration = m.duration;
            }
            if (m.expectSalary != null && m.hasOwnProperty("expectSalary")) {
                d.expectSalary = m.expectSalary;
            }
            if (m.salary != null && m.hasOwnProperty("salary")) {
                d.salary = m.salary;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.transportationFee != null && m.hasOwnProperty("transportationFee")) {
                d.transportationFee = m.transportationFee;
            }
            return d;
        };

        /**
         * Converts this WorkStoreStaffSalaryTotal to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreStaffSalaryTotal
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreStaffSalaryTotal.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreStaffSalaryTotal;
    })();

    Proto.WorkStoreStaffSalaryTotalList = (function() {

        /**
         * Properties of a WorkStoreStaffSalaryTotalList.
         * @memberof Proto
         * @interface IWorkStoreStaffSalaryTotalList
         * @property {Array.<Proto.IWorkStoreStaffSalaryTotal>|null} [value] WorkStoreStaffSalaryTotalList value
         */

        /**
         * Constructs a new WorkStoreStaffSalaryTotalList.
         * @memberof Proto
         * @classdesc Represents a WorkStoreStaffSalaryTotalList.
         * @implements IWorkStoreStaffSalaryTotalList
         * @constructor
         * @param {Proto.IWorkStoreStaffSalaryTotalList=} [p] Properties to set
         */
        function WorkStoreStaffSalaryTotalList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreStaffSalaryTotalList value.
         * @member {Array.<Proto.IWorkStoreStaffSalaryTotal>} value
         * @memberof Proto.WorkStoreStaffSalaryTotalList
         * @instance
         */
        WorkStoreStaffSalaryTotalList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkStoreStaffSalaryTotalList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreStaffSalaryTotalList
         * @static
         * @param {Proto.IWorkStoreStaffSalaryTotalList=} [properties] Properties to set
         * @returns {Proto.WorkStoreStaffSalaryTotalList} WorkStoreStaffSalaryTotalList instance
         */
        WorkStoreStaffSalaryTotalList.create = function create(properties) {
            return new WorkStoreStaffSalaryTotalList(properties);
        };

        /**
         * Encodes the specified WorkStoreStaffSalaryTotalList message. Does not implicitly {@link Proto.WorkStoreStaffSalaryTotalList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreStaffSalaryTotalList
         * @static
         * @param {Proto.IWorkStoreStaffSalaryTotalList} m WorkStoreStaffSalaryTotalList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreStaffSalaryTotalList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkStoreStaffSalaryTotal.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkStoreStaffSalaryTotalList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreStaffSalaryTotalList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreStaffSalaryTotalList} WorkStoreStaffSalaryTotalList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreStaffSalaryTotalList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreStaffSalaryTotalList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkStoreStaffSalaryTotal.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreStaffSalaryTotalList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreStaffSalaryTotalList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreStaffSalaryTotalList} WorkStoreStaffSalaryTotalList
         */
        WorkStoreStaffSalaryTotalList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreStaffSalaryTotalList)
                return d;
            var m = new $root.Proto.WorkStoreStaffSalaryTotalList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkStoreStaffSalaryTotalList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkStoreStaffSalaryTotalList.value: object expected");
                    m.value[i] = $root.Proto.WorkStoreStaffSalaryTotal.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreStaffSalaryTotalList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreStaffSalaryTotalList
         * @static
         * @param {Proto.WorkStoreStaffSalaryTotalList} m WorkStoreStaffSalaryTotalList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreStaffSalaryTotalList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkStoreStaffSalaryTotal.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkStoreStaffSalaryTotalList to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreStaffSalaryTotalList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreStaffSalaryTotalList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreStaffSalaryTotalList;
    })();

    Proto.WorkStaffHoliday = (function() {

        /**
         * Properties of a WorkStaffHoliday.
         * @memberof Proto
         * @interface IWorkStaffHoliday
         * @property {string|null} [uid] WorkStaffHoliday uid
         * @property {number|null} [type] WorkStaffHoliday type
         * @property {number|null} [value] WorkStaffHoliday value
         * @property {number|Long|null} [date] WorkStaffHoliday date
         * @property {number|Long|null} [startDate] WorkStaffHoliday startDate
         * @property {number|Long|null} [expireDate] WorkStaffHoliday expireDate
         * @property {number|Long|null} [registerDate] WorkStaffHoliday registerDate
         * @property {number|Long|null} [modifyDate] WorkStaffHoliday modifyDate
         * @property {Proto.IWorkStoreTimetable|null} [timetable] WorkStaffHoliday timetable
         * @property {string|null} [requestUser] WorkStaffHoliday requestUser
         * @property {string|null} [additionalInfo] WorkStaffHoliday additionalInfo
         */

        /**
         * Constructs a new WorkStaffHoliday.
         * @memberof Proto
         * @classdesc Represents a WorkStaffHoliday.
         * @implements IWorkStaffHoliday
         * @constructor
         * @param {Proto.IWorkStaffHoliday=} [p] Properties to set
         */
        function WorkStaffHoliday(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStaffHoliday uid.
         * @member {string} uid
         * @memberof Proto.WorkStaffHoliday
         * @instance
         */
        WorkStaffHoliday.prototype.uid = "";

        /**
         * WorkStaffHoliday type.
         * @member {number} type
         * @memberof Proto.WorkStaffHoliday
         * @instance
         */
        WorkStaffHoliday.prototype.type = 0;

        /**
         * WorkStaffHoliday value.
         * @member {number} value
         * @memberof Proto.WorkStaffHoliday
         * @instance
         */
        WorkStaffHoliday.prototype.value = 0;

        /**
         * WorkStaffHoliday date.
         * @member {number|Long} date
         * @memberof Proto.WorkStaffHoliday
         * @instance
         */
        WorkStaffHoliday.prototype.date = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStaffHoliday startDate.
         * @member {number|Long} startDate
         * @memberof Proto.WorkStaffHoliday
         * @instance
         */
        WorkStaffHoliday.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStaffHoliday expireDate.
         * @member {number|Long} expireDate
         * @memberof Proto.WorkStaffHoliday
         * @instance
         */
        WorkStaffHoliday.prototype.expireDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStaffHoliday registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStaffHoliday
         * @instance
         */
        WorkStaffHoliday.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStaffHoliday modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStaffHoliday
         * @instance
         */
        WorkStaffHoliday.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStaffHoliday timetable.
         * @member {Proto.IWorkStoreTimetable|null|undefined} timetable
         * @memberof Proto.WorkStaffHoliday
         * @instance
         */
        WorkStaffHoliday.prototype.timetable = null;

        /**
         * WorkStaffHoliday requestUser.
         * @member {string} requestUser
         * @memberof Proto.WorkStaffHoliday
         * @instance
         */
        WorkStaffHoliday.prototype.requestUser = "";

        /**
         * WorkStaffHoliday additionalInfo.
         * @member {string} additionalInfo
         * @memberof Proto.WorkStaffHoliday
         * @instance
         */
        WorkStaffHoliday.prototype.additionalInfo = "";

        /**
         * Creates a new WorkStaffHoliday instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStaffHoliday
         * @static
         * @param {Proto.IWorkStaffHoliday=} [properties] Properties to set
         * @returns {Proto.WorkStaffHoliday} WorkStaffHoliday instance
         */
        WorkStaffHoliday.create = function create(properties) {
            return new WorkStaffHoliday(properties);
        };

        /**
         * Encodes the specified WorkStaffHoliday message. Does not implicitly {@link Proto.WorkStaffHoliday.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStaffHoliday
         * @static
         * @param {Proto.IWorkStaffHoliday} m WorkStaffHoliday message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStaffHoliday.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                w.uint32(24).int32(m.value);
            if (m.date != null && Object.hasOwnProperty.call(m, "date"))
                w.uint32(32).int64(m.date);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(40).int64(m.startDate);
            if (m.expireDate != null && Object.hasOwnProperty.call(m, "expireDate"))
                w.uint32(48).int64(m.expireDate);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(56).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(64).int64(m.modifyDate);
            if (m.timetable != null && Object.hasOwnProperty.call(m, "timetable"))
                $root.Proto.WorkStoreTimetable.encode(m.timetable, w.uint32(74).fork()).ldelim();
            if (m.requestUser != null && Object.hasOwnProperty.call(m, "requestUser"))
                w.uint32(82).string(m.requestUser);
            if (m.additionalInfo != null && Object.hasOwnProperty.call(m, "additionalInfo"))
                w.uint32(90).string(m.additionalInfo);
            return w;
        };

        /**
         * Decodes a WorkStaffHoliday message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStaffHoliday
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStaffHoliday} WorkStaffHoliday
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStaffHoliday.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStaffHoliday();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.value = r.int32();
                    break;
                case 4:
                    m.date = r.int64();
                    break;
                case 5:
                    m.startDate = r.int64();
                    break;
                case 6:
                    m.expireDate = r.int64();
                    break;
                case 7:
                    m.registerDate = r.int64();
                    break;
                case 8:
                    m.modifyDate = r.int64();
                    break;
                case 9:
                    m.timetable = $root.Proto.WorkStoreTimetable.decode(r, r.uint32());
                    break;
                case 10:
                    m.requestUser = r.string();
                    break;
                case 11:
                    m.additionalInfo = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStaffHoliday message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStaffHoliday
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStaffHoliday} WorkStaffHoliday
         */
        WorkStaffHoliday.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStaffHoliday)
                return d;
            var m = new $root.Proto.WorkStaffHoliday();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.value != null) {
                m.value = d.value | 0;
            }
            if (d.date != null) {
                if ($util.Long)
                    (m.date = $util.Long.fromValue(d.date)).unsigned = false;
                else if (typeof d.date === "string")
                    m.date = parseInt(d.date, 10);
                else if (typeof d.date === "number")
                    m.date = d.date;
                else if (typeof d.date === "object")
                    m.date = new $util.LongBits(d.date.low >>> 0, d.date.high >>> 0).toNumber();
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.expireDate != null) {
                if ($util.Long)
                    (m.expireDate = $util.Long.fromValue(d.expireDate)).unsigned = false;
                else if (typeof d.expireDate === "string")
                    m.expireDate = parseInt(d.expireDate, 10);
                else if (typeof d.expireDate === "number")
                    m.expireDate = d.expireDate;
                else if (typeof d.expireDate === "object")
                    m.expireDate = new $util.LongBits(d.expireDate.low >>> 0, d.expireDate.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.timetable != null) {
                if (typeof d.timetable !== "object")
                    throw TypeError(".Proto.WorkStaffHoliday.timetable: object expected");
                m.timetable = $root.Proto.WorkStoreTimetable.fromObject(d.timetable);
            }
            if (d.requestUser != null) {
                m.requestUser = String(d.requestUser);
            }
            if (d.additionalInfo != null) {
                m.additionalInfo = String(d.additionalInfo);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStaffHoliday message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStaffHoliday
         * @static
         * @param {Proto.WorkStaffHoliday} m WorkStaffHoliday
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStaffHoliday.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.value = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.date = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.date = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.expireDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.expireDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.timetable = null;
                d.requestUser = "";
                d.additionalInfo = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.value != null && m.hasOwnProperty("value")) {
                d.value = m.value;
            }
            if (m.date != null && m.hasOwnProperty("date")) {
                if (typeof m.date === "number")
                    d.date = o.longs === String ? String(m.date) : m.date;
                else
                    d.date = o.longs === String ? $util.Long.prototype.toString.call(m.date) : o.longs === Number ? new $util.LongBits(m.date.low >>> 0, m.date.high >>> 0).toNumber() : m.date;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.expireDate != null && m.hasOwnProperty("expireDate")) {
                if (typeof m.expireDate === "number")
                    d.expireDate = o.longs === String ? String(m.expireDate) : m.expireDate;
                else
                    d.expireDate = o.longs === String ? $util.Long.prototype.toString.call(m.expireDate) : o.longs === Number ? new $util.LongBits(m.expireDate.low >>> 0, m.expireDate.high >>> 0).toNumber() : m.expireDate;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.timetable != null && m.hasOwnProperty("timetable")) {
                d.timetable = $root.Proto.WorkStoreTimetable.toObject(m.timetable, o);
            }
            if (m.requestUser != null && m.hasOwnProperty("requestUser")) {
                d.requestUser = m.requestUser;
            }
            if (m.additionalInfo != null && m.hasOwnProperty("additionalInfo")) {
                d.additionalInfo = m.additionalInfo;
            }
            return d;
        };

        /**
         * Converts this WorkStaffHoliday to JSON.
         * @function toJSON
         * @memberof Proto.WorkStaffHoliday
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStaffHoliday.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStaffHoliday;
    })();

    Proto.WorkStaffHolidayList = (function() {

        /**
         * Properties of a WorkStaffHolidayList.
         * @memberof Proto
         * @interface IWorkStaffHolidayList
         * @property {Array.<Proto.IWorkStaffHoliday>|null} [value] WorkStaffHolidayList value
         */

        /**
         * Constructs a new WorkStaffHolidayList.
         * @memberof Proto
         * @classdesc Represents a WorkStaffHolidayList.
         * @implements IWorkStaffHolidayList
         * @constructor
         * @param {Proto.IWorkStaffHolidayList=} [p] Properties to set
         */
        function WorkStaffHolidayList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStaffHolidayList value.
         * @member {Array.<Proto.IWorkStaffHoliday>} value
         * @memberof Proto.WorkStaffHolidayList
         * @instance
         */
        WorkStaffHolidayList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkStaffHolidayList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStaffHolidayList
         * @static
         * @param {Proto.IWorkStaffHolidayList=} [properties] Properties to set
         * @returns {Proto.WorkStaffHolidayList} WorkStaffHolidayList instance
         */
        WorkStaffHolidayList.create = function create(properties) {
            return new WorkStaffHolidayList(properties);
        };

        /**
         * Encodes the specified WorkStaffHolidayList message. Does not implicitly {@link Proto.WorkStaffHolidayList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStaffHolidayList
         * @static
         * @param {Proto.IWorkStaffHolidayList} m WorkStaffHolidayList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStaffHolidayList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkStaffHoliday.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkStaffHolidayList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStaffHolidayList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStaffHolidayList} WorkStaffHolidayList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStaffHolidayList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStaffHolidayList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkStaffHoliday.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStaffHolidayList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStaffHolidayList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStaffHolidayList} WorkStaffHolidayList
         */
        WorkStaffHolidayList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStaffHolidayList)
                return d;
            var m = new $root.Proto.WorkStaffHolidayList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkStaffHolidayList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkStaffHolidayList.value: object expected");
                    m.value[i] = $root.Proto.WorkStaffHoliday.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStaffHolidayList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStaffHolidayList
         * @static
         * @param {Proto.WorkStaffHolidayList} m WorkStaffHolidayList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStaffHolidayList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkStaffHoliday.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkStaffHolidayList to JSON.
         * @function toJSON
         * @memberof Proto.WorkStaffHolidayList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStaffHolidayList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStaffHolidayList;
    })();

    Proto.WorkStaffCompVacationInfo = (function() {

        /**
         * Properties of a WorkStaffCompVacationInfo.
         * @memberof Proto
         * @interface IWorkStaffCompVacationInfo
         * @property {string|null} [allDays] WorkStaffCompVacationInfo allDays
         * @property {string|null} [usedDays] WorkStaffCompVacationInfo usedDays
         * @property {string|null} [remainDays] WorkStaffCompVacationInfo remainDays
         * @property {number|Long|null} [supplyDate] WorkStaffCompVacationInfo supplyDate
         * @property {string|null} [supplyDays] WorkStaffCompVacationInfo supplyDays
         * @property {number|Long|null} [availableDate] WorkStaffCompVacationInfo availableDate
         */

        /**
         * Constructs a new WorkStaffCompVacationInfo.
         * @memberof Proto
         * @classdesc Represents a WorkStaffCompVacationInfo.
         * @implements IWorkStaffCompVacationInfo
         * @constructor
         * @param {Proto.IWorkStaffCompVacationInfo=} [p] Properties to set
         */
        function WorkStaffCompVacationInfo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStaffCompVacationInfo allDays.
         * @member {string} allDays
         * @memberof Proto.WorkStaffCompVacationInfo
         * @instance
         */
        WorkStaffCompVacationInfo.prototype.allDays = "";

        /**
         * WorkStaffCompVacationInfo usedDays.
         * @member {string} usedDays
         * @memberof Proto.WorkStaffCompVacationInfo
         * @instance
         */
        WorkStaffCompVacationInfo.prototype.usedDays = "";

        /**
         * WorkStaffCompVacationInfo remainDays.
         * @member {string} remainDays
         * @memberof Proto.WorkStaffCompVacationInfo
         * @instance
         */
        WorkStaffCompVacationInfo.prototype.remainDays = "";

        /**
         * WorkStaffCompVacationInfo supplyDate.
         * @member {number|Long} supplyDate
         * @memberof Proto.WorkStaffCompVacationInfo
         * @instance
         */
        WorkStaffCompVacationInfo.prototype.supplyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStaffCompVacationInfo supplyDays.
         * @member {string} supplyDays
         * @memberof Proto.WorkStaffCompVacationInfo
         * @instance
         */
        WorkStaffCompVacationInfo.prototype.supplyDays = "";

        /**
         * WorkStaffCompVacationInfo availableDate.
         * @member {number|Long} availableDate
         * @memberof Proto.WorkStaffCompVacationInfo
         * @instance
         */
        WorkStaffCompVacationInfo.prototype.availableDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new WorkStaffCompVacationInfo instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStaffCompVacationInfo
         * @static
         * @param {Proto.IWorkStaffCompVacationInfo=} [properties] Properties to set
         * @returns {Proto.WorkStaffCompVacationInfo} WorkStaffCompVacationInfo instance
         */
        WorkStaffCompVacationInfo.create = function create(properties) {
            return new WorkStaffCompVacationInfo(properties);
        };

        /**
         * Encodes the specified WorkStaffCompVacationInfo message. Does not implicitly {@link Proto.WorkStaffCompVacationInfo.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStaffCompVacationInfo
         * @static
         * @param {Proto.IWorkStaffCompVacationInfo} m WorkStaffCompVacationInfo message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStaffCompVacationInfo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.allDays != null && Object.hasOwnProperty.call(m, "allDays"))
                w.uint32(10).string(m.allDays);
            if (m.usedDays != null && Object.hasOwnProperty.call(m, "usedDays"))
                w.uint32(18).string(m.usedDays);
            if (m.remainDays != null && Object.hasOwnProperty.call(m, "remainDays"))
                w.uint32(26).string(m.remainDays);
            if (m.supplyDate != null && Object.hasOwnProperty.call(m, "supplyDate"))
                w.uint32(32).int64(m.supplyDate);
            if (m.supplyDays != null && Object.hasOwnProperty.call(m, "supplyDays"))
                w.uint32(42).string(m.supplyDays);
            if (m.availableDate != null && Object.hasOwnProperty.call(m, "availableDate"))
                w.uint32(48).int64(m.availableDate);
            return w;
        };

        /**
         * Decodes a WorkStaffCompVacationInfo message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStaffCompVacationInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStaffCompVacationInfo} WorkStaffCompVacationInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStaffCompVacationInfo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStaffCompVacationInfo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.allDays = r.string();
                    break;
                case 2:
                    m.usedDays = r.string();
                    break;
                case 3:
                    m.remainDays = r.string();
                    break;
                case 4:
                    m.supplyDate = r.int64();
                    break;
                case 5:
                    m.supplyDays = r.string();
                    break;
                case 6:
                    m.availableDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStaffCompVacationInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStaffCompVacationInfo
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStaffCompVacationInfo} WorkStaffCompVacationInfo
         */
        WorkStaffCompVacationInfo.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStaffCompVacationInfo)
                return d;
            var m = new $root.Proto.WorkStaffCompVacationInfo();
            if (d.allDays != null) {
                m.allDays = String(d.allDays);
            }
            if (d.usedDays != null) {
                m.usedDays = String(d.usedDays);
            }
            if (d.remainDays != null) {
                m.remainDays = String(d.remainDays);
            }
            if (d.supplyDate != null) {
                if ($util.Long)
                    (m.supplyDate = $util.Long.fromValue(d.supplyDate)).unsigned = false;
                else if (typeof d.supplyDate === "string")
                    m.supplyDate = parseInt(d.supplyDate, 10);
                else if (typeof d.supplyDate === "number")
                    m.supplyDate = d.supplyDate;
                else if (typeof d.supplyDate === "object")
                    m.supplyDate = new $util.LongBits(d.supplyDate.low >>> 0, d.supplyDate.high >>> 0).toNumber();
            }
            if (d.supplyDays != null) {
                m.supplyDays = String(d.supplyDays);
            }
            if (d.availableDate != null) {
                if ($util.Long)
                    (m.availableDate = $util.Long.fromValue(d.availableDate)).unsigned = false;
                else if (typeof d.availableDate === "string")
                    m.availableDate = parseInt(d.availableDate, 10);
                else if (typeof d.availableDate === "number")
                    m.availableDate = d.availableDate;
                else if (typeof d.availableDate === "object")
                    m.availableDate = new $util.LongBits(d.availableDate.low >>> 0, d.availableDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStaffCompVacationInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStaffCompVacationInfo
         * @static
         * @param {Proto.WorkStaffCompVacationInfo} m WorkStaffCompVacationInfo
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStaffCompVacationInfo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.allDays = "";
                d.usedDays = "";
                d.remainDays = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.supplyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.supplyDate = o.longs === String ? "0" : 0;
                d.supplyDays = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.availableDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.availableDate = o.longs === String ? "0" : 0;
            }
            if (m.allDays != null && m.hasOwnProperty("allDays")) {
                d.allDays = m.allDays;
            }
            if (m.usedDays != null && m.hasOwnProperty("usedDays")) {
                d.usedDays = m.usedDays;
            }
            if (m.remainDays != null && m.hasOwnProperty("remainDays")) {
                d.remainDays = m.remainDays;
            }
            if (m.supplyDate != null && m.hasOwnProperty("supplyDate")) {
                if (typeof m.supplyDate === "number")
                    d.supplyDate = o.longs === String ? String(m.supplyDate) : m.supplyDate;
                else
                    d.supplyDate = o.longs === String ? $util.Long.prototype.toString.call(m.supplyDate) : o.longs === Number ? new $util.LongBits(m.supplyDate.low >>> 0, m.supplyDate.high >>> 0).toNumber() : m.supplyDate;
            }
            if (m.supplyDays != null && m.hasOwnProperty("supplyDays")) {
                d.supplyDays = m.supplyDays;
            }
            if (m.availableDate != null && m.hasOwnProperty("availableDate")) {
                if (typeof m.availableDate === "number")
                    d.availableDate = o.longs === String ? String(m.availableDate) : m.availableDate;
                else
                    d.availableDate = o.longs === String ? $util.Long.prototype.toString.call(m.availableDate) : o.longs === Number ? new $util.LongBits(m.availableDate.low >>> 0, m.availableDate.high >>> 0).toNumber() : m.availableDate;
            }
            return d;
        };

        /**
         * Converts this WorkStaffCompVacationInfo to JSON.
         * @function toJSON
         * @memberof Proto.WorkStaffCompVacationInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStaffCompVacationInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStaffCompVacationInfo;
    })();

    Proto.WorkStoreTimetableApproveHistory = (function() {

        /**
         * Properties of a WorkStoreTimetableApproveHistory.
         * @memberof Proto
         * @interface IWorkStoreTimetableApproveHistory
         * @property {string|null} [uid] WorkStoreTimetableApproveHistory uid
         * @property {number|null} [type] WorkStoreTimetableApproveHistory type
         * @property {number|Long|null} [startDate] WorkStoreTimetableApproveHistory startDate
         * @property {number|Long|null} [endDate] WorkStoreTimetableApproveHistory endDate
         * @property {number|Long|null} [requestDate] WorkStoreTimetableApproveHistory requestDate
         * @property {number|Long|null} [approveDate] WorkStoreTimetableApproveHistory approveDate
         * @property {number|Long|null} [registerDate] WorkStoreTimetableApproveHistory registerDate
         * @property {number|Long|null} [modifyDate] WorkStoreTimetableApproveHistory modifyDate
         * @property {Proto.IWorkStore|null} [store] WorkStoreTimetableApproveHistory store
         * @property {Proto.IWorkStaff|null} [staff] WorkStoreTimetableApproveHistory staff
         * @property {Proto.IUser|null} [requestUser] WorkStoreTimetableApproveHistory requestUser
         * @property {Proto.IUser|null} [approveUser] WorkStoreTimetableApproveHistory approveUser
         */

        /**
         * Constructs a new WorkStoreTimetableApproveHistory.
         * @memberof Proto
         * @classdesc Represents a WorkStoreTimetableApproveHistory.
         * @implements IWorkStoreTimetableApproveHistory
         * @constructor
         * @param {Proto.IWorkStoreTimetableApproveHistory=} [p] Properties to set
         */
        function WorkStoreTimetableApproveHistory(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreTimetableApproveHistory uid.
         * @member {string} uid
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @instance
         */
        WorkStoreTimetableApproveHistory.prototype.uid = "";

        /**
         * WorkStoreTimetableApproveHistory type.
         * @member {number} type
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @instance
         */
        WorkStoreTimetableApproveHistory.prototype.type = 0;

        /**
         * WorkStoreTimetableApproveHistory startDate.
         * @member {number|Long} startDate
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @instance
         */
        WorkStoreTimetableApproveHistory.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableApproveHistory endDate.
         * @member {number|Long} endDate
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @instance
         */
        WorkStoreTimetableApproveHistory.prototype.endDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableApproveHistory requestDate.
         * @member {number|Long} requestDate
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @instance
         */
        WorkStoreTimetableApproveHistory.prototype.requestDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableApproveHistory approveDate.
         * @member {number|Long} approveDate
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @instance
         */
        WorkStoreTimetableApproveHistory.prototype.approveDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableApproveHistory registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @instance
         */
        WorkStoreTimetableApproveHistory.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableApproveHistory modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @instance
         */
        WorkStoreTimetableApproveHistory.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableApproveHistory store.
         * @member {Proto.IWorkStore|null|undefined} store
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @instance
         */
        WorkStoreTimetableApproveHistory.prototype.store = null;

        /**
         * WorkStoreTimetableApproveHistory staff.
         * @member {Proto.IWorkStaff|null|undefined} staff
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @instance
         */
        WorkStoreTimetableApproveHistory.prototype.staff = null;

        /**
         * WorkStoreTimetableApproveHistory requestUser.
         * @member {Proto.IUser|null|undefined} requestUser
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @instance
         */
        WorkStoreTimetableApproveHistory.prototype.requestUser = null;

        /**
         * WorkStoreTimetableApproveHistory approveUser.
         * @member {Proto.IUser|null|undefined} approveUser
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @instance
         */
        WorkStoreTimetableApproveHistory.prototype.approveUser = null;

        /**
         * Creates a new WorkStoreTimetableApproveHistory instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @static
         * @param {Proto.IWorkStoreTimetableApproveHistory=} [properties] Properties to set
         * @returns {Proto.WorkStoreTimetableApproveHistory} WorkStoreTimetableApproveHistory instance
         */
        WorkStoreTimetableApproveHistory.create = function create(properties) {
            return new WorkStoreTimetableApproveHistory(properties);
        };

        /**
         * Encodes the specified WorkStoreTimetableApproveHistory message. Does not implicitly {@link Proto.WorkStoreTimetableApproveHistory.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @static
         * @param {Proto.IWorkStoreTimetableApproveHistory} m WorkStoreTimetableApproveHistory message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreTimetableApproveHistory.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.startDate != null && Object.hasOwnProperty.call(m, "startDate"))
                w.uint32(24).int64(m.startDate);
            if (m.endDate != null && Object.hasOwnProperty.call(m, "endDate"))
                w.uint32(32).int64(m.endDate);
            if (m.requestDate != null && Object.hasOwnProperty.call(m, "requestDate"))
                w.uint32(40).int64(m.requestDate);
            if (m.approveDate != null && Object.hasOwnProperty.call(m, "approveDate"))
                w.uint32(48).int64(m.approveDate);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(56).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(64).int64(m.modifyDate);
            if (m.store != null && Object.hasOwnProperty.call(m, "store"))
                $root.Proto.WorkStore.encode(m.store, w.uint32(74).fork()).ldelim();
            if (m.staff != null && Object.hasOwnProperty.call(m, "staff"))
                $root.Proto.WorkStaff.encode(m.staff, w.uint32(82).fork()).ldelim();
            if (m.requestUser != null && Object.hasOwnProperty.call(m, "requestUser"))
                $root.Proto.User.encode(m.requestUser, w.uint32(90).fork()).ldelim();
            if (m.approveUser != null && Object.hasOwnProperty.call(m, "approveUser"))
                $root.Proto.User.encode(m.approveUser, w.uint32(98).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a WorkStoreTimetableApproveHistory message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreTimetableApproveHistory} WorkStoreTimetableApproveHistory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreTimetableApproveHistory.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreTimetableApproveHistory();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.startDate = r.int64();
                    break;
                case 4:
                    m.endDate = r.int64();
                    break;
                case 5:
                    m.requestDate = r.int64();
                    break;
                case 6:
                    m.approveDate = r.int64();
                    break;
                case 7:
                    m.registerDate = r.int64();
                    break;
                case 8:
                    m.modifyDate = r.int64();
                    break;
                case 9:
                    m.store = $root.Proto.WorkStore.decode(r, r.uint32());
                    break;
                case 10:
                    m.staff = $root.Proto.WorkStaff.decode(r, r.uint32());
                    break;
                case 11:
                    m.requestUser = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 12:
                    m.approveUser = $root.Proto.User.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreTimetableApproveHistory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreTimetableApproveHistory} WorkStoreTimetableApproveHistory
         */
        WorkStoreTimetableApproveHistory.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreTimetableApproveHistory)
                return d;
            var m = new $root.Proto.WorkStoreTimetableApproveHistory();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.startDate != null) {
                if ($util.Long)
                    (m.startDate = $util.Long.fromValue(d.startDate)).unsigned = false;
                else if (typeof d.startDate === "string")
                    m.startDate = parseInt(d.startDate, 10);
                else if (typeof d.startDate === "number")
                    m.startDate = d.startDate;
                else if (typeof d.startDate === "object")
                    m.startDate = new $util.LongBits(d.startDate.low >>> 0, d.startDate.high >>> 0).toNumber();
            }
            if (d.endDate != null) {
                if ($util.Long)
                    (m.endDate = $util.Long.fromValue(d.endDate)).unsigned = false;
                else if (typeof d.endDate === "string")
                    m.endDate = parseInt(d.endDate, 10);
                else if (typeof d.endDate === "number")
                    m.endDate = d.endDate;
                else if (typeof d.endDate === "object")
                    m.endDate = new $util.LongBits(d.endDate.low >>> 0, d.endDate.high >>> 0).toNumber();
            }
            if (d.requestDate != null) {
                if ($util.Long)
                    (m.requestDate = $util.Long.fromValue(d.requestDate)).unsigned = false;
                else if (typeof d.requestDate === "string")
                    m.requestDate = parseInt(d.requestDate, 10);
                else if (typeof d.requestDate === "number")
                    m.requestDate = d.requestDate;
                else if (typeof d.requestDate === "object")
                    m.requestDate = new $util.LongBits(d.requestDate.low >>> 0, d.requestDate.high >>> 0).toNumber();
            }
            if (d.approveDate != null) {
                if ($util.Long)
                    (m.approveDate = $util.Long.fromValue(d.approveDate)).unsigned = false;
                else if (typeof d.approveDate === "string")
                    m.approveDate = parseInt(d.approveDate, 10);
                else if (typeof d.approveDate === "number")
                    m.approveDate = d.approveDate;
                else if (typeof d.approveDate === "object")
                    m.approveDate = new $util.LongBits(d.approveDate.low >>> 0, d.approveDate.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.store != null) {
                if (typeof d.store !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableApproveHistory.store: object expected");
                m.store = $root.Proto.WorkStore.fromObject(d.store);
            }
            if (d.staff != null) {
                if (typeof d.staff !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableApproveHistory.staff: object expected");
                m.staff = $root.Proto.WorkStaff.fromObject(d.staff);
            }
            if (d.requestUser != null) {
                if (typeof d.requestUser !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableApproveHistory.requestUser: object expected");
                m.requestUser = $root.Proto.User.fromObject(d.requestUser);
            }
            if (d.approveUser != null) {
                if (typeof d.approveUser !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableApproveHistory.approveUser: object expected");
                m.approveUser = $root.Proto.User.fromObject(d.approveUser);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreTimetableApproveHistory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @static
         * @param {Proto.WorkStoreTimetableApproveHistory} m WorkStoreTimetableApproveHistory
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreTimetableApproveHistory.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.endDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.endDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.requestDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.requestDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.approveDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.approveDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.store = null;
                d.staff = null;
                d.requestUser = null;
                d.approveUser = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.startDate != null && m.hasOwnProperty("startDate")) {
                if (typeof m.startDate === "number")
                    d.startDate = o.longs === String ? String(m.startDate) : m.startDate;
                else
                    d.startDate = o.longs === String ? $util.Long.prototype.toString.call(m.startDate) : o.longs === Number ? new $util.LongBits(m.startDate.low >>> 0, m.startDate.high >>> 0).toNumber() : m.startDate;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                if (typeof m.endDate === "number")
                    d.endDate = o.longs === String ? String(m.endDate) : m.endDate;
                else
                    d.endDate = o.longs === String ? $util.Long.prototype.toString.call(m.endDate) : o.longs === Number ? new $util.LongBits(m.endDate.low >>> 0, m.endDate.high >>> 0).toNumber() : m.endDate;
            }
            if (m.requestDate != null && m.hasOwnProperty("requestDate")) {
                if (typeof m.requestDate === "number")
                    d.requestDate = o.longs === String ? String(m.requestDate) : m.requestDate;
                else
                    d.requestDate = o.longs === String ? $util.Long.prototype.toString.call(m.requestDate) : o.longs === Number ? new $util.LongBits(m.requestDate.low >>> 0, m.requestDate.high >>> 0).toNumber() : m.requestDate;
            }
            if (m.approveDate != null && m.hasOwnProperty("approveDate")) {
                if (typeof m.approveDate === "number")
                    d.approveDate = o.longs === String ? String(m.approveDate) : m.approveDate;
                else
                    d.approveDate = o.longs === String ? $util.Long.prototype.toString.call(m.approveDate) : o.longs === Number ? new $util.LongBits(m.approveDate.low >>> 0, m.approveDate.high >>> 0).toNumber() : m.approveDate;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.store != null && m.hasOwnProperty("store")) {
                d.store = $root.Proto.WorkStore.toObject(m.store, o);
            }
            if (m.staff != null && m.hasOwnProperty("staff")) {
                d.staff = $root.Proto.WorkStaff.toObject(m.staff, o);
            }
            if (m.requestUser != null && m.hasOwnProperty("requestUser")) {
                d.requestUser = $root.Proto.User.toObject(m.requestUser, o);
            }
            if (m.approveUser != null && m.hasOwnProperty("approveUser")) {
                d.approveUser = $root.Proto.User.toObject(m.approveUser, o);
            }
            return d;
        };

        /**
         * Converts this WorkStoreTimetableApproveHistory to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreTimetableApproveHistory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreTimetableApproveHistory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreTimetableApproveHistory;
    })();

    Proto.WorkStoreTimetableApproveHistoryList = (function() {

        /**
         * Properties of a WorkStoreTimetableApproveHistoryList.
         * @memberof Proto
         * @interface IWorkStoreTimetableApproveHistoryList
         * @property {Array.<Proto.IWorkStoreTimetableApproveHistory>|null} [value] WorkStoreTimetableApproveHistoryList value
         */

        /**
         * Constructs a new WorkStoreTimetableApproveHistoryList.
         * @memberof Proto
         * @classdesc Represents a WorkStoreTimetableApproveHistoryList.
         * @implements IWorkStoreTimetableApproveHistoryList
         * @constructor
         * @param {Proto.IWorkStoreTimetableApproveHistoryList=} [p] Properties to set
         */
        function WorkStoreTimetableApproveHistoryList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreTimetableApproveHistoryList value.
         * @member {Array.<Proto.IWorkStoreTimetableApproveHistory>} value
         * @memberof Proto.WorkStoreTimetableApproveHistoryList
         * @instance
         */
        WorkStoreTimetableApproveHistoryList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkStoreTimetableApproveHistoryList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreTimetableApproveHistoryList
         * @static
         * @param {Proto.IWorkStoreTimetableApproveHistoryList=} [properties] Properties to set
         * @returns {Proto.WorkStoreTimetableApproveHistoryList} WorkStoreTimetableApproveHistoryList instance
         */
        WorkStoreTimetableApproveHistoryList.create = function create(properties) {
            return new WorkStoreTimetableApproveHistoryList(properties);
        };

        /**
         * Encodes the specified WorkStoreTimetableApproveHistoryList message. Does not implicitly {@link Proto.WorkStoreTimetableApproveHistoryList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreTimetableApproveHistoryList
         * @static
         * @param {Proto.IWorkStoreTimetableApproveHistoryList} m WorkStoreTimetableApproveHistoryList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreTimetableApproveHistoryList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkStoreTimetableApproveHistory.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkStoreTimetableApproveHistoryList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreTimetableApproveHistoryList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreTimetableApproveHistoryList} WorkStoreTimetableApproveHistoryList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreTimetableApproveHistoryList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreTimetableApproveHistoryList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkStoreTimetableApproveHistory.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreTimetableApproveHistoryList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreTimetableApproveHistoryList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreTimetableApproveHistoryList} WorkStoreTimetableApproveHistoryList
         */
        WorkStoreTimetableApproveHistoryList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreTimetableApproveHistoryList)
                return d;
            var m = new $root.Proto.WorkStoreTimetableApproveHistoryList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkStoreTimetableApproveHistoryList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkStoreTimetableApproveHistoryList.value: object expected");
                    m.value[i] = $root.Proto.WorkStoreTimetableApproveHistory.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreTimetableApproveHistoryList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreTimetableApproveHistoryList
         * @static
         * @param {Proto.WorkStoreTimetableApproveHistoryList} m WorkStoreTimetableApproveHistoryList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreTimetableApproveHistoryList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkStoreTimetableApproveHistory.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkStoreTimetableApproveHistoryList to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreTimetableApproveHistoryList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreTimetableApproveHistoryList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreTimetableApproveHistoryList;
    })();

    Proto.WorkStoreTimetableErrorHistory = (function() {

        /**
         * Properties of a WorkStoreTimetableErrorHistory.
         * @memberof Proto
         * @interface IWorkStoreTimetableErrorHistory
         * @property {string|null} [uid] WorkStoreTimetableErrorHistory uid
         * @property {number|null} [type] WorkStoreTimetableErrorHistory type
         * @property {string|null} [date] WorkStoreTimetableErrorHistory date
         * @property {boolean|null} [resolved] WorkStoreTimetableErrorHistory resolved
         * @property {number|Long|null} [registerDate] WorkStoreTimetableErrorHistory registerDate
         * @property {number|Long|null} [modifyDate] WorkStoreTimetableErrorHistory modifyDate
         * @property {Proto.IWorkStore|null} [store] WorkStoreTimetableErrorHistory store
         * @property {Proto.IWorkStaff|null} [staff] WorkStoreTimetableErrorHistory staff
         * @property {Proto.IWorkStoreTimetable|null} [timetable] WorkStoreTimetableErrorHistory timetable
         * @property {Proto.IWorkStoreTimetableClock|null} [clock] WorkStoreTimetableErrorHistory clock
         */

        /**
         * Constructs a new WorkStoreTimetableErrorHistory.
         * @memberof Proto
         * @classdesc Represents a WorkStoreTimetableErrorHistory.
         * @implements IWorkStoreTimetableErrorHistory
         * @constructor
         * @param {Proto.IWorkStoreTimetableErrorHistory=} [p] Properties to set
         */
        function WorkStoreTimetableErrorHistory(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreTimetableErrorHistory uid.
         * @member {string} uid
         * @memberof Proto.WorkStoreTimetableErrorHistory
         * @instance
         */
        WorkStoreTimetableErrorHistory.prototype.uid = "";

        /**
         * WorkStoreTimetableErrorHistory type.
         * @member {number} type
         * @memberof Proto.WorkStoreTimetableErrorHistory
         * @instance
         */
        WorkStoreTimetableErrorHistory.prototype.type = 0;

        /**
         * WorkStoreTimetableErrorHistory date.
         * @member {string} date
         * @memberof Proto.WorkStoreTimetableErrorHistory
         * @instance
         */
        WorkStoreTimetableErrorHistory.prototype.date = "";

        /**
         * WorkStoreTimetableErrorHistory resolved.
         * @member {boolean} resolved
         * @memberof Proto.WorkStoreTimetableErrorHistory
         * @instance
         */
        WorkStoreTimetableErrorHistory.prototype.resolved = false;

        /**
         * WorkStoreTimetableErrorHistory registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkStoreTimetableErrorHistory
         * @instance
         */
        WorkStoreTimetableErrorHistory.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableErrorHistory modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkStoreTimetableErrorHistory
         * @instance
         */
        WorkStoreTimetableErrorHistory.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkStoreTimetableErrorHistory store.
         * @member {Proto.IWorkStore|null|undefined} store
         * @memberof Proto.WorkStoreTimetableErrorHistory
         * @instance
         */
        WorkStoreTimetableErrorHistory.prototype.store = null;

        /**
         * WorkStoreTimetableErrorHistory staff.
         * @member {Proto.IWorkStaff|null|undefined} staff
         * @memberof Proto.WorkStoreTimetableErrorHistory
         * @instance
         */
        WorkStoreTimetableErrorHistory.prototype.staff = null;

        /**
         * WorkStoreTimetableErrorHistory timetable.
         * @member {Proto.IWorkStoreTimetable|null|undefined} timetable
         * @memberof Proto.WorkStoreTimetableErrorHistory
         * @instance
         */
        WorkStoreTimetableErrorHistory.prototype.timetable = null;

        /**
         * WorkStoreTimetableErrorHistory clock.
         * @member {Proto.IWorkStoreTimetableClock|null|undefined} clock
         * @memberof Proto.WorkStoreTimetableErrorHistory
         * @instance
         */
        WorkStoreTimetableErrorHistory.prototype.clock = null;

        /**
         * Creates a new WorkStoreTimetableErrorHistory instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreTimetableErrorHistory
         * @static
         * @param {Proto.IWorkStoreTimetableErrorHistory=} [properties] Properties to set
         * @returns {Proto.WorkStoreTimetableErrorHistory} WorkStoreTimetableErrorHistory instance
         */
        WorkStoreTimetableErrorHistory.create = function create(properties) {
            return new WorkStoreTimetableErrorHistory(properties);
        };

        /**
         * Encodes the specified WorkStoreTimetableErrorHistory message. Does not implicitly {@link Proto.WorkStoreTimetableErrorHistory.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreTimetableErrorHistory
         * @static
         * @param {Proto.IWorkStoreTimetableErrorHistory} m WorkStoreTimetableErrorHistory message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreTimetableErrorHistory.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.date != null && Object.hasOwnProperty.call(m, "date"))
                w.uint32(26).string(m.date);
            if (m.resolved != null && Object.hasOwnProperty.call(m, "resolved"))
                w.uint32(32).bool(m.resolved);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(40).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(48).int64(m.modifyDate);
            if (m.store != null && Object.hasOwnProperty.call(m, "store"))
                $root.Proto.WorkStore.encode(m.store, w.uint32(58).fork()).ldelim();
            if (m.staff != null && Object.hasOwnProperty.call(m, "staff"))
                $root.Proto.WorkStaff.encode(m.staff, w.uint32(66).fork()).ldelim();
            if (m.timetable != null && Object.hasOwnProperty.call(m, "timetable"))
                $root.Proto.WorkStoreTimetable.encode(m.timetable, w.uint32(74).fork()).ldelim();
            if (m.clock != null && Object.hasOwnProperty.call(m, "clock"))
                $root.Proto.WorkStoreTimetableClock.encode(m.clock, w.uint32(82).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a WorkStoreTimetableErrorHistory message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreTimetableErrorHistory
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreTimetableErrorHistory} WorkStoreTimetableErrorHistory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreTimetableErrorHistory.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreTimetableErrorHistory();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.date = r.string();
                    break;
                case 4:
                    m.resolved = r.bool();
                    break;
                case 5:
                    m.registerDate = r.int64();
                    break;
                case 6:
                    m.modifyDate = r.int64();
                    break;
                case 7:
                    m.store = $root.Proto.WorkStore.decode(r, r.uint32());
                    break;
                case 8:
                    m.staff = $root.Proto.WorkStaff.decode(r, r.uint32());
                    break;
                case 9:
                    m.timetable = $root.Proto.WorkStoreTimetable.decode(r, r.uint32());
                    break;
                case 10:
                    m.clock = $root.Proto.WorkStoreTimetableClock.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreTimetableErrorHistory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreTimetableErrorHistory
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreTimetableErrorHistory} WorkStoreTimetableErrorHistory
         */
        WorkStoreTimetableErrorHistory.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreTimetableErrorHistory)
                return d;
            var m = new $root.Proto.WorkStoreTimetableErrorHistory();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.date != null) {
                m.date = String(d.date);
            }
            if (d.resolved != null) {
                m.resolved = Boolean(d.resolved);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.store != null) {
                if (typeof d.store !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableErrorHistory.store: object expected");
                m.store = $root.Proto.WorkStore.fromObject(d.store);
            }
            if (d.staff != null) {
                if (typeof d.staff !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableErrorHistory.staff: object expected");
                m.staff = $root.Proto.WorkStaff.fromObject(d.staff);
            }
            if (d.timetable != null) {
                if (typeof d.timetable !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableErrorHistory.timetable: object expected");
                m.timetable = $root.Proto.WorkStoreTimetable.fromObject(d.timetable);
            }
            if (d.clock != null) {
                if (typeof d.clock !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableErrorHistory.clock: object expected");
                m.clock = $root.Proto.WorkStoreTimetableClock.fromObject(d.clock);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreTimetableErrorHistory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreTimetableErrorHistory
         * @static
         * @param {Proto.WorkStoreTimetableErrorHistory} m WorkStoreTimetableErrorHistory
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreTimetableErrorHistory.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.date = "";
                d.resolved = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.store = null;
                d.staff = null;
                d.timetable = null;
                d.clock = null;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.date != null && m.hasOwnProperty("date")) {
                d.date = m.date;
            }
            if (m.resolved != null && m.hasOwnProperty("resolved")) {
                d.resolved = m.resolved;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.store != null && m.hasOwnProperty("store")) {
                d.store = $root.Proto.WorkStore.toObject(m.store, o);
            }
            if (m.staff != null && m.hasOwnProperty("staff")) {
                d.staff = $root.Proto.WorkStaff.toObject(m.staff, o);
            }
            if (m.timetable != null && m.hasOwnProperty("timetable")) {
                d.timetable = $root.Proto.WorkStoreTimetable.toObject(m.timetable, o);
            }
            if (m.clock != null && m.hasOwnProperty("clock")) {
                d.clock = $root.Proto.WorkStoreTimetableClock.toObject(m.clock, o);
            }
            return d;
        };

        /**
         * Converts this WorkStoreTimetableErrorHistory to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreTimetableErrorHistory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreTimetableErrorHistory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreTimetableErrorHistory;
    })();

    Proto.WorkStoreTimetableErrorHistoryList = (function() {

        /**
         * Properties of a WorkStoreTimetableErrorHistoryList.
         * @memberof Proto
         * @interface IWorkStoreTimetableErrorHistoryList
         * @property {Array.<Proto.IWorkStoreTimetableErrorHistory>|null} [value] WorkStoreTimetableErrorHistoryList value
         */

        /**
         * Constructs a new WorkStoreTimetableErrorHistoryList.
         * @memberof Proto
         * @classdesc Represents a WorkStoreTimetableErrorHistoryList.
         * @implements IWorkStoreTimetableErrorHistoryList
         * @constructor
         * @param {Proto.IWorkStoreTimetableErrorHistoryList=} [p] Properties to set
         */
        function WorkStoreTimetableErrorHistoryList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreTimetableErrorHistoryList value.
         * @member {Array.<Proto.IWorkStoreTimetableErrorHistory>} value
         * @memberof Proto.WorkStoreTimetableErrorHistoryList
         * @instance
         */
        WorkStoreTimetableErrorHistoryList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkStoreTimetableErrorHistoryList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreTimetableErrorHistoryList
         * @static
         * @param {Proto.IWorkStoreTimetableErrorHistoryList=} [properties] Properties to set
         * @returns {Proto.WorkStoreTimetableErrorHistoryList} WorkStoreTimetableErrorHistoryList instance
         */
        WorkStoreTimetableErrorHistoryList.create = function create(properties) {
            return new WorkStoreTimetableErrorHistoryList(properties);
        };

        /**
         * Encodes the specified WorkStoreTimetableErrorHistoryList message. Does not implicitly {@link Proto.WorkStoreTimetableErrorHistoryList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreTimetableErrorHistoryList
         * @static
         * @param {Proto.IWorkStoreTimetableErrorHistoryList} m WorkStoreTimetableErrorHistoryList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreTimetableErrorHistoryList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkStoreTimetableErrorHistory.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkStoreTimetableErrorHistoryList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreTimetableErrorHistoryList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreTimetableErrorHistoryList} WorkStoreTimetableErrorHistoryList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreTimetableErrorHistoryList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreTimetableErrorHistoryList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkStoreTimetableErrorHistory.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreTimetableErrorHistoryList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreTimetableErrorHistoryList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreTimetableErrorHistoryList} WorkStoreTimetableErrorHistoryList
         */
        WorkStoreTimetableErrorHistoryList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreTimetableErrorHistoryList)
                return d;
            var m = new $root.Proto.WorkStoreTimetableErrorHistoryList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkStoreTimetableErrorHistoryList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkStoreTimetableErrorHistoryList.value: object expected");
                    m.value[i] = $root.Proto.WorkStoreTimetableErrorHistory.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreTimetableErrorHistoryList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreTimetableErrorHistoryList
         * @static
         * @param {Proto.WorkStoreTimetableErrorHistoryList} m WorkStoreTimetableErrorHistoryList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreTimetableErrorHistoryList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkStoreTimetableErrorHistory.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkStoreTimetableErrorHistoryList to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreTimetableErrorHistoryList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreTimetableErrorHistoryList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreTimetableErrorHistoryList;
    })();

    Proto.WorkStoreTimetableApproveHistoryCount = (function() {

        /**
         * Properties of a WorkStoreTimetableApproveHistoryCount.
         * @memberof Proto
         * @interface IWorkStoreTimetableApproveHistoryCount
         * @property {Proto.IWorkStaff|null} [staff] WorkStoreTimetableApproveHistoryCount staff
         * @property {string|null} [allCount] WorkStoreTimetableApproveHistoryCount allCount
         * @property {string|null} [approvedCount] WorkStoreTimetableApproveHistoryCount approvedCount
         */

        /**
         * Constructs a new WorkStoreTimetableApproveHistoryCount.
         * @memberof Proto
         * @classdesc Represents a WorkStoreTimetableApproveHistoryCount.
         * @implements IWorkStoreTimetableApproveHistoryCount
         * @constructor
         * @param {Proto.IWorkStoreTimetableApproveHistoryCount=} [p] Properties to set
         */
        function WorkStoreTimetableApproveHistoryCount(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreTimetableApproveHistoryCount staff.
         * @member {Proto.IWorkStaff|null|undefined} staff
         * @memberof Proto.WorkStoreTimetableApproveHistoryCount
         * @instance
         */
        WorkStoreTimetableApproveHistoryCount.prototype.staff = null;

        /**
         * WorkStoreTimetableApproveHistoryCount allCount.
         * @member {string} allCount
         * @memberof Proto.WorkStoreTimetableApproveHistoryCount
         * @instance
         */
        WorkStoreTimetableApproveHistoryCount.prototype.allCount = "";

        /**
         * WorkStoreTimetableApproveHistoryCount approvedCount.
         * @member {string} approvedCount
         * @memberof Proto.WorkStoreTimetableApproveHistoryCount
         * @instance
         */
        WorkStoreTimetableApproveHistoryCount.prototype.approvedCount = "";

        /**
         * Creates a new WorkStoreTimetableApproveHistoryCount instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreTimetableApproveHistoryCount
         * @static
         * @param {Proto.IWorkStoreTimetableApproveHistoryCount=} [properties] Properties to set
         * @returns {Proto.WorkStoreTimetableApproveHistoryCount} WorkStoreTimetableApproveHistoryCount instance
         */
        WorkStoreTimetableApproveHistoryCount.create = function create(properties) {
            return new WorkStoreTimetableApproveHistoryCount(properties);
        };

        /**
         * Encodes the specified WorkStoreTimetableApproveHistoryCount message. Does not implicitly {@link Proto.WorkStoreTimetableApproveHistoryCount.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreTimetableApproveHistoryCount
         * @static
         * @param {Proto.IWorkStoreTimetableApproveHistoryCount} m WorkStoreTimetableApproveHistoryCount message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreTimetableApproveHistoryCount.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.staff != null && Object.hasOwnProperty.call(m, "staff"))
                $root.Proto.WorkStaff.encode(m.staff, w.uint32(10).fork()).ldelim();
            if (m.allCount != null && Object.hasOwnProperty.call(m, "allCount"))
                w.uint32(18).string(m.allCount);
            if (m.approvedCount != null && Object.hasOwnProperty.call(m, "approvedCount"))
                w.uint32(26).string(m.approvedCount);
            return w;
        };

        /**
         * Decodes a WorkStoreTimetableApproveHistoryCount message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreTimetableApproveHistoryCount
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreTimetableApproveHistoryCount} WorkStoreTimetableApproveHistoryCount
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreTimetableApproveHistoryCount.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreTimetableApproveHistoryCount();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.staff = $root.Proto.WorkStaff.decode(r, r.uint32());
                    break;
                case 2:
                    m.allCount = r.string();
                    break;
                case 3:
                    m.approvedCount = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreTimetableApproveHistoryCount message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreTimetableApproveHistoryCount
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreTimetableApproveHistoryCount} WorkStoreTimetableApproveHistoryCount
         */
        WorkStoreTimetableApproveHistoryCount.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreTimetableApproveHistoryCount)
                return d;
            var m = new $root.Proto.WorkStoreTimetableApproveHistoryCount();
            if (d.staff != null) {
                if (typeof d.staff !== "object")
                    throw TypeError(".Proto.WorkStoreTimetableApproveHistoryCount.staff: object expected");
                m.staff = $root.Proto.WorkStaff.fromObject(d.staff);
            }
            if (d.allCount != null) {
                m.allCount = String(d.allCount);
            }
            if (d.approvedCount != null) {
                m.approvedCount = String(d.approvedCount);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreTimetableApproveHistoryCount message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreTimetableApproveHistoryCount
         * @static
         * @param {Proto.WorkStoreTimetableApproveHistoryCount} m WorkStoreTimetableApproveHistoryCount
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreTimetableApproveHistoryCount.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.staff = null;
                d.allCount = "";
                d.approvedCount = "";
            }
            if (m.staff != null && m.hasOwnProperty("staff")) {
                d.staff = $root.Proto.WorkStaff.toObject(m.staff, o);
            }
            if (m.allCount != null && m.hasOwnProperty("allCount")) {
                d.allCount = m.allCount;
            }
            if (m.approvedCount != null && m.hasOwnProperty("approvedCount")) {
                d.approvedCount = m.approvedCount;
            }
            return d;
        };

        /**
         * Converts this WorkStoreTimetableApproveHistoryCount to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreTimetableApproveHistoryCount
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreTimetableApproveHistoryCount.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreTimetableApproveHistoryCount;
    })();

    Proto.WorkStoreTimetableApproveHistoryCountList = (function() {

        /**
         * Properties of a WorkStoreTimetableApproveHistoryCountList.
         * @memberof Proto
         * @interface IWorkStoreTimetableApproveHistoryCountList
         * @property {Array.<Proto.IWorkStoreTimetableApproveHistoryCount>|null} [value] WorkStoreTimetableApproveHistoryCountList value
         */

        /**
         * Constructs a new WorkStoreTimetableApproveHistoryCountList.
         * @memberof Proto
         * @classdesc Represents a WorkStoreTimetableApproveHistoryCountList.
         * @implements IWorkStoreTimetableApproveHistoryCountList
         * @constructor
         * @param {Proto.IWorkStoreTimetableApproveHistoryCountList=} [p] Properties to set
         */
        function WorkStoreTimetableApproveHistoryCountList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkStoreTimetableApproveHistoryCountList value.
         * @member {Array.<Proto.IWorkStoreTimetableApproveHistoryCount>} value
         * @memberof Proto.WorkStoreTimetableApproveHistoryCountList
         * @instance
         */
        WorkStoreTimetableApproveHistoryCountList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkStoreTimetableApproveHistoryCountList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkStoreTimetableApproveHistoryCountList
         * @static
         * @param {Proto.IWorkStoreTimetableApproveHistoryCountList=} [properties] Properties to set
         * @returns {Proto.WorkStoreTimetableApproveHistoryCountList} WorkStoreTimetableApproveHistoryCountList instance
         */
        WorkStoreTimetableApproveHistoryCountList.create = function create(properties) {
            return new WorkStoreTimetableApproveHistoryCountList(properties);
        };

        /**
         * Encodes the specified WorkStoreTimetableApproveHistoryCountList message. Does not implicitly {@link Proto.WorkStoreTimetableApproveHistoryCountList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkStoreTimetableApproveHistoryCountList
         * @static
         * @param {Proto.IWorkStoreTimetableApproveHistoryCountList} m WorkStoreTimetableApproveHistoryCountList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkStoreTimetableApproveHistoryCountList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkStoreTimetableApproveHistoryCount.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkStoreTimetableApproveHistoryCountList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkStoreTimetableApproveHistoryCountList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkStoreTimetableApproveHistoryCountList} WorkStoreTimetableApproveHistoryCountList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkStoreTimetableApproveHistoryCountList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkStoreTimetableApproveHistoryCountList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkStoreTimetableApproveHistoryCount.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkStoreTimetableApproveHistoryCountList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkStoreTimetableApproveHistoryCountList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkStoreTimetableApproveHistoryCountList} WorkStoreTimetableApproveHistoryCountList
         */
        WorkStoreTimetableApproveHistoryCountList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkStoreTimetableApproveHistoryCountList)
                return d;
            var m = new $root.Proto.WorkStoreTimetableApproveHistoryCountList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkStoreTimetableApproveHistoryCountList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkStoreTimetableApproveHistoryCountList.value: object expected");
                    m.value[i] = $root.Proto.WorkStoreTimetableApproveHistoryCount.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkStoreTimetableApproveHistoryCountList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkStoreTimetableApproveHistoryCountList
         * @static
         * @param {Proto.WorkStoreTimetableApproveHistoryCountList} m WorkStoreTimetableApproveHistoryCountList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkStoreTimetableApproveHistoryCountList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkStoreTimetableApproveHistoryCount.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkStoreTimetableApproveHistoryCountList to JSON.
         * @function toJSON
         * @memberof Proto.WorkStoreTimetableApproveHistoryCountList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkStoreTimetableApproveHistoryCountList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkStoreTimetableApproveHistoryCountList;
    })();

    Proto.WorkflowParams = (function() {

        /**
         * Properties of a WorkflowParams.
         * @memberof Proto
         * @interface IWorkflowParams
         * @property {string|null} [uid] WorkflowParams uid
         * @property {number|null} [type] WorkflowParams type
         * @property {string|null} [title] WorkflowParams title
         * @property {number|Long|null} [registerDate] WorkflowParams registerDate
         * @property {number|Long|null} [modifyDate] WorkflowParams modifyDate
         * @property {Proto.IUser|null} [user] WorkflowParams user
         * @property {Array.<Proto.IHashTag>|null} [adminTagList] WorkflowParams adminTagList
         * @property {Array.<Proto.IWorkflowParamsFormInfo>|null} [formList] WorkflowParams formList
         * @property {Array.<Proto.IWorkflowParamsMap>|null} [mapList] WorkflowParams mapList
         * @property {string|null} [dataCount] WorkflowParams dataCount
         */

        /**
         * Constructs a new WorkflowParams.
         * @memberof Proto
         * @classdesc Represents a WorkflowParams.
         * @implements IWorkflowParams
         * @constructor
         * @param {Proto.IWorkflowParams=} [p] Properties to set
         */
        function WorkflowParams(p) {
            this.adminTagList = [];
            this.formList = [];
            this.mapList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowParams uid.
         * @member {string} uid
         * @memberof Proto.WorkflowParams
         * @instance
         */
        WorkflowParams.prototype.uid = "";

        /**
         * WorkflowParams type.
         * @member {number} type
         * @memberof Proto.WorkflowParams
         * @instance
         */
        WorkflowParams.prototype.type = 0;

        /**
         * WorkflowParams title.
         * @member {string} title
         * @memberof Proto.WorkflowParams
         * @instance
         */
        WorkflowParams.prototype.title = "";

        /**
         * WorkflowParams registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkflowParams
         * @instance
         */
        WorkflowParams.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowParams modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkflowParams
         * @instance
         */
        WorkflowParams.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowParams user.
         * @member {Proto.IUser|null|undefined} user
         * @memberof Proto.WorkflowParams
         * @instance
         */
        WorkflowParams.prototype.user = null;

        /**
         * WorkflowParams adminTagList.
         * @member {Array.<Proto.IHashTag>} adminTagList
         * @memberof Proto.WorkflowParams
         * @instance
         */
        WorkflowParams.prototype.adminTagList = $util.emptyArray;

        /**
         * WorkflowParams formList.
         * @member {Array.<Proto.IWorkflowParamsFormInfo>} formList
         * @memberof Proto.WorkflowParams
         * @instance
         */
        WorkflowParams.prototype.formList = $util.emptyArray;

        /**
         * WorkflowParams mapList.
         * @member {Array.<Proto.IWorkflowParamsMap>} mapList
         * @memberof Proto.WorkflowParams
         * @instance
         */
        WorkflowParams.prototype.mapList = $util.emptyArray;

        /**
         * WorkflowParams dataCount.
         * @member {string} dataCount
         * @memberof Proto.WorkflowParams
         * @instance
         */
        WorkflowParams.prototype.dataCount = "";

        /**
         * Creates a new WorkflowParams instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowParams
         * @static
         * @param {Proto.IWorkflowParams=} [properties] Properties to set
         * @returns {Proto.WorkflowParams} WorkflowParams instance
         */
        WorkflowParams.create = function create(properties) {
            return new WorkflowParams(properties);
        };

        /**
         * Encodes the specified WorkflowParams message. Does not implicitly {@link Proto.WorkflowParams.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowParams
         * @static
         * @param {Proto.IWorkflowParams} m WorkflowParams message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowParams.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.title != null && Object.hasOwnProperty.call(m, "title"))
                w.uint32(26).string(m.title);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            if (m.user != null && Object.hasOwnProperty.call(m, "user"))
                $root.Proto.User.encode(m.user, w.uint32(50).fork()).ldelim();
            if (m.adminTagList != null && m.adminTagList.length) {
                for (var i = 0; i < m.adminTagList.length; ++i)
                    $root.Proto.HashTag.encode(m.adminTagList[i], w.uint32(58).fork()).ldelim();
            }
            if (m.formList != null && m.formList.length) {
                for (var i = 0; i < m.formList.length; ++i)
                    $root.Proto.WorkflowParamsFormInfo.encode(m.formList[i], w.uint32(66).fork()).ldelim();
            }
            if (m.mapList != null && m.mapList.length) {
                for (var i = 0; i < m.mapList.length; ++i)
                    $root.Proto.WorkflowParamsMap.encode(m.mapList[i], w.uint32(74).fork()).ldelim();
            }
            if (m.dataCount != null && Object.hasOwnProperty.call(m, "dataCount"))
                w.uint32(82).string(m.dataCount);
            return w;
        };

        /**
         * Decodes a WorkflowParams message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowParams
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowParams} WorkflowParams
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowParams.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowParams();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.title = r.string();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                case 6:
                    m.user = $root.Proto.User.decode(r, r.uint32());
                    break;
                case 7:
                    if (!(m.adminTagList && m.adminTagList.length))
                        m.adminTagList = [];
                    m.adminTagList.push($root.Proto.HashTag.decode(r, r.uint32()));
                    break;
                case 8:
                    if (!(m.formList && m.formList.length))
                        m.formList = [];
                    m.formList.push($root.Proto.WorkflowParamsFormInfo.decode(r, r.uint32()));
                    break;
                case 9:
                    if (!(m.mapList && m.mapList.length))
                        m.mapList = [];
                    m.mapList.push($root.Proto.WorkflowParamsMap.decode(r, r.uint32()));
                    break;
                case 10:
                    m.dataCount = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowParams message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowParams
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowParams} WorkflowParams
         */
        WorkflowParams.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowParams)
                return d;
            var m = new $root.Proto.WorkflowParams();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.user != null) {
                if (typeof d.user !== "object")
                    throw TypeError(".Proto.WorkflowParams.user: object expected");
                m.user = $root.Proto.User.fromObject(d.user);
            }
            if (d.adminTagList) {
                if (!Array.isArray(d.adminTagList))
                    throw TypeError(".Proto.WorkflowParams.adminTagList: array expected");
                m.adminTagList = [];
                for (var i = 0; i < d.adminTagList.length; ++i) {
                    if (typeof d.adminTagList[i] !== "object")
                        throw TypeError(".Proto.WorkflowParams.adminTagList: object expected");
                    m.adminTagList[i] = $root.Proto.HashTag.fromObject(d.adminTagList[i]);
                }
            }
            if (d.formList) {
                if (!Array.isArray(d.formList))
                    throw TypeError(".Proto.WorkflowParams.formList: array expected");
                m.formList = [];
                for (var i = 0; i < d.formList.length; ++i) {
                    if (typeof d.formList[i] !== "object")
                        throw TypeError(".Proto.WorkflowParams.formList: object expected");
                    m.formList[i] = $root.Proto.WorkflowParamsFormInfo.fromObject(d.formList[i]);
                }
            }
            if (d.mapList) {
                if (!Array.isArray(d.mapList))
                    throw TypeError(".Proto.WorkflowParams.mapList: array expected");
                m.mapList = [];
                for (var i = 0; i < d.mapList.length; ++i) {
                    if (typeof d.mapList[i] !== "object")
                        throw TypeError(".Proto.WorkflowParams.mapList: object expected");
                    m.mapList[i] = $root.Proto.WorkflowParamsMap.fromObject(d.mapList[i]);
                }
            }
            if (d.dataCount != null) {
                m.dataCount = String(d.dataCount);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowParams message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowParams
         * @static
         * @param {Proto.WorkflowParams} m WorkflowParams
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowParams.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.adminTagList = [];
                d.formList = [];
                d.mapList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.title = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.user = null;
                d.dataCount = "";
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.user != null && m.hasOwnProperty("user")) {
                d.user = $root.Proto.User.toObject(m.user, o);
            }
            if (m.adminTagList && m.adminTagList.length) {
                d.adminTagList = [];
                for (var j = 0; j < m.adminTagList.length; ++j) {
                    d.adminTagList[j] = $root.Proto.HashTag.toObject(m.adminTagList[j], o);
                }
            }
            if (m.formList && m.formList.length) {
                d.formList = [];
                for (var j = 0; j < m.formList.length; ++j) {
                    d.formList[j] = $root.Proto.WorkflowParamsFormInfo.toObject(m.formList[j], o);
                }
            }
            if (m.mapList && m.mapList.length) {
                d.mapList = [];
                for (var j = 0; j < m.mapList.length; ++j) {
                    d.mapList[j] = $root.Proto.WorkflowParamsMap.toObject(m.mapList[j], o);
                }
            }
            if (m.dataCount != null && m.hasOwnProperty("dataCount")) {
                d.dataCount = m.dataCount;
            }
            return d;
        };

        /**
         * Converts this WorkflowParams to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowParams
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowParams.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowParams;
    })();

    Proto.WorkflowParamsList = (function() {

        /**
         * Properties of a WorkflowParamsList.
         * @memberof Proto
         * @interface IWorkflowParamsList
         * @property {Array.<Proto.IWorkflowParams>|null} [value] WorkflowParamsList value
         */

        /**
         * Constructs a new WorkflowParamsList.
         * @memberof Proto
         * @classdesc Represents a WorkflowParamsList.
         * @implements IWorkflowParamsList
         * @constructor
         * @param {Proto.IWorkflowParamsList=} [p] Properties to set
         */
        function WorkflowParamsList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowParamsList value.
         * @member {Array.<Proto.IWorkflowParams>} value
         * @memberof Proto.WorkflowParamsList
         * @instance
         */
        WorkflowParamsList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkflowParamsList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowParamsList
         * @static
         * @param {Proto.IWorkflowParamsList=} [properties] Properties to set
         * @returns {Proto.WorkflowParamsList} WorkflowParamsList instance
         */
        WorkflowParamsList.create = function create(properties) {
            return new WorkflowParamsList(properties);
        };

        /**
         * Encodes the specified WorkflowParamsList message. Does not implicitly {@link Proto.WorkflowParamsList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowParamsList
         * @static
         * @param {Proto.IWorkflowParamsList} m WorkflowParamsList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowParamsList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkflowParams.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowParamsList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowParamsList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowParamsList} WorkflowParamsList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowParamsList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowParamsList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkflowParams.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowParamsList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowParamsList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowParamsList} WorkflowParamsList
         */
        WorkflowParamsList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowParamsList)
                return d;
            var m = new $root.Proto.WorkflowParamsList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkflowParamsList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkflowParamsList.value: object expected");
                    m.value[i] = $root.Proto.WorkflowParams.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowParamsList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowParamsList
         * @static
         * @param {Proto.WorkflowParamsList} m WorkflowParamsList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowParamsList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkflowParams.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowParamsList to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowParamsList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowParamsList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowParamsList;
    })();

    Proto.WorkflowParamsFormInfo = (function() {

        /**
         * Properties of a WorkflowParamsFormInfo.
         * @memberof Proto
         * @interface IWorkflowParamsFormInfo
         * @property {string|null} [uid] WorkflowParamsFormInfo uid
         * @property {number|null} [type] WorkflowParamsFormInfo type
         * @property {string|null} [name] WorkflowParamsFormInfo name
         * @property {boolean|null} [optional] WorkflowParamsFormInfo optional
         * @property {string|null} [setting] WorkflowParamsFormInfo setting
         * @property {string|null} [parent] WorkflowParamsFormInfo parent
         * @property {number|null} [order] WorkflowParamsFormInfo order
         * @property {number|Long|null} [registerDate] WorkflowParamsFormInfo registerDate
         * @property {number|Long|null} [modifyDate] WorkflowParamsFormInfo modifyDate
         * @property {number|null} [status] WorkflowParamsFormInfo status
         * @property {boolean|null} [preview] WorkflowParamsFormInfo preview
         */

        /**
         * Constructs a new WorkflowParamsFormInfo.
         * @memberof Proto
         * @classdesc Represents a WorkflowParamsFormInfo.
         * @implements IWorkflowParamsFormInfo
         * @constructor
         * @param {Proto.IWorkflowParamsFormInfo=} [p] Properties to set
         */
        function WorkflowParamsFormInfo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowParamsFormInfo uid.
         * @member {string} uid
         * @memberof Proto.WorkflowParamsFormInfo
         * @instance
         */
        WorkflowParamsFormInfo.prototype.uid = "";

        /**
         * WorkflowParamsFormInfo type.
         * @member {number} type
         * @memberof Proto.WorkflowParamsFormInfo
         * @instance
         */
        WorkflowParamsFormInfo.prototype.type = 0;

        /**
         * WorkflowParamsFormInfo name.
         * @member {string} name
         * @memberof Proto.WorkflowParamsFormInfo
         * @instance
         */
        WorkflowParamsFormInfo.prototype.name = "";

        /**
         * WorkflowParamsFormInfo optional.
         * @member {boolean} optional
         * @memberof Proto.WorkflowParamsFormInfo
         * @instance
         */
        WorkflowParamsFormInfo.prototype.optional = false;

        /**
         * WorkflowParamsFormInfo setting.
         * @member {string} setting
         * @memberof Proto.WorkflowParamsFormInfo
         * @instance
         */
        WorkflowParamsFormInfo.prototype.setting = "";

        /**
         * WorkflowParamsFormInfo parent.
         * @member {string} parent
         * @memberof Proto.WorkflowParamsFormInfo
         * @instance
         */
        WorkflowParamsFormInfo.prototype.parent = "";

        /**
         * WorkflowParamsFormInfo order.
         * @member {number} order
         * @memberof Proto.WorkflowParamsFormInfo
         * @instance
         */
        WorkflowParamsFormInfo.prototype.order = 0;

        /**
         * WorkflowParamsFormInfo registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkflowParamsFormInfo
         * @instance
         */
        WorkflowParamsFormInfo.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowParamsFormInfo modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkflowParamsFormInfo
         * @instance
         */
        WorkflowParamsFormInfo.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowParamsFormInfo status.
         * @member {number} status
         * @memberof Proto.WorkflowParamsFormInfo
         * @instance
         */
        WorkflowParamsFormInfo.prototype.status = 0;

        /**
         * WorkflowParamsFormInfo preview.
         * @member {boolean} preview
         * @memberof Proto.WorkflowParamsFormInfo
         * @instance
         */
        WorkflowParamsFormInfo.prototype.preview = false;

        /**
         * Creates a new WorkflowParamsFormInfo instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowParamsFormInfo
         * @static
         * @param {Proto.IWorkflowParamsFormInfo=} [properties] Properties to set
         * @returns {Proto.WorkflowParamsFormInfo} WorkflowParamsFormInfo instance
         */
        WorkflowParamsFormInfo.create = function create(properties) {
            return new WorkflowParamsFormInfo(properties);
        };

        /**
         * Encodes the specified WorkflowParamsFormInfo message. Does not implicitly {@link Proto.WorkflowParamsFormInfo.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowParamsFormInfo
         * @static
         * @param {Proto.IWorkflowParamsFormInfo} m WorkflowParamsFormInfo message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowParamsFormInfo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(16).int32(m.type);
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(26).string(m.name);
            if (m.optional != null && Object.hasOwnProperty.call(m, "optional"))
                w.uint32(32).bool(m.optional);
            if (m.setting != null && Object.hasOwnProperty.call(m, "setting"))
                w.uint32(42).string(m.setting);
            if (m.parent != null && Object.hasOwnProperty.call(m, "parent"))
                w.uint32(50).string(m.parent);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(56).int32(m.order);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(64).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(72).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(80).int32(m.status);
            if (m.preview != null && Object.hasOwnProperty.call(m, "preview"))
                w.uint32(88).bool(m.preview);
            return w;
        };

        /**
         * Decodes a WorkflowParamsFormInfo message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowParamsFormInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowParamsFormInfo} WorkflowParamsFormInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowParamsFormInfo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowParamsFormInfo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.type = r.int32();
                    break;
                case 3:
                    m.name = r.string();
                    break;
                case 4:
                    m.optional = r.bool();
                    break;
                case 5:
                    m.setting = r.string();
                    break;
                case 6:
                    m.parent = r.string();
                    break;
                case 7:
                    m.order = r.int32();
                    break;
                case 8:
                    m.registerDate = r.int64();
                    break;
                case 9:
                    m.modifyDate = r.int64();
                    break;
                case 10:
                    m.status = r.int32();
                    break;
                case 11:
                    m.preview = r.bool();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowParamsFormInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowParamsFormInfo
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowParamsFormInfo} WorkflowParamsFormInfo
         */
        WorkflowParamsFormInfo.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowParamsFormInfo)
                return d;
            var m = new $root.Proto.WorkflowParamsFormInfo();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.optional != null) {
                m.optional = Boolean(d.optional);
            }
            if (d.setting != null) {
                m.setting = String(d.setting);
            }
            if (d.parent != null) {
                m.parent = String(d.parent);
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.preview != null) {
                m.preview = Boolean(d.preview);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowParamsFormInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowParamsFormInfo
         * @static
         * @param {Proto.WorkflowParamsFormInfo} m WorkflowParamsFormInfo
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowParamsFormInfo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.type = 0;
                d.name = "";
                d.optional = false;
                d.setting = "";
                d.parent = "";
                d.order = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.preview = false;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.optional != null && m.hasOwnProperty("optional")) {
                d.optional = m.optional;
            }
            if (m.setting != null && m.hasOwnProperty("setting")) {
                d.setting = m.setting;
            }
            if (m.parent != null && m.hasOwnProperty("parent")) {
                d.parent = m.parent;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.preview != null && m.hasOwnProperty("preview")) {
                d.preview = m.preview;
            }
            return d;
        };

        /**
         * Converts this WorkflowParamsFormInfo to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowParamsFormInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowParamsFormInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowParamsFormInfo;
    })();

    Proto.WorkflowParamsFormInfoList = (function() {

        /**
         * Properties of a WorkflowParamsFormInfoList.
         * @memberof Proto
         * @interface IWorkflowParamsFormInfoList
         * @property {Array.<Proto.IWorkflowParamsFormInfo>|null} [value] WorkflowParamsFormInfoList value
         */

        /**
         * Constructs a new WorkflowParamsFormInfoList.
         * @memberof Proto
         * @classdesc Represents a WorkflowParamsFormInfoList.
         * @implements IWorkflowParamsFormInfoList
         * @constructor
         * @param {Proto.IWorkflowParamsFormInfoList=} [p] Properties to set
         */
        function WorkflowParamsFormInfoList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowParamsFormInfoList value.
         * @member {Array.<Proto.IWorkflowParamsFormInfo>} value
         * @memberof Proto.WorkflowParamsFormInfoList
         * @instance
         */
        WorkflowParamsFormInfoList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkflowParamsFormInfoList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowParamsFormInfoList
         * @static
         * @param {Proto.IWorkflowParamsFormInfoList=} [properties] Properties to set
         * @returns {Proto.WorkflowParamsFormInfoList} WorkflowParamsFormInfoList instance
         */
        WorkflowParamsFormInfoList.create = function create(properties) {
            return new WorkflowParamsFormInfoList(properties);
        };

        /**
         * Encodes the specified WorkflowParamsFormInfoList message. Does not implicitly {@link Proto.WorkflowParamsFormInfoList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowParamsFormInfoList
         * @static
         * @param {Proto.IWorkflowParamsFormInfoList} m WorkflowParamsFormInfoList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowParamsFormInfoList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkflowParamsFormInfo.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowParamsFormInfoList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowParamsFormInfoList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowParamsFormInfoList} WorkflowParamsFormInfoList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowParamsFormInfoList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowParamsFormInfoList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkflowParamsFormInfo.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowParamsFormInfoList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowParamsFormInfoList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowParamsFormInfoList} WorkflowParamsFormInfoList
         */
        WorkflowParamsFormInfoList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowParamsFormInfoList)
                return d;
            var m = new $root.Proto.WorkflowParamsFormInfoList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkflowParamsFormInfoList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkflowParamsFormInfoList.value: object expected");
                    m.value[i] = $root.Proto.WorkflowParamsFormInfo.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowParamsFormInfoList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowParamsFormInfoList
         * @static
         * @param {Proto.WorkflowParamsFormInfoList} m WorkflowParamsFormInfoList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowParamsFormInfoList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkflowParamsFormInfo.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowParamsFormInfoList to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowParamsFormInfoList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowParamsFormInfoList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowParamsFormInfoList;
    })();

    Proto.WorkflowParamsAnswer = (function() {

        /**
         * Properties of a WorkflowParamsAnswer.
         * @memberof Proto
         * @interface IWorkflowParamsAnswer
         * @property {string|null} [uid] WorkflowParamsAnswer uid
         * @property {number|null} [order] WorkflowParamsAnswer order
         * @property {number|Long|null} [registerDate] WorkflowParamsAnswer registerDate
         * @property {number|Long|null} [modifyDate] WorkflowParamsAnswer modifyDate
         * @property {Array.<Proto.IWorkflowParamsAnswerInfo>|null} [infoList] WorkflowParamsAnswer infoList
         */

        /**
         * Constructs a new WorkflowParamsAnswer.
         * @memberof Proto
         * @classdesc Represents a WorkflowParamsAnswer.
         * @implements IWorkflowParamsAnswer
         * @constructor
         * @param {Proto.IWorkflowParamsAnswer=} [p] Properties to set
         */
        function WorkflowParamsAnswer(p) {
            this.infoList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowParamsAnswer uid.
         * @member {string} uid
         * @memberof Proto.WorkflowParamsAnswer
         * @instance
         */
        WorkflowParamsAnswer.prototype.uid = "";

        /**
         * WorkflowParamsAnswer order.
         * @member {number} order
         * @memberof Proto.WorkflowParamsAnswer
         * @instance
         */
        WorkflowParamsAnswer.prototype.order = 0;

        /**
         * WorkflowParamsAnswer registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkflowParamsAnswer
         * @instance
         */
        WorkflowParamsAnswer.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowParamsAnswer modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkflowParamsAnswer
         * @instance
         */
        WorkflowParamsAnswer.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowParamsAnswer infoList.
         * @member {Array.<Proto.IWorkflowParamsAnswerInfo>} infoList
         * @memberof Proto.WorkflowParamsAnswer
         * @instance
         */
        WorkflowParamsAnswer.prototype.infoList = $util.emptyArray;

        /**
         * Creates a new WorkflowParamsAnswer instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowParamsAnswer
         * @static
         * @param {Proto.IWorkflowParamsAnswer=} [properties] Properties to set
         * @returns {Proto.WorkflowParamsAnswer} WorkflowParamsAnswer instance
         */
        WorkflowParamsAnswer.create = function create(properties) {
            return new WorkflowParamsAnswer(properties);
        };

        /**
         * Encodes the specified WorkflowParamsAnswer message. Does not implicitly {@link Proto.WorkflowParamsAnswer.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowParamsAnswer
         * @static
         * @param {Proto.IWorkflowParamsAnswer} m WorkflowParamsAnswer message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowParamsAnswer.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(16).int32(m.order);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(32).int64(m.modifyDate);
            if (m.infoList != null && m.infoList.length) {
                for (var i = 0; i < m.infoList.length; ++i)
                    $root.Proto.WorkflowParamsAnswerInfo.encode(m.infoList[i], w.uint32(42).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowParamsAnswer message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowParamsAnswer
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowParamsAnswer} WorkflowParamsAnswer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowParamsAnswer.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowParamsAnswer();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.order = r.int32();
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    m.modifyDate = r.int64();
                    break;
                case 5:
                    if (!(m.infoList && m.infoList.length))
                        m.infoList = [];
                    m.infoList.push($root.Proto.WorkflowParamsAnswerInfo.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowParamsAnswer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowParamsAnswer
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowParamsAnswer} WorkflowParamsAnswer
         */
        WorkflowParamsAnswer.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowParamsAnswer)
                return d;
            var m = new $root.Proto.WorkflowParamsAnswer();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.order != null) {
                m.order = d.order | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.infoList) {
                if (!Array.isArray(d.infoList))
                    throw TypeError(".Proto.WorkflowParamsAnswer.infoList: array expected");
                m.infoList = [];
                for (var i = 0; i < d.infoList.length; ++i) {
                    if (typeof d.infoList[i] !== "object")
                        throw TypeError(".Proto.WorkflowParamsAnswer.infoList: object expected");
                    m.infoList[i] = $root.Proto.WorkflowParamsAnswerInfo.fromObject(d.infoList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowParamsAnswer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowParamsAnswer
         * @static
         * @param {Proto.WorkflowParamsAnswer} m WorkflowParamsAnswer
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowParamsAnswer.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.infoList = [];
            }
            if (o.defaults) {
                d.uid = "";
                d.order = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = m.order;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.infoList && m.infoList.length) {
                d.infoList = [];
                for (var j = 0; j < m.infoList.length; ++j) {
                    d.infoList[j] = $root.Proto.WorkflowParamsAnswerInfo.toObject(m.infoList[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowParamsAnswer to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowParamsAnswer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowParamsAnswer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowParamsAnswer;
    })();

    Proto.WorkflowParamsAnswerList = (function() {

        /**
         * Properties of a WorkflowParamsAnswerList.
         * @memberof Proto
         * @interface IWorkflowParamsAnswerList
         * @property {Array.<Proto.IWorkflowParamsAnswer>|null} [value] WorkflowParamsAnswerList value
         */

        /**
         * Constructs a new WorkflowParamsAnswerList.
         * @memberof Proto
         * @classdesc Represents a WorkflowParamsAnswerList.
         * @implements IWorkflowParamsAnswerList
         * @constructor
         * @param {Proto.IWorkflowParamsAnswerList=} [p] Properties to set
         */
        function WorkflowParamsAnswerList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowParamsAnswerList value.
         * @member {Array.<Proto.IWorkflowParamsAnswer>} value
         * @memberof Proto.WorkflowParamsAnswerList
         * @instance
         */
        WorkflowParamsAnswerList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkflowParamsAnswerList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowParamsAnswerList
         * @static
         * @param {Proto.IWorkflowParamsAnswerList=} [properties] Properties to set
         * @returns {Proto.WorkflowParamsAnswerList} WorkflowParamsAnswerList instance
         */
        WorkflowParamsAnswerList.create = function create(properties) {
            return new WorkflowParamsAnswerList(properties);
        };

        /**
         * Encodes the specified WorkflowParamsAnswerList message. Does not implicitly {@link Proto.WorkflowParamsAnswerList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowParamsAnswerList
         * @static
         * @param {Proto.IWorkflowParamsAnswerList} m WorkflowParamsAnswerList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowParamsAnswerList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkflowParamsAnswer.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowParamsAnswerList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowParamsAnswerList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowParamsAnswerList} WorkflowParamsAnswerList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowParamsAnswerList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowParamsAnswerList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkflowParamsAnswer.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowParamsAnswerList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowParamsAnswerList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowParamsAnswerList} WorkflowParamsAnswerList
         */
        WorkflowParamsAnswerList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowParamsAnswerList)
                return d;
            var m = new $root.Proto.WorkflowParamsAnswerList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkflowParamsAnswerList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkflowParamsAnswerList.value: object expected");
                    m.value[i] = $root.Proto.WorkflowParamsAnswer.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowParamsAnswerList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowParamsAnswerList
         * @static
         * @param {Proto.WorkflowParamsAnswerList} m WorkflowParamsAnswerList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowParamsAnswerList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkflowParamsAnswer.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowParamsAnswerList to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowParamsAnswerList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowParamsAnswerList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowParamsAnswerList;
    })();

    Proto.WorkflowParamsAnswerInfo = (function() {

        /**
         * Properties of a WorkflowParamsAnswerInfo.
         * @memberof Proto
         * @interface IWorkflowParamsAnswerInfo
         * @property {string|null} [userUID] WorkflowParamsAnswerInfo userUID
         * @property {string|null} [infoUID] WorkflowParamsAnswerInfo infoUID
         * @property {string|null} [text] WorkflowParamsAnswerInfo text
         * @property {number|null} [number] WorkflowParamsAnswerInfo number
         * @property {number|Long|null} [date] WorkflowParamsAnswerInfo date
         * @property {number|Long|null} [registerDate] WorkflowParamsAnswerInfo registerDate
         * @property {number|Long|null} [modifyDate] WorkflowParamsAnswerInfo modifyDate
         * @property {Proto.IUploadFile|null} [file] WorkflowParamsAnswerInfo file
         * @property {string|null} [additional] WorkflowParamsAnswerInfo additional
         * @property {boolean|null} [readonly] WorkflowParamsAnswerInfo readonly
         * @property {string|null} [json] WorkflowParamsAnswerInfo json
         */

        /**
         * Constructs a new WorkflowParamsAnswerInfo.
         * @memberof Proto
         * @classdesc Represents a WorkflowParamsAnswerInfo.
         * @implements IWorkflowParamsAnswerInfo
         * @constructor
         * @param {Proto.IWorkflowParamsAnswerInfo=} [p] Properties to set
         */
        function WorkflowParamsAnswerInfo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowParamsAnswerInfo userUID.
         * @member {string} userUID
         * @memberof Proto.WorkflowParamsAnswerInfo
         * @instance
         */
        WorkflowParamsAnswerInfo.prototype.userUID = "";

        /**
         * WorkflowParamsAnswerInfo infoUID.
         * @member {string} infoUID
         * @memberof Proto.WorkflowParamsAnswerInfo
         * @instance
         */
        WorkflowParamsAnswerInfo.prototype.infoUID = "";

        /**
         * WorkflowParamsAnswerInfo text.
         * @member {string} text
         * @memberof Proto.WorkflowParamsAnswerInfo
         * @instance
         */
        WorkflowParamsAnswerInfo.prototype.text = "";

        /**
         * WorkflowParamsAnswerInfo number.
         * @member {number} number
         * @memberof Proto.WorkflowParamsAnswerInfo
         * @instance
         */
        WorkflowParamsAnswerInfo.prototype.number = 0;

        /**
         * WorkflowParamsAnswerInfo date.
         * @member {number|Long} date
         * @memberof Proto.WorkflowParamsAnswerInfo
         * @instance
         */
        WorkflowParamsAnswerInfo.prototype.date = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowParamsAnswerInfo registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkflowParamsAnswerInfo
         * @instance
         */
        WorkflowParamsAnswerInfo.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowParamsAnswerInfo modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkflowParamsAnswerInfo
         * @instance
         */
        WorkflowParamsAnswerInfo.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowParamsAnswerInfo file.
         * @member {Proto.IUploadFile|null|undefined} file
         * @memberof Proto.WorkflowParamsAnswerInfo
         * @instance
         */
        WorkflowParamsAnswerInfo.prototype.file = null;

        /**
         * WorkflowParamsAnswerInfo additional.
         * @member {string} additional
         * @memberof Proto.WorkflowParamsAnswerInfo
         * @instance
         */
        WorkflowParamsAnswerInfo.prototype.additional = "";

        /**
         * WorkflowParamsAnswerInfo readonly.
         * @member {boolean} readonly
         * @memberof Proto.WorkflowParamsAnswerInfo
         * @instance
         */
        WorkflowParamsAnswerInfo.prototype.readonly = false;

        /**
         * WorkflowParamsAnswerInfo json.
         * @member {string} json
         * @memberof Proto.WorkflowParamsAnswerInfo
         * @instance
         */
        WorkflowParamsAnswerInfo.prototype.json = "";

        /**
         * Creates a new WorkflowParamsAnswerInfo instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowParamsAnswerInfo
         * @static
         * @param {Proto.IWorkflowParamsAnswerInfo=} [properties] Properties to set
         * @returns {Proto.WorkflowParamsAnswerInfo} WorkflowParamsAnswerInfo instance
         */
        WorkflowParamsAnswerInfo.create = function create(properties) {
            return new WorkflowParamsAnswerInfo(properties);
        };

        /**
         * Encodes the specified WorkflowParamsAnswerInfo message. Does not implicitly {@link Proto.WorkflowParamsAnswerInfo.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowParamsAnswerInfo
         * @static
         * @param {Proto.IWorkflowParamsAnswerInfo} m WorkflowParamsAnswerInfo message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowParamsAnswerInfo.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(10).string(m.userUID);
            if (m.infoUID != null && Object.hasOwnProperty.call(m, "infoUID"))
                w.uint32(18).string(m.infoUID);
            if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                w.uint32(26).string(m.text);
            if (m.number != null && Object.hasOwnProperty.call(m, "number"))
                w.uint32(32).int32(m.number);
            if (m.date != null && Object.hasOwnProperty.call(m, "date"))
                w.uint32(40).int64(m.date);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            if (m.file != null && Object.hasOwnProperty.call(m, "file"))
                $root.Proto.UploadFile.encode(m.file, w.uint32(66).fork()).ldelim();
            if (m.additional != null && Object.hasOwnProperty.call(m, "additional"))
                w.uint32(74).string(m.additional);
            if (m.readonly != null && Object.hasOwnProperty.call(m, "readonly"))
                w.uint32(80).bool(m.readonly);
            if (m.json != null && Object.hasOwnProperty.call(m, "json"))
                w.uint32(90).string(m.json);
            return w;
        };

        /**
         * Decodes a WorkflowParamsAnswerInfo message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowParamsAnswerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowParamsAnswerInfo} WorkflowParamsAnswerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowParamsAnswerInfo.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowParamsAnswerInfo();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.userUID = r.string();
                    break;
                case 2:
                    m.infoUID = r.string();
                    break;
                case 3:
                    m.text = r.string();
                    break;
                case 4:
                    m.number = r.int32();
                    break;
                case 5:
                    m.date = r.int64();
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                case 8:
                    m.file = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 9:
                    m.additional = r.string();
                    break;
                case 10:
                    m.readonly = r.bool();
                    break;
                case 11:
                    m.json = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowParamsAnswerInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowParamsAnswerInfo
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowParamsAnswerInfo} WorkflowParamsAnswerInfo
         */
        WorkflowParamsAnswerInfo.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowParamsAnswerInfo)
                return d;
            var m = new $root.Proto.WorkflowParamsAnswerInfo();
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.infoUID != null) {
                m.infoUID = String(d.infoUID);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.number != null) {
                m.number = d.number | 0;
            }
            if (d.date != null) {
                if ($util.Long)
                    (m.date = $util.Long.fromValue(d.date)).unsigned = false;
                else if (typeof d.date === "string")
                    m.date = parseInt(d.date, 10);
                else if (typeof d.date === "number")
                    m.date = d.date;
                else if (typeof d.date === "object")
                    m.date = new $util.LongBits(d.date.low >>> 0, d.date.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.file != null) {
                if (typeof d.file !== "object")
                    throw TypeError(".Proto.WorkflowParamsAnswerInfo.file: object expected");
                m.file = $root.Proto.UploadFile.fromObject(d.file);
            }
            if (d.additional != null) {
                m.additional = String(d.additional);
            }
            if (d.readonly != null) {
                m.readonly = Boolean(d.readonly);
            }
            if (d.json != null) {
                m.json = String(d.json);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowParamsAnswerInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowParamsAnswerInfo
         * @static
         * @param {Proto.WorkflowParamsAnswerInfo} m WorkflowParamsAnswerInfo
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowParamsAnswerInfo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.userUID = "";
                d.infoUID = "";
                d.text = "";
                d.number = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.date = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.date = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.file = null;
                d.additional = "";
                d.readonly = false;
                d.json = "";
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.infoUID != null && m.hasOwnProperty("infoUID")) {
                d.infoUID = m.infoUID;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.number != null && m.hasOwnProperty("number")) {
                d.number = m.number;
            }
            if (m.date != null && m.hasOwnProperty("date")) {
                if (typeof m.date === "number")
                    d.date = o.longs === String ? String(m.date) : m.date;
                else
                    d.date = o.longs === String ? $util.Long.prototype.toString.call(m.date) : o.longs === Number ? new $util.LongBits(m.date.low >>> 0, m.date.high >>> 0).toNumber() : m.date;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.file != null && m.hasOwnProperty("file")) {
                d.file = $root.Proto.UploadFile.toObject(m.file, o);
            }
            if (m.additional != null && m.hasOwnProperty("additional")) {
                d.additional = m.additional;
            }
            if (m.readonly != null && m.hasOwnProperty("readonly")) {
                d.readonly = m.readonly;
            }
            if (m.json != null && m.hasOwnProperty("json")) {
                d.json = m.json;
            }
            return d;
        };

        /**
         * Converts this WorkflowParamsAnswerInfo to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowParamsAnswerInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowParamsAnswerInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowParamsAnswerInfo;
    })();

    Proto.WorkflowParamsAnswerInfoList = (function() {

        /**
         * Properties of a WorkflowParamsAnswerInfoList.
         * @memberof Proto
         * @interface IWorkflowParamsAnswerInfoList
         * @property {Array.<Proto.IWorkflowParamsAnswerInfo>|null} [value] WorkflowParamsAnswerInfoList value
         */

        /**
         * Constructs a new WorkflowParamsAnswerInfoList.
         * @memberof Proto
         * @classdesc Represents a WorkflowParamsAnswerInfoList.
         * @implements IWorkflowParamsAnswerInfoList
         * @constructor
         * @param {Proto.IWorkflowParamsAnswerInfoList=} [p] Properties to set
         */
        function WorkflowParamsAnswerInfoList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowParamsAnswerInfoList value.
         * @member {Array.<Proto.IWorkflowParamsAnswerInfo>} value
         * @memberof Proto.WorkflowParamsAnswerInfoList
         * @instance
         */
        WorkflowParamsAnswerInfoList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkflowParamsAnswerInfoList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowParamsAnswerInfoList
         * @static
         * @param {Proto.IWorkflowParamsAnswerInfoList=} [properties] Properties to set
         * @returns {Proto.WorkflowParamsAnswerInfoList} WorkflowParamsAnswerInfoList instance
         */
        WorkflowParamsAnswerInfoList.create = function create(properties) {
            return new WorkflowParamsAnswerInfoList(properties);
        };

        /**
         * Encodes the specified WorkflowParamsAnswerInfoList message. Does not implicitly {@link Proto.WorkflowParamsAnswerInfoList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowParamsAnswerInfoList
         * @static
         * @param {Proto.IWorkflowParamsAnswerInfoList} m WorkflowParamsAnswerInfoList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowParamsAnswerInfoList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkflowParamsAnswerInfo.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowParamsAnswerInfoList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowParamsAnswerInfoList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowParamsAnswerInfoList} WorkflowParamsAnswerInfoList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowParamsAnswerInfoList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowParamsAnswerInfoList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkflowParamsAnswerInfo.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowParamsAnswerInfoList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowParamsAnswerInfoList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowParamsAnswerInfoList} WorkflowParamsAnswerInfoList
         */
        WorkflowParamsAnswerInfoList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowParamsAnswerInfoList)
                return d;
            var m = new $root.Proto.WorkflowParamsAnswerInfoList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkflowParamsAnswerInfoList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkflowParamsAnswerInfoList.value: object expected");
                    m.value[i] = $root.Proto.WorkflowParamsAnswerInfo.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowParamsAnswerInfoList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowParamsAnswerInfoList
         * @static
         * @param {Proto.WorkflowParamsAnswerInfoList} m WorkflowParamsAnswerInfoList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowParamsAnswerInfoList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkflowParamsAnswerInfo.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowParamsAnswerInfoList to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowParamsAnswerInfoList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowParamsAnswerInfoList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowParamsAnswerInfoList;
    })();

    Proto.WorkflowParamsMap = (function() {

        /**
         * Properties of a WorkflowParamsMap.
         * @memberof Proto
         * @interface IWorkflowParamsMap
         * @property {string|null} [paramsUID] WorkflowParamsMap paramsUID
         * @property {string|null} [builderUID] WorkflowParamsMap builderUID
         * @property {number|Long|null} [registerDate] WorkflowParamsMap registerDate
         * @property {number|Long|null} [modifyDate] WorkflowParamsMap modifyDate
         * @property {number|null} [status] WorkflowParamsMap status
         * @property {string|null} [builderTitle] WorkflowParamsMap builderTitle
         * @property {Proto.IWorkflowBuilder|null} [builder] WorkflowParamsMap builder
         * @property {Proto.IUploadFile|null} [builderFile] WorkflowParamsMap builderFile
         */

        /**
         * Constructs a new WorkflowParamsMap.
         * @memberof Proto
         * @classdesc Represents a WorkflowParamsMap.
         * @implements IWorkflowParamsMap
         * @constructor
         * @param {Proto.IWorkflowParamsMap=} [p] Properties to set
         */
        function WorkflowParamsMap(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowParamsMap paramsUID.
         * @member {string} paramsUID
         * @memberof Proto.WorkflowParamsMap
         * @instance
         */
        WorkflowParamsMap.prototype.paramsUID = "";

        /**
         * WorkflowParamsMap builderUID.
         * @member {string} builderUID
         * @memberof Proto.WorkflowParamsMap
         * @instance
         */
        WorkflowParamsMap.prototype.builderUID = "";

        /**
         * WorkflowParamsMap registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkflowParamsMap
         * @instance
         */
        WorkflowParamsMap.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowParamsMap modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkflowParamsMap
         * @instance
         */
        WorkflowParamsMap.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowParamsMap status.
         * @member {number} status
         * @memberof Proto.WorkflowParamsMap
         * @instance
         */
        WorkflowParamsMap.prototype.status = 0;

        /**
         * WorkflowParamsMap builderTitle.
         * @member {string} builderTitle
         * @memberof Proto.WorkflowParamsMap
         * @instance
         */
        WorkflowParamsMap.prototype.builderTitle = "";

        /**
         * WorkflowParamsMap builder.
         * @member {Proto.IWorkflowBuilder|null|undefined} builder
         * @memberof Proto.WorkflowParamsMap
         * @instance
         */
        WorkflowParamsMap.prototype.builder = null;

        /**
         * WorkflowParamsMap builderFile.
         * @member {Proto.IUploadFile|null|undefined} builderFile
         * @memberof Proto.WorkflowParamsMap
         * @instance
         */
        WorkflowParamsMap.prototype.builderFile = null;

        /**
         * Creates a new WorkflowParamsMap instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowParamsMap
         * @static
         * @param {Proto.IWorkflowParamsMap=} [properties] Properties to set
         * @returns {Proto.WorkflowParamsMap} WorkflowParamsMap instance
         */
        WorkflowParamsMap.create = function create(properties) {
            return new WorkflowParamsMap(properties);
        };

        /**
         * Encodes the specified WorkflowParamsMap message. Does not implicitly {@link Proto.WorkflowParamsMap.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowParamsMap
         * @static
         * @param {Proto.IWorkflowParamsMap} m WorkflowParamsMap message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowParamsMap.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.paramsUID != null && Object.hasOwnProperty.call(m, "paramsUID"))
                w.uint32(10).string(m.paramsUID);
            if (m.builderUID != null && Object.hasOwnProperty.call(m, "builderUID"))
                w.uint32(18).string(m.builderUID);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(24).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(32).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(40).int32(m.status);
            if (m.builderTitle != null && Object.hasOwnProperty.call(m, "builderTitle"))
                w.uint32(50).string(m.builderTitle);
            if (m.builder != null && Object.hasOwnProperty.call(m, "builder"))
                $root.Proto.WorkflowBuilder.encode(m.builder, w.uint32(58).fork()).ldelim();
            if (m.builderFile != null && Object.hasOwnProperty.call(m, "builderFile"))
                $root.Proto.UploadFile.encode(m.builderFile, w.uint32(66).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a WorkflowParamsMap message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowParamsMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowParamsMap} WorkflowParamsMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowParamsMap.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowParamsMap();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.paramsUID = r.string();
                    break;
                case 2:
                    m.builderUID = r.string();
                    break;
                case 3:
                    m.registerDate = r.int64();
                    break;
                case 4:
                    m.modifyDate = r.int64();
                    break;
                case 5:
                    m.status = r.int32();
                    break;
                case 6:
                    m.builderTitle = r.string();
                    break;
                case 7:
                    m.builder = $root.Proto.WorkflowBuilder.decode(r, r.uint32());
                    break;
                case 8:
                    m.builderFile = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowParamsMap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowParamsMap
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowParamsMap} WorkflowParamsMap
         */
        WorkflowParamsMap.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowParamsMap)
                return d;
            var m = new $root.Proto.WorkflowParamsMap();
            if (d.paramsUID != null) {
                m.paramsUID = String(d.paramsUID);
            }
            if (d.builderUID != null) {
                m.builderUID = String(d.builderUID);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.builderTitle != null) {
                m.builderTitle = String(d.builderTitle);
            }
            if (d.builder != null) {
                if (typeof d.builder !== "object")
                    throw TypeError(".Proto.WorkflowParamsMap.builder: object expected");
                m.builder = $root.Proto.WorkflowBuilder.fromObject(d.builder);
            }
            if (d.builderFile != null) {
                if (typeof d.builderFile !== "object")
                    throw TypeError(".Proto.WorkflowParamsMap.builderFile: object expected");
                m.builderFile = $root.Proto.UploadFile.fromObject(d.builderFile);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowParamsMap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowParamsMap
         * @static
         * @param {Proto.WorkflowParamsMap} m WorkflowParamsMap
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowParamsMap.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.paramsUID = "";
                d.builderUID = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.builderTitle = "";
                d.builder = null;
                d.builderFile = null;
            }
            if (m.paramsUID != null && m.hasOwnProperty("paramsUID")) {
                d.paramsUID = m.paramsUID;
            }
            if (m.builderUID != null && m.hasOwnProperty("builderUID")) {
                d.builderUID = m.builderUID;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.builderTitle != null && m.hasOwnProperty("builderTitle")) {
                d.builderTitle = m.builderTitle;
            }
            if (m.builder != null && m.hasOwnProperty("builder")) {
                d.builder = $root.Proto.WorkflowBuilder.toObject(m.builder, o);
            }
            if (m.builderFile != null && m.hasOwnProperty("builderFile")) {
                d.builderFile = $root.Proto.UploadFile.toObject(m.builderFile, o);
            }
            return d;
        };

        /**
         * Converts this WorkflowParamsMap to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowParamsMap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowParamsMap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowParamsMap;
    })();

    Proto.WorkflowParamsMapList = (function() {

        /**
         * Properties of a WorkflowParamsMapList.
         * @memberof Proto
         * @interface IWorkflowParamsMapList
         * @property {Array.<Proto.IWorkflowParamsMap>|null} [value] WorkflowParamsMapList value
         */

        /**
         * Constructs a new WorkflowParamsMapList.
         * @memberof Proto
         * @classdesc Represents a WorkflowParamsMapList.
         * @implements IWorkflowParamsMapList
         * @constructor
         * @param {Proto.IWorkflowParamsMapList=} [p] Properties to set
         */
        function WorkflowParamsMapList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowParamsMapList value.
         * @member {Array.<Proto.IWorkflowParamsMap>} value
         * @memberof Proto.WorkflowParamsMapList
         * @instance
         */
        WorkflowParamsMapList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkflowParamsMapList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowParamsMapList
         * @static
         * @param {Proto.IWorkflowParamsMapList=} [properties] Properties to set
         * @returns {Proto.WorkflowParamsMapList} WorkflowParamsMapList instance
         */
        WorkflowParamsMapList.create = function create(properties) {
            return new WorkflowParamsMapList(properties);
        };

        /**
         * Encodes the specified WorkflowParamsMapList message. Does not implicitly {@link Proto.WorkflowParamsMapList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowParamsMapList
         * @static
         * @param {Proto.IWorkflowParamsMapList} m WorkflowParamsMapList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowParamsMapList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkflowParamsMap.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowParamsMapList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowParamsMapList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowParamsMapList} WorkflowParamsMapList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowParamsMapList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowParamsMapList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkflowParamsMap.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowParamsMapList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowParamsMapList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowParamsMapList} WorkflowParamsMapList
         */
        WorkflowParamsMapList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowParamsMapList)
                return d;
            var m = new $root.Proto.WorkflowParamsMapList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkflowParamsMapList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkflowParamsMapList.value: object expected");
                    m.value[i] = $root.Proto.WorkflowParamsMap.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowParamsMapList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowParamsMapList
         * @static
         * @param {Proto.WorkflowParamsMapList} m WorkflowParamsMapList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowParamsMapList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkflowParamsMap.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowParamsMapList to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowParamsMapList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowParamsMapList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowParamsMapList;
    })();

    Proto.WorkflowParamsConnectData = (function() {

        /**
         * Properties of a WorkflowParamsConnectData.
         * @memberof Proto
         * @interface IWorkflowParamsConnectData
         * @property {string|null} [formUID] WorkflowParamsConnectData formUID
         * @property {Proto.IWorkflowParams|null} [params] WorkflowParamsConnectData params
         * @property {Array.<Proto.IWorkflowParamsAnswer>|null} [paramsAnswer] WorkflowParamsConnectData paramsAnswer
         */

        /**
         * Constructs a new WorkflowParamsConnectData.
         * @memberof Proto
         * @classdesc Represents a WorkflowParamsConnectData.
         * @implements IWorkflowParamsConnectData
         * @constructor
         * @param {Proto.IWorkflowParamsConnectData=} [p] Properties to set
         */
        function WorkflowParamsConnectData(p) {
            this.paramsAnswer = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowParamsConnectData formUID.
         * @member {string} formUID
         * @memberof Proto.WorkflowParamsConnectData
         * @instance
         */
        WorkflowParamsConnectData.prototype.formUID = "";

        /**
         * WorkflowParamsConnectData params.
         * @member {Proto.IWorkflowParams|null|undefined} params
         * @memberof Proto.WorkflowParamsConnectData
         * @instance
         */
        WorkflowParamsConnectData.prototype.params = null;

        /**
         * WorkflowParamsConnectData paramsAnswer.
         * @member {Array.<Proto.IWorkflowParamsAnswer>} paramsAnswer
         * @memberof Proto.WorkflowParamsConnectData
         * @instance
         */
        WorkflowParamsConnectData.prototype.paramsAnswer = $util.emptyArray;

        /**
         * Creates a new WorkflowParamsConnectData instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowParamsConnectData
         * @static
         * @param {Proto.IWorkflowParamsConnectData=} [properties] Properties to set
         * @returns {Proto.WorkflowParamsConnectData} WorkflowParamsConnectData instance
         */
        WorkflowParamsConnectData.create = function create(properties) {
            return new WorkflowParamsConnectData(properties);
        };

        /**
         * Encodes the specified WorkflowParamsConnectData message. Does not implicitly {@link Proto.WorkflowParamsConnectData.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowParamsConnectData
         * @static
         * @param {Proto.IWorkflowParamsConnectData} m WorkflowParamsConnectData message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowParamsConnectData.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.formUID != null && Object.hasOwnProperty.call(m, "formUID"))
                w.uint32(10).string(m.formUID);
            if (m.params != null && Object.hasOwnProperty.call(m, "params"))
                $root.Proto.WorkflowParams.encode(m.params, w.uint32(18).fork()).ldelim();
            if (m.paramsAnswer != null && m.paramsAnswer.length) {
                for (var i = 0; i < m.paramsAnswer.length; ++i)
                    $root.Proto.WorkflowParamsAnswer.encode(m.paramsAnswer[i], w.uint32(26).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowParamsConnectData message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowParamsConnectData
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowParamsConnectData} WorkflowParamsConnectData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowParamsConnectData.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowParamsConnectData();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.formUID = r.string();
                    break;
                case 2:
                    m.params = $root.Proto.WorkflowParams.decode(r, r.uint32());
                    break;
                case 3:
                    if (!(m.paramsAnswer && m.paramsAnswer.length))
                        m.paramsAnswer = [];
                    m.paramsAnswer.push($root.Proto.WorkflowParamsAnswer.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowParamsConnectData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowParamsConnectData
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowParamsConnectData} WorkflowParamsConnectData
         */
        WorkflowParamsConnectData.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowParamsConnectData)
                return d;
            var m = new $root.Proto.WorkflowParamsConnectData();
            if (d.formUID != null) {
                m.formUID = String(d.formUID);
            }
            if (d.params != null) {
                if (typeof d.params !== "object")
                    throw TypeError(".Proto.WorkflowParamsConnectData.params: object expected");
                m.params = $root.Proto.WorkflowParams.fromObject(d.params);
            }
            if (d.paramsAnswer) {
                if (!Array.isArray(d.paramsAnswer))
                    throw TypeError(".Proto.WorkflowParamsConnectData.paramsAnswer: array expected");
                m.paramsAnswer = [];
                for (var i = 0; i < d.paramsAnswer.length; ++i) {
                    if (typeof d.paramsAnswer[i] !== "object")
                        throw TypeError(".Proto.WorkflowParamsConnectData.paramsAnswer: object expected");
                    m.paramsAnswer[i] = $root.Proto.WorkflowParamsAnswer.fromObject(d.paramsAnswer[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowParamsConnectData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowParamsConnectData
         * @static
         * @param {Proto.WorkflowParamsConnectData} m WorkflowParamsConnectData
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowParamsConnectData.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.paramsAnswer = [];
            }
            if (o.defaults) {
                d.formUID = "";
                d.params = null;
            }
            if (m.formUID != null && m.hasOwnProperty("formUID")) {
                d.formUID = m.formUID;
            }
            if (m.params != null && m.hasOwnProperty("params")) {
                d.params = $root.Proto.WorkflowParams.toObject(m.params, o);
            }
            if (m.paramsAnswer && m.paramsAnswer.length) {
                d.paramsAnswer = [];
                for (var j = 0; j < m.paramsAnswer.length; ++j) {
                    d.paramsAnswer[j] = $root.Proto.WorkflowParamsAnswer.toObject(m.paramsAnswer[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowParamsConnectData to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowParamsConnectData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowParamsConnectData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowParamsConnectData;
    })();

    Proto.WorkflowParamsConnectDataList = (function() {

        /**
         * Properties of a WorkflowParamsConnectDataList.
         * @memberof Proto
         * @interface IWorkflowParamsConnectDataList
         * @property {Array.<Proto.IWorkflowParamsConnectData>|null} [value] WorkflowParamsConnectDataList value
         */

        /**
         * Constructs a new WorkflowParamsConnectDataList.
         * @memberof Proto
         * @classdesc Represents a WorkflowParamsConnectDataList.
         * @implements IWorkflowParamsConnectDataList
         * @constructor
         * @param {Proto.IWorkflowParamsConnectDataList=} [p] Properties to set
         */
        function WorkflowParamsConnectDataList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowParamsConnectDataList value.
         * @member {Array.<Proto.IWorkflowParamsConnectData>} value
         * @memberof Proto.WorkflowParamsConnectDataList
         * @instance
         */
        WorkflowParamsConnectDataList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkflowParamsConnectDataList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowParamsConnectDataList
         * @static
         * @param {Proto.IWorkflowParamsConnectDataList=} [properties] Properties to set
         * @returns {Proto.WorkflowParamsConnectDataList} WorkflowParamsConnectDataList instance
         */
        WorkflowParamsConnectDataList.create = function create(properties) {
            return new WorkflowParamsConnectDataList(properties);
        };

        /**
         * Encodes the specified WorkflowParamsConnectDataList message. Does not implicitly {@link Proto.WorkflowParamsConnectDataList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowParamsConnectDataList
         * @static
         * @param {Proto.IWorkflowParamsConnectDataList} m WorkflowParamsConnectDataList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowParamsConnectDataList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkflowParamsConnectData.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowParamsConnectDataList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowParamsConnectDataList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowParamsConnectDataList} WorkflowParamsConnectDataList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowParamsConnectDataList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowParamsConnectDataList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkflowParamsConnectData.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowParamsConnectDataList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowParamsConnectDataList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowParamsConnectDataList} WorkflowParamsConnectDataList
         */
        WorkflowParamsConnectDataList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowParamsConnectDataList)
                return d;
            var m = new $root.Proto.WorkflowParamsConnectDataList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkflowParamsConnectDataList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkflowParamsConnectDataList.value: object expected");
                    m.value[i] = $root.Proto.WorkflowParamsConnectData.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowParamsConnectDataList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowParamsConnectDataList
         * @static
         * @param {Proto.WorkflowParamsConnectDataList} m WorkflowParamsConnectDataList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowParamsConnectDataList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkflowParamsConnectData.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowParamsConnectDataList to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowParamsConnectDataList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowParamsConnectDataList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowParamsConnectDataList;
    })();

    Proto.CompanyPointCode = (function() {

        /**
         * Properties of a CompanyPointCode.
         * @memberof Proto
         * @interface ICompanyPointCode
         * @property {string|null} [uid] CompanyPointCode uid
         * @property {string|null} [itemUID] CompanyPointCode itemUID
         * @property {number|null} [itemType] CompanyPointCode itemType
         * @property {string|null} [itemText] CompanyPointCode itemText
         * @property {Proto.IUploadFile|null} [image] CompanyPointCode image
         * @property {number|Long|null} [registerDate] CompanyPointCode registerDate
         * @property {number|Long|null} [modifyDate] CompanyPointCode modifyDate
         */

        /**
         * Constructs a new CompanyPointCode.
         * @memberof Proto
         * @classdesc Represents a CompanyPointCode.
         * @implements ICompanyPointCode
         * @constructor
         * @param {Proto.ICompanyPointCode=} [p] Properties to set
         */
        function CompanyPointCode(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CompanyPointCode uid.
         * @member {string} uid
         * @memberof Proto.CompanyPointCode
         * @instance
         */
        CompanyPointCode.prototype.uid = "";

        /**
         * CompanyPointCode itemUID.
         * @member {string} itemUID
         * @memberof Proto.CompanyPointCode
         * @instance
         */
        CompanyPointCode.prototype.itemUID = "";

        /**
         * CompanyPointCode itemType.
         * @member {number} itemType
         * @memberof Proto.CompanyPointCode
         * @instance
         */
        CompanyPointCode.prototype.itemType = 0;

        /**
         * CompanyPointCode itemText.
         * @member {string} itemText
         * @memberof Proto.CompanyPointCode
         * @instance
         */
        CompanyPointCode.prototype.itemText = "";

        /**
         * CompanyPointCode image.
         * @member {Proto.IUploadFile|null|undefined} image
         * @memberof Proto.CompanyPointCode
         * @instance
         */
        CompanyPointCode.prototype.image = null;

        /**
         * CompanyPointCode registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CompanyPointCode
         * @instance
         */
        CompanyPointCode.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CompanyPointCode modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CompanyPointCode
         * @instance
         */
        CompanyPointCode.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new CompanyPointCode instance using the specified properties.
         * @function create
         * @memberof Proto.CompanyPointCode
         * @static
         * @param {Proto.ICompanyPointCode=} [properties] Properties to set
         * @returns {Proto.CompanyPointCode} CompanyPointCode instance
         */
        CompanyPointCode.create = function create(properties) {
            return new CompanyPointCode(properties);
        };

        /**
         * Encodes the specified CompanyPointCode message. Does not implicitly {@link Proto.CompanyPointCode.verify|verify} messages.
         * @function encode
         * @memberof Proto.CompanyPointCode
         * @static
         * @param {Proto.ICompanyPointCode} m CompanyPointCode message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyPointCode.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.itemUID != null && Object.hasOwnProperty.call(m, "itemUID"))
                w.uint32(18).string(m.itemUID);
            if (m.itemType != null && Object.hasOwnProperty.call(m, "itemType"))
                w.uint32(24).int32(m.itemType);
            if (m.itemText != null && Object.hasOwnProperty.call(m, "itemText"))
                w.uint32(34).string(m.itemText);
            if (m.image != null && Object.hasOwnProperty.call(m, "image"))
                $root.Proto.UploadFile.encode(m.image, w.uint32(42).fork()).ldelim();
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(48).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(56).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a CompanyPointCode message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CompanyPointCode
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CompanyPointCode} CompanyPointCode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyPointCode.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CompanyPointCode();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.itemUID = r.string();
                    break;
                case 3:
                    m.itemType = r.int32();
                    break;
                case 4:
                    m.itemText = r.string();
                    break;
                case 5:
                    m.image = $root.Proto.UploadFile.decode(r, r.uint32());
                    break;
                case 6:
                    m.registerDate = r.int64();
                    break;
                case 7:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CompanyPointCode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CompanyPointCode
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CompanyPointCode} CompanyPointCode
         */
        CompanyPointCode.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CompanyPointCode)
                return d;
            var m = new $root.Proto.CompanyPointCode();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.itemUID != null) {
                m.itemUID = String(d.itemUID);
            }
            if (d.itemType != null) {
                m.itemType = d.itemType | 0;
            }
            if (d.itemText != null) {
                m.itemText = String(d.itemText);
            }
            if (d.image != null) {
                if (typeof d.image !== "object")
                    throw TypeError(".Proto.CompanyPointCode.image: object expected");
                m.image = $root.Proto.UploadFile.fromObject(d.image);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a CompanyPointCode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CompanyPointCode
         * @static
         * @param {Proto.CompanyPointCode} m CompanyPointCode
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyPointCode.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.itemUID = "";
                d.itemType = 0;
                d.itemText = "";
                d.image = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.itemUID != null && m.hasOwnProperty("itemUID")) {
                d.itemUID = m.itemUID;
            }
            if (m.itemType != null && m.hasOwnProperty("itemType")) {
                d.itemType = m.itemType;
            }
            if (m.itemText != null && m.hasOwnProperty("itemText")) {
                d.itemText = m.itemText;
            }
            if (m.image != null && m.hasOwnProperty("image")) {
                d.image = $root.Proto.UploadFile.toObject(m.image, o);
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this CompanyPointCode to JSON.
         * @function toJSON
         * @memberof Proto.CompanyPointCode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyPointCode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyPointCode;
    })();

    Proto.PlaylistTVOD = (function() {

        /**
         * Properties of a PlaylistTVOD.
         * @memberof Proto
         * @interface IPlaylistTVOD
         * @property {boolean|null} [enable] PlaylistTVOD enable
         * @property {number|null} [price] PlaylistTVOD price
         * @property {string|null} [currency] PlaylistTVOD currency
         * @property {number|Long|null} [registerDate] PlaylistTVOD registerDate
         * @property {number|Long|null} [modifyDate] PlaylistTVOD modifyDate
         * @property {Array.<Proto.IPlaylistTVODMethod>|null} [methodList] PlaylistTVOD methodList
         */

        /**
         * Constructs a new PlaylistTVOD.
         * @memberof Proto
         * @classdesc Represents a PlaylistTVOD.
         * @implements IPlaylistTVOD
         * @constructor
         * @param {Proto.IPlaylistTVOD=} [p] Properties to set
         */
        function PlaylistTVOD(p) {
            this.methodList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PlaylistTVOD enable.
         * @member {boolean} enable
         * @memberof Proto.PlaylistTVOD
         * @instance
         */
        PlaylistTVOD.prototype.enable = false;

        /**
         * PlaylistTVOD price.
         * @member {number} price
         * @memberof Proto.PlaylistTVOD
         * @instance
         */
        PlaylistTVOD.prototype.price = 0;

        /**
         * PlaylistTVOD currency.
         * @member {string} currency
         * @memberof Proto.PlaylistTVOD
         * @instance
         */
        PlaylistTVOD.prototype.currency = "";

        /**
         * PlaylistTVOD registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.PlaylistTVOD
         * @instance
         */
        PlaylistTVOD.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlaylistTVOD modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.PlaylistTVOD
         * @instance
         */
        PlaylistTVOD.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlaylistTVOD methodList.
         * @member {Array.<Proto.IPlaylistTVODMethod>} methodList
         * @memberof Proto.PlaylistTVOD
         * @instance
         */
        PlaylistTVOD.prototype.methodList = $util.emptyArray;

        /**
         * Creates a new PlaylistTVOD instance using the specified properties.
         * @function create
         * @memberof Proto.PlaylistTVOD
         * @static
         * @param {Proto.IPlaylistTVOD=} [properties] Properties to set
         * @returns {Proto.PlaylistTVOD} PlaylistTVOD instance
         */
        PlaylistTVOD.create = function create(properties) {
            return new PlaylistTVOD(properties);
        };

        /**
         * Encodes the specified PlaylistTVOD message. Does not implicitly {@link Proto.PlaylistTVOD.verify|verify} messages.
         * @function encode
         * @memberof Proto.PlaylistTVOD
         * @static
         * @param {Proto.IPlaylistTVOD} m PlaylistTVOD message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlaylistTVOD.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.enable != null && Object.hasOwnProperty.call(m, "enable"))
                w.uint32(8).bool(m.enable);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(17).double(m.price);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(26).string(m.currency);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(32).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(40).int64(m.modifyDate);
            if (m.methodList != null && m.methodList.length) {
                for (var i = 0; i < m.methodList.length; ++i)
                    $root.Proto.PlaylistTVODMethod.encode(m.methodList[i], w.uint32(50).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a PlaylistTVOD message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.PlaylistTVOD
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.PlaylistTVOD} PlaylistTVOD
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlaylistTVOD.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.PlaylistTVOD();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.enable = r.bool();
                    break;
                case 2:
                    m.price = r.double();
                    break;
                case 3:
                    m.currency = r.string();
                    break;
                case 4:
                    m.registerDate = r.int64();
                    break;
                case 5:
                    m.modifyDate = r.int64();
                    break;
                case 6:
                    if (!(m.methodList && m.methodList.length))
                        m.methodList = [];
                    m.methodList.push($root.Proto.PlaylistTVODMethod.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PlaylistTVOD message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.PlaylistTVOD
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.PlaylistTVOD} PlaylistTVOD
         */
        PlaylistTVOD.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.PlaylistTVOD)
                return d;
            var m = new $root.Proto.PlaylistTVOD();
            if (d.enable != null) {
                m.enable = Boolean(d.enable);
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.methodList) {
                if (!Array.isArray(d.methodList))
                    throw TypeError(".Proto.PlaylistTVOD.methodList: array expected");
                m.methodList = [];
                for (var i = 0; i < d.methodList.length; ++i) {
                    if (typeof d.methodList[i] !== "object")
                        throw TypeError(".Proto.PlaylistTVOD.methodList: object expected");
                    m.methodList[i] = $root.Proto.PlaylistTVODMethod.fromObject(d.methodList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a PlaylistTVOD message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.PlaylistTVOD
         * @static
         * @param {Proto.PlaylistTVOD} m PlaylistTVOD
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlaylistTVOD.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.methodList = [];
            }
            if (o.defaults) {
                d.enable = false;
                d.price = 0;
                d.currency = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.enable != null && m.hasOwnProperty("enable")) {
                d.enable = m.enable;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.methodList && m.methodList.length) {
                d.methodList = [];
                for (var j = 0; j < m.methodList.length; ++j) {
                    d.methodList[j] = $root.Proto.PlaylistTVODMethod.toObject(m.methodList[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this PlaylistTVOD to JSON.
         * @function toJSON
         * @memberof Proto.PlaylistTVOD
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlaylistTVOD.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlaylistTVOD;
    })();

    Proto.PlaylistTVODMethod = (function() {

        /**
         * Properties of a PlaylistTVODMethod.
         * @memberof Proto
         * @interface IPlaylistTVODMethod
         * @property {number|null} [type] PlaylistTVODMethod type
         * @property {number|Long|null} [registerDate] PlaylistTVODMethod registerDate
         * @property {number|Long|null} [modifyDate] PlaylistTVODMethod modifyDate
         */

        /**
         * Constructs a new PlaylistTVODMethod.
         * @memberof Proto
         * @classdesc Represents a PlaylistTVODMethod.
         * @implements IPlaylistTVODMethod
         * @constructor
         * @param {Proto.IPlaylistTVODMethod=} [p] Properties to set
         */
        function PlaylistTVODMethod(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PlaylistTVODMethod type.
         * @member {number} type
         * @memberof Proto.PlaylistTVODMethod
         * @instance
         */
        PlaylistTVODMethod.prototype.type = 0;

        /**
         * PlaylistTVODMethod registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.PlaylistTVODMethod
         * @instance
         */
        PlaylistTVODMethod.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlaylistTVODMethod modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.PlaylistTVODMethod
         * @instance
         */
        PlaylistTVODMethod.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new PlaylistTVODMethod instance using the specified properties.
         * @function create
         * @memberof Proto.PlaylistTVODMethod
         * @static
         * @param {Proto.IPlaylistTVODMethod=} [properties] Properties to set
         * @returns {Proto.PlaylistTVODMethod} PlaylistTVODMethod instance
         */
        PlaylistTVODMethod.create = function create(properties) {
            return new PlaylistTVODMethod(properties);
        };

        /**
         * Encodes the specified PlaylistTVODMethod message. Does not implicitly {@link Proto.PlaylistTVODMethod.verify|verify} messages.
         * @function encode
         * @memberof Proto.PlaylistTVODMethod
         * @static
         * @param {Proto.IPlaylistTVODMethod} m PlaylistTVODMethod message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlaylistTVODMethod.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(8).int32(m.type);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(16).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(24).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a PlaylistTVODMethod message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.PlaylistTVODMethod
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.PlaylistTVODMethod} PlaylistTVODMethod
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlaylistTVODMethod.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.PlaylistTVODMethod();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.type = r.int32();
                    break;
                case 2:
                    m.registerDate = r.int64();
                    break;
                case 3:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PlaylistTVODMethod message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.PlaylistTVODMethod
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.PlaylistTVODMethod} PlaylistTVODMethod
         */
        PlaylistTVODMethod.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.PlaylistTVODMethod)
                return d;
            var m = new $root.Proto.PlaylistTVODMethod();
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a PlaylistTVODMethod message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.PlaylistTVODMethod
         * @static
         * @param {Proto.PlaylistTVODMethod} m PlaylistTVODMethod
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlaylistTVODMethod.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.type = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this PlaylistTVODMethod to JSON.
         * @function toJSON
         * @memberof Proto.PlaylistTVODMethod
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlaylistTVODMethod.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlaylistTVODMethod;
    })();

    Proto.WorkflowBuilderTVOD = (function() {

        /**
         * Properties of a WorkflowBuilderTVOD.
         * @memberof Proto
         * @interface IWorkflowBuilderTVOD
         * @property {boolean|null} [enable] WorkflowBuilderTVOD enable
         * @property {number|null} [price] WorkflowBuilderTVOD price
         * @property {string|null} [currency] WorkflowBuilderTVOD currency
         * @property {string|null} [chargePoints] WorkflowBuilderTVOD chargePoints
         * @property {number|Long|null} [registerDate] WorkflowBuilderTVOD registerDate
         * @property {number|Long|null} [modifyDate] WorkflowBuilderTVOD modifyDate
         * @property {Array.<Proto.IWorkflowBuilderTVODMethod>|null} [methodList] WorkflowBuilderTVOD methodList
         */

        /**
         * Constructs a new WorkflowBuilderTVOD.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilderTVOD.
         * @implements IWorkflowBuilderTVOD
         * @constructor
         * @param {Proto.IWorkflowBuilderTVOD=} [p] Properties to set
         */
        function WorkflowBuilderTVOD(p) {
            this.methodList = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilderTVOD enable.
         * @member {boolean} enable
         * @memberof Proto.WorkflowBuilderTVOD
         * @instance
         */
        WorkflowBuilderTVOD.prototype.enable = false;

        /**
         * WorkflowBuilderTVOD price.
         * @member {number} price
         * @memberof Proto.WorkflowBuilderTVOD
         * @instance
         */
        WorkflowBuilderTVOD.prototype.price = 0;

        /**
         * WorkflowBuilderTVOD currency.
         * @member {string} currency
         * @memberof Proto.WorkflowBuilderTVOD
         * @instance
         */
        WorkflowBuilderTVOD.prototype.currency = "";

        /**
         * WorkflowBuilderTVOD chargePoints.
         * @member {string} chargePoints
         * @memberof Proto.WorkflowBuilderTVOD
         * @instance
         */
        WorkflowBuilderTVOD.prototype.chargePoints = "";

        /**
         * WorkflowBuilderTVOD registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkflowBuilderTVOD
         * @instance
         */
        WorkflowBuilderTVOD.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderTVOD modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkflowBuilderTVOD
         * @instance
         */
        WorkflowBuilderTVOD.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderTVOD methodList.
         * @member {Array.<Proto.IWorkflowBuilderTVODMethod>} methodList
         * @memberof Proto.WorkflowBuilderTVOD
         * @instance
         */
        WorkflowBuilderTVOD.prototype.methodList = $util.emptyArray;

        /**
         * Creates a new WorkflowBuilderTVOD instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilderTVOD
         * @static
         * @param {Proto.IWorkflowBuilderTVOD=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilderTVOD} WorkflowBuilderTVOD instance
         */
        WorkflowBuilderTVOD.create = function create(properties) {
            return new WorkflowBuilderTVOD(properties);
        };

        /**
         * Encodes the specified WorkflowBuilderTVOD message. Does not implicitly {@link Proto.WorkflowBuilderTVOD.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilderTVOD
         * @static
         * @param {Proto.IWorkflowBuilderTVOD} m WorkflowBuilderTVOD message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilderTVOD.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.enable != null && Object.hasOwnProperty.call(m, "enable"))
                w.uint32(8).bool(m.enable);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(17).double(m.price);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(26).string(m.currency);
            if (m.chargePoints != null && Object.hasOwnProperty.call(m, "chargePoints"))
                w.uint32(34).string(m.chargePoints);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(40).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(48).int64(m.modifyDate);
            if (m.methodList != null && m.methodList.length) {
                for (var i = 0; i < m.methodList.length; ++i)
                    $root.Proto.WorkflowBuilderTVODMethod.encode(m.methodList[i], w.uint32(58).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowBuilderTVOD message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilderTVOD
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilderTVOD} WorkflowBuilderTVOD
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilderTVOD.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilderTVOD();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.enable = r.bool();
                    break;
                case 2:
                    m.price = r.double();
                    break;
                case 3:
                    m.currency = r.string();
                    break;
                case 4:
                    m.chargePoints = r.string();
                    break;
                case 5:
                    m.registerDate = r.int64();
                    break;
                case 6:
                    m.modifyDate = r.int64();
                    break;
                case 7:
                    if (!(m.methodList && m.methodList.length))
                        m.methodList = [];
                    m.methodList.push($root.Proto.WorkflowBuilderTVODMethod.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilderTVOD message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilderTVOD
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilderTVOD} WorkflowBuilderTVOD
         */
        WorkflowBuilderTVOD.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilderTVOD)
                return d;
            var m = new $root.Proto.WorkflowBuilderTVOD();
            if (d.enable != null) {
                m.enable = Boolean(d.enable);
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.chargePoints != null) {
                m.chargePoints = String(d.chargePoints);
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.methodList) {
                if (!Array.isArray(d.methodList))
                    throw TypeError(".Proto.WorkflowBuilderTVOD.methodList: array expected");
                m.methodList = [];
                for (var i = 0; i < d.methodList.length; ++i) {
                    if (typeof d.methodList[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilderTVOD.methodList: object expected");
                    m.methodList[i] = $root.Proto.WorkflowBuilderTVODMethod.fromObject(d.methodList[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilderTVOD message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilderTVOD
         * @static
         * @param {Proto.WorkflowBuilderTVOD} m WorkflowBuilderTVOD
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilderTVOD.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.methodList = [];
            }
            if (o.defaults) {
                d.enable = false;
                d.price = 0;
                d.currency = "";
                d.chargePoints = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.enable != null && m.hasOwnProperty("enable")) {
                d.enable = m.enable;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.chargePoints != null && m.hasOwnProperty("chargePoints")) {
                d.chargePoints = m.chargePoints;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.methodList && m.methodList.length) {
                d.methodList = [];
                for (var j = 0; j < m.methodList.length; ++j) {
                    d.methodList[j] = $root.Proto.WorkflowBuilderTVODMethod.toObject(m.methodList[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilderTVOD to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilderTVOD
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilderTVOD.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilderTVOD;
    })();

    Proto.WorkflowBuilderTVODMethod = (function() {

        /**
         * Properties of a WorkflowBuilderTVODMethod.
         * @memberof Proto
         * @interface IWorkflowBuilderTVODMethod
         * @property {number|null} [type] WorkflowBuilderTVODMethod type
         * @property {number|Long|null} [registerDate] WorkflowBuilderTVODMethod registerDate
         * @property {number|Long|null} [modifyDate] WorkflowBuilderTVODMethod modifyDate
         */

        /**
         * Constructs a new WorkflowBuilderTVODMethod.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilderTVODMethod.
         * @implements IWorkflowBuilderTVODMethod
         * @constructor
         * @param {Proto.IWorkflowBuilderTVODMethod=} [p] Properties to set
         */
        function WorkflowBuilderTVODMethod(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilderTVODMethod type.
         * @member {number} type
         * @memberof Proto.WorkflowBuilderTVODMethod
         * @instance
         */
        WorkflowBuilderTVODMethod.prototype.type = 0;

        /**
         * WorkflowBuilderTVODMethod registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkflowBuilderTVODMethod
         * @instance
         */
        WorkflowBuilderTVODMethod.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderTVODMethod modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkflowBuilderTVODMethod
         * @instance
         */
        WorkflowBuilderTVODMethod.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new WorkflowBuilderTVODMethod instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilderTVODMethod
         * @static
         * @param {Proto.IWorkflowBuilderTVODMethod=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilderTVODMethod} WorkflowBuilderTVODMethod instance
         */
        WorkflowBuilderTVODMethod.create = function create(properties) {
            return new WorkflowBuilderTVODMethod(properties);
        };

        /**
         * Encodes the specified WorkflowBuilderTVODMethod message. Does not implicitly {@link Proto.WorkflowBuilderTVODMethod.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilderTVODMethod
         * @static
         * @param {Proto.IWorkflowBuilderTVODMethod} m WorkflowBuilderTVODMethod message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilderTVODMethod.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(8).int32(m.type);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(16).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(24).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a WorkflowBuilderTVODMethod message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilderTVODMethod
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilderTVODMethod} WorkflowBuilderTVODMethod
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilderTVODMethod.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilderTVODMethod();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.type = r.int32();
                    break;
                case 2:
                    m.registerDate = r.int64();
                    break;
                case 3:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilderTVODMethod message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilderTVODMethod
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilderTVODMethod} WorkflowBuilderTVODMethod
         */
        WorkflowBuilderTVODMethod.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilderTVODMethod)
                return d;
            var m = new $root.Proto.WorkflowBuilderTVODMethod();
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilderTVODMethod message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilderTVODMethod
         * @static
         * @param {Proto.WorkflowBuilderTVODMethod} m WorkflowBuilderTVODMethod
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilderTVODMethod.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.type = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilderTVODMethod to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilderTVODMethod
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilderTVODMethod.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilderTVODMethod;
    })();

    Proto.WorkflowBuilderTVODOrder = (function() {

        /**
         * Properties of a WorkflowBuilderTVODOrder.
         * @memberof Proto
         * @interface IWorkflowBuilderTVODOrder
         * @property {string|null} [uid] WorkflowBuilderTVODOrder uid
         * @property {string|null} [builderUID] WorkflowBuilderTVODOrder builderUID
         * @property {string|null} [answerUID] WorkflowBuilderTVODOrder answerUID
         * @property {string|null} [userUID] WorkflowBuilderTVODOrder userUID
         * @property {number|null} [chargePoint] WorkflowBuilderTVODOrder chargePoint
         * @property {number|null} [paymentType] WorkflowBuilderTVODOrder paymentType
         * @property {number|null} [thirdType] WorkflowBuilderTVODOrder thirdType
         * @property {string|null} [thirdCheckoutId] WorkflowBuilderTVODOrder thirdCheckoutId
         * @property {string|null} [thirdPaymentId] WorkflowBuilderTVODOrder thirdPaymentId
         * @property {boolean|null} [captured] WorkflowBuilderTVODOrder captured
         * @property {number|null} [quantity] WorkflowBuilderTVODOrder quantity
         * @property {number|null} [price] WorkflowBuilderTVODOrder price
         * @property {number|null} [fee] WorkflowBuilderTVODOrder fee
         * @property {number|null} [cashFee] WorkflowBuilderTVODOrder cashFee
         * @property {number|null} [payFee] WorkflowBuilderTVODOrder payFee
         * @property {number|null} [total] WorkflowBuilderTVODOrder total
         * @property {string|null} [currency] WorkflowBuilderTVODOrder currency
         * @property {boolean|null} [userCancel] WorkflowBuilderTVODOrder userCancel
         * @property {number|Long|null} [refundDate] WorkflowBuilderTVODOrder refundDate
         * @property {number|Long|null} [registerDate] WorkflowBuilderTVODOrder registerDate
         * @property {number|Long|null} [modifyDate] WorkflowBuilderTVODOrder modifyDate
         * @property {number|null} [status] WorkflowBuilderTVODOrder status
         * @property {boolean|null} [invoiceFixed] WorkflowBuilderTVODOrder invoiceFixed
         * @property {number|null} [point] WorkflowBuilderTVODOrder point
         */

        /**
         * Constructs a new WorkflowBuilderTVODOrder.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilderTVODOrder.
         * @implements IWorkflowBuilderTVODOrder
         * @constructor
         * @param {Proto.IWorkflowBuilderTVODOrder=} [p] Properties to set
         */
        function WorkflowBuilderTVODOrder(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilderTVODOrder uid.
         * @member {string} uid
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.uid = "";

        /**
         * WorkflowBuilderTVODOrder builderUID.
         * @member {string} builderUID
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.builderUID = "";

        /**
         * WorkflowBuilderTVODOrder answerUID.
         * @member {string} answerUID
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.answerUID = "";

        /**
         * WorkflowBuilderTVODOrder userUID.
         * @member {string} userUID
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.userUID = "";

        /**
         * WorkflowBuilderTVODOrder chargePoint.
         * @member {number} chargePoint
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.chargePoint = 0;

        /**
         * WorkflowBuilderTVODOrder paymentType.
         * @member {number} paymentType
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.paymentType = 0;

        /**
         * WorkflowBuilderTVODOrder thirdType.
         * @member {number} thirdType
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.thirdType = 0;

        /**
         * WorkflowBuilderTVODOrder thirdCheckoutId.
         * @member {string} thirdCheckoutId
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.thirdCheckoutId = "";

        /**
         * WorkflowBuilderTVODOrder thirdPaymentId.
         * @member {string} thirdPaymentId
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.thirdPaymentId = "";

        /**
         * WorkflowBuilderTVODOrder captured.
         * @member {boolean} captured
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.captured = false;

        /**
         * WorkflowBuilderTVODOrder quantity.
         * @member {number} quantity
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.quantity = 0;

        /**
         * WorkflowBuilderTVODOrder price.
         * @member {number} price
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.price = 0;

        /**
         * WorkflowBuilderTVODOrder fee.
         * @member {number} fee
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.fee = 0;

        /**
         * WorkflowBuilderTVODOrder cashFee.
         * @member {number} cashFee
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.cashFee = 0;

        /**
         * WorkflowBuilderTVODOrder payFee.
         * @member {number} payFee
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.payFee = 0;

        /**
         * WorkflowBuilderTVODOrder total.
         * @member {number} total
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.total = 0;

        /**
         * WorkflowBuilderTVODOrder currency.
         * @member {string} currency
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.currency = "";

        /**
         * WorkflowBuilderTVODOrder userCancel.
         * @member {boolean} userCancel
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.userCancel = false;

        /**
         * WorkflowBuilderTVODOrder refundDate.
         * @member {number|Long} refundDate
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.refundDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderTVODOrder registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderTVODOrder modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WorkflowBuilderTVODOrder status.
         * @member {number} status
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.status = 0;

        /**
         * WorkflowBuilderTVODOrder invoiceFixed.
         * @member {boolean} invoiceFixed
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.invoiceFixed = false;

        /**
         * WorkflowBuilderTVODOrder point.
         * @member {number} point
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         */
        WorkflowBuilderTVODOrder.prototype.point = 0;

        /**
         * Creates a new WorkflowBuilderTVODOrder instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @static
         * @param {Proto.IWorkflowBuilderTVODOrder=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilderTVODOrder} WorkflowBuilderTVODOrder instance
         */
        WorkflowBuilderTVODOrder.create = function create(properties) {
            return new WorkflowBuilderTVODOrder(properties);
        };

        /**
         * Encodes the specified WorkflowBuilderTVODOrder message. Does not implicitly {@link Proto.WorkflowBuilderTVODOrder.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @static
         * @param {Proto.IWorkflowBuilderTVODOrder} m WorkflowBuilderTVODOrder message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilderTVODOrder.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.builderUID != null && Object.hasOwnProperty.call(m, "builderUID"))
                w.uint32(18).string(m.builderUID);
            if (m.answerUID != null && Object.hasOwnProperty.call(m, "answerUID"))
                w.uint32(26).string(m.answerUID);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(34).string(m.userUID);
            if (m.chargePoint != null && Object.hasOwnProperty.call(m, "chargePoint"))
                w.uint32(40).int32(m.chargePoint);
            if (m.paymentType != null && Object.hasOwnProperty.call(m, "paymentType"))
                w.uint32(48).int32(m.paymentType);
            if (m.thirdType != null && Object.hasOwnProperty.call(m, "thirdType"))
                w.uint32(56).int32(m.thirdType);
            if (m.thirdCheckoutId != null && Object.hasOwnProperty.call(m, "thirdCheckoutId"))
                w.uint32(66).string(m.thirdCheckoutId);
            if (m.thirdPaymentId != null && Object.hasOwnProperty.call(m, "thirdPaymentId"))
                w.uint32(74).string(m.thirdPaymentId);
            if (m.captured != null && Object.hasOwnProperty.call(m, "captured"))
                w.uint32(80).bool(m.captured);
            if (m.quantity != null && Object.hasOwnProperty.call(m, "quantity"))
                w.uint32(88).int32(m.quantity);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(97).double(m.price);
            if (m.fee != null && Object.hasOwnProperty.call(m, "fee"))
                w.uint32(105).double(m.fee);
            if (m.cashFee != null && Object.hasOwnProperty.call(m, "cashFee"))
                w.uint32(113).double(m.cashFee);
            if (m.payFee != null && Object.hasOwnProperty.call(m, "payFee"))
                w.uint32(121).double(m.payFee);
            if (m.total != null && Object.hasOwnProperty.call(m, "total"))
                w.uint32(129).double(m.total);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(138).string(m.currency);
            if (m.userCancel != null && Object.hasOwnProperty.call(m, "userCancel"))
                w.uint32(144).bool(m.userCancel);
            if (m.refundDate != null && Object.hasOwnProperty.call(m, "refundDate"))
                w.uint32(152).int64(m.refundDate);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(160).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(168).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(176).int32(m.status);
            if (m.invoiceFixed != null && Object.hasOwnProperty.call(m, "invoiceFixed"))
                w.uint32(184).bool(m.invoiceFixed);
            if (m.point != null && Object.hasOwnProperty.call(m, "point"))
                w.uint32(193).double(m.point);
            return w;
        };

        /**
         * Decodes a WorkflowBuilderTVODOrder message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilderTVODOrder} WorkflowBuilderTVODOrder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilderTVODOrder.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilderTVODOrder();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.builderUID = r.string();
                    break;
                case 3:
                    m.answerUID = r.string();
                    break;
                case 4:
                    m.userUID = r.string();
                    break;
                case 5:
                    m.chargePoint = r.int32();
                    break;
                case 6:
                    m.paymentType = r.int32();
                    break;
                case 7:
                    m.thirdType = r.int32();
                    break;
                case 8:
                    m.thirdCheckoutId = r.string();
                    break;
                case 9:
                    m.thirdPaymentId = r.string();
                    break;
                case 10:
                    m.captured = r.bool();
                    break;
                case 11:
                    m.quantity = r.int32();
                    break;
                case 12:
                    m.price = r.double();
                    break;
                case 13:
                    m.fee = r.double();
                    break;
                case 14:
                    m.cashFee = r.double();
                    break;
                case 15:
                    m.payFee = r.double();
                    break;
                case 16:
                    m.total = r.double();
                    break;
                case 17:
                    m.currency = r.string();
                    break;
                case 18:
                    m.userCancel = r.bool();
                    break;
                case 19:
                    m.refundDate = r.int64();
                    break;
                case 20:
                    m.registerDate = r.int64();
                    break;
                case 21:
                    m.modifyDate = r.int64();
                    break;
                case 22:
                    m.status = r.int32();
                    break;
                case 23:
                    m.invoiceFixed = r.bool();
                    break;
                case 24:
                    m.point = r.double();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilderTVODOrder message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilderTVODOrder} WorkflowBuilderTVODOrder
         */
        WorkflowBuilderTVODOrder.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilderTVODOrder)
                return d;
            var m = new $root.Proto.WorkflowBuilderTVODOrder();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.builderUID != null) {
                m.builderUID = String(d.builderUID);
            }
            if (d.answerUID != null) {
                m.answerUID = String(d.answerUID);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.chargePoint != null) {
                m.chargePoint = d.chargePoint | 0;
            }
            if (d.paymentType != null) {
                m.paymentType = d.paymentType | 0;
            }
            if (d.thirdType != null) {
                m.thirdType = d.thirdType | 0;
            }
            if (d.thirdCheckoutId != null) {
                m.thirdCheckoutId = String(d.thirdCheckoutId);
            }
            if (d.thirdPaymentId != null) {
                m.thirdPaymentId = String(d.thirdPaymentId);
            }
            if (d.captured != null) {
                m.captured = Boolean(d.captured);
            }
            if (d.quantity != null) {
                m.quantity = d.quantity | 0;
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.fee != null) {
                m.fee = Number(d.fee);
            }
            if (d.cashFee != null) {
                m.cashFee = Number(d.cashFee);
            }
            if (d.payFee != null) {
                m.payFee = Number(d.payFee);
            }
            if (d.total != null) {
                m.total = Number(d.total);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.userCancel != null) {
                m.userCancel = Boolean(d.userCancel);
            }
            if (d.refundDate != null) {
                if ($util.Long)
                    (m.refundDate = $util.Long.fromValue(d.refundDate)).unsigned = false;
                else if (typeof d.refundDate === "string")
                    m.refundDate = parseInt(d.refundDate, 10);
                else if (typeof d.refundDate === "number")
                    m.refundDate = d.refundDate;
                else if (typeof d.refundDate === "object")
                    m.refundDate = new $util.LongBits(d.refundDate.low >>> 0, d.refundDate.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.invoiceFixed != null) {
                m.invoiceFixed = Boolean(d.invoiceFixed);
            }
            if (d.point != null) {
                m.point = Number(d.point);
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilderTVODOrder message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @static
         * @param {Proto.WorkflowBuilderTVODOrder} m WorkflowBuilderTVODOrder
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilderTVODOrder.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.builderUID = "";
                d.answerUID = "";
                d.userUID = "";
                d.chargePoint = 0;
                d.paymentType = 0;
                d.thirdType = 0;
                d.thirdCheckoutId = "";
                d.thirdPaymentId = "";
                d.captured = false;
                d.quantity = 0;
                d.price = 0;
                d.fee = 0;
                d.cashFee = 0;
                d.payFee = 0;
                d.total = 0;
                d.currency = "";
                d.userCancel = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.refundDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.refundDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.invoiceFixed = false;
                d.point = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.builderUID != null && m.hasOwnProperty("builderUID")) {
                d.builderUID = m.builderUID;
            }
            if (m.answerUID != null && m.hasOwnProperty("answerUID")) {
                d.answerUID = m.answerUID;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.chargePoint != null && m.hasOwnProperty("chargePoint")) {
                d.chargePoint = m.chargePoint;
            }
            if (m.paymentType != null && m.hasOwnProperty("paymentType")) {
                d.paymentType = m.paymentType;
            }
            if (m.thirdType != null && m.hasOwnProperty("thirdType")) {
                d.thirdType = m.thirdType;
            }
            if (m.thirdCheckoutId != null && m.hasOwnProperty("thirdCheckoutId")) {
                d.thirdCheckoutId = m.thirdCheckoutId;
            }
            if (m.thirdPaymentId != null && m.hasOwnProperty("thirdPaymentId")) {
                d.thirdPaymentId = m.thirdPaymentId;
            }
            if (m.captured != null && m.hasOwnProperty("captured")) {
                d.captured = m.captured;
            }
            if (m.quantity != null && m.hasOwnProperty("quantity")) {
                d.quantity = m.quantity;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.fee != null && m.hasOwnProperty("fee")) {
                d.fee = o.json && !isFinite(m.fee) ? String(m.fee) : m.fee;
            }
            if (m.cashFee != null && m.hasOwnProperty("cashFee")) {
                d.cashFee = o.json && !isFinite(m.cashFee) ? String(m.cashFee) : m.cashFee;
            }
            if (m.payFee != null && m.hasOwnProperty("payFee")) {
                d.payFee = o.json && !isFinite(m.payFee) ? String(m.payFee) : m.payFee;
            }
            if (m.total != null && m.hasOwnProperty("total")) {
                d.total = o.json && !isFinite(m.total) ? String(m.total) : m.total;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.userCancel != null && m.hasOwnProperty("userCancel")) {
                d.userCancel = m.userCancel;
            }
            if (m.refundDate != null && m.hasOwnProperty("refundDate")) {
                if (typeof m.refundDate === "number")
                    d.refundDate = o.longs === String ? String(m.refundDate) : m.refundDate;
                else
                    d.refundDate = o.longs === String ? $util.Long.prototype.toString.call(m.refundDate) : o.longs === Number ? new $util.LongBits(m.refundDate.low >>> 0, m.refundDate.high >>> 0).toNumber() : m.refundDate;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.invoiceFixed != null && m.hasOwnProperty("invoiceFixed")) {
                d.invoiceFixed = m.invoiceFixed;
            }
            if (m.point != null && m.hasOwnProperty("point")) {
                d.point = o.json && !isFinite(m.point) ? String(m.point) : m.point;
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilderTVODOrder to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilderTVODOrder
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilderTVODOrder.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilderTVODOrder;
    })();

    Proto.WorkflowBuilderTVODOrderList = (function() {

        /**
         * Properties of a WorkflowBuilderTVODOrderList.
         * @memberof Proto
         * @interface IWorkflowBuilderTVODOrderList
         * @property {Array.<Proto.IWorkflowBuilderTVODOrder>|null} [value] WorkflowBuilderTVODOrderList value
         */

        /**
         * Constructs a new WorkflowBuilderTVODOrderList.
         * @memberof Proto
         * @classdesc Represents a WorkflowBuilderTVODOrderList.
         * @implements IWorkflowBuilderTVODOrderList
         * @constructor
         * @param {Proto.IWorkflowBuilderTVODOrderList=} [p] Properties to set
         */
        function WorkflowBuilderTVODOrderList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * WorkflowBuilderTVODOrderList value.
         * @member {Array.<Proto.IWorkflowBuilderTVODOrder>} value
         * @memberof Proto.WorkflowBuilderTVODOrderList
         * @instance
         */
        WorkflowBuilderTVODOrderList.prototype.value = $util.emptyArray;

        /**
         * Creates a new WorkflowBuilderTVODOrderList instance using the specified properties.
         * @function create
         * @memberof Proto.WorkflowBuilderTVODOrderList
         * @static
         * @param {Proto.IWorkflowBuilderTVODOrderList=} [properties] Properties to set
         * @returns {Proto.WorkflowBuilderTVODOrderList} WorkflowBuilderTVODOrderList instance
         */
        WorkflowBuilderTVODOrderList.create = function create(properties) {
            return new WorkflowBuilderTVODOrderList(properties);
        };

        /**
         * Encodes the specified WorkflowBuilderTVODOrderList message. Does not implicitly {@link Proto.WorkflowBuilderTVODOrderList.verify|verify} messages.
         * @function encode
         * @memberof Proto.WorkflowBuilderTVODOrderList
         * @static
         * @param {Proto.IWorkflowBuilderTVODOrderList} m WorkflowBuilderTVODOrderList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkflowBuilderTVODOrderList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.WorkflowBuilderTVODOrder.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a WorkflowBuilderTVODOrderList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.WorkflowBuilderTVODOrderList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.WorkflowBuilderTVODOrderList} WorkflowBuilderTVODOrderList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkflowBuilderTVODOrderList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.WorkflowBuilderTVODOrderList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.WorkflowBuilderTVODOrder.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a WorkflowBuilderTVODOrderList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.WorkflowBuilderTVODOrderList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.WorkflowBuilderTVODOrderList} WorkflowBuilderTVODOrderList
         */
        WorkflowBuilderTVODOrderList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.WorkflowBuilderTVODOrderList)
                return d;
            var m = new $root.Proto.WorkflowBuilderTVODOrderList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.WorkflowBuilderTVODOrderList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.WorkflowBuilderTVODOrderList.value: object expected");
                    m.value[i] = $root.Proto.WorkflowBuilderTVODOrder.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a WorkflowBuilderTVODOrderList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.WorkflowBuilderTVODOrderList
         * @static
         * @param {Proto.WorkflowBuilderTVODOrderList} m WorkflowBuilderTVODOrderList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkflowBuilderTVODOrderList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.WorkflowBuilderTVODOrder.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this WorkflowBuilderTVODOrderList to JSON.
         * @function toJSON
         * @memberof Proto.WorkflowBuilderTVODOrderList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkflowBuilderTVODOrderList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkflowBuilderTVODOrderList;
    })();

    Proto.CommerceTVODMethod = (function() {

        /**
         * Properties of a CommerceTVODMethod.
         * @memberof Proto
         * @interface ICommerceTVODMethod
         * @property {number|null} [type] CommerceTVODMethod type
         * @property {number|Long|null} [registerDate] CommerceTVODMethod registerDate
         * @property {number|Long|null} [modifyDate] CommerceTVODMethod modifyDate
         */

        /**
         * Constructs a new CommerceTVODMethod.
         * @memberof Proto
         * @classdesc Represents a CommerceTVODMethod.
         * @implements ICommerceTVODMethod
         * @constructor
         * @param {Proto.ICommerceTVODMethod=} [p] Properties to set
         */
        function CommerceTVODMethod(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * CommerceTVODMethod type.
         * @member {number} type
         * @memberof Proto.CommerceTVODMethod
         * @instance
         */
        CommerceTVODMethod.prototype.type = 0;

        /**
         * CommerceTVODMethod registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.CommerceTVODMethod
         * @instance
         */
        CommerceTVODMethod.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CommerceTVODMethod modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.CommerceTVODMethod
         * @instance
         */
        CommerceTVODMethod.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new CommerceTVODMethod instance using the specified properties.
         * @function create
         * @memberof Proto.CommerceTVODMethod
         * @static
         * @param {Proto.ICommerceTVODMethod=} [properties] Properties to set
         * @returns {Proto.CommerceTVODMethod} CommerceTVODMethod instance
         */
        CommerceTVODMethod.create = function create(properties) {
            return new CommerceTVODMethod(properties);
        };

        /**
         * Encodes the specified CommerceTVODMethod message. Does not implicitly {@link Proto.CommerceTVODMethod.verify|verify} messages.
         * @function encode
         * @memberof Proto.CommerceTVODMethod
         * @static
         * @param {Proto.ICommerceTVODMethod} m CommerceTVODMethod message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommerceTVODMethod.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(8).int32(m.type);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(16).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(24).int64(m.modifyDate);
            return w;
        };

        /**
         * Decodes a CommerceTVODMethod message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.CommerceTVODMethod
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.CommerceTVODMethod} CommerceTVODMethod
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommerceTVODMethod.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.CommerceTVODMethod();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.type = r.int32();
                    break;
                case 2:
                    m.registerDate = r.int64();
                    break;
                case 3:
                    m.modifyDate = r.int64();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a CommerceTVODMethod message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.CommerceTVODMethod
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.CommerceTVODMethod} CommerceTVODMethod
         */
        CommerceTVODMethod.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.CommerceTVODMethod)
                return d;
            var m = new $root.Proto.CommerceTVODMethod();
            if (d.type != null) {
                m.type = d.type | 0;
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            return m;
        };

        /**
         * Creates a plain object from a CommerceTVODMethod message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.CommerceTVODMethod
         * @static
         * @param {Proto.CommerceTVODMethod} m CommerceTVODMethod
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommerceTVODMethod.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.type = 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
            }
            if (m.type != null && m.hasOwnProperty("type")) {
                d.type = m.type;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            return d;
        };

        /**
         * Converts this CommerceTVODMethod to JSON.
         * @function toJSON
         * @memberof Proto.CommerceTVODMethod
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommerceTVODMethod.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommerceTVODMethod;
    })();

    Proto.PlaylistTVODOrder = (function() {

        /**
         * Properties of a PlaylistTVODOrder.
         * @memberof Proto
         * @interface IPlaylistTVODOrder
         * @property {string|null} [uid] PlaylistTVODOrder uid
         * @property {string|null} [playlistUID] PlaylistTVODOrder playlistUID
         * @property {string|null} [userUID] PlaylistTVODOrder userUID
         * @property {number|null} [paymentType] PlaylistTVODOrder paymentType
         * @property {number|null} [thirdType] PlaylistTVODOrder thirdType
         * @property {string|null} [thirdCheckoutId] PlaylistTVODOrder thirdCheckoutId
         * @property {string|null} [thirdPaymentId] PlaylistTVODOrder thirdPaymentId
         * @property {boolean|null} [captured] PlaylistTVODOrder captured
         * @property {number|null} [quantity] PlaylistTVODOrder quantity
         * @property {number|null} [price] PlaylistTVODOrder price
         * @property {number|null} [fee] PlaylistTVODOrder fee
         * @property {number|null} [cashFee] PlaylistTVODOrder cashFee
         * @property {number|null} [payFee] PlaylistTVODOrder payFee
         * @property {number|null} [total] PlaylistTVODOrder total
         * @property {string|null} [currency] PlaylistTVODOrder currency
         * @property {boolean|null} [userCancel] PlaylistTVODOrder userCancel
         * @property {number|Long|null} [refundDate] PlaylistTVODOrder refundDate
         * @property {number|Long|null} [registerDate] PlaylistTVODOrder registerDate
         * @property {number|Long|null} [modifyDate] PlaylistTVODOrder modifyDate
         * @property {number|null} [status] PlaylistTVODOrder status
         * @property {boolean|null} [invoiceFixed] PlaylistTVODOrder invoiceFixed
         * @property {number|null} [point] PlaylistTVODOrder point
         */

        /**
         * Constructs a new PlaylistTVODOrder.
         * @memberof Proto
         * @classdesc Represents a PlaylistTVODOrder.
         * @implements IPlaylistTVODOrder
         * @constructor
         * @param {Proto.IPlaylistTVODOrder=} [p] Properties to set
         */
        function PlaylistTVODOrder(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PlaylistTVODOrder uid.
         * @member {string} uid
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.uid = "";

        /**
         * PlaylistTVODOrder playlistUID.
         * @member {string} playlistUID
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.playlistUID = "";

        /**
         * PlaylistTVODOrder userUID.
         * @member {string} userUID
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.userUID = "";

        /**
         * PlaylistTVODOrder paymentType.
         * @member {number} paymentType
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.paymentType = 0;

        /**
         * PlaylistTVODOrder thirdType.
         * @member {number} thirdType
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.thirdType = 0;

        /**
         * PlaylistTVODOrder thirdCheckoutId.
         * @member {string} thirdCheckoutId
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.thirdCheckoutId = "";

        /**
         * PlaylistTVODOrder thirdPaymentId.
         * @member {string} thirdPaymentId
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.thirdPaymentId = "";

        /**
         * PlaylistTVODOrder captured.
         * @member {boolean} captured
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.captured = false;

        /**
         * PlaylistTVODOrder quantity.
         * @member {number} quantity
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.quantity = 0;

        /**
         * PlaylistTVODOrder price.
         * @member {number} price
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.price = 0;

        /**
         * PlaylistTVODOrder fee.
         * @member {number} fee
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.fee = 0;

        /**
         * PlaylistTVODOrder cashFee.
         * @member {number} cashFee
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.cashFee = 0;

        /**
         * PlaylistTVODOrder payFee.
         * @member {number} payFee
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.payFee = 0;

        /**
         * PlaylistTVODOrder total.
         * @member {number} total
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.total = 0;

        /**
         * PlaylistTVODOrder currency.
         * @member {string} currency
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.currency = "";

        /**
         * PlaylistTVODOrder userCancel.
         * @member {boolean} userCancel
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.userCancel = false;

        /**
         * PlaylistTVODOrder refundDate.
         * @member {number|Long} refundDate
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.refundDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlaylistTVODOrder registerDate.
         * @member {number|Long} registerDate
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.registerDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlaylistTVODOrder modifyDate.
         * @member {number|Long} modifyDate
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.modifyDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlaylistTVODOrder status.
         * @member {number} status
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.status = 0;

        /**
         * PlaylistTVODOrder invoiceFixed.
         * @member {boolean} invoiceFixed
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.invoiceFixed = false;

        /**
         * PlaylistTVODOrder point.
         * @member {number} point
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         */
        PlaylistTVODOrder.prototype.point = 0;

        /**
         * Creates a new PlaylistTVODOrder instance using the specified properties.
         * @function create
         * @memberof Proto.PlaylistTVODOrder
         * @static
         * @param {Proto.IPlaylistTVODOrder=} [properties] Properties to set
         * @returns {Proto.PlaylistTVODOrder} PlaylistTVODOrder instance
         */
        PlaylistTVODOrder.create = function create(properties) {
            return new PlaylistTVODOrder(properties);
        };

        /**
         * Encodes the specified PlaylistTVODOrder message. Does not implicitly {@link Proto.PlaylistTVODOrder.verify|verify} messages.
         * @function encode
         * @memberof Proto.PlaylistTVODOrder
         * @static
         * @param {Proto.IPlaylistTVODOrder} m PlaylistTVODOrder message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlaylistTVODOrder.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.uid != null && Object.hasOwnProperty.call(m, "uid"))
                w.uint32(10).string(m.uid);
            if (m.playlistUID != null && Object.hasOwnProperty.call(m, "playlistUID"))
                w.uint32(18).string(m.playlistUID);
            if (m.userUID != null && Object.hasOwnProperty.call(m, "userUID"))
                w.uint32(34).string(m.userUID);
            if (m.paymentType != null && Object.hasOwnProperty.call(m, "paymentType"))
                w.uint32(48).int32(m.paymentType);
            if (m.thirdType != null && Object.hasOwnProperty.call(m, "thirdType"))
                w.uint32(56).int32(m.thirdType);
            if (m.thirdCheckoutId != null && Object.hasOwnProperty.call(m, "thirdCheckoutId"))
                w.uint32(66).string(m.thirdCheckoutId);
            if (m.thirdPaymentId != null && Object.hasOwnProperty.call(m, "thirdPaymentId"))
                w.uint32(74).string(m.thirdPaymentId);
            if (m.captured != null && Object.hasOwnProperty.call(m, "captured"))
                w.uint32(80).bool(m.captured);
            if (m.quantity != null && Object.hasOwnProperty.call(m, "quantity"))
                w.uint32(88).int32(m.quantity);
            if (m.price != null && Object.hasOwnProperty.call(m, "price"))
                w.uint32(97).double(m.price);
            if (m.fee != null && Object.hasOwnProperty.call(m, "fee"))
                w.uint32(105).double(m.fee);
            if (m.cashFee != null && Object.hasOwnProperty.call(m, "cashFee"))
                w.uint32(113).double(m.cashFee);
            if (m.payFee != null && Object.hasOwnProperty.call(m, "payFee"))
                w.uint32(121).double(m.payFee);
            if (m.total != null && Object.hasOwnProperty.call(m, "total"))
                w.uint32(129).double(m.total);
            if (m.currency != null && Object.hasOwnProperty.call(m, "currency"))
                w.uint32(138).string(m.currency);
            if (m.userCancel != null && Object.hasOwnProperty.call(m, "userCancel"))
                w.uint32(144).bool(m.userCancel);
            if (m.refundDate != null && Object.hasOwnProperty.call(m, "refundDate"))
                w.uint32(152).int64(m.refundDate);
            if (m.registerDate != null && Object.hasOwnProperty.call(m, "registerDate"))
                w.uint32(160).int64(m.registerDate);
            if (m.modifyDate != null && Object.hasOwnProperty.call(m, "modifyDate"))
                w.uint32(168).int64(m.modifyDate);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(176).int32(m.status);
            if (m.invoiceFixed != null && Object.hasOwnProperty.call(m, "invoiceFixed"))
                w.uint32(184).bool(m.invoiceFixed);
            if (m.point != null && Object.hasOwnProperty.call(m, "point"))
                w.uint32(193).double(m.point);
            return w;
        };

        /**
         * Decodes a PlaylistTVODOrder message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.PlaylistTVODOrder
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.PlaylistTVODOrder} PlaylistTVODOrder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlaylistTVODOrder.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.PlaylistTVODOrder();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.uid = r.string();
                    break;
                case 2:
                    m.playlistUID = r.string();
                    break;
                case 4:
                    m.userUID = r.string();
                    break;
                case 6:
                    m.paymentType = r.int32();
                    break;
                case 7:
                    m.thirdType = r.int32();
                    break;
                case 8:
                    m.thirdCheckoutId = r.string();
                    break;
                case 9:
                    m.thirdPaymentId = r.string();
                    break;
                case 10:
                    m.captured = r.bool();
                    break;
                case 11:
                    m.quantity = r.int32();
                    break;
                case 12:
                    m.price = r.double();
                    break;
                case 13:
                    m.fee = r.double();
                    break;
                case 14:
                    m.cashFee = r.double();
                    break;
                case 15:
                    m.payFee = r.double();
                    break;
                case 16:
                    m.total = r.double();
                    break;
                case 17:
                    m.currency = r.string();
                    break;
                case 18:
                    m.userCancel = r.bool();
                    break;
                case 19:
                    m.refundDate = r.int64();
                    break;
                case 20:
                    m.registerDate = r.int64();
                    break;
                case 21:
                    m.modifyDate = r.int64();
                    break;
                case 22:
                    m.status = r.int32();
                    break;
                case 23:
                    m.invoiceFixed = r.bool();
                    break;
                case 24:
                    m.point = r.double();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PlaylistTVODOrder message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.PlaylistTVODOrder
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.PlaylistTVODOrder} PlaylistTVODOrder
         */
        PlaylistTVODOrder.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.PlaylistTVODOrder)
                return d;
            var m = new $root.Proto.PlaylistTVODOrder();
            if (d.uid != null) {
                m.uid = String(d.uid);
            }
            if (d.playlistUID != null) {
                m.playlistUID = String(d.playlistUID);
            }
            if (d.userUID != null) {
                m.userUID = String(d.userUID);
            }
            if (d.paymentType != null) {
                m.paymentType = d.paymentType | 0;
            }
            if (d.thirdType != null) {
                m.thirdType = d.thirdType | 0;
            }
            if (d.thirdCheckoutId != null) {
                m.thirdCheckoutId = String(d.thirdCheckoutId);
            }
            if (d.thirdPaymentId != null) {
                m.thirdPaymentId = String(d.thirdPaymentId);
            }
            if (d.captured != null) {
                m.captured = Boolean(d.captured);
            }
            if (d.quantity != null) {
                m.quantity = d.quantity | 0;
            }
            if (d.price != null) {
                m.price = Number(d.price);
            }
            if (d.fee != null) {
                m.fee = Number(d.fee);
            }
            if (d.cashFee != null) {
                m.cashFee = Number(d.cashFee);
            }
            if (d.payFee != null) {
                m.payFee = Number(d.payFee);
            }
            if (d.total != null) {
                m.total = Number(d.total);
            }
            if (d.currency != null) {
                m.currency = String(d.currency);
            }
            if (d.userCancel != null) {
                m.userCancel = Boolean(d.userCancel);
            }
            if (d.refundDate != null) {
                if ($util.Long)
                    (m.refundDate = $util.Long.fromValue(d.refundDate)).unsigned = false;
                else if (typeof d.refundDate === "string")
                    m.refundDate = parseInt(d.refundDate, 10);
                else if (typeof d.refundDate === "number")
                    m.refundDate = d.refundDate;
                else if (typeof d.refundDate === "object")
                    m.refundDate = new $util.LongBits(d.refundDate.low >>> 0, d.refundDate.high >>> 0).toNumber();
            }
            if (d.registerDate != null) {
                if ($util.Long)
                    (m.registerDate = $util.Long.fromValue(d.registerDate)).unsigned = false;
                else if (typeof d.registerDate === "string")
                    m.registerDate = parseInt(d.registerDate, 10);
                else if (typeof d.registerDate === "number")
                    m.registerDate = d.registerDate;
                else if (typeof d.registerDate === "object")
                    m.registerDate = new $util.LongBits(d.registerDate.low >>> 0, d.registerDate.high >>> 0).toNumber();
            }
            if (d.modifyDate != null) {
                if ($util.Long)
                    (m.modifyDate = $util.Long.fromValue(d.modifyDate)).unsigned = false;
                else if (typeof d.modifyDate === "string")
                    m.modifyDate = parseInt(d.modifyDate, 10);
                else if (typeof d.modifyDate === "number")
                    m.modifyDate = d.modifyDate;
                else if (typeof d.modifyDate === "object")
                    m.modifyDate = new $util.LongBits(d.modifyDate.low >>> 0, d.modifyDate.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = d.status | 0;
            }
            if (d.invoiceFixed != null) {
                m.invoiceFixed = Boolean(d.invoiceFixed);
            }
            if (d.point != null) {
                m.point = Number(d.point);
            }
            return m;
        };

        /**
         * Creates a plain object from a PlaylistTVODOrder message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.PlaylistTVODOrder
         * @static
         * @param {Proto.PlaylistTVODOrder} m PlaylistTVODOrder
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlaylistTVODOrder.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.uid = "";
                d.playlistUID = "";
                d.userUID = "";
                d.paymentType = 0;
                d.thirdType = 0;
                d.thirdCheckoutId = "";
                d.thirdPaymentId = "";
                d.captured = false;
                d.quantity = 0;
                d.price = 0;
                d.fee = 0;
                d.cashFee = 0;
                d.payFee = 0;
                d.total = 0;
                d.currency = "";
                d.userCancel = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.refundDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.refundDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.registerDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.registerDate = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.modifyDate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.modifyDate = o.longs === String ? "0" : 0;
                d.status = 0;
                d.invoiceFixed = false;
                d.point = 0;
            }
            if (m.uid != null && m.hasOwnProperty("uid")) {
                d.uid = m.uid;
            }
            if (m.playlistUID != null && m.hasOwnProperty("playlistUID")) {
                d.playlistUID = m.playlistUID;
            }
            if (m.userUID != null && m.hasOwnProperty("userUID")) {
                d.userUID = m.userUID;
            }
            if (m.paymentType != null && m.hasOwnProperty("paymentType")) {
                d.paymentType = m.paymentType;
            }
            if (m.thirdType != null && m.hasOwnProperty("thirdType")) {
                d.thirdType = m.thirdType;
            }
            if (m.thirdCheckoutId != null && m.hasOwnProperty("thirdCheckoutId")) {
                d.thirdCheckoutId = m.thirdCheckoutId;
            }
            if (m.thirdPaymentId != null && m.hasOwnProperty("thirdPaymentId")) {
                d.thirdPaymentId = m.thirdPaymentId;
            }
            if (m.captured != null && m.hasOwnProperty("captured")) {
                d.captured = m.captured;
            }
            if (m.quantity != null && m.hasOwnProperty("quantity")) {
                d.quantity = m.quantity;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = o.json && !isFinite(m.price) ? String(m.price) : m.price;
            }
            if (m.fee != null && m.hasOwnProperty("fee")) {
                d.fee = o.json && !isFinite(m.fee) ? String(m.fee) : m.fee;
            }
            if (m.cashFee != null && m.hasOwnProperty("cashFee")) {
                d.cashFee = o.json && !isFinite(m.cashFee) ? String(m.cashFee) : m.cashFee;
            }
            if (m.payFee != null && m.hasOwnProperty("payFee")) {
                d.payFee = o.json && !isFinite(m.payFee) ? String(m.payFee) : m.payFee;
            }
            if (m.total != null && m.hasOwnProperty("total")) {
                d.total = o.json && !isFinite(m.total) ? String(m.total) : m.total;
            }
            if (m.currency != null && m.hasOwnProperty("currency")) {
                d.currency = m.currency;
            }
            if (m.userCancel != null && m.hasOwnProperty("userCancel")) {
                d.userCancel = m.userCancel;
            }
            if (m.refundDate != null && m.hasOwnProperty("refundDate")) {
                if (typeof m.refundDate === "number")
                    d.refundDate = o.longs === String ? String(m.refundDate) : m.refundDate;
                else
                    d.refundDate = o.longs === String ? $util.Long.prototype.toString.call(m.refundDate) : o.longs === Number ? new $util.LongBits(m.refundDate.low >>> 0, m.refundDate.high >>> 0).toNumber() : m.refundDate;
            }
            if (m.registerDate != null && m.hasOwnProperty("registerDate")) {
                if (typeof m.registerDate === "number")
                    d.registerDate = o.longs === String ? String(m.registerDate) : m.registerDate;
                else
                    d.registerDate = o.longs === String ? $util.Long.prototype.toString.call(m.registerDate) : o.longs === Number ? new $util.LongBits(m.registerDate.low >>> 0, m.registerDate.high >>> 0).toNumber() : m.registerDate;
            }
            if (m.modifyDate != null && m.hasOwnProperty("modifyDate")) {
                if (typeof m.modifyDate === "number")
                    d.modifyDate = o.longs === String ? String(m.modifyDate) : m.modifyDate;
                else
                    d.modifyDate = o.longs === String ? $util.Long.prototype.toString.call(m.modifyDate) : o.longs === Number ? new $util.LongBits(m.modifyDate.low >>> 0, m.modifyDate.high >>> 0).toNumber() : m.modifyDate;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.invoiceFixed != null && m.hasOwnProperty("invoiceFixed")) {
                d.invoiceFixed = m.invoiceFixed;
            }
            if (m.point != null && m.hasOwnProperty("point")) {
                d.point = o.json && !isFinite(m.point) ? String(m.point) : m.point;
            }
            return d;
        };

        /**
         * Converts this PlaylistTVODOrder to JSON.
         * @function toJSON
         * @memberof Proto.PlaylistTVODOrder
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlaylistTVODOrder.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlaylistTVODOrder;
    })();

    Proto.PlaylistTVODOrderList = (function() {

        /**
         * Properties of a PlaylistTVODOrderList.
         * @memberof Proto
         * @interface IPlaylistTVODOrderList
         * @property {Array.<Proto.IPlaylistTVODOrder>|null} [value] PlaylistTVODOrderList value
         */

        /**
         * Constructs a new PlaylistTVODOrderList.
         * @memberof Proto
         * @classdesc Represents a PlaylistTVODOrderList.
         * @implements IPlaylistTVODOrderList
         * @constructor
         * @param {Proto.IPlaylistTVODOrderList=} [p] Properties to set
         */
        function PlaylistTVODOrderList(p) {
            this.value = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PlaylistTVODOrderList value.
         * @member {Array.<Proto.IPlaylistTVODOrder>} value
         * @memberof Proto.PlaylistTVODOrderList
         * @instance
         */
        PlaylistTVODOrderList.prototype.value = $util.emptyArray;

        /**
         * Creates a new PlaylistTVODOrderList instance using the specified properties.
         * @function create
         * @memberof Proto.PlaylistTVODOrderList
         * @static
         * @param {Proto.IPlaylistTVODOrderList=} [properties] Properties to set
         * @returns {Proto.PlaylistTVODOrderList} PlaylistTVODOrderList instance
         */
        PlaylistTVODOrderList.create = function create(properties) {
            return new PlaylistTVODOrderList(properties);
        };

        /**
         * Encodes the specified PlaylistTVODOrderList message. Does not implicitly {@link Proto.PlaylistTVODOrderList.verify|verify} messages.
         * @function encode
         * @memberof Proto.PlaylistTVODOrderList
         * @static
         * @param {Proto.IPlaylistTVODOrderList} m PlaylistTVODOrderList message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlaylistTVODOrderList.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.value != null && m.value.length) {
                for (var i = 0; i < m.value.length; ++i)
                    $root.Proto.PlaylistTVODOrder.encode(m.value[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a PlaylistTVODOrderList message from the specified reader or buffer.
         * @function decode
         * @memberof Proto.PlaylistTVODOrderList
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {Proto.PlaylistTVODOrderList} PlaylistTVODOrderList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlaylistTVODOrderList.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.Proto.PlaylistTVODOrderList();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.value && m.value.length))
                        m.value = [];
                    m.value.push($root.Proto.PlaylistTVODOrder.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Creates a PlaylistTVODOrderList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Proto.PlaylistTVODOrderList
         * @static
         * @param {Object.<string,*>} d Plain object
         * @returns {Proto.PlaylistTVODOrderList} PlaylistTVODOrderList
         */
        PlaylistTVODOrderList.fromObject = function fromObject(d) {
            if (d instanceof $root.Proto.PlaylistTVODOrderList)
                return d;
            var m = new $root.Proto.PlaylistTVODOrderList();
            if (d.value) {
                if (!Array.isArray(d.value))
                    throw TypeError(".Proto.PlaylistTVODOrderList.value: array expected");
                m.value = [];
                for (var i = 0; i < d.value.length; ++i) {
                    if (typeof d.value[i] !== "object")
                        throw TypeError(".Proto.PlaylistTVODOrderList.value: object expected");
                    m.value[i] = $root.Proto.PlaylistTVODOrder.fromObject(d.value[i]);
                }
            }
            return m;
        };

        /**
         * Creates a plain object from a PlaylistTVODOrderList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Proto.PlaylistTVODOrderList
         * @static
         * @param {Proto.PlaylistTVODOrderList} m PlaylistTVODOrderList
         * @param {$protobuf.IConversionOptions} [o] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlaylistTVODOrderList.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.value = [];
            }
            if (m.value && m.value.length) {
                d.value = [];
                for (var j = 0; j < m.value.length; ++j) {
                    d.value[j] = $root.Proto.PlaylistTVODOrder.toObject(m.value[j], o);
                }
            }
            return d;
        };

        /**
         * Converts this PlaylistTVODOrderList to JSON.
         * @function toJSON
         * @memberof Proto.PlaylistTVODOrderList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlaylistTVODOrderList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlaylistTVODOrderList;
    })();

    return Proto;
})();

export { $root as default };
